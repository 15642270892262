import React from "react";
import triangleIcon from "../../../../../assets/images/tna/triangle.svg";

import "./TnaStepProgress.scss";

const TnaStepProgress = ({
  data,
  isComplete,
  inProgressStage,
  activeStage,
  switchStage,
  isAccordion
}) => {
  return (
    <div className="tna-graph__timeline">
      {data && data.map(stage => {
        let timelineProgressClass = !isComplete
          ? stage.id === inProgressStage
            ? "timeline timeline--progress"
            : "timeline"
          : "timeline timeline--complete";

        let isActive = stage.id === activeStage;

        let isMinDate = stage.minDate !== "Invalid date" ? true : false;
        let isMaxDate = stage.maxDate !== "Invalid date" ? true : false;

        let timelineDate = isMinDate
          ? isMaxDate
            ? stage.minDate === stage.maxDate
              ? stage.minDate
              : `${stage.minDate} - ${stage.maxDate}`
            : `${stage.minDate} - In progress`
          : `by ${stage.planDate}`;

        return (
          <div
            className={
              timelineProgressClass + " " + (isActive ? "timeline--active" : "")
            }
            key={stage.id}
            id={stage.id}
          >
            <div
              onClick={
                stage.id <= inProgressStage || isComplete
                  ? () => switchStage(stage.id)
                  : undefined
              }
            >
              <div className="timeline__heading">
                {stage.category.toLowerCase()}
              </div>
              <div className="timeline__date">{timelineDate}</div>
            </div>
            {isAccordion ? (
              <img className="timeline__pointer" src={triangleIcon} alt="" />
            ) : null}
          </div>
        );
      })}
    </div>
  );
};

export default TnaStepProgress;
