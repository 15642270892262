import React, { Component } from "react";
import PerfectScrollbar from 'react-perfect-scrollbar';

import "./style.scss";

import DesignSummaryForm from "./DesignSummaryForm";
import DesignSummaryDetails from "./DesignSummaryDetails";
import Button from "components/UI/Button/Button";

import {
  getCoverImagePlaceholder,
  getSmallImageUrl
} from "helpers/DesignHelpers";

import editIcon from "assets/images/image/ic-edit.svg";
import icUpload from "assets/images/action/ic-upload.svg";
import { IconSave } from "../../UI/Icons";
import { disableDesignActions } from "../../../helpers/DesignHelpers";

class DesignSummary extends Component {

  constructor(props) {
    super(props);

    this.state = {
      designSummaryChanged: {},
      editMode: false,
      disabledDesignActions: (props.design ? disableDesignActions(
        props.design.status
      ) : true),
    };


    this.coverFileRef = React.createRef(null);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.design !== nextProps.design) {
      this.setState({
        disabledDesignActions: disableDesignActions(
          this.props.design.status
        ),
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // After teh design summary is edited we need to set the editMode to false based on the modified_on field
    if (this.state.editMode && prevProps.design['modified_on']) {
      if (prevProps.design['modified_on'] != this.props.design.modified_on) {
        this.setState({
          editMode: false
        })
      }
    }
  }

  getCoverImage = files => {
    if (Object.keys(files).length > 0) {
      const fileId = Object.keys(files).filter(
        f => this.props.design.files[f].is_cover === 1
      )[0];
      if (fileId === undefined) {
        return { image: null, fileId: null };
      } else {
        return {
          image: getSmallImageUrl(files[fileId].fileloc),
          fileId: fileId
        };
      }
    } else {
      return { image: null, fileId: null };
    }
  };

  handleSummaryChange = ({ name, value }) => {
    if (this.props.design[name] !== value) {
      this.setState({
        designSummaryChanged: {
          ...this.state.designSummaryChanged,
          [name]: true
        }
      });
    } else {
      this.setState({
        designSummaryChanged: {
          ...this.state.designSummaryChanged,
          [name]: false
        }
      });
    }
  };

  removeCoverFile = ({ fileId }) => {
    if (!window.confirm("Are you sure you want to delete this cover image ?"))
      return;

    const currentCoverFile = this.props.design.files[fileId];
    const updatedFileObj = {
      file_id: fileId,
      file_title: currentCoverFile["file_title"],
      file_category: currentCoverFile["file_category"],
      cover: 0
    };

    this.props.patchDesignFile({
      fileInfo: updatedFileObj,
      designInfo: this.props.design
    });
    if (currentCoverFile["file_category"] === "CoverFile7894")
      this.props.deleteDesignFile({
        file_id: fileId,
        collection_id: this.props.design.collection_id
      });
  };

  uploadCoverFile = event => {
    const currentFile = event.target.files[0];
    if (currentFile === undefined) return;
    const newFileObject = new FormData();
    newFileObject.append("designfile", currentFile, currentFile.name);
    newFileObject.append("product_id", this.props.design.id);
    newFileObject.append("file_title", `CoverFile7894${Date.now()}`);
    newFileObject.append("file_category", "Inspiration");
    newFileObject.append("cover", 1);

    this.props.uploadNewDesignFile({
      design: this.props.design,
      file: newFileObject
    });
  };

  handleClick = editMode => {
    this.setState({
      editMode
    })
  }

  handleSaveClick = () => {
    this.props.submitEditDesignForm();
  }

  _handleFormSubmit = (...args) => {
    this.props.editDesign(...args)
      .then(() => {
        this.setState({
          editMode: false,
        })
      });
  }

  render() {
    const { editMode, disabledDesignActions } = this.state;
    const { image } = this.getCoverImage(this.props.design.files);
    let imgAttributes = { src: image };

    if (image === null) {
      imgAttributes = {
        ...getCoverImagePlaceholder(this.props.design),
        style: { objectFit: "scale-down" }
      };
    }

    return (
      <section className="design-summary-details">
        <div className="card card__section flex-col" >
          <div className="design-summary-details__cover-title">
            <div>Details</div>
            {
              !disabledDesignActions && (editMode ? (
                <aside className="flex">
                  <Button
                    category="black-ghost"
                    className="fs-12-bold"
                    onClick={() => this.handleClick(false)}
                  >
                    <span className="icon-text">Cancel</span>
                  </Button>

                  <Button
                    category="blue-bg"
                    className="fs-12-bold"
                    onClick={this.handleSaveClick}
                  >
                    <IconSave color="white" height="16px" style={{ marginRight: "4px" }} />
                    <span className="icon-text">Save</span>
                  </Button>
                </aside>

              ) : (
                  <Button category="btn" className="f-sz-14" onClick={() => this.handleClick(true)}>
                    <img src={editIcon} alt="edit" />Edit
                  </Button>
                )
              )}
          </div>
          <div className="flex design-summary-details__content" >
            <div className="design-summary-details__img">
              <img {...imgAttributes} alt="model" />
              {
                !image && !disabledDesignActions && (
                  <div className="upload_image">
                    <span>Upload cover image</span>
                    <span
                      onClick={() => {
                        this.coverFileRef.current.click();
                      }}
                    ><img src={icUpload} alt="Upload" /></span>
                    <input
                      ref={this.coverFileRef}
                      className="hide"
                      type="file"
                      name="coverfilefile"
                      accept="image/x-png,image/gif,image/jpeg"
                      onChange={this.uploadCoverFile}
                    />
                  </div>
                )
              }
            </div>
            <PerfectScrollbar className="design-summary-details__form">
              {
                editMode ? (
                  <DesignSummaryForm
                    design={this.props.design}
                    onSubmit={this._handleFormSubmit}
                    onFormChange={this.handleSummaryChange}
                  />
                ) : (
                    <DesignSummaryDetails design={this.props.design} currentUser={this.props.currentUser} />
                  )
              }

            </PerfectScrollbar>
          </div>
        </div>
      </section>
    );
  }
}

DesignSummary.defaultProps = {
  design: {
    files: []
  }
};

export default DesignSummary;
