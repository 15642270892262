import React from 'react';
import moment from 'moment';

export const dateDifference = (planDate) => {
  const daysDiff = moment(planDate).diff(Date(), "days");
  if (daysDiff < 0) {
    // Deadline crossed
    return <span style={{ color: 'var(--fill-error)' }}>{Math.abs(daysDiff)} days delayed</span>
  }
  else if (daysDiff > 0)
    return <span style={{ color: 'var(--fill-orange)' }}>Due in {daysDiff} days</span>
}

export const daysAgo = (date, { sinceDate, prefixOn } = {}) => {
  if (!date) {
    return;
  }

  sinceDate = sinceDate || new Date();

  const momentLastActivity = moment(date);
  const sinceLastActivity = moment.duration(moment(sinceDate).diff(momentLastActivity));
  const strLastActivity = sinceLastActivity.asDays() > 10
    ? (prefixOn ? 'on ' : '') + momentLastActivity.format('MMM D, YYYY')
    : sinceLastActivity.humanize() + ' ago';

  return strLastActivity;
}

export const areSameDates = (d1, d2) => {
  return moment(d1).startOf('day').diff(moment(d2).startOf('day')) === 0;
};
