import React, { Component } from "react";

import QuoteHeader from "./Header/index";
import QuoteTable from "./Table/index";
import EmptyScreen from "../UI/EmptyScreen/index"
import { FACTORY_ADMIN, FACTORY_MANAGER, CLIENT_SUPER, CLIENT } from '../../constants/Auth';
import { MAP_QUOTE_FILTER_STATUS } from '../../constants/Quote';

import emptyIcon from "assets/img/Quote/ic-quote-empty.svg";
import "./style.scss";
import { CURRENCY } from "../../constants/Design";


/**
 * MAP_QUOTE_STATUS is used in GET /quotes API for status based filtering
 * NOTE: MAP_QUOTE_STATUS is not related to quote status
 */
const MAP_QUOTE_STATUS = {
  all: MAP_QUOTE_FILTER_STATUS.ALL,
  requested: MAP_QUOTE_FILTER_STATUS.REQUESTED,
  awaited: MAP_QUOTE_FILTER_STATUS.REQUESTED,
  submitted: MAP_QUOTE_FILTER_STATUS.SUBMITTED,
  'review-pending': MAP_QUOTE_FILTER_STATUS.SUBMITTED,
  approved: MAP_QUOTE_FILTER_STATUS.APPROVED,
  rejected: MAP_QUOTE_FILTER_STATUS.REJECTED,
}


const DEFAULT_APPLIED_FILTERS = {
  buyer: { values: [] },
  factory: { values: [] },
};

const getQuotes = ({ appliedFilters, pagination }, props, state) => {
  const { getAllQuotes, selectedTabFilter } = props;

  if (typeof (getAllQuotes) !== 'function') {
    return;
  }

  pagination = pagination || props.pagination;
  appliedFilters = appliedFilters || state.appliedFilters;

  getAllQuotes({
    factoryIds: (appliedFilters.factory && appliedFilters.factory.values) || [],
    brandIds: (appliedFilters.buyer && appliedFilters.buyer.values) || [],
    offset: (pagination && pagination.nextOffset) || 0,
    quoteStatuses: [MAP_QUOTE_STATUS[selectedTabFilter]],
  });
}

class Quotes extends Component {

  constructor(props) {
    super(props);

    this.state = {
      hasFilters: false,
      appliedFilters: {
        ...DEFAULT_APPLIED_FILTERS,
      },
      mapFilters: {
        buyer: {
          title: 'BUYER',
          key: 'buyer',
          items: [],
          denyRoles: [CLIENT_SUPER, CLIENT],
        },
        factory: {
          title: 'FACTORY',
          key: 'factory',
          items: [],
          denyRoles: [FACTORY_ADMIN, FACTORY_MANAGER],
        },
      },

      propBrands: [],
      propFactories: [],
      propGetAllQuotes: null,
      selectedTabFilter: '',
      paginationIndex: 0,
    };
  }


  static getDerivedStateFromProps(nextProps, prevState) {
    const nextState = {
      appliedFilters: { ...prevState.appliedFilters }
    };
    let isGetQuotesRequired = false;

    if (nextProps.allBrands !== prevState.propBrands) {
      nextState.propBrands = nextProps.allBrands;

      nextState.mapFilters = nextState.mapFilters || { ...prevState.mapFilters };
      nextState.mapFilters.buyer = {
        ...prevState.mapFilters.buyer,
        items: (Array.isArray(nextProps.allBrands)
          && nextProps.allBrands.map(brand => ({
            key: brand.id,
            label: brand.name,
            value: brand.id,
            isSelected: brand.isSelected || false,
          }))) || [],
      };
    }

    if (nextProps.allFactories !== prevState.propFactories) {
      nextState.propFactories = nextProps.allFactories;

      nextState.mapFilters = nextState.mapFilters || { ...prevState.mapFilters };
      nextState.mapFilters.factory = {
        ...prevState.mapFilters.factory,
        items: (Array.isArray(nextProps.allFactories)
          && nextProps.allFactories.map(factory => ({
            key: factory.id,
            label: factory.name,
            value: factory.id,
            isSelected: factory.isSelected || false,
          }))) || [],
      };

      if (nextProps.userDetails && nextProps.userDetails.isSuperAdmin) {
        const locofastFactory = nextState.mapFilters.factory.items.find(item => item.key === null);
        if (locofastFactory) {
          locofastFactory.isSelected = true;
        }
      }
    }

    if (nextProps.selectedTabFilter !== prevState.selectedTabFilter) {
      nextState.selectedTabFilter = nextProps.selectedTabFilter;
      nextState.paginationIndex = 0;
      if (nextProps.getAllQuotes) {
        isGetQuotesRequired = true;
      }
    }

    if (nextProps.getAllQuotes !== prevState.propGetAllQuotes) {
      nextState.propGetAllQuotes = nextProps.getAllQuotes;
      if (typeof (nextProps.getAllQuotes) === 'function') {
        isGetQuotesRequired = true;
      }
    }

    if (isGetQuotesRequired) {
      getQuotes({}, nextProps, { ...prevState, ...nextState });
    }

    return Object.keys(nextState).length > 0 ? nextState : null;
  }

  paginationHandler = ({ offset, isnext }) => {
    const { quotes: { rows, paginate } } = this.props;
    const { paginationIndex } = this.state;

    if (!isnext) {
      // setSavedDesigns(prevSavedDesigns[paginationIndex - 1]);
      this.setState({ paginationIndex: paginationIndex - 1 });
      return;
    }

    if (rows.length > ((paginationIndex + 1) * paginate.limit)) {
      // then alter the designs

      // setSavedDesigns(orders);
    } else {
      getQuotes({
        pagination: paginate
      }, this.props, this.state);
    }

    this.setState({ paginationIndex: paginationIndex + 1 });

    // if (params.isnext) {
    //   getQuotes({
    //     pagination:{offset: pagination.nextOffset}
    //   }, this.props, this.state);
    // }
  }

  handleApplyFilters = (appliedFilters) => {
    const { mapFilters } = this.state;
    const updatedMapFilters = {};

    if (mapFilters.buyer && appliedFilters.filters.buyer) {
      const mapSelectedBrandIds = appliedFilters.filters.buyer.values
        .reduce((prev, value) => {
          prev[value] = true;
          return prev
        }, {});

      updatedMapFilters.buyer = {
        ...mapFilters.buyer,
        items: mapFilters.buyer.items.map(brand => {
          return {
            ...brand,
            isSelected: mapSelectedBrandIds[brand.value],
          };
        }),
      };
    }

    if (mapFilters.factory && appliedFilters.filters.factory) {
      const mapSelectedFactoryIds = appliedFilters.filters.factory.values
        .reduce((prev, value) => {
          prev[value] = true;
          return prev
        }, {});

      updatedMapFilters.factory = {
        ...mapFilters.factory,
        items: mapFilters.factory.items.map(factory => {
          return {
            ...factory,
            isSelected: mapSelectedFactoryIds[factory.value],
          };
        }),
      };
    }

    this.setState({
      appliedFilters: appliedFilters.filters,
      hasFilters: appliedFilters.filtersCount > 0,
      paginationIndex: 0,
      mapFilters: updatedMapFilters,
    });

    getQuotes({
      appliedFilters: appliedFilters.filters,
      pagination: { nextOffset: 0 }
    }, this.props, this.state);
  }

  onClearFilters = () => {
    this.handleApplyFilters({
      filters: {
        buyer: { values: [] },
        factory: { values: [] },
      },
      filtersCount: 0,
    });
  }

  _refreshQuotes = () => {
    getQuotes({}, this.props, this.state);
  }

  render() {
    const {
      openModal,
      quotes,
      getAllQuotes,
      allBrands,
      allFactories,
      selectedTabFilter,
      userDetails,
    } = this.props;
    const {
      hasFilters,
      paginationIndex,
      mapFilters,
    } = this.state;

    if ((!quotes.rows || !quotes.rows.length > 0)
      && (!quotes.paginate || !quotes.paginate.totalCount)
      && !hasFilters) {
      return <EmptyScreen
        icon={emptyIcon}
        heading="No quotes available"
        description={<>
          All requested, received and approved <br />
          quotes will be available here
        </>}
      // // enable CTA once cta link is available
      // cta={<Link className="link-blue" to="."> //
      //   <span> Learn more about quotes</span>
      // </Link>}
      />
    }

    return <>
      <QuoteHeader
        openModal={openModal}
        quotes={quotes.rows}
        pagination={quotes.paginate}
        getAllQuotes={getAllQuotes}
        allBrands={allBrands}
        allFactories={allFactories}
        selectedTabFilter={selectedTabFilter}
        userDetails={userDetails}
        onApplyFilters={this.handleApplyFilters}
        paginationHandler={this.paginationHandler}
        paginationIndex={paginationIndex}
        filters={Object.values(mapFilters)}
      />
      <QuoteTable
        quotes={quotes.rows.slice(paginationIndex * quotes.paginate.limit, (paginationIndex + 1) * quotes.paginate.limit)}
        hasFilters={hasFilters}
        userDetails={userDetails}
        onClearFilters={this.onClearFilters}
        openModal={openModal}
        currency={CURRENCY[1]}
        refreshQuotes={this._refreshQuotes}
      />
    </>;
  }
}

export default Quotes;
