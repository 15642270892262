import React, { useState, useEffect, useCallback } from "react";
import { Field, submit } from "redux-form";
import { connect } from "react-redux";
import find from "lodash/find";
import { components } from "react-select";
import isEmpty from "lodash/isEmpty";
import axios from "axios";

import Form from "components/UI/Form/Form";
import Select from "components/UI/Form/Select/Select";
import Input from "components/UI/Form/Input/Input";
import Button from "components/UI/Button/Button";
import MaterialImage from "./../MaterialDetail/MaterialImage";
import { postMaterialOutwards } from "store/actions/MaterialLibraryActions";
import FileInput from "components/UI/File/FileWithPreview";
import { TextSpliced } from "components/UI/TextSpliced";
import SizeTable from "./../SizeTable";
import StaticTable from "../SizeTable/StaticTable";

import { selectStyles } from "components/SwatchLibrary/AddSwatchModal/select-styles";

import { required, minValue, decimalUpto2 } from "helpers/form-validations";
import { IconArrowBroad } from "components/UI/Icons";
import { IconInfo } from "components/UI/Icons";
import { ReactComponent as EyeIcon } from "assets/images/materialLibrary/ic-eye.svg";

import { getCoverImagePlaceholder } from "helpers/DesignHelpers";
import { hasValidQuantity } from "helpers/materialHelper";

import "./styles.scss";
import { get_service_endpoint } from "ServiceEndpoints";

import VIEWS from "constants/MaterialLibrary";

const { customSingleStyles } = selectStyles;
const eq = get_service_endpoint("apiv2");

const FORM_NAME = "AssignFactory";

const OUTWARDS_VIEWS = {
  FORM: 0,
  DETAILS: 1
};

const minValue0 = minValue(0.01);

const Option = props => {
  return (
    <components.Option {...props}>
      <div className="option">
        <img
          src={
            props.data.image
              ? props.data.image
              : getCoverImagePlaceholder({ category: props.data.category })[
                  "src"
                ]
          }
          alt=""
        />
        <div>
          <div className="option__heading">{props.data.label}</div>
          <div className="option__subheading">{props.data.sourceDesignId}</div>
        </div>
      </div>
    </components.Option>
  );
};

const FormRow = ({
  children,
  label,
  required = false,
  style,
  quantity,
  availableQuantity,
  unit
}) => (
  <div className="form__row" style={{ ...style }}>
    <div>
      {label}
      {required && <sup>*</sup>}
    </div>
    {children}
    {quantity ? (
      quantity > availableQuantity ? (
        <div className="error">
          Quantity cannot be greater than {availableQuantity} {unit}
        </div>
      ) : null
    ) : null}
  </div>
);

const AssignFactoryModal = props => {
  const [formValid, setFormValid] = useState(false);
  const [formValuesRaw, setFormValuesRaw] = useState({
    materialId: props.material.id,
    ...props.initialValues
  });
  const [formValues, setFormValues] = useState({});
  const [currentView, setCurrentView] = useState(OUTWARDS_VIEWS.FORM);
  const [factories, setFactories] = useState([]);
  const [designs, setDesigns] = useState([]);
  const [factoryId, setFactoryId] = useState(null);
  const [challanFile, setChallanFile] = useState(null);

  const hasMultipleSizes = props.material.sizes && props.material.sizes.length > 0;

  useEffect(() => {
    axios
      .get(`${eq}/material/factories`, {
        params: { brandId: props.material.brandId }
      })
      .then(res => {
        setFactories(res.data);
      });
  }, []);

  useEffect(() => {
    if (factoryId) {
      axios
        .get(`${eq}/material/factories/designs`, {
          params: { brandId: props.material.brandId, factoryId }
        })
        .then(res => {
          setDesigns(res.data);
        });
    }
  }, [factoryId]);

  return (
    <React.Fragment>
      <div className="material__details">
        <div className="material__image_wrapper">
          <MaterialImage image={props.image} />
          <div className="material__details_form">
            <div className="assign_factory_modal">
              {OUTWARDS_VIEWS.FORM === currentView ? (
                <React.Fragment>
                  <div className="header flex f-sz-xl f-w-bold">
                    <Button
                      category="icon"
                      className="modal__nav-back"
                      onClick={() => props.switchViews(VIEWS.DETAILS)}
                    >
                      <IconArrowBroad direction="left" />
                    </Button>
                    Assign to factory
                  </div>
                  <Form
                    {...props}
                    form={FORM_NAME}
                    initialValues={formValuesRaw}
                  >
                    {({ changeFormValue, ...formProps }) => (
                      <div>
                        {(() => {
                          if (
                            formProps.valid &&
                            formProps.dirty &&
                            !formValid
                          ) {
                            if (hasMultipleSizes) {
                              if (
                                hasValidQuantity(
                                  props.formData.values,
                                  props.trimsAvailableSize
                                )
                              ) {
                                setFormValid(true);
                              }
                            } else if(props.formData.values.quantity <= props.availableQuantity){
                              setFormValid(true);
                            }
                          } else if (
                            formValid &&
                            props.formData &&
                            (formProps.invalid ||
                              (hasMultipleSizes ?
                                !hasValidQuantity(
                                  props.formData.values,
                                  props.trimsAvailableSize
                                ) : props.formData.values.quantity > props.availableQuantity)
                                )
                          ) {
                            setFormValid(false);
                          }
                        })()}
                        {(() => {
                          if (formProps.dirty) {
                            if (props.formData) {
                              setFactoryId(props.formData.values.factoryId);
                            }
                          }
                        })()}
                        <FormRow label="Factory" required>
                          <Field
                            name="factoryId"
                            component={Select}
                            options={factories.map(factory => ({
                              label: factory.name,
                              value: factory.id
                            }))}
                            styles={customSingleStyles}
                            validate={[required]}
                            selectProps={{
                              classNamePrefix: "react-select"
                            }}
                            className="react-select-container"
                            placeholder="Select factory"
                          />
                        </FormRow>
                        <div
                          className={`${
                            props.formData && props.formData.values.factoryId
                              ? ""
                              : "hidden"
                          }`}
                        >
                          <FormRow label="Assign for design">
                            <Field
                              name="designId"
                              component={Select}
                              options={designs}
                              styles={customSingleStyles}
                              selectProps={{
                                classNamePrefix: "react-select",
                                components: { Option }
                              }}
                              className="react-select-container"
                              placeholder="Select design"
                            />
                          </FormRow>
                          {props.material &&  props.material.sizes && props.material.sizes.length > 0 ? (
                            <div className="grid-2 col-equal">
                              <FormRow
                                label="Quantity"
                                required
                                quantity={
                                  props.formData
                                    ? props.formData.values.quantity
                                    : []
                                }
                                availableQuantity={props.availableQuantity}
                                unit={props.material.unit.shortName}
                              >
                                <Field
                                  name="quantity"
                                  component={SizeTable}
                                  tableHeading={`Quantity(${props.material.unit.shortName})`}
                                  availableSizes={Object.values(
                                    props.trimsAvailableSize
                                  )}
                                  renderAvailaiblity={hasMultipleSizes}
                                />
                              </FormRow>
                            </div>
                          ) : (
                            <div className="grid-2 padding-0 col-equal">
                              <FormRow
                                label="Quantity"
                                required
                                quantity={
                                  props.formData
                                    ? props.formData.values.quantity
                                    : []
                                }
                                availableQuantity={props.availableQuantity}
                                unit={props.material.unit.shortName}
                              >
                                <Field
                                  name="quantity"
                                  component={Input}
                                  type="number"
                                  validate={[required, minValue0, decimalUpto2]}
                                  rightAddon={
                                    <div>{props.material.unit.shortName}</div>
                                  }
                                  placeholder="Enter quantity"
                                />
                              </FormRow>
                              <FormRow label="Available Inventory">
                                <div className="l-height-50">
                                  {props.availableQuantity}{" "}
                                  {props.material.unit.shortName}
                                </div>
                              </FormRow>
                            </div>
                          )}
                          <div className="grid-2 col-equal">
                            <FormRow label="Attach Challan">
                              <Field
                                name="challanFile"
                                component={FileInput}
                                handleClick={file => {
                                  setChallanFile(file);
                                }}
                              />
                            </FormRow>
                            <div />
                          </div>
                        </div>
                      </div>
                    )}
                  </Form>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="header flex f-sz-xl f-w-bold">
                    <Button
                      category="icon"
                      className="modal__nav-back"
                      onClick={() => setCurrentView(OUTWARDS_VIEWS.FORM)}
                    >
                      <IconArrowBroad direction="left" />
                    </Button>
                    Assign to factory
                  </div>
                  <div className="info-note">
                    <IconInfo color="blue" />
                    <div>You won’t be able to change the details later.</div>
                  </div>
                  <div>
                    <div className="details__item">
                      <div className="label">Factory</div>
                      <div>{formValues["factoryId"].name}</div>
                    </div>
                    {
                      formValues["designId"] && (
                        <div className="details__item">
                          <div className="label">Assign for design</div>
                          <div>{formValues["designId"].label}</div>
                        </div>
                      )
                    }
                    {hasMultipleSizes ? (
                      <div className="details__item">
                        <div className="label">Quantity</div>
                        <div>
                          <StaticTable
                          sizes={formValues.selectedSizes}
                          tableHeading={`Quantity(${props.material.unit.shortName})`}
                        />
                        </div>
                      </div>
                    ) : (
                      <div className="details__item">
                        <div className="label">Quantity</div>
                        <div>
                          {formValues["quantity"]}{" "}
                          {props.material.unit.shortName}
                        </div>
                      </div>
                    )}
                    {challanFile && (
                      <div className="details__item with_image">
                        <div className="label">Challan</div>
                        <div className="input-file__meta-selected">
                          <TextSpliced
                            text={challanFile["name"]}
                            maxWidth="150"
                          />
                          <div
                            onClick={() =>
                              window.open(
                                window.URL.createObjectURL(challanFile)
                              )
                            }
                            target="blank"
                            className="clickable"
                          >
                            <EyeIcon />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
      <footer className="modal__footer">
        {currentView === OUTWARDS_VIEWS.FORM ? (
          <React.Fragment>
            <Button
              category="black-ghost"
              onClick={() => props.switchViews(VIEWS.DETAILS)}
            >
              BACK
            </Button>
            <Button
              category={formValid ? "blue-bg" : "medium disabled"}
              disabled={!formValid}
              onClick={() => {
                if (props.formData) {
                  const { values } = props.formData;
                  const factoryId = find(factories, {
                    id: values["factoryId"]
                  });
                  const designId = find(designs, {
                    value: values["designId"]
                  });
                  const rawTrimSizes = {};
                  let totalTrimsQuantity = 0;
                  const selectedSizes = Object.entries(values)
                    .filter(([key, value]) => key.includes("quantity-"))
                    .map(([key, value]) => {
                      const id = Number(key.split("quantity-")[1]);
                      rawTrimSizes[id] = value;
                      totalTrimsQuantity += Number(value);
                      return {
                        size: find(props.material.trimsSize, { id })["size"],
                        quantity: value,
                        id
                      };
                    });

                  const computedRawValues = {
                    ...formValuesRaw,
                    ...props.formData.values
                  };
                  if (!isEmpty(rawTrimSizes)) {
                    computedRawValues.trimsSizes = JSON.stringify(rawTrimSizes);
                    computedRawValues.quantity = totalTrimsQuantity;
                  }
                  setFormValuesRaw(computedRawValues);
                  setFormValues({
                    ...values,
                    factoryId,
                    designId,
                    selectedSizes
                  });
                  setFactoryId(props.formData.values.factoryId);
                  setCurrentView(OUTWARDS_VIEWS.DETAILS);
                }
              }}
            >
              NEXT
            </Button>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Button
              category="black-ghost"
              onClick={() => setCurrentView(OUTWARDS_VIEWS.FORM)}
            >
              BACK
            </Button>
            <Button
              category={formValid ? "blue-bg" : "medium disabled"}
              onClick={() => {
                props.postMaterialOutwards(formValuesRaw, props.filterList);
              }}
            >
              NEXT
            </Button>
          </React.Fragment>
        )}
      </footer>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  formData: state.form[FORM_NAME]
});
const mapDispatchToProps = dispatch => ({
  submitForm: formName => dispatch(submit(formName)),
  postMaterialOutwards: (...details) => dispatch(postMaterialOutwards(...details))
});

export default connect(mapStateToProps, mapDispatchToProps)(AssignFactoryModal);
