export const toggleStatus = (notificationList = [], itemDetails ) => {
  const { type, design_id } = itemDetails;
  const { id } = itemDetails;
  let filteredNotifications = [];
  switch(type) {
    case "tna": {
      filteredNotifications =  notificationList.filter((notification)=>{
        return notification["design_id"] == design_id  &&  notification["notif_details"]["type"] === "tna" && notification["notif_details"]["tnaAttributeId"] === id;
      });
      break;
    }
    case "sample": {
      const { sampleType } = itemDetails; 
      filteredNotifications = notificationList.filter((notification)=>{
        return notification["design_id"] == design_id  &&  notification["notif_details"]["type"] === "sample" && notification["notif_details"]["name"] === sampleType;
      });
      break;
    }
    case "dispatch": {
      filteredNotifications = notificationList.filter((notification)=>{
        return notification["design_id"] == design_id  &&  notification["notif_details"]["type"] === "dispatch";
      });
      break;
    }
    case "order": {
      filteredNotifications = notificationList.filter((notification)=>{
        return notification["design_id"] == design_id  &&  notification["notif_details"]["type"] === "order";
      });
      break;
    }
    case "quote": {
      filteredNotifications = notificationList.filter((notification)=>{
        return notification["design_id"] == design_id  &&  notification["notif_details"]["type"] === "quote" && notification["notif_details"]["quoteId"] === id;
      });
      break;
    }
  }
  return filteredNotifications.length > 0 ? true : false
}