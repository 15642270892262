import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import qs from "query-string";
import _ from "lodash";

import actions from "../../store/actions";

import Order from "../../components/Orders";
import { FACTORY_ADMIN, FACTORY_MANAGER, LOCO_SUPER, PRODUCT_DEVELOPMENT, PRODUCTION_MERCHANT } from "../../constants/Auth";
import { Helmet } from "react-helmet";
import { PAGE_TITLES } from "../../constants/Titles";

const Orders = ({
  openModal,
  orders: { orders, paginate },
  getAllOrders,
  allBrands,
  allFactories,
  getAllBrands,
  getAllFactories,
  userDetails,
  prevSavedOrders,
  usersList,
  updatedOrder,
  pendingDispatchNotifications,
  createDispatchNotification,
  removeDispatchNotification,
  setCurrentBrand,
  location: {
    searchText
  }
}) => {

  const selectedTabFilter = window.appHistory.location.search
    && qs.parse(window.appHistory.location.search.slice(1))['tab']
    || "pending";

  const isFactory = (userDetails.role === LOCO_SUPER
    || userDetails.role === FACTORY_MANAGER
    || userDetails.role === FACTORY_ADMIN
    || userDetails.role === PRODUCT_DEVELOPMENT
    || userDetails.role === PRODUCTION_MERCHANT);

  const [brandId, setBrandId] = useState(userDetails.brandId);

  return (<>
    <Helmet
      title={PAGE_TITLES.orders()}
    />
    <Order
      openModal={openModal}
      orders={orders}
      pagination={paginate}
      getAllOrders={getAllOrders}
      getAllBrands={getAllBrands}
      getAllFactories={getAllFactories}
      allBrands={allBrands}
      allFactories={allFactories}
      isFactory={isFactory}
      prevSavedOrders={prevSavedOrders}
      updatedOrder={updatedOrder}
      createDispatchNotification={createDispatchNotification}
      removeDispatchNotification={removeDispatchNotification}
      pendingDispatchNotifications={pendingDispatchNotifications}
      setCurrentBrand={setCurrentBrand}
      brandId={brandId}
      selectedTabFilter={selectedTabFilter}
      searchText={searchText}
    />
  </>
  );
};

const mapStateToProps = state => ({
  updatedOrder: state.products.updatedOrder,
  allBrands: state.products.allBrands,
  allFactories: (state.factory.factories || []),
  prevSavedDesigns: state.products.prevSavedDesigns,
  userDetails: state.auth.userDetails,
  orders: state.orders.orders,
  prevSavedOrders: state.orders.prevSavedOrders,
  usersList: state.auth.list,
  pendingDispatchNotifications:
    Object.values(
      _.get(
        state.notificationView.pendingDispatchNotifications,
        `${state.notificationView.currentBrand.brandId}`,
        {}
      )
    )
});

const mapDispatchToProps = dispatch => ({
  getAllDesigns: params => dispatch(actions.getAllDesigns(params)),
  getAllBrands: () => dispatch(actions.getAllBrands()),
  getAllFactories: (details) => dispatch(actions.getAllFactoriesAccount({ ...details, withSuppliers: 1 })),
  openModal: (name, additionalProps) =>
    dispatch(actions.openModal(name, additionalProps)),
  saveSelecteDesigns: params => dispatch(actions.saveSelecteDesigns(params)),
  getAllOrders: params => dispatch(actions.orders.getAllOrders(params)),
  createDispatchNotification: v =>
    dispatch(actions.createDispatchNotification(v)),
  removeDispatchNotification: v =>
    dispatch(actions.removeDispatchNotification(v)),
  setCurrentBrand: v => dispatch(actions.setCurrentBrand(v))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Orders);
