import React from "react";
import moment from "moment";

const PoDetailsItem = ({
  po: {
    isOrderComplete,
    ewayBillFile,
    challanFile,
    invoiceFile,
    packingListFile,
    courierPartner,
    dispatchDate = new Date(),
    quantity,
    trackingCode,
    numberOfBoxes
  } = {}
}) => {
  return (
    <div
      style={{
        borderBottom: "1px solid var(--fill-grey-dark)"
      }}
    >
      <div
        style={{
          padding: "16px"
        }}
      >
        {/* put here date and is order complete */}
        <div style={{}}>
          <span style={{ fontSize: "12px" }}> Dispatch Date: &nbsp;</span>
          <span>{moment(dispatchDate).format("MMMM DD, YYYY")} &nbsp;</span>
          {isOrderComplete ? (
            <span style={{ color: "#02bd70" }}>This completes your order</span>
          ) : null}
        </div>
        {/* SINGLE ROW  */}

        <div>
          <div
            style={{
              padding: "16px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flex: 1,
              lineHeight: "24px",
              borderBottom: "1px solid #f5f5f5",
              cursor: "pointer"
            }}
          >
            <div style={{ fontSize: "12px", color: "var(--fill-grey-medium)" }}>
              Dispatch Quantity
            </div>
            <div
              style={{
                fontSize: "14px",
                fontWeight: "600",
                color: "var(--fill-locofast-black)",
                lineHeight: "normal"
              }}
            >
              {quantity}
            </div>
          </div>

          <div
            style={{
              padding: "16px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flex: 1,
              lineHeight: "24px",
              borderBottom: "1px solid #f5f5f5",
              cursor: "pointer"
            }}
          >
            <div style={{ fontSize: "12px", color: "var(--fill-grey-medium)" }}>
              No of Boxes
            </div>
            <div
              style={{
                fontSize: "14px",
                fontWeight: "600",
                color: "var(--fill-locofast-black)",
                lineHeight: "normal"
              }}
            >
              {numberOfBoxes}
            </div>
          </div>
        </div>
        {/* SINGLE ROW  */}

        {/* SINGLE ROW  */}
        <div>
          <div
            style={{
              padding: "16px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flex: 1,
              lineHeight: "24px",
              borderBottom: "1px solid #f5f5f5",
              cursor: "pointer"
            }}
          >
            <div style={{ fontSize: "12px", color: "var(--fill-grey-medium)" }}>
              Tracking ID
            </div>
            <div
              style={{
                fontSize: "14px",
                fontWeight: "600",
                color: "var(--fill-locofast-black)",
                lineHeight: "normal"
              }}
            >
              {trackingCode}
            </div>
          </div>

          <div
            style={{
              padding: "16px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flex: 1,
              lineHeight: "24px",
              borderBottom: "1px solid #f5f5f5",
              cursor: "pointer"
            }}
          >
            <div style={{ fontSize: "12px", color: "var(--fill-grey-medium)" }}>
              Courier Partner
            </div>
            <div
              style={{
                fontSize: "14px",
                fontWeight: "600",
                color: "var(--fill-locofast-black)",
                lineHeight: "normal"
              }}
            >
              {courierPartner}
            </div>
          </div>
        </div>
        {/* SINGLE ROW  */}

        <div
          style={{
            padding: "10px",
            fontSize: "12px",
            color: "var(--fill-grey-dark)",
            backgroundColor: "var(--fill-grey-light5)"
          }}
        >
          Important Documents
        </div>
        {/* SINGLE ROW  */}
        <div
          style={{
            padding: "16px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid #f5f5f5",
            cursor: "pointer",
            backgroundColor: "var(--fill-grey-light5)"
          }}
        >
          <div style={{ fontSize: "12px", color: "var(--fill-grey-medium)" }}>
            Eway Bill
          </div>
          <div
            style={{
              fontSize: "14px",
              fontWeight: "600",
              color: "var(--fill-primary-dark)",
              lineHeight: "normal",
              textDecoration: "none"
            }}
          >
            <a href={ewayBillFile}>Download</a>
          </div>
        </div>
        {/* SINGLE ROW  */}

        {/* SINGLE ROW  */}
        <div
          style={{
            padding: "16px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid #f5f5f5",
            cursor: "pointer",
            backgroundColor: "var(--fill-grey-light5)"
          }}
        >
          <div style={{ fontSize: "12px", color: "var(--fill-grey-medium)" }}>
            Challan
          </div>
          <div
            style={{
              fontSize: "14px",
              fontWeight: "600",
              color: "var(--fill-primary-dark)",
              lineHeight: "normal",
              textDecoration: "none"
            }}
          >
            <a href={challanFile}>Download</a>
          </div>
        </div>
        {/* SINGLE ROW  */}

        {/* SINGLE ROW  */}
        <div
          style={{
            padding: "16px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid #f5f5f5",
            cursor: "pointer",
            backgroundColor: "var(--fill-grey-light5)"
          }}
        >
          <div style={{ fontSize: "12px", color: "var(--fill-grey-medium)" }}>
            Invoice Copy
          </div>
          <div
            style={{
              fontSize: "14px",
              fontWeight: "600",
              color: "var(--fill-primary-dark)",
              lineHeight: "normal",
              textDecoration: "none"
            }}
          >
            <a href={invoiceFile}>Download</a>
          </div>
        </div>
        {/* SINGLE ROW  */}

        {/* SINGLE ROW  */}
        <div
          style={{
            padding: "16px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid #f5f5f5",
            cursor: "pointer",
            backgroundColor: "var(--fill-grey-light5)"
          }}
        >
          <div style={{ fontSize: "12px", color: "var(--fill-grey-medium)" }}>
            Packing List
          </div>
          <div
            style={{
              fontSize: "14px",
              fontWeight: "600",
              color: "var(--fill-primary-dark)",
              lineHeight: "normal",
              textDecoration: "none"
            }}
          >
            <a href={packingListFile}>Download</a>
          </div>
        </div>
        {/* SINGLE ROW  */}
      </div>
    </div>
  );
};

export default PoDetailsItem;
