
export const formatDecimals = (amount) => {
  return amount.toLocaleString('en-IN', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const formatNumber = (num) => {
  return num.toLocaleString('en-IN');
};
