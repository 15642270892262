import React, { useState, useRef } from "react";
import moment from "moment";
import { connect } from "react-redux";

import { DATESTAMP_FORMAT } from "constants/Date";
import Button from "components/UI/Button/Button";

import { postMaterialNote } from "store/actions/MaterialLibraryActions";

const Notes = ({ notesList, material, postMaterialNote }) => {
  const [isInputVisible, setIsInputVisible] = useState(false);

  const noteRef = useRef(null)
  return (
    <React.Fragment>
      <div className="material__description_heading with_tabs">
        Notes
        {!isInputVisible && (
          <Button category="plain-blue" onClick={() => setIsInputVisible(true)}>
            + Add note
          </Button>
        )}
      </div>
      {isInputVisible && (
        <div className="input">
          <textarea placeholder="Write your note" ref={noteRef} maxLength="255"/>
          <div className="grid-2 i-grid">
            <Button
              category="black-ghost"
              onClick={() => setIsInputVisible(false)}
            >
              Cancel
            </Button>
            <Button category="blue-bg" onClick={()=>{
              if(noteRef.current && noteRef.current.value){

                postMaterialNote({
                  note: noteRef.current.value,
                  materialId: material.id
                })
              }
            }}>Save</Button>
          </div>
        </div>
      )}
      <div>
        {notesList.map(({ note, createdOn, createdBy }) => (
          <div className="material__notes">
            <div className="metadata">
              {moment(createdOn).format(DATESTAMP_FORMAT)} by {createdBy.name}
            </div>
            <div className="text">{note}</div>
          </div>
        ))}
      </div>
    </React.Fragment>
  );
};

export default connect(null, {
  postMaterialNote
})(Notes);
