import { handleActions } from "redux-actions";

const INITIAL_STATE = { list: {}, selectedUserCollections: {} };

const CollectionReducer = handleActions(
  {
    GET_COLLECTIONS_SUCCESS: (state, action) => ({
      ...state,
      list: action.payload,
      selectedUserCollections: {}
    }),
    SELECTED_USER_COLLECTIONS: (state, action) => {
      return {
        ...state,
        selectedUserCollections: action.payload
      };
    },

    /**
     * @param { Object } action
     * @param { Object } action[collection_id]
     */

    SELECT_COLLECTION_BY_ID: (state, action) => {
      const { collectionId, collections } = action.payload;

      return {
        ...state,
        selectedUserCollections: collections[collectionId],
        list: collections
      };
    },
    UPDATE_FACTORY_NAME: (state, action) => {
      let collections = state.list;
      collections[action.payload.id].factoryName = action.payload.factoryName;

      return {
        ...state,
        list: collections
      };
    },
    UPDATE_COLLECTIONS_BY_ID: (state, action) => {
      let collections = { ...state.list };

      Object.keys(action.payload).forEach(
        collectionId =>
          (collections[collectionId] = action.payload[collectionId])
      );

      return {
        ...state,
        list: collections
      };
    },
    LOGOUT: (state, action) => {
      return {
        ...state,
        ...INITIAL_STATE
      };
    }
  },
  INITIAL_STATE
);

export default CollectionReducer;
