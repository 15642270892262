import React from "react";
import moment from "moment";

import { ProfileAvatar } from "components/UI/ProfileAvatar";
import { TextSpliced } from "components/UI/TextSpliced";
import DateToolTip from "components/UI/DateToolTip";

import { getImageUrl } from "helpers/DesignHelpers";
import { Link } from "react-router-dom";

export const DesignRow = ({
  data: {
    collectionId,
    designId,
    designName,
    stage,
    status,
    createdByFirstName = "",
    createdByLastName = "",
    userProfilePicUrl,
    category,
    fileloc,
    createdById,
    sampleDispatchDate,
    sampleDueDate,
    statusActualDate,
    statusPlanDate
  }
}) => {
  let name = `${createdByFirstName}`;
  if (createdByLastName) {
    name = `${name} ${createdByLastName}`;
  }

  const [actualDate, planDate] =
    stage === "Sampling"
      ? [sampleDispatchDate, sampleDueDate]
      : [statusActualDate, statusPlanDate];

  let indicator = actualDate
    ? moment(actualDate).isAfter(planDate)
      ? "red"
      : "green"
    : moment(planDate).isBefore(moment())
    ? "red"
    : "green";

  let dateTooltipVisible = true;
  if (stage === "Production" && actualDate === null) {
    indicator = "orange";
    dateTooltipVisible = false;
  }

  const delayed = indicator === "red";

  return (
    <div className="design-table-row">
      <div className="design-col">
        <div className="col-img">
          <img
            src={getImageUrl(fileloc, { category })}
            className="design-img"
            alt="user avatar"
          />
        </div>
        <Link to={`../collections/${collectionId}/designs/${designId}`}>
          <TextSpliced
            className="col-title blu-title"
            text={designName}
            maxWidth="200"
          />
        </Link>
      </div>
      <div className="col-title">{stage}</div>
      <div className={`col-title date-tooltip-parent ${indicator}`}>
        {status}
        {stage && dateTooltipVisible && (
          <DateToolTip delayed={delayed} date={planDate} />
        )}
      </div>
      <div className="design-col">
        <div className="col-img">
          <ProfileAvatar
            key={designId}
            id={createdById}
            name={name}
            url={userProfilePicUrl}
            initialLength={2}
            staticColor
          />
        </div>
        <div className="col-title">{name}</div>
      </div>
      {/* <div className="request-col d-none">Request update</div> */}
    </div>
  );
};
