import React from 'react';
import { Field } from 'formik';
import * as Yup from "yup";
import ModalFooter from '../../../UI/Modal/ModalFooter';
import DesignCard from '../../../UI/Card/DesignCard';
import CollectionCard from '../../../UI/Card/CollectionCard';
import Input from 'components/CustomBrand/UI/Formik/Input';
import Form from 'components/CustomBrand/UI/Formik/Form';
import { ReactComponent as MoveRightBlue } from 'assets/images/navigation/dir-right-blue.svg';
import './style.scss';

const YUP_DUPLICATE_DESIGN_REQUEST = Yup.object().shape({
  sourceDesignId: Yup.string().max(25).required("Required").label('Design ID'),
});

const DuplicateDesignConfirmModal = ({
  design,
  collection,
  closeModal,
  onConfirm,
}) => {
  return <div className="duplicate-design-cfm-modal">
    <Form
      validateOnMount={true}
      validationSchema={YUP_DUPLICATE_DESIGN_REQUEST}
    >
      {
        ({ values, isValid }) => <>
          <div class="duplicate-design-cfm-modal__content">
            <div>
              <DesignCard design={design} />
              <Field
                className="field-input"
                name="sourceDesignId"
                component={Input}
                type="text"
                label={values.sourceDesignId ? 'Design ID' : null}
                placeholder="Enter design ID"
                autofocus
              />
            </div>
            <div className="duplicate-design-cfm-modal__img">
              {
                collection.isCurrent === 1
                  ? <span className="blue-dot" />
                  : <MoveRightBlue />
              }
            </div>
            <CollectionCard
              collection={collection}
              designs={collection.designs}
              designCount={3}
              tag={collection.isCurrent ? 'Current collection' : null}
            />
          </div>

          <ModalFooter
            cancel="Dismiss"
            submit="Confirm"
            canSubmit={isValid}
            canCancel={true}
            onCancel={closeModal}
            onSubmit={() => onConfirm(values)}
          />
        </>
      }
    </Form>
  </div>;
};

export default DuplicateDesignConfirmModal;
