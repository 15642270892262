import { handleActions } from "redux-actions";
import { STATUS_ORDER } from "../../constants/Design";
import _ from 'lodash';

const TabNumToString = {
  1: "sampling",
  2: "quote",
  3: "order",
  4: "dispatch",
  5: "tna"
};

const processDesigns = designs => {
  Object.keys(designs).forEach(dId => {
    designs[dId] = {
      ...designs[dId],
      statusString: STATUS_ORDER[designs[dId].status]
    };
  });
  return designs;
};

const INITIAL_STATE = {
  selectedDesigns: {},
  list: [],
  notificationDesigns: [],
  tabToDisplay: "",
  notificationPoOrders: [],
  pendingDispatchNotifications: {},
  currentBrand: {
    brandId: null
  },
  dispatchNotifications: [],
  pendingNotifications: [],
  notificationMode: false,
  notificationsList: []
};

const NotificationViewReducer = handleActions(
  {
    UPDATE_NOTIFICATION_VIEW_DESIGNS: (state, action) => {
      const newDesigns = processDesigns(action.payload.designs);
      return {
        ...state,
        // Update overall List
        list: {
          ...state.list,
          ...newDesigns
        },
        // Update selected designs lists
        selectedDesigns: newDesigns
      };
    },
    GET_NOTIFICATION_DETAILS: (state, action) => {
      // console.log(action.payload);
      return {
        ...state,
        notificationDesigns: action.payload.designs,
        notificationPoOrders: action.payload.orders,
        tabToDisplay: TabNumToString[action.payload.category]
      };
    },
    GET_NOTIFICATION_LIST: (state, action) => {
      return {
        ...state,
        list: action.payload
      };
    },
    CREATE_DISPATCH_NOTIFICATION: (state, action) => {
      return {
        ...state,
        pendingDispatchNotifications: {
          ...state.pendingDispatchNotifications,
          [action.payload.brandId]: {
            ...state.pendingDispatchNotifications[action.payload.brandId],
            [action.payload.dispatchId]: action.payload
          }
        }
      };
    },
    REMOVE_DISPATCH_NOTIFICATION: (state, action) => {
      return {
        ...state,
        pendingDispatchNotifications: {
          ...state.pendingDispatchNotifications,
          [action.payload.brandId]: Object.entries(
            state.pendingDispatchNotifications[action.payload.brandId]
          )
            .filter(([dispatchId, val]) => dispatchId !== action.payload.id)
            .reduce((acc, c) => ({ ...acc, [c[0]]: c[1] }), {})
        }
      };
    },
    SET_CURRENT_BRAND: (state, action) => {
      return {
        ...state,
        currentBrand: { brandId: action.payload.brandId }
      };
    },
    GET_DISPATCH_NOTIFICATION_LIST: (state,action) => {
      return {
        ...state,
        dispatchNotifications: action.payload
      }
    },
    ADD_PENDING_NOTIFICATION: (state, action) => {
      return {
        ...state, 
        pendingNotifications: [...state.pendingNotifications, action.payload]
      }
    },
    REMOVE_PENDING_NOTIFICATION: (state, action) => {
      const { type, design_id, name } = action.payload;
      const filteredNotifications = state.pendingNotifications.filter((notification)=>{
        return notification["design_id"]!=design_id  || notification["notif_details"]["type"] !== type || notification["notif_details"]["name"] !== name
      });
      return {
        ...state,
        pendingNotifications: [...filteredNotifications]
      }
    },
    SET_NOTIFICATION_MODE: (state, action) => {
      return {
        ...state,
        notificationMode: action.payload
      }
    },
    GET_ALL_NOTIFICATIONS: (state, action) => {
      return {
        ...state,
        notificationsList: action.payload
      }
    },
    LOGOUT: (state, action) => {
      return {
        ...state,
        ...INITIAL_STATE
      };
    }
  },
  INITIAL_STATE
);

export default NotificationViewReducer;
