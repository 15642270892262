import React, { useState, useEffect } from "react";
import Downshift from "downshift";
import PerfectScrollbar from "react-perfect-scrollbar";

import "./styles.scss";
import icDropdown from "assets/images/navigation/ic-arrow-drop-down-grey.svg";
import { ReactComponent as CloseImg } from "assets/images/action/close.svg";

const SingleSelect = ({
  label,
  filterOptions = [],
  onChange = () => {},
  selectedFilters = null
}) => {
  const [selection, setSelection] = useState(null);

  useEffect(() => {
    if (!selection && selectedFilters) {
      setSelection(selectedFilters);
    }
  }, [selectedFilters]);

  return (
    <div className="reports-dropdown single">
      <div className="reports-dropdown__label">{label}</div>
      <Downshift
        itemToString={item => item && item.label}
        onChange={selection => {
          setSelection(selection);
          onChange(selection);
        }}
        selectedItem={selectedFilters ? selectedFilters : null}
      >
        {({
          getInputProps,
          getToggleButtonProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          isOpen,
          inputValue,
          highlightedIndex,
          selectedItem,
          getRootProps,
          reset,
          clearSelection
        }) => {
          return (
            <div
              {...getToggleButtonProps()}
              className="downshift dropdown-filter"
              type="none"
            >
              <span
                className="flex input-box"
                {...getRootProps({}, { suppressRefError: true })}
              >
                {isOpen || !selection ? (
                  <input
                    className="select-input-transparent"
                    placeholder="All"
                    {...getInputProps()}
                  />
                ) : (
                  <div className="overflow-ellipsis">{selection.label}</div>
                )}

                <img src={icDropdown} alt="dropdown" />
              </span>
              {isOpen ? (
                <>
                  <div className="select-pane">
                    <div {...getMenuProps()}>
                      <PerfectScrollbar
                        option={{
                          suppressScrollX: true
                        }}
                        className={`filter-scroll ${!selection &&
                          "full-height"}`}
                      >
                        {filterOptions.map((item, index) => {
                          return (
                            <div
                              {...getItemProps({
                                className:
                                  "in-selection collection-collab__label_with_checkBox flex",
                                index,
                                item,
                                key: item.label
                              })}
                              style={{
                                fontWeight: item.isSelected ? "600" : "300",
                                paddingRight: "10px"
                              }}
                            >
                              <div
                                className="loco__radio loco__radio--left-align"
                                style={{ border: "none" }}
                                key={index}
                              >
                                <div
                                  {...getItemProps({
                                    key: item.value,
                                    index,
                                    item
                                  })}
                                  key={index}
                                >
                                  <input
                                    name="design_category_selectors"
                                    id={item.label}
                                    type="radio"
                                    value={item.value}
                                    checked={
                                      selectedItem &&
                                      selectedItem.value === item.value
                                    }
                                  />
                                  <label
                                    htmlFor={item.label}
                                    className="overflow-ellipsis"
                                  >
                                    {item.label}
                                  </label>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </PerfectScrollbar>
                      {selection && selection.value ? (
                        <div
                          className="reports-dropdown__footer"
                          onClick={() => {
                            setSelection(null);
                            onChange(null);
                            clearSelection();
                          }}
                        >
                          <CloseImg /> Clear selection
                        </div>
                      ) : null}
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          );
        }}
      </Downshift>
    </div>
  );
};

export default SingleSelect;
