import React from "react";
import {
  getSmallImageUrl,
  getCoverImagePlaceholder
} from "../../../helpers/DesignHelpers";

const DispatchQuantityItem = ({ data }) => {
  const {
    quantity,
    dispatchQuantity,
    design: { name, sourceDesignId, coverImageUrl },
    design
  } = data;

  return (
    <div
      style={{
        padding: "16px",

        height: "80px",
        backgroundColor: "var(--fill-white)",

        display: "flex",
        alignItems: "center",
        borderBottom: "1px solid #f5f5f5",
        cursor: "pointer"
      }}
    >
      <div
        style={{
          flex: 2,

          width: " 48px",
          height: " 48px",
          borderRadius: "100px",
          overflow: "hidden"
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              width: "48px",
              height: "48px",
              borderRadius: "100px",
              overflow: "hidden"
            }}
          >
            <img
              src={
                coverImageUrl
                  ? getSmallImageUrl(coverImageUrl)
                  : getCoverImagePlaceholder(design).src
              }
              alt="Design"
              style={{ height: "100%" }}
            />
          </div>
          <div style={{ paddingLeft: "8px", lineHeight: "22px" }}>
            <div className="value__class">{name}</div>
            <div className="label__class">{sourceDesignId}</div>
          </div>
        </div>
      </div>
      <div style={{ flex: 1, lineHeight: "24px" }}>
        {/* <div style={{ fontSize: "12px", color: "var(--fill-grey-medium)" }}>
          Design Qty
        </div> */}
        <div
          style={{
            fontSize: "14px",
            fontWeight: "600",
            color: "var(--fill-locofast-black)",
            lineHeight: "normal"
          }}
        >
          {quantity}
        </div>
      </div>
      <div style={{ flex: 1, lineHeight: "24px" }}>
        {/* <div style={{ fontSize: "12px", color: "var(--fill-grey-medium)" }}>
          Dispatch Qty
        </div> */}
        <div
          style={{
            fontSize: "14px",
            fontWeight: "600",
            color: "var(--fill-locofast-black)",
            lineHeight: "normal"
          }}
        >
          {dispatchQuantity}
        </div>
      </div>
    </div>
  );
};

export default DispatchQuantityItem;
