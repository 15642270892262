import React, { Component } from "react";
import Select from "../../UI/Form/Select/Select";
import { Field, Form, reduxForm, formValueSelector } from "redux-form";
import { required } from "../../../helpers/form-validations";
import Input from "../../UI/Form/Input/Input";
import Textarea from "../../UI/Form/Textarea/Textarea";

import "./NewDesign.scss";
import PerfectScrollbar from "react-perfect-scrollbar";

import {
  DESIGN_CATEGORIES,
  DESIGN_SUB_CATEGORIES
} from "./../../../constants/Design";
import Downshift from "downshift";

const formName = "NewDesign";
const designNameValidation = value =>
  value && !/^[a-zA-Z0-9 !"#$%&'()*+,\-.\/:;<=>?@[\]^_`{|}~\\]+$/.test(value)
    ? "No special characters allowed"
    : undefined;

class NewDesign extends Component {
  state = {
    category: "Women",
    sub_category: null,
    categoryOpen: true
  };
  render() {
    const {
      handleSubmit,
      //   pristine,
      //   reset,
      //   submitting,
      change,
      hiddenFields
    } = this.props;
    // Add hidden fields
    if (hiddenFields) hiddenFields.forEach(f => change(f.name, f.value));

    return (
      <PerfectScrollbar
        option={{
          suppressScrollX: true
        }}
        className="new-design flex-col"
        containerRef={ref => (this._scrollRef = ref)}
      >
        <Form
          name={formName}
          id={formName}
          onSubmit={handleSubmit(values => {
            this.props.onSubmit({
              ...values,
              category: this.state.category,
              sub_category: this.state.sub_category
            });
          })}
        >
          <Field
            normalize={value =>
              value &&
              value
                .split(" ")
                .map(w => w.charAt(0).toUpperCase() + w.slice(1))
                .join(" ")
            }
            component={Input}
            validate={[required, designNameValidation]}
            maxLength={50}
            type="text"
            name="name"
            label="Design Name"
            autoFocus={true}
            required
          />
          <Field
            component={Input}
            maxLength={25}
            type="text"
            name="design_id"
            label="Design ID"
            required
          />
          <Field
            component={Textarea}
            maxLength={150}
            type="text"
            name="description"
            label="Design description"
          />
          <span className="f-sz-sm input_label">
            Select category<sup>*</sup>
          </span>
          <div className="category_select" >
            {DESIGN_CATEGORIES.map(({ label, value }) => (
              <div className="loco__radio_tabs">
                <input
                  name="design_category_selectors"
                  id={label}
                  type="radio"
                  onChange={() =>
                    this.setState({
                      category: value,
                      categoryOpen: true
                    })
                  }
                  className={`${value == this.state.category ? "checked" : ""}`}
                  checked={value == this.state.category ? true : false}
                />
                <label for={label}>{label}</label>
              </div>
            ))}
          </div>
          <Downshift initialIsOpen={this.state.categoryOpen}
              itemToString={item => (item ? item.value : '')}
              onChange={(item) =>
                this.setState({
                  sub_category: item.value,
                  categoryOpen: true
                })
              }
          >
            {({
              getInputProps,
              getItemProps,
              getLabelProps,
              getMenuProps,
              isOpen,
              inputValue,
              highlightedIndex,
              selectedItem,
              getRootProps
            }) => (
              <React.Fragment>
                <div className={`flex-col`}>
                <div
                  style={{display: 'inline-block'}}
                  {...getRootProps({}, {suppressRefError: true})}
                >
                  {/* <input {...getInputProps()} /> */}
                </div>
                  <div
                    style={{ padding: "0" }}
                    className={`custom-category__select flex-col`}
                    {...getMenuProps()}
                  >
                    {this.state.category &&
                      DESIGN_SUB_CATEGORIES[this.state.category].map(
                        (item, index) => (
                          <div
                            className="loco__radio loco__radio--left-align"
                            style={{ border: "none" }}
                            key={index}
                          >
                            <div
                              {...getItemProps({
                                key: item.value,
                                index,
                                item
                              })}
                              key={index}
                            >
                              <input
                                name="design_category_selectors"
                                id={item.label}
                                type="radio"
                                value={item.value}
                                checked={
                                  selectedItem && selectedItem.value === item.value
                                }

                              />
                              <label htmlFor={item.label}>{item.label}</label>
                            </div>
                          </div>
                        )
                      )}
                  </div>
                </div>
              </React.Fragment>
            )}
          </Downshift>
        </Form>
      </PerfectScrollbar>
    );
  }
}

const mapStateToProps = (state, ownProps) =>
  ownProps.initialValues
    ? {
        initialValues: { ...ownProps.initialValues }
      }
    : {};

export default reduxForm({ form: formName }, mapStateToProps)(NewDesign);
