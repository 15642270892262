import React from 'react';
import PropTypes from 'prop-types';
import sum from 'lodash/sum';
import Button from '../../Button/Button';
import FilterDrawer from '../FilterDrawer/index';
import './index.scss';
import { IconFilter } from '../../Icons';

export default class FilterDrawerButton extends React.Component {
  static propTypes = {
    filters: PropTypes.array.isRequired,
    userRole: PropTypes.string.isRequired,
    showSingleFilterValues: PropTypes.bool,
  };

  static defaultProps = {
    showSingleFilterValues: true,
  };

  state = {
    showFiltersDrawer: false,
    filtersCount: 0,
    filters: [],
    propFilters: {},
    propShowSingleFilterValues: null,
  }

  static getDerivedStateFromProps(nextProps, oldState) {
    const nextState = {};

    if (nextProps.filters !== oldState.filters) {
      nextState.propFilters = nextProps.filters;
    }

    if (oldState.propShowSingleFilterValues !== nextProps.showSingleFilterValues) {
      nextState.propShowSingleFilterValues = nextProps.showSingleFilterValues;
    }

    if (nextState.propFilters || Object.prototype.hasOwnProperty.call(nextState, 'propShowSingleFilterValues')) {
      const showSingleFilterValues = nextState.propShowSingleFilterValues || oldState.propShowSingleFilterValues;

      nextState.filters = (nextState.propFilters || oldState.propFilters)
        .filter(filter => (!showSingleFilterValues || filter.items.length > 1));

      const filtersCount = sum(Object.values(nextState.filters)
        .map(filter => filter.items.filter(item => item.isSelected).length));

      nextState.filtersCount = filtersCount;
    }

    return Object.keys(nextState).length > 0 ? nextState : null;
  }

  setShowFiltersDrawer = (showFiltersDrawer) => {
    this.setState({ showFiltersDrawer });
  }

  handleApplyFilters = (appliedFilters) => {
    this.setState({ filtersCount: appliedFilters.filtersCount });

    if (typeof (this.props.onApplyFilters) === 'function') {
      this.props.onApplyFilters(appliedFilters);
    }
  };

  render() {
    const { userRole, disableConditions } = this.props;
    const { filters, showFiltersDrawer, filtersCount } = this.state;

    if (filters.length === 0) {
      return null;
    }

    return <div className="filter-drawer-button">
      <Button category="filter grid-2"
        onClick={() => {
          this.setShowFiltersDrawer(true);
        }}
      >
        {
          filtersCount > 0
            ? <span className="counter">{filtersCount}</span>
            : <IconFilter />
        }
        <span>Filters</span>
      </Button>
      <FilterDrawer
        isVisible={showFiltersDrawer}
        filters={filters}
        userRole={userRole}
        onClose={() => this.setShowFiltersDrawer(false)}
        onApplyFilters={(appliedFilters) => this.handleApplyFilters(appliedFilters)}
        disableConditions={disableConditions}
      />
    </div>
  }

}
