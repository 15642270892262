import React, { useState, useEffect } from "react";
import Dropdown from "react-dropdown";
import axios from "axios";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import actions from "../../store/actions";
import Button from "../UI/Button/Button";
import DesignFilter from "../Orders/DesignFilter";
import BootstrapError from "../UI/BootstrapError";

import styles from "./DetailsModel.module.scss";
import "react-dropdown/style.css";

import { get_service_endpoint } from "../../ServiceEndpoints";
const eq = get_service_endpoint("notificationView");
const ep = get_service_endpoint("collection");

const SwatchAddToDesignModel = ({
  isFactory,
  dispatch,
  swatches,
  clearState,
  brandid,
  onlyAddToDesign,
  details,
  history,
  collectionsProp
}) => {
  const [allBrands, setallBrands] = useState([]);
  const [selectedBrand, setselectedBrand] = useState(
    onlyAddToDesign ? { brandid: details.brandId } : null
  );
  const [collections, setcollections] = useState([]);
  const [selectedCollection, setselectedCollection] = useState(
    onlyAddToDesign ? { id: details.collectionId } : null
  );
  const [designs, setDesigns] = useState([]);
  const [selectedDesign, setselectedDesign] = useState(
    onlyAddToDesign ? { id: details.designId } : null
  );
  const [isError, setIsError] = useState({ message: "null", value: false });

  // ==== for files walaa flow ====
  const [initialBrand, setInitailBrand] = useState(null);
  const [initialCollection, setInitialCollection] = useState(null);
  const [isAdded, setIsAdded] = useState(false);



  // === This effect gets the all brands on initial load ===
  useEffect(() => {
    if (isFactory) {
      axios
        .get(`${eq}/users/getallbrands`)
        .then(res => {
          setallBrands(res.data);

          if (onlyAddToDesign) {
            const { brandId } = details;

            let brandName;
            res.data.forEach(brand => {
              if (brand.id === brandId) {
                brandName = brand.name;
              }
            });

            setInitailBrand(brandName);
          }
        })
        .catch();
    }
  }, []);

  // === This effect gets the collections on every brand selection from factory side  ===
  useEffect(() => {
    if (selectedBrand && isFactory && !onlyAddToDesign) {
      axios
        .get(`${ep}/getAllCollection`, {
          params: { brandName: selectedBrand.brandid }
        })
        .then(res => {
          const { data = [] } = res;
          if (data.resp && data.resp.code === 404) {
            if (collections.length) setcollections([]);
            return;
          } // resp comes when there is no collection for selected brand.

          const convertedCollections = Object.keys(data).map(key => data[key]);
          setcollections(convertedCollections);
          setselectedCollection(null);
        });
    }
  }, [selectedBrand]);

  //  +++++++++++++++ WHEN BRAND IS LOGGEN IN =============
  useEffect(() => {
    if (!isFactory && !onlyAddToDesign) {
      axios
        .get(`${eq}/orders/collections`, {
          params: { brandid }
        })
        .then(res => {
          const { data = [] } = res;
          // if (data.resp && data.resp.code === 404) {
          //   if (collections.length) setcollections([]);
          //   return;
          // } // resp comes when there is no collection for selected brand.

          const convertedCollections = Object.keys(data).map(key => data[key]);
          setcollections(convertedCollections);
          setselectedCollection(null);
        });
    }
  }, []);

  // === This effect pulls the designs for every collection change ===
  useEffect(() => {
    if (selectedCollection && !onlyAddToDesign) {
      axios
        .post(`${eq}/designsdetailsmini`, {
          collectionIds: [`${selectedCollection.id}`]
        })
        .then(res => {
          if (!Object.keys(res.data).length) {
            if (designs.length) setDesigns([]);
            return;
          }
          setDesigns(res.data);
        });
    }
  }, [selectedCollection]);

  // ==== this one for design flow ====
  useEffect(() => {
    if (onlyAddToDesign) {
      axios
        .get(`${eq}/orders/collections`, {
          params: { brandid: details.brandId }
        })
        .then(res => {
          const { data = [] } = res;

          const convertedCollections = Object.keys(data).map(key => data[key]);
          console.log("******************", convertedCollections);
          let collectionName;
          convertedCollections.forEach(col => {
            if (col.id === details.collectionId) {
              collectionName = col.name;
              console.log("COL>M+NAME", col.name);
            }
          });
          setInitialCollection(collectionName);
        });
    }
  }, []);

  // === This function shows the form ====
  const addDesignHandler = () => {
    if (isAdded && !onlyAddToDesign) {
      if (isFactory) {
        dispatch(actions.closeModal());
        history.push(
          `/${"factory"}/brands/${selectedBrand.brandid}/collections/${
            selectedCollection.id
          }/designs/${selectedDesign.id}/?tab=files`
        );
      } else {
        dispatch(actions.closeModal());
        history.push(
          `/${"brand"}/collections/${selectedCollection.id}/designs/${
            selectedDesign.id
          }/?tab=files`
        );
      }
      return;
    }

    if (isAdded && onlyAddToDesign) {
      dispatch(actions.closeModal());

      if (isFactory) {
        history.push(
          `/${"factory"}/brands/${details.brandId}/collections/${
            details.collectionId
          }/designs/${details.designId}/?tab=files`
        );
      } else {
        history.push(
          `/${"brand"}/collections/${details.collectionId}/designs/${
            details.designId
          }/?tab=files`
        );
      }
      return;
    }

    if (
      (isFactory && !selectedBrand) ||
      !selectedCollection ||
      !selectedDesign
    ) {
      setIsError({ message: "All fields are mandatory!", value: true });

      setTimeout(() => {
        setIsError({ message: "All fields are mandatory!", value: false });
      }, 800);
      return;
    }

    const swatchIds = swatches.map(swatch=>swatch.id)
    const request = {
      swatches: swatchIds,
      brandId: isFactory ? selectedBrand.brandid : brandid,
      collectionId: selectedCollection.id,
      designId: selectedDesign.id
    };
    console.log("REQUEST REQUEST::", request);
    axios
      .post(`${eq}/swatch/designs`, request)
      .then(res => {
        // dispatch(actions.flash({ message: "Added successfully." }));
        clearState();
        setIsAdded(true);
        // dispatch(actions.closeModal());

        // if (onlyAddToDesign) {
        //   if (isFactory) {
        //     history.push(
        //       `/${"factory"}/brands/${details.brandId}/collections/${
        //         details.collectionId
        //       }/designs/${details.designId}/?tab=files`
        //     );
        //   } else {
        //     history.push(
        //       `/${"brand"}/collections/${details.collectionId}/designs/${
        //         details.designId
        //       }/?tab=files`
        //     );
        //   }
        // }
      })
      .catch();
  };

  return (
    <div className={styles.swatch__details_add_to_design}>
      <div
        className={styles.swatch__details_form_}
        style={{ width: "400px", padding: "25px" }}
      >
        <div className={styles.swatch__details_form_upper_}>
          <div className={styles.swatch__name}>
            {/* 100% Cotton Red/White Colour Gingham Checks */}
          </div>

          {!isAdded ? (
            <>
              {/* ==== DROP DOWNS FROM HERE BRAND ==== */}
              {isFactory ? (
                <>
                  <div
                    style={{
                      fontSize: "12px",
                      color: "var(--fill-grey-medium)",
                      padding: "0 0 3px 10px"
                    }}
                  >
                    Brand
                  </div>
                  <div className={styles.dropdown__wrapper_custom}>
                    <Dropdown
                      options={allBrands.map(brand => ({
                        label: brand.name,
                        value: brand.id
                      }))}
                      onChange={({ value, label }) =>
                        setselectedBrand({ brandid: value, brandName: label })
                      }
                      value={
                        onlyAddToDesign
                          ? initialBrand
                          : selectedBrand
                          ? selectedBrand.brandName
                          : "Select one"
                      }
                      placeholder="Select one"
                      // className={styles.dropdown__styles}
                      // menuClassName={styles.dropdown__styles_menu}
                      style={{ cursor: "pointer" }}
                      disabled={onlyAddToDesign}
                    />
                  </div>
                </>
              ) : null}

              {/* ==== DROP DOWNS FROM HERE Collection ==== */}
              <div
                style={{
                  fontSize: "12px",
                  color: "var(--fill-grey-medium)",
                  padding: "0 0 3px 10px"
                }}
              >
                Collection
              </div>
              <div className={styles.dropdown__wrapper_custom}>
                <Dropdown
                  options={collections.map(({ name, id }) => ({
                    label: name,
                    value: id
                  }))}
                  onChange={({ label, value }) =>
                    setselectedCollection({ name: label, id: value })
                  }
                  value={
                    onlyAddToDesign
                      ? initialCollection
                      : selectedCollection
                      ? selectedCollection.name
                      : "Select one"
                  }
                  placeholder="Select one"
                  // className={styles.dropdown__styles}
                  // menuClassName={styles.dropdown__styles_menu}
                  style={{ cursor: "pointer" }}
                  disabled={
                    (isFactory ? !selectedBrand : false) || onlyAddToDesign
                  }
                />
              </div>

              <div
                style={{
                  fontSize: "12px",
                  color: "var(--fill-grey-medium)",
                  padding: "0 0 3px 10px"
                }}
              >
                Design
              </div>
              <div className={styles.dropdown__wrapper_custom_design}>
                <DesignFilter
                  title={"title"}
                  initialSelectedItem={{
                    label: onlyAddToDesign
                      ? details.designName
                      : "Select design",
                    value: null
                  }}
                  onChange={({ value }) => {
                    setselectedDesign(value);
                  }}
                  filterOptions={designs.map(design => ({
                    label: design.name,
                    value: design
                  }))}
                  disable={!selectedCollection || onlyAddToDesign}
                />
              </div>
            </>
          ) : (
            <div
              style={{
                fontSize: "14px",
                fontWeight: "bold",
                textAlign: "center",
                color: "var(--fill-green-dark)",
                padding: "12px",
                backgroundColor: "var(--fill-grey-light5)",
                marginTop: "30px"
              }}
            >
              SWATCH ADDED TO DESIGN
            </div>
          )}
        </div>
        <div style={{ textAlign: "center", marginTop: "30px" }}>
          <BootstrapError message={isError.message} show={isError.value} />
          <Button
            category="blue-bg"
            style={{ height: "48px", width: "100%" }}
            onClick={addDesignHandler}
          >
            <span style={{ fontWeight: "600" }}>
              {isAdded ? "VIEW DESIGN" : "ADD TO DESIGN"}
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  collectionsProp: state.collections
});

export default withRouter(connect(mapStateToProps, null)(SwatchAddToDesignModel));
