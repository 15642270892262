import React, { useState } from "react";
import Button from "../../../UI/Button/Button";
import collapseMoreImg from "../../../../assets/images/navigation/ic-chevron-down-blue.svg";
import "./QuoteAccordion.scss";

const QuoteAccordion = ({ sizesQuantity }) => {
  const [isExpand, setIsExpand] = useState(false);

  if (!sizesQuantity || sizesQuantity.length <= 0) {
    return null;
  }

  return (
    <div className={`sizes-quantity-accordion ${isExpand ? 'sizes-quantity-accordion--expanded' : ''}`}>
      <div className="sizes-quantity__header">
        <Button
          className="btn-expand"
          category="link grid-2 shrink"
          onClick={() => setIsExpand(!isExpand)}
        >
          <span>{sizesQuantity.length} size{sizesQuantity.length > 1 ? 's' : ''} requested</span>
          <img
            src={collapseMoreImg}
            className="arrow"
            alt="ic-collapse-more"
          />
        </Button>
      </div>
      {isExpand && (
        <div className="sizes-quantity__container">
          {sizesQuantity.map(item => {
            return (
              <div className="sizes-quantity__item" key={item.size}>
                <div className="size">{item.size}</div>
                <div className="quantity">{`${item.quantity} pcs`}</div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default QuoteAccordion;
