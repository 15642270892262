import PropTypes from "prop-types";
import React, { Component } from "react";
import { Form, reduxForm } from "redux-form";
import Select from "react-select";
import TestImg from "../../../assets/img/model.jpg";
import CloseImg from "../../../assets/images/navigation/ic-close-small.faded.svg";
import icDropdown from "../../../assets/images/navigation/ic-arrow-drop-down.svg";
import icPerson from "../../../assets/images/social/ic-person.faded.svg";
import icPersonAdd from "../../../assets/images/social/ic-person-add.faded.svg";

import { connect } from "react-redux";
import actions from "../../../store/actions";

import "./CollectionCollaboration.scss";
import { COLLABORATION_ROLES } from "./../../../constants/Collaboration";
import Downshift from "downshift";
const formName = "CollectionCollab";

class CollectionCollaboration_ViewOnly extends Component {
  static propTypes = {
    prop: PropTypes
  };
  state = {
    selectedUsers: [],
    isChanged: false
  };

  computeStateFromProps(collectionId, collectionDetails, listOfUsers) {
    let usersList = { ...listOfUsers };

    delete usersList[collectionDetails.userid];
    // Object.keys(usersList)
    //   .map(uKey =>
    //     usersList[uKey].name === "" || usersList[uKey].name === null
    //       ? (usersList[uKey]["name"] = usersList[uKey]["email"]) &&
    //         (usersList[uKey]["email"] = "")
    //       : uKey
    //   )
    //   .forEach(uKey => delete usersList[uKey]);

    if (collectionDetails.collebration) {
      const collaborators = Object.keys(collectionDetails.collebration).map(k => ({
        ...collectionDetails.collebration[k],
        email: usersList[k]["email"]
      }));

      const nonCollabUsers = Object.keys(usersList)
        .map(k => usersList[k])
        .filter(({ id }) => !collaborators.map(c => c.userid).includes(id));

      return {
        collaborators: collaborators,
        nonCollabUsers: nonCollabUsers,
        options: nonCollabUsers.map(user => ({
          label: user.email,
          value: user
        }))
      };
    } else {
      let nonCollabUsers = Object.keys(usersList).map(k => usersList[k]);

      return {
        collaborators: [],
        nonCollabUsers: nonCollabUsers,
        options: nonCollabUsers.map(user => ({
          label: user.email,
          value: user
        }))
      };
    }
  }

  componentDidMount() {
    const nextProps = this.props;

    this.setState(
      this.computeStateFromProps(
        nextProps.collectionDetails.id,
        nextProps.collectionDetails,
        nextProps.usersList
      )
    );
  }
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.collectionDetails !== this.props.collectionDetails ||
      nextProps.usersList !== this.props.usersList
    )
      this.setState(
        this.computeStateFromProps(
          nextProps.collectionDetails.id,
          nextProps.collectionDetails,
          nextProps.usersList
        )
      );
  }

  onChange = option => {
    const newState = {
      collaborators: [
        ...this.state.collaborators,
        ...this.state.nonCollabUsers
          .filter(u => u.email === option.label)
          .map(u => ({
            userid: u.id,
            role: COLLABORATION_ROLES["COLLAB_MANAGER"],
            name: u.name,
            email: u.email
          }))
      ],
      nonCollabUsers: this.state.nonCollabUsers.filter(u => u.email !== option.label),
      options: this.state.nonCollabUsers
        .filter(u => u.email !== option.label)
        .map(user => ({
          label: user.email,
          value: user
        })),
      isChanged: true
    };
    // console.log(newState);
    this.setState(newState);
  };

  deleteCollaboration = collab => {
    const newCollaborators = this.state.collaborators.filter(c => collab.userid !== c.userid);
    const newNonCollabUsers = Object.keys(this.props.usersList)
      .filter(k => k !== String(this.props.collectionDetails.userid))
      .map(k => this.props.usersList[k])
      .filter(({ id }) => !newCollaborators.map(c => c.userid).includes(id));

    const newState = {
      collaborators: newCollaborators,
      nonCollabUsers: newNonCollabUsers,
      options: newNonCollabUsers.map(user => ({
        label: user.email,
        value: user
      })),
      isChanged: true
    };

    this.setState(newState);
  };

  handleUserAuthorityChange = (collab, role) => {
    // console.log("Handle user auth change called");
    const newCollaborators = this.state.collaborators.map(c =>
      c.userid === collab.userid ? { ...c, role: role.value } : c
    );

    // console.log(newCollaborators);
    this.setState({ collaborators: newCollaborators, isChanged: true });
  };

  componentDidUpdate() {
    this.props.change("collaborations", this.state.collaborators);
  }

  render() {
    const {
      handleSubmit,
      // pristine,
      // reset,
      // submitting,
      change,
      hiddenFields
    } = this.props;

    // Add hidden fields
    if (hiddenFields) hiddenFields.forEach(f => change(f.name, f.value));

    return (
      <div style={{ padding: "1em 2em" }} className="col-collab flex-col">
        <div className="collection-collab__list">
          {this.state.collaborators &&
            this.state.collaborators.map(collab => {
              return (
                <div
                  style={{
                    userSelect: "none"
                  }}
                  className="collection-collab__label flex"
                >
                  <div className="collection-collab__img">
                    <img src={icPerson} alt="" />
                  </div>
                  <div className="collection-collab__text flex-col">
                    <div className="f-sz-l f-w-semi-bold">{collab.name ? collab.name : collab.email}</div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) =>
  ownProps.initialValues
    ? {
        initialValues: { ...ownProps.initialValues }
      }
    : {};

const mapDispatchToProps = dispatch => ({
  inviteUsers: details => dispatch(actions.inviteUsers(details)),
  getUsers: () => dispatch(actions.getUsers())
});

CollectionCollaboration_ViewOnly = connect(
  state => ({
    usersList: state.auth.list
  }),
  mapDispatchToProps
)(CollectionCollaboration_ViewOnly);

export default reduxForm({ form: formName }, mapStateToProps)(CollectionCollaboration_ViewOnly);
