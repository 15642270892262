import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import Button from "../../UI/Button/Button";
import { IconArrow, IconStar } from "../../UI/Icons";
import { ReactComponent as HandshakeIcon } from "../../../assets/img/Quote/hand-shake.svg"
import {
  getCoverImagePlaceholder,
  getSmallImageUrl,
  generateDesignLinkDummy,
} from "../../../helpers/DesignHelpers";
import { daysAgo } from "../../../helpers/DateHelpers";
import "../style.scss";

const MAP_STATUS_LABEL_CLS = {
  Requested: 'requested',
  Rejected: 'rejected',
};

const MAP_BRAND_CTA = {
  Awaited: null,
  'Review Pending': 'Review Quote',
  Approved: 'View Quote',
};

const MAP_FACTORY_CTA = {
  Requested: "Create Quote",
  'Review Pending': 'View Quote',
  Approved: 'View Quote',
};

const MAP_BRAND_STATUS_TS_LABEL = {
  'Review Pending': 'Received',
  'Awaited': 'Requested',
};

class QuoteItem extends React.Component {
  static propTypes = {
    quote: PropTypes.object.isRequired,
    userDetails: PropTypes.object.isRequired,
    currency: PropTypes.string.isRequired,
    openModal: PropTypes.func.isRequired,
    refreshQuotes: PropTypes.func.isRequired,
  };

  getCTA = () => {
    const {
      quote: {
        design_id,
        design_collection_id,
        brand_id,
        status_label,
        review_pending_count,
      },
      userDetails,
    } = this.props;
    const map = userDetails.isBrand || userDetails.isSuperAdmin
      ? MAP_BRAND_CTA
      : MAP_FACTORY_CTA;

    if (MAP_BRAND_CTA && status_label === 'Review Pending' && review_pending_count >= 1) {
      return <Button category="link" onClick={this._openReviewQuotesModal}>
        {map[status_label] + ' (' + review_pending_count + ')'}
      </Button>;
    }

    const cta = map[status_label];

    if (cta) {
      return <Link to={generateDesignLinkDummy({
        design: { id: design_id, },
        pathname: (userDetails.isBrand
          ? `/brand/collections/${design_collection_id}/designs/:design_id/`
          : `/factory/brands/${brand_id}/collections/${design_collection_id}/designs/:design_id/`),
        overrideTab: 'quote',
      })}>
        {cta}
      </Link>;
    }

    return null;
  }

  getTSLabel = ({ status_label, userDetails }) => {
    const map = userDetails.isBrand || userDetails.isSuperAdmin
      ? MAP_BRAND_STATUS_TS_LABEL
      : {};

    if (typeof (map[status_label]) === 'string') {
      return map[status_label] + " ";
    }

    return '';
  }

  _openReviewQuotesModal = () => {
    const {
      openModal,
      quote,
      currency,
      refreshQuotes,
      userDetails
    } = this.props;
    openModal("REVIEW_QUOTE_MODAL", {
      quote,
      currency: currency,
      onSuccess: refreshQuotes,
      isSuperAdmin: userDetails.isSuperAdmin,
    });

  }
  _openSizesQuoteModal = ({ design, sizes_quantity, quantity }) => {
    const { openModal } = this.props;
    openModal("QUOTE_SIZE_MODAL", {
      design,
      sizes_quantity,
      quantity,
    });
  };

  render() {
    const {
      quote: {
        design_cover_fileloc,
        design_category: category,
        best_quote: {
          factory_name,
          price = 0,
        },
        factories_count,
        design_name,
        source_design_id,
        brand_name,
        quantity,
        target_price,
        last_modified_on,
        status,
        status_label,
        design_id,
        design_collection_id,
        brand_id,
        sizes_quantity,
        is_pre_approved,
      },
      userDetails,
      currency,
    } = this.props;
    const priceVariation = target_price - price;
    const validImgUrl = (design_cover_fileloc && getSmallImageUrl(design_cover_fileloc));
    const imgLoc = validImgUrl || getCoverImagePlaceholder({ category }).src;
    const cta = this.getCTA();
    const tslabel = this.getTSLabel({ status_label, userDetails });
    // const canNudge = userDetails.isBrand
    //   ? moment(request_last_notified).diff(moment.now(), 'hours') >= 48
    //   : false;

    return (
      <div className={`table-row ${MAP_STATUS_LABEL_CLS[status_label] || ''}`}>
        <div className="container-table-section ">
          <div className="table-section-design design-wid">
            <div className={`section-design-img ${validImgUrl ? '' : 'dummy'}`}>
              <img src={imgLoc} alt="" />
            </div>
            <div className="section-design-text cell-v2">
              <div className="section-design-title overflow-ellipsis">
                <Link className="link-blue" to={generateDesignLinkDummy({
                  design: { id: design_id, },
                  pathname: (userDetails.isBrand
                    ? `../collections/${design_collection_id}/designs/:design_id/`
                    : `/factory/brands/${brand_id}/collections/${design_collection_id}/designs/:design_id/`),
                })}>
                  <span data-tip={design_name}>{design_name}</span>
                </Link>
              </div>
              <div className="f-color-faded">ID: {source_design_id}</div>
            </div>
          </div>
          {
            !userDetails.isBrand && <div className="table-section-brand overflow-ellipsis">
              <span data-tip={brand_name}>{brand_name}</span>
            </div>
          }
          {
            (userDetails.isBrand || !!userDetails.isSuperAdmin) && <div className="cell-v2 factory-info">
              <div className="table-section-factory overflow-ellipsis">
                <span data-tip={factory_name}>{factory_name}</span>
              </div>
              {factories_count > 1 && <Button category="link" onClick={this._openReviewQuotesModal}>
                + {factories_count - 1} more
              </Button>}
            </div>
          }
          <div className="table-section-quantity cell-v2">
            <div>{(quantity || 0).toLocaleString()}pcs</div>
            {(sizes_quantity && sizes_quantity.length > 0) && <Button
              category="link"
              onClick={() => this._openSizesQuoteModal({
                design: {
                  name: design_name,
                  category,
                  cover_fileloc: design_cover_fileloc,
                },
                quantity,
                sizes_quantity,
              })}
            >
              in {sizes_quantity.length} size{sizes_quantity.length > 1 ? 's' : ''}
            </Button>
            }
          </div>
          <div className="table-section-price">
            <div>{target_price ? `${currency} ${(target_price).toLocaleString('en-IN')}` : '--'}</div>
          </div>
          <div className="table-section-quote">
            {price > 0
              ? <div className="section-design-text cell-v2">
                <div className="section-design-title quote-price">
                  <span className="icon-text">{currency} {(price).toLocaleString()}</span>
                  {
                    price && (userDetails.isBrand || userDetails.isSuperAdmin) && status_label !== 'Approved'
                      ? <span className="best-quote" data-tip="Best quote"><IconStar color="yellow" /></span>
                      : ''
                  }
                  {
                    is_pre_approved ? <span className="pre-approved-quote" data-tip="Pre approved quote"><HandshakeIcon className="loco-icon" /></span> : null
                  }
                </div>
                {target_price ? <div className="price-variation">
                  {priceVariation === 0
                    ? <div className="title-match">MATCHED</div>
                    : <div className={priceVariation > 0 ? "title-green" : "title-red"}>
                      <span className="icon-text">{currency} {Math.abs(priceVariation)}</span>
                      {priceVariation > 0
                        ? <IconArrow direction="down" color="green" />
                        : <IconArrow direction="up" color="red" />
                      }
                    </div>}
                </div> : null}
              </div>
              : <span>--</span>
            }
          </div>
          <div className="table-section-status">
            <div className={`section-design-text ${cta ? 'hover-none' : ''}`}>
              <div className={`section-design-title status-${status} ${status_label === 'Rejected' ? "title-red" : ""}
                ${status_label === 'Approved' ? "title-green" : ""}`} >
                  { is_pre_approved ? "Pre-approved" : status_label }
              </div>
              <div className="design-title-date">{tslabel + (daysAgo(last_modified_on, {
                prefixOn: !!MAP_BRAND_STATUS_TS_LABEL[status_label]
              }) || '--')}</div>
            </div>
            {cta && <div className="title-blue title--review d-none">
              {cta}
            </div>
            }
          </div>
        </div>
      </div >
    );
  }
}
export default QuoteItem;
