import React, { Component } from "react";

// === Local imports ===
import HeaderWithLogo from "./MobileComponents/HeaderWithLogo";
import Design from "./MobileComponents/Design";
import Notify from "./MobileComponents/Notify";

const MobileView = ({ designs = [] }) => {
  console.log("DESIGNS IN LIST", designs);

  if (!designs.length) {
    return null;
  }

  return (
    <>
      <HeaderWithLogo />
      <Notify
        title={
          (designs[0]["samples"] && "Sample approval required") ||
          (designs[0]["quotes"] && "Quote Approval Required") ||
          (designs[0]["tna"] && "Time and Action Plan (TNA)")
        }
        message={
          (designs[0]["samples"] &&
            "Tap on the below designs to view & update the sample status.") ||
          (designs[0]["quotes"] &&
            "Tap on the below design(s) to view and update the quote status.") ||
          (designs[0]["tna"] &&
            "Click on below designs to view the status/progress.")
        }
      />

      {/* == DESIGN LIST STARTS */}
      <div style={{ padding: "0px 16px" }}>
        {designs.map(design => (
          <Design data={design} />
        ))}
      </div>
      {/* == DESIGN LIST ENDS */}
    </>
  );
};

export default MobileView;
