import React, { useState, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";

// == Local imports ==
import HeaderWithLogo from "./MobileComponents/HeaderWithLogo";
import DetailsDesign from "./MobileComponents/DetailsDesign";
import Tab from "./MobileComponents/Tab";
import SamplingDetails from "./MobileComponents/SamplingDetails";
import DesignSummary from "./MobileComponents/DesignSummary";
import QuoteList from "./MobileComponents/QuoteList";
import _ from "lodash";
import Tna from "./MobileComponents/Tna";
// ///////////////////
//  **Design** props contains the single design details.
//  also contains files and sampling one.

// ** Details props contails all the redux actions and defined state keys.

const Details = ({ details, usersList, ...restProps }) => {
  const [activeTab, setActiveTab] = useState(1);
  const { design } = restProps.location.state;

  const changeTabHandler = () => {
    if (!activeTab) {
      setActiveTab(1);
      return;
    }

    setActiveTab(0);
  };

  return (
    <div>
      <HeaderWithLogo isBackIcon />

      <DetailsDesign
        user={_.get(usersList, `${design.createdBy}.name`, null)}
      />

      {/* TABS */}
      <div
        style={{
          display: "flex",
          borderBottom: "1px solid #f5f5f5"
        }}
      >
        <Tab
          isActive={activeTab === 0}
          label="Design Summary"
          onClick={changeTabHandler}
        />
        <Tab
          isActive={activeTab === 1}
          label={
            details.selectedCategory
              ? details.selectedCategory === "quote"
                ? "Quote"
                : details.selectedCategory === "tna"
                ? "TNA"
                : "Sampling Details"
              : ""
          }
          onClick={changeTabHandler}
        />
      </div>
      {/* TABS */}

      {activeTab === 0 ? (
        <DesignSummary design={design} users={usersList} />
      ) : details.selectedCategory ? (
        details.selectedCategory === "quote" ? (
          <QuoteList
            quoteDetails={details.quoteDetails}
            getQuoteDetails={() =>
              details.actions.quote.getQuoteDetails({ id: design.id })
            }
          />
        ) : details.selectedCategory === "tna" ? (
          <Tna designId={design.id} />
        ) : (
          <SamplingDetails samples={design.samples} designId={design.id} />
        )
      ) : null}
    </div>
  );
};

export default Details;
