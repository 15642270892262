import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import isEqual from "lodash/isEqual";
import find from "lodash/find";
import moment from "moment";
import keys from "lodash/keys";
import { Helmet } from "react-helmet";

import CollectionDetails from "./../../components/Collections/CollectionDetails/CollectionDetails";
import InternalSidebar from "../../components/UI/InternalSidebar/InternalSidebar";
import PrivateContainer from "components/UI/PrivateContainer/PrivateContainer"
import Filter from "components/Product/Filter";

import "./CollectionDetailsContainer.scss";
import ImgChevronLeft from "assets/images/navigation/ic-chevron-left.svg";
import ImgAdd from "assets/images/content/ic-add.svg";

import actions from "../../store/actions";

import { getCurrentUserRole, canCreateDesign } from "../../helpers/AuthHelper";
import { computePath } from "../../helpers/pathHelper";

import { PAGE_TITLES } from "../../constants/Titles";
import { PRODUCTION_MERCHANT } from "../../constants/Auth";

class CollectionDetailsContainer extends Component {
  state = {
    userRole: getCurrentUserRole(),
    selectedCollection: {},
    selectedFactory: {},
    initialSelectedFactory: null,
  };

  componentDidMount() {
    const { currentUser, getAllCollections, match, getUsers, getDesignsByCollectionId, collections, getAssignedFactories} = this.props;
    let params;
    let isMerchant = currentUser.role === PRODUCTION_MERCHANT;
    if (isMerchant) {
      params = { factoryId: match.params.brand_id };
      getAssignedFactories();
    }
    getAllCollections(params);
    getUsers();
    if(match.params.collection_id){
      getDesignsByCollectionId(match.params.collection_id, null , isMerchant ? match.params.brand_id : null);
      const selectedCollectionId = Number(match.params.collection_id);
      let selectedCollection =
        Object.keys(collections).length > 0
          ? collections[
              Object.keys(collections).filter(
                (c) => collections[c].id === selectedCollectionId
              )[0]
            ]
          : {};
      this.setState({
        selectedCollection: selectedCollection,
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !isEqual(this.props, nextProps) || !isEqual(this.state, nextState)
    );
    // return true;
  }

  componentDidUpdate(oldProps, oldState) {
    const collectionNotEqual = !isEqual(oldState.selectedCollection, this.state.selectedCollection);
    const isMerchant = this.props.currentUser.role === PRODUCTION_MERCHANT;
    if (collectionNotEqual){
      if(this.state.selectedCollection.id){
        const factoryId = isMerchant ? (this.state.selectedFactory.id || this.props.match.params.brand_id ): null;
        this.props.getDesignsByCollectionId(this.state.selectedCollection.id, null, factoryId);
      }
    }

    if(this.state.redirect && (keys(this.props.collections)[0] !== keys(oldProps.collections)[0])){
      const selectedCollection = this.props.collections[keys(this.props.collections)[keys(this.props.collections).length - 1]];
      if(!collectionNotEqual && isMerchant){
        this.props.getDesignsByCollectionId(this.state.selectedCollection.id, null, this.state.selectedFactory.id);
      }
      this.setState({
        redirect: false,
        selectedCollection
      }, () => {
        this.goToBrand(this.state.selectedFactory.id, selectedCollection.id);
      });
    }
  }

  goToBrand = (id, collectionId) => {
    const { history } = this.props;
    const path = computePath();
    history.push(`/${path}/brands/${id}/collections/${collectionId}/?tab=allDesigns`)
  }

  reloadDesigns = () =>
    this.props.getDesignsByCollectionId(this.state.selectedCollection.id, null, this.state.selectedFactory.id);

  handleFactoryFilter = (selection) => {
    this.props.getAllCollections({ factoryId: selection.id });
    this.setState({
      selectedFactory: selection,
      redirect: true,
    })
  }


  static getDerivedStateFromProps(nextProps, prevState) {
    const collectionId = Number(nextProps.match.params.collection_id);
    const factoryId = Number(nextProps.match.params.brand_id);
    let factoryFilter = {};
    let initialSelectedFactory = prevState.initialSelectedFactory;

    if (!nextProps.collections || !Object.keys(nextProps.collections).length)
      return {
        ...prevState,
        selectedCollection: {},
      };

    if (!collectionId || !nextProps.collections[collectionId]) return null;

    if(nextProps.currentUser.role === PRODUCTION_MERCHANT && nextProps.factoryFilterOptions &&!initialSelectedFactory){
      initialSelectedFactory = find(nextProps.factoryFilterOptions, { id: factoryId });

      factoryFilter = {
        initialSelectedFactory ,
        selectedFactory: initialSelectedFactory
      }
    }

    return {
      ...prevState,
      selectedCollection: nextProps.collections[collectionId],
      ...factoryFilter,
    };

  }

  static propTypes = {
    openModal: PropTypes.func,
  };

  openNewCollectionModal = () => {
    this.props.openModal("NEW_COLLECTION", {
      hiddenFields: [
        { name: "brandId", value: String(this.state.selectedCollection.brand) },
      ],
    });
  };

  scrollRefHandler = (ref) => {
    this._scrollRef = ref;
  };

  render() {
    const { selectedCollection, selectedFactory, initialSelectedFactory } = this.state;
    const {
      collections,
      match: { params },
      currentUser,
      factoryFilterOptions,
    } = this.props;
    const selectedCollectionId = selectedCollection.id;

    let sortedCollections = [];

    if (currentUser.role === PRODUCTION_MERCHANT) {
      sortedCollections = Object.keys(collections).sort((a, b) => {
        return moment(collections[a]["created_on"]).isBefore(
          collections[b]["created_on"]
        )
          ? 1
          : -1;
      });
    } else {
      sortedCollections = Object.keys(collections)
        .filter((cId) =>
          params.brand_id
            ? Number(collections[cId]["brand"]) === Number(params.brand_id)
            : true
        )
        .sort((a, b) => {
          return moment(collections[a]["created_on"]).isBefore(
            collections[b]["created_on"]
          )
            ? 1
            : -1;
        });
    }

    // if (this._scrollRef && selectedCollectionId) {
    //   const findIndex = sortedCollections.findIndex(
    //     val => selectedCollectionId === Number(val)
    //   );
    //   // if (findIndex !== -1) this._scrollRef.scrollTop = findIndex * 70;
    // }

    const showFooter = canCreateDesign();
    return (
      <div className="col-container-details flex">
        <Helmet
          bodyAttributes={{
            class: "body_container",
          }}
          title={PAGE_TITLES.collectionDetails(
            selectedCollection.name || "Collections"
          )}
        />
        <InternalSidebar
          scrollRefHandler={this.scrollRefHandler}
          header={
            <Link
              to={{
                pathname: `../`,
              }}
              className="col-container-details__sidebar-item header flex"
            >
              <img src={ImgChevronLeft} alt="" />
              <span>All Collections</span>
            </Link>
          }
          footer={
            showFooter ? (
              <div
                onClick={this.openNewCollectionModal}
                className="col-container-details__sidebar-item footer flex"
              >
                <span>New Collection</span>
                <img src={ImgAdd} alt="" />
              </div>
            ) : (
              <div></div>
            )
          }
        >
          <>
          <PrivateContainer allowRoles={[PRODUCTION_MERCHANT]}>
              <Filter
                title="Factory"
                innerWidth="160px"
                width="160px"
                height="auto"
                initialSelectedItem={initialSelectedFactory}
                onChange={(selection) => {
                  this.handleFactoryFilter(selection);
                }}
                selectedItem={selectedFactory}
                filterOptions={factoryFilterOptions}
                marginRight="20px"
              />
            </PrivateContainer>
          {sortedCollections.map((collectionId) => (
            <Link
              className={`item-container ${
                selectedCollectionId === this.props.collections[collectionId].id
                  ? "active"
                  : ""
              }`}
              key={collectionId}
              to={`../${collectionId}/`}
            >
              <div
                // ref={ref => {
                //   console.log(ref);
                // }}
                className={`col-list__item${
                  selectedCollectionId ===
                  this.props.collections[collectionId].id
                    ? " col-list__item--active"
                    : ""
                }`}
              >
                <div className="col-list__title item__title overflow-ellipsis">
                  {this.props.collections[collectionId].name}
                </div>
                <div className="col-list__subtitle item__subtitle f-sz-sm">
                  {selectedCollectionId ===
                  this.props.collections[collectionId].id
                    ? Object.keys(this.props.selectedDesigns).length
                    : this.props.collections[collectionId].products_count}{" "}
                  Designs
                </div>
              </div>
            </Link>
          ))}
          </>
        </InternalSidebar>
        <div className="col-container-details__main">
          <CollectionDetails
            match={this.props.match}
            openModal={this.props.openModal}
            usersList={this.props.usersList}
            collection={selectedCollection}
            designs={this.props.selectedDesigns}
            isLoading={this.props.isLoading}
            reloadDesigns={this.reloadDesigns}
            currentUser={this.props.currentUser}
            collaborateCollection={this.props.collaborateCollection}
            brandFeatures={this.props.brandFeatures}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  collections: state.collections.list,
  usersList: state.auth.list,
  selectedDesigns: state.designs.selectedDesigns,
  isLoading:
    state.designs.isLoadingGetDesigns[props.match.params.collection_id],
  currentUser: state.auth.userDetails,
  brandFeatures: state.brands.brandFeatures,
  factoryFilterOptions: state.factory.transformedFactories
});

const mapDispatchToProps = (dispatch) => ({
  getAllCollections: (params) => dispatch(actions.getAllCollections(params)),
  getUsers: () => dispatch(actions.getUsers()),
  getDesignsByCollectionId: (collectionId, designId, factoryId) =>
    dispatch(actions.getDesignsByCollectionId(collectionId, designId, factoryId)),
  collaborateCollection: (data) =>
    dispatch(actions.updateCollectionCollab(data)),
  getAssignedFactories: (params) => dispatch(actions.getAssignedFactories(params))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CollectionDetailsContainer);
