import React from "react";
import "./styles.scss";

const StaticTable = ({ sizes = [], tableHeading }) => (
  <table className="size-table static-full">
    <thead>
      <td>Size</td>
      <td>{tableHeading}</td>
    </thead>
    <tbody>
      {sizes.map(({ size, quantity }, index) => (
        <tr className="size-input input-grp">
          <td>{size}</td>
          <td className="f-color-black">{quantity}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

export default StaticTable;
