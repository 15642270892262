import React, { Component } from "react";

import "./Textarea.scss";

class Input extends Component {
  state = {
    currentLength: 0,
    currentValue: ""
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      ...prevState,
      currentLength: nextProps.input.value.length
    };
  }

  handleChange = (evt, ...args) => {
    this.setState({
      currentLength: evt.target.value.length,
      currentValue: evt.target.value
    });
    this.props.input.onChange(evt);
  };

  render() {
    const {
      label,
      meta: { touched, error, warning },
      meta,
      input,
      maxLength,
      // import separately so that it doesn't override the default HTML onChange
      onChange,
      ...props
    } = this.props;
    const { currentLength } = this.state;

    return (
      <div className={`input-grp ${error === true ? "input-grp--error" : ""}`}>
        <label htmlFor={input.name} className="f-sz-sm f-color-faded">
          {label}
          {props.required === true && <sup>*</sup>}
        </label>
        <div>
          <textarea
            {...input}
            {...props}
            id={input.name}
            onBlur={this.handleChange}
            maxLength={maxLength}
            style={(() => {
              if (this.props.prefixText) {
                return {
                  paddingLeft: `${this.props.prefixText.length + 1}ch`,
                  margin: "1em"
                };
              }
            })()}
          />
          {this.props.prefixText && (
            <span
              style={{
                color: "var(--fill-grey-light)",
                position: "absolute",
                left: "10px",
                top: "14px"
              }}
            >
              {this.props.prefixText}
            </span>
          )}
        </div>
        <span className="input-grp__lower flex f-sz-sm">
          {touched &&
            ((error && <span className="error">{error}</span>) || (warning && <span>{warning}</span>))}

          {maxLength ? (
            <span className="input-len-count f-color-faded">
              {currentLength}/{maxLength}
            </span>
          ) : null}
        </span>
      </div>
    );
  }
}

export default Input;
