import { LOCO_SUPER, CLIENT_SUPER, CLIENT } from "constants/Auth"

export const checkCanMoveDesign = ({
  user: { name, role },
  design: { username }
}) => {
  if (
    role === LOCO_SUPER
    || role === CLIENT_SUPER
    || (role === CLIENT && name === username)
  ) {
    return true;
  }

  return false;
}
