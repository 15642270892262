import React from "react";

import Button from "components/UI/Button/Button";
import alertIcon from "assets/images/communication/ic-alert.svg";

const SkipSampling = ({ design, closeModal, openProductionSampleModal }) => {
  return (
    <div className="skip-sampling alert-modal">
      <img src={alertIcon} className="alert-icon" alt="alert" />
      <div className="f-sz-14 content">
        <h3>Skip to Production</h3>
        <p>
          Do you want to skip sampling for this design? You won’t be able to
          request a sample later.
        </p>
      </div>
      <footer>
        <Button
          category="btn black-ghost medium"
          onClick={() => {
            closeModal();
          }}
        >
          CANCEL
        </Button>

        <Button
          category="btn primary-blue"
          onClick={() => {
            /* @@parmas1 => skipSampling: boolean */
            openProductionSampleModal(1);
          }}
        >
          Skip to production
        </Button>
      </footer>
    </div>
  );
};

export default SkipSampling;
