import React from "react";

import Filter from "./Factoryfilter";

const HeaderWithFilters = ({
  cities = [],
  isFactory,
  toggleCity,
  selectAllCities,
  unSelectAllCities,
  showReset,
  applyFilter
}) => {

  return (
    <div style={{ display: "flex" }}>
      <Filter
        title="City"
        initialSelectedItem={{
          label: "All",
          value: "All"
        }}
        filterOptions={cities && cities.map(city => {
          return ({
            label: city.city,
            value: city.city,
            isSelected: city.isSelected,
            designCount: city.FactoryCount
          })
        }
        )}
        onChange={city => { toggleCity(city.value) }}
        applyCollections={applyFilter}
        disable={isFactory ? cities.length : true}
        cancelCollectionFilter={unSelectAllCities}
        reset={!showReset}
        containerWidth="250px"
        pillWidth="250px"
        selectAll={selectAllCities}
      />
    </div>
  );
};

export default HeaderWithFilters;

