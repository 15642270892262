import React from "react";
import { Link, withRouter } from "react-router-dom";

import {
  getSmallImageUrl,
  getCoverImagePlaceholder
} from "../../../helpers/DesignHelpers";

const Design = ({
  match,
  data,
  data: { name, category, subCategory, coverImageUrl = "dfghjk" } = {}
}) => {
  return (
    <Link
      to={{
        pathname: `${match.url}details/`,
        state: { design: data }
      }}
      style={{
        minHeight: "80px",
        boxShadow: " inset 0 -1px 0 0 var(--fill-grey-light3)",
        backgroundColor: "var(--fill-white)",

        display: "flex",
        alignItems: "center",
        borderBottom: "1px solid #f5f5f5",
        cursor: "pointer"
      }}
    >
      <div
        style={{
          width: " 48px",
          minHeight: " 48px",
          borderRadius: "100px",
          overflow: "hidden",
          marginRight: "16px"
        }}
      >
        <img
          src={
            coverImageUrl
              ? getSmallImageUrl(coverImageUrl)
              : getCoverImagePlaceholder(data).src
          }
          alt="Design"
          style={{ height: "100%", width: "100%" }}
        />
      </div>
      <div>
        <div
          style={{
            fontSize: "14px",
            fontWeight: "600",
            color: "var(--fill-locofast-black)",
            lineHeight: "normal"
          }}
        >
          {name}
        </div>
        <div style={{ fontSize: "12px", color: "var(--fill-grey-medium)" }}>
          {`${category} - ${subCategory}`}
        </div>
      </div>
    </Link>
  );
};

export default withRouter(Design);
