import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import Header from "../UI/Header";
import Table from "../UI/Table";
import FilterContainer from "./../UI/Filters";
import { DatePicker } from "./../UI/Filters/DateRangePicker";
import MultiSelect from "./../UI/Filters/MultiSelect";
import { getFactoryPerformance } from "store/actions/ReportsAction";
import Loader from "components/UI/InlineLoader";

const LIMIT = 20;
const HEADINGS = [
  "FACTORY",
  "NO. OF ORDERS",
  "NO. OF DESIGNS",
  "AVG. SAMPLE RATING",
  "SAMPLE ACCEPTANCE RATE",
  "AVG. QUOTE TAT",
  "AVG. SAMPLING TAT",
  "AVG. PRODUCTION TAT",
  "OTIF"
];

const TOOLTIP_DATA = {
  "NO. OF ORDERS": `<div class="subtext">The total number of orders placed with the factory</div>`,
  "NO. OF DESIGNS": `<div class="subtext">The sum-total of designs in the orders placed with the factory</div>`,
  "AVG. SAMPLE RATING": `<div class="subtext">The average of the sample ratings on each approved sample</div>`,
  "SAMPLE ACCEPTANCE RATE": `<div class="subtext">The ratio (in % ) of all sample approved to all the samples dispatched</div>`,
  "AVG. QUOTE TAT": `<div class="subtext">The average time taken by the factory to submit quote</div>`,
  "AVG. SAMPLING TAT": `<div class="subtext">The average time from sample request to approval</div>`,
  "AVG. PRODUCTION TAT": `<div class="subtext">The average time from PP sample approval to dispatch the production lot</div>`,
  OTIF: `<div class="subtext">Percentage of orders shipped in time and in full</div>`
};

const FactoryPerformance = ({
  getFactoryPerformance,
  factoryPerformance = { list: [] },
  loading
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [filters, setFilters] = useState({});
  const [dates, setDates] = useState({});

  useEffect(() => {
    getFactoryPerformance({ isInit: 1 });
  }, []);

  const paginationHandler = values => {
    if (
      values.offset &&
      factoryPerformance.list.length <= (currentIndex + 1) * LIMIT
    ) {
      setCurrentIndex(currentIndex + 1);
      getFactoryPerformance({
        offset: factoryPerformance.pagination.nextOffset,
        ...filters
      });
    } else {
      if (values.offset || (!values.offset && values.isnext === 1)) {
        setCurrentIndex(currentIndex + 1);
      } else if (values.isnext === 0) {
        setCurrentIndex(currentIndex - 1);
      }
    }
  };

  if (loading) {
    return <Loader />;
  }

  const getOverallReport = () => {
    return getFactoryPerformance({
      ...filters,
      limit: factoryPerformance.pagination.totalCount,
      skipStore: true,
      inlineLoader: false
    }).then(data => {
      return data.records;
    });
  };

  return (
    <div className="report-content">
      <Header
        heading="Factory Performance"
        reportType="factoryPerformance"
        pagination={factoryPerformance.pagination}
        currentIndex={currentIndex}
        paginationHandler={paginationHandler}
        getOverallReport={getOverallReport}
        headings={HEADINGS}
      />
      <FilterContainer
        updateFilters={() => {
          getFactoryPerformance({
            ...filters,
            ...dates,
            isInit: 1
          });
          setCurrentIndex(0);
        }}
      >
        <DatePicker
          label="Date Range"
          updateFilterDates={dates => setDates(dates)}
          selectedDates={dates}
        />
        <MultiSelect
          label="Factory"
          filterOptions={factoryPerformance.filters.factories}
          onChange={ids => {
            setFilters({ ...filters, factoryIds: ids ? ids.join() : null });
          }}
          selectedFilters={filters.factoryIds}
        />
      </FilterContainer>

      <Table
        headings={HEADINGS}
        tooltipData={TOOLTIP_DATA}
        data={factoryPerformance.list.slice(
          currentIndex * LIMIT,
          currentIndex * LIMIT + LIMIT
        )}
        gridTemplateColumns="
    minmax(200px, 1.5fr)
    minmax(140px, 1fr)
    minmax(140px, 1fr)
    minmax(174px, 1.2fr)
    minmax(204px, 1.5fr)
    minmax(140px, 1fr)
    minmax(164px, 1.2fr)
    minmax(180px, 1.3fr)
    minmax(140px, 1fr)
    "
      />
    </div>
  );
};

const mapStateToProps = state => ({
  factoryPerformance: state.reports.factoryPerformance,
  loading: state.reports.loading
});

export default connect(mapStateToProps, {
  getFactoryPerformance
})(FactoryPerformance);
