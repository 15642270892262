import { handleActions } from "redux-actions";

const INITIAL_STATE = {
  designs: [],
  selectedDesignId: null,
  messages: {},
  factories: {},
};

const MessageReducer = handleActions({
  MESSAGING_DESIGNS: (state, action) => {
    return {
      ...state,
      designs: action.payload,
    };
  },

  MESSAGING_DESIGN_FACTORIES: (state, action) => {
    const { designId, factories } = action.payload;

    return {
      ...state,
      factories: {
        ...state.factories,
        [designId]: factories,
      },
    };
  },

  MESSAGING_DESIGN_MESSAGES: (state, action) => {
    const { designId, factoryId, messages } = action.payload;

    return {
      ...state,
      messages: {
        ...state.messages,
        [designId]: {
          ...(state.messages[designId] || {}),
          [factoryId]: messages,
        },
      },
    };
  },

  MESSAGING_MESSAGE_SENT: (state, action) => {
    const { designId, factoryId, sentMessage } = action.payload;
    const factories = (state.messages[designId] || {});
    const factoryMessages = (factories[factoryId] || []);

    return {
      ...state,
      messages: {
        ...state.messages,
        [designId]: {
          ...factories,
          [factoryId]: [
            ...factoryMessages,
            sentMessage,
          ],
        },
      },
    };
  },

  MESSAGING_MARK_READ: (state, action) => {
    const { designId, factoryId } = action.payload;
    const factory = state.factories[designId]
      && state.factories[designId].find(factory => factory.id === factoryId);
    const readCount = (factory && factory.unread_count) || 0;

    if (readCount <= 0) {
      return state;
    }

    return {
      ...state,
      factories: {
        ...state.factories,
        ...(state.factories[designId]
          ? {
            [designId]: state.factories[designId].map(factory => {
              if (factory.id !== factoryId) {
                return factory;
              }

              return {
                ...factory,
                unread_count: 0,
              };
            })
          }
          : {}
        )
      },
      designs: state.designs.map(design => {
        if (design.id !== designId) {
          return design;
        }

        return {
          ...design,
          unreadCount: Math.max(0, design.unreadCount - readCount),
        };
      })
    };
  },

}, INITIAL_STATE)

export default MessageReducer;
