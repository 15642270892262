import { combineReducers } from "redux";
import { reducer as ReduxFormReducer } from "redux-form";
import AuthReducer from "./AuthReducer";
import CollectionReducer from "./CollectionReducer";
import DesignReducer from "./DesignReducer";
import FactoryReducer from "./FactoryReducer";
import GlobalReducer from "./GlobalReducer";
import NotificationViewReducer from "./NotificationViewReducer";
import Products from "./Products";
import SwatchLibraryReducer from "./SwatchLibraryReducer";
import MessageReduer from "./MessageReduer";
import BrandReducer from "./BrandReducer";
import OrderReducer from "./OrderReducer";
import QuoteReducer from "./QuoteReducer";
import DashboardReducer from "./DashboardReducer";
import MaterialLibraryReducer from "./MaterialLibraryReducer";
import ReportsReducer from "./ReportsReducer";
import TnaReducer from "./TnaReducer";
import UserReducer from "./UserReducer";
import PerformanceReportReducer from "./PerformanceReportReducer";
import LogisticReducer from './LogisticReducer';
import fabricQualitiesReducer from './FabricQualitiesReducer';

export default combineReducers({
  global: GlobalReducer,
  auth: AuthReducer,
  form: ReduxFormReducer,
  collections: CollectionReducer,
  designs: DesignReducer,
  factory: FactoryReducer,
  notificationView: NotificationViewReducer,
  products: Products,
  swatchLibrary: SwatchLibraryReducer,
  messaging: MessageReduer,
  brands: BrandReducer,
  logisticsPartner: LogisticReducer,
  orders: OrderReducer,
  quotes: QuoteReducer,
  dashboard: DashboardReducer,
  materialLibrary: MaterialLibraryReducer,
  reports: ReportsReducer,
  tna: TnaReducer,
  user: UserReducer,
  performance: PerformanceReportReducer,
  fabricQualities: fabricQualitiesReducer
});
