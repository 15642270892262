import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, Field, reduxForm } from "redux-form";
import Input from "../../UI/Form/Input/Input";
import { required } from "../../../helpers/form-validations";

import "./CollectionDetailsForm.scss";

const formName = "collectionDetails";
const collectionNameValidation = value =>
  value && /[^a-zA-Z0-9 .,'-]/i.test(value) ? "No special characters allowed" : undefined;
class NewCollection extends Component {
  static propTypes = {
    prop: PropTypes
  };
  render() {
    const { handleSubmit, pristine, reset, submitting, change, hiddenFields } = this.props;

    // Add hidden fields
    if (hiddenFields) hiddenFields.forEach(f => change(f.name, f.value));

    return (
      <div className="new-col flex" style={{ padding: "1em 2em" }}>
        <Form
          name={formName}
          id={formName}
          onSubmit={handleSubmit(values => {
            this.props.onSubmit(values);
          })}
        >
          <Field
            component={Input}
            validate={[required, collectionNameValidation]}
            required={true}
            maxLength={50}
            type="text"
            name="name"
            label="Name your collection"
            autoFocus={true}
          />
          {/*<Field
            component={Input}
            validate={[alphaNumeric]}
            // maxLength={50}
            type="text"
            name="description"
            label="Describe your collection"
         />*/}
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) =>
  ownProps.initialValues
    ? {
        initialValues: { ...ownProps.initialValues }
      }
    : {};

export default reduxForm({ form: formName }, mapStateToProps)(NewCollection);
