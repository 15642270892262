import React from "react";
import classnames from "classnames";
import "./FloatingCTA.scss";

const FloatingCTA = ({ onClick, children, position = "bottom", disabled = false, style = {} }) => {
  const floatingCTAClasses = classnames(`floating-cta floating-cta--${position.toLowerCase()}`, {
    "floating-cta--disabled": disabled
  });
  return (
    <div style={style} className={floatingCTAClasses}>
      <button onClick={onClick}>
        {children} &nbsp; <i className="fa fa-inverted fa-chevron-right" />
      </button>
    </div>
  );
};

export default FloatingCTA;
