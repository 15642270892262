import axios from "axios";
import { createAction } from "redux-actions";
import { get_service_endpoint } from "../../ServiceEndpoints";
import { flash } from "./CommonActions";
import { closeModal, openModal } from "./GlobalActions";
import _ from "lodash";
import { transformNotifications } from '../../helpers/transformNotifications';
const ep = get_service_endpoint("notificationView");

const GET_NOTIFICATION_DETAILS = createAction("GET_NOTIFICATION_DETAILS");
const GET_NOTIFICATION_LIST = createAction("GET_NOTIFICATION_LIST");
const LOGIN_SUCCESS = createAction("LOGIN_SUCCESS");
const CREATE_DISPATCH_NOTIFICATION = createAction(
  "CREATE_DISPATCH_NOTIFICATION"
);
const REMOVE_DISPATCH_NOTIFICATION = createAction(
  "REMOVE_DISPATCH_NOTIFICATION"
);
const SET_CURRENT_BRAND = createAction("SET_CURRENT_BRAND");
const GET_DISPATCH_NOTIFICATION_LIST = createAction(
  "GET_DISPATCH_NOTIFICATION_LIST"
);
const ADD_PENDING_NOTIFICATION = createAction("ADD_PENDING_NOTIFICATION");
const REMOVE_PENDING_NOTIFICATION = createAction("REMOVE_PENDING_NOTIFICATION");

const SET_NOTIFICATION_MODE = createAction("SET_NOTIFICATION_MODE");

const GET_ALL_NOTIFICATIONS = createAction("GET_ALL_NOTIFICATIONS");

export const createDispatchNotification = dispatchObj => dispatch => {
  dispatch(CREATE_DISPATCH_NOTIFICATION(dispatchObj));
};

export const removeDispatchNotification = ({
  dispatchId,
  brandId
}) => dispatch => {
  dispatch(REMOVE_DISPATCH_NOTIFICATION({ id: dispatchId, brandId }));
};

export const setCurrentBrand = ({ brandId }) => dispatch => {
  dispatch(SET_CURRENT_BRAND({ brandId }));
};

export const authTokenLogin = ({ authToken }) => dispatch => {
  localStorage.setItem("loggedIn", true);
  // Set default role as CLIENT, since nothing returned here, unlike /login
  localStorage.setItem("role", "CLIENT");
  return axios
    .get(`${get_service_endpoint("auth")}/authtokenLogin`, {
      params: { auth: authToken }
    })
    .then(res => {
      dispatch(LOGIN_SUCCESS(res.data));
    });
};

export const getNotificationsList = ({ brand }) => dispatch => {
  return axios
    .get(`${ep}/notifications/getallnotifications/`, {
      params: { brandid: brand.id }
    })
    .then(res => {
      dispatch(GET_NOTIFICATION_LIST(res.data));
    });
};

export const getAllUsersForBrand = ({ brandId }) => dispatch => {
  return axios
    .get(`${ep}/notifications/usersforbrand`, { params: { brandid: brandId } })
    .then(res => res.data);
};

const createNotification = notificationDetail => dispatch => {
  return axios
    .post(`${ep}/notifications/createbulknotification`, {
      ...notificationDetail
    })
    .then(res => {
      dispatch(
        getNotificationsList({
          brand: { id: notificationDetail.locofastrootId }
        })
      );
    });
};


/**
 * 
 * By stage because the api is designed that way
 */
// ?////////////////////???????????????????? ************
export const createNotifications = notificationDetail => dispatch => {
  if (
    !_.isArray(notificationDetail.selectedUserIndex) ||
    notificationDetail.selectedUserIndex.length < 1
  ) {
    dispatch(
      flash({ message: "Please select users before sending notification(s)" })
    );
    return;
  }

  const notificationsGroupedByStage = _.groupBy(
    notificationDetail.notifications,
    "stage"
  );

  const userList = _.filter(notificationDetail.userList, notif => _.includes(notificationDetail.selectedUserIndex, notif.id));

  _.forEach(notificationDetail.notifications, ({category, subCategory, design_id}) => {
    dispatch(REMOVE_PENDING_NOTIFICATION({type: category, name: subCategory, design_id}))
  })

  Object.entries(notificationsGroupedByStage).forEach(
    ([notificationType, notificationBody]) => {
      const { brand_id } = notificationDetail;
      const notifications = transformNotifications({ type: notificationType, notifications: notificationBody, userList, brand_id });
      dispatch(
        createNotification(notifications)
      );
    }
  );
  

  

  // resets the screen with callback function ****
  if (notificationDetail.resetScreen) {
    notificationDetail.resetScreen();
  }

  // window.lf.deletedRows = notificationDetail.notifications;

  dispatch(closeModal());
};


export const getNotificationDetails = ({ notificationId }) => dispatch => {
  return (
    axios
      .get(`${ep}/notifications/getalldesigns`, {
        params: { nid: notificationId }
      })

      //   return new Promise((resolve, reject) => {
      //     setTimeout(() => {
      //       resolve({
      //         data: {
      //           category: 1,
      //           designs: [
      //             {
      //               createdAt: "2019-02-18T12:51:44.000Z",
      //               updatedAt: "2019-02-18T12:51:44.000Z",
      //               id: 111,
      //               createdBy: 57,
      //               updatedBy: 57,
      //               name: "Test design",
      //               sourceDesignId: "TEST",
      //               category: "Men",
      //               subCategory: "Sports and active wear",
      //               description: null,
      //               status: 1,
      //               active: "Y",
      //               collectionId: 67,
      //               brandId: 7
      //             },
      //             {
      //               createdAt: "2019-02-18T13:57:07.000Z",
      //               updatedAt: "2019-02-18T15:45:22.000Z",
      //               id: 112,
      //               createdBy: 57,
      //               updatedBy: 57,
      //               name: "Test",
      //               sourceDesignId: "ASD",
      //               category: "Men",
      //               subCategory: "Sports and active wear",
      //               description: null,
      //               status: 7,
      //               active: "Y",
      //               collectionId: 70,
      //               brandId: 7
      //             }
      //           ]
      //         }
      //       });
      //     }, 700);
      //   })
      .then(res => {
        // this just makes the first active
        if (res.data.orders) {
          window.appHistory.push({
            poItemDetails: res.data.orders[0]
          });
        }
        dispatch(GET_NOTIFICATION_DETAILS(res.data));

        return res.data.designs.map(v => ({
          collection: v.collectionId,
          design: v.id
        }));
      })
  );
};

export const getAllDispatchNotifications = ({ brand }) => dispatch => {
  return axios
    .get(`${ep}/notifications/dispatchnotifications/`, {
      params: { brandid: brand.id }
    })
    .then(res => {
      dispatch(GET_DISPATCH_NOTIFICATION_LIST(res.data));
    });
};

// this gets hitted on sending dispatch notification
export const postDispatchNotification = notificationDetail => dispatch => {
  if (
    !_.isArray(notificationDetail.selectedUserIndex) ||
    notificationDetail.selectedUserIndex.length < 1
  ) {
    dispatch(
      flash({ message: "Please select users before sending notification(s)" })
    );
    return;
  }

  const userList = notificationDetail.userList.filter(u =>
    notificationDetail.selectedUserIndex.includes(u.id)
  );
  const NotificationObj = {
    modules: notificationDetail.notifications.map(v => ({
      category: 6,
      locofastrootId: v.brandId,
      orderPurchaseId: v.orderPurchaseId,
      orderDispatchId: v.dispatchId
    })),
    audience: userList.map(u => ({ audienceId: u.id, status: 1 })),
    locofastrootId: notificationDetail.notifications[0].brandId
  };

  // {
  //   "resp": {
  //     "data": {
  //       "createdAt": "2019-05-29T11:18:07.000Z",
  //       "updatedAt": "2019-05-29T11:18:07.000Z",
  //       "id": 787,
  //       "createdBy": 76,
  //       "updatedBy": 0,
  //       "name": "nCUyUhUeopWxsVrQMcNeYA",
  //       "scheduledOn": "2019-05-29T11:18:07.000Z",
  //       "status": 1,
  //       "locofastrootId": 11,
  //       "notificationModules": [
  //         {
  //           "status": 1,
  //           "createdAt": "2019-05-29T11:18:07.000Z",
  //           "updatedAt": "2019-05-29T11:18:07.000Z",
  //           "id": 1601,
  //           "createdBy": 76,
  //           "updatedBy": 0,
  //           "designsId": 0,
  //           "collectionId": 0,
  //           "sampleId": null,
  //           "sampleType": null,
  //           "quoteId": null,
  //           "orderPurchaseId": 55,
  //           "orderDispatchId": 74,
  //           "category": 6,
  //           "designStatus": 0,
  //           "tnaId": 0,
  //           "tnaAttributeId": 0,
  //           "notificationRootId": 787
  //         }
  //       ],
  //       "notificationAudience": [
  //         {
  //           "createdAt": "2019-05-29T11:18:07.000Z",
  //           "updatedAt": "2019-05-29T11:18:07.000Z",
  //           "id": 1077,
  //           "createdBy": 76,
  //           "updatedBy": 0,
  //           "audienceId": 77,
  //           "status": 1,
  //           "notificationRootId": 787
  //         }
  //       ]
  //     },
  //     "code": "200"
  //   }
  // }

  return axios
    .post(`${ep}/notifications/createbulknotification`, NotificationObj)
    .then(({ data }) => {
      data.notificationModules.forEach(({ orderDispatchId }) =>
        dispatch(
          removeDispatchNotification({
            dispatchId: String(orderDispatchId),
            brandId: String(data.locofastrootId)
          })
        )
      );
      dispatch(
        getAllDispatchNotifications({
          brand: { id: notificationDetail.notifications[0].brandId }
        })
      );
      dispatch(closeModal());
    });
};


export const addPendingNotification = notification => dispatch => {  
  dispatch(ADD_PENDING_NOTIFICATION(notification));
};

export const removePendingNotification = notification => dispatch => {
  dispatch(REMOVE_PENDING_NOTIFICATION(notification))
}

export const setNotificationMode = mode => dispatch => {
  dispatch(SET_NOTIFICATION_MODE(mode));
} 

export const getAllNotifications = params => dispatch => {
  axios.get(`${ep}/user/notifications`, {
    params
  }).then(notifications => {
    dispatch(GET_ALL_NOTIFICATIONS(notifications.data))
  })
}


export const setNotificationsRead = notifications => dispatch => {
  axios
    .patch(`${ep}/user/notifications/`, {
      notificationIds: notifications
    }, { params: { isLoader: true }})
    .then( res => {
      return;
    })
}