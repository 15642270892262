import { createAction } from "redux-actions";
import axios from "axios";
import { get_service_endpoint } from "../../ServiceEndpoints";
import { closeModal } from "./GlobalActions";

import _ from "lodash";
import { flash } from "./CommonActions";

import { normalizeForPatch, getMaterialInwardReqBody } from "helpers/materialHelper";

const ep = `${get_service_endpoint("apiv2")}/material`;

const GET_MATERIALS = createAction("GET_MATERIALS");
const GET_MATERIAL_VENDORS = createAction("GET_MATERIAL_VENDORS");
const GET_MATERIAL_FILTERS = createAction("GET_MATERIAL_FILTERS");
const GET_MATERIAL_OPTIONS = createAction("GET_MATERIAL_OPTIONS");
const ADD_MATERIAL_OPTION = createAction("ADD_MATERIAL_OPTION");
const SET_MATERIALS_PAGINATION = createAction("SET_MATERIALS_PAGINATION");
const ADD_MATERIAL_VENDOR = createAction("ADD_MATERIAL_VENDOR");
const GET_TRIMS_CATEGORIES = createAction("GET_TRIMS_CATEGORIES");
const GET_MATERIAL_LIBRARY_BRANDS = createAction("GET_MATERIAL_LIBRARY_BRANDS");

const convertObjectToFormData = rawJsonData => {
  let formData = new FormData();
  let files = {},
    arrays = {};

  const jsonData = normalizeForPatch(rawJsonData);
  const trimsSizes = {};
  const validTrimsizes = rawJsonData.validTrimSizes;
  let computedQuantity = 0;

  Object.keys(jsonData).forEach((k, index) => {
    if (jsonData[k] instanceof File) {
      files[k] = jsonData[k];
      return;
    } else if (jsonData[k] instanceof Array) {
      arrays[k] = jsonData[k];
      return;
    }
    if (jsonData[k] === null || k === "validTrimSizes")
      return;
    if (k.includes("quantity-")) {
      const quantity = k.split("quantity-");
      if (validTrimsizes.includes(Number(quantity[1]))) {
        trimsSizes[quantity[1]] = jsonData[k];
        computedQuantity += Number(jsonData[k]);
      }
      return;
    }
    formData.append(k, jsonData[k]);
  });

  // Send stringified arrays in formData in order to get it parsed in back-end
  Object.entries(arrays).forEach(([k, arr]) =>
    formData.append(k, JSON.stringify(arr))
  );

  if (Object.keys(trimsSizes).length > 0) {
    formData.append("trimsSizes", JSON.stringify(trimsSizes));
    formData.append("quantity", computedQuantity);
  }

  // Add files in the end of the Object
  Object.entries(files).forEach(([k, f]) => formData.append(k, f, f.name));

  return formData;
};

const validateTransformMaterialDetails = materialDetails => dispatch =>
  new Promise((resolve, reject) => {
    const formData = _.cloneDeep(materialDetails);
    if (
      (!formData.image && !(formData.image instanceof File)) ||
      !formData.colors ||
      !formData.unit
    ) {
      dispatch(flash({ message: "Required fields are empty!" }));
      reject();
      return;
    }

    if (formData.materialType === "1" && !formData.quantity) {
      if (!_.some(Object.keys(formData), key => key.includes("quantity-"))) {
        dispatch(flash({ message: "Quantity cannot be empty" }));
        reject();
        return;
      }
    }
    delete formData.fabricQuality;
    delete formData.fabricType;
    delete formData.sizes;
    delete formData.staticTrimsSize;
    resolve(formData);
  });

export const createMaterial = materialDetails => dispatch => {
  return dispatch(validateTransformMaterialDetails(materialDetails)).then(
    formData =>
      axios.post(`${ep}/`, convertObjectToFormData(formData)).then(() => {
        dispatch(flash({ message: "Material added successfully" }));
        dispatch(closeModal());
      })
  );
};

export const editMaterial = materialDetails => dispatch => {
  return dispatch(validateTransformMaterialDetails(materialDetails)).then(
    formData =>
      axios.patch(`${ep}/`, convertObjectToFormData(formData)).then(() => {
        dispatch(closeModal());
        dispatch(flash({ message: "Updated successfully" }));
      })
  );
};

export const filterMaterials = (filterData, pageOffSet = null) => (
  dispatch,
  getState
) => {
  let processedData = _.chain(filterData)
    // Convert all keys to camelCase
    .mapKeys((value, key) => _.camelCase(key))
    // Convert all Zero length arrays to null
    .mapValues((value, key) =>
      typeof value !== "string" && value !== null && value !== undefined
        ? value.length === 0
          ? null
          : value
        : value
    )
    // Get original value from Lodash wrapper
    .value();

  if (processedData["category"] && processedData["category"][0] === "null") {
    processedData["category"] = null;
  }

  if (processedData["idealFor"]) {
    const idealfor = processedData["idealFor"];
    if (idealfor.length === 1) {
      processedData["idealFor"] = idealfor[0];
    } else {
      processedData["idealFor"] = "2";
    }
  }

  // Change date formats
  // if (processedData["startDate"] !== null && processedData["endDate"] !== null) {
  //   processedData["startDate"] = moment(processedData["startDate"]).format(DATE_FORMAT);
  //   processedData["endDate"] = moment(processedData["endDate"]).format(DATE_FORMAT);
  // }

  processedData["pageOffSet"] = pageOffSet;
  processedData["numberOfRecords"] = 30;
  const temp = _.get(processedData, "stockAvailability.0", null);
  processedData["includeOutOfStock"] = temp === null ? temp : Number(temp);

  return axios
    .get(`${ep}/library`, { params: processedData })
    .then(({ data }) => {
      if (data.error) dispatch(GET_MATERIALS([]));
      else if (data instanceof Array) {
        dispatch(GET_MATERIALS(data));
        if (data.length === 0) {
          dispatch(SET_MATERIALS_PAGINATION({}));
        }
      } else {
        dispatch(GET_MATERIALS(data.materials));
        dispatch(SET_MATERIALS_PAGINATION(data.paginate));
      }
    });
};

export const getMaterialVendors = details => dispatch => {
  return axios
    .get(`${ep}/vendors`, { params: details })
    .then(({ data }) => dispatch(GET_MATERIAL_VENDORS(data)));
};

export const getMaterialFilters = ({
  brandId,
  materialType = "0",
  isSuperAdmin
}) => dispatch =>
    axios
      .get(`${ep}/filters`, {
        params: {
          brandid: brandId,
          materialType
          // startDate: startDate === null ? startDate : moment(startDate).format(DATE_FORMAT),
          // endDate: endDate === null ? endDate : moment(endDate).format(DATE_FORMAT)
        }
      })
      .then(({ data }) => {
        if (isSuperAdmin) {
          dispatch(GET_MATERIAL_LIBRARY_BRANDS(data.brands))
        }
        dispatch(GET_MATERIAL_FILTERS(data.filters));
      });

export const postMaterialVendors = details => dispatch => {
  return axios.post(`${ep}/vendor`, details).then(vendorData => {
    dispatch(ADD_MATERIAL_VENDOR(vendorData.data));
    dispatch(closeModal());
    dispatch(flash({ message: "Vendor added successfully" }));
  });
};

export const editMaterialVendors = details => dispatch => {
  return axios.patch(`${ep}/vendor`, details).then(vendorResponse => {
    dispatch(getMaterialVendors({ brandId: vendorResponse.data[0].brandId }));
    dispatch(closeModal());
    dispatch(flash({ message: "Vendor details edited successfully" }));
  });
};

export const postMaterialInventory = (details, filterList, refresh) => dispatch => {
  const body = getMaterialInwardReqBody(details);

  return axios.post(`${ep}/inwardentry`, body).then(response => {
    dispatch(closeModal());
    dispatch(flash({ message: "Material Inventory added successfully" }));
    if (refresh) {
      dispatch(filterMaterials(filterList));
    }
  });
};

export const updateMaterialInventory = (details, filterList, refresh) => dispatch => {
  const body = getMaterialInwardReqBody(details);

  return axios.patch(`${ep}/inwardentry`, body).then(response => {
    dispatch(closeModal());
    dispatch(flash({ message: "Material Inventory updated successfully" }));
    if (refresh) {
      dispatch(filterMaterials(filterList));
    }
  });
};

export const postMaterialOutwards = (details, filterList) => dispatch => {
  let formData = new FormData();
  let file = {};

  Object.keys(details).forEach((k, index) => {
    if (details[k] instanceof File) {
      file[k] = details[k];
      return;
    }
    formData.append(k, details[k]);
  });

  Object.entries(file).forEach(([k, f]) => formData.append(k, f, f.name));

  return axios.post(`${ep}/outwardentry`, formData).then(response => {
    dispatch(closeModal());
    dispatch(flash({ message: "Material assigned to factory successfully" }));
    if (!response.data.inStock) {
      dispatch(filterMaterials(filterList));
    }
  });
};

export const postMaterialNote = details => dispatch => {
  return axios.post(`${ep}/note`, details).then(response => {
    dispatch(closeModal());
  });
};

export const deleteMaterial = (details, filterList, paginate) => dispatch => {
  axios.delete(`${ep}/`, { data: details }).then(() => {
    dispatch(closeModal());
    dispatch(flash({ message: "Material deleted successfully" }));
    dispatch(filterMaterials(filterList, paginate));
  });
};


export const getTrimsCategories = () => dispatch =>
  axios
    .get(`${ep}/trimscategory`)
    .then(({ data }) => dispatch(GET_TRIMS_CATEGORIES(data)));


export const getMaterialOptions = ({ brandId }) => dispatch =>
  axios
    .get(`${ep}/options`, {
      params: {
        types: 'fabricComposition,pattern,weave',
        brandId
      }
    })
    .then(({ data }) => dispatch(GET_MATERIAL_OPTIONS(data)));

export const createMaterialOptions = ({ type, label, brandId }) => dispatch =>
  axios
    .post(`${ep}/option`, { type, label/* , brandId */ })
    .then(({ data }) => {
      return Promise.resolve(dispatch(ADD_MATERIAL_OPTION({ type, label, value: data.id })))
        .then(() => data);
    });
