import React, { Component } from "react";
import { Link, Route, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import { getCurrentUserRole } from "../../helpers/AuthHelper";
import { CLIENT_SUPER, LOCO_SUPER, CLIENT } from "../../constants/Auth";
import { connect } from "react-redux";

import InternalSidebar from "./../../components/UI/InternalSidebar/InternalSidebar";
import PrivateRoute from "./../../components/UI/PrivateRoute/PrivateRoute";
import PrivateContainer from "./../../components/UI/PrivateContainer/PrivateContainer";
import CostingReport from "../../components/Reports/Costing";
import SamplingReport from "../../components/Reports/Sampling";
import ProductionReport from "../../components/Reports/Production";
import ActiveFactoriesReport from "../../components/Reports/ActiveFactories";
import FactoryPerformance from "../../components/Reports/FactoryPerformance";
import BuyerPerformance from "../../components/Reports/BuyerPerformance";
import OrderReport from "../../components/Reports/Orders";
import DesignReport from "../../components/Reports/Design";
import MaterialReport from "../../components/Reports/Material";
import { PAGE_TITLES } from "../../constants/Titles";

class ReportsContainer extends Component {
  sideNavs = [
    {
      name: "Costing Report",
      link: "costing/",
      userRoles: [LOCO_SUPER, CLIENT_SUPER, CLIENT]
    },
    {
      name: "Sampling Report",
      link: "sampling/",
      baseRoute: "/reports/sampling",
      userRoles: [LOCO_SUPER, CLIENT_SUPER, CLIENT]
    },
    {
      name: "Production Report",
      link: "production/",
      baseRoute: "/reports/production",
      userRoles: [LOCO_SUPER, CLIENT_SUPER, CLIENT]
    },
    {
      name: "Active Factories",
      link: "factories/",
      userRoles: [LOCO_SUPER, CLIENT_SUPER, CLIENT]
    },
    {
      name: "Factory Performance",
      link: "performance/factory/",
      userRoles: [LOCO_SUPER, CLIENT_SUPER, CLIENT]
    },
    {
      name: "Buyer Performance",
      link: "performance/buyer/",
      userRoles: [LOCO_SUPER, CLIENT_SUPER]
    },
    {
      name: "Order Report",
      link: "order/",
      userRoles: [LOCO_SUPER, CLIENT_SUPER, CLIENT]
    },
    {
      name: "Design Report",
      link: "design/",
      userRoles: [LOCO_SUPER, CLIENT_SUPER, CLIENT],
    },
    {
      name: "Material Report",
      link: "material/",
      userRoles: [LOCO_SUPER, CLIENT_SUPER, CLIENT],
      feature: 'materialLibrary'
    }
  ];

  isCurrentTab(link) {
    return new RegExp(`/[^/]+/${link}`).test(this.props.location.pathname);
  }

  renderSideNavs = () => {
    const { brandFeatures, userDetails } = this.props;
    const { sideNavs } = this;
    const renderedSideNavs = sideNavs
      .filter(nav => !nav.feature || (brandFeatures && brandFeatures[nav.feature]) || userDetails.isSuperAdmin)
      .map(nav => (
        <PrivateContainer allowRoles={nav.userRoles} key={nav.link}>
          <Link to={this.props.match.path + nav.link}>
            <div
              className={`account-settings__item f-sz-l f-w-semi-bold ${
                this.isCurrentTab(nav.link) ? "current_tab" : ""
                }`}
            >
              {nav.icon ? <nav.icon className="nav-logo" /> : null}
              <span className="nav-name">{nav.name}</span>
            </div>
          </Link>
        </PrivateContainer>
      ));

    const currentUserRole = getCurrentUserRole();
    const firstSideNav = sideNavs.find(nav =>
      nav.userRoles.includes(currentUserRole)
    );
    const firstLink = (firstSideNav && firstSideNav.link) || "profile";

    return [renderedSideNavs, firstLink];
  };

  render() {
    const [renderedSideNavs, firstLink] = this.renderSideNavs();
    const { brandsList, userDetails, brandFeatures } = this.props;

    const isMaterialLibraryEnabled = (brandFeatures && brandFeatures.materialLibrary)
      || userDetails.isSuperAdmin
      || false;

    return (
      <div className="reports__container">
        <Helmet
          bodyAttributes={{
            class: "body_container"
          }}
          title={PAGE_TITLES.reports('--')}
        />
        <div className="flex">
          <InternalSidebar>{renderedSideNavs}</InternalSidebar>
          <div className="reports__content">
            <Route
              exact
              path={this.props.match.path}
              render={routeProps => {
                return (
                  <Redirect
                    {...routeProps}
                    to={this.props.match.path + firstLink}
                  />
                );
              }}
            />
            <Route
              path={this.props.match.path + "costing/"}
              state="Costing Report"
              render={routeProps => {
                return (
                  <React.Fragment>
                    <Helmet title={PAGE_TITLES.reports("Costing Report")} />
                    <CostingReport brandsList={brandsList} isBrandManager={userDetails.role === CLIENT} />
                  </React.Fragment>
                );
              }}
            />

            <PrivateRoute
              exact
              path={this.props.match.path + "sampling/"}
              userRoles={[CLIENT_SUPER, LOCO_SUPER, CLIENT]}
              render={routeProps => (
                <React.Fragment>
                  <Helmet title={PAGE_TITLES.reports("Sampling Report")} />
                  <SamplingReport brandsList={brandsList} isBrandManager={userDetails.role === CLIENT} />
                </React.Fragment>
              )}
            />

            <PrivateRoute
              path={this.props.match.path + "production/"}
              userRoles={[CLIENT_SUPER, LOCO_SUPER, CLIENT]}
              render={routeProps => (
                <React.Fragment>
                  <Helmet title={PAGE_TITLES.reports("Production Report")} />
                  <ProductionReport brandsList={brandsList} isBrandManager={userDetails.role === CLIENT} />
                </React.Fragment>
              )}
            />

            <PrivateRoute
              exact
              path={this.props.match.path + "factories/"}
              render={routeProps => (
                <React.Fragment>
                  <Helmet
                    title={PAGE_TITLES.reports("Active factories Report")}
                  />
                  <ActiveFactoriesReport brandsList={brandsList} isBrandManager={userDetails.role === CLIENT} />
                </React.Fragment>
              )}
            />

            <PrivateRoute
              path={this.props.match.path + "performance/factory/"}
              render={routeProps => (
                <React.Fragment>
                  <Helmet title={PAGE_TITLES.reports("Factory Performance")} />
                  <FactoryPerformance brandsList={brandsList} isBrandManager={userDetails.role === CLIENT} />
                </React.Fragment>
              )}
            />

            <PrivateRoute
              path={this.props.match.path + "performance/buyer/"}
              render={routeProps => (
                <React.Fragment>
                  <Helmet title={PAGE_TITLES.reports("Buyer Performance")} />
                  <BuyerPerformance brandsList={brandsList} isBrandManager={userDetails.role === CLIENT} />
                </React.Fragment>
              )}
            />

            <PrivateRoute
              exact
              path={this.props.match.path + "order/"}
              render={routeProps => (
                <React.Fragment>
                  <Helmet title={PAGE_TITLES.reports("Order Report")} />
                  <OrderReport brandsList={brandsList} isBrandManager={userDetails.role === CLIENT} />
                </React.Fragment>
              )}
            />

            <PrivateRoute
              exact
              path={this.props.match.path + "design/"}
              render={routeProps => (
                <React.Fragment>
                  <Helmet title={PAGE_TITLES.reports("Designs Report")} />
                  <DesignReport brandsList={brandsList} isBrandManager={userDetails.role === CLIENT} />
                </React.Fragment>
              )}
            />

            {isMaterialLibraryEnabled && <>
              <Route
                exact
                path={this.props.match.path + "material/"}
                render={routeProps => {
                  return (
                    <Redirect
                      {...routeProps}
                      to={this.props.match.path + "material/inventory"}
                    />
                  );
                }}
              />
              <PrivateRoute
                exact
                path={this.props.match.path + "material/:materialReportType(inventory|stockio)"}
                render={routeProps => (
                  <React.Fragment>
                    <Helmet title={PAGE_TITLES.reports("Material Report")} />
                    <MaterialReport
                      brandsList={brandsList}
                      isBrandManager={userDetails.role === CLIENT}
                      reportType={routeProps.match.params.materialReportType.toLowerCase()} />
                  </React.Fragment>
                )}
              />
            </>}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  brandsList: Object.values(state.factory.brandsList).map(brand => ({
    value: brand.id,
    label: brand.name
  })),
  userDetails: state.auth.userDetails,
  brandFeatures: state.brands.brandFeatures,
});

export default connect(mapStateToProps, null)(ReportsContainer);
