import { truncate } from 'lodash';
import React, { useCallback } from "react";
import OrgTable from "../../OrgSettings/Mills/OrgTable";
import OrgRowDetails from "../OrgRowDetails";
import CertifiedTick from '../../../../assets/CertifiedTick.svg'

const Table = ({
  path,
  data,
  users,
  accountManagers,
  onOrgEdit = (org) => null,
  onOrgExpandChange = (org, isExpanded) => null,
  onEditUser = (org, user) => null,
  onUnassignAM = (org, user) => null,
  onAddUser = (org) => null,
  onAssignAM = (org) => null,
  queryParams,
}) => {
  const rowRenderer = useCallback((org) => {
    return (
      <>
        <div>
          <div>
            <div className='text-center'>
              <span>
                {truncate(org.supplierName, {
                  length: 20
                })}
              </span>
              {
                org.isCertified ? <span className='ml-1'>
                <img src={CertifiedTick} alt='certified vendor'/>
               </span> : ''
              }
            </div>
            <div className='text-center' style={{ fontSize: "10px" }}>
              {org.aliasName}
            </div>
          </div>
        </div>
        <div>
          {org.fabricType === 1 ? 'Knits' : 2 ? 'Woven' : 'Knits, Woven'}
        </div>
        <div>
          {org.fabricProductionServices.map((data, index) => (
            <div>
              {index ? "," : ""} {data}
            </div>
          ))}
        </div>

        <div style={{ display: "flex", flexDirection: "column" }}>
          {org?.allocatedSm?.map((sm) => (
            <div>
              {sm.fabricCategory.map(
                (fab, index) => (index ? " " : "") + fab.category
              )}
              : {sm.supplierManagerName}
            </div>
          ))}
        </div>

        <div className='activeOrders'>{org.activeOrdersCount}</div>
        <div>{org.isVerified ? "Verified" : "Unverified"}</div>
      </>
    );
  }, []);

  const rowDetailsRenderer = useCallback(
    (org) => {
      return (
        <>
          <OrgRowDetails
            org={org}
            orgUsers={users[org.id]}
            accountManagers={accountManagers[org.id]}
            onEditUser={onEditUser}
            onUnassignAM={onUnassignAM}
            onAddUser={onAddUser}
            onAssignAM={onAssignAM}
          />
        </>
      );
    },
    [accountManagers, onAddUser, onAssignAM, onEditUser, onUnassignAM, users]
  );

  return (
    <OrgTable
      className="mills-org-table"
      headers={[
        "Mill Name",
        "Fabric Type",
        "Services",
        "Supplier Manager/s",
        "Active Orders",
        "Mill State",
      ]}
      data={data}
      gridTemplateColumns="1fr 1fr 1fr 1fr 1fr"
      rowRenderer={rowRenderer}
      rowDetailsRenderer={rowDetailsRenderer}
      onOrgEdit={onOrgEdit}
      onOrgExpandChange={onOrgExpandChange}
      path={path}
      queryParams={queryParams}
    />
  );
};

export default Table;
