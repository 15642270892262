import axios from "axios";
import { get_service_endpoint } from "ServiceEndpoints";
import find from "lodash/find";

const endpoint = get_service_endpoint("apiv2");

const categoryTypeToNumber = {
  "Prototype/Fit Sample": "0",
  "Size-set Sample": "1",
  "Preproduction Sample": "2",
  "Top of Bulk (TOB) Sample": "3",
  Production: "4"
};

export const allocateFactory = (
  samplingData,
  formValues,
  commonData,
  factories
) => {
  // id denotes the sampleRootId
  const {
    designId,
    sampleType,
    newSampleId,
    lfrootAddressId,
    sampleStatus
  } = samplingData;
  const { factoryId, dueDate } = formValues;
  const { brandId, collectionId } = commonData;

  if (factoryId) {
    const requestObj = {
      brandId: brandId.value,
      factoryId,
      collection: collectionId.value,
      lfrootAddressId: factories ? find(factories, { id: factoryId })["addresses"][0].id : lfrootAddressId,
      designId,
      category: categoryTypeToNumber[sampleType],
      designSampleDetailsId: newSampleId
    };
    axios.post(`${endpoint}/factory/collections`, requestObj).then(res => {
      axios
        .patch(`${endpoint}/sample`, {
          id: newSampleId,
          dueDate,
          designId,
          sampleStatus,
          sampleType
        })
        .then(result => {})
        .catch(err => {
          console.log(err);
        });
    });
  }
};
