import React, { Component } from "react";

import "./styles.scss";
import Button from "components/UI/Button/Button";
import alertIcon from "assets/images/communication/ic-alert.svg";

class SubmitTNAConfirmation extends Component {
  render() {
    const { closeModal, handleSubmit } = this.props;

    return (
      <div className="tna-submit-modal">
        <img src={alertIcon} className="alert-icon" alt="alert" />
        <div className="f-sz-14 content">
          <h3>Submit TNA</h3>
          <p>
            You won't be able to make changes once submitted. Are you sure you
            want to continue?
          </p>
        </div>
        <div className="submit-tna-footer">
          <footer>
            <Button
              category="btn black-ghost medium"
              onClick={() => {
                closeModal();
              }}
            >
              CANCEL
            </Button>

            <Button
              category="btn primary-blue"
              onClick={() => {
                handleSubmit();
                closeModal();
              }}
            >
              SUBMIT TNA
            </Button>
          </footer>
        </div>
      </div>
    );
  }
}

export default SubmitTNAConfirmation;
