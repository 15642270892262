import React, { Component } from "react";
import PropTypes from "prop-types";
import Table from "../../components/UI/Table/CustomTable";
import moment from "moment";
import "./SamplingHistoryModal.scss";
import { TIMESTAMP_FORMAT, DATESTAMP_FORMAT } from "./../../constants/Date";
import _ from 'lodash';

export default class SamplingHistoryModal extends Component {
  static propTypes = {
    samplingHistory: PropTypes.object,
  };
  state = {
    hoverRow: ""
  };

  render() {
    const versions = this.props.samplingHistory;
    const isBrand = this.props.isBrand || false;

    let headers = ["Sample type", "Factory", "Time taken"];
    if(isBrand){
      headers = ["Sample type", "Time taken"]
    }
    return (
      <div className="sample-version-history">
        <Table className="sample-version-history__table" headers={headers}>
          {_.map(versions, (version => (
            <tr
              onMouseEnter={() => this.setState({ hoverRow: version })}
              onMouseLeave={() => this.setState({ hoverRow: null })}
            >
              
              <td>
                <div className="f-sz-l f-w-semi-bold" style={{ textTransform: "capitalize", lineHeight: '24px' }}>
                  {version['name']}                
                </div>
                <div className="f-color-faded">
                  Approved on {moment(version['approved_on']).format(DATESTAMP_FORMAT)}
                </div>
              </td>

              {
                !isBrand && <td>
                  <div className="f-sz-l f-w-semi-bold" style={{ textTransform: "capitalize", lineHeight: '24px' }}>
                    {version['factoryName']}
                  </div>
                </td>
              }
              

              <td>
                <div className="f-sz-l f-w-semi-bold" style={{ textTransform: "capitalize", lineHeight: '24px' }}>
                  {version['daysTaken']} days                
                </div>
                <div className="f-color-faded">
                  {version["attempts"]} attempts
                </div>
              </td>
             
            </tr>
          )))}
        </Table>
      </div>
    );
  }
}
