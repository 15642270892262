import React, { useState, useEffect } from "react";
import PerfectScrollBar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
import _ from "lodash";
import { Button, Modal, Radio, Switch, Select } from "antd";
import * as qs from 'query-string'

import './styles.scss';

import icNavLeft from './../../../../assets/images/navigation/ic-chevron-left.svg';
import { connect } from 'react-redux';
import {
  changeMillStatus,
  changeLCVStatus,
  getMillDetails,
  getMills,
  deactivateMill,
  updateAllocatedSM as updateAllocatedSMAction,
} from 'store/actions/UserActions';
import { DeactivateMillModal } from './EditMillModal';
import Sections from './MillDetailSections';
import { getLocofastUsers as getLocofastUsersAction } from 'store/actions/AuthActions';
import StatusToggler from 'components/UI/Toggler/StatusToggler';
import { flash } from 'store/actions/CommonActions';

const EditMill = ({
  initialValues = {},
  mills,
  getMills,
  getMillDetails,
  millDetails,
  match,
  changeMillStatus,
  changeLCVStatus,
  deactivateMill,
  getLocofastUsers,
  updateAllocatedSM,
  lfUsers,
  showErrorMessage,
  ...restProps
}) => {
  const [deactivateModal, setDeactivateModal] = useState(false);
  const [editSM, setEditSM] = useState('');
  const [SMDetails, setSMDetails] = useState({});
  const [contactPerson, setContactPerson] = useState('');
  const [millData, setMillData] = useState({});
  const [isNotFoundError, setIsNotFoundError] = useState(false);
  const id = match.params.id;
  const { Option } = Select
  const queryParams = qs.parse(restProps.location.search)

  const handleActivate = () => {
    if(millData?.isActive === 'Y'){
      setDeactivateModal(!deactivateModal);
    } else {
      deactivateMill({
        supplierId: millData?.supplierId.toString(),
        newStatus: 'activate',
      });
    }
  };

  const handleStatusChange = (isOnline) => {
    const status = isOnline ? 1 : 0;
    const supplierId = millData?.supplierId;
    changeMillStatus({ supplierId, status });
  };

  const handleEditSM = (data) => {
    setSMDetails(data);
    setEditSM(data.id);
  };

  const handleUpdateSM = (data) => {
    const fabricCategoryId = SMDetails?.category[0]?.id;
    const managerId = SMDetails?.id;
    const supplierId = millData?.supplierId;
    updateAllocatedSM({ fabricCategoryId, managerId, supplierId });
    setEditSM('');
  };

  const handleLCVChange = (status, annualTurnover) => {
    const isCertified = status ? 1 : 0;
    const supplierId = millData?.supplierId;
    if (annualTurnover) {
      changeLCVStatus({ supplierId, isCertified });
    } else {
      showErrorMessage('Please upload annual turnover details');
    }
  };

  useEffect(() => {
    if (millDetails?.data.id) {
      setIsNotFoundError('');
      setContactPerson(millDetails?.data.firstName + ' ' + (millDetails?.data.lastName || ''));
      setMillData(millDetails?.data);
    }
    if (millDetails?.error) {
      setIsNotFoundError(millDetails?.error);
    }
  }, [millDetails]);

  useEffect(() => {
    setMillData({})
    getMillDetails(id);
    getLocofastUsers(['13']);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const { annualTurnover = null } = millData;
  return (
    <div className="factory-add-edit-container">
      <PerfectScrollBar style={{ maxHeight: '900px' }}>
        <div className="factory-container">
          <header>
            <div className="navContainer">
              <div className="flex-column">
                <div className="mill-heading">Mill Details</div>
                <div className="leftNavBtns">
                  <Link className="nav-left" to={`../${queryParams.search ? `?search=${queryParams.search}&page=${queryParams.page}` : ''}`}>
                    <img src={icNavLeft} alt="nav-left" />
                  </Link>
                  <div className="mill-heading-details">
                    <h4>{millData?.companyName}</h4>
                    <p>{millData?.alias}</p>

                    {millData?.deactivationReason && (
                      <p className="red-message">
                        Deactivation Reason: {millData?.deactivationReason}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="rightNavBtns">
                <div className="mt-5">
                  {millData?.supplierManager?.map((sm) => (
                    <div className="mr-1">
                      <span>
                        {sm?.fabricCategory?.map((data) => data.category + ' ')}: {sm?.name + ' '}
                      </span>
                      <button
                        className="millEditBtn"
                        onClick={() =>
                          handleEditSM({
                            id: sm.id,
                            category: sm?.fabricCategory,
                          })
                        }>
                        edit
                      </button>
                    </div>
                  ))}
                </div>
                <div className='millDetailsToggles'>
                  <StatusToggler
                    label={'Mill Status'}
                    condition={millData?.isOnline}
                    onFalseLabel={'Offline'}
                    onTrueLabel={'Online'}
                    onChange={handleStatusChange}
                  />
                  <StatusToggler
                    label={'Locofast Certified Vendor'}
                    condition={millData?.isCertified}
                    onFalseLabel={'No'}
                    onTrueLabel={'Yes'}
                    onChange={(status) => handleLCVChange(status, annualTurnover)}
                  />
                  <StatusToggler
                    label={'Status'}
                    condition={millData?.isActive === "Y"}
                    onFalseLabel={'Deactivate'}
                    onTrueLabel={'Activate'}
                    onChange={() => handleActivate()}
                  />
                </div>
              </div>
            </div>
          </header>
          {isNotFoundError || !millData?.id ? (
            <section className="details-card details-error">
              <div className="error">Mill doesn't exist !!</div>
            </section>
          ) : (
            <>
              <DeactivateMillModal
                visible={deactivateModal}
                toggle={setDeactivateModal}
                data={millData}
                action={deactivateMill}
              />
              <Sections millData={millData} contactPerson={contactPerson} />
            </>
          )}
        </div>
      </PerfectScrollBar>
      <div>
        <Modal
          title={'Select New Supplier Manager'}
          visible={editSM}
          // onOk={handleSubmit}
          onCancel={() => setEditSM('')}
          okButtonProps={{ disabled: true }}
          cancelButtonProps={{ disabled: false }}
          footer={[
            <Button key="back" onClick={() => setEditSM('')}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              role="submit"
              onClick={handleUpdateSM}
              disabled={editSM == SMDetails?.id}>
              Update
            </Button>,
          ]}>
          <>
            <h3>
              Category:{' '}
              {SMDetails?.category?.map((data) => (
                <span>{data.category + ' '}</span>
              ))}
            </h3>
            <Select
              placeholder="Select SM"
              optionFilterProp="children"
              showSearch={true}
              value={SMDetails.id}
              onChange={(e) => setSMDetails({...SMDetails, id: e})}
              className="dropdown-select"
            >
              {lfUsers?.length && lfUsers.map((user) => (
                <Option key={user.id} value={user.id}>
                  {`${user.firstName} ${user.lastName}`}
                </Option>
              ))}
            </Select>
          </>
        </Modal>
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  mills: props.users || state.user.mills,
  millDetails: state.user.millDetails,
  lfUsers: state.auth.lfUsers,
});

const mapDispatchToProps = (dispatch) => ({
  getMills: () => dispatch(getMills()),
  showErrorMessage: (message) => dispatch(flash({ message })),
  getMillDetails: (id) => dispatch(getMillDetails(id)),
  changeMillStatus: (data) => dispatch(changeMillStatus(data)),
  changeLCVStatus: (data) => dispatch(changeLCVStatus(data)),
  deactivateMill: (data) => dispatch(deactivateMill(data)),
  getLocofastUsers: (data) => dispatch(getLocofastUsersAction(data)),
  updateAllocatedSM: (data) => dispatch(updateAllocatedSMAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditMill);
