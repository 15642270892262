import { createAction } from "redux-actions";

import axios from "axios";

import { get_service_endpoint } from "../../ServiceEndpoints";
import {
  withFactory
} from "./../../helpers/requestManager";

const eq = get_service_endpoint("notificationView");

const PRODUCTS_GET_DESIGNS = createAction("PRODUCTS_GET_DESIGNS");
const PRODUCTS_GET_ALL_BRANDS = createAction("PRODUCTS_GET_ALL_BRANDS");
const PRODUCTS_SELECTED_COLLECTIONS = createAction(
  "PRODUCTS_SELECTED_COLLECTIONS"
);

const ORDERS_UPDATE_SINGLE_ORDER = createAction("ORDERS_UPDATE_SINGLE_ORDER");

const SAMPLING_TRACKER_GET_ALL_SAMPLES = createAction(
  "SAMPLING_TRACKER_GET_ALL_SAMPLES"
);
const CHNAGE_ACTIVE_TAB_IN_TRACKER = createAction(
  "CHNAGE_ACTIVE_TAB_IN_TRACKER"
);

const DISPATCH_DETAILS_UPDATED_IN_SAMPLING_TRACKER = createAction(
  "DISPATCH_DETAILS_UPDATED_IN_SAMPLING_TRACKER"
);

const GET_ALL_PRODUCTS_FOR_PRODUCTION_TRACKER = createAction(
  "GET_ALL_PRODUCTS_FOR_PRODUCTION_TRACKER"
);

export const getAllDesigns = params => dispatch => {
  axios
    .get(`${eq}/orders/products`, { params })
    .then(res => {
      const { brandid, col, st, qt, isnext } = params;
      let refresh = false;

      if (((brandid || col || st || qt) && !isnext) || !isnext) refresh = true; // this refreshes with new chunck of data

      return dispatch(
        PRODUCTS_GET_DESIGNS({
          data: res.data,
          refresh
        })
      );
    })
    .catch();
  // was returning here () => GET_DESIGNS(wrong)
};

export const getAllBrands = params => dispatch => {
  return axios
    .get(`${eq}/users/getallbrands`)
    .then(res => {
      return dispatch(PRODUCTS_GET_ALL_BRANDS(res.data));
    })
    .catch();
};

export const updateSingleOrder = order => dispatch => {
  return dispatch(ORDERS_UPDATE_SINGLE_ORDER(order));
};

export const saveSelecteDesigns = params => dispatch => {
  return dispatch(PRODUCTS_SELECTED_COLLECTIONS(params));
};

export const getAllSamplesForSampleTracker = (params = {}) => dispatch => {
  const computedParams = withFactory(params, 'factory');
  axios.get(`${eq}/orders/sampletracker`, { params: computedParams }).then(res => {
    const { refresh = false } = params;

    return dispatch(
      SAMPLING_TRACKER_GET_ALL_SAMPLES({
        data: res.data,
        refresh
      })
    );
  });
};

export const dispatchDeatilsUpdatedInSaplinTracker = data => dispatch => {
  dispatch(DISPATCH_DETAILS_UPDATED_IN_SAMPLING_TRACKER({ data }));
};

export const getAllProductsForProductionTracker = (params = {}) => (dispatch, state) => {
  const computedParams = withFactory(params, 'factory');
  axios.get(`${eq}/orders/productiontracker`, { params: computedParams }).then(res => {
    const { refresh = false } = params;

    return dispatch(
      GET_ALL_PRODUCTS_FOR_PRODUCTION_TRACKER({
        data: res.data,
        refresh //this has to be added ***
      })
    );
  });
};

export const changeTrackerTab = params => dispatch => {
  dispatch(
    CHNAGE_ACTIVE_TAB_IN_TRACKER({
      activeTab: params.activeTab
    })
  );
};
