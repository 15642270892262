import { createAction } from "redux-actions";
import axios from "axios";
import { get_service_endpoint } from "../../ServiceEndpoints";

const eq = get_service_endpoint("notificationView");

const QUOTES_GET_ALL_QUOTES = createAction("QUOTES_GET_ALL_QUOTES");
const QUOTES_GET_COUNT = createAction("QUOTES_GET_COUNT");
const QUOTES_FACTORIES = createAction("QUOTES_FACTORIES");

export const getAllQuotes = (params = {}) => dispatch => {
  axios
    .get(`${eq}/quotes`, {
      params: {
        ...params,
        offset: params.offset ? params.offset : null,
        quoteStatuses: (Array.isArray(params.quoteStatuses) && params.quoteStatuses.length > 0
          ? params.quoteStatuses.join(',') : null),
        brandIds: (Array.isArray(params.brandIds) && params.brandIds.length > 0
          ? params.brandIds.join(',') : null),
        factoryIds: (Array.isArray(params.factoryIds) && params.factoryIds.length > 0
          ? params.factoryIds.map(item => item + '').join(',') : null),
      }
    })
    .then(res => {
      return dispatch(
        QUOTES_GET_ALL_QUOTES({
          ...res.data,
          refresh: !params.offset,
        })
      );
    });
};

export const getQuotesCount = (params = {}) => dispatch => {
  axios
    .get(`${eq}/quotes/count`, {
      params: {
        ...params,
        brandIds: (Array.isArray(params.brandIds) && params.brandIds.length > 0
          ? params.brandIds.join(',') : null),
        factoryIds: (Array.isArray(params.factoryIds) && params.factoryIds.length > 0
          ? params.factoryIds.map(item => item + '').join(',') : null),
        groupByKey: 'status',
      }
    })
    .then(res => {
      return dispatch(QUOTES_GET_COUNT(res.data));
    });
}

export const getAllQuotesFactories = () => dispatch => {
  axios
    .get(`${eq}/quotes/factories`)
    .then(res => {
      return dispatch(QUOTES_FACTORIES(res.data));
    });
}
