import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

import CollectionList from "../../components/Collections/CollectionList/CollectionList";

import {
  LOCO_SUPER,
  PRODUCTION_MERCHANT,
  PRODUCT_DEVELOPMENT,
  FACTORY_ADMIN,
  FACTORY_MANAGER,
} from "../../constants/Auth";
import { PAGE_TITLES } from "../../constants/Titles";

import actions from "../../store/actions";
import { computePath } from "../../helpers/pathHelper";
import { getCurrentUserRole } from "../../helpers/AuthHelper";

class FactoryCollectionListContainer extends Component {

  componentDidMount() {
    this.loadCollectionList();
  }

  loadCollectionList = () =>
    this.loadCollections().then((collectionIds) =>
      this.props.getDesignSummariesByCollection({
        collectionIds: collectionIds,
        factoryId: this.props.currentBrand.brandId
      })
    );

  reloadDesigns = () => {
    this.props.getDesignSummariesByCollection({
      collectionIds: Object.values(this.props.collections.list).map(
        (c) => c.id
      ),
    });
  };

  // Returns an array of all the collectionIds
  loadCollections = () => {
    const { userDetails, getCollectionsByBrandId, match: { params } } = this.props;
    const { role } = userDetails;
    const lfRoles = [
      LOCO_SUPER,
      PRODUCTION_MERCHANT,
      PRODUCT_DEVELOPMENT,
      FACTORY_ADMIN,
      FACTORY_MANAGER,
    ];
    if (lfRoles.includes(role)) {
      return getCollectionsByBrandId({ factoryId: params.brand_id })
        .then((data) => Object.keys(data));
    }

    return this.props
      .getAllCollections()
      .then((data) => Object.values(data).map((c) => c.id));
  };

  goToBrand = (id) => {
    const { history } = this.props;
    const path = computePath();
    history.push(`/${path}/brands/${id}/collections`);
  };

  render() {
    const { brandsList, currentBrand, factories } = this.props;
    return (
      <React.Fragment>
        <Helmet
          bodyAttributes={{
            class: "body_container",
          }}
          title={PAGE_TITLES.collections()}
        />
        <CollectionList
          designList={this.props.designList}
          brands={brandsList}
          factories={factories}
          goToBrand={this.goToBrand}
          currentBrand={currentBrand}
          openNewDesignModal={(collectionId) =>
            this.props.openModal("NEW_DESIGN", {
              hiddenFields: [
                {
                  name: "collection_id",
                  value: collectionId,
                },
                {
                  name: "brandId",
                  value: this.props.match.params.brand_id,
                },
              ],
              afterSubmit: () => this.reloadDesigns(),
            })
          }
          openNewCollectionModal={() =>
            this.props.openModal("NEW_COLLECTION", {
              hiddenFields: [
                {
                  name: "brandId",
                  value: this.props.match.params.brand_id,
                },
              ],
              afterSubmit: () => this.loadCollectionList(),
            })
          }
          userDetails={this.props.userDetails}
          userRole={getCurrentUserRole()}
          collections={this.props.collections.list}
          createCollection={this.props.createCollection}
          openModal={this.props.openModal}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  collections: state.collections,
  userDetails: state.auth.userDetails,
  designList: state.designs.summaries,
  brandsList: state.factory.brandsList,
  currentBrand: state.notificationView.currentBrand,
  factories: state.factory.factories,
});

const mapDispatchToProps = (dispatch) => ({
  openModal: (content, additionalProps) =>
    dispatch(actions.openModal(content, additionalProps)),
  getAllCollections: () => dispatch(actions.getAllCollections()),
  getDesignSummariesByCollection: (details) =>
    dispatch(actions.getDesignSummariesByCollection(details)),
  getCollectionsByBrandId: (details) =>
    dispatch(actions.getCollectionsByBrandId(details)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FactoryCollectionListContainer)
);
