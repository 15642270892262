import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Row, Col, Radio, Input, Button, Divider, Modal } from "antd";
import "react-phone-number-input/style.css";
import { isValidPhoneNumber } from "react-phone-number-input";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';
import { getFileName } from "helpers/getNameFromUrl";
import "./styles.scss";
import FileUpload from "components/HOCs/FileUpload";
import { BUSINESS_NATURE, BUSINESS_NATURE_TYPE, MANUFACTURER_TYPE_VALUE, TRADER_TYPE_VALUE } from "../../constants";
import { phoneNumberFormatter } from 'helpers/phoneNumberTransformer';

const BASIC_INFO = Yup.object().shape({
  firstName: Yup.string().trim().min(1).max(100).required("Required"),
  lastName: Yup.string().trim().min(1).max(100).notRequired(),
  designation: Yup.string().trim().min(1).max(100).notRequired(),
  email: Yup.string().trim().email().max(100).required("Required"),
  website: Yup.string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Enter correct url!"
    )
    .notRequired(),
  gstCertificate: Yup.mixed().when("isInternational", {
    is: (value) => value,
    then: Yup.mixed().notRequired(),
    otherwise: Yup.mixed().required("Required"),
  }),
  phoneNumber: Yup.string().required("Required"),
  uniqueTaxDocId: Yup.string().when("isInternational", {
    is: (value) => value,
    then: Yup.string().required("Required"),
    otherwise: Yup.string().notRequired(),
  }),
  uniqueTaxDocName: Yup.string().when("isInternational", {
    is: (value) => value,
    then: Yup.string().required("Required"),
    otherwise: Yup.string().notRequired(),
  }),
  uniqueTaxIdCertificate: Yup.mixed().when("isInternational", {
    is: (value) => value,
    then: Yup.mixed().required("Required"),
    otherwise: Yup.mixed().notRequired(),
  }),
  natureOfBusiness: Yup.number().oneOf(Object.values(BUSINESS_NATURE_TYPE)).required("Required"),
});

const BasicInfo = ({ customer, updateBasicInfo }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [fileGST, setFileGST] = useState("");
  const [fileTAX, setFileTAX] = useState("");
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = (values, isValid, isSubmitting) => {
    if (isValid && !isSubmitting) {
      updateBasicInfo({ id: customer?.id, params: values });
      setIsModalVisible(false);
    }
  };
  const handleCancel = (setValues, initialValues) => {
    setValues({ ...initialValues });
    setFileGST("");
    setFileTAX("");
    setIsModalVisible(false);
  };

  useEffect(() => {
    setInitialValues({
      firstName: customer?.contactPersonFirstName || "",
      lastName: customer?.contactPersonLastName || "",
      designation: customer?.designation || "",
      email: customer?.email || "",
      website: customer?.website || "",
      phoneNumber: customer?.phone || "",
      gstCertificate: customer?.gstCertificate || "",
      uniqueTaxDocId: customer?.uniqueTaxDocId || "",
      uniqueTaxDocName: customer?.uniqueTaxDocName || "",
      uniqueTaxIdCertificate: customer?.uniqueTaxIdCertificate || "",
      isInternational: customer?.isInternational || false,
      natureOfBusiness: customer?.natureOfBusiness || "",
    });
  }, [customer]);

  const {contactPersonFirstName="", contactPersonLastName=""} = customer;
  return (
    <>
      <div className="basicInfocontainer">
        <Formik
          initialValues={initialValues}
          validationSchema={BASIC_INFO}
          enableReinitialize
          validateOnMount={true}
        >
          {({
            dirty,
            errors,
            touched,
            values,
            isValid,
            isSubmitting,
            setValues,
            setFieldValue,
            setFieldError,
          }) => (
            <>
              <div className="basicHeader">
                <div>Basic Information</div>
                <Button type="link" onClick={showModal}>
                  Edit
                </Button>
                <Modal
                  title="Edit Basic Information"
                  visible={isModalVisible}
                  onOk={() => {
                    handleOk(values, isValid, isSubmitting);
                  }}
                  onCancel={() => handleCancel(setValues, initialValues)}
                  cancelButtonProps={{ style: { display: "none" } }}
                  width={500}
                  okText="Save"
                  okButtonProps={{
                    disabled:
                      !dirty ||
                      Object.keys(errors).length > 0 ||
                      !isValidPhoneNumber(values.phoneNumber || ""),
                  }}
                >
                  <Form>
                    <Row gutter={[8, 8]}>
                      <Col span={12}>
                        <div className="element">
                          <label className="label">
                            Contact Person First Name
                          </label>
                          <Field
                            className="inputFormik"
                            name="firstName"
                            value={values.firstName}
                          />
                          <ErrorMessage
                            name="firstName"
                            component="div"
                            render={(msg) => (
                              <div className="errorMsg">{msg}</div>
                            )}
                          />
                        </div>
                        <div className="element">
                          <label className="label">Company Website</label>
                          <Field
                            className="inputFormik"
                            name="website"
                            value={values.website}
                          />
                          <ErrorMessage
                            name="website"
                            component="div"
                            render={(msg) => (
                              <div className="errorMsg">{msg}</div>
                            )}
                          />
                        </div>
                        <div className="element">
                          <label className="label">Email</label>
                          <Field
                            className="inputFormik"
                            name="email"
                            value={values.email}
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            render={(msg) => (
                              <div className="errorMsg">{msg}</div>
                            )}
                          />
                        </div>
                        {customer?.isInternational === 0 && (
                          <div className="element">
                            <label className="label">GST Certificate</label>
                            <br />
                            <FileUpload
                              file={fileGST}
                              setFile={setFileGST}
                              name="gstCertificate"
                              existingFileName={
                                customer?.gstCertificate || null
                              }
                              setFieldValue={setFieldValue}
                              required={true}
                            />
                          </div>
                        )}
                        {customer?.isInternational === 1 && (
                          <div className="element">
                            <label className="label">
                              unique Tax Id Certificate
                            </label>
                            <br />
                            <FileUpload
                              file={fileTAX}
                              setFile={setFileTAX}
                              name="uniqueTaxIdCertificate"
                              existingFileName={
                                customer?.gstCertificate || null
                              }
                              setFieldValue={setFieldValue}
                              required={true}
                            />
                          </div>
                        )}
                      </Col>
                      <Col span={12}>
                        <div className="element">
                          <label className="label">
                            Contact Person Last Name
                          </label>
                          <Field
                            className="inputFormik"
                            name="lastName"
                            value={values?.lastName}
                          />
                          <ErrorMessage
                            name="lastName"
                            component="div"
                            render={(msg) => (
                              <div className="errorMsg">{msg}</div>
                            )}
                          />
                        </div>
                        <div className="element">
                          <label className="label">Phone Number</label>
                          <PhoneInput
                            containerClass='phone-input'
                            name="phoneNumber"
                            country={'IN'}
                            value={values.phoneNumber}
                            onChange={(value, country) => {
                              setFieldValue("phoneNumber", phoneNumberFormatter(value, country.dialCode));
                            }}
                            error={
                              values?.phoneNumber
                                ? isValidPhoneNumber(values?.phoneNumber)
                                  ? undefined
                                  : "Invalid phone number"
                                : "Phone number required"
                            }
                          />
                          {values?.phoneNumber ? (
                            isValidPhoneNumber(
                              values?.phoneNumber
                            ) ? undefined : (
                              <div className="errorMsg">
                                Phone number Invalid
                              </div>
                            )
                          ) : (
                            "Phone number required"
                          )}
                        </div>
                        <div className="element">
                          <label className="label">Designation</label>
                          <Field
                            className="inputFormik"
                            name="designation"
                            value={values?.designation}
                          />
                          <ErrorMessage
                            name="designation"
                            component="div"
                            render={(msg) => (
                              <div className="errorMsg">{msg}</div>
                            )}
                          />
                        </div>
                        <div className="element">
                          <label className="customerLabel">
                            Business nature
                          </label>
                          <Radio.Group
                            optionType="button"
                            buttonStyle="solid"
                            onChange={(e) => setFieldValue('natureOfBusiness', e.target.value)}
                            value={values.natureOfBusiness}
                          >
                            {BUSINESS_NATURE.map((option) => (
                              <Radio.Button
                                value={option.value}
                                key={option.value}
                              >
                                {option.title}
                              </Radio.Button>
                            ))}
                          </Radio.Group>
                        </div>
                        {customer?.isInternational === 1 && (
                          <>
                            <div className="element">
                              <label className="label">unique Tax Doc Id</label>
                              <Field
                                className="inputFormik"
                                name="uniqueTaxDocId"
                                value={values.uniqueTaxDocId}
                              />
                              <ErrorMessage
                                name="uniqueTaxDocId"
                                component="div"
                                render={(msg) => (
                                  <div className="errorMsg">{msg}</div>
                                )}
                              />
                            </div>
                            <div className="element">
                              <label className="label">
                                unique Tax Doc Name
                              </label>
                              <Field
                                className="inputFormik"
                                name="uniqueTaxDocName"
                                value={values.uniqueTaxDocName}
                              />
                              <ErrorMessage
                                name="uniqueTaxDocName"
                                component="div"
                                render={(msg) => (
                                  <div className="errorMsg">{msg}</div>
                                )}
                              />
                            </div>
                          </>
                        )}
                      </Col>
                    </Row>
                  </Form>
                </Modal>
              </div>

              <Row gutter={[8, 8]}>
                <Col span={12}>
                  <div className="element">
                    <label className="customerLabel">Customer Type</label>
                    <Radio.Group
                      value={customer?.isInternational === 1 ? 1 : 0}
                      className="radio_button"
                      disabled
                    >
                      <Radio value={0}>India</Radio>
                      <Radio value={1}>International</Radio>
                    </Radio.Group>
                  </div>
                  {customer?.isInternational === 0 && (
                    <div className="element">
                      <label className="label">GST Number</label>
                      <Input
                        className="inputFormikClear"
                        value={customer?.gstNumber || ""}
                        disabled
                      />
                    </div>
                  )}

                  {customer?.isInternational === 1 && (
                    <>
                      <Col span={12}>
                        <div className="element">
                          <label className="label">Unique tax ID</label>
                          <Input
                            className="inputFormikClear"
                            value={customer?.uniqueTaxDocId || ""}
                            disabled
                          />
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className="element">
                          <label className="label">Name of tax document</label>
                          <Input
                            value={customer?.uniqueTaxDocName || ""}
                            disabled
                            className="inputFormikClear"
                          />
                        </div>
                      </Col>
                    </>
                  )}

                  <div className="element">
                    <label className="label">Legal Name</label>
                    <Input
                      className="inputFormikClear"
                      value={customer?.name || ""}
                      disabled
                    />
                  </div>
                  {customer?.isInternational === 0 && (
                    <div className="element">
                      <label className="label">GST Certificate</label>
                      <br />
                      <Button href={customer?.gstCertificate}>{`${
                        customer?.gstCertificate
                          ? getFileName(customer?.gstCertificate)
                          : "Not Available"
                      }`}</Button>
                    </div>
                  )}
                  {customer?.isInternational === 1 && (
                    <div className="element">
                      <label className="label">Unique tax ID Certificate</label>
                      <br />
                      <Button href={customer?.uniqueTaxIdCertificate}>{`${
                        customer?.uniqueTaxIdCertificate
                          ? getFileName(customer?.uniqueTaxIdCertificate)
                          : "Not Available"
                      }`}</Button>
                    </div>
                  )}
                </Col>
                <Col span={12}>
                <div className="element">
                    <label className="customerLabel">Business nature</label>
                    <Radio.Group
                      value={+customer?.natureOfBusiness === MANUFACTURER_TYPE_VALUE ? MANUFACTURER_TYPE_VALUE : TRADER_TYPE_VALUE}
                      className="radio_button"
                      disabled
                    >
                      <Radio value={MANUFACTURER_TYPE_VALUE}>Manufacturer</Radio>
                      <Radio value={TRADER_TYPE_VALUE}>Trader</Radio>
                    </Radio.Group>
                  </div>
                  <div className="element">
                    <label className="label">Contact Person</label>
                    <Input
                      value={
                        `${contactPersonFirstName ? contactPersonFirstName : ''} ${contactPersonLastName ? contactPersonLastName : ''}`
                      } 
                      disabled
                      className="inputFormikClear"
                    />
                  </div>
                  <div className="element">
                    <label className="label">Designation</label>
                    <Input
                      className="inputFormikClear"
                      value={customer?.designation || ""}
                      disabled
                    />
                  </div>
                  <div className="element">
                    <label className="label">Company Website</label>
                    <Input
                      className="inputFormikClear"
                      value={customer?.website || ""}
                      disabled
                    />
                  </div>
                  <Col span={12}>
                    <div className="element">
                      <label className="label">Email</label>
                      <Input
                        className="inputFormikClear"
                        value={customer?.email || ""}
                        disabled
                      />
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="element">
                      <label className="label">Phone Number</label>
                      <Input
                        value={customer?.phone}
                        disabled
                        className="inputFormikClear"
                      />
                    </div>
                  </Col>
                </Col>
              </Row>
            </>
          )}
        </Formik>
      </div>
      <Divider />
    </>
  );
};

export default BasicInfo;
