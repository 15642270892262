import AlertImg from "../../assets/images/communication/ic-alert.svg";

import CollectionDetailsForm from "../../components/Collections/CollectionDetailsForm/CollectionDetailsForm";
import InviteUsersForm from "../../components/AccountSettings/InviteUsers/InviteUsersForm";
import UserDetails from "../../components/AccountSettings/UserDetails/UserDetails";
import FirstLogin from "../../components/Authentication/FirstLogin/FirstLogin";
import CollectionCollaboration from "../../components/Collections/CollectionCollaboration/CollectionCollaboration";

import CollectionCollaboration_ViewOnly from "../../components/Collections/CollectionCollaboration/Collaborators";

import NewDesign from "../../components/Designs/NewDesign/NewDesign";
import AddFilesForm from "../../components/Designs/DesignFiles/AddFilesForm";
import EditFilesForm from "../../components/Designs/DesignFiles/EditFilesForm";
import SampleVersionHistory from "./../../components/Designs/SamplingDetails/SampleVersionHistory/index";
import SampleDispatchDetails from "./../../components/Designs/SamplingDetails/SampleDispatchDetails";
import MoveDesignModal from "../../components/Collections/CollectionDetails/MoveDesignModal";
import MoveDesignConfirmModal from "../../components/Collections/CollectionDetails/MoveDesignConfirmModal";
import DuplicateDesignModal from "../../components/Collections/CollectionDetails/DuplicateDesignModal";
import DuplicateDesignConfirmModal from "../../components/Collections/CollectionDetails/DuplicateDesignConfirmModal";

// Quote
import UpdateQuoteRequestModal from "../../components/Designs/QuoteDetails/UpdateRequestModal";
import ResubmitQuoteModal from "../../components/Designs/QuoteDetails/QuoteActions/ResubmitQuoteModal";
import ApproveQuoteModal from "../../components/Designs/QuoteDetails/QuoteActions/ApproveQuoteModal";
import NewQuoteModal from "../../components/Designs/QuoteDetails/NewQuoteModal";
import SubmitQuoteModal from "../../components/Designs/QuoteDetails//Modals/SubmitQuoteModal/index";
import ReviewQuoteModal from "../../components/Quotes/ReviewQuoteModal/index";
import ConfirmWithdrawQuoteModal from "../../components/Designs/QuoteDetails/ConfirmWithdrawQuoteModal/index";
import SizesQuoteModal from "../../components/Quotes/SizesQuoteModal";

import OrderFile from "./../../components/Designs/OrderConfirmation/OrderFile";
import DispatchFile from "./../../components/Designs/Dispatch/DispatchFile";
import NotificationUserList from "../NotificationContainer/NotificationUserList";
import NotificationSentUserList from "../NotificationContainer/NotificationSentUserList";

import UpdateSampleStatusModal from "../../components/Designs/SamplingDetails/SampleStatusUpdate";
import SampleNotRequiredModal from "../../components/Designs/SamplingDetails/SampleNotRequiredModal";
import PreProductionFileDetailsModel from "../../components/Designs/FileDetailsModal/FileDetailsModal";
import SamplingCourierDetails from "../../components/Designs/SamplingDetails/CourierDetailsModal";
import ProductsCreateOrderModel from "../../components/Product/CreateOrderModel";

// orders modals
import ordersViewDesigns from "../../components/Orders/ViewDesignsModal";
import ordersAddDispatchDetails from "../../components/Orders/AddDispatchDetailsModel";
import ordersEditPo from "../../components/Orders/EditPoModel";
import ordersViewDispatchDetails from "../../components/Orders/ViewDispatchDetailsModal";

// swatch modals
import AddSwatchModal from "../../components/SwatchLibrary/AddSwatchModal";
import swatchDetail from "../../components/SwatchLibrary/DetailsModel";
import swatchAddToDesign from "../../components/SwatchLibrary/AddToDesignModel";
import swatchShareWithBrands from "../../components/SwatchLibrary/ShareWithBrandModel";
import swatchShareWithContacts from "../../components/SwatchLibrary/ShareWithContacts";
import AddVendor from "../../components/SwatchLibrary/AddVendor";

// notification modal
import notificationModal from "../../components/UI/Modal/NotificationModel";

// sampling tracker
import addDueDateModalFromSamplingTracker from "../../components/SamplingTracker/AddDueDateModal";

// add new user from manage factory **
import manageFactoryAddNewUser from "../../components/AccountSettings/FactoryList/AddUserModal";
import manageFactoryShowAllUers from "../../components/AccountSettings/FactoryList/AllUsersModal";
import manageFactoryShowAllAddresses from "../../components/AccountSettings/FactoryList/AddressesModal";
import manageLfUsersAddUser from "../../components/AccountSettings/ManageLfUsers/AddUserModal";
import BrandDetailsModal from "./../../components/AccountSettings/BrandSignup/BrandDetailsModal";
import PartnerDetailsModal from "./../../components/AccountSettings/CourierPartners/PartnerModals/PartnerDetailsModal.jsx";
import BrandUserModal from "./../../components/AccountSettings/BrandSignup/AddUserModal";
import FactoryProductionModal from "./../../components/AccountSettings/FactoryList/FactoryProductionModal";
import FactoryMerchantAssociation from "../../components/AccountSettings/FactoryList/FactoryMerchantAssociation";
import ResetPasswordModal from "../../components/AccountSettings/ResetPassword/ResetPasswordModal";
import UpdateUserPhoneModal from "../../components/AccountSettings/ProfileSettings/UpdateUserPhoneModal";
import AddEditUserModal from "../../components/AccountSettings/ManageUsers/AddEditUserModal";

// factory association
import factoryAssociation from "../../components/Collections/ChooseFactoryModal/ChooseFactoryModal";
import CommentsModal from "../../components/Designs/SamplingDetails/CommentsModal";
import SamplingHistoryModal from "../../components/SamplingTracker/SamplingHistoryModal";

// TNA
import UpdateTnaRedirect from "../../components/ProductionTracker/UpdateTnaRedirect";
import QuoteRequestModal from "../../components/CustomBrand/Quotes/QuoteRequestModal";
import SkipSamplingModal from "../../components/Designs/SamplingDetails/SkipSamplingModal";
import SampleRequestModal from "../../components/Designs/SamplingDetails/SampleRequest";
import MoveToProduction from "../../components/Designs/Production/RequestProductionModal";
import CopyTnaModal from "components/Designs/TnaPlan/CopyTnaModal";

import { CollaborationModal } from "../../components/Collaboration";

import NewTnaGraph from "./../../components/ProductionTracker/NewTnaGraph";
import SubmitTnaConfirmation from "components/Designs/TnaPlan/SubmitTnaConfirmation";
import ActualDateModal from "components/Designs/TnaPlan/ActualDateModal";
import RequestResubmissionModal from "components/Designs/BrandTna/RequestResubmissionModal";

import AddEditVendorModal from "components/AccountSettings/ManageVendors/AddEditVendor";
import MaterialDetails from "components/MaterialLibrary/MaterialDetail";
import AddMaterialModal from "../../components/MaterialLibrary/AddMaterialModal";
import DeleteMaterialModal from "components/MaterialLibrary/DeleteMaterial";
import ChangeOrgModal from "../../components/Orders/CreateOrder/ChangeOrgModal";
import AddAddressModal from "../../components/Orders/CreateOrder/AddAddressModal";
import AddCustomerModal from "components/AccountSettings/Customers/AddCustomerModal";
import OrgSelectUserModal from "components/AccountSettings/OrgSettings/OrgSelectUserModal";

// Sales head team management
import TeamMembers from "components/AccountSettings/ManageLfUsers/Team/TeamMembers";
import ManageMembers from "components/AccountSettings/ManageLfUsers/Team/ManageMembers";

// settings/users
import DeactivateModal from "components/AccountSettings/ManageLfUsers/DeactivateModal";
import RoleChangeModal from "components/AccountSettings/ManageLfUsers/RoleChangeModal";


export default {
  NEW_COLLECTION: {
    title: "New Collection",
    innerContent: CollectionDetailsForm,
    footer: "Create Collection",
    formName: "collectionDetails",
    submitAction: "createCollection",
    modalType: "v2"
  },
  EDIT_COLLECTION: {
    title: "Edit Collection",
    innerContent: CollectionDetailsForm,
    footer: "Edit Collection",
    formName: "collectionDetails",
    submitAction: "editCollection"
  },
  DELETE_COLLECTION: {
    title: "Delete Collection",
    innerContent: "Are you sure you want to delete this collection ?",
    footer: "Delete Collection",
    formName: "",
    submitAction: "deleteCollection"
  },
  DELETE_DESIGN: {
    titleImg: AlertImg,
    title: "Delete Design?",
    className: "alert-modal",
    innerContent: "Once the design is deleted, all details associated with the design will be deleted.",
    footer: "Delete Design",
    formName: "",
    withCancel: true,
    submitAction: "deleteDesign"
  },
  ARCHIVE_DESIGN: {
    title: "Archive Design",
    innerContent: "Are you sure you want to archive this design ?",
    footer: "Archive Design",
    formName: "",
    submitAction: "updateDesignStatus"
  },
  RESTORE_DESIGN: {
    title: "Restore Design",
    innerContent: "Are you sure you want to restore this design ?",
    footer: "Restore Design",
    formName: "",
    submitAction: "updateDesignStatus"
  },
  // DELETE_DESIGN_FILE: {
  //   title: "Delete File",
  //   innerContent: "Are you sure you want to delete this File ?",
  //   footer: "Delete File",
  //   formName: "",
  //   submitAction: "deleteDesignFile"
  // },
  INVITE_USERS: {
    title: "Invite Users",
    innerContent: InviteUsersForm,
    footer: "Send Invite(s)",
    formName: "InviteUsers",
    submitAction: "inviteUsers",
    modalType: "right-full-height"
  },
  USER_DETAILS: {
    title: "User Details",
    innerContent: UserDetails,
    footer: null,
    formName: null,
    submitAction: null,
    modalType: "right" //right-full-height
  },
  FIRST_LOGIN: {
    title: null,
    innerContent: FirstLogin,
    footer: "CONTINUE TO DASHBOARD",
    formName: "FirstLogin",
    submitAction: "updateUserDetails",
    noEsc: true
  },
  COLLECTION_COLLAB: {
    title: "Manage Users",
    subtitle: "Users added to this collection appear here",
    innerContent: CollectionCollaboration,
    footer: "Save Changes",
    formName: "CollectionCollab",
    submitAction: "updateCollectionCollab",
    modalType: "right"
  },
  COLLECTION_COLLAB_VIEWONLY: {
    title: "Collaborators",
    subtitle: "Users added to this collection appear here",
    innerContent: CollectionCollaboration_ViewOnly,
    footer: null,
    modalType: "right",
    formName: "CollectionCollab"
  },
  NEW_DESIGN: {
    title: "New Design",
    innerContent: NewDesign,
    footer: "Create Design",
    filledFooter: true,
    formName: "NewDesign",
    submitAction: "createDesign"
  },
  UPLOAD_FILE: {
    title: "Add File",
    innerContent: AddFilesForm,
    filledFooter: true,
    footer: "Upload file",
    formName: "AddFile",
    submitAction: "uploadDesignFileModal"
  },
  EDIT_FILE: {
    title: "Edit File",
    innerContent: EditFilesForm,
    filledFooter: true,
    footer: "Edit file",
    formName: "EditFile",
    submitAction: "updateDesignFileModal"
  },
  UPLOAD_FILE_PRE_PRODUCTION: {
    // This model uploads the pre-production file ..
    title: "Add File",
    innerContent: OrderFile,
    footer: "Upload file",
    formName: "addOrderFile",
    submitAction: "uploadPreproductionFile"
  },
  PRE_PRODUCTION_FILE_DETAILS: {
    // ???????????????????????????
    title: "Add File",
    innerContent: PreProductionFileDetailsModel,
    footer: "Upload file",
    formName: "addOrderFile",
    submitAction: "uploadPreproductionFile"
  },
  SAMPLE_VERSION_HISTORY: {
    title: "Sampling History",
    innerContent: SampleVersionHistory,
    footer: null,
    formName: "EditDesignFile",
    submitAction: "editDesignFile"
  },
  SAMPLE_DISPATCH_DETAILS: {
    title: "Dispatch Details",
    innerContent: SampleDispatchDetails,
    footer: null,
    formName: "",
    submitAction: null
  },
  UPDATE_SAMPLE_STATUS: {
    title: "Update sample status",
    innerContent: UpdateSampleStatusModal, // work on this ..
    footer: null,
    formName: "EditDesignFile",
    submitAction: "editDesignFile"
  },
  COMMENTS_MODAL: {
    title: "Comments",
    innerContent: CommentsModal,
    footer: null,
    formName: "EditDesignFile",
    submitAction: "editDesignFile"
  },
  RESUBMIT_QUOTE_MODAL: {
    title: "Reject quote",
    innerContent: ResubmitQuoteModal,
    footer: "REJECT QUOTE",
    formName: "",
    submitAction: "updateQuoteStatus",
    withCancel: true
  },
  APPROVE_QUOTE_MODAL: {
    title: "Approve quote",
    innerContent: ApproveQuoteModal,
    footer: "APPROVE QUOTE",
    formName: "",
    submitAction: "updateQuoteStatus",
    withCancel: true
  },
  SAMPLING_ADD_COURIERS_DETAILS: {
    title: "Sample Dispatch Details",
    innerContent: SamplingCourierDetails,
    footer: null,
    formName: "nothing",
    submitAction: null
  },
  CREATE_QUOTE_MODAL: {
    title: "Select Quote Type",
    innerContent: NewQuoteModal,
    footer: "CREATE QUOTE",
    formName: "newQuote",
    submitAction: "createQuote"
  },
  ADD_ORDER_FILE: {
    title: "Add File",
    innerContent: OrderFile,
    footer: "UPLOAD FILE",
    formName: "addOrderFile",
    submitAction: "updateOrderFiles"
  },
  ADD_DISPATCH_FILE: {
    title: "Add File",
    innerContent: DispatchFile,
    footer: "UPLOAD FILE",
    formName: "addDispatchFile",
    submitAction: "updateDispatchFiles"
  },
  SEND_NOTIFICATION_USER_LIST: {
    title: "Send Notification to",
    innerContent: NotificationUserList,
    footer: "Send Notification",
    formName: "sendNotificationUserList",
    submitAction: "createNotifications"
  },
  SEND_DISPATCH_NOTIFICATION_USER_LIST: {
    title: "Send Notification to",
    innerContent: NotificationUserList,
    footer: "Send Notification",
    formName: "sendNotificationUserList",
    submitAction: "postDispatchNotification"
  },
  NOTIFICATION_SENT_TO: {
    title: "Notification Sent to",
    innerContent: NotificationSentUserList,
    footer: null,
    formName: "sentNotificationUserList",
    submitAction: null
  },
  PRODUCTS_CREATE_ORDER: {
    title: "Create Order",
    innerContent: ProductsCreateOrderModel,
    footer: null,
    formName: "productsCreateOrder",
    submitAction: null
  },
  ORDERS_VIEW_DESIGNS: {
    title: "Designs",
    innerContent: ordersViewDesigns,
    footer: null,
    formName: "productsCreateOrder",
    submitAction: null
  },
  ORDERS_ADD_DISPATCH_DETAILS: {
    title: "Add dispatch details",
    innerContent: ordersAddDispatchDetails,
    footer: null,
    formName: "productsCreateOrder",
    submitAction: null
  },
  ORDERS_EDIT_PO_ORDERS: {
    title: "Edit PO",
    innerContent: ordersEditPo,
    footer: null,
    formName: "productsCreateOrder",
    submitAction: null
  },
  ORDERS_VIEW_DISPATCH_DETAILS: {
    title: "Dispatch details",
    innerContent: ordersViewDispatchDetails,
    footer: null,
    formName: "productsCreateOrder",
    submitAction: null
  },
  ADD_SWATCH: {
    title: "Add New Swatch",
    formName: "AddSwatch",
    innerContent: AddSwatchModal,
    footer: "Save",
    submitAction: "createSwatch"
  },
  EDIT_SWATCH: {
    title: "Edit Swatch",
    formName: "AddSwatch",
    innerContent: AddSwatchModal,
    footer: "Save",
    submitAction: "editSwatch"
  },
  SWATCH_LIBRARY_SHOW_SWATCH_DETAILS: {
    title: "",
    innerContent: swatchDetail,
    footer: null,
    formName: "swatchDetail",
    submitAction: null
  },
  SWATCH_LIBRARY_ADD_SWATCH_TO_DESIGN: {
    title: "Add to Design",
    innerContent: swatchAddToDesign,
    footer: null,
    formName: "swatchDetail",
    submitAction: null
  },
  SWATCH_LIBRARY_SHARE_WITH_BRAND_DESIGNS: {
    title: "Share",
    innerContent: swatchShareWithBrands,
    footer: null,
    formName: "swatchDetail",
    submitAction: null
  },
  SWATCH_LIBRARY_SHARE_WITH_CONTACTS: {
    title: "Share",
    innerContent: swatchShareWithContacts,
    footer: null,
    formName: "swatchDetail",
    submitAction: null
  },
  NOTIFICATION_MODAL: {
    title: "Notifications",
    innerContent: notificationModal,
    footer: null,
    formName: "notifications",
    submitAction: null,
    modalType: "right",
    noShadow: true
  },
  SAMPLING_TRACKER_ADD_DUE_DATE: {
    title: "Enter Due Date",
    innerContent: addDueDateModalFromSamplingTracker,
    footer: null,
    formName: "",
    submitAction: null
  },
  MANAGE_FACTORY_ADD_NEW_USER: {
    title: "Add User",
    innerContent: manageFactoryAddNewUser,
    footer: null,
    formName: "",
    submitAction: null,
    modalType: "v2"
  },
  MANAGE_FACTORY_EDIT_NEW_USER: {
    title: "Edit User",
    innerContent: manageFactoryAddNewUser,
    footer: null,
    formName: "",
    submitAction: null,
    modalType: "v2"
  },
  MANAGER_FACTORY_MERCHANTS_ASSOCIATION: {
    title: "Assign Production Merchants",
    innerContent: FactoryMerchantAssociation,
    footer: null,
    formName: "",
    submitAction: null,
    modalType: "v2"
  },
  MANAGE_FACTORY_SHOW_ALL_USERS: {
    title: "Users",
    innerContent: manageFactoryShowAllUers,
    footer: null,
    formName: "",
    submitAction: null,
    modalType: "right-full-height"
  },
  MANAGE_FACTORY_SHOW_ALL_ADDRESSES: {
    title: "Addresses",
    innerContent: manageFactoryShowAllAddresses,
    footer: null,
    formName: "",
    submitAction: null
  },
  FACTORY_PRODUCTION_DATA: {
    title: "In Production",
    innerContent: FactoryProductionModal,
    footer: null,
    formName: "",
    submitAction: null
  },
  ASSOCIATE_FACTORY: {
    title: "Assign factory",
    innerContent: factoryAssociation,
    footer: null,
    formName: "",
    submitAction: null
  },
  SAMPLE_NOT_REQUIRED: {
    title: "Please confirm",
    innerContent: SampleNotRequiredModal,
    footer: null,
    formName: "",
    submitAction: null,
    showClose: false
  },
  VIEW_SAMPLING_HISTORY: {
    title: "Sampling history",
    innerContent: SamplingHistoryModal,
    footer: null,
    submitAction: null
  },
  REDIRECT_TNA_UPDATE: {
    title: "",
    innerContent: UpdateTnaRedirect,
    footer: null,
    submitAction: null
  },
  ADD_NEW_VENDOR: {
    title: "Add vendor",
    innerContent: AddVendor,
    footer: "Save",
    formName: "NewVendor",
    submitAction: "postSwatchVendors"
  },
  ADD_LF_USER: {
    title: "Add User",
    innerContent: manageLfUsersAddUser,
    footer: "Save",
    formName: "",
    submitAction: "getLocofastUsers",
    modalType: "v2"
  },
  UPDATE_LF_USER: {
    title: "Edit User",
    innerContent: manageLfUsersAddUser,
    footer: "Save",
    formName: "",
    submitAction: null,
    modalType: "v2"
  },
  ADD_NEW_PARTNER: {
    title: "Add Partner",
    formName: "AddPartner",
    innerContent: PartnerDetailsModal,
    footer: null,
    modalType: "v2 brand-details"
  },
  EDIT_PARTNER_DETAILS: {
    title: "Edit Partner",
    formName: "AddPartner",
    innerContent: PartnerDetailsModal,
    footer: "Update",
    submitAction: "updateLogiPartner",
    modalType: "v2 brand-details"
  },
  ADD_NEW_BRAND: {
    title: "Add Brand",
    formName: "AddBrand",
    innerContent: BrandDetailsModal,
    footer: "Save",
    submitAction: "createNewBrand",
    modalType: "v2 brand-details"
  },
  EDIT_BRAND_DETAILS: {
    title: "Edit Brand",
    formName: "AddBrand",
    innerContent: BrandDetailsModal,
    footer: "Update",
    submitAction: "updateBrandDetails",
    modalType: "v2 brand-details"
  },
  ADD_BRAND_USER: {
    title: "Add User",
    formName: "AddUser",
    innerContent: BrandUserModal,
    footer: "Save",
    submitAction: "createBrandUser",
    modalType: "v2"
  },
  EDIT_BRAND_USER: {
    title: "Edit User",
    formName: "EditUser",
    innerContent: BrandUserModal,
    footer: "Update",
    submitAction: "updateBrandUser",
    modalType: "v2"
  },
  CHANGE_PASSWORD: {
    title: "Change password",
    formName: "ResetPasswordForm",
    innerContent: ResetPasswordModal,
    footer: "Save Changes",
    submitAction: "changePassword",
    modalType: "v2"
  },
  UPDATE_USER_PHONE: {
    title: "Edit mobile number",
    formName: "UpdateUserPhoneForm",
    innerContent: UpdateUserPhoneModal,
    footer: "Save Changes",
    submitAction: "updateUserPhone",
    modalType: "v2"
  },
  SET_USER_PHONE: {
    title: "Add mobile number",
    formName: "UpdateUserPhoneForm",
    innerContent: UpdateUserPhoneModal,
    footer: "Save Changes",
    submitAction: "updateUserPhone",
    modalType: "v2"
  },
  ADD_USER: {
    title: "Add user",
    formName: "AddEditUserForm",
    innerContent: AddEditUserModal,
    footer: "Add User",
    submitAction: "createUser",
    modalType: "v2"
  },
  EDIT_USER: {
    title: "Edit user",
    formName: "AddEditUserForm",
    innerContent: AddEditUserModal,
    footer: "Save changes",
    submitAction: "updateUser",
    modalType: "v2"
  },
  REQUEST_QUOTE: {
    title: "Request quote",
    formName: "",
    innerContent: QuoteRequestModal,
    footer: null,
    submitAction: "requestQuote",
    modalType: "request-quote"
  },
  UPDATE_QUOTE_FACTORIES: {
    title: "Request quote",
    formName: "",
    innerContent: QuoteRequestModal,
    footer: null,
    submitAction: "requestQuote",
    modalType: "request-quote"
  },
  SKIP_SAMPLING: {
    title: "",
    formName: "",
    innerContent: SkipSamplingModal,
    footer: null,
    submitAction: null,
    modalType: "request-quote",
  },
  REQUEST_SAMPLE: {
    title: "Request prototype sample",
    className: 'request_sample_modal',
    innerContent: SampleRequestModal,
    footer: null,
    submitAction: "requestQuote",
    modalType: "v2"
  },
  MOVE_TO_PRODUCTION: {
    title: "Request production",
    className: 'request_sample_modal',
    innerContent: MoveToProduction,
    footer: null,
    submitAction: "requestQuote",
    modalType: "v2"
  },
  COLLECTION_COLLABORATION: {
    title: "Sharing settings",
    formName: "",
    innerContent: CollaborationModal,
    footer: null
  },
  VIEW_TNA_PROGRESS: {
    title: "Sharing settings",
    formName: "",
    innerContent: NewTnaGraph,
    footer: null
  },
  SUBMIT_TNA_CONFIRMATION: {
    title: "",
    innerContent: SubmitTnaConfirmation,
    footer: null,
    submitAction: null
  },
  MARK_TNA_ACTION_COMPLETE: {
    title: "",
    innerContent: ActualDateModal,
    footer: null,
    submitAction: null
  },
  UPDATE_QUOTE_REQUEST: {
    title: "Change deadline",
    innerContent: UpdateQuoteRequestModal,
    footer: null,
    submitAction: null
  },
  REVIEW_QUOTE_MODAL: {
    title: "Quotes",
    innerContent: ReviewQuoteModal,
    footer: null,
    submitAction: null
  },
  VIEW_MATERIAL_DETAILS: {
    title: "Material details",
    innerContent: MaterialDetails,
    footer: null,
    formName: "",
    submitAction: null
  },
  ADD_MATERIAL: {
    title: "Add Fabric",
    formName: "AddMaterial",
    innerContent: AddMaterialModal,
    footer: "Add Material",
    submitAction: "createMaterial"
  },
  EDIT_MATERIAL: {
    title: "Edit Fabric",
    formName: "AddMaterial",
    innerContent: AddMaterialModal,
    footer: "Edit Material",
    submitAction: "editMaterial"
  },
  DELETE_MATERIAL: {
    title: null,
    formName: null,
    innerContent: DeleteMaterialModal,
    footer: null,
    submitAction: null,
    modalType: "delete-material"
  },
  SUBMIT_QUOTE_MODAL: {
    title: "Submit Quote",
    innerContent: SubmitQuoteModal,
    footer: "Submit Quote",
    submitAction: "updateQuote",
    withCancel: true
  },
  UPDATE_QUOTE_MODAL: {
    title: "Update Quote",
    innerContent: SubmitQuoteModal,
    footer: "Update Quote",
    submitAction: "updateQuote",
    withCancel: true
  },
  ADD_VENDOR: {
    title: "Add vendor",
    formName: "AddEditVendor",
    innerContent: AddEditVendorModal,
    footer: "Add Vendor",
    submitAction: "postMaterialVendors",
    modalType: "v2"
  },
  EDIT_VENDOR: {
    title: "Edit vendor",
    formName: "AddEditVendor",
    innerContent: AddEditVendorModal,
    footer: "Save changes",
    submitAction: "editMaterialVendors",
    modalType: "v2"
  },
  CREATE_ORDER_CHANGE_ORG: {
    title: "",
    className: "create-order-change-org",
    innerContent: ChangeOrgModal,
    modalType: "v2",
    titleImg: AlertImg,
    withCancel: true,
    submitAction: "closeModal"
  },
  ORDER_ADD_ADDRESS: {
    title: "Add new address",
    className: "order-add-address",
    innerContent: AddAddressModal,
    modalType: "v2",
    footer: "ADD & SAVE ADDRESS",
    submitAction: "addBrandAddress"
  },
  WITHDRAW_QUOTE_MODAL: {
    modalType: "v2",
    title: "Withdraw quote request?",
    className: "withdraw-quote-modal",
    innerContent: ConfirmWithdrawQuoteModal,
    footer: "CONFIRM",
    formName: "",
    submitAction: "removeFactoryFromRequest",
    withCancel: true
  },
  QUOTE_SIZE_MODAL: {
    title: "Requested sizes",
    innerContent: SizesQuoteModal,
    footer: null,
    submitAction: null
  },
  MOVE_DESIGN: {
    title: "Choose a collection",
    innerContent: MoveDesignModal,
    footer: null,
    submitAction: null,
  },
  MOVE_DESIGN_CONFIRM: {
    title: "Confirm movement",
    innerContent: MoveDesignConfirmModal,
    footer: null,
    submitAction: null,
  },
  COPY_TNA: {
    title: "Select a design",
    innerContent: CopyTnaModal,
    footer: null,
    submitAction: null,
  },
  REQUEST_TNA_RESUBMISSION: {
    title: "Request TNA resubmission",
    innerContent: RequestResubmissionModal,
    footer: null,
    submitAction: null
  },
  DUPLICATE_DESIGN: {
    title: "Choose a collection",
    innerContent: DuplicateDesignModal,
    footer: null,
    submitAction: null,
  },
  DUPLICATE_DESIGN_CONFIRM: {
    title: "Review",
    innerContent: DuplicateDesignConfirmModal,
    footer: null,
    submitAction: null,
  },
  ADD_CUSTOMER: {
    title: "Add Customer",
    innerContent: AddCustomerModal,
    footer: null,
    submitAction: null,
  },
  EDIT_CUSTOMER: {
    title: "Edit Customer",
    innerContent: AddCustomerModal,
    footer: null,
    submitAction: null,
  },
  ASSIGN_ACCOUNT_MANAGER: {
    title: "Add Account Manager",
    innerContent: OrgSelectUserModal,
    footer: null,
    submitAction: null,
  },
  UNASSIGN_ACCOUNT_MANAGER: {
    title: "Remove Account Manager",
    innerContent: "This will remove the account manager, are you sure you want to proceed?",
    footer: "Proceed",
    formName: "",
    submitAction: "assignUsersToOrg"
  },
  VIEW_TEAM_MEMBERS: {
    innerContent: TeamMembers,
    footer: null,
    formName: null,
    submitAction: null,
    className: "view-team-modal",
  },
  MANAGE_TEAM_MEMBERS: {
    innerContent: ManageMembers,
    footer: null,
    formName: null,
    submitAction: null,
    className: "manage-team-modal",
  },
  ROLE_DEACTIVATE: {
    innerContent: DeactivateModal,
    title: null,
    footer: null,
    formName: null,
    submitAction: null,
    className: "deactivate-user-modal",
  },
  ROLE_CHANGE: {
    innerContent: RoleChangeModal,
    title: null,
    footer: null,
    formName: null,
    submitAction: null,
    className: "role-change-modal",
  }
};
