import React from "react";
import CheckBox from "components/UI/Checkbox/Checkbox";
import { TextSpliced } from "components/UI/TextSpliced";

const FactoryCheckbox = ({
  selected,
  handleClick,
  factoryId,
  factoryName,
  disabled,
  recent,
}) => (
    <div>
      <CheckBox
        label={<TextSpliced text={factoryName} maxWidth={recent ? 200 : 258} />}
        value={selected}
        onClick={() => {
          handleClick(factoryId);
        }}
        disabled={disabled}
        name={factoryName}
      />
      {recent && (
        <span className="recent-tag">
          Recent
        </span>
      )}
    </div>
  );

export default FactoryCheckbox;
