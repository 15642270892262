import React from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import './index.scss';


function getFilteredSuggestions(value, suggestions, filterSuggestions) {
  if (typeof (filterSuggestions) !== 'function') {
    return;
  }

  const filteredSuggestions = filterSuggestions(value, suggestions);

  return filteredSuggestions;
}

export default class Autosuggest extends React.Component {
  static propTypes = {
    renderSuggestion: PropTypes.func.isRequired,
    filterSuggestions: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    suggestions: PropTypes.array.isRequired,
  }

  state = {
    suggestions: [],
    showSuggestions: true,
    filteredSuggestions: [],
    filterSuggestions: null,
    inputValue: ''
  }



  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.filterSuggestions || nextProps.suggestions) {
      const nextFilterSuggestions = nextProps.filterSuggestions || prevState.filterSuggestions;
      const nextSuggestions = nextProps.suggestions || prevState.suggestions;

      const filteredSuggestions = getFilteredSuggestions(prevState.inputValue, nextSuggestions, nextFilterSuggestions)

      return {
        filteredSuggestions,
        suggestions: nextSuggestions,
        filterSuggestions: nextFilterSuggestions,
      };
    }

    return null
  }

  onSearchChange = (event) => {
    if (typeof (this.props.filterSuggestions) !== 'function') {
      return;
    }

    const inputValue = event.target.value;

    this.setState({
      inputValue,
      filteredSuggestions: getFilteredSuggestions(inputValue, this.state.suggestions, this.props.filterSuggestions)
    });
  }

  onSearchFocus = () => {
    this.setState({ showSuggestions: true })
  }

  onSearchBlur = () => {
    this.setState({ showSuggestions: false });
  }

  onSuggestionClick = (event, suggestion) => {
    if (typeof (this.props.onSelect) === 'function') {
      this.props.onSelect(event, suggestion);
    }
    event.preventDefault();
  }

  renderSuggestions = () => {
    const { renderSuggestion } = this.props;
    const { filteredSuggestions } = this.state;

    return filteredSuggestions.map(suggestion => (<div
      key={suggestion.key || suggestion.id}
      className="item-suggestion"
      onClick={(event) => this.onSuggestionClick(event, suggestion)}
      onMouseDown={(event) => event.preventDefault()}
    >{renderSuggestion(suggestion)}
    </div>));
  }

  render() {
    const { className } = this.props;
    const { showSuggestions, filteredSuggestions } = this.state;

    return <div className={`lf-ui-autosuggest ${className || ''}`}>
      <input className="input input-search"
        type="text"
        onFocus={this.onSearchFocus}
        onBlur={this.onSearchBlur}
        onChange={this.onSearchChange} />
      <div className={`suggestions-container ${showSuggestions && filteredSuggestions.length > 0 ? 'show' : 'hide'}`}>
        <PerfectScrollbar>
          {this.renderSuggestions()}
        </PerfectScrollbar>
      </div>
    </div>;
  }
}
