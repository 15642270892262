import React, { Component } from "react";
import PropTypes from 'prop-types';
import { withForwardedRef } from "../../../HOCs/withForwardedRef"

import { omit, sumBy, merge, random } from "lodash";
import DashedBorder from "../../DashedBorder";
import { IconAttachFile, IconClose } from "../../Icons";
import { ReactComponent as EyeImg } from '../../../../assets/images/materialLibrary/ic-eye.svg';
import "./style.scss";
import Button from "../../Button/Button";

const SIZE_5MB = 5 * 1024 * 1024;

class CustomFile extends Component {
  static propTypes = {
    placeholder: PropTypes.string,
    maxFilesCount: PropTypes.number,
    maxFilesSize: PropTypes.number,
    inputFiles: PropTypes.array,
    onViewFiles: PropTypes.func,
    files: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string.isRequired,
    })),
  };

  static defaultProps = {
    placeholder: "Select file",
    maxFilesCount: 1,
    maxFilesSize: SIZE_5MB,
  };

  state = {
    currentLength: 0,
    prefixWidth: 0,
    suffixWidth: 0,
    inputFiles: [],
    fileNames: '',
    filesBindingPending: false,

    propsInputFiles: null,
    propsFiles: null,
  };

  constructor(props) {
    super(props)
    this.inputField = React.createRef();
  }

  static getDerivedStateFromProps(nextProps, oldState) {
    const nextState = {};

    if (nextProps.inputFiles !== oldState.propsInputFiles) {
      nextState.propsInputFiles = nextProps.inputFiles;
      nextState.inputFiles = nextProps.files;
      nextState.fileNames = ((nextState.inputFiles && Array.from(nextState.inputFiles)) || [])
        .map(file => file.name)
        .join(', ');
      nextState.filesBindingPending = true;
    }

    if (nextProps.files !== oldState.propsFiles) {
      nextState.propsFiles = nextProps.files;
      nextState.fileNames = ((nextProps.files && Array.from(nextProps.files)) || [])
        .map(file => file.name)
        .join(', ');
    }

    return Object.keys(nextState).length > 0 ? nextState : null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.filesBindingPending && this.inputField.current) {
      if (Array.isArray(this.state.inputFiles)) {
        const dT = new ClipboardEvent('').clipboardData || // Firefox < 62 workaround exploiting https://bugzilla.mozilla.org/show_bug.cgi?id=1422655
          new DataTransfer(); // specs compliant (as of March 2018 only Chrome)
        this.state.inputFiles.filter(file => file instanceof File).forEach(file => dT.items.add(file));
        this.inputField.current.files = dT.files;
      }

      this.setState({ filesBindingPending: false });
    }
  }

  _handleInputFileChange = (e, ...args) => {
    const {
      maxFilesCount,
      maxFilesSize,
      accept,
      onChange,
      input,
    } = this.props;

    const { files } = e.target;

    if (files.length > 0) {

      // validate files count
      if (files.length > maxFilesCount) {
        // TODO: handle it when multiple is enabled
      }

      // validate files size
      const totalSize = sumBy(files, 'size');
      if (totalSize > maxFilesSize) {
        // TODO: handle it when size of files is more than allowed
      }

      // validate files types
      if (accept && accept.length > 0) {
        const allowedTypes = accept.split(/,/);
        const reg = new RegExp(/\.\w+?$/g);

        const hasInvalidFileTypes = Array.from(files).some((file) => {
          const regResult = reg.exec(file.name);

          // check whether file has the extension or not
          if (regResult && regResult[0]) {
            // check if file extension is allowed or not
            if (!allowedTypes.includes(regResult)) {
              return true;
            }
          }

          // check if file type is allowed or not
          if (!allowedTypes.includes(file.type)) {
            return true;
          }

          return false
        });

        if (hasInvalidFileTypes) {
          // TODO: handle if the user has selected invalid file types
        }
      }
    }

    const names = (Array.from(files) || []).map(f => f.name).join(', ');

    this.setState({
      fileNames: names,
      inputFiles: files,
    });

    if (typeof (onChange) === 'function') {
      onChange(e);
    }

    // for redux-form
    if (input && typeof (input.onChange) === 'function') {
      input.onChange(e);
    }
  };

  _handleViewFiles = () => {
    const { onViewFiles } = this.props;
    const { inputFiles } = this.state;


    if (inputFiles && inputFiles[0]) {
      if (inputFiles[0].url) {
        window.open(inputFiles[0].url);
      } else {
        window.open(
          window.URL.createObjectURL(inputFiles[0])
        );
      }
    }

    if (typeof (onViewFiles) === 'function') {
      onViewFiles(inputFiles);
    }
  }

  _handleRemoveFiles = (e) => {
    const { onChange, input } = this.props;

    if (this.inputField.current) {
      this.inputField.current.value = '';
    }

    this.setState({
      fileNames: '',
      inputFiles: [],
    });

    if (typeof (onChange) === 'function') {
      onChange(e);
    }

    // for redux-form
    if (input && typeof (input.onChange) === 'function') {
      input.onChange(e);
    }
  }

  _renderSelectedFile(uniqueId) {
    const { fileNames } = this.state;

    return <label htmlFor={uniqueId} className="custom-file__field-box">
      <div className="custom-file__file-name overflow-ellipsis">
        {fileNames}
      </div>
      <Button type="button" className="cta-view" category="img shrink" onClick={this._handleViewFiles}>
        <EyeImg />
      </Button>
      <Button type="button" className="cta-remove" category="img shrink" onClick={this._handleRemoveFiles}>
        <IconClose color="grey-medium" />
      </Button>
    </label>;
  }

  _renderSelectFile(uniqueId) {
    const {
      placeholder,
    } = this.props;

    return <DashedBorder htmlFor={uniqueId} className="custom-file__field-box" useLabel={true}>
      <div className="custom-file__placeholder">
        {placeholder}
      </div>
      <IconAttachFile color="grey-medium" />
    </DashedBorder>
  }

  render() {
    const {
      label,
      onChange,
      forwardedRef,
      className,
      placeholder,
      meta = {},
      ...props
    } = this.props;
    const { inputFiles } = this.state;
    const { touched, error, warning } = meta;
    const uniqueId = props.id || `customFile${random(10000, 99999)}`;

    return (
      <div className={`input-grp custom-file ${className || ''} ${inputFiles && inputFiles.length > 0 ? 'custom-file--selected' : ''}`}>
        {label && <label htmlFor={uniqueId} className="custom-file__label f-sz-sm f-color-faded">
          {label}
          {props.required === true && <sup>*</sup>}
        </label>}
        {
          inputFiles && inputFiles.length > 0
            ? this._renderSelectedFile(uniqueId)
            : this._renderSelectFile(uniqueId)
        }
        {
          <span className="input-grp__lower flex f-sz-sm">
            {touched &&
              ((error && <span className="error">{error}</span>) ||
                (warning && <span>{warning}</span>))}
          </span>
        }
        <input
          {...(omit(this.props, 'prefixText', 'suffixText', 'gluePrefix', 'glueSuffix'))}
          id={uniqueId}
          onChange={this._handleInputFileChange}
          type="file"
          ref={ref => {
            if (forwardedRef) {
              if (typeof (forwardedRef) === 'function') {
                forwardedRef(ref);
              } else if (Object.prototype.hasOwnProperty.call(forwardedRef, 'current')) {
                forwardedRef.current = ref;
              }
            }
            this.inputField.current = ref;
          }}
        />
      </div>
    );
  }
}

export default withForwardedRef(CustomFile);
