import React, { Component } from "react";
import PropTypes from "prop-types";
import { Route, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import actions from "../../store/actions";

import Header from "../../components/UI/Header/Header";
import Sidebar from "./../../components/UI/Sidebar/Sidebar";

import { ReactComponent as OrdersImg } from "../../assets/images/navigation/ic-nav-orders.svg";
import { ReactComponent as CollectionsImg } from "assets/images/navigation/collections.svg";
import { ReactComponent as InboxImg } from "assets/images/navigation/ic-nav-messaging.svg";
import { ReactComponent as SamplingImg } from "assets/images/navigation/sampling.svg";
import { ReactComponent as ProductionImg } from "assets/images/navigation/ic-nav-production-2.svg"
import { ReactComponent as SettingsImg } from "assets/images/navigation/settings.svg";
import { ReactComponent as SwatchLibImg } from "../../assets/images/navigation/swatch.svg";
import { ReactComponent as NotificationImg } from "../../assets/images/Left Nav/ic_nav_notifications.svg";

import CollectionDetailsContainer from "../CollectionDetailsContainer/CollectionDetailsContainer";
import DesignDetailsContainer from "../DesignDetailsContainer/DesignDetailsContainer";

import PrivateRoute from "../../components/UI/PrivateRoute/PrivateRoute";
import AccountSettingsContainer from "./../AccountSettingsContainer/AccountSettingsContainer";
import BrandSearch from "./../../components/Factory/BrandSearch/BrandSearch";
import FactoryNotificationDashboard from "../NotificationContainer/FactoryNotificationDashboard";
import _ from "lodash";
import CollectionListContainer from "../CollectionListContainer/CollectionListContainer";
import SwatchLibraryContainer from "../SwatchLibraryContainer/SwatchLibraryContainer";

import Products from "../ProductContainer";
import Orders from "../OrdersContainer";
import SamplingTracker from "../SamplingTrackerContainer";
import ProductionTracker from "../ProductionTrackerContainer";
import { FACTORY_ADMIN, FACTORY_MANAGER, LOCO_SUPER, PRODUCT_DEVELOPMENT } from "../../constants/Auth";
import { getCurrentUserRole, refreshTokenIfOld } from "../../helpers/AuthHelper";
import MessagesContainer from "../MessagesContainer/index";

class ProductDevelopmentContainer extends Component {
  static propTypes = {
    openModal: PropTypes.func.isRequired,
    collections: PropTypes.object.isRequired,
    userDetails: PropTypes.object.isRequired,
    getAllCollections: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.props.factoryNewGetAllBrands();
    this.props.getUsers();
    this.props.getAllNotifications({ isLoader: true });
    if (getCurrentUserRole() == LOCO_SUPER || getCurrentUserRole() == PRODUCT_DEVELOPMENT) {
      this.props.setNotificationMode(true);
    }
    else {
      this.props.setNotificationMode(false);
    }

    let sidebarUpperMenu = [
      {
        name: "Collections",
        id: "collections",
        img: CollectionsImg,
        link: "/manager/brands/"
      },
      {
        name: "Sampling",
        id: "sampling",
        img: SamplingImg, // have to change this .. icon
        link: "/manager/sampling/in-progress/",
        baseRoute: "/manager/sampling"
      },
      {
        name: "production",
        id: "production",
        img: ProductionImg,
        link: "/manager/production/in-progress/",
        baseRoute: "/manager/production"
      },
      {
        name: "Orders",
        id: "orders",
        img: OrdersImg,
        link: "/manager/orders"
      },
      {
        name: "Swatch Library",
        id: "swatch Library",
        img: SwatchLibImg,
        link: "/manager/swatchLibrary/"
      },
      {
        name: "messages",
        id: "messages",
        img: InboxImg,
        link: "/manager/messages/"
      },
      {
        name: "Settings",
        id: "settings",
        img: SettingsImg,
        link: "/manager/settings/"
      }
    ];

    if (getCurrentUserRole() === FACTORY_ADMIN || getCurrentUserRole() === FACTORY_MANAGER) {

      sidebarUpperMenu = [
        {
          name: "Collections",
          id: "collections",
          img: CollectionsImg,
          link: "/manager/brands/"
        },
        {
          name: "Sampling",
          id: "sampling",
          img: SamplingImg, // have to change this .. icon
          link: "/manager/sampling/in-progress/"
        },
        {
          name: "production",
          id: "production",
          img: ProductionImg,
          link: "/manager/production/in-progress/"
        },
        {
          name: "Settings",
          id: "settings",
          img: SettingsImg,
          link: "/manager/settings/"
        }
      ];

    }

    refreshTokenIfOld(this.props.refreshToken);

    this.state = {
      upperMenu: sidebarUpperMenu,
      userRole: getCurrentUserRole()
    };

  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.pathname === "/manager/brands/") {
      if (this.props.currentBrand.brandId !== null)
        this.props.setCurrentBrand({ brandId: null });
    }
  }

  openSendNotificationModal = () => {
    this.props.openModal("SEND_NOTIFICATION_MODAL", {
      hiddenFields: [
        {
          name: "notification",
          value: window.lf.notifs[this.props.match.params.design_id]
        }
      ]
    });
  };

  render() {
    const { upperMenu } = this.state;
    const { setCurrentBrand, currentBrand, notifications, setNotificationsRead } = this.props;

    const { modal, closeModal, userDetails } = this.props;

    return (
      <div
        className="brand-dash"
        onClick={() => {
          if (modal.isOpen) {
            closeModal();
          }
        }}
      >
        <Header
          brandName={_.get(currentBrand, "brand_name", null)}
          isFactory={userDetails.role === LOCO_SUPER || userDetails.role === FACTORY_ADMIN || userDetails.role === FACTORY_MANAGER}
          openModel={() =>
            this.props.openModal("NOTIFICATION_MODAL", {
              userDetails,
              isFactory: true,
              brandId: currentBrand && currentBrand.brandIid
            })
          }
          brandId={currentBrand && currentBrand.brandId}
          dontShowInLogin={window.appHistory.location !== "/auth/login/"}
          noBellIcon={getCurrentUserRole() !== LOCO_SUPER && getCurrentUserRole() !== PRODUCT_DEVELOPMENT}
          notifications={notifications}
          setNotificationsRead={setNotificationsRead}
          userDetails={userDetails}
        />
        <div
          className="brand-dash__main" // this.props.modal.isOpen
        >
          <Sidebar
            openSendNotificationModal={this.openSendNotificationModal}
            upperItems={upperMenu}
            lowerItems={[
              {
                name: "Notifications",
                id: "notifications",
                img: NotificationImg,
                link: "/manager/notifications/"
              }
            ]}
            userDetails={this.props.userDetails}
          />

          {/* // TODO : Add Messages Tab */}

          <div className="brand-dash__content">
            <PrivateRoute
              exact
              path="/manager/swatchLibrary"
              render={routeProps => {
                return (
                  <SwatchLibraryContainer {...routeProps} isFactory={true} isSuperAdmin={getCurrentUserRole() === 'LOCO_SUPER'} />
                );
              }}
            />
            <PrivateRoute
              exact
              path="/manager/brands"
              render={routeProps => {
                if (currentBrand.brandId) setCurrentBrand({ brandId: null });
                if (getCurrentUserRole() == LOCO_SUPER) {
                  this.props.setNotificationMode(true);
                }
                else {
                  this.props.setNotificationMode(false);
                }
                return (
                  <BrandSearch
                    {...routeProps}
                    openModal={this.props.openModal}
                    brands={this.props.brandsList}
                    selectedBrand={currentBrand}
                    isFactory={true}
                  />
                );
              }}
            />
            <PrivateRoute
              exact
              path="/manager/notifications/"
              render={routeProps => {
                const { brandId } = currentBrand;
                const redirectBrandId = this.props.brandsList[0]["brand_id"] || this.props.brandsList[0]["id"];
                return brandId ? <Redirect to={`/manager/brand/${brandId}/notifications/`} /> : <Redirect to={`/manager/brand/${redirectBrandId}/notifications/`} />;
              }}
            />
            <PrivateRoute
              exact
              path="/manager/brand/:brand_id/notifications/"
              render={routeProps => {
                let selectedBrand = {
                  ...this.props.brandsList.filter(
                    v =>
                      Number(v.brand_id) ===
                      Number(routeProps.match.params.brand_id)
                  )[0],
                  brandId: routeProps.match.params.brand_id
                };
                if (currentBrand.brandId !== selectedBrand.brandId) setCurrentBrand(selectedBrand);
                return (
                  <FactoryNotificationDashboard {...routeProps} />
                )
              }}
            />
            <PrivateRoute
              exact
              path="/manager/brands/:brand_id/collections/"
              render={routeProps => {
                let selectedBrand = {
                  ...this.props.brandsList.filter(
                    v =>
                      Number(v.brand_id) ===
                      Number(routeProps.match.params.brand_id)
                  )[0],
                  brandId: routeProps.match.params.brand_id
                };
                if (currentBrand.brandId !== selectedBrand.brandId) setCurrentBrand(selectedBrand);
                return (
                  <CollectionListContainer {...routeProps} isFactory={true} />
                );
              }}
            />
            <PrivateRoute
              exact
              path="/manager/brands/:brand_id/collections/:collection_id"
              render={routeProps => {
                let selectedBrand = {
                  ...this.props.brandsList.filter(
                    v =>
                      Number(v.brand_id) ===
                      Number(routeProps.match.params.brand_id)
                  )[0],
                  brandId: routeProps.match.params.brand_id
                };
                if (currentBrand.brandId !== selectedBrand.brandId) setCurrentBrand(selectedBrand);
                return (
                  <CollectionDetailsContainer
                    {...routeProps}
                    openModal={this.props.openModal}
                  />
                );
              }}
            />
            <PrivateRoute
              exact
              path="/manager/brands/:brand_id/collections/:collection_id/designs/:design_id"
              render={routeProps => {
                let selectedBrand = {
                  ...this.props.brandsList.filter(
                    v =>
                      Number(v.brand_id) ===
                      Number(routeProps.match.params.brand_id)
                  )[0],
                  brandId: routeProps.match.params.brand_id
                };
                if (currentBrand.brandId !== selectedBrand.brandId) setCurrentBrand(selectedBrand);
                return (
                  <DesignDetailsContainer
                    {...routeProps}
                    openModal={this.props.openModal}
                    isFactory={true}
                    isSuperAdmin={this.props.userDetails.role === LOCO_SUPER}
                    brandDetails={
                      this.props.brandsList.filter(
                        ({ id }) =>
                          Number(id) === Number(currentBrand.brandId)
                      )[0]
                    }
                  />
                );
              }}
            />
            <Route
              path="/manager/messages"
              render={routeProps => <MessagesContainer {...routeProps} />}
            />

            <Route
              path="/manager/settings/"
              render={routeProps => {
                if (currentBrand.brandId) setCurrentBrand({ brandId: null });
                return (
                  <AccountSettingsContainer {...routeProps} />
                )
              }
              }
            />
            <Route
              path="/manager/sampling/approved/"
              render={routeProps => {
                if (currentBrand.brandId) setCurrentBrand({ brandId: null });
                return (<Products {...routeProps} />)
              }
              }
            />
            <Route
              path="/manager/orders"
              render={routeProps => {
                if (currentBrand.brandId) setCurrentBrand({ brandId: null });
                return (<Orders {...routeProps} />)
              }
              }
            />
            <Route
              path="/manager/sampling/in-progress/"
              render={routeProps => {
                if (currentBrand.brandId) setCurrentBrand({ brandId: null });
                return (
                  <SamplingTracker
                    {...routeProps}
                    isFactory={true}
                    allBrands={this.props.brandsList}
                  />
                )
              }}
            />
            <Route
              path="/manager/production/in-progress/"
              render={routeProps => {
                if (currentBrand.brandId) setCurrentBrand({ brandId: null });
                return (<ProductionTracker
                  {...routeProps}
                  isFactory={true}
                  allBrands={this.props.brandsList}
                  userDetails={this.props.userDetails}
                />
                )
              }}
            />
            <Route
              path="/manager/production/dispatched/"
              render={routeProps => {
                if (currentBrand.brandId) setCurrentBrand({ brandId: null });
                return (<ProductionTracker
                  {...routeProps}
                  isFactory={true}
                  allBrands={this.props.brandsList}
                  userDetails={this.props.userDetails}
                />
                )
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  collections: state.collections,
  userDetails: state.auth.userDetails,
  designList: state.designs.list,
  brandsList: state.factory.brandsList,
  currentBrand: state.notificationView.currentBrand,
  modal: state.global.modal,
  notifications: state.notificationView.notificationsList,
});

const mapDispatchToProps = dispatch => ({
  openModal: (content, additionalProps) =>
    dispatch(actions.openModal(content, additionalProps)),
  closeModal: (content, additionalProps) =>
    dispatch(actions.closeModal(content, additionalProps)),
  getAllCollections: () => dispatch(actions.getAllCollections()),
  getUsers: () => dispatch(actions.getUsers()),
  getDesignsByCollectionId: collectionId =>
    dispatch(actions.getDesignsByCollectionId(collectionId)),
  factoryGetDetails: details => dispatch(actions.factoryGetDetails(details)),
  getCollectionsByBrandId: details =>
    dispatch(actions.getCollectionsByBrandId(details)),
  factoryNewGetAllBrands: () => dispatch(actions.factoryNewGetAllBrands()),
  setCurrentBrand: v => dispatch(actions.setCurrentBrand(v)),
  setNotificationMode: mode => dispatch(actions.setNotificationMode(mode)),
  getAllNotifications: details => dispatch(actions.getAllNotifications(details)),
  setNotificationsRead: notifications => dispatch(actions.setNotificationsRead(notifications)),
  refreshToken: () => dispatch(actions.refreshToken()),

});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ProductDevelopmentContainer)
);
