import {  
  getCurrentUserRole,
  getUser
} from "./AuthHelper";

import { FACTORY_ADMIN, FACTORY_MANAGER } from "./../constants/Auth";



export const withFactory = (params, key) => {
  const currentUser = getUser();
  const currentRole = currentUser.role;
  if(currentRole == FACTORY_ADMIN || currentRole == FACTORY_MANAGER){
    return {
      ...params, 
      [key]: currentUser.brandId
    }
  } else {
    return { ...params };
  }
}

