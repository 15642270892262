import React from "react";
import { Field } from "redux-form";
import Form from "../../UI/Form/Form";
import Input from "../../UI/Form/Input/Input";
import File from "../../UI/Form/File/File";

const DispatchFile = props => (
  <Form
    {...props}
    onSubmit={values => {
      if (!values["attachmentname"]) {
        alert("Please enter a file name before trying to upload.");
        return;
      }

      if (!values["dispatchfiles"]) {
        alert("Please select a file before trying to upload.");
        return;
      }

      if (props.invalidFileNames && props.invalidFileNames.includes(values["attachmentname"])) {
        alert("Please use another file name");
        return;
      }

      props.onSubmit(values);
    }}
    form="addDispatchFile"
  >
    {({ changeFormValue }) => (
      <div style={{ padding: "1em 2em" }}>
        <div className="flex" style={{ justifyContent: "center" }}>
          <File
            onChange={file => {
              changeFormValue("dispatchfiles", file);
            }}
            hasAdvancedOptions={false}
          />
        </div>
        <Field
          component={Input}
          name="attachmentname"
          label="File title"
          required
          type="text"
          disabled={props.initialValues && props.initialValues["attachmentname"]}
        />
      </div>
    )}
  </Form>
);

export default DispatchFile;
