import React from "react";
import Downshift from "downshift";
import icDropdown from "../../assets/images/navigation/ic-arrow-drop-down.svg";

const NotificationFilter = ({
  title,
  onChange,
  initialSelectedItem,
  filterOptions,
  selectedItem,
  className = ""
}) => {
  let optionalProps = {};

  if (selectedItem) {
    optionalProps.selectedItem = selectedItem;
  }

  return (
    <Downshift
      initialSelectedItem={initialSelectedItem}
      itemToString={item => item.label}
      {...optionalProps}
      onChange={selection => {
        onChange(selection);
        // alert(JSON.stringify(selection))
        // this.handleUserAuthorityChange(collab, selection);
      }}>
      {({
        getInputProps,
        getToggleButtonProps,
        getItemProps,
        getLabelProps,
        getMenuProps,
        isOpen,
        inputValue,
        highlightedIndex,
        selectedItem
      }) => {
        return (
          <div
            className={className}
            {...getToggleButtonProps()}
            type="none"
            style={{
              userSelect: "none",
              marginTop: "0.3rem",
              position: "relative",
              cursor: "pointer",
              border: "solid 1px var(--fill-grey-light2)",
              borderRadius: "8px",
              padding: "6px 12px",
              width: "138px",
              height: "48px"
            }}>
            <span {...getLabelProps()}>{title}</span>
            <span className="flex" style={{ alignItems: "center" }}>
              <input
                style={{
                  border: "none",
                  backgroundColor: "transparent",
                  maxWidth: "80px"
                }}
                className="select-input-transparent"
                readOnly={true}
                disabled={true}
                {...getInputProps()}
              />
              <img
                src={icDropdown}
                alt="dropdown"
                style={{ marginLeft: "auto" }}
              />
            </span>
            {isOpen ? (
              <div
                className="select-pane"
                onClick={e => {
                  e.stopPropagation();
                }}>
                {filterOptions
                  .filter(item => item.label !== selectedItem.label)
                  .map((item, index) => {
                    return (
                      <div
                        {...getItemProps({
                          className:
                            "in-selection collection-collab__label flex notification-filter",
                          index,
                          item,
                          key: item.label
                        })}>
                        {item.label}
                      </div>
                    );
                  })}
              </div>
            ) : null}
          </div>
        );
      }}
    </Downshift>
  );
};

export default NotificationFilter;
