import { orderBy } from "lodash";

export const FILTER_FABRIC_CATEGORY = [
  { label: "All", value: null }, // null
  { label: "Men", value: "10" },
  { label: "Women", value: "20" },
  { label: "Kids", value: "30" }
];

export const FILTER_MATERIAL_CATEGORY = [
  { label: "Men", value: "1" },
  { label: "Women", value: "2" },
  { label: "Kids", value: "3" }
];

export const FILTER_FABRIC_IDEAL_FOR = [
  { label: "Top", value: 0 },
  { label: "Bottom", value: 1 }
];

const FILTER_FABRIC_QUALITY_RAW = [
  { label: "Cotton", value: "1" },
  { label: "Linen", value: "2" },
  { label: "Polyester", value: "4" },
  { label: "Tencel", value: "5" },
  { label: "Viscose", value: "6" },
  { label: "Poly Cotton", value: "7" },
  { label: "Linen Cotton", value: "9" },
  { label: "Cotton Lycra", value: "13" },
  { label: "Poly Lycra", value: "15" },
  { label: "Viscose Lycra", value: "17" },
  { label: "Cotton Viscose Lycra", value: "23" },
  { label: "Poly Georgette", value: "26" },
  { label: "Poly Satin", value: "34" },
  { label: "Poly Viscose Lycra", value: "35" },
  { label: "Poly Lurex", value: "38" },
  { label: "Nylon Lycra", value: "39" },
    {
      label: "Cotton Viscose Linen",
      value: "42"
    },
    {
      label: "Cambric",
      value: "43"
    },
    {
      label: "Chambray",
      value: "44"
    },
    {
      label: "Poplin",
      value: "45"
    },
    {
      label: "Cotton Voile",
      value: "46"
    },
    {
      label: "Viscose Georgette",
      value: "47"
    },
    {
      label: "Poly Chiffon",
      value: "48"
    },
    {
      label: "Summercool",
      value: "49"
    },
    {
      label: "Taffeta",
      value: "50"
    },
    {
      label: "Net",
      value: "51"
    },
    {
      label: "Corduroy",
      value: "52"
    },
    {
      label: "Denim",
      value: "53"
    },
    {
      label: "Silk",
      value: "54"
    },
    {
      label: "Velvet",
      value: "55"
    },
    {
      label: "Leather",
      value: "56"
    },
    {
      label: "PU",
      value: "57"
    },
    {
      label: "Nylon",
      value: "58"
    },
    {
      label: "Poly Knit",
      value: "59"
    },
    {
      label: "Rib",
      value: "60"
    },
    {
      label: "Interlock",
      value: "62"
    },
    {
      label: "Poly Techno",
      value: "63"
    },
    {
      label: "Ponte Knit",
      value: "64"
    },
    {
      label: "Heather Knit",
      value: "65"
    },
    {
      label: "Single Jersey",
      value: "66"
    },
    {
      label: "Poly Crepe",
      value: "67"
    },
    {
      label: "Linen Silk",
      value: "68"
    },
    {
      label: "Cotton Silk",
      value: "61"
    },
    {
      label: "Wool",
      value: "69"
    },
    {
      label: "Acrylic",
      value: "70"
    },
    {
      label: "Poly Spandex",
      value: "71"
    },
    {
      label: "Scuba",
      value: "72"
    },
    {
      label: "Roma",
      value: "73"
    }
];

export const FILTER_FABRIC_QUALITY = orderBy(
  FILTER_FABRIC_QUALITY_RAW,
  ["label"],
  ["asc"]
);

export const FILTER_FABRIC_TYPE_RAW = [
  { label: "1x1", value: "1" },
  { label: "2x2", value: "2" },
  { label: "Checks", value: "3" },
  { label: "Printed", value: "4" },
  { label: "Chambray", value: "5" },
  { label: "Recategorized", value: "7" },
  { label: "Buta/Swiss Dot", value: "9" },
  { label: "French Terry", value: "15" },
  { label: "Gingham Checks", value: "16" },
  { label: "Fleece", value: "17" },
  { label: "HoneyComb", value: "18" },
  { label: "Stripes", value: "19" },
  { label: "Melange", value: "24" },
  { label: "Plain", value: "29" },
  { label: "Solid", value: "42" },
  { label: "Yarn Dyed", value: "53" },
  { label: "Yarn Dyed Checks", value: "61" },
  { label: "Dobby", value: "65" },
  { label: "Yarn Dyed Stripes", value: "67" },
  { label: "Foil Print", value: "71" },
  { label: "Crinkle", value: "74" },
  { label: "Fancy Knit", value: "76" },
  { label: "Flocking", value: "77" },
  { label: "Floral Print", value: "84" },
  { label: "Animal Print", value: "85" },
  { label: "Polka Dot", value: "86" },
  { label: "Space Dyed", value: "87" },
  { label: "Schiffly", value: "88" }
];

export const FILTER_FABRIC_TYPE = orderBy(
  FILTER_FABRIC_TYPE_RAW,
  ["label"],
  ["asc"]
);

export const FILTER_FABRIC_WEAVE = [
  { label: "Crepe", value: "1" },
  { label: "Dobby", value: "2" },
  { label: "Drill", value: "3" },
  { label: "Embroidery", value: "4" },
  { label: "Herringbone", value: "5" },
  { label: "Interlock", value: "6" },
  { label: "Jersey", value: "7" },
  { label: "Net", value: "8" },
  { label: "Oxford", value: "9" },
  { label: "Pique", value: "10" },
  { label: "Plain", value: "11" },
  { label: "Rib", value: "12" },
  { label: "Satin", value: "13" },
  { label: "Single Jersey", value: "14" },
  { label: "Slub", value: "15" },
  { label: "Twill", value: "16" },
  { label: "Jacquard", value: "17" },
  { label: "Moss Crepe", value: "18" },
  { label: "Canvas", value: "19" },
  { label: "Knit", value: "20" },
  { label: "Chenille", value: "21" }
];

export const FILTER_FABRIC_COLORS = [
  { value: "1", color: "#000000", label: "Black" },
  { value: "2", color: "#0C090A", label: "Night" },
  { value: "3", color: "#736F6E", label: "Gray" },
  { value: "4", color: "#151B54", label: "Midnight Blue" },
  { value: "5", color: "#000080", label: "Navy Blue" },
  { value: "6", color: "#151B8D", label: "Denim Dark Blue" },
  { value: "7", color: "#2B60DE", label: "Royal Blue" },
  { value: "8", color: "#95B9C7", label: "Baby Blue" },
  { value: "9", color: "#6698FF", label: "Sky Blue" },
  { value: "10", color: "#79BAEC", label: "Denim Blue" },
  { value: "11", color: "#82CAFA", label: "Light Sky Blue" },
  { value: "12", color: "#C2DFFF", label: "Sea Blue" },
  { value: "13", color: "#ADDFFF", label: "Light Blue" },
  { value: "14", color: "#00FFFF", label: "Cyan or Aqua" },
  { value: "15", color: "#43C6DB", label: "Turquoise" },
  { value: "16", color: "#008080", label: "Teal" },
  { value: "17", color: "#4E8975", label: "Sea Green" },
  { value: "18", color: "#4E9258", label: "Forest Green" },
  { value: "19", color: "#00FF00", label: "Green" },
  { value: "20", color: "#FFFF00", label: "Yellow" },
  { value: "21", color: "#FFFFCC", label: "Cream" },
  { value: "22", color: "#F3E5AB", label: "Vanilla" },
  { value: "23", color: "#FFE5B4", label: "Peach" },
  { value: "24", color: "#FFDB58", label: "Mustard" },
  { value: "25", color: "#FBB917", label: "Saffron" },
  { value: "26", color: "#ADA96E", label: "Khaki" },
  { value: "27", color: "#B87333", label: "Copper" },
  { value: "28", color: "#493D26", label: "Mocha" },
  { value: "29", color: "#6F4E37", label: "Coffee" },
  { value: "30", color: "#C36241", label: "Rust" },
  { value: "31", color: "#C85A17", label: "Chocolate" },
  { value: "32", color: "#FF7F50", label: "Coral" },
  { value: "33", color: "#FF0000", label: "Red" },
  { value: "34", color: "#C24641", label: "Cherry Red" },
  { value: "35", color: "#810541", label: "Maroon" },
  { value: "36", color: "#E8ADAA", label: "Rose" },
  { value: "37", color: "#FAAFBE", label: "Pink" },
  { value: "38", color: "#FAAFBA", label: "Light Pink" },
  { value: "39", color: "#E56E94", label: "Blush Red" },
  { value: "40", color: "#F660AB", label: "Hot Pink" },
  { value: "41", color: "#F535AA", label: "Neon Pink" },
  { value: "42", color: "#FF00FF", label: "Magenta" },
  { value: "43", color: "#B93B8F", label: "Plum" },
  { value: "44", color: "#4B0082", label: "Indigo" },
  { value: "45", color: "#842DCE", label: "Dark Violet" },
  { value: "46", color: "#8D38C9", label: "Violet" },
  { value: "47", color: "#8E35EF", label: "Purple" },
  { value: "48", color: "#FDEEF4", label: "Pearl" },
  { value: "49", color: "#FEFCFF", label: "Milk White" },
  { value: "50", color: "#FFFFFF", label: "White" },
  { value: "51", color: "######", label: "Multi colour" },
  { value: "52", color: "#FFDB58", label: "Mustard" },
  { value: "53", color: "#FDD017", label: "Bright Gold" },
  { value: "54", color: "#EAC117", label: "Golden brown" },
  { value: "55", color: "#F2BB66", label: "Macaroni and Chees" },
  { value: "56", color: "#FBB917", label: "Saffron" },
  { value: "57", color: "#FFA62F", label: "Cantaloupe" },
  { value: "58", color: "#E9AB17", label: "Bee Yellow" },
  { value: "59", color: "#E2A76F", label: "Brown Sugar" },
  { value: "60", color: "#DEB887", label: "BurlyWood" },
  { value: "61", color: "#FFCBA4", label: "Deep Peach" },
  { value: "62", color: "#C9BE62", label: "Ginger Brown" },
  { value: "63", color: "#E8A317", label: "School Bus Yellow" },
  { value: "64", color: "#EE9A4D", label: "Sandy Brown" },
  { value: "65", color: "#C8B560", label: "Fall Leaf Brown" },
  { value: "66", color: "#D4A017", label: "Orange Gold" },
  { value: "67", color: "#C2B280", label: "Sand" },
  { value: "68", color: "#C7A317", label: "Cookie Brown" },
  { value: "69", color: "#C68E17", label: "Caramel" },
  { value: "70", color: "#B5A642", label: "Brass" },
  { value: "71", color: "#ADA96E", label: "Khaki" },
  { value: "72", color: "#C19A6B", label: "Camel brown" },
  { value: "73", color: "#CD7F32", label: "Bronze" },
  { value: "74", color: "#C88141", label: "Tiger Orange" },
  { value: "75", color: "#C58917", label: "Cinnamon" },
  { value: "76", color: "#AF7817", label: "Dark Goldenrod" },
  { value: "77", color: "#B87333", label: "Copper" },
  { value: "78", color: "#966F33", label: "Wood" },
  { value: "79", color: "#806517", label: "Oak Brown" },
  { value: "80", color: "#827839", label: "Moccasin" },
  { value: "81", color: "#F5F5DC", label: "Beige" },
  { value: "82", color: "#FFFFCC", label: "Cream" },
  { value: "83", color: "#FFFFCC", label: "Blonde" },
    {
      value: "84",
      label: "indian red",
      color: "#CD5C5C"
    },
    {
      value: "85",
      label: "light coral",
      color: "#F08080"
    },
    {
      value: "86",
      label: "salmon",
      color: "#FA8072"
    },
    {
      value: "87",
      label: "dark salmon",
      color: "#E9967A"
    },
    {
      value: "88",
      label: "light salmon",
      color: "#FFA07A"
    },
    {
      value: "89",
      label: "crimson",
      color: "#DC143C"
    },
    {
      value: "90",
      label: "firebrick",
      color: "#B22222"
    },
    {
      value: "91",
      label: "dark red",
      color: "#8B0000"
    },
    {
      value: "92",
      label: "pink",
      color: "#FFC0CB"
    },
    {
      value: "93",
      label: "light pink",
      color: "#FFB6C1"
    },
    {
      value: "94",
      label: "hot pink",
      color: "#FF69B4"
    },
    {
      value: "95",
      label: "deep pink",
      color: "#FF1493"
    },
    {
      value: "96",
      label: "medium violet red",
      color: "#C71585"
    },
    {
      value: "97",
      label: "pale violet red",
      color: "#DB7093"
    },
    {
      value: "98",
      label: "tomato",
      color: "#FF6347"
    },
    {
      value: "99",
      label: "orangered",
      color: "#FF4500"
    },
    {
      value: "100",
      label: "dark orange",
      color: "#FF8C00"
    },
    {
      value: "101",
      label: "orange",
      color: "#FFA500"
    },
    {
      value: "102",
      label: "gold",
      color: "#FFD700"
    },
    {
      value: "103",
      label: "light yellow",
      color: "#FFFFE0"
    },
    {
      value: "104",
      label: "lemon chiffon",
      color: "#FFFACD"
    },
    {
      value: "105",
      label: "light goldenrod yellow",
      color: "#FAFAD2"
    },
    {
      value: "106",
      label: "papaya whip",
      color: "#FFEFD5"
    },
    {
      value: "107",
      label: "moccasin",
      color: "#FFE4B5"
    },
    {
      value: "108",
      label: "peach puff",
      color: "#FFDAB9"
    },
    {
      value: "109",
      label: "pale goldenrod",
      color: "#EEE8AA"
    },
    {
      value: "110",
      label: "khaki",
      color: "#F0E68C"
    },
    {
      value: "111",
      label: "dark khaki",
      color: "#BDB76B"
    },
    {
      value: "112",
      label: "lavender",
      color: "#E6E6FA"
    },
    {
      value: "113",
      label: "thistle",
      color: "#D8BFD8"
    },
    {
      value: "114",
      label: "plum",
      color: "#DDA0DD"
    },
    {
      value: "115",
      label: "violet",
      color: "#EE82EE"
    },
    {
      value: "116",
      label: "orchid",
      color: "#DA70D6"
    },
    {
      value: "117",
      label: "medium orchid",
      color: "#BA55D3"
    },
    {
      value: "118",
      label: "medium purple",
      color: "#9370DB"
    },
    {
      value: "119",
      label: "rebecca purple",
      color: "#663399"
    },
    {
      value: "120",
      label: "blue violet",
      color: "#8A2BE2"
    },
    {
      value: "121",
      label: "dark violet",
      color: "#9400D3"
    },
    {
      value: "122",
      label: "dark orchid",
      color: "#9932CC"
    },
    {
      value: "123",
      label: "dark magenta",
      color: "#8B008B"
    },
    {
      value: "124",
      label: "purple",
      color: "#800080"
    },
    {
      value: "125",
      label: "slate blue",
      color: "#6A5ACD"
    },
    {
      value: "126",
      label: "dark slate blue",
      color: "#483D8B"
    },
    {
      value: "127",
      label: "medium slate blue",
      color: "#7B68EE"
    },
    {
      value: "128",
      label: "green yellow",
      color: "#ADFF2F"
    },
    {
      value: "129",
      label: "chartreuse",
      color: "#7FFF00"
    },
    {
      value: "130",
      label: "lawn green",
      color: "#7CFC00"
    },
    {
      value: "131",
      label: "lime green",
      color: "#32CD32"
    },
    {
      value: "132",
      label: "pale green",
      color: "#98FB98"
    },
    {
      value: "133",
      label: "light green",
      color: "#90EE90"
    },
    {
      value: "134",
      label: "medium spring green",
      color: "#00FA9A"
    },
    {
      value: "135",
      label: "spring green",
      color: "#00FF7F"
    },
    {
      value: "136",
      label: "medium sea green",
      color: "#3CB371"
    },
    {
      value: "137",
      label: "sea green",
      color: "#2E8B57"
    },
    {
      value: "138",
      label: "forest green",
      color: "#228B22"
    },
    {
      value: "139",
      label: "green",
      color: "#008000"
    },
    {
      value: "140",
      label: "dark green",
      color: "#006400"
    },
    {
      value: "141",
      label: "yellow green",
      color: "#9ACD32"
    },
    {
      value: "142",
      label: "olived rab",
      color: "#6B8E23"
    },
    {
      value: "143",
      label: "olive",
      color: "#808000"
    },
    {
      value: "144",
      label: "dark olive green",
      color: "#556B2F"
    },
    {
      value: "145",
      label: "medium aquamarine",
      color: "#66CDAA"
    },
    {
      value: "146",
      label: "dark sea green",
      color: "#8FBC8B"
    },
    {
      value: "147",
      label: "light sea green",
      color: "#20B2AA"
    },
    {
      value: "148",
      label: "dark cyan",
      color: "#008B8B"
    },
    {
      value: "149",
      label: "light cyan",
      color: "#E0FFFF"
    },
    {
      value: "150",
      label: "pale turquoise",
      color: "#AFEEEE"
    },
    {
      value: "151",
      label: "aquamarine",
      color: "#7FFFD4"
    },
    {
      value: "152",
      label: "turquoise",
      color: "#40E0D0"
    },
    {
      value: "153",
      label: "medium turquoise",
      color: "#48D1CC"
    },
    {
      value: "154",
      label: "dark turquoise",
      color: "#00CED1"
    },
    {
      value: "155",
      label: "cadet blue",
      color: "#5F9EA0"
    },
    {
      value: "156",
      label: "steel blue",
      color: "#4682B4"
    },
    {
      value: "157",
      label: "light steel blue",
      color: "#B0C4DE"
    },
    {
      value: "158",
      label: "powder blue",
      color: "#B0E0E6"
    },
    {
      value: "159",
      label: "light blue",
      color: "#ADD8E6"
    },
    {
      value: "160",
      label: "skyblue",
      color: "#87CEEB"
    },
    {
      value: "161",
      label: "light skyblue",
      color: "#87CEFA"
    },
    {
      value: "162",
      label: "deep skyblue",
      color: "#00BFFF"
    },
    {
      value: "163",
      label: "dodger blue",
      color: "#1E90FF"
    },
    {
      value: "164",
      label: "cornflower blue",
      color: "#6495ED"
    },
    {
      value: "165",
      label: "royal blue",
      color: "#4169E1"
    },
    {
      value: "166",
      label: "blue",
      color: "#0000FF"
    },
    {
      value: "167",
      label: "medium blue",
      color: "#0000CD"
    },
    {
      value: "168",
      label: "dark blue",
      color: "#00008B"
    },
    {
      value: "169",
      label: "midnight blue",
      color: "#191970"
    },
    {
      value: "170",
      label: "corn silk",
      color: "#FFF8DC"
    },
    {
      value: "171",
      label: "blanched almond",
      color: "#FFEBCD"
    },
    {
      value: "172",
      label: "bisque",
      color: "#FFE4C4"
    },
    {
      value: "173",
      label: "navajo white",
      color: "#FFDEAD"
    },
    {
      value: "174",
      label: "wheat",
      color: "#F5DEB3"
    },
    {
      value: "175",
      label: "tan",
      color: "#D2B48C"
    },
    {
      value: "176",
      label: "rosy brown",
      color: "#BC8F8F"
    },
    {
      value: "177",
      label: "sandy brown",
      color: "#F4A460"
    },
    {
      value: "178",
      label: "golden rod",
      color: "#DAA520"
    },
    {
      value: "179",
      label: "dark golden rod",
      color: "#B8860B"
    },
    {
      value: "180",
      label: "peru",
      color: "#CD853F"
    },
    {
      value: "181",
      label: "chocolate",
      color: "#D2691E"
    },
    {
      value: "182",
      label: "saddle brown",
      color: "#8B4513"
    },
    {
      value: "183",
      label: "sienna",
      color: "#A0522D"
    },
    {
      value: "184",
      label: "brown",
      color: "#A52A2A"
    },
    {
      value: "185",
      label: "maroon",
      color: "#800000"
    },
    {
      value: "186",
      label: "snow",
      color: "#FFFAFA"
    },
    {
      value: "187",
      label: "honeydew",
      color: "#F0FFF0"
    },
    {
      value: "188",
      label: "mintcream",
      color: "#F5FFFA"
    },
    {
      value: "189",
      label: "azure",
      color: "#F0FFFF"
    },
    {
      value: "190",
      label: "aliceblue",
      color: "#F0F8FF"
    },
    {
      value: "191",
      label: "ghost white",
      color: "#F8F8FF"
    },
    {
      value: "192",
      label: "white smoke",
      color: "#F5F5F5"
    },
    {
      value: "193",
      label: "seashell",
      color: "#FFF5EE"
    },
    {
      value: "194",
      label: "old lace",
      color: "#FDF5E6"
    },
    {
      value: "195",
      label: "floral white",
      color: "#FFFAF0"
    },
    {
      value: "196",
      label: "ivory",
      color: "#FFFFF0"
    },
    {
      value: "197",
      label: "antique white",
      color: "#FAEBD7"
    },
    {
      value: "198",
      label: "linen",
      color: "#FAF0E6"
    },
    {
      value: "199",
      label: "lavender blush",
      color: "#FFF0F5"
    },
    {
      value: "200",
      label: "misty rose",
      color: "#FFE4E1"
    },
    {
      value: "201",
      label: "gains boro",
      color: "#DCDCDC"
    },
    {
      value: "202",
      label: "light gray",
      color: "#D3D3D3"
    },
    {
      value: "203",
      label: "silver",
      color: "#C0C0C0"
    },
    {
      value: "204",
      label: "dark gray",
      color: "#A9A9A9"
    },
    {
      value: "205",
      label: "gray",
      color: "#808080"
    },
    {
      value: "206",
      label: "dim gray",
      color: "#696969"
    },
    {
      value: "207",
      label: "light slate gray",
      color: "#778899"
    },
    {
      value: "208",
      label: "slate gray",
      color: "#708090"
    },
    {
      value: "209",
      label: "dark slate gray",
      color: "#2F4F4F"
    }
];

export const FILTER_FABRIC_VENDORS = [
  { label: "VENDO1", value: "123" },
  { label: "VENDO5", value: "456" }
];

export const FILTER_TOP_TIME = [
  { label: "Last 7 days", value: "1" },
  { label: "Last 14 days", value: "2" },
  { label: "Last 30 days", value: "3" }
  // Custom case add date picker
  // { label: "Custom", value: "4" }
];

export const FILTER_UPLOAD_PERIOD = [
  { label: "Last 7 days", value: "1" },
  { label: "Last 14 days", value: "2" },
  { label: "Last 30 days", value: "3" }
];

export const FILTER_FABRIC_INSTOCK = [
  { label: "Include out of stock", value: 1 }
]; // { label: "Out of stock", value: 1 } removed this one

export const FILTER_FABRIC_IMPORTED = [
  { label: "Imported", value: 1 }
]; // { label: "Out of stock", value: 1 } removed this one


export const FILTER_FABRIC_YARDAGE_AVAILABE = [
  { label: "Available ", value: 1 }
]; // { label: "Out of stock", value: 1 } removed this one

export const FILTER_FABRIC_SWATCH_AVAILABE = [{ label: "Available", value: 1 }]; // { label: "Out of stock", value: 1 } removed this one

export const FILTER_FABRIC_FABRIC_CATEGORY = [
  { label: "Knit", value: "knit" },
  { label: "Woven", value: "woven" }
];

export const FILTER_FABRIC_VENDOR_TYPE = [
  { label: "Domestic", value: "0"},
  { label: "Overseas", value: "1"}
]

export const FILTER_FABRIC_AVAILABILITY = [
  { label: "Surplus", value: "1"},
  { label: "Made to Order", value: "0"}
]


// Were used as constant filters, now filters are fetched from the API
//
// export const BRAND_FILTERS = [
//   { title: "CATEGORY", options: FILTER_FABRIC_CATEGORY, type: "single" },
//   { title: "FABRIC QUALITY", options: FILTER_FABRIC_QUALITY, type: "multi" },
//   { title: "FABRIC TYPE", options: FILTER_FABRIC_TYPE, type: "multi" },
//   { title: "COLOR", options: FILTER_FABRIC_COLORS, type: "multi" }
// ];

// export const FACTORY_FILTERS = [
//   ...BRAND_FILTERS,
//   // { title: "VENDORS", options: FILTER_FABRIC_VENDORS, type: "multi" }
// ];

export const ADDITIONAL_STATIC_FILTERS = [
  { title: "CATEGORY", options: FILTER_FABRIC_CATEGORY, type: "single" },
  { title: "FABRIC CATEGORY", options: FILTER_FABRIC_FABRIC_CATEGORY, type: "single" },
  { title: "FABRIC WIDTH", type: 'multiInput', notVisibleToBrand: true},
  { title: "WEIGHT", type: 'multiInput', notVisibleToBrand: true},
  { title: "IDEAL FOR", options: FILTER_FABRIC_IDEAL_FOR, type: "multi" },
  { title: "AVAILABILITY", options: FILTER_FABRIC_AVAILABILITY, type: "single", notVisibleToBrand: true },
  { title: "VENDOR PRICE", type: 'multiInput', notVisibleToBrand: true},
  { title: "VENDOR TYPE", options: FILTER_FABRIC_VENDOR_TYPE, type: "single", notVisibleToBrand: true },
  { title: "QUANTITY", type: 'multiInput', notVisibleToBrand: true},
  { title: "MOQ", type: 'multiInput', notVisibleToBrand: true},
  { title: "STOCK IMPORTED", options: FILTER_FABRIC_IMPORTED, type: "multi", notVisibleToBrand: true },
  { title: "SWATCH STATUS", options: FILTER_FABRIC_SWATCH_AVAILABE, type: "single", notVisibleToBrand: true},
  { title: "YARDAGE STATUS",options: FILTER_FABRIC_YARDAGE_AVAILABE, type: "single", notVisibleToBrand: true},
  { title: "STOCK AVAILABILITY", options: FILTER_FABRIC_INSTOCK, type: "single", notVisibleToBrand: true  },
];

export const FILTER_SWATCH_AVAILABILITY = [
  { label: "Made to order", value: "Minimum Order Quantity(MOQ)" },
  { label: "Surplus", value: "Inventory" }
];
