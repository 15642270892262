import React, { useState } from "react";
import classnames from "classnames";
import PerfectScrollbar from "react-perfect-scrollbar";
import Popover from "react-tiny-popover";
import sum from "lodash/sum";
import CheckBox from "../../../../UI/Checkbox/Checkbox";
import Button from "../../../../UI/Button/Button";
import CustomInput from "../../../../UI/Form/Input/CustomInput";
import { IconArrowDropDown, IconPlus } from "../../../../UI/Icons";
import { ReactComponent as CloseImg } from "assets/images/navigation/ic-close.svg";
import './style.scss';

const isValidQuantity = (value) => {
  if (!(/^\d+$/g).test(value)) {
    return false;
  }

  return true;
}

const CheckList = ({
  sizes,
  selected = [],
  onChange,
}) => {
  return (<PerfectScrollbar className="formik_size-qty__checklist">
    {sizes.map(({ label, value }) => (<CheckBox
      key={value}
      label={label}
      value={value}
      checked={selected.includes(value)}
      onClick={e => {
        if (selected.includes(value)) {
          onChange(selected.filter(selValue => selValue !== value), { [value]: false });
        } else {
          onChange([...selected, value], { [value]: true });
        }
      }}
    />))}
  </PerfectScrollbar>);
}

const SizeQuantity = ({
  field,
  form,
  sizes,
  readonly = false,
  ...props
}) => {
  const [isSizePopoverVisible, setSizePopoverVisible] = useState(false);
  const { name, value = [] } = field;
  const { touched, errors, values: prevValues, setValues } = form;

  const selectedSizes = value.map(v => v.size);
  const totalQty = sum(value.filter(v => !v.hasError).map(v => +v.quantity));
  const countSizes = Object.keys(value).length;
  const mapValues = value.reduce((prev, next) => {
    prev[next.size] = next;
    return prev;
  }, {});

  // sets the field value; along with total quantity
  const _setFieldValue = (value) => {
    setValues({
      ...prevValues,
      [name]: value,
      quantity: sum(value.map(v => +v.quantity)),
    });
  }

  return <div className={classnames('formik_size-qty', {
    'formik_size-qty--readonly': readonly,
  })}>
    <div className="formik_size-qty__field-list">
      {
        value.map(sizeQty => <div className="formik_size-qty__field-item" key={sizeQty.size}>
          {
            readonly
              ? <>
                <span className="flex-grow">{sizeQty.size}</span>
                <span className="flex-grow">{sizeQty.quantity} pcs</span>
              </>
              : <>
                <CustomInput
                  className={sizeQty.hasError ? 'has-error' : ''}
                  label={sizeQty.size}
                  prefixText="Qty"
                  gluePrefix={true}
                  value={sizeQty.quantity}
                  onChange={e => {
                    const result = {
                      ...mapValues,
                      [sizeQty.size]: {
                        size: sizeQty.size,
                        quantity: e.target.value,
                        hasError: !isValidQuantity(e.target.value),
                      },
                    };
                    _setFieldValue(Object.values(result));
                  }}
                />
                <Button className="btn-remove-size tooltip-container" category="img shrink" onClick={() => {
                  const result = { ...mapValues };
                  delete result[sizeQty.size];
                  _setFieldValue(Object.values(result));
                }}>
                  <CloseImg className="img-close" />
                  <span className="tooltip dark-tooltip tooltip--v-bottom tooltip--h-center">Remove</span>
                </Button>
              </>
          }
        </div>)
      }
    </div>
    {
      !readonly && <>
        <Popover
          position="bottom"
          align="start"
          isOpen={isSizePopoverVisible}
          onClickOutside={() => setSizePopoverVisible(false)}
          content={
            <CheckList
              sizes={sizes}
              selected={selectedSizes}
              onChange={(_, changed) => {
                const result = { ...mapValues };
                Object.entries(changed).forEach(([size, isSelected]) => {
                  if (isSelected) {
                    result[size] = {
                      size,
                      quantity: 0,
                    };
                  } else {
                    delete result[size];
                  }
                });

                _setFieldValue(Object.values(result));
              }} />
          }
        >
          <Button
            className={classnames("btn-show-sizes", {
              'btn-show-sizes--popover': isSizePopoverVisible,
            })}
            category="primary-text grid-2"
            onClick={() => setSizePopoverVisible(!isSizePopoverVisible)}
            type="button"
          >
            <IconPlus color="blue" />
            ADD SIZE
            <IconArrowDropDown color="blue" />
          </Button>
        </Popover>
        {touched[field.name] && typeof (errors[field.name]) === 'string' && (
          <div className="error">{errors[field.name]}</div>
        )}
      </>
    }
    {
      countSizes > 0
        ? <div className="formik_size-qty__final">
          <div>
            {countSizes} sizes
          </div>
          <div>
            {totalQty} pcs
          </div>
        </div>
        : null
    }
  </div>
};

export default SizeQuantity;
