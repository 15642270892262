import React from "react";
import "./styles.scss";
import { getSmallImageUrlForCover } from "helpers/DesignHelpers";

export default function MaterialCard({
  children,
  img,
  onSelect,
  openModal,
  materialId,
  colors,
  generatedMaterialId,
  inStock=1,
  ...props
}) {
  const moreColors = colors => {
    const colorsArray = colors.slice(0, 6);
    return colorsArray.map(colorObj => (
      <>
        <span
          className="material-card__more-color-dot"
          style={{ background: colorObj.color }}
        ></span>
      </>
    ));
  };

  return (
    <div
      className="material-card flex-col"
      onClick={() => {
        // === send this materialId and brandid when brand is logged in ===
        openModal("VIEW_MATERIAL_DETAILS", {
          materialId: materialId,
          filterList: props.filterList
        });
      }}
    >
      {inStock === 0 && <div className="material-card__stock_label">Out of stock</div>}
      <img
        src={getSmallImageUrlForCover(img)}
        className="material-card__img"
        alt=""
      />
      <div className="content">
        <div className="caption">ID: {generatedMaterialId || "--"}</div>
        <div className="material-name">{children}</div>

        {colors.length > 0 && (
          <>
            <div className="colors-list">
              <div>Available in</div>
              <div className="flex center">
                {moreColors(colors)}
                {colors.length > 6 && <span>+{colors.length - 6}</span>}
              </div>
            </div>
            <div className="material-card__more_color">
              {`${colors.length} ${
                colors.length === 1 ? `color` : `colors`
              }`}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
