import React, { useState, useEffect } from "react";
import axios from "axios";
import { connect } from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";
import { withRouter } from "react-router-dom";
import { submit } from "redux-form";
import find from "lodash/find";
import round from "lodash/round";
import ReactTooltip from "react-tooltip";

import actions from "store/actions";

import "./styles.scss";
import "react-dropdown/style.css";

import Button from "components/UI/Button/Button";
import StockDetails from "./StockDetails";
import Notes from "./Notes";

import { get_service_endpoint } from "ServiceEndpoints";

import { canEditSwatch } from "helpers/swatchHelper";
import { transformForEdit } from "helpers/materialHelper";
import InventoryModal from "./../InventoryModal";
import AssignFactoryModal from "./../AssignFactoryModal";
import MaterialImage from "./MaterialImage";
import { ReactComponent as EditPencilIcon } from "assets/images/action/ic-edit-pencil.svg";
import { ReactComponent as TrashIcon } from "assets/images/materialLibrary/trash.svg";

import VIEWS, {
  MATERIAL_CATEGORY_HASHES,
  MATERIAL_TYPE_CATEGORY,
  MATERIAL_LIBRARY_TABS
} from "constants/MaterialLibrary";
import StaticTable from "../SizeTable/StaticTable";
import { useCallback } from "react";
import { formatNumber } from "helpers/i18nHelper";

const eq = get_service_endpoint("notificationView");

const tabTitles = [
  {
    id: 0,
    label: "Material Details"
  },
  {
    id: 1,
    label: "Stock I/O"
  },
  {
    id: 2,
    label: "Notes"
  }
];

const INITIAL_INVENTORY_PROPS = {
  editMode: false,
  initialValues: {}
};

const MaterialDetailModal = ({
  materialId,
  material = {},
  vendors,
  closeModal,
  openModal,
  paginate,
  deleteMaterial,
  ...props
}) => {
  const [materialDetail, setMaterial] = useState(material);
  const [activeTab, setActiveTab] = useState(0);
  const [currentView, setCurrentView] = useState(VIEWS.DETAILS);
  const [inventoryProps, setInventoryProps] = useState(INITIAL_INVENTORY_PROPS);
  const [factoryProps, setFactoryProps] = useState(INITIAL_INVENTORY_PROPS);

  const refs = tabTitles.reduce((acc, value) => {
    acc[value.id] = React.createRef();
    return acc;
  }, {});

  const handleClick = id => {
    refs[id].current.scrollIntoView();
    setActiveTab(id);
  };

  // == This effect pulls the swatch details ==
  useEffect(() => {
    axios
      .get(`${eq}/material`, { params: { materialId } })
      .then(res => {
        setMaterial(res.data);
      })
      .catch();
  }, []);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  const editMaterialHandler = () => {

    const hasMultiSize = materialDetail.material.materialType == MATERIAL_LIBRARY_TABS.trims && materialDetail.material.sizes && materialDetail.material.sizes.length > 0

    const initialValues = transformForEdit(
      materialDetail.material,
      materialDetail.colors
    );
    const materialTypeStr = materialDetail.material.materialType == MATERIAL_LIBRARY_TABS.fabric
      ? "fabric"
      : "trims";

    // close model
    closeModal();
    // open edit model
    openModal("EDIT_MATERIAL", {
      initialValues,
      vendors,
      afterSubmit: () => {
        props.filterMaterials({
          materialType: materialDetail.material.materialType,
          brandId: materialDetail.material.brandId
        });
      },
      extraFields: {
        materialType: materialDetail.material.materialType,
        brandId: materialDetail.material.brandId
      },
      editMode: true,
      title: `Edit ${materialTypeStr}`,
      footer: `Update ${materialTypeStr}`,
      hasMultiSize,
      staticTrimsSize: hasMultiSize ? Object.values(materialDetail.material.sizes).map(size => ({
        size: size.trimsSizesId.size,
        id: size.trimsSizesId.id,
        quantity: size.quantity
      })) : null
    });
  };

  const deleteMaterialHandler = () => {
    // close model
    closeModal();
    openModal("DELETE_MATERIAL", {
      deleteMaterialAction: () => {
        deleteMaterial(
          {
            materialId: materialDetail.material.id
          },
          props.filterList,
          paginate.currentPageOffSet
        );
      },
      extraFields: {
        materialType: materialDetail.material.materialType,
        brandId: materialDetail.material.brandId
      },
      editMode: true
    });
  };

  const updateInwardEntry = useCallback((item) => {
    switchViews(VIEWS.INVENTORY, {
      ...item,
      scheduleInward: true,
    });
  }, []);

  let { inwards = [], outwards = [], notes = [], colors = [] } = materialDetail;

  const {
    threadCount,
    construction,
    weight,
    weave,
    fabricWidth,
    categoryType,
    finish,
    shrinkage,
    fabricType,
    fabricQuality,
    image,
    name = "Default",
    idealFor,
    category,
    oldId,
    unit,
    price,
    generatedMaterialId,
    trimsCategory,
    quantity,
    inStock,
    sizes = [],
    testReportUrl,
    materialType,
    alertEnabled,
    alertQuantity,
  } = materialDetail.material || {};

  const IDEAL_FOR = {
    0: "Top",
    1: "Bottom",
    2: "Top, Bottom"
  };

  const switchViews = (view, initialValues) => {
    if (view === VIEWS.INVENTORY) {
      setInventoryProps({
        editMode: true,
        initialValues
      });
    } else if (view === VIEWS.FACTORY) {
      setFactoryProps({
        editMode: true,
        initialValues
      });
    } else {
      setInventoryProps(INITIAL_INVENTORY_PROPS);
    }
    setCurrentView(view);
  };

  let availableQuantity = 0,
    totalInwards = 0,
    totalOutwards = 0,
    materialQuantity = quantity,
    latestPrice = price;
  const availableMultiSizes = {};

  if (materialDetail.inwards) {
    if (sizes.length > 0) {
      sizes.forEach(size => {
        if (availableMultiSizes[size.trimsSizesId.id]) {
          availableMultiSizes[size.trimsSizesId.id].quantity = round(
            availableMultiSizes[size.trimsSizesId.id].quantity + size.quantity, 2
          );
        } else {
          availableMultiSizes[size.trimsSizesId.id] = {
            id: size.trimsSizesId.id,
            size: size.trimsSizesId.size,
            quantity: size.quantity
          };
        }
      });

      materialDetail.inwards.forEach(inward => {
        inward.size.forEach(size => {
          if (availableMultiSizes[size.trims_size_id]) {
            availableMultiSizes[size.trims_size_id].quantity = round(
              availableMultiSizes[size.trims_size_id].quantity + size.quantity, 2
            );
          } else {
            availableMultiSizes[size.trims_size_id] = {
              id: size.trims_size_id,
              size: size.size,
              quantity: size.quantity
            };
          }
        });
      });

      materialDetail.outwards.forEach(outward => {
        outward.size.forEach(size => {
          if (availableMultiSizes[size.trims_size_id]) {
            availableMultiSizes[size.trims_size_id].quantity = round(
              availableMultiSizes[size.trims_size_id].quantity - size.quantity, 2
            );
          } else {
            availableMultiSizes[size.trims_size_id] = {
              id: size.trims_size_id,
              size: size.size,
              quantity: size.quantity
            };
          }
        });
      });
    }
    totalInwards = materialDetail.inwards.filter(item => item.actualInwardDate).reduce(
      (acc, { quantity }) => acc + quantity,
      0
    );
    totalOutwards = materialDetail.outwards.reduce(
      (acc, { quantity }) => acc + quantity,
      0
    );
  }
  availableQuantity = materialQuantity + totalInwards - totalOutwards;

  if (materialDetail.inwards && materialDetail.inwards[0]) {
    latestPrice = materialDetail.inwards[0].price;
  }

  return (
    <div className="material__modal">
      {currentView === VIEWS.DETAILS ? (
        <React.Fragment>
          <div className="material__sections">
            <ul className="section-links">
              {tabTitles.map(tab => (
                <li
                  onClick={() => handleClick(tab.id)}
                  className={activeTab === tab.id ? "active" : ""}
                >
                  {tab.label}
                </li>
              ))}
            </ul>
          </div>
          <PerfectScrollbar style={{ maxHeight: "620px" }}>
            <div className="material__details">
              <div className="material__image_wrapper">
                <MaterialImage image={image} />
                <div className="material__details_form">
                  <div className="material__details_form_upper">
                    <div className="material__data_actions">
                      <div className="material__id">ID: {generatedMaterialId}</div>
                      <div className="material__data_icons">
                        <div onClick={editMaterialHandler}>
                          <EditPencilIcon />
                        </div>
                        <div onClick={deleteMaterialHandler}>
                          <TrashIcon />
                        </div>
                      </div>
                    </div>

                    <div className="material__name">{`${name}`}</div>
                    {/* ======  THESE ARE SWATCH DETAILS ===== */}
                    <div className="material__metadata grid-2">
                      <div>
                        <div className="label">Price</div>
                        <div>
                          ₹{latestPrice} / {unit && unit.shortName}
                        </div>
                      </div>
                      <div>
                        <div className="label">Inventory</div>
                        <div>
                          {formatNumber(availableQuantity)} {unit && unit.shortName}
                        </div>
                      </div>
                      <div>
                        <div className="label">Low Stock Alert</div>
                        <div>
                          {
                            (alertEnabled === '1' || alertEnabled === 1)
                              ? <>
                                {
                                  alertQuantity <= 0
                                    ? `On out of stock`
                                    : `Below ${alertQuantity} ${unit && unit.shortName}`
                                }
                              </>
                              : <span>OFF</span>
                          }
                        </div>
                      </div>
                    </div>
                    <div className="grid-2 material__actions">
                      <Button
                        category="black-ghost"
                        onClick={() => setCurrentView(VIEWS.INVENTORY)}
                      >
                        Add Inventory
                      </Button>
                      {inStock ? (
                        <Button
                          category="blue-bg"
                          onClick={() => setCurrentView(VIEWS.FACTORY)}
                        >
                          Assign to Factory
                        </Button>
                      ) : null}
                    </div>

                    <hr />
                    {sizes.length > 0 && (
                      <div>
                        <div className="material__description_heading">
                          Inventory Details
                        </div>
                        <StaticTable
                          sizes={Object.values(availableMultiSizes)}
                          tableHeading={`Quantity(${unit.unit})`}
                        />
                      </div>
                    )}
                    <div ref={refs[0]}>
                      <div className="material__description_wrapper">
                        <div>
                          <div
                            className="material__description_heading"
                            id="details"
                          >
                            Material Details
                          </div>
                        </div>
                      </div>
                      <div className="material__table_wrapper">
                        {fabricType && (
                          <div className="material__table_item">
                            <div className="material__table_item_label">
                              Pattern
                            </div>
                            <div className="material__table_item_value">
                              {fabricType && `${fabricType.name}`}
                            </div>
                          </div>
                        )}
                        {fabricQuality && (
                          <div className="material__table_item">
                            <div className="material__table_item_label">
                              Quality
                            </div>
                            <div className="material__table_item_value">
                              {fabricQuality.name}
                            </div>
                          </div>
                        )}
                        {trimsCategory && (
                          <div className="material__table_item">
                            <div className="material__table_item_label">
                              Category
                            </div>
                            <div className="material__table_item_value">
                              {trimsCategory.categoryName}
                            </div>
                          </div>
                        )}
                        {colors && (
                          <div className="material__table_item">
                            <div className="material__table_item_label">
                              Available colors
                            </div>
                            <div className="material__table_item_value flex colors">
                              {colors.map(colorDetails => (
                                <div
                                  className="material__color"
                                  style={{
                                    backgroundColor: colorDetails.color.code
                                  }}
                                  data-tip={colorDetails.color.name}
                                  data-class="over-modal"
                                  data-place="bottom"
                                ></div>
                              ))}
                            </div>
                          </div>
                        )}
                        {threadCount && (
                          <div className="material__table_item">
                            <div className="material__table_item_label">
                              Thread count
                            </div>
                            <div className="material__table_item_value">
                              {threadCount} threads / inch
                            </div>
                          </div>
                        )}
                        {weight && (
                          <div className="material__table_item">
                            <div className="material__table_item_label">
                              Weight
                            </div>
                            <div className="material__table_item_value">
                              {weight} gsm
                            </div>
                          </div>
                        )}
                        {weave ? (
                          <div className="material__table_item">
                            <div className="material__table_item_label">
                              Weave
                            </div>
                            <div className="material__table_item_value">
                              {weave.name}
                            </div>
                          </div>
                        ) : null}
                        {generatedMaterialId ? (
                          <div className="material__table_item">
                            <div className="material__table_item_label">
                              Material ID
                            </div>
                            <div className="material__table_item_value">
                              {generatedMaterialId}
                            </div>
                          </div>
                        ) : null}
                        {oldId ? (
                          <div className="material__table_item">
                            <div className="material__table_item_label">
                              Old ID
                            </div>
                            <div className="material__table_item_value">
                              {oldId}
                            </div>
                          </div>
                        ) : null}
                        {fabricWidth && (
                          <div className="material__table_item">
                            <div className="material__table_item_label">
                              Fabric Width
                            </div>
                            <div className="material__table_item_value">
                              {fabricWidth} inch
                            </div>
                          </div>
                        )}
                        {finish && (
                          <div className="material__table_item">
                            <div className="material__table_item_label">
                              Finish
                            </div>
                            <div className="material__table_item_value">
                              {finish}
                            </div>
                          </div>
                        )}
                        {shrinkage && (
                          <div className="material__table_item">
                            <div className="material__table_item_label">
                              Shrinkage
                            </div>
                            <div className="material__table_item_value">
                              {shrinkage}%
                            </div>
                          </div>
                        )}
                        {unit && (
                          <div className="material__table_item">
                            <div className="material__table_item_label">
                              Unit of Measurement
                            </div>
                            <div className="material__table_item_value">
                              {unit.unit}
                            </div>
                          </div>
                        )}
                        {category && (
                          <div className="material__table_item">
                            <div className="material__table_item_label">
                              Fabric category
                            </div>
                            <div className="material__table_item_value">
                              {MATERIAL_CATEGORY_HASHES[category].label}
                            </div>
                          </div>
                        )}
                        {categoryType !== null && categoryType !== undefined && (
                          <div className="material__table_item">
                            <div className="material__table_item_label">
                              Type of category
                            </div>
                            <div className="material__table_item_value">
                              {
                                find(MATERIAL_TYPE_CATEGORY, {
                                  value: String(categoryType)
                                }).title
                              }
                            </div>
                          </div>
                        )}
                        {idealFor != null && (
                          <div className="material__table_item">
                            <div className="material__table_item_label">
                              Ideal for
                            </div>
                            <div className="material__table_item_value">
                              {IDEAL_FOR[idealFor]}
                            </div>
                          </div>
                        )}
                        {testReportUrl != null && (
                          <div className="material__table_item">
                            <div className="material__table_item_label">
                              Test report
                            </div>
                            <div className="material__table_item_value">
                              <a className="link-blue"
                                href={testReportUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                              >Download</a>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div ref={refs[1]}>
                      <StockDetails
                        inwards={inwards}
                        outwards={outwards}
                        unit={unit}
                        openModal={openModal}
                        image={image}
                        closeModal={closeModal}
                        switchViews={switchViews}
                        updateInwardEntry={updateInwardEntry}
                      />
                    </div>
                    <div className="material__notes_container" ref={refs[2]}>
                      <Notes
                        notesList={notes}
                        material={materialDetail.material}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </PerfectScrollbar>
        </React.Fragment>
      ) : currentView === VIEWS.INVENTORY ? (
        <InventoryModal
          image={image}
          switchViews={switchViews}
          material={materialDetail.material}
          filterList={props.filterList}
          vendors={vendors}
          {...inventoryProps}
        />
      ) : (
            <AssignFactoryModal
              image={image}
              switchViews={switchViews}
              material={materialDetail.material}
              availableQuantity={availableQuantity}
              filterList={props.filterList}
              trimsAvailableSize={availableMultiSizes}
              {...factoryProps}
            />
          )}
    </div>
  );
};

const mapStateToProps = ({ materialLibrary, collections }) => ({
  collectionsProp: collections,
  paginate: materialLibrary.paginate
});

const mapDispatchToProps = dispatch => ({
  filterMaterials: (details, pageOffset = null) =>
    dispatch(actions.filterMaterials(details, pageOffset)),
  getMaterialFilters: details => dispatch(actions.getMaterialFilters(details)),
  openModal: (payload, additionalProps) =>
    dispatch(actions.openModal(payload, additionalProps)),
  closeModal: () => dispatch(actions.closeModal()),
  submitForm: formName => dispatch(submit(formName)),
  deleteMaterial: (...details) => dispatch(actions.deleteMaterial(...details))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MaterialDetailModal)
);
