import React, { Component } from "react";
import cloneDeep from "lodash/cloneDeep";
import filter from "lodash/filter";
import "react-dropdown/style.css";

import SampleRow from "./SampleRow";
import { getSampleDetails } from "../../../store/actions/DesignActions";

import { connect } from "react-redux";
import skippedIcon from "../../../assets/images/sampling/skip-sampling.svg";
import NavHeaders from "components/NavHeaders";

import { getCurrentUserRole } from "../../../helpers/AuthHelper";
import { APPROVED } from "../../../constants/Sample";

class NewForm extends Component {
  state = {
    showSelect: false,
    existingSamples: [],
    sampleSize: "",
    sampleError: false,
    sampleContainer: [],
    sampleKeys: {}, //Object.keys(this.props.sampleDetails)
    sampleDetails: this.props.sampleDetails,
    versionHistory: {},

    // new sample api's ****
    allSamplesNewAndPrev: [],
    isOpen: false
  };

  componentWillReceiveProps(nextProps) {
    this.setState({ versionHistory: nextProps.versionHistoryDetails });

    if (true) {
      const { newSampleDetails } = nextProps;
      this.setState({
        // sampleDetails: nextProps.sampleDetails,
        allSamplesNewAndPrev: newSampleDetails.samples
          .filter(sample => sample.isProductionSample === 0)
          .sort((firstEl, secondEl) => secondEl.id - firstEl.id)
      });
    }
  }

  changeDataHandler = (key, id, value) => {
    const { allSamplesNewAndPrev } = this.state;

    let updatedSamples = allSamplesNewAndPrev
      .map(sample => {
        if (sample.id === id) {
          sample[key] = value;
          sample.showCancel = true;
          return { ...sample };
        }
        return sample;
      })
      .sort((firstEl, secondEl) => secondEl.id - firstEl.id);

    this.setState({ allSamplesNewAndPrev: updatedSamples });
  };

  saveDataForCancel = () => {
    this.setState({
      savedSampleDetails: cloneDeep(this.state.sampleDetails)
    });
  };

  // ============ this takes key from sample row and returns sample history array ======
  getVersionHistoryDetailHandler = key => {
    const { versionHistory } = this.state;

    if (Object.keys(versionHistory).length > 0) {
      if (versionHistory[key]) {
        return versionHistory[key];
      } else {
        return [];
      }
    } else {
      return [];
    }
  };

  sampleNotRequiredObj = (
    { name: designName, id: designId, id: design_id },
    { id: collectionId, brand: brandId },
    { sampleRootId = null }
  ) => {
    return {
      collectionId,
      designId,
      brandId,
      designName,
      design_id,
      showCancel: false,
      sampleRootId
    };
  };

  render() {
    const {
      sampleSize,
      allSamplesNewAndPrev,
      isOpen
    } = this.state;

    const {
      design,
      isFactory,
      newSampleDetails
    } = this.props;

    // Only factory/ lfusers can see the due date/factory data
    const samplingSkipped = design.skip_sampling;

    const validSamples = filter(
      allSamplesNewAndPrev,
      sample =>
        sample.sampleType !== "Sample Not Required" &&
        sample.isProductionSample === 0
    );
    if (this.props.isLoading) {
      return <div>loading....</div>;
    }

    return (
      <>
        <div>
          {validSamples.length > 0 ? (
            <div className="title-sampling-req flex">
              <span className="flex-grow">Requested samples</span>
            </div>
          ) : samplingSkipped ? (
            <div className="flex center sampling-details__skipped">
              <img src={skippedIcon} alt="" />
              <div>
                <div className="text">Sampling skipped</div>
                <div className="subtext">
                  Prototype sample was not requested for this design
                </div>
              </div>
            </div>
          ) : null}
        </div>
        {validSamples.length > 0 && (
          <NavHeaders userRole={getCurrentUserRole()} tab="details" />
        )}
        <div
          className={`sample-row ${
            isOpen
              ? "accordion-container__opened"
              : "accordion-container__closed"
          }`}
        >
          {(allSamplesNewAndPrev.length > 0 || isFactory) && (
            <>
              {Object.keys(this.props.newSampleDetails).length &&
                allSamplesNewAndPrev.map(
                  (sample, index) =>
                    sample.sampleType !== "Sample Not Required" &&
                    (() => {
                      const sampleApproved = sample.sampleStatus === APPROVED;
                      if (!isOpen && sampleApproved && index !== 0) return null;
                      return (
                        <SampleRow
                          // new sample ap's

                          newSampleDetails={allSamplesNewAndPrev}
                          // ***************
                          data={sample}
                          sampleSize={sampleSize}
                          changeData={this.changeDataHandler}
                          saveDataForCancel={this.saveDataForCancel}
                          openSampleUpdateModal={
                            this.props.openSampleUpdateModal
                          }
                          designId={this.props.designDetail["id"]}
                          postPreProductionCommit={
                            this.props.postPreProductionCommit
                          }
                          isSuperAdmin={this.props.isSuperAdmin}
                          isFactory={this.props.isFactory}
                          openFileDetailsModel={this.props.openFileDetailsModel}
                          openVersionHistoryModal={
                            this.props.openVersionHistoryModal
                          }
                          getVersionHistoryDetail={
                            this.getVersionHistoryDetailHandler
                          }
                          openCourierDetailsModel={
                            this.props.openCourierDetailsModel
                          }
                          versionDetail={this.state.versionHistory}
                          collection={this.props.collection}
                          design={this.props.design}
                          openModal={this.props.openModal}
                          prevFactoryId={
                            index > 0 &&
                            allSamplesNewAndPrev[index - 1].factoryId
                          }
                          prevFactoryName={
                            index > 0 &&
                            allSamplesNewAndPrev[index - 1].factoryName
                          }
                          quoteQuantity={newSampleDetails.quantity}
                          userDetails={this.props.userDetails}
                        />
                      );
                    })()
                )}
              {allSamplesNewAndPrev.length > 1 && !isOpen && (
                <div
                  onClick={() => {
                    this.setState({
                      isOpen: true
                    });
                  }}
                  className={`accordion-title`}
                >
                  {allSamplesNewAndPrev.length - 1} Approved Samples
                  <span className="accordion-icon" />
                </div>
              )}
            </>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.global.loader.isLoading
});

const mapDispatchToProps = dispatch => ({
  getSampleDetails: params => dispatch(getSampleDetails(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(NewForm);
