import React from "react";
import styles from "../Product/Product.module.scss";
import Tabs from "../ProductionTracker/Tabs";

const HeaderWithFilters = ({
  allBrands,
  onChange,
  collections = [],
  selectCollection,
  applyCollections,
  isFactory,
  cancelCollectionFilter,
  currentUser,
  getFiltersValue,
  showReset,
  resetFilters,
  totalItems,
  render,
  factories,
  isSuperAdmin
}) => {

  let totalCounts = {};

  if (totalItems) {
    totalCounts = {
      inProgress:
        totalItems.inProgressCount !== undefined
          ? totalItems.inProgressCount
          : totalItems.totalCount,
      approved:
        totalItems.approvedCount !== undefined
          ? totalItems.approvedCount
          : totalItems.totalCount
    };
  }

  return (
      <div
        className={styles.header__filter_wrapper}
        style={{ padding: "16px 44px 0" }}
      >
        <Tabs
          isFactory={isFactory}
          labelStyles={{ textTransform: "uppercase", fontSize: "12px" }}
          totalCounts={{ ...totalCounts }}
        />
        {render()}
      </div>
  );
};

export default HeaderWithFilters;
