import { handleActions } from "redux-actions";

const INITIAL_STATE = {
  tableData: {},
  disabledTableData:{},
  materialData: {},
  supplierData: {}
};

const FabricQualitiesReducer = handleActions(
  {
    GET_MATERIAL_DATA: (state, action) => {
      return {
        ...state,
        materialData: action.payload,
      };
    },
    GET_TOP_SELL_TABLE_DATA: (state, action) => {
      return {
        ...state,
        tableData: action.payload
      }
    },
    GET_DISABLE_TABLE_DATA: (state, action) => {
      return {
        ...state,
        disabledTableData: action.payload
      }
    },
    GET_SUPPLIER_DATA: (state, action) => {
      return {
        ...state,
        supplierData: action.payload
      }
    },
  },

  INITIAL_STATE
);

export default FabricQualitiesReducer;
