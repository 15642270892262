import React, { Component } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import moment from "moment";
import classNames from "classnames";
import CloseImg from "../../../../assets/images/navigation/ic-close.svg";
import ChevronRightImg from "../../../../assets/images/navigation/chevron-right-shadow.svg";
import DownloadImg from "../../../../assets/images/action/ic-download.svg";

import { TIMESTAMP_FORMAT } from "../../../../constants/Date";
import { checkPic, getBgUrl } from "../../../../helpers/DesignHelpers";
import Button from "../../Button/Button";
import "./index.scss";

export default class ImageModal extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    selected: PropTypes.object.isRequired,
    files: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  state = {
    selected: null,
    selectedIndex: -1,
    canNavigateRight: false,
    canNavigateLeft: false,

    propSelected: null,
    propFiles: null,
  };

  constructor(props) {
    super(props);
    this.el = document.createElement('div');
  }

  static getDerivedStateFromProps(nextProps, oldState) {
    const nextState = {};

    if (nextProps.selected !== oldState.propSelected) {
      nextState.propSelected = nextProps.selected;
      nextState.selected = nextProps.selected;
      nextState.selectedIndex = nextState.selected ? nextProps.files.findIndex(item => item.id === nextState.selected.id) : -1;
    }

    if (nextProps.files !== oldState.propFiles) {
      nextState.propFiles = nextProps.files;
      nextState.files = nextProps.files;

      if (!nextState.selected && !oldState.selected && nextProps.files && nextProps.files.length > 0) {
        nextState.selected = nextProps.files[0];
        nextState.selectedIndex = 0;
      }
    }

    if (nextState.hasOwnProperty('selectedIndex')) {
      nextState.canNavigateLeft = nextState.selectedIndex > 0;
      nextState.canNavigateRight = nextState.selectedIndex !== -1 && nextState.selectedIndex < nextProps.files.length - 1;
    }

    return Object.keys(nextState).length > 0 ? nextState : null;
  }

  componentDidMount() {
    window.addEventListener("keydown", this._handleKeyDown.bind(this), false);
    document.body.appendChild(this.el);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this._handleKeyDown.bind(this), false);
    document.body.removeChild(this.el);
  }

  shouldComponentUpdate(nextProps) {
    return (nextProps.isVisible || nextProps.isVisible !== this.props.isVisible);
  }

  _handleKeyDown(event) {
    if (event.keyCode === 27) {
      this.props.onClose();
      event.stopPropagation();
    } else if (event.key === 'ArrowRight') {
      this._handleNavigation(1);
    } else if (event.key === 'ArrowLeft') {
      this._handleNavigation(-1);
    }
  }

  _selectIndex = (index) => {
    const { files } = this.props;

    this.setState({
      selected: files[index],
      selectedIndex: index,
      canNavigateLeft: index > 0,
      canNavigateRight: index < files.length - 1,
    });
  }

  _handleNavigation = (direction) => {
    const { selectedIndex, canNavigateLeft, canNavigateRight } = this.state;
    const newIndex = selectedIndex + direction;

    if ((direction < 0 && !canNavigateLeft)
      || (direction > 0 && !canNavigateRight)) {
      return;
    }

    this._selectIndex(newIndex);
  }

  _handleFileSelect = (file, index) => {
    this._selectIndex(index);
  }

  _renderContent() {
    const { title, onClose } = this.props;
    const { canNavigateLeft, canNavigateRight, files } = this.state;
    const { id, url, name, createdAt, uploadedBy } = this.state.selected || {};
    const isImage = checkPic(url);

    return <div className="modal__container image-modal__container">
      <div className="image-modal">
        <div className="image-modal__view flex">
          <div className="image-modal__img-frame">
            <img src={isImage ? url : getBgUrl(url)} alt="file" />
            <Button
              category="img shrink"
              className="cta-navigate-left"
              disabled={!canNavigateLeft}
              onClick={() => this._handleNavigation(-1)}
            >
              <img className="rot-180" src={ChevronRightImg} alt="" />
            </Button>
            <Button
              category="img shrink"
              className="cta-navigate-right"
              disabled={!canNavigateRight}
              onClick={() => this._handleNavigation(1)}
            >
              <img src={ChevronRightImg} alt="" />
            </Button>
          </div>
          <div className="image-modal__img-list">
            {files.map((file, index) => <Button
              key={file.id}
              category="img shrink"
              className={classNames("image-modal__img-item", {
                'image-modal__img-item--selected': file.id === id
              })}
              onClick={() => this._handleFileSelect(file, index)}
            >
              <img src={file.displayUrl} alt="" />
            </Button>)}
          </div>
        </div>
        <div className="image-modal__meta flex-col">
          <div className="image-modal__header flex">
            <span className="f-sz-xl f-w-bold">{title}</span>
            <span onClick={onClose} className="image-modal__close-img">
              <img src={CloseImg} alt="close" />
            </span>
          </div>
          <div className="image-modal__content">
            <div className="image-modal__label f-sz-sm ">File Name</div>
            <div className="">{name}</div>
            {
              uploadedBy && <>
                <div className="image-modal__label f-sz-sm ">Uploaded By</div>
                <div className="">{uploadedBy.name}</div>
              </>
            }
            {
              createdAt && <>
                <div className="image-modal__label f-sz-sm ">Date</div>
                <div className="">{moment(createdAt).format(TIMESTAMP_FORMAT)}</div>
              </>
            }
            <div className="image-modal__cta-download">
              <a href={url} className="btn btn--primary-text btn--grid-2">
                Download File <img src={DownloadImg} alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>;
  }

  render() {
    const { isVisible } = this.props;

    if (!isVisible) {
      return null;
    }

    return ReactDOM.createPortal(
      this._renderContent(),
      this.el
    );
  }
}
