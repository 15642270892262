import { handleActions } from "redux-actions";
import findIndex from "lodash/findIndex"
import { Mixpanel } from "./../../Mixpanel";

import { AUTH_ROLES } from "../../constants/Auth";
import { prepareUserDetails } from "../../helpers/AuthHelper";

const INITIAL_STATE = {
  loggedIn: localStorage.getItem("loggedIn") === "true",
  userDetails: prepareUserDetails(JSON.parse(localStorage.getItem("userDetails"))),
  list: {},
  createPassword: {
    inProgress: false,
    success: false,
    error: null
  },
  validateCreatePasswordToken: {
    inProgress: false,
    success: false,
    error: null
  },
  forgotPassword: {
    inProgress: false,
    success: false,
    error: null,
    email: ''
  },
};

const AuthReducer = handleActions(
  {
    LOGIN_SUCCESS: (state, action) => {
      const userDetails = prepareUserDetails({
        ...action.payload,
        role: AUTH_ROLES[Number(action.payload.role)],
      });

      localStorage.setItem("loggedIn", true);
      localStorage.setItem("role", userDetails.role);
      localStorage.setItem("brandName", action.payload.brand);
      localStorage.setItem("userDetails", JSON.stringify(userDetails));
      localStorage.setItem("accessToken", action.payload.accessToken);

      const {
        email,
        brandId,
        brand,
        name,
        role,
        id
      } = userDetails;
      // mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);
      Mixpanel.track('User Loggedin', {
        email,
        brandId,
        brand,
        name,
        role,
        id
      });

      return {
        ...state,
        loggedIn: true,
        userDetails: prepareUserDetails(userDetails),
      };
    },
    LOGOUT: (state, action) => {
      localStorage.clear();
      localStorage.setItem("loggedIn", false);
      return state;
    },
    REFRESH_TOKEN_SUCCESS: (state, action) => {
      const userDetails = prepareUserDetails({
        ...state.userDetails,
        ...action.payload,
        role: AUTH_ROLES[Number(action.payload.role)],
      });

      localStorage.setItem("role", userDetails.role);
      localStorage.setItem("userDetails", JSON.stringify(userDetails));
      localStorage.setItem("accessToken", action.payload.accessToken);

      return {
        ...state,
        userDetails: prepareUserDetails(userDetails),
      };
    },

    FORGOT_PASSWORD_RESET: (state, action) => {
      return {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          inProgress: false,
          success: false,
          error: null
        }
      }
    },
    FORGOT_PASSWORD_REQUEST: (state, action) => {
      const { email } = action.payload;
      return {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          inProgress: true,
          email
        }
      }
    },
    FORGOT_PASSWORD_SUCCESS: (state, action) => {
      return {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          inProgress: false,
          success: true,
          error: null
        }
      }
    },
    FORGOT_PASSWORD_FAILURE: (state, action) => {
      const { error } = action.payload;
      return {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          inProgress: false,
          success: false,
          error
        }
      }
    },

    VALIDATE_CREATE_PASSWORD_TOKEN_REQUEST: (state, action) => {
      const { token } = action.payload;
      return {
        ...state,
        validateCreatePasswordToken: {
          ...state.validateCreatePasswordToken,
          inProgress: true,
          token
        }
      }
    },
    VALIDATE_CREATE_PASSWORD_TOKEN_SUCCESS: (state, action) => {
      const { name, email, brandId } = action.payload;
      return {
        ...state,
        validateCreatePasswordToken: {
          ...state.validateCreatePasswordToken,
          inProgress: false,
          success: true,
          error: null,
          name,
          email,
          brandId,
        },
      }
    },
    VALIDATE_CREATE_PASSWORD_TOKEN_FAILURE: (state, action) => {
      const { error } = action.payload;
      return {
        ...state,
        validateCreatePasswordToken: {
          ...state.validateCreatePasswordToken,
          inProgress: false,
          success: false,
          error
        }
      }
    },

    CREATE_PASSWORD_REQUEST: (state) => {
      return {
        ...state,
        createPassword: {
          ...state.createPassword,
          inProgress: true
        }
      }
    },
    CREATE_PASSWORD_SUCCESS: (state) => {
      return {
        ...state,
        createPassword: {
          ...state.createPassword,
          inProgress: false,
          success: true,
          error: null
        }
      }
    },
    CREATE_PASSWORD_FAILURE: (state, action) => {
      const { error } = action.payload;
      return {
        ...state,
        createPassword: {
          ...state.createPassword,
          inProgress: false,
          success: false,
          error
        }
      }
    },

    GET_USER_LIST: (state, action) => {

      return {
        ...state,
        // Merge userDetails List Hashes
        list: {
          // ...state.usersList,
          ...action.payload.list
        }
      };
    },
    DELETE_USER: (state, action) => {

      return {
        ...state
      };
    },
    UPDATE_LOGGED_IN_USER_DETAILS: (state, action) => {
      localStorage.setItem(
        "userDetails",
        JSON.stringify({
          ...state.userDetails,
          ...action.payload
        })
      );
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          ...action.payload
        }
      };
    },
    UPDATE_LOGGED_IN_USER_IMAGE: (state, action) => {
      localStorage.setItem(
        "userDetails",
        JSON.stringify({
          ...state.userDetails,
          image: action.payload.image
        })
      );
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          image: action.payload.image
        }
      }
    },
    DELETE_LOGGED_IN_USER_IMAGE: state => {
      localStorage.setItem(
        "userDetails",
        JSON.stringify({
          ...state.userDetails,
          image: null
        })
      );
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          image: null
        }
      }
    },
    GET_LF_USERS: (state, action) => {
      return {
        ...state,
        lfUsers: action.payload
      }
    },
    ADD_LF_USER: (state, action) => {
      return {
        ...state,
        lfUsers: [
          ...state.lfUsers,
          action.payload
        ],
      }
    },
    EDIT_LF_USER: (state, action) => {
      const userIndex = findIndex(state.lfUsers, { id: action.payload.id });
      const currentLfUsers = Object.assign([], state.lfUsers);
      if(userIndex){
        currentLfUsers[userIndex] = { ...currentLfUsers[userIndex], ...action.payload };
      }
      return {
        ...state,
        lfUsers: currentLfUsers
      }
    },
    CREATE_USER: (state, action) => {
      return {
        ...state,
        list: {
          ...state.list,
          [action.payload.id]: action.payload
        }
      }
    },
    UPDATE_USER: (state, action) => {
      return {
        ...state,
        list: {
          ...state.list,
          [action.payload.id]: action.payload
        }
      }
    },
  },
  INITIAL_STATE
);

export default AuthReducer;
