import React from "react";
import Downshift from "downshift";
import PerfectScrollbar from "react-perfect-scrollbar";
import differenceBy from "lodash/differenceBy";
import { Formik, Form, FieldArray, Field } from "formik";

import icDropdown from "./../../../assets/images/navigation/ic-arrow-drop-down.svg";
import Trash from "../../../assets/images/action/ic-delete.faded.svg";
import Button from "./../../UI/Button/Button";

import "./FactoryMerchantAssociation.scss";

const itemToString = (item) => item.label;
const merchantShape = { label: "", value: "" };

const DownshiftSelect = ({
  options,
  onChange,
  selectedMerchant,
  index,
  selected,
}) => {
  return (
    <Downshift
      itemToString={itemToString}
      onChange={(selection) => {
        onChange(`merchants[${index}]`, selection);
      }}
      className="downshift_select"
      initialSelectedItem={selectedMerchant}
    >
      {({
        getInputProps,
        getToggleButtonProps,
        getItemProps,
        getLabelProps,
        getMenuProps,
        isOpen,
        inputValue,
        highlightedIndex,
      }) => {
        return (
          <div
            {...getToggleButtonProps()}
            type="none"
            className="select-container"
          >
            <div className="flex items-center justify-between">
              <Field name={`merchants[${index}]`} as="select">
                {({ field }) => (
                  <input
                    className="select-input-transparent"
                    disabled={true}
                    {...getInputProps()}
                    {...field}
                    value={selectedMerchant && selectedMerchant.label}
                    placeholder="Select"
                  />
                )}
              </Field>

              <img src={icDropdown} alt="dropdown" />
            </div>

            {isOpen ? (
              <div
                className="select-pane"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <div>
                  <PerfectScrollbar>
                    {differenceBy(options, selected, "value").map(
                      (item, index) => {
                        return (
                          <div
                            {...getItemProps({
                              className:
                                "in-selection collection-collab__label_left_aigned",
                              index,
                              item,
                              key: item.label,
                            })}
                          >
                            {item.label}
                          </div>
                        );
                      }
                    )}
                  </PerfectScrollbar>
                </div>
              </div>
            ) : null}
          </div>
        );
      }}
    </Downshift>
  );
};

const FactoryMerchantAssociation = ({
  editMode,
  updateAssociation,
  merchantsList,
  createAssociation,
  initialValues,
  factoryId,
}) => {
  const handleSubmit = (values) => {
    // Filter out empty merchants data
    const merchantsData = values.merchants.reduce((acc, { value }) => value ? acc.concat(value) : acc, []);
    const request = {
      factoryId,
      merchants: merchantsData,
    };
    if (editMode) {
      updateAssociation(request);
      return;
    }

    createAssociation(request);
  };
  return (
    <section className="factory-merchant-form">
      <Formik
        initialValues={{ merchants: initialValues }}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <FieldArray name="merchants">
              {({ insert, remove, push }) => (
                <div>
                  {values.merchants &&
                    values.merchants.map((data, index) => (
                      <div className="row" key={index}>
                        <DownshiftSelect
                          options={merchantsList}
                          selectedMerchant={data || {}}
                          onChange={setFieldValue}
                          index={index}
                          selected={values.merchants}
                        />

                        <img
                          src={Trash}
                          alt="trash"
                          onClick={() => {
                            remove(index);
                          }}
                        />
                      </div>
                    ))}
                  {values.merchants.length < merchantsList.length && (
                    <Button
                      category="btn"
                      className="addMerchant"
                      onClick={() => push(merchantShape)}
                      type="button"
                      // Disable button if previous select does not have value
                      disabled={
                        values.merchants[values.merchants.length - 1] &&
                        !values.merchants[values.merchants.length - 1].value
                      }
                    >
                      + Add more
                    </Button>
                  )}
                </div>
              )}
            </FieldArray>

            <div className="footer modal__footer">
              <span onClick={handleSubmit}>SAVE</span>
            </div>
          </Form>
        )}
      </Formik>
    </section>
  );

  // create associations
};

FactoryMerchantAssociation.defaultProps = {
  initialValues: {},
  merchantsList: [],
};

export default FactoryMerchantAssociation;
