import React, { Component } from "react";
import PropTypes from "prop-types";
import "./SamplingDetails.scss";

import moment from "moment";
import isEqual from "lodash/isEqual";

import { TIMESTAMP_FORMAT } from "../../../constants/Date";
import FileDetailsModel from "../FileDetailsModal/FileDetailsModal";

import axios from "axios";
import { get_service_endpoint } from "./../../../ServiceEndpoints";

import NewForm from "./NewForm";

import { openModal, closeModal } from "../../../store/actions/GlobalActions";
import { connect } from "react-redux";
import { getCurrentUserRole } from "../../../helpers/AuthHelper";

class SamplingDetails extends React.PureComponent {
  static propTypes = {
    attachSample: PropTypes.func,
    sampleDetails: PropTypes.object,
    openVersionHistoryModal: PropTypes.func
  };

  static defaultProps = {
    sampleDetails: {
      id: null
    }
  };

  state = {
    sampleDetails: this.props.sampleDetails,
    sampleTypesForm: {},
    sampleTypesFormChanged: false,
    versionHistoryDetails: {},
    // new sample api's
    newSampleDetails: {}
  };

  componentDidMount() {
    // // this.getVersionHistory();

    this.props.getSamplesByDesignId();
    if (this.props.newSampleDetails.designId) {
      this.getVersionHistory();
    }
  }

  componentWillReceiveProps(nextProps) {

    if (!isEqual(this.props.newSampleDetails, nextProps.newSampleDetails)) {
      this.setState({ newSampleDetails: nextProps.newSampleDetails }, () => {
        this.getVersionHistory();
      });
      // this.getAllCommentsHandler(this.props.design.id);
    }

    this.setState({
      sampleDetails: nextProps.newSampleDetails
    });
  }


  showModelHandler = (dataForFileModel = false) => {
    const { showModel } = this.state;

    if (dataForFileModel) {
      this.setState({ showModel: !showModel, dataForFileModel });
    } else {
      this.setState({ showModel: !showModel });
    }
  };

  getVersionHistory = () => {
    const designId = typeof (this.props.newSampleDetails.designId) === 'object'
      ? this.props.newSampleDetails.designId.id
      : this.props.newSampleDetails.designId;
    axios
      .get(`${get_service_endpoint("notificationView")}/samplehistory`, {
        params: { designid: designId }
      })
      .then(res => {
        if (Object.keys(res.data).length > 0) {
          this.setState({ versionHistoryDetails: res.data });
        }
      })
      .catch(err =>
        console.log("ERR WHILE GETTING THE VERSION HISTORY DETAILS !!!")
      );
  };

  render() {
    if (
      !this.props.sampleDetails
    ) {
      return null;
    }

    return (
      <div className="custom-scroll-container">
        <div className="sampling-details">
          <div className="req" />
          {this.state.showModel ? (
            <FileDetailsModel
              file={this.state.dataForFileModel}
              onClose={this.showModelHandler}
            />
          ) : null}

          <NewForm
            newSampleDetails={this.props.newSampleDetails}
            brandId={this.props.brandId}
            designDetail={this.props.design}
            isSuperAdmin={
              this.props.isSuperAdmin || getCurrentUserRole() === "LOCO_SUPER"
            }
            isFactory={
              this.props.isFactory ||
              getCurrentUserRole() === "FACTORY_ADMIN" ||
              getCurrentUserRole() === "FACTORY_MANAGER"
            }
            openFileDetailsModel={this.showModelHandler}
            openSampleUpdateModal={this.props.openSampleUpdateModal}
            openFileUploadModal={this.props.openFileUploadModal}
            postPreProductionCommit={this.props.postPreProductionCommit}
            openVersionHistoryModal={this.props.openVersionHistoryModal}
            openCourierDetailsModel={this.props.courierModel}
            collection={this.props.collection}
            design={this.props.design}
            versionHistoryDetails={this.state.versionHistoryDetails}
            openModal={this.props.openModal}
            closeModal={this.props.closeModal}
            userDetails={this.props.userDetails}
          />
          <input
            ref="sampleFileInput"
            className="hide"
            type="file"
            name="myfile"
            accept=".csv,.xls,.xlsx"
            onChange={val => {
              this.props.postSampleMeasurementSheet(
                {
                  sampleFile: val.target.files[0],
                  design: this.props.design,
                  sampleRootId: this.props.newSampleDetails.id
                },
                window.location.href
              );
            }}
          />
          <div className="sampling-details__view-file">
            <div className="sampling-details__view-file__header flex">
              <div className="left cell-v2">
                <div className="header f-sz-xl f-w-semi-bold">
                  Graded measurement sheet
                </div>
                <div className="sub-header f-color-faded">
                  Last updated on{" "}
                  {moment(this.props.newSampleDetails["updatedAt"]).format(
                    TIMESTAMP_FORMAT
                  )}
                </div>
              </div>
              {!this.props.isFactory && (
                <div className="right">
                  <button
                    className="btn--plain-blue"
                    onClick={() => {
                      if (
                        window.confirm(
                          "Existing file will be replaced. Do you want to continue ?"
                        )
                      )
                        this.refs["sampleFileInput"].click();
                    }}
                  >
                    {this.props.newSampleDetails.spreadSheetId
                      ? "Replace and Update File"
                      : "Upload File"}
                  </button>
                </div>
              )}
            </div>
            <div className="card__section">
              {this.props.newSampleDetails.spreadSheetId && (
                <iframe
                  ref={ref => (this.iframeRef = ref)}
                  title="MYFRAME"
                  width="100%"
                  height="500px"
                  frameborder="0"
                  src={`https://docs.google.com/spreadsheets/d/${this.props.newSampleDetails.spreadSheetId}/preview`}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userDetails: state.auth.userDetails
});

const mapDispatchToProps = dispatch => ({
  openModal: (name, additionalProps) =>
    dispatch(openModal(name, additionalProps)),
  closeModal: () => dispatch(closeModal())
});

export default connect(mapStateToProps, mapDispatchToProps)(SamplingDetails);
