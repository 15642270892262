import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { IconPlus } from '../../../../UI/Icons';
import { ReactComponent as TrashRedImg } from '../../../../../assets/images/action/trash-red-small.svg';
import { ReactComponent as TrashWhiteImg } from '../../../../../assets/images/action/trash-white-small.svg';
import { formatDecimals } from '../../../../../helpers/i18nHelper';
import { getCoverImage } from '../../../../../helpers/DesignHelpers';
import './style.scss';

export default class DesignCard extends React.Component {
  static propTypes = {
    design: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      sourceDesignId: PropTypes.string.isRequired,
      coverPic: PropTypes.string.isRequired,
      category: PropTypes.string.isRequired,
      quotePrice: PropTypes.number.isRequired,
      quoteQuantity: PropTypes.number.isRequired,
    }).isRequired,
    currency: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    isSelected: PropTypes.bool.isRequired,
    isHovered: PropTypes.bool,
  }

  render() {
    const { design, currency, isSelected, isHovered, onChange } = this.props;

    const url = getCoverImage(design);

    return <button
      onClick={() => onChange(!isSelected)}
      className={classnames("orders-design-card clickable", {
        'orders-design-card--hover': isHovered,
        'orders-design-card--selected': isSelected,
      })}
    >
      <div className="orders-design-card__cover-pic-container">
        <img src={url} alt="cover" className="orders-design-card__cover-pic" />
      </div>
      <div className="orders-design-card__content">
        <div className="orders-design-card__content__header flex-col">
          <div className="orders-design-card__design-name overflow-ellipsis">
            <span className="">
              {design.name}
            </span>
          </div>
          <div className="orders-design-card__design-id">
            {design.sourceDesignId}
          </div>
        </div>
        <div className="orders-design-card__content__footer flex">
          <div className="orders-design-card__design-price">
            {currency}&nbsp;{formatDecimals(design.quotePrice)}
          </div>
          <div className="orders-design-card__cta-list">
            {
              isSelected
                ? <div
                  className="orders-design-card__cta-remove btn btn--grid-2 btn--red-text btn--black-ghost"
                >
                  <TrashRedImg className="trash-red" style={{ width: '12px' }} />
                  <TrashWhiteImg className="trash-white" style={{ width: '12px' }} />
                  Remove
                </div>
                : <div
                  className="orders-design-card__cta-add btn btn--grid-2 btn--primary-text btn--black-ghost"
                >
                  <IconPlus
                    color={isSelected ? 'white' : 'blue'}
                    style={{ width: '12px' }} />
                  Add
                </div>
            }
          </div>
        </div>
      </div>
    </button>
  }
}
