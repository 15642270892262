import React, { useState, useEffect } from "react";
import s from "./Filters.module.scss";
import classnames from "classnames";
import FilterGroup from "./FilterGroup";
import PerfectScrollbar from "react-perfect-scrollbar";
import { openModal } from "../../store/actions/GlobalActions";
import DebounceInput from "./../../components/UI/DebounceInput/DebounceInput";
import { connect } from "react-redux";
import { ReactComponent as ArrowIcon } from "assets/images/navigation/ic-chevron-down-blue.svg";

const CATEGORY_FILTER = "CATEGORY";

const Filters = ({
  onFiltersChange,
  filters = [],
  filterList,
  openModal,
  materialLibrary,
  trims
}) => {
  const [searchActive, setSearchActive] = useState(false);
  useEffect(() => {}, [searchActive]);

  const CURRENT_FILTERS = trims
    ? filters.filter(filter => Boolean(!filter.hiddenForTrims))
    : filters;

  const handleFilterChange = (filterName, type) => list => {
    let selectedEntries;
    if (type === "multiInput") {
      selectedEntries = list;
    } else {
      selectedEntries = Object.entries(list)
        .filter(([key, value]) => value)
        .map(f => f[0]);
    }
    if(selectedEntries.length > 0 || Object.keys(selectedEntries).length > 0 || filterList[filterName]){
      onFiltersChange({ ...filterList, [filterName]: selectedEntries });
    }
  };

  const WithInput = ({
    label,
    style,
    inputStyle = {},
    type = "text",
    onChange,
    id,
    value,
    isError = false,
    required = false
  }) => (
    <div style={{ padding: "12px 0px", ...style }}>
      <div className="label__class" style={{ paddingBottom: "4px" }}>
        {label} {required ? <sup>*</sup> : null}
      </div>
      <DebounceInput
        onBlur={() => setSearchActive(false)}
        autoFocus={searchActive}
        value={filterList["search"]}
        placeholder="&#xf002; &nbsp; Search material"
        handleChange={data => {
          setSearchActive(true);
          onFiltersChange({ ...filterList, search: data });
        }}
      />
    </div>
  );

  return (
    <PerfectScrollbar
      style={{
        maxHeight: "100%",
        padding: "14px 16px",
        paddingBottom: "calc(100% - 96px)"
      }}
      className={"flex-col"}
    >
      <WithInput />
      <div
        className={classnames(s.head, s.section, "f-w-semi-bold f-sz-l flex")}
      >
        FILTERS
        {Object.entries(filterList)
          .filter(
            ([name, filters]) =>
              filters !== null &&
              typeof filters !== "string" &&
              filters !== undefined
          )
          .reduce(
            (acc, [name, filters]) =>
              acc || filters.length > 0 || Object.entries(filters).length > 0,
            false
          ) && (
          <div
            style={{
              marginLeft: "auto",
              paddingRight: "12px",
              color: "var(--fill-primary-medium)",
              cursor: "pointer",
              fontWeight: "300",
              fontSize: "12px"
            }}
            onClick={() => window.location.reload()}
          >
            Reset Filters
          </div>
        )}
      </div>
      {CURRENT_FILTERS.map(Filter => (
        <FilterItem
          Filter={Filter}
          openModal={openModal}
          handleFilterChange={handleFilterChange}
          materialLibrary={materialLibrary}
        />
      ))}
    </PerfectScrollbar>
  );
};

const FilterItem = ({
  Filter,
  openModal,
  handleFilterChange,
  materialLibrary
}) => {
  const [isOpen, setIsOpen] = useState(Filter.title === CATEGORY_FILTER);

  return (
    <div className={s.section} key={Filter.title}>
      {Filter.title !== CATEGORY_FILTER ? (
        <div
          className={classnames(s.head, "f-sz-l filter-title")}
          style={{
            fontWeight: "700",
            fontSize: "14px",
            display: "flex",
            justifyContent: "space-between"
          }}
          onClick={() => setIsOpen(!isOpen)}
        >
          {Filter.title === "VENDORS" && !materialLibrary ? (
            <>
              {Filter.title}
              <span
                onClick={() => {
                  openModal("ADD_NEW_VENDOR", {
                    hiddenFields: []
                  });
                  return null;
                }}
                style={{
                  cursor: "pointer",
                  color: "var(--fill-primary-medium)"
                }}
              >
                +ADD
              </span>
            </>
          ) : Filter.title === "FABRIC QUALITY" ? (
            "FABRIC COMPOSITION"
          ) : (
            Filter.title
          )}
          <ArrowIcon />
        </div>
      ) : (
        <div style={{ marginTop: "0.5em" }} />
      )}
        <FilterGroup
          {...Filter}
          filterChanged={handleFilterChange(Filter.title, Filter.type)}
          isOpen={isOpen}
        />
    </div>
  );
};
const mapDispatchToProps = dispatch => ({
  openModal: (content, additionalProps) =>
    dispatch(openModal(content, additionalProps))
});

export default connect(null, mapDispatchToProps)(Filters);
