import React, { useRef } from "react";
import PropTypes from "prop-types";

import { getFileData } from "helpers/swatchHelper";

import Trash from "assets/images/action/ic-delete.faded.svg";
import icPlcAttachment from "assets/images/editor/ic-attach-file.svg";
import icUpload from "assets/images/action/ic-upload.svg";

const anyChange = (prevProps, nextProps) => {
  if (nextProps.input.value) {
    return prevProps.input.value === nextProps.input.value;
  }
  return false;
};

const File = React.memo(
  ({
    input: { value, onChange },
    meta: { submitFailed, invalid },
    headerText,
    required,
    ...restProps
  }) => {
    const fileInputRef = useRef(null);

    const handleChange = e => {
      onChange(e.target.files[0]);
    };

    const deleteFile = () => {
      fileInputRef.current.value = "";
      onChange("");
    };

    const [containerBg, fileName] = getFileData(value);

    return (
      <div
        className="file-vertical material-file"
        style={{
          backgroundImage: value ? `url(${containerBg})` : null,
          border:
            submitFailed && invalid ? "1px dashed red" : "1px dashed #bfbfbf"
        }}
      >
        <input
          type="file"
          accept=".jpg, .png, .jpeg"
          onChange={handleChange}
          ref={fileInputRef}
          className={"hide"}
        />
        {value !== "" ? (
          <React.Fragment>
            <div className="selected flex">
              <div className="flex-col">
                <span className="input-file__meta-selected">
                  <span>{`${fileName.slice(0, 15)}...`}</span>
                  <div className="input-file__delete">
                    <img src={Trash} alt="" onClick={deleteFile} />
                  </div>
                </span>
              </div>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div
              className="attachment_icon"
              onClick={() => fileInputRef.current.click()}
            >
              <img
                style={{ margin: "auto" }}
                src={icPlcAttachment}
                alt="attachment"
              />
            </div>
            <div className="input-file__upload_image">
              <div>Upload Image</div>

              <img
                src={icUpload}
                alt="Upload"
                onClick={() => {
                  fileInputRef.current.click();
                }}
              />
            </div>
          </React.Fragment>
        )}
      </div>
    );
  },
  anyChange
);

File.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  }),
  headerText: PropTypes.string,
  required: PropTypes.bool
};

File.defaultProps = {
  headerText: "Image",
  required: false
};

export default File;
