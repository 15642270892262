import { getCurrentUserRole } from "./AuthHelper";
import { LOCO_SUPER, FABRIC_SUPPLIER, BDE, CLIENT_SUPER, CLIENT} from "./../constants/Auth"

export const canDeleteSwatch = () => {
  const allowedRoles = [LOCO_SUPER, FABRIC_SUPPLIER, BDE];
  return allowedRoles.includes(getCurrentUserRole()) ? true : false
}

export const canAddToDesign = () => {
  const allowedRoles = [LOCO_SUPER, CLIENT_SUPER, CLIENT];
  return allowedRoles.includes(getCurrentUserRole()) ? true : false
}

export const canShareWithContacts = () => {
  const allowedRoles = [BDE];
  return allowedRoles.includes(getCurrentUserRole()) ? true : false  
}

export const canEditSwatch = () => {
  const allowedRoles = [LOCO_SUPER, FABRIC_SUPPLIER];
  return allowedRoles.includes(getCurrentUserRole()) ? true : false  
}

export const getFileData = (fileValue) => {
  if(fileValue){
    if(typeof fileValue == "object"){
      return [window.URL.createObjectURL(fileValue), fileValue.name]
    } else {
      return [fileValue, fileValue.split("/").slice(-1)[0]]
    }
  }
  return []
}

const stringToBoolean = value => value == 1 ? "1" : value == "0" ? 0 : null;

const idToValue = value => {
  if(value == 0)
    return "Minimum Order Quantity(MOQ)";
  else if(value == 1)
    return "Inventory";
  return value   
}

export const transformForEdit = swatch => {
  const { image, rulerImage, extraImages, category, fabricQuality, color, fabricType, weave, vendorPrices, moreColors, inStock, imported, yardageAvailable, swatchAvailable, stockAvailability, availability, fabricAvailability } = swatch;
  return {
    ...swatch,
    category: category.map(s => String(s)),
    fabricQuality: String(fabricQuality.value),
    color: String(color.value),
    fabricType: String(fabricType.value),
    weave: weave ? String(weave.value) : null,
    vendorPrices: vendorPrices.map(({ vendorId, price }) => ({
      vendorId,
      price
    })),
    moreColors: moreColors.map(s=>String(s.value)),
    extraImages: extraImages.map(image=>({
      image
    })),
    inStock : stringToBoolean(inStock),
    imported: stringToBoolean(imported),
    yardageAvailable: stringToBoolean(yardageAvailable),
    swatchAvailable: stringToBoolean(swatchAvailable),
    stockAvailability: stringToBoolean(stockAvailability),
    availability: stringToBoolean(availability),
    fabricAvailability: idToValue(fabricAvailability)
  };
}

export const normalizeForPatch = jsonData => {
  // If ideal for has 2 values then it means both 'top' and 'bottom' were selected
  if(jsonData['idealFor'] instanceof Array){
    if(jsonData['idealFor'].length > 1) {
      jsonData['idealFor'] = "2";
    } else {
      jsonData['idealFor'] = jsonData['idealFor'][0];
    }
  }

  // Had to do this because redux-form only supports string values to evaluate radio buttons checked or not
  jsonData['inStock'] = jsonData['inStock'] == "1" ? 1 : 0;
  jsonData['imported'] = jsonData['imported'] == "1" ? 1 : 0;
  jsonData['yardageAvailable'] = jsonData['yardageAvailable'] == "1" ? 1 : 0;
  jsonData['swatchAvailable'] = jsonData['swatchAvailable'] == "1" ? 1 : 0;
  jsonData['stockAvailability'] = jsonData['stockAvailability'] == "1" ? 1 : 0;
  jsonData['availability'] = jsonData['availability'] == "1" ? 1 : 0;
  return jsonData;
}