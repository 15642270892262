import { createAction } from "redux-actions";
import axios from "axios";
import { get_service_endpoint } from "../../ServiceEndpoints";

const ep = `${get_service_endpoint("designs")}/dashboard`;
const apiUser = get_service_endpoint("auth");

export const setDispatchSummary = createAction("SET_DASHBOARD_SUMMARY");
export const setActiveDesigns = createAction("SET_DASHBOARD_DESIGNS");
export const setDispatchSchedules = createAction("SET_DASHBOARD_DISPATCHES");
export const setActiveFactories = createAction("SET_DASHBOARD_FACTORIES");
export const setCurrentTasks = createAction("SET_DASHBOARD_TASKS");
export const setPendingApprovals = createAction("SET_DASHBOARD_PENDING_APPROVALS");
export const DASHBOARD_GET_MY_TEAM = createAction("DASHBOARD_GET_MY_TEAM");

export const getDashboardSummary = () => dispatch => {
  return axios
    .get(`${ep}/summary`)
    .then(res => {
      dispatch(setDispatchSummary(res.data.resp));
      return res.data;
    });
}

export const getDashboardActiveDesigns = params => dispatch => {
  return axios
    .get(`${ep}/activeDesigns`, {
      params
    })
    .then(res => {
      dispatch(setActiveDesigns({
        data: res.data,
        refresh: !params.offset,
      }));
      return res.data;
    });
}

export const getDashboardDispatches = () => dispatch => {
  return axios
    .get(`${ep}/dispatchSchedule`)
    .then(res => {
      dispatch(setDispatchSchedules(res.data));
      return res.data;
    });
}
export const getDashboardActiveFactories = params => dispatch => {
  return axios
    .get(`${ep}/activeFactories`, {
      params
    })
    .then(res => {
      dispatch(setActiveFactories(res.data));
      return res.data;
    });
}

export const getDashboardTasks = params => dispatch => {
  return axios
    .get(`${ep}/tasks`, {
      params
    })
    .then(res => {
      dispatch(setCurrentTasks(res.data));
      return res.data;
    });
}

export const getDashboardPendingData = () => dispatch => {
  return axios
    .get(`${ep}/pendingApprovals`)
    .then(res => {
      dispatch(setPendingApprovals(res.data));
      return res.data;
    });
}

export const getMyTeam = (params = {}) => dispatch => {
  axios
    .get(`${apiUser}/dashboard/myTeam`, { params })
    .then(res => {
      return dispatch(DASHBOARD_GET_MY_TEAM({
        rows: res.data,
        paginate: {
          count: res.data.length,
        },
        refresh: true,
      }));
    });
};

