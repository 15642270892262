import { copyBrandFeatures } from "helpers/brandHelpers";
import { handleActions } from "redux-actions";

const INITIAL_STATE = {
  smReports: {},
  amReports: {},
  isLoading: false
};

const PerformanceReportReducer = handleActions({
  GET_REPORTS_LOADING: (state, action) => {
    return {
      ...state,
      isLoading: action.payload
    };
  },
  GET_SM_REPORTS: (state, action) => {
    const { data } = action.payload
    return {
      ...state,
      smReports: data,
      isLoading: false
    }
  },
  GET_AM_REPORTS: (state, action) => {
    const { data } = action.payload
    return {
      ...state,
      amReports: data,
      isLoading: false
    }
  },
}, INITIAL_STATE);

export default PerformanceReportReducer;
