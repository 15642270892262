import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import actions from "../../store/actions";
import { computePath } from "./../../helpers/pathHelper";

const Tabs = ({
  wrapperStyles = {},
  labelStyles = {},
  isFactory,
  activeTab,
  changeTrackerTab,
  totalCounts: { inProgress = 0, dispatched = 0 } = {}
}) => {
  let path = computePath();

  const windowLocation = window.location.pathname;
  if (activeTab == 0 && windowLocation.indexOf("in-progress") == -1) {
    changeTrackerTab({ activeTab: 1 });
  } else if (activeTab == 1 && windowLocation.indexOf("dispatched") == -1) {
    changeTrackerTab({ activeTab: 0 });
  }
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "320px",
        paddingLeft: "12px",
        ...wrapperStyles
      }}
    >
      <Link
        to={`/${path}/production/in-progress/`}
        className={`tabs__tab ${activeTab === 0 ? "tabs__tab--active" : ""}`}
        onClick={() => changeTrackerTab({ activeTab: 0 })}
      >
        <div>
          In Progress <span>({inProgress})</span>
        </div>
      </Link>
      <Link
        to={`/${path}/production/dispatched/`}
        className={`tabs__tab ${activeTab === 1 ? "tabs__tab--active" : ""}`}
        onClick={() => changeTrackerTab({ activeTab: 1 })}
      >
        <div>
          Dispatched <span>({dispatched})</span>
        </div>
      </Link>
      {/* <Pagination
          label={
            "col" in filtersValue ||
            "due" in filtersValue ||
            "nst" in filtersValue ||
            "pst" in filtersValue
              ? "designs found"
              : "designs in production"
          }
          onclick={paginationHandler}
          page={paginate}
          currentIndex={currentIndex}
          style={{ padding: "10px 32px", paddingBottom: "0px" }}
        /> */}
    </div>
  );
};

const mapStateToProps = state => ({
  activeTab: state.products.trackerActiveTab
});

const mapDispatchToProps = dispatch => ({
  changeTrackerTab: params => dispatch(actions.changeTrackerTab(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(Tabs);
