import React from "react";
import { Link } from "react-router-dom";

import emptyIcon from "assets/img/Factory/manage-factory.svg";
import plusIcon from "assets/images/content/ic-plus.png";
import Button from "../../UI/Button/Button";

const EmptyList = () => {
  return (
    <div className="empty-list-container empty-list-container--factory-list">
      <div className="img-container">
        <img src={emptyIcon} alt="Empty! Add factory" />
      </div>
      <div className="content">
        <div className="heading">Manage factories</div>
        <div className="description">
          Add factories you work with here. <br />
            We'll invite them to Locofast.
          </div>
        <div className="cta">
          <Link to="./add">
            <Button category="grid-2 blue-bg">
              <img src={plusIcon} />
              <span> Add Factory</span>
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default EmptyList;
