import React, { useState, useEffect } from "react";
import { Field, submit } from "redux-form";
import { connect } from "react-redux";
import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import axios from "axios";
import moment from "moment";
import { Link } from "react-router-dom";

import Form from "components/UI/Form/Form";
import Select from "components/UI/Form/Select/Select";
import Input from "components/UI/Form/Input/Input";
import Button from "components/UI/Button/Button";
import MaterialImage from "./../MaterialDetail/MaterialImage";
import SizeTable from "./../SizeTable";
import StaticTable from "../SizeTable/StaticTable";
import FileInput from "components/UI/File/FileWithPreview";
import UIDatePicker from "components/UI/Datepicker";
import Checkbox from "../../UI/Checkbox/Checkbox";
import { selectStyles } from "components/SwatchLibrary/AddSwatchModal/select-styles";
import { IconArrowBroad } from "components/UI/Icons";
import { IconInfo } from "components/UI/Icons";
import { TextSpliced } from "components/UI/TextSpliced";

import { postMaterialInventory, updateMaterialInventory } from "store/actions/MaterialLibraryActions";
import { closeModal } from "store/actions/GlobalActions";
import { DATESTAMP_FORMAT } from "constants/Date";
import VIEWS from "constants/MaterialLibrary";
import { required, minValue, maxValue, decimalUpto2 } from "helpers/form-validations";
import { hasQuantity } from "helpers/materialHelper";
import { get_service_endpoint } from "ServiceEndpoints";

import { ReactComponent as EyeIcon } from "assets/images/materialLibrary/ic-eye.svg";
import "./styles.scss";

const eq = get_service_endpoint("apiv2");

const { customSingleStyles } = selectStyles;

const FORM_NAME = "AddInventory";

const INVENTORY_VIEWS = {
  FORM: 0,
  DETAILS: 1
};

const minValue0 = minValue(0.01);
const maxValue999999 = maxValue(999999);

const FormRow = ({ children, label, required = false, style }) => (
  <div className="form__row" style={{ ...style }}>
    <div>
      {label}
      {required && <sup>*</sup>}
    </div>
    {children}
  </div>
);

const InventoryModal = props => {
  const [formValid, setFormValid] = useState(false);
  const [formValuesRaw, setFormValuesRaw] = useState({
    ...props.initialValues,
    brandId: props.material.brandId,
    materialId: props.material.id,
    expectedInwardDate: (props.initialValues && props.initialValues.expectedInwardDate) || new Date(),
    vendorId: (props.initialValues
      && (
        (props.initialValues.vendorDetails && props.initialValues.vendorDetails.id)
        || props.initialValues.vendorId
      )),
    ...(((props.initialValues && props.initialValues.size) || []).reduce((agg, item) => {
      agg[`quantity-${item.trims_size_id}`] = item.quantity;
      return agg;
    }, {}))
  });
  const [formValues, setFormValues] = useState();
  const [currentView, setCurrentView] = useState(INVENTORY_VIEWS.FORM);
  const [vendors, setVendors] = useState(Array.isArray(props.vendors) ? props.vendors : []);
  const [poFile, setPoFile] = useState(props.initialValues.poFile);
  const [invoiceFile, setInvoiceFile] = useState(props.initialValues.invoiceFile);

  const hasMultipleSizes = props.material.sizes && props.material.sizes.length > 0;
  const canScheduleInward = (props.formData && props.formData.values && props.formData.values.scheduleInward);

  useEffect(() => {
    axios
      .get(`${eq}/material/vendors`, {
        params: { brandId: props.material.brandId }
      })
      .then(res => {
        setVendors(res.data);
      });
  }, []);

  return (
    <React.Fragment>
      <div className="material__details">
        <div className="material__image_wrapper">
          <MaterialImage image={props.image} />
          <div className="material__details_form">
            <div className="inventory_modal">
              {INVENTORY_VIEWS.FORM === currentView ? (
                <React.Fragment>
                  <React.Fragment>
                    <div className="header flex f-sz-xl f-w-bold">
                      <Button
                        category="icon"
                        className="modal__nav-back"
                        onClick={() => props.switchViews(VIEWS.DETAILS)}
                      >
                        <IconArrowBroad direction="left" />
                      </Button>
                      Add Inventory
                    </div>
                    <div className="info-note">
                      <IconInfo color="blue" />{" "}
                      <div>
                        <Link
                          to="/brand/settings/vendors"
                          onClick={() => props.closeModal()}
                        >
                          <span className="link-blue">Go to settings</span>
                        </Link>{" "}
                        to add a new vendor.
                      </div>
                    </div>
                  </React.Fragment>
                  <Form
                    {...props}
                    onSubmit={() => {
                      if (formValues.id) {
                        props.updateMaterialInventory(formValues);
                      } else {
                        props.postMaterialInventory(formValues);
                      }
                    }}
                    form={FORM_NAME}
                    initialValues={formValuesRaw}
                  >
                    {({ changeFormValue, ...formProps }) => (
                      <div>
                        {(() => {
                          if (
                            formProps.valid &&
                            formProps.dirty &&
                            !formValid
                          ) {
                            if (hasMultipleSizes) {
                              if (hasQuantity(props.formData.values)) {
                                setFormValid(true);
                              }
                            } else {
                              setFormValid(true);
                            }
                          } else if (
                            formValid &&
                            props.formData &&
                            (formProps.invalid ||
                              (hasMultipleSizes && !hasQuantity(props.formData.values)))
                          ) {
                            setFormValid(false);
                          }
                        })()}
                        <FormRow label="Vendor" required>
                          <Field
                            name="vendorId"
                            component={Select}
                            options={vendors.map(vendor => ({
                              label: vendor.company,
                              value: vendor.id
                            }))}
                            styles={customSingleStyles}
                            validate={[required]}
                            selectProps={{
                              classNamePrefix: "react-select"
                            }}
                            className="react-select-container"
                            placeholder="Select vendor"
                          />
                        </FormRow>
                        <div className="grid-2 padding-0">
                          {!hasMultipleSizes && (
                            <FormRow label="Quantity" required>
                              <Field
                                name="quantity"
                                component={Input}
                                type="number"
                                validate={[required, minValue0, maxValue999999, decimalUpto2]}
                                rightAddon={
                                  <div>{props.material.unit.shortName}</div>
                                }
                                placeholder="Enter quantity"
                              />
                            </FormRow>
                          )}
                          <FormRow label="Price (in INR)" required>
                            <Field
                              name="price"
                              component={Input}
                              type="number"
                              validate={[required, minValue0, decimalUpto2]}
                              rightAddon={
                                <div>/ {props.material.unit.shortName}</div>
                              }
                              placeholder="Enter price"
                            />
                          </FormRow>
                          {hasMultipleSizes && <div />}
                        </div>
                        {hasMultipleSizes && (
                          <FormRow label="Quantity" required>
                            <Field
                              name="quantity"
                              component={SizeTable}
                              tableHeading={`Quantity(${props.material.unit.shortName})`}
                              availableSizes={props.material.trimsSize}
                            />
                          </FormRow>
                        )}
                        <div className={'field-inward-date ' + (hasMultipleSizes ? "" : "grid-2")}>
                          <FormRow label="Inward date" required>
                            <Field
                              name="expectedInwardDate"
                              className="inward-date"
                              component={UIDatePicker}
                              placeholder="Select inward date"
                              disabled={!canScheduleInward}
                              popperPlacement="bottom-start"
                            />
                          </FormRow>
                          <FormRow>
                            <Checkbox
                              baseClassname="schedule-inward-check"
                              label="Schedule inward"
                              value={canScheduleInward}
                              onClick={() => changeFormValue('scheduleInward', !canScheduleInward)}
                            />
                          </FormRow>
                        </div>
                        <div className="grid-2 col-equal">
                          <FormRow label="Attach PO">
                            <Field
                              name="poFile"
                              component={FileInput}
                              handleClick={setPoFile}
                            />
                          </FormRow>
                          <FormRow label="Attach Invoice">
                            <Field
                              name="invoiceFile"
                              component={FileInput}
                              handleClick={setInvoiceFile}
                            />
                          </FormRow>
                        </div>
                      </div>
                    )}
                  </Form>
                </React.Fragment>
              ) : (
                  <React.Fragment>
                    <div className="header flex f-sz-xl f-w-bold">
                      <Button
                        category="icon"
                        className="modal__nav-back"
                        onClick={() => setCurrentView(INVENTORY_VIEWS.FORM)}
                      >
                        <IconArrowBroad direction="left" />
                      </Button>
                    Add Inventory
                  </div>
                    <div className="info-note">
                      <IconInfo color="blue" />
                      <div>You won’t be able to change the details later.</div>
                    </div>
                    <div>
                      <div className="details__item">
                        <div className="label">Vendor</div>
                        <div>{formValues["vendor"].company}</div>
                      </div>
                      {!hasMultipleSizes && (
                        <div className="details__item">
                          <div className="label">Quantity</div>
                          <div>
                            {formValues["quantity"]}{" "}
                            {props.material.unit.shortName}
                          </div>
                        </div>
                      )}

                      <div className="details__item">
                        <div className="label">Price</div>
                        <div>
                          ₹{formValues["price"]} / {props.material.unit.shortName}
                        </div>
                      </div>
                      {hasMultipleSizes && (
                        <div className="details__item">
                          <div className="label">Quantity</div>
                          <div>
                            <StaticTable
                              sizes={formValues.selectedSizes}
                              tableHeading={`Quantity(${props.material.unit.shortName})`}
                            />
                          </div>
                        </div>

                      )}
                      <div className="details__item">
                        <div className="label">Inward date</div>
                        <div>
                          {
                            moment(formValues.scheduleInward ? formValues.expectedInwardDate : undefined).format(DATESTAMP_FORMAT)
                          }
                        </div>
                      </div>
                      <div className='grid-2'>
                        {poFile && poFile.name && (
                          <div className="details__item with_image">
                            <div className="label">PO</div>
                            <div className="input-file__meta-selected">
                              <TextSpliced
                                text={poFile["name"]}
                                maxWidth="150"
                              />
                              <div
                                onClick={() =>
                                  window.open(
                                    window.URL.createObjectURL(poFile)
                                  )
                                }
                                target="blank"
                                className="clickable"
                              >
                                <EyeIcon />
                              </div>
                            </div>
                          </div>
                        )}
                        {invoiceFile && invoiceFile.name && (
                          <div className="details__item with_image">
                            <div className="label">Invoice</div>
                            <div className="input-file__meta-selected">
                              <TextSpliced
                                text={invoiceFile.name}
                                maxWidth="150"
                              />
                              <div
                                onClick={() =>
                                  window.open(
                                    window.URL.createObjectURL(invoiceFile)
                                  )
                                }
                                target="blank"
                                className="clickable"
                              >
                                <EyeIcon />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </React.Fragment>
                )}
            </div>
          </div>
        </div>
      </div>
      <footer className="modal__footer">
        {currentView === INVENTORY_VIEWS.FORM ? (
          <React.Fragment>
            <Button
              category="black-ghost"
              onClick={() => {
                props.switchViews(VIEWS.DETAILS);
              }}
            >
              BACK
            </Button>
            <Button
              category={formValid ? "blue-bg" : "medium disabled"}
              disabled={!formValid}
              onClick={() => {
                if (props.formData) {
                  const { values } = props.formData;
                  const vendor = find(vendors, {
                    id: values["vendorId"]
                  });
                  const rawTrimSizes = {};
                  let totalTrimsQuantity = 0;
                  const selectedSizes = Object.entries(values)
                    .filter(([key, value]) => key.includes("quantity-"))
                    .map(([key, value]) => {
                      const id = Number(key.split("quantity-")[1]);
                      rawTrimSizes[id] = value;
                      totalTrimsQuantity += Number(value);
                      return {
                        size: find(props.material.trimsSize, { id })["size"],
                        quantity: value,
                        id
                      };
                    });

                  const computedRawValues = {
                    ...formValuesRaw,
                    ...props.formData.values,
                    poFile,
                    invoiceFile,
                  };
                  if (!isEmpty(rawTrimSizes)) {
                    computedRawValues.trimsSizes = JSON.stringify(rawTrimSizes);
                    computedRawValues.quantity = totalTrimsQuantity;
                  }
                  setFormValuesRaw(computedRawValues);
                  setFormValues({
                    ...values,
                    vendor,
                    selectedSizes
                  });
                  setCurrentView(INVENTORY_VIEWS.DETAILS);
                }
              }}
            >
              NEXT
            </Button>
          </React.Fragment>
        ) : (
            <React.Fragment>
              <Button
                category="black-ghost"
                onClick={() => setCurrentView(INVENTORY_VIEWS.FORM)}
              >
                BACK
            </Button>
              <Button
                category={formValid ? "blue-bg" : "medium disabled"}
                onClick={() => {
                  if (formValues.id) {
                    props.updateMaterialInventory(formValuesRaw);
                  } else {
                    props.postMaterialInventory(
                      formValuesRaw,
                      props.filtersList,
                      !props.material.inStock,
                    );
                  }
                }}
              >
                NEXT
            </Button>
            </React.Fragment>
          )}
      </footer>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  formData: state.form[FORM_NAME]
});
const mapDispatchToProps = dispatch => ({
  submitForm: formName => dispatch(submit(formName)),
  postMaterialInventory: (...details) =>
    dispatch(postMaterialInventory(...details)),
  updateMaterialInventory: (...details) =>
    dispatch(updateMaterialInventory(...details)),
  closeModal: () => dispatch(closeModal())
});

export default connect(mapStateToProps, mapDispatchToProps)(InventoryModal);
