import React from "react";

import styles from "./Product.module.scss";
import icNavLeft from "assets/images/navigation/ic-chevron-left.svg";
import icNavRight from "assets/images/navigation/ic-chevron-right.svg";

const Pagination = ({
  onclick,
  currentIndex,
  page: {
    nextOffset,
    nextDateOffSet,
    numberOfRecords = "",
    prevOffset,
    prevDateOffSet,
    limit = 20,
    totalCount = 0
  } = {},
  style = {},
  innerWrapperStyle = {},
  label = "products",
  extraData = null,
  showTotalRecords = true,
  className=""
}) => {
  const noOfpages = Math.ceil(totalCount / limit);

  let showingTill = limit * (currentIndex + 1);

  if (showingTill > totalCount) {
    showingTill = totalCount;
  }

  if (totalCount <= limit) {
    return null;
  }

  return (
    <div className={`${styles.pagination__wrapper} ${className}`} style={{ ...style }}>
      {showTotalRecords ? (
        <div className={styles.pagination__count}>
          {`${totalCount} ${label}`} {extraData}
        </div>
      ) : (
          <div className={styles.pagination__count}></div>
        )}
      <div className={styles.pagination__inner_wrapper} style={{ ...innerWrapperStyle}}>
        <div
          className={styles.pagination__pages}
        >{`${
          !currentIndex ? 1 : currentIndex * limit + 1
          } - ${showingTill} of ${totalCount}`}</div>
        <div className={styles.pagination__btn_wrapper}>
          <div
            className={`${styles.pagination__btn} ${
              !currentIndex ? "grey-overlay" : ""
              }`}
            onClick={() => {
              if (!currentIndex) return;

              onclick({ offset: (prevOffset || prevDateOffSet), isnext: 0 });
            }}
          >
            <img src={icNavLeft} alt="right" />
          </div>
          <div
            className={`${styles.pagination__btn}
              ${
              noOfpages === currentIndex + 1 || !noOfpages
                ? "grey-overlay"
                : ""
              }
            `}
            onClick={() => {
              if (noOfpages === currentIndex + 1) return;

              onclick({ offset: (nextOffset || nextDateOffSet), isnext: 1 });
            }}
          >
            <img src={icNavRight} alt="right" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
