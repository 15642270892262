import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import axios from "axios";
import { Helmet } from "react-helmet";

import ProductionItem from "./ProductionTrackerItem";
import Pagination from "../Product/Pagination";
import FloatingBar from "../UI/BottomBar";
import Tabs from "./ProductionTabs";
import NavHeaders from "components/NavHeaders";
import { FilterDrawerButton } from "./../UI/Filter";
import EmptyFilterResult from "../UI/EmptyFilterResult";
import EmptyScreen from "../UI/EmptyScreen";

import {
  getCurrentUserRole,
  checkBrand,
  checkFactory
} from "../../helpers/AuthHelper";
import { LOCO_SUPER } from "../../constants/Auth";

import { PRODUCTION_FILTERS } from "constants/Design";

import ImgSewing from "assets/images/emptyPages/ic-sewing.svg";
import "./style.scss";

import { get_service_endpoint } from "../../ServiceEndpoints";
const eq = get_service_endpoint("notificationView");

const ProductionTracker = ({
  isFactory,
  allBrands,
  products: { products = [], paginate },
  openModal,
  prevProductsForTracker = [],
  getAllProducts,
  currentUserId,
  getAllUserForBrand,
  userRole,
  tab,
  stage,
  showBrandFilter,
  location: { pathFilters = {} },
  closeModal,
  ...restProps
}) => {
  const isUserFromBrand = checkBrand();

  const [filtersValue, setFiltersValue] = useState({
    ...pathFilters,
    brandid: isUserFromBrand ? currentUserId : null
  });
  const [appliedFiltersCount, setAppliedFiltersCount] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentProducts, setCurrentProducts] = useState([]);
  const [samplesForNotification, setSamplesForNotification] = useState(null);
  const [factories, setFactories] = useState([]);

  const isSuperAdmin = getCurrentUserRole() == LOCO_SUPER;
  const isFactoryManagerAdmin = checkFactory();

  useEffect(() => {
    if (products && !products.length && !currentProducts.length) {
      return;
    }

    updateCurrentProducts([...products]);

    if (prevProductsForTracker.length === 1) {
      setCurrentIndex(0);
      setSamplesForNotification(null);
    }
  }, [products, prevProductsForTracker]);

  useEffect(() => {
    if (Object.keys(filtersValue).length) {
      // call api here ...
      if (isFactory) {
        getAllProducts({ ...filtersValue, init: 1, refresh: true });
      } else {
        getAllProducts({
          ...filtersValue,
          init: 1,
          refresh: true
        });
      }
    }
  }, [filtersValue]);

  useEffect(() => {
    if (!isFactoryManagerAdmin) {
      axios
        .get(`${eq}/orders/sampletracker/factories`, {
          params: {
            tab,
            stage: stage == "in-progress" ? "progress" : stage
          }
        })
        .then(res => {
          setFactories(Object.keys(res.data).map(key => res.data[key]));
        });
    }
  }, [stage]);

  // updates states and tooltip
  const updateCurrentProducts = products => {
    setCurrentProducts(products);

    setTimeout(() => {
      ReactTooltip.rebuild();
    }, 1000);
  };

  const getFiltersValueHandler = value => {
    let newAppliedFilterCount = 0;
    const transformedFilters = Object.values(value.filters).reduce(
      (acc, filter) => {
        acc[filter.name] = filter.values[0];

        if (acc[filter.name]) {
          newAppliedFilterCount += 1;
        }

        return acc;
      },
      {}
    );

    setAppliedFiltersCount(newAppliedFilterCount);
    setFiltersValue({
      ...transformedFilters
    });
  };

  // ************  PAGINATION HANDLER ***********
  const paginationHandler = ({ offset, isnext }) => {
    if (isnext) {
      // check length of saved designs
      const savedDesignsLength = prevProductsForTracker.length - 1;

      if (savedDesignsLength > currentIndex) {
        // then alter the designs

        updateCurrentProducts(prevProductsForTracker[currentIndex + 1]);
        setCurrentIndex(currentIndex + 1);
      } else {
        if (isFactory) {
          getAllProducts({
            ...filtersValue,
            offset,
            isnext,
            init: 0
          });
        } else {
          getAllProducts({
            ...filtersValue,
            offset,
            isnext,
            init: 0,
          });
        }
        setCurrentIndex(currentIndex + 1);
      }
      return;
    }

    if (!isnext) {
      updateCurrentProducts(prevProductsForTracker[currentIndex - 1]);
      setCurrentIndex(currentIndex - 1);

      return;
    }
  };

  const saveSampleForNotificationHandler = (sample, isAlreadyAdded) => {
    if (isAlreadyAdded) {
      let updatedSamples = samplesForNotification.filter(
        svSample => sample.tnaId !== svSample.tnaId
      );

      setSamplesForNotification(updatedSamples);
      updateCurrentSamplesOnCheck(sample);
      return;
    }

    if (!samplesForNotification) {
      setSamplesForNotification([sample]);
      updateCurrentSamplesOnCheck(sample);
      return;
    }

    setSamplesForNotification([...samplesForNotification, sample]);

    updateCurrentSamplesOnCheck(sample);
  };

  const updateCurrentSamplesOnCheck = sample => {
    const updatedSamples = currentProducts.filter(curSample => {
      if (curSample.tnaId === sample.tnaId) {
        let isSelected = curSample.isSelected;
        curSample.isSelected = !isSelected;

        return { ...curSample };
      }

      return curSample;
    });

    updateCurrentProducts(updatedSamples);
  };

  const resetAfterNotifications = () => {
    getAllProducts({ ...filtersValue, init: 1, refresh: true });
  };

  let totalCounts = {};
  if (paginate) {
    totalCounts = paginate.inProgressCount
      ? {
          inProgress: paginate.inProgressCount,
          dispatched: paginate.totalCount
        }
      : {
          inProgress: paginate.totalCount,
          dispatched: paginate.otherCount
        };
  }

  const filters = [];
  if (!isUserFromBrand) {
    filters.push({
      title: "CUSTOMER",
      key: PRODUCTION_FILTERS.brandId,
      items: allBrands.map(({ id, name }) => ({
        key: id,
        label: name,
        value: id,
        isSelected: filtersValue[PRODUCTION_FILTERS.brandId] === id
      })),
      isSingleSelect: true
    });
  }

  if (!isFactoryManagerAdmin) {
    filters.push({
      title: "FACTORY",
      key: PRODUCTION_FILTERS.factory,
      items: factories.map(({ id, name }) => ({
        key: id,
        label: name,
        value: id,
        isSelected: filtersValue[PRODUCTION_FILTERS.factory] === id
      })),
      isSingleSelect: true
    });
  }

  filters.push({
    title: "Production stage",
    key: PRODUCTION_FILTERS.productionStage,
    items: productionStageOptions.map(({ label, value }, index) => ({
      key: index,
      label,
      value,
      isSelected: filtersValue[PRODUCTION_FILTERS.productionStage] === value
    })),
    isSingleSelect: true
  });

  filters.push({
    title: "Due date",
    key: PRODUCTION_FILTERS.dueDate,
    items: dueDateOptions.map(({ label, value }, index) => ({
      key: index,
      label,
      value,
      isSelected: filtersValue[PRODUCTION_FILTERS.dueDate] === value
    })),
    isSingleSelect: true
  });

  if (isSuperAdmin) {
    filters.push({
      title: "Notification status",
      key: PRODUCTION_FILTERS.notificationStatus,
      items: notificationStatusOptions.map(({ label, value }, index) => ({
        key: index,
        label,
        value,
        isSelected:
          filtersValue[PRODUCTION_FILTERS.notificationStatus] === value
      })),
      isSingleSelect: true
    });
  }

  return (
    <>
      <Helmet
        bodyAttributes={{
          class: "body_container"
        }}
      />
      <div className="custom-scroll-sample">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "16px 32px 0",
            backgroundColor: "white"
          }}
        >
          <Tabs
            isFactory={isFactory}
            labelStyles={{ textTransform: "uppercase", fontSize: "12px" }}
            totalCounts={{ ...totalCounts }}
          />
          <div className="flex">
            <Pagination
              onclick={paginationHandler}
              page={paginate}
              currentIndex={currentIndex}
              style={{ padding: "4px 32px", paddingBottom: "0px" }}
              showTotalRecords={false}
            />
            <FilterDrawerButton
              filters={[...filters]}
              onApplyFilters={appliedFilters =>
                getFiltersValueHandler(appliedFilters)
              }
              disableConditions={[
                {
                  // denotes the condition -> if key is "pst" and Requested is selected then disable all filter items for "due"
                  key: "pst",
                  value: "Requested",
                  disable: {
                    key: "due",
                    value: "all"
                  }
                },
                {
                  // If key is "due" and any one is selected then disable "Requested" filter item for "pst" filter
                  key: "due",
                  value: "*",
                  disable: {
                    key: "pst",
                    value: "Requested"
                  }
                }
              ]}
            />
          </div>
        </div>

        <NavHeaders
          userRole={userRole}
          tab="production"
          isFactory={isFactory}
        />

        <div style={{ minHeight: "50vh" }} className="sample-row">
          {(() => {
            if (!currentProducts.length) {
              return appliedFiltersCount > 0 ? (
                <EmptyFilterResult
                  onClearFilters={() => {
                    getFiltersValueHandler({
                      filters: {
                        factory: { values: [] },
                        brand: { values: [] },
                        pst: { values: [] },
                        nst: { values: [] },
                        due: { values: [] }
                      },
                      filtersCount: 0
                    });
                  }}
                />
              ) : (
                <EmptyScreen
                  icon={ImgSewing}
                  heading="No designs in production"
                  description={
                    <>
                      Designs undergoing production will be
                      <br />
                      available here
                    </>
                  }
                />
              );
            }

            //  return actuls products ****
            return currentProducts.map(product => {
              return (
                <ProductionItem
                  data={product}
                  isFactory={isFactory}
                  userRole={userRole}
                  key={product.id}
                  isBrandSelected={
                    filtersValue.brandid && filtersValue.brandid != "null"
                  }
                  getSampleForNotification={saveSampleForNotificationHandler}
                  openModal={openModal}
                  closeModal={closeModal}
                  getAllProducts={resetAfterNotifications}
                />
              );
            });
          })()}
        </div>
        {samplesForNotification && samplesForNotification.length ? (
          <FloatingBar
            count={samplesForNotification && samplesForNotification.length}
            onClear={() => {
              const updatedSamples = currentProducts.filter(curSample => {
                curSample.isSelected = false;

                return { ...curSample };
              });

              updateCurrentProducts(updatedSamples);
              setSamplesForNotification(null);
            }}
            openModal={() => {
              getAllUserForBrand({ brandId: filtersValue.brandid }).then(
                userList => {
                  openModal("SEND_NOTIFICATION_USER_LIST", {
                    userList,
                    hiddenFields: [
                      {
                        name: "notifications",
                        value: samplesForNotification.map(notif => {
                          return {
                            ...notif,
                            stage: "TNA",
                            brand_id: notif.brandId,
                            design_id: notif.designId,
                            collection_id: notif.collectionId,
                            tnaId: notif.tnaId,
                            tnaAttributeId: notif.tnaAttributeId
                          };
                        })
                      },
                      { name: "userList", value: userList },
                      { name: "brand_id", value: filtersValue.brandid },
                      { name: "resetScreen", value: resetAfterNotifications }
                    ]
                  });
                }
              );
            }}
            modalKeys={[]}
            style={{}}
            isFactory={isFactory}
            onlyAddToDesign={true}
            selectionLabel="Products Selected"
            buttonLable="Send Notification"
          />
        ) : null}
      </div>
    </>
  );
};

export default ProductionTracker;

const productionStageOptions = [
  {
    label: "All",
    value: "null"
  },
  {
    label: "Requested",
    value: "Requested"
  },
  {
    label: "Material Inwards",
    value: "Material Inward and Testing"
  },
  {
    label: "Production Sample",
    value: "Pre-production Sample"
  },
  {
    label: "Production",
    value: "Production Cycle"
  },
  {
    label: "Finishing and Packing",
    value: "Finishing and Packing"
  }
];

const dueDateOptions = [
  {
    label: "All",
    value: null
  },
  {
    label: "On time",
    value: 4
  },
  {
    label: "Upcoming",
    value: 3
  },
  {
    label: "Delayed",
    value: 2
  },
  {
    label: "Dispatched",
    value: 1
  }
];

// Sent in last 10 days   *** 1
// Not sent in last 10 days -- We can’t fetch the data which doesn’t exist
// -- Above is being interpreted as sent before 10 days  **** 2

const notificationStatusOptions = [
  {
    label: "All",
    value: null
  },
  {
    label: "Sent in last 10 days",
    value: 1
  },
  {
    label: "Not Sent in last 10 days",
    value: 2
  }
];
