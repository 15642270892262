import { createAction } from "redux-actions";
import axios from "axios";
import { get_service_endpoint } from "../../ServiceEndpoints";
import { DESIGN_STATUS_KEYS } from "./../../constants/Design";
import { closeModal, isUploading, finishedLoading, finishedUploading } from "./GlobalActions";
import { flash } from "./CommonActions";
import _ from "lodash";
import actions from ".";
import history from "./../../utilites/history";
import { computePath, constructPath } from "../../helpers/pathHelper";
import { getCollectionById } from "./CollectionActions";
import { getAllCollections } from './CollectionActions';

// have to use delay because API insert into activity log async.
const ACTIVITY_LOG_FETCH_DELAY = 500;
const DESIGN_DETAILS_FETCH_DELAY = 500;

const ep = get_service_endpoint("designs");
const apiv2 = get_service_endpoint("apiv2");

const newEp = get_service_endpoint("notificationView");

const delay = t => new Promise(resolve => setTimeout(resolve, t));

// const GET_DESIGN_SUCCESS = createAction("GET_DESIGN_SUCCESS");
const GET_DESIGNS_REQUEST = createAction("GET_DESIGNS_REQUEST");
const GET_DESIGNS_SUCCESS = createAction("GET_DESIGNS_SUCCESS");
const CREATE_DESIGN_SUCCESS = createAction("CREATE_DESIGN_SUCCESS");
const EDIT_DESIGN_SUCCESS = createAction("EDIT_DESIGN_SUCCESS");
const DELETE_DESIGN_SUCCESS = createAction("DELETE_DESIGN_SUCCESS");
const GET_SAMPLE_DETAILS = createAction("GET_SAMPLE_DETAILS");
const GET_SAMPLE_HISTORY = createAction("GET_SAMPLE_HISTORY");

const GET_QUOTE_DETAILS = createAction("GET_QUOTE_DETAILS");
const GET_QUOTE_SUMMARY = createAction("GET_QUOTE_SUMMARY");
const UPDATE_QUOTE_REQUEST_DETAILS = createAction("UPDATE_QUOTE_REQUEST_DETAILS");
const UPDATE_QUOTE_DEADLINE = createAction("UPDATE_QUOTE_DEADLINE");
const UPDATE_QUOTE_DUE_DATE = createAction("UPDATE_QUOTE_DUE_DATE");

const GET_ORDER_DETAILS = createAction("GET_ORDER_DETAILS");
const GET_DISPATCH_DETAILS = createAction("GET_DISPATCH_DETAILS");
const GET_DESIGN_JOURNEY = createAction("GET_DESIGN_JOURNEY");
const GET_DESIGN_ACTIVITY = createAction("GET_DESIGN_ACTIVITY");
const UPDATE_NOTIFICATION_VIEW_DESIGNS = createAction(
  "UPDATE_NOTIFICATION_VIEW_DESIGNS"
);
const UPDATE_ORDER_FILE = createAction("UPDATE_ORDER_FILE");
const DELETE_LOCAL_ORDER_FILE = createAction("DELETE_LOCAL_ORDER_FILE");
const UPDATE_DISPATCH_FILE = createAction("UPDATE_DISPATCH_FILE");
const DELETE_LOCAL_DISPATCH_FILE = createAction("DELETE_LOCAL_DISPATCH_FILE");
const GET_DESIGN_SUMMARIES_SUCCESS = createAction(
  "GET_DESIGN_SUMMARIES_SUCCESS"
);

// ======== Factory TNA actions ==========
const GET_FACTORY_TNA_TEMPLATE = createAction("GET_FACTORY_TNA_TEMPLATE");
const POST_FACTORY_TNA_TEMPLATE = createAction("POST_FACTORY_TNA_TEMPLATE");
const GET_BRAND_TNA_DETAILS = createAction("GET_BRAND_TNA_DETAILS");
const GET_PRE_PRODUCTION_COMMIT = createAction("GET_PRE_PRODUCTION_COMMIT");

// ============= new sample api's =================
const GET_SAMPLE_DETAILS_BY_DESIGN_ID = createAction(
  "GET_SAMPLE_DETAILS_BY_DESIGN_ID"
);
const GET_ALL_COMMITS_FOR_SAMPLES = createAction("GET_ALL_COMMITS_FOR_SAMPLES");
const ADD_NEW_COMMINT_TO_SAMPLING_COMMINT = createAction(
  "ADD_NEW_COMMINT_TO_SAMPLING_COMMINT"
);
const UPDATE_DESIGN_DETAILS = createAction("UPDATE_DESIGN_DETAILS");

export const getDesignSummariesByCollection = ({
  collectionIds,
  factoryId
}) => dispatch => {
  if (!collectionIds || !collectionIds.length) return new Promise((resolve, reject) => resolve([]));
  return axios
    .post(`${newEp}/designsdetailsmini`, { collectionIds: collectionIds, factoryId })
    .then(({ data }) => {
      dispatch(GET_DESIGN_SUMMARIES_SUCCESS(data));
    });
};

let latestDesignToken = null;
export const getDesignsByCollectionId = (collectionId, designId, factoryId) => dispatch => {
  const currentToken = Math.random();
  latestDesignToken = currentToken;

  dispatch(
    GET_DESIGNS_REQUEST({
      collection_id: collectionId,
      design_id: designId,
    })
  );
  const params = {
    collection_id: collectionId, design_id: designId
  };
  if(factoryId){
    params.assignedFactories = [factoryId]
  }

  return axios
    .get(`${ep}/product/`, {
      params
    })
    .then(res => {
      if (res.data.resp && res.data.resp.code === 404) return { data: {} };
      return res;
    })
    .then(res => res.data)
    .then(data => {
      if (latestDesignToken === currentToken) {
        dispatch(
          GET_DESIGNS_SUCCESS({
            collection_id: collectionId,
            designs: data
          })
        )
      }
    });
};

// export const getDesignById = designDetails => dispatch => {
//   return axios
//     .get(`${ep}/product/`, { params: designDetails })
//     .then(res => res.data)
//     .then(data => dispatch(GET_DESIGN_SUCCESS(data)));
// };

export const createDesign = designDetails => dispatch => {
  return axios
    .post(`${ep}/product/`, designDetails)
    .then(res => { dispatch(CREATE_DESIGN_SUCCESS()); return res })
    .then(res => {
      dispatch(getDesignsByCollectionId(designDetails.collection_id));
      dispatch(
        actions.flash({
          message: `Design ${designDetails.name} created `,
          type: "action",
          actionType: "create"
        })
      );
      const path = computePath();
      const prefix = path === "brand" ? `/brand` : `/${path}/brands/${designDetails.brandId}`
      history.push(`${prefix}/collections/${designDetails.collection_id}/designs/${res.data.designId}/?tab=summary`);
    })
    .then(() => dispatch(closeModal()));
};

export const editDesign = designDetails => dispatch => {
  delete designDetails["status"];

  designDetails.additional_fields = [];

  // moving "fields_*" to array "additional_fields"
  Object.keys(designDetails).forEach(key => {
    if (key.startsWith("field_")) {
      designDetails.additional_fields.push({
        id: key.substr(6),
        value: designDetails[key] && designDetails[key].trim(),
      });

      delete designDetails[key];
    }
  });

  return axios
    .patch(`${ep}/product/`, designDetails)
    .then(() => dispatch(closeModal()));
};

export const deleteDesign = designDetails => dispatch => {
  const navigate = designDetails.navigate;
  delete designDetails.navigate;

  return axios
    .delete(`${ep}/product/`, { data: designDetails })
    .then(res => dispatch(DELETE_DESIGN_SUCCESS()))
    .then(res => {
      dispatch(getDesignsByCollectionId(designDetails.collection_id));

      if (navigate) {
        const path = computePath();
        const prefix = path.split('/collections')[0];
        history.push(`/${prefix}/collections/${designDetails.collection_id}/`);
      }
    })
    .then(() => dispatch(closeModal()));
};

export const updateDesignStatus = ({ id, collection_id, status }) => dispatch => {
  return axios
    .put(`${ep}/products/${id}/status`, { id, collection_id, status })
    .then(res => dispatch(DELETE_DESIGN_SUCCESS()))
    .then(res => {
      dispatch(getDesignsByCollectionId(collection_id));

      const tab = (status === DESIGN_STATUS_KEYS.DRAFT && "draft")
        || (status === DESIGN_STATUS_KEYS.ARCHIVED && "archived")
        || (status === DESIGN_STATUS_KEYS.CANCELED && "canceled")
        || "allDesigns";

      const path = computePath();
      const prefix = path.split('/collections')[0];
      history.push(`/${prefix}/collections/${collection_id}/?tab=${tab}`);
    })
    .then(() => dispatch(closeModal()));
};

export const deleteDesignFile = ({ file_id, designId }) => dispatch => {
  return (
    axios
      .delete(`${ep}/fileAttach`, { data: { file_id } })
      // .then(res => dispatch(getDesignsByCollectionId(formVal.collection_id)))
      .then(() => dispatch(closeModal()))
      .then(() => dispatch(getActivityLogs(designId, ACTIVITY_LOG_FETCH_DELAY)))
  );
};

export const patchDesignFile = ({ designInfo, fileInfo }) => dispatch => {
  if (fileInfo.size > 5000000) {
    dispatch(
      flash({
        message: "Please upload a smaller file (<5MB)"
      })
    );
    return;
  }

  return axios.patch(`${ep}/fileAttach`, fileInfo)
    .then(() => dispatch(getActivityLogs(designInfo.id, ACTIVITY_LOG_FETCH_DELAY)));
};

export const uploadDesignFileModal = ({ data, hiddenFields, designName, designId, collectionId }) => dispatch => {
  if (!window.locofastGlobals.files) {
    window.locofastGlobals.files = [];
  }
  window.locofastGlobals.files.push({
    file_title: data.get("file_title"),
    file_category: data.get("file_category"),
    file_added_at: new Date(),
    progress: {}
  });
  const currentUploadFileIndex = window.locofastGlobals.files.length - 1;
  dispatch(closeModal());
  dispatch(finishedLoading());

  return axios
    .post(`${get_service_endpoint("designs")}/fileAttach`, data, {
      onUploadProgress: ProgressEvent => {

        if (
          _.isUndefined(window.locofastGlobals.files[currentUploadFileIndex])
        ) {
          return;
        }

        window.locofastGlobals.files[currentUploadFileIndex]["progress"] = {
          percentage: Math.round(
            (ProgressEvent.loaded / ProgressEvent.total) * 100,
            2
          )
        };
        if (
          window.locofastGlobals.files[currentUploadFileIndex]["progress"][
          "percentage"
          ] >= 100
        ) {
          window.locofastGlobals.files.splice(currentUploadFileIndex, 1);
        }
      }
    })
    .then(() => {
      dispatch(getActivityLogs(designId, ACTIVITY_LOG_FETCH_DELAY));
      dispatch(getDesignsByCollectionId(collectionId));
    })
    .then(res => {
      dispatch(
        flash({
          message: `File added for ${designName}`,
          type: "action",
          actionType: "Upload"
        })
      );
    })
    .catch(reason => {
      dispatch(closeModal());
    });
};

export const updateDesignFileModal = ({ data, designId, collectionId }) => dispatch => {
  return axios
    .patch(`${get_service_endpoint("swatchLibrary")}/designfiles`, data)
    .then(res => {
      dispatch(
        flash({
          message: `File Updated for ${data.get('design_name')}`,
          type: "action",
          actionType: "Upload"
        })
      );
      dispatch(
        EDIT_DESIGN_SUCCESS({
          updatedFileDetails: res.data
        })
      )
      dispatch(getDesignsByCollectionId(collectionId));
      dispatch(closeModal());
    })
    .then(() => dispatch(getActivityLogs(designId, ACTIVITY_LOG_FETCH_DELAY)));
}

export const getActivityLogs = (designId, delayTime = 0) => dispatch => {
  return delay(delayTime)
    .then(() => axios.get(`${apiv2}/designs/${designId}/activity`))
    .then(res => {
      dispatch(GET_DESIGN_ACTIVITY({
        designId,
        activityLogs: res.data,
      }));
    });
};

export const uploadNewDesignFile = ({ design, file }) => dispatch => {
  if (file.get("designfile").size > 5000000) {
    dispatch(
      flash({
        message: "Please upload a smaller file (<5MB)"
      })
    );
    return Promise.reject();
  }

  return axios.post(`${ep}/fileAttach`, file)
    .then(res => {
      if (file.get("file_category") === "CoverFile7894") {
        dispatch(
          flash({
            message: "Cover image updated"
          })
        );
      }
      return {};
    })
    .then(() => dispatch(getActivityLogs(design.id, ACTIVITY_LOG_FETCH_DELAY)));
};

// ************************  NEW SAMPLE API STARTS ************************

export const getSamplesByDesignId = (params, overrideData = {}) => dispatch => {
  axios
    .get(`${newEp}/sample`, { params: { designid: params.designId } })
    .then(res => {
      const { data } = res;

      // it is add extra data if its not present in response
      if (overrideData.sampleId) {
        const sample = data.samples.find(item => item.id === overrideData.sampleId);
        if (sample) {
          sample.dueDate = sample.dueDate || overrideData.dueDate;
          sample.factoryId = sample.factoryId || overrideData.factoryId;
          sample.factoryName = sample.factoryName || overrideData.factoryName;
        }
      }

      // const str = window.location.href;
      // if (str.match(/\/brand\/collections\/(.+?)\/designs\//)) {
      //   const splitArr = str.split('/');
      //   const oldColId = parseInt(splitArr[splitArr.length - 4]);
      //   if (oldColId !== data.collectionId) {
      //     const postfix = str.split('/brand/')[1];
      //     history.push(`/brand/${postfix.replace(oldColId, data.collectionId)}`);
      //   }
      // }
      return dispatch(GET_SAMPLE_DETAILS_BY_DESIGN_ID(data));
    });
};

export const getSampleHistory = (params) => dispatch => {
  axios
    .get(`${newEp}/samplehistory`, { params: { ...params, designid: params.designId } })
    .then(res => {
      const { data } = res;

      return dispatch(GET_SAMPLE_HISTORY({ designId: params.designId, sampleHistory: data }));
    });
};

export const postNewSampleDetails = (params) => dispatch => {
  return axios.post(`${newEp}/sample`, params)
    .then(res => {
      dispatch(
        flash({
          message: `${params.sampleType} for ${params.designName} requested.`,
          type: "action",
          actionType: "Sample"
        })
      );
      return res.data
    })
    .then((res) => {
      const path = computePath();
      const prefix = path.split('/collections')[0];
      history.push(`/${prefix}/collections/${params.collectionId}/designs/${params.designId}/?tab=sampling`);

      return res;
    })
    .then((res) => {
      dispatch(getDesignById({
        design_id: params["designId"],
        collection_id: params["collectionId"],
      }));
      dispatch(getSamplesByDesignId({ designId: params.designId }, {
        sampleId: res.newSampleId,
        dueDate: params.dueDate,
        factoryId: params.factoryId,
        factoryName: params.factoryName,
      }));

      return res;
    });
};

export const postSampleCourierDetails = params => dispatch => {
  axios.post(`${newEp}/sample/dispatch`, params).then(res => {
    dispatch(flash({ message: "Sample added successfully." }));
    dispatch(getSamplesByDesignId({ designId: params.designId }));
  });
};

// this is only for uploading CSV file ============ ******* ===========
export const postSampleMeasurementSheet = (details, pathName) => dispatch => {

  // here id is sample root id **********
  const {
    sampleFile,
    sampleRootId,
    design: { collection_id, id, brand }
  } = details;
  const data = new FormData();

  if (
    !["CSV", "XLS", "XLSX"].includes(
      sampleFile.name.split(".")[1].toUpperCase()
    )
  ) {
    dispatch(
      flash({
        message:
          "Only XLS,XLSX and CSV file formats are supported, please try to import again."
      })
    );

    return;
  }

  data.append("designId", id);
  data.append("collectionId", collection_id);
  data.append("brandId", brand);
  data.append("id", sampleRootId);
  data.append("sampleSheet", sampleFile);

  dispatch(isUploading({
    fileName: sampleFile.name,
    isLoader: true
  }));

  axios.post(`${newEp}/samplefile`, data, {
    params: {
      isLoader: true
    }
  }).then(res => {
    dispatch(finishedUploading({
      pathName: pathName
    }));
    dispatch(getDesignById({ design_id: id, collection_id: collection_id }));
    dispatch(getSamplesByDesignId({ designId: id }));
    dispatch(getActivityLogs(id, ACTIVITY_LOG_FETCH_DELAY));
  }).catch(err => {
    dispatch(finishedUploading({
      pathName: pathName,
      error: 'Error occurred while uploading the sheet!'
    }));
  });
};

// ************************  NEW SAMPLE API ENDS ************************

export const getSampleDetails = designDetails => dispatch => {
  return axios
    .get(`${ep}/designSample`, {
      params: { design_id: designDetails.id }
    })
    .then(res => dispatch(GET_SAMPLE_DETAILS(res.data)));
};

export const getQuoteDetails = designDetails => dispatch => {
  // dispatch(progress({message: 'File Uploading'}))
  return axios
    .get(`${ep}/designs/${designDetails.id}/quotes`, {
      params: {
        groupKey: 'status',
      },
    })
    .then(res => {
      dispatch(GET_QUOTE_DETAILS(res.data));
      dispatch(finishedLoading());
    });
};

export const getQuoteSummary = designDetails => dispatch => {
  return axios
    .get(`${ep}/quotesummary`, {
      params: { design_id: designDetails.id }
    })
    .then(res => {
      dispatch(GET_QUOTE_SUMMARY(res.data));
    });
};

export const deleteQuote = details => dispatch => {
  // let data = new FormData();
  // data.append("quote_id", details.id);
  return axios
    .delete(`${ep}/quote`, { data: { quote_id: details.id } })
    .then(res => {
      dispatch(getQuoteDetails({ id: details.designId }));
    });
};

export const updateQuoteStatus = ({
  design_id,
  quote_id,
  status,
  factoryId,
  design_name,
  comment = "",
  flashMsg = "Successfull.",
  collection_id
}) => dispatch => {
  return axios
    .patch(`${ep}/quotestatus`, {
      quote_id,
      status: String(status),
      comment
    })
    .then(() => dispatch(closeModal()))
    .then(() => {

      if (status == 2) {
        axios.post(`${apiv2}/comments/designs/${design_id}/factories/${factoryId}`, {
          designId: design_id,
          moduleRef: null,
          moduleType: 2,
          moduleStatus: null,
          moduleId: quote_id,
          comment
        }).then(data => {
          dispatch(getQuoteDetails({ id: String(design_id) }));
        })
      } else {
        dispatch(getDesignById({ design_id, collection_id }));
        dispatch(getQuoteDetails({ id: String(design_id) }));
        dispatch(getSamplesByDesignId({ designId: design_id }));
      }
      window.location.reload();
      dispatch(
        actions.flash({
          message: flashMsg, //`Quote for ${design_name} approved.`,
          type: "action",
          actionType: "Update"
        })
      );
    });
};

export const createQuoteSummary = ({ design_id, quoteSummary }) => dispatch => {
  return axios
    .post(`${ep}/quotesummary`, {
      design_id: design_id,
      ...quoteSummary
    })
    .then(() => dispatch(getQuoteSummary({ id: design_id })));
};

export const updateQuoteSummary = ({ design_id, quoteSummary }) => dispatch => {
  return axios
    .patch(`${ep}/quotesummary`, {
      design_id: design_id,
      ...quoteSummary
    })
    .then(() => dispatch(getQuoteSummary({ id: design_id })));
};

export const createQuote = details => dispatch => {
  // console.log(details);
  delete details["status"];
  delete details["comment"];
  delete details["id"];
  // Over ride default quote quantity if specified
  if (details["quantity"]) {
    details["quote"]["quantity"] = Number(details["quantity"]);
  }

  delete details["quantity"];
  return axios
    .post(`${ep}/quote`, {
      ...details,
      status: "-1"
    })
    .then(res => {
      dispatch(getQuoteDetails({ id: details.design_id }));
    })
    .then(() => dispatch(closeModal()));
  // .then(() =>
  //   dispatch(
  //     actions.flash({
  //       message: `Quote for ${details.design_name} submitted.`,
  //       type: "action",
  //       actionType: "Create"
  //     })
  //   )
  // );
};

export const updateQuote = (quoteDetails) => dispatch => {
  if (quoteDetails.quote.additionalCosts) {
    if (!_.get(quoteDetails, "quote.additionalCosts.Dumpage", false)) {
      quoteDetails.quote.additionalCosts["Dumpage"] = 0;
    }
  }


  return axios
    .patch(`${ep}/quote`, {
      ...quoteDetails
    })
    .then(() => dispatch(getQuoteDetails({ id: quoteDetails.design_id })))
    .then(() => dispatch(closeModal()))
    .then(() => {
      dispatch(
        actions.flash({
          message: quoteDetails.flashMsg || `Quote for ${quoteDetails.design_name} ${quoteDetails.status === '-1' ? 'saved' : 'submitted'}`,
          type: "action",
          actionType: "Create"
        })
      );
    });
};

export const getOrderDetails = ({ design_id }) => dispatch => {
  return axios
    .get(`${ep}/production`, {
      params: {
        design_id
      }
    })
    .then(res => {
      dispatch(
        GET_ORDER_DETAILS(res.data === 0 ? {} : Object.values(res.data)[0])
      );
    });
};

export const updateOrderFiles = val => dispatch => {
  dispatch(UPDATE_ORDER_FILE(val));
  dispatch(closeModal());
  return Promise.resolve();
};

export const addOrUpdateOrderFile = val => dispatch => {
  if (!val["productionfile"]) {
    dispatch(
      flash({ message: "Please select a file before trying to upload." })
    );
    return;
  }

  if (!val["file_name"]) {
    dispatch(
      flash({ message: "Please enter a file title before trying to upload." })
    );
    return;
  }

  const data = new FormData();
  Object.keys(val).forEach(key =>
    key !== "productionfile" ? data.append(key, val[key]) : null
  );
  data.append(
    "productionfile",
    val["productionfile"],
    val["productionfile"]["name"]
  );

  return axios.post(`${ep}/productionmatrix`, data).then(() => {
    dispatch(DELETE_LOCAL_ORDER_FILE(val));
  });
  // .then(res => {
  //   if (res.data === 0) {
  //     dispatch(addOrderDetails({ design_id: val["design_id"] })).then(() => {
  //       dispatch(addOrUpdateOrderFile(val));
  //     });
  //   } else {
  //     dispatch(getOrderDetails({ design_id: val["design_id"] }));
  //   }
  // })
  // .then(() => dispatch(closeModal()));
};

export const addOrderDetails = orderDetails => dispatch => {
  return axios.post(`${ep}/production`, orderDetails).then(res => {
    Promise.all(
      orderDetails.files.map(f => dispatch(addOrUpdateOrderFile(f)))
    ).then(() => dispatch(getOrderDetails(orderDetails)));
  });
};

export const patchOrderDetails = orderDetails => dispatch => {
  return axios.patch(`${ep}/production`, orderDetails).then(res => {
    Promise.all(
      orderDetails.files
        .filter(f => !f.id)
        .map(f => dispatch(addOrUpdateOrderFile(f)))
    ).then(() => dispatch(getOrderDetails(orderDetails)));
  });
  // .then(() => dispatch(getOrderDetails(orderDetails)));
};

export const deleteOrderFile = orderDetails => dispatch => {
  if (orderDetails.file_id)
    return axios
      .delete(`${ep}/productdataionmatrix`, {
        data: { id: orderDetails.file_id }
      })
      .then(() =>
        dispatch(getOrderDetails({ design_id: orderDetails.design_id }))
      );
  else {
    // console.log(orderDetails);
    dispatch(DELETE_LOCAL_ORDER_FILE(orderDetails));
  }
};

export const changeOrderStatus = orderStatusDetails => dispatch => {
  return axios
    .patch(`${ep}/productionstatus`, {
      design_id: orderStatusDetails["design_id"],
      status: orderStatusDetails["status"]
    })
    .then(() =>
      dispatch(getOrderDetails({ design_id: orderStatusDetails.design_id }))
    );
};

export const getDispatchDetails = ({ design_id }) => dispatch => {
  return axios
    .get(`${ep}/dispatch`, {
      params: {
        design_id
      }
    })
    .then(res => {
      dispatch(
        GET_DISPATCH_DETAILS(res.code === 404 ? {} : Object.values(res.data)[0])
      );
    });
};

export const addDispatchDetails = dispatchDetails => dispatch => {
  // return axios
  //   .post(`${ep}/dispatch`, dispatchDetails)
  //   .then(res => {
  //     console.log(res.data);
  //   })
  //   .then(() => dispatch(getDispatchDetails(dispatchDetails)));

  return axios.post(`${ep}/dispatch`, dispatchDetails).then(res => {
    Promise.all(
      dispatchDetails.files.map(f => dispatch(addOrUpdateDispatchFile(f)))
    ).then(() => dispatch(getDispatchDetails(dispatchDetails)));
  });
};

export const patchDispatchDetails = dispatchDetails => dispatch => {
  return axios.patch(`${ep}/dispatch`, dispatchDetails).then(res => {
    // console.log(res.data);
    Promise.all(
      dispatchDetails.files
        .filter(f => !f.id)
        .map(f => dispatch(addOrUpdateDispatchFile(f)))
    ).then(() => dispatch(getDispatchDetails(dispatchDetails)));
  });
};

export const patchDispatchStatus = dispatchDetails => dispatch => {
  if (!dispatchDetails["received_quanity"]) {
    dispatch(
      flash({
        message:
          "Please specify the quantity recieved in Consignment recieving details"
      })
    );
    return;
  }

  if (!dispatchDetails["order_closed"]) {
    dispatch(
      flash({
        message:
          "Please specify whether the order has been closed or not, in Consignment recieving details"
      })
    );
    return;
  }

  if (!dispatchDetails["receive_time"]) {
    dispatch(
      flash({
        message:
          "Please specify the exact recieving time, in Consignment recieving details"
      })
    );
    return;
  }

  return axios
    .patch(`${ep}/dispatchstatus`, dispatchDetails)
    .then(res => {
      console.log(res.data);
    })
    .then(() => dispatch(getDispatchDetails(dispatchDetails)));
};

export const updateDispatchFiles = val => dispatch => {
  dispatch(UPDATE_DISPATCH_FILE(val));
  dispatch(closeModal());
  return Promise.resolve();
};

export const addOrUpdateDispatchFile = val => dispatch => {
  if (!val["dispatchfiles"]) {
    dispatch(
      flash({ message: "Please select a file before trying to upload." })
    );
    return;
  }

  if (!val["attachmentname"]) {
    dispatch(
      flash({ message: "Please enter a file title before trying to upload." })
    );
    return;
  }

  const data = new FormData();
  Object.keys(val).forEach(key =>
    key !== "dispatchfiles" ? data.append(key, val[key]) : null
  );
  data.append(
    "dispatchfiles",
    val["dispatchfiles"],
    val["dispatchfiles"]["name"]
  );

  return axios.post(`${ep}/dispatchfiles`, data).then(() => {
    dispatch(DELETE_LOCAL_DISPATCH_FILE(val));
  });
  // .then(res => {
  //   if (res.data === 0) {
  //     dispatch(
  //       addDispatchDetails({
  //         design_id: val["design_id"],
  //         dispatched_quanity: 0,
  //         dispatch_time: new Date(),
  //         consignment_number: "EXAMPLENUMBER"
  //       })
  //     ).then(() => {
  //       dispatch(addOrUpdateDispatchFile(val));
  //     });
  //   } else {
  //     dispatch(getDispatchDetails({ design_id: val["design_id"] }));
  //   }
  // })
  // .then(() => dispatch(closeModal()));
};

export const deleteDispatchFile = dispatchDetails => dispatch => {
  return axios
    .delete(`${ep}/dispatchfiles`, {
      data: { id: dispatchDetails.fileconst_id }
    })
    .then(() =>
      dispatch(getDispatchDetails({ design_id: dispatchDetails.design_id }))
    );
};

export const getDesignJourney = designDetails => dispatch => {
  return axios
    .get(`${ep}/designJourney`, {
      params: { design_id: designDetails.id }
    })
    .then(res => {
      dispatch(GET_DESIGN_JOURNEY(res.data));
    });
};

export const getDesignById = ({ design_id, collection_id }) => dispatch => {
  return delay(DESIGN_DETAILS_FETCH_DELAY)
    .then(() => axios.get(`${ep}/product`, {
      params: {
        id: design_id,
        collection_id: collection_id,
      }
    }))
    .then(resp => {
      dispatch(UPDATE_DESIGN_DETAILS({
        id: resp.data.id,
        details: resp.data,
      }));

      return resp.data;
    });
};

export const getMultipleDesigns = ({ idList }) => dispatch => {
  return axios
    .all(
      idList.map(({ design, collection }) =>
        dispatch(
          getDesignById({ design_id: design, collection_id: collection })
        )
      )
    )
    .then(respList =>
      respList.reduce((acc, res) => ({ ...acc, ...res.data }), {})
    )
    .then(data => {
      // console.log(data);
      delete data["resp"];
      // dispatch(
      //   GET_DESIGNS_SUCCESS({
      //     // collection_id: ,
      //     designs: data
      //   })
      // );
      return dispatch(
        UPDATE_NOTIFICATION_VIEW_DESIGNS({
          designs: data
        })
      );
    });
};

//  these are difinitions for TNA functions

export const getFactoryTnaTemplate = ({ brandid, designid }) => dispatch => {
  axios
    .get(`${newEp}/tna/template/`, {
      params: { brandid, designid }
    })
    .then(res => {
      return dispatch(
        GET_FACTORY_TNA_TEMPLATE({
          payload: res.data.tnaAttributes
        })
      );
    })
    .catch(err =>
      console.log("ERROR IN GETTING TEMPLATE FOR FACTORY TNA", err)
    );
};

export const getBrandTnaTemplate = designid => dispatch => {
  axios
    .get(`${newEp}/tna/brandtna/`, {
      params: { designid } // this.props.designId
    })
    .then(res => {
      return dispatch(
        GET_BRAND_TNA_DETAILS({
          payload: res.data
        })
      );
    });
};

export const postPreProductionCommit = ({
  designId,
  comment,
  moduleRef,
  moduleType = 1
}) => dispatch => {
  // axios
  //   .post(`${newEp}/comments`, {designId, comment, moduleRef, moduleType})
  //   .then(res => {
  //     return dispatch(GET_PRE_PRODUCTION_COMMIT({
  //       payload: res.data
  //     }))
  //   })
  //   .catch(err => console.log("ERR IN COMMITS "))

  alert("ITTS GET COUGHT &&&");
};

//  THIS FUNCTION HAS BEEN USED BY PRE PRODUCTION FILE UPLOAD MODEL ------
export const uploadPreproductionFile = ({
  productionfile = false,
  file_name,
  designId,
  moduleRef,
  getDetails
}) => dispatch => {
  // alert("HEY HITTED ..") // upload file here
  // if(!productionfile) {
  //   alert("Please upload a file!");
  //   return;
  // }

  let data = new FormData();

  data.append("designId", designId);
  data.append("moduleRef", moduleRef);
  data.append("moduleType", 1);
  data.append("file", productionfile);

  axios
    .post(`${newEp}/files`, data)
    .then(res => {
      console.log("RES FORM FILES API ", res);
      getDetails(res.data);
      dispatch(closeModal());
    })
    .catch(err =>
      console.log("ERROR WHILE UPLOADING PRE PRODUCTION FILE ", err)
    );
};

export const getAllCommintsForSampling = ({ id }) => dispatch => {
  axios
    .get(`${get_service_endpoint("notificationView")}/comments`, {
      params: {
        designid: id,
        moduletype: 1
      }
    })
    .then(res => {
      dispatch(GET_ALL_COMMITS_FOR_SAMPLES({ commints: res.data }));
    });
};

export const addNewCommintToSamplingCommints = ({ data }) => dispatch => {
  dispatch(ADD_NEW_COMMINT_TO_SAMPLING_COMMINT({ commint: data }));
};

export const requestQuote = (
  quoteDetails,
  designName,
  collectionId,
  brandId
) => dispatch => {
  const quoteBody = Object.assign({}, quoteDetails);
  quoteBody['quantity'] = Number(quoteBody['quantity']);
  quoteBody['price'] = Number(quoteBody['price']);
  return axios
    .post(`${apiv2}/quote/request`, {
      ...quoteBody
    })
    .then(resp => dispatch(UPDATE_QUOTE_REQUEST_DETAILS(resp.data)))
    .then(() => dispatch(closeModal()))
    .then(() => dispatch(getDesignById({ design_id: quoteDetails.designId, collection_id: collectionId })))
    .then(() => {
      history.push(`/${constructPath(brandId)}/collections/${collectionId}/designs/${quoteDetails.designId}/?tab=quote`);
    })
    .then(() =>
      dispatch(
        actions.flash({
          message: `Quote for ${designName} ${quoteBody['preApprovedQuote'] ? 'pre-approved': 'requested'}.`,
          type: "action",
          actionType: "Create"
        })
      )
    );
};

export const updateQuoteRequest = ({
  requestId,
  ...requestBody
}) => dispatch => {
  return axios
    .patch(`${apiv2}/quote/requests/${requestId}`, {
      ...requestBody,
    })
    .then(response => {
      dispatch(UPDATE_QUOTE_REQUEST_DETAILS({
        ...response.data[0],
        sizes_quantity: requestBody.sizesQuantity,
        due_date: requestBody.dueDate,
        closure_date: requestBody.closureDate,
      }))
      dispatch(closeModal());
    });
}

export const updateQuoteFactories = (
  requestId,
  requestBody,
  designId
) => dispatch => {
  return axios
    .post(`${apiv2}/quote/requests/${requestId}/factories`, {
      ...requestBody
    })
    .then(response => {
      dispatch(closeModal());
      dispatch(getQuoteDetails({ id: designId }))
    }
    );
}

export const removeFactoryFromRequest = ({ quoteRequestId, designId, factoryIds }) => dispatch => {
  axios
    .delete(`${apiv2}/quote/requests/${quoteRequestId}/factories`, {
      data: { factoryIds },
    })
    .then(res => {
      dispatch(closeModal());
      dispatch(getQuoteDetails({ id: designId }));
    });
}

export const notifyQuoteRequest = (
  requestId
) => dispatch => {
  return axios
    .patch(`${apiv2}/quote/requests/${requestId}/notify`)
    .then(response =>
      dispatch(UPDATE_QUOTE_REQUEST_DETAILS(response.data))
    );
}

export const moveDesign = ({
  design,
  collection,
  designId,
  collectionId,
}) => dispatch => {
  return axios
    .post(`${ep}/movedesign`, {
      design_id: designId,
      collection_id: collectionId,
    })
    .then(response => {
      if (design && design.id) {
        dispatch(getCollectionById(design.collection_id));
        dispatch(getAllCollections());
        dispatch(
          actions.flash({
            message: `Successfully moved design ${design.name}${collection ? ` to ${collection.name} collection` : ''}`,
            type: "action",
            actionType: "move"
          })
        );
      }
    });
}

export const duplicateDesign = ({
  design,
  collection,
  designId,
  collectionId,
  brandId,
  sourceDesignId,
}) => dispatch => {
  return axios
    .post(`${ep}/design/copy`, {
      designId,
      collectionId,
      brandId,
      sourceDesignId,
    })
    .then(response => {
      if (design && design.id) {
        dispatch(getCollectionById(design.collection_id));
        dispatch(getAllCollections());
        dispatch(
          actions.flash({
            message: `Successfully copied design ${design.name}${collection ? ` to ${collection.name} collection` : ''}`,
            type: "action",
            actionType: "copy"
          })
        );
      }

      return response.data.resp;
    });
}
