import React, { Component } from "react";

import styles from "./Product.module.scss";
import RedButton from "./RedButton";

const BottomBar = ({ selctedItems, createOrder, clearSelectedFilters }) => {
  return (
    <div className={styles.bottom__bar_wrapper}>
      <div>
        <div
          className={"f-sz-l f-w-bold"}
        >{`${selctedItems} Products Selected`}</div>
        <div
          style={{
            fontSize: "12px",
            color: "var(--fill-primary-dark)",
            lineHeight: "20px",
            cursor: 'pointer',
            // textAlign:'center'
          }}
          onClick={clearSelectedFilters}
        >
          Clear selected designs
        </div>
      </div>
      <RedButton label="Create Order" onClick={createOrder} />
    </div>
  );
};

export default BottomBar;
