import React, { useState, useEffect } from "react";
import isEmpty from "lodash/isEmpty";
import take from "lodash/take";
import mapValues from "lodash/mapValues";
import classnames from "classnames";

import Checkbox from "../../components/UI/Checkbox/Checkbox";
import Button from "components/UI/Button/Button";

import s from "./FilterGroup.module.scss";

const MAX_FILTER_NUM = 8;

const FilterGroup = ({
  title,
  options = [],
  type = "single",
  filterChanged = () => {},
  isOpen = false
}) => {
  const [filterDisplayNum, setFilterDisplayNum] = useState(MAX_FILTER_NUM);
  const [filterSelections, setfilterSelections] = useState(
    options.reduce((acc, opt) => ({ ...acc, [opt["value"]]: false }), {})
  );

  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  useEffect(() => {
    filterChanged(filterSelections);
    return () => {};
  }, [filterSelections]);

  useEffect(() => {
    // Check if the any of the Options are hidden
    if (filterDisplayNum < options.length) {
      // List closed
      // De-select the hidden options if selected

      let newFilterSelections = Object.entries(filterSelections).reduce(
        (acc, [key, value]) => ({
          ...acc,
          // Return original seletion value if the key is in visibleOptions
          [key]: visibleOptions.map(o => o.value).includes(key) ? value : false
        }),
        {}
      );
      setfilterSelections(newFilterSelections);
    }
  }, [filterDisplayNum]);

  // Display only the first MAX_FILTER_NUM options at start
  const visibleOptions = take(options, filterDisplayNum);
  const hiddenOptionsNum = options.length - visibleOptions.length;

  const handleKeyPress = (event, key) => {
    if (event.key === "Enter") {
      return;
    } else {
      if (key === "gte") {
        setMinPrice(event.target.value);
      } else {
        setMaxPrice(event.target.value);
      }
    }
  };

  if (type === "single") {
    return (
      <div className={classnames(!isOpen && s.closed)}>
        {visibleOptions.map(({ label, value }) => (
          <div
            key={value}
            className="loco__radio loco__radio--left-align loco__radio--light"
            style={{ border: "none", height: "30px", padding: "8px" }}
          >
            <input
              onClick={() => {
                // De-select all other selections
                let newFilterSelections = mapValues(
                  filterSelections,
                  () => false
                );
                // Select only the current selection
                setfilterSelections({ ...newFilterSelections, [value]: true });
              }}
              name={label}
              id={label}
              type="radio"
              checked={filterSelections[value]}
            />
            <label htmlFor={label}>{label}</label>
          </div>
        ))}
      </div>
    );
  } else if (type === "multi") {
    return (
      <div className={classnames(!isOpen && s.closed)}>
        {visibleOptions.map(({ label, value, color }) => (
          <div key={value} style={{ minHeight: "20px", padding: "6px 12px" }}>
            <Checkbox
              theme="light"
              onClick={() => {
                setfilterSelections({
                  ...filterSelections,
                  [value]: !filterSelections[value]
                });
              }}
              value={filterSelections[value]}
              label={
                title === "FABRIC COLOR" || title === "COLOR" ? (
                  <div className="flex">
                    <div
                      style={{
                        borderRadius: "10px",
                        width: "15px",
                        height: "15px",
                        background: color,
                        marginRight: "5px",
                        marginTop: "2px"
                      }}
                    />
                    {label}
                  </div>
                ) : (
                  label
                )
              }
              readOnly
            />
          </div>
        ))}

        {/* Show only if there exist any more options than the ones displayed above */}
        {hiddenOptionsNum > 0 ? (
          <div
            style={{ minHeight: "20px", margin: "2px", padding: "6px 12px" }}
          >
            <Checkbox
              onClick={() => setFilterDisplayNum(options.length)}
              baseClassname={s.hiddenCheckbox}
              label={`+${hiddenOptionsNum} more`}
              readOnly
            />
          </div>
        ) : options.length > MAX_FILTER_NUM ? (
          <div
            style={{ minHeight: "20px", margin: "2px", padding: "6px 12px" }}
          >
            <Checkbox
              onClick={() => setFilterDisplayNum(MAX_FILTER_NUM)}
              baseClassname={s.hiddenCheckbox}
              label={"Show less"}
              readOnly
            />
          </div>
        ) : null}
      </div>
    );
  } else if (type === "multiInput") {
    return (
      <div className={classnames(!isOpen && s.closed)}>
        <div className={classnames("flex", s.flexColumn)}>
          <input
            type="number"
            onChange={e => handleKeyPress(e, "gte")}
            name={`${title}.minPrice`}
            placeholder="Min"
            className={s.priceInput}
            value={minPrice}
          />
          -
          <input
            type="number"
            onChange={e => handleKeyPress(e, "lte")}
            name={`${title}.maxPrice`}
            placeholder="Max"
            className={s.priceInput}
            value={maxPrice}
          />
        </div>
        <div className={`flex ${s.filterButtons}`}>
          <Button
            category="black-ghost"
            disabled={isEmpty(minPrice) || isEmpty(maxPrice)}
            onClick={() => {
              setMinPrice("");
              setMaxPrice("");
              setfilterSelections({
                ...filterSelections,
                gte: "",
                lte: ""
              });
            }}
          >
            Clear
          </Button>
          <Button
            category="primary-blue"
            disabled={isEmpty(minPrice) || isEmpty(maxPrice)}
            onClick={() => {
              setfilterSelections({
                ...filterSelections,
                gte: minPrice,
                lte: maxPrice
              });
            }}
          >
            Apply
          </Button>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default FilterGroup;
