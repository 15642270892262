import React, { Component } from "react";
import { connect } from "react-redux";

import { LogItem } from "./LogItem";
import * as designActions from "../../../store/actions/DesignActions";
import Button from "../../UI/Button/Button";

import "./style.scss";

const LIMIT = 10;

class ActivityLog extends Component {
  state = {
    visibleUptoIndex: LIMIT
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.designId !== prevState.designId) {
      return {
        designId: nextProps.designId,
        designStatus: nextProps.designStatus
      };
    }
    return null;
  }

  // UNSAFE_componentWillReceiveProps(nextProps, oldProps) {
  //   if (oldProps.designId !== nextProps.designId
  //     || nextProps.designStatus !== oldProps.designStatus) {
  //     this.props.getActivityLogs(nextProps.designId);
  //   }
  // }

  componentDidMount() {
    this.props.getActivityLogs(this.props.designId);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.designId !== this.props.designId ||
      this.props.designStatus !== prevProps.designStatus
    ) {
      this.props.getActivityLogs(this.props.designId);
      this.setState({
        visibleUptoIndex: LIMIT
      });
    }
  }

  // _fetchActivityLog = (designId) => {
  //   axios
  //   .get(`${apiv2}/designs/${designId}/activity`).then(response=>{
  //     this.setState({
  //       logData: response.data,
  //       designId
  //     })
  //   })
  // }

  render() {
    const { activityLogs } = this.props;
    const { visibleUptoIndex } = this.state;

    return (
      <div className="log-container">
        <div className="log-container-header">
          <span>
            <span className="title">Activity Log</span>
          </span>
        </div>
        <div className="log-container-body">
          {activityLogs.slice(0, visibleUptoIndex).map(log => (
            <LogItem logData={log} key={log.id}/>
          ))}
          {visibleUptoIndex < activityLogs.length && (
            <Button
              category="plain-blue"
              onClick={() =>
                this.setState({
                  visibleUptoIndex: visibleUptoIndex + LIMIT
                })
              }
              className="flex capitalize"
            >
              Load more
            </Button>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  activityLogs: state.designs.mapActivityLogs[ownProps.designId] || []
});

const mapDispatchToProps = dispatch => ({
  getActivityLogs: designId => dispatch(designActions.getActivityLogs(designId))
});

export default connect(mapStateToProps, mapDispatchToProps)(ActivityLog);
