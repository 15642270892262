import React, { Component } from "react";
import PropTypes from "prop-types";
import './FactoryProductionModal.scss';

const FactoryProductionModal = ({ productionDetails }) => {

  const rows = Object.entries(productionDetails).map(([key,value])=>({ brand: key, count: value.length}))

  // const rows = [{ 'brand': 'Dope', 'count': 3}, { 'brand': 'Chill', 'count': '4'}]
  // console.log(rows,Object.entries(productionDetails))

  return (
    <div className="prod-container">
      <table>
        <tr>
          <th>Brand</th>
          <th>No. of designs</th>
        </tr>
        {
          rows.map(({brand, count})=>
          <tr>
            <td title={brand} className="truncate">{brand}</td>
            <td>{count} designs</td>
          </tr>
          )
        }
      </table>
    </div>
  )
}

export default FactoryProductionModal;