export const transformNotifications = item => {
  const { type, notifications, userList, brand_id } = item;
  let transformedNotifications = {
    audience: userList.map(u => ({ audienceId: u.id, status: 1 })),
    locofastrootId: brand_id
  };

  switch(type) {
    case "Quote": {
      transformedNotifications.modules =  notifications.map(n => ({
          designsId: n.design_id,
          collectionId: n.collection_id,
          category: 2,
          quoteId: n.quoteId,
          designStatus: 1
        }))
      break;
    }
    case "Order Confirmation": {
      transformedNotifications.modules = notifications.map(n => ({
            designsId: n.design_id,
            collectionId: n.collection_id,
            category: 3,
            orderId: n.orderId,
            designStatus: 1
          }))
      break;
    }
    case "Dispatch": {
      transformedNotifications.modules = notifications.map(n => ({
          designsId: n.design_id,
          collectionId: n.collection_id,
          category: 4,
          dispatchId: n.dispatchId,
          designStatus: 1
        }))
      break;
    }
    case "Sampling" : {
      transformedNotifications.modules = notifications.map(n => ({
          designsId: n.design_id,
          collectionId: n.collection_id,
          category: 1,
          sampleId: n.sampleId,
          sampleType: n.subCategory,
          designStatus: 1
        }))
      break;
    }
    case "TNA" : {
      transformedNotifications.modules = notifications.map(n => ({
          designsId: n.design_id,
          collectionId: n.collection_id,
          category: 5,
          tnaId: n.tnaId,
          tnaAttributeId: n.tnaAttributeId,
          designStatus: 1
        }))
    }
  }
  return transformedNotifications;
}