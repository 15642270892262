import React, { Component } from "react";

class Checkbox extends Component {
  render() {
    let {
      value,
      label,
      name,
      onClick,
      baseClassname = "",
      style = {
        padding: '0',
        width: '90%'
      },
      setRef = () => ({}),
      disabled,
      theme = "default",
      ...props
    } = this.props;
    name = name ? name : label;
    return (
      <div
        className={`control control-checkbox control-checkbox--${theme} ${baseClassname}`}
        // To prevent calling onClick twice (due to click on <label> and <input> bubbling up)
        // Check if the event target has type checkbox, i.e. label indirectly clicks on checkbox
        onClick={evt => {
          evt.preventDefault();
          evt.stopPropagation();
          return onClick ? onClick(evt) : null;
        }}
        style={style}
      >
        <label htmlFor={name}>{label}</label>
        <input
          type="checkbox"
          name={name}
          id={name}
          disabled={disabled}
          // ref="checkbox"
          // value={true}
          checked={value}
          ref={setRef}
          style={{ backgroundColor: "transparent" }}
          onChange={() => null}
          {...props}
        />
        <div className="control_indicator" style={{ right: '0', left: 'auto'}} />
      </div>
    );
  }
}

export default Checkbox;
