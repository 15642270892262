import React, { useEffect, useState } from 'react';
import Popover from "react-tiny-popover";
import Button from '../Button/Button';
import moreVertImg from "assets/images/navigation/ic-more-vert.svg";
import './style.scss';

const Toolbar = ({ buttons = [], maxButtons = 0 }) => {
  const [toolbarButtons, setToolbarButtons] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [isPopOverVisible, setPopoverVisibility] = useState(false);

  useEffect(() => {
    const _toolbarButtons = maxButtons > 0 ? buttons.slice(0, maxButtons) : [...buttons];
    const _menuItems = maxButtons > 0 ? buttons.slice(maxButtons) : [];

    setToolbarButtons(_toolbarButtons.map(btn => ({ ...btn, className: (btn.className || '') })));
    setMenuItems(_menuItems.map(btn => ({ ...btn, className: (btn.className || '') })));

  }, [buttons, maxButtons]);

  return <div className="toolbar">
    <div className="toolbar-btn-list">
      {toolbarButtons.map(btn => <Button
        key={btn.key}
        category={btn.category}
        disabled={btn.disabled}
        onClick={btn.onClick}
        className={`toolbar-btn {btn.category}`}
      >
        {btn.content}
      </Button>)}
    </div>
    {menuItems.length > 0 && <div className="toolbar-menu-items">
      <Popover
        position={"bottom"}
        isOpen={isPopOverVisible}
        onClickOutside={() => setPopoverVisibility(false)}
        content={
          menuItems.map(item => <div
            key={item.key}
            className={`pop-over__item flex f-sz-l ${item.className}`}
            onClick={(e) => {
              setPopoverVisibility(false);
              item.onClick(e);
            }}
          >{item.content}</div>)
        }
      >
        <Button
          category="img shrink"
          onClick={evt => {
            setPopoverVisibility(true);
          }}
        >
          <img
            className="header-dots"
            src={moreVertImg}
            alt="three dots"
          />
        </Button>
      </Popover>
    </div>}
  </div>;
}

export default Toolbar;
