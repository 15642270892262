import React from "react";
import { Field } from "redux-form";

import { minValue, maxValue, decimalUpto2 } from "helpers/form-validations";

import "./styles.scss";

const minValue0 = minValue(0.01);
const maxValue999999 = maxValue(999999);

const renderField = ({
  input,
  label,
  type,
  meta: { touched, error },
  availableQuantity
}) => (
  <tr className="size-input input-grp">
    <td>{label}</td>
    {availableQuantity >= 0 && <td>{availableQuantity}</td>}
    <td>
      <input {...input} type={type} />
    </td>
  </tr>
);

const SizeTable = ({
  fields = [],
  meta,
  availableSizes,
  tableHeading,
  renderAvailaiblity
}) => (
  <table className="size-table">
    <thead>
      <td>Size</td>
      {renderAvailaiblity && <td>Available Inventory</td>}
      <td>{tableHeading}</td>
    </thead>
    <tbody>
      {availableSizes.map((size, index) => (
        <Field
          name={`quantity-${size.id}`}
          type="number"
          component={renderField}
          label={size.size}
          availableQuantity={size.quantity}
          validate={[maxValue999999, minValue0, decimalUpto2]}
        />
      ))}
    </tbody>
    {meta.error && <li className="error">{meta.error}</li>}
  </table>
);

export default SizeTable;
