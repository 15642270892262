import React, { Component } from "react";
import PropTypes from "prop-types";
import { Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import get from "lodash/get";

import actions from "../../store/actions";

import Header from "../../components/UI/Header/Header";
import Sidebar from "./../../components/UI/Sidebar/Sidebar";
import PrivateRoute from "../../components/UI/PrivateRoute/PrivateRoute";
import BrandSearch from "./../../components/Factory/BrandSearch/BrandSearch";
import CollectionDetailsContainer from "../CollectionDetailsContainer/CollectionDetailsContainer";
import DesignDetailsContainer from "../DesignDetailsContainer/DesignDetailsContainer";
import AccountSettingsContainer from "./../AccountSettingsContainer/AccountSettingsContainer";
import FactoryCollectionListContainer from "../FactoryCollectionListContainer/FactoryCollectionListContainer";

import Products from "../ProductContainer";
import SamplingTracker from "../SamplingTrackerContainer";
import ProductionTracker from "../ProductionTrackerContainer";
import { ReactComponent as CollectionsImg } from "assets/images/navigation/collections.svg";
import { ReactComponent as SamplingImg } from "assets/images/navigation/sampling.svg";
import { ReactComponent as ProductionImg } from "assets/images/navigation/ic-nav-production-2.svg"

import {  LOCO_SUPER } from "../../constants/Auth";
import { getCurrentUserRole, refreshTokenIfOld } from "../../helpers/AuthHelper";

import './ProductMerchantContainer.scss';

class ProductMerchantContainer extends Component {
  static propTypes = {
    openModal: PropTypes.func.isRequired,
    collections: PropTypes.object.isRequired,
    userDetails: PropTypes.object.isRequired,
    getAllCollections: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.props.getUsers();
    let sidebarUpperMenu = [
      {
        name: "Collections",
        id: "collections",
        img: CollectionsImg,
        link: "/merchant/brands/"
      },
      {
        name: "Sampling",
        id: "sampling",
        img: SamplingImg, // have to change this .. icon
        link: "/merchant/sampling/in-progress/",
        baseRoute: "/merchant/sampling"
      },
      {
        name: "Production",
        id: "production",
        img: ProductionImg,
        link: "/merchant/production/in-progress/",
        baseRoute: "/merchant/production"
      },
    ];
    refreshTokenIfOld(this.props.refreshToken);

    this.state = {
      upperMenu: sidebarUpperMenu,
      userRole: getCurrentUserRole()
    };

  }

  componentDidMount(){
    this.props.getAssignedFactories();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.pathname === "/merchant/brands/") {
      if (this.props.currentBrand.brandId !== null)
        this.props.setCurrentBrand({ brandId: null });
    }
  }

  render() {
    const { upperMenu } = this.state;
    const { setCurrentBrand, currentBrand } = this.props;

    const { modal, closeModal, userDetails } = this.props;

    return (
      <div
        className="brand-dash merchant-dashboard"
        onClick={() => {
          if (modal.isOpen) {
            closeModal();
          }
        }}
      >
        <Header
          brandName={get(currentBrand, "brand_name", null)}
          openModel={() =>
            this.props.openModal("NOTIFICATION_MODAL", {
              userDetails,
              brandId: currentBrand && currentBrand.brandIid
            })
          }
          brandId={currentBrand && currentBrand.brandId}
          dontShowInLogin={window.appHistory.location !== "/auth/login/"}
          noBellIcon
          userDetails={userDetails}
        />
        <div
          className="brand-dash__main"
        >
          <Sidebar
            upperItems={upperMenu}
            userDetails={this.props.userDetails}
          />

          <div className="brand-dash__content">
            <PrivateRoute
              exact
              path="/merchant/brands"
              render={routeProps => {
                if (currentBrand.brandId) setCurrentBrand({ brandId: null });
                return (
                  <BrandSearch
                    {...routeProps}
                    openModal={this.props.openModal}
                    brands={this.props.factories}
                    selectedBrand={currentBrand}
                  />
                );
              }}
            />
            {/* /merchant/brands/:brand_id/collections/ -> brand_id here denotes the factory_id */}
            {/* Retained because all over the codebase params is checked */}
            <PrivateRoute
              exact
              path="/merchant/brands/:brand_id/collections/"
              render={routeProps => {
                let selectedBrand = {
                  ...this.props.factories.filter(
                    v =>
                      Number(v.factory_id) ===
                      Number(routeProps.match.params.brand_id)
                  )[0],
                  brandId: routeProps.match.params.brand_id
                };
                if (currentBrand.brandId !== selectedBrand.brandId) setCurrentBrand(selectedBrand);
                return (
                  <FactoryCollectionListContainer {...routeProps}/>
                );
              }}
            />
            <PrivateRoute
              exact
              path="/merchant/brands/:brand_id/collections/:collection_id"
              render={routeProps => {
                let selectedBrand = {
                  ...this.props.factories.filter(
                    v =>
                      Number(v.factory_id) ===
                      Number(routeProps.match.params.brand_id)
                  )[0],
                  brandId: routeProps.match.params.brand_id
                };
                if (currentBrand.brandId !== selectedBrand.brandId) setCurrentBrand(selectedBrand);
                return (
                  <CollectionDetailsContainer
                    {...routeProps}
                    openModal={this.props.openModal}
                  />
                );
              }}
            />
            <PrivateRoute
              exact
              path="/merchant/brands/:brand_id/collections/:collection_id/designs/:design_id"
              render={routeProps => {
                let selectedBrand = {
                  ...this.props.factories.filter(
                    v =>
                      Number(v.factory_id) ===
                      Number(routeProps.match.params.brand_id)
                  )[0],
                  brandId: routeProps.match.params.brand_id
                };
                if (currentBrand.brandId !== selectedBrand.brandId) setCurrentBrand(selectedBrand);
                return (
                  <DesignDetailsContainer
                    {...routeProps}
                    openModal={this.props.openModal}
                    isSuperAdmin={this.props.userDetails.role === LOCO_SUPER}
                    brandDetails={
                      this.props.factories.filter(
                        ({ id }) =>
                          Number(id) === Number(currentBrand.brandId)
                      )[0]
                    }
                  />
                );
              }}
            />

          <Route
              path="/merchant/settings/"
              render={routeProps => {
                if (currentBrand.brandId) setCurrentBrand({ brandId: null });
                return (
                  <AccountSettingsContainer {...routeProps} />
                )
              }
              }
            />

            <Route
              path="/merchant/sampling/approved/"
              render={routeProps => {
                if (currentBrand.brandId) setCurrentBrand({ brandId: null });
                return (<Products {...routeProps} />)
              }
              }
            />
            <Route
              path="/merchant/sampling/in-progress/"
              render={routeProps => {
                if (currentBrand.brandId) setCurrentBrand({ brandId: null });
                return (
                  <SamplingTracker
                    {...routeProps}
                    allBrands={this.props.brandsList}
                  />
                )
              }}
            />
            <Route
              path="/merchant/production/in-progress/"
              render={routeProps => {
                if (currentBrand.brandId) setCurrentBrand({ brandId: null });
                return (<ProductionTracker
                  {...routeProps}
                  allBrands={this.props.brandsList}
                  userDetails={this.props.userDetails}
                />
                )
              }}
            />
            <Route
              path="/merchant/production/dispatched/"
              render={routeProps => {
                if (currentBrand.brandId) setCurrentBrand({ brandId: null });
                return (<ProductionTracker
                  {...routeProps}
                  isFactory={true}
                  allBrands={this.props.brandsList}
                  userDetails={this.props.userDetails}
                />
                )
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  collections: state.collections,
  userDetails: state.auth.userDetails,
  designList: state.designs.list,
  brandsList: state.factory.brandsList,
  currentBrand: state.notificationView.currentBrand,
  modal: state.global.modal,
  factories: state.factory.factories
});

const mapDispatchToProps = dispatch => ({
  openModal: (content, additionalProps) =>
    dispatch(actions.openModal(content, additionalProps)),
  closeModal: (content, additionalProps) =>
    dispatch(actions.closeModal(content, additionalProps)),
  getAllCollections: () => dispatch(actions.getAllCollections()),
  getUsers: () => dispatch(actions.getUsers()),
  getDesignsByCollectionId: collectionId =>
    dispatch(actions.getDesignsByCollectionId(collectionId)),
  factoryGetDetails: details => dispatch(actions.factoryGetDetails(details)),
  getCollectionsByBrandId: details =>
    dispatch(actions.getCollectionsByBrandId(details)),
  setCurrentBrand: v => dispatch(actions.setCurrentBrand(v)),
  refreshToken: () => dispatch(actions.refreshToken()),
  getAssignedFactories: (details) =>
    dispatch(actions.getAssignedFactories(details)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ProductMerchantContainer)
);
