import React from "react";
import { Link, withRouter } from "react-router-dom";

import HeaderWithLogo from "./HeaderWithLogo";
import Notify from "./Notify";
import moment from "moment";

const PoList = ({ list = [], match }) => {
  if (!list.length) {
    return null;
  }
  
  return (
    <div>
      <HeaderWithLogo />
      <Notify
        title="View Dispatch Details"
        message="Tap on the below purchase orders to view the details."
      />
      {list.map(po => {
        return (
          <Link
            to={{
              pathname: `${match.url}poDetails/`,
              state: { po }
            }}
          >
            <div
              style={{
                padding: "16px",
                minHeight: "80px",
                boxShadow: " inset 0 -1px 0 0 var(--fill-grey-light3)",
                backgroundColor: "var(--fill-white)",
                borderBottom: "1px solid #f5f5f5",

                display: "flex",
                // alignItems: "center",
                flexDirection: "column",
                justifyContent: "center"
              }}
            >
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "600",
                  color: "var(--fill-locofast-black)",
                  lineHeight: "normal"
                }}
              >
                {`PO Number: ${po.poNumber}`}
                <span
                  style={{ fontSize: "12px", color: "var(--fill-grey-medium)" }}
                >{` (${moment(po.poDate).format("MMMM DD, YYYY")})`}</span>
              </div>
              <div>
                <span
                  style={{ fontSize: "12px", color: "var(--fill-grey-medium)" }}
                >
                  {`Qty: ${po.totalQuantity}, `}
                </span>
                <span
                  style={{ fontSize: "12px", color: "var(--fill-grey-medium)" }}
                >
                  {po.city}
                </span>
              </div>
            </div>
          </Link>
        );
      })}
    </div>
  );
};

export default withRouter(PoList);
