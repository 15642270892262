import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import qs from "query-string";
import "./CollectionDetails.scss";
import { CollaborationAvatars } from "./../../Collaboration";
import InfoButton from "./../../UI/InfoButton/InfoButton";
import Tabs from "../../UI/UrlTabs/index";
import DesignGrid from "./../../Designs/DesignGrid/DesignGrid";
import { DESIGN_STATUS } from "./../../../constants/Design";
import { TIMESTAMP_FORMAT } from "./../../../constants/Date";
import { FACTORY_ADMIN, FACTORY_MANAGER } from "../../../constants/Auth";
import { getCurrentUserRole } from "../../../helpers/AuthHelper";

export default class CollectionDetails extends Component {
  static propTypes = {
    prop: PropTypes
  };

  openNewDesignModal = () =>
    this.props.openModal("NEW_DESIGN", {
      hiddenFields: [
        { name: "collection_id", value: this.props.collection.id },
        { name: "brandId", value: String(this.props.collection.brand) }
      ],
      afterSubmit: this.props.reloadDesigns
    });

  getSelectedTab = () => {
    if (!window.appHistory.location.search) {
      return "allDesigns";
    }

    const queryParams = qs.parse(window.appHistory.location.search);

    return queryParams['tab'] || "allDesigns";
  }

  render() {
    const {
      collection,
      usersList,
      currentUser,
      collaborateCollection,
      brandFeatures,
      isLoading,
    } = this.props;

    const Header = (
      <div className="flex col-details__tabs col-details__header">
        <div className="flex-col">
          <div className="flex col-details__title">
            <div className="col-details__name">{collection.name}</div>
          </div>
          <div className="col-details__metadata">
            <span className="col-details__author">
              Created by {collection.username} &middot; {" "}
              {moment(collection.created_on).format(TIMESTAMP_FORMAT)}
            </span>
          </div>
        </div>

        {(!!brandFeatures.collaboration) && <><div
          className="col-details__collaborators"
          onClick={() => {
            this.props.openModal("COLLECTION_COLLABORATION", {
              collaborateCollection: collaborateCollection,
              collection: collection,
              collaborators: collection.collebration,
              users: usersList,
              loggedInUser: currentUser
            });
          }}
        >
          <CollaborationAvatars
            collection={collection}
            collaborators={collection.collebration || {}}
            users={usersList}
            loggedInUser={currentUser}
          />
        </div></>}
      </div>
    );

    const designTabs_designLists = {
      "All designs": Object.keys(this.props.designs).map(
        designId => this.props.designs[designId]
      ),
      Draft: Object.keys(this.props.designs)
        .map(designId => this.props.designs[designId])
        .filter(d => d.statusString === DESIGN_STATUS[1]),
      Quote: Object.keys(this.props.designs)
        .map(designId => this.props.designs[designId])
        .filter(d => d.statusString === DESIGN_STATUS[2]),
      Sampling: Object.keys(this.props.designs)
        .map(designId => this.props.designs[designId])
        .filter(d => d.statusString === DESIGN_STATUS[3]),
      Production: Object.keys(this.props.designs)
        .map(designId => this.props.designs[designId])
        .filter(d => d.statusString === DESIGN_STATUS[4]),
      Shipped: Object.keys(this.props.designs)
        .map(designId => this.props.designs[designId])
        .filter(d => d.statusString === DESIGN_STATUS[5]),
      Archived: Object.keys(this.props.designs)
        .map(designId => this.props.designs[designId])
        .filter(d => d.statusString === DESIGN_STATUS[6]),
      Canceled: Object.keys(this.props.designs)
        .map(designId => this.props.designs[designId])
        .filter(d => d.statusString === DESIGN_STATUS[7])
    };

    const initialTabUrl = this.getSelectedTab();

    return (
      <div className="col-details">
        {(Object.keys(this.props.designs).length === 0 && isLoading === false) ? (
          <Tabs
            hasBadges={true}
            header={Header}
            initialTabUrl="allDesigns"
            tabs={[
              {
                name: "All Designs",
                tabUrl: "allDesigns",
                panel: (
                  <div className="col-details__no-designs flex">
                    {getCurrentUserRole() !== FACTORY_ADMIN &&
                      getCurrentUserRole() !== FACTORY_MANAGER && (
                        <InfoButton
                          onClick={this.openNewDesignModal}
                          icon={<i className="fa fa-plus" />}
                          title="Add a design to this collection"
                          subtitle="All designs for this collection will appear here"
                        />
                      )}
                  </div>
                ),
                badge: designTabs_designLists["All designs"].length
              }
            ]}
          />
        ) : (
            <Tabs
              hasBadges={true}
              header={Header}
              initialTabUrl={initialTabUrl}
              tabs={[
                {
                  name: "All Designs",
                  tabUrl: "allDesigns",
                  panel: (
                    <DesignGrid
                      match={this.props.match}
                      openModal={this.props.openModal}
                      openNewDesignModal={this.openNewDesignModal}
                      designs={designTabs_designLists["All designs"]}
                      currentUser={this.props.currentUser}
                    />
                  ),
                  badge: designTabs_designLists["All designs"].length
                },
                {
                  name: "Draft",
                  tabUrl: "draft",
                  panel: (
                    <DesignGrid
                      match={this.props.match}
                      openModal={this.props.openModal}
                      openNewDesignModal={this.openNewDesignModal}
                      designs={designTabs_designLists["Draft"]}
                      currentUser={this.props.currentUser}
                    />
                  ),
                  badge: designTabs_designLists["Draft"].length
                },
                {
                  name: "Quote",
                  tabUrl: "quote",
                  panel: (
                    <DesignGrid
                      match={this.props.match}
                      openModal={this.props.openModal}
                      openNewDesignModal={this.openNewDesignModal}
                      designs={designTabs_designLists["Quote"]}
                      currentUser={this.props.currentUser}
                    />
                  ),
                  badge: designTabs_designLists["Quote"].length
                },
                {
                  name: "Sampling",
                  tabUrl: "sampling",
                  panel: (
                    <DesignGrid
                      match={this.props.match}
                      openModal={this.props.openModal}
                      openNewDesignModal={this.openNewDesignModal}
                      designs={designTabs_designLists["Sampling"]}
                      currentUser={this.props.currentUser}
                    />
                  ),
                  badge: designTabs_designLists["Sampling"].length
                },
                {
                  name: "Production",
                  tabUrl: "production",
                  panel: (
                    <DesignGrid
                      match={this.props.match}
                      openModal={this.props.openModal}
                      openNewDesignModal={this.openNewDesignModal}
                      designs={designTabs_designLists["Production"]}
                      currentUser={this.props.currentUser}
                    />
                  ),
                  badge: designTabs_designLists["Production"].length
                },
                {
                  name: "Shipped",
                  tabUrl: "shipped",
                  panel: (
                    <DesignGrid
                      match={this.props.match}
                      openModal={this.props.openModal}
                      openNewDesignModal={this.openNewDesignModal}
                      designs={designTabs_designLists["Shipped"]}
                      currentUser={this.props.currentUser}
                    />
                  ),
                  badge: designTabs_designLists["Shipped"].length
                },
                {
                  name: "Archived",
                  tabUrl: "archived",
                  panel: (
                    <DesignGrid
                      match={this.props.match}
                      openModal={this.props.openModal}
                      openNewDesignModal={this.openNewDesignModal}
                      designs={designTabs_designLists["Archived"]}
                      currentUser={this.props.currentUser}
                    />
                  ),
                  badge: designTabs_designLists["Archived"].length
                },
                {
                  name: "Canceled",
                  tabUrl: "canceled",
                  panel: (
                    <DesignGrid
                      match={this.props.match}
                      openModal={this.props.openModal}
                      openNewDesignModal={this.openNewDesignModal}
                      designs={designTabs_designLists["Canceled"]}
                      currentUser={this.props.currentUser}
                    />
                  ),
                  badge: designTabs_designLists["Canceled"].length
                }
              ]}
            />
          )}
      </div>
    );
  }
}
