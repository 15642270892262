import React from "react";
import QuoteCard from "..//QuoteCardFactory";
import "./index.scss";

const SubmitQuoteModal = ({ quoteRequestDetails, quote, oldQuoteDetails, newQuoteDetails, design }) => {
  const isPriceHigher = newQuoteDetails.totalCost > quoteRequestDetails.price;

  return (
    <div className="submit-quote-modal">
      <QuoteCard
        targetPrice={quoteRequestDetails.price}
        oldSubmitPrice={quote.status !== '-1' && +(oldQuoteDetails && oldQuoteDetails.totalCost)}
        newSubmitPrice={+(newQuoteDetails.totalCost)}
        currency={'INR'}
        design={design}
        noteText={
          isPriceHigher
            ? `This quote is above the target price requested by ${quoteRequestDetails.brand_name}.`
            : null}
      />
    </div>
  )
};

export default SubmitQuoteModal;
