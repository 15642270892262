import React from 'react';
import Pagination from '../../Product/Pagination';
import Tabs from '../../UI/UrlTabs/index';
import { FilterDrawerButton } from "../../UI/Filter";

import {
  MAP_TABS_BRAND,
  MAP_TABS_FACTORY
} from "constants/Quote";

const MAP_TAB_URL_COUNT = {
  'all': 'total',

  'awaited': 'awaited',
  'requested': 'awaited',

  'review-pending': 'submitted',
  'submitted': 'submitted',

  'approved': 'approved',
  'rejected': 'rejected',
};

const MAP_SYN_TAB_URLS = {
  brand: {
    'awaited': 'requested',
    'review-pending': 'submitted',
  },
  factory: {
    'submitted': 'review-pending',
    'requested': 'awaited',
  },
};

export default class Header extends React.Component {
  state = {
  }

  render() {
    const {
      pagination,
      selectedTabFilter = 'all',
      userDetails,
      filters,
      onApplyFilters,
      paginationHandler,
      paginationIndex,
    } = this.props;

    const mapTabs = userDetails.isBrand || userDetails.isSuperAdmin ? MAP_TABS_BRAND : MAP_TABS_FACTORY;

    return (<div className='quotes-header'>
      <Tabs
        hasBadges={true}
        initialTabUrl={selectedTabFilter}
        tabs={Object.entries(mapTabs).map(([key, tab]) => ({
          ...tab,
          badge: (pagination && pagination[`${MAP_TAB_URL_COUNT[tab.tabUrl]}Count`]) || 0,
        }))
        }
      />
      <Pagination
        label="orders"
        onclick={paginationHandler}
        page={pagination && {
          ...pagination,
          totalCount: pagination[`${MAP_TAB_URL_COUNT[selectedTabFilter]}Count`]
        }}
        currentIndex={paginationIndex}
        showTotalRecords={false}
      />
      <FilterDrawerButton
        userRole={userDetails.role}
        filters={filters}
        onApplyFilters={onApplyFilters}
      />
    </div>
    );
  }
}
