import React, { useState, useEffect } from "react";
import Dropdown from "react-dropdown";
import axios from "axios";
import _ from "lodash";
import { connect } from "react-redux";

import PropTypes from "prop-types";
import actions from "../../store/actions";

import Button from "../UI/Button/Button";
import MultiselectFilter from "../UI/MultiselectFilter";
import BootstrapError from "../UI/BootstrapError";

import styles from "./DetailsModel.module.scss";
import "react-dropdown/style.css";

import { get_service_endpoint } from "../../ServiceEndpoints";
const eq = get_service_endpoint("notificationView");
const ep = get_service_endpoint("collection");

// ==== THIS IS ONLY SHOWN FEOM FACTORY SIDE ====
const ShareWithBrandModel = ({
  isFactory,
  dispatch,
  swatches,
  clearState,
  brandid
}) => {
  const propTypes = {
    isFactory: PropTypes.bool.isRequired
  };
  const [allBrands, setallBrands] = useState([]);
  const [selectedBrand, setselectedBrand] = useState(null);
  const [users, setusers] = useState([]);
  const [selectedUsers, setselectedUsers] = useState([]);
  const [isError, setIsError] = useState({ message: "null", value: false });

  // === This effect gets the all brands on initial load ===
  useEffect(() => {
    axios
      .get(`${eq}/users/getallbrands`)
      .then(res => {
        setallBrands(res.data);
      })
      .catch();
  }, []);

  // === This effect gets the users on every brand selection  when isFactory = true ===
  useEffect(() => {
    if (selectedBrand) {
      axios
        .get(`${eq}/notifications/usersforbrand`, {
          params: { brandid: selectedBrand.brandid }
        })
        .then(res => {
          setusers(res.data);
        });
    }
  }, [selectedBrand]);

  // == handles the chackbox user filter ===
  const selectUserHandler = id => {
    // This saves the selected users
    if (_.includes(selectedUsers, id)) {
      _.remove(selectedUsers, user => user.id === id);

      setselectedUsers(selectedUsers);
    } else {
      if (selectedUsers) {
        setselectedUsers([
          ...selectedUsers,
          { id, brandId: selectedBrand.brandid }
        ]);
      } else {
        setselectedUsers([{ id, brandId: selectedBrand.brandid }]);
      }
    }

    // == This changes the users as slected ==
    const updatedUsers = users.map(user => {
      if (user.id === id) {
        user.isSelected = !user.isSelected;
        return { ...user };
      }
      return user;
    });
    setusers(updatedUsers);
  };

  const selectAllUsersHandler = () => {
    // == this one is for deselect all ===
    if (users.length === selectedUsers.length) {
      const updatedUsers = users.map(user => {
        user.isSelected = false;
        return { ...user };
      });

      setusers(updatedUsers);
      setselectedUsers([]);
      return;
    }

    // == this one is for select all ===
    let userIds = [];

    const updatedUsers = users.map(user => {
      userIds.push({ id: user.id, brandId: selectedBrand.brandid });
      user.isSelected = true;
      return { ...user };
    });

    setusers(updatedUsers);
    setselectedUsers(userIds);
  };

  // === This function shares the designs Multiple or single the form ====
  const shareDesignHandler = () => {
    const swatchids = swatches.map(swatch=>swatch.id)
    const request = {
      swatches: swatchids,
      users: selectedUsers
    };

    if (!selectedUsers.length) {
      setIsError({ message: "Select users!", value: true });
      setTimeout(() => {
        setIsError({ message: "", value: false });
      }, 800);
      return;
    }
    axios
      .post(`${eq}/swatch/brands`, request)
      .then(res => {
        dispatch(actions.flash({ message: "Shared successfully." }));
        clearState();
        dispatch(actions.closeModal());
      })
      .catch();
  };

  return (
    <div className={styles.swatch__details_add_to_design}>
      <div
        className={styles.swatch__details_form_}
        style={{ width: "400px", padding: "25px" }}
      >
        <div className={styles.swatch__details_form_upper_}>
          <div className={styles.swatch__name}>
            {/* 100% Cotton Red/White Colour Gingham Checks */}
          </div>

          <>
            {/* ==== DROP DOWNS FROM HERE BRAND ==== */}

            <div
              style={{
                fontSize: "12px",
                color: "var(--fill-grey-medium)",
                padding: "0 0 3px 10px"
              }}
            >
              Brand
            </div>
            <div className={styles.dropdown__wrapper_custom}>
              <Dropdown
                options={allBrands.map(brand => ({
                  label: brand.name,
                  value: brand.id
                }))}
                onChange={({ value, label }) =>
                  setselectedBrand({ brandid: value, brandName: label })
                }
                value={selectedBrand ? selectedBrand.brandName : "Select one"}
                placeholder="Select one"
                // className={styles.dropdown__styles}
                // menuClassName={styles.dropdown__styles_menu}
                style={{ cursor: "pointer" }}
                disabled={false}
              />
            </div>

            <div
              style={{
                fontSize: "12px",
                color: "var(--fill-grey-medium)",
                padding: "0 0 3px 10px"
              }}
            >
              Users
            </div>

            <MultiselectFilter
              initialSelectedItem={{
                label: "Select",
                value: "All"
              }}
              filterOptions={users.map(user => ({
                label: user.name,
                value: user.id,
                isSelected: user.isSelected,
                designCount: user.designCount,
                email: user.email
              }))}
              onChange={selection => selectUserHandler(selection.value)}
              selectAll={selectAllUsersHandler}
              disable={!selectedBrand}
              isApplyBar
            />

            {/* ==== DROP DOWNS FROM HERE Collection ==== */}
          </>
        </div>

        <div style={{ textAlign: "center", marginTop: "30px" }}>
          <div style={{ height: "30px" }}>
            <BootstrapError message={isError.message} show={isError.value} />
          </div>
          <Button
            category="blue-bg"
            style={{ height: "48px", width: "100%" }}
            onClick={shareDesignHandler}
          >
            <span style={{ fontWeight: "600" }}>SHARE</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default connect(null)(ShareWithBrandModel);
