import React, { useReducer } from 'react';

export const useToggleMenu = () => {

  /**
   * States: Hovered - Handling kebab menu dots,
   *         Clicked - Handling menu options
   * 
   */

  function reducer(state, action){
    switch (action.type) {
      case "hovered_false": {
        return {
          ...state,
          hovered: state.clicked ? true : false
        }
      }
      case "hovered_true": 
      return {
        hovered: true,
        clicked: false
      }
      case "clicked_true": return {
        ...state,
        clicked: true
      }
      case "clicked_false": return {
        ...state,
        clicked: false
      }
      default: return state
    }
  }
  const [ state, dispatch ] = useReducer(reducer, { clicked: false, hovered: false});

  return [state, dispatch, { 
    "hovered_false" : "hovered_false",
    "hovered_true": "hovered_true",
    "clicked_true": "clicked_true",
    "clicked_false": "clicked_false"
  }];
}