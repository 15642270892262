import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import moment from "moment";

import { getImageUrl } from "helpers/DesignHelpers";
import {
  computeOnTime
} from './helper';


export default class DispatchStatus extends Component {
  componentDidMount() {
    ReactTooltip.rebuild();
  }
  render() {
    const { data } = this.props;
    const {
      cover_pic_url,
      category,
      design_name,
      factory_name,
      destination_city,
      order_quantity,
      order_due_date,
      is_order_complete,
      destination_address
    } = data;

    return (
      <div className="quote">
        <div className="due-status-section">
          <span className="status-section-img">
            <img
              src={getImageUrl(cover_pic_url, { category })}
              className="status-img"
              alt="user avatar"
            />
          </span>

          <div className="timing-status-section">
            <div className="status-section-content">
              <span className="status-title-top">{design_name}</span>
              <div className="profile-section-status">
                <div className="gray-dot-section">
                  <div className="grey-circle-new" />
                  <div className="grey-line" />
                  <div className="grey-square" />
                </div>
                <div className="profile-section-title">
                  <div className="status-title-location title--pb">
                  {factory_name || "-"}
                  </div>
                  <div
                    className="status-title-location"
                  >
                    {destination_city || "-"}
                    {destination_address && (
                      <div className="visible-on-hover">
                        <span>Delivery address</span>
                        <p>{destination_address}</p>
                      </div>
                    )}
                  </div>

                </div>
              </div>
            </div>
            <div className="timing-status-right">
              <div className="timing-status-title">{order_quantity} pcs</div>
              {computeOnTime(order_due_date, is_order_complete) ? (
                <div className="timing-status-success">On time</div>
              ) : (
                <div className="timing-status-delayed">Delayed</div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
