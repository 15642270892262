import { createAction } from "redux-actions";
import axios from "axios";
import { get_service_endpoint } from "../../ServiceEndpoints";
import { getBrandTnaTemplate } from "./DesignActions";

const eq = get_service_endpoint("notificationView");

const TNA_DESIGN_SUGGESTIONS = createAction("TNA_DESIGN_SUGGESTIONS");
const GET_DESIGN_TNA_TEMPLATE = createAction("GET_DESIGN_TNA_TEMPLATE");

export const getTnaDesignSuggestions = ({ designId }) => dispatch => {
  axios
    .get(`${eq}/tna/suggestions`, {
      params: { designId }
    })
    .then(res => {
      return dispatch(
        TNA_DESIGN_SUGGESTIONS({
          designId,
          suggestions: res.data,
        })
      );
    });
};

export const updateDesignTna = (request) => dispatch => {
  return axios
    .patch(`${eq}/tna`, request)
    .then(res => {
      dispatch(getBrandTnaTemplate(request.designId));
      return res;
    });
};

export const getDesignTnaTemplate = ({ designId, brandId }) => (dispatch) => {
  return axios
    .get(`${eq}/tna/template/`, {
      params: {
        brandid: brandId,
        designid: designId
      }
    })
    .then(res => {
      return dispatch(GET_DESIGN_TNA_TEMPLATE({
        data: res.data,
        designId,
        brandId,
      }));
    });
};

export const uploadTnaAttachment = ({ id, tnaId, designId, files }) => (dispatch) => {
  let formData = new FormData();

  formData.append("id", id);
  formData.append("tnaId", tnaId);
  formData.append("designId", designId);

  files.forEach(file => {
    formData.append("file", file);
  });

  return axios
    .post(`${get_service_endpoint("notificationView")}/tna/image`, formData);
};

export const updateDesignTnaStatus = ({ tnaId, designId, status, note }) => dispatch => {
  return axios
    .patch(`${eq}/tna/status`, { tnaId, status, note })
    .then((body) => {
      dispatch(getBrandTnaTemplate(designId));
      return body;
    });
};
