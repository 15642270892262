import React from "react";
import { Field } from "redux-form";
import Form from "../../UI/Form/Form";
import Input from "../../UI/Form/Input/Input";
import File from "../../UI/Form/File/File";

const OrderFile = props => {
  const { acceptTypes=""} = props; // THIS was added for limiting image files only 

  return (
    <Form
      {...props}
      onSubmit={values => {
        if (!values["file_name"]) {
          alert("Please enter a file name before trying to upload.");
          return;
        }

        if (!values["productionfile"]) {
          alert("Please select a file before trying to upload.");
          return;
        }

        if (props.invalidFileNames && props.invalidFileNames.includes(values["file_name"])) {
          alert("Please use another file name");
          return;
        }

        props.onSubmit(values);
      }}
      form="addOrderFile"
    >
      {({ changeFormValue }) => (
        <div style={{ padding: "1em 2em" }}>
          <div className="flex" style={{ justifyContent: "center" }}>
            <File
              onChange={file => {
                changeFormValue("productionfile", file);
              }}
              hasAdvancedOptions={false}
              acceptTypes={acceptTypes}
            />
          </div>
          <Field
            component={Input}
            name="file_name"
            label="File title"
            required
            type="text"
            disabled={props.initialValues && props.initialValues["file_name"]}
          />
        </div>
      )}
    </Form>
  );
};

export default OrderFile;
