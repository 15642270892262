import React, { useEffect, useState, useRef, useCallback } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import ReactTooltip from "react-tooltip";

import { TextSpliced } from "components/UI/TextSpliced";
import EmptyScreen from "components/UI/EmptyScreen";
import { ReactComponent as HelpIcon } from "assets/images/navigation/help-circle.svg";

import "./styles.scss";

const Table = ({
  headings = [],
  data = [],
  gridTemplateColumns = "",
  tooltipData = {}
}) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);
  return (
    <PerfectScrollbar>
      <table className="reports-table" style={{ gridTemplateColumns }}>
        <thead>
          <tr>
            {headings.map(heading => (
              <th>
                {heading}
                {tooltipData[heading] && (
                  <div
                    className="header-tooltip"
                    data-tip={tooltipData[heading]}
                    data-class="white-tooltip"
                    data-html={true}
                  >
                    <HelpIcon />
                  </div>
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.length === 0 ? (
            <EmptyScreen heading="No results found" />
          ) : (
              data.map((dataRow, ind) => {
                return (
                  <tr key={ind}>
                    {dataRow.map(columnValue => (
                      <td>
                        {columnValue.url
                          ? (<a className="link"
                            href={columnValue.url}
                            alt=""
                            target="_blank"
                            rel="noopener noreferrer">
                            <TextSpliced
                              className="profile-name text-black"
                              text={columnValue.label || '--'}
                              maxWidth={columnValue.maxWidth}
                            />
                          </a>)
                          : columnValue.maxWidth ? (
                            <TextSpliced
                              className="profile-name text-black"
                              text={columnValue.value || '--'}
                              maxWidth={columnValue.maxWidth}
                            />
                          ) : (
                              columnValue.value || "--"
                            )}
                      </td>
                    ))}
                  </tr>
                );
              })
            )}
        </tbody>
      </table>
    </PerfectScrollbar>
  );
};

export default Table;
