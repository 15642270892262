import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import actions from "../../store/actions";
import { computePath } from "../../helpers/pathHelper";

const Tabs = ({
  wrapperStyles = {},
  labelStyles = {},
  isFactory,
  activeTab,
  changeTrackerTab,
  totalCounts: { inProgress = 0, approved = 0 } = {},
  userRole
}) => {
  const windowLocation = window.location.pathname;
  if (activeTab == 0 && windowLocation.indexOf("in-progress") == -1) {
    changeTrackerTab({ activeTab: 1 });
  } else if (activeTab == 1 && windowLocation.indexOf("approved") == -1) {
    changeTrackerTab({ activeTab: 0 });
  }

  let path = computePath();

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        ...wrapperStyles
      }}
    >
      <Link
        to={`/${path}/sampling/in-progress/`}
        className={`tabs__tab ${activeTab === 0 ? "tabs__tab--active" : ""}`}
        onClick={() => changeTrackerTab({ activeTab: 0 })}
      >
        <div>
          In Progress <span>({inProgress})</span>
        </div>
      </Link>
      {/* {userRole != "FACTORY_ADMIN" && userRole !== "FACTORY_MANAGER" && ( */}
      <Link
        to={`/${path}/sampling/approved/`}
        className={`tabs__tab ${activeTab === 1 ? "tabs__tab--active" : ""}`}
        onClick={() => changeTrackerTab({ activeTab: 1 })}
      >
        <div>
          Approved <span>({approved})</span>
        </div>
      </Link>
      {/* )} */}
    </div>
  );
};

const mapStateToProps = state => ({
  activeTab: state.products.trackerActiveTab,
  userRole: state.auth.userDetails.role
});

const mapDispatchToProps = dispatch => ({
  changeTrackerTab: params => dispatch(actions.changeTrackerTab(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(Tabs);
