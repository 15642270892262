import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Field } from "formik";
import * as Yup from "yup";
import isEmpty from "lodash/isEmpty";
import find from "lodash/find";

import {
  getSizesByDesignType,
  transformFactoriesToOptions
} from "helpers/DesignHelpers";
import {
  postNewSampleDetails,
  getFactoryTnaTemplate,
  getSamplesByDesignId
} from "store/actions/DesignActions";
import { getAllFactoriesForQuotes } from "store/actions/FactoryActions";
import { closeModal } from "store/actions/GlobalActions";

import ProgressBar from "components/UI/ProgressSteps";
import DesignCard from "components/Designs/DesignCard/Facade";

import Form from "components/CustomBrand/UI/Formik/Form";
import Select from "components/CustomBrand/UI/Formik/Select";
import ReactSelect from "components/CustomBrand/UI/Formik/Select/ReactSelect";
import Input from "components/CustomBrand/UI/Formik/Input";
import DatePicker from "components/CustomBrand/UI/Formik/Datepicker";
import Button from "components/UI/Button/Button";

import { ReactComponent as RightArrow } from "assets/images/navigation/ic-chevron-right.svg";
import { IconInfo } from "components/UI/Icons";

import { allocateFactory } from "./services";

import "./styles.scss";
import { MAP_SAMPLE_TYPES } from "constants/Design";
import { flashError } from "store/actions/CommonActions";

const ProductionRequest = ({
  design,
  factories,
  sampleDetails,
  tnaTemplate,
  quoteRequestDetails,
  postNewSampleDetails,
  closeModal,
  getAllFactoriesForQuotes,
  getFactoryTnaTemplate,
  getSamplesByDesignId,
  flashError,
  currentUser,
  setHeaderTitle,
  skipSampling = 1
}) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const { category, sub_category } = design;

  const [formValue, setFormValue] = useState({});
  const [sampleSize, setSampleSize] = useState(null);
  const [dueDate, setDueDate] = useState(null);

  useEffect(() => {
    getAllFactoriesForQuotes({
      designId: design.id,
    });
  }, []);

  useEffect(() => {
    setHeaderTitle("Request production");
  }, []);

  const sizes = getSizesByDesignType(category, sub_category);

  useEffect(() => {
    const { id: designId } = design;
    const brandId = design.brand_id || design.brand;
    getFactoryTnaTemplate({
      designid: designId,
      brandid: brandId
    });
  }, []);

  const sampleForm = useRef();
  const factoryForm = useRef();

  let previousFactory = {};
  if (sampleDetails && sampleDetails.samples.length > 0) {
    const {
      factoryId,
      factoryName,
      lfrootAddressId
    } = sampleDetails.samples[0];
    previousFactory = {
      factoryId,
      factoryName,
      lfrootAddressId
    };
  } else if (quoteRequestDetails) {
    const factoryDetails = find(factories, { id: design.factory_assigned });
    if (factoryDetails) {
      previousFactory = {
        factoryId: factoryDetails.id,
        factoryName: factoryDetails.name,
        lfrootAddressId: factoryDetails.addresses[0].designs
      };
    }
  }

  let maxSizes = 5;

  return (
    <React.Fragment>
      <section className="production_request">
        <ProgressBar
          activeIndex={activeIndex}
          handleClick={setActiveIndex}
          panels={[
            {
              heading: "Sample details",
              content: (
                <Form
                  validationSchema={Yup.object().shape({
                    sampleSize: Yup.string()
                      .typeError("Sample size must be a string")
                      .required("Required"),
                    skipApproval: Yup.boolean()
                  })}
                  innerRef={sampleForm}
                  initialValues={
                    formValue.sampleSize
                      ? { ...formValue, sampleSize: formValue.sampleSize }
                      : {}
                  }
                >
                  {({
                    changeFormValue,
                    validateForm,
                    setFieldValue,
                    ...formProps
                  }) => (
                    <div className="production_request__wrapper">
                      <div className="info-note">
                        <IconInfo color="blue" />
                        <div>
                          <div className="f-color-black title__heading">
                            Important
                          </div>
                          <div className="text">
                            Factory will start bulk production after
                            dispatch/approval of PP/Size-set sample.
                          </div>
                          <br />
                          <div className="text">
                            For PP sample, select 1 size.
                          </div>
                          <div className="text">
                            For Size-set sample, select up to 5 sizes.
                          </div>
                        </div>
                      </div>
                      <div className="input_grp">
                        <Field
                          component={ReactSelect}
                          name="sampleSize"
                          type="select"
                          label={`Select size (up to ${maxSizes})`}
                          placeholder="Select sample size"
                          options={sizes}
                          required
                          isMulti
                          limit={maxSizes}
                          defaultValue={sampleSize ? sampleSize : null}
                          onChange={size => {
                            setSampleSize(size);
                            setFieldValue("sampleSize", size);
                          }}
                        />
                      </div>
                      <div className="input_grp checkbox">
                        <Field
                          name="skipApproval"
                          id="skipApproval"
                          label="Skip sample approval"
                          className="control control-checkbox control-checkbox--default"
                          type="checkbox"
                        />
                        <label htmlFor="skipApproval">
                          Skip sample approval
                        </label>
                      </div>
                    </div>
                  )}
                </Form>
              ),
              disabled: false
            },
            {
              heading: "Sample due date",
              content: (
                <div>
                  <Form
                    validationSchema={Yup.object().shape({
                      orderQuantity: Yup.string()
                        .typeError("Quantity cannot be empty")
                        .required("Required"),
                      dueDate: Yup.date()
                        .min(Date())
                        .required("Required"),
                      factoryId: Yup.number().required("Required")
                    })}
                    innerRef={factoryForm}
                    initialValues={
                      previousFactory
                        ? {
                            orderQuantity: sampleDetails.quantity,
                            factoryId: previousFactory.factoryId
                          }
                        : {
                            orderQuantity: sampleDetails.quantity
                          }
                    }
                  >
                    {({ changeFormValue, validateForm, ...formProps }) => {
                      if (!dueDate && formProps.dirty && formProps.isValid) {
                        setDueDate(formProps.values.dueDate);
                      }
                      return (
                        <React.Fragment>
                          <div>
                            <div className="production_request__wrapper">
                              {isEmpty(previousFactory) ? (
                                <div className="input_grp">
                                  <Field
                                    component={Select}
                                    name="factoryId"
                                    type="select"
                                    label="Select factory"
                                    placeholder="Select factory"
                                    options={transformFactoriesToOptions(
                                      factories
                                    )}
                                    required
                                  />
                                </div>
                              ) : (
                                <div className="input_grp">
                                  <label>Factory</label>
                                  <div className="factory-name">
                                    {previousFactory.factoryName}
                                  </div>
                                </div>
                              )}

                              <div className="input_grp">
                                <Field
                                  component={Input}
                                  name="orderQuantity"
                                  type="text"
                                  label="Order quantity"
                                  placeholder="Enter order quantity"
                                  required
                                />
                              </div>
                              <div className="input_grp">
                                <Field
                                  component={DatePicker}
                                  className="v2"
                                  name="dueDate"
                                  type="date"
                                  label="Due date"
                                  placeholder="Select date"
                                  required
                                  inline
                                  customInput={({ value, onClick }) => (
                                    <div
                                      className="custom_datepicker formik_datepicker"
                                      type="date"
                                      onClick={onClick}
                                    >
                                      {value || (
                                        <span className="f-color-faded">
                                          Select date
                                        </span>
                                      )}
                                    </div>
                                  )}
                                  defaultValue={dueDate}
                                />
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      );
                    }}
                  </Form>
                </div>
              ),
              disabled: Object.keys(formValue).length === 0
            }
          ]}
        />
        <DesignCard card={design} />
      </section>
      <div className="modal__footer flex f-sz-m f-w-bold footer">
        {activeIndex === 0 ? (
          <Button
            category="btn primary-blue"
            className="flex center"
            onClick={() => {
              sampleForm.current.validateForm().then(errors => {
                if (isEmpty(errors)) {
                  setFormValue(sampleForm.current.values);
                  setActiveIndex(1);
                } else {
                  return;
                }
              });
            }}
            disabled={isEmpty(sampleSize)}
          >
            NEXT <RightArrow />
          </Button>
        ) : (
          <Button
            category="btn primary-blue"
            className="flex center"
            onClick={() => {
              if (!factoryForm.current.values.dueDate) {
                flashError("Invalid due date");
                return;
              }
              const {
                brand: brandId,
                collection_id: collectionId,
                id: designId,
                name: designName
              } = design;
              const details = {
                collectionId,
                designId,
                brandId,
                designName,
                sampleRootId: sampleDetails.id,
                ...formValue,
                dueDate: factoryForm.current.values.dueDate,
                factoryId: previousFactory.factoryId,
                factoryName: previousFactory.factoryName,
                skipSampling,
                isProductionSample: 1
              };

              details.sampleType =
                sampleSize.length > 1
                  ? MAP_SAMPLE_TYPES.sizeSet
                  : MAP_SAMPLE_TYPES.preProduction;
              details.sampleSize = sampleSize.map(size => size.value).join(",");
              try {
                postNewSampleDetails(details).then(sampleData => {
                  const { newSampleId, designId, lfrootAddressId } = sampleData;
                  const { skipApproval = 0 } = formValue;

                  const {
                    orderQuantity,
                    factoryId,
                    dueDate
                  } = factoryForm.current.values;
                  allocateFactory(
                    {
                      newSampleId,
                      designId,
                      sampleType: details.sampleType,
                      lfrootAddressId,
                      isProductionSample: details.isProductionSample,
                      orderQuantity,
                      factoryId,
                      brandId,
                      collectionId,
                      skipApproval: skipApproval ? 1 : 0,
                      dueDate,
                      sampleStatus: null
                    },
                    tnaTemplate,
                    factories
                  );
                  getSamplesByDesignId({ designId });
                  closeModal();
                });
              } catch (err) {
                console.log(err);
              }
            }}
            disabled={!dueDate}
          >
            REQUEST SAMPLE
          </Button>
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  factories: state.factory.factoriesForQuotes,
  tnaTemplate: state.designs.factoryTnaTemplate,
  currentUser: state.auth.userDetails
});

const mapDispatchToProps = dispatch => ({
  postNewSampleDetails: details => dispatch(postNewSampleDetails(details)),
  getAllFactoriesForQuotes: details =>
    dispatch(getAllFactoriesForQuotes(details)),
  getFactoryTnaTemplate: details => dispatch(getFactoryTnaTemplate(details)),
  getSamplesByDesignId: details => dispatch(getSamplesByDesignId(details)),
  closeModal: () => dispatch(closeModal()),
  flashError: message => dispatch(flashError(message))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductionRequest);
