import React, { Component } from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import moment from "moment";

import Button from "components/UI/Button/Button";
import { TIME_DATE_FORMAT } from "constants/Date";
import { closeModal } from "store/actions/GlobalActions";
import { updateQuoteRequest } from "store/actions/DesignActions";
import { IconInfo } from "components/UI/Icons";

import "./styles.scss";

class UpdateRequestModal extends Component {
  static propTypes = {
    requestId: PropTypes.number.isRequired,
    closureDate: PropTypes.string,
    dueDate: PropTypes.string,
    minDate: PropTypes.string,
    maxDate: PropTypes.string,
    setHeaderTitle: PropTypes.func, // from Modal.jsx
  };

  constructor(props) {
    super(props);

    this.state = {
      date: this.props.closureDate || this.props.dueDate,
      keyDate: this.props.closureDate ? 'closureDate' : 'dueDate',
    };
  }

  renderNote() {
    const { date, keyDate } = this.state;

    if (keyDate !== 'closureDate') {
      return null;
    }

    const daysDiff = moment(date).diff(moment(), "days");

    return (
      <p className="note">
        <IconInfo color="blue" />
        {daysDiff > 0 ? (
          <span> Deadline in {daysDiff} days. </span>
        ) : daysDiff === 0 ? (
          <span>Deadline today</span>
        ) : (
              <span>Deadline past due in {Math.abs(daysDiff)} days.</span>
            )}
      </p>
    );
  }

  renderFooter() {
    const { updateQuoteRequest, requestId, closeModal } = this.props;
    const { date, keyDate } = this.state;

    return (
      <footer>
        <Button
          category="black-ghost medium"
          onClick={() => {
            closeModal();
          }}
        >
          CANCEL
        </Button>

        <Button
          category="primary-blue"
          onClick={() => {
            if (date) {
              updateQuoteRequest({ requestId, [keyDate]: date });
            }
          }}
        >
          CONFIRM
        </Button>
      </footer>
    );
  }

  render() {
    const { minDate = new Date(), maxDate } = this.props;
    const { date } = this.state;

    return (
      <div className="date-modal quote-date-modal">
        <main>
          <DatePicker
            fixedHeight
            popperPlacement="auto"
            popperClassName="custom-datepicker-popper"
            popperModifiers={{
              preventOverflow: {
                editMode: true,
                escapeWithReference: false // force popper to stay in viewport (even when input is scrolled out of view)
              }
            }}
            placeholderText="Select Date"
            dateFormat={TIME_DATE_FORMAT}
            className={"custom-datepicker custom-datepicker-nobg"}
            onChange={date =>
              this.setState({
                date
              })
            }
            inline
            selected={date}
            highlightDates={[{
              "react-datepicker__day--highlighted-custom": [this.props.closureDate]
            }]}
            minDate={minDate}
            maxDate={maxDate}
          />
        </main>

        {this.renderNote()}
        {this.renderFooter()}
      </div>
    );
  }
}

export default connect(null, {
  closeModal,
  updateQuoteRequest
})(UpdateRequestModal);
