import React from "react";
import { Link } from "react-router-dom";
import { ProfileAvatar } from "../../UI/ProfileAvatar";
import { useHover } from "../../../customHooks/useHover";
import { LOCO_SUPER, CLIENT_SUPER, CLIENT } from "../../../constants/Auth";
import { useToggle } from "../../../customHooks/useToggle";
import FactoryRenderer from "./FactoryRenderer";
import PrivateContainer from "../../UI/PrivateContainer/PrivateContainer";
import { TextSpliced } from "components/UI/TextSpliced";

import editIcon from "./../../../assets/images/action/ic-edit.svg";
import icAddPeople from "../../../assets/images/social/ic-person-add.faded.svg";

import "./FactoryItem.scss";

const FactoryItem = ({
  data: { name, users = [], addresses = [], id, productionDetails = {}, alias },
  addUser,
  showUsers,
  data,
  usersCount,
  clickedFactories,
  openModal,
  merchantsList,
  handleClick,
  updateUser,
  updateAssociation,
  createAssociation,
  isSuperAdmin,
  ...restProps
}) => {
  const [hoverRef, hovered] = useHover();

  const handleAddClick = () => {
    addUser({ factoryName: name, factoryId: id, addresses });
  };

  const viewDetails = productionDetails => {
    openModal("FACTORY_PRODUCTION_DATA", {
      productionDetails
    });
  };

  const associateProductMerchant = editMode => {
    const transformedMerchants = merchantsList.map(({ id, name }) => ({
      label: name,
      value: id
    }));
    openModal("MANAGER_FACTORY_MERCHANTS_ASSOCIATION", {
      merchantsList: transformedMerchants,
      createAssociation,
      updateAssociation,
      factoryId: id,
      initialValues: data.merchants || [{
        label: '',
        value: ''
      }],
      editMode
    });
  };

  return (
    <React.Fragment>
      <div className="container accounts-factories">
        <div
          className={`factory_row ${isSuperAdmin ? "grid-6" : ""}`}
          ref={hoverRef}
        >
          <div className="factory-cell name">
            <div className="name-container">
              <ProfileAvatar id={id} name={name} alias={alias} staticColor />
              <div>
                <TextSpliced
                  className="profile-name text-black"
                  text={name}
                  maxWidth="200"
                />
                {data.createdByName && (
                  <PrivateContainer
                    allowRoles={[LOCO_SUPER]}
                    render={() => (
                      <div className="factory-cell added-by">
                        by {data.createdByName}
                        {data.isCreatorLfUser ? (
                          <span className="lf-logo" />
                        ) : null}
                      </div>
                    )}
                  />
                )}
              </div>
            </div>
          </div>
          <PrivateContainer
            allowRoles={[LOCO_SUPER]}
            render={() => (
              <div className="factory-cell owner-name">
                {Array.isArray(data.associatedBrands) &&
                  data.associatedBrands.length > 0 && (
                    <div>
                      {data.associatedBrands[0].name}
                      {data.associatedBrands.length > 1 && (
                        <span className="extra-brands-count">
                          + {data.associatedBrands.length - 1} more
                        </span>
                      )}
                    </div>
                  )}
              </div>
            )}
          />
          <PrivateContainer
            allowRoles={[CLIENT_SUPER, CLIENT]}
            render={() => (
              <div className="factory-cell added-by text-black">
                {data.createdByName}
                {data.isCreatorLfUser ? <span className="lf-logo" /> : null}
              </div>
            )}
          />
          <PrivateContainer
            allowRoles={[LOCO_SUPER]}
            render={() => (
              <div className="factory-cell added-by text-black">
                {data.factoryDetail.sourcedByLf ? "Yes" : "No"}
              </div>
            )}
          />
          <PrivateContainer
            allowRoles={[LOCO_SUPER]}
            render={() => (
              <>
                <div className="factory-cell merchants">
                  {data.merchants && data.merchants.length > 0 ? (
                    <React.Fragment>
                      <div className="text-black" style={{ padding: "8px 0" }}>
                        {data.merchants.map(({ label }) => label).join(` | `)}
                        <div
                         onClick={() => associateProductMerchant(true)}
                         className="link with-hover"
                        >Edit</div>
                      </div>
                    </React.Fragment>
                  ) : (
                    <p
                      className="link"
                      onClick={() => associateProductMerchant(false)}
                    >
                      ASSIGN
                    </p>
                  )}
                </div>
              </>
            )}
          />

          <div className="factory-cell">
            <UserColumn
              brandId={id}
              usersCount={users.length}
              hovered={hovered}
              setClickHandler={handleClick}
              handleAddClick={handleAddClick}
              updateAssociation={updateAssociation}
              data={data}
            />
          </div>
        </div>
        <div className="footer-container">
          {users && clickedFactories[id] && (
            <FactoryRenderer
              updateUser={updateUser}
              openModal={openModal}
              usersList={users}
              updateAssociation={updateAssociation}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

const UserColumn = ({
  usersCount,
  hovered,
  setClickHandler,
  brandId,
  handleAddClick,
  data
}) => {
  const [buttonRef, opened] = useToggle();

  return (
    <div className="more-options">
      {hovered && (
        <React.Fragment>
          <span className="grid-1 icons-sec">
            <Link
              className="clickable"
              to={{
                pathname: `../factories/edit/?id=${data.id}`,
                state: { initialValues: data, editMode: true }
              }}
            >
              <img src={editIcon} className="edit-icon icon-edit" />
            </Link>
          </span>
          <span onClick={handleAddClick} className="icons-sec clickable">
            <img src={icAddPeople} className="edit-icon icon-add" />
          </span>
        </React.Fragment>
      )}
      <span className="grid-3" ref={buttonRef} onClick={setClickHandler}>
        {usersCount} Users
        <span>
          {opened ? (
            <i className="fa fa-angle-up" />
          ) : (
            <i className="fa fa-angle-down" />
          )}
        </span>
      </span>
    </div>
  );
};

export default FactoryItem;
