import React, { useState, useEffect } from "react";
import axios from "axios";
import _ from "lodash";
import { connect } from "react-redux";

import PropTypes from "prop-types";
import actions from "../../store/actions";

import Button from "../UI/Button/Button";
import BootstrapError from "../UI/BootstrapError";

import styles from "./DetailsModel.module.scss";
import "react-dropdown/style.css";

import { get_service_endpoint } from "../../ServiceEndpoints";
import Downshift from "downshift";
import PerfectScrollbar from 'react-perfect-scrollbar';
import icDropdown from "./../../assets/images/navigation/ic-arrow-drop-down.svg";
const eq = get_service_endpoint("apiv2");


// ==== THIS IS ONLY SHOWN FEOM FACTORY SIDE ====
const ShareWithContacts = ({
  isFactory,
  dispatch,
  swatches,
  clearState,
  brandid
}) => {
  const propTypes = {
    isFactory: PropTypes.bool.isRequired
  };
  const [users, setUsers] = useState([]);
  const [selectedUsers, setselectedUsers] = useState([]);
  const [isError, setIsError] = useState({ message: "null", value: false });

  useEffect(()=>{
    axios.get(`${eq}/users/getallcontacts`, {
      params: {
        isLoader: true
      }
    }).then(response=>{
      setUsers(response.data)
    })
  },[])

  // == handles the chackbox user filter ===
  const selectUserHandler = item => {
    // This saves the selected users
    // if (_.includes(selectedUsers, { email: item })) {
    //   _.remove(selectedUsers, user => user.id === item);

    //   setselectedUsers(selectedUsers);

    // } else {
      const user = _.find(users, {id: item});
      setselectedUsers([user]);
    // }

    // == This changes the users as slected ==
    const updatedUsers = users.map(user => {
      if (user.id === item) {
        return { ...user };
      }
      return user;
    });
    setUsers(updatedUsers);
  };

  // === This function shares the designs Multiple or single the form ====
  const shareDesignHandler = () => {
    const swatchids = swatches.map(swatch=>swatch.id)
    const request = {
      swatches: swatchids,
      users: selectedUsers
    };

    if (!selectedUsers.length) {
      setIsError({ message: "Select users!", value: true });
      setTimeout(() => {
        setIsError({ message: "", value: false });
      }, 800);
      return;
    }
    axios
      .post(`${eq}/swatch/contacts`, request)
      .then(res => {
        dispatch(actions.flash({ message: "Shared successfully." }));
        clearState();
        dispatch(actions.closeModal());
      })
      .catch();
  };

  const contactOptions = users.map(user => ({
    label: user.name,
    value: user.id,
    email: user.email
  }))
  return (
    <div className={styles.swatch__details_add_to_design}>
      <div
        className={styles.swatch__details_form_}
        style={{ width: "400px", padding: "25px" }}
      >
        <div className={styles.swatch__details_form_upper_}>
          <div className={styles.swatch__name}>
            {/* 100% Cotton Red/White Colour Gingham Checks */}
          </div>

          <>
            {/* ==== DROP DOWNS FROM HERE BRAND ==== */}
            <div
              style={{
                fontSize: "12px",
                color: "var(--fill-grey-medium)",
                padding: "0 0 3px 10px"
              }}
            >
              Users
            </div>

            {/* <Dropdown
              // initialSelectedItem={{
              //   label: "Select",
              //   value: "All"
              // }}
              options={contactOptions}
              onChange={(selection) => selectUserHandler(selection.value)}
              value={selectedUsers.length > 0 ? selectedUsers[0].name : "Select one"}
              isApplyBar
              placeholder="Select one"
              style={{ cursor: "pointer" }}
              menuClassName={styles.dropdown__styles_menu}
            /> */}
            <Downshift
            itemToString={item => item && item.label}
            onChange={selection => {
              selectUserHandler(selection.value)
            }}
            >
            {({
              getInputProps,
              getToggleButtonProps,
              getItemProps,
              getLabelProps,
              getMenuProps,
              isOpen,
              inputValue,
              highlightedIndex,
              selectedItem
            }) => {
              return (
                <div
                  {...getToggleButtonProps()}
                  type="none"
                  style={{
                    userSelect: "none",
                    position: "relative",
                    cursor: "pointer",
                    border: "solid 1px var(--fill-grey-light)",
                    borderRadius: "2px",
                    padding: "6px 12px",
                    height: "44px"
                  }}
                >

                  <span className="flex" style={{ alignItems: "center", justifyContent: "space-between" }}>
                    <input
                      style={{
                        border: "none",
                        backgroundColor: "transparent",
                        maxWidth: "100%",
                        width: '100%',
                        fontWeight:'600',
                        color: 'var(--fill-locofast-black)'
                      }}
                      className="select-input-transparent"
                      readOnly={true}
                      disabled={true}
                      placeholder="Select user"
                      {...getInputProps()}
                    />
                    <img
                      src={icDropdown}
                      alt="dropdown"
                      style={{ marginTop: '4px'}}
                    />
                  </span>

                  {isOpen ? (
                    <div
                      className="select-pane"
                      onClick={e => {
                        e.stopPropagation();
                      }}
                      // style={{width:innerWidth}}
                    >
                      <div>
                        <PerfectScrollbar  style={{ maxHeight: "300px" }}>
                          {contactOptions
                            // .filter(item => item.label !== selectedItem.label)
                            .map((item, index) => {
                              return (
                                <div
                                  {...getItemProps({
                                    className:
                                      `in-selection collection-collab__label_left_aigned flex ${selectedItem && selectedItem.label === item.label && 'selected'}`,
                                    index,
                                    item,
                                    key: item.label,
                                    style: {
                                      flexDirection: 'column'
                                    }
                                  })}
                                >
                                  {item.label}
                                  <span>{item.email}</span>
                                </div>
                              );
                            })}
                        </PerfectScrollbar>
                      </div>
                    </div>
                  ) : null}
                </div>
              );
            }}
          </Downshift>

            {/* ==== DROP DOWNS FROM HERE Collection ==== */}
          </>
        </div>

        <div style={{ textAlign: "center", marginTop: "30px" }}>
          <div style={{ height: "30px" }}>
            <BootstrapError message={isError.message} show={isError.value} />
          </div>
          <Button
            category="blue-bg"
            style={{ height: "48px", width: "100%" }}
            onClick={shareDesignHandler}
            type="button"
          >
            <span style={{ fontWeight: "600" }}>SHARE</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default connect(null)(ShareWithContacts);
