import React, { useState, useEffect } from "react";
import deepEqual from "deep-equal";
import { DateRangePicker } from "react-dates";

import { ReactComponent as CancelIcon } from "assets/images/navigation/ic-close-small.faded.svg";
import { ReactComponent as DropdownIcon } from "assets/images/navigation/ic-arrow-drop-down-grey.svg";

export const DatePicker = ({
  label,
  updateFilterDates,
  selectedDates = {}
}) => {
  const [dates, setDates] = useState({ minDate: null, maxDate: null });
  const [focusedInput, setFocusedInput] = useState(null);

  useEffect(() => {
    if (!deepEqual(dates, selectedDates)) {
      updateFilterDates(dates);
    }
  }, [dates]);

  useEffect(() => {
    if (selectedDates.minDate || selectedDates.maxDate) {
      setDates({
        minDate: selectedDates.minDate,
        maxDate: selectedDates.maxDate
      });
    }
  }, [selectedDates]);

  return (
    <div className="reports-datepicker">
      <div className="reports-dropdown__label">{label}</div>
      <DateRangePicker
        startDate={dates.minDate}
        startDateId="your_unique_start_date_id"
        endDate={dates.maxDate}
        endDateId="your_unique_end_date_id"
        onDatesChange={({ startDate, endDate }) =>
          setDates({ minDate: startDate, maxDate: endDate })
        }
        focusedInput={focusedInput}
        onFocusChange={focusedInput => setFocusedInput(focusedInput)}
        displayFormat="D MMM YYYY"
        customArrowIcon="-"
        isOutsideRange={date => date.startOf("day").isAfter(new Date())}
        showClearDates
        customCloseIcon={(dates.minDate || dates.maxDate) && <CancelIcon />}
        customInputIcon={
          dates.minDate || dates.maxDate ? null : <DropdownIcon />
        }
        inputIconPosition="after"
        minimumNights={0}
      />
    </div>
  );
};
