import React from 'react';
import * as Yup from "yup";
import { Field } from 'formik';
import { ALL_STATES } from '../../../../constants/Global';
import Form from '../../../CustomBrand/UI/Formik/Form';
import Input from '../../../CustomBrand/UI/Formik/Input';
import Select from '../../../CustomBrand/UI/Formik/Select';
import './style.scss';

const YUP_ADDRESS = Yup.object().shape({
  addressLine1: Yup.string().required("Required"),
  addressLine2: Yup.string(),
  landmark: Yup.string(),
  city: Yup.string().required("Required"),
  state: Yup.string().required("Required"),
  tag: Yup.string(),
});

const ALL_STATES_LABELED = ALL_STATES.map(state => ({
  label: state,
  value: state,
}));


export default class AddAddressModal extends React.Component {

  constructor(props) {
    super(props);

    props.setIsFooterEnabled(false);
  }

  _onValidationChange = (isValid) => {
    const { setIsFooterEnabled } = this.props;

    setIsFooterEnabled(isValid);
  }

  render() {
    const { onSubmit } = this.props;

    return <div
      className="order-add-address-modal"
    >
      <Form
        onValidationChange={this._onValidationChange}
        validationSchema={YUP_ADDRESS}
        validateOnMount={true}
        onSubmit={onSubmit}
      >
        {() => (<>
          <Field
            component={Input}
            type="text"
            className="address__line-1"
            label="Address Line 1"
            required={true}
            autoFocus={true}
            name="addressLine1"
          />
          <Field
            component={Input}
            type="text"
            className="address__line-2"
            label="Address Line 2"
            name="addressLine2"
          />
          <Field
            component={Input}
            type="text"
            className="address__landmark"
            label="Landmark"
            name="landmark"
          />
          <Field
            component={Input}
            type="text"
            className="address__city"
            label="City"
            name="city"
            required={true}
          />
          <Field
            component={Select}
            className="address__state"
            options={ALL_STATES_LABELED}
            label="State"
            name="state"
            required={true}
            placeholder="Select State"
          />
          <Field
            component={Input}
            type="text"
            className="address__name"
            label="Save address as (will not be included in address)"
            name="tag"
            placeholder="E.g. Mumbai Warehouse"
          />
        </>)}
      </Form>
    </div>
  }
}
