import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import Popover from "react-tiny-popover";
import {
  QUOTE_STATUS_CNAME,
  QUOTE_STATUS_AS_STRING,
  QUOTE_STATUS_TO_STRING_BRAND,
  QUOTE_STATUS_TO_STRING_FACTORY,
  QUOTE_STATUS,
} from "../../../../constants/Quote";
import { daysAgo } from "../../../../helpers/DateHelpers";
import {
  IconLocation,
  IconFactoryMachine,
  IconArrow,
  IconClose,
  IconCheckMark,
  IconSave,
  IconSubmit,
  IconEditPencil,
} from "../../../UI/Icons";
import NotificationToggle from "../../../UI/Toggle/NotificationToggle";
import { toggleStatus } from "../../../../helpers/toggleStatus";
import Note from "../../../UI/Note/Note";
import BundledQuote from "../BundledQuote";
import OpenQuote from "../OpenQuote";
import {
  CLIENT,
  CLIENT_SUPER,
  LOCO_SUPER,
  FACTORY_ADMIN,
  FACTORY_MANAGER,
  PRODUCT_DEVELOPMENT,
  PRODUCTION_MERCHANT,
} from "../../../../constants/Auth";
import Button from "../../../UI/Button/Button";

import collapseMoreImg from "../../../../assets/images/navigation/Line.svg";
import icFile from "../../../../assets/images/file-dollar.svg";
import acceptedFile from "../../../../assets/images/gree.svg";
import rejectFile from "../../../../assets/images/file-text.svg";
import moreVert from "../../../../assets/images/navigation/ic-more-vert.svg";

const MAP_STATUS_IMG_FILE = {
  [QUOTE_STATUS_AS_STRING.DRAFT]: icFile,
  [QUOTE_STATUS_AS_STRING.SUBMITTED]: icFile,
  [QUOTE_STATUS_AS_STRING.ACCEPTED]: acceptedFile,
  [QUOTE_STATUS_AS_STRING.RESUBMISSION]: rejectFile,
  [QUOTE_STATUS_AS_STRING.WITHDRAWN]: icFile,
};

export default class QuoteDropdown extends Component {
  static propTypes = {
    quote: PropTypes.object.isRequired,
    design: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    currency: PropTypes.string.isRequired,
    defaultExpanded: PropTypes.bool,
    updateQuote: PropTypes.func.isRequired,
    openSubmitQuoteModal: PropTypes.func.isRequired,
    openUpdateQuoteModal: PropTypes.func.isRequired,
    openWithdrawQuoteRequestModal: PropTypes.func.isRequired,
    flashError: PropTypes.func.isRequired,
    anyQuoteAccepted: PropTypes.bool.isRequired,
  };

  saveQuoteInsider = {};

  constructor(props) {
    super(props);

    this.state = {
      ...this._getQuoteForState(props.quote),
      isExpanded: props.defaultExpanded || false,
      isFormChanged: false,
      isSaved: false,
      countAwaited: 0,
      isRevising: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.quote, this.props.quote)) {
      this.setState(this._getQuoteForState(nextProps.quote));
    }
  }

  _getQuoteForState = (quote) => {
    const copyQuote = cloneDeep(quote);
    const quoteDetails = copyQuote.quoteDetails;

    if (quoteDetails && copyQuote["status"] === "-1") {
      quoteDetails["bundled_quantity"] = copyQuote["total_quantity"];
      quoteDetails["total_quantity"] = copyQuote["total_quantity"];
    }

    return {
      quote: copyQuote,
      quoteDetails,
    };
  };

  _toggleDropdown = () => {
    this.setState({
      isExpanded: !this.state.isExpanded,
    });
  };

  _onEditQuote = ({ quoteDetails, quote }) => {
    this.setState({
      isFormChanged: true,
      quoteDetails,
    });
  };

  _onReviseQuote = (quote) => {
    this.setState({ isRevising: true });
  };

  _onCancelReviseQuote = () => {
    const { quote } = this.props;
    this.setState({ isRevising: false });
    this.setState(this._getQuoteForState(quote));
  };

  _onSaveQuote = (quote, quoteDetails) => {
    const { design, updateQuote, flashError } = this.props;
    const totalCost =
      quote.type === "BUNDLED"
        ? quoteDetails.price_per_garment
        : quoteDetails.totalCost;
    const totalQuantity =
      quote.type === "BUNDLED"
        ? quoteDetails["bundled_quantity"]
        : quoteDetails["total_quantity"];

    if (!/^\d{1,}(\.\d{1,2})?$/.test(totalCost) || totalCost <= 0) {
      flashError(
        "Invalid amount. It should be more than zero and up to 2 decimals."
      );
      return;
    }

    if (!(Number(totalCost.split(".")[0]) < Number.MAX_SAFE_INTEGER)) {
      flashError("Invalid amount.");
      return;
    }

    this.setState({ isFormChanged: false });

    updateQuote({
      quote: quoteDetails,
      quote_id: quote.id,
      quote_type: quote.quote_type,
      status: quote.status,
      total_cost: totalCost,
      total_quantity: totalQuantity,
      design_name: design.name,
      design_id: design.id,
      collection_id: design.collection_id,
      flashMsg: `Quote for ${design.name} saved`,
    });
  };

  _onUpdateQuote = (isSubmit) => {
    const {
      design,
      openSubmitQuoteModal,
      openUpdateQuoteModal,
      flashError,
      quote: propsQuote,
    } = this.props;
    const { quote, quoteDetails } = this.state;
    const totalCost =
      quote.type === "BUNDLED"
        ? quoteDetails.price_per_garment
        : quoteDetails.totalCost;
    const totalQuantity =
      quote.type === "BUNDLED"
        ? quoteDetails["bundled_quantity"]
        : quoteDetails["total_quantity"];

    if (!/^\d{1,}(\.\d{1,2})?$/.test(totalCost) || totalCost <= 0) {
      flashError(
        "Invalid amount. It should be more than zero and up to 2 decimals."
      );
      return;
    }

    if (!(Number(totalCost.split(".")[0]) < Number.MAX_SAFE_INTEGER)) {
      flashError("Invalid amount.");
      return;
    }

    const openQuoteModal = isSubmit
      ? openSubmitQuoteModal
      : openUpdateQuoteModal;

    openQuoteModal({
      hiddenFields: {
        quote: quoteDetails,
        quote_id: quote.id,
        quote_type: quote["quote_type"],
        total_cost: totalCost,
        total_quantity: totalQuantity,
        status: 0,
        design_name: design.name,
        design_id: design.id,
        collection_id: design.collection_id,
        flashMsg: `Quote for ${design.name} ${
          isSubmit ? "submitted" : "updated"
        }`,
      },
      newQuoteDetails: quoteDetails,
      oldQuoteDetails: propsQuote.quoteDetails,
      onSuccess: () => {
        this.setState({ isRevising: false });
      },
    });

    // updateQuote({
    //   quote: quoteDetails,
    //   quote_id: quote.id,
    //   quote_type: quote["quote_type"],
    //   total_cost: quoteDetails["price_per_garment"],
    //   total_quantity: quoteDetails["bundled_quantity"],
    //   status: 0,
    //   design_name: design.name,
    //   collection_id: design.collection_id,
    //   flashMsg: `Quote for ${
    //     design.name
    //     } submitted`,
    // });
  };

  _withdrawQuoteRequest = (quote) => {
    const {
      quoteRequestDetails: { id: quoteRequestId, design_id: designId },
      openWithdrawQuoteRequestModal,
    } = this.props;

    openWithdrawQuoteRequestModal({
      hiddenFields: {
        quoteRequestId,
        designId,
        factoryIds: [quote.factory_id],
      },
    });

    this.setState({ popOverOpen: false });
  };

  _getHeaderSubtitle = (quote) => {
    switch (quote.status) {
      case "-1":
        return "DRAFT";
      default:
        return `Shared by ${quote.name}, ${daysAgo(quote.created_on)}`;
    }
  };

  _renderDropdownActions = ({ quote }) => {
    const {
      openRejectQuoteModal,
      openApproveQuoteModal,
      currentUser,
    } = this.props;
    const { role: userRole } = currentUser;
    const { countAwaited, isRevising, quoteDetails } = this.state;

    if (!quoteDetails) {
      return null;
    }

    const quoteStatus = Number(quote["status"]);

    // For Loco admins
    if (
      userRole === FACTORY_ADMIN ||
      userRole === FACTORY_MANAGER ||
      userRole === PRODUCT_DEVELOPMENT ||
      userRole === PRODUCTION_MERCHANT
    ) {
      switch (quoteStatus) {
        case QUOTE_STATUS.DRAFT:
          return (
            <>
              <Button
                className="cta-save-quote"
                category="black-ghost primary-text"
                onClick={(e) => {
                  this._onSaveQuote(quote, quoteDetails);
                  e.stopPropagation();
                }}
                disabled={
                  !this.state.isFormChanged || quoteDetails.totalCost <= 0
                }
              >
                <IconSave
                  color={
                    this.state.isFormChanged || quoteDetails.totalCost <= 0
                      ? "blue"
                      : "gray"
                  }
                  height="20px"
                />
                <span className="icon-text">Save</span>
              </Button>
              <Button
                category="blue-bg"
                onClick={(e) => {
                  this._onUpdateQuote(true);
                  e.stopPropagation();
                }}
                disabled={quoteDetails.totalCost <= 0}
              >
                <IconSubmit color="white" height="20px" />
                <span className="icon-text">Submit</span>
              </Button>
            </>
          );
        case QUOTE_STATUS.SUBMITTED:
          if (isRevising) {
            return (
              <>
                <Button
                  category="black-ghost primary-text"
                  onClick={(e) => {
                    this._onCancelReviseQuote(quote);
                    e.stopPropagation();
                  }}
                >
                  <span className="icon-text">Cancel</span>
                </Button>
                <Button
                  category="blue-bg"
                  onClick={(e) => {
                    this._onUpdateQuote(false);
                    e.stopPropagation();
                  }}
                  disabled={!this.state.isFormChanged}
                >
                  <IconSave color="white" height="20px" />
                  <span className="icon-text">Update</span>
                </Button>
              </>
            );
          } else {
            return (
              <>
                <Button
                  category="black-ghost primary-text"
                  onClick={(e) => {
                    this._onReviseQuote(quote);
                    e.stopPropagation();
                  }}
                >
                  <IconEditPencil color="blue" height="20px" />
                  <span className="icon-text">Revise Quote</span>
                </Button>
              </>
            );
          }
        case QUOTE_STATUS.ACCEPTED && quote.is_pre_approved: {
          return (
            <div className="quote-status">Pre-approved</div>
          )
        }
        default:
          return (
            <div className="quote-status">
              {QUOTE_STATUS_TO_STRING_FACTORY[quote.status.toUpperCase()]}
            </div>
          );
      }
    }
    // For Clients
    else if (
      userRole === CLIENT_SUPER ||
      userRole === CLIENT ||
      userRole === LOCO_SUPER
    ) {
      switch (quoteStatus) {
        case 0:
          return (
            <React.Fragment>
              <Button
                category="black-ghost red-text"
                onClick={(e) => {
                  openRejectQuoteModal(quote);
                  e.stopPropagation();
                }}
              >
                <IconClose color="red" height="20px" />
                <span className="icon-text">Reject</span>
              </Button>
              <Button
                category="black-ghost primary-text"
                onClick={(e) => {
                  openApproveQuoteModal(quote, countAwaited);
                  e.stopPropagation();
                }}
              >
                <IconCheckMark color="blue" height="20px" />
                <span className="icon-text">Approve</span>
              </Button>
            </React.Fragment>
          );
        default:
          return null;
      }
    } else {
      return null;
    }
  };

  _renderDropdownHeaderFactory = ({ quote, quoteDetails }) => {
    return (
      <>
        <div className="file-image">
          <img src={MAP_STATUS_IMG_FILE[quote.status]} alt="File" height="32" />
        </div>
        <div className="flex-col cell-v2">
          <div className="overflow-ellipsis">
            <div className="quote__type-name">
              {(quote.quote_type && quote.quote_type.toLowerCase()) || "--"}{" "}
              quote
            </div>
          </div>
          <div className="quote__subtitle">
            {this._getHeaderSubtitle(quote)}
          </div>
        </div>
        <div className="dropdown__header__last-col">
          <div className="quote-cta-list">
            {this._renderDropdownActions({ quote })}
          </div>
        </div>
      </>
    );
  };

  _renderDropdownHeaderBrand = ({ quote, quoteDetails }) => {
    const { currentUser, quoteRequestDetails, anyQuoteAccepted } = this.props;

    const quoteId = quote["id"];
    const isBrandView = currentUser.isBrand || currentUser.isSuperAdmin;
    const canExpand = quoteId && isBrandView && quote.quote_type === "OPEN";
    const totalCost = (quoteDetails && +quoteDetails.totalCost) || 0;
    const quoteAmountDiff =
      quoteDetails && quoteRequestDetails
        ? totalCost - quoteRequestDetails.price
        : 0;
    const absQuoteAmountDiff = Math.abs(quoteAmountDiff.toFixed(2));
    const quoteAmountDiffStatus =
      quoteAmountDiff === 0 ? "matched" : quoteAmountDiff > 0 ? "more" : "less";

    return (
      <>
        <div className="file-image">
          <img src={icFile} alt="File" height="25" />
        </div>
        <div className="flex-col cell-v2">
          <div className="overflow-ellipsis">
            <span>{quote.factory_name}</span>
          </div>
          <div>
            {!quote.is_pre_approved && (quote.modified_on ? (
              <>
                {isBrandView ? "Received" : "Submitted"}{" "}
                {daysAgo(quote.modified_on, { prefixOn: true })}
              </>
            ) : (
              <span className="f-color-faded">--</span>
            ))}
          </div>
        </div>
        <div>
          <IconLocation />
          <span className="icon-text overflow-ellipsis">
            {quote.factory_city}
          </span>
        </div>
        <div>
          <IconFactoryMachine />
          <span className="icon-text">
            {quote.factory_machines} &nbsp;
            {quote.factory_machines <= 0 ? "machine" : "machines"}
          </span>
        </div>
        <div
          className={`cell-v2 t-align-right quote-price quote-price--${quoteAmountDiffStatus}`}
        >
          {quote.id ? (
            <>
              <div>
                {this.props.currency}&nbsp;{totalCost.toFixed(2)}
              </div>
              {!quote.is_pre_approved && (
                <div className="quote-price__info">
                  {quoteAmountDiff === 0 ? (
                    "MATCHED"
                  ) : (
                    <>
                      <span>
                        <span className="icon-text">
                          {this.props.currency}&nbsp;
                          {absQuoteAmountDiff.toFixed(2)}
                        </span>
                        <IconArrow
                          color={quoteAmountDiff > 0 ? "red" : "green"}
                          direction={quoteAmountDiff > 0 ? "up" : "down"}
                        />
                      </span>
                    </>
                  )}
                </div>
              )}
            </>
          ) : (
            <span className="f-color-faded">--</span>
          )}
        </div>

        {/* last col - ctas, status and dropdown */}
        <div className="dropdown__header__last-col">
          {!anyQuoteAccepted && (
            <div className="quote-cta-list">
              {this._renderDropdownActions({ quote })}
              {this.props.notificationMode && Number(quote.status) === 0 && (
                <NotificationToggle
                  theme="locofast"
                  enabled={toggleStatus(this.props.notificationList, {
                    design_id: this.props.match.params.design_id,
                    type: "quote",
                    id: quoteId,
                  })}
                  onStateChanged={({ enabled }) => {
                    const checked = enabled;
                    const {
                      design_id,
                      collection_id,
                      brand_id,
                    } = this.props.match.params;
                    if (checked) {
                      const { design, collection } = this.props;
                      this.props.addPendingNotification({
                        design_id,
                        brand_id,
                        collection_id,
                        designName: design.name,
                        collectionName: collection.name,
                        notif_details: {
                          type: "quote",
                          name: quote.quote_type,
                          quoteId,
                        },
                      });
                    } else {
                      this.props.removePendingNotification({
                        design_id,
                        type: "quote",
                        name: quote,
                      });
                    }
                  }}
                />
              )}
            </div>
          )}

          {/* quote status */}
          {(anyQuoteAccepted ||
            quote.status !== QUOTE_STATUS_AS_STRING.SUBMITTED) && (
            <div className="quote-status cell-v2">
              <div className="quote-status__info">
                {!anyQuoteAccepted ||
                quote.status !== QUOTE_STATUS_AS_STRING.DRAFT
                  ? quote.status === QUOTE_STATUS_AS_STRING.WITHDRAWN
                    ? "Withdrawn"
                    : quote.is_pre_approved ? "Pre-approved" : QUOTE_STATUS_TO_STRING_BRAND[quote.status]
                  : "Not received"}
                {!anyQuoteAccepted &&
                  quote.status === QUOTE_STATUS_AS_STRING.DRAFT &&
                  this._renderPopover(quote)}
              </div>
              {quote.modified_on && (
                <div className="quote-status__date">
                  {daysAgo(quote.modified_on)}
                </div>
              )}
            </div>
          )}

          {/* expand/collapse img */}
          <div
            style={{
              marginLeft: "24px",
              userSelect: "none",
              visibility: canExpand ? "visible" : "hidden",
              height: "100%",
              display: "inline-block",
            }}
          >
            <img
              className="arrow"
              style={{
                transform: `rotate(${this.state.isExpanded ? -180 : 0}deg)`,
              }}
              src={collapseMoreImg}
              alt="ic-collapse-more"
            />
          </div>
        </div>
      </>
    );
  };

  _renderPopover = (quote) => {
    return (
      <div className="quote-status__popover">
        <Popover
          position={"bottom"}
          isOpen={this.state.popOverOpen}
          onClickOutside={() => this.setState({ popOverOpen: false })}
          content={
            <div
              className="pop-over__item flex f-sz-l"
              onClick={() => this._withdrawQuoteRequest(quote)}
            >
              Withdraw request
            </div>
          }
        >
          <img
            className="header-dots"
            onClick={(evt) => {
              this.setState({ popOverOpen: true });
            }}
            src={moreVert}
            alt="three dots"
          />
        </Popover>
      </div>
    );
  };

  _renderDropdownHeader = ({ quote, quoteDetails }) => {
    const { currentUser } = this.props;

    const quoteId = quote["id"];
    const isBrandView = currentUser.isBrand || currentUser.isSuperAdmin;
    const canExpand = quoteId && isBrandView && quote.quote_type === "OPEN";

    return (
      <div
        className={classnames(
          "dropdown__header",
          "card-section",
          "flex",
          `dropdown__header--${QUOTE_STATUS_CNAME[quote.status]}`,
          {
            expanded: this.state.isExpanded,
            expandable: canExpand,
            "can-hover":
              isBrandView &&
              (canExpand ||
                quote.status === QUOTE_STATUS_AS_STRING.DRAFT ||
                quote.status === QUOTE_STATUS_AS_STRING.SUBMITTED),
          }
        )}
        onClick={() => canExpand && this._toggleDropdown(quote)}
      >
        {isBrandView
          ? this._renderDropdownHeaderBrand({ quote, quoteDetails })
          : this._renderDropdownHeaderFactory({ quote, quoteDetails })}
      </div>
    );
  };

  _renderDropdownFooter({ quote }) {
    const { isExpanded, isRevising, quoteDetails } = this.state;
    const quoteId = quote.id;

    if (!quote["quote_type"]) {
      return;
    }

    return (
      <div
        key={quoteId}
        className={classnames({
          card__section: true,
          hide: !isExpanded,
        })}
      >
        {quote["comment"] && <Note>{quote["comment"]}</Note>}
        {quote["quote_type"] === "BUNDLED" ? (
          <BundledQuote
            status={quote["status"]}
            readOnly={
              ["1", "2"].includes(quote["status"]) ||
              (quote["status"] === "0" && !isRevising) ||
              this.props.readOnly
            }
            quoteDetails={quoteDetails}
            currency={this.props.currency}
            updateEditedQuote={({ quoteDetails }) =>
              this._onEditQuote({ quote, quoteDetails })
            }
          />
        ) : (
          <OpenQuote
            currency={this.props.currency}
            saveQuoteHandler={(ref) => (this.saveQuoteInsider[quoteId] = ref)}
            updateEditedQuote={({ quoteDetails, ...args }) => {
              console.log("args", { quoteDetails, ...args });
              this._onEditQuote({ quoteDetails, quote });
            }}
            readOnly={
              ["1", "2"].includes(quote["status"]) ||
              (quote["status"] === "0" && !isRevising) ||
              this.props.readOnly
            }
            cancelReviseQuote={this._onCancelReviseQuote}
            quote={quoteDetails}
            status={quote["status"]}
            saveQuote={(quoteDetails) =>
              this.props.updateQuote({
                quote: quoteDetails,
                quote_id: quoteId,
                quote_type: quote["quote_type"],
                total_cost: quoteDetails.totalCost,
                total_quantity: quoteDetails["total_quantity"],
                status: quote["status"],
                design_name: this.props.design.name,
                collection_id: this.props.design.collection_id,
              })
            }
            saveAndSubmitQuote={(quoteDetails) => {
              this.setState({ isRevising: false });
              this._onUpdateQuote(
                quote["status"] === QUOTE_STATUS_AS_STRING.DRAFT
              );
            }}
          />
        )}
      </div>
    );
  }

  render() {
    const { quote, quoteDetails, popOverOpen } = this.state;

    return (
      <div
        key={quote.qrf_id}
        className={classnames(
          "quote-dropdowns__quote-item dropdown card",
          quote.id &&
            `quote-dropdowns__quote-item--${quote.quote_type.toLowerCase()}`,
          {
            "dropdown--accepted": quote["status"] === "1",
            "dropdown--popover": popOverOpen,
          }
        )}
      >
        {this._renderDropdownHeader({
          quote,
          quoteDetails: quoteDetails,
          deleteQuote: this.props.deleteQuote,
        })}
        {/* {this.state.dropdown[quoteId] && ( */}
        {/* //TODO: , DEMO: */}
        {this._renderDropdownFooter({
          quote,
          quoteDetails: quoteDetails,
        })}
      </div>
    );
  }
}
