export const APPROVED = "Approved";
export const RESUBMIT = "Resubmission requested";
export const DESIGN_CANCELED = "Design canceled";

export const SAMPLE_STATUS_OPTIONS = [
  { value: APPROVED, label: "Approved" },
  { value: RESUBMIT, label: "Resubmit" },
  { value: DESIGN_CANCELED, label: "Cancel Design" }
]

export const PRODUCTION_SAMPLE_STATUSES = [
  { value: APPROVED, label: "Approved" },
  { value: RESUBMIT, label: "Resubmit" }
]

export const SAMPLE_STATUS_PLACEHOLDERS = {
  [APPROVED]: 'Please enter your comments here.',
  [RESUBMIT]: 'Please share what would you like to be improved.',
  [DESIGN_CANCELED]: 'Please share the reason for cancellation.',
}
