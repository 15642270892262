import { createAction } from "redux-actions";
import axios from "axios";
import keys from "lodash/keys";
import { get_service_endpoint } from "../../ServiceEndpoints";
// import { DESIGN_STATUS } from "./../../constants/Design";
import { closeModal } from "./GlobalActions";
import { flash } from "./CommonActions";

const ep = get_service_endpoint("designs");
const eqNew = get_service_endpoint("notificationView");
const apiUserEndpoint = get_service_endpoint("auth");

// const GET_FACTORY_DESIGNS = createAction("GET_FACTORY_DESIGNS");
const GET_DESIGNS_SUCCESS = createAction("GET_DESIGNS_SUCCESS");
const GET_FACTORY_BRANDS_NEW = createAction("GET_FACTORY_BRANDS_NEW");
const GET_ALL_FACTORIES_ACCOUNT = createAction("GET_ALL_FACTORIES_ACCOUNT");
const GET_ALL_FACTORIES_FOR_QUOTES = createAction("GET_ALL_FACTORIES_FOR_QUOTES");
const GET_SELECTED_USER_ADDRESSES = createAction("GET_SELECTED_USER_ADDRESSES");
const GET_ALL_CITIES = createAction("GET_ALL_CITIES");

const SET_TRANSFORMED_FACTORIES = createAction("SET_TRANSFORMED_FACTORIES");

export const factoryNewGetAllBrands = () => dispatch => {
  return axios
    .get(`${get_service_endpoint("notificationView")}/users/getallbrands/`)
    .then(res => {

      dispatch(GET_FACTORY_BRANDS_NEW(res.data));
    });
};

export const factoryGetDetails = ({
  brand_name,
  collection_id,
  design_id
}) => dispatch => {
  //13.233.53.126:8282/getalldesign?brandName=<brand-name>&collection_id=<valid collection id>&id=<valid design id>
  return axios
    .get(`${ep}/getalldesign`, {
      params: {
        brandName: brand_name,
        collection_id: collection_id,
        id: design_id
      }
    })
    .then(res => {
      // console.log(res.data);
      dispatch(GET_DESIGNS_SUCCESS({ designs: res.data }));
      // dispatch(GET_FACTORY_DESIGNS(res.data));
    });
};

export const getAllFactoriesAccount = params => dispatch => {
  return axios
    .get(`${eqNew}/factory`, {
      params
    })
    .then(res => {
      dispatch(GET_ALL_FACTORIES_ACCOUNT({ data: res.data }));
    });
};

export const getAllFactoriesForQuotes = params => dispatch => {
  return axios
    .get(`${eqNew}/quotes/request/factories`, {
      params
    })
    .then(res => {
      dispatch(GET_ALL_FACTORIES_FOR_QUOTES({ data: res.data }));
    });
};

// To fetch assigned factories for production merchant
export const getAssignedFactories = params => dispatch => {
  return axios
    .get(`${eqNew}/users/factories`, {
      params
    })
    .then(res => {
      let isFiltered = false;
      if(keys(params).length > 0){
        isFiltered = true;
      }
      dispatch(GET_ALL_FACTORIES_ACCOUNT({ data: res.data, isFiltered }));
      dispatch(SET_TRANSFORMED_FACTORIES({ data: res.data, isFiltered }));
    });
};

export const createNewFactoryAccount = (params, attachments) => dispatch => {
  return axios.post(`${eqNew}/factory`, params).then(res => {
    if(attachments.length > 0){
      const formData = new FormData();
      formData.append('factoryId', res.data.id);
      const files = []
      attachments.forEach(attachment => {
        if(attachment instanceof File){
          files.push(attachment)
        } else {
          formData.append('attachment',attachment)
        }
      })
      files.forEach(file=>formData.append('attachment', file))
      axios.post(`${eqNew}/factory/attachments`, formData).then(data=>{
        dispatch(getAllFactoriesAccount({}));
        window.history.back();
        dispatch(
          flash({
            message: "Factory added successfully.",
            type: "action",
            actionType: "Create"
          })
        );
      })
    } else {
      dispatch(getAllFactoriesAccount({}));
        window.history.back();
        dispatch(
          flash({
            message: "Factory added successfully.",
            type: "action",
            actionType: "Create"
          })
        );
    }
  });
};

export const setFactoriesData = data => dispatch => {
  dispatch(GET_ALL_FACTORIES_ACCOUNT({ data: data }));
}

export const updateFactoryAccount = (details, attachments) => dispatch => {
  if (details.status) {
    details.status = 'Y';
  } else {
    details.status = 'N';
  }
  return axios.patch(`${eqNew}/factory`, details).then(res => {
    if(attachments.length > 0){
      const formData = new FormData();
      formData.append('factoryId', res.data.id);
      const files = []
      attachments.forEach(attachment => {
        if(attachment instanceof File){
          files.push(attachment)
        } else {
          formData.append('attachment',attachment)
        }
      })
      files.forEach(file=>formData.append('attachment', file))
      axios.patch(`${eqNew}/factory/attachments`, formData).then(data=>{
        dispatch(getAllFactoriesAccount({}));
        window.history.back();
        dispatch(
          flash({
            message: "Factory updated successfully.",
            type: "action",
            actionType: "Update"
          })
        );
      })
    } else {
      dispatch(getAllFactoriesAccount({}));
        window.history.back();
        dispatch(
          flash({
            message: "Factory updated successfully.",
            type: "action",
            actionType: "Update"
          })
        );
    }
  });
}

export const createNewUserAccount = params => dispatch => {
  return axios.post(`${eqNew}/factory/user`, params).then(res => {
    dispatch(getAllFactoriesAccount({}));
    dispatch(
      flash({
        message: "User added successfully.",
        type: "action",
        actionType: "Create"
      })
    );
    dispatch(closeModal());
  });
};

export const createMerchantAssociations = params => dispatch => {
  return axios.post(`${eqNew}/factory/merchants`, params).then(res => {
    dispatch(getAllFactoriesAccount({}));
    dispatch(
      flash({
        message: "Merchant added successfully",
        type: "action",
        actionType: "Create"
      })
    );
    dispatch(closeModal());
  });
}

export const updateMerchantAssociations = params => dispatch => {
  return axios.patch(`${eqNew}/factory/merchants`, params).then(res => {
    dispatch(getAllFactoriesAccount({}));
    dispatch(
      flash({
        message: "Merchant updated successfully",
        type: "action",
        actionType: "Update"
      })
    );
    dispatch(closeModal());
  });
}

export const updateUserAccount = details => dispatch => {
  return axios
    .patch(`${eqNew}/factory/user`, {
      ...details,
      isEnabled: '' + (+details.isEnabled)
    }).then(res => {
      dispatch(getAllFactoriesAccount({}));
      dispatch(
        flash({
          message: "User updated successfully.",
          type: "action",
          actionType: "Update"
        })
      );
      dispatch(closeModal());
    });
}

export const getSelectedUserAddresses = details => dispatch => {
  return axios
    .get(`${eqNew}/factory/useraddress`, {
      params: {
        userid: details.userId
      }
    })
    .then(res => {

      dispatch(GET_SELECTED_USER_ADDRESSES({ data: res.data }));
      return res.data;
    });
};

export const deleteUser = email => dispatch => {
  return axios
    .delete(`${apiUserEndpoint}/deleteuser`, { data : {email : email} })
    .then(res => {

      // dispatch(FACTORY_USER_DELETED({ data: res.data }));
      return res.data;
    });
};

export const getAllCities = () => dispatch => {
  return axios
    .get(`${eqNew}/factory/cities`).then(res => {
      dispatch(GET_ALL_CITIES(res.data));
      return res.data;

    })
}

export const getAllCollectionWithFactories = brandId => {
  return axios
    .get(`${eqNew}/factory/collection?brandid=${brandId}`).then(res => {
      return res.data;

    })
}


export const uploadImageForTnaAttribute = payload => {
  return axios
    .get(`${eqNew}/tna/image`).then(res => {
      return res.data;

    })
}
