import React from 'react';

const DashedBorder = ({
  borderRadius = 4,  // number; without units
  borderColor = '#D4D4D4',
  borderWidth = 2,  // number; without units
  borderDashArray = '6, 6',
  useLabel = false,
  children,
  style,
  ...props
}) => {
  const backgroundImage = `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='${borderRadius}' ry='${borderRadius}' stroke='${encodeURIComponent(borderColor)}' stroke-width='${borderWidth}' stroke-dasharray='${encodeURIComponent(borderDashArray)}' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`;

  if (useLabel) {
    return <label
      {...props}
      style={{
        ...style,
        backgroundImage,
        borderRadius: `${borderRadius}px`,
        ...style,
      }}
    >{children}</label>;
  }

  return <div
    {...props}
    style={{
      ...style,
      backgroundImage,
      borderRadius: `${borderRadius}px`,
    }}
  >{children}</div>;
};

export default DashedBorder;
