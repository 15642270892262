import React, { useState } from "react";
import PropTypes from "prop-types";

import Button from "./../Button";
import "./styles.scss";

const DropdownButton = ({
  category,
  children,
  titleContent,
  list,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="dropdown-container">
      <Button category={category} {...props} onClick={() => setIsOpen(!isOpen)}>
        {titleContent}
      </Button>
      {isOpen && (
        <div className="items">
          {list.map(({ title, clickHandler }) => (
            <div
              onClick={() => {
                setIsOpen(false)
                clickHandler();
              }}
              className="list-item"
            >
              {title}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

DropdownButton.propTypes = {
  children: PropTypes.any,
  category: PropTypes.string,
  titleContent: PropTypes.any,
  list: PropTypes.array
};

export default DropdownButton;
