import React, { Component } from "react";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { getSmallImageUrl, getCoverImagePlaceholder } from "../../../helpers/DesignHelpers";
import sum from "lodash/sum";
import "./style.scss";

class SizesQuoteModal extends Component {
  render() {
    const {
      design: {
        name: deisgnName,
        category,
        files,
        cover_fileloc,
      },
      sizes_quantity,
    } = this.props;

    const coverFile = files && Object.values(files).find(file => file.is_cover);
    const coverFileLoc = cover_fileloc || (coverFile && coverFile.fileloc)
    const validImgUrl = (coverFileLoc && getSmallImageUrl(coverFileLoc));
    const imgLoc = validImgUrl || getCoverImagePlaceholder({ category }).src;
    const totalQuantity = sum(sizes_quantity.map(item => item.quantity));

    return (
      <div className="flex-col new-quote-form size-quote-modal">
        <div className="design">
          <div className={`design__pic ${validImgUrl ? '' : 'dummy'}`}>
            <img src={imgLoc} alt="Design Cover" />
          </div>
          <div className="design__details">
            <div className="design-name">{deisgnName}</div>
            <div className="design-info">
              <div className="label">Total quantity</div>
              <div className="info">{totalQuantity} pcs</div>
            </div>
          </div>
        </div>
        <div className="sizes-container">
          <div className="sizes-container__header">
            <div>Sizes</div>
            <div>Quantity</div>
          </div>
          <PerfectScrollbar className="sizes-container__list">
            {
              sizes_quantity.map(sizeQty =>
                <div className="sizes-container__item" key={sizeQty.size}>
                  <div>{sizeQty.size}</div>
                  <div>{sizeQty.quantity} pcs</div>
                </div>
              )
            }
          </PerfectScrollbar>
        </div>
      </div>
    );
  }
}

export default SizesQuoteModal;
