import { get_service_endpoint } from "ServiceEndpoints";
import axios from "axios";
import findIndex from "lodash/findIndex";
import remove from "lodash/remove";
import { convertDate } from "helpers/convertDate";
import { TNA_ATTRIBUTES_ORDER } from "constants/Tna";

/**
 *
 * @param { function } openModal
 * @param { function } closeModal
 * @param { string } label denotes the stage tna subcategory
 * @param { object } tnaData
 * @param { array } attributes denotes teh flatten tna attributes
 * @param { object } nextStage denotes the next stage attribute object
 * @param { function } afterSubmit denotes teh function to run after submission - in this case fetches products
 *
 */
export const openMarkCompleteModal = (
  openModal,
  closeModal,
  label,
  tnaData,
  attributes,
  nextStage,
  afterSubmit,
  dateLimit
) => {
  openModal("MARK_TNA_ACTION_COMPLETE", {
    handleSubmit: date => {
      const { id, designId, status } = tnaData;

      const nextStageIndex = findIndex(attributes, { id: nextStage.id });
      const updatedAttributes = [...attributes];
      updatedAttributes[nextStageIndex] = {
        ...nextStage,
        attributeValue: convertDate(date)
      };


      remove(updatedAttributes, {
        categoryOrder: TNA_ATTRIBUTES_ORDER.SAMPLE_APPROVAL.categoryOrder,
        attributeOrder: TNA_ATTRIBUTES_ORDER.SAMPLE_APPROVAL.attributeOrder
      });

      const patchRequest = {
        id,
        designId,
        status,
        attributes: updatedAttributes
      };

      axios
        .patch(`${get_service_endpoint("notificationView")}/tna`, patchRequest)
        .then(res => {
          afterSubmit(updatedAttributes);
        })
        .catch(err => {
          console.log(err);
          // alert("Oops! Something went wrong.");
        });
    },
    onUploadFile: files =>
      uploadFile(nextStage.tnaId, nextStage.id, files, tnaData.designId),
    closeModal: closeModal,
    title: label,
    dateLimit
  });
};

export const uploadFile = (tnaId, attributeId, files, designId) => {
  let formData = new FormData();
  formData.append("id", attributeId);
  formData.append("tnaId", tnaId);
  formData.append("designId", designId);
  files.forEach(file => {
    formData.append("file", file);
  });

  return axios
    .post(`${get_service_endpoint("notificationView")}/tna/image`, formData)
    .then(res => {
      return res.data;
    });
};
