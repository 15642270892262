import React from 'react';
import moment from 'moment';
import find from 'lodash/find';
import { TNA_STATUS } from 'constants/Tna';
import TnaHeader from '../BrandTna/TnaHeader';
import TnaPlan from '../TnaPlan';
import { connect } from 'react-redux';
import { getDesignTnaTemplate } from 'store/actions/TnaActions';
import { DATESTAMP_FORMAT } from 'constants/Date';
import './style.scss';

class FactoryTna extends React.Component {
  state = {
    tnaHeaderData: {
      factory: "",
      orderQuantity: "",
      poQuantity: "",
      poNumber: [],
    },
    productionSample: {},
  };

  constructor(props) {
    super(props);

    props.getDesignTnaTemplate({
      designId: props.designId,
      brandId: props.brandId,
    });
  }

  static getDerivedStateFromProps(nextProps, oldState) {
    if (nextProps.designTna && nextProps.designTna.designId && !oldState.tnaHeaderData.factory) {
      const {
        orderQuantity,
        poNumber,
        assocQuantity,
        skipApproval,
        status,
        costClosureDate,
        brandName,
      } = nextProps.designTna;

      const { sampleDetails } = nextProps;

      const productionSample =
        find(sampleDetails.samples, { isProductionSample: 1 }) || {};

      const tnaHeaderData = {
        brandName: brandName || '--',
        factory: productionSample.factoryName,
        orderQuantity: orderQuantity ? `${orderQuantity} pcs` : "NA",
        poQuantity: assocQuantity ? `${assocQuantity} pcs` : "NA",
        poNumber: poNumber || "NA",
        isSampleApprovalRequired: !skipApproval ? 'Yes' : 'No',
        costClosureDate: (costClosureDate && moment(costClosureDate).format(DATESTAMP_FORMAT)) || 'NA',
      };

      return {
        tnaHeaderData,
        productionSample,
        tnaStatus: status,
      };
    }

    return null;
  }

  render() {
    const {
      tnaHeaderData,
      // tnaGraphData,
      // isRequestComplete,
      // showCta,
      // skipSampleApproval,
      tnaStatus,
      productionSample
    } = this.state;
    const {
      designId,
      collection,
      design,
      brandId,
      getFactoryTnaTemplate,
      templateDetails,
      notificationList,
      getBrandTnaTemplate,
      openModal,
      closeModal,
      sampleDetails,
      quoteDetails,
    } = this.props;

    return (
      <div
        className={`custom-scroll-container factory-tna ${tnaStatus === TNA_STATUS.APPROVED ?
          "blue_container" : ''}`}
      >
        <TnaHeader
          data={tnaHeaderData}
          tnaStatus={tnaStatus}
          isFactory={true}
          sampleType={productionSample.sampleType}
          sampleSize={productionSample.sampleSize}
        />
        <TnaPlan
          designId={designId}
          collection={collection}
          design={design}
          brandId={brandId}
          getFactoryTnaTemplate={getFactoryTnaTemplate}
          templateDetails={templateDetails}
          notificationList={notificationList}
          getBrandTnaTemplate={getBrandTnaTemplate}
          quoteDetails={quoteDetails}
          openModal={openModal}
          closeModal={closeModal}
          sampleDetails={sampleDetails}
          isFactory={true}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  designTna: state.tna.designTnaTemplates[props.designId],
});

const mapDispatchToProps = (dispatch) => ({
  getDesignTnaTemplate: (arg) => dispatch(getDesignTnaTemplate(arg)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FactoryTna);
