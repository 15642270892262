import React, { Component } from "react";
import PropTypes from "prop-types";
import "./PopOver.scss";

class PopOver extends Component {
  state = {
    visible: this.props.visible
  };

  toggleVisibility = visibility =>
    this.setState({
      visible: this.props.visible
    });

  componentWillReceiveProps(nextProps) {
    if (nextProps.visible !== this.state.visible) {
      this.setState({ visible: nextProps.visible });
    }
  }  

  render() {
    const { props, state } = this;
    const popUpContainer_style =
      "pop-over__popup-container" +
      (!state.visible ? " pop-over__popup-container--hide" : "");

    return (
      <div
        className={`pop-over__popup ${props.className}`}
      >
        <div className={popUpContainer_style}>{props.content}</div>
        <div
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            this.toggleVisibility(true);
          }}
          onBlur={e=>{
            e.stopPropagation();
            e.preventDefault();
            this.toggleVisibility(false);
          }}
        >
          {props.children}
        </div>
      </div>
    );
  }
}

PopOver.propTypes = {
  children: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired
};

export default PopOver;
