import React, { useEffect } from 'react';
import classnames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { getCoverImage } from '../../../../helpers/DesignHelpers';
import './style.scss';

const DesignCard = ({
  design: {
    id,
    name,
    category,
    design_id,
    files = {},
    username
  }
}) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const coverImage = Object.values(files).find(file => file.is_cover);
  const coverImageUrl = coverImage && coverImage.fileloc;
  const isPlaceholder = !coverImageUrl;
  const url = getCoverImage({ category, coverPic: coverImageUrl });

  return <div className="ui-design-card">
    <div
      className={classnames("ui-design-card__img", {
        'ui-design-card__img--placeholder': isPlaceholder
      })}
    >
      <img src={url} alt={`Design ${name}`} />
    </div>
    <div className="ui-design-card__info">
      <div className="ui-design-card__source-id overflow-ellipsis-lines-2"
        data-tip={design_id}
        data-class="dark-tooltip over-modal">
        ID: {design_id}
      </div>
      <div className="ui-design-card__name overflow-ellipsis-lines-2"
        data-tip={name}
        data-class="dark-tooltip over-modal">
        {name}
      </div>
      <div className="ui-design-card__creator overflow-ellipsis-lines-2"
        data-tip={username}
        data-class="dark-tooltip over-modal">
        <div>Created by</div>
        <div>{username}</div>
      </div>
    </div>
  </div>
};

export default DesignCard;
