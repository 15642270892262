import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { getCoverImage } from '../../../../helpers/DesignHelpers';
import { formatNumber, formatDecimals } from '../../../../helpers/i18nHelper';
import OrderPlacedImg from '../../../../assets/img/Orders/OrderPlacedStamp.svg';
import './style.scss';

export default class OrderSuccess extends React.Component {
  static propTypes = {
    factory: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    address: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    currency: PropTypes.string.isRequired,
    totalQuantity: PropTypes.number.isRequired,
    poValue: PropTypes.number.isRequired,
  };

  _formatAddress = (strAddress) => {
    return strAddress.replace(/(\$\s*)+/g, ', ');
  }

  _renderOrderInfo() {
    const { currency, factory, totalQuantity, poValue, address } = this.props;

    return <div className="create-order-success__info">
      <div className="create-order-success__info__title">Order created</div>
      <div className="create-order-success__info__subtitle">
        Order details shared with {factory.name}
      </div>
      <div className="create-order-success__info__grid">
        <div className="create-order-success__info__grid-row">
          <div className="create-order-success__info__grid-label">
            Total quantity
          </div>
          <div className="create-order-success__info__grid-value">
            {formatNumber(totalQuantity)} pcs
          </div>
        </div>
        <div className="create-order-success__info__grid-row">
          <div className="create-order-success__info__grid-label">
            Amount payable
          </div>
          <div className="create-order-success__info__grid-value">
            {currency} {formatDecimals(poValue)}
          </div>
        </div>
      </div>

      <div className="create-order-success__info__title-2">Delivery address</div>
      <div className="create-order-success__info__address">
        {this._formatAddress(address.address)}
      </div>

      <div className="create-order-success__info__logo">
        <img src={OrderPlacedImg} alt=""/>
      </div>
    </div>;
  }

  _renderDesigns() {
    const { currency, designs } = this.props;

    return <div className="create-order-success__designs-table">
      <div className="create-order-success__designs-table__header">
        <div className="col-head">DESIGN</div>
        <div className="col-head t-align-right">PRICE</div>
        <div className="col-head t-align-right">QUANTITY</div>
      </div>
      {designs.map(design => <div
        className="create-order-success__designs-table__row"
      >
        <div className="design-info">
          <div className="design-cover">
            <img src={getCoverImage(design)} alt="" />
          </div>
          <div className="design-name overflow-ellipsis">{design.name}</div>
        </div>
        <div className="design-price t-align-right">{currency} {formatDecimals(design.price)}</div>
        <div className="design-quantity t-align-right">{formatNumber(design.quantity)} Pcs.</div>
      </div>)}
    </div>;
  }

  _renderCta() {
    return <div className="create-order-success__cta-container">
      <Link to="../" className="btn btn--black-ghost">View All Orders</Link>
    </div>;
  }


  render() {
    return <div className="create-order-success">
      <div className="create-order-success__container">
        {this._renderOrderInfo()}
        {this._renderDesigns()}
        {this._renderCta()}
      </div>
    </div>
  }
}
