import React, { Component } from "react";

import emptyIcon from "assets/img/Quote/ic-quote-empty.svg";
import { ReactComponent as AlertTriangleIcon } from "assets/images/communication/alert-triangle.svg";
import { IconArrowLong } from "../../../../UI/Icons";
import "./index.scss";

const QuoteCardFactory = ({
  design,
  targetPrice,
  oldSubmitPrice,
  newSubmitPrice,
  noteText,
  currency,
}) => {
  const quotePriceStatus = targetPrice === newSubmitPrice
    ? 'matched'
    : targetPrice > newSubmitPrice
      ? 'lower'
      : 'higher';

  return (
    <div className={`quote-card quote-card--factory quote-card--price-${quotePriceStatus}`}>
      <div className="card-horizontal">
        <img
          src={design.coverImage.src}
          alt=""
          className="cover-image"
          style={
            design.coverImage.placeholder
              ? { objectFit: "none" }
              : { objectFit: "cover" }
          }
        />
        <div className="quote-card__summary">
          <div className="quote-card__empty-icon">
            <img src={emptyIcon} alt="" />
          </div>
          <div className="quote-card__submit-price-details">
            {
              oldSubmitPrice > 0 && <>
                <div className="quote-card__submit-price quote-card__submit-price--old">
                  {currency} {oldSubmitPrice.toFixed(2)}
                </div>
                <IconArrowLong direction="right" />
              </>
            }
            <div className="quote-card__submit-price quote-card__submit-price--new">
              {currency} {newSubmitPrice.toFixed(2)}
            </div>
          </div>
          <div className="quote-card__target-price">
            Target price: {currency} {targetPrice.toFixed(2)}
          </div>
        </div>
      </div>
      {noteText && <div className="footer-note">
        <AlertTriangleIcon className="footer-note__img" />
        <span className="footer-note__text">
          {noteText}
        </span>
      </div>}
    </div>
  );
};

export default QuoteCardFactory;
