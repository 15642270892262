import React, { useState, useEffect } from "react";

import _ from "lodash";
import isEmpty from "lodash/isEmpty";

import HeaderWithFilters from "./../SamplingTracker/SampleTrackerHeaderWithFilters";
import DesignsWithScroll from "./DesignsWithScroll";
import BottomBar from "./BottomBar";
import Pagination from "./Pagination";
import { FilterDrawerButton } from "./../UI/Filter";
import NavHeaders from "components/NavHeaders";
import EmptyFilterResult from "../UI/EmptyFilterResult";
import EmptyScreen from "../UI/EmptyScreen";

import styles from "./Product.module.scss";

import { get_service_endpoint } from "../../ServiceEndpoints";
import { getCurrentUserRole } from "../../helpers/AuthHelper";
import { LOCO_SUPER, PRODUCTION_MERCHANT } from "../../constants/Auth";
import axios from "axios";
import avgAttemptsIcon from "assets/images/sampling/avg-attempts.svg";
import avgDaysIcon from "assets/images/sampling/avg-days.svg";
import ImgSampling from "assets/images/emptyPages/ic_empty_sampling.svg";

import {
  canCreateOrder,
  checkBrand,
  checkFactory
} from "./../../helpers/AuthHelper";

import "./styles.scss";

const eq = get_service_endpoint("notificationView");
const apibrand = get_service_endpoint("collection");
const initialPaginationObj = {
  limit: 20,
  isnext: 0,
  init: 1,
  offset: null
}

const Product = ({
  designs: { products = [], paginate },
  openModal,
  getAllDesigns,
  allBrands,
  prevSavedDesigns,
  isFactory,
  saveSelecteDesigns,
  collectionForBrand,
  currentUser,
  tab,
  stage,
  brandId,
}) => {
  const [savedDesigns, setSavedDesigns] = useState([]);
  const [selectedDesigns, setSelectedDesigns] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const [collections, setCollections] = useState([]);
  const [selectedBrandId, setSelectedBrandId] = useState(null);
  const [selectedFactoryId, setSelectedFactoryId] = useState(null);
  const [stats, setStats] = useState({});
  const [factories, setFactories] = useState([]);
  const [appliedFiltersCount, setAppliedFiltersCount] = useState(0);
  const [paginationObj, setPaginationObj] = useState(initialPaginationObj);

  // === this checks the products change ===
  const isSuperAdmin = getCurrentUserRole() == LOCO_SUPER;
  const isUserFromBrand = checkBrand();
  const isFactoryManagerAdmin = checkFactory();
  const isProductionMerchant = getCurrentUserRole() === PRODUCTION_MERCHANT;

  const [filtersValue, setFiltersValue] = useState(() => {
    if (isUserFromBrand && !isProductionMerchant) return { brandid: brandId };
    if (isFactoryManagerAdmin) return { factory: brandId };
    return {};
  });

  useEffect(() => {
    setSavedDesigns([...products]);
    if (prevSavedDesigns.length === 1) {
      setCurrentIndex(0);
      setSelectedDesigns([]);
    }
  }, [products, prevSavedDesigns]);

  useEffect(() => {
    if(isSuperAdmin){
      axios
        .get(`${eq}/productstats`, {
          params: { ...filtersValue }
        })
        .then(res => {
          setStats(res.data);
        })
      .catch(err => console.log(err));
    }
  }, [selectedBrandId]);

  useEffect(() => {
    if (!isFactoryManagerAdmin) {
      axios
        .get(`${eq}/orders/sampletracker/factories`, {
          params: {
            tab,
            stage: stage == "in-progress" ? "progress" : stage
          }
        })
        .then(res => {
          setFactories(Object.keys(res.data).map(key => res.data[key]));
        });
    }
  }, [selectedBrandId]);

  // === This effect is for getting collections on brand change ===
  useEffect(() => {
    if (selectedBrandId && isFactory) {
      axios
        .get(`${eq}/orders/collections`, {
          params: { brandid: selectedBrandId }
        })
        .then(res => {
          setCollections(Object.keys(res.data).map(key => res.data[key]));
        })
        .catch(err => console.log(err));
    }
  }, [selectedBrandId]);

  useEffect(() => {
    if (isSuperAdmin) {
      axios
        .get(`${eq}/orders/sampletracker/factories`, {
          params: {
            tab,
            stage: stage == "in-progress" ? "progress" : stage
          }
        })
        .then(res => {
          setFactories(Object.keys(res.data).map(key => res.data[key]));
        });
    }
  }, [stage]);

  useEffect(() => {
    if (!isFactory) {
      setCollections(
        Object.keys(collectionForBrand).map(key => collectionForBrand[key])
      );
    }
  }, [collectionForBrand]);

  // === This effect gets the colection when brand is logged in ====
  useEffect(() => {
    if (isFactory) {
      axios
        .get(`${apibrand}/collection`)
        .then(res => {
          // setCollections(Object.keys(res.data).map(key => res.data[key]));
        })
        .catch(err => console.log(err));
    }
  }, []);

  // === this function handles the select design process through checboxs
  const getSeletedDesign = design => {
    if (checkBrand && !selectedFactoryId) {
      return;
    } else if (isSuperAdmin && !selectedBrandId) return;

    const updatedDesigns = savedDesigns.filter((dezine, index) => {
      if (dezine.designId === design.designId) {
        let selectedValue = !dezine.isSelected;
        dezine.isSelected = selectedValue;

        // this changes the selection from previous designs of pages
        prevSavedDesigns[currentIndex][index]["isSelected"] = selectedValue;

        return { ...dezine };
      }
      return dezine;
    });

    if (_.includes(selectedDesigns, design)) {
      _.remove(selectedDesigns, selectedDesign => selectedDesign === design);
      setSelectedDesigns(selectedDesigns);
    } else {
      setSelectedDesigns([...selectedDesigns, design]);
    }

    setSavedDesigns(updatedDesigns);
  };

  // === Opens the create order model ===
  const openCreateOrderModelHandler = () => {
    // === this saves the selected designs in redux ===
    // if(!isCreateOrderBefore) {
    //   saveSelecteDesigns(selectedDesigns);
    //   setIsCreateOrderBefore(true);
    // }

    saveSelecteDesigns(selectedDesigns);

    // === this opens the create order model & redux provides it selected products ===
    openModal("PRODUCTS_CREATE_ORDER", { brandid: selectedBrandId, isFactory });
  };

  // === Handles the filter process ===
  const getFilteredDesigns = (value, rest) => {
    const transformedFilters = Object.values(value.filters).reduce(
      (acc, filter) => {
        if (Array.isArray(filter.values)) {
          acc[filter.name] = filter.values[0];
        } else {
          acc[filter.name] = filter.values;
        }
        return acc;
      },
      {}
    );

    getAllDesigns({ ...transformedFilters, ...initialPaginationObj });

    if (transformedFilters.brandid) {
      setSelectedBrandId(transformedFilters.brandid);
    }
    if (transformedFilters.factory) {
      setSelectedFactoryId(transformedFilters.factory);
    }

    // === this is for calling getDesign in pagination along selected filter value ===
    setFiltersValue(transformedFilters);
    setCurrentIndex(0);
    setPaginationObj(initialPaginationObj)
    setAppliedFiltersCount(value.filtersCount);
  };

  // === clears the selected designs with checkboxes
  const clearSelectedFilters = () => {
    const updatedDesigns = savedDesigns.map(design => {
      design.isSelected = 0;
      return { ...design };
    });

    prevSavedDesigns.forEach(design => (design.isSelected = 0));

    setSelectedDesigns([]);
    setSavedDesigns(updatedDesigns);
  };

  const paginationHandler = ({ offset, isnext }) => {
    if (isnext) {
      // check length of saved designs
      const savedDesignsLength = prevSavedDesigns.length - 1;

      if (savedDesignsLength > currentIndex) {
        // then alter the designs

        setSavedDesigns(prevSavedDesigns[currentIndex + 1]);
        setCurrentIndex(currentIndex + 1);
      } else {
        setPaginationObj({ ...paginationObj, offset, isnext });
        getAllDesigns({ ...filtersValue, ...paginationObj, offset, isnext, init: 1 });

        setCurrentIndex(currentIndex + 1);
      }
      return;
    }

    if (!isnext) {
      setSavedDesigns(prevSavedDesigns[currentIndex - 1]);
      setCurrentIndex(currentIndex - 1);
      setPaginationObj({ ...paginationObj, offset, isnext });
      return;
    }
  };

  const filters = [];
  if (!isUserFromBrand && allBrands.length > 1) {
    filters.push({
      title: "CUSTOMER",
      key: "brandid",
      items: allBrands.map(({ id, name }) => ({
        key: id,
        label: name,
        value: id,
        isSelected: (filtersValue && filtersValue["brandid"] === id) || false,
      })),
      isSingleSelect: true
    });
  }

  if (!isFactoryManagerAdmin) {
    filters.push({
      title: "FACTORY",
      key: "factory",
      items: factories.map(({ id, name }) => ({
        key: id,
        label: name,
        value: id,
        isSelected: (filtersValue && filtersValue["factory"] === id) || false,
      })),
      isSingleSelect: true
    });
  }

  filters.push({
    title: "Sample type",
    key: "st",
    items: sampleTypeOptions.map(({ label, value }, index) => ({
      key: index,
      label,
      value,
      isSelected: (filtersValue && filtersValue["st"] === value) || false,
    })),
    isSingleSelect: true
  });

  return (
    <div className="wrapper__outer custom-scroll-sample">
      <HeaderWithFilters
        allBrands={allBrands}
        onChange={getFilteredDesigns}
        isFactory={isFactory}
        currentUser={currentUser}
        totalItems={paginate}
        render={() => (
          <div className="flex">
            <Pagination
              onclick={paginationHandler}
              page={paginate}
              currentIndex={currentIndex}
              option={{ suppressScrollX: true }}
              showTotalRecords={false}
            />
            <FilterDrawerButton
              filters={[...filters]}
              onApplyFilters={appliedFilters => {
                getFilteredDesigns(appliedFilters)
              }}
            />
          </div>
        )}
        isSuperAdmin={isSuperAdmin}
        factories={factories}
        canCreateOrder={canCreateOrder()}
      />

      {savedDesigns && savedDesigns.length ? (
        <>
          {isSuperAdmin && (
            <div
              style={{
                margin: "10px 32px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "8px"
              }}
            >
              <div className={styles.metrics}>
                <div className="average-entities orange">
                  <img src={avgDaysIcon} alt="Graph Icon" />
                  <div>
                    <p style={{ color: "var(--fill-grey-medium)" }}>
                      Avg. Sampling Time{" "}
                    </p>
                    <p>
                      <b>
                        {stats.avgDays ? `${stats.avgDays} days` : "Loading.."}
                      </b>
                    </p>
                  </div>
                </div>
                <div className="average-entities purple">
                  <img src={avgAttemptsIcon} alt="Attempts Icon" />
                  <div>
                    <p style={{ color: "var(--fill-grey-medium)" }}>
                      Avg. Attempts{" "}
                    </p>
                    <p>
                      <b>
                        {stats.avgAttempts
                          ? `${stats.avgAttempts} attempts`
                          : "Loading.."}
                      </b>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          <NavHeaders userRole={getCurrentUserRole()} tab="sampling" />

          <DesignsWithScroll
            designs={savedDesigns}
            getSeletedDesign={getSeletedDesign}
            isFactory={isFactory}
            disableCheckbox={
              // If from brand, factory has to be chosen,
              // If lf admin, brand has to be selected
              isUserFromBrand
                ? (selectedFactoryId
                  ? false
                  : true)
                : isSuperAdmin
                  ? (selectedBrandId
                    ? false
                    : true)
                  : (selectedBrandId === null || selectedBrandId === "null")
            }
            canCreateOrder={canCreateOrder()}
          />
          {/* <div style={{ paddingBottom: "30px" }}></div> */}
          {selectedDesigns.length ? (
            <BottomBar
              selctedItems={selectedDesigns.length}
              createOrder={openCreateOrderModelHandler}
              clearSelectedFilters={clearSelectedFilters}
            />
          ) : null}
        </>
      ) : (
          appliedFiltersCount > 0
            ? <EmptyFilterResult
              onClearFilters={() => {
                getFilteredDesigns({
                  filters: {
                    factory: { values: [] },
                    brand: { values: [] },
                    st: { values: [] },
                  },
                  filtersCount: 0,
                });
              }}
            />
            : <EmptyScreen
              icon={ImgSampling}
              heading="No approved samples"
              description={<>
                Approved design samples will be<br />
                available here. You can place orders for<br />
                approved samples.
              </>}
            />
        )}
    </div>
  );
};

const sampleTypeOptions = [
  {
    label: "All",
    value: "null"
  },
  {
    label: "Pre-production",
    value: "Preproduction Sample"
  },
  {
    label: "Top of Bulk",
    value: "Top of Bulk (TOB) Sample"
  },
  {
    label: "Fit/Size",
    value: "Size-set Sample"
  },
  {
    label: "Prototype",
    value: "Prototype/Fit Sample"
  },
  {
    label: "Not Required",
    value: "Sample Not Required"
  }
];
export default Product;

// have to add bottom padding ....  after select designs
