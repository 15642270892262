import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";

import PerfectScrollbar from "react-perfect-scrollbar";
import DatePicker from "react-datepicker";

import RedButton from "../Product/RedButton";
import fileIcon from "../../assets/images/editor/ic-attach-file.svg";
import Filter from "../Product/Filter";
import AddressFilter from "./AddressFilter";

import cancelIcon from "../../assets/img/close-red.svg";

import styles from "../Product/Product.module.scss";
import actions from "../../store/actions";

import { get_service_endpoint } from "../../ServiceEndpoints";
import axios from "axios";

const eq = get_service_endpoint("notificationView");

const InputWithText = ({
  label,
  value,
  isDate = false,
  onChange,
  ID,
  type = "text",
  isError
}) => {
  const [selectedDate, setSelectedDate] = useState("");

  return (
    <div className={styles.input__with_text_wrapper}>
      <div className={styles.with__text_label}>{label}</div>

      {!isDate ? (
        <input
          type={type}
          className={styles.with__text_input}
          value={value}
          onChange={ev => {
            onChange(ID, ev.target.value);
          }}
          disabled={ID === "totalQuantity"}
        />
      ) : (
          <div style={{ marginLeft: "0px", width: "262px" }}>
            <DatePicker
              // withPortal
              popperPlacement="auto"
              popperClassName="custom-datepicker-popper"
              required={false}
              popperModifiers={{
                preventOverflow: {
                  enabled: true,
                  escapeWithReference: false
                }
              }}
              readOnly={false}
              minDate={""}
              maxDate={ID === "poDate" ? new Date() : ""}
              placeholderText="Select Date"
              dateFormat="MMMM dd, yyyy"
              className="custom-datepicker"
              selected={selectedDate ? selectedDate : moment(value).valueOf()}
              onChange={date => {
                console.log("ATE PPPP", date);
                setSelectedDate(date);
                onChange(ID, date);
              }}
              disabled={false}
            />
          </div>
        )}
    </div>
  );
};

const InputWithFileUpload = ({
  label,
  onChange,
  ID = "poFile",
  value,
  deletePOFile
}) => {
  const [fileName, setFileName] = useState("");
  return (
    <div className={styles.input__with_text_wrapper}>
      <div className={styles.with__text_label}>{label}</div>
      <div className={styles.with_input_type_file_wrapper}>
        <input
          type="file"
          className={styles.with__text_input_hidden}
          onChange={ev => {
            if (ev.target.files.length) {
              setFileName(ev.target.files[0]["name"]);
              onChange(ID, ev.target.files[0]);
            }
          }}
          style={{ display: fileName || value ? "none" : "" }}
        />
        {!value && !fileName ? (
          <div className={styles.visible__file_uploader_wrapper}>
            <div className={styles.visible__file_text}>
              {fileName ? fileName : "Attach file"}
            </div>
            <div className={styles.visible__file_icon}>
              {/* <i className="fa fa-paperclip f-sz-xxl"></i> */}
              <img src={fileIcon} alt="file" className="upload__ico" />
            </div>
          </div>
        ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px 0 10px 13px",
                width: "262px"
              }}
            >
              {fileName ? (
                <span>
                  {fileName.length > 20
                    ? `${fileName.slice(0, 10)}...${fileName.slice(
                      fileName.length - 10 - fileName.length
                    )}`
                    : fileName}
                </span>
              ) : (
                  <span>
                    {value.length > 20
                      ? `${value.slice(0, 10)}...${value.slice(
                        value.length - 10 - value.length
                      )}`
                      : value}
                  </span>
                )}
              <img
                src={cancelIcon}
                alt="change"
                style={{ color: "#ff0000", cursor: "pointer" }}
                onClick={() => {
                  setFileName("");
                  deletePOFile();
                }}
              />
            </div>
          )}
      </div>
    </div>
  );
};

const InputWithFilter = ({
  onChange,
  ID,
  addresses,
  label,
  title = "Address",
  value
}) => {
  if (ID === "shippingMode") {
    if (value == 10) {
      value = "Forwarder";
    } else {
      value = "By Hand";
    }
  }

  if (ID === "addressId") {
    addresses.forEach(address => {
      if (address.id == value) {
        value = address.city;
      }
    });
  }
  return (
    <div className={styles.input__with_text_wrapper}>
      <div className={styles.with__text_label}>{label}</div>
      <div className={styles.with_input_type_file_wrapper}>
        {false ? (
          <div>
            <div>{"initialValue"}</div>
            <img src={cancelIcon} alt="cancel" />
          </div>
        ) : (
            <div style={{ marginLeft: "0px" }}>
              <Filter
                title={title}
                initialSelectedItem={{
                  label: value,
                  value: "0"
                }}
                onChange={selection => {
                  onChange(ID, selection.value);
                }}
                filterOptions={addresses.map(address => ({
                  label: address.addressLine1,
                  value: address.id
                }))}
                width="265px"
                height={ID === "shippingMode" ? "90px" : "250px"}
              />
            </div>
          )}
      </div>
    </div>
  );
};

const InputWithAddressFilter = ({
  onChange,
  ID,
  addresses,
  label,
  title = "Address",
  value
}) => {
  const [selectedValue, setSelectedValue] = useState("");

  if (value && (!selectedValue || selectedValue.value === '0')) {
    addresses.forEach(address => {
      if (address.id === value) {
        setSelectedValue({
          label: address.addressLine1,
          value: address.id
        });
      }
    });
  } else {
    if (!selectedValue) {
      setSelectedValue({
        label: "Select one",
        value: "0"
      });
    }
  }

  return (
    <div className={styles.input__with_text_wrapper}>
      <div className={styles.with__text_label}>{label}</div>
      <div className={styles.with_input_type_file_wrapper}>
        {false ? (
          <div>
            <div>{"initialValue"}</div>
            <img src={cancelIcon} alt="cancel" />
          </div>
        ) : (
            <div style={{ marginLeft: "0px" }}>
              <AddressFilter
                title={title}
                initialSelectedItem={selectedValue}
                onChange={selection => {
                  onChange(ID, selection.value);
                }}
                filterOptions={addresses.map(address => ({
                  label: address.addressLine1,
                  value: address
                }))}
                selectedItem={selectedValue}
                width="265px"
                height={ID === "shippingMode" ? "100px" : "200px"}
              />
            </div>
          )}
      </div>
    </div>
  );
};

const EditPoModel = ({
  brandid,
  orderid,
  dispatch,
  userDetails,
}) => {
  const [poDetails, setPodetails] = useState({
    brandRepName: userDetails.isBrand ? userDetails.name : '',
  });
  const [addresses, setAddresses] = useState([]);
  const [poError, setPoError] = useState(false);

  useEffect(() => {
    axios
      .get(`${eq}/address`, { params: { brandid } })
      .then(res => {
        console.log("RES FORM ADDRESS api", res);
        setAddresses(res.data);
      })
      .catch(err => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .get(`${eq}/orders/purchaseorder`, { params: { orderid } })
      .then(res => {
        console.log("RES FORM ADDRESS api POPOPO", res.data);
        setPodetails(res.data);
      })
      .catch(err => {
        dispatch(
          actions.flash({
            message: "Error while fetching the PO Details."
          })
        );
        console.log("ERR".err);
      });
  }, []);

  // ===== This handles the validation plus $$$$$$ navigation to po and post create order api =====
  const toNextHandler = () => {
    if (true) {
      if (validatePoDetails()) {
        setPoError(true);
        setTimeout(() => {
          setPoError(false);
        }, 1000);
        return;
      }

      const data = new FormData();
      // data.append("products", JSON.stringify(withQuantityDesigns));
      data.append("poNumber", poDetails.poNumber);
      data.append("addressId", poDetails.addressId);
      data.append(
        "poDate",
        moment(poDetails.poDate).format("YYYY-MM-DD hh:mm:ss")
      );
      data.append(
        "dueDate",
        moment(poDetails.dueDate).format("YYYY-MM-DD hh:mm:ss")
      );
      data.append("paymentDueIn", poDetails.paymentDueIn);
      data.append("totalQuantity", poDetails.totalQuantity);
      data.append("totalAmount", poDetails.totalAmount);
      data.append("shippingMode", poDetails.shippingMode);
      data.append("brandRepName", poDetails.brandRepName);
      data.append("brandId", userDetails.isBrand ? userDetails.brandId : brandid);
      data.append("id", orderid);
      data.append("poFile", poDetails.poFile);

      axios
        .patch(`${eq}/orders/purchaseorder`, data)
        .then(res => {
          console.log("RES FORM CREATE ORDER ::: api", res);

          if (!res.data.error) {
            // navigate to orders
            dispatch(actions.closeModal());
            dispatch(actions.updateSingleOrder(res.data[0]));
            dispatch(
              actions.flash({ message: "PO Details updated successfully." })
            );
          } else {
            dispatch(actions.flash({ message: "Something went wrong!" }));
            return;
          }
        })
        .catch(err => console.log(err));
    }
  };

  // ==== this function validates the po data on submit ===
  const validatePoDetails = () => {
    const keys = [
      "poNumber",
      "addressId",
      "poDate",
      "dueDate",
      "paymentDueIn",
      "totalAmount",
      "poFile",
      "shippingMode",
      "brandRepName"
    ];
    let poDetailsError = false;
    keys.forEach(key => {
      if (!poDetails[key]) {
        poDetailsError = true;
      }
    });

    if (poDetailsError) return true;

    return false;
  };

  // ==== saves po details ====   ??????
  const savePODetailsHandler = (ID, value) => {
    if (ID === "poDate" || ID === "dueDate") {
      let convertedValue = moment(value).format("YYYY-MM-DD hh:mm:ss");
      value = convertedValue;
    }

    let updatedPoDetails = {
      ...poDetails,
      [ID]: value
    };
    setPodetails(updatedPoDetails);
  };

  const deletePOFileHandler = () => {
    poDetails.poFile = null;
    setPodetails({ ...poDetails });
  };

  return poDetails && addresses.length ? (
    <div className={styles.model}>
      <div className={styles.scroll__wrapper} style={{ height: "500px" }}>
        <PerfectScrollbar style={{ maxHeight: window.innerHeight }}>
          <div className={styles.po__wrapper}>
            <InputWithText
              value={poDetails.poNumber}
              onChange={savePODetailsHandler}
              ID="poNumber"
              label="PO Number"
            />
            {
              // brand shouldn't see the brand-rep name
              !userDetails.isBrand && <InputWithText
                value={poDetails.brandRepName}
                onChange={savePODetailsHandler}
                ID="brandRepName"
                label="Brand-rep name"
              />
            }
            <InputWithText
              value={poDetails.poDate}
              onChange={savePODetailsHandler}
              ID="poDate"
              label="PO Date"
              isDate
            />
            <InputWithText
              value={poDetails.dueDate}
              onChange={savePODetailsHandler}
              ID="dueDate"
              label="Deadline Date"
              isDate
            />
            <InputWithText
              value={poDetails.paymentDueIn}
              onChange={savePODetailsHandler}
              ID="paymentDueIn"
              label="Payment Due (in days)"
            //   type="number"
            />
            <InputWithText
              onChange={savePODetailsHandler}
              ID="totalQuantity"
              label="Total Quantity"
              value={poDetails.totalQuantity}
              disabled={true}
            />
            <InputWithAddressFilter
              onChange={savePODetailsHandler}
              ID="addressId"
              label="Shipping Address"
              addresses={addresses}
              value={poDetails.addressId}
            />
            {/* <InputWithText
                  onChange={savePODetailsHandler}
                  ID="location"
                  label="Location"
                /> */}
            <InputWithText
              onChange={savePODetailsHandler}
              ID="totalAmount"
              label="Total Amount"
              type="number"
              value={poDetails.totalAmount}
            />
            <InputWithFilter
              onChange={savePODetailsHandler}
              ID="shippingMode"
              label="Shipping Mode"
              addresses={[
                { addressLine1: "Forwarder", id: 10 },
                { addressLine1: "By Hand", id: 20 }
              ]}
              value={poDetails.shippingMode}
              title="Shipping Mode"
            />
            <InputWithFileUpload
              label="Upload PO"
              ID={"poFile"}
              onChange={savePODetailsHandler}
              deletePOFile={deletePOFileHandler}
              value={
                typeof poDetails.poFile === "string" ? poDetails.poFile : null
              }
            />
          </div>
        </PerfectScrollbar>
      </div>

      {poError ? (
        <div
          style={{
            fontSize: "10px",
            padding: "8px",
            backgroundColor: "#f8d7da",
            color: "#721c24",
            textAlign: "center",
            marginBottom: "-10px",
            position: "absolute",
            right: 0,
            bottom: "12%",
            width: "100%",
            opacity: poError ? 1 : 0,
            transition: "0.2s all"
          }}
        >
          All fields are mandatory
        </div>
      ) : null}

      <div className={styles.order__footer_wrapper}>
        <div className={styles.order__footer_details}>
          {/* <div className={styles.order__footer_details_label}>
            In this order
          </div>
          <div className="f-sz-l f-w-bold">{`${designs.length} Products`}</div> */}
        </div>

        <div className={styles.order__footer_btn_wrapper}>
          <RedButton label={"Update"} onClick={toNextHandler} />
        </div>
      </div>
    </div>
  ) : null;
};

const mapStateToProps = state => ({
  designs: state.products.selectedDesigns,
  userDetails: state.auth.userDetails,
});

export default connect(mapStateToProps)(EditPoModel);
