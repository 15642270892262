import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FilterLabel } from "./../../UI/Filter";
import Button from "./../../UI/Button/Button";
import PrivateContainer from "../../UI/PrivateContainer/PrivateContainer";
import Filters from "./Filters";
import { LOCO_SUPER, CLIENT_SUPER, CLIENT } from "../../../constants/Auth";
import "./FactoryHeader.scss";

class FactoryHeader extends Component {

  render() {
    const {
      brands,
      cities,
      applyFiltersHandler,
      toggleCity,
      selectAllCities,
      unSelectAllCities,
      onBrandSelect,
      isSuperAdmin
    } = this.props;

    return (
      <div className="factory-header">
        <div className="header-row">
          <div style={{ fontSize: "24px", fontWeight: 600, flex: 1 }}>
            Factories
          </div>
          <Filters
            cities={cities}
            applyFilter={applyFiltersHandler}
            toggleCity={toggleCity}
            selectAllCities={selectAllCities}
            unSelectAllCities={unSelectAllCities}
          />
          <div className="actions">
            <Link className="action" to={this.props.match.path + "factories/add"}>
              <Button type="button" category="blue-bg">
                Add Factory
              </Button>
            </Link>
          </div>
        </div>
        <div className="header-row">
          <div className={`table-headers ${isSuperAdmin ? "grid-6" : ""}`}>
            <div className="name">Factory</div>
            <PrivateContainer allowRoles={[LOCO_SUPER]}>
              <FilterLabel
                label="OWNER"
                allKey="all"
                filterKey="owner"
                filterName="owner"
                filterItems={brands}
                isMultiSelect={false}
                onSelect={onBrandSelect}
              />
            </PrivateContainer>
            <PrivateContainer allowRoles={[CLIENT_SUPER, CLIENT]}>
              <div className="added-by">Added by</div>
            </PrivateContainer>
            <PrivateContainer allowRoles={[LOCO_SUPER]}>
              <div className="merchant">LF Network</div>
            </PrivateContainer>
            <PrivateContainer allowRoles={[LOCO_SUPER]}>
              <div className="merchant">Merchant</div>
            </PrivateContainer>

            <div>{/* Column header for the extra-actions in last col */}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default FactoryHeader;
