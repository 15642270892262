import React, { Component } from "react";
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { ProfileAvatar } from "../../UI/ProfileAvatar";
import './index.scss';
import { getDesignLink } from "../../../helpers/DesignHelpers";
import { Link } from "react-router-dom";

export default class Header extends Component {
  static propTypes = {
    design: PropTypes.object.isRequired,
    receiverOrg: PropTypes.object.isRequired,
    userDetails: PropTypes.object.isRequired,
    showDesignName: PropTypes.bool,
    style: PropTypes.object,
  };

  render() {
    const {
      receiverOrg,
      userDetails,
      design,
      style,
      showDesignName = false,
    } = this.props;
    const designLink = getDesignLink(design, (userDetails.isFactory || userDetails.isSuperAdmin));

    return (
      <div className={classnames('messaging-header', {
        'is-factory': userDetails.isFactory,
        'is-brand': userDetails.isBrand,
      })} style={style}>
        <ProfileAvatar
          className="messaging-header__sender-org-avatar"
          id={receiverOrg && receiverOrg.id}
          name={receiverOrg && receiverOrg.name}
          url={receiverOrg && receiverOrg.image}
        />
        <div className="messaging-header__sender-org-name">
          {receiverOrg && receiverOrg.name}
        </div>
        {showDesignName && <div className="messaging-header__design-name">
          <Link to={designLink}>{design.name}</Link>
        </div>}
      </div>
    );
  }
}
