import React from "react";

import emptyIcon from "assets/img/Quote/ic-quote-empty.svg";

const EmptyList = ({
  heading,
  description,
  cta,
  designCoverPicUrl,
  isExpired
}) => {
  return (
    <div className="empty-list-container empty-list-container-quotes">
       <div className="img-container-quote">
        <img className="cover-pic" src={designCoverPicUrl} alt="Design Cover Pic" />
        <img className= "empty-quote-icon" src={emptyIcon} alt="Quote" />
      </div>
      <div className="content">
        {isExpired ?
          <div className="heading closure-expired">{heading}</div>
          :
          <div className="heading">{heading}</div>}
        <div className="description">{description}</div>
        {cta && <div className="cta">
          {cta}
        </div>}
      </div>
    </div >
  );
};

export default EmptyList;
