import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import actions from "../../store/actions";
import ProductionTracker from "../../components/ProductionTracker";
import DispatchedProductionTracker from "../../components/ProductionTracker/DispatchedProduction";
import { showBrandFilter } from './../../helpers/AuthHelper';
import { Helmet } from "react-helmet";
import { PAGE_TITLES } from "../../constants/Titles";

const ProductionTrackerContainer = ({
  isFactory,
  getAllProducts,
  products = [],
  openModal,
  closeModal,
  allBrands,
  getAllUserForBrand,
  brandId,
  userDetails,
  usersList,
  updatedSampleWithDispatch,
  prevProductsForProductionTracker,
  changeTrackerTab,
  location,
  match,
  ...restProps
}) => {

  const pathVariables = match.path.split("/")
  const tab = pathVariables[2];
  const stage = pathVariables[3];

  // this sets the production tab active
  useEffect(() => {
    changeTrackerTab({ activeTab: 0 });
  }, []);

  const showBrandFilterSelect = showBrandFilter();

  return (<>
    <Helmet
      title={PAGE_TITLES.production()}
    />
    {stage === "in-progress" ?
      <ProductionTracker
        products={products}
        isFactory={isFactory}
        userRole={userDetails.role}
        openModal={openModal}
        closeModal={closeModal}
        allBrands={allBrands}
        getAllProducts={getAllProducts}
        getAllUserForBrand={getAllUserForBrand}
        currentUserId={userDetails.brandId}
        prevProductsForTracker={prevProductsForProductionTracker}
        updatedSampleWithDispatch={updatedSampleWithDispatch}
        tab={tab}
        stage={stage}
        showBrandFilter={showBrandFilterSelect}
        location={location}
      /> :
      <DispatchedProductionTracker
        products={products}
        isFactory={isFactory}
        userRole={userDetails.role}
        openModal={openModal}
        allBrands={allBrands}
        getAllProducts={getAllProducts}
        getAllUserForBrand={getAllUserForBrand}
        currentUserId={userDetails.brandId}
        prevProductsForTracker={prevProductsForProductionTracker}
        updatedSampleWithDispatch={updatedSampleWithDispatch}
        tab={tab}
        stage={stage}
        showBrandFilter={showBrandFilterSelect}
      />}
  </>
  );
};

const mapStateToProps = ({ products, auth }) => ({
  products: products.productionTrackerProducts,
  updatedSampleWithDispatch: products.updatedSampleWithDispatch,
  prevProductsForProductionTracker:
    products.prevProductsForProductionTracker,
  userDetails: auth.userDetails
});

const mapDispatchToProps = dispatch => ({
  getAllProducts: params =>
    dispatch(actions.getAllProductsForProductionTracker(params)),
  openModal: (content, additionalProps) =>
    dispatch(actions.openModal(content, additionalProps)),
  closeModal: () =>
    dispatch(actions.closeModal()),
  getAllUserForBrand: details => dispatch(actions.getAllUsersForBrand(details)),
  changeTrackerTab: params => dispatch(actions.changeTrackerTab(params))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductionTrackerContainer);
