import React, { Component } from "react";
import TnaDetails from "./TnaDetails";
import "./TnaGraph.scss";
import TnaStepProgress from "./TnaStepProgress";
import orderBy from "lodash/orderBy";

const ORDER_PROD_SAMPLE = 3;

class TnaGraph extends Component {
  state = {
    isComplete: false,
    activeStage: "",
    inProgressStage: "",
    activeStageData: [],
    activeCategory: "",

    propsData: null,
    propsSampleDetails: null,
    propsSampleHistory: null,
  };

  static getCategorySubCategories = (data, stageId) => {
    let categoryData = data.find(item => item.id === stageId);
    let subCategories = categoryData ? categoryData.subCategories : [];
    return { subCategories, categoryData };
  };

  switchStage = stageId => {
    const { data } = this.props;
    let { subCategories, categoryData } = TnaGraph.getCategorySubCategories(
      data,
      stageId
    );
    this.setState({
      ...this.state,
      activeStage: stageId,
      activeStageData: subCategories,
      activeCategory: categoryData.category
    });
  };

  static getDerivedStateFromProps(nextProps, oldState) {
    if ((oldState.propsSampleDetails !== nextProps.sampleDetails
      || oldState.propsData !== nextProps.data
      || oldState.propsSampleHistory !== nextProps.sampleHistory)
      && (nextProps.data && nextProps.sampleDetails && nextProps.design)) {
      const { skipSampleApproval, design, sampleDetails, sampleHistory } = nextProps;
      const data = [...nextProps.data];

      // getting the PP/Prod sample attribute
      const sampleAttribute = data.find(tnaAttr => tnaAttr.id === ORDER_PROD_SAMPLE);

      // checking if there are any samples
      if (sampleAttribute
        && sampleDetails.samples
        && sampleDetails.samples.length > 0) {

        // getting the production sample
        const productionSample = sampleDetails.samples.find(sample => sample.isProductionSample);

        if (productionSample) {

          const productionSampleHistory = sampleHistory && sampleHistory[productionSample.sampleType];

          const approvedSampleHistory = productionSampleHistory && productionSampleHistory.find(st => st.sampleStatus === 'Approved');

          // sorting the sample history by last updated except approved
          const sortedSampleHistory = productionSampleHistory
            ? orderBy(productionSampleHistory.filter(st => st.sampleStatus !== 'Approved'), ['updatedAt'], ['desc'])
            : [];

          const latestSampleHistory = sortedSampleHistory[0] || {};
          const latestSampleDispatch = productionSample.sampleDispatch[0] || {};

          // getting the latest sample files (either from dispatch or resubmission request whichever is latest)
          const latestSampleFiles = latestSampleHistory.updatedAt > latestSampleDispatch.createdAt
            ? latestSampleHistory.files
            : latestSampleDispatch.files;

          // checking if latest status update has any files
          if (latestSampleFiles
            && latestSampleFiles.length > 0) {

            // set the sample dispatch files to tna pp sample attribute
            sampleAttribute.subCategories[0].files = latestSampleFiles;
          }

          if (approvedSampleHistory
            && approvedSampleHistory.files
            && approvedSampleHistory.files.length
            && sampleAttribute.subCategories[1]
            ) {
              sampleAttribute.subCategories[1].files = approvedSampleHistory.files ;
          }
        }
      }

      let isComplete = false;

      let inProgressStage = data.find(stage => {
        let invalidActualDate = stage.subCategories.find(subStage => {
          if (
            subStage.subCategory.includes("approved") &&
            subStage.actualDate === null &&
            skipSampleApproval
          ) {
            return false;
          }
          return subStage.actualDate === null;
        });
        if (invalidActualDate) {
          return stage.id;
        } else {
          return null;
        }
      });

      if (!inProgressStage) {
        inProgressStage = data[data.length - 1];
        isComplete = true;
      }

      if (design.tnaCategory === "Dispatch") {
        isComplete = true;
      }

      let lastCompleteStage = !inProgressStage
        ? data[data.length - 1].id
        : inProgressStage.id - 1;
      let { subCategories, categoryData } = TnaGraph.getCategorySubCategories(
        data,
        inProgressStage.id
      );

      return {
        inProgressStage: inProgressStage.id,
        lastCompleteStage,
        activeStage: inProgressStage.id,
        activeStageData: subCategories,
        activeCategory: categoryData.category,
        isComplete,
        data,
        propsData: nextProps.data,
        propsSampleDetails: sampleDetails,
        propsSampleHistory: sampleHistory,
      };
    }

    return null;
  }

  render() {
    const {
      activeStage,
      inProgressStage,
      activeStageData,
      isComplete,
      activeCategory,
      data,
    } = this.state;
    const {
      design,
      sampleDetails,
      showCta,
      addNewCommint,
      openVersionHistoryModal,
      openSampleStatusUpdateModal,
      isAccordion,
      fetchTna,
      comments,
      designId,
      graphLabel = "Track progress",
      sampleHistory,
    } = this.props;
    return (
      <>
        <div className="tna-graph">
          <div className="tna-graph__heading">{graphLabel}</div>
          <TnaStepProgress
            data={data}
            activeStage={activeStage}
            inProgressStage={inProgressStage}
            isComplete={isComplete}
            switchStage={this.switchStage}
            isAccordion={isAccordion}
          />
        </div>
        {isAccordion ? (
          <TnaDetails
            stageId={activeStage}
            inProgressStage={inProgressStage}
            data={activeStageData}
            isComplete={isComplete}
            design={design}
            sampleDetails={sampleDetails}
            showCta={showCta}
            activeCategory={activeCategory}
            addNewCommint={addNewCommint}
            openVersionHistoryModal={openVersionHistoryModal}
            openSampleStatusUpdateModal={openSampleStatusUpdateModal}
            fetchTna={fetchTna}
            comments={comments}
            designId={designId}
            sampleHistory={sampleHistory}
          />
        ) : null}
      </>
    );
  }
}

export default TnaGraph;
