import { createAction } from "redux-actions";
import axios from "axios";
import moment from "moment";
import { get_service_endpoint } from "../../ServiceEndpoints";
import { flash } from "./CommonActions";

const ep = `${get_service_endpoint("apiv2")}/reports`;

const GET_COSTING_REPORT = createAction("GET_COSTING_REPORT");
const GET_SAMPLING_REPORT = createAction("GET_SAMPLING_REPORT");
const GET_PRODUCTION_REPORT = createAction("GET_PRODUCTION_REPORT");
const GET_ACTIVE_FACTORIES_REPORT = createAction("GET_ACTIVE_FACTORIES_REPORT");
const GET_FACTORY_PERFORMANCE = createAction("GET_FACTORY_PERFORMANCE");
const GET_BUYER_PERFORMANCE = createAction("GET_BUYER_PERFORMANCE");
const GET_ORDERS_REPORT = createAction("GET_ORDERS_REPORT");
const GET_DESIGNS_REPORT = createAction("GET_DESIGNS_REPORT");
const GET_MATERIAL_REPORT = createAction("GET_MATERIAL_REPORT");

const REPORTS_LOADING = createAction("REPORTS_LOADING");

const formatParams = params => ({
  ...params,
  inlineLoader: true,
  minDate: params.minDate ? moment(params.minDate).format('YYYY-MM-DD') : null,
  maxDate: params.maxDate ? moment(params.maxDate).format('YYYY-MM-DD') : null,
  limit: params.limit ? params.limit : 20,
  brandId: params.brandId && params.brandId.value ? params.brandId.value : null
});

export const getCostingReport = (params = {}) => dispatch => {
  if (!params.skipStore) {
    dispatch(REPORTS_LOADING(true));
  }
  return axios
    .get(`${ep}/costing`, {
      params: formatParams(params)
    })
    .then(({ data }) => {
      if (params.skipStore) {
        return Promise.resolve(data);
      } else {
        dispatch(GET_COSTING_REPORT({ ...data, isInit: params.isInit }));
      }
    })
    .catch(err => {
      dispatch(REPORTS_LOADING(false));
      dispatch(
        flash({
          message: "Could not fetch costing reports",
          type: "action",
          status: "error"
        })
      );
    });
};

export const getSamplingReport = (params = {}) => dispatch => {
  if (!params.skipStore) {
    dispatch(REPORTS_LOADING(true));
  }
  return axios
    .get(`${ep}/sampling`, {
      params: formatParams(params)
    })
    .then(({ data }) => {
      if (params.skipStore) {
        return Promise.resolve(data);
      } else {
        dispatch(GET_SAMPLING_REPORT({ ...data, isInit: params.isInit }));
      }
    })
    .catch(err => {
      dispatch(REPORTS_LOADING(false));
      dispatch(
        flash({
          message: "Could not fetch sampling reports",
          type: "action",
          status: "error"
        })
      );
    });
};

export const getProductionReport = (params = {}) => dispatch => {
  if (!params.skipStore) {
    dispatch(REPORTS_LOADING(true));
  }
  return axios
    .get(`${ep}/production`, {
      params: formatParams(params)
    })
    .then(({ data }) => {
      if (params.skipStore) {
        return Promise.resolve(data);
      } else {
        dispatch(GET_PRODUCTION_REPORT({ ...data, isInit: params.isInit }));
      }
    })
    .catch(err => {
      console.log(err)
      dispatch(REPORTS_LOADING(false));
      dispatch(
        flash({
          message: "Could not fetch production reports",
          type: "action",
          status: "error"
        })
      );
    });
};

export const getActiveFactories = (params = {}) => dispatch => {
  if (!params.skipStore) {
    dispatch(REPORTS_LOADING(true));
  }
  return axios
    .get(`${ep}/activefactories`, {
      params: formatParams(params)
    })
    .then(({ data }) => {
      if (params.skipStore) {
        dispatch(REPORTS_LOADING(false));
        return Promise.resolve(data);
      } else {
        dispatch(
          GET_ACTIVE_FACTORIES_REPORT({ ...data, isInit: params.isInit })
        );
      }
    })
    .catch(err => {
      dispatch(REPORTS_LOADING(false));
      dispatch(
        flash({
          message: "Could not fetch active factories",
          type: "action",
          status: "error"
        })
      );
    });
};

export const getFactoryPerformance = (params = {}) => dispatch => {
  if (!params.skipStore) {
    dispatch(REPORTS_LOADING(true));
  }
  return axios
    .get(`${ep}/performance/factory`, {
      params: formatParams(params)
    })
    .then(({ data }) => {
      if (params.skipStore) {
        return Promise.resolve(data);
      } else {
        dispatch(GET_FACTORY_PERFORMANCE({ ...data, isInit: params.isInit }));
      }
    })
    .catch(err => {
      dispatch(REPORTS_LOADING(false));
      dispatch(
        flash({
          message: "Could not fetch factory performance",
          type: "action",
          status: "error"
        })
      );
    });
};

export const getBuyerPerformance = (params = {}) => dispatch => {
  if (!params.skipStore) {
    dispatch(REPORTS_LOADING(true));
  }
  return axios
    .get(`${ep}/performance/buyer`, {
      params: formatParams(params)
    })
    .then(({ data }) => {
      if (params.skipStore) {
        return Promise.resolve(data);
      } else {
        dispatch(GET_BUYER_PERFORMANCE({ ...data, isInit: params.isInit }));
      }
    })
    .catch(err => {
      dispatch(REPORTS_LOADING(false));
      dispatch(
        flash({
          message: "Could not fetch buyer performance",
          type: "action",
          status: "error"
        })
      );
    });
};

export const getOrdersReport = (params = {}) => dispatch => {
  if (!params.skipStore) {
    dispatch(REPORTS_LOADING(true));
  }
  return axios
    .get(`${ep}/orders`, {
      params: formatParams(params)
    })
    .then(({ data }) => {
      if (params.skipStore) {
        return Promise.resolve(data);
      } else {
        dispatch(GET_ORDERS_REPORT({ ...data, isInit: params.isInit }));
      }
    })
    .catch(err => {
      dispatch(REPORTS_LOADING(false));
      dispatch(
        flash({
          message: "Could not fetch orders report",
          type: "action",
          status: "error"
        })
      );
    });
};

export const getDesignsReport = (params = {}) => dispatch => {
  if (!params.skipStore) {
    dispatch(REPORTS_LOADING(true));
  }
  return axios
    .get(`${ep}/designs`, {
      params: formatParams(params)
    })
    .then(({ data }) => {
      if (params.skipStore) {
        return Promise.resolve(data);
      } else {
        dispatch(GET_DESIGNS_REPORT({ ...data, isInit: params.isInit }));
      }
    })
    .catch(err => {
      dispatch(REPORTS_LOADING(false));
      dispatch(
        flash({
          message: "Could not fetch designs report",
          type: "action",
          status: "error"
        })
      );
    });
};

export const getMaterialReport = (params = {}) => dispatch => {
  const { skipStore } = params;

  // delete param 'skipStore' from request params
  delete params.skipStore;

  if (!skipStore) {
    dispatch(REPORTS_LOADING(true));
  }
  return axios
    .get(`${ep}/material`, {
      params: formatParams(params)
    })
    .then(({ data }) => {
      if (skipStore) {
        return Promise.resolve(data);
      } else {
        dispatch(GET_MATERIAL_REPORT({ ...data, reportType: params.reportType, isInit: params.isInit }));
      }
    })
    .catch(err => {
      dispatch(REPORTS_LOADING(false));
      dispatch(
        flash({
          message: "Could not fetch material report",
          type: "action",
          status: "error"
        })
      );
    });
};
