import React, { useState, useMemo, useEffect } from "react";
import moment from "moment";
import groupBy from "lodash/groupBy";
import uniqWith from "lodash/uniqWith";
import DatePicker from "react-datepicker";
import { getMonth, getYear } from "date-fns";
import PerfectScrollbar from "react-perfect-scrollbar";

import DispatchStatus from "./DispatchStatus";
import EmptyScreen from "../../UI/EmptyScreen/index.jsx";
import dispatchImg from "assets/images/dashboard/package.svg";
import deliveryImg from "assets/images/dashboard/delivery.svg";

import { computeOnTime } from "./helper";

import "./customDatepicker.scss";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];
const monthsFull = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

const DispatchSchedule = ({ dispatchSchedule }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [dispatchData, setDispatchData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(getMonth(new Date()));
  const [selectedYear, setSelectedYear] = useState(getYear(new Date()));

  const [highlightWithRanges, dueDateData] = useMemo(() => {
    const filteredDates = Object.entries(
      groupBy(dispatchSchedule, "po_number")
    ).map(([key, values]) => values[0]);

    const groupedByDueDate = {};
    Object.entries(
      groupBy(dispatchSchedule, ({ order_due_date }) =>
        moment(order_due_date).format("LL")
      )
    ).forEach(([key, values]) => {
      groupedByDueDate[key] = uniqWith(
        values,
        (val1, val2) =>
          val1.po_number === val2.po_number && val1.design_id === val2.design_id
      );
    });

    const dotHashes = {};
    const datesWithDot = {};

    filteredDates.forEach(data => {
      if (moment(data.order_due_date).isBefore(moment())) {
        if (data.is_order_complete) {
          // Completed
          const date = moment(data.order_due_date).format("LL");

          if (dotHashes[date]) {
            dotHashes[date] += 1;
          } else {
            dotHashes[date] = 1;
          }
          if (dotHashes[date] === 3) {
            datesWithDot[date].splice(-1, 1, "greyplus");
          } else if (dotHashes[date] <= 2) {
            if (datesWithDot[date]) {
              datesWithDot[date].push("greydouble");
            } else {
              datesWithDot[date] = ["grey"];
            }
          }
        } else {
          // Delayed
          const date = moment(data.order_due_date).format("LL");

          if (dotHashes[date]) {
            dotHashes[date] += 1;
          } else {
            dotHashes[date] = 1;
          }
          if (dotHashes[date] === 3) {
            datesWithDot[date].splice(-1, 1, "redplus");
          } else if (dotHashes[date] <= 2) {
            if (datesWithDot[date]) {
              datesWithDot[date].push("reddouble");
            } else {
              datesWithDot[date] = ["red"];
            }
          }
        }
      }
      // If today then it will be green
      if (moment(data.order_due_date).isAfter(moment())) {
        // fUTURE
        const date = moment(data.order_due_date).format("LL");

        if (dotHashes[date]) {
          dotHashes[date] += 1;
        } else {
          dotHashes[date] = 1;
        }
        if (dotHashes[date] === 3) {
          datesWithDot[date].splice(-1, 1, "greenplus");
        } else if (dotHashes[date] <= 2) {
          if (datesWithDot[date]) {
            datesWithDot[date].push("greendouble");
          } else {
            datesWithDot[date] = ["green"];
          }
        }
      }
    });

    /* react-datepicker accepts highlight dates as an array of objects.
      With the key being the className given to particular date
      and values denoting the dates to which the class is applied.*/

    const rangeHash = {
      "highlighted-dates react-datepicker__day--highlighted-custom-greenplus dummy-class": [],
      "highlighted-dates react-datepicker__day--highlighted-custom-redplus dummy-class": [],
      "highlighted-dates react-datepicker__day--highlighted-custom-greyplus dummy-class": [],
      "highlighted-dates react-datepicker__day--highlighted-custom-green": [],
      "highlighted-dates react-datepicker__day--highlighted-custom-grey": [],
      "highlighted-dates react-datepicker__day--highlighted-custom-red": [],
      "highlighted-dates react-datepicker__day--highlighted-custom-greendouble": [],
      "highlighted-dates react-datepicker__day--highlighted-custom-greydouble": [],
      "highlighted-dates react-datepicker__day--highlighted-custom-reddouble": []
    };
    Object.entries(datesWithDot).forEach(([key, value]) => {
      return value.forEach(colorName => {
        if (
          colorName === "greenplus" ||
          colorName === "redplus" ||
          colorName === "greyplus"
        ) {
          rangeHash[
            `highlighted-dates react-datepicker__day--highlighted-custom-${colorName} dummy-class`
          ].push(moment(key).toDate());
        } else {
          rangeHash[
            `highlighted-dates react-datepicker__day--highlighted-custom-${colorName}`
          ].push(moment(key).toDate());
        }
      });
    });
    const classWithDates = Object.entries(rangeHash).map(([key, value]) => ({
      [key]: value
    }));
    return [classWithDates, groupedByDueDate];
  }, [dispatchSchedule]);

  useEffect(() => {
    setDispatchData(dueDateData);
  }, [dueDateData]);

  const selectedDateLl = moment(selectedDate).format("LL");
  const currentDayData = dispatchData[selectedDateLl] || [];

  const renderDayContents = (day, date) => {
    const tentativeDate = `${monthsFull[selectedMonth]} ${day}, ${selectedYear}`;
    const dispatchedDate = dispatchData[tentativeDate];
    let onTime = 0,
      delayed = 0;
    if (dispatchedDate) {
      uniqWith(
        dispatchedDate,
        (val1, val2) => val1.po_number === val2.po_number
      ).forEach(({ order_due_date, is_order_complete }) => {
        computeOnTime(order_due_date, is_order_complete) ? onTime++ : delayed++;
      });
    }
    const tooltip = (
      <>
        <div className="green">
          <span>On time</span> <b>{onTime}</b>
        </div>
        <div className="red">
          <span>Delayed</span> <b>{delayed}</b>
        </div>
      </>
    );
    return (
      <React.Fragment>
        <span>{day}</span>
        {dispatchedDate && <div className="visible-on-hover">{tooltip}</div>}
      </React.Fragment>
    );
  };

  const RenderMonth = ({ turn, changeMonth, tense }) => {
    let dates, hasDelayed;
    if (tense === "prev") {
      dates =
        highlightWithRanges[5][
          "highlighted-dates react-datepicker__day--highlighted-custom-red"
        ];
      hasDelayed = dates.some(
        d =>
          d.toString().includes(`${months[turn]}`) &&
          d.toString().includes(selectedYear)
      );
    }

    return (
      <div
        key={months[turn]}
        value={months[turn]}
        onClick={() => changeMonth(turn)}
        className={tense}
      >
        {tense === "prev" && hasDelayed && <span></span>}
        {months[turn]}
        {tense === "current" && ` ${selectedYear}`}
      </div>
    );
  };

  return (
    <div className="dispatch-design">
      <div className="dispatch-design-conatiner">
        <div className="calender-section">
          <div className="design-conatiner-heading">
            <img
              src={dispatchImg}
              className="active-icon"
              alt="dispatch icon"
            />{" "}
            Dispatch Schedule
          </div>
          <div className="datepicker-inline-view green-dot-style  red-dot-style plus-sign gray-dot-style ">
            <DatePicker
              inline
              calendarContainer="MyContainer"
              showMonth
              className="calender-day-style"
              highlightDates={highlightWithRanges}
              onChange={val => setSelectedDate(val)}
              selected={selectedDate}
              renderDayContents={renderDayContents}
              renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled
              }) => (
                <div
                  style={{
                    margin: 10,
                    display: "flex",
                    justifyContent: "center"
                  }}
                >
                  <button
                    onClick={decreaseMonth}
                    disabled={prevMonthButtonDisabled}
                    className="react-datepicker__navigation react-datepicker__navigation--previous"
                  >
                    {"<"}
                  </button>


                  <div className="grid-header">
                    <RenderMonth
                      turn={(selectedMonth - 2 + 12) % 12}
                      changeMonth={() => {
                        // TODO: Could be handled better. Need to Work on this.
                        setTimeout(() => {
                          decreaseMonth();
                        }, 0);
                        decreaseMonth();
                      }}
                      tense="prev"
                    />
                    <RenderMonth
                      turn={(selectedMonth - 1 + 12) % 12}
                      changeMonth={() => {
                        decreaseMonth();
                      }}
                      tense="prev"
                    />
                    <RenderMonth
                      turn={selectedMonth}
                      changeMonth={changeMonth}
                      tense="current"
                    />
                    <RenderMonth
                      turn={(selectedMonth + 1 + 12) % 12}
                      changeMonth={() => {
                        increaseMonth();
                      }}
                      tense="next"
                    />
                    <RenderMonth
                      turn={(selectedMonth + 2 + 12) % 12}
                      changeMonth={() => {
                        setTimeout(() => {
                          increaseMonth();
                        }, 0);
                        increaseMonth();
                      }}
                      tense="next"
                    />
                  </div>

                  <button
                    onClick={increaseMonth}
                    disabled={nextMonthButtonDisabled}
                    className="react-datepicker__navigation react-datepicker__navigation--next"
                  >
                    {">"}
                  </button>
                </div>
              )}
              onMonthChange={date => {
                setSelectedMonth(getMonth(date));
                setSelectedYear(getYear(date));
              }}
            />
          </div>
        </div>

        <div className="dispatch-status-section">
          <div className="status-title">
            {currentDayData.length}{" "}
            {currentDayData.length === 1 ? "dispatch" : "dispatches"} due on{" "}
            {selectedDateLl}
          </div>
          <PerfectScrollbar>
            <div className="dispatch-status-section-content">
              {currentDayData.length > 0 ? (
                Object.entries(groupBy(currentDayData, "po_number")).map(
                  ([key, values]) => (
                    <React.Fragment>
                      <p className="order-title">Order ID: {key}</p>
                      {values.map(val => (
                        <DispatchStatus data={val} />
                      ))}
                    </React.Fragment>
                  )
                )
              ) : (
                <EmptyScreen
                  icon={deliveryImg}
                  heading="No dispatches"
                  description={
                    <>
                      There are no dispatches
                      <br />
                      scheduled on this day
                    </>
                  }
                />
              )}
            </div>
          </PerfectScrollbar>
        </div>
      </div>
    </div>
  );
};

export default DispatchSchedule;
