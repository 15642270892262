import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import qs from "qs";

import PerfectScrollbar from "react-perfect-scrollbar";
import DatePicker from "react-datepicker";

import prodImage from "../../assets/product-item.jpeg";
import RedButton from "../Product/RedButton";
import greenCheck from "../../assets/images/circle-with-check-green.svg";
import fileIcon from "../../assets/images/editor/ic-attach-file.svg";
import Filter from "../Product/Filter";
import Toggle from "../UI/Toggle";
import cancelIcon from "../../assets/img/close-red.svg";

import styles from "../Product/Product.module.scss";
import actions from "../../store/actions";

import { get_service_endpoint } from "../../ServiceEndpoints";
import axios from "axios";
import { getSmallImageUrl } from "../../helpers/DesignHelpers";

const eq = get_service_endpoint("notificationView");

const OrderItem = ({
  design: { designName, sourceDesignId, designId, quantity, imageUrl },
  design,
  saveDesignQuantity,
  nextErr
}) => {
  let smallImage;
  if (imageUrl) {
    smallImage = getSmallImageUrl(imageUrl);
  }
  return (
    <div className={styles.order__item_wrapper}>
      <div className={styles.order__item_image_wrapper}>
        <img
          src={smallImage}
          alt="Design"
          className={styles.order__item_image}
        />
      </div>
      <div className={styles.order__item_details}>
        <div className="f-sz-l f-w-bold">{designName}</div>
        <div className={styles.order__item_id}>{sourceDesignId}</div>
      </div>
      <div className={styles.order__item_qty}>
        <input
          type="number"
          name="orderQuantity"
          id="orderQuantity"
          disabled
          className={styles.order__item_qty_input}
          onChange={ev => {
            saveDesignQuantity(designId, "quantity", ev.target.value);
          }}
          value={design.quantity}
          style={{ border: !quantity && nextErr ? "1px solid #ff0000" : "" }}
        />
      </div>
      <div className={styles.order__item_qty}>
        <input
          type="number"
          name="orderQuantity"
          id="orderQuantity"
          className={styles.order__item_qty_input}
          onChange={ev => {
            saveDesignQuantity(designId, "dispatchQuantity", ev.target.value);
          }}
          value={design.dispatchQuantity}
          style={{ border: !quantity && nextErr ? "1px solid #ff0000" : "" }}
        />
      </div>
    </div>
  );
};

const InputWithText = ({
  label,
  value,
  isDate = false,
  onChange,
  ID,
  type = "text",
  isError,
  disabled = false
}) => {
  const [selectedDate, setSelectedDate] = useState("");
  return (
    <div className={styles.input__with_text_wrapper}>
      <div className={styles.with__text_label}>{label}</div>

      {!isDate ? (
        <input
          type={type}
          className={styles.with__text_input}
          value={value}
          onChange={ev => {
            onChange(ID, ev.target.value);
          }}
          disabled={disabled}
          style={{ border: isError ? "1px solid #ff0000" : "" }}
        />
      ) : (
        <div style={{ marginLeft: "0px", width: "262px" }}>
          <DatePicker
            // withPortal
            popperPlacement="auto"
            popperClassName="custom-datepicker-popper"
            required={true}
            popperModifiers={{
              preventOverflow: {
                enabled: true,
                escapeWithReference: false
              }
            }}
            readOnly={false}
            minDate={""}
            maxDate={new Date()}
            placeholderText="Select Date"
            dateFormat="MMM dd, yyyy"
            className="custom-datepicker"
            selected={selectedDate}
            onChange={date => {
              setSelectedDate(date);
              onChange(ID, date);
            }}
            disabled={false}
            style={{ border: isError ? "1px solid #ff0000" : "" }}
          />
        </div>
      )}
    </div>
  );
};

const InputWithFileUpload = ({
  label,
  onChange,
  ID = "",
  value,
  deleteFile
}) => {
  const [fileName, setFileName] = useState("");
  return (
    <div className={styles.input__with_text_wrapper}>
      <div className={styles.with__text_label}>{label}</div>
      <div className={styles.with_input_type_file_wrapper}>
        <input
          type="file"
          className={styles.with__text_input_hidden}
          onChange={ev => {
            if (ev.target.files.length) {
              setFileName(ev.target.files[0]["name"]);
              onChange(ID, ev.target.files[0]);
            }
          }}
          style={{ display: fileName || value ? "none" : "" }}
        />
        {/* <div className={styles.visible__file_uploader_wrapper}>
          <div className={styles.visible__file_text}>
            {fileName ? fileName : "Attach file"}
          </div>
          <div className={styles.visible__file_icon}>
            <img src={fileIcon} alt="file" className="upload__ico" />
          </div>
        </div> */}

        {!value && !fileName ? (
          <div className={styles.visible__file_uploader_wrapper}>
            <div className={styles.visible__file_text}>
              {fileName ? fileName : "Attach file"}
            </div>
            <div className={styles.visible__file_icon}>
              {/* <i className="fa fa-paperclip f-sz-xxl"></i> */}
              <img src={fileIcon} alt="file" className="upload__ico" />
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              // marginLeft: "10px",
              padding: "10px 0 10px 13px",
              width: "262px"
            }}
          >
            {fileName ? (
              <span>
                {fileName.length > 20
                  ? `${fileName.slice(0, 10)}...${fileName.slice(
                      fileName.length - 10 - fileName.length
                    )}`
                  : fileName}
              </span>
            ) : (
              <span>
                {value.length > 20
                  ? `${value.slice(0, 10)}...${value.slice(
                      value.length - 10 - value.length
                    )}`
                  : value}
              </span>
            )}
            <img
              src={cancelIcon}
              alt="change"
              style={{ color: "#ff0000", cursor: "pointer" }}
              onClick={() => {
                setFileName("");
                deleteFile(ID);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const InputWithFilter = ({ onChange, ID, addresses, label }) => (
  <div className={styles.input__with_text_wrapper}>
    <div className={styles.with__text_label}>{label}</div>
    <div className={styles.with_input_type_file_wrapper}>
      <Filter
        title="Address"
        initialSelectedItem={{
          label: "All",
          value: "All"
        }}
        onChange={selection => {
          onChange(ID, selection.value);
        }}
        filterOptions={addresses.map(address => ({
          label: address.addressLine1,
          value: address.id
        }))}
        // marginRight="20px"
      />
    </div>
  </div>
);

//  ==== here ID is purchase id ==== from order item ====
const AddDispatchDetailsModel = ({
  brandid,
  id,
  addressId,
  dispatch,
  orderId
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [designs, setDesigns] = useState([]);
  const [nextErr, setNextErr] = useState(false);
  const [dispatchDetails, setDispatchDetails] = useState({});
  const [poError, setPoError] = useState(false);

  useEffect(() => {
    axios
      .get(`${eq}/orders/designs`, { params: { orderid: orderId } })
      .then(res => {
        // setAddresses(res.data);
        setDesigns(res.data);
      })
      .catch(err => {
        // console.log(err);
      });
  }, []);

  const saveDesignQuantity = (id, key, value) => {
    const updatedWithQuantityDesigns = designs.map(design => {
      if (design.designId === id) {
        design[key] = value;
        return { ...design };
      }
      return design;
    });

    setDesigns(updatedWithQuantityDesigns);

    if (nextErr) setNextErr(false);
  };

  // ===== This handles the validation plus $$$$$$ navigation to po and post create order api =====
  const toNextHandler = () => {
    //  == valodates for empty dispatch quantity ==
    if (!activeIndex) {
      let isDispatchQtyEmpty = false;
      // designs.forEach(({ dispatchQuantity }) => {
      //   if (!dispatchQuantity) {
      //     isDispatchQtyEmpty = true;
      //   }
      // });

      if (!designs.some(({ dispatchQuantity }) => dispatchQuantity)) {
        isDispatchQtyEmpty = true;
      }

      if (isDispatchQtyEmpty) {
        setPoError(true);
        setTimeout(() => {
          setPoError(false);
        }, 1000);
        return;
      }
    }

    // == changes the index ==
    if (!activeIndex) {
      setActiveIndex(1);
      return;
    }

    if (validatePoDetails()) {
      setNextErr(true);
      setPoError(true);
      setTimeout(() => {
        setPoError(false);
      }, 1000);
      return;
    }


    const designs_transformed = designs.map(design=>({...design, dispatchQuantity: Number(design.dispatchQuantity)}))
    const data = new FormData();
    data.append("selectedProducts", JSON.stringify(designs_transformed));
    data.append("brandId", brandid);
    data.append("addressId", addressId);
    data.append("orderPurchaseId", id);
    data.append(
      "quantity",
      designs.reduce((acc, design) => acc + Number(design.dispatchQuantity), 0)
    );
    data.append("numberOfBoxes", dispatchDetails.numberOfBoxes);
    data.append("dispatchDate", dispatchDetails.dispatchDate);
    data.append("trackingCode", dispatchDetails.trackingCode);
    data.append("courierPartner", dispatchDetails.courierPartner);
    data.append("isOrderComplete", dispatchDetails.isOrderComplete ? 1 : 0);
    data.append("ewayBillFile", dispatchDetails.ewayBillFile);
    data.append("challanFile", dispatchDetails.challanFile);
    data.append("invoiceFile", dispatchDetails.invoiceFile);
    data.append("packingListFile", dispatchDetails.packingListFile);
    //   == here remain sthe addredsss ID ====

    axios
      .post(`${eq}/orders/dispatch`, data)
      .then(res => {
        if (!res.data.error) {
          dispatch(actions.updateSingleOrder(res.data[0]));
          dispatch(actions.closeModal());
          dispatch(
            actions.flash({
              message: `Dispatch details for order ${
                res.data[0]["poNumber"]
              } updated.`,
              type: "action",
              actionType: "Update"
            })
          );

          if (dispatchDetails.isOrderComplete) {
            window.appHistory.push({
              search: qs.stringify({
                ...qs.parse(window.appHistory.location.search.slice(1)),
                tab: 'dispatched',
              })
            });
          }
        } else {
          dispatch(actions.flash({ message: "Something went wrong!" }));
          return;
        }
      })
      .catch(err => {
        alert("something went wrong!");
        // console.log(err);
      });
  };

  // ==== this function validates the po data on submit ===
  const validatePoDetails = () => {
    // console.log("YYYY", dispatchDetails);
    const keys = [
      "numberOfBoxes",
      "dispatchDate",
      "trackingCode",
      "courierPartner"
    ];

    // "ewayBillFile",
    //   "challanFile",
    //   "invoiceFile",
    //   "packingListFile"
    let dispatchDetailsError = false;
    keys.forEach(key => {
      if (!dispatchDetails[key]) {
        dispatchDetailsError = true;
      }
    });

    if (!dispatchDetailsError) {
      if (
        ![
          dispatchDetails["ewayBillFile"],
          dispatchDetails["challanFile"],
          dispatchDetails["invoiceFile"],
          dispatchDetails["packingListFile"]
        ].some(file => file)
      ) {
        dispatchDetailsError = true;
      }
    }

    if (dispatchDetailsError) return true;

    return false;
  };

  // ==== saves po details ====   ??????
  const savePODetailsHandler = (ID, value) => {
    if (nextErr) setNextErr(false);
    if (ID === "dispatchDate") {
      let convertedValue = moment(value).format("YYYY-MM-DD hh:mm:ss");
      value = convertedValue;
    }

    let updatedPoDetails = {
      ...dispatchDetails,
      [ID]: value
    };
    setDispatchDetails(updatedPoDetails);
  };

  // === THIS function deletes the attached files like eway Bill ect. ======
  const deletefFileHandler = ID => {
    let updatedPoDetails = {
      ...dispatchDetails,
      [ID]: ""
    };
    setDispatchDetails(updatedPoDetails);
  };

  // this style is for bar with check icons ===
  const activeStyle = {
    fontWeight: "bold",
    color: "var(--fill-locofast-black)"
  };

  return (
    <div className={styles.model_add_dispatch}>
      <div className={styles.scroll__wrapper}>
        {/* == this is bar with check icon == */}
        <div className={styles.bar__wrapper}>
          <div className={styles.bar__item} style={{ flex: 3 }}>
            <div
              className={styles.bar__label}
              style={activeIndex === 0 ? activeStyle : {}}
            >
              1. Dispatch Quantity
            </div>
            <div className={styles.bar__icon}>
              <img style={{ display: "flex" }} src={greenCheck} alt="check" />
            </div>
            <div className={styles.bar__line} style={{}} />
          </div>
          <div className={styles.bar__item} style={{ justifyContent: "left" }}>
            <div
              className={styles.bar__label}
              style={activeIndex === 1 ? activeStyle : {}}
            >
              2. Dispatch Details
            </div>
            <div className={styles.bar__icon}>
              {activeIndex >= 1 ? (
                <img style={{ display: "flex" }} src={greenCheck} alt="check" />
              ) : null}
            </div>
            {/* <div className={styles.bar__line} /> */}
          </div>
        </div>
        {/* Bar with check icons ends */}

        <div style={{ height: "460px" }}>
          <PerfectScrollbar style={{ maxHeight: window.innerHeight }}>
            {activeIndex === 1 ? (
              <>
                <div
                  className={styles.po__back_btn}
                  onClick={() => setActiveIndex(0)}
                >
                  Back
                </div>
                <div className={styles.po__wrapper}>
                  <InputWithText
                    value={designs.reduce(
                      (acc, design) => acc + Number(design.dispatchQuantity),
                      0
                    )}
                    onChange={savePODetailsHandler}
                    ID="quantity"
                    label="Dispatch Quantity"
                    type="number"
                    disabled
                  />
                  <InputWithText
                    isError={nextErr}
                    value={dispatchDetails.numberOfBoxes}
                    onChange={savePODetailsHandler}
                    ID="numberOfBoxes"
                    label="No of boxes*"
                    type="number"
                  />
                  <InputWithText
                    isError={nextErr}
                    value={dispatchDetails.dispatchDate}
                    onChange={savePODetailsHandler}
                    ID="dispatchDate"
                    label="Dispatch date*"
                    isDate
                  />
                  <InputWithText
                    isError={nextErr}
                    value={dispatchDetails.trackingCode}
                    onChange={savePODetailsHandler}
                    ID="trackingCode"
                    label="Tracking ID*"
                  />
                  <InputWithText
                    isError={nextErr}
                    value={dispatchDetails.courierPartner}
                    onChange={savePODetailsHandler}
                    ID="courierPartner"
                    label="Courier partner*"
                  />

                  <InputWithFileUpload
                    deleteFile={deletefFileHandler}
                    label="E-way Bill"
                    ID={"ewayBillFile"}
                    onChange={savePODetailsHandler}
                  />

                  <InputWithFileUpload
                    deleteFile={deletefFileHandler}
                    label="Challan"
                    ID={"challanFile"}
                    onChange={savePODetailsHandler}
                  />

                  <InputWithFileUpload
                    deleteFile={deletefFileHandler}
                    label="Invoice Copy"
                    ID={"invoiceFile"}
                    onChange={savePODetailsHandler}
                  />

                  <InputWithFileUpload
                    deleteFile={deletefFileHandler}
                    label="Packing list"
                    ID={"packingListFile"}
                    onChange={savePODetailsHandler}
                  />

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      paddingTop: "10px"
                    }}
                  >
                    <div className={styles.with__text_label}>
                      Is the order complete?
                    </div>
                    <Toggle
                      onStateChanged={result => {
                        // console.log("EEEEE  TTTTTTT", result);
                        savePODetailsHandler("isOrderComplete", result.enabled);
                      }}
                      hideText
                      theme="locofast"
                      style={{ marginRight: "110px" }}
                    />
                  </div>
                </div>
              </>
            ) : null}
            {activeIndex === 0 ? (
              <>
                <div className={styles.model__header_wrapper_add_dispatch}>
                  <div />
                  <div className={styles.model__header_item}>Design</div>
                  <div
                    className={styles.model__header_item}
                  >{`Quantity (pcs)`}</div>
                  <div
                    className={styles.model__header_item}
                  >{`Dispatch Quantity (pcs)`}</div>
                </div>

                {designs.map(design => (
                  <OrderItem
                    design={design}
                    nextErr={nextErr}
                    saveDesignQuantity={saveDesignQuantity}
                  />
                ))}
              </>
            ) : null}
          </PerfectScrollbar>
        </div>
      </div>

      {poError ? (
        <div
          style={{
            fontSize: "10px",
            padding: "8px",
            backgroundColor: "#f8d7da",
            color: "#721c24",
            textAlign: "center",
            marginBottom: "-10px",
            position: "absolute",
            right: 0,
            bottom: "12%",
            width: "100%",
            opacity: poError ? 1 : 0,
            transition: "0.2s all"
          }}
        >
          {!activeIndex
            ? "Please enter dispatch quantity."
            : "Please fill required fields."}
        </div>
      ) : null}

      <div className={styles.order__footer_wrapper}>
        <div className={styles.order__footer_details}>
          {/* <div className={styles.order__footer_details_label}>
            In this order
          </div>
          <div className="f-sz-l f-w-bold">{`${designs.length} Products`}</div> */}
        </div>

        <div className={styles.order__footer_btn_wrapper}>
          <RedButton
            label={!activeIndex ? "Next" : "Save"}
            onClick={() => {
              toNextHandler();
            }}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  designs: state.products.selectedDesigns
});

// const mapDispatchToProps = dispatch => ({
//   updateSingleOrder: params => dispatch(actions.updateSingleOrder(params)),
// })

export default connect(mapStateToProps)(AddDispatchDetailsModel);
