import { handleActions } from "redux-actions";

const INITIAL_STATE = {
  summary: {},
  activeDesigns: {},
  dispatchSchedule: [],
  activeFactories: [],
  currentTasks: [],
  pendingApprovals: {},
  myTeam: {
    rows: [],
    paginate: {
      count: 0,
    },
  },
};

const Dashboard = handleActions({
  SET_DASHBOARD_SUMMARY: (state, action) => {
    return {
      ...state,
      summary: action.payload,
    };
  },
  SET_DASHBOARD_DESIGNS: (state, action) => {
    const { data, refresh } = action.payload;
    let allDesigns = refresh
      ? data.designsData
      : [...state.activeDesigns.allDesigns, ...data.designsData];

    const {
      paginate
    } = data;

    return {
      ...state,
      activeDesigns: {
        ...data,
        allDesigns,
        counts: paginate.isNext && state.activeDesigns.counts ? state.activeDesigns.counts : data.counts
      },
    };
  },
  SET_DASHBOARD_DISPATCHES: (state, action) => {
    return {
      ...state,
      dispatchSchedule: action.payload,
    };
  },
  SET_DASHBOARD_FACTORIES: (state, action) => {
    return {
      ...state,
      activeFactories: action.payload,
    };
  },
  SET_DASHBOARD_TASKS: (state, action) => {
    return {
      ...state,
      currentTasks: action.payload,
    };
  },
  SET_DASHBOARD_PENDING_APPROVALS: (state, action) => {
    return {
      ...state,
      pendingApprovals: action.payload,
    };
  },
  DASHBOARD_GET_MY_TEAM: (state, action) => {
    const { rows, paginate, refresh } = action.payload;
    const updatedPaginate = {
      ...paginate,
      numberOfRecords: (rows && rows.length) || 0,
    }

    return {
      ...state,
      myTeam: (refresh
        ? {
          rows,
          paginate: updatedPaginate,
        }
        : {
          rows: [...state.myTeam.rows, ...rows],
          paginate: updatedPaginate,
        }),
    }
  },
  LOGOUT: (state, action) => {
    return {
      ...state,
      ...INITIAL_STATE
    };
  },
}, INITIAL_STATE);

export default Dashboard;
