import React, { Component } from "react";
import DatePicker from "react-datepicker";

import "./styles.scss";
import Button from "components/UI/Button/Button";
import InputAttachments from "components/UI/InputAttachments";

import { VALID_FILE_TYPES } from "constants/Global";

class ActualDateModal extends Component {
  state = {
    date: null,
    file: null,
    files: []
  };

  handleAttachmentsChange = files => {
    this.setState({ files });
  };

  render() {
    const {
      closeModal,
      handleSubmit,
      dateLimit = {},
      onUploadFile
    } = this.props;
    const { files, date } = this.state;
    return (
      <div className="date-modal">
        <p className="note">
          You won’t be able to change this date once submitted.
        </p>
        <main>
          <p>Select date</p>
          <DatePicker
            fixedHeight
            popperPlacement="auto"
            popperClassName="custom-datepicker-popper"
            popperModifiers={{
              preventOverflow: {
                editMode: true,
                escapeWithReference: false // force popper to stay in viewport (even when input is scrolled out of view)
              }
            }}
            minDate={dateLimit.minDate}
            maxDate={dateLimit.maxDate}
            placeholderText="Select Date"
            dateFormat="MMMM dd, yyyy"
            className={"custom-datepicker custom-datepicker-nobg"}
            onChange={date => {
              this.setState({
                date
              });
            }}
            inline
            selected={date}
          />
          <div className="attach-file">
            <InputAttachments
              maxCount={4}
              labelOnMax="hide"
              fileTypes={VALID_FILE_TYPES}
              onChange={this.handleAttachmentsChange}
              labelText="Attach files(up to 4)"
            />
          </div>
        </main>
        <footer>
          <Button
            category="black-ghost medium"
            onClick={() => {
              closeModal();
            }}
          >
            CANCEL
          </Button>

          <Button
            category="primary-blue"
            onClick={() => {
              if (date) {
                handleSubmit(date);
                if (files.length > 0) {
                  onUploadFile(files);
                }
                closeModal();
              }
            }}
          >
            CONFIRM
          </Button>
        </footer>
      </div>
    );
  }
}

export default ActualDateModal;
