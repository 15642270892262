import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { getCoverImage } from '../../../../helpers/DesignHelpers';
import './style.scss';

const DesignAvatar = ({
  design: {
    id,
    name,
    category = 'Women',
    coverPic,
    coverPicUrl,
    coverImageUrl,
  },
  size = '24px',
  style
}) => {
  const isPlaceholder = !coverPic && !coverImageUrl && !coverPicUrl;
  const url = getCoverImage({ category, coverPic: (coverPic || coverPicUrl || coverImageUrl) });

  return <div
    className={classnames(`design-avatar`, {
      'design-avatar--placeholder': isPlaceholder
    })}
    style={{
      ...style,
      '--avatar-size': size,
    }}
  >
    <img src={url} alt={`${name}`} />
  </div>
};

DesignAvatar.propTypes = {
  design: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    category: PropTypes.string,
    coverPic: PropTypes.string,
    coverImageUrl: PropTypes.string,
  }),
  size: PropTypes.string,
};

export default DesignAvatar;
