import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";

import Summary from "components/Dashboard/Summary";
import Sidebar from "components/Dashboard/sidebar";
import ActiveDesigns from "components/Dashboard/ActiveDesigns";
import ActiveFactories from "components/Dashboard/ActiveFactories";
import CurrentTasks from "components/Dashboard/ContinueTasks";
import DispatchSchedule from "components/Dashboard/DispatchSchedule";
import Team from "components/Dashboard/myTeam";

import * as actions from "store/actions/DashboardActions";

import "./UserDashboardContainer.scss";
import {
  getCurrentUserRole
} from "helpers/AuthHelper";

import {
  CLIENT_SUPER
} from "constants/Auth";
import { PAGE_TITLES } from "../../constants/Titles";

class Dashboard extends Component {
  componentDidMount() {
    this.props.getDashboardSummary();
    this.props.getDashboardActiveFactories();
    this.props.getDashboardTasks();
    this.props.getDashboardPendingData();
    this.props.getDashboardDispatches();
  }

  render() {
    const {
      summary,
      activeDesigns,
      activeFactories,
      currentTasks,
      pendingApprovals,
      dispatchSchedule
    } = this.props;

    const isBrandAdmin = getCurrentUserRole() == CLIENT_SUPER

    return (
      <>
        <Helmet
          bodyAttributes={{
            class: "dashboard-body-style"
          }}
          title={PAGE_TITLES.dashboard()}
        />
        <div className="dashboard">
          <div className="dashboard-left-section">
            <Summary summary={summary} />
            <ActiveDesigns
              activeDesigns={activeDesigns}
              getDesigns={this.props.getDashboardActiveDesigns}
            />
            <DispatchSchedule dispatchSchedule={dispatchSchedule} />
            <div className="factories-tast-section">
              <ActiveFactories activeFactories={activeFactories} />
              <CurrentTasks tasks={currentTasks} />
            </div>
            {
              isBrandAdmin && <Team />
            }

          </div>

          <div className="dashboard-right-section">
            <Sidebar pendingApprovals={pendingApprovals} />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  summary: state.dashboard.summary,
  activeDesigns: state.dashboard.activeDesigns,
  dispatchSchedule: state.dashboard.dispatchSchedule,
  activeFactories: state.dashboard.activeFactories,
  currentTasks: state.dashboard.currentTasks,
  pendingApprovals: state.dashboard.pendingApprovals
});

const mapDispatchToProps = dispatch => ({
  getDashboardSummary: () => dispatch(actions.getDashboardSummary()),
  getDashboardActiveDesigns: params =>
    dispatch(actions.getDashboardActiveDesigns(params)),
  getDashboardDispatches: () => dispatch(actions.getDashboardDispatches()),
  getDashboardActiveFactories: () =>
    dispatch(actions.getDashboardActiveFactories()),
  getDashboardTasks: () => dispatch(actions.getDashboardTasks()),
  getDashboardPendingData: () => dispatch(actions.getDashboardPendingData())
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
