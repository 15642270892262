import React, { useState } from "react";
import PropTypes from "prop-types";
import CloseImg from "../../../assets/images/navigation/ic-close.svg";
import Button from "components/UI/Button/Button";
import "./Modal.scss";
import "./AlertModal.scss";
import { IconArrowBroad } from "../Icons";
import { useEffect } from "react";

const Modal = props => {
  const { additionalProps } = props;
  const [modalTitle, setModalTitle] = useState(() =>
    additionalProps.title ? additionalProps.title : props.content.title
  );
  const [footer, setFooter] = useState(() =>
    additionalProps.footer ? additionalProps.footer : props.content.footer
  );
  const [isFooterEnabled, setIsFooterEnabled] = useState(true);
  const titleImg = additionalProps.titleImg || props.content.titleImg;
  const modalClassName =
    (props.content.modalType
      ? "modal__wrapper--" + props.content.modalType
      : "") + (props.content.className ? " " + props.content.className : "");
  const setHeaderTitle = title => {
    setModalTitle(title);
  };

  useEffect(() => {
    // when content changes (mostly from openNextModal)

    // set header title
    setModalTitle(additionalProps.title ? additionalProps.title : props.content.title);

    // set footer CTAs
    setFooter(additionalProps.footer ? additionalProps.footer : props.content.footer);
  }, [props.content]);

  //right-full-height
  return (
    <div
      className={`modal__wrapper flex-col ${modalClassName}`}
      style={{
        borderRadius: props.isCustomStyles ? 0 : "",
        boxShadow: props.isCustomStyles ? "none" : "",
        border: "1px solid #d4d4d4",
        ...(additionalProps.styles || {})
      }}
    >
      {modalTitle !== null ? (
        <div
          className={`modal__header flex f-sz-xl f-w-bold ${
            titleImg ? "has-img" : ""
          }`}
        >
          <div className="flex-col">
            {titleImg && (
              <div className="modal__header__img">
                <img src={titleImg} alt="" />
              </div>
            )}
            <div>
              {props.canNavigateBack ? (
                <Button
                  category="icon"
                  className="modal__nav-back"
                  onClick={props.navigateBack}
                >
                  <IconArrowBroad direction="left" />
                </Button>
              ) : null}
              {modalTitle}
            </div>
            {additionalProps.subtitle ? (
              <div style={{ fontWeight: "normal", paddingTop: "4px" }} className="f-sz-m">
                {`${additionalProps.subtitle.label}: ${additionalProps.subtitle.value}`}
              </div>
            ) : null}
          </div>
          {props.content.showClose !== false && (
            <span onClick={props.closeModal} className="modal__close">
              <img src={CloseImg} alt="close modal" />
            </span>
          )}
        </div>
      ) : (
        <div className="modal__header--empty" />
      )}

      {typeof props.content.innerContent === "function" ? (
        // <div className="modal__content">
        <props.content.innerContent
          setHeaderTitle={setHeaderTitle}
          setFooter={setFooter}
          setIsFooterEnabled={setIsFooterEnabled}
          openNextModal={props.openNextModal}
          closeModal={props.closeModal}
          onSubmit={values => {
            let formActionRet = props.formAction(values);
            if (formActionRet) {
              return formActionRet.then(data => {
                if (props.additionalProps.afterSubmit) {
                  props.additionalProps.afterSubmit(data);
                }
              });
            } else {
              return;
            }
          }}
          {...props.additionalProps}
        />
      ) : (
        // </div>
        <div className="modal__content--text f-sz-l f-w-semi-bold">
          {props.content.innerContent}
        </div>
      )}

      {footer !== null && (
        <div
          className={`modal__footer f-sz-m f-w-bold ${
            props.content.filledFooter ? "filled" : ""
          }`}
        >
          {props.content.withCancel && (
            <Button
              onClick={props.closeModal}
              category="btn black-ghost medium"
            >
              CANCEL
            </Button>
          )}
          <Button
            type="submit"
            onClick={() => {
              // If remote Submit needed (for forms with values in store)
              if (props.remoteSubmit) props.remoteSubmit();
              // If form with only hidden values
              else {
                let data = {};

                if (Array.isArray(props.additionalProps.hiddenFields)) {
                  props.additionalProps.hiddenFields.forEach(
                    f => (data[f.name] = f.value)
                  );
                } else {
                  data = props.additionalProps.hiddenFields;
                }

                props.formAction(data);
              }

              // IMPORTANT: Close Modal after loading info in the Actions.
            }}
            disabled={!isFooterEnabled}
            category="primary-blue"
          >
            {footer}
          </Button>
        </div>
      )}
    </div>
  );
};

Modal.propTypes = {
  content: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired
};

export default Modal;
