export const TNA_ATTRIBUTES_ORDER = {
  INWARDS_STAGE: {
    categoryOrder: 2,
  },
  SAMPLE_DISPATCH: {
    categoryOrder: 3,
    attributeOrder: 1
  },
  SAMPLE_APPROVAL: {
    categoryOrder: 3,
    attributeOrder: 2
  }
};

export const TNA_STATUS = {
  TEMPLATE: 0,
  CREATED: 1,
  SUBMITTED: 2,
  RESUBMITTED: 3,
  APPROVED: 4
};
