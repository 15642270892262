import React from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Button from '../../../../UI/Button/Button';
import OrderSummary from '../../OrderSummary/index';
import CustomInput from '../../../../UI/Form/Input/CustomInput';
import EmptyScreen from '../../../../UI/EmptyScreen';

import { formatNumber, formatDecimals } from '../../../../../helpers/i18nHelper';

import { ReactComponent as TrashRedImg } from '../../../../../assets/images/action/trash-red.svg';
import { ReactComponent as TrashGreyImg } from '../../../../../assets/images/action/trash-grey.svg';
import DesignsEmptyImg from '../../../../../assets/images/emptyPages/designs-empty.svg';

import './style.scss';
import { getCoverImage } from '../../../../../helpers/DesignHelpers';

const SQL_MAX_INT_SGN = 2147483647;
const MAX_PRICE = SQL_MAX_INT_SGN;
const MAX_QUANTITY = SQL_MAX_INT_SGN;
const MAX_PO_VALUE = SQL_MAX_INT_SGN;

export default class DesignsListSummary extends React.Component {
  static propTypes = {
    factory: PropTypes.object.isRequired,
    brand: PropTypes.object,
    designs: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      sourceDesignId: PropTypes.string.isRequired,
      coverPic: PropTypes.string.isRequired,
      category: PropTypes.string.isRequired,
      quotePrice: PropTypes.number.isRequired,
      quoteQuantity: PropTypes.number.isRequired,
    })).isRequired,
    currency: PropTypes.string.isRequired,
    submitText: PropTypes.string.isRequired,
    submitTooltip: PropTypes.string,
    onOrgChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onRemoveDesign: PropTypes.func,
    isReadonly: PropTypes.bool,
    canContinue: PropTypes.bool,
    poValue: PropTypes.number,
  };

  static defaultProps = {
    isReadonly: false,
  };

  state = {
    totalQuantity: 0,
    subTotal: 0,
    poValue: null,
    mapDesignInfo: {},
    isFormValid: false,
    propsDesigns: null,
    propsPOValue: null,
  };

  static getDerivedStateFromProps(nextProps, oldState) {
    const nextState = {};

    if (nextProps.designs !== oldState.propsDesigns) {
      let totalQuantity = 0;
      let subTotal = 0;
      let newMapDesignsInfo = {};
      if (Array.isArray(nextProps.designs)) {
        const oldMapDesignsInfo = oldState.mapDesignInfo;
        newMapDesignsInfo = nextProps.designs.reduce((prev, design) => {
          prev[design.id] = oldMapDesignsInfo[design.id] || {
            price: design.price || design.quotePrice,
            quantity: design.quantity || design.quoteQuantity || 0,
            isPriceInvalid: design.isPriceInvalid || false,
            isQuantityInvalid: design.isQuantityInvalid || false,
          };

          totalQuantity += +prev[design.id].quantity;
          subTotal += prev[design.id].quantity * prev[design.id].price;

          return prev;
        }, {});
      }

      nextState.propsDesigns = nextProps.designs;
      nextState.mapDesignInfo = newMapDesignsInfo;
      nextState.totalQuantity = totalQuantity;
      nextState.subTotal = subTotal;
    }

    if (nextProps.poValue !== oldState.propsPOValue) {
      nextState.propsPOValue = nextProps.poValue;
      nextState.poValue = nextProps.poValue;
    }

    if (Object.values(nextState).length) {
      nextState.isFormValid = DesignsListSummary.isValidState(nextProps, {
        ...oldState,
        ...nextState
      });
    }

    return Object.values(nextState).length > 0 ? nextState : null;
  }

  static isValidState(props, state) {
    if (props.isReadonly) {
      return false;
    }

    if (!state.totalQuantity || state.totalQuantity <= 0
      || !state.poValue || state.poValue <= 0) {
      return false;
    }

    // any design has 0 qty or price
    const hasInvalidDesign = (props.designs.some(design => (+state.mapDesignInfo[design.id].quantity) <= 0
      || (+state.mapDesignInfo[design.id].price) <= 0));

    if (hasInvalidDesign) {
      return false;
    }

    return true;
  }

  _removeDesign = (design) => {
    const { onRemoveDesign } = this.props;

    onRemoveDesign(design);
  }

  _validateAndSave(newState) {
    const state = {
      ...this.state,
      ...newState,
    };

    const isFormValid = DesignsListSummary.isValidState(this.props, state);

    this.setState({
      ...newState,
      isFormValid,
    });
  }

  _handleChange = (design, key, value) => {
    const reg = (key === 'price' || key === 'poValue') ? (/^\d+(\.?)(\d{1,2})?$/g) : (/^\d+$/g);

    if (value && !reg.test(value)) {
      return false;
    }

    const numValue = +value;

    if (key === 'quantity' && numValue > MAX_QUANTITY) {
      return;
    }

    if (key === 'price' && numValue > MAX_PRICE) {
      return;
    }

    if (key === 'poValue' && numValue > MAX_PO_VALUE) {
      return;
    }

    if (key === 'poValue') {
      this._validateAndSave({ poValue: value });
      return;
    }

    const { mapDesignInfo, totalQuantity, subTotal } = this.state;
    const designInfo = mapDesignInfo[design.id];
    const newDesignQuantity = key === 'quantity' ? numValue : designInfo.quantity;
    const newDesignPrice = key === 'price' ? numValue : designInfo.price;
    const newTotalQuantity = totalQuantity + (newDesignQuantity - designInfo.quantity);
    const newSubTotal = subTotal + ((newDesignQuantity * newDesignPrice) - (designInfo.quantity * designInfo.price));

    this._validateAndSave({
      totalQuantity: newTotalQuantity,
      subTotal: newSubTotal,
      mapDesignInfo: {
        ...mapDesignInfo,
        [design.id]: {
          ...designInfo,
          [key]: value,
        },
      },
    });
  }

  _handleSubmit = () => {
    const { onSubmit, designs } = this.props;
    const { totalQuantity, poValue, mapDesignInfo } = this.state;

    if (typeof (onSubmit) === 'function') {
      onSubmit({
        designs: designs.map(design => ({
          ...design,
          price: +mapDesignInfo[design.id].price,
          quantity: +mapDesignInfo[design.id].quantity,
        })),
        totalQuantity,
        poValue: +poValue,
      });
    }
  }

  _renderDesignsList = () => {
    const { designs, currency, isReadonly } = this.props;
    const { mapDesignInfo } = this.state;

    return <PerfectScrollbar className="summary__design-list">
      {designs.map((design) => <div className="summary__design-item" key={design.id}>
        <div className="summary__design-item__cover-pic">
          <img src={getCoverImage(design)} alt="cover" />
        </div>
        <div className="summary__design-item__content">
          <div className="summary__design-item__content__header flex">
            <div className="summary__design-item__design-name flex-grow overflow-ellipsis">
              <span>
                {design.name}
              </span>
            </div>
            {!isReadonly && <div className="summary__design-item__design-cta">
              <Button className="cta-design-delete" category="img shrink" onClick={() => this._removeDesign(design)}>
                <TrashRedImg className="img-trash-red" />
                <TrashGreyImg className="img-trash-grey" />
              </Button>
            </div>}
          </div>
          <div className="summary__design-item__content__footer flex">
            {
              isReadonly
                ? <div className="summary__design-item__design-price-quantity">
                  {currency}{mapDesignInfo[design.id].price} x {mapDesignInfo[design.id].quantity}pcs
                  </div>
                : <>
                  <div className="summary__design-item__design-price">
                    <CustomInput
                      className="input-price"
                      prefixText={currency}
                      gluePrefix={true}
                      value={mapDesignInfo[design.id].price}
                      onChange={evt => {
                        this._handleChange(design, 'price', evt.target.value);
                      }}
                    />
                  </div>
                  <div className="summary__design-item__design-quantity">
                    <CustomInput
                      className="input-quantity"
                      prefixText="Qty"
                      gluePrefix={true}
                      value={mapDesignInfo[design.id].quantity}
                      onChange={evt => {
                        this._handleChange(design, 'quantity', evt.target.value);
                      }}
                    />
                  </div>
                </>
            }
          </div>
        </div>
      </div>)}
    </PerfectScrollbar>;
  }

  _renderTotal = () => {
    const { currency, isReadonly, submitText, submitTooltip, canContinue } = this.props;
    const { totalQuantity, poValue, isFormValid } = this.state;
    const hasSubmitTooltip = submitTooltip && submitTooltip.trim().length > 0;

    return <div className="summary__design-total flex-col">
      <div className="summary__design-total__quantity flex">
        <span className="field-label flex-grow">Total Quantity</span>
        <span className="field-value">{formatNumber(totalQuantity)} pcs</span>
      </div>
      <div className="summary__design-total__po-value flex">
        {
          isReadonly
            ? <>
              <div className="field-label flex-grow">
                P.O. Value
              </div>
              <div className="field-value">
                {currency}{formatDecimals(+(poValue || 0))}
              </div>
            </>
            : <CustomInput
              className="input-po-value"
              prefixText={`P.O. Value (${currency})`}
              placeholder="Enter Amount"
              gluePrefix={true}
              value={poValue}
              onChange={evt => {
                this._handleChange(null, 'poValue', evt.target.value);
              }}
            />
        }
      </div>
      <Button
        className="cta-continue tooltip-container"
        category="blue-bg"
        type="submit"
        disabled={!isFormValid && (!isReadonly || !canContinue)}
        onClick={() => this._handleSubmit()}
      >
        {submitText}
        {hasSubmitTooltip && <div
          className="tooltip dark-tooltip tooltip--h-center tooltip--v-top"
          style={{ '--padding': '8px' }}
        >{submitTooltip}</div>}
      </Button>
    </div>
  }

  render() {
    const { brand, factory, designs, onOrgChange } = this.props;

    return <OrderSummary
      brand={brand}
      factory={factory}
      onOrgChange={onOrgChange}
      canChangeBrand={false}
    >
      {
        designs && designs.length > 0
          ? <>
            {this._renderDesignsList()}
            {this._renderTotal()}
          </>
          : <div className="summary__empty-designs">
            <EmptyScreen
              icon={DesignsEmptyImg}
              iconSize="72px"
              iconCurved={false}
              heading="No designs selected"
              description={<>
                Select designs that you want to <br />
                order
              </>}
            />
          </div>
      }
    </OrderSummary>
  }
}
