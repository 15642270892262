export const Sizes = {
  MEN: {
    TOPWEAR: [
      {
        "label": "10",
        "value": "10"
      },
      {
        "label": "11",
        "value": "11"
      },
      {
        "label": "18",
        "value": "18"
      },
      {
        "label": "19",
        "value": "19"
      },
      {
        "label": "20",
        "value": "20"
      },
      {
        "label": "21",
        "value": "21"
      },
      {
        "label": "22",
        "value": "22"
      },
      {
        "label": "25",
        "value": "25"
      },
      {
        "label": "26",
        "value": "26"
      },
      {
        "label": "28",
        "value": "28"
      },
      {
        "label": "30",
        "value": "30"
      },
      {
        "label": "31",
        "value": "31"
      },
      {
        "label": "31.5",
        "value": "31.5"
      },
      {
        "label": "32",
        "value": "32"
      },
      {
        "label": "33",
        "value": "33"
      },
      {
        "label": "34",
        "value": "34"
      },
      {
        "label": "35",
        "value": "35"
      },
      {
        "label": "36",
        "value": "36"
      },
      {
        "label": "36.5",
        "value": "36.5"
      },
      {
        "label": "37",
        "value": "37"
      },
      {
        "label": "38",
        "value": "38"
      },
      {
        "label": "39",
        "value": "39"
      },
      {
        "label": "40",
        "value": "40"
      },
      {
        "label": "40.5",
        "value": "40.5"
      },
      {
        "label": "41",
        "value": "41"
      },
      {
        "label": "42",
        "value": "42"
      },
      {
        "label": "43",
        "value": "43"
      },
      {
        "label": "44",
        "value": "44"
      },
      {
        "label": "45",
        "value": "45"
      },
      {
        "label": "46",
        "value": "46"
      },
      {
        "label": "47",
        "value": "47"
      },
      {
        "label": "48",
        "value": "48"
      },
      {
        "label": "50",
        "value": "50"
      },
      {
        "label": "52",
        "value": "52"
      },
      {
        "label": "54",
        "value": "54"
      },
      {
        "label": "56",
        "value": "56"
      },
      {
        "label": "58",
        "value": "58"
      },
      {
        "label": "60",
        "value": "60"
      },
      {
        "label": "7",
        "value": "7"
      },
      {
        "label": "8",
        "value": "8"
      },
      {
        "label": "9",
        "value": "9"
      },
      {
        "label": "L",
        "value": "L"
      },
      {
        "label": "L/XL",
        "value": "L/XL"
      },
      {
        "label": "M",
        "value": "M"
      },
      {
        "label": "M/L",
        "value": "M/L"
      },
      {
        "label": "ONESIZE",
        "value": "ONESIZE"
      },
      {
        "label": "PACK",
        "value": "PACK"
      },
      {
        "label": "S",
        "value": "S"
      },
      {
        "label": "S/M",
        "value": "S/M"
      },
      {
        "label": "XL",
        "value": "XL"
      },
      {
        "label": "XS",
        "value": "XS"
      },
      {
        "label": "XS/S",
        "value": "XS/S"
      },
      {
        "label": "XXL",
        "value": "XXL"
      }
    ],
    BOTTOMWEAR: [
      {
        "label": "10",
        "value": "10"
      },
      {
        "label": "11",
        "value": "11"
      },
      {
        "label": "18",
        "value": "18"
      },
      {
        "label": "19",
        "value": "19"
      },
      {
        "label": "20",
        "value": "20"
      },
      {
        "label": "21",
        "value": "21"
      },
      {
        "label": "22",
        "value": "22"
      },
      {
        "label": "25",
        "value": "25"
      },
      {
        "label": "26",
        "value": "26"
      },
      {
        "label": "28",
        "value": "28"
      },
      {
        "label": "30",
        "value": "30"
      },
      {
        "label": "31",
        "value": "31"
      },
      {
        "label": "31.5",
        "value": "31.5"
      },
      {
        "label": "32",
        "value": "32"
      },
      {
        "label": "33",
        "value": "33"
      },
      {
        "label": "34",
        "value": "34"
      },
      {
        "label": "35",
        "value": "35"
      },
      {
        "label": "36",
        "value": "36"
      },
      {
        "label": "36.5",
        "value": "36.5"
      },
      {
        "label": "37",
        "value": "37"
      },
      {
        "label": "38",
        "value": "38"
      },
      {
        "label": "39",
        "value": "39"
      },
      {
        "label": "40",
        "value": "40"
      },
      {
        "label": "40.5",
        "value": "40.5"
      },
      {
        "label": "41",
        "value": "41"
      },
      {
        "label": "42",
        "value": "42"
      },
      {
        "label": "43",
        "value": "43"
      },
      {
        "label": "44",
        "value": "44"
      },
      {
        "label": "45",
        "value": "45"
      },
      {
        "label": "46",
        "value": "46"
      },
      {
        "label": "47",
        "value": "47"
      },
      {
        "label": "48",
        "value": "48"
      },
      {
        "label": "50",
        "value": "50"
      },
      {
        "label": "52",
        "value": "52"
      },
      {
        "label": "54",
        "value": "54"
      },
      {
        "label": "56",
        "value": "56"
      },
      {
        "label": "58",
        "value": "58"
      },
      {
        "label": "60",
        "value": "60"
      },
      {
        "label": "7",
        "value": "7"
      },
      {
        "label": "8",
        "value": "8"
      },
      {
        "label": "9",
        "value": "9"
      },
      {
        "label": "L",
        "value": "L"
      },
      {
        "label": "L/XL",
        "value": "L/XL"
      },
      {
        "label": "M",
        "value": "M"
      },
      {
        "label": "M/L",
        "value": "M/L"
      },
      {
        "label": "ONESIZE",
        "value": "ONESIZE"
      },
      {
        "label": "PACK",
        "value": "PACK"
      },
      {
        "label": "S",
        "value": "S"
      },
      {
        "label": "S/M",
        "value": "S/M"
      },
      {
        "label": "XL",
        "value": "XL"
      },
      {
        "label": "XS",
        "value": "XS"
      },
      {
        "label": "XS/S",
        "value": "XS/S"
      },
      {
        "label": "XXL",
        "value": "XXL"
      }
    ],
    SPORTSWEAR: [
      {
        "label": "10",
        "value": "10"
      },
      {
        "label": "11",
        "value": "11"
      },
      {
        "label": "18",
        "value": "18"
      },
      {
        "label": "19",
        "value": "19"
      },
      {
        "label": "20",
        "value": "20"
      },
      {
        "label": "21",
        "value": "21"
      },
      {
        "label": "22",
        "value": "22"
      },
      {
        "label": "25",
        "value": "25"
      },
      {
        "label": "26",
        "value": "26"
      },
      {
        "label": "28",
        "value": "28"
      },
      {
        "label": "30",
        "value": "30"
      },
      {
        "label": "31",
        "value": "31"
      },
      {
        "label": "31.5",
        "value": "31.5"
      },
      {
        "label": "32",
        "value": "32"
      },
      {
        "label": "33",
        "value": "33"
      },
      {
        "label": "34",
        "value": "34"
      },
      {
        "label": "35",
        "value": "35"
      },
      {
        "label": "36",
        "value": "36"
      },
      {
        "label": "36.5",
        "value": "36.5"
      },
      {
        "label": "37",
        "value": "37"
      },
      {
        "label": "38",
        "value": "38"
      },
      {
        "label": "39",
        "value": "39"
      },
      {
        "label": "40",
        "value": "40"
      },
      {
        "label": "40.5",
        "value": "40.5"
      },
      {
        "label": "41",
        "value": "41"
      },
      {
        "label": "42",
        "value": "42"
      },
      {
        "label": "43",
        "value": "43"
      },
      {
        "label": "44",
        "value": "44"
      },
      {
        "label": "45",
        "value": "45"
      },
      {
        "label": "46",
        "value": "46"
      },
      {
        "label": "47",
        "value": "47"
      },
      {
        "label": "48",
        "value": "48"
      },
      {
        "label": "50",
        "value": "50"
      },
      {
        "label": "52",
        "value": "52"
      },
      {
        "label": "54",
        "value": "54"
      },
      {
        "label": "56",
        "value": "56"
      },
      {
        "label": "58",
        "value": "58"
      },
      {
        "label": "60",
        "value": "60"
      },
      {
        "label": "7",
        "value": "7"
      },
      {
        "label": "8",
        "value": "8"
      },
      {
        "label": "9",
        "value": "9"
      },
      {
        "label": "L",
        "value": "L"
      },
      {
        "label": "L/XL",
        "value": "L/XL"
      },
      {
        "label": "M",
        "value": "M"
      },
      {
        "label": "M/L",
        "value": "M/L"
      },
      {
        "label": "ONESIZE",
        "value": "ONESIZE"
      },
      {
        "label": "PACK",
        "value": "PACK"
      },
      {
        "label": "S",
        "value": "S"
      },
      {
        "label": "S/M",
        "value": "S/M"
      },
      {
        "label": "XL",
        "value": "XL"
      },
      {
        "label": "XS",
        "value": "XS"
      },
      {
        "label": "XS/S",
        "value": "XS/S"
      },
      {
        "label": "XXL",
        "value": "XXL"
      }
    ],
    INDIANWEAR: [
      {
        "label": "10",
        "value": "10"
      },
      {
        "label": "11",
        "value": "11"
      },
      {
        "label": "18",
        "value": "18"
      },
      {
        "label": "19",
        "value": "19"
      },
      {
        "label": "20",
        "value": "20"
      },
      {
        "label": "21",
        "value": "21"
      },
      {
        "label": "22",
        "value": "22"
      },
      {
        "label": "25",
        "value": "25"
      },
      {
        "label": "26",
        "value": "26"
      },
      {
        "label": "28",
        "value": "28"
      },
      {
        "label": "30",
        "value": "30"
      },
      {
        "label": "31",
        "value": "31"
      },
      {
        "label": "31.5",
        "value": "31.5"
      },
      {
        "label": "32",
        "value": "32"
      },
      {
        "label": "33",
        "value": "33"
      },
      {
        "label": "34",
        "value": "34"
      },
      {
        "label": "35",
        "value": "35"
      },
      {
        "label": "36",
        "value": "36"
      },
      {
        "label": "36.5",
        "value": "36.5"
      },
      {
        "label": "37",
        "value": "37"
      },
      {
        "label": "38",
        "value": "38"
      },
      {
        "label": "39",
        "value": "39"
      },
      {
        "label": "40",
        "value": "40"
      },
      {
        "label": "40.5",
        "value": "40.5"
      },
      {
        "label": "41",
        "value": "41"
      },
      {
        "label": "42",
        "value": "42"
      },
      {
        "label": "43",
        "value": "43"
      },
      {
        "label": "44",
        "value": "44"
      },
      {
        "label": "45",
        "value": "45"
      },
      {
        "label": "46",
        "value": "46"
      },
      {
        "label": "47",
        "value": "47"
      },
      {
        "label": "48",
        "value": "48"
      },
      {
        "label": "50",
        "value": "50"
      },
      {
        "label": "52",
        "value": "52"
      },
      {
        "label": "54",
        "value": "54"
      },
      {
        "label": "56",
        "value": "56"
      },
      {
        "label": "58",
        "value": "58"
      },
      {
        "label": "60",
        "value": "60"
      },
      {
        "label": "7",
        "value": "7"
      },
      {
        "label": "8",
        "value": "8"
      },
      {
        "label": "9",
        "value": "9"
      },
      {
        "label": "L",
        "value": "L"
      },
      {
        "label": "L/XL",
        "value": "L/XL"
      },
      {
        "label": "M",
        "value": "M"
      },
      {
        "label": "M/L",
        "value": "M/L"
      },
      {
        "label": "ONESIZE",
        "value": "ONESIZE"
      },
      {
        "label": "PACK",
        "value": "PACK"
      },
      {
        "label": "S",
        "value": "S"
      },
      {
        "label": "S/M",
        "value": "S/M"
      },
      {
        "label": "XL",
        "value": "XL"
      },
      {
        "label": "XS",
        "value": "XS"
      },
      {
        "label": "XS/S",
        "value": "XS/S"
      },
      {
        "label": "XXL",
        "value": "XXL"
      }
    ],
    SLEEPWEAR: [
      {
        "label": "10",
        "value": "10"
      },
      {
        "label": "11",
        "value": "11"
      },
      {
        "label": "18",
        "value": "18"
      },
      {
        "label": "19",
        "value": "19"
      },
      {
        "label": "20",
        "value": "20"
      },
      {
        "label": "21",
        "value": "21"
      },
      {
        "label": "22",
        "value": "22"
      },
      {
        "label": "25",
        "value": "25"
      },
      {
        "label": "26",
        "value": "26"
      },
      {
        "label": "28",
        "value": "28"
      },
      {
        "label": "30",
        "value": "30"
      },
      {
        "label": "31",
        "value": "31"
      },
      {
        "label": "31.5",
        "value": "31.5"
      },
      {
        "label": "32",
        "value": "32"
      },
      {
        "label": "33",
        "value": "33"
      },
      {
        "label": "34",
        "value": "34"
      },
      {
        "label": "35",
        "value": "35"
      },
      {
        "label": "36",
        "value": "36"
      },
      {
        "label": "36.5",
        "value": "36.5"
      },
      {
        "label": "37",
        "value": "37"
      },
      {
        "label": "38",
        "value": "38"
      },
      {
        "label": "39",
        "value": "39"
      },
      {
        "label": "40",
        "value": "40"
      },
      {
        "label": "40.5",
        "value": "40.5"
      },
      {
        "label": "41",
        "value": "41"
      },
      {
        "label": "42",
        "value": "42"
      },
      {
        "label": "43",
        "value": "43"
      },
      {
        "label": "44",
        "value": "44"
      },
      {
        "label": "45",
        "value": "45"
      },
      {
        "label": "46",
        "value": "46"
      },
      {
        "label": "47",
        "value": "47"
      },
      {
        "label": "48",
        "value": "48"
      },
      {
        "label": "50",
        "value": "50"
      },
      {
        "label": "52",
        "value": "52"
      },
      {
        "label": "54",
        "value": "54"
      },
      {
        "label": "56",
        "value": "56"
      },
      {
        "label": "58",
        "value": "58"
      },
      {
        "label": "60",
        "value": "60"
      },
      {
        "label": "7",
        "value": "7"
      },
      {
        "label": "8",
        "value": "8"
      },
      {
        "label": "9",
        "value": "9"
      },
      {
        "label": "L",
        "value": "L"
      },
      {
        "label": "L/XL",
        "value": "L/XL"
      },
      {
        "label": "M",
        "value": "M"
      },
      {
        "label": "M/L",
        "value": "M/L"
      },
      {
        "label": "ONESIZE",
        "value": "ONESIZE"
      },
      {
        "label": "PACK",
        "value": "PACK"
      },
      {
        "label": "S",
        "value": "S"
      },
      {
        "label": "S/M",
        "value": "S/M"
      },
      {
        "label": "XL",
        "value": "XL"
      },
      {
        "label": "XS",
        "value": "XS"
      },
      {
        "label": "XS/S",
        "value": "XS/S"
      },
      {
        "label": "XXL",
        "value": "XXL"
      }
    ],
    ACCESSORIES: [
      {
        "label": "10",
        "value": "10"
      },
      {
        "label": "11",
        "value": "11"
      },
      {
        "label": "18",
        "value": "18"
      },
      {
        "label": "19",
        "value": "19"
      },
      {
        "label": "20",
        "value": "20"
      },
      {
        "label": "21",
        "value": "21"
      },
      {
        "label": "22",
        "value": "22"
      },
      {
        "label": "25",
        "value": "25"
      },
      {
        "label": "26",
        "value": "26"
      },
      {
        "label": "28",
        "value": "28"
      },
      {
        "label": "30",
        "value": "30"
      },
      {
        "label": "31",
        "value": "31"
      },
      {
        "label": "31.5",
        "value": "31.5"
      },
      {
        "label": "32",
        "value": "32"
      },
      {
        "label": "33",
        "value": "33"
      },
      {
        "label": "34",
        "value": "34"
      },
      {
        "label": "35",
        "value": "35"
      },
      {
        "label": "36",
        "value": "36"
      },
      {
        "label": "36.5",
        "value": "36.5"
      },
      {
        "label": "37",
        "value": "37"
      },
      {
        "label": "38",
        "value": "38"
      },
      {
        "label": "39",
        "value": "39"
      },
      {
        "label": "40",
        "value": "40"
      },
      {
        "label": "40.5",
        "value": "40.5"
      },
      {
        "label": "41",
        "value": "41"
      },
      {
        "label": "42",
        "value": "42"
      },
      {
        "label": "43",
        "value": "43"
      },
      {
        "label": "44",
        "value": "44"
      },
      {
        "label": "45",
        "value": "45"
      },
      {
        "label": "46",
        "value": "46"
      },
      {
        "label": "47",
        "value": "47"
      },
      {
        "label": "48",
        "value": "48"
      },
      {
        "label": "50",
        "value": "50"
      },
      {
        "label": "52",
        "value": "52"
      },
      {
        "label": "54",
        "value": "54"
      },
      {
        "label": "56",
        "value": "56"
      },
      {
        "label": "58",
        "value": "58"
      },
      {
        "label": "60",
        "value": "60"
      },
      {
        "label": "7",
        "value": "7"
      },
      {
        "label": "8",
        "value": "8"
      },
      {
        "label": "9",
        "value": "9"
      },
      {
        "label": "L",
        "value": "L"
      },
      {
        "label": "L/XL",
        "value": "L/XL"
      },
      {
        "label": "M",
        "value": "M"
      },
      {
        "label": "M/L",
        "value": "M/L"
      },
      {
        "label": "ONESIZE",
        "value": "ONESIZE"
      },
      {
        "label": "PACK",
        "value": "PACK"
      },
      {
        "label": "S",
        "value": "S"
      },
      {
        "label": "S/M",
        "value": "S/M"
      },
      {
        "label": "XL",
        "value": "XL"
      },
      {
        "label": "XS",
        "value": "XS"
      },
      {
        "label": "XS/S",
        "value": "XS/S"
      },
      {
        "label": "XXL",
        "value": "XXL"
      }
    ],
    PLUSSIZE: [
      {
        "label": "2XL",
        "value": "2XL"
      },
      {
        "label": "3XL",
        "value": "3XL"
      },
      {
        "label": "4XL",
        "value": "4XL"
      },
      {
        "label": "5XL",
        "value": "5XL"
      },
    ]
  },
  WOMEN: {
    INDIANWEAR: [
      {
        "label": "22",
        "value": "22"
      },
      {
        "label": "23",
        "value": "23"
      },
      {
        "label": "24",
        "value": "24"
      },
      {
        "label": "25",
        "value": "25"
      },
      {
        "label": "26",
        "value": "26"
      },
      {
        "label": "27",
        "value": "27"
      },
      {
        "label": "28",
        "value": "28"
      },
      {
        "label": "29",
        "value": "29"
      },
      {
        "label": "29.5",
        "value": "29.5"
      },
      {
        "label": "30",
        "value": "30"
      },
      {
        "label": "31",
        "value": "31"
      },
      {
        "label": "31.5",
        "value": "31.5"
      },
      {
        "label": "32",
        "value": "32"
      },
      {
        "label": "33",
        "value": "33"
      },
      {
        "label": "34",
        "value": "34"
      },
      {
        "label": "34/57",
        "value": "34/57"
      },
      {
        "label": "34/59",
        "value": "34/59"
      },
      {
        "label": "34/60",
        "value": "34/60"
      },
      {
        "label": "34/61",
        "value": "34/61"
      },
      {
        "label": "34/64",
        "value": "34/64"
      },
      {
        "label": "35",
        "value": "35"
      },
      {
        "label": "36",
        "value": "36"
      },
      {
        "label": "36/57",
        "value": "36/57"
      },
      {
        "label": "36/59",
        "value": "36/59"
      },
      {
        "label": "36/60",
        "value": "36/60"
      },
      {
        "label": "36/61",
        "value": "36/61"
      },
      {
        "label": "36/62",
        "value": "36/62"
      },
      {
        "label": "36/64",
        "value": "36/64"
      },
      {
        "label": "37",
        "value": "37"
      },
      {
        "label": "38",
        "value": "38"
      },
      {
        "label": "38/57",
        "value": "38/57"
      },
      {
        "label": "38/59",
        "value": "38/59"
      },
      {
        "label": "38/60",
        "value": "38/60"
      },
      {
        "label": "38/61",
        "value": "38/61"
      },
      {
        "label": "38/62",
        "value": "38/62"
      },
      {
        "label": "38/64",
        "value": "38/64"
      },
      {
        "label": "39",
        "value": "39"
      },
      {
        "label": "3XL",
        "value": "3XL"
      },
      {
        "label": "3XS",
        "value": "3XS"
      },
      {
        "label": "40",
        "value": "40"
      },
      {
        "label": "40.5",
        "value": "40.5"
      },
      {
        "label": "40/57",
        "value": "40/57"
      },
      {
        "label": "40/59",
        "value": "40/59"
      },
      {
        "label": "40/60",
        "value": "40/60"
      },
      {
        "label": "42/61",
        "value": "42/61"
      },
      {
        "label": "42/62",
        "value": "42/62"
      },
      {
        "label": "42/64",
        "value": "42/64"
      },
      {
        "label": "41",
        "value": "41"
      },
      {
        "label": "42",
        "value": "42"
      },
      {
        "label": "42/57",
        "value": "42/57"
      },
      {
        "label": "42/59",
        "value": "42/59"
      },
      {
        "label": "42/60",
        "value": "42/60"
      },
      {
        "label": "44/61",
        "value": "44/61"
      },
      {
        "label": "44/62",
        "value": "44/62"
      },
      {
        "label": "44/64",
        "value": "44/64"
      },
      {
        "label": "45",
        "value": "45"
      },
      {
        "label": "46",
        "value": "46"
      },
      {
        "label": "48",
        "value": "48"
      },
      {
        "label": "49",
        "value": "49"
      },
      {
        "label": "4XL",
        "value": "4XL"
      },
      {
        "label": "50",
        "value": "50"
      },
      {
        "label": "52",
        "value": "52"
      },
      {
        "label": "5XL",
        "value": "5XL"
      },
      {
        "label": "6XL",
        "value": "6XL"
      },
      {
        "label": "7XL",
        "value": "7XL"
      },
      {
        "label": "8XL",
        "value": "8XL"
      },
      {
        "label": "L",
        "value": "L"
      },
      {
        "label": "L/XL",
        "value": "L/XL"
      },
      {
        "label": "M",
        "value": "M"
      },
      {
        "label": "M/L",
        "value": "M/L"
      },
      {
        "label": "ONESIZE",
        "value": "ONESIZE"
      },
      {
        "label": "S",
        "value": "S"
      },
      {
        "label": "S/M",
        "value": "S/M"
      },
      {
        "label": "XL",
        "value": "XL"
      },
      {
        "label": "XL/XXL",
        "value": "XL/XXL"
      },
      {
        "label": "XS",
        "value": "XS"
      },
      {
        "label": "XS/M",
        "value": "XS/M"
      },
      {
        "label": "XS/S",
        "value": "XS/S"
      },
      {
        "label": "XXL",
        "value": "XXL"
      },
      {
        "label": "XXS",
        "value": "XXS"
      },
      {
        "label": "XXS/XS",
        "value": "XXS/XS"
      },
      {
        "label": "32/37",
        "value": "32/37"
      },
      {
        "label": "32/40",
        "value": "32/40"
      },
      {
        "label": "32/41",
        "value": "32/41"
      },
      {
        "label": "34/32",
        "value": "34/32"
      },
      {
        "label": "34/34",
        "value": "34/34"
      },
      {
        "label": "34/36",
        "value": "34/36"
      },
      {
        "label": "34/37",
        "value": "34/37"
      },
      {
        "label": "34/38",
        "value": "34/38"
      },
      {
        "label": "34/40",
        "value": "34/40"
      },
      {
        "label": "34/41",
        "value": "34/41"
      },
      {
        "label": "34/42",
        "value": "34/42"
      },
      {
        "label": "34/43",
        "value": "34/43"
      },
      {
        "label": "34/44",
        "value": "34/44"
      },
      {
        "label": "34/45",
        "value": "34/45"
      },
      {
        "label": "34/47",
        "value": "34/47"
      },
      {
        "label": "34/52",
        "value": "34/52"
      },
      {
        "label": "34/55",
        "value": "34/55"
      },
      {
        "label": "34/56",
        "value": "34/56"
      },
      {
        "label": "36/32",
        "value": "36/32"
      },
      {
        "label": "36/34",
        "value": "36/34"
      },
      {
        "label": "36/36",
        "value": "36/36"
      },
      {
        "label": "36/37",
        "value": "36/37"
      },
      {
        "label": "36/38",
        "value": "36/38"
      },
      {
        "label": "36/40",
        "value": "36/40"
      },
      {
        "label": "36/41",
        "value": "36/41"
      },
      {
        "label": "36/42",
        "value": "36/42"
      },
      {
        "label": "36/43",
        "value": "36/43"
      },
      {
        "label": "36/44",
        "value": "36/44"
      },
      {
        "label": "36/45",
        "value": "36/45"
      },
      {
        "label": "36/46",
        "value": "36/46"
      },
      {
        "label": "36/47",
        "value": "36/47"
      },
      {
        "label": "36/52",
        "value": "36/52"
      },
      {
        "label": "36/55",
        "value": "36/55"
      },
      {
        "label": "36/56",
        "value": "36/56"
      },
      {
        "label": "38/32",
        "value": "38/32"
      },
      {
        "label": "38/34",
        "value": "38/34"
      },
      {
        "label": "38/36",
        "value": "38/36"
      },
      {
        "label": "38/37",
        "value": "38/37"
      },
      {
        "label": "38/38",
        "value": "38/38"
      },
      {
        "label": "38/40",
        "value": "38/40"
      },
      {
        "label": "38/41",
        "value": "38/41"
      },
      {
        "label": "38/42",
        "value": "38/42"
      },
      {
        "label": "38/43",
        "value": "38/43"
      },
      {
        "label": "38/44",
        "value": "38/44"
      },
      {
        "label": "38/45",
        "value": "38/45"
      },
      {
        "label": "38/46",
        "value": "38/46"
      },
      {
        "label": "38/47",
        "value": "38/47"
      },
      {
        "label": "38/52",
        "value": "38/52"
      },
      {
        "label": "38/55",
        "value": "38/55"
      },
      {
        "label": "38/56",
        "value": "38/56"
      },
      {
        "label": "40/32",
        "value": "40/32"
      },
      {
        "label": "40/34",
        "value": "40/34"
      },
      {
        "label": "40/36",
        "value": "40/36"
      },
      {
        "label": "40/37",
        "value": "40/37"
      },
      {
        "label": "40/38",
        "value": "40/38"
      },
      {
        "label": "40/40",
        "value": "40/40"
      },
      {
        "label": "40/41",
        "value": "40/41"
      },
      {
        "label": "40/42",
        "value": "40/42"
      },
      {
        "label": "40/43",
        "value": "40/43"
      },
      {
        "label": "40/44",
        "value": "40/44"
      },
      {
        "label": "40/45",
        "value": "40/45"
      },
      {
        "label": "40/46",
        "value": "40/46"
      },
      {
        "label": "40/47",
        "value": "40/47"
      },
      {
        "label": "40/52",
        "value": "40/52"
      },
      {
        "label": "40/55",
        "value": "40/55"
      },
      {
        "label": "40/56",
        "value": "40/56"
      },
      {
        "label": "40/61",
        "value": "40/61"
      },
      {
        "label": "42/32",
        "value": "42/32"
      },
      {
        "label": "42/34",
        "value": "42/34"
      },
      {
        "label": "42/36",
        "value": "42/36"
      },
      {
        "label": "42/37",
        "value": "42/37"
      },
      {
        "label": "42/38",
        "value": "42/38"
      },
      {
        "label": "42/40",
        "value": "42/40"
      },
      {
        "label": "42/41",
        "value": "42/41"
      },
      {
        "label": "42/42",
        "value": "42/42"
      },
      {
        "label": "42/43",
        "value": "42/43"
      },
      {
        "label": "42/44",
        "value": "42/44"
      },
      {
        "label": "42/45",
        "value": "42/45"
      },
      {
        "label": "42/46",
        "value": "42/46"
      },
      {
        "label": "42/47",
        "value": "42/47"
      },
      {
        "label": "42/48",
        "value": "42/48"
      },
      {
        "label": "42/49",
        "value": "42/49"
      },
      {
        "label": "42/50",
        "value": "42/50"
      },
      {
        "label": "42/51",
        "value": "42/51"
      },
      {
        "label": "42/52",
        "value": "42/52"
      },
      {
        "label": "42/53",
        "value": "42/53"
      },
      {
        "label": "42/54",
        "value": "42/54"
      },
      {
        "label": "42/55",
        "value": "42/55"
      },
      {
        "label": "42/56",
        "value": "42/56"
      },
      {
        "label": "43",
        "value": "43"
      },
      {
        "label": "44",
        "value": "44"
      },
      {
        "label": "44/32",
        "value": "44/32"
      },
      {
        "label": "44/34",
        "value": "44/34"
      },
      {
        "label": "44/36",
        "value": "44/36"
      },
      {
        "label": "44/37",
        "value": "44/37"
      },
      {
        "label": "44/38",
        "value": "44/38"
      },
      {
        "label": "44/40",
        "value": "44/40"
      },
      {
        "label": "44/41",
        "value": "44/41"
      },
      {
        "label": "44/42",
        "value": "44/42"
      },
      {
        "label": "44/43",
        "value": "44/43"
      },
      {
        "label": "44/44",
        "value": "44/44"
      },
      {
        "label": "44/45",
        "value": "44/45"
      },
      {
        "label": "44/46",
        "value": "44/46"
      },
      {
        "label": "44/47",
        "value": "44/47"
      },
      {
        "label": "44/48",
        "value": "44/48"
      },
      {
        "label": "44/49",
        "value": "44/49"
      },
      {
        "label": "44/50",
        "value": "44/50"
      },
      {
        "label": "44/51",
        "value": "44/51"
      },
      {
        "label": "44/52",
        "value": "44/52"
      },
      {
        "label": "44/53",
        "value": "44/53"
      },
      {
        "label": "44/54",
        "value": "44/54"
      },
      {
        "label": "44/55",
        "value": "44/55"
      },
      {
        "label": "44/56",
        "value": "44/56"
      },
      {
        "label": "44/57",
        "value": "44/57"
      },
      {
        "label": "44/59",
        "value": "44/59"
      },
      {
        "label": "44/60",
        "value": "44/60"
      }
    ],
    WESTERNWEAR: [
      {
        "label": "22",
        "value": "22"
      },
      {
        "label": "23",
        "value": "23"
      },
      {
        "label": "24",
        "value": "24"
      },
      {
        "label": "25",
        "value": "25"
      },
      {
        "label": "26",
        "value": "26"
      },
      {
        "label": "27",
        "value": "27"
      },
      {
        "label": "28",
        "value": "28"
      },
      {
        "label": "29",
        "value": "29"
      },
      {
        "label": "29.5",
        "value": "29.5"
      },
      {
        "label": "30",
        "value": "30"
      },
      {
        "label": "31",
        "value": "31"
      },
      {
        "label": "31.5",
        "value": "31.5"
      },
      {
        "label": "32",
        "value": "32"
      },
      {
        "label": "33",
        "value": "33"
      },
      {
        "label": "34",
        "value": "34"
      },
      {
        "label": "34/57",
        "value": "34/57"
      },
      {
        "label": "34/59",
        "value": "34/59"
      },
      {
        "label": "34/60",
        "value": "34/60"
      },
      {
        "label": "34/61",
        "value": "34/61"
      },
      {
        "label": "34/64",
        "value": "34/64"
      },
      {
        "label": "35",
        "value": "35"
      },
      {
        "label": "36",
        "value": "36"
      },
      {
        "label": "36/57",
        "value": "36/57"
      },
      {
        "label": "36/59",
        "value": "36/59"
      },
      {
        "label": "36/60",
        "value": "36/60"
      },
      {
        "label": "36/61",
        "value": "36/61"
      },
      {
        "label": "36/62",
        "value": "36/62"
      },
      {
        "label": "36/64",
        "value": "36/64"
      },
      {
        "label": "37",
        "value": "37"
      },
      {
        "label": "38",
        "value": "38"
      },
      {
        "label": "38/57",
        "value": "38/57"
      },
      {
        "label": "38/59",
        "value": "38/59"
      },
      {
        "label": "38/60",
        "value": "38/60"
      },
      {
        "label": "38/61",
        "value": "38/61"
      },
      {
        "label": "38/62",
        "value": "38/62"
      },
      {
        "label": "38/64",
        "value": "38/64"
      },
      {
        "label": "39",
        "value": "39"
      },
      {
        "label": "3XL",
        "value": "3XL"
      },
      {
        "label": "3XS",
        "value": "3XS"
      },
      {
        "label": "40",
        "value": "40"
      },
      {
        "label": "40.5",
        "value": "40.5"
      },
      {
        "label": "40/57",
        "value": "40/57"
      },
      {
        "label": "40/59",
        "value": "40/59"
      },
      {
        "label": "40/60",
        "value": "40/60"
      },
      {
        "label": "42/61",
        "value": "42/61"
      },
      {
        "label": "42/62",
        "value": "42/62"
      },
      {
        "label": "42/64",
        "value": "42/64"
      },
      {
        "label": "41",
        "value": "41"
      },
      {
        "label": "42",
        "value": "42"
      },
      {
        "label": "42/57",
        "value": "42/57"
      },
      {
        "label": "42/59",
        "value": "42/59"
      },
      {
        "label": "42/60",
        "value": "42/60"
      },
      {
        "label": "44/61",
        "value": "44/61"
      },
      {
        "label": "44/62",
        "value": "44/62"
      },
      {
        "label": "44/64",
        "value": "44/64"
      },
      {
        "label": "45",
        "value": "45"
      },
      {
        "label": "46",
        "value": "46"
      },
      {
        "label": "48",
        "value": "48"
      },
      {
        "label": "49",
        "value": "49"
      },
      {
        "label": "4XL",
        "value": "4XL"
      },
      {
        "label": "50",
        "value": "50"
      },
      {
        "label": "52",
        "value": "52"
      },
      {
        "label": "5XL",
        "value": "5XL"
      },
      {
        "label": "6XL",
        "value": "6XL"
      },
      {
        "label": "7XL",
        "value": "7XL"
      },
      {
        "label": "8XL",
        "value": "8XL"
      },
      {
        "label": "L",
        "value": "L"
      },
      {
        "label": "L/XL",
        "value": "L/XL"
      },
      {
        "label": "M",
        "value": "M"
      },
      {
        "label": "M/L",
        "value": "M/L"
      },
      {
        "label": "ONESIZE",
        "value": "ONESIZE"
      },
      {
        "label": "S",
        "value": "S"
      },
      {
        "label": "S/M",
        "value": "S/M"
      },
      {
        "label": "XL",
        "value": "XL"
      },
      {
        "label": "XL/XXL",
        "value": "XL/XXL"
      },
      {
        "label": "XS",
        "value": "XS"
      },
      {
        "label": "XS/M",
        "value": "XS/M"
      },
      {
        "label": "XS/S",
        "value": "XS/S"
      },
      {
        "label": "XXL",
        "value": "XXL"
      },
      {
        "label": "XXS",
        "value": "XXS"
      },
      {
        "label": "XXS/XS",
        "value": "XXS/XS"
      },
      {
        "label": "32/37",
        "value": "32/37"
      },
      {
        "label": "32/40",
        "value": "32/40"
      },
      {
        "label": "32/41",
        "value": "32/41"
      },
      {
        "label": "34/32",
        "value": "34/32"
      },
      {
        "label": "34/34",
        "value": "34/34"
      },
      {
        "label": "34/36",
        "value": "34/36"
      },
      {
        "label": "34/37",
        "value": "34/37"
      },
      {
        "label": "34/38",
        "value": "34/38"
      },
      {
        "label": "34/40",
        "value": "34/40"
      },
      {
        "label": "34/41",
        "value": "34/41"
      },
      {
        "label": "34/42",
        "value": "34/42"
      },
      {
        "label": "34/43",
        "value": "34/43"
      },
      {
        "label": "34/44",
        "value": "34/44"
      },
      {
        "label": "34/45",
        "value": "34/45"
      },
      {
        "label": "34/47",
        "value": "34/47"
      },
      {
        "label": "34/52",
        "value": "34/52"
      },
      {
        "label": "34/55",
        "value": "34/55"
      },
      {
        "label": "34/56",
        "value": "34/56"
      },
      {
        "label": "36/32",
        "value": "36/32"
      },
      {
        "label": "36/34",
        "value": "36/34"
      },
      {
        "label": "36/36",
        "value": "36/36"
      },
      {
        "label": "36/37",
        "value": "36/37"
      },
      {
        "label": "36/38",
        "value": "36/38"
      },
      {
        "label": "36/40",
        "value": "36/40"
      },
      {
        "label": "36/41",
        "value": "36/41"
      },
      {
        "label": "36/42",
        "value": "36/42"
      },
      {
        "label": "36/43",
        "value": "36/43"
      },
      {
        "label": "36/44",
        "value": "36/44"
      },
      {
        "label": "36/45",
        "value": "36/45"
      },
      {
        "label": "36/46",
        "value": "36/46"
      },
      {
        "label": "36/47",
        "value": "36/47"
      },
      {
        "label": "36/52",
        "value": "36/52"
      },
      {
        "label": "36/55",
        "value": "36/55"
      },
      {
        "label": "36/56",
        "value": "36/56"
      },
      {
        "label": "38/32",
        "value": "38/32"
      },
      {
        "label": "38/34",
        "value": "38/34"
      },
      {
        "label": "38/36",
        "value": "38/36"
      },
      {
        "label": "38/37",
        "value": "38/37"
      },
      {
        "label": "38/38",
        "value": "38/38"
      },
      {
        "label": "38/40",
        "value": "38/40"
      },
      {
        "label": "38/41",
        "value": "38/41"
      },
      {
        "label": "38/42",
        "value": "38/42"
      },
      {
        "label": "38/43",
        "value": "38/43"
      },
      {
        "label": "38/44",
        "value": "38/44"
      },
      {
        "label": "38/45",
        "value": "38/45"
      },
      {
        "label": "38/46",
        "value": "38/46"
      },
      {
        "label": "38/47",
        "value": "38/47"
      },
      {
        "label": "38/52",
        "value": "38/52"
      },
      {
        "label": "38/55",
        "value": "38/55"
      },
      {
        "label": "38/56",
        "value": "38/56"
      },
      {
        "label": "40/32",
        "value": "40/32"
      },
      {
        "label": "40/34",
        "value": "40/34"
      },
      {
        "label": "40/36",
        "value": "40/36"
      },
      {
        "label": "40/37",
        "value": "40/37"
      },
      {
        "label": "40/38",
        "value": "40/38"
      },
      {
        "label": "40/40",
        "value": "40/40"
      },
      {
        "label": "40/41",
        "value": "40/41"
      },
      {
        "label": "40/42",
        "value": "40/42"
      },
      {
        "label": "40/43",
        "value": "40/43"
      },
      {
        "label": "40/44",
        "value": "40/44"
      },
      {
        "label": "40/45",
        "value": "40/45"
      },
      {
        "label": "40/46",
        "value": "40/46"
      },
      {
        "label": "40/47",
        "value": "40/47"
      },
      {
        "label": "40/52",
        "value": "40/52"
      },
      {
        "label": "40/55",
        "value": "40/55"
      },
      {
        "label": "40/56",
        "value": "40/56"
      },
      {
        "label": "40/61",
        "value": "40/61"
      },
      {
        "label": "42/32",
        "value": "42/32"
      },
      {
        "label": "42/34",
        "value": "42/34"
      },
      {
        "label": "42/36",
        "value": "42/36"
      },
      {
        "label": "42/37",
        "value": "42/37"
      },
      {
        "label": "42/38",
        "value": "42/38"
      },
      {
        "label": "42/40",
        "value": "42/40"
      },
      {
        "label": "42/41",
        "value": "42/41"
      },
      {
        "label": "42/42",
        "value": "42/42"
      },
      {
        "label": "42/43",
        "value": "42/43"
      },
      {
        "label": "42/44",
        "value": "42/44"
      },
      {
        "label": "42/45",
        "value": "42/45"
      },
      {
        "label": "42/46",
        "value": "42/46"
      },
      {
        "label": "42/47",
        "value": "42/47"
      },
      {
        "label": "42/48",
        "value": "42/48"
      },
      {
        "label": "42/49",
        "value": "42/49"
      },
      {
        "label": "42/50",
        "value": "42/50"
      },
      {
        "label": "42/51",
        "value": "42/51"
      },
      {
        "label": "42/52",
        "value": "42/52"
      },
      {
        "label": "42/53",
        "value": "42/53"
      },
      {
        "label": "42/54",
        "value": "42/54"
      },
      {
        "label": "42/55",
        "value": "42/55"
      },
      {
        "label": "42/56",
        "value": "42/56"
      },
      {
        "label": "43",
        "value": "43"
      },
      {
        "label": "44",
        "value": "44"
      },
      {
        "label": "44/32",
        "value": "44/32"
      },
      {
        "label": "44/34",
        "value": "44/34"
      },
      {
        "label": "44/36",
        "value": "44/36"
      },
      {
        "label": "44/37",
        "value": "44/37"
      },
      {
        "label": "44/38",
        "value": "44/38"
      },
      {
        "label": "44/40",
        "value": "44/40"
      },
      {
        "label": "44/41",
        "value": "44/41"
      },
      {
        "label": "44/42",
        "value": "44/42"
      },
      {
        "label": "44/43",
        "value": "44/43"
      },
      {
        "label": "44/44",
        "value": "44/44"
      },
      {
        "label": "44/45",
        "value": "44/45"
      },
      {
        "label": "44/46",
        "value": "44/46"
      },
      {
        "label": "44/47",
        "value": "44/47"
      },
      {
        "label": "44/48",
        "value": "44/48"
      },
      {
        "label": "44/49",
        "value": "44/49"
      },
      {
        "label": "44/50",
        "value": "44/50"
      },
      {
        "label": "44/51",
        "value": "44/51"
      },
      {
        "label": "44/52",
        "value": "44/52"
      },
      {
        "label": "44/53",
        "value": "44/53"
      },
      {
        "label": "44/54",
        "value": "44/54"
      },
      {
        "label": "44/55",
        "value": "44/55"
      },
      {
        "label": "44/56",
        "value": "44/56"
      },
      {
        "label": "44/57",
        "value": "44/57"
      },
      {
        "label": "44/59",
        "value": "44/59"
      },
      {
        "label": "44/60",
        "value": "44/60"
      }
    ],
    SLEEPWEAR: [
      {
        "label": "22",
        "value": "22"
      },
      {
        "label": "23",
        "value": "23"
      },
      {
        "label": "24",
        "value": "24"
      },
      {
        "label": "25",
        "value": "25"
      },
      {
        "label": "26",
        "value": "26"
      },
      {
        "label": "27",
        "value": "27"
      },
      {
        "label": "28",
        "value": "28"
      },
      {
        "label": "29",
        "value": "29"
      },
      {
        "label": "29.5",
        "value": "29.5"
      },
      {
        "label": "30",
        "value": "30"
      },
      {
        "label": "31",
        "value": "31"
      },
      {
        "label": "31.5",
        "value": "31.5"
      },
      {
        "label": "32",
        "value": "32"
      },
      {
        "label": "33",
        "value": "33"
      },
      {
        "label": "34",
        "value": "34"
      },
      {
        "label": "34/57",
        "value": "34/57"
      },
      {
        "label": "34/59",
        "value": "34/59"
      },
      {
        "label": "34/60",
        "value": "34/60"
      },
      {
        "label": "34/61",
        "value": "34/61"
      },
      {
        "label": "34/64",
        "value": "34/64"
      },
      {
        "label": "35",
        "value": "35"
      },
      {
        "label": "36",
        "value": "36"
      },
      {
        "label": "36/57",
        "value": "36/57"
      },
      {
        "label": "36/59",
        "value": "36/59"
      },
      {
        "label": "36/60",
        "value": "36/60"
      },
      {
        "label": "36/61",
        "value": "36/61"
      },
      {
        "label": "36/62",
        "value": "36/62"
      },
      {
        "label": "36/64",
        "value": "36/64"
      },
      {
        "label": "37",
        "value": "37"
      },
      {
        "label": "38",
        "value": "38"
      },
      {
        "label": "38/57",
        "value": "38/57"
      },
      {
        "label": "38/59",
        "value": "38/59"
      },
      {
        "label": "38/60",
        "value": "38/60"
      },
      {
        "label": "38/61",
        "value": "38/61"
      },
      {
        "label": "38/62",
        "value": "38/62"
      },
      {
        "label": "38/64",
        "value": "38/64"
      },
      {
        "label": "39",
        "value": "39"
      },
      {
        "label": "3XL",
        "value": "3XL"
      },
      {
        "label": "3XS",
        "value": "3XS"
      },
      {
        "label": "40",
        "value": "40"
      },
      {
        "label": "40.5",
        "value": "40.5"
      },
      {
        "label": "40/57",
        "value": "40/57"
      },
      {
        "label": "40/59",
        "value": "40/59"
      },
      {
        "label": "40/60",
        "value": "40/60"
      },
      {
        "label": "42/61",
        "value": "42/61"
      },
      {
        "label": "42/62",
        "value": "42/62"
      },
      {
        "label": "42/64",
        "value": "42/64"
      },
      {
        "label": "41",
        "value": "41"
      },
      {
        "label": "42",
        "value": "42"
      },
      {
        "label": "42/57",
        "value": "42/57"
      },
      {
        "label": "42/59",
        "value": "42/59"
      },
      {
        "label": "42/60",
        "value": "42/60"
      },
      {
        "label": "44/61",
        "value": "44/61"
      },
      {
        "label": "44/62",
        "value": "44/62"
      },
      {
        "label": "44/64",
        "value": "44/64"
      },
      {
        "label": "45",
        "value": "45"
      },
      {
        "label": "46",
        "value": "46"
      },
      {
        "label": "48",
        "value": "48"
      },
      {
        "label": "49",
        "value": "49"
      },
      {
        "label": "4XL",
        "value": "4XL"
      },
      {
        "label": "50",
        "value": "50"
      },
      {
        "label": "52",
        "value": "52"
      },
      {
        "label": "5XL",
        "value": "5XL"
      },
      {
        "label": "6XL",
        "value": "6XL"
      },
      {
        "label": "7XL",
        "value": "7XL"
      },
      {
        "label": "8XL",
        "value": "8XL"
      },
      {
        "label": "L",
        "value": "L"
      },
      {
        "label": "L/XL",
        "value": "L/XL"
      },
      {
        "label": "M",
        "value": "M"
      },
      {
        "label": "M/L",
        "value": "M/L"
      },
      {
        "label": "ONESIZE",
        "value": "ONESIZE"
      },
      {
        "label": "S",
        "value": "S"
      },
      {
        "label": "S/M",
        "value": "S/M"
      },
      {
        "label": "XL",
        "value": "XL"
      },
      {
        "label": "XL/XXL",
        "value": "XL/XXL"
      },
      {
        "label": "XS",
        "value": "XS"
      },
      {
        "label": "XS/M",
        "value": "XS/M"
      },
      {
        "label": "XS/S",
        "value": "XS/S"
      },
      {
        "label": "XXL",
        "value": "XXL"
      },
      {
        "label": "XXS",
        "value": "XXS"
      },
      {
        "label": "XXS/XS",
        "value": "XXS/XS"
      },
      {
        "label": "32/37",
        "value": "32/37"
      },
      {
        "label": "32/40",
        "value": "32/40"
      },
      {
        "label": "32/41",
        "value": "32/41"
      },
      {
        "label": "34/32",
        "value": "34/32"
      },
      {
        "label": "34/34",
        "value": "34/34"
      },
      {
        "label": "34/36",
        "value": "34/36"
      },
      {
        "label": "34/37",
        "value": "34/37"
      },
      {
        "label": "34/38",
        "value": "34/38"
      },
      {
        "label": "34/40",
        "value": "34/40"
      },
      {
        "label": "34/41",
        "value": "34/41"
      },
      {
        "label": "34/42",
        "value": "34/42"
      },
      {
        "label": "34/43",
        "value": "34/43"
      },
      {
        "label": "34/44",
        "value": "34/44"
      },
      {
        "label": "34/45",
        "value": "34/45"
      },
      {
        "label": "34/47",
        "value": "34/47"
      },
      {
        "label": "34/52",
        "value": "34/52"
      },
      {
        "label": "34/55",
        "value": "34/55"
      },
      {
        "label": "34/56",
        "value": "34/56"
      },
      {
        "label": "36/32",
        "value": "36/32"
      },
      {
        "label": "36/34",
        "value": "36/34"
      },
      {
        "label": "36/36",
        "value": "36/36"
      },
      {
        "label": "36/37",
        "value": "36/37"
      },
      {
        "label": "36/38",
        "value": "36/38"
      },
      {
        "label": "36/40",
        "value": "36/40"
      },
      {
        "label": "36/41",
        "value": "36/41"
      },
      {
        "label": "36/42",
        "value": "36/42"
      },
      {
        "label": "36/43",
        "value": "36/43"
      },
      {
        "label": "36/44",
        "value": "36/44"
      },
      {
        "label": "36/45",
        "value": "36/45"
      },
      {
        "label": "36/46",
        "value": "36/46"
      },
      {
        "label": "36/47",
        "value": "36/47"
      },
      {
        "label": "36/52",
        "value": "36/52"
      },
      {
        "label": "36/55",
        "value": "36/55"
      },
      {
        "label": "36/56",
        "value": "36/56"
      },
      {
        "label": "38/32",
        "value": "38/32"
      },
      {
        "label": "38/34",
        "value": "38/34"
      },
      {
        "label": "38/36",
        "value": "38/36"
      },
      {
        "label": "38/37",
        "value": "38/37"
      },
      {
        "label": "38/38",
        "value": "38/38"
      },
      {
        "label": "38/40",
        "value": "38/40"
      },
      {
        "label": "38/41",
        "value": "38/41"
      },
      {
        "label": "38/42",
        "value": "38/42"
      },
      {
        "label": "38/43",
        "value": "38/43"
      },
      {
        "label": "38/44",
        "value": "38/44"
      },
      {
        "label": "38/45",
        "value": "38/45"
      },
      {
        "label": "38/46",
        "value": "38/46"
      },
      {
        "label": "38/47",
        "value": "38/47"
      },
      {
        "label": "38/52",
        "value": "38/52"
      },
      {
        "label": "38/55",
        "value": "38/55"
      },
      {
        "label": "38/56",
        "value": "38/56"
      },
      {
        "label": "40/32",
        "value": "40/32"
      },
      {
        "label": "40/34",
        "value": "40/34"
      },
      {
        "label": "40/36",
        "value": "40/36"
      },
      {
        "label": "40/37",
        "value": "40/37"
      },
      {
        "label": "40/38",
        "value": "40/38"
      },
      {
        "label": "40/40",
        "value": "40/40"
      },
      {
        "label": "40/41",
        "value": "40/41"
      },
      {
        "label": "40/42",
        "value": "40/42"
      },
      {
        "label": "40/43",
        "value": "40/43"
      },
      {
        "label": "40/44",
        "value": "40/44"
      },
      {
        "label": "40/45",
        "value": "40/45"
      },
      {
        "label": "40/46",
        "value": "40/46"
      },
      {
        "label": "40/47",
        "value": "40/47"
      },
      {
        "label": "40/52",
        "value": "40/52"
      },
      {
        "label": "40/55",
        "value": "40/55"
      },
      {
        "label": "40/56",
        "value": "40/56"
      },
      {
        "label": "40/61",
        "value": "40/61"
      },
      {
        "label": "42/32",
        "value": "42/32"
      },
      {
        "label": "42/34",
        "value": "42/34"
      },
      {
        "label": "42/36",
        "value": "42/36"
      },
      {
        "label": "42/37",
        "value": "42/37"
      },
      {
        "label": "42/38",
        "value": "42/38"
      },
      {
        "label": "42/40",
        "value": "42/40"
      },
      {
        "label": "42/41",
        "value": "42/41"
      },
      {
        "label": "42/42",
        "value": "42/42"
      },
      {
        "label": "42/43",
        "value": "42/43"
      },
      {
        "label": "42/44",
        "value": "42/44"
      },
      {
        "label": "42/45",
        "value": "42/45"
      },
      {
        "label": "42/46",
        "value": "42/46"
      },
      {
        "label": "42/47",
        "value": "42/47"
      },
      {
        "label": "42/48",
        "value": "42/48"
      },
      {
        "label": "42/49",
        "value": "42/49"
      },
      {
        "label": "42/50",
        "value": "42/50"
      },
      {
        "label": "42/51",
        "value": "42/51"
      },
      {
        "label": "42/52",
        "value": "42/52"
      },
      {
        "label": "42/53",
        "value": "42/53"
      },
      {
        "label": "42/54",
        "value": "42/54"
      },
      {
        "label": "42/55",
        "value": "42/55"
      },
      {
        "label": "42/56",
        "value": "42/56"
      },
      {
        "label": "43",
        "value": "43"
      },
      {
        "label": "44",
        "value": "44"
      },
      {
        "label": "44/32",
        "value": "44/32"
      },
      {
        "label": "44/34",
        "value": "44/34"
      },
      {
        "label": "44/36",
        "value": "44/36"
      },
      {
        "label": "44/37",
        "value": "44/37"
      },
      {
        "label": "44/38",
        "value": "44/38"
      },
      {
        "label": "44/40",
        "value": "44/40"
      },
      {
        "label": "44/41",
        "value": "44/41"
      },
      {
        "label": "44/42",
        "value": "44/42"
      },
      {
        "label": "44/43",
        "value": "44/43"
      },
      {
        "label": "44/44",
        "value": "44/44"
      },
      {
        "label": "44/45",
        "value": "44/45"
      },
      {
        "label": "44/46",
        "value": "44/46"
      },
      {
        "label": "44/47",
        "value": "44/47"
      },
      {
        "label": "44/48",
        "value": "44/48"
      },
      {
        "label": "44/49",
        "value": "44/49"
      },
      {
        "label": "44/50",
        "value": "44/50"
      },
      {
        "label": "44/51",
        "value": "44/51"
      },
      {
        "label": "44/52",
        "value": "44/52"
      },
      {
        "label": "44/53",
        "value": "44/53"
      },
      {
        "label": "44/54",
        "value": "44/54"
      },
      {
        "label": "44/55",
        "value": "44/55"
      },
      {
        "label": "44/56",
        "value": "44/56"
      },
      {
        "label": "44/57",
        "value": "44/57"
      },
      {
        "label": "44/59",
        "value": "44/59"
      },
      {
        "label": "44/60",
        "value": "44/60"
      }
    ],
    SPORTSWEAR: [
      {
        "label": "22",
        "value": "22"
      },
      {
        "label": "24",
        "value": "24"
      },
      {
        "label": "25",
        "value": "25"
      },
      {
        "label": "26",
        "value": "26"
      },
      {
        "label": "27",
        "value": "27"
      },
      {
        "label": "28",
        "value": "28"
      },
      {
        "label": "28B",
        "value": "28B"
      },
      {
        "label": "29",
        "value": "29"
      },
      {
        "label": "30",
        "value": "30"
      },
      {
        "label": "30A",
        "value": "30A"
      },
      {
        "label": "30B",
        "value": "30B"
      },
      {
        "label": "30C",
        "value": "30C"
      },
      {
        "label": "30D",
        "value": "30D"
      },
      {
        "label": "30E",
        "value": "30E"
      },
      {
        "label": "31",
        "value": "31"
      },
      {
        "label": "32",
        "value": "32"
      },
      {
        "label": "32A",
        "value": "32A"
      },
      {
        "label": "32B",
        "value": "32B"
      },
      {
        "label": "32C",
        "value": "32C"
      },
      {
        "label": "32D",
        "value": "32D"
      },
      {
        "label": "32DD",
        "value": "32DD"
      },
      {
        "label": "32E",
        "value": "32E"
      },
      {
        "label": "32F",
        "value": "32F"
      },
      {
        "label": "33",
        "value": "33"
      },
      {
        "label": "34",
        "value": "34"
      },
      {
        "label": "34A",
        "value": "34A"
      },
      {
        "label": "34B",
        "value": "34B"
      },
      {
        "label": "34C",
        "value": "34C"
      },
      {
        "label": "34D",
        "value": "34D"
      },
      {
        "label": "34DD",
        "value": "34DD"
      },
      {
        "label": "34E",
        "value": "34E"
      },
      {
        "label": "34F",
        "value": "34F"
      },
      {
        "label": "34G",
        "value": "34G"
      },
      {
        "label": "35",
        "value": "35"
      },
      {
        "label": "36",
        "value": "36"
      },
      {
        "label": "36A",
        "value": "36A"
      },
      {
        "label": "36B",
        "value": "36B"
      },
      {
        "label": "36C",
        "value": "36C"
      },
      {
        "label": "36D",
        "value": "36D"
      },
      {
        "label": "36DD",
        "value": "36DD"
      },
      {
        "label": "36E",
        "value": "36E"
      },
      {
        "label": "36F",
        "value": "36F"
      },
      {
        "label": "27",
        "value": "27"
      },
      {
        "label": "28B",
        "value": "28B"
      },
      {
        "label": "28A",
        "value": "28A"
      },
      {
        "label": "28B",
        "value": "28B"
      },
      {
        "label": "38C",
        "value": "38C"
      },
      {
        "label": "38D",
        "value": "38D"
      },
      {
        "label": "38DD",
        "value": "38DD"
      },
      {
        "label": "38E",
        "value": "38E"
      },
      {
        "label": "38F",
        "value": "38F"
      },
      {
        "label": "39",
        "value": "39"
      },
      {
        "label": "3XL",
        "value": "3XL"
      },
      {
        "label": "3XS",
        "value": "3XS"
      },
      {
        "label": "40",
        "value": "40"
      },
      {
        "label": "40B",
        "value": "40B"
      },
      {
        "label": "40C",
        "value": "40C"
      },
      {
        "label": "40D",
        "value": "40D"
      },
      {
        "label": "42",
        "value": "42"
      },
      {
        "label": "46",
        "value": "46"
      },
      {
        "label": "4XL",
        "value": "4XL"
      },
      {
        "label": "50",
        "value": "50"
      },
      {
        "label": "5XL",
        "value": "5XL"
      },
      {
        "label": "6XL",
        "value": "6XL"
      },
      {
        "label": "7",
        "value": "7"
      },
      {
        "label": "L",
        "value": "L"
      },
      {
        "label": "L/XL",
        "value": "L/XL"
      },
      {
        "label": "M",
        "value": "M"
      },
      {
        "label": "M/L",
        "value": "M/L"
      },
      {
        "label": "ONESIZE",
        "value": "ONESIZE"
      },
      {
        "label": "S",
        "value": "S"
      },
      {
        "label": "S/M",
        "value": "S/M"
      },
      {
        "label": "UK1",
        "value": "UK1"
      },
      {
        "label": "UK1.5",
        "value": "UK1.5"
      },
      {
        "label": "UK10",
        "value": "UK10"
      },
      {
        "label": "UK10.5",
        "value": "UK10.5"
      },
      {
        "label": "UK11",
        "value": "UK11"
      },
      {
        "label": "UK11.5",
        "value": "UK11.5"
      },
      {
        "label": "UK12",
        "value": "UK12"
      },
      {
        "label": "UK13.5",
        "value": "UK13.5"
      },
      {
        "label": "UK2",
        "value": "UK2"
      },
      {
        "label": "UK2.5",
        "value": "UK2.5"
      },
      {
        "label": "UK3",
        "value": "UK3"
      },
      {
        "label": "UK3.5",
        "value": "UK3.5"
      },
      {
        "label": "UK4",
        "value": "UK4"
      },
      {
        "label": "UK4.5",
        "value": "UK4.5"
      },
      {
        "label": "UK5",
        "value": "UK5"
      },
      {
        "label": "UK5.5",
        "value": "UK5.5"
      },
      {
        "label": "UK6",
        "value": "UK6"
      },
      {
        "label": "UK6.5",
        "value": "UK6.5"
      },
      {
        "label": "UK7",
        "value": "UK7"
      },
      {
        "label": "UK7.5",
        "value": "UK7.5"
      },
      {
        "label": "UK8",
        "value": "UK8"
      },
      {
        "label": "UK8.5",
        "value": "UK8.5"
      },
      {
        "label": "UK9",
        "value": "UK9"
      },
      {
        "label": "UK9.5",
        "value": "UK9.5"
      },
      {
        "label": "XL",
        "value": "XL"
      },
      {
        "label": "XL/XXL",
        "value": "XL/XXL"
      },
      {
        "label": "XS",
        "value": "XS"
      },
      {
        "label": "XS/S",
        "value": "XS/S"
      },
      {
        "label": "XXL",
        "value": "XXL"
      },
      {
        "label": "XXS",
        "value": "XXS"
      },
      {
        "label": "XSS/XS",
        "value": "XSS/XS"
      }
    ],
    ACCESSORIES: [
      {
        "label": "10",
        "value": "10"
      },
      {
        "label": "11",
        "value": "11"
      },
      {
        "label": "18",
        "value": "18"
      },
      {
        "label": "19",
        "value": "19"
      },
      {
        "label": "20",
        "value": "20"
      },
      {
        "label": "21",
        "value": "21"
      },
      {
        "label": "22",
        "value": "22"
      },
      {
        "label": "25",
        "value": "25"
      },
      {
        "label": "26",
        "value": "26"
      },
      {
        "label": "28",
        "value": "28"
      },
      {
        "label": "30",
        "value": "30"
      },
      {
        "label": "31",
        "value": "31"
      },
      {
        "label": "31.5",
        "value": "31.5"
      },
      {
        "label": "32",
        "value": "32"
      },
      {
        "label": "33",
        "value": "33"
      },
      {
        "label": "34",
        "value": "34"
      },
      {
        "label": "35",
        "value": "35"
      },
      {
        "label": "36",
        "value": "36"
      },
      {
        "label": "36.5",
        "value": "36.5"
      },
      {
        "label": "37",
        "value": "37"
      },
      {
        "label": "38",
        "value": "38"
      },
      {
        "label": "39",
        "value": "39"
      },
      {
        "label": "40",
        "value": "40"
      },
      {
        "label": "40.5",
        "value": "40.5"
      },
      {
        "label": "41",
        "value": "41"
      },
      {
        "label": "42",
        "value": "42"
      },
      {
        "label": "43",
        "value": "43"
      },
      {
        "label": "44",
        "value": "44"
      },
      {
        "label": "45",
        "value": "45"
      },
      {
        "label": "46",
        "value": "46"
      },
      {
        "label": "47",
        "value": "47"
      },
      {
        "label": "48",
        "value": "48"
      },
      {
        "label": "50",
        "value": "50"
      },
      {
        "label": "52",
        "value": "52"
      },
      {
        "label": "54",
        "value": "54"
      },
      {
        "label": "56",
        "value": "56"
      },
      {
        "label": "58",
        "value": "58"
      },
      {
        "label": "60",
        "value": "60"
      },
      {
        "label": "7",
        "value": "7"
      },
      {
        "label": "8",
        "value": "8"
      },
      {
        "label": "9",
        "value": "9"
      },
      {
        "label": "L",
        "value": "L"
      },
      {
        "label": "L/XL",
        "value": "L/XL"
      },
      {
        "label": "M",
        "value": "M"
      },
      {
        "label": "M/L",
        "value": "M/L"
      },
      {
        "label": "ONESIZE",
        "value": "ONESIZE"
      },
      {
        "label": "PACK",
        "value": "PACK"
      },
      {
        "label": "S",
        "value": "S"
      },
      {
        "label": "S/M",
        "value": "S/M"
      },
      {
        "label": "XL",
        "value": "XL"
      },
      {
        "label": "XS",
        "value": "XS"
      },
      {
        "label": "XS/S",
        "value": "XS/S"
      },
      {
        "label": "XXL",
        "value": "XXL"
      }
    ],
    PLUSSIZE: [
      {
        "label": "2XL",
        "value": "2XL"
      },
      {
        "label": "3XL",
        "value": "3XL"
      },
      {
        "label": "4XL",
        "value": "4XL"
      },
      {
        "label": "5XL",
        "value": "5XL"
      },
    ]
  },
  KIDS: {
    ALL: [
      {
        "label": "0-3 months",
        "value": "0-3 months"
      },
      {
        "label": "3-6 months",
        "value": "3-6 months"
      },
      {
        "label": "6-9 months",
        "value": "6-9 months"
      },
      {
        "label": "9-12 months",
        "value": "9-12 months"
      },
      {
        "label": "12-18 months",
        "value": "12-18 months"
      },
      {
        "label": "18-24 months",
        "value": "18-24 months"
      },
      {
        "label": "2-4 years",
        "value": "2-4 years"
      },
      {
        "label": "4-6 years",
        "value": "4-6 years"
      },
      {
        "label": "6-8 years",
        "value": "6-8 years"
      },
      {
        "label": "8-10 years",
        "value": "8-10 years"
      },
      {
        "label": "10-12 years",
        "value": "10-12 years"
      },
      {
        "label": "12+ years",
        "value": "12+ years"
      }
    ]
  }
};
