import React, { Component } from "react";
import Downshift from "downshift";
import icDropdown from "../../../assets/images/navigation/ic-arrow-drop-down.svg";

import CheckBox from "../../UI/Checkbox/Checkbox";

import PerfectScrollbar from "react-perfect-scrollbar";

class FactoryFilter extends Component {
  constructor(props) {
    super(props);
    const {filterOptions} = props;
    this.state = {
      selections: [],
      allSelected : filterOptions.filter(option => option.isSelected).length === filterOptions.length
    };
  }
  

  componentWillReceiveProps(nextProps) {
    const { selections } = this.state;
    const {filterOptions} = nextProps;
    if (
      selections.length &&
      !this.props.filterOptions.filter(item => item.isSelected).length
    ) {
      this.setState({ selections: [] });
    };
    const allSelected = filterOptions.filter(option => option.isSelected).length === filterOptions.length;
    this.setState({allSelected});
  }

  getTheLabelValue = () => {
    const { selections } = this.state;
    if (!selections.length) {
      return "All";
    }

    if (selections.length === 1) {
      return selections[0];
    }

    return `${
      this.props.filterOptions.filter(item => item.isSelected).length
    } selected`;
  };

  render() {
    const {
      title = "Collections",
      onChange,
      initialSelectedItem,
      filterOptions = [],
      marginRight = "0px",
      disable,
      applyCollections,
      cancelCollectionFilter,
      containerWidth = "400px",
      containerHeight = "350px",
      pillWidth = "138px",
      selectAll
    } = this.props;

    const { allSelected } = this.state;

    return (
      <Downshift
        initialSelectedItem={initialSelectedItem}
        itemToString={item => item.label}
        onChange={selection => {
          onChange(selection);

          this.setState({
            selections: [...this.state.selections, selection.label]
          });
          // alert(JSON.stringify(selection))
          // this.handleUserAuthorityChange(collab, selection);
        }}
      >
        {({
          getInputProps,
          getToggleButtonProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          isOpen,
          inputValue,
          highlightedIndex,
          selectedItem
        }) => {
          return (
            <div
              {...getToggleButtonProps()}
              type="none"
              style={{
                userSelect: "none",
                marginTop: "0.3rem",
                position: "relative",
                cursor: "pointer",
                border: "solid 1px var(--fill-grey-light2)",
                borderRadius: "8px",
                padding: "6px 12px",
                width: pillWidth,
                height: "48px",
                marginRight: marginRight
              }}
            >
              <span
                {...getLabelProps()}
                style={{ fontSize: "12px", color: "var(--fill-grey-dark)" }}
              >
                {title}
              </span>
              <span className="flex" style={{ alignItems: "center" }}>
                <input
                  style={{
                    border: "none",
                    backgroundColor: "transparent",
                    maxWidth: "60px",
                    fontWeight: "600"
                  }}
                  className="select-input-transparent"
                  readOnly={true}
                  disabled={true}
                  value={this.getTheLabelValue()}
                  // {...getInputProps()} // have to refactor this one mostly .. uncommit this for value in filter
                />
                <img
                  src={icDropdown}
                  alt="dropdown"
                  style={{ marginLeft: "auto" }}
                />
              </span>
              {isOpen && disable ? (
                <div
                  className="select-pane"
                  // onClick={e => {
                  // //   e.stopPropagation();
                  // }}
                  style={{
                    width: containerWidth,
                    height: containerHeight,

                    paddingLeft: "10px",
                    alignItems: "center",
                    position: "relative"
                    // paddingBottom: filterOptions.length? '45px':'0px',
                  }}
                >
                  {/* THIS IS COLLECTION STATUS LABEL AT TOP ==== STARTS ==== */}
                  <div
                    style={{
                      padding: "15px 0px",
                      paddingTop: "15px",
                      paddingBottom: "25px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center"
                    }}
                    id="DOEN"
                    onClick={() => selectAll()}
                  >
                    <div className="f-w-bold">{`All ${title} (${
                      filterOptions.length
                    })`}</div>
                    <div
                      style={{
                        transform: "translateY(-8px)",
                        paddingRight: "10px"
                      }}
                    >
                      <CheckBox
                        value={
                          filterOptions.filter(option => option.isSelected)
                            .length === filterOptions.length
                        }
                        onClick={allSelected ? cancelCollectionFilter : selectAll}
                      />
                    </div>
                  </div>
                  {/* THIS IS COLLECTION STATUS LABEL AT TOP ==== ENDS ==== */}

                  {/* === THESE ARE BRAND OPTION FROM HERE ==== STARTS ==== */}
                  <div style={{ height: "255px", overflow: "hidden" }}>
                    <PerfectScrollbar
                      option={{
                        suppressScrollX: true
                      }}
                    >
                      {filterOptions
                        // .filter(item => item.label !== selectedItem.label)
                        .map((item, index) => {
                          return (
                            <div
                              {...getItemProps({
                                className:
                                  "in-selection collection-collab__label_with_checkBox flex",
                                index,
                                item,
                                key: item.label
                              })}
                              style={{
                                fontWeight: item.isSelected ? "600" : "300",
                                paddingRight: "10px"
                              }}
                            >
                              <span>{`${item.label}`}</span>
                              <div style={{ transform: "translateY(-8px)" }}>
                                <CheckBox
                                  value={item.isSelected}
                                  onClick={() =>
                                    onChange({ value: item.value })
                                  }
                                />
                              </div>
                            </div>
                          );
                        })}
                    </PerfectScrollbar>
                  </div>
                  {/* === THESE ARE BRAND OPTION FROM HERE ==== ENDS ==== */}

                  {/* ==== THESE ARE APPLY AND CANCEL BUTTONS ===== STARTS ==== */}
                  {filterOptions.length ? (
                    <div
                      style={{
                        backgroundColor: "#fff",
                        position: "absolute",
                        right: 0,
                        bottom: 0,
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",

                        boxShadow: " 0 8px 16px 0 rgba(0, 1, 38, 0.5)"
                      }}
                    >
                      <div
                        style={{
                          fontSize: "12px",
                          fontWeight: 600,
                          textTransform: "uppercase",
                          color: "var(--fill-grey-medium)",
                          letterSpacing: "1px",
                          padding: "15px 10px"
                        }}
                        onClick={() => {
                          cancelCollectionFilter();
                          this.setState({ selections: [] });
                        }}
                      >
                        Clear selection
                      </div>
                      <div
                        style={{
                          fontSize: "12px",
                          fontWeight: 600,
                          textTransform: "uppercase",
                          color: "var(--fill-secondary-dark)",
                          letterSpacing: "1px",
                          padding: "15px 10px"
                        }}
                        onClick={applyCollections}
                      >
                        Apply
                      </div>
                    </div>
                  ) : null}
                  {/* ==== THESE ARE APPLY AND CANCEL BUTTONS ===== ENDS ==== */}
                </div>
              ) : null}
            </div>
          );
        }}
      </Downshift>
    );
  }
}

export default FactoryFilter;
