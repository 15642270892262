import React from 'react';
import Classnames from 'classnames'
import PerfectScrollbar from 'react-perfect-scrollbar';
import keyBy from 'lodash/keyBy';
import { ReactComponent as ImgArrowDropUp } from 'assets/images/navigation/ic-arrow-drop-up.svg';
import { ReactComponent as ImgCheck } from 'assets/images/navigation/ic-check.svg';
import './index.scss';

export default class FilterLabel extends React.Component {
  state = {
    filterItems: [],
    mapSelectedItems: {},
    isDropdownVisible: false,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const nextState = {};

    // allKey exists in nextProps
    if (Object.prototype.hasOwnProperty.call(nextProps, 'allKey')) {
      nextState.allKey = nextProps.allKey;
      nextState.hasAllKey = nextState.allKey !== undefined;
    }

    if (Array.isArray(nextProps.filterItems)) {
      let nextFilterItems = nextProps.filterItems;
      const selectedItems = nextFilterItems.filter(item => item.isSelected);
      const nextMapSelectedItems = keyBy(selectedItems, 'key');
      const selectedCount = selectedItems.length;
      const allKey = nextProps.allKey || prevState.allKey;

      // select all key by default if no items are selected
      if (selectedCount === 0 && allKey) {
        nextFilterItems = nextFilterItems.map(item => {
          if (item.key === allKey) {
            item.isSelected = true;
          }

          return item;
        });
      }

      nextState.filterItems = nextFilterItems;
      nextState.mapSelectedItems = nextMapSelectedItems;
    }

    return nextState;
  }

  handleClickOnDocument = (e) => {
    if (this.nodeDropdownFilterItems.contains(e.target)) {
      return;
    }

    this.setIsVisible(false);
  }

  handleFilterLabelClick = () => {
    const newVisibleState = !this.state.isDropdownVisible;

    this.setIsVisible(newVisibleState);
    this.setState({ isDropdownVisible: newVisibleState });
  }

  handleFilterItemChanged = (filterItem) => {
    const { isMultiSelect } = this.props;
    const updatedSelectionState = !filterItem.isSelected;

    // if not a multi-select, item can't be deselected
    if (!isMultiSelect && updatedSelectionState === false) {
      return;
    }

    const { filterItems, mapSelectedItems } = this.state;
    let updatedFilterItems = filterItems;
    let updatedMapSelectedItems = { ...mapSelectedItems };

    if (isMultiSelect) {
      // TODO: work on "all" keys for multi-select

      updatedFilterItems = updatedFilterItems.map(item => {
        if (item.key === filterItem.key) {
          return item;
        }
        return {
          ...item,
          isSelected: updatedSelectionState,
        };
      });

      if (updatedSelectionState) {
        updatedMapSelectedItems[filterItem.key] = filterItem;
      } else {
        delete updatedMapSelectedItems[filterItem.key];
      }
    } else {
      updatedMapSelectedItems = { [filterItem.key]: filterItem };
    }

    if (!isMultiSelect) {
      if (typeof (this.props.onSelect) === 'function') {
        this.props.onSelect([filterItem]);
        this.setIsVisible(false);
      }
    }

    this.setState({
      filterItems: updatedFilterItems,
      mapSelectedItems: updatedMapSelectedItems,
    });
  }

  setIsVisible = (isVisible) => {
    if (isVisible) {
      document.addEventListener('mousedown', this.handleClickOnDocument, false);
    } else {
      document.removeEventListener('mousedown', this.handleClickOnDocument, false);
    }

    this.setState({ isDropdownVisible: isVisible });
  }

  renderDropdown() {
    const { filterKey, filterName } = this.props;
    const { filterItems } = this.state;

    return filterItems.map((filterItem) => (<div
      className="filter-item"
      key={filterItem.key}>
      <input
        type="checkbox"
        id={`filter-item-${filterKey}-${filterItem.key}`}
        name={filterName}
        value={filterItem.value || ''}
        checked={filterItem.isSelected}
        onChange={() => this.handleFilterItemChanged(filterItem)}
      />
      <label htmlFor={`filter-item-${filterKey}-${filterItem.key}`}>
        <ImgCheck className="img-check" />
        <span>{filterItem.label}</span>
      </label>
    </div>));
  }

  render() {
    const { label } = this.props;
    const { isDropdownVisible } = this.state;
    const className = Classnames('filter-label', isDropdownVisible ? 'show' : null);

    return <div className={className}
      ref={node => this.nodeDropdownFilterItems = node}
    >
      <span className="title-container" onClick={this.handleFilterLabelClick}>
        <span className="title">
          {label}
        </span>
        <ImgArrowDropUp className="img-arrow-drop-up" />
      </span>
      {isDropdownVisible && <div className="dropdown">
        <PerfectScrollbar>
          {this.renderDropdown()}
        </PerfectScrollbar>
      </div>
      }
    </div>
  }
}
