import { handleActions } from "redux-actions";

const INITIAL_STATE = {
  designSuggestions: {},
  designTnaTemplates: {},
};

const Tna = handleActions({
  TNA_DESIGN_SUGGESTIONS: (state, action) => {
    const { designId, suggestions } = action.payload;

    return {
      ...state,
      designSuggestions: {
        ...state.designSuggestions,
        [designId]: suggestions,
      }
    };
  },
  GET_DESIGN_TNA_TEMPLATE: (state, action) => {
    const { designId, data, } = action.payload;

    return {
      ...state,
      designTnaTemplates: {
        ...state.designTnaTemplates,
        [designId]: data,
      },
    };
  },
}, INITIAL_STATE);

export default Tna;
