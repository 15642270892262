import React, { useState, useRef } from "react";
import moment from "moment";
import map from "lodash/map";
import { connect } from "react-redux";

import styles from "./Product.module.scss";
import itemStyles from "./../SamplingTracker/SampleTrackerItem.module.scss";

import { getSmallImageUrl } from "../../helpers/DesignHelpers";
import { getCurrentUserRole } from "../../helpers/AuthHelper";
import { openModal } from "../../store/actions/GlobalActions";
import { DATESTAMP__SHORT_FORMAT } from "./../../constants/Date";
import {
  PRODUCTION_MERCHANT,
  PRODUCT_DEVELOPMENT,
  LOCO_SUPER,
  CLIENT_SUPER,
  CLIENT,
  FACTORY_ADMIN,
  FACTORY_MANAGER
} from "../../constants/Auth";

import DesignImageCard from "./../ProductionTracker/DesignImageCard";
import SampleFiles from "../Designs/SamplingDetails/SampleFiles";

const Design = ({
  data: {
    id,
    designName,
    isSelected,
    brandId,
    brandName,
    collectionId,
    collectionName,
    designId,
    imageUrl,
    quoteAmount,
    quoteApprovedOn,
    quoteStatus,
    quoteRequestStatus,
    sampleApprovedOn,
    sampleStatus,
    sampleType,
    sourceDesignId,
    factoryId,
    factoryName,
    attemptsData,
    sampleRequestedOn,
    dueDate,
    sampleAttachments,
  },
  data,
  getSeletedDesign,
  isFactory,
  openModal,
  isSampling,
  hideCheckbox
}) => {
  const attachments = sampleAttachments[id];
  const [showExtraSamples, setShowExtraSamples] = useState(false);
  const currentUserRoleValue = getCurrentUserRole();

  let smallImageUrl;
  if (imageUrl) {
    smallImageUrl = getSmallImageUrl(imageUrl);
  }

  let isAdmin = false,
    isBrand = false,
    isFactoryManagerOrAdmin = false;
  if (
    currentUserRoleValue == LOCO_SUPER ||
    currentUserRoleValue == PRODUCT_DEVELOPMENT ||
    currentUserRoleValue == PRODUCTION_MERCHANT
  ) {
    isAdmin = true;
  } else if (
    currentUserRoleValue == CLIENT_SUPER ||
    currentUserRoleValue == CLIENT
  ) {
    isBrand = true;
  } else if (
    currentUserRoleValue == FACTORY_ADMIN ||
    currentUserRoleValue == FACTORY_MANAGER
  ) {
    isFactoryManagerOrAdmin = true;
  }

  const multipleSampleTypes = sampleType.split(",");

  const viewMore = useRef(null);

  const handleClick = () => {
    // setShowExtraSamples(true);
    // viewMore.current.style.display = 'none';
    let mapIndex = 0;
    const transformAttemptsData = map(attemptsData, (data, index) => {
      return {
        name: data[0]["sample_type"],
        approved_on: sampleApprovedOn[mapIndex],
        factoryName: factoryName || "N/A",
        daysTaken: dateDifference(
          sampleApprovedOn[mapIndex],
          sampleRequestedOn[mapIndex++]
        ),
        attempts: data[0].failedAttempts + 1
      };
    });
    openModal("VIEW_SAMPLING_HISTORY", {
      samplingHistory: transformAttemptsData,
      isBrand: !isFactory
    });
  };

  const hideVisibiltyStyles = {
    visibility: "visible",
    opacity: 1,
    height: "auto",
    transition: "all 0.5s ease-in"
  };
  const showVisibiltyStyles = {
    visibility: "hidden",
    opacity: 0,
    height: "0",
    transition: "all 0.5s ease-out"
  };

  const dateDifference = (date1, date2) => {
    return moment(date1).diff(moment(date2), "days");
  };

  return (
    <div
      className="card-custom-product sampling-tracker"
      style={
        isBrand
          ? { gridTemplateColumns: "repeat(4,1fr) 150px" }
          : { gridTemplateColumns: "repeat(5,1fr) 150px" }
      }
    >
      <DesignImageCard
        isFactory={isFactory}
        brandId={brandId}
        collectionId={collectionId}
        designId={designId}
        smallImageUrl={smallImageUrl}
        brandName={brandName}
        sourceDesignId={sourceDesignId}
        designName={designName}
        isCellV2={true}
        factoryId={factoryId}
      />
      {!isBrand && (
        <div className={styles.design__details}>
          <div className={`${itemStyles.item__p} cell-v2`}>
            <div>
              <span data-tip={brandName}>{brandName}</span>
            </div>
          </div>
        </div>
      )}

      {multipleSampleTypes.length > 1 ? (
        <div className={styles.design__status} style={{paddingLeft: 0}}>
          {multipleSampleTypes.map((item, index) => (
            <div
              key={index}
              className={`${showExtraSamples && styles.design__sample_wrapper} cell-v2`}
              style={
                index > 0
                  ? showExtraSamples
                    ? hideVisibiltyStyles
                    : showVisibiltyStyles
                  : {}
              }
            >
              <div className={styles.design__status_type}>{item}</div>
              {sampleStatus === "Sample Not Required" ? null : (
                <div className={styles.design__status_date}>
                  on {moment(sampleRequestedOn[index]).format("MMMM DD, YYYY")}
                </div>
              )}
            </div>
          ))}
          <div
            onClick={handleClick}
            className={`${styles.design__status__viewmore} link-hover`}
            ref={viewMore}
          >
            + {multipleSampleTypes.length - 1} more
          </div>
        </div>
      ) : (
          <div className={`${styles.design__status} cell-v2`} style={{paddingLeft: 0}}>
            <div className={styles.design__status_type}>{sampleType}</div>
            {sampleStatus === "Sample Not Required" ? null : (
              <div className={styles.design__status_date}>
                On {moment(sampleRequestedOn[0]).format("MMMM DD, YYYY")}
              </div>
            )}
          </div>
        )}
      <div className={`${itemStyles.item__wrapper} cell-v2`}>
        {!isFactoryManagerOrAdmin && (
          <div className={`${itemStyles.item__p} overflow-ellipsis`}>
            <span data-tip={factoryName}>
              {factoryName || "N/A"}
            </span>
          </div>
        )}
        {dueDate &&
          (isAdmin ? (
            <div className={styles.design__status_date}>
              Due on {moment(dueDate).format(DATESTAMP__SHORT_FORMAT)}
            </div>
          ) : (
              <div className={styles.design__status_date}>
                Due on {moment(dueDate).format(DATESTAMP__SHORT_FORMAT)}
              </div>
            ))}
      </div>
      <div className={isSampling ? styles.design__status__is_sampling : styles.design__status}>
        {sampleStatus === "Sample Not Required" ? null : (
          <div className="flex cell-checkbox">
            <div className="cell-v2">
              <div className="text-green">Approved</div>
              <div className={styles.design__status_date}>
                On {moment(sampleApprovedOn[0]).format("MMMM DD, YYYY")}
              </div>
            </div>
          </div>
        )}
      </div>


      <div>
        <SampleFiles designName={designName} files={attachments} />
      </div>

    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  openModal: (name, additionalProps) =>
    dispatch(openModal(name, additionalProps))
});

export default connect(null, mapDispatchToProps)(Design);
