import qs from "query-string";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import last from "lodash/last";
import moment from "moment";

import { Sizes } from "../constants/Sizes";
import { DESIGN_STATUS_KEYS } from "constants/Design";

import menPlaceholder from "../assets/images/placeholders/ic_plc_men.svg";
import womenPlaceholder from "../assets/images/placeholders/ic_plc_women.svg";
import kidsPlaceholder from "../assets/images/placeholders/ic_plc_kids.svg";
import excelImg from "assets/images/summary/excel.svg";
import pdfImg from "assets/images/summary/pdf.svg";
import docImg from "assets/images/summary/doc.svg";
import { checkFactoryOrMerchant } from "./AuthHelper";

export const getCoverImage = ({ category, coverPic, coverSize = 'small' }) => {
  if (coverPic) {
    return coverSize === 'cover'
      ? getSmallImageUrlForCover(coverPic)
      : getSmallImageUrl(coverPic);
  }

  return getCoverImageByCategory(category);
};

export const getCoverImagePlaceholder = designDetails => {
  const imgAttributes = {};
  const placeholder = getCoverImageByCategory(designDetails.category);

  if (placeholder) {
    imgAttributes.src = placeholder;
  }

  return imgAttributes;
};

const getCoverImageByCategory = category => {
  switch (category) {
    case "Men":
      return menPlaceholder;
    case "Women":
      return womenPlaceholder;
    case "Kids":
      return kidsPlaceholder;
    default:
      break;
  }
  return null;
};

export const getSmallImageUrl = imgUrl => {
  let arr = imgUrl.split(".");
  arr[arr.length - 2] = arr[arr.length - 2] + "_small";
  arr = arr.join(".");
  return arr;
};

export const getSmallImageUrlForCover = imgUrl => {
  let arr = imgUrl.split(".");
  arr[arr.length - 2] = arr[arr.length - 2] + "_cover";
  arr = arr.join(".");
  return arr;
};

/**
 * @param {String} image  The cover image url
 * @param {object} designData
 * @param { string } designData['category'] Design category
 */
export const getImageUrl = (image, designData) => {
  let smallImageUrl;
  if (image) {
    smallImageUrl = getSmallImageUrl(image);
  } else {
    smallImageUrl = getCoverImagePlaceholder(designData).src;
  }
  return smallImageUrl;
};

// Returns a React-router <Link /> compatible object
// design: Design Object
// location: react-router location object from withRouter()
export const generateDesignLink = ({
  pathname,
  design,
  location = { search: "" },
  overrideTab = null
}) => {
  const currentQueryString = qs.parse(location.search);
  let currentTab =
    design.statusString.toLowerCase() === "order closed"
      ? "dispatch"
      : design.statusString.toLowerCase();

  if (overrideTab !== null) {
    currentTab =
      overrideTab.toLowerCase() === "order closed"
        ? "dispatch"
        : overrideTab.toLowerCase();
  }

  return {
    pathname: pathname.replace(":design_id", design.id),
    // `../${design.id}/`
    search: qs.stringify({
      ...currentQueryString,
      tab: currentTab
    })
  };
};

export const getDesignLink = (design, isFactory, tab = "summary") => {
  const collectionId = design.collectionId || design.collection_id;
  const designId = design.designId || design.design_id || design.id;
  const brandId = design.brandId || design.brand_id || design.brand;

  return isFactory
    ? `/factory/brands/${brandId}/collections/${collectionId}/designs/${designId}/?tab=${tab}`
    : `/brand/collections/${collectionId}/designs/${designId}/?tab=${tab}`;
};

// Dummy function which doesn't redirect according to status
// To be disabled in the future
export const generateDesignLinkDummy = ({
  pathname,
  design,
  location = { search: "" },
  overrideTab = null
}) => {
  const currentQueryString = qs.parse(location.search);
  // let currentTab =
  //   design.statusString.toLowerCase() === "order closed" ? "dispatch" : design.statusString.toLowerCase();

  // if (overrideTab !== null) {
  //   currentTab = overrideTab.toLowerCase() === "order closed" ? "dispatch" : overrideTab.toLowerCase();
  // }
  let currentTab = overrideTab || "summary";

  return {
    pathname: pathname.replace(":design_id", design.id),
    // `../${design.id}/`
    search: qs.stringify({
      ...currentQueryString,
      tab: currentTab
    })
  };
};

export const getDesignSizes = ({ category, sub_category, subCategory }) => {
  return getSizesByDesignType(category, sub_category || subCategory);
};

export const getSizesByDesignType = (category, sub_category) => {
  const [size_category, size_sub_category] = getDesignCategory(
    category,
    sub_category
  );
  return Sizes[size_category][size_sub_category];
};

const getDesignCategory = (category, sub_category) => {
  switch (category) {
    case "Men": {
      switch (sub_category) {
        case "Top wear":
          return ["MEN", "TOPWEAR"];
        case "Bottom wear":
          return ["MEN", "BOTTOMWEAR"];
        case "Sports and active wear":
          return ["MEN", "SPORTSWEAR"];
        case "Indian and festive wear":
          return ["MEN", "INDIANWEAR"];
        case "Inner and sleep wear":
          return ["MEN", "SLEEPWEAR"];
        case "Fashion accessories":
          return ["MEN", "ACCESSORIES"];
        case "Plus size":
          return ["MEN", "PLUSSIZE"];
        default:
          return null;
      }
    }
    case "Women": {
      switch (sub_category) {
        case "Indian and fusion wear":
          return ["WOMEN", "INDIANWEAR"];
        case "Western wear":
          return ["WOMEN", "WESTERNWEAR"];
        case "Sleep wear":
          return ["WOMEN", "SLEEPWEAR"];
        case "Sports and active wear":
          return ["WOMEN", "SPORTSWEAR"];
        case "Accessories":
          return ["WOMEN", "ACCESSORIES"];
        case "Plus size":
          return ["WOMEN", "PLUSSIZE"];
        default:
          return null;
      }
    }
    case "Kids": {
      return ["KIDS", "ALL"];
    }
    default:
      return null;
  }
};

export const transformFactoriesToOptions = factories => {
  return map(factories, factory => ({
    label: factory.name,
    value: factory.id
  }));
};

export const getFileDisplayUrl = ({ url }) => {
  if (checkPic(url)) {
    return url;
  }

  return getBgUrl(url);
};

export const getBgUrl = file => {
  const regex = /(xlsx|xls|csv|pdf|doc|docx){1}$/;
  const testUrl = regex.exec(file);
  if (testUrl) {
    return icons[testUrl[0]];
  }
  return icons.xls;
};

export const checkPic = file => {
  const regex = /(png|jpeg|jpg){1}$/;
  const testUrl = regex.exec(file);
  if (testUrl) {
    return true;
  }
  return false;
};

const icons = {
  xlsx: excelImg,
  xls: excelImg,
  csv: excelImg,
  pdf: pdfImg,
  doc: docImg,
  docx: docImg
};

export const designInactive = status =>
  status === DESIGN_STATUS_KEYS.ARCHIVED ||
  status === DESIGN_STATUS_KEYS.CANCELED;

export const disableDesignActions = status =>
  status === DESIGN_STATUS_KEYS.ARCHIVED ||
  status === DESIGN_STATUS_KEYS.CANCELED ||
  checkFactoryOrMerchant();

export const checkTnaReadyForPP = ({ sampleType, tnaPayload }) => {
  if (sampleType === "Preproduction Sample") {
    if (isEmpty(tnaPayload)) {
      return false;
    }

    const tnaData = tnaPayload;
    if (tnaData.tnaAttributes) {
      // tna attributes is split category wise, material inward is the first category.
      const lastField = last(tnaData.tnaAttributes[0].data);

      if (!lastField.attributeValue) {
        return false;
      }
    } else {
      return false;
    }
  }

  return true;
};

export const computeTimeStatus = (
  orderDueDate,
  dispatchDate,
  orderStatus,
  dispatchStatus
) => {
  return (moment(dispatchDate).isBefore(orderDueDate) &&
    orderStatus == "Completed" &&
    dispatchStatus == "Dispatched") ||
    moment(orderDueDate).isAfter(moment())
    ? "on-time"
    : "delayed";
};

export const hasQuoteRequestExpired = closureDate => {
  return (
    moment.duration(moment(closureDate).diff(moment(new Date()))).asSeconds() <=
    0
  );
};

/** 23,24,25 => 23, 24, 25 */
export const transformSizes = sizes =>
  sizes && sizes.length > 1
    ? sizes
      .split(",")
      .map(size => ` ${size}`)
      .join(",")
    : sizes;

export const capitalize = text => {
  if (typeof text !== 'string') return text;
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
};

export const toSentenceCase = text => {
  if (typeof text !== 'string') return text;

  return text.split(' ')
    .map((w, indW) => (indW === 0
      ? (w[0].toUpperCase() + w.slice(1))
      : w.toLowerCase()
    ))
    .join(' ');
}
