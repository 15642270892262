import React from "react";
import moment from "moment";

import { DATESTAMP_FORMAT } from "constants/Date";
import { REQUEST_OPTIONS } from "constants/Quote";
import Button from "../../../UI/Button/Button";
import QuoteAccordion from "../QuoteAccordion";
import { IconEditPencil } from "components/UI/Icons";

const RequestedQuoteDetails = ({
  details: {
    factory_name = "Locofast",
    buyer_name,
    brand_name,
    quantity,
    price,
    due_date: dueDate,
    last_notified,
    id,
    type,
    closure_date,
    production_mode,
    sizes_quantity
  },
  quotesNotAvailable,
  currentUser,
  openUpdateRequestModal,
  quoteAccepted,
  quotePreapproved
}) => {
  const hasSizes = sizes_quantity && sizes_quantity.length > 0;

  return (
    <div className={'requested-quote ' + (hasSizes ? 'has-sizes' : '')}>
      <div className="requested-quote__header flex">
        <div className="title-qoute-req flex-grow">
          <div>Quote {quotePreapproved ? 'details' : 'request' } from {(currentUser.isBrand
            ? buyer_name
            : brand_name) || 'brand'}</div>
        </div>

        {!quoteAccepted && currentUser.isBrand && (
          <Button
            className="cta-edit-quote-request"
            category="grid-2 shrink"
            onClick={openUpdateRequestModal}
          >
            <IconEditPencil />
            <span>Edit</span>
          </Button>
        )}
      </div>

      <div className="flex container-block">
        <div className="flex details">
          <div className="flex meta-data">
            <div>
              <div className="title-req">Order quantity</div>
              <div>{quantity}pcs</div>
            </div>
            {price && (
              <div>
                <div className="title-req">Target price</div>
                <div>{(price && `INR ${price}`) || "--"}</div>
              </div>
            )}
            <div>
              <div className="title-req">Tentative production date</div>
              <div className="with-edit">
                {moment(dueDate).format(DATESTAMP_FORMAT)}
              </div>
            </div>
            <div>
              <div className="title-req">Preferred quote</div>
              <div>{type
                ? REQUEST_OPTIONS.type[type] + ' quote'
                : 'N/A'}
              </div>
            </div>
            <div>
              <div className="title-req">Production mode</div>
              <div>{production_mode
                ? REQUEST_OPTIONS.mode[production_mode]
                : 'N/A'}
              </div>
            </div>
            <div>
              <div className="title-req">Deadline</div>
              {closure_date
                ? <div className="with-edit">
                  {moment(closure_date).format(DATESTAMP_FORMAT)}
                </div>
                : <div>N/A</div>
              }
            </div>
          </div>
        </div>
      </div>

      <QuoteAccordion sizesQuantity={sizes_quantity} />
    </div>
  );
};

export default RequestedQuoteDetails;
