import React, { useEffect, useState } from 'react';
import { ReactComponent as MoveRightBlue } from 'assets/images/navigation/dir-right-blue.svg';
import './style.scss';

const DEF_SPEED = 250;

const AnimMove = ({
  count = 3,
  speed = DEF_SPEED,
}) => {
  const [intervalId, setIntervalId] = useState(0);
  const [indHigh, setIndHigh] = useState(0);

  useEffect(() => {
    return () => {
      clearInterval(intervalId);
    }
  }, []);

  useEffect(() => {
    const interval = speed < 10 ? DEF_SPEED : speed;

    if (intervalId) {
      clearInterval(intervalId);
    }

    const _intervalId = setInterval(() => {
      setIndHigh(indHigh => (indHigh + 1) % count);
    }, interval);

    setIntervalId(_intervalId);
  }, [speed]);

  return <div className="anim-move">
    {
      Array.from(Array(count)).map((k, ind) =>
        <MoveRightBlue key={ind} className={`icon ${indHigh === ind ? 'icon--high' : ''}`} />
      )
    }
  </div>
};

export default AnimMove;
