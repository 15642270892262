import React from "react";
import Downshift from "downshift";
import icDropdown from "../../assets/images/navigation/ic-arrow-drop-down.svg";

import PerfectScrollbar from "react-perfect-scrollbar";

const NotificationFilter = ({
  title,
  onChange,
  initialSelectedItem,
  filterOptions=[],
  selectedItem,
  marginRight = "0px",
  height = "250px",
  width = "138px",
}) => {
  return (
    <Downshift
      initialSelectedItem={initialSelectedItem}
      selectedItem={selectedItem}
      itemToString={item => item.label}
      onChange={selection => {
        onChange({value: selection.value.id});
        // alert(JSON.stringify(selection))
        // this.handleUserAuthorityChange(collab, selection);
      }}
      style={{ fontSize: "12px", fontWeight: 600 }}
    >
      {({
        getInputProps,
        getToggleButtonProps,
        getItemProps,
        getLabelProps,
        getMenuProps,
        isOpen,
        inputValue,
        highlightedIndex,
        selectedItem
      }) => {
        return (
          <div
            {...getToggleButtonProps()}
            type="none"
            style={{
              userSelect: "none",
              marginTop: "0.3rem",
              position: "relative",
              cursor: "pointer",
              border: "solid 1px var(--fill-grey-light2)",
              borderRadius: "8px",
              padding: "6px 12px",
              width: width,
              height: "48px",
              marginRight: marginRight
            }}
          >
            <span {...getLabelProps()} style={{ fontSize: "12px", color:'#333340' }}>
              {title}
            </span>
            <span className="flex" style={{ alignItems: "center" }}>
              <input
                style={{
                  border: "none",
                  backgroundColor: "transparent",
                  maxWidth: "80px",
                  fontWeight:'600',
                  color: 'var(--fill-locofast-black)'
                }}
                className="select-input-transparent"
                readOnly={true}
                disabled={true}
                {...getInputProps()}
              />
              <img
                src={icDropdown}
                alt="dropdown"
                style={{ marginLeft: "auto" }}
              />
            </span>

            {isOpen ? (
              <div
                className="select-pane"
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                <div style={{ height: height }}>
                  <PerfectScrollbar>
                    {filterOptions
                      // .filter(
                      //   item => item.label.city !== selectedItem.label.city
                      // )
                      .map((item, index) => {
                        return (
                          <div
                            {...getItemProps({
                              className:
                                "in-selection collection-collab__label_left_aigned flex",
                              index,
                              item,
                              key: item.label.id
                            })}
                            style={{ padding: "0" }}
                          >
                            <div
                              style={{
                                borderBottom: "1px solid #eee",
                                padding: " 5px 10px",
                                width: "100%",
                                lineHeight: "15px",
                                fontSize: "10px",
                                color: "var(--fill-locfast-black)"
                              }}
                            >
                              <div style={{fontSize:'12px'}}>{item.value.addressLine1}</div>
                              <div>{item.value.addressLine2}</div>
                              <div>{item.value.city}</div>
                              <div>{item.value.pinCode}</div>
                            </div>
                          </div>
                        );
                      })}
                  </PerfectScrollbar>
                </div>
              </div>
            ) : null}
          </div>
        );
      }}
    </Downshift>
  );
};

export default NotificationFilter;
