import React, { useState, useEffect, Fragment } from "react";
import { Field, FieldArray } from "redux-form";
import classnames from "classnames";
import s from "./style.module.scss";
import { components } from "react-select";
import { default as ReactSelect } from "react-select";
import {
  FILTER_FABRIC_QUALITY,
  FILTER_FABRIC_CATEGORY,
  FILTER_FABRIC_TYPE,
  FILTER_FABRIC_WEAVE,
  FILTER_FABRIC_COLORS,
  FILTER_SWATCH_AVAILABILITY,
  FILTER_FABRIC_IDEAL_FOR
} from "./../../../constants/SwatchLibrary";

import Form from "./../../UI/Form/Form";
import FileInput from "./../../UI/Form/FileVertical/FileVertical";
import Select from "./../../UI/Form/Select/Select";
import Input from "./../../UI/Form/Input/Input";
import CustomSelect from "./../../UI/Form/CustomSelect/CustomSelect";
import Button from './../../UI/Button/Button';

import { 
  required, 
  minValue, 
  maxLength,
  sanitised,
  customVendorValidate 
} from "./../../../helpers/form-validations";

import {
  selectStyles
} from "./select-styles";

const moqMinValue = minValue(1);
const maxLength25 = maxLength(25);
const { customSingleStyles, customMultiStyles, moreColorStyles } = selectStyles;

const FormRow = ({ children, label, required = false }) => (
  <div className={classnames("flex", s.addSwatchModal__input_grp)}>
    <div className=".f-sz-m-16">
      {label}
      {required && <sup>*</sup>}
    </div>
    {children}
  </div>
);

const FormGroup = ({ children}) => {
  return (
  <div className={classnames("flex", s.addSwatchModal__adjacent)}>
    {children}
  </div>
)}

const MyColorOption = ({ innerProps, innerRef, data, ...props }) => (
  <components.Option innerRef={innerRef} innerProps={innerProps} {...props}>
    <div className="flex" style={{ alignItems: "center" }}>
      <div
        style={{
          height: "20px",
          width: "20px",
          borderRadius: "10px",
          marginRight: "5px",
          background: data.color,
          border: "1px solid #bfbfbf"
        }}
      />
      <span className="capitalize">{data.label}</span>
    </div>
  </components.Option>
);

const MoreColorMultiValue = ({ innerProps, innerRef, data,children, ...props }) => (
  <components.MultiValue innerRef={innerRef} innerProps={innerProps} {...props}>
    <div className="flex" style={{ alignItems: "center" }}>
      <div
        style={{
          height: "24px",
          width: "24px",
          borderRadius: "50%",
          border: "1px solid #bfbfbf",
          background: data.color
        }}
      />
    </div>
  </components.MultiValue>
);

const dot = (color = "#ccc") => ({
  alignItems: "center",
  display: "flex",

  ":before": {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: "block",
    marginRight: 8,
    height: 20,
    width: 20,
    border: "1px solid #bfbfbf"
  }
});

const colorDot = (color = "#ccc") => ({
  alignItems: "center",
  display: "flex",

  ":before": {
    backgroundColor: color,
    borderRadius: '50%',
    content: '" "',
    display: "block",
    height: 24,
    width: 24,
    border: "1px solid #bfbfbf"
  }
})

const colourStyles = {
  control: styles => ({ ...styles, width: "182px", backgroundColor: "white" }),
  input: styles => ({ ...styles }),
  placeholder: styles => ({ ...styles }),
  singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
  multiValue: (styles, { data }) => ({ ...styles, ...colorDot(data.color) })
};



const getInitialValues = props => {
  const { initialValues = {} } = props;
  const {  fabricAvailability, color = null } = initialValues;
  return {
    iavailability: fabricAvailability,
    iavailabiltyValue: fabricAvailability != null ? fabricAvailability == 0 ? { label: "Made to order", value: "Minimum Order Quantity(MOQ)" } : { label: "Surplus", value: "Inventory" }  : "",
    ifabriccolor: color
  }
}

const AddSwatchModal = props => {

  const {
        iavailability,
        iavailabiltyValue,
        ifabriccolor
  }  = getInitialValues(props);
  

  const [availability, setAvailability] = useState(iavailability);
  const [fabricColor, setFabricColor] = useState(ifabriccolor);

  const renderExtraImages = ({ fields, meta: { touched, error, submitFailed } }) => (
    <div style={{ display: 'flex'}}>      
      {fields.map((member, index) =>
        <Field 
          key={index}
          name={`${member}.image`}
          component = {FileInput}
          headerText="Image"
          showTrash={true}
        />
      )}
      <div className={ s.addSwatchModal__add_image}>
        <Button onClick={() => fields.push({})}
          type="button"
          category="add medium">
          +
        </Button>
      </div>
    </div>
  )

  return (
    <Form
      {...props}
      initialValues={props.initialValues ? props.initialValues : { inStock: "1", swatchAvailable: "1" }}
      form="AddSwatch"
      className={s.addSwatchModal__scroll}
    >
      {({ changeFormValue, ...formProps }) => (
        <div className={s.addSwatchModal}>
          <FormGroup>
            <Field 
              name="image"
              component = {FileInput}
              validate={[required]}
              headerText="Image"
              validate={[required]}
            />
            <Field 
              name="rulerImage"
              component = {FileInput}
              validate={[required]}
              headerText="Ruler Image"
              validate={[required]}
            />
            <FieldArray name="extraImages" component={renderExtraImages}/>
          </FormGroup>
          <FormRow label="Color" required>
            <Field
              name="color"
              component={Select}
              styles={customSingleStyles}
              validate={[required]}
              options={FILTER_FABRIC_COLORS}
              selectProps={{
                components: { Option: MyColorOption },
                styles: colourStyles
              }}
              onChange={(color)=>{setFabricColor(color[0])}}
            />
          </FormRow>
          <FormRow label="More color options">
            <Field
              name="moreColors"
              component={CustomSelect}
              styles={moreColorStyles}
              options={FILTER_FABRIC_COLORS.filter(({value})=>value != fabricColor)}
              selectProps={{
                components: { MultiValue: MoreColorMultiValue, Option: MyColorOption },
                styles: moreColorStyles
              }}
              isMulti
            />
          </FormRow>
          <FormRow label="Fabric Composition" required>
            <Field
              name="fabricQuality"
              component={Select}
              options={FILTER_FABRIC_QUALITY}
              styles={customSingleStyles}
              validate={[required]}
            />
          </FormRow>
          <FormRow label="Pattern" required>
            <Field
              name="fabricType"
              component={Select}
              options={FILTER_FABRIC_TYPE}
              styles={customSingleStyles}
              validate={[required]}
            />
          </FormRow>
          <FormRow label="Weave">
            <Field
              name="weave"
              component={Select}
              options={FILTER_FABRIC_WEAVE}
              styles={customSingleStyles}
            />
          </FormRow>
          <FormRow label="Category" required>
            <Field
              name="category"
              component={Select}
              // Exclude ALL filter since here we only select specific tags
              options={FILTER_FABRIC_CATEGORY.slice(1)}
              styles={customMultiStyles}
              isMulti
              validate={[required]}
            />
          </FormRow>
          <FormRow label="Ideal for" required>
            <Field
              name="idealFor"
              component={Select}
              // Exclude ALL filter since here we only select specific tags
              options={FILTER_FABRIC_IDEAL_FOR}
              styles={customMultiStyles}
              isMulti
              validate={[required]}
            />
          </FormRow>
          <FormRow label="Fabric Category" required>
            <div className={`flex ${s.column}`}>
              <Field 
                name="fabricCategory"
                component={RadioGroup}
                validate={[required]}
                options = {
                  [
                    { title: 'Knit', value: 'knit' },
                    { title: 'Woven', value: 'woven' },
                  ]                 
                } 
              />  
            </div>
          </FormRow>
          <FormRow label="Yardage Available" required>
            <div className={`flex ${s.column}`}>
              <Field 
                  name="yardageAvailable"
                  component={RadioGroup}
                  validate={[required]}
                  options = {
                    [
                      { title: 'Yes', value: "1" },
                      { title: 'No', value: "0" }
                    ]                 
                  } 
                /> 
            </div>
          </FormRow>
          <FormRow label="Swatch Available" required>
            <div className={`flex ${s.column}`}>
              <Field 
                    name="swatchAvailable"
                    component={RadioGroup}
                    validate={[required]}
                    options = {
                      [
                        { title: 'Yes', value: "1" },
                        { title: 'No', value: "0" }
                      ]                 
                    } 
                /> 
            </div>
          </FormRow>
          
          <FormRow label="Availability" required>
            <Field
              name="fabricAvailability"
              component={Select}
              options={FILTER_SWATCH_AVAILABILITY}
              styles={customSingleStyles}
              validate={[required]}
              onChange={(event, newValue)=>{
                setAvailability(newValue)
              }}
              defaultValue={iavailabiltyValue}
            />
          </FormRow>
          { 
            availability && (availability === "Inventory" ? 
              <Fragment>
                <FormRow label={availability} required>
                  <Field 
                    name="quantity" 
                    component={Input} 
                    type="number" 
                    validate={[required, moqMinValue]}
                    placeholder="Enter only numbers"
                  />
                </FormRow>
                <FormRow label="Minimum Order quantity" required>
                  <Field
                    name="minimumOrderQuantity"
                    component={Input}
                    type="number"
                    validate={[required, moqMinValue]}
                    placeholder="Enter only numbers"
                  />
                </FormRow>
              </Fragment>
              :   
              <FormRow label={availability} required>
                  <Field 
                    name="quantity"
                    component={Input} 
                    type="number" 
                    validate={[required, moqMinValue]}
                    placeholder="Only numbers"
                  />
              </FormRow>)
          }
          <FormRow label="Unit of Measurement" required>
            <div className={`flex ${s.column}`}>
              <Field 
                name="unit"
                component={RadioGroup}
                validate={[required]}
                options = {
                  [
                    { title: 'Kg', value: 'kg' },
                    { title: 'Meter', value: 'meter' }
                  ]                 
                } 
              /> 
            </div>
          </FormRow>
          
          <FormRow label="Vendor and Prices" required>
            <Field component={VendorPricingFormField} vendorList={props.vendors} name="vendorPrices" submitFailed={props.submitFailed} validate={[required, customVendorValidate]}/>
          </FormRow>
          
          <FormRow label="In Stock" required>
            <div className={`flex ${s.column}`}>
              <Field 
                name="inStock"
                component={RadioGroup}
                validate={[required]}
                options = {
                  [
                    { title: 'Yes', value: "1" },
                    { title: 'No', value: "0" }
                  ]                    
                } 
              /> 
            </div>
          </FormRow>

          <FormRow label="Imported">
            <div className={`flex ${s.column}`}>
              <Field 
                name="imported"
                component={RadioGroup}
                options = {
                  [
                    { title: 'Yes', value: "1" },
                    { title: 'No', value: "0" }
                  ]                    
                } 
              /> 
            </div>
          </FormRow>
          
          {/* Optional Fields :: START */}
          <FormRow label="Thread Count">
            <Field name="threadCount" component={Input} type="text" validate={[maxLength25, sanitised]}/>
          </FormRow>
          <FormRow label="Construction">
            <Field name="construction" component={Input} type="text" validate={[maxLength25]}/>
          </FormRow>
          <FormRow label="Weight">
            <Field name="weight" component={Input} type="text" validate={[maxLength25]}/>
          </FormRow>
          
          <FormRow label="Fabric Width">
            <Field name="fabricWidth" component={Input} type="text" validate={[maxLength25]}/>
          </FormRow>
          <FormRow label="Finish">
            <Field name="finish" component={Input} type="text" validate={[maxLength25]}/>
          </FormRow>
          <FormRow label="Shrinkage">
            <Field name="shrinkage" component={Input} type="text" validate={[maxLength25]} />
          </FormRow>
          {/* Optional Fields :: END */}
        </div>
      )}
    </Form>
  );
};

const RadioGroup = props => {
  const { input, meta, options } = props;
  const hasError = meta.touched && meta.error;
  return (  
    <Fragment>
      <div className={`flex ${s['loco__radio']} ${s['loco__radio--left-align']}`}>
        {options.map(o => <div><label key={o.value}><input type="radio" {...input} value={o.value} checked={o.value === input.value} /> <span/>{o.title}</label></div>)}
      </div>
      {hasError && <span className="error">{meta.error}</span>}
    </Fragment>
    
  )
} 

const VendorPricingTable = ({
  selectedVendors = [],
  vendorList = [],
  changeVendorPrice = null,
  deleteVendor = null,
  submitFailed = false,
  value = {}
}) => {
  return (
    <div className="flex-col">
      {selectedVendors.map(({ vendorId, price }) => (
        <div key={vendorId} className="flex" style={{ padding: "10px 0", alignItems: "center" }}>
          <label htmlFor={"vendor" + vendorId}>
            {vendorList.find(({ id }) => Number(id) === Number(vendorId)).name}
          </label>
          <div style={{ marginLeft: "auto", maxWidth: "60%", marginRight: "30px" }}>
            {changeVendorPrice ? (
              <div className="flex">
                <input
                  id={"vendor" + vendorId}
                  name={"vendor" + vendorId}
                  style={{ padding: "5px" }}
                  placeholder="Add price here"
                  value={price}
                  onChange={evt => changeVendorPrice({ vendorId, price: evt.target.value })}
                  required
                  type="number"
                />
                &nbsp;&nbsp;
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    deleteVendor(vendorId);
                  }}
                >
                  x
                </span>
              </div>
            ) : (
              <Fragment>
                <div style={{ marginLeft: "11px" }}>{price}</div>
              </Fragment>
            )}
            {
              submitFailed && price == "" && <span className="error" style={{ marginTop: '8px'}}>This field cannot be empty</span>
            }
          </div>
        </div>
      ))}
    </div>
  );
};

const VendorPricingFormField = ({ input: { onChange, value }, vendorList, meta: { submitFailed}}) => {
  return (
    <div className="flex-col">
      <ReactSelect
        styles={customSingleStyles}
        placeholder="Select a vendor"
        options={vendorList
          .filter(
            ({ id }) => !(value instanceof Array ? value : []).map(({ vendorId }) => vendorId).includes(id)
          )
          .map(({ name, id }) => ({ label: name, value: id }))}
        onChange={selection => {
          onChange([...value, { vendorId: selection.value, price: "" }]);
        }}
      />
      {
        submitFailed && value == "" && 
        <p style={{
          color: 'var(--fill-error)'
        }}>This field cannot be empty</p>
      }
      <hr />
      <VendorPricingTable
        selectedVendors={value instanceof Array ? value : []}
        vendorList={vendorList}
        deleteVendor={deleteVendorId => {
          onChange(value.filter(({ vendorId }) => deleteVendorId !== vendorId));
        }}
        changeVendorPrice={({ vendorId: changedVendorId, price }) => {
          onChange(value.map(v => (changedVendorId === v.vendorId ? { ...v, price: price } : v)));
        }}
        submitFailed={submitFailed}
        value={value}
      />
    </div>
  );
};

export default AddSwatchModal;
