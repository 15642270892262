import React, { Component } from 'react';
import PropTypes from 'prop-types';

import UploadFileModal from '../UploadFileModal/index';
import Button from '../../UI/Button/Button';
import fileIcon from '../../../assets/images/editor/ic-attach-file.svg';
import '../../UI/Form/Input/Input.scss';
import './index.scss';
import { IconInfo } from '../../UI/Icons';

export default class Footer extends Component {
  static propTypes = {
    message: PropTypes.string,
    canMessage: PropTypes.bool,
    userDetails:  PropTypes.object.isRequired,
    receiverOrg: PropTypes.object.isRequired,
    onSendMessage: PropTypes.func.isRequired,
    style: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      message: props.message || '',
      showUploadImageModal: false,
    };
  }

  focus() {
    this.refTxtMessageField && this.refTxtMessageField.focus();
  }

  _toggleUploadImageModal = () => {
    const { showUploadImageModal } = this.state;
    this.setState({ showUploadImageModal: !showUploadImageModal });
  }

  _onChangeInput = event => {
    this.setState({ message: event.currentTarget.value });
  }

  _onKeyPress = event => {
    if ((event.which === 13 || event.keyCode === 13)
      && !event.shiftKey) {
      this._sendMessage(this.state.message);
      event.preventDefault();

      // clear message
      this.setState({
        message: '',
      });
    }
  }

  _sendMessage = (message, files) => {
    const { onSendMessage } = this.props;

    // if there are no messages or no files, no need to send message
    if ((!message || message.length === 0) && (!files || files.length === 0)) {
      return;
    }


    this.setState({
      message: (files && files.length > 0) ? this.state.message : '', // clear message if its not from file modal
      showUploadImageModal: false, // close upload image modal
    });

    onSendMessage({ message, files });
  }

  _renderCannotMessage = () => {
    const { userDetails = {} } = this.props;

    return <div className="messaging-footer messaging-footer--blocked">
      <IconInfo />
      <div className="text">
        You can no longer exchange messages with this {userDetails.isBrand ? 'factory' : 'brand'}
      </div>
    </div>
  }

  render() {
    const {
      receiverOrg,
      style,
      canMessage,
    } = this.props;
    let receiverName;

    if(receiverOrg && receiverOrg.name){
      receiverName = receiverOrg.name;
    }

    const { message, showUploadImageModal } = this.state;

    if (!canMessage) {
      return this._renderCannotMessage();
    }

    return (
      <div className={'messaging-footer'} style={style}>
        <div className="options options-left">
          <button
            type="button"
            className="attach"
            onClick={this._toggleUploadImageModal}
            disabled={!canMessage}
          >
            <img
              src={fileIcon}
              alt="file"
              className="upload__ico"
              style={{ height: "16px" }}
            />
          </button>
        </div>
        <textarea
          ref={(ref) => this.refTxtMessageField = ref}
          className="textarea"
          placeholder={`Message ${receiverName}`}
          onChange={(e) => this._onChangeInput(e)}
          onKeyPress={(e) => this._onKeyPress(e)}
          value={message}
          autoFocus={true}
          disabled={!canMessage}
        />
        <div className="options options-right">
          <Button
            category="blue-bg"
            disabled={!canMessage || !message || message.length === 0}
            onClick={() => this._sendMessage(message)}
          >
            Send
          </Button>
        </div>

        <UploadFileModal
          onSendMessage={this._sendMessage}
          onClose={this._toggleUploadImageModal}
          isVisible={showUploadImageModal}
          receiverOrg={receiverOrg}
        />

      </div>
    );
  }
}
