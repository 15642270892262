import React, { useState } from "react";
import moment from "moment";
import findIndex from "lodash/findIndex";
import find from "lodash/find";
import last from "lodash/last";
import isEmpty from "lodash/isEmpty";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import styles from "../Product/Product.module.scss";
import itemStyles from "./../SamplingTracker/SampleTrackerItem.module.scss";

import {
  getSmallImageUrl,
  getCoverImagePlaceholder,
  getDesignLink
} from "../../helpers/DesignHelpers";
import {
  TIME_DATE_FORMAT,
  DATESTAMP__SHORT_FORMAT
} from "../../constants/Date";
import {
  CLIENT,
  CLIENT_SUPER,
  LOCO_SUPER,
  PRODUCT_DEVELOPMENT,
  PRODUCTION_MERCHANT
} from "../../constants/Auth";
import { PRODUCTION_SAMPLE_STATUSES } from "constants/Sample";
import { TNA_ATTRIBUTES_ORDER, TNA_STATUS } from "constants/Tna";

import { getCurrentUserRole } from "./../../helpers/AuthHelper";
import DesignImageCard from "./../ProductionTracker/DesignImageCard";
import DateToolTip from "components/UI/DateToolTip";
import Button from "components/UI/Button/Button";
import plusIcon from "assets/images/content/ic-plus.png";

import MarkComplete from "components/Designs/TnaPlan/MarkCompleteButton";

import { openMarkCompleteModal } from "./service";
import TnaImageModal from "./TnaImageModal";
import actions from "store/actions";
import { flash } from "store/actions/CommonActions";
import { computePath } from "helpers/pathHelper";

const path = computePath();
const { SAMPLE_APPROVAL } = TNA_ATTRIBUTES_ORDER;

const Design = ({
  data: {
    id,
    designName,
    isSelected,
    brandId,
    brandName,
    collectionId,
    designId,
    factoryId,
    factoryName,
    imageUrl,
    sourceDesignId,
    productionStage,
    productionStageDate,
    notificationSentDate,
    notificationCategory,

    // new keys ** productionStage": "Production Cycle"
    tnaSubAttributeValue,
    tna,
    notificationTnaStage,
    notificationStatus,
    tnaAttributeId,
    sampleType,
    sampleId
  },
  data,
  userRole,
  getSampleForNotification,
  isBrandSelected,
  openModal,
  closeModal,
  getAllProducts,
  flash,
  openVersionHistoryModal,
  addNewCommint
}) => {
  const [imageDetails, setImageDetails] = useState({});
  const [showModal, setShowModal] = useState(false);

  let smallImageUrl;
  if (imageUrl) {
    smallImageUrl = getSmallImageUrl(imageUrl);
  } else {
    smallImageUrl = getCoverImagePlaceholder(data).src;
  }

  const currentUserRoleValue = getCurrentUserRole();
  let isAdmin = false,
    isBrand = false,
    isFactory = false;
  if (
    currentUserRoleValue === LOCO_SUPER ||
    currentUserRoleValue === PRODUCT_DEVELOPMENT
  ) {
    isAdmin = true;
  } else if (
    currentUserRoleValue === CLIENT_SUPER ||
    currentUserRoleValue === CLIENT
  ) {
    isBrand = true;
  } else {
    isFactory = true;
  }

  const dispatched = productionStage === "Dispatch";

  const dateDiff = moment(productionStageDate).diff(
    moment(tnaSubAttributeValue),
    "days"
  );

  let nextStage = null,
    currentStageData,
    currentStageDiff,
    tnaApproved = true;
  const flattenAttributes = [];

  if (data.tna && data.tna.status === TNA_STATUS.APPROVED) {
    const attributes = data.tna.tnaAttributes;
    attributes.forEach(row => {
      flattenAttributes.push(...row.data);
    });
    const currentIndex = findIndex(flattenAttributes, { id: tnaAttributeId });
    nextStage = flattenAttributes[currentIndex + 1];
    if (nextStage && nextStage.subCategory) {
      nextStage.subCategory = nextStage.subCategory.replace(
        "Preproduction Sample",
        "PP Sample"
      );
    }

    if (
      nextStage &&
      nextStage.categoryOrder === SAMPLE_APPROVAL.categoryOrder &&
      nextStage.attributeOrder === SAMPLE_APPROVAL.attributeOrder
    ) {
      // In case of sample approval, we cant fill the actual date so skip 1 index
      nextStage = flattenAttributes[currentIndex + 2];
    }
    currentStageData = find(flattenAttributes, { id: tnaAttributeId });
    currentStageDiff = currentStageData
      ? moment(currentStageData.attributeValue).diff(
        moment(currentStageData.subAttributeValue),
        "days"
      )
      : null;
  } else {
    tnaApproved = false;
  }

  const sampleApprovalStage =
    productionStage && productionStage.includes("dispatched");

  const openDispatchDetailsModal = () => {
    openModal("SAMPLING_ADD_COURIERS_DETAILS", {
      details: {
        data: {
          designId,
          sampleType,
          sampleStatus: null,
          id: sampleId,
          factoryId
        }
      },
      prevTnaDate: productionStageDate,
      viewSuccessState: true,
      afterSubmit: getAllProducts,
      brandName
    });
  };
  const openTnaProgressModal = () => {
    openModal("VIEW_TNA_PROGRESS", {
      title: designName,
      subtitle: !isFactory && {
        label: "Factory",
        value: factoryName
      },
      designId,
      sourceID: sourceDesignId,
      data: tna,
      design: data,
      showImageModal: imageDetails => {
        setImageDetails(imageDetails);
        setShowModal(true);
      },
      fetchTna: getAllProducts
    });
  };
  const MenuItems = [
    {
      title: "Design",
      content: (
        <DesignImageCard
          isFactory={isFactory}
          brandId={brandId}
          collectionId={collectionId}
          designId={designId}
          smallImageUrl={smallImageUrl}
          brandName={brandName}
          sourceDesignId={sourceDesignId}
          designName={designName}
          isCellV2={true}
          factoryId={factoryId}
        />
      )
    },
    {
      title: "Order Quantity",
      content: (
        <div className={`cell-v2 ${itemStyles.item__wrapper}`}>
          <div>
            {tna && (tna.orderQuantity ? `${tna.orderQuantity} pcs.` : "N/A")}
          </div>
          <div>
            {tna &&
              (tna.assocQuantity ? `Qty from POs: ${tna.assocQuantity}` : "")}
          </div>
        </div>
      )
    },
    {
      title: "Brand",
      disabledFor: [CLIENT, CLIENT_SUPER],
      content: (
        <div className="cell-v2 overflow-text">
          <span data-tip={brandName}>{brandName}</span>
        </div>
      )
    },
    ...(((isBrand || isAdmin || currentUserRoleValue === PRODUCTION_MERCHANT) && [
      {
        title: "Factory",
        content: (
          <div className="overflow-ellipsis cell-v2">
            <div>
              <span data-tip={factoryName}>{factoryName || "N/A"}</span>
            </div>
          </div>
        )
      }
    ]) ||
      []),
    {
      title: "Status",
      content: (
        <React.Fragment>
          <div className="flex status-row">
            <div className={`${styles.design__status} cell-v2`}>
              <div
                className={`status-grid
                  date-tooltip-parent
                  ${tnaSubAttributeValue
                    ? dateDiff > 0
                      ? "red"
                      : "green"
                    : currentStageDiff > 0
                      ? "red"
                      : productionStage // productionStage is falsy in case production hasnt started.
                        ? "green"
                        : "orange"
                  }`}
              >
                {isFactory && !dispatched ? (
                  <span className="link-hover" onClick={openTnaProgressModal}>
                    {dispatched ? "Dispatched" : productionStage}
                  </span>
                ) : (
                    <span>
                      {dispatched
                        ? "Dispatched"
                        : productionStage ||
                        (tna &&
                          tna.status === TNA_STATUS.APPROVED &&
                          "Yet to start")}{" "}
                    </span>
                  )}

                {(tnaSubAttributeValue || dispatched) && (
                  <DateToolTip>
                    <div
                      className={`date-tip ${dateDiff > 0 ? "delayed" : "ontime"
                        }`}
                    >
                      {Math.abs(dateDiff)} days{" "}
                      {dateDiff > 0 ? "delayed" : "early"}
                    </div>
                    <div className="due-info multi-dates">
                      <div>
                        Plan date:{" "}
                        {moment(tnaSubAttributeValue).format(
                          DATESTAMP__SHORT_FORMAT
                        )}
                      </div>
                      <div>
                        Completed on:{" "}
                        {moment(productionStageDate).format(
                          DATESTAMP__SHORT_FORMAT
                        )}
                      </div>
                    </div>
                  </DateToolTip>
                )}
              </div>
              {/* If factory and production lot is not created - mark complete cta will be visible */}
              {isFactory && !isAdmin && !dispatched && tnaApproved ? (
                <div
                  className={`next-stage ${!productionStage ? "no-prev-stage" : ""
                    }`}
                >
                  {nextStage &&
                    (!productionStage ? (
                      // In case production is at first stage, add link to the the it.
                      <span
                        className="link-hover"
                        onClick={openTnaProgressModal}
                      >
                        {nextStage.subCategory}
                      </span>
                    ) : (
                        nextStage.subCategory
                      ))}
                  {nextStage &&
                    nextStage.subCategory &&
                    nextStage.subCategory.includes("Sample dispatch") &&
                    isFactory ? (
                      <Button
                        category="blue-bg"
                        onClick={openDispatchDetailsModal}
                      >
                        <img src={plusIcon} alt="add" className="plus-icon" />
                      Add dispatch details
                      </Button>
                    ) : sampleApprovalStage && !data.tna.skipApproval ? null : (
                      <MarkComplete
                        handleClick={() => {
                          openMarkCompleteModal(
                            openModal,
                            closeModal,
                            nextStage.subCategory,
                            data.tna,
                            flattenAttributes,
                            nextStage,
                            updatedAttributes => {
                              if (
                                !isEmpty(last(updatedAttributes).attributeValue)
                              ) {
                                flash({
                                  message: `Production lot for ${data.designName} dispatched`,
                                  type: "action"
                                });
                              }
                              getAllProducts();
                            },
                            {
                              minDate: productionStageDate || undefined,
                              maxDate: moment().format(TIME_DATE_FORMAT)
                            }
                          );
                        }}
                      />
                    )}
                </div>
              ) : !tnaApproved ? (
                isFactory ? (
                  tna.status === TNA_STATUS.RESUBMITTED ? "TNA resubmission requested" : tna.status === TNA_STATUS.SUBMITTED ? 'TNA submitted' : 'TNA requested'
                ) : (
                    ((tna &&
                      (tna.status === TNA_STATUS.TEMPLATE ||
                        tna.status === TNA_STATUS.CREATED)) ||
                      !tna)
                      ? "Planned TNA awaited"
                      : <Link
                        target="_blank"
                        to={`/${path}/collections/${collectionId}/designs/${designId}/?tab=designtna`}
                      >
                        <Button category="blue-bg">
                          <div>View planned TNA</div>
                        </Button>
                      </Link>
                  )
              ) : (
                    // If tna is created, then the track/view progress cta is visible
                    <div className="flex link-hover">
                      <div
                        className="show_btn"
                        onClick={() => {
                          if (tna.status !== TNA_STATUS.APPROVED) {
                            const link = getDesignLink(data, isFactory, 'designtna');
                            window.open(link, '_blank');
                            return;
                          }

                          openModal("VIEW_TNA_PROGRESS", {
                            title: designName,
                            subtitle: !isFactory && {
                              label: "Factory",
                              value: factoryName
                            },
                            designId,
                            sourceID: sourceDesignId,
                            data: tna,
                            design: data,
                            showImageModal: imageDetails => {
                              setImageDetails(imageDetails);
                              setShowModal(true);
                            },
                            fetchTna: getAllProducts
                          });
                        }}
                      >
                        {dispatched
                          ? "View progress"
                          : productionStage
                            ? "Track progress"
                            : "View planned TNA"}
                      </div>
                    </div>
                  )}
            </div>
            {showModal && (
              <TnaImageModal
                imageUrl={imageDetails.imageUrl}
                date={imageDetails.date}
                fileName={imageDetails.fileName}
                title={imageDetails.title}
                uploadedBy={imageDetails.uploadedBy}
                onClose={() => {
                  setShowModal(false);
                  setImageDetails({});
                }}
              />
            )}
            {sampleApprovalStage && isBrand && !data.tna.skipApproval && (
              <Button
                onClick={() => {
                  openModal("UPDATE_SAMPLE_STATUS", {
                    commentDetails: {
                      commitMsg: false,
                      request: {
                        sampleType,
                        factoryName,
                        sampleId,
                        collectionId,
                        designId,
                        factoryId
                      },
                      sampleStatusTypes: PRODUCTION_SAMPLE_STATUSES,
                      cardData: {
                        name: designName,
                        statusString: "Production",
                        image: smallImageUrl
                      },
                      designId: designId,
                      withComment: true,
                      update: getAllProducts
                    }
                  });
                }}
                category="blue-bg"
              >
                Update Status
              </Button>
            )}
            {isFactory &&
              ((tna && tna.status !== TNA_STATUS.APPROVED) || !tna) && (
                <Link
                  target="_blank"
                  to={`/${path}/brands/${currentUserRoleValue === PRODUCTION_MERCHANT ? factoryId : brandId}/collections/${collectionId}/designs/${designId}/?tab=designtna`}
                >
                  <Button category="blue-bg">
                    <div>{
                      (tna.status === TNA_STATUS.TEMPLATE || tna.status === TNA_STATUS.CREATED)
                        ? "Create TNA"
                        : tna.status === TNA_STATUS.RESUBMITTED
                          ? "Revise TNA"
                          : "View TNA"
                    }</div>
                  </Button>
                </Link>
              )}
          </div>
        </React.Fragment>
      )
    }
  ];

  return (
    <div
      className="card-custom-product"
      style={{
        gridTemplateColumns: isBrand
          ? "repeat(3, 1fr) 1.3fr"
          : (!isAdmin && currentUserRoleValue !== PRODUCTION_MERCHANT)
            ? "repeat(3, 1fr) 1.2fr"
            : "repeat(5, 1fr)",
        padding: isFactory ? "12px 20px" : "16px 20px"
      }}
    >
      {MenuItems.filter(item =>
        item.disabledFor
          ? item.disabledFor.includes(userRole)
            ? false
            : true
          : true
      ).map((menu, index) => (
        <div className={itemStyles.item__wrapper} key={index}>
          {menu.content}
        </div>
      ))}
    </div>
  );
};

const mapDispatchToProps = {
  flash,
  addNewCommint: details => actions.addNewCommintToSamplingCommints(details)
};

export default connect(null, mapDispatchToProps)(Design);
