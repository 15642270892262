import moment from "moment";
import isArray from "lodash/isArray";

import { formatSeconds } from "constants/Date.js";
import { formatNumber } from "helpers/i18nHelper";
import { DATESTAMP__SHORT_FORMAT } from "../constants/Date";
import { CURRENCY } from "constants/Design";

const DEFAULT = "--";

export const transformCosting = list => {
  if (list.length === 0) return [];
  return list.map(item => {
    const {
      name,
      createdOn,
      collectionName,
      factoryName,
      quantity,
      targetPrice,
      approvedQuote,
      lowestQuote,
      creatorName
    } = item;
    return [
      {
        value: name,
        maxWidth: "180"
      },
      {
        value: moment(createdOn).format("ll")
      },
      {
        value: collectionName,
        maxWidth: "150"
      },
      {
        value: factoryName || DEFAULT,
        maxWidth: "150"
      },
      {
        value: quantity ? `${formatNumber(quantity)} pcs` : DEFAULT
      },
      {
        value: targetPrice ? `INR ${formatNumber(targetPrice)}` : DEFAULT
      },
      {
        value: approvedQuote ? `INR ${formatNumber(approvedQuote)}` : DEFAULT
      },
      {
        value: lowestQuote ? `INR ${formatNumber(lowestQuote)}` : DEFAULT
      },
      {
        value: creatorName,
        maxWidth: "135"
      }
    ];
  });
};

export const transformSampling = list => {
  if (list.length === 0) return [];
  return list.map(item => {
    const {
      designName,
      sampleCreatedOn,
      collectionName,
      factoryName,
      sampleType,
      samplingTimeInDays,
      currentStatus,
      onTimeStatus,
      creatorName
    } = item;
    return [
      {
        value: designName,
        maxWidth: "180"
      },
      {
        value: moment(sampleCreatedOn).format("ll")
      },
      {
        value: collectionName,
        maxWidth: "150"
      },
      {
        value: factoryName || DEFAULT,
        maxWidth: "150"
      },
      {
        value: sampleType,
        maxWidth: "150"
      },
      {
        value: samplingTimeInDays ? `${samplingTimeInDays} days` : DEFAULT
      },
      {
        value: currentStatus
      },
      {
        value: onTimeStatus
      },
      {
        value: creatorName,
        maxWidth: "135"
      }
    ];
  });
};

export const transformProduction = list => {
  if (list.length === 0) return [];
  return list.map(item => {
    const {
      name,
      createdOn,
      collectionName,
      factoryName,
      productionQuantity,
      productionStatus,
      onTimeStatus,
      creatorName,
      productionTAT
    } = item;
    return [
      {
        value: name,
        maxWidth: "180"
      },
      {
        value: moment(createdOn).format("ll")
      },
      {
        value: collectionName,
        maxWidth: "150"
      },
      {
        value: factoryName || DEFAULT,
        maxWidth: "150"
      },
      {
        value: productionQuantity
          ? `${formatNumber(productionQuantity)} pcs`
          : DEFAULT
      },
      {
        value: productionTAT ? `${formatSeconds(productionTAT)}` : DEFAULT
      },
      {
        value: productionStatus
      },
      {
        value: onTimeStatus
      },
      {
        value: creatorName,
        maxWidth: "135"
      }
    ];
  });
};

export const transformActiveFactories = list => {
  if (list.length === 0) return [];
  return list.map(item => {
    const {
      factoryName,
      designCount,
      designsInQuoteCount,
      designsInSamplingCount,
      designsInProductionCount,
      productionQuantity,
      factoryFirstActivityOn,
      factoryLastActivityOn
    } = item;
    return [
      {
        value: factoryName,
        maxWidth: "180"
      },
      {
        value: `${designCount} designs`
      },
      {
        value: `${designsInQuoteCount} designs`
      },
      {
        value: `${designsInSamplingCount} designs`
      },
      {
        value: `${designsInProductionCount} designs`
      },
      {
        value: productionQuantity
          ? `${formatNumber(productionQuantity)} pcs`
          : DEFAULT
      },
      {
        value: factoryFirstActivityOn
          ? moment(factoryFirstActivityOn).format("ll")
          : DEFAULT
      },
      {
        value: factoryLastActivityOn
          ? moment(factoryLastActivityOn).format("ll")
          : DEFAULT
      }
    ];
  });
};

export const transformFactoryPerformance = list => {
  if (list.length === 0) return [];
  return list.map(item => {
    const {
      factoryName,
      ordersCount,
      designsCount,
      sampleAcceptanceRate,
      avgQuoteTAT,
      avgSamplingTAT,
      avgProductionTAT,
      avgRating,
      otif
    } = item;

    return [
      {
        value: factoryName,
        maxWidth: "180"
      },
      {
        value: `${ordersCount || 0} orders`
      },
      {
        value: `${designsCount || 0} designs`
      },
      {
        value: avgRating
      },
      {
        value: sampleAcceptanceRate ? `${sampleAcceptanceRate}%` : DEFAULT
      },
      {
        value: avgQuoteTAT ? `${formatSeconds(avgQuoteTAT)}` : DEFAULT
      },
      {
        value: avgSamplingTAT ? `${formatSeconds(avgSamplingTAT)}` : DEFAULT
      },
      {
        value: avgProductionTAT ? `${formatSeconds(avgProductionTAT)}` : DEFAULT
      },
      {
        value: otif ? `${otif}%` : DEFAULT
      }
    ];
  });
};

export const transformBuyerPerformance = list => {
  if (list.length === 0) return [];
  return list.map(item => {
    const {
      name,
      designsCreatedCount,
      totalVolume,
      averagePurchaseCost,
      averageSampleApprovalTime
    } = item;

    return [
      {
        value: name,
        maxWidth: "150"
      },
      {
        value: `${designsCreatedCount || 0} designs`
      },
      {
        value: totalVolume ? `${formatNumber(totalVolume)}` : DEFAULT
      },
      {
        value: averagePurchaseCost
          ? `INR ${formatNumber(averagePurchaseCost)}`
          : DEFAULT
      },
      {
        value: averageSampleApprovalTime
          ? `${formatSeconds(averageSampleApprovalTime)}`
          : DEFAULT
      }
    ];
  });
};

export const transformOrders = list => {
  if (list.length === 0) return [];
  return list.map(item => {
    const {
      poNumber,
      totalAmount,
      totalQuantity,
      factoryName,
      onTimeStatus,
      orderCompleteDispatchStatus
    } = item;
    return [
      {
        value: poNumber,
        maxWidth: "150"
      },
      {
        value: totalAmount ? `INR ${formatNumber(totalAmount)}` : DEFAULT
      },
      {
        value: totalQuantity ? `${formatNumber(totalQuantity)} Pcs.` : DEFAULT
      },
      {
        value: factoryName || DEFAULT,
        maxWidth: "150"
      },
      {
        value: orderCompleteDispatchStatus
      },
      {
        value: onTimeStatus
      }
    ];
  });
};

export const transformDesigns = (list, metadata) => {
  if (list.length === 0) return [];
  return list.map(item => {
    const {
      id,
      name,
      cover_pic_url,
      source_design_id,
      creatorName,
      createdOn,
      collectionId,
      collectionName,
      category,
      sub_category,
      additionalFields
    } = item;

    const transFormedAdditionalFields = metadata && metadata.additionalFields ?
      metadata.additionalFields.map(maf => ({
        value: additionalFields[maf.field_id],
        maxWidth: "150",
      }))
      :
      [];

    return [
      {
        // url: `/brand/collections/${collectionId}/designs/${id}`,
        value: name,
        maxWidth: "150"
      },
      {
        url: cover_pic_url,
        label: "View",
        value: cover_pic_url,
      },
      {
        value: source_design_id,
        maxWidth: "150"
      },
      {
        value: creatorName,
        maxWidth: "150"
      },
      {
        value: moment(createdOn).format(DATESTAMP__SHORT_FORMAT)
      },
      {
        value: collectionName,
        maxWidth: "150"
      },
      {
        value: category,
        maxWidth: "150"
      },
      {
        value: sub_category,
        maxWidth: "150"
      },
      ...transFormedAdditionalFields
    ];
  });
};

export const transformMaterials = (list, { reportType }) => {
  switch (reportType) {
    case 'inventory':
      return transformMaterialInventories(list);

    case 'stockio':
      return transformMaterialStockIO(list);

    default:
      return [];
  }
};

export const transformMaterialInventories = (list) => {
  if (list.length === 0) return [];
  return list.map(item => {
    const {
      materialName,
      sourceMaterialId,
      lastInwardDate,
      lastInwardQuantity,
      lastInwardPrice,
      lastOutwardDate,
      lastOutwardQuantity,
      availableQuantity,
      unitShortName,
      materialTypeName,
    } = item;
    return [
      {
        value: materialName,
        maxWidth: "190"
      },
      {
        value: materialTypeName,
      },
      {
        value: sourceMaterialId,
        maxWidth: "110"
      },
      {
        value: lastInwardDate && moment(lastInwardDate).format(DATESTAMP__SHORT_FORMAT),
        maxWidth: '150'
      },
      {
        value: lastInwardQuantity ? `${lastInwardQuantity} ${unitShortName}` : null,
        maxWidth: '150'
      },
      {
        value: lastInwardPrice ? `${CURRENCY[1]} ${lastInwardPrice} / ${unitShortName}` : null,
        maxWidth: '150'
      },
      {
        value: lastOutwardDate && moment(lastOutwardDate).format(DATESTAMP__SHORT_FORMAT),
        maxWidth: '150'
      },
      {
        value: lastOutwardQuantity ? `${lastOutwardQuantity} ${unitShortName}` : null,
        maxWidth: '150'
      },
      {
        value: availableQuantity ? `${availableQuantity} ${unitShortName}` : null,
        maxWidth: '150'
      },
    ];
  });
};

export const transformMaterialStockIO = (list) => {
  if (list.length === 0) return [];

  return list.map(item => {
    const {
      materialName,
      sourceMaterialId,
      date,
      movement,
      factoryName,
      vendorName,
      quantity,
      price,
      unitShortName,
      materialTypeName,
      designId,
      designName,
      collectionId,
    } = item;

    return [
      {
        value: materialName,
        maxWidth: "190"
      },
      {
        value: materialTypeName,
      },
      {
        value: sourceMaterialId,
        maxWidth: "110"
      },
      {
        value: date && moment(date).format(DATESTAMP__SHORT_FORMAT),
        maxWidth: '150'
      },
      {
        value: movement,
      },
      {
        value: factoryName || vendorName,
        maxWidth: '150'
      },
      {
        value: quantity ? `${quantity} ${unitShortName}` : null,
        maxWidth: '150'
      },
      {
        value: price ? `${CURRENCY[1]} ${price} / ${unitShortName}` : null,
        maxWidth: '150'
      },
      {
        url: designId && `/brand/collections/${collectionId}/designs/${designId}`,
        label: designName,
        value: designName,
        maxWidth: '150'
      }
    ];
  });
};

const REPORT_TRANSFORMERS = {
  costing: transformCosting,
  sampling: transformSampling,
  production: transformProduction,
  activeFactories: transformActiveFactories,
  factoryPerformance: transformFactoryPerformance,
  buyerPerformance: transformBuyerPerformance,
  orders: transformOrders,
  designs: transformDesigns,
  material: transformMaterials,
};

export const computeCsvBlob = (rawContent, reportType, headings, metadata) => {
  let csvBlob = "";
  let content = REPORT_TRANSFORMERS[reportType](rawContent, metadata);
  content.unshift(headings);
  for (let i = 0; i < content.length; i++) {
    let value = content[i];

    for (let j = 0; j < value.length; j++) {
      let innerValue =
        (value[j] === null || value[j].value === null || value[j].value === '')
          ? ""
          : value[j].value
            ? value[j].value.toString()
            : value[j].toString();
      let result = innerValue.replace(/"/g, '""');
      if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
      if (j > 0) csvBlob += ",";
      csvBlob += result;
    }

    csvBlob += "\n";
  }

  return csvBlob;
};

export const transformFilters = filters => {
  const transformedFilters = {};
  if (filters) {
    Object.entries(filters).forEach(([key, value]) => {
      if (isArray(value)) {
        transformedFilters[key] = value.map(({ id, name }) => ({
          label: name,
          value: String(id)
        }));
      }
    });
  }
  return transformedFilters;
};
