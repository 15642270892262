import React from "react";
import { LOCO_SUPER, CLIENT_SUPER, CLIENT } from "constants/Auth";
import { FACTORY_ADMIN, FACTORY_MANAGER } from "../../constants/Auth";

import Button from "../UI/Button/Button";

const headings = {
  production: userRole => {
    switch (userRole) {
      case CLIENT_SUPER:
      case CLIENT:
        return {
          content: ["Design", "Order Quantity", "Factory", "Status"],
          grid: "repeat(3,1fr) 1.3fr"
        };
      case FACTORY_ADMIN:
      case FACTORY_MANAGER:
        return {
          content: ["Design", "Order Quantity", "Brand", "Status"],
          grid: "repeat(3,1fr) 1.2fr"
        };
      default:
        return {
          content: ["Design", "Order Quantity", "Brand", "Factory", "Status"],
          grid: "0.8fr 0.82fr 0.82fr 0.75fr 1fr"
        };
    }
  },
  sampling: userRole => {
    switch (userRole) {
      case CLIENT_SUPER:
      case CLIENT:
        return {
          content: ["Design", "Requested sample", "Factory", "Status"],
          grid: "repeat(4,1fr) 150px"
        };
      case FACTORY_ADMIN:
      case FACTORY_MANAGER:
        return {
          content: [
            "Design",
            "Brand",
            "Requested sample",
            "due date",
            "Status"
          ],
          grid: "repeat(5,1fr) 150px"
        };
      default:
        return {
          content: ["Design", "Brand", "Requested sample", "Factory", "Status"],
          grid: "repeat(5,1fr) 150px"
        };
    }
  },
  details: userRole => {
    switch (userRole) {
      case FACTORY_ADMIN:
      case FACTORY_MANAGER:
        return {
          content: ["Type & Size", "Due Date", "Status", "", ""],
          grid: "1fr 1.5fr 1fr 140px 1fr"
        };
      default:
        return {
          content: ["Type & Size", "Factory / Due Date", "Status", "", ""],
          grid: "1fr 1.5fr 1fr 140px 1fr"
        };
    }
  },
  quoteRequest: () => {
    return {
      content: ["Factory", "Specialization", "Location", "Machines"],
      grid: "2fr 1fr 1fr 100px"
    }
  }
};

const NavHeaders = ({
  userRole = LOCO_SUPER,
  tab = "production",
  slice = 0,
  isFactory
}) => {
  const headers = headings[tab](userRole) || [];
  return (
    <div className="table ">
      <div
        className="production-table"
        style={{ gridTemplateColumns: headers.grid }}
      >
        {headers.content.map(heading => (
          <div className="table__header_item">{heading}</div>
        ))}
      </div>
    </div>
  );
};

export default NavHeaders;
