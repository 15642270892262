import React from 'react';
import PropTypes from 'prop-types';

import ModalWithChildren from '../../UI/Modal/ModalWithChildren';
import Button from '../../UI/Button/Button';
import File from '../../UI/Form/File/File';
import Input from '../../UI/Form/Input/CustomInput';

export default class UploadFileModal extends React.Component {
  static propTypes = {
    isVisible: PropTypes.bool.isRequired,
    receiverOrg: PropTypes.object.isRequired,
    message: PropTypes.string,
    files: PropTypes.array,
    style: PropTypes.object,
    onSendMessage: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      message: props.message || [],
      files: props.files || [],
    };
  }

  _onSelectFile = (file) => {
    if (file) {
      this.setState({ files: [file] });
    }
    else {
      this.setState({ files: [] });
    }
  }

  _onChangeMessage = event => {
    this.setState({ message: event.currentTarget.value });
  }

  render() {
    const { receiverOrg, onSendMessage, onClose, isVisible } = this.props;
    const { message, files, style } = this.state;
    let receiverName = receiverOrg ? receiverOrg.name : '';

    return <ModalWithChildren
      title="Send File"
      isOpen={isVisible}
      onClose={onClose}
      style={style}
      footer={<Button
        category="blue-bg align-right"
        disabled={message.length === 0 || files.length === 0}
        onClick={() => onSendMessage(message, files)}>Send File</Button>}
    >
      <File
        onChange={this._onSelectFile}
        hasAdvancedOptions={false}
      />
      <div className="input-grp">
        <Input type="text"
          label="Enter Message"
          name="file_title"
          onChange={this._onChangeMessage}
          value={message}
          placeholder={`Message ${receiverName}`}
        />
      </div>
    </ModalWithChildren>;
  }
}
