import React, { Component } from "react";
import isEmpty from "lodash/isEmpty";
import { Link } from "react-router-dom";

import designAddedImg from "assets/images/dashboard/icon-col.svg";
import quoteAwaitedImg from "assets/images/dashboard/icon-2.svg";
import samplingImg from "assets/images/dashboard/sampling.svg";
import productionImg from "assets/images/dashboard/production.svg";

import Loader from "components/UI/InlineLoader";

import { computePath } from "helpers/pathHelper";
import {
  SAMPLING_FILTERS,
  PRODUCTION_FILTERS
} from "constants/Design";

import {
  MAP_TABS_BRAND
} from "constants/Quote";

export default class Cards extends Component {
  render() {
    const { summary } = this.props;

    if (isEmpty(summary)) {
      return <Loader />;
    }

    const { designs, quotes_awaited, sampling, production } = summary;

    return (
      <div className="dashboard-cards">
        <div className="dashboard-cards-container">
          {/* design card starts here */}
          <div className="dashboard-cards-design">
            <div className="">
              <div className="dashboard-cards-content">
                <div className="cards-content-left">
                  <div className="cards-content-title">designs added</div>
                  <div className="cards-content-number">
                    {designs["total"]}
                    <span className="span-normal">this month</span>
                  </div>
                </div>
                <div className="cards-content-right">
                  <img src={designAddedImg} />
                </div>
              </div>
            </div>
            <div className="">
              <div className="dashboard-cards-end text-float-left">
                {designs["total"] === 0 ? (
                  <div>
                    <Link to="../collections">
                      <div className="cards-title-view">Add Design</div>
                    </Link>
                  </div>
                ) : (
                  <>
                    {Boolean(designs["deceleration"]) ? (
                      <div className={`cards-end-num red`}>
                        <i class="fa fa-caret-down mr-2" />{" "}
                        {designs["diff_percentage"]}%
                      </div>
                    ) : (
                      <div className={`cards-end-num green`}>
                        <i class="fa fa-caret-up mr-2" />{" "}
                        {designs["diff_percentage"]}%
                      </div>
                    )}
                    <div className="cards-end-title">vs last month</div>
                  </>
                )}
              </div>
            </div>
          </div>
          {/* design card end here */}

          {/*  quote awaited  card starts here */}
          <div className="dashboard-cards-design">
            <div className="">
              <div className="dashboard-cards-content">
                <div className="cards-content-left">
                  <div className="cards-content-title">quotes awaited</div>
                  <div className="cards-content-number">{quotes_awaited}</div>
                </div>
                <div className="cards-content-right">
                  <img src={quoteAwaitedImg} />
                </div>
              </div>
            </div>
            {quotes_awaited > 0 && (
              <div className="">
                <Link
                  className="dashboard-cards-end"
                  to={`/${computePath()}/quotes/?tab=${MAP_TABS_BRAND.requested.tabUrl}`}
                >
                  <div className="cards-title-view">View All</div>
                </Link>
              </div>
            )}
          </div>

          {/*  quote awaited  card end here */}
          {/*  sampling card start here */}
          <div className="dashboard-cards-design">
            <div className="">
              <div className="dashboard-cards-content">
                <div className="cards-content-left">
                  <div className="cards-content-title">in sampling</div>
                  <Link
                    to="../sampling/in-progress/"
                    className="cards-content-number blue-on-hover"
                  >
                    {sampling["total"]}
                  </Link>
                </div>
                <div className="cards-content-right">
                  <img src={samplingImg} />
                </div>
              </div>
            </div>
            <div className="">
              <div className="dashboard-cards-end">
                <div>
                  <div className="cards-end-title dot-green">On time</div>
                  <div className="cards-end-title dot-red">Delayed</div>
                </div>
                <div>
                  <div className="status-number">
                    {sampling.total > 0 ?
                      <Link
                        to={{
                          pathname: "../sampling/in-progress/",
                          pathFilters: {
                            [SAMPLING_FILTERS.dispatchStatus]: 5 // 5 denotes on-time
                          }
                        }}
                        className="status-number blue-on-hover"
                      >
                        {sampling["on_time"]}
                      </Link>
                      : "-"}
                  </div>
                  <div className="status-number">
                    {sampling.total > 0 ?
                      <Link
                        to={{
                          pathname: "../sampling/in-progress/",
                          pathFilters: {
                            [SAMPLING_FILTERS.dispatchStatus]: 2 // 2 denotes delayed
                          }
                        }}
                        className="status-number blue-on-hover"
                      >
                        {sampling["delay"]}
                      </Link>
                       : "-"}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*  sampling card end here */}
          {/*  production card start here */}
          <div className="dashboard-cards-design">
            <div className="">
              <div className="dashboard-cards-content">
                <div className="cards-content-left">
                  <div className="cards-content-title">in production</div>
                  <Link
                    to="../production/in-progress/"
                    className="cards-content-number blue-on-hover"
                  >
                    {production["total"]}
                  </Link>
                </div>
                <div className="cards-content-right">
                  <img src={productionImg} />
                </div>
              </div>
            </div>
            <div className="">
              <div className="dashboard-cards-end">
                <div>
                  <div className="cards-end-title dot-orange">Requested</div>
                  <div className="cards-end-title dot-green">On time</div>
                  <div className="cards-end-title dot-red">Delayed</div>
                </div>
                <div>
                <div className="status-number">
                    {production["total"] > 0 ?
                    <Link
                      to={{
                        pathname: "../production/in-progress/",
                        pathFilters: {
                          [PRODUCTION_FILTERS.productionStage]: "Requested"
                        }
                      }}
                      className="status-number blue-on-hover"
                    >
                      {production["requested"]}
                    </Link>
                   : "-"}
                  </div>
                  <div className="status-number">
                    {production["total"] > 0 ? (
                        <Link
                          to={{
                            pathname: "../production/in-progress/",
                            pathFilters: {
                              [PRODUCTION_FILTERS.dueDate]: 4 // 4 denotes on-time
                            }
                          }}
                          className="status-number blue-on-hover"
                         >
                           {production["on_time"]}
                         </Link>
                    ) : (
                      "-"
                    )}
                  </div>
                  <div className="status-number">
                    {production["total"] > 0 ?
                    <Link
                      to={{
                        pathname: "../production/in-progress/",
                        pathFilters: {
                          [PRODUCTION_FILTERS.dueDate]: 2 // 2 denotes delayed
                        }
                      }}
                      className="status-number blue-on-hover"
                    >
                      {production["delay"]}
                    </Link>
                   : "-"}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*  production card end here */}
        </div>
      </div>
    );
  }
}
