import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../../UI/Button/Button';
import OrderSummary from '../../OrderSummary/index';
import EmptyScreen from '../../../../UI/EmptyScreen';
import FactoryImg from '../../../../../assets/images/dashboard/factories.svg';
import NoBrandImg from '../../../../../assets/images/emptyPages/no-brand.svg';

import './style.scss';

export default class BrandSummary extends React.Component {
  static propTypes = {
    brand: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
  };

  _handleSubmit = () => {
    const { onSubmit, brand } = this.props;

    if (typeof (onSubmit) === 'function' && brand) {
      onSubmit({
        brand
      });
    }
  }

  render() {
    const { brand } = this.props;

    return <OrderSummary
      brand={brand}
      canChangeBrand={false}
    >
      <div className="brand-summary__empty">
        {brand
          ? <EmptyScreen
            icon={FactoryImg}
            iconPosition='bottom'
            iconCurved={true}
            iconSize="72px"
            heading={`${brand.factoriesCount} factor${brand.factoriesCount > 1 ? 'ies' : 'y'} available`}
          />
          : <EmptyScreen
            icon={NoBrandImg}
            iconSize="72px"
            heading="No brand selected"
            description="Select a brand to continue"
          />}
      </div>
      <div className="brand-summary__cta-container">
        <Button
          className="cta-continue tooltip-container"
          category="blue-bg"
          disabled={!brand}
          onClick={() => this._handleSubmit()}
        >
          CONTINUE
          {!brand && <div className="tooltip dark-tooltip tooltip--h-center tooltip--v-top">
            Select a brand to continue
          </div>}
        </Button>
      </div>
    </OrderSummary>
  }
}
