import React, { useEffect, useState } from "react";
import find from "lodash/find";
import map from "lodash/map";
import moment from "moment";
import Popover from "react-tiny-popover";

import styles from "../Product/Product.module.scss";
import tnaStyles from "./../Designs/SamplingDetails/NewForm.module.scss";
import itemStyles from "./SampleTrackerItem.module.scss";
import "./../Designs/SamplingDetails/SampleRow.scss";
import "react-tabs/style/react-tabs.css";
import "./styles.scss";

import { getCurrentUserRole } from "../../helpers/AuthHelper";
import {
  getSmallImageUrl,
  getCoverImagePlaceholder
} from "../../helpers/DesignHelpers";
import {
  TIMESTAMP_FORMAT,
  DATESTAMP__SHORT_FORMAT
} from "../../constants/Date";
import {
  LOCO_SUPER,
  CLIENT_SUPER,
  CLIENT,
  PRODUCT_DEVELOPMENT,
  PRODUCTION_MERCHANT
} from "../../constants/Auth";
import { checkTnaReadyForPP } from "helpers/DesignHelpers";
import { SAMPLE_STATUS_OPTIONS } from "../../constants/Sample";

import CheckBox from "../UI/Checkbox/Checkbox";
import notificationIcon from "../../assets/images/navigation/ic_notification_sent.svg";
import Button from "./../UI/Button/Button";
import DesignImageCard from "./../ProductionTracker/DesignImageCard";
import { STATUS_ORDER } from "constants/Design";
import DateToolTip from "components/UI/DateToolTip";

import SampleFiles from "../Designs/SamplingDetails/SampleFiles";

const Design = ({
  data,
  getSeletedDesign,
  isFactory,
  disableCheckbox,
  getSampleForNotification,
  openAddDueDateModal,
  isBrandSelected,
  openModal,
  closeModal,
  openSampleUpdateModal,
  tnaTemplate,
  userDetails
}) => {
  let smallImageUrl;

  const [sampleData, setSampleData] = useState(data);

  const {
    designName,
    isSelected,
    brandId,
    brandName,
    collectionId,
    designId,
    imageUrl,
    sampleRequestedOn,
    sampleStatus,
    sampleType,
    sourceDesignId,
    dueDate,
    dispatchSentDate,
    sampleDispatch,
    sampleId,
    factoryName,
    factoryId,
    notificationSentDate,
    notificationStatus,
    designSampleId,
    tnaDispatchPlanDate,
    sampleAttachments,
  } = sampleData;
  const attachments = sampleAttachments[sampleId];
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  if (imageUrl) {
    smallImageUrl = getSmallImageUrl(imageUrl);
  } else {
    smallImageUrl = getCoverImagePlaceholder(data).src;
  }

  useEffect(() => {
    setSampleData(data);
  }, [data]);

  const dateDifference = dueDate => {
    const daysDiff = moment(dueDate).diff(Date(), "days");
    return daysDiff;
    // if(daysDiff == 0)   return daysDiff
    // else if(daysDiff >0) return daysDiff;
    // else
    //   return daysDiff
  };

  const multiDateDifference = (dueDate, date) => {
    return moment(dueDate).diff(moment(date), "days");
  };

  const currentUserRoleValue = getCurrentUserRole();
  let isAdmin = false,
    isBrand = false,
    isMerchant = false,
    isFactoryUser = false;
  if (
    currentUserRoleValue === LOCO_SUPER ||
    currentUserRoleValue === PRODUCT_DEVELOPMENT
  ) {
    isAdmin = true;
  } else if (
    currentUserRoleValue === CLIENT_SUPER ||
    currentUserRoleValue === CLIENT
  ) {
    isBrand = true;
  } else if (currentUserRoleValue === PRODUCTION_MERCHANT) {
    isMerchant = true;
  } else isFactoryUser = true;

  let currentSampleDispatch = find(sampleDispatch, { isActive: true });

  const openCourierDetailsModal = details => {
    openModal("SAMPLING_ADD_COURIERS_DETAILS", {
      details: {
        ...details,
        data: {
          ...details.data,
          id: details.data.sampleId,
        },
      },
      setDispatchData,
      design: {
        id: designId,
        name: designName
      }
    });
  };

  const setFactoryAndTna = (dueDate, { label, value }) => {
    const dueIn = dateDifference(dueDate);
    const newState = { ...sampleData, dueDate, dueIn, factoryName: label };
    setSampleData(newState);
  };

  const setDispatchData = ({ date }, dispatchData, commentUploadData) => {
    //ADD Dispatch data to tnaData => sampleDipspatch
    const dispatchSentDays = multiDateDifference(dueDate, date);
    const newState = {
      ...sampleData,
      dispatchSentDate: date,
      dispatchSentDays,
      dispatchStatus: 1,
      sampleAttachments: {
        [sampleId]: ((commentUploadData && commentUploadData.files.map(file => ({
          ...file,
          uploadedBy: {
            name: userDetails.name,
          },
        }))) || []),
      },
    };
    setSampleData(newState);
  };

  const setSampleStatus = (sampleStatus, commentUploadData) => {
    let newSampleStatus = sampleStatus;
    let newSampleDispatch;
    if (sampleStatus === "") {
      newSampleDispatch = map(sampleData.sampleDispatch, disapatchData => ({
        ...disapatchData,
        isActive: false
      }));
    } else {
      newSampleDispatch = sampleData.sampleDispatch;
      newSampleStatus = sampleStatus;
    }
    setSampleData({
      ...sampleData,
      sampleStatus: newSampleStatus,
      dispatchSentDate: null,
      dispatchSentDays: null,
      dispatchStatus: null,
      tnaData: { ...sampleData.tnaData, sampleDispatch: newSampleDispatch },
      sampleAttachments: {
        [sampleId]: ((commentUploadData && commentUploadData.files.map(file => ({
          ...file,
          uploadedBy: {
            name: userDetails.name,
          },
        }))) || []),
      },
    });
  };

  const openAssociateModal = () => {
    openModal("ASSOCIATE_FACTORY", {
      setFactoryAndTna: setFactoryAndTna,
      hiddenFields: [
        { name: "collectionId", value: collectionId },
        { name: "brandId", value: brandId },
        { name: "sampleType", value: sampleType },
        { name: "sampleData ", value: data }
      ],
      initialValues: {
        name: collectionId,
        factory: factoryId,
        factoryName
        // description: collection.description
      },
      isAdmin: isAdmin
    });
  };

  const DispatchButton = () => (
    <Button
      category="text right"
      onClick={() => {
        const isTNAReady = checkTnaReadyForPP({
          sampleType,
          tnaPayload: tnaTemplate.payload
        });
        if (!isTNAReady) {
          closeModal();
          openModal("REDIRECT_TNA_UPDATE", {
            hiddenFields: [
              { name: "brandId", value: brandId },
              { name: "collectionId", value: collectionId },
              { name: "designId", value: designId }
            ],
            closeModal
          });
        } else {
          openCourierDetailsModal({
            data,
            currentSampleDispatch
          });
        }
      }}
      className="link-hover"
    >
      Add Dispatch Details
    </Button>
  );

  const [showFactoryName, setShowFactoryName] = useState(false);

  useEffect(() => {
    setShowFactoryName(() => (factoryName && !isFactoryUser ? true : false));
  }, [sampleData]);

  const dispatchDelayed = moment(
    dispatchSentDate ? dispatchSentDate : new Date()
  ).isAfter(dueDate);
  let dispatchStatus = dispatchDelayed ? "red" : "green";

  const sampleDelayed = moment().isAfter(dueDate);
  let sampleDelayStatus = sampleDelayed ? "red" : "green";

  return (
    <>
      <div
        className="card-custom-product sampling-tracker"
        style={{ gridTemplateColumns: isBrand ? "repeat(4,1fr) 150px" : "repeat(5,1fr) 150px" }}
      >
        <DesignImageCard
          isFactory={isFactory}
          brandId={brandId}
          collectionId={collectionId}
          designId={designId}
          smallImageUrl={smallImageUrl}
          brandName={brandName}
          sourceDesignId={sourceDesignId}
          designName={designName}
          isCellV2={true}
          factoryId={factoryId}
        />

        {!isBrand && (
          <div className="overflow-text cell-v2">
            <span data-tip={brandName}>{brandName || "N/A"}</span>
          </div>
        )}

        <div className={`${styles.design__status} cell-v2`} style={{ paddingLeft: 0 }}>
          <div className={styles.design__status_type}>{sampleType}</div>
          {sampleType === "Sample Not Required" ? null : (
            <div className={styles.design__status_date}>
              {sampleRequestedOn
                ? moment(sampleRequestedOn).format("MMMM DD, YYYY")
                : null}
            </div>
          )}
        </div>

        <div className={`${itemStyles.item__wrapper} cell-v2`}>
          {showFactoryName && (
            <div className={`${itemStyles.item__p} overflow-text`}>
              <span data-tip={factoryName}>{factoryName || "N/A"}</span>
            </div>
          )}

          {dueDate ? (
            <div className={styles.design__status_date}>
              Due on {moment(dueDate).format(DATESTAMP__SHORT_FORMAT)}
            </div>
          ) : isAdmin ? (
            !factoryName && (
              <>
                <div className="link-hover" onClick={openAssociateModal}>
                  Assign Factory
                </div>
              </>
            )
          ) : !userDetails.isFactory ? (
            "Locofast"
          ) : (
            "--"
          )}
        </div>

        <div className="flex cell-checkbox sampling-status">
          {dispatchSentDate ? (
            <div className="cell-v2">
              <div>
                <div
                  className={`status-grid date-tooltip-parent ${itemStyles.item__no_margin} ${dispatchStatus}`}
                >
                  Dispatched
                  <DateToolTip
                    delayed={dispatchDelayed}
                    ontime={!dispatchDelayed}
                    date={dispatchSentDate}
                    category="dispatched"
                  />
                </div>
              </div>
              {(isBrand || isAdmin) &&
                (sampleStatus === "Resubmission Requested" ||
                  sampleStatus === "Requested") &&
                dispatchSentDate && (
                  <div
                    onClick={() => {
                      openSampleUpdateModal({
                        designId,
                        collectionId,
                        moduleRef: sampleType,
                        // postComment: postPreProductionCommit,
                        moduleStatus: sampleStatus,
                        moduleId: sampleId,
                        request: data,
                        update: (data, commentData) => setSampleStatus(data, commentData),
                        noPrevComments: true,
                        withComment: true,
                        designName: designName,
                        cardData: {
                          id: designId,
                          image: smallImageUrl,
                          name: data.designName,
                          category: data.category,
                          status: data.status,
                          statusString: STATUS_ORDER[data.status]
                        },
                        sampleStatusTypes: SAMPLE_STATUS_OPTIONS
                        // changeData
                      });
                    }}
                    className="track-btn link-hover"
                  >
                    Update Status
                  </div>
                )}
            </div>
          ) : dueDate ? (
            <>
              <div className="cell-v2" style={{ cursor: "pointer" }}>
                <div
                  className={`status-grid
                  date-tooltip-parent
                  ${sampleDelayStatus}
                `}
                >
                  Pending
                  <DateToolTip
                    delayed={sampleDelayed}
                    ontime={!sampleDelayed}
                    date={dueDate}
                  />
                </div>
                {!isBrand && !isAdmin && <DispatchButton />}
              </div>
            </>
          ) : (
            <div className="status-grid green date-tooltip-parent">
              Pending
              <DateToolTip delayed={false} ontime={true} date={null} />
            </div>
          )}
          <div className={tnaStyles.design__checkbox}>
            {isFactory && dispatchSentDate && !isMerchant ? (
              isBrandSelected ? (
                <CheckBox
                  label={""}
                  value={isSelected}
                  disabled={
                    !(
                      (sampleStatus === "Requested" ||
                        sampleStatus === "Resubmission Requested") &&
                      dispatchSentDate &&
                      isBrandSelected
                    )
                  }
                  onClick={() => {
                    getSampleForNotification(data, isSelected);
                  }}
                />
              ) : (
                notificationStatus === 1 && (
                  <div
                    onMouseOver={() => setIsPopoverOpen(true)}
                    onMouseOut={() => setIsPopoverOpen(false)}
                  >
                    <Popover
                      isOpen={isPopoverOpen}
                      position={"left"}
                      content={
                        <div
                          style={{
                            padding: "12px",
                            lineHeight: "24px",
                            minWidth: "120px"
                          }}
                        >
                          {moment(notificationSentDate).format(
                            TIMESTAMP_FORMAT
                          )}
                        </div>
                      }
                    >
                      <img src={notificationIcon} alt="Notification sent" />
                    </Popover>
                  </div>
                )
              )
            ) : null}
          </div>
        </div>


        <div>
          <SampleFiles designName={designName} files={attachments} />
        </div>

      </div>
    </>
  );
};

export default Design;
