import React, { useState, useEffect } from "react";
import PerfectScrollBar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
import qs from "query-string";
import _ from "lodash";
import axios from "axios";
import { get_service_endpoint } from "./../../../ServiceEndpoints";

import CheckBox from "./CustomCheckBox";
import MultiSelectFilter from "./MultiSelectFilter";
import FloatingCTA from "../../UI/FloatingCTA/FloatingCTA";
import File from "./CustomFile";

import { findIndex } from "lodash";
import "./styles.scss";

import { ALL_STATES } from "./../../../constants/Global";
import Downshift from "downshift";
import icDropdown from "./../../../assets/images/navigation/ic-arrow-drop-down.svg";
import icNavLeft from "./../../../assets/images/navigation/ic-chevron-left.svg";
import PerfectScrollbar from "react-perfect-scrollbar";

const BrandCard = ({
  data: { name = "default", id, isSelected },
  onSelect
}) => {
  return (
    <div
      style={{
        width: "150px",
        minHeight: "100px",
        backgroundColor: "var(--fill-grey-light5)",
        borderRadius: "4px",
        margin: "16px",
        border: "1px solid var(--fill-grey-light3)",
        position: "relative",
        cursor: "pointer"
      }}
    >
      <div
        style={{
          height: "60px"
        }}
      >
        <img
          src={`https://s3.ap-south-1.amazonaws.com/${process.env.REACT_APP_BRAND_LOGO_PATH}/${id}.png`}
          alt={name}
          style={{ height: "60px", width: "150px" }}
        />
      </div>
      <div style={{ padding: "8px", wordBreak: "break-all" }}>{name}</div>
    </div>
  );
};

const Heading = ({ label }) => <div className="card-heading">{label}</div>;

const SecondaryHeading = ({ label, required }) => (
  <div className="heading--secondary">
    {label} {required && <sup>*</sup>}
  </div>
);

const WithInput = ({
  label,
  style,
  inputStyle = {},
  type = "text",
  onChange,
  id,
  value,
  isError = false,
  required = false,
  ...props
}) => (
  <div style={{ padding: "12px 0px", flex: 1, ...style }}>
    <div className="label__class" style={{ paddingBottom: "4px" }}>
      {label} {required ? <sup>*</sup> : null}
    </div>
    <input
      type="text"
      className={`${isError ? "inputerror" : ""}`}
      style={{
        width: "90%",
        ...inputStyle,
        border: isError ? "1px solid var(--fill-error)" : ""
      }}
      value={value}
      onChange={event => onChange(id, event.target.value)}
      {...props}
    />
  </div>
);

const InputWithoutLabel = ({
  label,
  style = {},
  inputStyle = { width: "100%" },
  type = "text",
  onChange,
  id,
  value,
  isError = false,
  required = false,
  ...props
}) => (
  <div style={{ ...style }}>
    <input
      type="text"
      className={`${isError ? "inputerror" : ""}`}
      style={{
        ...inputStyle,
        border: isError ? "1px solid var(--fill-error)" : ""
      }}
      value={value}
      onChange={event => onChange(id, event.target.value)}
      {...props}
    />
  </div>
);

const Select = ({
  initialSelectedItem,
  onChange,
  id,
  options,
  label,
  placeholder,
  className,
  isError,
  value
}) => {
  const initialSelectedItemLabel =
    ((options || []).find(item => item.value == initialSelectedItem) || {})
      .label || initialSelectedItem;

  return (
    <div className={`select-container ${className}`}>
      {label && <div className="label__class">{label}</div>}
      <Downshift
        initialSelectedItem={initialSelectedItem}
        itemToString={item => item && item.label}
        onChange={selection => {
          onChange(id, selection.value);
        }}
        style={{ fontSize: "12px", fontWeight: 600 }}
      >
        {({
          getInputProps,
          getToggleButtonProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          isOpen,
          inputValue,
          highlightedIndex,
          selectedItem
        }) => {
          return (
            <div
              {...getToggleButtonProps()}
              type="none"
              className={`downshift state-select ${className}`}
              style={{
                position: "relative",
                cursor: "pointer",
                // handle `isError` border color
                height: "42px"
              }}
              tabIndex="0"
            >
              <span
                className="flex"
                style={{
                  alignItems: "center",
                  justifyContent: "space-between"
                }}
              >
                <input
                  style={{
                    border: "none",
                    backgroundColor: "transparent",
                    maxWidth: "100%",
                    width: "100%",
                    fontWeight: "600",
                    color: "var(--fill-locofast-black)"
                  }}
                  className="select-input-transparent"
                  readOnly={true}
                  disabled={true}
                  placeholder={placeholder}
                  value={
                    (selectedItem && selectedItem.label) ||
                    initialSelectedItemLabel
                  }
                />
                <img
                  src={icDropdown}
                  alt="dropdown"
                  style={{ marginTop: "2px" }}
                />
              </span>

              {isOpen ? (
                <div
                  className="select-pane"
                  onClick={e => {
                    e.stopPropagation();
                  }}
                  {...getMenuProps()}
                >
                  <div>
                    <PerfectScrollbar style={{ maxHeight: "200px" }}>
                      {options
                        // .filter(item => item.label !== selectedItem.label)
                        .map((item, index) => {
                          return (
                            <div
                              {...getItemProps({
                                className: `in-selection collection-collab__label_left_aigned flex ${
                                  (selectedItem && selectedItem.value) ==
                                    item.value || highlightedIndex == index
                                    ? "selected"
                                    : ""
                                }`,
                                index,
                                item,
                                key: item.value
                              })}
                            >
                              {item.label}
                            </div>
                          );
                        })}
                    </PerfectScrollbar>
                  </div>
                </div>
              ) : null}
            </div>
          );
        }}
      </Downshift>
    </div>
  );
};

const TextArea = ({
  label,
  style = {},
  inputStyle = { width: "100%" },
  type = "text",
  onChange,
  id,
  value,
  isError = false,
  required = false,
  ...props
}) => (
  <div style={{ ...style }}>
    <textarea
      className={`address-textarea ${isError ? "inputerror" : ""}`}
      style={{
        ...inputStyle,
        border: isError ? "1px solid var(--fill-error)" : ""
      }}
      value={value}
      onChange={event => onChange(id, event.target.value)}
      rows="5"
      {...props}
    />
  </div>
);

const ToggleField = ({ status, onChange }) => {
  const enabled = status;

  const switchClasses = `switch switch--default switch--custom flex`;

  const togglerClasses = `switch-toggle switch-toggle--${
    enabled ? "on" : "off"
  }`;
  return (
    <React.Fragment>
      <input
        type="checkbox"
        onChange={selection => onChange("status", !status)}
        value={status}
        className="invisible_checkbox"
      />
      <div className={switchClasses}>
        <div className={togglerClasses} />
      </div>
    </React.Fragment>
  );
};

const FlexWrapper = ({ style, children }) => (
  <div
    style={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      ...style
    }}
  >
    {children}
  </div>
);

const CreateFactory = ({
  initialValues = {},
  allBrands = [],
  createNewFactory,
  updateFactoryAccount,
  match,
  factories,
  currentUser,
  ...restProps
}) => {
  const [details, setDetails] = useState({});
  const [registeredAddress, setRegisteredAddress] = useState({});
  const [factoryDetail, setFactoryDetails] = useState({
    sourcedByLf: false
  });

  const [expertiseSelected, setExpertiseSelected] = useState(expertiseOptions);
  const [selectedCategories, setSelectedCategories] = useState(factoryCategory);
  const [factoryBrands, setFactoryBrands] = useState([]);
  const [activeAddress, setActiveAddress] = useState(0); // handles the single address item in edit mode **
  const [addresses, setAddresses] = useState([{}]); // this one on edit time
  const [isAddressSameAsRegistered, setIsAddressSameAsRegistered] = useState(
    false
  );
  const [brands, setBrands] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isNotFoundError, setIsNotFoundError] = useState(false);

  const [factAddress, setFactAddress] = useState(addressTemplateObj);
  const [isEditMode, setIsEditMode] = useState(match.params.action === "edit");
  const [attachments, setAttachments] = useState([]);

  const newEp = get_service_endpoint("apiv2");

  // =========  this effect handles the initial values on edit time ===========
  useEffect(() => {
    if (!isEditMode) {
      return;
    }

    if (restProps.location.state) {
      const {
        location: {
          state: { initialValues }
        }
      } = restProps;

      initializeStateFromFactory(initialValues);
    } else {
      const queryParams = qs.parse(restProps.location.search);
      const factoryId = +queryParams["id"];

      if (!factoryId) {
        // show factory 404
        setIsNotFoundError(true);
      }

      if (factories && Array.isArray(factories) && factories.length > 0) {
        const factory = factories.find(item => item.id === factoryId);

        if (factory) {
          initializeStateFromFactory(factory);
        } else {
          // factory not found
          setIsNotFoundError(true);
        }
      }
    }
  }, [factories]);

  useEffect(() => {
    if (!restProps.location.state) return;
    const {
      location: {
        state: { initialValues, editMode }
      }
    } = restProps;
    if (editMode && initialValues.id) {
      axios
        .get(`${newEp}/factory/attachments`, {
          params: { factoryId: initialValues.id, isLoader: true }
        })
        .then(response => {
          setAttachments(response.data);
        });
    }
  }, []);

  useEffect(() => {
    if (allBrands.length) {
      // let updatedBrands = allBrands.map(brand => {
      //   brand.isSelected = false;

      //   return brand;
      // });
      let updatedBrands = allBrands.filter(brand => {
        if (findIndex(factoryBrands, { brandId: brand.id }) !== -1) {
          return brand;
        }
        return false;
      });
      setBrands(updatedBrands);
    }
  }, [allBrands, factoryBrands]);

  const saveDetailsHandler = (id, value) => {
    details[id] = value;
    setDetails({ ...details });
  };

  const saveRegisteredAddressHandler = (id, value) => {
    registeredAddress[id] = value;
    setRegisteredAddress({ ...registeredAddress });
  };

  const saveFactoryDetailsHandler = (id, value) => {
    if (id == "phone" && value && value.length > 10) {
      value = value.substr(0, 10);
    }
    factoryDetail[id] = value;
    setFactoryDetails({ ...factoryDetail });
  };

  const selectExpertiseOptionsHandler = id => {
    const updatedExpertiseOptions = expertiseSelected.filter(exp => {
      if (exp.value === id) {
        let selected = exp.isSelected;
        exp.isSelected = !selected;

        return { ...exp };
      }

      return exp;
    });

    setExpertiseSelected(updatedExpertiseOptions);
  };

  const selectAllExpertiseOptionsHandler = () => {
    const updatedExpertiseOptions = expertiseSelected.map(exp => {
      exp.isSelected = true;
      return { ...exp };
    });

    setExpertiseSelected(updatedExpertiseOptions);
  };

  const unSelectAllExpertiseOptionsHandler = () => {
    const updatedExpertiseOptions = expertiseSelected.map(exp => {
      exp.isSelected = false;
      return { ...exp };
    });

    setExpertiseSelected(updatedExpertiseOptions);
  };

  const selectCategoryHandler = id => {
    const updatedCategories = selectedCategories.filter(cat => {
      if (cat.value === id) {
        let selected = cat.isSelected;
        cat.isSelected = !selected;
        return { ...cat };
      }

      return cat;
    });

    setSelectedCategories(updatedCategories);
  };

  const selectAllCategoryHandler = () => {
    const updatedCategories = selectedCategories.map(cat => {
      cat.isSelected = true;
      return { ...cat };
    });

    setSelectedCategories(updatedCategories);
  };

  const unSelectAllCategoryHandler = () => {
    const updatedCategories = selectedCategories.map(cat => {
      cat.isSelected = false;
      return { ...cat };
    });

    setSelectedCategories(updatedCategories);
  };

  const saveFactoryAddressHandler = (key, value) => {
    // addresses[activeAddress][key] = value;
    // setAddresses([...addresses]);

    if (key == "phone" && value && value.length > 10) {
      value = value.substr(0, 10);
    }

    factAddress[key] = value;

    setFactAddress({ ...factAddress });

    // manage active address on EDIT *******
  };

  const selectBrandsHandler = id => {
    const updatedBrands = brands.filter(brand => {
      if (brand.id === id) {
        let selected = brand.isSelected;
        brand.isSelected = !selected;

        return { ...brand };
      }

      return brand;
    });

    setBrands(updatedBrands);
  };

  const handleSameAsRegisteredAddress = () => {
    if (!isAddressSameAsRegistered) {
      // addresses[0] = registeredAddress;
      // setAddresses([...addresses]);

      setFactAddress(_.cloneDeep(registeredAddress));
    }

    if (isAddressSameAsRegistered) {
      // setAddresses({});

      setFactAddress(addressTemplateObj);
    }

    setIsAddressSameAsRegistered(!isAddressSameAsRegistered);
  };

  const submitHandler = () => {
    // do validation here
    let submitError = false;
    const { name, email } = details;
    const { owner, phone } = factoryDetail;

    if (!name || !phone || !email || !owner || !isValidContactNumber(phone)) {
      submitError = true;
    }

    if (
      !["addressLine", "city", "pinCode", "state"].every(
        key => registeredAddress[key]
      )
    ) {
      console.log("err", registeredAddress);

      submitError = true;
    }

    // validation for factory adress on post
    if (
      Object.keys(addressTemplateObj).some(key => factAddress[key]) &&
      !Object.keys(addressTemplateObj).every(key => factAddress[key])
    ) {
      console.log("err", factAddress);
      submitError = true;
    }
    // if (!factAddress["phone"] || !isValidContactNumber(factAddress["phone"])) {
    //   console.log(factAddress);
    //   submitError = true;
    // }

    if (!registeredAddress.addressLine) {
      submitError = true;
    }

    if (submitError) {
      setIsError(true);
      return;
    }

    const curretRegistered = registeredAddress.addressLine.split("\n");
    const modifiedRegistered = Object.assign({}, registeredAddress);
    modifiedRegistered.addressLine1 = curretRegistered[0];
    modifiedRegistered.addressLine2 = curretRegistered.slice(1).join();

    const currentFact = factAddress.addressLine.split("\n");
    const modifiedFact = Object.assign({}, factAddress);
    modifiedFact.addressLine1 = currentFact[0];
    modifiedFact.addressLine2 = currentFact.slice(1).join();

    let factoryBrands = [];
    // brands.forEach(({ isSelected, id }) => {
    //   if (isSelected) {
    //     factoryBrands.push({ brandId: id });
    //   }
    // });

    if (details.brandId) {
      factoryBrands.push({ brandId: details.brandId });
    }

    // if there are no factory brands then associate the factory with the brand currentUser is associated with
    if (factoryBrands.length === 0) {
      factoryBrands.push({ brandId: currentUser.brandId });
    }

    let category = [];
    selectedCategories.forEach(({ value, isSelected }) => {
      if (isSelected) {
        category.push(value);
      }
    });

    let expertise = [];
    expertiseSelected.forEach(({ value, isSelected }) => {
      if (isSelected) {
        expertise.push(value);
      }
    });

    const request = {
      ...details,
      name: owner,
      company: name,
      registeredAddress: modifiedRegistered,
      addresses: [{ ...modifiedFact, addressType: 2 }],
      factoryDetail: {
        ...factoryDetail,
        category: category.join(),
        expertise: expertise.join()
      },
      factoryBrands,
      phone: factoryDetail.phone,
      type: 3
    };

    // here I check for POST or PATCH *******************
    if (isEditMode) {
      updateFactoryAccount(request, attachments);
      return;
    }

    createNewFactory(request, attachments);
  };

  const isBrandSelected = brandId => {
    if (!isEditMode) {
      return true;
    }
    if (!factoryBrands || !factoryBrands) return false;

    let brandIndex = findIndex(factoryBrands, { brandId: brandId });
    const isSelected = brandIndex !== -1;
    return brandIndex !== -1;
  };

  const isValidContactNumber = number => {
    return (
      number &&
      number.length >= 9 &&
      number.length <= 10 &&
      number.match(/^\d+$/)
    );
  };

  const getFactoryDetails = factoryId => {
    axios
      .get(`${newEp}/factory/${factoryId}`, {
        params: {
          isLoader: true
        }
      })
      .then(response => {
        initializeStateFromFactory(response.data);
      });
  };

  const initializeStateFromFactory = factory => {
    const {
      name,
      gstNumber,
      phone,
      email,
      webUrl,
      factoryDetail,
      registeredAddress,
      addresses,
      id,
      factoryBrands,
      status
    } = factory;

    const formattedAddress = Object.assign({}, registeredAddress);
    formattedAddress.addressLine = `${formattedAddress.addressLine1} \n ${formattedAddress.addressLine2}`;
    const associatedBrandId =
      (Array.isArray(factoryBrands) &&
        factoryBrands.length &&
        factoryBrands[0].id) ||
      currentUser.brandId;

    setDetails({
      name,
      gstNumber,
      phone,
      webUrl,
      email,
      id,
      status,
      brandId: associatedBrandId
    });
    setFactoryDetails(factoryDetail);
    setRegisteredAddress(formattedAddress);
    setFactoryBrands(factoryBrands);
    setIsEditMode(true);
    if (addresses.length) {
      const [address] = addresses;
      address.addressLine = `${address.addressLine1} \n ${address.addressLine2}`;
      setFactAddress(address);
    }
  };

  return (
    <div className="factory-add-edit-container">
      <PerfectScrollBar style={{ maxHeight: "900px" }}>
        <div className="factory-container">
          <header>
            <div>
              <Link className="nav-left" to="../">
                <img src={icNavLeft} alt="nav-left" />
              </Link>
              <h3>{isEditMode ? `Edit Factory` : `Add Factory`}</h3>
            </div>
            {
              // Remove factory status field since API has to be fixed
              /* {
              isEditMode && !isNotFoundError && (
                <div>
                  Factory status (Active) &nbsp;
                  <ToggleField status={details.status} onChange={saveDetailsHandler} />
                </div>
              )
            } */
            }
          </header>
          {isNotFoundError ? (
            <section className="details-card details-error">
              <div className="error">Factory doesn't exist !!</div>
            </section>
          ) : (
            <>
              <section className="details-card">
                <Heading label="Basic Details" />
                <div className="card-body">
                  <FlexWrapper>
                    <WithInput
                      label="Factory Name"
                      id="name"
                      value={details.name}
                      isError={isError && !details.name}
                      onChange={saveDetailsHandler}
                      required
                    />
                    <WithInput
                      label="Owner Name"
                      id="owner"
                      value={factoryDetail.owner}
                      isError={isError && !factoryDetail.owner}
                      onChange={saveFactoryDetailsHandler}
                      required
                    />
                  </FlexWrapper>
                  <FlexWrapper>
                    <WithInput
                      label="Phone Number"
                      value={factoryDetail.phone}
                      isError={
                        isError &&
                        (!factoryDetail.phone ||
                          !isValidContactNumber(factoryDetail.phone))
                      }
                      onChange={saveFactoryDetailsHandler}
                      id="phone"
                      required
                    />
                    <WithInput
                      label="Email ID"
                      value={details.email}
                      isError={isError && !details.email}
                      onChange={saveDetailsHandler}
                      id="email"
                      required
                      disable={isEditMode}
                    />
                  </FlexWrapper>

                  <FlexWrapper>
                    <WithInput
                      label="Website"
                      value={details.webUrl}
                      onChange={saveDetailsHandler}
                      id="webUrl"
                    />
                    <div style={{ flex: 1 }} />
                  </FlexWrapper>
                </div>
              </section>
              {currentUser.isSuperAdmin && (
                <section className="details-card">
                  <Heading label="Factory Association" />
                  <div className="card-body">
                    <FlexWrapper>
                      <Select
                        label="Brand"
                        id="brandId"
                        className="brand"
                        initialSelectedItem={details.brandId}
                        isError={isError && !details.brandId}
                        onChange={saveDetailsHandler}
                        placeholder="Brand"
                        options={allBrands.map(b => ({
                          label: b.name,
                          value: b.id
                        }))}
                      />
                      <div style={{ flex: 1 }} />
                    </FlexWrapper>
                    <FlexWrapper>
                      <div
                        className="lf-supplier-radio"
                      >
                        <div>Locofast Supplier Network</div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            className="loco__radio loco__radio--left-align loco__radio--light"
                            style={{
                              border: "none",
                              height: "30px",
                              paddingLeft: "0"
                            }}
                          >
                            <input
                              name="sourcedByLf"
                              id="sourced_by_lf_1"
                              type="radio"
                              checked={factoryDetail.sourcedByLf === true}
                              // value="savings"
                              // checked={accType === "savings"}
                              onChange={() =>
                                saveFactoryDetailsHandler("sourcedByLf", true)
                              }
                            />
                            <label htmlFor="sourced_by_lf_1">Yes</label>
                          </div>
                          <div
                            className="loco__radio loco__radio--left-align loco__radio--light"
                            style={{
                              border: "none",
                              height: "30px",
                              padding: "8px"
                            }}
                          >
                            <input
                              name="sourcedByLf"
                              id="sourced_by_lf_0"
                              type="radio"
                              checked={factoryDetail.sourcedByLf === false}
                              // checked={accType === "current"}
                              onChange={() =>
                                saveFactoryDetailsHandler("sourcedByLf", false)
                              }
                            />
                            <label htmlFor="sourced_by_lf_0">No</label>
                          </div>
                        </div>
                      </div>

                      <div style={{ flex: 1 }} />
                    </FlexWrapper>
                  </div>
                </section>
              )}
              <section className="details-card">
                <Heading label="Addresses" />
                <div className="card-body">
                  <FlexWrapper style={{ alignItems: "flex-start" }}>
                    <div className="full-width">
                      <SecondaryHeading label={"Registered Address"} required />
                      <div className="factory_address">
                        <TextArea
                          label="First Line"
                          id="addressLine"
                          className="addressLine"
                          value={registeredAddress.addressLine}
                          isError={isError && !registeredAddress.addressLine}
                          onChange={saveRegisteredAddressHandler}
                          placeholder="Enter registered address"
                        />
                        <div className="factory_address-group">
                          <InputWithoutLabel
                            label="City"
                            id="city"
                            className="city"
                            value={registeredAddress.city}
                            isError={isError && !registeredAddress.city}
                            onChange={saveRegisteredAddressHandler}
                            placeholder="City"
                          />
                          <Select
                            id="state"
                            className="state"
                            initialSelectedItem={registeredAddress.state}
                            isError={isError && !registeredAddress.state}
                            onChange={saveRegisteredAddressHandler}
                            options={ALL_STATES.map(item => ({
                              label: item,
                              value: item
                            }))}
                            placeholder="State"
                          />
                          <InputWithoutLabel
                            label="Pin Code"
                            id="pinCode"
                            className="pinCode"
                            value={registeredAddress.pinCode}
                            isError={isError && !registeredAddress.pinCode}
                            onChange={saveRegisteredAddressHandler}
                            placeholder="Pin code"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="full-width">
                      <SecondaryHeading label={"Factory Address"} required />
                      <div className="factory_address">
                        <div>
                          <TextArea
                            label="First Line"
                            id="addressLine"
                            className="addressLine"
                            value={factAddress["addressLine"]}
                            isError={isError && !factAddress.addressLine}
                            onChange={saveFactoryAddressHandler}
                            placeholder="Enter Factory address"
                          />
                        </div>
                        <div className="factory_address-group">
                          <InputWithoutLabel
                            label="City"
                            id="city"
                            className="city"
                            value={factAddress["city"]}
                            isError={isError && !factAddress.city}
                            onChange={saveFactoryAddressHandler}
                            placeholder="City"
                          />
                          <Select
                            id="state"
                            className="state"
                            initialSelectedItem={factAddress.state}
                            isError={isError && !factAddress.state}
                            onChange={saveFactoryAddressHandler}
                            options={ALL_STATES.map(item => ({
                              label: item,
                              value: item
                            }))}
                            placeholder="State"
                          />
                          <InputWithoutLabel
                            label="Pin Code"
                            id="pinCode"
                            className="pinCode"
                            value={factAddress["pinCode"]}
                            isError={isError && !factAddress.pinCode}
                            onChange={saveFactoryAddressHandler}
                            placeholder="Pin code"
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          padding: "8px 0px",
                          paddingBottom: "16px"
                        }}
                      >
                        <CheckBox
                          label="Same as registered address"
                          value={isAddressSameAsRegistered}
                          disabled={!Object.keys(registeredAddress).length}
                          onClick={() => {
                            if (!Object.keys(registeredAddress).length) return;
                            handleSameAsRegisteredAddress();
                          }}
                        />
                      </div>
                    </div>
                  </FlexWrapper>
                </div>
              </section>
              <section className="details-card">
                <Heading label="Additional details" />
                <div className="card-body">
                  <FlexWrapper>
                    <WithInput
                      label="Number of Machines"
                      id="machines"
                      value={factAddress["machines"]}
                      onChange={saveFactoryAddressHandler}
                    />
                    <WithInput
                      label="Number of Employees"
                      id="employees"
                      value={factoryDetail["employees"]}
                      onChange={saveFactoryDetailsHandler}
                    />
                  </FlexWrapper>
                  <FlexWrapper>
                    <div style={{ flex: 1 }}>
                      <div
                        className="label__class"
                        style={{ paddingBottom: "4px" }}
                      >
                        Expertise
                      </div>
                      <MultiSelectFilter
                        title="Expertise"
                        initialSelectedItem={{
                          label: "All",
                          value: "All"
                        }}
                        filterOptions={expertiseSelected.map(
                          ({ label, value, isSelected }) => ({
                            label,
                            value,
                            isSelected
                          })
                        )}
                        onChange={selection => {
                          // saveFactoryDetailsHandler("expertise", selection.value);
                          selectExpertiseOptionsHandler(selection.value);
                        }}
                        selectAll={selectAllExpertiseOptionsHandler}
                        cancelCollectionFilter={
                          unSelectAllExpertiseOptionsHandler
                        }
                        disable={false}
                        // marginRight="20px"
                        wrapperWidth="180px"
                      />
                    </div>
                    <div style={{ flex: 1, alignSelf: "flex-end" }}>
                      <div
                        className="label__class"
                        style={{ paddingBottom: "4px" }}
                      >
                        Category
                      </div>
                      <MultiSelectFilter
                        title="Categories"
                        initialSelectedItem={{
                          label: "All",
                          value: "All"
                        }}
                        filterOptions={factoryCategory.map(
                          ({ label, value, isSelected }) => ({
                            label,
                            value,
                            isSelected
                          })
                        )}
                        onChange={selection => {
                          // saveFactoryDetailsHandler("category", selection.value);
                          selectCategoryHandler(selection.value);
                        }}
                        selectAll={selectAllCategoryHandler}
                        cancelCollectionFilter={unSelectAllCategoryHandler}
                        disable={false}
                        cancelCollectionFilter={"cancelCollectionFilter"}
                        // marginRight="20px"
                        wrapperWidth="180px"
                      />
                    </div>
                  </FlexWrapper>

                  <FlexWrapper>
                    <WithInput
                      label="Year of Establishment"
                      id="foundedOn"
                      value={factoryDetail.foundedOn}
                      onChange={saveFactoryDetailsHandler}
                    />
                    <WithInput
                      label="Compliance"
                      id="compliance"
                      value={factoryDetail.compliance}
                      onChange={saveFactoryDetailsHandler}
                    />
                  </FlexWrapper>
                  <FlexWrapper>
                    <WithInput
                      label="Annual Turnover (in lakhs)"
                      id="turnOver"
                      value={factoryDetail["turnOver"]}
                      onChange={saveFactoryDetailsHandler}
                    />
                    <div style={{ flex: "1" }} />
                  </FlexWrapper>
                </div>
              </section>
              <section className="details-card">
                <Heading label="GST and bank details" />
                <div className="card-body">
                  <FlexWrapper>
                    <WithInput
                      label="GST No."
                      value={details.gstNumber}
                      onChange={saveDetailsHandler}
                      id="gstNumber"
                    />
                    <div style={{ flex: "1" }} />
                  </FlexWrapper>
                  <SecondaryHeading label="Bank details" />
                  <FlexWrapper>
                    <WithInput
                      label="Beneficiary Name"
                      id="bankBeneficiary"
                      value={factoryDetail.bankBeneficiary}
                      onChange={saveFactoryDetailsHandler}
                    />
                    <WithInput
                      label="Account Number"
                      id="bankAccountNumber"
                      value={factoryDetail.bankAccountNumber}
                      onChange={saveFactoryDetailsHandler}
                    />
                  </FlexWrapper>
                  <FlexWrapper>
                    <WithInput
                      label="Bank Name"
                      id="bankName"
                      value={factoryDetail.bankName}
                      onChange={saveFactoryDetailsHandler}
                    />
                    <WithInput
                      label="IFSC"
                      id="bankIfsc"
                      value={factoryDetail.bankIfsc}
                      onChange={saveFactoryDetailsHandler}
                    />
                  </FlexWrapper>
                  <FlexWrapper>
                    <div
                      style={{
                        display: "flex",
                        width: "45%",
                        justifyContent: "inherit"
                      }}
                    >
                      <p>Account type</p>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                          id={"Savings"}
                          className="loco__radio loco__radio--left-align loco__radio--light"
                          style={{
                            border: "none",
                            height: "30px",
                            padding: "8px"
                          }}
                        >
                          <input
                            name="accType"
                            id="savings"
                            type="radio"
                            checked={factoryDetail.bankType == "savings"}
                            // value="savings"
                            // checked={accType === "savings"}
                            onChange={() =>
                              saveFactoryDetailsHandler("bankType", "savings")
                            }
                          />
                          <label htmlFor="savings">Savings</label>
                        </div>
                        <div
                          id={"Current"}
                          className="loco__radio loco__radio--left-align loco__radio--light"
                          style={{
                            border: "none",
                            height: "30px",
                            padding: "8px"
                          }}
                        >
                          <input
                            name="accType"
                            id="current"
                            type="radio"
                            checked={factoryDetail.bankType == "current"}
                            // checked={accType === "current"}
                            onChange={() =>
                              saveFactoryDetailsHandler("bankType", "current")
                            }
                          />
                          <label htmlFor="current">Current</label>
                        </div>
                      </div>
                    </div>

                    <div style={{ flex: 1 }} />
                  </FlexWrapper>
                </div>
              </section>
              <section className="details-card">
                <Heading label="Attachments" />
                <div className="card-body">
                  <FlexWrapper>
                    <File
                      onChange={selection => {
                        if (selection.deleted) {
                          const newAttachments = attachments.filter(
                            file => file.name !== selection.name
                          );
                          setAttachments(newAttachments);
                        } else {
                          setAttachments([...attachments, selection]);
                        }
                      }}
                      initialFileName={
                        attachments[0] &&
                        !(attachments[0] instanceof File) &&
                        attachments[0]
                      }
                      validate={[]}
                    />
                  </FlexWrapper>
                </div>
              </section>

              {/* ---------  Brands ------------ */}
              {brands.length > 0 && (
                <section className="details-card">
                  <Heading label="Brands Associated" />
                  <div className="card-body">
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      {brands.map(brand => (
                        <BrandCard data={brand} />
                      ))}
                    </div>
                  </div>
                </section>
              )}

              {/* ================== SUBMIT =============== */}
              <FloatingCTA
                style={{ position: "fixed" }}
                onClick={submitHandler}
              >
                <span>SUBMIT</span>
              </FloatingCTA>
              {/* ========================================= */}

              {/* CONTACT DETAILS */}
            </>
          )}
        </div>
      </PerfectScrollBar>
    </div>
  );
};

export default CreateFactory;

const factoryCategory = [
  { label: "Men", value: "Men", isSelected: true },
  { label: "Women", value: "Women", isSelected: true },
  { label: "Kids", value: "Kids", isSelected: true }
];

const expertiseOptions = [
  {
    label: "Knits",
    value: "Knits",
    isSelected: true
  },
  {
    label: "Woven",
    value: "Woven",
    isSelected: true
  }
];

const addressTemplateObj = {
  addressLine: "",
  city: "",
  pinCode: "",
  state: ""
};
