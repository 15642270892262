import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import Header from "../UI/Header";
import Table from "../UI/Table";
import FilterContainer from "./../UI/Filters";
import { DatePicker } from "./../UI/Filters/DateRangePicker";
import MultiSelect from "./../UI/Filters/MultiSelect";
import SingleSelect from "./../UI/Filters/SingleSelect";
import { getCostingReport } from "store/actions/ReportsAction";
import Loader from "components/UI/InlineLoader";

const LIMIT = 20;
const HEADINGS = [
  "DESIGN",
  "CREATED ON",
  "COLLECTION",
  "FACTORY",
  "QUANTITY",
  "TARGET PRICE",
  "APPROVED QUOTE",
  "LOWEST QUOTE",
  "BUYER"
];

const TOOLTIP_DATA = {
  BUYER: `<div class="subtext">The user who created the design</div>`
};

const CostingReport = ({
  getCostingReport,
  costing = { list: [], filters: {} },
  loading,
  userDetails,
  brandsList,
  isBrandManager
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [filters, setFilters] = useState({});
  const [dates, setDates] = useState({});

  useEffect(() => {
    getCostingReport({ isInit: 1 });
  }, []);

  const paginationHandler = values => {
    if (values.offset && costing.list.length <= (currentIndex + 1) * LIMIT) {
      setCurrentIndex(currentIndex + 1);
      getCostingReport({
        offset: costing.pagination.nextOffset,
        ...filters
      });
    } else {
      if (values.offset || (!values.offset && values.isnext === 1)) {
        setCurrentIndex(currentIndex + 1);
      } else if (values.isnext === 0) {
        setCurrentIndex(currentIndex - 1);
      }
    }
  };

  if (loading) {
    return <Loader />;
  }

  const getOverallReport = () => {
    return getCostingReport({
      ...filters,
      limit: costing.pagination.totalCount,
      skipStore: true,
      inlineLoader: false
    }).then(data => {
      return data.records;
    });
  };

  return (
    <div className="report-content">
      <Header
        heading="Costing report"
        reportType="costing"
        pagination={costing.pagination}
        currentIndex={currentIndex}
        paginationHandler={paginationHandler}
        getOverallReport={getOverallReport}
        headings={HEADINGS}
      />
      <FilterContainer
        updateFilters={() => {
          getCostingReport({
            ...filters,
            ...dates,
            isInit: 1
          });
          setCurrentIndex(0);
        }}
      >
        {userDetails.isSuperAdmin && (
          <SingleSelect
            label="Brand"
            filterOptions={brandsList}
            onChange={id => {
              setFilters({ ...filters, brandId: id ? id : null });
            }}
            selectedFilters={filters.brandId}
          />
        )}
        <DatePicker
          label="Date Range"
          updateFilterDates={dates => {
            setDates(dates);
          }}
          selectedDates={dates}
        />
        <MultiSelect
          label="Factory"
          filterOptions={costing.filters.factories}
          onChange={ids => {
            setFilters({ ...filters, factoryIds: ids ? ids.join() : null });
          }}
          selectedFilters={filters.factoryIds}
        />
        {!isBrandManager && (
          <MultiSelect
            label="Buyer"
            filterOptions={costing.filters.buyers}
            onChange={ids => {
              setFilters({ ...filters, creatorIds: ids ? ids.join() : null });
            }}
            selectedFilters={filters.creatorIds}
          />
        )}
      </FilterContainer>

      <Table
        headings={HEADINGS}
        tooltipData={TOOLTIP_DATA}
        data={costing.list.slice(
          currentIndex * LIMIT,
          currentIndex * LIMIT + LIMIT
        )}
        gridTemplateColumns="
      minmax(200px, 1.66fr)
    minmax(120px, 1.2fr)
    minmax(170px, 1fr)
    minmax(170px, 1fr)
    minmax(140px, 1fr)
    minmax(140px, 1fr)
    minmax(140px, 1fr)
    minmax(115px, 1.2fr)
    minmax(155px, 1.2fr)"
      />
    </div>
  );
};

const mapStateToProps = state => ({
  costing: state.reports.costing,
  loading: state.reports.loading,
  userDetails: state.auth.userDetails
});

export default connect(mapStateToProps, {
  getCostingReport
})(CostingReport);
