import React, { Component } from "react";
import axios from "axios";
import _ from "lodash";
import ReactTooltip from "react-tooltip";
import { withRouter } from "react-router-dom";
import moment from "moment";

import { get_service_endpoint } from "ServiceEndpoints";
import FormItem from "./FormItem";
import { convertDate, convertToIso } from "helpers/convertDate";
import { transformSizes } from "helpers/DesignHelpers";

import Header from "./Header";
import TnaImageModal from "../../ProductionTracker/TnaImageModal";
import { DATESTAMP_FORMAT, DATESTAMP__SHORT_FORMAT } from "constants/Date";
import { MAP_SAMPLE_TYPES } from "constants/Design";
import { TNA_STATUS, TNA_ATTRIBUTES_ORDER } from "constants/Tna";
import CreateTna from "./CreateTna";
import Button from "components/UI/Button/Button";
import TnaGraph from "components/Designs/BrandTna/TnaGraph";
import EmptyScreen from "components/UI/EmptyScreen";
import copyIcon from "assets/images/action/ic-copy-blue.svg";
import copyTnaImg from "assets/images/emptyPages/ic-copy-tna.svg";
import imgCalendar from "assets/images/image/ic-calendar-blue.svg";

import styles from "./DesignTna.module.scss";
import "./styles.scss";
import Toolbar from "components/UI/Toolbar";
import { TextSpliced } from "components/UI/TextSpliced";
import DesignAvatar from "components/UI/Avatar/DesignAvatar";
import { TnaNoteList } from "../DesignTna/TnaNote";

const CATEGORY_PRODUCTION = "production cycle";
const SUB_CATEGORY_BULK_CUT_COMPLETED = "bulk cut completed";

const INITIAL_PREV_DATE = {
  prevPlanDate: undefined,
  prevActualDate: undefined
};

class TnaPlan extends Component {
  state = {
    data: [],
    filteredData: [],
    dataKeys: false,
    designId: "",
    sourceId: "",
    id: "",
    dataForEditMode: [],

    styleId: "",
    orderQuantity: "",
    poNo: "",
    costClosureDate: null,
    deliveryDate: null,
    dueDate: null,

    showFlash: false,
    showSubmit: false,
    showEdit: false,
    isPatch: false,
    onSubmitError: false,

    currentMinDate: new Date("2019-01-01"),

    isError: false,
    emptyTargetValue: false,
    isException: false,
    factoryData: {},
    copyTnaSourceDesign: null,
    notes: [],
  };

  componentDidMount() {
    this.getTemplateDataHandler();
    this.props.getFactoryTnaTemplate({
      brandid: this.props.brandId,
      designid: parseInt(this.props.designId)
    });
    ReactTooltip.rebuild();
  }

  getTemplateDataHandler = (isEditMode = false) => {
    return axios
      .get(`${get_service_endpoint("notificationView")}/tna/template/`, {
        params: {
          brandid: this.props.brandId,
          designid: parseInt(this.props.designId)
        }
      })
      .then(res => {
        let data = res.data.tnaAttributes;

        let filteredData = _.groupBy(data, item => item.categoryOrder);
        let showEdit = res.data.id ? true : false;

        let sourceId = res.data.design.sourceDesignId;

        const {
          deliveryDate,
          orderQuantity,
          poNumber,
          costClosureDate,
          id,
          dueDate,
          factoryData,
          ppSampleApproval,
          assocQuantity,
          status,
          brandName,
          skipApproval,
          notes
        } = res.data;

        this.setState({
          data,
          filteredData,
          dataKeys: Object.keys(filteredData),
          designId: parseInt(this.props.designId),
          costClosureDate: costClosureDate,
          factoryData,
          deliveryDate: deliveryDate,
          dueDate,
          poNo: poNumber,
          orderQuantity: orderQuantity,
          showEdit: isEditMode ? !isEditMode : showEdit,
          isPatch: showEdit,
          sourceId,
          id,
          ppSampleApproval,
          assocQuantity,
          status,
          brandName,
          skipApproval,
          notes
        });
      })
      .catch(err => console.log("ERR WHILE GETTING TNA TEMPLATE", err));
  };

  saveDataHandler = (id, value, saveAndSubmit) => {
    let convertedValue = convertToIso(value);

    const { data } = this.state;

    let updatedData = data.filter(item => {
      if (item.tnaTemplateAttributeId === id) {
        item.attributeValue = convertedValue;
        return { ...item };
      }
      return item;
    });

    let updatedFilteredData = _.groupBy(
      updatedData,
      item => item.categoryOrder
    );

    this.setState(
      {
        data: updatedData,
        filteredData: updatedFilteredData,
        showSubmit: true,
        isException: false
      },
      () => {
        if (saveAndSubmit) {
          this.submitHandler();
        }
      }
    );
  };

  deleteDateHandler = id => {
    const { data } = this.state;

    let updatedData = data.filter(item => {
      if (item.tnaTemplateAttributeId === id) {
        item.attributeValue = null;
        return { ...item };
      }
      return item;
    });

    let updatedFilteredData = _.groupBy(
      updatedData,
      item => item.categoryOrder
    );

    this.setState({
      data: updatedData,
      filteredData: updatedFilteredData,
      showSubmit: true,
      isException: false
    });
  };

  saveStaticCheckBoxHandler = (id, temp, value) => {
    this.setState({ [id]: value, showSubmit: true });
  };

  saveCheckBoxDataHandler = (id, relationWithDateId, value) => {
    const { data } = this.state;

    let updatedData = data.filter(item => {
      if (item.tnaTemplateAttributeId === id) {
        item.isSelected = value;
        return { ...item };
      }
      return item;
    });

    let updatedFilteredData = _.groupBy(
      updatedData,
      item => item.categoryOrder
    );

    this.setState({
      data: updatedData,
      filteredData: updatedFilteredData,
      showSubmit: true
    });
  };

  saveTargetValueHandler = (id, value) => {
    const { data } = this.state;
    let updatedData = data.filter(item => {
      if (item.tnaTemplateAttributeId === id) {
        item.subAttributeValue = value;
        return { ...item };
      }
      return item;
    });

    let updatedFilteredData = _.groupBy(
      updatedData,
      item => item.categoryOrder
    );

    this.setState({
      data: updatedData,
      filteredData: updatedFilteredData,
      showSubmit: true
    });
  };

  getTnaRequestData = (markSubmit = false) => {
    const {
      data,
      designId,
      costClosureDate,
      deliveryDate,
      orderQuantity,
      poNo,
      id,
      dueDate,
      status
    } = this.state;

    const request = {
      id,
      designId,
      poNumber: poNo,
      orderQuantity,
      deliveryDate: deliveryDate ? convertDate(deliveryDate) : null,
      costClosureDate: costClosureDate ? convertDate(costClosureDate) : null,
      dueDate: dueDate ? convertDate(dueDate) : null,
      status: markSubmit ? markSubmit : status,
      attributes: data
    };

    return request;
  }

  submitHandler = (markSubmit = false, extraParams) => {
    const {
      data,
      designId,
      costClosureDate,
      deliveryDate,
      isPatch,
      orderQuantity,
      poNo,
      id,
      dueDate,
      status
    } = this.state;

    const request = {
      ...this.getTnaRequestData(markSubmit),
      ...extraParams
    };

    // ************** NEW VALIDATION LOGIC **************************

    let isException = false;

    const validatedData = data.map((item, index) => {
      // ************** THIS WILL CHECK FOR SELECTED AND EMPTY TARGET VALUE

      if (
        item.isSelected &&
        item.attributeValue &&
        !item.subAttributeValue &&
        item.subAttribute &&
        item.subAttribute !== "Plan Date"
      ) {
        item.emptyTarget = true;
        isException = true;
      } else {
        item.emptyTarget = false;
      }

      return item;
    });

    if (isException) {
      let updatedFilteredData = _.groupBy(
        validatedData,
        item => item.categoryOrder
      );

      this.setState({ isException, filteredData: updatedFilteredData });
      return;
    }

    // **************************************************************

    if (!isPatch) {
      return axios
        .post(`${get_service_endpoint("notificationView")}/tna`, request)
        .then(res => {
          if (true) {
            this.setState({
              showSubmit: false,
              showEdit: true,
              isPatch: true,
              status: markSubmit ? markSubmit : status
            });
          }

          if (res.ERROR) {
            // show error
          }
          this.getTemplateDataHandler();
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      const dataWithConvertedDate = data.map(item => {
        if (item.attributeValue) {
          item.attributeValue = convertToIso(item.attributeValue);
        } else {
          item.attributeValue = null;
        }
        return { ...item };
      });

      const patchRequest = {
        id,
        designId: parseInt(designId),
        poNumber: poNo,
        orderQuantity,
        deliveryDate: deliveryDate ? convertDate(deliveryDate) : null,
        costClosureDate: costClosureDate ? convertDate(costClosureDate) : null,
        dueDate: dueDate ? convertDate(dueDate) : null,
        attributes: dataWithConvertedDate,
        status: markSubmit ? markSubmit : status,
        ...extraParams
      };

      return axios
        .patch(`${get_service_endpoint("notificationView")}/tna`, patchRequest)
        .then(res => {
          if (true)
            this.setState({
              showSubmit: false,
              showEdit: true,
              isPatch: true,
              status: markSubmit ? markSubmit : status
            });
            this.getTemplateDataHandler();
          })
        .catch(err => {
          console.log(err);
          // alert("Oops! Something went wrong.");
        });
    }
  };

  validateDatesHandler = (nextDate, previousDate) => {
    return !moment(nextDate).isSameOrAfter(previousDate);
  };

  deleteFile = (attributeId, fileID, key) => {
    const { filteredData } = this.state;

    return axios
      .delete(`${get_service_endpoint("notificationView")}/files`, {
        params: {
          id: fileID
        }
      })
      .then(res => {
        let attributeIndex = _.findIndex(filteredData[key], {
          id: attributeId
        });
        if (attributeIndex !== -1) {
          let files = filteredData[key][attributeIndex].files;
          if (!files || !files.length) return;
          let fileIndex = _.findIndex(files, { id: fileID });
          if (fileIndex !== -1) files.splice(fileIndex);
          filteredData[key][attributeIndex].files = files;
        }
        this.setState({ filteredData: filteredData });
      });
  };

  uploadFile = (attributeId, files = [], key) => {
    const { designId, filteredData, id } = this.state;
    let formData = new FormData();
    formData.append("id", attributeId);
    formData.append("tnaId", id);

    formData.append("designId", designId);
    files.forEach(file => {
      formData.append("file", file);
    });
    return axios
      .post(`${get_service_endpoint("notificationView")}/tna/image`, formData)
      .then(res => {
        if (res.data) {
          let attributeIndex = _.findIndex(filteredData[key], {
            id: attributeId
          });

          if (attributeIndex !== -1) {
            let files = filteredData[key][attributeIndex].files;

            if (!files || !files.length) files = [];
            files.unshift(...res.data);
            filteredData[key][attributeIndex].files = files;
          }
          this.setState({ filteredData: filteredData });
        }
        return res.data;
      });
  };

  showImageModal = data => {
    this.setState({ modalData: data }, () => {
      this.setState({ showImageModal: true });
    });
  };

  hideImageModal = () => {
    this.setState({ showImageModal: false });
  };

  getPreviousDate = (categoryOrder, mapIndex) => {
    if (mapIndex === 0) {
      return { ...INITIAL_PREV_DATE };
    }

    const { data, ppSampleApproval, skipApproval } = this.state;
    const prevData = data[mapIndex - 1];
    const curData = data[mapIndex];

    // if PP sample is approved, select the PP sample approval date
    // as prevActual date for Bulk Cut Complete
    if (
      !skipApproval &&
      ppSampleApproval &&
      ppSampleApproval.approvedOn &&
      curData &&
      curData.category &&
      curData.category.toLowerCase() === CATEGORY_PRODUCTION &&
      curData.subCategory &&
      curData.subCategory.toLowerCase() === SUB_CATEGORY_BULK_CUT_COMPLETED
    ) {
      return {
        prevPlanDate: undefined,
        prevActualDate: ppSampleApproval.approvedOn
      };
    }

    return {
      prevPlanDate: prevData ? prevData.subAttributeValue : undefined,
      prevActualDate: prevData ? prevData.attributeValue : undefined
    };
  };

  getNextDate = (categoryOrder, mapIndex) => {
    const initialObj = {
      nextPlanDate: undefined,
      nextActualDate: undefined
    };

    const { data } = this.state;

    if (mapIndex === Object.keys(data).length - 1) {
      return { ...initialObj };
    }

    const sampleDispatchStage = _.find(data, {
      categoryOrder: TNA_ATTRIBUTES_ORDER.SAMPLE_DISPATCH.categoryOrder,
      attributeOrder: TNA_ATTRIBUTES_ORDER.SAMPLE_DISPATCH.attributeOrder
    });
    return {
      nextPlanDate:
        categoryOrder === TNA_ATTRIBUTES_ORDER.INWARDS_STAGE.categoryOrder &&
          sampleDispatchStage
          ? sampleDispatchStage.subAttributeValue
          : data[mapIndex + 1].subAttributeValue,
      nextActualDate: data[mapIndex + 1].attributeValue
        ? data[mapIndex + 1].attributeValue
        : undefined
    };
  };

  copyTnaHandler = (editMode = false) => {
    const { openModal, design } = this.props;
    const { data, copyTnaSourceDesign } = this.state;

    openModal('COPY_TNA', {
      design,
      requestData: this.getTnaRequestData(false),
      designTnaAttributes: data,
      oldSelectedDesign: copyTnaSourceDesign,
      onSuccess: (selectedDesign) => {
        this.getTemplateDataHandler(editMode);
        this.setState({ copyTnaSourceDesign: selectedDesign });
      }
    });
  };

  copyTnaClearAllHandler = () => {
    const { data } = this.state;

    this.setState({
      copyTnaSourceDesign: null,
      data: data.map(item => {
        if (item.categoryOrder === TNA_ATTRIBUTES_ORDER.SAMPLE_DISPATCH.categoryOrder
          && item.attributeOrder === TNA_ATTRIBUTES_ORDER.SAMPLE_DISPATCH.attributeOrder) {
          return item;
        }

        return {
          ...item,
          subAttributeValue: null,
        };
      }),
    }, () => {
      this.submitHandler()
        .then(() => {
          this.getTemplateDataHandler();
        });
    });
  }

  createTnaHandler = () => {
    const { id, designId, data } = this.state;

    if (id === "") {
      const request = {
        status: 1,
        designId,
        attributes: data,
        skipApproval: 0
      };
      axios
        .post(`${get_service_endpoint("notificationView")}/tna`, request)
        .then(() => {
          this.getTemplateDataHandler();
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      const request = {
        id,
        status: 1,
        designId,
        attributes: data
      };
      axios
        .patch(`${get_service_endpoint("notificationView")}/tna`, request)
        .then(() => {
          this.setState({
            status: 1,
            showEdit: false,
            showSubmit: true
          });
        });
    }
  };

  getTimelineStatus = (actualDate, planDate) => {
    let timelineDate, timelineStatus, timelineClass;
    actualDate = actualDate ? moment(actualDate) : null;
    planDate = planDate ? moment(planDate) : null;

    if (actualDate) {
      let datesDiff = actualDate.diff(planDate, "days");

      if (datesDiff < 0) {
        timelineDate = moment(actualDate).format(DATESTAMP__SHORT_FORMAT);
        timelineStatus = `${datesDiff * -1} ${
          datesDiff * -1 > 1 ? "days" : "day"
          } early`;
        timelineClass = "green";
      } else if (datesDiff === 0) {
        timelineDate = moment(actualDate).format(DATESTAMP__SHORT_FORMAT);
        timelineStatus = `On time`;
        timelineClass = "green";
      } else {
        timelineDate = moment(actualDate).format(DATESTAMP__SHORT_FORMAT);
        timelineStatus = datesDiff
          ? `${datesDiff} ${datesDiff > 1 ? "days" : "day"} delay`
          : 'Delayed';
        timelineClass = "red";
      }
    } else {
      timelineDate = `due on ${moment(planDate).format(
        DATESTAMP__SHORT_FORMAT
      )}`;
      timelineStatus = `In progress`;
      timelineClass = "yellow";
    }

    return { timelineDate, timelineStatus, timelineClass };
  };

  getTnaGraphData = filteredData => {
    let tnaGraphData = Object.values(filteredData).map(tnaAttribute => {
      let minDate, maxDate, planDate;

      minDate = tnaAttribute[0].attributeValue;
      maxDate = tnaAttribute[tnaAttribute.length - 1].attributeValue;

      planDate = tnaAttribute[tnaAttribute.length - 1].subAttributeValue;

      let subCategories = tnaAttribute.map(step => {
        let actualDate, planDate, timelineDate, timelineStatus, timelineClass;
        actualDate = step.attributeValue;
        planDate = step.subAttributeValue;

        if (!planDate) {
          //Only in PP sample approval case it's null
          if (actualDate) {
            timelineDate = `${moment(actualDate).format(
              DATESTAMP__SHORT_FORMAT
            )}`;
            timelineStatus = `${step.attribute}`;
            timelineClass = "green";
          } else {
            timelineDate = "";
            timelineStatus = "";
            timelineClass = "";
          }
        } else {
          let timelineData = this.getTimelineStatus(actualDate, planDate);
          timelineDate = timelineData.timelineDate;
          timelineStatus = timelineData.timelineStatus;
          timelineClass = timelineData.timelineClass;
        }

        return {
          subCategory: step.subCategory,
          actualDate: actualDate,
          planDate: planDate,
          timelineDate,
          timelineStatus,
          timelineClass,
          files: step.files
        };
      });

      return {
        id: tnaAttribute[0].categoryOrder,
        category: tnaAttribute[0].category,
        subCategories,
        minDate: moment(minDate).format(DATESTAMP__SHORT_FORMAT),
        maxDate: moment(maxDate).format(DATESTAMP__SHORT_FORMAT),
        planDate: moment(planDate).format(DATESTAMP__SHORT_FORMAT)
      };
    });
    return tnaGraphData;
  };

  renderDataKeys = () => {
    const { dataKeys } = this.state;

    if (!dataKeys) return;

    const {
      filteredData,
      showEdit,
      currentMinDate,
      onSubmitError,
      isException,
      ppSampleApproval,
      status,
      brandName,
      data,
      skipApproval,
    } = this.state;

    const {
      openModal,
      closeModal,
    } = this.props;

    let mapIndexX = 0;
    let mapIndexY = 0;
    let flattenedTnaIndex = 0;
    let memoIndex;


    const lastTnaAttribute = _.last(data);
    let arePlanDatesFilled = false;
    if (lastTnaAttribute && lastTnaAttribute["subAttributeValue"]) {
      arePlanDatesFilled = true;
    }

    const productionSample =
      _.find(data, {
        categoryOrder: TNA_ATTRIBUTES_ORDER.SAMPLE_DISPATCH.categoryOrder,
        attributeOrder: TNA_ATTRIBUTES_ORDER.SAMPLE_DISPATCH.attributeOrder
      }) || {};

    let productionSampleDispatched = false;
    if (productionSample.attributeValue) {
      productionSampleDispatched = !!productionSample.attributeValue;
    }

    const isApproved = (status === TNA_STATUS.APPROVED);

    return dataKeys.map((key, index) => {
      return (
        <div key={index} className="tna__attribute_groups">
          <div className={`${styles.detail} ${isApproved ? styles.approved : ''}`}>
            <Header headerLabel={filteredData[key][0].category} />
            <div>
              <span>Planned date</span>
              {arePlanDatesFilled &&
                isApproved && (
                  <span>Actual date</span>
                )}
            </div>
          </div>
          {filteredData[key].map(
            (
              {
                category,
                subCategory,
                attribute,
                subAttribute,
                subAttributeValue,
                onEditValue,
                isSelected,
                attributeValue,
                tnaTemplateAttributeId,
                invalidDate = false,
                emptyDate,
                emptyTarget,
                id,
                imageUrl,
                files,
                categoryOrder
              },
              colIndex
            ) => {
              memoIndex = flattenedTnaIndex++;
              return (
                <FormItem
                  tnaId={id}
                  designId={this.props.designId}
                  files={files}
                  onUploadFile={file =>
                    this.uploadFile(id, file, key)
                  }
                  onDelete={fileId =>
                    this.deleteFile(id, fileId, key)
                  }
                  editMode={!showEdit}
                  showImageModal={this.showImageModal}
                  label={subCategory}
                  getData={this.saveDataHandler}
                  getTargetValue={this.saveTargetValueHandler}
                  getCheckBoxValue={this.saveCheckBoxDataHandler}
                  selectedDate={attributeValue}
                  id={tnaTemplateAttributeId}
                  index={index}
                  checkBoxId={tnaTemplateAttributeId}
                  isChecked={isSelected}
                  swingPercentage={subAttribute}
                  withLabel={attribute}
                  sideLabel={attribute}
                  editValue={onEditValue}
                  onSubmitError={onSubmitError}
                  minDate={currentMinDate}
                  invalidDate={invalidDate}
                  targetValue={subAttributeValue}
                  newError={isException}
                  emptyDate={emptyDate}
                  emptyTarget={emptyTarget}
                  showAttachFile={this.state.id && true}
                  resetDate={this.deleteDateHandler}
                  notificationList={this.props.notificationList}
                  previousFieldData={this.state.data[memoIndex - 1]}
                  prevDate={this.getPreviousDate(
                    categoryOrder,
                    mapIndexX++
                  )}
                  nextDate={this.getNextDate(
                    categoryOrder,
                    mapIndexY++
                  )}
                  ppSampleApproval={ppSampleApproval}
                  arePlanDatesFilled={arePlanDatesFilled}
                  status={status}
                  openModal={openModal}
                  closeModal={closeModal}
                  sampleDetails={this.props.sampleDetails}
                  fetchTna={this.getTemplateDataHandler}
                  brandName={brandName}
                  categoryOrder={categoryOrder}
                  productionSampleDispatched={
                    productionSampleDispatched
                  }
                  skipApproval={skipApproval}
                />
              );
            }
          )}
        </div>
      );
    });
  }

  cancelTnaHandler = () => {
    this.getTemplateDataHandler(false);
    this.setState({ showEdit: true, showSubmit: false });
  }

  editTnaHandler = () => this.getTemplateDataHandler(true);

  saveTnaHandler = () => this.submitHandler();

  submitTnaHandler = () => {
    const {
      openModal,
      closeModal,
    } = this.props;

    openModal("SUBMIT_TNA_CONFIRMATION", {
      closeModal,
      handleSubmit: () =>
        this.submitHandler(TNA_STATUS.SUBMITTED)
    });
  };

  reqResubmitTnaHandler = () => {
    const {
      openModal,
      closeModal,
      updateDesignTnaStatus,
      design,
    } = this.props;
    const {
      id: tnaId
    } = this.state;

    openModal("REQUEST_TNA_RESUBMISSION", {
      initialValues: {},
      onSubmit: (data) => {
        updateDesignTnaStatus({
          tnaId,
          note: data.note,
          status: 3,
          designId: design.id,
        })
          .then(() => {
            this.getTemplateDataHandler();
            closeModal();
          });
      }
    });
  };

  approveTnaHandler = () => {
    const { updateDesignTnaStatus, design } = this.props;
    const { id: tnaId } = this.state;

    updateDesignTnaStatus({
      tnaId,
      status: 4,
      designId: design.id,
    })
      .then(() => this.getTemplateDataHandler());
  };

  renderTnaCtas = () => {
    const {
      showEdit,
      showSubmit,
      status,
      data,
    } = this.state;
    const { isFactory } = this.props;

    const lastTnaAttribute = _.last(this.state.data);
    let arePlanDatesFilled = false;
    if (lastTnaAttribute && lastTnaAttribute["subAttributeValue"]) {
      arePlanDatesFilled = true;
    }

    const toolbarButtons = (
      isFactory
        ? [
          (showEdit && (status === TNA_STATUS.CREATED || status === TNA_STATUS.RESUBMITTED)) && {
            key: 1,
            category: 'blue-bg',
            onClick: this.editTnaHandler,
            content: 'Edit TNA',
          },
          ...(!showEdit
            ? [
              {
                key: 2,
                category: 'blue-bg-outline',
                onClick: this.cancelTnaHandler,
                content: 'Cancel',
              },
              (arePlanDatesFilled && data.every(obj => obj.subAttributeValue)) && {
                key: 3,
                category: 'blue-bg',
                onClick: this.submitTnaHandler,
                content: 'Submit TNA',
              },
              (status === TNA_STATUS.CREATED) && {
                key: 4,
                category: 'blue-bg',
                onClick: this.saveTnaHandler,
                content: 'Save changes',
                disabled: !showSubmit
              }
            ] : []),
        ]
        : [
          (status !== TNA_STATUS.RESUBMITTED) && {
            key: 101,
            category: 'blue-bg-outline',
            onClick: this.reqResubmitTnaHandler,
            content: 'Request Resubmission',
          },
          {
            key: 102,
            category: 'blue-bg',
            onClick: this.approveTnaHandler,
            content: 'Approve',
          },
        ]
    ).filter(item => item);

    return <div className={styles.detail__header_with_edit}>
      {isFactory
        ? <Header
          headerLabel="Time and Action Plan"
          overrideClass="tna__action_heading"
        >
          <span className={styles.calender__label}>
            Submitted information will be visible to the brand
        </span>
        </Header>
        : <Header
          headerLabel="Plan TNA"
          overrideClass="tna__action_heading"
        >
        </Header>}
      <Toolbar buttons={toolbarButtons} maxButtons={2} />
    </div>;
  }

  render() {
    const {
      filteredData,
      modalData,
      showImageModal,
      status,
      data,
      copyTnaSourceDesign,
      notes
    } = this.state;
    const {
      openModal,
      sampleDetails,
      design,
      isFactory
    } = this.props;

    const productionSample =
      _.find(data, {
        categoryOrder: TNA_ATTRIBUTES_ORDER.SAMPLE_DISPATCH.categoryOrder,
        attributeOrder: TNA_ATTRIBUTES_ORDER.SAMPLE_DISPATCH.attributeOrder
      }) || {};

    return (
      <div className={`design-tna-plan ${styles.wrapper}`}>
        {status === TNA_STATUS.APPROVED && (
          <TnaGraph
            data={this.getTnaGraphData(filteredData)}
            design={design}
            sampleDetails={sampleDetails}
            openVersionHistoryModal={sampleVersions => {
              openModal("SAMPLE_VERSION_HISTORY", {
                sampleVersions: sampleVersions
              });
            }}
            designId={design.id}
            graphLabel="Production progress"
          />
        )}
        {((status === 0 || !status) && isFactory) ? (
          <CreateTna
            onCopyTna={() => this.copyTnaHandler(true)}
            onCreateTna={this.createTnaHandler}
            sampleStatus={productionSample && productionSample.category}
          />
        ) : (
            <>
              {this.renderTnaCtas()}
              {/* This contains calender and checkbox */}

              {status !== TNA_STATUS.APPROVED && <div className="design-tna-notes">
                <TnaNoteList notes={notes} />
              </div>}

              {/* ========================================== */}
              {/* Form HERE IT STARTS THE DYNAMIC FORM @@@@@ */}

              <div className={`tna-plan-container ${status === TNA_STATUS.APPROVED ? 'approved' : ''}`}>
                <div>
                  {this.renderDataKeys()}
                </div>
                {((status !== TNA_STATUS.APPROVED && status !== TNA_STATUS.SUBMITTED) && isFactory) && <div className="copy-tna-container">
                  {
                    copyTnaSourceDesign
                      ? <EmptyScreen
                        icon={
                          <div className="design-with-cal">
                            <DesignAvatar design={copyTnaSourceDesign} size='72px' />
                            <img src={imgCalendar} alt="calendar" />
                          </div>
                        }
                        iconSize="128px"
                        heading={<TextSpliced text={copyTnaSourceDesign.designName} maxWidth={200} />}
                        description={<>
                          TNA is copied from {copyTnaSourceDesign.designName}. Want to change the design?
                        </>}
                        cta={
                          <div className="flex flex-col">
                            <Button
                              category="blue-bg-outline"
                              onClick={() => this.copyTnaHandler()}
                            >
                              Choose other
                            </Button>
                            <Button
                              category="primary-text"
                              onClick={this.copyTnaClearAllHandler}
                            >
                              Clear all
                            </Button>
                          </div>
                        }
                      />
                      : <EmptyScreen
                        icon={copyTnaImg}
                        iconSize="128px"
                        heading="Copy TNA"
                        description={<>
                          Let’s make this simple.
                          Now you can copy TNA from your
                          recent designs.
                        </>}
                        cta={
                          <Button
                            category="primary-text grid-2"
                            onClick={() => this.copyTnaHandler()}
                          >
                            <img src={copyIcon} alt="copy" />
                            Copy TNA
                          </Button>
                        }
                      />
                  }
                </div>}
              </div>
            </>
          )}

        {showImageModal && (
          <TnaImageModal
            imageUrl={modalData.imageUrl}
            fileName={modalData.fileName}
            title={modalData.title}
            date={modalData.date}
            uploadedBy={modalData.uploadedBy}
            onClose={this.hideImageModal}
          />
        )}
      </div>
    );
  }
}

export default withRouter(TnaPlan);
