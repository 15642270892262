import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import "./CollectionCard.scss";
import PerfectScrollbar from "react-perfect-scrollbar";
import Popover from "react-tiny-popover";
// Nested scroll container
// import LazyLoad from "react-lazyload";
import chevronRight from "../../../assets/images/navigation/ic-chevron-right.svg";
import moreVert from "../../../assets/images/navigation/ic-more-vert.svg";
import icAdd from "../../../assets/images/content/ic-add.svg";
import icEdit from "../../../assets/images/image/ic-edit.svg";
import icDelete from "../../../assets/images/action/ic-delete.svg";
import icAddBlue from "../../../assets/images/content/ic-add-blue.svg";

import get from "lodash/get";
import { TIMESTAMP_FORMAT } from "./../../../constants/Date";
import {
  getCoverImagePlaceholder,
  getSmallImageUrl,
  generateDesignLinkDummy as generateDesignLink
} from "../../../helpers/DesignHelpers";
import { getCurrentUserRole } from "../../../helpers/AuthHelper";
import {
  FACTORY_ADMIN,
  FACTORY_MANAGER,
  PRODUCTION_MERCHANT
} from "../../../constants/Auth";
import { DESIGN_STATUS_KEYS } from "../../../constants/Design";

class CollectionCard extends Component {
  static propTypes = {
    collection: PropTypes.object
  };

  state = {
    currentSelectedDesignRow: null,
    popOverOpen: false,
    userRole: getCurrentUserRole()
  };
  render() {
    const {
      collection,
      openModal,
      isOwner,
      designs,
      openNewDesignModal,
      match: { url }
    } = this.props;

    const { userRole } = this.state;
    const canDelete = designs.length === 0 || !designs.some((design) => ![1, 2, 11].includes(design.status));

    const Menu = (
      <React.Fragment>
        <div
          onClick={() =>
            openModal("EDIT_COLLECTION", {
              hiddenFields: [{ name: "id", value: collection.id }],
              initialValues: {
                name: collection.name
                // description: collection.description
              }
            })
          }
          className="pop-over__item flex f-sz-l"
        >
          <img src={icEdit} alt="edit" />
          <span>Edit</span>
        </div>
        {canDelete && (
          <div
            onClick={() =>
              openModal("DELETE_COLLECTION", {
                hiddenFields: [{ name: "id", value: collection.id }]
              })
            }
            className="pop-over__item flex f-sz-l"
          >
            <img src={icDelete} alt="delete collection" />
            <span>Delete</span>
          </div>
        )}
      </React.Fragment>
    );

    return (
      <div className="col-card flex">
        <div
          className={`col-card__header flex f-sz-m ${
            designs.length === 0 ? "col-card__header--empty" : ""
          }`}
        >
          <div className="col-card__details">
            <div
              className="col-card__title f-sz-l f-w-semi-bold"
              style={{ lineHeight: "1.25", marginBottom: "2px" }}
            >
              <Link
                style={{ wordBreak: "break-word" }}
                to={`${url}${collection.id}/?tab=allDesigns`}
              >
                {collection.name}
              </Link>
            </div>
            <div
              className="col-card__metadata f-sz-sm"
              style={{ color: "var(--fill-grey-dark)" }}
            >
              by {collection.username} &nbsp;&nbsp;
              {moment(collection.created_on)
                .format(TIMESTAMP_FORMAT)
                .toString()}
            </div>
          </div>
          {isOwner && (
            <div className="col-card__options">
              <Popover
                isOpen={this.state.popOverOpen}
                position={"bottom"}
                content={<div>{Menu}</div>}
                onClickOutside={() => this.setState({ popOverOpen: false })}
              >
                <img
                  onClick={() => this.setState({ popOverOpen: true })}
                  src={moreVert}
                  alt="three dots"
                  className="custom-center"
                />
              </Popover>
            </div>
          )}
        </div>

        {designs.length > 0 ? (
          <React.Fragment>
            <PerfectScrollbar
              className="col-card__designs flex"
              option={{
                wheelPropagation: true
              }}
            >
              {designs
                .sort((a, b) => {
                  return moment(a["createdAt"]).isBefore(b["createdAt"])
                    ? 1
                    : -1;
                })
                .map((product, index) => (
                  <Link
                    // onMouseEnter={() =>
                    //   this.setState({
                    //     currentSelectedDesignRow: index
                    //   })
                    // }
                    // onMouseLeave={() =>
                    //   this.setState({
                    //     currentSelectedDesignRow: null
                    //   })
                    // }
                    to={generateDesignLink({
                      design: product,
                      pathname: `${collection.id}/designs/:design_id/`
                    })}
                    key={product.id}
                  >
                    <div className={`product flex ${(product.status === DESIGN_STATUS_KEYS.CANCELED || product.status === DESIGN_STATUS_KEYS.ARCHIVED) ? "inactive" : ""}`}>
                      <span
                        data-tip={
                          product.statusString
                            ? product.statusString.toLowerCase()
                            : "draft"
                        }
                        className="product__status"
                      >
                        {/* <LazyLoad height={40} overflow={true}> */}
                        <img
                          style={{ backgroundColor: "#f1f1f1" }}
                          src={
                            get(product, "coverImageUrl", "").length > 0
                              ? getSmallImageUrl(product["coverImageUrl"])
                              : getCoverImagePlaceholder(product).src
                          }
                          alt="sample status"
                        />
                        {/* </LazyLoad> */}
                      </span>
                      <span className="product__details flex f-sz-m">
                        <span className="product__name">{product.name}</span>
                        <span className="product__metadata f-sz-sm f-color-faded">
                          {product.category} - {product.subCategory}
                        </span>
                      </span>
                      <span
                        className={
                          this.state.currentSelectedDesignRow === index
                            ? ""
                            : "hide-visibility"
                        }
                      >
                        <img src={chevronRight} alt="right arrow" />
                      </span>
                    </div>
                  </Link>
                ))}
            </PerfectScrollbar>
          </React.Fragment>
        ) : (
          <div className="col-card__designs flex">
            {userRole !== FACTORY_ADMIN && userRole !== FACTORY_MANAGER ? (
              <div className="col-card__new-design f-sz-m f-color-faded">
                <div onClick={openNewDesignModal} className="btn flex">
                  <img
                    style={{ margin: "auto" }}
                    src={icAddBlue}
                    alt="add design"
                  />
                </div>
                <span>Add a design</span>
              </div>
            ) : (
              <div />
            )}
          </div>
        )}
        <div className="col-card__footer flex">
          <div className="col-card__design-num f-sz-sm">
            {String(designs.length)} design(s)
          </div>
          {/*
          {userRole !== FACTORY_ADMIN && userRole !== FACTORY_MANAGER && collection.factoryName && <div className="col-card__add-design tag-pill clickable" onClick={openAssociateModal}>
            {collection.factoryName}
          </div> */}

          {userRole !== FACTORY_ADMIN &&
            userRole !== FACTORY_MANAGER &&
            userRole !== PRODUCTION_MERCHANT && (
              <div
                onClick={openNewDesignModal}
                className="col-card__add-design"
                data-tip="Add Design"
                data-place="bottom"
              >
                {/* <i class="fa fa-plus" /> */}
                <img src={icAdd} alt="add design" />
              </div>
            )}
        </div>
      </div>
    );
  }
}

export default withRouter(CollectionCard);
