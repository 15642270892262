import { createAction } from "redux-actions";
import axios from "axios";
import { get_service_endpoint } from "../../ServiceEndpoints";
import { closeModal } from "./GlobalActions";

import _ from "lodash";
import { flash } from "./CommonActions";
import moment from "moment";

import { normalizeForPatch } from "./../../helpers/swatchHelper";

const ep = get_service_endpoint("swatchLibrary");

const GET_SWATCHES = createAction("GET_SWATCHES");
const GET_SWATCH_VENDORS = createAction("GET_SWATCH_VENDORS");
const GET_SWATCH_DESIGN_FILES = createAction("GET_SWATCH_DESIGN_FILES");
const SET_DESIGN_FILES = createAction("SET_DESIGN_FILES");
const GET_SWATCH_FILTERS = createAction("GET_SWATCH_FILTERS");
const SET_SWATCH_PAGINATION = createAction("SET_SWATCH_PAGINATION");
const DELETE_SWATCH = createAction("DELETE_SWATCH");
const ADD_VENDOR = createAction("ADD_VENDOR");

const DATE_FORMAT = "YYYY-MM-DD";

const convertObjectToFormData = rawJsonData => {

  let formData = new FormData();
  let files = {},
    arrays = {extraImagesUrls: []};

  const jsonData = normalizeForPatch(rawJsonData);

  let addedExtraImages = [];

  Object.keys(jsonData).forEach((k,index) => {
    if(k == "extraImages"){
       addedExtraImages = jsonData['extraImages'].filter(({image})=>{
        if(image instanceof File){
          return true;
        } else {
          arrays['extraImagesUrls'].push(image);
          return false;
        }
      })
    } else {
      if (jsonData[k] instanceof File) {
        files[k] = jsonData[k];
        return;
      } else if (jsonData[k] instanceof Array) {
        arrays[k] = jsonData[k];
        return;
      }
      formData.append(k, jsonData[k]);
    }
  });

  // Send stringified arrays in formData in order to get it parsed in back-end
  Object.entries(arrays).forEach(([k, arr]) => formData.append(k, JSON.stringify(arr)));
  // Add files in the end of the Object
  Object.entries(files).forEach(([k, f]) => formData.append(k, f, f.name));

  // delete formData['extraImages'];
  if(jsonData["extraImages"] && jsonData["extraImages"].length > 0){
    addedExtraImages.forEach(imageFile => {
      const image = imageFile.image;
      if(image instanceof File)
      formData.append("extraImages", image,image.name)
    })
  }
  return formData;
};

const validateTransformSwatchDetails = swatchDetails => dispatch =>
  new Promise((resolve, reject) => {
    const formData = _.cloneDeep(swatchDetails);
    if (
      !formData.category.length ||
      !formData.vendorPrices ||
      (!formData.image && !(formData.image instanceof File)) ||
      (!formData.rulerImage && !(formData.rulerImage instanceof File)) ||
      !formData.color ||
      !formData.fabricQuality ||
      formData.availability == "" ||
      !formData.quantity ||
      formData.idealFor == undefined ||
      !formData.fabricCategory ||
      formData.yardageAvailable == undefined ||
      !formData.unit
    ) {
      dispatch(flash({ message: "Required fields are empty!" }));
      reject();
      return;
    }
    resolve(formData);
  });

export const createSwatch = form => dispatch => {
  return dispatch(validateTransformSwatchDetails(form)).then(formData =>
    axios.post(`${ep}/`, convertObjectToFormData(formData)).then(() => {
      dispatch(flash({ message: "Swatch added successfully" }));
      dispatch(closeModal());
    })
  );
};

export const editSwatch = swatchDetails => dispatch => {
  return dispatch(validateTransformSwatchDetails(swatchDetails)).then(formData =>
    axios.patch(`${ep}/`, convertObjectToFormData(formData)).then(() => {
      dispatch(closeModal());
      dispatch(flash({ message: "Updated successfully" }));
    })
  );
};

export const filterSwatches = (filterData, pageOffSet=null) => (dispatch, getState) => {
  let processedData = _.chain(filterData)
  // Convert all keys to camelCase
    .mapKeys((value, key) => _.camelCase(key))
    // Convert all Zero length arrays to null
    .mapValues((value, key) =>
      typeof value !== "string" && value !== null ? (value.length === 0 ? null : value) : value
    )
    // Get original value from Lodash wrapper
    .value();

  if (processedData["category"] && processedData["category"][0] === "null") {
    processedData["category"] = null;
  }

  if( processedData['idealFor']) {
    const idealfor = processedData['idealFor']
    if(idealfor.length == 1){
      processedData['idealFor'] = idealfor[0]
    } else {
      processedData['idealFor'] = "2"
    }
  }

  // Change date formats
  // if (processedData["startDate"] !== null && processedData["endDate"] !== null) {
  //   processedData["startDate"] = moment(processedData["startDate"]).format(DATE_FORMAT);
  //   processedData["endDate"] = moment(processedData["endDate"]).format(DATE_FORMAT);
  // }

  processedData["pageOffSet"] = pageOffSet;
  processedData["numberOfRecords"] = 30;
  const temp = _.get(processedData, "stockAvailability.0", null);
  delete processedData['stockAvailability'];
  processedData["includeOutOfStock"] = temp === null ? temp : Number(temp);

  const userRole = getState().auth.userDetails['role'];

  // Send the userRole in teh post data
  processedData['userRole'] = userRole;

  return axios.post(`${ep}/library`, processedData).then(({ data }) => {
    if (data.error) dispatch(GET_SWATCHES([]));
    else if (data instanceof Array) {
      dispatch(GET_SWATCHES(data));
      if(data.length == 0) {
        dispatch(SET_SWATCH_PAGINATION({}));
      }
    }
    else {
      dispatch(GET_SWATCHES(data.swatches));
      dispatch(SET_SWATCH_PAGINATION(data.paginate));
    }

  });
};

export const getSwatchVendors = () => dispatch =>
  axios.get(`${ep}/vendor`).then(({ data }) => dispatch(GET_SWATCH_VENDORS(data)));

export const getSwatchDesignFiles = ({ designId }) => dispatch =>
  axios
    .get(`${ep}/designfiles`, { params: { designid: designId } })
    .then(({ data }) => {
      dispatch(GET_SWATCH_DESIGN_FILES(data));
      dispatch(SET_DESIGN_FILES({data, designId}));
    })
    .catch(e => {
      dispatch(GET_SWATCH_DESIGN_FILES([]));
    });

export const getSwatchFilters = ({ brandId, startDate, endDate, dateFilter }) => dispatch =>
  axios
    .get(`${ep}/filters`, {
      params: {
        brandid: brandId,
        dateFilter
        // startDate: startDate === null ? startDate : moment(startDate).format(DATE_FORMAT),
        // endDate: endDate === null ? endDate : moment(endDate).format(DATE_FORMAT)
      }
    })
    .then(({ data }) => dispatch(GET_SWATCH_FILTERS(data)));

export const postSwatchVendors = details => dispatch => {
  axios.post(`${ep}/vendordata`, details).then(vendorData => {
    dispatch(ADD_VENDOR(vendorData.data));
    dispatch(closeModal());
    dispatch(flash({ message: "Vendor added successfully" }));
  })
};

export const deleteSwatch = ({id}) => dispatch => {
    axios.delete(`${ep}/`, { params: {
      id
    }}).then(() => {
      dispatch((DELETE_SWATCH(id)));
      dispatch(closeModal());
      dispatch(flash({ message: "Swatch deleted successfully" }));
    })
};
