import React, { Component } from "react";
import PropTypes from "prop-types";

import RedCross from "assets/img/Quote/cross-with-circle.svg";
import QuoteCard from "./QuoteCardBrand";
import "./styles.scss";

const ResubmitQuoteModal = ({ quoteDetails, design }) => (
  <div className="quote-actions reject">
    <QuoteCard
      quoteDetails={quoteDetails}
      design={design}
      noteText="You’ll be unable to accept this quote once rejected."
      overlayIcon={RedCross}
    />
  </div>
);

export default ResubmitQuoteModal;
