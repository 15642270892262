import React, { Component, useRef } from "react";
import PropTypes from "prop-types";
import Trash from "../../../../assets/images/action/ic-delete.faded.svg";
import "./FileVertical.scss";
import icPlcAttachment from "../../../../assets/images/file/ic-attach-file.svg";
import { getFileData } from "./../../../../helpers/swatchHelper";

const File = React.memo(({
   input: { value, onChange },
   meta: { submitFailed , invalid }, 
  headerText,
  required,
  ...restProps
}) => {

  const fileInputRef = useRef(null);

  const handleChange = e => {
    onChange(e.target.files[0])
  }

  const deleteFile = () => {
    fileInputRef.current.value = "";
    onChange("");
  };

  const [ containerBg, fileName ] = getFileData(value);

  return (
      <div
        className = "input-file-vertical"
        style = {{
          backgroundImage: value ? `url(${containerBg})`: null,
          border: submitFailed && invalid ? '1px dashed red': '1px dashed #bfbfbf',
        }}
      >
        <input
          type='file'
          accept='.jpg, .png, .jpeg'
          onChange={handleChange}
          ref={fileInputRef}
          className={"hide"}
        />
        {value !==  "" ? (
          <React.Fragment>
            <div className="input-file__wrapper selected flex">
              <div className="flex-col">
                <span className="input-file__meta-selected">
                  <span>
                    { `${fileName.slice(0,15)}...` }
                  </span>
                  <div className="input-file__delete">
                    <img src={Trash} alt="" onClick={deleteFile} />
                  </div>
                </span>
              </div>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className="input-file__wrapper flex">
              <div className="input-file__file-thumb flex"
                onClick={() =>  fileInputRef.current.click() }           
              >
                <img
                  style={{ margin: "auto" }}
                  src={icPlcAttachment}
                  alt="attachment"
                />
              </div>
              <div className="input-file__progress flex-col">
                <span className="input-file__meta">
                  <span className="input-header__text">
                    { headerText } 
                    { required && <sup>*</sup>}
                    <br />
                  </span>
                  <span className="f-sz-sm">
                    Attach image
                  </span>
                </span>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
  )
}, anyChange)

const anyChange = (prevProps, nextProps) => {
  if(nextProps.input.value){
    return prevProps.input.value === nextProps.input.value
  }
  return false;
}

File.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string
    ])
  }),
  headerText: PropTypes.string,
  required: PropTypes.bool
}

File.defaultProps= { 
  headerText: "Image",
  required: false
}

export default File;
