import React, { useState, useEffect } from "react";
import isEmpty from "lodash/isEmpty";

import unionImg from "assets/images/dashboard/union.svg";
import sewingImg from "assets/images/dashboard/sewing.svg";

import { Header } from "./../Header";
import { DesignRow } from "./DesignRow";
import { HeaderTabs } from "./../HeaderTabs";
import { Pagination } from "./../Pagination";

import Loader from "components/UI/InlineLoader";
import EmptyScreen, { Horizontal } from "../../UI/EmptyScreen/index.jsx";

import "./styles.scss";

const ActiveDesign = ({
  getDesigns,
  activeDesigns: propDesigns
}) => {

  const [filters, setFilters] = useState({ filter: "all" });
  const [currentPage, setCurrentPage] = useState(0); // 0th index
  const [lastFetchedPage, setLastFetchedPage] = useState(-1); // 0th index
  const [activeDesigns, setActiveDesigns] = useState({});
  const fetchLimit = 7;

  useEffect(() => {
    // TODO: Filters effect causes the api to be called twice.
    if (currentPage > lastFetchedPage || filters.filter !== activeDesigns.paginate.filter)
      fetchDesigns(filters, currentPage);
  }, [filters])

  useEffect(() => {
    setActiveDesigns(propDesigns);
    setLastFetchedPage(currentPage);
  }, [propDesigns])


  const handlePagination = (value, page) => {
    const isNext = page > lastFetchedPage;
    fetchDesigns({ ...filters, offset: activeDesigns.paginate.nextOffset, ...value }, page, isNext)
  }

  const fetchDesigns = (filters, page, isNext = true) => {
    if (isNext) {
      getDesigns({ ...filters, limit: fetchLimit, isNext: activeDesigns.counts ? 1 : 0, isLoader: true });
      setCurrentPage(page);
      setLastFetchedPage(page);
    } else {
      const {
        paginate,
        allDesigns
      } = activeDesigns;
      setActiveDesigns({
        ...activeDesigns,
        designsData: allDesigns.slice((page) * paginate.limit, (page + 1) * paginate.limit),
      })
      setCurrentPage(page);
      return;
    }
  };

  if (isEmpty(activeDesigns)) {
    return <Loader />;
  }

  const { counts, designsData, paginate } = activeDesigns;

  return (
    <div className="active-design">
      <div className="active-design-conatiner">
        <div className="design-conatiner-heading">
          <img src={unionImg} className="active-icon" alt="" />
          Active designs
        </div>
        <div className="design-conatiner-tabs">
          <div className="tabs flex">
            <HeaderTabs
              onChange={value => {
                setCurrentPage(0);
                setLastFetchedPage(0);
                setFilters({
                  ...filters,
                  filter: value,
                })
              }}
              tabs={[
                {
                  label: `All (${counts.allCount})`,
                  value: "all"
                },
                {
                  label: `Sampling (${counts.samplingCount})`,
                  value: "sampling"
                },
                {
                  label: `Production (${counts.productionCount})`,
                  value: "production"
                }
              ]}
            />
          </div>
        </div>
      </div>
      <div className="design-active-table">
        <Header headings={["Design", "Stage", "Status", "Owner"]} />
        <section className="design-table-container">
          {
            designsData.length > 0
              ? designsData.map((design, index) => (
                <DesignRow data={design} key={`${design.designId}-${index}`} />
              ))
              : <EmptyScreen
                icon={sewingImg}
                iconSize="72px"
                iconCurved={false}
                orientation={Horizontal}
                heading="No active designs"
                description={<>
                  None of your designs are <br />
                    in sampling or production
                  </>}
              />
          }
        </section>
        <Pagination
          data={{
            total: designsData.length,
            totalCount: counts[`${filters.filter}Count`],
            limit: paginate.limit
          }}
          onChange={handlePagination}
          currentIndex={currentPage}
        />
      </div>
    </div>
  )
}

export default ActiveDesign;
