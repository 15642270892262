import React from 'react';
import moment from 'moment';
import './CalendarPage.scss';

export default class CalendarPage extends React.Component {

  getDateParts = (args = []) => {
    const { date } = this.props;
    const momentDate = moment(date);
    return args.map(arg => momentDate.format(arg));
  }

  render() {
    const [date, monthName, fullYear] = this.getDateParts(['DD', 'MMM', 'YYYY']);

    return <div className="calendar-page">
      <div className="month">{monthName}</div>
      <div className="date">{date}</div>
      <div className="year">{fullYear}</div>
    </div>
  }
}
