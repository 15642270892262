import React, { useEffect } from "react";
import "./SwatchCard.scss";
import Checkbox from "../../components/UI/Checkbox/Checkbox";
import { getSmallImageUrlForCover } from "../../helpers/DesignHelpers";
import classnames from "classnames";
import { useHover } from './../../customHooks/useHover';
import { FILTER_FABRIC_COLORS } from './../../constants/SwatchLibrary';
import find from 'lodash/find'
import { canDeleteSwatch } from "./../../helpers/swatchHelper"

export default function SwatchCard({
  children,
  img,
  onSelect,
  openModal,
  isSelected = false,
  swatchId,
  brandid,
  isFactory,
  deleteSwatch,
  onlyAddToDesign,
  details,
  colors,
  displayId,
  ...props
}) {
  useEffect(() => {
    return () => {};
  }, [isSelected]);

  const [ref, hovered] = useHover();

  const moreColors = (colors) => {
    const colorsArray = colors.slice(0,6);
    const diff = colors.length - colorsArray.length;
    return ( colorsArray.map( 
      colorObj=> 
      <>
      <span className="swatch-card__more-color-dot" style={{ background: colorObj.color}}></span>
      </>
      )
    )
  }

  const showDelete = canDeleteSwatch(); 

  return (
    <div
      className={classnames("swatch-card flex-col", { "swatch-card--selected": isSelected })}
      style={{ marginBottom: "20px", }}
      onClick={() => {
        // === send this swatchid and brandid when brand is logged in ===
        openModal("SWATCH_LIBRARY_SHOW_SWATCH_DETAILS", {
          swatchid: swatchId,
          brandid,
          isFactory,
          onlyAddToDesign,
          details,
          showDelete,
          deleteSwatch
        });
      }}
      ref={ref}
    >
      <div
        style={{
          left: "10px",
          top: "1em",
          zIndex: "999"
        }}
        id="checkbox"
      >
        <Checkbox value={isSelected} onClick={() => onSelect(!isSelected)} />
      </div>
      <img src={getSmallImageUrlForCover(img)} className="swatch-card__img" alt="" />
      { hovered && <div className="caption">ID: {displayId}</div>}
      <div className="swatch-card__caption f-w-semi-bold">{children}</div>
      {
        colors.length > 0 && (
          hovered ? 
            (
              <>
              <div
                style={{
                  padding: '2px 0'
                }}
              >Also available in</div>
              <div style={{
                display: 'flex',
                alignItems: 'center',
                color: 'var(--fill-grey-medium)'
              }}>
                { 
                  moreColors(colors)
                }
                {
                  colors.length > 6 && <span>+{colors.length - 6}</span> 
                }
              </div>
              </>
            )
            :
            (
            <div className="swatch-card__more_color">
              {`${colors.length} more ${colors.length == 1 ? `color`:`colors`}`}
            </div>
          ))
      }

    </div>
  );
}
