import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import classnames from 'classnames';
import Button from './../Button/Button';
import ImgClose from 'assets/images/navigation/ic-close.svg';
import './index.scss';
import isBoolean from 'lodash/isBoolean';

export default class SideDrawer extends React.Component {
  state = {
    isVisible: false
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (isBoolean(nextProps.isVisible)) {
      return {
        isVisible: nextProps.isVisible
      };
    }

    return null;
  }

  closeDrawer = () => {
    if (typeof (this.props.onClose) === 'function') {
      this.props.onClose();
    }

    this.setState({ isVisible: false });
  }

  renderFooter() {
    if (typeof (this.props.renderFooter) === 'function') {
      return this.props.renderFooter();
    }

    const { submitText } = this.props;

    return <div className="footer-content">
      <Button category="blue-bg">{submitText}</Button>
    </div>
  }

  render() {
    const {
      direction,
      title,
      subtitle,
      children,
      className
    } = this.props;
    const { isVisible } = this.state;

    return (<div className={classnames('side-drawer', direction, isVisible ? 'show' : 'hide', className)}>
      <div className="side-drawer-backdrop" onClick={this.closeDrawer}></div>
      <div className="side-drawer-container">
        <div className="header">
          <div className="title-container">
            <span className="title">{title}</span>
            <span className="close" onClick={this.closeDrawer}>
              <img src={ImgClose} alt="" />
            </span>
          </div>
          {subtitle && <div className="subtitle">{subtitle}</div>}
        </div>
        <div className="side-drawer-content">
          <div className="side-drawer-content-container">
            <PerfectScrollbar>
              {children}
            </PerfectScrollbar>
          </div>
        </div>
        <div className="footer">
          {this.renderFooter()}
        </div>
      </div>
    </div>);
  }
}
