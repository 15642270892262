import React, { Component } from "react";
import PropTypes from "prop-types";
import Form from "../../components/UI/Form/Form";
import PerfectScollbar from "react-perfect-scrollbar";
import Checkbox from "../../components/UI/Checkbox/Checkbox";

export default class NotificationUserList extends Component {
  state = {
    selectedUserIndex: []
  };
  checkboxRefs = {};

  render() {
    return (
      <Form {...this.props} form="sendNotificationUserList">
        {({ changeFormValue, ...props }) => {
          return (
            <div style={{ width: "400px", padding: "1em 2em" }}>
              <div
                className="f-w-semi-bold"
                style={{
                  lineHeight: "1.43",
                  height: "48px",
                  padding: "14px 0px",
                  borderBottom: "1px solid var(--fill-grey-light3)"
                }}
              >
                To
              </div>
              <PerfectScollbar
                option={{
                  suppressScrollX: true
                }}
                style={{ maxHeight: "300px", height: "300px" }}
              >
                {props.userList.map((user, index) => (
                  <div
                    className="flex"
                    style={{
                      padding: "20px 0px",
                      height: "84px",
                      justifyContent: "space-between",
                      alignItems: "center",
                      cursor: "pointer"
                    }}
                    onClick={evt => {
                      console.log("evt", evt, evt.target);
                      this.checkboxRefs[index].click();
                    }}
                  >
                    <div
                      className="flex-col"
                      style={{
                        userSelect: "none"
                      }}
                    >
                      <div
                        className="notif-user-list__user-name"
                      >
                        {user["name"]}
                      </div>
                      <div
                        className="notif-user-list__user-email"
                      >
                        {user["email"]}
                      </div>
                    </div>
                    <div style={{ alignSelf: "end" }}>
                      <Checkbox
                        value={this.state.selectedUserIndex.includes(user.id)}
                        setRef={ref => {
                          // console.log(ref);
                          this.checkboxRefs[index] = ref;
                        }}
                        onClick={() => {
                          this.setState((state, props) => {
                            let newSelectedUserIndex = [];
                            if (state.selectedUserIndex.includes(user.id)) {
                              newSelectedUserIndex = state.selectedUserIndex.filter(i => i !== user.id);
                            } else {
                              newSelectedUserIndex = [...new Set([...state.selectedUserIndex, user.id])];
                            }

                            changeFormValue("selectedUserIndex", newSelectedUserIndex);
                            return {
                              selectedUserIndex: newSelectedUserIndex
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                ))}
              </PerfectScollbar>
            </div>
          );
        }}
      </Form>
    );
  }
}
