import React, { Component } from "react";
import PropTypes from "prop-types";
import { Route, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import actions from "../../store/actions";

import Header from "../../components/UI/Header/Header";
import Sidebar from "./../../components/UI/Sidebar/Sidebar";

import { ReactComponent as SwatchLibImg } from "../../assets/images/Left Nav/ic-nav-swatchlib.svg";


import PrivateRoute from "../../components/UI/PrivateRoute/PrivateRoute";
import AccountSettingsContainer from "./../AccountSettingsContainer/AccountSettingsContainer";
import BrandSearch from "./../../components/Factory/BrandSearch/BrandSearch";
import FactoryNotificationDashboard from "../NotificationContainer/FactoryNotificationDashboard";
import _ from "lodash";
import SwatchLibraryContainer from "../SwatchLibraryContainer/SwatchLibraryContainer";

import { FACTORY_ADMIN, FACTORY_MANAGER, LOCO_SUPER } from "../../constants/Auth";
import { getCurrentUserRole, refreshTokenIfOld } from "../../helpers/AuthHelper";

class FabricSupplierContainer extends Component {
  static propTypes = {
    openModal: PropTypes.func.isRequired,
    collections: PropTypes.object.isRequired,
    userDetails: PropTypes.object.isRequired,
    getAllCollections: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    let sidebarUpperMenu = [
      {
        name: "Swatch Library",
        img: SwatchLibImg,
        link: "/supply/swatchLibrary/"
      }
    ];

    refreshTokenIfOld(this.props.refreshToken);

    this.state = {
      upperMenu: sidebarUpperMenu,
      userRole: getCurrentUserRole()
    };

  }


  componentWillReceiveProps(nextProps) {
    if (nextProps.location.pathname === "/factory/brands/") {
      if (this.props.currentBrand.brandId !== null)
        this.props.setCurrentBrand({ brandId: null });
    }
  }

  render() {
    const { upperMenu } = this.state;
    const { userDetails } = this.props;

    return (
      <div
        className="brand-dash"
        onClick={() => {
          if (this.props.modal.isOpen) {
            this.props.closeModal();
          }
        }}
      >
        <Header
          isFactory={this.props.userDetails.role === LOCO_SUPER || this.props.userDetails.role === FACTORY_ADMIN || this.props.userDetails.role === FACTORY_MANAGER}
          dontShowInLogin={window.appHistory.location !== "/auth/login/"}
          noBellIcon={getCurrentUserRole() !== LOCO_SUPER}
          userDetails={userDetails}
        />
        <div
          className="brand-dash__main" // this.props.modal.isOpen
        >
          <Sidebar
            upperItems={upperMenu}
            userDetails={this.props.userDetails}
          />

          {/* // TODO : Add Messages Tab */}

          <div className="brand-dash__content">
            <PrivateRoute
              exact
              path="/supply/swatchLibrary"
              render={routeProps => {
                return (
                  <SwatchLibraryContainer {...routeProps} isFactory={false} isSuperAdmin={getCurrentUserRole() === 'LOCO_SUPER'} userRole={getCurrentUserRole()} />
                );
              }}
            />
            <Route
              path="/supply/settings/"
              render={routeProps => {
                return (
                  <AccountSettingsContainer {...routeProps} />
                )
              }
              }
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userDetails: state.auth.userDetails,
  modal: state.global.modal,
});

const mapDispatchToProps = dispatch => ({
  openModal: (content, additionalProps) =>
    dispatch(actions.openModal(content, additionalProps)),
  closeModal: (content, additionalProps) =>
    dispatch(actions.closeModal(content, additionalProps)),
  refreshToken: () => dispatch(actions.refreshToken()),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(FabricSupplierContainer)
);
