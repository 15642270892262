import axios from "axios";
import { createAction } from "redux-actions";
import { get_service_endpoint } from "../../ServiceEndpoints";

const apiv2 = get_service_endpoint("apiv2");

const MESSAGING_DESIGNS = createAction("MESSAGING_DESIGNS");
const MESSAGING_DESIGN_FACTORIES = createAction("MESSAGING_DESIGN_FACTORIES");
const MESSAGING_DESIGN_MESSAGES = createAction("MESSAGING_DESIGN_MESSAGES");
const MESSAGING_MESSAGE_SENT = createAction("MESSAGING_MESSAGE_SENT");
const MESSAGING_MARK_READ = createAction("MESSAGING_MARK_READ");

export const fetchDesignsForMessaging = (brandId = null) => dispatch => {
  let params = {};
  if (brandId) {
    params = { brandId }
  }
  return axios
    .get(`${apiv2}/comments/designs`, { params })
    .then(res => {
      dispatch(MESSAGING_DESIGNS(res.data));
      return res.data;
    });
}

export const fetchFactoriesForDesignId = ({ designId }) => dispatch => {
  return axios
    .get(`${apiv2}/comments/designs/${designId}/factories`)
    .then(res => {
      dispatch(MESSAGING_DESIGN_FACTORIES({
        designId,
        factories: res.data,
      }));

      return res.data;
    });
}

export const fetchMessagesByDesignId = ({ designId, factoryId }) => dispatch => {
  return axios
    .get(`${apiv2}/comments/designs/${designId}/factories/${factoryId}`)
    .then(res => {
      dispatch(MESSAGING_DESIGN_MESSAGES({
        factoryId,
        designId,
        messages: res.data,
      }));

      // mark message as read after 2 sec
      setTimeout(()=>{
        dispatch(MESSAGING_MARK_READ({
          designId,
          factoryId,
        }));
      }, 2000);

      return res.data;
    });
}

export const sendMessage = ({ designId, factoryId, message, file = null }) => dispatch => {
  const payload = new FormData();

  payload.append('designId', designId);
  payload.append('factoryId', factoryId);
  payload.append('comment', message);
  payload.append('moduleType', 5);

  if (file) {
    payload.append('file', file);
  }

  return axios
    .post(`${apiv2}/comments/designs/${designId}/factories/${factoryId}`, payload)
    .then(res => {
      if (res.data && res.data.comment) {
        dispatch(MESSAGING_MESSAGE_SENT({
          designId,
          factoryId,
          sentMessage: res.data,
        }));
      }

      return res.data;
    });
}
