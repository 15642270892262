import React, { useEffect } from "react";
import { Table, Popover } from 'antd'
import { CSVLink, CSVDownload } from 'react-csv'

import Pagination from "../../../components/Product/Pagination";

// styles
import 'antd/dist/antd.css'
import './table.scss'
import moment from "moment";
import { useState } from "react";
import { QUICK_MENU_OPTIONS } from "constants/PerformanceReport";

const getColumns = (data) => {

  const requestsheaders = [
    { label: "Account Manager", key: "am" },
    { label: "City Head", key: "cityHead" },
    { label: "Quantity Generated", key: "quantityGenerated" },
    { label: "Requests Closed", key: "requestsClosed" },
  ];

  const getRequestsData = data?.map(i => ({
    am: i?.name,
    cityHead: i?.cityHead,
    requestsClosed: i.requestsClosed,
    quantityGenerated: i?.quantityGenerated?.map((j, idx) => `${j?.quantity} ${j?.unit} (${j?.count})`).join(' ; ') || 0
  }))

  const ordersHeaders = [
    { label: "Account Manager", key: "am" },
    { label: "City Head", key: "cityHead" },
    { label: "Quantity Booked", key: "quantityBooked" },
    { label: "Sales Value Exc. GST", key: "salesValue" },
    { label: "Profit %", key: "profitPercentage" },
    { label: "New Customers", key: "newCustomers" },
    { label: "Processing", key: "processing" },
    { label: "Weaving", key: "weaving" },
  ]

  const ordersData = data?.map(i => ({
    am: i?.name,
    cityHead: i?.cityHead,
    quantityBooked: i?.quantityBooked?.map((j, idx) => `${j?.quantity} ${j?.unit} (${j?.count})`).join(' ; ') || 0,
    salesValue: i.salesValue,
    profitPercentage: `${i?.profitPercentage?.avg || 0} [ ${i?.profitPercentage?.min || 0}, ${i?.profitPercentage?.max || 0}]`,
    newCustomers: i.newCustomers,
    processing: i.processingOrdersCount,
    weaving: i.weavingOrdersCount
  }))

  const columns = [
    {
      title: () => <p className='center'>AM</p>,
      dataIndex: 'name',
      key: 'name',
      width: 120,
      fixed: 'left',
      align: 'left',
      className: 'fixed-column'
    },
    {
      title: 'City Head',
      dataIndex: 'cityHead',
      key: 'cityHead',
      width: 120,
      align: 'left',
      render: (val) => val ? val : '-'
    },
    {
      title: () => {
        return (
          <div>
            Requests
            {
              getRequestsData !== undefined &&
              <CSVLink CSVLink className='report-download-btn' filename='AM-requests' data={getRequestsData} headers={requestsheaders}>
                <img src={require('../../../assets/images/download.svg')} alt='' />
                Reports</CSVLink>}
          </div >
        )
      },
      children: [
        {
          title: 'Quantity Generated',
          dataIndex: 'quantityGenerated',
          key: 'quantityGenerated',
          align: 'center',
          render: (val) => {
            if (val.length === 0) {
              return '-'
            }
            return (
              val && val?.map(i => {
                return (
                  <div className='relative quantity-booked'>
                    <p className='quantity-value'>{i?.quantity} {i?.unit || 0}</p>
                    <span span className='quantity-circled-value'> {i?.count} </span>
                  </div>
                )
              })
            )
          }
        },
        {
          title: 'Requests Closed',
          dataIndex: ['requestsClosed', 'closingComments'],
          key: 'requestsClosed',
          align: 'center',
          className: 'column-right-border',
          render: (val, data) => {
            const { closingComments, requestsClosed } = data
            return (
              <div className='row-center'>
                <p className='quantity-value'>{requestsClosed}</p>
                <div className='info-img'>
                  <Popover content={
                    <div className='info-modal'>
                      {closingComments && closingComments?.map(i => {
                        return (
                          <div className='info-item'>
                            <b>{i?.count}</b>
                            <p>{i?.comment}</p>
                          </div>
                        )
                      })}
                    </div>
                  }>
                    <img src={require('../../../assets/images/info.svg')} alt='info' />
                  </Popover>
                </div>
              </div>
            )
          }
        },
      ]
    },
    {
      title: () => {
        return (
          <div>
            Orders
            {
              ordersData !== undefined &&
              <CSVLink className='report-download-btn' filename='AM-orders' headers={ordersHeaders} data={ordersData}>
                <img src={require('../../../assets/images/download.svg')} alt='' />
                Reports</CSVLink>}
          </div>
        )
      },
      children: [
        {
          title: 'Quantity booked',
          dataIndex: 'quantityBooked',
          key: 'quantityBooked',
          align: 'center',
          render: (val) => {
            if (val.length === 0) {
              return '-'
            }
            return (
              val && Array.isArray(val) && val?.map(i => {
                return (
                  <div className='relative quantity-booked'>
                    <p className='quantity-value'>{i.quantity} {i.unit}</p>
                    <span span className='quantity-circled-value'> {i.count} </span>
                  </div>
                )
              })
            )
          }
        },
        {
          title: 'Sales value Excl. GST',
          dataIndex: 'salesValue',
          key: 'salesValue',
          align: 'center',
        },
        {
          title: 'Profit %',
          dataIndex: 'profitPercentage',
          key: 'profitPercentage',
          width: 180,
          align: 'center',
          render: (val) => {
            return (<div className='relative margin-value'>
              {val.avg || 0}
              <p className='avg-quotes-range'>[{val.min || 0}, {val.max || 0}]</p>
            </div>
            )
          }
        },
        {
          title: 'New Customers',
          dataIndex: 'newCustomers',
          key: 'newCustomers',
          align: 'center'
        },
        {
          title: 'Processing',
          dataIndex: 'processingOrdersCount',
          key: 'processingOrdersCount',
          align: 'center'
        },
        {
          title: 'Weaving',
          dataIndex: 'weavingOrdersCount',
          key: 'weavingOrdersCount',
          align: 'center',
        },
      ]
    },
  ]
  return columns
}

const AMTable = props => {
  const { data, currentIndex, paginate, paginationHandler, minDate, maxDate } = props
  const [daysResults, setDaysResults] = useState(QUICK_MENU_OPTIONS[2])

  const getDaysResults = () => {
    let diff = moment(maxDate).diff(moment(minDate), 'days')
    switch (diff) {
      case 7:
        return 'past 7 days'
      case 14:
        return 'past 14 days'
      case 0:
        return 'Yesterday'
      case 30:
      case 31:
        return 'past 30 days'
      default:
        return `${minDate} - ${maxDate}`
    }
  }
  return (
    <>
      <div className="table-container am-table">
        <Table
          columns={getColumns(data)}
          dataSource={data}
          bordered
          size="middle"
          rowClassName='row-table'
          pagination={false}
        />
      </div>
      <div
        className='bottom-bar'
      >
        <div className='content'>
          Showing results from {
            getDaysResults()
          }
          <span>Indian customers only</span>
        </div>
        <div>
          <Pagination
            label="orders"
            onclick={paginationHandler}
            page={
              paginate && {
                ...paginate,
                totalCount: paginate?.count?.accountManagers
              }
            }
            currentIndex={currentIndex}
            showTotalRecords={false}
            className="pagination-wrapper"
          />
        </div>
      </div>
    </>
  );
}

export default AMTable;
