import React from "react";
import DatePicker from "react-datepicker";
import moment from "moment";

import "./styles.scss";

const DatePickerField = ({
  field,
  form: { touched, errors, setFieldValue },
  label,
  required,
  placeholder="",
  className,
  inline,
  minDate = new Date(),
  maxDate,
  children=null,
  defaultValue,
  customInput: CustomInput,
  disabled,
  ...props
}) => {
  const { name } = field;
  if(!field.value && defaultValue){
    setFieldValue(name, defaultValue)
  }

  return (
    <div className={`formik_datepicker ${className}`}>
      <label htmlFor={name} style={{ display: "block" }}>
        {label}
        {required && <sup>*</sup>}
      </label>
      <DatePicker
        name={name}
        selected={(field.value && new Date(field.value))|| defaultValue}
        onChange={date =>
          setFieldValue(name, moment(date).format("YYYY-MM-DD hh:mm:ss"))
        }
        placeholderText={placeholder}
        minDate={minDate}
        maxDate={maxDate}
        dateFormat="MMMM dd, yyyy"
        customInput={CustomInput && <CustomInput />}
        showPopperArrow={false}
        inline={inline}
        disabled={disabled}
        popperPlacement="auto"
        {...props}
      />
      {touched[field.name] && errors[field.name] && (
        <div className="error">{errors[field.name]}</div>
      )}
    </div>
  );
};

export default DatePickerField;
