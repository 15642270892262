import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import CloseImg from "../../../assets/images/navigation/ic-close.svg";

import "./FileDetailsModal.scss";
import { TIMESTAMP_FORMAT } from "./../../../constants/Date";

export default class FileDetailsModal extends Component {
  static propTypes = {
    prop: PropTypes
  };

  render() {
    const { file, goToPrev, goToNext, onClose } = this.props;
    return file !== undefined ? (
      <div className="modal__container">
        <div className="file-details__modal flex">
          <div className="file-details__view flex">
            <img src={file.fileloc} />
          </div>
          <div className="file-details__meta flex-col">
            <div className="file-details__header flex">
              <span className="f-sz-xl f-w-bold">{file.file_title}</span>
              <span onClick={onClose} className="file-details__close-img">
                <img src={CloseImg} />
              </span>
            </div>
            <div className="file-details__content">
              <div className="file-details__label f-sz-sm ">File Name</div>
              <div className="">{file.file_title}</div>
              <div className="file-details__label f-sz-sm ">Category</div>
              <div className="">{file.file_category ? file.file_category : 'N/A'}</div>
              <div className="file-details__label f-sz-sm ">Uploaded By</div>
              <div className="">{file.uploaded_by}</div>
              <div className="file-details__label f-sz-sm ">Date</div>
              <div className="">
                {moment(file.file_added_at).format(TIMESTAMP_FORMAT)}
              </div>
            </div>

            <a href={file.fileloc} className="file-details__footer f-sz-l">
              Download File
            </a>
          </div>
        </div>
      </div>
    ) : null;
  }
}
