import PropTypes from "prop-types";
import React, { Component } from "react";
import { Form, reduxForm } from "redux-form";
import PerfectScrollbar from "react-perfect-scrollbar";
import CloseImg from "../../../assets/images/navigation/ic-close-small.faded.svg";
import icDropdown from "../../../assets/images/navigation/ic-arrow-drop-down.svg";
import icPerson from "../../../assets/images/social/ic-person.faded.svg";
import icPersonAdd from "../../../assets/images/social/user-plus.svg";

import { connect } from "react-redux";
import actions from "../../../store/actions";

import "./CollectionCollaboration.scss";
import { COLLABORATION_ROLES } from "./../../../constants/Collaboration";
import Downshift from "downshift";
const formName = "CollectionCollab";

class CollectionCollaboration extends Component {
  static propTypes = {
    prop: PropTypes
  };
  state = {
    selectedUsers: [],
    isChanged: false
  };

  computeStateFromProps(collectionId, collectionDetails, listOfUsers) {
    let usersList = { ...listOfUsers };

    delete usersList[collectionDetails.userid];
    // Object.keys(usersList)
    //   .map(uKey =>
    //     usersList[uKey].name === "" || usersList[uKey].name === null
    //       ? (usersList[uKey]["name"] = usersList[uKey]["email"]) &&
    //         (usersList[uKey]["email"] = "")
    //       : uKey
    //   )
    //   .forEach(uKey => delete usersList[uKey]);

    if (collectionDetails.collebration) {
      const collaborators = Object.keys(collectionDetails.collebration).map(k => ({
        ...collectionDetails.collebration[k],
        email: usersList[k]["email"]
      }));

      const nonCollabUsers = Object.keys(usersList)
        .map(k => usersList[k])
        .filter(({ id }) => !collaborators.map(c => c.userid).includes(id));

      return {
        collaborators: collaborators,
        nonCollabUsers: nonCollabUsers,
        options: nonCollabUsers.map(user => ({
          label: user.email,
          value: user
        }))
      };
    } else {
      let nonCollabUsers = Object.keys(usersList).map(k => usersList[k]);

      return {
        collaborators: [],
        nonCollabUsers: nonCollabUsers,
        options: nonCollabUsers.map(user => ({
          label: user.email,
          value: user
        }))
      };
    }
  }

  componentDidMount() {
    const nextProps = this.props;

    this.setState(
      this.computeStateFromProps(
        nextProps.collectionDetails.id,
        nextProps.collectionDetails,
        nextProps.usersList
      )
    );
  }
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.collectionDetails !== this.props.collectionDetails ||
      nextProps.usersList !== this.props.usersList
    )
      this.setState(
        this.computeStateFromProps(
          nextProps.collectionDetails.id,
          nextProps.collectionDetails,
          nextProps.usersList
        )
      );
  }

  onChange = option => {
    const newState = {
      collaborators: [
        ...this.state.collaborators,
        ...this.state.nonCollabUsers
          .filter(u => u.email === option.label)
          .map(u => ({
            userid: u.id,
            role: COLLABORATION_ROLES["COLLAB_MANAGER"],
            name: u.name,
            email: u.email
          }))
      ],
      nonCollabUsers: this.state.nonCollabUsers.filter(u => u.email !== option.label),
      options: this.state.nonCollabUsers
        .filter(u => u.email !== option.label)
        .map(user => ({
          label: user.email,
          value: user
        })),
      isChanged: true
    };
    // console.log(newState);
    this.setState(newState);
  };

  deleteCollaboration = collab => {
    const newCollaborators = this.state.collaborators.filter(c => collab.userid !== c.userid);
    const newNonCollabUsers = Object.keys(this.props.usersList)
      .filter(k => k !== String(this.props.collectionDetails.userid))
      .map(k => this.props.usersList[k])
      .filter(({ id }) => !newCollaborators.map(c => c.userid).includes(id));

    const newState = {
      collaborators: newCollaborators,
      nonCollabUsers: newNonCollabUsers,
      options: newNonCollabUsers.map(user => ({
        label: user.email,
        value: user
      })),
      isChanged: true
    };

    this.setState(newState);
  };

  handleUserAuthorityChange = (collab, role) => {
    // console.log("Handle user auth change called");
    const newCollaborators = this.state.collaborators.map(c =>
      c.userid === collab.userid ? { ...c, role: role.value } : c
    );

    // console.log(newCollaborators);
    this.setState({ collaborators: newCollaborators, isChanged: true });
  };

  componentDidUpdate() {
    this.props.change("collaborations", this.state.collaborators);
  }

  render() {
    const {
      handleSubmit,
      // pristine,
      // reset,
      // submitting,
      change,
      hiddenFields
    } = this.props;

    // Add hidden fields
    if (hiddenFields) hiddenFields.forEach(f => change(f.name, f.value));

    return (
      <div
        style={{
          // marginTop: "-20px",
          padding: "1em 2em",
          flexGrow: 1
        }}
        className="col-collab flex-col"
      >
        <br />
        <Form
          name={formName}
          id={formName}
          onSubmit={handleSubmit(values => {
            if (this.state.isChanged) this.props.onSubmit(values);
          })}
        >
          <div className="input-grp">
            <Downshift
              onChange={selection => {
                this.onChange(selection);
                // alert(`You selected ${JSON.stringify(selection)}`);
              }}
              itemToString={item => (item ? item.label : "")}
            >
              {({
                getInputProps,
                getItemProps,
                getLabelProps,
                getMenuProps,
                isOpen,
                inputValue,
                highlightedIndex,
                selectedItem
              }) => {
                return (
                  <div style={{ position: "relative" }} className="flex-col">
                    <label {...getLabelProps()} className="f-sz-sm">
                      Invite with email / name
                    </label>
                    <input {...getInputProps()} type="text" />
                    <div {...getMenuProps()}>
                      {isOpen ? (
                        <div className="select-pane">
                          <PerfectScrollbar style={{ maxHeight: "50vh" }}>
                            {[
                              ...this.state.options
                                .filter(
                                  item =>
                                    !inputValue ||
                                    item.label.toLowerCase().includes(inputValue.toLowerCase()) ||
                                    (item.value.name
                                      ? item.value.name.toLowerCase().includes(inputValue.toLowerCase())
                                      : item.value.email.toLowerCase().includes(inputValue.toLowerCase()))
                                )
                                .map((item, index) => {
                                  const { value } = item;
                                  return (
                                    <div
                                      {...getItemProps({
                                        className: "in-selection collection-collab__label flex",
                                        index,
                                        item,
                                        key: item.label
                                      })}
                                    >
                                      <div className="collection-collab__img">
                                        <img src={icPerson} alt="" />
                                      </div>
                                      <div className="collection-collab__text flex-col">
                                        {value.name ? (
                                          <React.Fragment>
                                            <div>{value.name}</div>
                                            <div
                                              style={{
                                                color: "var(--fill-grey-dark)",
                                                marginTop: "4px"
                                              }}
                                              className="f-sz-sm"
                                            >
                                              {value.email}
                                            </div>
                                          </React.Fragment>
                                        ) : (
                                          <React.Fragment>
                                            <div>{value.email}</div>
                                            <div
                                              style={{
                                                color: "var(--fill-grey-dark)",
                                                marginTop: "4px"
                                              }}
                                              className="f-sz-sm"
                                            >
                                              Invitation Pending
                                            </div>
                                          </React.Fragment>
                                        )}
                                      </div>
                                    </div>
                                  );
                                }),
                              inputValue !== "" &&
                                /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(inputValue) &&
                                // Check if user email not already present
                                !Object.entries(this.props.usersList)
                                  .map(([id, user]) => user.email)
                                  .includes(inputValue) && (
                                  <div
                                    className="in-selection collection-collab__label flex"
                                    key="custom"
                                    onClick={() =>
                                      this.props
                                        .inviteUsers({
                                          emails: [inputValue],
                                          noClose: false
                                        })
                                        .then(() => this.props.getUsers())
                                    }
                                  >
                                    <div className="collection-collab__img">
                                      <img src={icPersonAdd} alt="" />
                                    </div>
                                    <div className="collection-collab__text flex-col">
                                      <div>{inputValue}</div>
                                      <div
                                        className="f-sz-sm"
                                        style={{
                                          lineHeight: "1.33",
                                          marginTop: "4px",
                                          color: "var(--fill-primary-medium)"
                                        }}
                                      >
                                        Invite to join company
                                      </div>
                                    </div>
                                  </div>
                                )
                            ]}
                          </PerfectScrollbar>
                        </div>
                      ) : null}
                    </div>
                  </div>
                );
              }}
            </Downshift>
          </div>
        </Form>
        <PerfectScrollbar style={{ paddingRight: "11px" }} option={{ suppressScrollX: true }}>
          <div className="collection-collab__list">
            {this.state.collaborators &&
              this.state.collaborators
                // .filter(
                //   collab => collab["userid"] !== this.props.currentUser["id"]
                // )
                .map(collab => {
                  return (
                    <div
                      style={{
                        userSelect: "none"
                      }}
                      className="collection-collab__label flex"
                    >
                      <div className="collection-collab__img">
                        <img src={icPerson} alt="" />
                      </div>
                      <div className="collection-collab__text flex-col">
                        <div className="f-sz-l f-w-semi-bold">{collab.name ? collab.name : collab.email}</div>
                        <div className="f-color-faded col-collab__dropdown	">
                          {/*collab.role === 1 ? "Admin" : "Manager"*/}
                          <Downshift
                            initialSelectedItem={{
                              value: collab.role,
                              label: collab.role === 1 ? "Admin" : "Manager"
                            }}
                            itemToString={item => item.label}
                            onChange={selection => {
                              // alert(JSON.stringify(selection))
                              this.handleUserAuthorityChange(collab, selection);
                            }}
                          >
                            {({
                              getInputProps,
                              getToggleButtonProps,
                              getItemProps,
                              getLabelProps,
                              getMenuProps,
                              isOpen,
                              inputValue,
                              highlightedIndex,
                              selectedItem
                            }) => {
                              return (
                                <div
                                  style={{
                                    marginTop: "0.3rem",
                                    position: "relative",
                                    maxWidth: "75px",
                                    cursor: "pointer"
                                  }}
                                >
                                  <span
                                    {...getToggleButtonProps()}
                                    className="flex"
                                    style={{ alignItems: "center" }}
                                    type="none"
                                  >
                                    <input
                                      style={{
                                        border: "none",
                                        backgroundColor: "transparent"
                                      }}
                                      className="select-input-transparent"
                                      readOnly={true}
                                      disabled={true}
                                      {...getInputProps()}
                                    />
                                    <img src={icDropdown} alt="dropdown" />
                                    {collab.name ? null : (
                                      <div
                                        style={{
                                          marginLeft: "15px",
                                          whiteSpace: "nowrap",
                                          color: "var(--fill-grey-dark)",
                                          fontSize: "12px"
                                        }}
                                      >
                                        Invitation Pending
                                      </div>
                                    )}
                                  </span>
                                  {isOpen ? (
                                    <div className="select-pane">
                                      {[{ label: "Admin", value: 1 }, { label: "Manager", value: 2 }]
                                        .filter(item => item.label !== selectedItem.label)
                                        .map((item, index) => {
                                          return (
                                            <div
                                              {...getItemProps({
                                                className: "in-selection collection-collab__label flex",
                                                index,
                                                item,
                                                key: item.label
                                              })}
                                            >
                                              {item.label}
                                            </div>
                                          );
                                        })}
                                    </div>
                                  ) : null}
                                </div>
                              );
                            }}
                          </Downshift>
                          {
                            //   <Select
                            //   options={[
                            //     { value: 1, label: "Admin" },
                            //     { value: 2, label: "Manager" }
                            //   ]}
                            //   isSearchable={false}
                            //   onChange={role =>
                            //     this.handleUserAuthorityChange(collab, role)
                            //   }
                            //   // value={collab.role}
                            //   defaultValue={{
                            //     value: collab.role,
                            //     label: collab.role === 1 ? "Admin" : "Manager"
                            //   }}
                            //   classNamePrefix="col-collab__dropdown"
                            //   placeholder="Select an option"
                            // />
                          }
                        </div>
                      </div>
                      <div
                        onClick={() => this.deleteCollaboration(collab)}
                        style={{ maxWidth: "15px", cursor: "pointer" }}
                        className="f-color-faded"
                      >
                        <img src={CloseImg} alt="" />
                      </div>
                    </div>
                  );
                })}
          </div>
        </PerfectScrollbar>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) =>
  ownProps.initialValues
    ? {
        initialValues: { ...ownProps.initialValues }
      }
    : {};

const mapDispatchToProps = dispatch => ({
  inviteUsers: details => dispatch(actions.inviteUsers(details)),
  getUsers: () => dispatch(actions.getUsers())
});

CollectionCollaboration = connect(
  state => ({
    usersList: state.auth.list,
    currentUser: state.auth.userDetails
  }),
  mapDispatchToProps
)(CollectionCollaboration);

export default reduxForm({ form: formName }, mapStateToProps)(CollectionCollaboration);
