import React from "react";
import { Link, withRouter } from "react-router-dom";
import moment from "moment";

// == local imports ==
import { TIMESTAMP_FORMAT } from "../../../constants/Date";
import {
  getSmallImageUrl,
  getCoverImagePlaceholder
} from "../../../helpers/DesignHelpers";

const Design = ({ match, user, ...restProps }) => {
  const {
    design: { name, coverImageUrl, createdAt, category } = {}
  } = restProps.location.state;

  return (
    <div
      style={{
        height: "132px",
        boxShadow: " inset 0 -1px 0 0 var(--fill-grey-light3)",
        backgroundColor: "var(--fill-white)",

        display: "flex",
        alignItems: "center",
        borderBottom: "1px solid #f5f5f5",

        padding: "16px"
      }}
    >
      <div
        style={{
          width: "100px",
          height: "100px",
          borderRadius: "4px",
          overflow: "hidden",
          marginRight: "18px"
        }}
      >
        <img
          src={
            coverImageUrl
              ? getSmallImageUrl(coverImageUrl)
              : getCoverImagePlaceholder({ category }).src
          }
          alt="Design"
          style={{ height: "100%", width: "100%" }}
        />
      </div>
      <div style={{ flex: 1 }}>
        <div
          style={{
            fontSize: "14px",
            fontWeight: "600",
            color: "var(--fill-locofast-black)",
            lineHeight: "normal"
          }}
        >
          {name}
        </div>
        {user && <div
          style={{
            fontSize: "12px",
            color: "var(--fill-grey-medium)",
            lineHeight: "20px"
          }}
        >
          by {user}
        </div>}
        <div
          style={{
            fontSize: "12px",
            color: "var(--fill-grey-medium)"
          }}
        >
          {moment(createdAt).format(TIMESTAMP_FORMAT)}
        </div>
      </div>
    </div>
  );
};

export default withRouter(Design);
