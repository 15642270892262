import React, { useEffect, useCallback, useState } from "react";
import ReactTooltip from "react-tooltip";

export const TextSpliced = ({ text, className = '', maxWidth = 200 }) => {
  const [withEllipsis, setWithEllipsis] = useState(false);

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const measuredRed = useCallback(node => {
    if (node !== null) {
      setWithEllipsis(node.getBoundingClientRect().width >= maxWidth);
    }
  }, []);
  return (
    <div
      ref={measuredRed}
      className={`${className} overflow-ellipsis`}
      data-tip={withEllipsis ? text : null}
      data-class="dark-tooltip over-modal"
      style={{ maxWidth: `${maxWidth}px` }}
    >
      {text}
    </div>
  );
};
