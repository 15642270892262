import {
  FILTER_FABRIC_IDEAL_FOR,
  FILTER_FABRIC_INSTOCK
} from "./SwatchLibrary";

const MATERIAL_MODAL_VIEWS = {
  DETAILS: 0,
  INVENTORY: 1,
  FACTORY: 2
};

export const MATERIAL_LIBRARY_TABS = {
  fabric: "0",
  trims: "1"
};

export const MATERIAL_UNITS = [
  { title: "Meter", value: "2" },
  { title: "Kilogram", value: "1" },
  { title: "Pieces", value: "6"},
];

export const MATERIAL_UNITS_TRIMS = [
  { title: "Meter", value: "2" },
  { title: "Kilogram", value: "1" },
  { title: "Pieces", value: "6"},
  { title: "Gross", value: "7"}
];

export const MATERIAL_UNITS_HASH = {
  "2": "Meter",
  "1": "Kilogram",
  "6": "Piece",
  "7": "Gross",
};

export const MATERIAL_TYPE_CATEGORY = [
  { title: "Knit", value: "0" },
  { title: "Woven", value: "1" }
];

export const MATERIAL_TYPE_HASH = {
  "0": "Fabric",
  "1": "Trims"
};

export const MATERIAL_CATEGORY_HASHES = {
  1: { label: "Men", value: ["1"] },
  2: { label: "Women", value: ["2"] },
  3: { label: "Kids", value: ["3"] },
  4: { label: "Men, Women", value: ["1", "2"] },
  8: { label: "Men, Kids", value: ["1", "3"] },
  16: { label: "Women, Kids", value: ["2", "3"] },
  32: { label: "Men, Women, Kids", value: ["1", "2", "3"] }
};

export const FILTER_MATERIAL_CATEGORY = [
  { label: "All", value: Object.keys(MATERIAL_CATEGORY_HASHES).join(",") }, // null
  { label: "Men", value: "1,4,8,32" },
  { label: "Women", value: "2,4,16,32" },
  { label: "Kids", value: "3,8,16,32" }
];

export default MATERIAL_MODAL_VIEWS;

export const ADDITIONAL_STATIC_FILTERS = [
  {
    title: "CATEGORY",
    options: FILTER_MATERIAL_CATEGORY,
    type: "single",
    hiddenForTrims: true
  },
  {
    title: "FABRIC TYPE",
    options: [
      { label: "Knit", value: "0" },
      { label: "Woven", value: "1" }
    ],
    type: "single",
    hiddenForTrims: true
  },
  {
    title: "FABRIC WIDTH",
    type: "multiInput",
    notVisibleToBrand: true,
    hiddenForTrims: true
  },
  {
    title: "WEIGHT",
    type: "multiInput",
    notVisibleToBrand: true,
    hiddenForTrims: true
  },
  {
    title: "IDEAL FOR",
    options: FILTER_FABRIC_IDEAL_FOR,
    type: "multi",
    hiddenForTrims: true
  },
  { title: "PRICE", type: "multiInput", notVisibleToBrand: true },
  { title: "QUANTITY", type: "multiInput", notVisibleToBrand: true },
  {
    title: "STOCK AVAILABILITY",
    options: FILTER_FABRIC_INSTOCK,
    type: "single",
    notVisibleToBrand: true
  }
];

export const MATERIAL_IDEAL_FOR_HASHES = {
  0: [0],
  1: [1],
  2: [0, 1]
};
