import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import _ from "lodash";

import { ADDITIONAL_STATIC_FILTERS } from "constants/MaterialLibrary";
import { PAGE_TITLES } from "constants/Titles";
import { MATERIAL_LIBRARY_TABS } from "constants/MaterialLibrary";

import actions from "store/actions";

import Tabs from "components/UI/UrlTabs/index";
import LibraryGrid from "components/MaterialLibrary/LibraryGrid";
import Button from "components/UI/Button/Button";
import DropdownButton from "components/UI/Button/DropdownButton";
import Filters from "../../components/SwatchLibrary/Filters";
import Filter from "components/UI/Filter/Filter";


import { ReactComponent as ToggleIcon } from "assets/images/action/ic_toggle.svg";
import { ReactComponent as ArrowIcon } from "assets/images/navigation/ic-chevron-down-blue.svg";

import "./styles.scss";

const MAP_TABS = {
  fabric: {
    name: "Fabric",
    tabUrl: "fabric",
    paginate: "totalFabricCount"
  },
  trims: {
    name: "Trims",
    tabUrl: "trims",
    paginate: "totalTrimsCount"
  }
};

// Custom usePrevious value hook
const usePrevious = value => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
};

const computeCurrentTab = search => {
  const tab = search.split("=")[1];
  return { tab, value: MATERIAL_LIBRARY_TABS[tab] };
};
const MaterialLibraryContainer = ({
  userDetails,
  usersList,
  match,
  location,
  history,
  filterMetadata,
  paginate,
  userRole,
  libraryBrands,
  ...props
}) => {
  let swatchFilters = [];
  swatchFilters = ADDITIONAL_STATIC_FILTERS.slice();

  const [currentTab, setCurrentTab] = useState(() => {
    const tab = computeCurrentTab(location.search);
    return tab.value;
  });

  const mergedFiltersMetadata = [
    swatchFilters[0],
    ...filterMetadata,
    ...swatchFilters.slice(1)
  ];

  const [filterList, setfilterList] = useState({
    dateFilter: null,
    uploadFilter: null,
    brandId: null,
    search: null,
    materialType: currentTab,
    isSuperAdmin: userDetails.isSuperAdmin,
    ...mergedFiltersMetadata
      .map(f => f.title)
      .reduce((acc, val) => ({ ...acc, [val]: [] }), {})
  });

  const [anyFiltersApplied, setAnyFiltersApplied ] = useState(false);

  const previousFilterList = usePrevious(filterList);

  useEffect(() => {
    if (_.isEqual(previousFilterList, filterList)) return undefined;
    props.filterMaterials(filterList);
  }, [filterList, currentTab]);

  useEffect(() => {
    const { value } = computeCurrentTab(location.search);
    setCurrentTab(value);
    setfilterList({ materialType: value });
    props.getMaterialFilters({ materialType: value, brandId: filterList.brandId });
    setAnyFiltersApplied(false);
  }, [location.search]);

  useEffect(() => {
    // This effect is responsible for scrolling to the top of the page on next button click
    if (containerEle.current) {
      containerEle.current.scrollTop = 0;
    }
    }, [props.materialsList]);

  useEffect(() => {
    props.getMaterialFilters(filterList);
    props.getTrimsCategories();
  }, []);

  useEffect(()=>{
    if(libraryBrands.length > 0 && !filterList.brandId){
      setfilterList({...filterList, brandId: libraryBrands[0].value })
    }
  },[libraryBrands])

  useEffect(()=>{
    if(userDetails.isSuperAdmin){
      props.getMaterialFilters(filterList);
    }
  }, [filterList.brandId])

  const loadNext = () => {
    props.filterMaterials(filterList, paginate.nextPageOffSet);
  };

  const loadPrevious = () => {
    props.filterMaterials(filterList, paginate.prevPageOffSet);
  };

  const containerEle = useRef(null);

  return (
    <div className="flex-col material-library-container">
      <Helmet
        bodyAttributes={{
          class: "body_container"
        }}
        title={PAGE_TITLES.materialLibrary()}
      />
      <div className="flex header ">
        <Tabs
          hasBadges={true}
          initialTabUrl={"fabric"}
          tabs={Object.entries(MAP_TABS).map(([key, tab]) => ({
            ...tab,
            badge: paginate.totalRecords
              ? paginate.totalRecords[tab.paginate]
              : 0
          }))}
        />
        <div className="grid-2">
          {
            userDetails.isSuperAdmin && libraryBrands.length > 0 && <Filter
              width={150}
              title="Brand"
              onChange={({ label, value }) => {
                setfilterList({ ...filterList, brandId: value });
              }}
              filterOptions={libraryBrands}
              initialSelectedItem={filterList.brandId}
            />
          }

          <DropdownButton
            category="blue-bg grid-2"
            className="white-arrow"
            titleContent={
              <>
                Add New <ArrowIcon />
              </>
            }
            list={[
              {
                title: <div>Fabric</div>,
                clickHandler: () =>
                  props.openModal("ADD_MATERIAL", {
                    afterSubmit: () => {
                      props.filterMaterials(filterList);
                      props.getMaterialFilters(filterList);
                    },
                    extraFields: {
                      materialType: "0",
                      brandId: userDetails.brandId,
                      inStock: 1
                    },
                    vendors: props.vendors,
                    title: "Add fabric",
                    footer: "Add fabric"
                  })
              },
              {
                title: <div>Trims</div>,
                clickHandler: () =>
                  props.openModal("ADD_MATERIAL", {
                    afterSubmit: () => {
                      props.filterMaterials(filterList);
                      props.getMaterialFilters(filterList);
                    },
                    extraFields: {
                      materialType: "1",
                      brandId: userDetails.brandId,
                      inStock: 1
                    },
                    vendors: props.vendors,
                    title: "Add trims",
                    footer: "Add trims"
                  })
              }
            ]}
          />
        </div>
      </div>

      <div className="flex content" id="material-library-container">
        {paginate.totalRecords
          ? (anyFiltersApplied || props.materialsList.length > 0) && (
              <div className="left-container">
                <div className="filters" style={{ zIndex: "0" }}>
                  <Filters
                    filterList={filterList}
                    filters={mergedFiltersMetadata}
                    onFiltersChange={newFilters => {
                      setAnyFiltersApplied(true);
                      setfilterList({ ...filterList, ...newFilters });
                    }}
                    materialLibrary
                    trims={currentTab === MATERIAL_LIBRARY_TABS.trims}
                  />
                </div>
              </div>
            )
          : null}

        <div className="main-container">
          <div className="library-grid-container" ref={containerEle}>
            <div style={{ padding: "0px 24px" }}>
              <LibraryGrid
                cardList={props.materialsList}
                openModal={props.openModal}
                paginate={paginate}
                loadNext={loadNext}
                loadPrevious={loadPrevious}
                isTrims={currentTab === MATERIAL_LIBRARY_TABS.trims}
                anyFiltersApplied={anyFiltersApplied}
                filterList={filterList}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ materialLibrary, products, auth }) => ({
  materialsList: materialLibrary.list,
  filterMetadata: materialLibrary.filters,
  libraryBrands: materialLibrary.brands,
  paginate: materialLibrary.paginate
});

const mapDispatchToProps = dispatch => ({
  openModal: (payload, additionalProps) =>
    dispatch(actions.openModal(payload, additionalProps)),
  filterMaterials: (details, pageOffset = null) =>
    dispatch(actions.filterMaterials(details, pageOffset)),
  getMaterialFilters: details => dispatch(actions.getMaterialFilters(details)),
  getTrimsCategories: details => dispatch(actions.getTrimsCategories(details))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MaterialLibraryContainer)
);
