import React from 'react';
import PropTypes from 'prop-types';

export default class BaseCollaborationComponent extends React.Component {
  static propTypes = {
    collection: PropTypes.object.isRequired,
    collaborators: PropTypes.object.isRequired,
    users: PropTypes.object.isRequired,
    loggedInUser: PropTypes.object.isRequired,
  }

  state = {
    loggedInUser: null,
    users: null,
    collaborators: null,
    collection: null,
    collaboratedUsers: [],
    remainingUsers: [],
    collectionOwner: null,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const updatedState = {};
    let hasUpdatedProps;

    if (nextProps.collaborators !== prevState.collaborators) {
      updatedState.collaborators = nextProps.collaborators;
      hasUpdatedProps = true;
    }

    if (nextProps.users !== prevState.users) {
      updatedState.users = nextProps.users;
      hasUpdatedProps = true;
    }

    if (nextProps.collection !== prevState.collection) {
      updatedState.collection = nextProps.collection;
      hasUpdatedProps = true;
    }

    if (nextProps.loggedInUser !== prevState.loggedInUser) {
      updatedState.loggedInUser = nextProps.loggedInUser;
      hasUpdatedProps = true;
    }

    if (updatedState.collaborators || updatedState.users) {
      const updatedCollaborators = updatedState.collaborators || prevState.collaborators;
      const updatedUsers = updatedState.users || prevState.users;
      const loggedInUser = updatedState.loggedInUser || prevState.loggedInUser;

      if (updatedCollaborators && updatedUsers && loggedInUser) {
        const collaboratedUsers = [];
        const mapRemainingUsers = { ...updatedUsers };

        for (var userId in updatedCollaborators) {
          collaboratedUsers.push({
            ...updatedUsers[userId],
            ...updatedCollaborators[userId]
          })

          delete mapRemainingUsers[userId];
        }

        // removing loggedInUser from remaining users list
        delete mapRemainingUsers[loggedInUser.id];

        updatedState.collaboratedUsers = collaboratedUsers;
        updatedState.remainingUsers = Object.values(mapRemainingUsers);
      }
    }

    if ((updatedState.collection && updatedState.collection.userid )|| updatedState.users) {
      const collectionUserId = (updatedState.collection && updatedState.collection.userid) || (prevState.collection && prevState.collection.userid);
      const updatedUsers = updatedState.users || prevState.users;

      updatedState.collectionOwner = {
        ...(updatedUsers[collectionUserId])
      }
    }

    return hasUpdatedProps ? updatedState : null;
  }
}
