import React, { Component } from "react";
import { connect } from "react-redux";
import PerfectScrollBar from "react-perfect-scrollbar";
import moment from "moment";
import _ from "lodash";
import axios from "axios";

// ******* local imports
import styles from "./NewForm.module.scss";
import fileIcon from "../../../assets/images/editor/ic-attach-file.svg";
import cancelIcon from "../../../assets/img/close-red.svg";
import { get_service_endpoint } from "../../../ServiceEndpoints";

import actions from "../../../store/actions";
import { TIMESTAMP_FORMAT } from "../../../constants/Date";
const newEp = get_service_endpoint("notificationView");

const RightMsgItem = ({ data: { comment, createdAt, files, name } }) => {
  return (
    <div style={{ textAlign: "right" }}>
      <div style={{ width: "100%" }}>
        {/* Name */}
        <div
          style={{
            marginLeft: "200px",
            marginBottom: "12px",
            padding: "16px",
            backgroundColor: "#f5f5f5",
            borderRadius: "16px",
            borderBottomRightRadius: "0px",
            lineHeight: "20px"
          }}
        >
          <div
            style={{
              color: "var(--fill-grey-medium)",
              fontSize: "12px",
              fontWeight: "bold",
              textTransform: "capitalize"
            }}
          >
            {name}
          </div>

          {/* message */}
          <div
            style={{
              fontSize: "14px",
              color: "var(--fill-locofast-black)",
              padding: "6px 0px"
            }}
          >
            {comment !== "User has not added any comment" ? comment : null}
          </div>

          {/* Files */}

          {files.map(file => (
            <div>
              <a
                href={file.url}
                style={{
                  color: "var(--fill-grey-medium)",
                  fontSize: "10px",
                  padding: "1px 6px",
                  borderRadius: "50px",
                  backgroundColor: "var(--fill-white)",
                  marginLeft: "6px"
                }}
              >
                {file.name}
              </a>
            </div>
          ))}

          {/* Date */}
          <div style={{ color: "var(--fill-grey-dark)", fontSize: "12px" }}>
            {moment(createdAt).format(TIMESTAMP_FORMAT)}
          </div>
        </div>
      </div>
    </div>
  );
};

const LeftMsgItem = ({ data: { comment, createdAt, files, name } }) => {
  return (
    <div style={{ textAlign: "left" }}>
      <div style={{ width: "100%" }}>
        {/* Name */}
        <div
          style={{
            marginRight: "200px",
            marginBottom: "12px",
            padding: "16px",
            backgroundColor: "var(--fill-primary-light)",
            borderRadius: "16px",
            borderBottomLeftRadius: "0px",
            lineHeight: "20px"
          }}
        >
          <div
            style={{
              color: "var(--fill-grey-medium)",
              fontSize: "12px",
              fontWeight: "bold",
              textTransform: "capitalize"
            }}
          >
            {name}
          </div>

          {/* message */}
          <div
            style={{
              fontSize: "14px",
              color: "var(--fill-locofast-black)",
              padding: "6px 0px"
            }}
          >
            {comment}
          </div>

          {/* Files */}

          {files.map(file => (
            <div>
              <a
                href={file.url}
                style={{
                  color: "var(--fill-grey-medium)",
                  fontSize: "10px",
                  padding: "1px 6px",
                  borderRadius: "50px",
                  backgroundColor: "var(--fill-white)",
                  marginRight: "6px"
                }}
              >
                {file.name}
              </a>
            </div>
          ))}

          {/* Date */}
          <div style={{ color: "var(--fill-grey-dark)", fontSize: "12px" }}>
            {moment(createdAt).format(TIMESTAMP_FORMAT)}
          </div>
        </div>
      </div>
    </div>
  );
};

class CommentsModal extends Component {
  state = {
    commit: "",
    prevComments: [],
    files: [],
    fileNames: [],
    noPrevComments: true
  };

  componentDidMount() {
    console.log("PROPS IN COMMINTS", this.props);
    const {
      designId,
      moduleRef,
      moduleId,
      noPrevComments = false,
      commentDetails = []
    } = this.props.commentDetails;

    // DesinId and ModuleRef
    if (!noPrevComments) {
      // axios
      //   .get(`${get_service_endpoint("notificationView")}/comments`, {
      //     params: {
      //       designid: designId,
      //       moduletype: 1
      //     }
      //   })
      //   .then(res => {
      //     this.setState({ noPrevComments: false, prevComments: res.data });
      //     // console.log("RRTYUIOEERO", res);
      //   });

      this.setState({ prevComments: commentDetails, noPrevComments: false });
    }
    this.scrollToBottom();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  scrollToBottom = () => {
    if (this._scrollRef && this._scrollRef.scrollIntoView) {
      this._scrollRef.scrollIntoView();
    }
  };

  removeFileHandler = index => {
    const { fileNames, files } = this.state;

    fileNames.splice(index, 1);
    files.splice(index, 1);

    this.setState({ fileNames, files });
  };

  updateAndContinueHandler = () => {
    let { commit } = this.state;
    const {
      designId,
      moduleRef,
      request,
      moduleStatus,
      moduleId,
      withComment = false,
      addNewCommint,
      designName,
      request: { factoryId},
    } = this.props.commentDetails;

    //  this is patch here for every update
    // this data is for comment and upload api ..

    //  this was added because der was some issue on uploading file with empty commit ******
    if (this.state.files.length && !commit) {
      commit = "User has not added any comment";
    }

    const data = new FormData();
    data.append("designId", designId);
    data.append("moduleRef", moduleRef);
    data.append("comment", commit);
    data.append("moduleType", 1);
    data.append("moduleStatus", moduleStatus);
    data.append("moduleId", moduleId);

    this.state.files.map(file => {
      data.append("file", file);
    });

    if (withComment) {
      axios.patch(`${newEp}/sample`, request).then(res => {
        if (commit || this.state.files.length) {
          axios
          .post(`${newEp}/comments/designs/${designId}/factories/${factoryId}`, data)
            .then(res => {
              this.props.dispatch(
                actions.flash({
                  message: `Status for ${moduleRef} for ${designName} changed ${moduleStatus ? 'to '+moduleStatus : '' }`,
                  type: "action",
                  actionType: "Update"
                })
              );
              this.props.dispatch(actions.getSamplesByDesignId({ designId }));
              this.props.dispatch(actions.closeModal());
            })
            .catch(err => console.log("ERR IN COMMITS "));
        } else {
          this.props.dispatch(actions.getSamplesByDesignId({ designId }));
          this.props.dispatch(
            actions.flash({
              message: `Status for ${moduleRef} for ${designName} changed ${moduleStatus ? 'to '+moduleStatus : '' }`,
              type: "action",
              actionType: "Update"
            })
          );
          this.props.dispatch(actions.closeModal());
        }
      });
    } else {
      axios
        .post(`${newEp}/comments/designs/${designId}/factories/${factoryId}`, data)
        .then(res => {
          // this.props.dispatch(
          //   actions.flash({ message: "Comment added successfully." })
          // );
          // this.props.dispatch(actions.getSamplesByDesignId({ designId }));
          // this.props.dispatch(actions.closeModal());

          // add response to the commits

          this.props.dispatch(actions.flash({message:`Message sent`, type:"action", actionType:"Update"}))

          const { prevComments } = this.state;

          this.setState({
            prevComments: [...prevComments, { ...res.data }],
            files: [],
            fileNames: [],
            commit: ""
          });

          addNewCommint({ data: res.data });
        })
        .catch(err => console.log("ERR IN COMMITS "));
    }
  };

  render() {
    // console.log("DATA IN COMMENTS MODAL", this.props);
    const {
      designId,
      moduleRef,
      commitMsg = false,
      getCommitDetails,
      commitDate = false,
      request, // this is actuall data for update ..
      update, // this is update function itself,
      isFactory,
      designName,
      submitLabel = "Update and Continue",
      sample = {},
      commentDetails = []
    } = this.props.commentDetails;

    console.log("PROPS IN RENDER", this.state.prevComments);

    return (
      <div
        className={styles.commit}
        style={{
          width: "650px",
          borderTop: "1px solid var(--fill-grey-light3)",

          marginTop: "20px",

          padding: "0px 16px"
        }}
      >
        <div
          style={{
            fontSize: "12px",
            color: "var(--fill-grey-medium)",
            position: "fixed",
            top: "55px",
            left: "25px"
          }}
        >
          {designName}
        </div>
        {!this.state.noPrevComments ? (
          <PerfectScrollBar style={{ maxHeight: "250px" }}>
            <div style={{ marginTop: "12px" }} />
            {/* PREVIOUS COMMENTS  */}
            <div
              style={{
                padding: "0px 8px",
                marginRight: "6px"
              }}
            >
              {this.state.prevComments.map(
                (
                  { comment, createdAt, files, createdBy: { name, role } = {} },
                  index
                ) => {
                  if (isFactory) {
                    if (role === "1") {
                      return (
                        <RightMsgItem
                          data={{ comment, createdAt, files, name }}
                        />
                      );
                    } else {
                      return (
                        <LeftMsgItem
                          data={{ comment, createdAt, files, name }}
                        />
                      );
                    }
                  } else {
                    if (role === "1") {
                      return (
                        <LeftMsgItem
                          data={{ comment, createdAt, files, name }}
                        />
                      );
                    } else {
                      return (
                        <RightMsgItem
                          data={{ comment, createdAt, files, name }}
                        />
                      );
                    }
                  }
                }
              )}
            </div>

            {/* OHh this was truely amazing */}
            <div
              ref={ref => {
                this._scrollRef = ref;
              }}
            />
          </PerfectScrollBar>
        ) : null}

        <div
          className={styles.commit__text_wrapper}
          style={{ marginTop: "16px", position: "relative" }}
        >
          {/* <div className={styles.commit__reason_label}>Reason for resubmission</div> */}
          <textarea
            name="commitMessage"
            id="sampleCommit"
            cols="20"
            rows="10"
            className={styles.commit__textarea}
            onChange={ev => this.setState({ commit: ev.target.value })}
            autoFocus
            value={commitMsg ? commitMsg : this.state.commit}
            disabled={commitMsg}
            style={{
              height: 100 + this.state.fileNames.length * 20,
              borderRadius: "16px",
              outline: "none",
              padding: "16px"
            }}
          />

          {this.state.fileNames.map(({ name }, index) => (
            <div
              style={{
                padding: "2px 6px",
                backgroundColor: "var(--fill-grey-light5)",
                borderRadius: "50px",
                position: "absolute",
                left: "16px",
                bottom: !index ? 2 : index * 24,

                display: "flex",
                alignItems: "center"
              }}
            >
              <span
                style={{ fontSize: "10px", color: "var(--fill-grey-medium)" }}
              >
                {name}
              </span>
              <img
                src={cancelIcon}
                alt="Cancel"
                style={{
                  height: "12px",
                  paddingLeft: "6px",
                  cursor: "pointer"
                }}
                onClick={() => this.removeFileHandler(index)}
              />
            </div>
          ))}
        </div>
        <hr />
        {!commitMsg ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              alignItems: "center",

              paddingBottom: "16px"
            }}
          >
            <button
              className={styles.commit__btn}
              onClick={() => {
                this.updateAndContinueHandler();
              }}
            >
              {submitLabel}
            </button>
            <div style={{ position: "relative", flex: 1, cursor: "pointer" }}>
              <input
                type="file"
                onChange={ev => {
                  if (ev.target.files.length) {
                    this.setState({
                      fileNames: [
                        ...this.state.fileNames,
                        {
                          id: this.state.fileNames.length,
                          name: ev.target.files[0]["name"]
                        }
                      ],
                      files: [...this.state.files, ev.target.files[0]]
                    });
                  }
                }}
                style={{ zIndex: 99999, opacity: 0 }}
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "absolute",
                  top: 0,
                  left: "6px",
                  zIndex: -10
                }}
              >
                <div className={styles.visible__file_icon}>
                  {/* <i className="fa fa-paperclip f-sz-xxl"></i> */}
                  <img
                    src={fileIcon}
                    alt="file"
                    className="upload__ico"
                    style={{ height: "16px" }}
                  />
                </div>
                <div style={{ cursor: "pointer" }}>Attach File</div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="m"
            style={{
              textAlign: "right",
              paddingRight: "10px",
              color: "#333340"
            }}
          >
            <i>{`Updated on: ${commitDate}`}</i>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    data: state.preProductionCommit
  };
};

export default connect(mapStateToProps)(CommentsModal);
