import React, { useState, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Popover from "react-tiny-popover";
import moreVert from "../../../assets/images/navigation/ic-more-vert.svg";
import icDelete from "../../../assets/images/action/ic-delete.svg";
import icRestore from "assets/images/action/ic-restore.svg";
import icMove from "assets/images/action/ic-move.svg";
import icDuplicate from "assets/images/action/ic-duplicate.svg";
import archiveImg from "assets/images/action/archive-24px.svg";
import {
  getCoverImagePlaceholder,
  getSmallImageUrl,
  generateDesignLinkDummy as generateDesignLink
} from "../../../helpers/DesignHelpers";
import { canCreateDesign } from "../../../helpers/AuthHelper";
import { DESIGN_STATUS_KEYS } from "constants/Design";

import { useToggleMenu } from "customHooks/useToggleMenu";
import "./DesignCard.scss";
import { checkCanMoveDesign } from "helpers/permissionHelper";
import history from "utilites/history";

// Archived and cancelled designs will be greyed out.
const computeCardGrey = status =>
  status === 11 || status === 12 ? "grey-card" : "";

const DesignCard = ({ card, currentUser, match, openModal }) => {
  const [isPopOverOpen, setIsPopOverOpen] = useState(false);
  const [state, dispatch, possibleStates] = useToggleMenu();
  const showDesignActions = useMemo(canCreateDesign, []);
  const {
    hovered_false,
    hovered_true,
    clicked_true,
    clicked_false
  } = possibleStates;

  const cover_img =
    card.files[
    Object.keys(card.files).filter(
      fileId => card.files[fileId].is_cover === 1
    )
    ];
  let imgAttributes = {};
  // Create the thumbnail url by adding "_small" before the file extension
  if (cover_img) {
    // let arr = cover_img.fileloc.split(".");
    // arr[arr.length - 2] = arr[arr.length - 2] + "_small";
    // arr = arr.join(".");
    imgAttributes = { src: getSmallImageUrl(cover_img.fileloc) };
  } else {
    imgAttributes = {
      ...getCoverImagePlaceholder(card),
      className: "design-card__img design-card__img--placeholder"
    };
  }

  const moveDesignHandler = useCallback(() => {
    openModal("MOVE_DESIGN", {
      design: card,
    });
    dispatch({ type: clicked_false });
    dispatch({ type: hovered_false });
  }, []);

  const duplicateDesignHandler = useCallback(() => {
    openModal("DUPLICATE_DESIGN", {
      design: card,
      onSuccess: ({ designId, collectionId }) => {
        history.push(`/brand/collections/${collectionId}`, {
          highlightDesignId: designId
        });
      },
    });
    dispatch({ type: clicked_false });
    dispatch({ type: hovered_false });
  }, []);

  const archiveDesignHandler = useCallback(() => {
    openModal("ARCHIVE_DESIGN", {
      hiddenFields: [
        { name: "id", value: card.id },
        { name: "collection_id", value: card.collection_id },
        { name: "status", value: DESIGN_STATUS_KEYS.ARCHIVED },
      ]
    });
    dispatch({ type: clicked_false });
    dispatch({ type: hovered_false });
  }, []);

  const restoreDesignHandler = useCallback(() => {
    openModal("RESTORE_DESIGN", {
      hiddenFields: [
        { name: "id", value: card.id },
        { name: "collection_id", value: card.collection_id },
        { name: "status", value: DESIGN_STATUS_KEYS.DRAFT }
      ]
    });
    dispatch({ type: clicked_false });
    dispatch({ type: hovered_false });
  }, []);

  const deleteDesignHandler = useCallback(() => {
    openModal("DELETE_DESIGN", {
      hiddenFields: [
        { name: "id", value: card.id },
        { name: "collection_id", value: card.collection_id },
      ]
    })
    dispatch({ type: clicked_false });
    dispatch({ type: hovered_false });
  }, []);

  const menuItems = useMemo(() => [
    ...((checkCanMoveDesign({ user: currentUser, design: card }))
      ? [
        {
          key: 'move',
          img: icMove,
          label: 'Move',
          onClick: moveDesignHandler,
        },
        {
          key: 'duplicate',
          img: icDuplicate,
          label: 'Duplicate',
          onClick: duplicateDesignHandler,
        },
      ]
      : []),
    ((card.status === DESIGN_STATUS_KEYS.DRAFT
      || card.status === DESIGN_STATUS_KEYS.MEASUREMENT_SHEET_UPLOADED) && {
      key: 'archive',
      img: archiveImg,
      label: 'Archive',
      onClick: archiveDesignHandler,
    }),
    (card.status === DESIGN_STATUS_KEYS.ARCHIVED && {
      key: 'restore',
      img: icRestore,
      label: 'Restore',
      onClick: restoreDesignHandler,
    }),
    ((card.status === DESIGN_STATUS_KEYS.DRAFT
      || card.status === DESIGN_STATUS_KEYS.MEASUREMENT_SHEET_UPLOADED
      || card.status === DESIGN_STATUS_KEYS.ARCHIVED) && {
      key: 'delete',
      img: icDelete,
      label: 'Delete',
      onClick: deleteDesignHandler,
    }),
  ].filter(item => item), [card]);

  const Menu = (
    <div className="pop-over__item-list">
      {
        menuItems.map(item => <div
          onClick={item.onClick}
          className={`pop-over__item item-${item.key} flex f-sz-l`}
        >
          <img src={item.img} className={`img-${item.key}`} alt={`${item.key} design`} />
          <span>{item.label}</span>
        </div>)
      }
    </div>
  );

  const designLink = generateDesignLink({
    pathname: "designs/:design_id/",
    design: card
  });

  return (
    <div className={`design-card ${computeCardGrey(card.status)}`}
      onMouseEnter={() => dispatch({ type: hovered_true })}
      onMouseLeave={() => dispatch({ type: hovered_false })}
    >
      <Link to={designLink}>
        <div className="design-card__cover">
          <img
            className="design-card__img"
            {...imgAttributes}
            alt="Design card"
          />
        </div>

        <div
          className="design-card__caption hover-dots"
        >
          <div>
            <div
              className={`design-card__status ${card.statusString.toLowerCase()}`}
            >
              {card.statusString.toLowerCase()}
            </div>
            <div className="design-card__name">{card.name}</div>
          </div>
          <div>
            <Popover
              isOpen={isPopOverOpen}
              position={"bottom"}
              content={<div>{Menu}</div>}
              onClickOutside={() => setIsPopOverOpen(false)}
            >
              {(showDesignActions && menuItems.length > 0) ? (
                state.hovered && (
                  <div>
                    <Popover
                      isOpen={state.clicked}
                      position={"bottom"}
                      content={Menu}
                      onClickOutside={() => dispatch({ type: clicked_false })}
                    >
                      <img
                        onClick={(e) => {
                          state.clicked
                            ? dispatch({ type: clicked_false })
                            : dispatch({ type: clicked_true })

                          e.preventDefault();
                        }}
                        src={moreVert}
                        alt="Menu"
                      />
                    </Popover>
                  </div>
                )
              ) : (
                  <div />
                )}
            </Popover>
          </div>
        </div>
      </Link>
    </div>
  );
};

DesignCard.propTypes = { card: PropTypes.object };

export default DesignCard;
