import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import CustomInput from '../../../UI/Form/Input/CustomInput';
import { IconMagnifier } from '../../../UI/Icons';
import EmptyScreen from '../../../UI/EmptyScreen';
import BrandSummary from './BrandSummary';
import OrgRow from '../OrgRow';
import './style.scss';

export default class SelectBrand extends React.Component {
  static propTypes = {
    brands: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      factoriesCount: PropTypes.number.isRequired,
    })),
    currency: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    value: PropTypes.object,
  };

  static defaultProps = {
    brands: [],
  }

  state = {
    filteredBrands: [],
    searchText: '',
    selectedBrand: null,

    propsBrands: null,
    propsValue: null,
  };

  static getDerivedStateFromProps(nextProps, oldState) {
    const nextState = {};

    if (nextProps.brands !== oldState.propsBrands) {
      nextState.propsBrands = nextProps.brands;
      nextState.filteredBrands = nextProps.brands || [];
    }

    if (nextProps.value !== oldState.propsValue) {
      nextState.propsValue = nextProps.value;
      nextState.selectedBrand = nextProps.value;
    }

    return Object.keys(nextState).length > 0 ? nextState : null;
  }

  _handleSearchTextChange = (e) => {
    const { brands } = this.props;
    const nextState = {
      searchText: e.target.value,
      validSearchText: e.target.value.trim().toLowerCase(),
    };
    const validSearchText = nextState.searchText.trim().toLowerCase();

    if (nextState.validSearchText !== this.state.validSearchText) {
      if (validSearchText.length > 0) {
        nextState.filteredBrands = brands.filter(brand =>
          brand.name.toLowerCase().indexOf(validSearchText) >= 0
        );
      } else {
        nextState.filteredBrands = brands;
      }
    }

    this.setState(nextState);
  }

  _handleBrandSelection = (selectedBrand, isSelected) => {
    this.setState({
      selectedBrand: isSelected ? selectedBrand : null,
    });
  }

  render() {
    const { onSubmit, brands } = this.props;
    const { filteredBrands, selectedBrand, searchText } = this.state;

    return <div className="order-select-brand">
      <div className="order-select-brand__search">
        <div className="heading">Select brand to order</div>
        <div className="order-select-brand__search__field">
          <CustomInput
            placeholder="Search brand"
            prefixText={<IconMagnifier color="grey-medium" />}
            autoFocus={true}
            value={searchText}
            onChange={this._handleSearchTextChange}
          />
        </div>
        <PerfectScrollbar className="order-select-brand__search__results">
          {
            filteredBrands.length === 0
              ?
              brands.length === 0
                ? <EmptyScreen heading="No brands available" />
                : <EmptyScreen heading="No results found" />
              : <div>
                {filteredBrands.map(brand => (<OrgRow
                  key={brand.id}
                  org={brand}
                  onChange={(e, isSelected) => this._handleBrandSelection(brand, isSelected)}
                  isSelected={selectedBrand && selectedBrand.id === brand.id}
                />))}
              </div>
          }
        </PerfectScrollbar>
      </div>
      <div className="order-select-brand__summary">
        <BrandSummary
          brand={selectedBrand}
          onSubmit={onSubmit}
        />
      </div>
    </div>;
  }
}
