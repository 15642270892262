import React from "react";
import moment from "moment";
import "./SampleDispatchDetails.scss";
import { TIMESTAMP_FORMAT } from "./../../../constants/Date";

export default function SampleDispatchDetails({
  dispatchDetails: {
    sentOn,
    courierPartner,
    trackingNumber
  }
})
{
    return (
      <div className="sample-dispatch-details">
        <p>Tracking Number : {trackingNumber}</p>
        <p>Courier Partner: {courierPartner}</p>
        <p>Sample Dispatched on : { moment(sentOn).format(TIMESTAMP_FORMAT)}</p>
      </div>
    )
}
