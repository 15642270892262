import React, { Component } from "react";

const BootStrapError = ({ message, show }) => {
  return (
    <div
      style={{
        fontSize: "10px",
        padding: "8px",
        backgroundColor: "#f8d7da",
        color: "#721c24",
        textAlign: "center",
        marginBottom: "5px",
        // // position: "absolute",
        // // right: 0,
        // bottom: "12%",
        width: "100%",
        opacity: show ? 1 : 0,
        transition: "0.2s all",
      }}
    >
      {message}
    </div>
  );
};
export default BootStrapError;
