import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import orderBy from 'lodash/orderBy';
import ReactTooltip from 'react-tooltip';
import { IconMagnifier } from '../../../UI/Icons';
import CustomInput from '../../../UI/Form/Input/CustomInput';
import Button from '../../../UI/Button/Button';
import DesignAvatarList from '../../../UI/Avatar/DesignAvatarList';
import ModalFooter from '../../../UI/Modal/ModalFooter';
import EmptyScreen from '../../../UI/EmptyScreen';
import { DATESTAMP_FORMAT } from '../../../../constants/Date';
import actions from '../../../../store/actions/index';
import thumbsDownImg from "assets/images/action/ic-thumbsup-grey.svg";
import './style.scss';

const CollectionCard = ({ collection, isSelected, onClick, onKeyPress }) => {

  return <Button
    className={classnames("card collection-card", {
      'card--selected': isSelected
    })}
    category=""
    type="Button"
    onClick={onClick}
    onKeyPress={onKeyPress}
  >
    <div className="card--info">
      <div className="card--title">
        <span className="overflow-ellipsis" data-tip={collection.name} data-class="dark-tooltip over-modal">
          {collection.name}
        </span>
        <span className="f-color-faded-2 col-username" data-tip={collection.username} data-class="dark-tooltip over-modal">
          by {collection.username}
        </span>
      </div>
      <div className="card--sub-title f-color-faded-2">
        <div className="line-part">
          {collection.designs.length} design{collection.designs.length > 1 ? 's' : ''}
        </div>
        <div className="line-part">
          {moment(collection.created_on).format(DATESTAMP_FORMAT)}
        </div>
      </div>
    </div>
    <div className="card--sub-info">
      <DesignAvatarList designs={collection.designs} count={3} size="48px" />
    </div>
  </Button>
};


const MoveDesignModal = ({
  collectionId,
  brandId,
  design,
  getAllCollections,
  getDesigns,
  designs,
  collections,
  closeModal,
  openNextModal,
  moveDesign,
  onSuccess = () => { },
}) => {
  const [transformedCollections, setTransformedCollections] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [filteredCollections, setFilteredCollections] = useState([]);
  const [canMove, setCanMove] = useState(false);
  const designId = design.id;

  useEffect(() => {
    getAllCollections();
  }, []);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [filteredCollections]);

  useEffect(() => {
    if (!collections) {
      return;
    }

    const collectionIds = Object.keys(collections);

    if (collectionIds.length === 0) {
      return;
    }

    getDesigns({ collectionIds });

  }, [collections]);

  useEffect(() => {
    if (!collections || !designs || !Array.isArray(designs) || designs.length === 0) {
      return;
    }

    const mapCollections = { ...collections };

    // remove the collection of source design
    delete mapCollections[design.collection_id];

    const collectionsList = orderBy(Object.values(mapCollections), 'created_on', 'desc');

    collectionsList.forEach(collection => {
      collection.designs = [];
    });

    designs.forEach(d => {
      const collection = mapCollections[d.collectionId];

      if (!collection) {
        return;
      }

      collection.designs.push(d);
    });

    setTransformedCollections(collectionsList);

    // if (collectionsList.length > 0) {
    //   // debugger;
    //   setSelectedCollection(collectionsList.find(c => c.designs.length > 0));
    //   setCanMove(true);
    // }

  }, [collections, designs]);

  // apply filter when searchText of collections change
  useEffect(() => {
    filterCollections(searchText, transformedCollections);
  }, [searchText, transformedCollections]);

  useEffect(() => {
    if (canMove && selectedCollection) {
      openNextModal('MOVE_DESIGN_CONFIRM', {
        hiddenFields: {
          designId,
          collectionId: selectedCollection.id,
        },
        design,
        collection: selectedCollection,
        onConfirm: () => {
          moveDesign({ design, designId, collectionId: selectedCollection.id, collection: selectedCollection, })
            .then(closeModal)
            .then(() => onSuccess({ designId, collectionId: selectedCollection.id }));
        },
      });
    }
  }, [canMove, selectedCollection]);

  const filterCollections = useCallback((text, collectionsList) => {
    collectionsList = (collectionsList && [...collectionsList]) || transformedCollections;

    if (text && text.trim()) {
      const trimmedSearchText = text.trim();
      const _filteredCollections = collectionsList.filter(item => item.name.toLowerCase().includes(trimmedSearchText.toLowerCase()));

      setFilteredCollections(_filteredCollections);
    } else {
      setFilteredCollections(collectionsList);
    }
  }, []);

  const searchTextChangeHandler = useCallback((e) => {
    setSearchText(e.target.value);
    // filterCollections(e.target.value);
  }, []);


  const modalCancelHandler = useCallback(() => {
    closeModal();
  }, []);

  const modalSubmitHandler = useCallback(() => {
    if (!selectedCollection) {
      return;
    }

    setCanMove(true);
  }, [selectedCollection]);

  const onCollectionClickHandler = useCallback((e, collection) => {
    setSelectedCollection(collection);
  }, []);

  const onCollectionKeyPressHandler = useCallback((e, collection) => {
    if (e.which === 32) {
      e.preventDefault();
      e.stopPropagation();

      setSelectedCollection(collection);
    } else if (e.which === 13) {
      e.preventDefault();
      e.stopPropagation();

      setSelectedCollection(collection);
      setCanMove(true);
    }
  }, []);

  return <div className="move-design-modal">
    <div className="move-design-modal__controls">
      <CustomInput
        onChange={searchTextChangeHandler}
        value={searchText}
        prefixText={<IconMagnifier color="grey-medium" />}
        autoFocus
      />
      <span className="move-design-modal__count f-color-faded">
        Showing {filteredCollections.length} collection{filteredCollections.length > 1 ? 's' : ''}
      </span>
    </div>
    <PerfectScrollbar className="collection-card-container">
      {
        filteredCollections.length > 0
          ? <div className="collection-card-list"> {filteredCollections.map(collection => <CollectionCard
            key={collection.id}
            collection={collection}
            isSelected={selectedCollection && (collection.id === selectedCollection.id)}
            onClick={(e) => onCollectionClickHandler(e, collection)}
            onKeyPress={(e) => onCollectionKeyPressHandler(e, collection)}
          />)}</div>
          : <EmptyScreen
            icon={thumbsDownImg}
            iconSize="72px"
            heading={
              transformedCollections.length === 0
                ? "No collections found"
                : "No matching results"
            }
            description={
              transformedCollections.length !== 0
                ? <>
                  No results found for <b>"{searchText}"</b>
                </>
                : null
            }
          />
      }
    </PerfectScrollbar>
    <ModalFooter
      cancel="Close"
      submit="Move"
      canSubmit={selectedCollection}
      canCancel={true}
      onCancel={modalCancelHandler}
      onSubmit={modalSubmitHandler}
    />
  </div>;
};


const mapStateToProps = (state, props) => ({
  designs: props.designs || state.designs.summaries,
  collections: props.collections || state.collections.list,
});

const mapDispatchToProps = (dispatch) => ({
  getAllCollections: () => dispatch(actions.getAllCollections()),
  getDesigns: ({ collectionIds }) => dispatch(actions.getDesignSummariesByCollection({ collectionIds })),
  moveDesign: (args) => dispatch(actions.moveDesign(args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MoveDesignModal);
