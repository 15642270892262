import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Switch } from "react-router-dom";
import "../DesignDetailsContainer/DesignDetailsContainer.scss";
import InternalSidebar from "../../components/UI/InternalSidebar/InternalSidebar";
import { Link } from "react-router-dom";
import { submit } from "redux-form";
import DesignDetails from "../../components/Designs/DesignDetails/DesignDetails";
import actions from "../../store/actions";
import Header from "../../components/UI/Header/Header";
import _ from "lodash";
import Tabs from "../../components/UI/UrlTabs/index";
import qs from "query-string";
import Sidebar from "../../components/UI/Sidebar/Sidebar";
import "./NotificationContainer.scss";
import {
  generateDesignLink,
  getSmallImageUrl,
  getCoverImagePlaceholder
} from "../../helpers/DesignHelpers";

// ********* import for tabs *** Dispatch Desktop notification ***********
import DispatchQuantity from "./DesktopComponents/DispatchQuantity";
import DispatchDetails from "./DesktopComponents/DispatchDetails";
import Notify from "./MobileComponents/Notify";

class NotificationContainer extends Component {
  state = { selectedDesignId: "" };
  componentWillMount() {
    // window.localStorage.setItem("fileReload", false);
  }
  componentDidMount() {
    this.props.actions.common.setNotificationMode(false);

    const authToken = qs.parse(this.props.location.search).a;
    if (authToken && authToken !='undefined'){
      this.props.actions.common.authTokenLogin({ authToken }).then(() =>
      this.props.actions.common
        .getNotificationDetails({
          notificationId: this.props.match.params.notification_id
        })
        .then(notificationDesigns => {
          this.props.actions.designDetails.getMultipleDesigns({
            idList: notificationDesigns
          });
          notificationDesigns
            .map(v => v.collection)
            .forEach(cId =>
              this.props.actions.collectionDetails.getCollectionById(cId)
            );
        })
    );
    }

    else
      this.props.actions.common
        .getNotificationDetails({
          notificationId: this.props.match.params.notification_id
        })
        .then(notificationDesigns => {
          this.props.actions.designDetails.getMultipleDesigns({
            idList: notificationDesigns
          });
          notificationDesigns
            .map(v => v.collection)
            .forEach(cId =>
              this.props.actions.collectionDetails.getCollectionById(cId)
            );
        });
  }

  // componentDidMount() {
  // this.props.actions.designDetails
  //   .getMultipleDesigns({
  //     idList: [
  //       { design: 133, collection: 83 },
  //       { design: 129, collection: 81 },
  //       { design: 136, collection: 83 }
  //     ]
  //   })
  // }

  reloadDesigns = () => {
    this.props.actions["designDetails"].getMultipleDesigns({
      idList: Object.values(this.props.selectedDesigns).map(des => ({
        design: des.id,
        collection: des.collection_id
      }))
    });
  };

  reloadFiles = () => {
    this.props.actions.designFiles.getSwatchDesignFiles({
      designId: this.props.match.params.design_id
    });
  };

  componentWillReceiveProps(nextProps) {
    const { selectedCategory } = nextProps;
    if (
      !_.isEqual(nextProps["selectedDesigns"], this.props["selectedDesigns"])
    ) {
      // window.appHistory.push("/notification/" + Object.values(nextProps["selectedDesigns"])[0]["id"]);
      if (nextProps.match.params.design_id)
        window.appHistory.push({
          pathname:
            "../" + Object.values(nextProps["selectedDesigns"])[0]["id"] + "/",
          search:
            selectedCategory === "tna"
              ? "tab=designtna"
              : nextProps.location.search
        });
      else
        window.appHistory.push({
          pathname: Object.values(nextProps["selectedDesigns"])[0]["id"],
          search:
            selectedCategory === "tna"
              ? "tab=designtna"
              : nextProps.location.search
        });
      // console.log("CHANGED", nextProps["selectedDesigns"]);
    }

    if (
      this.props.match.params.design_id !== nextProps.match.params.design_id
    ) {
      let queryStr = qs.parse(nextProps.location.search);
      if (!queryStr.tab) {
        queryStr.tab = "summary";
      }
      window.appHistory.push({
        search: qs.stringify(queryStr)
      });
    }
    const newQueryObj = qs.parse(nextProps.location.search);
    // console.log("PARSE", !JSON.parse(window.localStorage.getItem("fileReload")));
    // Check if it's the first time when all the designs were fetched
    // If _true_ then redirect the tab to selected Category
    if (
      Object.keys(nextProps.selectedDesigns).length > 0 &&
      Object.keys(this.props.selectedDesigns).length === 0 &&
      // Only redirect if no tab specified
      // !qs.parse(nextProps.location.search).tab
      // Doesn't work: END
      // HACK
      newQueryObj.initialTab
    ) {
      setTimeout(() => {
        // window.localStorage.setItem("fileReload", false);
        delete newQueryObj.initialTab;
        window.appHistory.push({
          search: qs.stringify({
            ...newQueryObj,
            tab: nextProps.selectedCategory
          })
        });
      }, 100);
    }
  }

  getCoverImageFromFiles = files => {
    let coverImage;
    Object.keys(files).forEach(key => {
      if (files[key].is_cover) {
        coverImage = files[key].fileloc;
      }
    });

    if (coverImage) {
      return getSmallImageUrl(coverImage);
    }

    return null;
  };

  scrollRefHandler = ref => {
    this._scrollRef = ref;
  };

  render() {
    const selectedDesignId = Number(this.props.match.params.design_id);
    const queryStrObj = qs.parse(this.props.location.search);
    // if (!selectedDesignId && !this.props.selectedDesigns[selectedDesignId]) {
    //   return null;
    // }

    let tabSpecificOptions = {};
    switch (this.props.selectedCategory) {
      case "sampling":
        tabSpecificOptions = {
          viewParticularSamples: _.get(
            this.props.notificationDesigns.filter(
              ({ id }) => id === selectedDesignId
            ),
            "0",
            { samples: [] }
          ).samples.map(({ sampleType }) => sampleType)
        };
        break;
      default:
        break;
    }

    if (this._scrollRef && selectedDesignId) {
      const findIndex = Object.keys(this.props["selectedDesigns"]).findIndex(
        val => Number(selectedDesignId) === Number(val)
      );
      if (findIndex !== -1) this._scrollRef.scrollTop = findIndex * 70;
    }

    // if (selectedDesignId) {
    const DispatchDetailsTab = {
      name: "Dispatch Details",
      tabUrl: "dispatchDetails",
      panel: (
        <DispatchDetails
          key={this.props.designId}
          openModal={this.props.openModal}
          submitEditDesignForm={this.props.submitEditDesignForm}
          editDesign={this.props.editDesign}
          design={this.props.design}
          patchDesignFile={this.props.patchDesignFile}
          uploadNewDesignFile={this.props.uploadNewDesignFile}
          deleteDesignFile={this.props.deleteDesignFile}
          data={this.props.location.poItemDetails}
        />
      )
    };

    const DispatchQuantityTab = {
      name: "Dispatch Quantity",
      tabUrl: "dispatchQuantity",
      panel: (
        <DispatchQuantity
          key={this.props.designId}
          openModal={this.props.openModal}
          submitEditDesignForm={this.props.submitEditDesignForm}
          editDesign={this.props.editDesign}
          design={this.props.design}
          patchDesignFile={this.props.patchDesignFile}
          uploadNewDesignFile={this.props.uploadNewDesignFile}
          deleteDesignFile={this.props.deleteDesignFile}
          data={this.props.location.poItemDetails}
        />
      )
    };

    //  */******this handles the dummy initial load  */
    if (!this.props.notificationDesigns.length) {
      return null;
    }

    return (
      <div className="flex-col" style={{ height: "100%" }}>
        <Header theme="notification" noBellIcon />
        <div
          style={{ height: "calc(100% - 56px)" }}
          className="design-container-details flex"
        >
          <InternalSidebar
            scrollRefHandler={this.scrollRefHandler}
            theme="notification"
            header={
              <>
                <Notify
                  title={
                    (this.props.selectedCategory === "sampling" &&
                      "Sampling  approval required") ||
                    (this.props.selectedCategory === "quote" &&
                      "Quote  approval required") ||
                    (this.props.selectedCategory === "tna" &&
                      "Time and Action Plan (TNA)") ||
                    (true && //this.props.selectedCategory === "dispatch"
                      "View Dispatch Details")
                  }
                  message={
                    (this.props.selectedCategory === "sampling" &&
                      "Tap on the below designs to view & update the sample status.") ||
                    (this.props.selectedCategory === "quote" &&
                      "Tap on the below designs to view & update the quote status.") ||
                    (this.props.selectedCategory === "tna" &&
                      "Click on below designs to view the status/progress.") ||
                    (true && //this.props.selectedCategory === "dispatch"
                      "Tap on the below purchase orders to view the details.")
                  }
                  style={{ width: "70px" }}
                  containerStyles={{
                    backgroundColor: "var(--fill-primary-medium)"
                  }}
                  labelStyles={{ color: "#fff" }}
                />
                <div
                  style={{
                    height: "56px",
                    padding: "16px 20px",
                    alignItems: "center",
                    color: "var(--fill-white)"
                  }}
                  className="f-sz-l f-w-bold flex"
                >
                  DESIGNS
                </div>
              </>
            }
            footer={null}
          >
            {this.props.notificationDesigns.length
              ? selectedDesignId &&
                Object.values(this.props["selectedDesigns"]).map(design => {

                  let coverImageFromFiles = this.getCoverImageFromFiles(
                    design["files"]
                  );

                  return (
                    <Link
                      key={design["id"]}
                      // to={{ pathname: "../" + design.id, search: this.props.location.search }}
                      to={generateDesignLink({
                        design: { ...design, statusString: "" },
                        location: this.props.location,
                        pathname: "../:design_id/",
                        overrideTab: this.props.selectedCategory.toLowerCase()
                      })}
                    >
                      <div
                        className={`notification-list__item${
                          selectedDesignId === design["id"]
                            ? " notification-list__item--active"
                            : ""
                        }`}
                      >
                        {/* <div
                          className="notification-list__title f-w-semi-bold"
                          // data-tip={this.props.selectedDesigns[dId].name}
                          data-place="right"
                          data-delay-show="500"
                        > */}
                        {/* {this.props.selectedDesigns[dId].name} */}
                        {/* {design["name"]}
                        </div>
                        <div
                          style={{
                            textTransform: "capitalize",
                            color: "var(--fill-primary-dark)"
                          }}
                          className="notification-list__subtitle f-sz-sm"
                        > */}
                        {/* {this.props.selectedDesigns[dId].statusString.toLowerCase()} */}
                        {/* {this.props.selectedCategory.toLowerCase()}
                        </div> */}

                        {/* ITS FROM HERE WITH THUMNAIL IMAGE */}

                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              width: "48px",
                              height: "48px",
                              borderRadius: "100px",
                              overflow: "hidden"
                            }}
                          >
                            <img
                              src={
                                coverImageFromFiles
                                  ? coverImageFromFiles
                                  : getCoverImagePlaceholder(design).src
                              }
                              alt="Design"
                              style={{ width: "100%", height: "100%" }}
                            />
                          </div>
                          <div style={{ paddingLeft: "12px", color: "#fff" }}>
                            <div
                              className="design-list__title f-w-semi-bold"
                              data-tip={design.name}
                              data-place="right"
                              data-delay-show="500"
                            >
                              {design.name}
                            </div>
                            <div
                              style={{
                                textTransform: "capitalize",
                                color: "var(--fill-white)"
                              }}
                              className="design-list__subtitle f-sz-sm"
                            >
                              {`${design.category.toLowerCase()} - ${design.sub_category.toLowerCase()}`}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  );
                })
              : this.props.notificationPoOrders.map(po => {
                  return (
                    <Link
                      to={{
                        pathname: `${this.props.match.url}/podetails/`,
                        // search: "?sort=name",
                        // hash: "#the-hash",
                        state: { data: po }
                      }}
                    >
                      <div
                        className={`notification-list__item${
                          this.props.location.poItemDetails
                            ? this.props.location.poItemDetails.id === po["id"]
                              ? " notification-list__item--active"
                              : ""
                            : ""
                        }`}
                      >
                        <div
                          className="notification-list__title f-w-semi-bold"
                          // data-tip={this.props.selectedDesigns[dId].name}
                          data-place="right"
                          data-delay-show="500"
                        >
                          {/* {this.props.selectedDesigns[dId].name} */}
                          {po["poNumber"]}
                        </div>
                        <div
                          style={{
                            textTransform: "capitalize",
                            color: "var(--fill-primary-dark)"
                          }}
                          className="notification-list__subtitle f-sz-sm"
                        >
                          {`Qty: ${po.totalQuantity}, ${po.city}`}
                        </div>
                      </div>
                    </Link>
                  );
                })}
          </InternalSidebar>
          <div className="design-container-details__main">
            {this.props.notificationDesigns.length ? (
              <DesignDetails
                hideAddSampleBtnInNOtification
                reloadDesigns={this.reloadDesigns}
                viewParticularTab={this.props.selectedCategory}
                // viewParticularSamples={this.}
                {...tabSpecificOptions}
                isFactory={this.props.isFactory}
                // Send the current selected tab name from url
                // currentTab={(() => {
                //   const queryParams = qs.parse(this.props.location.search);
                //   // console.log("CURRENT:", queryParams);
                //   const currentTabUrl = queryParams["tab"] ? queryParams["tab"].toLowerCase() : "summary";
                //   // console.log("CURRENT:", currentTabUrl);

                //   // if (!this.props.location.search)
                //   //   window.appHistory.push({
                //   //     search: qs.stringify({ ...queryStrObj, tab: currentTabUrl })
                //   //   });
                //   return currentTabUrl;
                // })()}
                newSampleDetails={this.props.newSampleDetails}
                getSamplesByDesignId={() => {
                  this.props.getSamplesByDesignId({
                    designId: this.props.designId
                  });
                  console.log("hello");
                }}
                sampleDetails={this.props.sampleDetails}
                design={this.props.selectedDesigns[selectedDesignId]}
                // TODO: Delete once Factory module works
                designId={selectedDesignId}
                brandId={
                  this.props.notificationDesigns[0].brandId
                }
                collection={
                  this.props.selectedDesigns[selectedDesignId]
                    ? this.props.collections[
                        this.props.selectedDesigns[selectedDesignId]
                          .collection_id
                      ]
                    : {}
                }
                quoteSummary={this.props.quoteSummary}
                quoteDetails={this.props.quoteDetails}
                usersList={this.props.usersList}
                designFiles={this.props.designFiles}
                orderDetails={this.props.orderDetails}
                dispatchDetails={this.props.dispatchDetails}
                getDesignJourney={this.props.getDesignJourney}
                designJourney={this.props.designJourney}
                currentUser={this.props.currentUser}
                getAllCommints={this.props.actions.designDetails.getAllCommints}
                orderFiles={this.props.orderFiles}
                dispatchFiles={this.props.dispatchFiles}
                {...this.props.actions["designSummary"]}
                {...this.props.actions["designFiles"]}
                {...this.props.actions["sampling"]}
                {...this.props.actions["quote"]}
                {...this.props.actions["orderConfirmation"]}
                {...this.props.actions["dispatch"]}
                {...this.props.actions["common"]}
                getDesignFiles={this.reloadFiles}
                // Customize some actions for NotificationView
                //eslint-disable-next-line react/jsx-no-duplicate-props
                editDesign={values =>
                  this.props.actions["designSummary"]
                    .editDesign(values)
                    .then(() => this.reloadDesigns())
                }
                patchDesignFile={values =>
                  this.props.actions.designFiles
                    .patchDesignFile(values)
                    .then(() => this.reloadFiles())
                }
                uploadNewDesignFile={values =>
                  this.props.actions.designFiles
                    .uploadNewDesignFile(values)
                    .then(() => {
                      this.reloadFiles();
                    })
                }
                deleteDesignFile={values =>
                  this.props.actions.designFiles
                    .deleteDesignFile(values)
                    .then(() => {
                      this.reloadFiles();
                    })
                }
                // this i added after notification error
                getBrandTnaTemplate={
                  this.props.actions.designDetails.getBrandTnaTemplate
                }
                noTnaBrand={this.props.brandTnaTemplate}
                noAddFilesFromNotifications
                getFactoryTnaTemplate={() => {}}
              />
            ) : (
              // **********************   WORKING HERE ***************  ON DISPTACT DETAILS FOR DESKTOP ********

              <div>
                <div className="design-details">
                  <Tabs
                    // initialTabUrl={this.props.currentTab}
                    initialTabUrl="dispatchQuantity"
                    header={null}
                    tabs={Object.values({
                      dispatchQuantity: DispatchQuantityTab,
                      dispatchDetails: DispatchDetailsTab
                    })}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
  // return null;
  // }
}

const mapStateToProps = state => {
  return {
    collections: state.collections.list,
    usersList: state.auth.list,
    selectedDesigns: state.notificationView.selectedDesigns,
    sampleDetails: state.designs.samplingDetails,
    quoteDetails: state.designs.quoteDetails,
    quoteSummary: state.designs.quoteSummary,
    orderDetails: state.designs.orderDetails,
    dispatchDetails: state.designs.dispatchDetails,
    designJourney: state.designs.designJourney,
    currentUser: state.auth.userDetails,
    selectedCategory: state.notificationView.tabToDisplay,
    notificationDesigns: state.notificationView.notificationDesigns,
    notificationPoOrders: state.notificationView.notificationPoOrders,
    orderFiles: state.designs.orderFiles,
    dispatchFiles: state.designs.dispatchFiles,
    brandTnaTemplate: state.designs.brandTnaTemplate,
    // not having changes
    designFiles: state.swatchLibrary.files,
    newSampleDetails: state.designs.newSampleDetails
  };
};

const mapDispatchToProps = dispatch => ({
  actions: {
    common: {
      openModal: (name, additionalProps) =>
        dispatch(actions.openModal(name, additionalProps)),
      getNotificationDetails: details =>
        dispatch(actions.getNotificationDetails(details)),
      authTokenLogin: details => dispatch(actions.authTokenLogin(details)),
      setNotificationMode: mode => dispatch(actions.setNotificationMode(mode))
    },
    collectionDetails: {
      getCollectionById: collectionId =>
        dispatch(actions.getCollectionById(collectionId))
    },
    designDetails: {
      getMultipleDesigns: details =>
        dispatch(actions.getMultipleDesigns(details)),
      getBrandTnaTemplate: details =>
        dispatch(actions.getBrandTnaTemplate(details)),
      getAllCommints: details =>
        dispatch(actions.getAllCommintsForSampling(details))
    },
    designSummary: {
      submitEditDesignForm: () => dispatch(submit("designSummary")),
      editDesign: values => dispatch(actions.editDesign(values))
    },
    designFiles: {
      patchDesignFile: details => dispatch(actions.patchDesignFile(details)),
      uploadNewDesignFile: details =>
        dispatch(actions.uploadNewDesignFile(details)),
      deleteDesignFile: details => dispatch(actions.deleteDesignFile(details)),
      getSwatchDesignFiles: details =>
        dispatch(actions.getSwatchDesignFiles(details))
    },
    sampling: {
      getSampleDetails: designDetails =>
        dispatch(actions.getSampleDetails(designDetails)),
      updateSampleDetails: details =>
        dispatch(actions.updateSampleDetails(details)),
      // new api s
      getSamplesByDesignId: details =>
        dispatch(actions.getSamplesByDesignId(details)),
      postNewSampleDetails: details =>
        dispatch(actions.postNewSampleDetails(details))
    },
    quote: {
      getQuoteSummary: designDetails =>
        dispatch(actions.getQuoteSummary(designDetails)),
      getQuoteDetails: designDetails =>
        dispatch(actions.getQuoteDetails(designDetails)),
      updateQuoteStatus: details =>
        dispatch(actions.updateQuoteStatus(details)),
      updateQuoteSummary: details =>
        dispatch(actions.updateQuoteSummary(details)),
      createQuoteSummary: details =>
        dispatch(actions.createQuoteSummary(details)),
      updateQuote: details => dispatch(actions.updateQuote(details)),
      createQuote: details => dispatch(actions.createQuote(details))
    },
    orderConfirmation: {
      getOrderDetails: details => dispatch(actions.getOrderDetails(details)),
      addOrderDetails: details => dispatch(actions.addOrderDetails(details)),
      patchOrderDetails: details =>
        dispatch(actions.patchOrderDetails(details)),
      deleteOrderFile: details => dispatch(actions.deleteOrderFile(details)),
      changeOrderStatus: details => dispatch(actions.changeOrderStatus(details))
    },
    dispatch: {
      getDispatchDetails: details =>
        dispatch(actions.getDispatchDetails(details)),
      addDispatchDetails: details =>
        dispatch(actions.addDispatchDetails(details)),
      patchDispatchDetails: details =>
        dispatch(actions.patchDispatchDetails(details)),
      patchDispatchStatus: details =>
        dispatch(actions.patchDispatchStatus(details)),
      deleteDispatchFile: details =>
        dispatch(actions.deleteDispatchFile(details))
    }
  }
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(NotificationContainer)
);
