import React, { useMemo, useCallback, useRef, useState, useEffect } from 'react';
import Button from 'components/UI/Button/Button';
import './styles.scss';
import { IconAttachFile } from 'components/UI/Icons';

const Compress = require("compress.js");
const compress = new Compress();

const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

const compressFile = async (file, field, changeFormValue, extraImages) => {
  if (!file || file.deleted) {
    changeFormValue(field, file);
    return;
  }

  const compressedFile = await compress
    .compress([file], {
      size: 2,
      quality: 0.9,
      maxWidth: 1920,
      maxHeight: 1920,
      resize: true
    })
    .then(data => {
      const fileBlob = b64toBlob(data[0].data, file.type);
      var compressFile = new File([fileBlob], file.name);
      changeFormValue(field, compressFile);
    });
  return await compressedFile;
};

const InputLogo = ({
  field,
  form: { touched, errors, setFieldValue },
  label,
  required,
}) => {
  const fileInputRef = useRef(null);
  const [imgUrl, setImgUrl] = useState(null);
  const [fileName, setFileName] = useState(null);

  useEffect(() => {
    if ((field.value instanceof File) && FileReader) {
      const fr = new FileReader();

      fr.onload = function () {
        setImgUrl(fr.result);
      }

      fr.readAsDataURL(field.value);

      setFileName(field.value.name);
    } else if (field.value && field.value.imgUrl) {
      setImgUrl(field.value.imgUrl);
      setFileName(field.value.name);
    } else {
      setImgUrl(null);
      setFileName(null);
    }
  }, [field.value]);

  const fileChangeHandler = useCallback((e) => {
    compressFile(e.target.files[0], field.name, setFieldValue);
  }, []);

  const deleteFileHandler = () => {
    fileInputRef.current.value = "";
    setFieldValue(field.name, "");
  };

  return <div className="formik-field formik-input-logo">
    {label && <label htmlFor={field.name} className="label">
      {label}
      {
        required && <sup>*</sup>
      }
    </label>}
    <div className="formik-field__input">
      <div className="formik-field__input__img">
        {
          imgUrl
            ? <img src={imgUrl} alt="" />
            : <IconAttachFile color="blue" />
        }
      </div>
      <div className="cell-v2">
        {fileName
          ? fileName
          : <>
            <div>Upload customer logo</div>
            <div>Only JPEG is allowed</div>
          </>
        }
      </div>
      {
        fileName
          ? <Button
            category="plain-blue"
            type="button"
            onClick={deleteFileHandler}
          >Delete</Button>
          : <Button
            category="plain-blue"
            type="button"
            onClick={() => fileInputRef.current.click()}
          >Attach</Button>
      }
    </div>
    <input
      type="file"
      accept=".jpg, .png, .jpeg, .pdf, .doc, .docx, .xls, .xlsx"
      onChange={fileChangeHandler}
      ref={fileInputRef}
      className={"hide"}
    />
    {touched[field.name] &&
      errors[field.name] && <div className="error">{errors[field.name]}</div>}
  </div>;
}


export default InputLogo;
