import JSZip from "jszip";

import { saveAs } from 'file-saver';
import axios from "axios";

let zip = new JSZip();
let folder = zip.folder('swatches');

export const downloadZip = async (files) => {

  for (let i = 0; i < files.length; i++) {
    const { url, name } = files[i];
    const resp = await fetch(url);
    const blob = await resp.blob();
    // const fileBlob = b64toBlob(base64img, "string/image");
    var fileImg = new File([blob], "dope");
    folder.file(`${name}.jpg`, fileImg);
  }

  const arch = await folder.generateAsync({ type: "blob" }).then(
    (content)=>
    {
      saveAs(content, "swatches");
    }
  );
  
} 