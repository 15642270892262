import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import Popover from "react-tiny-popover";
import icDelete from "assets/images/action/ic-delete.svg";
import icRestore from "assets/images/action/ic-restore.svg";
import moreVert from "assets/images/navigation/ic-more-vert.svg";
import archiveImg from "assets/images/action/archive-24px.svg";
import icMove from "assets/images/action/ic-move.svg";
import icDuplicate from "assets/images/action/ic-duplicate.svg";
import Tabs from "./HeaderTab";
import DesignSummary from "../DesignSummary";
import { TIMESTAMP_FORMAT } from "constants/Date";
import FilesContainer from "./../FilesContainer";
import ActivityLog from "../ActivityLog";
import history from "utilites/history";

import MessagesTab from "./../MessagesTab/MessagesTab";

import { canCreateQuote } from "helpers/AuthHelper";
import { getCurrentUserRole } from "helpers/AuthHelper";
import {
  LOCO_SUPER,
  FACTORY_MANAGER,
  FACTORY_ADMIN,
  CLIENT,
  CLIENT_SUPER,
  PRODUCTION_MERCHANT,
  PRODUCT_DEVELOPMENT
} from "constants/Auth";
import SamplingDetails from "../SamplingDetails/SamplingDetails_New";
import QuoteDetails from "../QuoteDetails/QuoteDetails";

import FactoryTna from "../FactoryTna";
import BrandTna from "../BrandTna";

import "./DesignDetails.scss";
import "react-perfect-scrollbar/dist/css/styles.css";
import Button from "components/UI/Button/Button";

import {
  QUOTE_STATUS_AS_STRING,
  QUOTE_JOURNEY_SUBTITLES
} from "../../../constants/Quote";
import {
  DESIGN_STATUS_KEYS,
  STATUS_HASH,
  DESIGN_SAMPLING_STATUS
} from "../../../constants/Design";
import {
  TNA_STATUS
} from "../../../constants/Tna";

import {
  disableDesignActions,
  hasQuoteRequestExpired
} from "helpers/DesignHelpers";
import { APPROVED, RESUBMIT } from "../../../constants/Sample";
import { checkCanMoveDesign } from "helpers/permissionHelper";

const ALLOWED_USER_ROLES = [
  FACTORY_ADMIN,
  FACTORY_MANAGER,
  PRODUCT_DEVELOPMENT,
  PRODUCTION_MERCHANT
];

export default class DesignDetails extends Component {
  static defaultProps = {
    design: { statusString: "Draft", files: {} },
    collection: {
      collebration: {}
    },
    viewParticularSamples: []
  };

  state = {
    popOverOpen: false
  };

  componentDidMount() {
    // this.checkForBrandTnaTemplate()
    if (this.props.designId && !_.isNaN(this.props.designId)) {
      this.props.getSamplesByDesignId({ designId: this.props.designId });
      this.props.getQuoteDetails({ id: this.props.designId });
      this.props.getBrandTnaTemplate(this.props.designId);

      const {
        designId,
        getAllFactoriesForQuotes,
        isFactory
      } = this.props;

      if (!isFactory) {
        getAllFactoriesForQuotes({
          designId: designId,
        });
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.designId != nextProps.designId) {
      this.props.getSamplesByDesignId({ designId: nextProps.designId });
      this.props.getQuoteDetails({ id: nextProps.designId });
      this.props.getBrandTnaTemplate(nextProps.designId);
    }
  }

  getCtaContent = ({ disabledDesignActions }) => {
    const {
      design,
      isFactory,
      isSuperAdmin,
      newSampleDetails,
      quoteDetails,
      openModal,
      factoriesForQuotes
    } = this.props;

    // until proper design data is not present, don't show CTA
    if (!design.id) {
      return null;
    }

    let isSpreadsheetAdded = false;
    let isQuoteApproved = false;
    if (newSampleDetails && newSampleDetails.spreadSheetId) {
      isSpreadsheetAdded = true;
    }

    if (!_.isEmpty(quoteDetails[QUOTE_STATUS_AS_STRING.ACCEPTED])) {
      isQuoteApproved = true;
    }

    if (disabledDesignActions || isFactory) {
      return null;
    }

    if (
      (isSpreadsheetAdded || isQuoteApproved) &&
      factoriesForQuotes &&
      factoriesForQuotes.length > 0
    ) {
      if (isQuoteApproved) {
        const samples = newSampleDetails.samples;
        let showSampleCta = false,
          showProdutionCta = false;
        let sampleInProgress = false;
        let sampleKeys = samples.map(sample => {
          if (
            sample.sampleStatus === null ||
            sample.sampleStatus === RESUBMIT
          ) {
            sampleInProgress = true;
          }
          return sample.sampleType;
        });
        if (samples.length === 0 && !design.skipSampling) {
          showSampleCta = true;
        } else if (
          !_.find(samples, { isProductionSample: 1 }) &&
          design.status < DESIGN_STATUS_KEYS.PRODUCTION &&
          !sampleInProgress
        ) {
          showProdutionCta = true;
        }
        if (showSampleCta) {
          return (
            <div className="grid-2">
              <Button
                category="black-ghost"
                onClick={() =>
                  openModal("SKIP_SAMPLING", {
                    design,
                    openProductionSampleModal: (skipSampling = 0) => {
                      openModal("MOVE_TO_PRODUCTION", {
                        design,
                        sampleDetails: newSampleDetails,
                        quoteRequestDetails: this.props.quoteRequestDetails,
                        skipSampling
                      });
                    }
                  })
                }
              >
                Skip to Production
              </Button>
              <Button
                category="primary-blue"
                onClick={() =>
                  openModal("REQUEST_SAMPLE", {
                    design,
                    sampleDetails: newSampleDetails,
                    quoteRequestDetails: this.props.quoteRequestDetails
                  })
                }
              >
                Request Sample
              </Button>
            </div>
          );
        } else if (showProdutionCta) {
          return (
            <Button
              category="primary-blue"
              onClick={() =>
                openModal("MOVE_TO_PRODUCTION", {
                  design,
                  sampleDetails: newSampleDetails,
                  quoteRequestDetails: this.props.quoteRequestDetails,
                  skipSampling: 0
                })
              }
            >
              Move to Production
            </Button>
          );
        }
        return null;
      } else {
        if (this.props.quoteRequestDetails) {
          return null;
        } else {
          return (
            <div className='grid-2'>
              <Button
                category="btn ghost blue"
                onClick={() =>
                  this.props.openModal("REQUEST_QUOTE", { design, preApproved: true })
                }
              >
                Enter Pre-Approved Quote
              </Button>
              <Button
                category="primary-blue"
                onClick={() =>
                  this.props.openModal("REQUEST_QUOTE", { design })
                }
              >
                Request Quote
              </Button>
            </div>
          );
        }
      }
    } else {
      if (this.props.quoteRequestDetails) return null;
      return (
        <div className="with_hover">
          <Button category="disabled medium">Request Quote</Button>
          <span className="custom-tooltip">
            {isSpreadsheetAdded
              ? "Please add at least one factory to request a quote"
              : "Upload measurement sheet to request quote"}
          </span>
        </div>
      );
    }
  };

  getDesignProductionTab = ({ canAccess }) => {
    const { quoteDetails = {} } = this.props;

    return {
      name: "production",
      tabUrl: "designtna",
      threshold: 9,
      disabled: true,
      tooltip:
        "Design can be moved to production after the prototype sample is approved",
      // position: "right",
      panel: canAccess ? (
        <FactoryTna
          designId={this.props.designId}
          collection={this.props.collection}
          design={this.props.design}
          brandId={this.props.brandId}
          getFactoryTnaTemplate={this.props.getFactoryTnaTemplate}
          templateDetails={this.props.templateDetails}
          notificationList={this.props.notificationList}
          getBrandTnaTemplate={this.props.getBrandTnaTemplate}
          quoteDetails={quoteDetails}
          openModal={this.props.openModal}
          closeModal={this.props.closeModal}
          sampleDetails={this.props.newSampleDetails}
        />
      ) : (
          <BrandTna
            designId={this.props.designId}
            design={this.props.design}
            collection={this.props.collection}
            sampleDetails={this.props.newSampleDetails}
            checkForTemplate={this.checkForBrandTnaTemplate}
            addNewCommint={this.props.addNewCommint}
            openVersionHistoryModal={sampleVersions => {
              this.props.openModal("SAMPLE_VERSION_HISTORY", {
                design: this.props.design,
                sampleVersions: sampleVersions
              });
            }}
            getFactoryTnaTemplate={this.props.getFactoryTnaTemplate}
            notificationList={this.props.notificationList}
            getBrandTnaTemplate={this.props.getBrandTnaTemplate}
            quoteDetails={quoteDetails}
            openModal={this.props.openModal}
            closeModal={this.props.closeModal}
          />
        )
    };
  };

  getDesignSummaryTab = ({ disabledDesignActions }) => {
    return {
      name: "Design details",
      tabUrl: "summary",
      threshold: 2,
      panel: (
        <div>
          <div className="custom-scroll-container">
            <DesignSummary
              key={this.props.designId}
              openModal={this.props.openModal}
              submitEditDesignForm={this.props.submitEditDesignForm}
              editDesign={this.props.editDesign}
              currentUser={this.props.currentUser}
              design={this.props.design}
              patchDesignFile={this.props.patchDesignFile}
              uploadNewDesignFile={this.props.uploadNewDesignFile}
              deleteDesignFile={this.props.deleteDesignFile}
              getDesignFiles={this.props.getDesignFiles}
            />
            <FilesContainer
              isInsideNotificationContainer={
                this.props.viewParticularTab ? true : false
              }
              canEdit={true}
              currentUser={this.props.currentUser}
              reloadDesigns={this.props.reloadDesigns}
              openModal={this.props.openModal}
              design={this.props.design}
              deleteDesignFile={this.props.deleteDesignFile}
              getDesignFiles={this.props.getDesignFiles}
              isFactory={this.props.isFactory}
              noAddFilesFromNotifications={
                this.props.noAddFilesFromNotifications
              }
              newSampleDetails={this.props.newSampleDetails}
              spreadsheetUploaded={
                this.props.newSampleDetails
                  ? this.props.newSampleDetails.spreadSheetId
                  : false
              }
              postSampleMeasurementSheet={this.props.postSampleMeasurementSheet}
              disableDesignActions={disabledDesignActions}
            />
            <ActivityLog
              designId={this.props.designId}
              designStatus={this.props.design.status}
            />
          </div>
        </div>
      )
    };
  };

  getDesignMessagesTab = () => ({
    name: "Messages",
    tabUrl: "messages",
    position: "right",
    disabled: true,
    count: this.props.messagesCount,
    panel: (
      <MessagesTab
        design={this.props.design}
        quoteRequestDetails={this.props.quoteRequestDetails}
        newSampleDetails={this.props.newSampleDetails}
      />
    )
  });

  getDesignSamplingTab = () => ({
    name: "Sampling",
    tabUrl: "sampling",
    disabled: true,
    tooltip: this.props.currentUser.isBrand
      ? "Sample can be requested after a quote is approved"
      : "Design moves to sampling once a quote is approved",
    threshold: 7,
    panel: (
      <SamplingDetails
        // for new sampling api's
        getSamplesByDesignId={() => {
          this.props.getSamplesByDesignId({
            designId: this.props.designId
          });
        }}
        newSampleDetails={{ ...this.props.newSampleDetails }}
        postSampleMeasurementSheet={this.props.postSampleMeasurementSheet}
        design={this.props.design}
        collection={this.props.collection}
        key={this.props.designId}
        postPreProductionCommit={this.props.postPreProductionCommit}
        isSuperAdmin={this.props.isSuperAdmin}
        isFactory={this.props.isFactory}
        brandId={this.props.brandId}
        openVersionHistoryModal={sampleVersions => {
          this.props.openModal("SAMPLE_VERSION_HISTORY", {
            design: this.props.design,
            sampleVersions: sampleVersions
          });
        }}
        openSampleUpdateModal={details => {
          this.props.openModal("UPDATE_SAMPLE_STATUS", {
            commentDetails: details
          });
        }}
        courierModel={(details, extra) => {
          this.props.openModal("SAMPLING_ADD_COURIERS_DETAILS", {
            details,
            extra,
            design: this.props.design
          });
        }}
        openFileUploadModal={details => {
          this.props.openModal("UPLOAD_FILE_PRE_PRODUCTION", {
            ...details,
            initialValues: {
              file_name: "Pre-production Sample"
            },
            hiddenFields: [
              { name: "designId", value: this.props.designId },
              { name: "moduleRef", value: details.moduleRef },
              { name: "getDetails", value: details.getDetails }
            ],
            acceptTypes: "image/x-png,image/gif,image/jpeg"
          });
        }}
        sampleDetails={
          Object.keys(this.props.sampleDetails).length > 0
            ? Object.entries(this.props.sampleDetails).filter(
              ([key, val]) => key !== "version"
            )[0][1]
            : {}
        }
      />
    )
  });

  getDesignQuoteTab = () => ({
    name: "Quote",
    tabUrl: "quote",
    disabled: true,
    tooltip: "Fill design details to request quote from a factory",
    threshold: 5,
    panel: (
      <QuoteDetails
        design={this.props.design}
        key={this.props.designId}
        collection={this.props.collection}
        deleteQuote={this.props.deleteQuote}
        getQuoteDetails={() =>
          this.props.getQuoteDetails({ id: this.props.designId })
        }
        readOnly={!canCreateQuote()}
        quoteDetails={this.props.quoteDetails}
        quoteRequestDetails={this.props.quoteRequestDetails}
        notifyQuoteRequest={this.props.notifyQuoteRequest}
        openModal={this.props.openModal}
        sampleDetails={this.props.newSampleDetails}
        flashError={this.props.flashError}
        openCreateQuoteModal={() => {
          this.props.openModal("CREATE_QUOTE_MODAL", {
            hiddenFields: [
              { name: "design_id", value: this.props.designId },
              {
                name: "total_quantity",
                value:
                  (this.props.quoteRequestDetails &&
                    this.props.quoteRequestDetails.quantity) ||
                  0
              },
              { name: "total_cost", value: 0 },
              { name: "design_name", value: this.props.design.name }
            ],
            initialValues: {
              quote_type:
                this.props.quoteRequestDetails &&
                  this.props.quoteRequestDetails.type === "0"
                  ? "OPEN"
                  : "BUNDLED"
            }
          });
        }}
        updateQuote={quoteDetails =>
          this.props.updateQuote({
            ...quoteDetails,
            design_id: this.props.designId,
            collection_id: this.props.collection.id
          })
        }
        // createQuoteSummary={this.props.createQuoteSummary}
        openApproveQuoteModal={(quote, countAwaited) =>
          this.props.openModal("APPROVE_QUOTE_MODAL", {
            hiddenFields: [
              { name: "design_id", value: this.props.design.id },
              { name: "quote_id", value: quote.id },
              { name: "factoryId", value: quote.factoryId },
              { name: "status", value: 1 },
              { name: "design_name", value: this.props.design.name },
              {
                name: "flashMsg",
                value: `Accepted quote of ${quote.factory_name} for ${this.props.design.name}`
              }
            ],
            design: this.props.design,
            quoteDetails: quote,
            countAwaited
          })
        }
        openRejectQuoteModal={quote => {
          this.props.openModal("RESUBMIT_QUOTE_MODAL", {
            hiddenFields: [
              { name: "design_id", value: this.props.design.id },
              { name: "quote_id", value: quote.id },
              { name: "factoryId", value: quote.factoryId },
              { name: "status", value: 2 },
              { name: "design_name", value: this.props.design.name },
              {
                name: "flashMsg",
                value: `Rejected quote of ${quote.factory_name} for ${this.props.design.name}`
              }
            ],
            design: this.props.design,
            quoteDetails: quote
          });
        }}
        createQuote={quote =>
          this.props.createQuote({
            ...quote,
            design_id: this.props.designId
          })
        }
        notificationMode={this.props.notificationMode}
        notificationList={this.props.notificationList}
        addPendingNotification={this.props.addPendingNotification}
        removePendingNotification={this.props.removePendingNotification}
        currentUser={this.props.currentUser}
      />
    )
  });

  moveDesignHandler = () => {
    const { design, openModal } = this.props;
    openModal("MOVE_DESIGN", {
      design: design,
      onSuccess: ({ designId, collectionId }) => {
        history.push(`/brand/collections/${collectionId}/designs/${designId}`);
      },
    });

    this.setState({ popOverOpen: false });
  };

  duplicateDesignHandler = () => {
    const { design, openModal } = this.props;
    openModal("DUPLICATE_DESIGN", {
      design: design,
      onSuccess: ({ designId, collectionId }) => {
        history.push(`/brand/collections/${collectionId}/designs/${designId}`);
      },
    });

    this.setState({ popOverOpen: false });
  };

  archiveDesignHandler = () => {
    const { design, openModal } = this.props;
    openModal("ARCHIVE_DESIGN", {
      hiddenFields: [
        { name: "id", value: design.id },
        { name: "collection_id", value: design.collection_id },
        { name: "status", value: DESIGN_STATUS_KEYS.ARCHIVED },
      ]
    });

    this.setState({ popOverOpen: false });
  };

  restoreDesignHandler = () => {
    const { design, openModal } = this.props;
    openModal("RESTORE_DESIGN", {
      hiddenFields: [
        { name: "id", value: design.id },
        { name: "collection_id", value: design.collection_id },
        { name: "status", value: DESIGN_STATUS_KEYS.DRAFT }
      ]
    });

    this.setState({ popOverOpen: false });
  };

  deleteDesignHandler = () => {
    const { design, openModal } = this.props;
    openModal("DELETE_DESIGN", {
      hiddenFields: [
        { name: "id", value: design.id },
        { name: "collection_id", value: design.collection_id },
        { name: "navigate", value: true }
      ]
    })

    this.setState({ popOverOpen: false });
  };

  renderHeader = ({ disabledDesignActions }) => {
    const { design, isFactory, isSuperAdmin, currentUser } = this.props;

    if (!design.id) {
      return;
    }

    const menuItems = [
      ...((checkCanMoveDesign({ user: currentUser, design }))
        ? [
          {
            key: 'move',
            img: icMove,
            label: 'Move',
            onClick: this.moveDesignHandler,
          },
          {
            key: 'duplicate',
            img: icDuplicate,
            label: 'Duplicate',
            onClick: this.duplicateDesignHandler,
          },
        ]
        : []),
      ((design.status === DESIGN_STATUS_KEYS.DRAFT
        || design.status === DESIGN_STATUS_KEYS.MEASUREMENT_SHEET_UPLOADED) && {
        key: 'archive',
        img: archiveImg,
        label: 'Archive',
        onClick: this.archiveDesignHandler,
      }),
      (design.status === DESIGN_STATUS_KEYS.ARCHIVED && {
        key: 'restore',
        img: icRestore,
        label: 'Restore',
        onClick: this.restoreDesignHandler,
      }),
      ((design.status === DESIGN_STATUS_KEYS.DRAFT
        || design.status === DESIGN_STATUS_KEYS.MEASUREMENT_SHEET_UPLOADED
        || design.status === DESIGN_STATUS_KEYS.ARCHIVED) && {
        key: 'delete',
        img: icDelete,
        label: 'Delete',
        onClick: this.deleteDesignHandler,
      }),
    ].filter(item => item);

    const Menu = (
      <div className="pop-over__item-list">
        {
          menuItems.map(item => <div
            onClick={item.onClick}
            className={`pop-over__item item-${item.key} flex f-sz-l`}
            key={item.key}
          >
            <img src={item.img} className={`img-${item.key}`} alt={`${item.key} design`} />
            <span>{item.label}</span>
          </div>)
        }
      </div>
    );

    return (
      <div className="design-details__header flex center">
        <div className="design-details-custom__left">
          <div className="design-details__title flex">
            {this.props.design.name} &nbsp;&nbsp;
            {!(isFactory && !isSuperAdmin) && menuItems.length > 0 && (
              <div className="design-details-custom__right">
                <Popover
                  isOpen={this.state.popOverOpen}
                  position={"bottom"}
                  content={Menu}
                  onClickOutside={() => this.setState({ popOverOpen: false })}
                >
                  <img
                    className="header-dots"
                    onClick={evt => {
                      this.setState({ popOverOpen: true });
                    }}
                    src={moreVert}
                    alt="three dots"
                  />
                </Popover>
              </div>
            )}
          </div>
          <div className="col-details__metadata">
            <span className="col-details__author">
              Created by {design.username}
            </span>
            <span className="col-details__time">
              {moment(design.created_on).format(TIMESTAMP_FORMAT)}
            </span>
          </div>
        </div>
        {/* Hide when used in NotificationContainer */}
        {!this.props.viewParticularTab && (
          <React.Fragment>
            <div
              onClick={() => {
                let modalName = "COLLECTION_COLLAB";
                if (
                  this.props.collection.collebration[this.props.currentUser.id]
                ) {
                  if (
                    this.props.collection.collebration[
                    this.props.currentUser.id
                    ]["role"] === 2
                  ) {
                    modalName = "COLLECTION_COLLAB_VIEWONLY"; // TODO: check me out
                  }
                }
                this.props.openModal(modalName, {
                  hiddenFields: [
                    { name: "collectionId", value: this.props.collection.id }
                  ],
                  collectionDetails: this.props.collection,
                  usersList: this.props.usersList
                });
              }}
              className="flex"
              // design-details-custom__center user-badge__wrapper
              style={{ marginLeft: "auto" }}
            ></div>

            {this.getCtaContent({ disabledDesignActions })}
          </React.Fragment>
        )}
      </div>
    );
  };

  render() {
    const { currentUser, design, noTnaBrand: tnaDetails } = this.props;

    const disabledDesignActions = disableDesignActions(
      this.props.design.status
    );

    const canAccess = ALLOWED_USER_ROLES.includes(getCurrentUserRole());

    const DesignSummaryTab = this.getDesignSummaryTab({
      disabledDesignActions
    });
    const DesignQuoteTab = this.getDesignQuoteTab();
    const DesignSamplingTab = this.getDesignSamplingTab();
    const DesignProductionTab = this.getDesignProductionTab({ canAccess });
    const DesignMessagesTab = this.getDesignMessagesTab();

    if (
      this.props.design &&
      this.props.design.status !== DESIGN_STATUS_KEYS.ARCHIVED &&
      this.props.design.status !== DESIGN_STATUS_KEYS.CANCELED
    ) {
      /**
       * Quote tab will be visible only when the quote has been requested || sampling has begun
       * Sampling tab will be visible only when quote has been approved || Sampling has been skipped.
       */
      if (this.props.quoteRequestDetails) {
        DesignQuoteTab.disabled = false;
        DesignMessagesTab.disabled = false;
        DesignQuoteTab.subTitle = "Requested";
      }

      const designStatus = this.props.design.status;
      const samplingSkipped = this.props.design.skip_sampling;
      if (
        !_.isEmpty(this.props.quoteDetails) ||
        designStatus >= DesignQuoteTab.threshold
      ) {
        const approvedQuote =
          this.props.quoteDetails["1"] &&
          this.props.quoteDetails["1"].length > 0;
        const activeQuote =
          this.props.quoteDetails["0"] &&
          this.props.quoteDetails["0"].length > 0;
        const anyQuoteRejected =
          this.props.quoteDetails["2"] &&
          this.props.quoteDetails["2"].length > 0;
        const sampleExists =
          _.filter(this.props.newSampleDetails.samples, {
            isProductionSample: 0
          }).length > 0;

        if (approvedQuote) {
          DesignQuoteTab.subTitle = QUOTE_JOURNEY_SUBTITLES.APPROVED;
        } else if (activeQuote) {
          if (currentUser.isFactory) {
            DesignQuoteTab.subTitle = QUOTE_JOURNEY_SUBTITLES.SUBMITTED;
          } else {
            DesignQuoteTab.subTitle = QUOTE_JOURNEY_SUBTITLES.RECEIVED;
          }
        } else if (STATUS_HASH["QUOTE"].includes(this.props.design.status)) {
          if (currentUser.isFactory) {
            if (anyQuoteRejected) {
              DesignQuoteTab.subTitle = QUOTE_JOURNEY_SUBTITLES.REJECTED;
            } else {
              DesignQuoteTab.subTitle = QUOTE_JOURNEY_SUBTITLES.REQUESTED;
            }
          } else {
            DesignQuoteTab.subTitle = QUOTE_JOURNEY_SUBTITLES.AWAITED;
          }
        } else {
          if (
            currentUser.isFactory &&
            design.status >= DESIGN_STATUS_KEYS.QUOTE_APPROVED
          ) {
            DesignQuoteTab.subTitle = QUOTE_JOURNEY_SUBTITLES.NOT_REQUESTED;
          } else {
            DesignQuoteTab.subTitle = QUOTE_JOURNEY_SUBTITLES.NOT_REQUESTED;
          }
        }

        if (sampleExists) {
          DesignSamplingTab.disabled = false;
          DesignMessagesTab.disabled = false;

          const sampleDetails = this.props.newSampleDetails;
          const sampleInProgress = _.find(
            sampleDetails.samples,
            ({ sampleStatus, isProductionSample }) =>
              (sampleStatus === null || sampleStatus === RESUBMIT) &&
              isProductionSample === 0
          );
          if (sampleInProgress) {
            DesignSamplingTab.subTitle = DESIGN_SAMPLING_STATUS.REQUESTED;
          }
        } else if (samplingSkipped) {
          DesignSamplingTab.disabled = false;
          DesignMessagesTab.disabled = false;
          DesignSamplingTab.subTitle = DESIGN_SAMPLING_STATUS.SKIPPED;
        }
        if (
          design.status === DESIGN_STATUS_KEYS.DISPATCHED ||
          design.status === DESIGN_STATUS_KEYS.PRODUCTION
        ) {
          DesignProductionTab.disabled = false;
          if (tnaDetails.payload) {
            if (tnaDetails.payload.status === TNA_STATUS.SUBMITTED) {
              DesignProductionTab.subTitle = "TNA Approval Pending";
            } else if (tnaDetails.payload.status !== TNA_STATUS.APPROVED) {
              DesignProductionTab.subTitle = "TNA requested";
            }
          }
        }
        DesignQuoteTab.disabled = false;
      }

      if (
        currentUser.isFactory &&
        this.props.quoteRequestDetails &&
        this.props.quoteRequestDetails.closure_date &&
        !this.props.quoteDetails[QUOTE_STATUS_AS_STRING.ACCEPTED]
      ) {
        let requestExpired = false;

        // if factory's quote was not accepted, and deadline has passed away
        requestExpired = hasQuoteRequestExpired(
          this.props.quoteRequestDetails.closure_date
        );

        // if factory never submitted the quote, but brand
        // has accepted the quote from another factory
        if (this.props.design.status === DESIGN_STATUS_KEYS.QUOTE_APPROVED) {
          requestExpired = true;
        }

        if (requestExpired) {
          DesignQuoteTab.subTitle = QUOTE_JOURNEY_SUBTITLES.EXPIRED;
        }
      }
    }

    const Header = this.renderHeader({ disabledDesignActions });

    const DesignDetailsTabs = {
      summary: DesignSummaryTab,
      quote: DesignQuoteTab,
      sampling: DesignSamplingTab,
      production: DesignProductionTab,
      messages: DesignMessagesTab
    };

    return (
      <div className="design-details-custom">
        <Tabs
          // initialTabUrl={this.props.currentTab}
          initialTabUrl="summary"
          header={Header}
          design={this.props.design}
          tabs={
            this.props.viewParticularTab
              ? [
                DesignDetailsTabs["summary"],
                DesignDetailsTabs["files"],
                // Had to add this because DesignDetailsTabs wont have tna as key.(Tna tab logic is based on pp sample request and approval)
                this.props.viewParticularTab === "tna"
                  ? DesignDetailsTabs.production
                  : DesignDetailsTabs[this.props.viewParticularTab]
                // ...Object.values(currentDesignDetailsTab)
              ]
              : Object.values(DesignDetailsTabs)
          }
        />
      </div>
    );
  }
}
