import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import moment from 'moment';
import orderBy from 'lodash/orderBy';
import keyBy from 'lodash/keyBy';
import PerfectScrollbar from 'react-perfect-scrollbar';
import EmptyScreen from 'components/UI/EmptyScreen';
import DesignAvatar from 'components/UI/Avatar/DesignAvatar';
import { TextSpliced } from 'components/UI/TextSpliced';
import ModalFooter from 'components/UI/Modal/ModalFooter';
import { getTnaDesignSuggestions, updateDesignTna } from 'store/actions/TnaActions';
import { DATESTAMP__SHORT_FORMAT } from 'constants/Date';
import { TNA_ATTRIBUTES_ORDER } from 'constants/Tna';
import thumbsDownImg from "assets/images/action/ic-thumbsup-grey.svg";
import chooseDesignImg from "assets/images/emptyPages/ic-choose-design.svg";
import './style.scss';

const DesignCardWithTna = ({ design, isSelected, onClick, onKeyPress }) => {
  return <div
    className={classnames("design-card-with-tna card", {
      'card--selected': isSelected,
    })}
    category=""
    onClick={onClick}
    onKeyPress={onKeyPress}
    tabIndex={1}
  >
    <div className="design-card-with-tna__cover">
      <DesignAvatar design={design} size="56px" />
    </div>
    <div className="design-card-with-tna__design-info">
      <div className="design-name">
        <TextSpliced text={design.designName} maxWidth={128} />
      </div>
      <div className="design-id">
        <TextSpliced text={design.sourceDesignId} maxWidth={128} />
      </div>
    </div>
    <div className="design-card-with-tna__tna-info">
      <div className={classnames("tna-step",
        (design.prevStep && design.prevStep.formattedTimestamp)
          ? `tna-step--${design.isDelayed ? 'delayed' : 'on-time'}`
          : 'tna-step--idle' )}>
        <span className="tna-step__indicator"></span>
        <TextSpliced
          className="tna-step__name inline"
          text={(design.prevStep && design.prevStep.subCategory) || "Yet to start"}
          maxWidth={120}
        />
        <span className="tna-step__date">{design.prevStep && design.prevStep.formattedTimestamp}</span>
      </div>
      <div className="tna-step">
        <span className="tna-step__indicator"></span>
        <span className="tna-step__name">{design.nextStep.subCategory}</span>
        <span className="tna-step__date">due on {design.nextStep.formattedTimestamp}</span>
      </div>
    </div>
  </div>
};

const DesignTnaPlan = ({ design, mapTnaAttributes, tnaTemplate }) => {
  return <div className="design-tna-plan">
    <div className="design-info">
      <DesignAvatar design={design} size="32px" />
      <TextSpliced text={design.designName} className="design-info__name" maxWidth={350} />
    </div>
    <div>
      {tnaTemplate.map(templateCategory => <div
        className="tna-category"
        key={templateCategory.order}
      >
        <div className="tna-row tna-category__row">
          <div className="tna-category__name">{templateCategory.category}</div>
          <div className="tna-category__sub-text">PLANNED DUE DATE</div>
        </div>
        {templateCategory.subCategories
          .filter(templateSubCategory => mapTnaAttributes[templateSubCategory.tnaTemplateId].isSelected && mapTnaAttributes[templateSubCategory.tnaTemplateId].subAttributeValue)
          .map(templateSubCategory => <div
            className="tna-subcategory"
            key={templateSubCategory.order}
          >
            <div className="tna-row tna-subcategory__row">
              <div className="tna-subcategory__name">{templateSubCategory.subCategory}</div>
              <div className="tna-subcategory__date">{moment(mapTnaAttributes[templateSubCategory.tnaTemplateId].subAttributeValue).format(DATESTAMP__SHORT_FORMAT)}</div>
            </div>
          </div>)}
      </div>)}
    </div>
    <div className="tna-category"></div>
  </div>;
};

const CopyTnaModal = ({
  design,
  designTnaAttributes,
  requestData,
  suggestions,
  tnaTemplate,
  oldSelectedDesign,
  onSubmit,
  closeModal,
  updateDesignTna,
  getTnaDesignSuggestions,
  onSuccess,
}) => {
  const [groupedDesigns, setGroupedDesigns] = useState([]);
  const [selectedDesign, setSelectedDesign] = useState(null);
  const [formattedTnaTemplate, setFormattedTnaTemplate] = useState([]);

  useEffect(() => {
    getTnaDesignSuggestions({ designId: design.id, });
  }, []);

  // group designs on the basis of "move to prod" date
  useEffect(() => {
    if (!suggestions || !tnaTemplate) {
      return;
    }

    const mapTnaTemplate = keyBy(tnaTemplate, 'id');
    const dispatchTemplate = tnaTemplate[tnaTemplate.length - 1];

    const _groupedSuggestions = {};
    suggestions.forEach(suggestion => {
      if (oldSelectedDesign && oldSelectedDesign.id === suggestion.id) {
        return;
      }

      let latestCompletedStep = null; // suggestion.tna.find(t => t.attributeValue !== null);
      const mapTnaAttributes = keyBy(suggestion.tna, 'tnaTemplateAttributeId');
      const formattedDate = moment(suggestion.movedToProductionOn).format(DATESTAMP__SHORT_FORMAT);

      for (let ind = 0; ind < suggestion.tna.length; ind++) {
        if (suggestion.tna[ind].attributeValue === null) {
          break;
        }

        latestCompletedStep = suggestion.tna[ind];
      }

      if (latestCompletedStep) {
        const completedStepTemplate = mapTnaAttributes[latestCompletedStep.tnaTemplateAttributeId];

        if (completedStepTemplate.categoryOrder === TNA_ATTRIBUTES_ORDER.SAMPLE_DISPATCH.categoryOrder
          && completedStepTemplate.attributeOrder === TNA_ATTRIBUTES_ORDER.SAMPLE_DISPATCH.attributeOrder
          && suggestion.sampleApprovedOn) {
          latestCompletedStep = {
            category: 'Sample Approval',
            attributeValue: suggestion.sampleApprovedOn,
            isProduction: true,
          };
        }
      }

      _groupedSuggestions[formattedDate] = _groupedSuggestions[formattedDate] || {
        formattedDate,
        designs: [],
      };

      _groupedSuggestions[formattedDate].designs.push({
        ...suggestion,
        isDelayed: (!latestCompletedStep || (latestCompletedStep.attributeValue > latestCompletedStep.subAttributeValue)),
        mapTnaAttributes,
        prevStep: (latestCompletedStep && {
          ...mapTnaTemplate[latestCompletedStep.tnaTemplateAttributeId],
          ...(latestCompletedStep.isProduction ? latestCompletedStep : {}),
          formattedTimestamp: moment(latestCompletedStep.attributeValue).format(DATESTAMP__SHORT_FORMAT),
        }) || {},
        nextStep: {
          subCategory: 'Dispatch',
          formattedTimestamp: moment(mapTnaAttributes[dispatchTemplate.id].subAttributeValue).format(DATESTAMP__SHORT_FORMAT)
        },
      });
    });

    setGroupedDesigns(orderBy(Object.values(_groupedSuggestions), 'formattedDate', 'desc'));

  }, [suggestions, tnaTemplate]);

  useEffect(() => {
    const _groupedTnaTemplate = {};
    tnaTemplate.forEach(item => {
      _groupedTnaTemplate[item.categoryOrder] = _groupedTnaTemplate[item.categoryOrder] || {
        order: item.categoryOrder,
        category: item.category,
        subCategories: {},
      };

      _groupedTnaTemplate[item.categoryOrder].subCategories[item.attributeOrder] =
        _groupedTnaTemplate[item.categoryOrder].subCategories[item.attributeOrder] || {
          order: item.attributeOrder,
          subCategory: item.subCategory,
          tnaTemplateId: item.id,
        };
    });

    const _formattedTnaTemplate = orderBy(Object.values(_groupedTnaTemplate), 'order');
    _formattedTnaTemplate.forEach(item => {
      item.subCategories = orderBy(Object.values(item.subCategories), 'order');
    });

    setFormattedTnaTemplate(_formattedTnaTemplate);

  }, [tnaTemplate]);

  const designClickHandler = useCallback((design) => {
    setSelectedDesign(design);
  }, []);

  const designKeyPressHandler = useCallback((e, design) => {
    if (e.which === 32) {
      e.preventDefault();
      e.stopPropagation();

      setSelectedDesign(design);
    } else if (e.which === 13) {
      e.preventDefault();
      e.stopPropagation();

      setSelectedDesign(design);
      submitDesign(e, design);
    }
  }, []);

  const submitDesign = useCallback((e, argSelectedDesign) => {
    const finalSelectedDesign = argSelectedDesign || selectedDesign;

    if (!finalSelectedDesign) {
      return;
    }

    const dispatchAttribute = tnaTemplate.find(item =>
      item.categoryOrder === TNA_ATTRIBUTES_ORDER.SAMPLE_DISPATCH.categoryOrder
      && item.attributeOrder === TNA_ATTRIBUTES_ORDER.SAMPLE_DISPATCH.attributeOrder
    );
    const dispatchAttributeId = dispatchAttribute && dispatchAttribute.id;

    const request = {
      ...requestData,
      status: 1,
      attributes: designTnaAttributes.map(dta => {
        if (dta.tnaTemplateAttributeId === dispatchAttributeId) {
          return dta;
        }

        return {
          ...dta,
          subAttributeValue: finalSelectedDesign.mapTnaAttributes[dta.tnaTemplateAttributeId]
            && finalSelectedDesign.mapTnaAttributes[dta.tnaTemplateAttributeId].subAttributeValue,
        };
      })
    };

    updateDesignTna(request)
      .then(() => {
        closeModal();

        if (onSuccess) {
          onSuccess(finalSelectedDesign);
        }
      });

  }, [selectedDesign, tnaTemplate, requestData, designTnaAttributes, updateDesignTna, closeModal, onSuccess]);

  return <div className="copy-tna-modal">
    <div className="copy-tna-modal__split-container">
      {groupedDesigns.length > 0
        ?
        <>
          <PerfectScrollbar className="copy-tna-modal__grouped-container">
            {groupedDesigns.map(item => <div
              key={item.formattedDate}
              className="copy-tna-modal__grouped-item"
            >
              <div className="copy-tna-modal__grouped-date">
                {item.formattedDate}
              </div>
              <div className="copy-tna-modal__grouped-designs">
                {item.designs.map(d => <DesignCardWithTna
                  key={d.id}
                  design={d}
                  isSelected={selectedDesign && selectedDesign.id === d.id}
                  onClick={() => designClickHandler(d)}
                  onKeyPress={(e) => designKeyPressHandler(e, d)}
                />)}
              </div>
            </div>)}
          </PerfectScrollbar>
          <PerfectScrollbar className="copy-tna-modal__design-plan-container">
            {
              selectedDesign
                ? <DesignTnaPlan
                  design={selectedDesign}
                  mapTnaAttributes={selectedDesign.mapTnaAttributes}
                  tnaTemplate={formattedTnaTemplate}
                />
                : <EmptyScreen
                  icon={chooseDesignImg}
                  iconSize="48px"
                  description="Select a design to view and copy the TNA"
                />
            }
          </PerfectScrollbar>
        </>
        : <EmptyScreen
          icon={thumbsDownImg}
          heading="No designs available"
        />
      }
    </div>
    <ModalFooter
      cancel="Dismiss"
      submit="Copy TNA"
      canSubmit={selectedDesign}
      canCancel={true}
      onCancel={closeModal}
      onSubmit={submitDesign}
    />
  </div>;
};

const mapStateToProps = (state, props) => ({
  suggestions: props.suggestions || (state.tna.designSuggestions[props.design.id] && state.tna.designSuggestions[props.design.id].designs) || [],
  tnaTemplate: props.tnaTemplate || (state.tna.designSuggestions[props.design.id] && state.tna.designSuggestions[props.design.id].tnaTemplateMetadata) || [],
});

const mapDispatchToProps = (dispatch) => ({
  getTnaDesignSuggestions: (...args) => dispatch(getTnaDesignSuggestions(...args)),
  updateDesignTna: (...args) => dispatch(updateDesignTna(...args)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CopyTnaModal);
