import React, { useState, useEffect } from "react";
import moment from "moment";
import Dropdown from "react-dropdown";
import _ from "lodash";
import axios from "axios";
import { withRouter } from "react-router-dom";

// == local imports ==
import { get_service_endpoint } from "../../../ServiceEndpoints";
import { APPROVED, RESUBMIT } from "../../../constants/Sample";

const eq = get_service_endpoint("notificationView");

// zindex is pending here ====
const SamplingDetails = ({ samples = [{}], designId, location }) => {
  const {
    sampleType,
    sampleStatus,
    sampleSize,
    createdAt,
    updatedAt = new Date(),
    id: sampleOnScreenId
  } = samples[0];

  console.log("STAUS OOOOO", samples);

  const [choosedSampleStatus, setChoosedSampleStatus] = useState(sampleStatus);
  const [comment, setcomment] = useState("");
  const [isUpdated, setIsUpdated] = useState(false);

  // == states for comment ==
  const [prevComments, setPrevComments] = useState([]);
  const [showPrevComments, setShowPrevComments] = useState(false);

  // === this controlls the load prev comments button ===
  useEffect(() => {
    // const fetchData = async () => {
    //   const result = await axios.get(
    //     `${get_service_endpoint("notificationView")}/comments`,
    //     {
    //       params: {
    //         designid: designId,
    //         moduletype: "1",
    //         moduleref: sampleType
    //       }
    //     }
    //   );
    //   // if (result.data.length > 0) {
    //   //   setPrevComments(result.data);
    //   // }

    //   console.log("RRRR", result);
    //   setPrevComments(result.data);
    // };
    // fetchData();
  }, []);

  // == this function gets called on clcking the load prev comments ==
  const getCommentsHandler = async () => {
    const result = await axios.get(
      `${get_service_endpoint("notificationView")}/comments`,
      {
        params: {
          designid: designId,
          moduletype: "1",
          moduleref: sampleType
        }
      }
    );

    // if (result.data.length > 0) {
    //   setPrevComments(result.data);
    // }
    console.log("RRRR", result);
    setPrevComments(result.data);
  };

  const updateSample = () => {
    if (choosedSampleStatus == sampleStatus && !comment) {
      return;
    }

    if (isUpdated) {
      return;
    }

    samples[0].sampleStatus = choosedSampleStatus;

    // patch here
    axios.patch(`${eq}/sample`, samples[0]).then(res => {

      if (comment) {
        // post commit here
        const request = {
          comment,
          designId,
          moduleRef: sampleType,
          moduleType: 1
        };

        axios
          .post(`${get_service_endpoint("notificationView")}/comments`, request)
          .then(res => console.log("RESPONSE FROM POST COMMMNETS ", res));
      }
      setIsUpdated(true);
      setShowPrevComments(false);
      setcomment("");
      getCommentsHandler();
    });
    // also update comments
  };

  return (
    <div
      style={{ paddingTop: "10px", position: "relative", marginBottom: "50px" }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",

          height: "64px",
          padding: "0px 16px"
        }}
      >
        <div style={{ fontSize: "12px", color: "var(--fill-grey-medium)" }}>
          Sample Type
        </div>
        <div style={{ textAlign: "right" }}>
          <div
            style={{
              fontSize: "14px",
              fontWeight: "600",
              color: "var(--fill-locofast-black)"
            }}
          >
            {sampleType}
          </div>
          <div
            style={{
              fontSize: "12px",
              color: "var(--fill-grey-medium)",
              lineHeight: "20px"
            }}
          >{`Requested on ${moment(createdAt).format("MMM DD, YYYY")}`}</div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",

          height: "48px",
          padding: "0px 16px"
        }}
      >
        <div style={{ fontSize: "12px", color: "var(--fill-grey-medium)" }}>
          Sample Size
        </div>
        <div
          style={{
            fontSize: "14px",
            fontWeight: "600",
            color: "var(--fill-locofast-black)"
          }}
        >
          {sampleSize}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",

          height: "64px",
          padding: "0px 16px"
        }}
      >
        <div style={{ fontSize: "12px", color: "var(--fill-grey-medium)" }}>
          Sample Status
        </div>
        <div style={{ textAlign: "right" }}>
          <div
            style={{
              fontSize: "14px",
              fontWeight: "600",
              color: "var(--fill-locofast-black)"
            }}
          >
            <Dropdown
              options={_.orderBy(
                options.filter(option => option.label !== sampleStatus),
                ["value"],
                ["asc"]
              )}
              onChange={({ label }) => {
                setChoosedSampleStatus(label);
                setIsUpdated(false);
              }}
              value={choosedSampleStatus ? choosedSampleStatus : sampleStatus}
              arrowClassName={"notification_sampling_arrow"}
              controlClassName={"notification_sampling_control"}
              placeholderClassName={"notification_sampling_placeholder"}
              placeholder="Select status"
              className={"notification_sampling_class"}
              menuClassName={"notification_sampling_menu"}
              style={{ cursor: "pointer" }}
              disabled={
                sampleStatus === APPROVED
              }
            />
          </div>
          <div
            style={{
              fontSize: "12px",
              color: "var(--fill-grey-medium)",
              lineHeight: "20px"
            }}
          ></div>
          {/* {`Updated on ${moment(updatedAt).format("MMM DD, YYYY")}`} */}
        </div>
      </div>

      <div style={{ margin: "14px" }}>
        <div
          style={{
            fontSize: "14px",
            fontWeight: "600",
            color: "var(--fill-locofast-black)",
            lineHeight: "25px"
          }}
        >
          Comments{" "}
          <span
            style={{
              fontSize: "10px",
              color: "var(--fill-grey-medium)"
            }}
          >
            (optional)
          </span>
        </div>
        <textarea
          name="comments"
          id="comment"
          cols="30"
          rows="10"
          placeholder="Write comment."
          style={{
            padding: "10px",
            width: "100%",
            color: "var(--fill-locofast-black)",
            border: "1px solid var(--fill-grey-light)",
            outline: "none",
            fontSize: "12px"
          }}
          onChange={ev => {
            setcomment(ev.target.value);
            setIsUpdated(false);
          }}
          value={comment}
        />
      </div>

      {/* == COMMENTS SECTION == */}
      {/* <div style={{ padding: "8px 16px" }}>
        {prevComments.length && !showPrevComments ? (
          <div
            style={{
              textAlign: "right",
              color: "var(--fill-green-dark)",
              fontSize: "12px",
              cursor: "pointer"
            }}
            onClick={() => {
              getCommentsHandler();
              setShowPrevComments(true);
            }}
          >
            Load previous comments
          </div>
        ) : null}

        {showPrevComments
          ? prevComments.map(({ comment, createdAt }) => (
              <div>
                <div
                  style={{
                    fontSize: "12px",
                    color: "var(--fill-grey-dark)",
                    width: "80%",
                    paddingBottom: "5px"
                  }}
                >
                  {comment}
                </div>
                <div
                  style={{
                    fontSize: "12px",
                    color: "var(--fill-grey-light)",
                    textAlign: "right",
                    padding: "8px 0px"
                  }}
                >
                  <i>{moment(createdAt).format("MMMM DD, YYYY")}</i>
                </div>
              </div>
            ))
          : null}
      </div> */}
      {/* == COMMENTS SECTION == */}

      {/* UPDATE BUTTON  */}
      <div
        style={{
          position: "fixed",
          right: 0,
          bottom: 0,
          height: "48px",
          borderTop: "1px solid #f5f5f5",
          color: isUpdated
            ? "var(--fill-green-dark)"
            : choosedSampleStatus == sampleStatus && !comment
            ? "var(--fill-grey-medium)"
            : "var(--fill-secondary-dark)",
          fontSize: "14px",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          letterSpacing: "1px",
          backgroundColor: "#fff",
          fontWeight: 600,

          cursor: "pointer",
          zIndex: 999999
        }}
        onClick={updateSample}
      >
        {isUpdated ? "UPDATED" : "UPDATE"}
      </div>
    </div>
  );
};

// == Options for sample status dropdown ==
const options = [
  { value: 2, label: APPROVED },
  { value: 3, label: RESUBMIT },
  { value: 4, label: "Design canceled" }
];

export default withRouter(SamplingDetails);
