import React, { useState, useEffect } from "react";
import moment from "moment";
import { Link } from "react-router-dom";

import homeImg from "assets/images/dashboard/home.svg";
import factoriesImg from "assets/images/dashboard/factories.svg";
import { Header } from "./Header";
import { Pagination } from "./Pagination";
import { TextSpliced } from "components/UI/TextSpliced";
import EmptyScreen, { Horizontal } from "../UI/EmptyScreen/index.jsx";

import {
  SAMPLING_FILTERS,
  PRODUCTION_FILTERS
} from "constants/Design";

const ActiveFactories = ({ activeFactories = [] }) => {
  const fetch_limit = 7;
  const [factoriesList, setFactoriesList] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    setFactoriesList(
      activeFactories.slice(
        currentIndex * fetch_limit,
        (currentIndex + 1) * fetch_limit
      )
    );
  }, [activeFactories, currentIndex]);

  return (
    <div className="active-design active-factory">
      <div className="active-design-conatiner">
        <div className="design-conatiner-heading">
          <img src={homeImg} className="active-icon" alt="factories" /> Active
          factories
        </div>
      </div>
      <div className="design-active-table">
        <Header
          headings={["Factory", "Sampling", "Production", "Last activity"]}
        />
        <div className="design-table-section">
          {factoriesList.length > 0 ? (
            factoriesList.map(
              ({
                factory_name,
                factory_id,
                designs_in_sampling_count,
                designs_in_production_count,
                factory_last_activity_on
              }) => (
                <div className="design-table-row">
                  <TextSpliced
                    className="col-title blu-title wid-220"
                    text={factory_name}
                    maxWidth="220"
                  />
                  <div className="col-title">
                    {designs_in_sampling_count ? (
                      <Link
                        to={{
                          pathname: "../sampling/in-progress/",
                          pathFilters: {
                            [SAMPLING_FILTERS.factory]:  factory_id
                          }
                        }}
                        className="blue-on-hover"
                      >
                        {designs_in_sampling_count}
                      </Link>
                    ) : (
                      "-"
                    )}
                  </div>
                  <div className="col-title">
                  {designs_in_production_count ? (
                      <Link
                        to={{
                          pathname: "../production/in-progress/",
                          pathFilters: {
                            [PRODUCTION_FILTERS.factory]:  factory_id
                          }
                        }}
                        className="blue-on-hover"
                      >
                        {designs_in_production_count}
                      </Link>
                    ) : (
                      "-"
                    )}
                  </div>
                  <div className="col-title">
                    {factory_last_activity_on
                      ? moment(factory_last_activity_on).fromNow()
                      : "-"}
                  </div>
                </div>
              )
            )
          ) : (
            <EmptyScreen
              icon={factoriesImg}
              iconSize="72px"
              orientation={Horizontal}
              heading="No active factories"
              description={
                <>
                  None of the factories are <br />
                  working on any design
                </>
              }
            />
          )}
        </div>
        <Pagination
          data={{
            totalCount: activeFactories.length,
            limit: fetch_limit
          }}
          onChange={(value, index) => setCurrentIndex(index)}
          currentIndex={currentIndex}
          entity={{ singular: "factory", plural: "factories" }}
        />
      </div>
    </div>
  );
};

export default ActiveFactories;
