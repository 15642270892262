import React from "react";
import emptyIcon from "assets/images/emptyPages/ic-tna-empty.svg";
import EmptyScreen from "components/UI/EmptyScreen";

const TnaAwaited = () => (
  <EmptyScreen
    icon={emptyIcon}
    heading="TNA requested"
    description={<>
      You have requested the production <br />
      plan. Factory will share the plan shortly.
    </>}
  />
);

export default TnaAwaited;
