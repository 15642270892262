import React from "react";

import dispatchImg from "assets/images/tna/ic_dispatch.svg";

const SuccessState = ({ link, closeModal, brandName }) => (
  <section className="success-state">
    <img src={dispatchImg} alt="dispatch success" />
    <h4>Done!</h4>
    <p>Dispatch details shared with {brandName}.</p>
  </section>
);

export default SuccessState;
