import React, { Component } from "react";
import { default as ReactSelect } from "react-select";

import "./styles.scss";

const Select = ({
  input,
  options,
  label,
  required,
  placeholder,
  form: { touched, errors, setFieldValue },
  field,
  isMulti,
  limit=1,
  defaultValue,
  ...props
}) => {
  const { name,value } = field;
  if(!value && defaultValue){
    setFieldValue(name, defaultValue)
  }
  return (
    <div className="formik_select">
      <label>
        {label}
        {required && <sup>*</sup>}
      </label>
      <ReactSelect
        placeholder={placeholder}
        options={isMulti && value ? value.length === limit ? [] : options : options}
        onChange={selection => {
          if(isMulti){
            const mergedSizes = selection.slice(0,limit).map(selection=> selection.value);
            setFieldValue(name, mergedSizes);
          } else {
            setFieldValue(name, selection.value);
          }
        }}
        isMulti={isMulti}
        defaultValue={defaultValue}
        {...props}
      />
      {touched[field.name] &&
        errors[field.name] && <div className="error">{errors[field.name]}</div>}
    </div>
  );
};

export default Select;

