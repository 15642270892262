import React from 'react';
import PropTypes from 'prop-types';
import { ORG_TYPES, ORG_TYPE_NAMES } from '../../../../constants/Global';
import './style.scss';

const MAP_ORG_MSG = {
  [ORG_TYPES.brand]: "You already have selected a factory. Your current selection will be lost if you change the brand.",
  [ORG_TYPES.factory]: "You already have selected designs from another factory. Your current selections will be lost if you change the factory.",
}

export default class ChangeOrgModal extends React.Component {
  static propTypes = {
    orgType: PropTypes.oneOf(ORG_TYPES),
  };

  constructor(props) {
    super(props);
    this._updateTexts(props);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.orgType !== nextProps.orgType) {
      this._updateTexts(nextProps);
    }
  }

  _updateTexts(props) {

    const orgTypeName = ORG_TYPE_NAMES[props.orgType];

    if (props.setHeaderTitle) {
      props.setHeaderTitle(`Change ${orgTypeName}`);
    }

    if (props.setFooter) {
      props.setFooter(`YES, CHANGE ${orgTypeName}`);
    }
  }

  render() {
    const { orgType } = this.props;

    return <div
      className="orders-change-org-modal"
    >
      <span className="f-color-faded">
        {MAP_ORG_MSG[orgType]}
      </span>
    </div>
  }
}
