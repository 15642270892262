import React from "react";
import { Divider } from "antd";
import AddressTemplate from "./addressTemplate";
import "./styles.scss";
const Address = ({ customer }) => {
  const defaultAddress = [];
  const otherAddress = [];
  if (customer?.otherAddresses?.length > 0) {
    let address = customer?.otherAddresses?.forEach((address, index) => {
      if (address?.isDefault) {
        defaultAddress.push(address);
      } else {
        otherAddress.push(address);
      }
    });
  }

  return (
    <>
      {customer && (
        <div className="basicInfocontainer">
          <h3 className="basicHeader">Address</h3>
          <p>Default Address</p>
          {defaultAddress.map((address, index) => (
            <AddressTemplate address={address} key={index} />
          ))}
          <p>Other Address</p>
          {otherAddress.map((address, index) => (
            <AddressTemplate address={address} key={index} />
          ))}
        </div>
      )}
      <Divider />
    </>
  );
};

export default Address;
