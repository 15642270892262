import React, { Component } from "react";
import PropTypes from "prop-types";
import FloatingCTA from "../../components/UI/FloatingCTA/FloatingCTA";
import moment from "moment";
import { TIMESTAMP_FORMAT, DATESTAMP_FORMAT } from "../../constants/Date";
import _ from "lodash";
import {
  getCoverImagePlaceholder,
} from "../../helpers/DesignHelpers";
// https://github.com/adazzle/react-data-grid/issues/356#issuecomment-400906447
const ReactDataGrid = require("react-data-grid");

const EmptyRowsView = () => {
  const message = "No notifications to show";
  return (
    <div
      style={{
        textAlign: "center",
        padding: "100px"
      }}>
      <h3>{message}</h3>
    </div>
  );
};

export default class NotificationList extends Component {
  static propTypes = {
    prop: PropTypes
  };

  static defaultProps = {
    selectionDisable: false
  };

  columns = [
    { key: "designName", name: "Design",
      formatter: data => {
        const designImage = _.get(data.row, "imageUrl")
        return (
          <div style={{display: 'flex', alignItems: 'center'}}>
            { this.props.notificationType !== 'pending' &&  
              <span
                style={{
                backgroundImage: `${ designImage? 'url(' + designImage + ')' : 'url(' + getCoverImagePlaceholder({category: _.get(data.row, "designCategory")}).src + ')'}`,
                backgroundSize: "cover",
              }}
              className="design-icon"
            >
              <span />
            </span>
            }
            <span style={{ paddingLeft: '10px'}}>
            {_.get(data, "value")}
            </span>
          </div>
        )
      }
    },
    { key: "collectionName", name: "Collection" },
    { key: "stage", name: "Stage" },
    {
      key: "subCategory",
      name: "Sub-category",
      formatter: data => (
        <div style={{ textTransform: "capitalize" }}>
          {_.get(data, "value", "") !== null
            ? _.get(data, "value", "").toLowerCase()
            : ""}
        </div>
      )
    },
    {
      key: "timestamp",
      name: "Timestamp",
      // width: 128,
      // sortable: true,
      formatter: ({ value }) =>
        value ? moment(value).format(TIMESTAMP_FORMAT) : null
    },
    {
      key: "status",
      name: "Status",
      width: 60,
      formatter: data => twoRowCell(data, "underline")
    },
    {
      key: "brandResponse",
      name: "Brand Response",
      formatter: data => twoRowCell(data)
    }
  ].map(col => ({
    ...col,
    ...DefaultColumnProperties
  }));

  state = {
    selectedIndexes: [],
    filterType: this.props.notificationType
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.rows !== nextProps.rows) {
      this.setState({ selectedIndexes: [] });
    }
  }

  formatRow(row) {
    const newRow = row;

    return newRow;
  }

  render() {
    const { rows, onGridSort, selectionDisable } = this.props;
    return (
      <React.Fragment>
        <ReactDataGrid
          emptyRowsView={EmptyRowsView}
          rowsCount={rows.length}
          columns={this.columns}
          rowGetter={i => ({ ...rows[i], rowIndex: i })}
          rowHeight={80}
          headerRowHeight={48}
          onRowClick={(rowId, row, column) => {
            // Return if column not defined
            // When clicked on header row
            if (!column) {
              return;
            }
            if (column.name === "Status") {
              if (
                row[column.key].heading === "Sent" &&
                this.props.openSentToModal
              ) {
                this.props.openSentToModal(row);
              }
            }
          }}
          rowSelection={{
            onRowsSelected: rows => {
              this.setState({
                selectedIndexes: [
                  ...this.state.selectedIndexes,
                  ...rows.map(r => r.rowIdx)
                ]
              });
            },
            onRowsDeselected: rows => {
              this.setState({
                selectedIndexes: this.state.selectedIndexes.filter(
                  i => rows.map(r => r.rowIdx).indexOf(i) === -1
                )
              });
            },
            showCheckbox: !selectionDisable,
            selectBy: {
              indexes: this.state.selectedIndexes
            }
          }}
          //   enableCellAutoFocus={false}
          onGridSort={onGridSort}
        />
        {this.state.selectedIndexes.length > 0 && (
          <FloatingCTA
            onClick={() => {
              this.props.sendNotifications(
                this.state.selectedIndexes.map(i => this.props.rows[i])
              );
            }}>
            Send
          </FloatingCTA>
        )}
      </React.Fragment>
    );
  }
}

const twoRowCell = (data, extraClass = "") => {
  //   console.log(data);
  const { heading, subHeading } = data.value;
  return (
    <div className={extraClass} style={{ whiteSpace: "pre-wrap" }}>
      <div>{heading}</div>
      <div>{subHeading}</div>
    </div>
  );
};
const DefaultColumnProperties = {
  // sortable: true
  // headerRenderer: ({ column: { name } }) => {
  //   // console.log("HEADER DATA", name);
  //   return <div className="factory-notif-dash__table-header">{name}</div>;
  // }
};


const DispatchColumns = [
  {
    key: "poNumber",
    name: "PO Number",
    formatter: data => {
      return (
        <div className="flex-col">
          <span>{data.value}</span>
          <a
            href={data.row.poFile}
            style={{
              marginTop: "5px",
              cursor: "pointer",
              textDecoration: "underline"
            }}>
            (download PO)
          </a>
        </div>
      );
    }
  },
  {
    key: "productsCount",
    name: "Order Quantity",
    formatter: data =>
      `${data.row.totalQuantity} (${data.row.productsCount ||
        data.row.numberOfDesigns} designs)`
  },
  {
    key: "poDate",
    name: "PO Date",
    formatter: ({ value }) => moment(value).format(DATESTAMP_FORMAT)
  },
  {
    key: "dispatchDate",
    name: "Dispatch Date",
    formatter: ({ value }) =>
      value ? moment(value).format(DATESTAMP_FORMAT) : "Dispatch pending"
  },
  {
    key: "city",
    name: "City"
  },
  {
    key: "totalQuantity",
    name: "Dispatched Quantity & Boxes",
    formatter: data =>
      `${data.value || data.row.quantity} ${
        data.row.numberOfBoxes ? `(${data.row.numberOfBoxes} boxes)` : ""
      }`
  },
  {
    key: "trackingCode",
    name: "Tracking ID and Courier Partner",
    formatter: data =>
      data.value
        ? `${data.value} Shipped by ${data.row.courierPartner}`
        : "Details not available"
  },
  {
    key: "ewayBillFile",
    name: "Important Documents",
    // width: '150px',
    formatter: ({
      value,
      row: { ewayBillFile, challanFile, invoiceFile, packingListFile }
    }) =>
      value ? (
        <div className="flex-col" style={{ textDecoration: "underline" }}>
          <a
            href={ewayBillFile}
            style={{ marginBottom: "3px", cursor: "pointer" }}>
            E-Way bill
          </a>
          <a
            href={challanFile}
            style={{ marginBottom: "3px", cursor: "pointer" }}>
            Challan
          </a>
          <a
            href={invoiceFile}
            style={{ marginBottom: "3px", cursor: "pointer" }}>
            Invoice
          </a>
          <a
            href={packingListFile}
            style={{ marginBottom: "3px", cursor: "pointer" }}>
            Packing List
          </a>
        </div>
      ) : (
        "No files attached"
      )
  }
];

export class DispatchNotificationList extends Component {
  static propTypes = {
    prop: PropTypes
  };

  static defaultProps = {
    selectionDisable: false
  };

  state = {
    selectedIndexes: []
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.rows !== nextProps.rows) {
      this.setState({ selectedIndexes: [] });
    }
  }

  formatRow(row) {
    const newRow = row;

    return newRow;
  }

  render() {
    const { rows, onGridSort, selectionDisable } = this.props;
    return (
      <React.Fragment>
        <div className="notification-dispatch__container">
          <ReactDataGrid
            emptyRowsView={EmptyRowsView}
            rowsCount={rows.length}
            columns={DispatchColumns}
            rowGetter={i => ({ ...rows[i], rowIndex: i })}
            rowHeight={80}
            headerRowHeight={48}
            // onRowClick={(rowId, row, column) => {
            //   // Return if column not defined
            //   // When clicked on header row
            //   if (!column) {
            //     return;
            //   }
            //   if (column.name === "Status") {
            //     if (
            //       row[column.key].heading === "Sent" &&
            //       this.props.openSentToModal
            //     ) {
            //       this.props.openSentToModal(row);
            //     }
            //   }
            // }}
            rowSelection={{
              onRowsSelected: rows => {
                this.setState({
                  selectedIndexes: [
                    ...this.state.selectedIndexes,
                    ...rows.map(r => r.rowIdx)
                  ]
                });
              },
              onRowsDeselected: rows => {
                this.setState({
                  selectedIndexes: this.state.selectedIndexes.filter(
                    i => rows.map(r => r.rowIdx).indexOf(i) === -1
                  )
                });
              },
              showCheckbox: !selectionDisable,
              selectBy: {
                indexes: this.state.selectedIndexes
              }
            }}
            //   enableCellAutoFocus={false}
            onGridSort={onGridSort}
          />
          {this.state.selectedIndexes.length > 0 && (
            <FloatingCTA
              onClick={() => {
                this.props.sendNotifications(
                  this.state.selectedIndexes.map(i => this.props.rows[i])
                );
              }}>
              Send
            </FloatingCTA>
          )}
        </div>
      </React.Fragment>
    );
  }
}
