import React from "react";
import { Row, Col, Input, Divider } from "antd";
import "./styles.scss";
import { UNDEFINED_VALUE } from "../../constants";
const VirtualPayment = ({vpa}) => {
  const {bankAccountDetails:{accountNumber, name, ifsc, bankName},upiDetails:{address}}= vpa || {bankAccountDetails:{accountNumber:'', name:'', ifsc:'', bankName:''},upiDetails:{address:''}};
  const getValue=(value)=>{
    return value? value: UNDEFINED_VALUE
  }
  return (
    <>
      <div className="basicInfocontainer">
        <div className="basicHeader">
           <div>Virtual Payment and Account Details</div>
        </div>
       
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <div className="element">
              <label className="label">Accont Number</label>
              <Input className="inputFormikClear" placeholder="Account Number" value={getValue(accountNumber)} disabled/>
            </div>
            <div className="element">
              <label className="label">Account Name</label>
              <Input className="inputFormikClear" placeholder="Account Name" value={getValue(name)} disabled/>
            </div>
            <div className="element">
              <label className="label">IFSC Code</label>
              <Input className="inputFormikClear" placeholder="IFSC" value={getValue(ifsc)} disabled/>
            </div>
          </Col>
          <Col span={12}>
            <div className="element">
              <label className="label">UPI ID</label>
              <Input className="inputFormikClear" placeholder="upi id" value={getValue(address)} disabled/>
            </div>
            <div className="element">
              <label className="label">Bank Name</label>
              <Input className="inputFormikClear" placeholder="bank name" value={getValue(bankName)} disabled/>
            </div>
          </Col>
        </Row>
      </div>
      <Divider />
    </>
  );
};

export default VirtualPayment;
