import React from "react";
import moment from "moment";

import { ProfileAvatar } from "../../UI/ProfileAvatar/index";
import icFilePlaceholder from "../../../assets/images/file/ic-file-placeholder.svg";

import { TIMESTAMP_FORMAT } from "constants/Date";
import { getBgUrl, checkPic } from "helpers/DesignHelpers";

export const LogItem = ({ logData }) => {
  const { userImage, data, timestamp, userName } = logData;
  const parsedData = JSON.parse(data);
  const { description, title, metadata, type } = parsedData;
  const isPic = (metadata && checkPic(metadata.fileloc)) || false;
  const isValidDownLink =
    (metadata && metadata.fileloc && metadata.fileloc.startsWith("http")) ||
    false;

  return (
    <div className="log-container-details">
      <div className="container-details-left">
        <ProfileAvatar url={userImage} name={userName} size={32} />
      </div>
      <div className="container-details-right">
        <p className="title">
          {description}.
          <span className="date">
            {moment(timestamp).format(TIMESTAMP_FORMAT)}
          </span>
        </p>
        {title === "File" &&
          metadata &&
          (metadata.is_image || isPic ? (
            <div className="container-right-img">
              <img src={metadata.fileloc} className="attached-img" alt="" />
              <div className="dot-text">
                {metadata.file_title} - {metadata.file_category}
                {type !== "delete" && (
                  <span className="dot">
                    <a
                      className="link-hover"
                      href={isValidDownLink ? metadata.fileloc : ""}
                    >
                      Download
                    </a>
                  </span>
                )}
              </div>
            </div>
          ) : (
            <div className="container-details-pdf">
              <div className="pdf-img-box">
                <img
                  src={
                    type === "delete"
                      ? icFilePlaceholder
                      : getBgUrl(metadata.fileloc)
                  }
                  className="pdf-img"
                  alt=""
                />
              </div>
              <div className="pdf-title">
                <p className="pdf-title-sub">{metadata.file_category}</p>
                <div className="dot-text">
                  <span className="file_title">{metadata.file_title}</span>
                  {type !== "delete" && (
                    <>
                      <span className="dot"></span>
                      <span className="action-download">
                        <a
                          className="link-hover"
                          href={isValidDownLink ? metadata.fileloc : ""}
                        >
                          Download
                        </a>
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
