import React, { Component } from "react";
import PropTypes from 'prop-types';
import CustomInput from "../../../UI/Form/Input/CustomInput";
import './index.scss';

export default class BundledQuote extends Component {
  static propTypes = {
    quoteDetails: PropTypes.object.isRequired,
    currency: PropTypes.string.isRequired,
    readOnly: PropTypes.bool.isRequired,
    updateEditedQuote: PropTypes.func.isRequired,
  };

  _handleQuoteChange = (name, value) => {
    const { updateEditedQuote } = this.props;

    updateEditedQuote({
      quoteDetails: {
        ...this.props.quoteDetails,
        [name]: value,
        totalCost: value,
      }
    });
  };

  render() {
    const { quoteDetails, currency, readOnly } = this.props;
    const price = (quoteDetails["price_per_garment"]);

    return (
      <div class="bundled-quote-container">
        <div
          className="flex"
          style={{
            alignItems: 'center',
            height: 'inherit'
          }}
        >
          <div className="cell-v2 flex-grow">
            <div>
              Price per garment
            </div>
            <div>
              Quote is exclusive of local taxes
            </div>
          </div>
          {readOnly
            ? (
              <div className="submitted-quote">
                {currency} {((+price).toFixed(2))||'--'}
              </div>
            )
            : (
              <>
                <div className="flex input-box">
                  <CustomInput
                    prefixText={currency}
                    gluePrefix={true}
                    disabled={readOnly}
                    value={price}
                    onChange={evt => {
                      this._handleQuoteChange("price_per_garment", evt.target.value);
                    }}
                    id="price_per_garment"
                    autoFocus
                  />
                </div>
              </>
            )
          }

        </div>
      </div>
    );
  }
}
