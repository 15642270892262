import sortBy from "lodash/sortBy";
import find from "lodash/find";
import remove from "lodash/remove";
import isEmpty from "lodash/isEmpty";
import moment from "moment";

import { RESUBMIT } from "constants/Sample";
import { TIMESTAMP_FORMAT } from "constants/Date";

const transformDispatchData = dispatchData => {
  const tempDispatchData = Object.assign({}, dispatchData);
  tempDispatchData.updatedBy = dispatchData.createdBy;
  tempDispatchData.sampleStatus = "Dispatched";
  tempDispatchData.remarks = {
    comment: `Sample dispatched via ${
      dispatchData.courierPartner
    } with tracking ID ${dispatchData.trackingNumber} on ${moment(
      dispatchData.sentDate
    ).format(TIMESTAMP_FORMAT)}`
  };
  return tempDispatchData;
};

export const transformHistory = (
  samplingHistory,
  sampleType,
  comments,
  sampleDispatch
) => {
  /***
   *  Merge sample dispatch data along with the sampling version history
   *  cases:
   *    - Sample status - if Requested -- Nothing to add
   *                    - else if other stage -- Add the remarks as well as create new row for the dispatch data
   */
  const modifiedVersionDetails = [];
  let tempIndex = 0;

  const commentsCopy = sortBy(comments, "createdAt").reverse();

  // let commentsIndex = 0;
  // const filteredComments = comments.filter(({moduleStatus}) => moduleStatus == )
  samplingHistory[sampleType].forEach((item, index) => {
    if (item.sampleStatus !== "Requested") {
      // If re-submit then, we assume there must be a dispatch data associated with it.
      if (item.sampleStatus === RESUBMIT) {
        if (index === 0 && find(sampleDispatch, { isActive: true })) {
          const dispatch = Object.assign({}, sampleDispatch[tempIndex++]);
          const transformedDispatchData = transformDispatchData(dispatch);
          modifiedVersionDetails.push(transformedDispatchData);
        }
      }
      item.updatedBy = item.createdBy;
      const dispatchData = Object.assign({}, sampleDispatch[tempIndex++]);
      if (comments.length > 0) {
        const statusComment = find(commentsCopy, {
          moduleStatus: item.sampleStatus,
          moduleId: item.sampleId || item.id
        });
        if (statusComment) {
          item.remarks = {
            comment: statusComment.comment
          };
          remove(commentsCopy, { id: statusComment.id });
        }
      }
      if (!isEmpty(dispatchData)) {
        const transformedDispatchData = transformDispatchData(dispatchData);
        modifiedVersionDetails.push(item, transformedDispatchData);
      } else {
        modifiedVersionDetails.push(item);
      }
    } else {
      item.updatedBy = item.createdBy;
      modifiedVersionDetails.push(item);
    }
  });

  // Case when sample requested, dispatched but status not updated by the brand
  if (samplingHistory[sampleType].length === 1 && sampleDispatch.length > 0) {
    const dispatchData = Object.assign({}, sampleDispatch[tempIndex++]);
    if (!isEmpty(dispatchData)) {
      dispatchData.updatedBy = dispatchData.createdBy;
      dispatchData.sampleStatus = "Dispatched";
      dispatchData.remarks = {
        comment: `Sample dispatched via ${
          dispatchData.courierPartner
        } with tracking ID ${dispatchData.trackingNumber} on ${moment(
          dispatchData.sentDate
        ).format(TIMESTAMP_FORMAT)}`
      };
      modifiedVersionDetails.unshift(dispatchData);
    }
  }
  return modifiedVersionDetails;
};
