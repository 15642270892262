import React from "react";
import ToggleSwitch from ".";

const NotificationToggle = props => {
  const { hideText=false, setLeftBuffer=0 } = props
  return (
    <ToggleSwitch {...props} style={{ ...props.style, width: "180px", alignItems: "center" }}>
      {({ enabled }) => {
        return (
          <div
            style={{
              marginRight: "auto",
              fontSize: "14px",
              color: "var(--fill-grey-dark)",
              lineHeight: "1.43",
              whiteSpace: "nowrap",
              userSelect: "none",
              cursor: "pointer",
              marginLeft: {setLeftBuffer}
            }}
            className="f-w-semi-bold"
          >
            {hideText ? null : `Notifications (${enabled ? "ON" : "OFF"})`}
          </div>
        );
      }}
    </ToggleSwitch>
  );
};

export default NotificationToggle;
