import React, { useEffect, useState } from "react";
import PerfectScrollBar from "react-perfect-scrollbar";
import axios from "axios";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { get_service_endpoint } from "../../../ServiceEndpoints";
import moment from "moment";
import { TIMESTAMP_FORMAT } from "../../../constants/Date";
import actions from "../../../store/actions";
import { computePath } from "../../../helpers/pathHelper";

const newEp = get_service_endpoint("notificationView");
const urlLink = window.location.origin;

const NotificationItem = ({
  data: {
    createdBy: { role },
    title,
    createdAt,
    userImage = false,
    notificationRoot,
    collection,
    design,
    stage,
    brand: { name, id: brandId },
    isRead,
    id
  },
  isFactory,
  dispatch
}) => {
  const notificationIcon = require(`../../../assets/images/Notifications/${stage}.svg`);
  const path = computePath();
  var tab = 'summary'
  var keywords = title.match(/sampling|quote|new comment/g);
  if (keywords && keywords.length > 0) {
    tab = keywords[0].replace('new comment', 'messages')
  }

  const nameInitials = name.split(' ')
    .map(item => item.match(/\w/g))
    .filter(res => res && res.length > 0)
    .slice(0, 4)
    .map(res => res[0]);

  return notificationRoot ? (
    <div
      onClick={() => {
        dispatch(actions.closeModal());
      }}
      style={isRead ? { opacity: 0.7 } : {}}
    >
      <a
        href={`${urlLink}/notification/${notificationRoot}/design/`}
        target={"_blank"}
      >
        <div
          style={{
            display: "flex",
            padding: "12px 16px",
          }}
        >
          <div style={{ width: "60px" }}>
            <div
              style={{
                width: "42px",
                height: "42px",
                borderRadius: "100px",
                //   overflow: "hidden",
                backgroundColor: "var(--fill-grey-light5)",

                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: 600,
                fontSize: "12px",
                letterSpacing: "2px",
                color: "var(--fill-secondary-medium)",

                position: "relative"
              }}
            >
              {userImage ? (
                <img src={userImage} style={{ width: '100%', height: '100%', borderRadius: '50%' }} alt="LF" />
              ) : (
                  <div style={{ textTransform: "uppercase" }}>
                    {nameInitials}
                  </div>
                )}

              <img
                // src={getNotificationTypeIcon({ stage })}
                src={notificationIcon}
                alt="LF"
                style={{
                  position: "absolute",
                  bottom: -4,
                  right: -4,
                  width: "20px",
                  height: "20px",
                  borderRadius: "100px",
                  backgroundColor: "#ccc"
                }}
              />
            </div>
          </div>
          <div style={{ flex: 1 }}>
            <div
              className="value__class"
              style={{
                lineHeight: "16px",
                letterSpacing: "0.2px",
                fontWeight: 300,
                fontSize: "12px"
              }}
            >
              {title}
            </div>
            <div
              className="label__class"
              style={{ paddingTop: "6px", fontSize: "10px" }}
            >
              {moment(createdAt).format(TIMESTAMP_FORMAT)}
              <span
                style={{
                  // padding: "2px 6px",
                  backgroundColor: "var(--fill-primary-light)",
                  // color: "#fff",
                  borderRadius: "50px",
                  marginLeft: "12px",
                  textTransform: "capitalize"
                }}
              >
                {isFactory && role !== "1" ? (
                  <span style={{ padding: "0px 6px" }}>{name}</span>
                ) : role === "1" ? (
                  <span style={{ padding: "0px 6px" }}>LOCOFAST</span>
                ) : null}
              </span>
            </div>
          </div>
        </div>
      </a>
    </div>
  ) : (
      <div
        onClick={() => {
          dispatch(actions.closeModal());
        }}
        style={isRead ? { opacity: 0.7 } : {}}
      >
        <Link
          to={
            path == "brand"
              ? `/brand/collections/${collection}/designs/${design}/`
              : `/${path}/brands/${brandId}/collections/${collection}/designs/${design}/?tab=${tab}`
          }
        >
          <div
            style={{
              display: "flex",
              padding: "12px 16px"
            }}
          >
            <div style={{ width: "60px" }}>
              <div
                style={{
                  width: "42px",
                  height: "42px",
                  borderRadius: "100px",
                  //   overflow: "hidden",
                  backgroundColor: "var(--fill-grey-light5)",

                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: 600,
                  fontSize: "12px",
                  letterSpacing: "2px",
                  color: "var(--fill-secondary-medium)",

                  position: "relative"
                }}
              >
                {userImage ? (
                  <img src={userImage} style={{ width: '100%', height: '100%', borderRadius: '50%' }} alt="LF" />
                ) : (
                    <div style={{ textTransform: "uppercase" }}>
                      {nameInitials}
                    </div>
                  )}

                <img
                  src={notificationIcon}
                  alt="LF"
                  style={{
                    position: "absolute",
                    bottom: -4,
                    right: -4,
                    width: "20px",
                    height: "20px",
                    borderRadius: "100px",
                    backgroundColor: "#ccc"
                  }}
                />
              </div>
            </div>
            <div style={{ flex: 1 }}>
              <div
                className="value__class"
                style={{
                  lineHeight: "16px",
                  letterSpacing: "0.2px",
                  fontWeight: 300,
                  fontSize: "12px"
                }}
              >
                {title}
              </div>
              <div
                className="label__class"
                style={{ paddingTop: "6px", fontSize: "10px" }}
              >
                {moment(createdAt).format(TIMESTAMP_FORMAT)}
                <span
                  style={{
                    // padding: "2px 6px",
                    backgroundColor: "var(--fill-primary-light)",
                    // color: "#fff",
                    borderRadius: "50px",
                    marginLeft: "12px",
                    textTransform: "capitalize"
                  }}
                >
                  {isFactory && role !== "1" ? (
                    <span style={{ padding: "0px 6px" }}>{name}</span>
                  ) : role === "1" ? (
                    <span style={{ padding: "0px 6px" }}>LOCOFAST</span>
                  ) : null}
                </span>
              </div>
            </div>
          </div>
        </Link>
      </div>
    );
};

const NotificationModel = ({
  userDetails,
  isFactory = false,
  brandId,
  usersList,
  dispatch,
  ...restProps
}) => {
  const [notifications, setNotifictions] = useState(null);

  useEffect(() => {
    if (isFactory) {
      getNotificationsHandler(null);
    }
  }, []);

  useEffect(() => {
    let currentBrandId = null;
    if (!isFactory) {
      let userId = userDetails.id;
      Object.keys(usersList).forEach(key => {
        if (userId == key) {
          currentBrandId = usersList[key]["brand"];
        }
      });

      //    fetch notificatios
      getNotificationsHandler(currentBrandId);
    }
  }, [usersList]);

  const getNotificationsHandler = brand => {
    axios
      .get(`${newEp}/user/notifications`, {
        params: { brandid: brand, isLoader: true }
      })
      .then(res => {
        setNotifictions(res.data);
      });
  };

  if (!notifications) {
    return (
      <div
        className="value__classkk"
        style={{ padding: "80px 32px", textAlign: "center" }}
      >
        Loading...
      </div>
    );
  }

  if (!notifications.length) {
    return (
      <div
        className="value__class"
        style={{ padding: "80px 32px", textAlign: "center" }}
      >
        No notifications.
      </div>
    );
  }
  return (
    <div style={{}} className="message__pick">
      <PerfectScrollBar style={{ maxHeight: "75vh" }}>
        {notifications.map(notification => (
          <NotificationItem
            data={notification}
            isFactory={isFactory}
            brandId={brandId}
            dispatch={dispatch}
          />
        ))}
      </PerfectScrollBar>
    </div>
  );
};

export default connect(null)(NotificationModel);
