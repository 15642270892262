import { handleActions } from "redux-actions";
import { STATUS_ORDER } from "./../../constants/Design";
import {
  getCoverImagePlaceholder,
  getSmallImageUrl,
} from "helpers/DesignHelpers";
import orderBy from "lodash/orderBy";

const getCoverImage = (design) => {
  const coverImg =
    design.files[
    Object.keys(design.files).filter(
      fileId => design.files[fileId].is_cover === 1
    )
    ];
  if (coverImg) {
    return { src: getSmallImageUrl(coverImg.fileloc) };
  }
  return { ...getCoverImagePlaceholder(design), placeholder: true };
}

const processDesigns = designs => {
  Object.keys(designs).forEach(dId => {
    designs[dId] = {
      ...designs[dId],
      coverImage: getCoverImage(designs[dId]),
      statusString: STATUS_ORDER[designs[dId].status]
    };
  });
  return designs;
};
const processUpdatedDesigns = (previousSelectedDesigns, updatedFile) => {
  const fileId = updatedFile.id;

  Object.values(previousSelectedDesigns).some(function (obj) {
    if (fileId in obj.files) {
      obj.files[fileId] = {
        file_title: updatedFile.file_title,
        fileloc: updatedFile.fileloc,
        file_category: updatedFile.file_category,
        is_cover: updatedFile.is_cover,
        is_image: updatedFile.is_image,
        uploaded_by: updatedFile.uploaded_by,
        file_added_at: updatedFile.file_added_at,
      };

    }
    return true;
  });
  return previousSelectedDesigns;
}



const INITIAL_STATE = {
  list: {},
  selectedDesigns: {},
  samplingDetails: {},
  quoteDetails: {},
  quoteSummary: {},
  orderDetails: {},
  dispatchDetails: {},
  orderFiles: [],
  dispatchFiles: [],
  summaries: [],
  factoryTnaTemplate: [],
  brandTnaTemplate: [],
  preProductionCommit: {},
  newSampleDetails: { samples: [] },
  sampleCommints: [],
  mapActivityLogs: {},
  isLoadingGetDesigns: {},
  sampleHistory: {},
};

const DesignReducer = handleActions(
  {
    GET_DESIGN_SUMMARIES_SUCCESS: (state, action) => {
      return {
        ...state,
        summaries: action.payload.map(d => ({
          ...d,
          statusString: STATUS_ORDER[d.status]
        }))
      };
    },
    GET_DESIGNS_REQUEST: (state, action) => {
      const { collection_id } = action.payload;
      return {
        ...state,
        isLoadingGetDesigns: {
          ...state.isLoadingGetDesigns,
          [collection_id]: true,
        },
      };
    },
    GET_DESIGNS_SUCCESS: (state, action) => {
      const { collection_id } = action.payload;
      const newDesigns = processDesigns(action.payload.designs);
      return {
        ...state,
        // Update overall List
        list: {
          ...state.list,
          ...newDesigns
        },
        isLoadingGetDesigns: {
          ...state.isLoadingGetDesigns,
          [collection_id]: false,
        },
        // Update selected designs lists
        selectedDesigns: newDesigns
      };
    },
    EDIT_DESIGN_SUCCESS: (state, action) => {
      const newDesigns = processUpdatedDesigns(state.selectedDesigns, action.payload.updatedFileDetails);
      return {
        ...state,
        selectedDesigns: newDesigns
      }
    },
    GET_SAMPLE_DETAILS: (state, action) => {
      return {
        ...state,
        samplingDetails: action.payload === 0 ? {} : action.payload
      };
    },
    // new sample api's
    GET_SAMPLE_DETAILS_BY_DESIGN_ID: (state, action) => {
      return {
        ...state,
        newSampleDetails: !Object.keys(action.payload).length
          ? { samples: [], id: null }
          : {
            ...action.payload,
            samples: action.payload.samples.map(sample => ({
              ...sample,
              sampleDispatch: (sample.sampleDispatch && orderBy(sample.sampleDispatch, 'createdAt', 'desc')) || []
            }))
          }
      };
    },
    GET_SAMPLE_HISTORY: (state, action) => {
      const { designId, sampleHistory } = action.payload;
      return {
        ...state,
        sampleHistory: {
          ...state.sampleHistory,
          [designId]: sampleHistory,
        }
      };
    },
    GET_QUOTE_DETAILS: (state, action) => {
      return {
        ...state,
        quoteDetails: action.payload.quotes || [],
        quoteRequestDetails: action.payload.requestedQuote
      };
    },
    GET_QUOTE_SUMMARY: (state, action) => {
      return {
        ...state,
        quoteSummary: action.payload
      };
    },
    UPDATE_QUOTE_DEADLINE: (state, action) => {
      return {
        ...state,
        quoteRequestDetails: {
          ...state.quoteRequestDetails,
          closure_date: action.payload
        }
      }
    },
    UPDATE_QUOTE_DUE_DATE: (state, action) => {
      return {
        ...state,
        quoteRequestDetails: {
          ...state.quoteRequestDetails,
          dueDate: action.payload,
          due_date: action.payload,
        }
      }
    },
    UPDATE_QUOTE_REQUEST_DETAILS: (state, action) => {
      return {
        ...state,
        quoteRequestDetails: {
          ...state.quoteRequestDetails,
          ...action.payload
        }
      }
    },
    GET_FACTORY_DESIGNS: (state, action) => {
      return {
        ...state
      };
    },
    GET_ORDER_DETAILS: (state, action) => {
      return {
        ...state,
        orderDetails: action.payload
      };
    },
    GET_DISPATCH_DETAILS: (state, action) => {
      return {
        ...state,
        dispatchDetails: action.payload ? action.payload : {}
      };
    },
    GET_DESIGN_JOURNEY: (state, action) => {
      return {
        ...state,
        designJourney: action.payload
      };
    },
    UPDATE_ORDER_FILE: (state, action) => {
      return {
        ...state,
        orderFiles: [...state.orderFiles, action.payload]
      };
    },
    DELETE_LOCAL_ORDER_FILE: (state, action) => {
      return {
        ...state,
        orderFiles: state.orderFiles.filter(
          x =>
            x.file_name !== action.payload.file_name ||
            x.design_id !== action.payload.design_id
        )
      };
    },
    UPDATE_DISPATCH_FILE: (state, action) => {
      return {
        ...state,
        dispatchFiles: [...state.dispatchFiles, action.payload]
      };
    },
    DELETE_LOCAL_DISPATCH_FILE: (state, action) => {
      return {
        ...state,
        dispatchFiles: state.dispatchFiles.filter(
          x =>
            x.file_name !== action.payload.file_name ||
            x.design_id !== action.payload.design_id
        )
      };
    },
    GET_FACTORY_TNA_TEMPLATE: (state, action) => {
      // console.log("SINNER IN ", action);
      return {
        ...state,
        factoryTnaTemplate: action.payload
      };
    },
    GET_BRAND_TNA_DETAILS: (state, action) => {
      return {
        ...state,
        brandTnaTemplate: action.payload
      };
    },
    GET_PRE_PRODUCTION_COMMIT: (state, action) => {
      return {
        ...state,
        preProductionCommit: action.payload
      };
    },
    GET_ALL_COMMITS_FOR_SAMPLES: (state, action) => {
      return {
        ...state,
        sampleCommints: action.payload.commints
      };
    },
    ADD_NEW_COMMINT_TO_SAMPLING_COMMINT: (state, action) => {
      return {
        ...state,
        sampleCommints: [...state.sampleCommints, action.payload.commint]
      };

    },
    UPDATE_DESIGN_DETAILS: (state, action) => {
      const details = action.payload.details;
      const designs = processDesigns(details);
      return {
        ...state,
        selectedDesigns: {
          ...state.selectedDesigns,
          ...designs
        }
      }
    },
    SET_DESIGN_FILES: (state, action) => {
      const design = state.selectedDesigns[action.payload.designId];
      design.files = action.payload.data;
      return {
        ...state,
        selectedDesigns: {
          ...state.selectedDesigns,
          [action.payload.designId]: design
        }
      }
    },
    LOGOUT: (state, action) => {
      return {
        ...state,
        ...INITIAL_STATE
      };
    },
    GET_DESIGN_ACTIVITY: (state, action) => {
      const { designId, activityLogs } = action.payload;
      return {
        ...state,
        mapActivityLogs: {
          ...state.mapActivityLogs,
          [designId]: activityLogs,
        }
      }
    },
    // GET_DESIGN_SUCCESS: (state, action) => {
    //   const newDesigns = processDesigns(action.payload);

    //   let updatedSelectedDesigns = state.selectedDesigns;

    //   Object.keys(updatedSelectedDesigns).forEach(k => {
    //     if (newDesigns.hasOwnProperty(k)) {
    //       updatedSelectedDesigns[k] = newDesigns[k];
    //     }
    //   });

    //   return {
    //     ...state,
    //     list: {
    //       ...state.list,
    //       ...newDesigns
    //     },
    //     selectedDesigns: updatedSelectedDesigns
    //   };
    // }
  },
  INITIAL_STATE
);

export default DesignReducer;
