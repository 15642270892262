import React, { useState, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { findIndex } from 'lodash';
import classnames from 'classnames';
import './AddUserModal.scss';
import { checkSuperAdmin } from "../../../helpers/AuthHelper";

const WithInput = ({
  label,
  style,
  inputStyle = {},
  value = "",
  type = "text",
  disable = false,
  onChange,
  id,
  isError,
  required
}) => (
    <div
      style={{
        padding: "12px 0px",
        flex: 1,
        ...style
      }}
    >
      <div className="addUserModal__label">
        {label} {required ? <sup>*</sup> : null}
      </div>
      <input
        type={type}
        disabled={disable}
        style={{
          width: "100%",
          border: isError ? "1px solid var(--fill-error)" : "",
          ...inputStyle
        }}
        value={value}
        onChange={event => onChange(id, event.target.value)}
      />
    </div>
  );

const WithToggle = ({
  label,
  onChange,
  id,
  value
}) => {

  const switchClasses = classnames(`switch switch--default switch--custom`, "flex");

  const togglerClasses = classnames("switch-toggle", `switch-toggle--${value ? "on" : "off"}`);
  return (
    <React.Fragment>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className="label__class" style={{ paddingBottom: "4px" }}>
          {label}
        </div>
        <div>
          <input
            type="checkbox"
            className="addUserModal__invisible_checkbox"
            value={value}
            onChange={event => { onChange(id, event.target.checked) }}
            defaultChecked
          />
          <div className={switchClasses} >
            <div className={togglerClasses} />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const AddUserModal = ({
  isBack,
  onBack,
  data = {},
  createUser,
  updateUser,
  deleteUser,
  editMode,
  initialValues = {}
}) => {
  const [details, setDetails] = useState({ role: '5', ...initialValues });
  const [isError, setIsError] = useState(false);
  const isSuperAdmin = checkSuperAdmin();

  const saveDetailsHandler = (key, value) => {
    details[key] = value;
    setDetails({ ...details });
  };

  const handleValidation = () => {
    const { firstName, email } = details;

    if (!firstName || !email) {
      return false;
    }

    return true;
  };

  let {
    firstName,
    lastName,
    email,
    factoryId,
    phone,
    isEnabled
  } = details;

  if (isEnabled == undefined) {
    isEnabled = true;
  }

  return (
    <>
      <PerfectScrollbar>
        <div className="addUserModal__main_container">
          <div className={editMode ? "" : "hide"}>
            <WithToggle
              label="User status(Active)"
              id="isEnabled"
              type="checkbox"
              value={isEnabled}
              onChange={saveDetailsHandler}
            />
          </div>
          <div className="fields-two">
            <WithInput
              label="First Name"
              value={firstName}
              id="firstName"
              required
              isError={isError && !firstName}
              onChange={saveDetailsHandler}
            />
            <WithInput
              label="Last Name"
              value={lastName}
              id="lastName"
              onChange={saveDetailsHandler}
            />
          </div>
          <WithInput
            label="Email ID"
            value={email}
            id="email"
            required
            isError={isError && !email}
            onChange={saveDetailsHandler}
            disable={editMode && !isSuperAdmin}
          />
          <WithInput
            label="Mobile Number"
            value={phone}
            id="phone"
            onChange={saveDetailsHandler}
          />
        </div>
      </PerfectScrollbar>

      {/* SUBMIT buttons */}

      <div
        className="modal__footer modal__footer-factory-user"
      >
        <span
          onClick={() => {
            if (!handleValidation()) {
              setIsError(true);
              return;
            }

            const request = {
              ...details,
              factoryId: data.factoryId
            };

            if (editMode) {
              updateUser(request);
              return;
            }
            createUser(request);
          }}
        >
          SAVE
        </span>
      </div>
    </>
  );
};

export default AddUserModal;
