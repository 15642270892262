import React from "react";
import "./styles.scss";

const InlineLoader = () => {
  return (
    <div className="inline__loader">
      <div

        className="inner-circle"
      >
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

export default InlineLoader;
