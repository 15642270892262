import React from 'react';
import BaseCollaborationComponent from '../BaseCollaborationComponent/BaseCollaborationComponent';
import { ProfileAvatar } from '../../UI/ProfileAvatar';

import ImgUsersPlus from 'assets/images/social/user-plus.svg'
import './CollaborationAvatars.scss';

export default class CollaborationAvatars extends BaseCollaborationComponent {
  static propTypes = {
    ...BaseCollaborationComponent.propTypes
  }

  render() {
    const { collaboratedUsers } = this.state;
    const extraUsersCount = collaboratedUsers.length > 3 ? collaboratedUsers.length - 2 : 0;
    const slicedUsers = extraUsersCount ? collaboratedUsers.slice(0, 2) : collaboratedUsers;

    return (<div className="collaboration-avatars">
      {slicedUsers && slicedUsers.map(user => (
        <ProfileAvatar
          name={user.name}
          url={user.url || user.image}
          tooltip={user.name}
          initialLength={2}
        />))}
      {extraUsersCount > 0 && <ProfileAvatar
        name={`+ ${extraUsersCount}`}
        initialLength={2} />}
      <img className="users" src={ImgUsersPlus} alt="" />
    </div>);
  }
}
