import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import axios from "axios";
import moment from "moment";

import actions from "../../store/actions";

import { get_service_endpoint } from "../../ServiceEndpoints";
import Button from "../UI/Button/Button";
const newEp = get_service_endpoint("notificationView");

const AddDueDateModel = ({ dispatch, details, ...restProps }) => {
  const [date, setDate] = useState(null);

  const addDueDateHandler = () => {
    if (!date) return;

    //  PATCH request here
    axios
      .patch(`${newEp}/sample`, {
        ...details,
        dueDate: date
      })
      .then(res => {
        // do stuff here
        // dispatch(actions.getAllSamplesForSampleTracker({})); //  give it filters
        const { data } = details;
        data.dueDate = moment(date).format();
        dispatch(
          actions.dispatchDeatilsUpdatedInSaplinTracker({
            ...data
          })
        );
        dispatch(actions.closeModal());
      });
  };

  return (
    <div
      style={{
        borderTop: "1px solid var(--fill-grey-light3)",
        marginBottom: "8px"
      }}
    >
      <div style={{ minWidth: "400px", padding: "16px 28px" }}>
        <div className="label__class" style={{ padding: "6px 2px 6px 2px" }}>
          Due Date<sup>*</sup>
        </div>
        <DatePicker
          // withPortal
          popperPlacement="auto"
          popperClassName="custom-datepicker-popper"
          required={true}
          popperModifiers={{
            // offset: {
            //   offset: "0,100%"
            // },
            preventOverflow: {
              enabled: true,
              escapeWithReference: false // force popper to stay in viewport (even when input is scrolled out of view)
              // boundariesElement: this.refs["datepickerContainer"]
            }
          }}
          // readOnly={"hideValue"}
          // popperPlacement="bottom-end"
          // minDate={new Date()}
          disabled={false}
          placeholderText={"Select Date"}
          dateFormat="MMMM dd, yyyy"
          className="custom-datepicker"
          selected={date ? date : details.data.dueDate}
          onChange={arg1 => {
            setDate(arg1);
          }}
        />
      </div>

      {/* BUTTONS  */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0px 28px",
          backgroundColor: "#fff",
          alignItems: "center",
          borderTop: "1px solid var(--fill-grey-light3)",

          marginTop: "12px"
        }}
      >
        <div
          onClick={() => {
            dispatch(actions.closeModal());
          }}
          style={{ cursor: "pointer" }}
        >
          CANCEL
        </div>
        <Button
          category="blue-bg"
          onClick={addDueDateHandler}
        >
          <span>UPDATE</span>
        </Button>
      </div>
    </div>
  );
};

export default connect(null)(AddDueDateModel);
