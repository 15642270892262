import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import qs from "query-string";
import { withRouter } from "react-router-dom";

import "./styles.scss";

import { QUOTE_JOURNEY_SUBTITLES } from "constants/Quote";
import { DESIGN_SAMPLING_STATUS } from "constants/Design";

import { designInactive } from "helpers/DesignHelpers";
import { ReactComponent as CheckIcon } from "assets/images/journey/ic-blue-check.svg";
import { ReactComponent as ClockIcon } from "assets/images/journey/ic-blue-clock.svg";
import { ReactComponent as SkipIcon } from "assets/images/journey/ic-skipped.svg";

class HeaderTab extends Component {
  state = {
    selectedUrl: "",
    tabPanels: []
  };

  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      tabPanels: props.tabs.map(x => ({ panel: x.panel, url: x.tabUrl }))
    };
  }

  componentWillReceiveProps(nextProps) {
    const queryString = qs.parse(nextProps.location.search);
    this.setState(() => {
      const newTabPanels = nextProps.tabs.map(x => ({
        panel: x ? x.panel : null,
        url: x ? x.tabUrl : "#"
      }));
      // Fallback to props.initialTabUrl
      let newTabUrl = nextProps.initialTabUrl;
      const newTabIndex = newTabPanels.findIndex(
        val => val.url === queryString.tab
      );
      if (newTabIndex !== -1) {
        // Tab found
        newTabUrl = queryString.tab;
      } else {
        window.appHistory.push({
          search: qs.stringify({ ...queryString, tab: newTabUrl })
        });
      }
      return { tabPanels: newTabPanels, selectedUrl: newTabUrl };
    });
  }

  getStateValue = (url, threshold, subTitle = "", disabled = false) => {
    const {
      design: { status }
    } = this.props;

    const defaultState = { text: "", completed: 0 };

    if (designInactive(status)) {
      if (url === "summary") return { text: "Canceled", completed: 0 };
      else return defaultState;
    }

    if (disabled) {
      return defaultState;
    }

    switch (url) {
      case "summary": {
        if (status === 1) return { text: "Draft", completed: 0 };
        else if (status >= threshold)
          return { text: "Completed", completed: 1 };
        break;
      }
      case "quote": {
        if (
          subTitle === QUOTE_JOURNEY_SUBTITLES.APPROVED ||
          subTitle === QUOTE_JOURNEY_SUBTITLES.REJECTED
        )
          return { text: subTitle, completed: 1 };
        else return { text: subTitle, completed: 0 };
      }
      case "sampling": {
        if (subTitle === DESIGN_SAMPLING_STATUS.REQUESTED) {
          return { text: subTitle, completed: 0 };
        } else if (subTitle === DESIGN_SAMPLING_STATUS.SKIPPED) {
          return { text: subTitle, completed: 2 };
        } else {
          if (status === 6) return { text: "Requested", completed: 0 };
          if (status >= threshold) return { text: "Approved", completed: 1 };
        }
        break;
      }
      case "designtna": {
        if (subTitle) return { text: subTitle, completed: 0 };
        else if (status >= threshold)
          return { text: "Completed", completed: 1 };
        if (status === 8) return { text: "In progress", completed: 0 };
        break;
      }
      default:
        return defaultState;
    }
    return defaultState;
  };

  getIcon = completed => {
    switch (completed) {
      case 1:
        return <CheckIcon />;
      case 0:
        return <ClockIcon />;
      case 2:
        return <SkipIcon />;
      default:
        return null;
    }
  };

  render() {
    const {
      design: { status }
    } = this.props;
    const { props } = this;

    return (
      <div className="header-tabs flex-col">
        <div className="header-tabs__header flex-col">
          <div className="header-tabs__custom-header">{props.header}</div>
        </div>
        <div className="header-tabs__navbar flex">
          {props.tabs.map((t, index) => {
            const { text, completed } = this.getStateValue(
              t.tabUrl,
              t.threshold,
              t.subTitle,
              t.disabled
            );
            const active = this.state.selectedUrl === t.tabUrl;

            const Icon = this.getIcon(completed);
            return (
              <div
                key={index}
                className={classnames("header-tabs__tab flex", {
                  "header-tabs__tab--active":
                    this.state.selectedUrl === t.tabUrl,
                  "header-tabs__tab--disabled": t.disabled,
                  completed: status >= t.threshold,
                  messages: t.tabUrl === "messages"
                })}
                data-tip={t.disabled ? t.tooltip : ""}
                data-class="dark-tooltip"
                onClick={() => {
                  if (t.disabled) return;
                  this.setState({
                    // selected: index,
                    selectedUrl: t.tabUrl
                  });
                  // if (t.tabUrl) window.appHistory.push(`?${t.tabUrl}`);
                  if (t.tabUrl) {
                    window.appHistory.push({
                      search: qs.stringify({
                        ...qs.parse(this.props.location.search),
                        tab: t.tabUrl
                      })
                    });
                  }
                }}
              >
                <div
                  className={`header-tabs__tab-image ${
                    active ? "active" : !completed ? "inactive" : "completed"
                  }`}
                >
                  {!t.disabled && t.name !== "Messages" && Icon}
                </div>
                <div className="header-tabs__tab-block">
                  <span className="title">{t.name}</span>
                  <span
                    className={`sub_title ${status >= t.threshold &&
                      "completed"}`}
                  >
                    {text}
                  </span>
                  {t.count !== undefined ? (
                    <div>
                      <span className="count">{t.count}</span>
                    </div>
                  ) : null}
                </div>
              </div>
            );
          })}
        </div>
        <div className="header-tabs__panel flex-col scroll-auto">
          {this.state.selectedUrl
            ? this.state.tabPanels[
                this.state.tabPanels.findIndex(
                  val => val.url === this.state.selectedUrl
                )
              ].panel
            : null}
        </div>
      </div>
    );
  }
}

HeaderTab.propTypes = {
  tabs: PropTypes.array.isRequired
};

export default withRouter(HeaderTab);
