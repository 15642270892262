import React, { useState, useEffect } from "react";

export const HeaderTabs = ({ tabs, onChange, index }) => {
  const [activeIndex, setActiveIndex] = useState(index || 0);

  useEffect(() => {
    onChange(tabs[activeIndex].value);
  }, [activeIndex]);

  useEffect(() => {
    if(index){
      setActiveIndex(index);
    }
  }, [index]);

  return (
    <div className="tabs flex">
      {tabs.map((tab, index) => (
        <div
          className={
            index === activeIndex
              ? "dashboard-tabs-active"
              : "dashboard-tabs-default"
          }
          onClick={
            ()=>setActiveIndex(index)
          }
        >
          {tab["label"]}
        </div>
      ))}
    </div>
  );
};
