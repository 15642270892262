export const MATERIAL_REPORT_TYPES = {
  stockio: 1,
  inventory: 2,
};

export const MATERIAL_REPORT_TYPE_NAMES = {
  1: 'stockio',
  2: 'inventory',
};

export const FILTER_MATERIAL_TYPES = [
  { label: "Fabric", value: "0" },
  { label: "Trim", value: "1" },
];

export const FILTER_MATERIAL_MOVEMENTS = [
  { label: "Inward", value: "1" },
  { label: "Outward", value: "2" },
];
