import React, { Component } from "react";
import sum from 'lodash/sum';
import _ from "lodash";
import classnames from "classnames";
import icEdit from "../../../../assets/images/image/ic-edit.svg";
import icTrash from "../../../../assets/images/action/ic-trash.svg";
import { getCurrentUserRole } from "../../../../helpers/AuthHelper";
import {
  LOCO_SUPER,
  FACTORY_ADMIN,
  FACTORY_MANAGER
} from "../../../../constants/Auth";
import Button from "../../../UI/Button/Button";
import { IconSave, IconSubmit } from "../../../UI/Icons";
import { QUOTE_STATUS_AS_STRING } from "../../../../constants/Quote";
import "./index.scss";

export default class OpenQuote extends Component {
  state = {
    editing: false,
    hover: {
      tableKey: null,
      rowKey: null
    },
    total_quantity: "",
    isDirty: false,
  };

  componentDidMount() {
    this.setState({
      ...this.props.quote,
    });

    this._resetCost(this.props.quote);

    this.props.saveQuoteHandler(this.saveQuote);
  }

  componentWillReceiveProps(nextProps) {
    // if (this.state.isDirty) {
    //   debugger;
    // }
    // if (nextProps.table !== this.props.table || nextProps.additionalCosts !== this.props.additionalCosts) {
    this.setState({
      ...nextProps.quote,
    });

    if (this.props.isReadOnly && !nextProps.isReadOnly) {
      this.setState({
        isDirty: false,
      });
    }

    this._resetCost(nextProps.quote);
  }

  _resetCost = ({ table, additionalCosts } = {}) => {

    // preparing source data
    const quoteTable = table || this.state.table;
    const quoteAdditionalCosts = additionalCosts || this.state.additionalCosts;

    // computing cost
    const tableCost = sum([].concat.apply([], Object.values(quoteTable)).map(r => (r.consumption || 0) * (r.rate || 0)));
    const totalAdditionalCosts = sum(Object.values(quoteAdditionalCosts).map(value => tableCost * value / 100));

    const totalCost = (tableCost + totalAdditionalCosts).toFixed(2);

    this.setState({ totalCost });

    return totalCost;
  }

  enableSaveInBottomHandlerAndCallChangeFormOfParent = () => {
    if (!this.state.enableSaveInBottom) {
      this.setState({ enableSaveInBottom: true, isSaved: false });
    }
  };

  rowChange = ({ tableKey, rowKey, columnKey, newValue }) => {
    let mock = this.state.table;

    // Check if the changed row was deleted and do no processing
    if (!mock[tableKey][rowKey]) {
      return;
    }

    mock[tableKey][rowKey][columnKey] = newValue;

    console.log(mock[tableKey][rowKey][columnKey]);
    this.setState({
      isDirty: true,
      table: mock
    });

    const totalCost = this._resetCost({ table: mock });

    this.props.updateEditedQuote({
      quoteDetails: {
        table: mock,
        additionalCosts: this.state.additionalCosts,
        totalCost: totalCost,
        total_quantity: this.state.total_quantity,
      }
    });
    // Force update to re-calculate the total cost
    this.forceUpdate();
  };

  additionalCostChange = ({ costName, value }) => {
    // console.log("AdditionalCost change triggered", costName, ":", value);
    let mock = this.state.additionalCosts;

    mock[costName] = value;

    this.setState({
      isDirty: true,
      additionalCosts: mock
    });

    const totalCost = this._resetCost({ additionalCosts: mock });

    this.props.updateEditedQuote({
      quoteDetails: {
        table: this.state.table,
        additionalCosts: mock,
        totalCost: totalCost,
        total_quantity: this.state.total_quantity,
      }
    });
    // Force update to re-calculate the total cost
    this.forceUpdate();
  };

  addNewRow = ({ tableKey }) => {
    // console.log("OnRowAdd Called");
    let mock = this.state.table;
    mock[tableKey].push({
      name: ""
    });

    this.setState({
      isDirty: true,
      table: mock
    });

    const totalCost = this._resetCost({ table: mock });

    this.props.updateEditedQuote({
      quoteDetails: {
        table: mock,
        additionalCosts: this.state.additionalCosts,
        totalCost: totalCost,
        total_quantity: this.state.total_quantity,
      }
    });
  };

  deleteRow = ({ tableKey, rowKey }) => {
    // console.log("onDeleteRow Called");

    let mock = { ...this.state.table };

    // delete mock[tableKey][rowKey];
    // const { [rowKey], ...newTable } = mock[tableKey]
    let newTable = [];
    mock[tableKey].forEach((val, key) =>
      key !== rowKey ? (newTable[key] = mock[tableKey][key]) : null
    );
    mock[tableKey] = newTable.filter(v => v !== null);

    this.setState({
      isDirty: true,
      table: mock,
    });

    const totalCost = this._resetCost({ table: mock });

    // this.props.updateEditedQuote({
    //   quote: {
    //     table: mock,
    //     additionalCosts: this.state.additionalCosts,
    //     totalCost: totalCost
    //   }
    // });
  };

  saveQuote = () => {
    this.props.saveQuote({
      table: this.state.table,
      totalCost: this.state.totalCost,
      additionalCosts: this.state.additionalCosts,
      total_quantity: this.state.total_quantity,
    });

    this.setState({ enableSaveInBottom: false, isSaved: true });
  };

  render() {
    if (!this.state.table) {
      return null;
    }

    const { currency, quote, status, cancelReviseQuote, readOnly } = this.props;
    const { table, additionalCosts, totalCost, isDirty } = this.state;
    let totalCosts = 0, totalAdditionalCosts = 0;

    return (
      <div
        className={`open-quote open-quote--${readOnly ? 'readonly' : 'editable'}`}
        onChange={this.enableSaveInBottomHandlerAndCallChangeFormOfParent}
      >

        <div className="table table--additional-costs flex-col">
          <div className="table__header table__row flex f-w-semi-bold f-sz-l">
            <span>Total Charges</span>
            <span className="table__calculated-data">
              {currency} &nbsp;
              {totalCost}
            </span>
          </div>
        </div>

        {Object.keys(table).map((tableHeading, indTableHeading) => {
          const tableTotal = table[tableHeading]
            .map(rowData =>
              (rowData["consumption"] * rowData["rate"]).toFixed(2)
            )
            .map(cost => (isNaN(cost) ? 0 : cost))
            .reduce((acc, currVal) => acc + Number(currVal), 0)
            .toFixed(2);

          totalCosts += Number(tableTotal);

          return (
            <div className="flex" key={indTableHeading}>
              <div style={{ flexGrow: "1" }} className="table flex-col">
                <div className="table__title f-w-semi-bold f-sz-xl">
                  {tableHeading}
                </div>
                <div className="table__header f-w-semi-bold table__row flex">
                  <span className="table__row__big">Particulars</span>
                  <span>Consumption</span>
                  <span>Rate / Unit</span>
                  <span>Amount</span>
                </div>
                <div className="table__content">
                  {table[tableHeading].map((rowData, rowIndex) => {
                    const isHoverRow =
                      this.state.hover.tableKey === tableHeading &&
                      this.state.hover.rowKey === rowIndex;
                    // Only display filled rows to Clients
                    if (
                      getCurrentUserRole() !== LOCO_SUPER &&
                      getCurrentUserRole() !== FACTORY_ADMIN &&
                      getCurrentUserRole() !== FACTORY_MANAGER
                    ) {
                      if (
                        !rowData["name"] ||
                        !rowData["consumption"] ||
                        !rowData["rate"]
                      ) {
                        return null;
                      }
                    }
                    return (
                      <React.Fragment key={rowIndex}>
                        <div
                          className={`table__row flex ${
                            isHoverRow ? "table__row--hover" : ""
                            }`}
                        >
                          <span
                            contentEditable={!this.props.readOnly}
                            className="table__row__big flex single-line"
                            onClick={() => {
                              this.setState({ editing: true });
                            }}
                          >
                            <span>{rowData["name"]}</span>
                            <span contentEditable={false} className="table__row-cta-list">
                              <Button
                                category="img shrink"
                                className="table__row-cta-edit"
                              >
                                <img alt="edit" src={icEdit} />
                              </Button>
                              <Button
                                category="img shrink"
                                className={`table__row-cta-delete ${readOnly ? 'hide' : ''}`}
                                onClick={() =>
                                  this.deleteRow({
                                    tableKey: tableHeading,
                                    rowKey: rowIndex
                                  })
                                }
                              >
                                <img alt="edit" src={icTrash} />
                              </Button>
                            </span>
                          </span>
                          <span
                            contentEditable={!this.props.readOnly}
                            onFocus={() => this.setState({ editing: true })}
                            onBlur={evt => {
                              this.setState({
                                editing: false,
                                hover: {
                                  tableKey: null,
                                  rowKey: null
                                }
                              });
                              // Check if non empty text
                              if (evt.currentTarget.innerText) {
                                this.rowChange({
                                  tableKey: tableHeading,
                                  rowKey: rowIndex,
                                  columnKey: "consumption",
                                  newValue: Number(evt.currentTarget.innerText)
                                });
                              }
                            }}
                          >
                            {rowData["consumption"]}
                          </span>
                          <span
                            contentEditable={!this.props.readOnly}
                            onFocus={() => this.setState({ editing: true })}
                            onBlur={evt => {
                              this.setState({
                                editing: false,
                                hover: {
                                  tableKey: null,
                                  rowKey: null
                                }
                              });
                              // Check if non empty text
                              if (evt.currentTarget.innerText) {
                                this.rowChange({
                                  tableKey: tableHeading,
                                  rowKey: rowIndex,
                                  columnKey: "rate",
                                  newValue: Number(evt.currentTarget.innerText)
                                });
                              }
                            }}
                          >
                            {rowData["rate"]}
                          </span>
                          <span className="table__calculated-data">
                            {currency} &nbsp;
                            {isNaN(rowData["consumption"] * rowData["rate"])
                              ? (0).toFixed(2)
                              : (
                                rowData["consumption"] * rowData["rate"]
                              ).toFixed(2)}
                          </span>
                        </div>
                      </React.Fragment>
                    );
                  })}
                  {!this.props.readOnly && (
                    <div className="table__row flex">
                      <span
                        onClick={() => {
                          this.addNewRow({ tableKey: tableHeading });
                        }}
                        style={{
                          textAlign: "left",
                          minWidth: "100%",
                          color: "var(--fill-primary-medium)"
                        }}
                      >
                        Add item
                      </span>
                    </div>
                  )}
                </div>
                <div className="table__footer table__row flex f-w-semi-bold f-sz-l">
                  <span>Total {tableHeading} Charges</span>
                  <span className="table__calculated-data">
                    {currency} &nbsp;
                    {tableTotal}
                  </span>
                </div>
              </div>
              <div style={{ maxWidth: "24px" }}>
                <div
                  style={{ visibility: "hidden" }}
                  className="table__title f-w-semi-bold f-sz-xl"
                >
                  A
                </div>
                <div
                  style={{ visibility: "hidden" }}
                  className="table__header f-w-semi-bold table__row flex"
                >
                  <span>A</span>
                </div>
              </div>
            </div>
          );
        })}

        {
          <div
            className="table table--additional-costs flex-col"
          >
            <div className="table__content">
              {Object.keys(additionalCosts).map(costName => {
                const additionalCost = (
                  (totalCosts * Number(additionalCosts[costName])) /
                  100
                ).toFixed(2);
                totalAdditionalCosts += Number(additionalCost);
                if (getCurrentUserRole() !== LOCO_SUPER) {
                  if (additionalCosts[costName] === 0) {
                    return null;
                  }
                }
                return (
                  <div className="table__row flex">
                    <span className="table__row__big--additional">
                      {costName} (%)
                    </span>
                    <div
                      className={classnames({
                        "lf-percent-input": true,
                        "lf-percent-input--disabled": this.props.readOnly
                      })}
                    >
                      <input
                        disabled={this.props.readOnly}
                        type="text"
                        style={{
                          border: "none",
                          textAlign: "right"
                        }}
                        onChange={({ target: { value } }) => {
                          this.additionalCostChange({
                            costName: costName,
                            value: _.isFinite(Number(value))
                              ? Number(value)
                              : ""
                          });
                        }}
                        value={additionalCosts[costName]}
                      />
                    </div>
                    <span className="table__calculated-data">
                      {currency} &nbsp;
                      {additionalCost}
                    </span>
                  </div>
                );
              })}
            </div>
            <div className="table__footer table__row flex f-w-semi-bold f-sz-l">
              <span>Total Charges</span>
              <span className="table__calculated-data">
                {currency} &nbsp;
                {totalCost}
              </span>
            </div>
          </div>
        }
        <div className="flex">
          <span
            style={{
              marginLeft: "auto",
              marginRight: "24px",
              lineHeight: "1.33",
              letterSpacing: "0.2px",
              color: "var(--fill-grey-medium)"
              //   fontStyle: "italic"
            }}
            className="f-sz-sm"
          >
            <i>Quote is exclusive of local taxes</i>
          </span>
        </div>
        <br />
        <div
          style={{ marginLeft: "auto", marginRight: "24px" }}
          className="flex"
        >
          {this.props.children}
        </div>
        {!this.props.readOnly && (
          <div className="flex">
            <div
              className="quote-cta-list"
              style={{ marginLeft: "auto", marginRight: "24px" }}
            >
              {
                (status === QUOTE_STATUS_AS_STRING.DRAFT)
                  ? <>
                    <Button
                      category="black-ghost primary-text"
                      onClick={this.saveQuote}
                      disabled={!isDirty}
                    >
                      <IconSave color="blue" height="20px" />
                      <span className="icon-text">Save</span>
                    </Button>
                  </>
                  : <>
                    <Button
                      category="black-ghost primary-text"
                      onClick={(e) => {
                        cancelReviseQuote(quote);
                        e.stopPropagation();
                      }}
                    >
                      <span className="icon-text">Cancel</span>
                    </Button>
                  </>
              }
              <Button
                category="blue-bg"
                onClick={() => {
                  this.props.saveAndSubmitQuote({
                    table: this.state.table,
                    totalCost: this.state.totalCost,
                    additionalCosts: this.state.additionalCosts,
                    total_quantity: this.state.total_quantity
                  });
                }}
                disabled={!isDirty && !(status === QUOTE_STATUS_AS_STRING.DRAFT && totalCost > 0)}
              >
                <IconSubmit color="white" height="20px" />
                <span className="icon-text">
                  {status === QUOTE_STATUS_AS_STRING.DRAFT ? 'Submit' : 'Update'}
                </span>
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}
