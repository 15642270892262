import { handleActions } from "redux-actions";
import {
  transformCosting,
  transformSampling,
  transformProduction,
  transformActiveFactories,
  transformFactoryPerformance,
  transformBuyerPerformance,
  transformOrders,
  transformDesigns,
  transformMaterials,
  transformFilters,
} from "helpers/reportsTransformer";
import { MATERIAL_REPORT_TYPE_NAMES } from "constants/Reports";

const INITIAL_STATE = {
  costing: { list: [], filters: {} },
  sampling: { list: [], filters: {} },
  production: { list: [], filters: {} },
  activeFactories: { list: [], filters: {} },
  factoryPerformance: { list: [], filters: {} },
  buyerPerformance: { list: [], filters: {} },
  orders: { list: [], filters: {} },
  designs: { list: [], filters: {}, metadata: {} },
  materials: {
    inventory: { list: [], filters: {}, metadata: {} },
  },
  loading: false
};

const ReportsReducer = handleActions(
  {
    REPORTS_LOADING: (state, action) => {
      return {
        ...state,
        loading: action.payload
      };
    },
    GET_COSTING_REPORT: (state, action) => {
      const { paginate, records, filters, isInit } = action.payload;
      return {
        ...state,
        loading: false,
        costing: {
          pagination: {
            ...paginate,
            totalCount: isInit
              ? paginate.count.total
              : state.costing.pagination.totalCount
          },
          list: isInit
            ? transformCosting(records)
            : [...state.costing.list, ...transformCosting(records)],
          filters: isInit ? transformFilters(filters) : state.costing.filters
        }
      };
    },
    GET_SAMPLING_REPORT: (state, action) => {
      const { paginate, records, filters, isInit } = action.payload;
      return {
        ...state,
        loading: false,
        sampling: {
          pagination: {
            ...paginate,
            totalCount: isInit
              ? paginate.count.total
              : state.sampling.pagination.totalCount
          },
          list: isInit
            ? transformSampling(records)
            : [...state.sampling.list, ...transformSampling(records)],
          filters: isInit ? transformFilters(filters) : state.sampling.filters
        }
      };
    },
    GET_PRODUCTION_REPORT: (state, action) => {
      const { paginate, records, isInit, filters } = action.payload;
      return {
        ...state,
        loading: false,
        production: {
          pagination: {
            ...paginate,
            totalCount: isInit
              ? paginate.count.total
              : state.production.pagination.totalCount
          },
          list: isInit
            ? transformProduction(records)
            : [...state.production.list, ...transformProduction(records)],
          filters: isInit ? transformFilters(filters) : state.production.filters
        }
      };
    },
    GET_ACTIVE_FACTORIES_REPORT: (state, action) => {
      const { paginate, records, isInit, filters } = action.payload;
      return {
        ...state,
        loading: false,
        activeFactories: {
          pagination: {
            ...paginate,
            totalCount: isInit
              ? paginate.count.total
              : state.activeFactories.pagination.totalCount
          },
          list: isInit
            ? transformActiveFactories(records)
            : [...state.activeFactories.list, ...transformActiveFactories(records)],
          filters: isInit ? transformFilters(filters) : state.activeFactories.filters
        }
      };
    },
    GET_FACTORY_PERFORMANCE: (state, action) => {
      const { paginate, records, filters, isInit } = action.payload;
      return {
        ...state,
        loading: false,
        factoryPerformance: {
          pagination: {
            ...paginate,
            totalCount: isInit
              ? paginate.count.total
              : state.factoryPerformance.pagination.totalCount
          },
          list: isInit
            ? transformFactoryPerformance(records)
            : [...state.factoryPerformance.list, ...transformFactoryPerformance(records)],
          filters: isInit ? transformFilters(filters) : state.factoryPerformance.filters
        }
      };
    },
    GET_BUYER_PERFORMANCE: (state, action) => {
      const { paginate, records, filters, isInit } = action.payload;
      return {
        ...state,
        loading: false,
        buyerPerformance: {
          pagination: {
            ...paginate,
            totalCount: isInit
              ? paginate.count.total
              : state.buyerPerformance.pagination.totalCount
          },
          list: isInit
            ? transformBuyerPerformance(records)
            : [...state.buyerPerformance.list, ...transformBuyerPerformance(records)],
          filters: isInit ? transformFilters(filters) : state.buyerPerformance.filters
        }
      };
    },
    GET_ORDERS_REPORT: (state, action) => {
      const { paginate, records, filters, isInit } = action.payload;
      return {
        ...state,
        loading: false,
        orders: {
          pagination: {
            ...paginate,
            totalCount: isInit
              ? paginate.count.total
              : state.orders.pagination.totalCount
          },
          list: isInit
            ? transformOrders(records)
            : [...state.orders.list, ...transformOrders(records)],
          filters: isInit ? transformFilters(filters) : state.orders.filters
        }
      };
    },
    GET_DESIGNS_REPORT: (state, action) => {
      const { paginate, records, filters, metadata, isInit } = action.payload;
      const actualMetadata = isInit ? metadata : state.designs.metadata;

      return {
        ...state,
        loading: false,
        designs: {
          pagination: {
            ...paginate,
            totalCount: isInit
              ? paginate.count.total
              : state.designs.pagination.totalCount
          },
          list: isInit
            ? transformDesigns(records, actualMetadata)
            : [...state.designs.list, ...transformDesigns(records, actualMetadata)],
          filters: isInit ? transformFilters(filters) : state.designs.filters,
          metadata: actualMetadata,
        }
      };
    },
    GET_MATERIAL_REPORT: (state, action) => {
      const { reportType: reportTypeId, paginate, records, filters, metadata, isInit } = action.payload;
      const reportType = MATERIAL_REPORT_TYPE_NAMES[reportTypeId];
      const actualMetadata = isInit ? metadata : state.materials[reportType].metadata;

      return {
        ...state,
        loading: false,
        materials: {
          ...state.materials,
          [reportType]: {
            pagination: {
              ...paginate,
              totalCount: isInit
                ? paginate.count.total
                : state.materials[reportType].pagination.totalCount
            },
            list: isInit
              ? transformMaterials(records, { reportType })
              : [...state.materials[reportType].list, ...transformMaterials(records, { reportType })],
            filters: isInit ? transformFilters(filters) : state.materials[reportType].filters,
            metadata: actualMetadata,
          }
        }
      };
    },
    LOGOUT: (state, action) => {
      return {
        ...state,
        ...INITIAL_STATE
      };
    }
  },
  INITIAL_STATE
);

export default ReportsReducer;
