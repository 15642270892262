import React from 'react'
import CustomFile from '../../../../UI/Form/CustomFile';

const File = ({
  field,
  form,
  ...props
}) => {
  const { name } = field;
  const { touched, errors, setFieldValue, setFieldTouched } = form;

  return (
    <div className={`formik_file`}>
      <CustomFile onChange={e => {
        !touched[name] && setFieldTouched(name);
        setFieldValue(name, e.target.files);
      }} {...props} />
      {touched[name] &&
        errors[name] && <div className="error">{errors[name]}</div>}
    </div>
  );
};

export default File;
