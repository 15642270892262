import React, { Component } from "react";
import { PropTypes } from "prop-types";
import classnames from "classnames";
import PerfectScrollbar from 'react-perfect-scrollbar';
import Popover from "react-tiny-popover";
import moment from "moment";
import { Link } from "react-router-dom";
import Button from "../../UI/Button/Button";
import { IconArrow, IconCheckMark, IconClose, IconLocation, IconFactoryMachine } from "../../UI/Icons";
import { getCoverImagePlaceholder } from "../../../helpers/DesignHelpers";
import { daysAgo } from "../../../helpers/DateHelpers";
import { QUOTE_STATUS_CNAME, QUOTE_STATUS_TO_STRING_BRAND, QUOTE_STATUS, QUOTE_STATUS_AS_STRING } from "../../../constants/Quote";
import { DATESTAMP_FORMAT } from "../../../constants/Date";
import moreVert from "../../../assets/images/navigation/ic-more-vert.svg";
import "./index.scss";

const STATUS_ORDER = {
  '1': 4,
  '0': 3,
  '2': 2,
  '-1': 1,
  '-2': 0,
};

class ReviewQuoteModal extends Component {
  static propTypes = {
    currency: PropTypes.string.isRequired,
    quote: PropTypes.object.isRequired,
    openNextModal: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);


    this.state = {
      allStatusQuotes: this._getAllQuotes(props),
      anyQuoteAccepted: Array.isArray(props.quote.quote_details['1']) && props.quote.quote_details[1].length > 0,
      design: this._getDesign(props),
      popOverOpen: {},
    };
  }

  _getDesign(props) {
    const { quote } = props;
    let coverPicUrl = quote.design_cover_fileloc
      ? quote.design_cover_fileloc
      : getCoverImagePlaceholder({ category: quote.design_category }).src;

    return {
      id: props.quote.design_id,
      name: props.quote.design_name,
      category: props.quote.design_category,
      coverImage: {
        src: coverPicUrl,
        placeholder: !quote.design_cover_fileloc,
      }
    };
  }

  _getAllQuotes(props) {
    return Object.keys(props.quote.quote_details)
      .sort((a, b) => STATUS_ORDER[a] < STATUS_ORDER[b] ? 1 : -1)
      .map(key => {
        return {
          status: key,
          quotes: props.quote.quote_details[key]
            .map(quote => ({
              ...quote,
              status: Number(quote.status),
              parsedQuote: JSON.parse(quote.quote),
            })),
        }
      });
  }

  _getAdditionalProps = (quote) => {
    const { design } = this.state;
    return {
      design,
      quoteDetails: quote,
    }

  }

  _openApproveQuoteModal(quote) {
    const { openNextModal, quote: { quote_details } } = this.props;
    const { design } = this.state;

    openNextModal("APPROVE_QUOTE_MODAL", {
      ...(this._getAdditionalProps(quote)),
      countAwaited: (quote_details['-1'] && quote_details['-1'].length) || 0,
      hiddenFields: [
        { name: "design_id", value: design.id },
        { name: "quote_id", value: quote.id },
        { name: "factoryId", value: quote.factoryId },
        { name: "status", value: 1 },
        { name: "design_name", value: design.name },
        {
          name: "flashMsg",
          value: `Quote for ${design.name} approved`
        }
      ],
    });
  }

  _openRejectQuoteModal(quote) {
    const { openNextModal } = this.props;
    const { design } = this.state;

    openNextModal("RESUBMIT_QUOTE_MODAL", {
      ...(this._getAdditionalProps(quote)),
      hiddenFields: [
        { name: "design_id", value: design.id },
        { name: "quote_id", value: quote.id },
        { name: "factoryId", value: quote.factoryId },
        { name: "status", value: 2 },
        { name: "design_name", value: design.name },
        {
          name: "flashMsg",
          value: `Quote for ${design.name} rejected`
        }
      ],
    });
  }

  _withdrawQuoteRequest = (quote) => {
    const {
      openNextModal,
    } = this.props;

    openNextModal("WITHDRAW_QUOTE_MODAL", {
      hiddenFields: {
        quoteRequestId: quote.quote_request_id,
        designId: quote.design_id,
        factoryIds: [quote.factory_id],
      },
    });

    this.setState({ popOverOpen: false });
  }

  _renderPopover = (quote) => {
    const { anyQuoteAccepted, popOverOpen } = this.state;

    if (anyQuoteAccepted || quote.status !== QUOTE_STATUS.DRAFT) {
      return null;
    }

    return <div className="quote-status__popover">
      <Popover
        position={"bottom"}
        isOpen={popOverOpen[quote.qrf_id]}
        onClickOutside={() => this.setState({ popOverOpen: {} })}
        content={
          <div
            className="pop-over__item flex f-sz-l"
            onClick={() => this._withdrawQuoteRequest(quote)}
          >Withdraw request</div>
        }
      >
        <img
          className="header-dots"
          onClick={evt => {
            this.setState({ popOverOpen: { [quote.qrf_id]: true } });
          }}
          src={moreVert}
          alt="three dots"
        />
      </Popover>
    </div>;
  }

  renderFactoryQuoteCta(factoryQuote) {
    const { id: quoteId, status } = factoryQuote;

    if (!quoteId || status !== QUOTE_STATUS.SUBMITTED) {
      return null;
    }

    return <>
      <Button
        category="ghost"
        className="tooltip-container"
        onClick={() => this._openRejectQuoteModal(factoryQuote)}
      >
        <IconClose color="red" />
        <div className="tooltip dark-tooltip tooltip--h-center tooltip--v-bottom">Reject quote</div>
      </Button>
      <Button
        category="ghost"
        className="tooltip-container"
        onClick={() => this._openApproveQuoteModal(factoryQuote)}
      >
        <IconCheckMark color="green" />
        <div className="tooltip dark-tooltip tooltip--h-center tooltip--v-bottom">Accept quote</div>
      </Button>
    </>;
  }

  renderFactoryQuoteRow(factoryQuote) {
    const { quote } = this.props;
    const { anyQuoteAccepted, popOverOpen } = this.state;

    const { parsedQuote, qrf_id } = factoryQuote;
    const quoteAmountDiff = ((parsedQuote && quote.target_price) ? (parsedQuote.totalCost - quote.target_price) : 0);
    const absQuoteAmountDiff = Math.abs(quoteAmountDiff.toFixed(2));
    const quoteAmountDiffStatus = quoteAmountDiff === 0
      ? 'matched'
      : (quoteAmountDiff > 0 ? 'more' : 'less');

    return (
      <div
        className={classnames(`factory-quote-row flex factory-quote-row--${QUOTE_STATUS_CNAME[factoryQuote.status]}`,
          popOverOpen[factoryQuote.qrf_id] ? 'factory-quote-row--popover' : null)}
        key={qrf_id}
      >
        <div className="flex-col cell-v2">
          <div className="overflow-ellipsis">
            <span>
              {factoryQuote.factory_name}
            </span>
          </div>
          <div>
            {factoryQuote.created_on
              ? <>
                Received on {moment(factoryQuote.created_on).format(DATESTAMP_FORMAT)}
              </>
              : <span className="f-color-faded">--</span>}
          </div>
        </div>
        <div className="f-color-faded overflow-ellipsis">
          <IconLocation color="grey-medium" />
          <span className="icon-text">
            {factoryQuote.factory_city}
          </span>
        </div>
        <div className="f-color-faded">
          <IconFactoryMachine color="grey-medium" />
          <span className="icon-text">
            {factoryQuote.factory_machines}
          </span>
        </div>
        <div className={`cell-v2 quote-price quote-price--${quoteAmountDiffStatus}`}>
          {factoryQuote.id
            ? <>
              <div>
                {this.props.currency}&nbsp;{parsedQuote.totalCost}
              </div>
              <div className="quote-price__info">
                {quoteAmountDiff === 0
                  ? 'MATCHED'
                  : <>
                    <span>
                      <span className="icon-text">
                        {this.props.currency}&nbsp;{absQuoteAmountDiff}
                      </span>
                      <IconArrow
                        color={quoteAmountDiff > 0 ? 'red' : 'green'}
                        direction={quoteAmountDiff > 0 ? 'up' : 'down'} />
                    </span>
                  </>
                }
              </div>
            </>
            : <span className="f-color-faded">--</span>}
        </div>

        {/* last col - ctas, status and dropdown */}
        <div className="factory-quote-row__last-col">
          {!anyQuoteAccepted && <div className="quote-cta-list">
            {this.renderFactoryQuoteCta(factoryQuote)}
          </div>}

          {/* quote status */}
          {(anyQuoteAccepted || factoryQuote.status !== QUOTE_STATUS.SUBMITTED)
            && <div className="quote-status cell-v2">
              <div className="quote-status__info">
                {(!anyQuoteAccepted || factoryQuote.status !== QUOTE_STATUS.DRAFT)
                  ? factoryQuote.status === QUOTE_STATUS.WITHDRAWN
                    ? 'Withdrawn'
                    : QUOTE_STATUS_TO_STRING_BRAND[factoryQuote.status]
                  : 'Not received'
                }
                {this._renderPopover(factoryQuote)}
              </div>
              {factoryQuote.modified_on && <div className="quote-status__date">
                {daysAgo(factoryQuote.modified_on)}
              </div>}
            </div>
          }
        </div>
      </div>);
  }

  renderStatusQuotes(statusQuotes) {
    const { anyQuoteAccepted } = this.state;
    return (
      <div className="status-quotes" key={statusQuotes.status}>
        <div className="status-quotes__header">
          {(!anyQuoteAccepted || statusQuotes.status !== QUOTE_STATUS_AS_STRING.DRAFT)
            ? QUOTE_STATUS_TO_STRING_BRAND[statusQuotes.status]
            : 'Not received'
          }
          &nbsp;
          ({statusQuotes.quotes.length})
        </div>
        <div className="status-quotes__list">
          {statusQuotes.quotes.map(quote => this.renderFactoryQuoteRow(quote))}
        </div>
      </div>
    )
  }

  renderDesignDetails() {
    const { quote, currency, closeModal, isSuperAdmin } = this.props;
    const { design } = this.state;
    const designLink = `/${isSuperAdmin ? `factory/brands/${quote.brand_id}` : "brand"}/collections/${quote.design_collection_id}/designs/${quote.design_id}/`;

    let coverPicUrl = design.coverImage.src;

    // if (coverPicUrl != null) {
    //   coverPicUrl = coverPicUrl.fileloc;
    // } else {
    //   coverPicUrl = getCoverImagePlaceholder(design).src
    // }

    return <div className="design-container">
      <div className="cover-pic">
        <img src={coverPicUrl} alt="cover-pic" />
      </div>
      <div className="design-details flex-col">
        <div className="design-name link-blue">
          <Link onClick={closeModal} to={`${designLink}?tab=summary`}>{quote.design_name}</Link>
        </div>
        <div className="design-info">
          <div className="cell-info">
            <div className="label">Target price</div>
            <div className="info">{currency} {quote.target_price}</div>
          </div>
          <div className="cell-info">
            <div className="label">Deadline</div>
            <div className="info">{moment(quote.request_closure_date).format(DATESTAMP_FORMAT)}</div>
          </div>
        </div>
      </div>
      <div>
        <Button className="cta-view-design" category="black-ghost blue">
          <span className="link-blue">
            <Link onClick={closeModal} to={`${designLink}?tab=quote`}>View Design</Link>
          </span>
        </Button>
      </div>
    </div>
  }

  render() {
    const { allStatusQuotes } = this.state;
    return (<PerfectScrollbar>
      <div className="review-quote-modal">
        {this.renderDesignDetails()}
        <div className="quotes-container">
          {allStatusQuotes.map(statusQuotes => this.renderStatusQuotes(statusQuotes))}
        </div>

      </div>
    </PerfectScrollbar>)
  }
}

export default ReviewQuoteModal;
