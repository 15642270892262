import React from "react";

import emptyIcon from "assets/img/Messages/ic-message-empty.svg";

const EmptyList = () => {
  return (
    <div className="empty-list-container">
      <div className="img-container">
        <img src={emptyIcon} alt="Empty! Add Messages" />
      </div>
      <div className="content">
        <div className="heading">Type your first message!</div>
        <div className="description">
          Have all your conversations on this<br />
          design here. Chat away!
        </div>
      </div>
    </div >
  );
};

export default EmptyList;
