export const selectStyles = {
  'customSingleStyles' : {
    control: provided => ({
      ...provided,
      minWidth: 180
    })
  },

  'customMultiStyles' : {
    control: provided => {
      return ({
      ...provided,
      minWidth: 180,
      height: 42
    })}
  },
  
  'moreColorStyles' : {
    control: provided => {
      return ({
      ...provided,
      minWidth: 180,
      // minHeight: 42,
      height: 42
    })},
    valueContainer: styles => ({
      ...styles,
      position: 'initial'
    }), 
    selectContainer: styles => {
      return ({
      // height: 42'
    }) },
    multiValue: styles => ({
      ...styles,
      backgroundColor: 'white',
      borderRadius: 50,
      border: '1px solid var(--fill-grey-light)'
    }),
    multiValueRemove: styles => ({
      ...styles,
      color: 'var(--fill-grey-light)',
      ':hover':{
        background: 'transparent'
      }
    })
  }
}
