import React, { useState } from "react";
import moment from "moment";
import FileDetailsModal from "./FileDetailsModal";

// hane to set scroll ref to modal top =====
const DesignSummary = ({ design, users }) => {
  const [showModal, setShowModal] = useState(false);
  const [activeFile, setActiveFile] = useState({});

  // console.log("RRRRRRRRRRRRRRR", Object.keys(users).map(key => users[key]));

  // console.log("IIIIIIIIIIIIIIII", users);

  const showImageDetailsHandler = () => {
    setShowModal(!showModal);
  };

  const { name, sourceDesignId, category, subCategory, files = [] } = design;

  return (
    <div>
      <div style={{ padding: "16px 0px", position: "relative" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",

            height: "64px",
            padding: "0px 16px"
          }}
        >
          <div
            style={{
              fontSize: "12px",
              color: "var(--fill-grey-medium)",
              width: "200px"
            }}
          >
            Design Name
          </div>

          <div
            style={{
              fontSize: "14px",
              fontWeight: "600",
              color: "var(--fill-locofast-black)",
              width: "250px",
              textAlign: "right"
            }}
          >
            {name}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",

            height: "64px",
            padding: "0px 16px"
          }}
        >
          <div style={{ fontSize: "12px", color: "var(--fill-grey-medium)" }}>
            Design ID
          </div>

          <div
            style={{
              fontSize: "14px",
              fontWeight: "600",
              color: "var(--fill-locofast-black)"
            }}
          >
            {sourceDesignId}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",

            height: "64px",
            padding: "0px 16px"
          }}
        >
          <div style={{ fontSize: "12px", color: "var(--fill-grey-medium)" }}>
            Design Category
          </div>

          <div
            style={{
              fontSize: "14px",
              fontWeight: "600",
              color: "var(--fill-locofast-black)"
            }}
          >
            {`${category} - ${subCategory}`}
          </div>
        </div>
      </div>

      {/* FILES FROM HERE */}
      <div style={{ padding: "16px", lineHeight: "20px" }}>
        <div
          style={{
            fontSize: "14px",
            fontWeight: "600",
            color: "var(--fill-locofast-black)",
            paddingBottom: "16px"
          }}
        >
          Design Files
        </div>

        {/* == Files List == */}

        {files.map(file => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",

                height: "64px"
              }}
            >
              <div>
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "var(--fill-locofast-black)",
                    cursor: "pointer"
                  }}
                  onClick={() => {
                    setActiveFile(file);
                    showImageDetailsHandler();
                  }}
                >
                  {file.fileTitle}
                </div>
                <div
                  style={{ fontSize: "12px", color: "var(--fill-grey-medium)" }}
                >
                  {file.fileCategory}
                </div>
              </div>

              <div
                style={{ fontSize: "12px", color: "var(--fill-grey-medium)" }}
              >
                {`${moment(file.createdAt).format("MMM DD, YYYY")}`}
              </div>
            </div>
          );
        })}
      </div>

      {/* Image Modal starts */}
      {showModal ? (
        <FileDetailsModal
          file={activeFile}
          user={
            Object.keys(users)
              .map(key => users[key])
              .filter(user => user.id == activeFile.createdBy)[0]
          }
          closeModal={showImageDetailsHandler}
        />
      ) : null}
      {/* Image Modal ends  */}
    </div>
  );
};

export default DesignSummary;
