import React, { Component } from "react";
import DatePicker from "react-datepicker";
import styles from "./DesignTna.module.scss";
import moment from "moment";

import { TNA_STATUS, TNA_ATTRIBUTES_ORDER } from "constants/Tna";
import find from "lodash/find";

import MarkCompleteButton from "./MarkCompleteButton";
import Button from "components/UI/Button/Button";
import TnaFiles from "components/Designs/SamplingDetails/SampleFiles";

import { areSameDates } from "../../../helpers/DateHelpers";
import { TIME_DATE_FORMAT, DATESTAMP_FORMAT } from "constants/Date";

import plusIcon from "assets/images/content/ic-plus.png";

const PRODUCTION_SAMPLE_CATEGORY = 3;

export default class FormItem extends Component {
  state = {
    isError: false,
    invalidDate: false,
    emptyTargetValue: false,
    indexError: false,
    newError: false
  };

  static getDerivedStateFromProps(props) {
    const { newError } = props;

    if (newError) {
      return {
        newError
      };
    }

    return null;
  }

  openMarkCompleteModal = (arg1, label, dateLimit) => {
    this.props.openModal("MARK_TNA_ACTION_COMPLETE", {
      handleSubmit: date => {
        this.setState({ isError: false });
        this.props.getData(this.props.id, date, true); // true indicates save and submit
      },
      onUploadFile: this.props.onUploadFile,
      closeModal: this.props.closeModal,
      title: label,
      dateLimit
    });
  };

  openDispatchDetailsModal = () => {
    const { prevDate } = this.props;
    const samplingDetails = find(this.props.sampleDetails.samples, {
      isProductionSample: 1
    });

    this.props.openModal("SAMPLING_ADD_COURIERS_DETAILS", {
      details: {
        data: {
          designId: samplingDetails.designId,
          sampleType: samplingDetails.sampleType,
          sampleStatus: samplingDetails.sampleStatus,
          id: samplingDetails.id,
          factoryId: samplingDetails.factoryId,
        }
      },
      prevTnaDate: prevDate && prevDate.prevActualDate,
      viewSuccessState: true,
      afterSubmit: this.props.fetchTna,
      brandName: this.props.brandName
    });
  };

  render() {
    let {
      label = "Default label",
      swingPercentage = false,
      checkBoxWithPercentageStyles = {
        flexDirection: "row-reverse",
        display: "flex"
      },
      id,
      selectedDate,
      isChecked,
      getTargetValue,
      editMode,
      targetValue = "",
      files,
      showAttachFile,
      onUploadFile,
      prevDate,
      nextDate,
      ppSampleApproval,
      previousFieldData,
      arePlanDatesFilled,
      status,
      categoryOrder,
      skipApproval
    } = this.props;

    if (swingPercentage) {
      checkBoxWithPercentageStyles = {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
      };
    }

    let isPlanDateDisabled = false;

    isPlanDateDisabled =
      editMode &&
      previousFieldData !== undefined &&
      previousFieldData.subAttributeValue === null;

    let isActualDateDisabled = true;
    isActualDateDisabled =
      status === TNA_STATUS.APPROVED &&
      previousFieldData !== undefined &&
      !previousFieldData.attributeValue;

    let dateDifference = {};
    if (selectedDate && targetValue) {
      let diff = moment(targetValue).diff(moment(selectedDate), "days");
      if (diff < 0) {
        dateDifference = {
          label: `${-diff} days delay`,
          class: "red"
        };
      } else if (diff === 0) {
        dateDifference = {
          label: `ON TIME`,
          class: "green"
        };
      } else {
        dateDifference = {
          label: `${diff} days early`,
          class: "green"
        };
      }
      if (nextDate.nextActualDate !== undefined) {
        dateDifference.class = "grey";
      }
    }

    const isSampleDispatchStage =
      label.includes("dispatch") &&
      categoryOrder === TNA_ATTRIBUTES_ORDER.SAMPLE_DISPATCH.categoryOrder;
    const productionSample = find(this.props.sampleDetails.samples, {
      isProductionSample: 1
    });

    // allow tna dispatch date to be set if its not set or if its not equal to PP sample dispatch date
    const doesPPSampleHaveDueDate = (productionSample
      && productionSample.dueDate
      && areSameDates(productionSample.dueDate, targetValue)) ? true : false;

    return (
      <React.Fragment>
        <div className={`${styles.row} ${status === TNA_STATUS.APPROVED ? styles.approved : ''}`}>
          <span className="f-w-semi-bold attribute-label">{label}</span>
          <div className="dates-group">
            <div style={{ checkBoxWithPercentageStyles }}>
              <div className={styles.inner__wrapper_datepicker}>
                {editMode &&
                  (status === TNA_STATUS.CREATED || status === TNA_STATUS.SUBMITTED || status === TNA_STATUS.RESUBMITTED) &&
                  (!isSampleDispatchStage || !doesPPSampleHaveDueDate) ? (
                    <DatePicker
                      withPortal={this.props.withPortal}
                      fixedHeight
                      popperClassName="custom-datepicker-popper"
                      required={isChecked}
                      popperModifiers={{
                        // offset: {
                        //   offset: "0,100%"
                        // },
                        preventOverflow: {
                          editMode: true,
                          escapeWithReference: false // force popper to stay in viewport (even when input is scrolled out of view)
                          // boundariesElement: this.refs["datepickerContainer"]
                        }
                      }}
                      isClearable={editMode && targetValue ? true : false}
                      minDate={prevDate && prevDate.prevPlanDate}
                      maxDate={nextDate && nextDate.nextPlanDate}
                      disabled={isPlanDateDisabled}
                      placeholderText="Select Date"
                      dateFormat="MMMM dd, yyyy"
                      className={"custom-datepicker custom-datepicker-nobg"}
                      selected={moment(targetValue).valueOf()}
                      onChange={arg1 => {
                        // if (targetValue) return;
                        getTargetValue(id, arg1);
                        // getTargetValue(id, arg1);
                      }}
                    />
                  ) : targetValue ? (
                    moment(targetValue).format(DATESTAMP_FORMAT)
                  ) : (
                      "-"
                    )}
              </div>
            </div>
            <div className="flex">
              <div className="flex inner__wrapper_datepicker">
                {!selectedDate && !isActualDateDisabled ? (
                  arePlanDatesFilled &&
                  (categoryOrder > PRODUCTION_SAMPLE_CATEGORY &&
                    skipApproval === 0
                    ? ppSampleApproval.approvedOn
                    : true) &&
                  status === TNA_STATUS.APPROVED &&
                  (isSampleDispatchStage ? (
                    <Button
                      category="blue-bg"
                      onClick={this.openDispatchDetailsModal}
                    >
                      <img src={plusIcon} alt="add" className="plus-icon" />
                      Add dispatch details
                    </Button>
                  ) : (
                      <MarkCompleteButton
                        handleClick={arg1 => {
                          this.openMarkCompleteModal(arg1, label, {
                            minDate: prevDate && prevDate.prevActualDate,
                            maxDate: moment().format(TIME_DATE_FORMAT)
                          });
                        }}
                        onUploadFile={files => onUploadFile(files)}
                      />
                    ))
                ) : selectedDate ? (
                  <span className="date">
                    {moment(selectedDate).format(DATESTAMP_FORMAT)}
                  </span>
                ) : status !== TNA_STATUS.APPROVED && editMode ? null : (
                  "-"
                )}
              </div>
            </div>
            {dateDifference.label && (
              <span className={`date-difference ${dateDifference.class}`}>
                {dateDifference.label}
              </span>
            )}
            {showAttachFile && <TnaFiles files={files} designName={label} />}
          </div>
        </div>

        {status === TNA_STATUS.APPROVED && isSampleDispatchStage && !skipApproval && (
          <div className={`${styles.row} ${status === TNA_STATUS.APPROVED ? styles.approved : ''}`}>
            <span className="f-w-semi-bold attribute-label">
              {productionSample && productionSample.sampleType} approved
            </span>
            <div className="dates-group">
              <div className="flex">
                <div className={styles.inner__wrapper_datepicker}>-</div>
              </div>
              <div className="flex">
                <div className="flex inner__wrapper_datepicker">
                  {ppSampleApproval.approvedOn
                    ? moment(ppSampleApproval.approvedOn).format(
                      DATESTAMP_FORMAT
                    )
                    : "-"}
                </div>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

FormItem.defaultProps = {
  withPortal: false
};
