import React, { Component } from "react";
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { ProfileAvatar } from "../../UI/ProfileAvatar";
import './index.scss';

export default class FactoryList extends Component {
  static propTypes = {
    factories: PropTypes.array.isRequired,
    selected: PropTypes.number.isRequired,
    onSelect: PropTypes.func.isRequired,
    style: PropTypes.object,
  };

  render() {
    const { factories, selected, onSelect, style } = this.props;

    return (
      <div className="messaging-factory-item-list" style={style}>
        {factories.map(factory => (
          <div
            className={classnames(`messaging-factory-item`, {
              'messaging-factory-item--selected': factory.id === selected,
              'messaging-factory-item--disabled': !factory.can_message,
            })}
            onClick={() => onSelect(factory)}
            key={factory.id}
          >
            <div className="messaging-factory-item__avatar">
              <ProfileAvatar
                name={factory.name}
                url={factory.image}
                id={factory.id}
                alias={factory.can_message?null:'var(--fill-grey-light2)'}
              />
            </div>
            <div className="messaging-factory-item__name overflow-ellipsis" >
              {factory.name}
            </div>
            {factory.unread_count > 0 && <div className="messaging-factory-item__unread-count">
              {factory.unread_count}
            </div>}
          </div>)
        )}
      </div>
    );
  }
}
