import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import "./TnaImageModal.scss";
import { computePath } from "../../helpers/pathHelper";
import Button from "components/UI/Button/Button";
import alertIcon from "assets/images/communication/ic-alert.svg";
class UpdateTnaRedirect extends Component {
  render() {
    const { hiddenFields, closeModal } = this.props;
    const [brand, collection, design] = hiddenFields;

    return (
      <div className="tna-redirect-modal">
        <img src={alertIcon} className="alert-icon" alt="alert" />
        <div className="f-sz-14 content">
          <h3>TNA pending</h3>
          <p>
            You need to create a time and action plan before you can add the
            dispatch details.
          </p>
        </div>
        <div className="quote-request-footer">
          <footer>
            <Button
              category="btn black-ghost medium"
              onClick={() => {
                closeModal();
              }}
            >
              CANCEL
            </Button>

            <Button
              category="btn primary-blue"
              onClick={() => {
                closeModal();
                const path = computePath();
                this.props.history.push(
                  `/${path}/brands/${brand.value}/collections/${collection.value}/designs/${design.value}/?tab=designtna`
                );
              }}
            >
              UPDATE TNA
            </Button>
          </footer>
        </div>
      </div>
    );
  }
}

export default withRouter(UpdateTnaRedirect);
