import React, { Component } from "react";

import styles from "./DesignTna.module.scss";

const Header = ({
  headerLabel = "Default label",
  noClass = false,
  overrideClass,
  children
}) => (
  <div
    className={`${noClass ? "noClass" : styles.header} ${
      overrideClass ? styles[overrideClass] : ""
    } tna__heading_item` }
  >
    <div className={styles.primary__heading}>{headerLabel}</div>
    {children}
  </div>
);

export default Header;
