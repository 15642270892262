import React, { useEffect } from "react";
import classnames from "classnames";
import moment from "moment";
import s from "./QuoteList.module.scss";
import { TIMESTAMP_FORMAT } from "../../../constants/Date";
import { QUOTE_STATUS } from "../../../constants/Quote";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";

// comment:
// null
// created_by:
// 76
// created_on:
// "2019-05-23T06:51:45.000Z"
// design_id:
// 352
// id:
// 206
// modified_by:
// 76
// modified_on:
// "2019-05-23T06:52:26.000Z"
// modifier_name:
// "loco super user"
// name:
// "loco super user"
// quote:
// "{\"table\":{\"fabrics\":[{\"name\":\"Fabric\",\"consumption\":10,\"rate\":20}],\"trims\":[{\"name\":\"M / Label\",\"consumption\":12,\"rate\":32},{\"name\":\"Wash Care Label\"},{\"name\":\"Hang Tag\",\"consumption\":45,\"rate\":65},{\"…"
// quote_type:
// "OPEN"
// status:
// "0"
// total_cost:
// 0
// total_quantity:
// 0

const StatusAccepted = ({ quote }) => (
  <div
    className="f-sz-sm"
    style={{
      color: "var(--fill-grey-medium)",
      marginBottom: "0"
    }}>
    <div
      style={{
        color: "var(--fill-green-dark)",
        lineHeight: "1.33",
        fontWeight: "bold"
      }}>
      ACCEPTED
    </div>
    <div>by {quote.modifier_name}</div>
    <div>{moment(quote.modified_on).format(TIMESTAMP_FORMAT)}</div>
  </div>
);

const StatusResubmit = ({ quote }) => (
  <div
    className="f-sz-sm"
    style={{
      color: "var(--fill-grey-medium)",
      marginBottom: "0"
    }}>
    <div
      style={{
        color: "#ffa552",
        lineHeight: "1.33",
        fontWeight: "bold"
      }}>
      RESUBMISSION REQUESTED
    </div>
    <div>by {quote.modifier_name}</div>
    <div>{moment(quote.modified_on).format(TIMESTAMP_FORMAT)}</div>
  </div>
);

const StatusSubmitted = () => (
  <div
    className="f-sz-sm"
    style={{
      lineHeight: "1.33",
      fontWeight: "bold",
      color: "var(--fill-grey-medium)",
      marginBottom: "0"
    }}>
    RECEIVED
  </div>
);

const renderLeft = ({ quote, ...props }) => (
  <div className={classnames("flex-col", s.left)}>
    <div
      style={{
        fontWeight: "600",
        lineHeight: "1.43",
        color: "var(--fill-locofast-black)"
      }}>
      {moment(quote.created_on).format(TIMESTAMP_FORMAT)}
    </div>
    {
      {
        [QUOTE_STATUS.SUBMITTED]: <StatusSubmitted />,
        [QUOTE_STATUS.RESUBMISSION]: <StatusResubmit quote={quote} />,
        [QUOTE_STATUS.ACCEPTED]: <StatusAccepted quote={quote} />
      }[Number(quote.status)]
    }
  </div>
);

const renderRight = ({ quote }) => (
  <div className="flex-col" style={{ textAlign: "right" }}>
    <div
      className="f-w-semi-bold"
      style={{ marginBottom: "4px", lineHeight: "1.43" }}>
      Rs {JSON.parse(quote.quote).totalCost}
      {/* {
        {
          BUNDLED: ,
          OPEN: quote.total_cost
        }[quote.quote_type]
      } */}
    </div>
    <div
      className="f-sz-sm"
      style={{ textTransform: "uppercase", color: "var(--fill-grey-dark)" }}>
      {quote.quote_type} QUOTE
    </div>
  </div>
);

const QuoteList = ({ quoteDetails, getQuoteDetails, ...props }) => {
  useEffect(() => {
    getQuoteDetails();
  }, []);
  return (
    <div style={{ padding: "12px" }}>
      <br />
      {/* TODO: Implement when overall scrollbar reduced */}
      {/* <PerfectScrollbar> */}
      {Object.values(quoteDetails).map(quote => (
        <QuoteCard quote={quote} />
      ))}
      {/* </PerfectScrollbar> */}
    </div>
  );
};

export const QuoteCard = ({ quote, className = "", style = {} }) => (
  <Link
    to={{
      pathname: "../quote/" + quote.id + "/"
    }}
    style={style}
    className={classnames(
      "card",
      s.quote_card,
      {
        [QUOTE_STATUS.SUBMITTED]: "",
        [QUOTE_STATUS.RESUBMISSION]: s.quote__resubmit,
        [QUOTE_STATUS.ACCEPTED]: s.quote__accepted
      }[Number(quote.status)],
      className
    )}>
    <div>{renderLeft({ quote })}</div>
    <div style={{ marginLeft: "auto" }}>{renderRight({ quote })}</div>
  </Link>
);

export default QuoteList;
