import { handleActions } from "redux-actions";

const INITIAL_STATE = {
  brandsList: [],
  factories: [],
  factoriesForQuotes: [],
  selectedUserAddresses: null,
  cities : null
};
const FactoryReducer = handleActions(
  {
    GET_FACTORY_BRANDS_NEW: (state, action) => {
      return {
        ...state,
        brandsList : action.payload
      };
    },
    GET_ALL_FACTORIES_ACCOUNT: (state, action) => {
      if(!action.payload.isFiltered){
      return {
          ...state,
          factories: action.payload.data
        };
      }
      return state;
    },

    SET_TRANSFORMED_FACTORIES: (state, action) => {
      const transformedFactories = action.payload.data.map((factory) => ({
        label: factory.name,
        id: factory.id,
        value: factory.id,
      }));

      if(action.payload.isFiltered){
        return {
          ...state,
          transformedFilterFactories: transformedFactories
        }
      }

      return {
        ...state,
        transformedFactories
      };
    },
    GET_ALL_FACTORIES_FOR_QUOTES: (state, action) => {
      return {
        ...state,
        factoriesForQuotes: action.payload.data
      };
    },
    GET_SELECTED_USER_ADDRESSES: (state, action) => {
      return {
        ...state,
        selectedUserAddresses: action.payload.data
      };
    },
    GET_ALL_CITIES: (state, action) => {
      return {
        ...state,
        cities: action.payload
      };
    },
    LOGOUT: (state, action) => {
      return {
        ...state,
        ...INITIAL_STATE
      };
    }

  },
  INITIAL_STATE
);

export default FactoryReducer;
