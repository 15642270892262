import React from "react";
import classnames from "classnames";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import { ReactComponent as CalendarIcon } from "assets/images/action/ic-calendar-today.svg";

import "./style.scss";


const CustomDatePicker = ({ disabled, value, onClick }) => (
  <div
    tabIndex="0"
    className={classnames("custom_datepicker formik_datepickerf flex", {
      'disabled': disabled,
    })}
    type="date"
    onClick={onClick}
  >
    {value
      ? <span className="flex-grow">{value}</span>
      : (
        <span className="f-color-faded">
          Select date
        </span>
      )}
    <CalendarIcon />
  </div>
);

const DatePicker = ({
  input,
  label,
  required,
  placeholder = "",
  className,
  inline,
  minDate = new Date(),
  maxDate,
  defaultValue,
  customInput: CustomInput = CustomDatePicker,
  disabled,
  ...props
}) => {
  const { name, value, onChange } = input;

  return (
    <div className={`rf-datepicker ${className}`}>
      <ReactDatePicker
        name={name}
        selected={(value && new Date(value)) || defaultValue}
        onChange={date =>
          onChange(moment(date).format("YYYY-MM-DD"))
        }
        placeholderText={placeholder}
        minDate={minDate}
        maxDate={maxDate}
        dateFormat="MMMM dd, yyyy"
        customInput={CustomInput && <CustomInput />}
        showPopperArrow={false}
        inline={inline}
        disabled={disabled}
        {...props}
      />
    </div>
  );
};

export default DatePicker;
