import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import Product from "../../components/Product";
import actions from "../../store/actions";

import axios from "axios";

import { get_service_endpoint } from "../../ServiceEndpoints";
import { Helmet }from 'react-helmet';

import {
  FACTORY_ADMIN,
  FACTORY_MANAGER,
  LOCO_SUPER,
  PRODUCT_DEVELOPMENT,
  PRODUCTION_MERCHANT
} from "../../constants/Auth";
import { PAGE_TITLES } from "../../constants/Titles";
const eq = get_service_endpoint("notificationView");

const Products = ({
  designs,
  getAllDesigns,
  openModal,
  allBrands,
  getAllBrands,
  prevSavedDesigns,
  userDetails,
  saveSelecteDesigns,
  match,
  usersList,
  getUsers
}) => {
  // == These are colections when brand is loggen in =====
  const [collectionForBrand, setcollectionForBrand] = useState([]);
  const [currentUser, setCurrentUser] = useState(false);

  const isFactory =
    userDetails.role === LOCO_SUPER ||
    userDetails.role === FACTORY_ADMIN ||
    userDetails.role === FACTORY_MANAGER;

  const isManager = (userDetails.role === PRODUCT_DEVELOPMENT || userDetails.role === PRODUCTION_MERCHANT);

  const isFactoryAdminManager = userDetails.role === FACTORY_ADMIN || userDetails.role === FACTORY_MANAGER


  useEffect(() => {
    if (!isFactory && !isManager) {
      // === get all collections and then call getAllDesigns along with all collections //
      if (!Object.keys(usersList).length) {
        getUsers();
      }

      if (Object.keys(usersList).length) {
        const fetchData = async () => {
          let currentUser = "";
          Object.keys(usersList).forEach(key => {
            if (key == userDetails.id) {
              currentUser = usersList[key].brand;
            }
          });
          setCurrentUser(currentUser);
          const result = await axios.get(`${eq}/orders/collections`, {
            params: { brandid: currentUser }
          });
          const collections = Object.keys(result.data).map(
            key => result.data[key]["id"]
          );
          //  getAllDesigns({ limit: 20, init: 1, col: JSON.stringify(collections) });
          setcollectionForBrand(result.data);
        };
        fetchData();
      }
    }
  }, [usersList]);

  //  === this gets hitted on fosctory ====
  useEffect(() => {
      let filter = { limit: 20, init: 1, isnext: 0 };
      if(isFactoryAdminManager){
        if (Object.keys(usersList).length) {
          let factoryId;
          Object.keys(usersList).forEach(key => {
            if (key == userDetails.id) {
              factoryId = usersList[key].brand;
            }
          });
          filter.factory = factoryId;
          getAllDesigns({...filter});
          getAllBrands();
        }
      } else if(isFactory|| isManager){
        getAllDesigns({...filter});
        getAllBrands();
      } else {
        filter.brandid = userDetails.brandId;
        getAllDesigns({...filter});
      }
  }, [usersList]);

  const pathVariables = match.path.split("/");
  const tab = pathVariables[2];
  const stage = pathVariables[3];

  return (
    <React.Fragment>
      <Helmet
        bodyAttributes={{
            class: 'body_container'
        }}
        title={PAGE_TITLES.sampling()}
      />
      <Product
        designs={designs}
        openModal={openModal}
        getAllDesigns={getAllDesigns}
        allBrands={allBrands}
        prevSavedDesigns={prevSavedDesigns}
        isFactory={isFactory}
        saveSelecteDesigns={saveSelecteDesigns}
        collectionForBrand={collectionForBrand}
        currentUser={currentUser}
        tab={tab}
        stage={stage}
        brandId={userDetails.brandId}
      />
    </React.Fragment>

  );
};

const mapStateToProps = ({ products, auth }) => ({
  designs: products.designs,
  allBrands: products.allBrands,
  prevSavedDesigns: products.prevSavedDesigns,
  userDetails: auth.userDetails,
  usersList: auth.list
});

const mapDispatchToProps = dispatch => ({
  getAllDesigns: params => dispatch(actions.getAllDesigns(params)),
  getAllBrands: () => dispatch(actions.getAllBrands()),
  openModal: (name, additionalProps) =>
    dispatch(actions.openModal(name, additionalProps)),
  saveSelecteDesigns: params => dispatch(actions.saveSelecteDesigns(params)),
  getUsers: () => dispatch(actions.getUsers())
});

export default connect(mapStateToProps, mapDispatchToProps)(Products);
