import React, { Component } from "react";
import moment from "moment";
import { Link } from "react-router-dom";

import { getImageUrl } from "helpers/DesignHelpers";
import { computePath } from "helpers/pathHelper";

export default class Quote extends Component {
  relativePath = (data, tab) => {
    const { brand, collection_id, design_id } = data;
    const path = computePath();
    switch (path) {
      case "factory":
        return `/${path}/brands/${brand}/collections/${collection_id}/designs/${design_id}/?tab=${tab}`;
      default:
        return `/brand/collections/${collection_id}/designs/${design_id}/?tab=${tab}`;
    }
  };

  dateDiff = (date) => {
    const formattedDate = moment(date);
    const daysDiff = moment().diff(formattedDate, "days");
    if(daysDiff <= 10 ) return moment(date).fromNow();
    return ` on ${formattedDate.format("LL")}`;
  }

  render() {
    const { data, filter, tab, cta } = this.props;
    const { design_name, sent_date, cover_pic_url, category } = data;
    return (
      <div className="quote">
        <div className="quote-status-section">
          <span className="status-section-img">
            <img
              src={getImageUrl(cover_pic_url, { category })}
              className="status-img"
              alt="user avatar"
            />
          </span>
          <div className="status-section-content">
            <span className="status-title-top">{design_name}</span>
            <span className="status-title-bottom hover-none">
              {filter === 'quote' ?
              'Received' :
              'Dispatched'} {this.dateDiff(sent_date)}
            </span>
            <Link
              className="dashboard-cards-end"
              to={this.relativePath(data, tab)}
            >
              <span className="status-title-bottom blu-title d-none">
                {cta}
              </span>{" "}
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
