import React, { useState } from 'react';
import starIcon from '../../../assets/images/ic_star.svg';
import starIconSelected from '../../../assets/images/ic_star_selected.svg';
import './index.scss';

const STARS_ARRAY = [...new Array(5)];

const StarRating = (props) => {
  const [rating, setRating] = useState(props.initialRating || null);
  const [hover, setHover] = useState(null);

  function handleRatingChange(ratingValue) {
    setRating(ratingValue);
    props.updateRating(ratingValue);
  }

  return (
    <div className='star-rating'>
      {STARS_ARRAY.map((star, i) => {
        const ratingValue = i + 1;
        const status = ratingValue <= (hover || rating) ? 'selected' : 'non-selected';

        return (
          <label key={i}>
            <input
              type='radio'
              name='rating'
              value={ratingValue}
              onClick={() => handleRatingChange(ratingValue)}
            />
            <img
              className='star-rating__icon'
              src={status === 'selected' ? starIconSelected : starIcon}
              onMouseEnter={() => setHover(ratingValue)}
              onMouseOut={() => setHover(null)}
              alt='star-icon'
            />
          </label>
        );
      })}
    </div>
  );
};

export default StarRating;
