import React from 'react';
import { ProfileAvatar } from '../ProfileAvatar';
import './index.scss';

export default class ProfilePlate extends React.Component {
  render() {
    const { profile } = this.props;

    return (<div className="profile-plate">
      <ProfileAvatar
        name={profile.name}
        url={profile.url || profile.image}
      />
      <div className="profile-details">
        <div className="profile-name">{profile.name}</div>
        <div className="profile-email">{profile.email}</div>
      </div>
    </div>);
  }
}
