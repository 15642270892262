import React, { useState } from "react";
import moment from "moment";
import classnames from "classnames";

import { HeaderTabs } from "components/Dashboard/HeaderTabs";
import { DATESTAMP_FORMAT } from "constants/Date";
import { TextSpliced } from "components/UI/TextSpliced";

import { ReactComponent as InwardsIcon } from "assets/images/materialLibrary/inwards.svg";
import { ReactComponent as OutwardsIcon } from "assets/images/materialLibrary/outwards.svg";
import { ReactComponent as AttachmentIcon } from "assets/images/editor/ic-attach-file.svg";
import { ReactComponent as ArrowIcon } from "assets/images/navigation/ic-chevron-down-blue.svg";

import StaticTable from "../../SizeTable/StaticTable";
import Button from "../../../UI/Button/Button";

const filtersList = {
  inwards: {
    label: "INWARD",
    value: "inwards",
    component: props => <InwardEntry {...props} />
  },
  outwards: {
    label: "OUTWARD",
    value: "outwards",
    component: props => <OutwardEntry {...props} />
  }
};

const useToggle = (initialValue = false) => {
  const [value, setValue] = useState(initialValue);
  const toggleValue = () => setValue(!value);
  return [value, toggleValue];
};

const formatInwardDate = (expectedInwardDate, actualInwardDate) => {
  if (actualInwardDate) {
    return moment(actualInwardDate).format(DATESTAMP_FORMAT);
  }

  const momentInwardDate = moment(expectedInwardDate);
  const diff = momentInwardDate.diff(moment().startOf('day').add(1, 'day'));
  const strDateInfo = `expected by ${momentInwardDate.format(DATESTAMP_FORMAT)}`;

  if (diff >= 0) {
    return strDateInfo;
  }

  return `was ${strDateInfo}`;
}

const InwardEntry = ({
  items,
  unit,
  openModal,
  image,
  closeModal,
  switchViews,
  updateInwardEntry,
}) => {
  return items.map(
    (item) => {
      const { vendorDetails, expectedInwardDate, actualInwardDate, quantity, price, size = 0, poFile, invoiceFile } = item;
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const [isOpen, setIsOpen] = useToggle();

      return (
        <div className="material__stock_container">
          <div className="material__stock inwards">
            <div>
              <InwardsIcon />
            </div>
            <div>
              <div className="name">
                <TextSpliced text={vendorDetails.company} maxWidth="250" />
              </div>
              <div className="subtext">
                <span className="subtext__date">
                  {formatInwardDate(expectedInwardDate, actualInwardDate)}
                </span>
                {!actualInwardDate && <Button
                  category="primary-blue sm"
                  onClick={() => updateInwardEntry(item)}
                >
                  Update
                </Button>}
              </div>
            </div>
            <div className="t-align-right">
              {quantity}{" "}
              {unit.shortName}
            </div>
            <div className='flex flex-col'>
              <div className="t-align-right">
                ₹{price} / {unit.shortName}
              </div>
              <div className='flex justify-end'>
                {poFile && (
                    <div className="subtext">
                      <a href={poFile} className="attached-file">
                        {" "}
                        <AttachmentIcon /> <span>PO</span>
                      </a>
                    </div>
                )}
                {invoiceFile && (
                  <div className="subtext">
                    <a href={invoiceFile} className="attached-file">
                      {" "}
                      <AttachmentIcon /> <span>INV</span>
                    </a>
                  </div>
                )}
            </div>
            </div>

            {size.length > 0
              && (
                <div
                  onClick={setIsOpen}
                  className={classnames({
                    size__accordion: true,
                    opened: isOpen
                  })}
                >
                  <ArrowIcon />
                </div>
              )}
          </div>
          {size.length > 0 && isOpen && (
            <StaticTable sizes={size} tableHeading={`Quantity(${unit.unit})`} />
          )}
        </div>
      );
    }
  );
};

const OutwardEntry = ({ items, unit, switchViews }) => {
  return items.map(
    ({
      factoryDetails,
      designDetails,
      createdAt,
      quantity,
      challanFile,
      size
    }) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const [isOpen, setIsOpen] = useToggle();

      return (
        <div className="material__stock_container">
          <div className="material__stock outwards">
            <div>
              <OutwardsIcon />
            </div>
            <div>
              <div className="name">
                <TextSpliced text={factoryDetails.name} maxWidth="230" />
              </div>
              <div className="subtext">
                {moment(createdAt).format(DATESTAMP_FORMAT)}
              </div>
            </div>
            {
              designDetails ? (
                <div>
                <div>
                  <TextSpliced text={designDetails.name} maxWidth="230" />
                </div>
                <div className="subtext">{designDetails.sourceDesignId}</div>
              </div>
              ) : '--'
            }

            <div className="col-qty">
              <div>
                {quantity} {unit.shortName}
              </div>
              {challanFile && (
                <div className="subtext">
                  <a href={challanFile} className="attached-file">
                    {" "}
                    <AttachmentIcon /> <span>Challan</span>
                  </a>
                </div>
              )}
            </div>
            {size.length > 0 && (
              <div
                onClick={setIsOpen}
                className={classnames({
                  size__accordion: true,
                  opened: isOpen
                })}
              >
                <ArrowIcon />
              </div>
            )}
          </div>
          {size.length > 0 && isOpen && (
            <StaticTable sizes={size} tableHeading={`Quantity(${unit.unit})`} />
          )}
        </div>
      );
    }
  );
};

const StockDetails = props => {
  const [filter, setFilter] = useState(filtersList["inwards"].value);
  return (
    <React.Fragment>
      <div className="material__description_heading with_tabs">
        Stock I/O
        <HeaderTabs
          onChange={value => {
            setFilter(value);
          }}
          tabs={Object.values(filtersList)}
        />
      </div>
      <div>
        {filtersList[filter].component({
          items: props[filter],
          ...props
        })}
      </div>
    </React.Fragment>
  );
};

export default StockDetails;
