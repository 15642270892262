import React, { useState, useCallback } from 'react';
import Select from 'react-select';
import BaseSelect from './BaseSelect';
import Button from 'components/UI/Button/Button';
import { IconPlus } from 'components/UI/Icons/index';

const RFReactCreatableSelect = ({
  addCustomText,
  minCustomOptionLength = 2,
  maxCustomOptionLength = 50,
  onAddOption,
  ...props
}) => {
  const [isNewOptionVisible, setNewOptionVisible] = useState(false);
  const [newOptionValue, setNewOptionValue] = useState('');

  const handleInputChange = useCallback((inputValue, { action }) => {
    if (action !== 'input-change') {
      return;
    }

    const strippedValue = inputValue.trim();
    const exists = props.options.some(item => item.label.toLowerCase().includes(strippedValue));

    setNewOptionVisible(!exists
      && strippedValue.length >= minCustomOptionLength
      && strippedValue.length <= maxCustomOptionLength
      );

    if (!exists) {
      setNewOptionValue(inputValue);
    }
  }, [props.options]);

  const handleAddNewOption = useCallback(() => {
    onAddOption(newOptionValue)
      .then((newOption) => {
        props.input.onChange(String(newOption.id));
        setNewOptionVisible(false);
      });
  }, [newOptionValue, onAddOption]);

  return <BaseSelect
    {...props}
    selectProps={{
      onInputChange: handleInputChange,
      ...props.selectProps
    }}
    select={Select}
  >
    {isNewOptionVisible && <Button
      className="cta-add-custom"
      category="grid-2"
      type="button"
      onClick={handleAddNewOption}
    >
      <IconPlus color="blue" />
      <span>{addCustomText || 'Add new'}</span>
    </Button>}
  </BaseSelect>;
};

export default RFReactCreatableSelect;
