import React, { Component } from "react";
import './style.scss';

class ConfirmWithdrawQuoteModal extends Component {
  render() {
    return (
      <div className="withdraw-quote-modal__content">
        Once the request is withdrawn, the factory will no longer have access to this design.
      </div>
    );
  }
}

export default ConfirmWithdrawQuoteModal;
