import React, { Component } from "react";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";

import ImgPlus from "assets/images/content/ic-plus.png";

import "./CollectionList.scss";

import CollectionCard from "./../CollectionCard/CollectionCard";
import Button from "./../../UI/Button/Button";
import InfoButton from "../../UI/InfoButton/InfoButton";
import moment from "moment";
import {
  FACTORY_ADMIN,
  FACTORY_MANAGER,
  CLIENT,
  CLIENT_SUPER,
  PRODUCTION_MERCHANT,
} from "../../../constants/Auth";
import Filter from "../../Product/Filter";
import PrivateContainer from "../../UI/PrivateContainer/PrivateContainer";

export default class CollectionList extends Component {
  static propTypes = {
    openModal: PropTypes.func.isRequired,
    collections: PropTypes.object.isRequired,
  };

  render() {
    const {
      collections,
      openModal,
      userDetails,
      designList,
      openNewDesignModal,
      openNewCollectionModal,
      userRole,
      brands,
      currentBrand,
      goToBrand,
      factories=[],
    } = this.props;

    const transformedBrands = brands.map((brand) =>
      brand.name
        ? { label: brand.name, id: brand.id, value: brand.id }
        : { label: brand.brand_name, id: brand.brand_id, value: brand.brand_id }
    );

    const transformedFactories = factories.map((factory) => ({
      label: factory.name,
      id: factory.id,
      value: factory.id,
    }));

    let selected;
    if(userDetails.role === PRODUCTION_MERCHANT){
      selected = transformedFactories.find(
        (brand) => brand.id == currentBrand.brandId
      );
    } else {
      selected = transformedBrands.find(
        (brand) => brand.id == currentBrand.brandId
      );
    }

    return (
      <div className="collections custom-scroll-container">
        <PrivateContainer denyRoles={[CLIENT, CLIENT_SUPER]}>
          <div className="flex collections__header">
            <div className="downshift-filter-container">
              <PrivateContainer denyRoles={[PRODUCTION_MERCHANT]}>
                <Filter
                  title="Brand"
                  innerWidth="160px"
                  width="160px"
                  height="auto"
                  initialSelectedItem={selected}
                  onChange={(selection) => {
                    goToBrand(selection.id);
                  }}
                  selectedItem={selected}
                  filterOptions={transformedBrands}
                  marginRight="20px"
                />
              </PrivateContainer>
              <PrivateContainer allowRoles={[PRODUCTION_MERCHANT]}>
                <Filter
                  title="Factory"
                  innerWidth="160px"
                  width="160px"
                  height="auto"
                  initialSelectedItem={selected}
                  onChange={(selection) => {
                    goToBrand(selection.id);
                  }}
                  selectedItem={selected}
                  filterOptions={transformedFactories}
                  marginRight="20px"
                />
              </PrivateContainer>
            </div>
          </div>
        </PrivateContainer>

        <div className="content-container">
          {Object.keys(collections).length > 0 ? (
            <React.Fragment>
              {/* change perfect scroll bar to div */}
              <div className="collections__content">
                {Object.keys(collections)
                  .sort((a, b) => {
                    return moment(collections[a]["created_on"]).isBefore(
                      collections[b]["created_on"]
                    )
                      ? 1
                      : -1;
                  })
                  .map((collectionId) => (
                    <CollectionCard
                      key={collectionId}
                      openNewDesignModal={() =>
                        openNewDesignModal(collectionId)
                      }
                      designs={designList.filter((design) => {
                        return (
                          Number(design.collectionId) === Number(collectionId)
                        );
                      })}
                      isOwner={
                        collections[Number(collectionId)] &&
                        (userDetails.id ===
                          collections[Number(collectionId)].userid ||
                          userDetails.role === "LOCO_SUPER")
                      }
                      hideAssociation={userDetails.role === "LOCO_SUPER"}
                      openModal={openModal}
                      collection={collections[collectionId]}
                      userRole={userDetails.role}
                    />
                  ))}
              </div>
              <div className="collections__footer">
                {userRole !== FACTORY_ADMIN &&
                  userRole !== FACTORY_MANAGER &&
                  userRole != PRODUCTION_MERCHANT && (
                    <Button
                      data-tip="Add Collection"
                      data-place="left"
                      className="collections__new-btn"
                      onClick={openNewCollectionModal}
                      shadow={true}
                      category="circle"
                    >
                      <img src={ImgPlus} alt="" />
                    </Button>
                  )}
              </div>
            </React.Fragment>
          ) : (
            <div className="collections__content">
              {userRole !== FACTORY_ADMIN &&
              userRole !== FACTORY_MANAGER &&
              userRole !== PRODUCTION_MERCHANT ? (
                <div className="collections__new-collection">
                  <InfoButton
                    icon={<img src={ImgPlus} alt="" />}
                    onClick={openNewCollectionModal}
                    title="Create a new collection"
                    subtitle="A collection is a group of designs. All your collections will appear here."
                    btnContainerClass="info-btn__pink"
                    extraBtnClass="info-btn__filled"
                  />
                </div>
              ) : (
                "No Collections Added"
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}
