import React from "react";
import { cloneDeep, sum, find } from "lodash";
import Button from "../../Button/Button";
import SideDrawer from "../../SideDrawer/index";
import Checkbox from "../../Checkbox/Checkbox";
import { ReactComponent as ImgAdd } from "assets/images/content/ic-add.svg";
import "./index.scss";
import Radio from "../../Radio";

// TODO: break me into modular filter component with their own state

const MAX_FILTER_ITEMS = 10;

export default class FilterDrawer extends React.Component {
  state = {
    mapFilters: {
      /* == mapFilters example when stored ==
      'filter-key': {
        key: '',
        title: '',
        allKey: '',
        hasAllKey: false,
        mapItems: {
          'item-key-1': {
            ...,
            isSelected: false,
          },
          'item-key-2': {},
        },
        items: [{}],
      }
      */
    },
    mapFiltersCount: {}
  };

  constructor(props) {
    super(props);

    this.state.filters = this.transformFilters(props.filters, props.userRole);
  }

  UNSAFE_componentWillReceiveProps(nextProps, oldProps) {
    if (nextProps.filters !== oldProps.filters) {
      const nextFilters = this.transformFilters(
        nextProps.filters,
        nextProps.userRole
      );
      this.setState({
        filters: nextFilters,
        mapFiltersCount: nextFilters.reduce((prev, curr) => {
          prev[curr.key] = curr.items.filter(item => item.isSelected).length;
          return prev;
        }, {})
      });
    }

    if (nextProps.isVisible !== oldProps.isVisible) {
      if (nextProps.isVisible) {
        window.addEventListener("keydown", this.escHandler, false);
      } else {
        window.removeEventListener("keydown", this.escHandler, false);
      }
    }
  }

  escHandler = event => {
    if (event.keyCode === 27) {
      typeof this.props.onClose === "function" && this.props.onClose();
    }
  };

  transformFilters = (filters, userRole) => {
    const nextFilters = cloneDeep(filters).filter(
      filter =>
        !userRole ||
        ((!Array.isArray(filter.allowRoles) ||
          filter.allowRoles.includes(userRole)) &&
          (!Array.isArray(filter.denyRoles) ||
            !filter.denyRoles.includes(userRole)))
    );
    // .forEach(nextFilter => {
    //   nextFilter.hasAllKey = nextFilter.allKey !== undefined;
    //   nextFilter.items = this.sortFilterItems(nextFilter);
    //   nextFilter.showRemainingItems = false;
    // })
    return nextFilters;
  };

  sortFilterItems = filter => {
    return filter.items.sort((item1, item2) => {
      if (item1.key === filter.allKey) {
        return 2;
      }
      if (item2.key === filter.allKey) {
        return -2;
      }
      return item1.key > item2.key ? 1 : -1;
    });
  };

  showRemainingFilterItems = selectedFilter => {
    const { filters } = this.state;

    this.setState({
      filters: filters.map(filter => {
        if (filter.key === selectedFilter.key) {
          return {
            ...filter,
            showRemainingItems: true
          };
        }
        return filter;
      })
    });
  };

  handleFilterItemChanged = (e, filter, filterItem) => {
    const { filters } = this.state;
    const newSelectedState = !filterItem.isSelected;

    // if filter has "All" Key and its changed
    if (filter.hasAllKey && filter.allKey === filterItem.key) {
      const nextFilters = filters.map(itFilter => {
        if (itFilter.key !== filter.key) {
          return itFilter;
        }

        return {
          ...itFilter,
          items: itFilter.items.map(item => ({
            ...item,
            isSelected: newSelectedState
          }))
        };
      });

      this.setState({
        filters: nextFilters,
        mapFiltersCount: nextFilters.reduce((prev, curr) => {
          prev[curr.key] = curr.items.filter(item => item.isSelected).length;
          return prev;
        }, {})
      });

      return;
    }

    const nextFilters = filters.map(itFilter => {
      if (itFilter.key !== filter.key) {
        return itFilter;
      }

      return {
        ...itFilter,
        items: itFilter.items.map(item => {
          if (item.key === itFilter.allKey) {
            return {
              ...item,
              isSelected: false
            };
          }

          if (item.key !== filterItem.key) {
            return item;
          }

          return {
            ...item,
            isSelected: newSelectedState
          };
        })
      };
    });

    this.setState({
      filters: nextFilters,
      mapFiltersCount: nextFilters.reduce((prev, curr) => {
        prev[curr.key] = curr.items.filter(item => item.isSelected).length;
        return prev;
      }, {})
    });
  };

  handleSingleFilterItemChanged = (e, filter, filterItem) => {
    const { filters } = this.state;
    const nextFilters = filters.map(itFilter => {
      if (itFilter.key !== filter.key) {
        return itFilter;
      }

      return {
        ...itFilter,
        items: itFilter.items.map(item => {
          if (item.key !== filterItem.key) {
            return {
              ...item,
              isSelected: false
            };
          }

          return {
            ...item,
            isSelected: true
          };
        })
      };
    });

    this.setState({
      filters: nextFilters,
      mapFiltersCount: nextFilters.reduce((prev, curr) => {
        prev[curr.key] = curr.items.filter(item => item.isSelected).length;
        return prev;
      }, {})
    });
  };

  clearFilters = () => {
    const { filters } = this.state;

    filters.forEach(filter => {
      filter.items.forEach(filterItem => {
        filterItem.isSelected = false;
      });
    });

    this.setState({ filters: [...filters], mapFiltersCount: {} });

    this.applyFilters(filters);
  };

  clearFilter = filter => {
    const { filters, mapFiltersCount } = this.state;

    this.setState({
      filters: filters.map(itFilter => {
        if (itFilter.key !== filter.key) {
          return itFilter;
        }

        return {
          ...itFilter,
          items: itFilter.items.map(item => ({
            ...item,
            isSelected: false
          }))
        };
      }),
      mapFiltersCount: {
        ...mapFiltersCount,
        [filter.key]: 0
      }
    });
  };

  applyFilters = filters => {
    let selectedFilterValuesCount = 0;
    filters = filters || this.state.filters;

    const selectedFilters = filters.reduce((prev, filter) => {
      const selectedFilterValues = filter.items
        .filter(filterItem => filterItem.isSelected)
        .map(filterItem => filterItem.value);

      selectedFilterValuesCount += selectedFilterValues.length;

      prev[filter.key] = {
        name: filter.key,
        values: selectedFilterValues
      };

      return prev;
    }, {});

    this.props.onApplyFilters &&
      this.props.onApplyFilters({
        filters: selectedFilters,
        filtersCount: selectedFilterValuesCount
      });

    typeof this.props.onClose === "function" && this.props.onClose();
  };

  renderDrawerFooter = () => {
    const { mapFiltersCount } = this.state;
    const totalFilterCount = sum(Object.values(mapFiltersCount));
    return (
      <div className="side-drawer-filters-footer">
        {totalFilterCount > 0 && (
          <Button
            category="blue filled"
            className="f-w-bold"
            onClick={this.clearFilters}
          >
            CLEAR
          </Button>
        )}
        <Button
          className="action-apply"
          category="blue-bg"
          onClick={() => this.applyFilters()}
        >
          APPLY FILTERS
        </Button>
      </div>
    );
  };

  renderFilter = (filter, toBeDisabled) => {
    const { mapFiltersCount } = this.state;
    const maxFilterItems = filter.maxFilterItems || MAX_FILTER_ITEMS;
    const firstFilterItems = filter.showRemainingItems
      ? filter.items
      : filter.items.slice(0, maxFilterItems);
    const remainingFilterItemsCount = filter.showRemainingItems
      ? []
      : filter.items.length - maxFilterItems;

    return (
      <div className="filter" key={filter.key}>
        <div className="filter-header">
          <span className="title">{filter.title}</span>
          {mapFiltersCount[filter.key] > 0 && (
            <Button
              className="action-clear"
              category="text"
              onClick={() => this.clearFilter(filter)}
            >
              Clear
            </Button>
          )}
        </div>
        <div className="filter-items">
          {firstFilterItems.length ? (
            firstFilterItems.map(filterItem => (
              <div className="filter-item radio-group" key={filterItem.key}>
                {filter.isSingleSelect ? (
                  <Radio
                    label={filterItem.label}
                    checked={filterItem.isSelected}
                    name={filter.key}
                    value={filterItem.value}
                    onClick={e =>
                      this.handleSingleFilterItemChanged(e, filter, filterItem)
                    }
                    disabled={
                      toBeDisabled[filter.key] &&
                      (toBeDisabled[filter.key] === filterItem.value ||
                        toBeDisabled[filter.key] === "all")
                    }
                  />
                ) : (
                  <Checkbox
                    label={filterItem.label}
                    checked={filterItem.isSelected}
                    name={filter.key}
                    value={filterItem.value}
                    onClick={e =>
                      this.handleFilterItemChanged(e, filter, filterItem)
                    }
                  />
                )}
              </div>
            ))
          ) : (
            <div className="filter-item f-color-faded">
              No filters in this category
            </div>
          )}
          {remainingFilterItemsCount > 0 && !filter.showRemainingItems && (
            <div
              className="filter-item filter-items-available"
              onClick={() => this.showRemainingFilterItems(filter)}
            >
              <ImgAdd className="img-add" />
              <span className="label">{remainingFilterItemsCount} More</span>
            </div>
          )}
        </div>
      </div>
    );
  };

  render() {
    const { isVisible, disableConditions } = this.props;
    const { filters } = this.state;

    const toBeDisabled = {};
    if (disableConditions) {
      disableConditions.forEach(condition => {
        const filterObj = find(filters, { key: condition.key });
        let filterSelected;
        // "*" denotes if any one is selected
        if (condition.value === "*") {
          filterSelected = find(filterObj.items, { isSelected: true });
        } else {
          filterSelected = find(filterObj.items, {
            label: condition.value,
            isSelected: true
          });
        }
        if (filterSelected) {
          toBeDisabled[condition.disable.key] = condition.disable.value;
        }
      });
    }
    return (
      <SideDrawer
        className="side-drawer-filters"
        direction="right"
        title="Filters"
        renderFooter={this.renderDrawerFooter}
        isVisible={isVisible}
        onClose={this.props.onClose}
      >
        <div className="filters-list">
          {filters &&
            filters.map(filter => {
              return this.renderFilter(filter, toBeDisabled);
            })}
        </div>
      </SideDrawer>
    );
  }
}
