import { handleActions } from "redux-actions";
import {
  FILTER_FABRIC_COLORS,
  FILTER_FABRIC_QUALITY,
  FILTER_FABRIC_TYPE
} from "../../constants/SwatchLibrary";
import _ from "lodash";

const INITIAL_STATE = { list: [], files: [], filters: [], paginate : {} };

const convertFiltersObjToArray = filters =>
  Object.entries(filters).map(([filterName, filterOptions]) => ({
    title: _.upperCase(filterName),
    options: filterOptions.map((f)=>({label: f.name, value: String(f.id), color: f.code})),
    type: "multi"
  }));
  

const SwatchLibraryReducer = handleActions(
  {
    GET_SWATCHES: (state, action) => {
      return {
        ...state,
        list: action.payload
      };
    },
    GET_SWATCH_VENDORS: (state, action) => {
      return { ...state, vendors: action.payload };
    },
    GET_SWATCH_DESIGN_FILES: (state, action) => {
      return { ...state, files: action.payload };
    },
    GET_SWATCH_FILTERS: (state, action) => {
      return { ...state, filters: convertFiltersObjToArray(action.payload) };
    },
    SET_SWATCH_PAGINATION : (state, action) =>  {
      return { ...state, paginate: action.payload };
    },
    DELETE_SWATCH: (state, action) => {
      const swatchId = action.payload;
      return { ...state, list: state.list.filter(({id}) => id !== swatchId)}
    },
    ADD_VENDOR: (state, action) => {
      const vendorData = action.payload;
      return {
        ...state,
        vendors: [
          ...state.vendors,
          vendorData
        ]
      }
    },
    LOGOUT: (state, action) => {
      return {
        ...state,
        ...INITIAL_STATE
      };
    }
  },
  INITIAL_STATE
);

export default SwatchLibraryReducer;
