import axios from "axios";
import { get_service_endpoint } from "ServiceEndpoints";
import find from "lodash/find";

const endpoint = get_service_endpoint("apiv2");

const categoryTypeToNumber = {
  "Prototype/Fit Sample": "0",
  "Size-set Sample": "1",
  "Preproduction Sample": "2",
  "Top of Bulk (TOB) Sample": "3",
  Production: "4"
};

export const allocateFactory = (samplingData, tnaTemplate, factories) => {
  // id denotes the sampleRootId
  const {
    designId,
    sampleType,
    newSampleId,
    lfrootAddressId,
    isProductionSample,
    orderQuantity,
    factoryId,
    brandId,
    collectionId,
    skipApproval,
    dueDate,
    sampleStatus
  } = samplingData;
  const request = {
    designId,
    orderQuantity,
    attributes: tnaTemplate.payload,
    skipApproval
  };

  if (isProductionSample) {
    if (orderQuantity === 0) return;
    if (request.attributes && request.attributes[0].tnaId) {
      axios
        .patch(`${get_service_endpoint("notificationView")}/tna`, {
          ...request,
          id: request.attributes[0].tnaId
        })
        .then(res => {
          if (res.ERROR) {
            // show error
          }
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      axios
        .post(`${get_service_endpoint("notificationView")}/tna`, request)
        .then(res => {
          if (res.ERROR) {
            // show error
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }

  if (factoryId) {
    const requestObj = {
      brandId: brandId.value,
      factoryId,
      collection: collectionId.value,
      lfrootAddressId: factories
        ? find(factories, { id: factoryId })["addresses"][0].id
        : lfrootAddressId,
      designId,
      category: categoryTypeToNumber[sampleType],
      designSampleDetailsId: newSampleId
    };
    axios.post(`${endpoint}/factory/collections`, requestObj).then(res => {
      axios
        .patch(`${endpoint}/sample`, {
          id: newSampleId,
          dueDate,
          designId,
          sampleStatus,
          sampleType
        })
        .then(result => {
          window.appHistory.push(
            `/brand/collections/${collectionId}/designs/${designId}/?tab=designtna`
          );
        })
        .catch(err => {
          console.log(err);
        });
    });
  }
};
