import React, { Component } from "react";
import moment from "moment";
import find from "lodash/find";

import { connect } from "react-redux";
import { getSamplesByDesignId, getSampleHistory } from "store/actions/DesignActions";
import TnaGraph from "../../Designs/BrandTna/TnaGraph";
import "./NewTnaGraph.scss";
import { getCoverImagePlaceholder } from "helpers/DesignHelpers";

import { TNA_ATTRIBUTES_ORDER } from "constants/Tna";
import { DATESTAMP__SHORT_FORMAT } from "constants/Date";
import { APPROVED } from "constants/Sample";
import { STATUS_ORDER } from "constants/Design";

const { SAMPLE_DISPATCH } = TNA_ATTRIBUTES_ORDER;
class NewTnaGraph extends Component {
  state = {
    showCta: false,
    tnaGraphData: null,
    propSampleDetails: null
  };

  static getDerivedStateFromProps(nextProps, oldState) {
    if (
      nextProps.data &&
      oldState.propSampleDetails !== nextProps.sampleDetails
    ) {
      const { data, sampleDetails, userDetails } = nextProps;

      const [tnaGraphData, showCta] = NewTnaGraph.getTnaGraphData(
        data.tnaAttributes,
        data.skipApproval,
        sampleDetails,
        userDetails
      );

      return { tnaGraphData, propSampleDetails: sampleDetails, showCta };
    }

    return null;
  }

  componentDidMount() {
    const { getSamplesByDesignId, getSampleHistory, design } = this.props;
    getSamplesByDesignId({ designId: design.designId });
    getSampleHistory({ designId: design.designId, isProductionSample: 1 });
  }

  static getTnaGraphData = (
    categories,
    skipApproval,
    sampleDetails,
    userDetails
  ) => {
    let showCta = false;
    let tnaGraphData = categories.map(tnaAttribute => {
      let minDate, maxDate, planDate;

      const productionSample =
        find(sampleDetails.samples, { isProductionSample: 1 }) || {};

      const productionSampleApproved =
        productionSample.sampleStatus === APPROVED;

      minDate = tnaAttribute["data"][0].attributeValue;
      maxDate =
        tnaAttribute["data"][tnaAttribute["data"].length - 1].attributeValue;

      planDate =
        tnaAttribute["data"][tnaAttribute["data"].length - 1].subAttributeValue;

      // In case sample is not approved we take sample dispatch plan date(previous step) as 'by' date for sampling stage
      if (!planDate) {
        planDate = tnaAttribute.data[0].subAttributeValue;
      }

      // In case sample approval is skipped the maxDate will be equivalent to sample dispatch date
      if (
        tnaAttribute.order ===
        TNA_ATTRIBUTES_ORDER.SAMPLE_DISPATCH.categoryOrder &&
        !maxDate &&
        skipApproval
      ) {
        maxDate = tnaAttribute["data"][0].attributeValue;
      }

      let subCategories = tnaAttribute.data.map(step => {
        let actualDate, planDate, timelineDate, timelineStatus, timelineClass;
        actualDate = step.attributeValue;
        planDate = step.subAttributeValue;

        if (!planDate) {
          //Only in PP sample approval case it's null
          if (actualDate) {
            timelineDate = `${moment(actualDate).format(
              DATESTAMP__SHORT_FORMAT
            )}`;
            timelineStatus = `${step.attribute}`;
            timelineClass = "green";
          } else {
            timelineDate = "";
            timelineStatus = "";
            timelineClass = "";
          }
        } else {
          let timelineData = NewTnaGraph.getTimelineStatus(
            actualDate,
            planDate
          );
          timelineDate = timelineData.timelineDate;
          timelineStatus = timelineData.timelineStatus;
          timelineClass = timelineData.timelineClass;
        }

        if (
          step.categoryOrder === SAMPLE_DISPATCH.categoryOrder &&
          step.attributeOrder === SAMPLE_DISPATCH.attributeOrder &&
          step.attributeValue &&
          !productionSampleApproved &&
          !skipApproval &&
          userDetails.isBrand
        ) {
          showCta = true;
        }

        return {
          subCategory: step.subCategory,
          actualDate: actualDate,
          planDate: planDate,
          timelineDate,
          timelineStatus,
          timelineClass,
          files: step.files
        };
      });

      return {
        id: tnaAttribute.order,
        category: tnaAttribute.category,
        subCategories,
        minDate: moment(minDate).format(DATESTAMP__SHORT_FORMAT),
        maxDate: moment(maxDate).format(DATESTAMP__SHORT_FORMAT),
        planDate: moment(planDate).format(DATESTAMP__SHORT_FORMAT),
        showCta
      };
    });
    return [tnaGraphData, showCta];
  };

  static getTimelineStatus = (actualDate, planDate) => {
    let timelineDate, timelineStatus, timelineClass;
    actualDate = actualDate ? moment(actualDate) : null;
    planDate = planDate ? moment(planDate) : null;

    if (actualDate) {
      let datesDiff = actualDate.diff(planDate, "days");

      if (datesDiff < 0) {
        timelineDate = moment(actualDate).format(DATESTAMP__SHORT_FORMAT);
        timelineStatus = `${datesDiff * -1} ${
          datesDiff * -1 > 1 ? "days" : "day"
          } early`;
        timelineClass = "green";
      } else if (datesDiff === 0) {
        timelineDate = moment(actualDate).format(DATESTAMP__SHORT_FORMAT);
        timelineStatus = `On time`;
        timelineClass = "green";
      } else {
        timelineDate = moment(actualDate).format(DATESTAMP__SHORT_FORMAT);
        timelineStatus = datesDiff
          ? `${datesDiff} ${datesDiff > 1 ? "days" : "day"} delay`
          : 'Delayed';
        timelineClass = "red";
      }
    } else {
      timelineDate = `due on ${moment(planDate).format(
        DATESTAMP__SHORT_FORMAT
      )}`;
      timelineStatus = `In progress`;
      timelineClass = "yellow";
    }

    return { timelineDate, timelineStatus, timelineClass };
  };

  _transformDesign = ({
    designName,
    designId,
    category,
    designStatus,
    imageUrl,
    tnaCategory
  }) => ({
    name: designName,
    id: designId,
    category,
    statusString: STATUS_ORDER[designStatus],
    image: imageUrl || getCoverImagePlaceholder({ category }).src,
    tnaCategory
  });

  render() {
    const {
      data,
      design,
      openNextModal,
      sampleDetails = {},
      fetchTna,
      sampleHistory
    } = this.props;
    const { showCta, tnaGraphData = [] } = this.state;

    return (
      <div className="custom-scroll-container">
        <TnaGraph
          data={tnaGraphData}
          design={this._transformDesign(design)}
          showCta={showCta}
          sampleDetails={sampleDetails}
          sampleHistory={sampleHistory}
          openVersionHistoryModal={sampleVersions => {
            openNextModal("SAMPLE_VERSION_HISTORY", {
              sampleVersions: sampleVersions
            });
          }}
          openSampleStatusUpdateModal={commentDetails => {
            openNextModal("UPDATE_SAMPLE_STATUS", {
              commentDetails: commentDetails
            });
          }}
          isAccordion={true}
          designId={design.designId}
          skipSampleApproval={data.skipApproval}
          fetchTna={fetchTna}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  userDetails: state.auth.userDetails,
  sampleDetails: state.designs.newSampleDetails,
  sampleHistory: (props.designId && state.designs.sampleHistory[props.designId]) || {},
});

export default connect(mapStateToProps, {
  getSamplesByDesignId,
  getSampleHistory,
})(NewTnaGraph);
