import { handleActions } from "redux-actions";
import _ from "lodash";

const INITIAL_STATE = {
  list: [],
  files: [],
  filters: [],
  paginate: {},
  vendors: [],
  brands: [],
  options: {
    fabricComposition: [],
    pattern: [],
    weave: [],
  },
};

const convertFiltersObjToArray = filters =>
  Object.entries(filters).map(([filterName, filterOptions]) => ({
    title: _.upperCase(filterName),
    options: filterOptions.map((f) => ({ label: f.name, value: String(f.id), color: f.code })),
    type: "multi"
  }));


const MaterialLibraryReducer = handleActions(
  {
    GET_MATERIALS: (state, action) => {
      return {
        ...state,
        list: action.payload
      };
    },
    GET_MATERIAL_VENDORS: (state, action) => {
      return { ...state, vendors: action.payload };
    },
    GET_MATERIAL_FILTERS: (state, action) => {
      return { ...state, filters: convertFiltersObjToArray(action.payload) };
    },
    GET_MATERIAL_LIBRARY_BRANDS: (state, action) => {
      return { ...state, brands: action.payload.map((f) => ({ label: f.name, value: String(f.id) })) };
    },
    GET_MATERIAL_OPTIONS: (state, action) => {
      return {
        ...state,
        options: {
          fabricComposition: action.payload.fabricComposition,
          pattern: action.payload.pattern,
          weave: action.payload.weave,
        }
      };
    },
    ADD_MATERIAL_OPTION: (state, action) => {
      const { type, label, value } = action.payload;

      return {
        ...state,
        options: {
          ...state.options,
          [type]: [
            ...state.options[type],
            { label, value },
          ],
        }
      };
    },
    GET_TRIMS_CATEGORIES: (state, action) => {
      return { ...state, trimsCategories: action.payload.map(category => ({ label: category.categoryName, value: String(category.id), sizes: category.sizes })) }
    },
    SET_MATERIALS_PAGINATION: (state, action) => {
      return { ...state, paginate: action.payload };
    },
    ADD_MATERIAL_VENDOR: (state, action) => {
      const vendorData = action.payload;
      return {
        ...state,
        vendors: [
          ...state.vendors,
          vendorData
        ]
      }
    },
    LOGOUT: (state, action) => {
      return {
        ...state,
        ...INITIAL_STATE
      };
    }
  },
  INITIAL_STATE
);

export default MaterialLibraryReducer;
