
export function ProcessPaginate(
  { rows: nextRows, paginate: nextPaginate },
  { rows = [], pages = [], paginate = {} } = {},
) {

  // if there is no prev offset; then its the first page
  if (!nextPaginate.prevOffset) {
    return {
      rows: nextRows,
      pages: [nextRows],
      paginate: nextPaginate,
    };
  }

  return {
    rows: [...rows, ...nextRows],
    pages: [...pages, rows],
    paginate: {
      ...paginate,
      ...nextPaginate,
      count: {
        ...paginate.count,
        ...nextPaginate.count,
      }
    },
  };
}
