import React, { Component } from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import get from "lodash/get";

import actions from "store/actions";
import { setCurrentBrand, setNotificationMode } from "store/actions/NotificationActions";

import "./BrandDashboardContainer.scss";

import { ReactComponent as DashboardImg } from "assets/images/navigation/dashboard.svg";
import { ReactComponent as collectionsImg } from "assets/images/navigation/collections.svg";
import { ReactComponent as inboxImg } from "assets/images/navigation/ic-nav-messaging.svg";
import { ReactComponent as productionImg } from "assets/images/navigation/ic-nav-production-2.svg";
import { ReactComponent as samplingImg } from "assets/images/navigation/sampling.svg";
import { ReactComponent as SettingsImg } from "assets/images/navigation/settings.svg";
import { ReactComponent as quoteImg } from "assets/images/navigation/ic-nav-quote.svg";
import { ReactComponent as OrdersImg } from "../../assets/images/navigation/ic-nav-orders.svg";
import { ReactComponent as MaterialLibraryImg } from "assets/images/dashboard/materialLibrary.svg";
import { ReactComponent as HelpImg } from "assets/images/action/ic-nav-help.svg";
import { ReactComponent as ReportsImg } from "assets/images/dashboard/reports.svg";


import Header from "components/UI/Header/Header";
import Sidebar from "components/UI/Sidebar/Sidebar";

import PrivateRoute from "components/UI/PrivateRoute/PrivateRoute";

import CollectionDetailsContainer from "./../CollectionDetailsContainer/CollectionDetailsContainer";
import DesignDetailsContainer from "./../DesignDetailsContainer/DesignDetailsContainer";
import AccountSettingsContainer from "./../AccountSettingsContainer/AccountSettingsContainer";
import CollectionListContainer from "./../CollectionListContainer/CollectionListContainer";
import SwatchLibraryContainer from "./../SwatchLibraryContainer/SwatchLibraryContainer";
import QuotesContainer from "./../QuotesContainer/index";
import ReportsContainer from "./../ReportsContainer";

import Products from "./../ProductContainer";
import Orders from "./../OrdersContainer";
import SamplingTracker from "./../SamplingTrackerContainer";
import ProductionTracker from "./../ProductionTrackerContainer";
import MessagesContainer from "./../MessagesContainer/index";


import Dashboard from "../UserDashboardContainer/UserDashboardContainer";
import MaterialLibraryContainer from "./../MaterialLibraryContainer";
import Help from '../../components/Help/index';
import { refreshTokenIfOld, getHostBrand } from "../../helpers/AuthHelper";
import CreateOrderContainer from "../OrdersContainer/CreateOrderContainer";

class BrandDashboardContainer extends Component {

  static propTypes = {
    openModal: PropTypes.func.isRequired
  };

  state = {
    isHelpVisible: false,
  };

  constructor(props) {
    super(props);

    if (props.userDetails && props.userDetails.isBrand) {
      props.getBrandFeatures(props.userDetails.brandId);
    }

    refreshTokenIfOld(this.props.refreshToken);
  }

  componentWillMount() {
    if (get(this.props, "userDetails.name", null) === null) {
      this.props.openModal("FIRST_LOGIN");
    }
  }

  componentDidMount() {
    const { brandId } = this.props.userDetails;
    const { setCurrentBrand, setNotificationMode, getAllNotifications, getUsers } = this.props;
    getUsers();
    setCurrentBrand({ brandId });
    setNotificationMode(false);
    getAllNotifications({ isLoader: true, brandId });
  }

  _toggleHelp() {
    this.setState({ isHelpVisible: !this.state.isHelpVisible });
  }

  render() {
    const { userDetails, brandFeatures } = this.props;
    const { isHelpVisible } = this.state;
    const hostBrand = getHostBrand();
    const upperItems = [
      (userDetails.isBrand && brandFeatures.dashboard) && {
        name: "Dashboard",
        img: DashboardImg,
        link: "/brand/dashboard/"
      },
      {
        name: "Collections",
        id: "collections",
        img: collectionsImg,
        link: "/brand/collections/"
      },
      {
        name: "Quotes",
        id: "quotes",
        img: quoteImg,
        link: "/brand/quotes/?tab=all"
      },
      {
        name: "Sampling",
        id: "sampling",
        img: samplingImg, // have to change this .. icon
        link: "/brand/sampling/in-progress/",
        baseRoute: "/brand/sampling"
      },
      {
        name: "Production",
        id: "production",
        img: productionImg,
        link: "/brand/production/in-progress/",
        baseRoute: "/brand/production"
      },
      {
        name: "Orders",
        id: "orders",
        img: OrdersImg,
        link: "/brand/orders/"
      },
      {
        name: "Messages",
        id: "messages",
        img: inboxImg,
        link: "/brand/messages/"
      },
      brandFeatures.materialLibrary && {
        name: "Material Library",
        img: MaterialLibraryImg,
        link: "/brand/library/"
      },
      brandFeatures.brandReports && {
        name: "Reports",
        id: "reports",
        img: ReportsImg,
        link: "/brand/reports/"
      },
      {
        name: "Settings",
        id: "settings",
        img: SettingsImg,
        link: "/brand/settings/"
      }
    ].filter(item => item);
    const lowerItems = (hostBrand && hostBrand.showHelp && userDetails.isBrand)
      ? [
        {
          id: "help",
          name: "Help",
          img: HelpImg,
          onClick: () => this._toggleHelp(),
        }
      ]
      : [];

    return (
      <div className="brand-dash" >
        <Header
          isFactory={false}
          openModel={() =>
            this.props.openModal("NOTIFICATION_MODAL", {
              userDetails: this.props.userDetails,
              brandId:
                this.props.brandId,
              usersList: this.props.usersList
            })
          }
          brandId={
            Object.keys(this.props.usersList).length &&
            this.props.usersList[this.props.userDetails.id] &&
            this.props.usersList[this.props.userDetails.id].brand
          }
          dontShowInLogin={window.appHistory.location !== "/auth/login/"}
          notifications={this.props.notifications}
          setNotificationsRead={this.props.setNotificationsRead}
          userDetails={userDetails}
        />
        <div className="brand-dash__main">
          <Sidebar
            upperItems={upperItems}
            lowerItems={lowerItems}
            userDetails={this.props.userDetails}
          />
          <div className="brand-dash__content">
            <Help
              isVisible={isHelpVisible}
              onClose={() => this._toggleHelp()}
            />
            <PrivateRoute
              exact
              path="/brand/"
              render={() => {

                return Object.keys(brandFeatures).length > 0 ?
                  <Redirect to={upperItems[0].link} />
                  : null
              }}
            />
            <PrivateRoute
              exact
              path="/brand/collections/"
              render={routeProps => {
                return <CollectionListContainer {...routeProps} brandId={this.props.brandId} />;
              }}
            />
            <PrivateRoute
              exact
              path="/brand/collections/:collection_id"
              render={routeProps => (
                <CollectionDetailsContainer
                  {...routeProps}
                  openModal={this.props.openModal}
                />
              )}
            />
            <Route
              exact
              path="/brand/collections/:collection_id/designs/:design_id"
              render={routeProps => (
                <DesignDetailsContainer
                  {...routeProps}
                  openModal={this.props.openModal}
                />
              )}
            />
            <Route
              path="/brand/quotes/"
              render={routeProps => <QuotesContainer {...routeProps} />}
            />
            <Route
              path="/brand/sampling/approved/"
              render={routeProps => <Products {...routeProps} />}
            />
            <Route
              path="/brand/sampling/in-progress/"
              render={routeProps => (
                <SamplingTracker
                  {...routeProps}
                  isFactory={false}
                  userDetails={this.props.userDetails}
                  usersList={this.props.usersList}
                />
              )}
            />
            <Route
              path="/brand/production/in-progress/"
              render={routeProps => (
                <ProductionTracker
                  {...routeProps}
                  isFactory={false}
                  userDetails={this.props.userDetails}
                  usersList={this.props.usersList}
                />
              )}
            />
            <Route
              path="/brand/production/dispatched/"
              render={routeProps => (
                <ProductionTracker
                  {...routeProps}
                  isFactory={false}
                  userDetails={this.props.userDetails}
                  usersList={this.props.usersList}
                />
              )}
            />
            <Route
              path="/brand/orders"
              exact
              render={routeProps => <Orders {...routeProps} />}
            />
             <Route
              path="/brand/orders/new/"
              render={routeProps => <CreateOrderContainer {...routeProps} />}
            />
            <Route
              path="/brand/messages"
              render={routeProps => <MessagesContainer {...routeProps} />}
            />
            <PrivateRoute
              path="/brand/swatchLibrary/"
              render={routeProps => (
                <SwatchLibraryContainer
                  {...routeProps}
                  userDetails={this.props.userDetails}
                  usersList={this.props.usersList}
                />
              )}
            />
            <PrivateRoute
              path="/brand/library/"
              render={routeProps => (
                <MaterialLibraryContainer
                  {...routeProps}
                  userDetails={this.props.userDetails}
                  usersList={this.props.usersList}
                />
              )}
            />
            <Route
              path="/brand/reports/"
              render={routeProps => (
                <ReportsContainer {...routeProps} />
              )}
            />
            <Route
              path="/brand/settings/"
              render={routeProps => (
                <AccountSettingsContainer {...routeProps} />
              )}
            />
            <Route
              path="/brand/dashboard/"
              render={routeProps => {
                return <Dashboard {...routeProps} />;
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

BrandDashboardContainer.propTypes = {
  userDetails: PropTypes.shape({
    id: PropTypes.number,
    brand: PropTypes.string,
    role: PropTypes.string,
    name: PropTypes.string,
    brandId: PropTypes.number
  }),
  usersList: PropTypes.object,
  notifications: PropTypes.array,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  setCurrentBrand: PropTypes.func.isRequired,
  setNotificationMode: PropTypes.func.isRequired,
  getAllNotifications: PropTypes.func.isRequired,
  setNotificationsRead: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  userDetails: state.auth.userDetails,
  usersList: state.auth.list,
  notifications: state.notificationView.notificationsList,
  brandFeatures: state.brands.brandFeatures,
});

const mapDispatchToProps = dispatch => ({
  openModal: (content, additionalProps) =>
    dispatch(actions.openModal(content, additionalProps)),
  closeModal: (content, additionalProps) => dispatch(actions.closeModal()),
  getUsers: () => dispatch(actions.getUsers()),
  setCurrentBrand: (brand) => dispatch(setCurrentBrand(brand)),
  setNotificationMode: mode => dispatch(setNotificationMode(mode)),
  getAllNotifications: details => dispatch(actions.getAllNotifications(details)),
  setNotificationsRead: notifications => dispatch(actions.setNotificationsRead(notifications)),
  getBrandFeatures: (brandId) => dispatch(actions.getBrandFeatures(brandId)),
  refreshToken: () => dispatch(actions.refreshToken()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BrandDashboardContainer);
