import React, { useState, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import Tabs from "../UI/UrlTabs/index";
import Pagination from "../Product/Pagination";
import OrderItem from "./OrderItem";
import { FilterDrawerButton } from "./../UI/Filter";
import EmptyFilterResult from "../UI/EmptyFilterResult";
import EmptyScreen from "../UI/EmptyScreen";
import Button from "components/UI/Button/Button";
import PrivateContainer from "components/UI/PrivateContainer/PrivateContainer";
import DebounceInput from "components/UI/DebounceInput/DebounceInput";

import {
  getCurrentUserRole,
  checkBrand,
  checkFactory,
  checkCanUpdateOrder
} from "../../helpers/AuthHelper";
import {
  LOCO_SUPER,
  CLIENT,
  PRODUCT_DEVELOPMENT,
  CLIENT_SUPER
} from "../../constants/Auth";

import ImgOrders from "assets/images/emptyPages/ic-orders.svg";
import ImgPlus from "assets/images/content/ic-plus.png";

import "./Orders.scss";

// === HERE IS AN ISSUE IN BOTTOM PAGINATION THAT SCROLL SHOULD BE STARTED FROM TOP ===

const Orders = ({
  openModal,
  orders = [],
  getAllOrders,
  getAllBrands,
  getAllFactories,
  allBrands,
  allFactories,
  isFactory,
  prevSavedOrders,
  pagination,
  updatedOrder,
  createDispatchNotification,
  removeDispatchNotification,
  pendingDispatchNotifications,
  setCurrentBrand,
  brandId,
  selectedTabFilter,
  searchText
}) => {
  const isUserFromFactory = checkFactory();
  const isUserFromBrand = checkBrand();

  const [appliedFilters, setAppliedFilters] = useState({
    dispatchStatus: selectedTabFilter,
    brandIds: isUserFromBrand ? [brandId] : [],
    factoryIds: isUserFromFactory ? [brandId] : []
  });
  const [appliedFiltersCount, setAppliedFiltersCount] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [savedOrders, setSavedOrders] = useState([]);

  const tableHeadings = [
    "P.O. Details",
    ...(isUserFromBrand ? [] : ["Brand"]),
    ...(isUserFromFactory ? [] : ["Factory"]),
    "Quantity",
    "Amount",
    "Delivering To",
    "Status"
  ];
  const canEditOrder = checkCanUpdateOrder();

  useEffect(() => {
    if (!appliedFilters.searchText || appliedFilters.searchText.length === 0) {
      setAppliedFilters({ ...appliedFilters, searchText });
    }
  }, [searchText]);

  useEffect(() => {
    if (selectedTabFilter === appliedFilters.dispatchStatus) {
      return;
    }

    setAppliedFilters({
      ...appliedFilters,
      dispatchStatus: selectedTabFilter
    });
  }, [selectedTabFilter]);

  // ==== this observes the change in single order due to updates ===
  useEffect(() => {
    if (updatedOrder) {
      savedOrders.forEach((order, index) => {
        if (order.id === updatedOrder.id) {
          savedOrders[index] = { ...order, ...updatedOrder };
        }
      });

      updateSavedOrders([...savedOrders]);
    }
  }, [updatedOrder]);

  useEffect(() => {
    if (prevSavedOrders.length === 1) {
      setCurrentIndex(0);
    }

    // FIXME: max update depth exceeding from here
    updateSavedOrders(orders);
  }, [prevSavedOrders, orders]);

  useEffect(() => {
    // === this pulls the all brands when factory is loggen in ===
    if (!(allBrands.length > 0) && isFactory) {
      getAllBrands();
    }

    // === this pulls the all brands when factory is loggen in ===
    if (isUserFromBrand) {
      getAllFactories({
        brandId
      });
    }
  }, []);

  useEffect(() => {
    getFilteredValue({
      brandIds: appliedFilters.brandIds,
      factoryIds: appliedFilters.factoryIds,
      dispatchStatus: appliedFilters.dispatchStatus,
      searchText: appliedFilters.searchText,
      isNext: 0,
      init: 1
    });
    setSavedOrders([]);
  }, [appliedFilters]);

  const updateSavedOrders = orders => {
    setSavedOrders(orders);

    setTimeout(() => {
      ReactTooltip.rebuild();
    }, 1000);
  };

  const openModalHandler = (key, params) => {
    openModal(key, params);
  };

  const getFilteredValue = params => {
    getAllOrders(params);
  };

  const paginationHandler = ({ offset, isnext }) => {
    if (isnext) {
      // check length of saved designs
      const savedDesignsLength = prevSavedOrders.length - 1;

      if (savedDesignsLength > currentIndex) {
        // then alter the designs
        updateSavedOrders(prevSavedOrders[currentIndex + 1]);
        setCurrentIndex(currentIndex + 1);
      } else {
        getFilteredValue({
          offset,
          isNext: isnext,
          ...appliedFilters,
          init: 0
        });
        setCurrentIndex(currentIndex + 1);
      }
      return;
    }

    if (!isnext) {
      updateSavedOrders(prevSavedOrders[currentIndex - 1]);
      setCurrentIndex(currentIndex - 1);

      return;
    }
  };

  const handleApplyFilters = ({ filters, filtersCount }) => {
    setAppliedFilters({
      ...appliedFilters,
      brandIds: (filters.buyer && filters.buyer.values) || [],
      factoryIds: (filters.factory && filters.factory.values) || []
    });
    setAppliedFiltersCount(filtersCount);
  };

  const filters = [];

  if (!isUserFromBrand) {
    filters.push({
      title: "CUSTOMER",
      key: "buyer",
      items: allBrands.map(brand => ({
        key: brand.id, // key should be unique + (string | number)
        label: brand.name,
        value: brand.id,
        isSelected: appliedFilters.brandIds.includes(brand.id)
      }))
    });
  }

  if (!isUserFromFactory) {
    filters.push({
      title: "FACTORY",
      key: "factory",
      items: (allFactories || []).map(factory => ({
        key: factory.id, // key should be unique + (string | number)
        label: factory.name,
        value: factory.id,
        isSelected: appliedFilters.factoryIds.includes(factory.id)
      }))
    });
  }

  const isSuperAdmin = getCurrentUserRole() == LOCO_SUPER;

  const renderHeader = () => {
    return (
      <div className="row header-row">
        <PrivateContainer
          allowRoles={[LOCO_SUPER, CLIENT_SUPER, CLIENT, PRODUCT_DEVELOPMENT]}
        >
          <Link to="new/">
            <Button className="cta-create-order" category="blue-bg grid-2">
              <img src={ImgPlus} alt="" />
              Create Order
            </Button>
          </Link>
        </PrivateContainer>
        <div className="filter-group">
          <DebounceInput
            value={appliedFilters["searchText"]}
            placeholder="&#xf002; &nbsp; Search orders"
            handleChange={data => {
              setAppliedFilters({ ...appliedFilters, searchText: data });
            }}
            className="orders-search"
            minLength={1}
          />
          <FilterDrawerButton
            filters={[...filters]}
            onApplyFilters={appliedFilters =>
              handleApplyFilters(appliedFilters)
            }
          />
        </div>
        <Pagination
          label="orders"
          onclick={paginationHandler}
          page={
            pagination && {
              ...pagination,
              totalCount: pagination[`${selectedTabFilter}Count`]
            }
          }
          currentIndex={currentIndex}
          showTotalRecords={false}
          className="pagination-wrapper"
        />
      </div>
    );
  };

  const renderOrders = () => {
    return savedOrders && savedOrders.length ? (
      <div className="table">
        <div
          className="table-header"
          // style={{
          //   gridTemplateColumns: !isFactory ? "repeat(6, 1fr)" : ""
          // }}
        >
          {tableHeadings.map((heading, ind) => (
            <div key={ind} className="table__header_item">
              {heading}
            </div>
          ))}
        </div>
        {savedOrders.map(order => (
          <OrderItem
            brandSelected={
              Array.isArray(appliedFilters.brandIds) &&
              appliedFilters.brandIds.length > 0
            }
            isNotificationSelected={pendingDispatchNotifications
              .map(({ id }) => id)
              .includes(Number(order.id))}
            createDispatchNotification={createDispatchNotification}
            removeDispatchNotification={removeDispatchNotification}
            order={order}
            openModalHandler={openModalHandler}
            isFactory={isFactory}
            canEditOrder={canEditOrder}
            isSuperAdmin={isSuperAdmin}
          />
        ))}
      </div>
    ) : appliedFiltersCount > 0 ? (
      <EmptyFilterResult
        onClearFilters={() => {
          handleApplyFilters({
            filters: {
              factory: { values: [] },
              buyer: { values: [] }
            },
            filtersCount: 0
          });
        }}
      />
    ) : (
      <EmptyScreen
        icon={ImgOrders}
        heading={
          selectedTabFilter === "pending"
            ? "No pending orders"
            : "No dispatched orders"
        }
        description={
          selectedTabFilter === "pending" ? (
            <>
              You don’t have any pending orders. <br />
              We’ll let you know when you <br />
              have new orders.
            </>
          ) : (
            <>You don’t have any dispatched orders</>
          )
        }
      />
    );
  };

  return (
    <div
      className={`orders-wrapper scrollable ${
        isUserFromBrand || isUserFromFactory ? "orders-wrapper-one-col" : ""
      }`}
    >
      <Helmet
        bodyAttributes={{
          class: "body_container"
        }}
      />
      <Tabs
        hasBadges={true}
        header={renderHeader()}
        initialTabUrl={selectedTabFilter}
        tabs={[
          {
            name: "Pending",
            tabUrl: "pending",
            panel: renderOrders(),
            badge: (pagination && pagination.pendingCount) || 0
          },
          {
            name: "Dispatched",
            tabUrl: "dispatched",
            panel: renderOrders(),
            badge: (pagination && pagination.dispatchedCount) || 0
          }
        ]}
      />
    </div>
  );
};

export default Orders;
