import React from "react";
import Button from "components/UI/Button/Button";
import EmptyScreen from "components/UI/EmptyScreen";
import emptyIcon from "assets/images/emptyPages/ic-tna-empty.svg";
import plusIcon from "assets/images/content/ic-plus.png";
import copyIcon from "assets/images/action/ic-copy-blue.svg";

const CreateTna = ({ onCreateTna, onCopyTna, sampleStatus = "PP sample" }) => (
  <EmptyScreen
    className="create-tna-container"
    icon={emptyIcon}
    heading="TNA pending"
    description={<>Please submit planned TNA before <br /> creating the {sampleStatus}.</>}
    cta={
      <>
        <Button
          category="primary-text grid-2"
          onClick={onCopyTna}
        >
          <img src={copyIcon} alt="copy" />
          Copy TNA
        </Button>
        <Button
          category="blue-bg grid-2"
          onClick={onCreateTna}
        >
          <img src={plusIcon} alt="add" />
          Create TNA
        </Button>
      </>
    }
  />
);

export default CreateTna;
