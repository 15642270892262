import Downshift from "downshift";

// local imports ****
import cancelIcon from "assets/img/close.svg";

import React, { Component, Fragment } from "react";
import "./styles.scss";

export default class Search extends Component {
  state = {
    isFocused: false
  };

  onViewAll = category => {
    const { onChangeSearchType } = this.props;
    onChangeSearchType({ value: category }, true);
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  setInputRef = node => (this.inputRef = node);

  setWrapperRef = node => (this.wrapperRef = node);

  handleClickOutside = event => {
    const { isFocused } = this.state;

    if (isFocused) {
      if ((!this.wrapperRef || !this.wrapperRef.contains(event.target))
        && (!this.inputRef || !this.inputRef !== event.target)) {
        this.setState({
          isFocused: false
        });
      } else {
        event.preventDefault();
      }
    }
  };

  unFocus = () => {
    this.setState({ isFocused: false });
    this.inputRef.blur();
  }

  render() {
    const {
      results,
      onClickResult,
      showResults,
      onChange,
      types,
      onChangeSearchType,
      selectedSearchType,
      searchQuery
    } = this.props;
    const { isFocused } = this.state;
    return (
      <div className="search_container">
        {/* Search Bar ********** */}

        {/* Search results */}
        <Downshift itemToString={item => (item ? item.label : "")}>
          {({
            getInputProps,
            getItemProps,
            getLabelProps,
            getMenuProps,
            isOpen,
            inputValue,
            highlightedIndex,
            selectedItem
          }) => (
              <div>
                {/* <label {...getLabelProps()}>Enter a fruit</label> */}
                {/* <input {...getInputProps()} /> */}
                <div style={{ display: "flex" }}>
                  {/* *** ================ *** */}
                  <div style={{ position: "relative" }} id="search">
                    <input
                      ref={this.setInputRef}
                      type="search"
                      onChange={e => {
                        this.setState({ isFocused: true });
                        onChange(e);
                      }}
                      onFocus={() => {
                        this.setState({ isFocused: true });
                      }}
                      onBlur={() => {
                        this.unFocus();
                      }}
                      value={searchQuery}
                    />
                    {showResults && isFocused && results
                      && Object.keys(results).length > 0 && (
                        <div className="search-dropdown" ref={this.setWrapperRef}>
                          <ul className="search-dropdown-list">
                            {Object.keys(results).map(category => {
                              let result = results[category];
                              return (
                                <Fragment key={category}>
                                  <li className="heading">
                                    {category} (
                                  {result.data ? result.data.length : 0}{" "}
                                  Results){" "}
                                    <a
                                      className="heading-link clickable"
                                      onClick={e => {
                                        this.onViewAll(category);
                                      }}
                                    >
                                      View All
                                  </a>
                                  </li>
                                  {result.data &&
                                    result.data.length > 0 &&
                                    result.data.map(item => (
                                      <li
                                        className="clickable"
                                        onClick={() => {
                                          this.unFocus();
                                          this.setState({ isFocused: false });
                                          onClickResult(category, item);
                                        }}
                                      >
                                        {item.val ? item.val : item.name}
                                      </li>
                                    ))}
                                </Fragment>
                              );
                            })}
                          </ul>
                        </div>
                      )}

                  </div>
                </div>
              </div>
            )}
        </Downshift>
      </div>
    );
  }
}
