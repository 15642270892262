import React, { Component } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import qs from "query-string";
import { submit } from "redux-form";
import _ from "lodash";
import PerfectScrollbar from "react-perfect-scrollbar";

// === Local imports ===
import Designs from "./MobileViewDesigns";
import Details from "./MobileViewDetails";
import actions from "../../store/actions";
import QuoteDetails from "./MobileComponents/QuoteDetails";
import PoList from "./MobileComponents/MobilePoList";
import PoDetails from "./MobileComponents/PoDetails";
import Tna from "./MobileComponents/Tna";

class MobileView extends Component {
  componentDidMount() {
    this.props.actions.common.setNotificationMode(false);

    // for getting all users as userList
    this.props.actions.common.getUsers();

    const authToken = qs.parse(this.props.location.search).a;
    if (authToken)
      this.props.actions.common.authTokenLogin({ authToken }).then(() =>
        this.props.actions.common
          .getNotificationDetails({
            notificationId: this.props.match.params.notification_id
          })
          .then(notificationDesigns => {
            this.props.actions.designDetails.getMultipleDesigns({
              idList: notificationDesigns
            });
            notificationDesigns
              .map(v => v.collection)
              .forEach(cId =>
                this.props.actions.collectionDetails.getCollectionById(cId)
              );
          })
      );
    else
      this.props.actions.common
        .getNotificationDetails({
          notificationId: this.props.match.params.notification_id
        })
        .then(notificationDesigns => {
          this.props.actions.designDetails.getMultipleDesigns({
            idList: notificationDesigns
          });
          notificationDesigns
            .map(v => v.collection)
            .forEach(cId =>
              this.props.actions.collectionDetails.getCollectionById(cId)
            );
        });
    return undefined;
  }

  componentWillReceiveProps(nextProps) {
    console.log("NEXT PROPS", nextProps);

    //  if (
    //   !_.isEqual(nextProps["selectedDesigns"], this.props["selectedDesigns"])
    // ) {
    //   // window.appHistory.push("/notification/" + Object.values(nextProps["selectedDesigns"])[0]["id"]);
    //   if (nextProps.match.params.design_id)
    //     window.appHistory.push({
    //       pathname:
    //         "../" + Object.values(nextProps["selectedDesigns"])[0]["id"] + "/",
    //       search: nextProps.location.search
    //     });
    //   else
    //     window.appHistory.push({
    //       pathname: Object.values(nextProps["selectedDesigns"])[0]["id"],
    //       search: nextProps.location.search
    //     });
    //   // console.log("CHANGED", nextProps["selectedDesigns"]);
    // }

    // if (
    //   this.props.match.params.design_id !== nextProps.match.params.design_id
    // ) {
    //   let queryStr = qs.parse(nextProps.location.search);
    //   if (!queryStr.tab) {
    //     queryStr.tab = "summary";
    //   }
    //   window.appHistory.push({
    //     search: qs.stringify(queryStr)
    //   });
    // }
    // const newQueryObj = qs.parse(nextProps.location.search);
    // // console.log("PARSE", !JSON.parse(window.localStorage.getItem("fileReload")));
    // // Check if it's the first time when all the designs were fetched
    // // If _true_ then redirect the tab to selected Category
    // if (
    //   Object.keys(nextProps.selectedDesigns).length > 0 &&
    //   Object.keys(this.props.selectedDesigns).length === 0 &&
    //   // Only redirect if no tab specified
    //   // !qs.parse(nextProps.location.search).tab
    //   // Doesn't work: END
    //   // HACK
    //   newQueryObj.initialTab
    // ) {
    //   setTimeout(() => {
    //     // window.localStorage.setItem("fileReload", false);
    //     delete newQueryObj.initialTab;
    //     window.appHistory.push({
    //       search: qs.stringify({
    //         ...newQueryObj,
    //         tab: nextProps.selectedCategory
    //       })
    //     });
    //   }, 100);
    // }
  }

  render() {
    const { match, notificationDesigns = [] } = this.props;

    console.log("ITS THERE WWWW", this.props);
    return (
      <div className="notifcations">
        <PerfectScrollbar
          style={{ maxHeight: window.innerHeight, maxWidth: window.innerWidth }}
        >
          <Switch>
            <Route
              path={`${match.url}`}
              exact
              render={routeProps =>
                notificationDesigns.length ? (
                  <Designs {...routeProps} designs={notificationDesigns} />
                ) : (
                  // <Tna />
                  <PoList
                    {...routeProps}
                    details={this.props}
                    list={this.props.notificationPoOrders}
                  />
                )
              }
            />

            <Route
              path={`${match.url}/details/`}
              render={routeProps => {
                return (
                  <Details
                    {...routeProps}
                    details={this.props}
                    usersList={this.props.usersList}
                  />
                );
              }}
            />

            <Route
              path={`${match.url}/quote/:quote_id`}
              render={routeProps => {
                const selectedQuote = _.get(
                  this.props.quoteDetails,
                  Number(routeProps.match.params.quote_id),
                  null
                );
                if (selectedQuote === null || selectedQuote === undefined) {
                  routeProps.history.goBack();
                  return null;
                }
                return (
                  <QuoteDetails
                    {...routeProps}
                    quote={selectedQuote}
                    getQuoteDetails={this.props.actions.quote.getQuoteDetails}
                    updateQuoteStatus={
                      this.props.actions.quote.updateQuoteStatus
                    }
                  />
                );
              }}
            />

            <Route
              path={`${match.url}/poDetails`}
              render={routeProps => {
                console.log("ASD /po/details");
                return <PoDetails {...routeProps} details={this.props} />;
              }}
            />

            {/* <Route
            path={`${match.url}/po/`}
            render={routeProps => (
              <PoList
                {...routeProps}
                details={this.props}
                list={this.props.notificationPoOrders}
              />
            )}
          /> */}
          </Switch>
        </PerfectScrollbar>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    collections: state.collections.list,
    usersList: state.auth.list,
    selectedDesigns: state.notificationView.selectedDesigns,
    sampleDetails: state.designs.samplingDetails,
    quoteDetails: state.designs.quoteDetails,
    quoteSummary: state.designs.quoteSummary,
    orderDetails: state.designs.orderDetails,
    dispatchDetails: state.designs.dispatchDetails,
    designJourney: state.designs.designJourney,
    currentUser: state.auth.userDetails,
    selectedCategory: state.notificationView.tabToDisplay,
    notificationDesigns: state.notificationView.notificationDesigns,
    notificationPoOrders: state.notificationView.notificationPoOrders,
    orderFiles: state.designs.orderFiles,
    dispatchFiles: state.designs.dispatchFiles,
    brandTnaTemplate: state.designs.brandTnaTemplate

    // ***** not have swatch library change set yet *****
    // designFiles: state.swatchLibrary.files
  };
};

const mapDispatchToProps = dispatch => ({
  actions: {
    common: {
      openModal: (name, additionalProps) =>
        dispatch(actions.openModal(name, additionalProps)),
      getNotificationDetails: details =>
        dispatch(actions.getNotificationDetails(details)),
      authTokenLogin: details => dispatch(actions.authTokenLogin(details)),
      getUsers: () => dispatch(actions.getUsers()),
      setNotificationMode: mode => dispatch(actions.setNotificationMode(mode))
    },
    collectionDetails: {
      getCollectionById: collectionId =>
        dispatch(actions.getCollectionById(collectionId))
    },
    designDetails: {
      getMultipleDesigns: details =>
        dispatch(actions.getMultipleDesigns(details)),
      getBrandTnaTemplate: details =>
        dispatch(actions.getBrandTnaTemplate(details))
    },
    designSummary: {
      submitEditDesignForm: () => dispatch(submit("designSummary")),
      editDesign: values => dispatch(actions.editDesign(values))
    },
    designFiles: {
      patchDesignFile: details => dispatch(actions.patchDesignFile(details)),
      uploadNewDesignFile: details =>
        dispatch(actions.uploadNewDesignFile(details)),
      deleteDesignFile: details => dispatch(actions.deleteDesignFile(details)),
      getSwatchDesignFiles: details =>
        dispatch(actions.getSwatchDesignFiles(details))
    },
    sampling: {
      getSampleDetails: designDetails =>
        dispatch(actions.getSampleDetails(designDetails)),
      updateSampleDetails: details =>
        dispatch(actions.updateSampleDetails(details))
    },
    quote: {
      getQuoteSummary: designDetails =>
        dispatch(actions.getQuoteSummary(designDetails)),
      getQuoteDetails: designDetails =>
        dispatch(actions.getQuoteDetails(designDetails)),
      updateQuoteStatus: details =>
        dispatch(actions.updateQuoteStatus(details)),
      updateQuoteSummary: details =>
        dispatch(actions.updateQuoteSummary(details)),
      createQuoteSummary: details =>
        dispatch(actions.createQuoteSummary(details)),
      updateQuote: details => dispatch(actions.updateQuote(details)),
      createQuote: details => dispatch(actions.createQuote(details))
    },
    orderConfirmation: {
      getOrderDetails: details => dispatch(actions.getOrderDetails(details)),
      addOrderDetails: details => dispatch(actions.addOrderDetails(details)),
      patchOrderDetails: details =>
        dispatch(actions.patchOrderDetails(details)),
      deleteOrderFile: details => dispatch(actions.deleteOrderFile(details)),
      changeOrderStatus: details => dispatch(actions.changeOrderStatus(details))
    },
    dispatch: {
      getDispatchDetails: details =>
        dispatch(actions.getDispatchDetails(details)),
      addDispatchDetails: details =>
        dispatch(actions.addDispatchDetails(details)),
      patchDispatchDetails: details =>
        dispatch(actions.patchDispatchDetails(details)),
      patchDispatchStatus: details =>
        dispatch(actions.patchDispatchStatus(details)),
      deleteDispatchFile: details =>
        dispatch(actions.deleteDispatchFile(details))
    }
  }
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MobileView)
);
