import React, { Component } from "react";
import classnames from 'classnames';
import "./styles.scss";

const Radio = props => {
  const {
    input,
    options,
    field,
    required,
    label,
    form: { setFieldValue },
    handleChange = () => { },
    disabled,
    className = '',
    hasInlineOptions = false,
  } = props;
  const { name } = field;

  return (
    <div className={classnames(`formik_radio`, className, {
      'formik_radio--inline-options': hasInlineOptions,
      disabled,
    })}>
      <label htmlFor={name}>
        {label}
        {required && <sup>*</sup>}
      </label>
      <div className="flex formik_radio__options">
        {options.map((o, index) => (
          <div key={index} className="radio_group radio_group--left-align">
            <label key={o.value}>
              <input
                type="radio"
                {...input}
                value={o.value}
                onChange={e => {
                  setFieldValue(name, o.value);
                  handleChange(e.target.value);
                }}
                checked={o.value === field.value}
                disabled={disabled}
              />
              <span />
              {o.label}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Radio;
