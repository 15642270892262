import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import DesignMessages from "../../Messaging/DesignMessages";
import {
  fetchMessagesByDesignId,
  sendMessage,
  fetchFactoriesForDesignId,
} from "./../../../store/actions/MessageActions";
import {
  factoryNewGetAllBrands
} from "./../../../store/actions/FactoryActions";
import "./../../../containers/MessagesContainer/MessagesContainer.scss";
import "./MessagesTab.scss";

class MessagesTab extends Component {
  state = {
    brand: null,

    propDesign: null,
  }

  static getDerivedStateFromProps(nextProps, oldState) {
    const nextState = {};

    if (oldState.brand === null && nextProps.userDetails) {
      if (nextProps.userDetails.isBrand) {
        nextState.brand = { id: nextProps.userDetails.brandId, name: nextProps.userDetails.brand };
      }
    }

    if (nextProps.design !== oldState.propDesign) {
      nextState.propDesign = nextProps.design;
      if (nextProps.design && nextProps.design.id) {
        nextProps.fetchFactories({ designId: nextProps.design.id });
      }
    }

    return Object.keys(nextState).length > 0 ? nextState : null;
  }

  componentDidMount() {
    this.props.getAllBrands();
  }

  loadMessages = designId => {
    if (!designId) {
      return;
    }
    const { fetchMessagesByDesignId } = this.props;
    fetchMessagesByDesignId(designId);
  }

  render() {
    const {
      brands,
      design,
      factories,
      userDetails,
      messages,
      sendMessage,
      fetchFactories,
      fetchMessages,
    } = this.props;
    const { brand } = this.state;

    if (!design || !design.id) {
      return null;
    }

    const designBrand = brand || brands.find(b => b.id === design.brand);


    return (
      <div className="messages-console__wrapper flex-col">
        <DesignMessages
          factories={factories && factories[design.id]}
          messages={messages[design.id]}
          userDetails={userDetails}
          brand={designBrand}
          design={design}
          showDesignName={false}
          sendMessage={sendMessage}
          fetchFactories={fetchFactories}
          fetchMessages={fetchMessages}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    userDetails: state.auth.userDetails,
    brands: state.factory.brandsList,
    messages: state.messaging.messages,
    factories: state.messaging.factories,
  };
};

const mapDispatchToProps = dispatch => ({
  getAllBrands: () => dispatch(factoryNewGetAllBrands()),

  fetchFactories: ({ designId }) => dispatch(fetchFactoriesForDesignId({ designId })),
  fetchMessages: ({ designId, factoryId }) => dispatch(fetchMessagesByDesignId({ designId, factoryId })),
  sendMessage: ({ designId, factoryId, message, file = null }) => dispatch(sendMessage({ designId, factoryId, message, file })),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MessagesTab)
);
