import React, { useState, useEffect } from "react";
import moment from "moment";
import find from "lodash/find";
import last from "lodash/last";
import "react-dates/initialize";

import styles from "./NewForm.module.scss";
import "react-dropdown/style.css";

import Button from "./../../UI/Button/Button";

import { withRouter } from "react-router-dom";
import { DATESTAMP_FORMAT } from "./../../../constants/Date";
import {
  DESIGN_CANCELED,
  APPROVED,
  RESUBMIT
} from "./../../../constants/Sample";

import "./SampleRow.scss";
import "react-tabs/style/react-tabs.css";
import { connect } from "react-redux";

import { getBrandTnaTemplate } from "./../../../store/actions/DesignActions";
import { closeModal } from "../../../store/actions/GlobalActions";
import { checkFactoryOrMerchant, getCurrentUserRole } from "../../../helpers/AuthHelper";
import { PRODUCT_DEVELOPMENT } from "../../../constants/Auth";
import { SAMPLE_STATUS_OPTIONS } from "../../../constants/Sample";
import { checkTnaReadyForPP } from "../../../helpers/DesignHelpers";
import { transformHistory } from "../../../helpers/samplingHelper";

import orangeClock from "assets/images/journey/ic-orange-clock.svg";
import greenTick from "assets/images/communication/ic-success.svg";
import { IconInfo } from "../../UI/Icons";
import SampleFiles from "./SampleFiles";

const SampleStatus = ({
  sample: { id, designId, sampleStatus, sampleDispatch },
  openCourierDetailsModel
}) => {
  const isApproved = sampleStatus === APPROVED;

  const currentSampleDispatch = find(sampleDispatch, { isActive: true });
  const isDispatched = currentSampleDispatch && currentSampleDispatch.sentDate;
  let status = sampleStatus;

  if (!sampleStatus) {
    if (isDispatched) {
      status = "Dispatched";
    } else {
      status = "Sample Requested";
    }
  } else if (sampleStatus === RESUBMIT) {
    if (isDispatched) {
      status = "Dispatched";
    } else {
      status = sampleStatus;
    }
  }

  return (
    <div className={`flex ${isApproved ? "text-green" : ""}`}>
      {status}
      {!sampleStatus && isDispatched && (
        <Button
          category="img shrink"
          className="info__icon clickable"
          onClick={() =>
            openCourierDetailsModel({
              data: {
                sampleId: id,
                designId,
                sampleStatus,
                sampleDispatch: [last(sampleDispatch)]
              },
              currentSampleDispatch
            })
          }
        >
          <IconInfo color="grey" />
        </Button>
      )}
    </div>
  );
};

const SampleRow = ({
  data: {
    sampleSize,
    sampleStatus,
    id = false,
    dueDate,
    showCancel,
    updatedAt,
    sampleType,
    sampleDispatch,
    factoryName,
    factoryId
  },
  changeData,
  openSampleUpdateModal,
  designId,
  postPreProductionCommit,
  isFactory,
  isSuperAdmin,
  data,
  saveDataForCancel,
  openVersionHistoryModal,
  getVersionHistoryDetail,
  versionDetail,
  collection,
  design,
  // new sampleapi's
  openCourierDetailsModel,
  openModal,
  closeModal,
  comments,
  prevFactoryId,
  prevFactoryName,
  quoteQuantity,
  tnaTemplate,
  userDetails
}) => {
  const sampleApproved = sampleStatus === APPROVED;

  // Track/Update will be visible when tna is active, Track progress will be visible when no tna is active

  const [freezStatus, setFreezStatus] = useState(false);
  const [version, setVersion] = useState([]);

  const [showFactoryName, setShowFactoryName] = useState(() =>
    factoryName ? true : false
  );
  const [showTNAPendingPopover, setShowTNAPendingPopover] = useState(false);

  // ======== THIS EFFECT CONTROLLES THE FREEZING FUNCTIONALITY OF SAMPLE SIZE AND STATUS =======
  useEffect(() => {
    if (sampleStatus === APPROVED || sampleStatus === DESIGN_CANCELED) {
      setFreezStatus(true);
    } else {
      setFreezStatus(false);
    }
  }, [id]);

  useEffect(() => {
    if (!showCancel) {
      if (sampleStatus === APPROVED || sampleStatus === DESIGN_CANCELED) {
        setFreezStatus(true);
      } else {
        setFreezStatus(false);
      }
    }
  }, [showCancel, sampleStatus]);

  useEffect(() => {
    if (!checkTnaReadyForPP({ sampleType, tnaPayload: tnaTemplate.payload })) {
      setShowTNAPendingPopover(true);
    }
  }, [tnaTemplate]);

  useEffect(() => {
    // versionDetail throughout denotes the sample history
    if (Object.keys(versionDetail).length > 0) {
      if (versionDetail[sampleType]) {
        setVersion(
          transformHistory(versionDetail, sampleType, comments, sampleDispatch)
        );
      } else {
        setVersion([]);
      }
    } else {
      setVersion([]);
    }
  }, [versionDetail, sampleStatus, id, data]);

  const getUpdatedVersionAfterUpdate = () => {
    let version = getVersionHistoryDetail(sampleType);
    setVersion(version);
  };

  const openAssociateModal = () => {
    openModal("ASSOCIATE_FACTORY", {
      hiddenFields: [
        { name: "collectionId", value: collection.id },
        { name: "brandId", value: collection.brand },
        { name: "sampleType", value: sampleType },
        { name: "sampleData ", value: data }
      ],
      initialValues: {
        name: collection.name,
        factory: factoryId || prevFactoryId,
        factoryName: factoryName || prevFactoryName,
        orderQuantity: quoteQuantity
        // description: collection.description
      },
      setShowFactoryName,
      isAdmin: isSuperAdmin
    });
  };

  const DispatchButton = () => {
    return (
      <Button
        category="blue-bg"
        onClick={() => {
          showTNAPendingPopover
            ? openModal("REDIRECT_TNA_UPDATE", {
                hiddenFields: [
                  { name: "brandId", value: collection.brand },
                  { name: "collectionId", value: collection.id },
                  { name: "designId", value: designId }
                ],
                closeModal
              })
            : openCourierDetailsModel({
                data,
                currentSampleDispatch: find(sampleDispatch, {
                  isActive: true
                })
              });
        }}
      >
        Add dispatch details
      </Button>
    );
  };

  const currentSampleDispatch = find(sampleDispatch, { isActive: true });
  const isDispatched = currentSampleDispatch && currentSampleDispatch.sentDate;

  const calculateDiff = () => {
    if (currentSampleDispatch && dueDate) {
      const formatDueDate = moment(dueDate).format(DATESTAMP_FORMAT);
      const formatSampleDispatch = moment(
        currentSampleDispatch.sentDate
      ).format(DATESTAMP_FORMAT);
      return moment(formatDueDate).diff(moment(formatSampleDispatch), "days");
    }
  };
  const daysDiff = calculateDiff();

  const isManager = getCurrentUserRole() === PRODUCT_DEVELOPMENT;

  const SampleCta = () => {
    const canDispatch = checkFactoryOrMerchant() && !isDispatched;
    const canUpdateStatus = isDispatched && !freezStatus && !checkFactoryOrMerchant();

    if (canDispatch) {
      return <DispatchButton />;
    }

    if (canUpdateStatus) {
      return (
        <Button
          category="blue-bg"
          onClick={() => {
            openSampleUpdateModal({
              designId,
              collectionId: collection.id,
              moduleRef: sampleType,
              postComment: postPreProductionCommit,
              moduleStatus: sampleStatus,
              moduleId: id,
              request: data,
              // update,
              noPrevComments: true,
              withComment: true,
              designName: design.name,
              sampleStatusTypes: SAMPLE_STATUS_OPTIONS,
              changeData,
              cardData: design
            });
            getUpdatedVersionAfterUpdate();
            // this updates the version details after updating the sampleStatus .

            if (sampleStatus === APPROVED) {
              setFreezStatus(true);
            }
            saveDataForCancel();
          }}
        >
          Update status
        </Button>
      );
    }

    return null;
  };

  return (
    <>
      <div className={`${styles.sample__row}`}>
        <div className="sample-row-grid">
          <div className="flex">
            <img
              src={sampleApproved ? greenTick : orangeClock}
              alt=""
              className="status-img"
            />
            <div className="cell-v2">
              <div>{sampleType}</div>
              <div className={styles.sample__secondary_text}>
                Size: {sampleSize}
              </div>
            </div>
          </div>

          {isManager || isSuperAdmin ? (
            <div>
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center"
                  // transform: "translateY(-6px)"
                }}
              >
                <div>
                  <div className="cell-v2">
                    {//Todo: Display factory name, factory name and due date
                    (factoryName && sampleStatus !== RESUBMIT) ||
                    showFactoryName ? (
                      <div>{factoryName}</div>
                    ) : (
                      <Button
                        category="plain-blue"
                        onClick={openAssociateModal}
                      >
                        Assign factory
                      </Button>
                    )}
                    {dueDate ? (
                      <>
                        <div className="date-style">
                          Due on {moment(dueDate).format(DATESTAMP_FORMAT)}
                        </div>
                        {daysDiff < 0 ? (
                          <div
                            style={{
                              color: "var(--fill-error)",
                              margin: "5px 0"
                            }}
                          >
                            {Math.abs(daysDiff)} days delayed
                          </div>
                        ) : null}
                      </>
                    ) : !factoryName || sampleStatus === RESUBMIT ? null : (
                      <div
                        style={{
                          color: "var(--fill-secondary-medium)",
                          textTransform: "uppercase",
                          cursor: "pointer"
                        }}
                        onClick={openAssociateModal}
                      >
                        Enter due date
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="cell-v2">
              {!userDetails.isFactory && showFactoryName && (
                <div>{factoryName}</div>
              )}
              {dueDate && (
                <div className="date-style">
                  Due on {moment(dueDate).format(DATESTAMP_FORMAT)}
                </div>
              )}
              {daysDiff < 0 ? (
                <div
                  style={{
                    color: "var(--fill-error)",
                    margin: "5px 0"
                  }}
                >
                  {Math.abs(daysDiff)} days delayed
                </div>
              ) : null}
            </div>
          )}

          <div>
            {id && (
              <div className="sample-status cell-v2">
                <SampleStatus
                  sample={data}
                  openCourierDetailsModel={openCourierDetailsModel}
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  <div
                    style={{
                      cursor: "pointer",
                      color: "#333340"
                    }}
                    className={`version__history ${styles.sample__secondary_text}`}
                    // onClick={() => openVersionHistoryModal(version)}
                  >
                    {`${moment(updatedAt).format(`MMM DD, YYYY`)} `}
                    <span
                      onClick={() => openVersionHistoryModal(version)}
                      style={{
                        color: "var(--fill-primary-blue)",
                        paddingLeft: "5px"
                      }}
                    >
                      History
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="sample-row__attachment-list">
            <SampleFiles
              designName={design.name}
              files={version[0] && version[0].files}
              openModal={openModal}
            />
          </div>

          <div className="sample-row--last">
            <SampleCta />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  users: state.auth.list,
  comments: state.messaging.messages,
  tnaTemplate: state.designs.brandTnaTemplate
});

const mapDispatchToProps = dispatch => ({
  getBrandTnaTemplate: details => dispatch(getBrandTnaTemplate(details)),
  closeModal: () => dispatch(closeModal())
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SampleRow)
);
