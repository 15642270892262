import React from "react";
import Select from "react-select";
import Button from "./../../Button/Button";

import "./CustomSelect.scss";
import PropTypes from "prop-types";
RFReactSelect.defaultProps = {
  multi: false,
  className: ""
};

RFReactSelect.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired
  }).isRequired,
  options: PropTypes.array.isRequired,
  isMulti: PropTypes.bool,
  className: PropTypes.string,
  styles: PropTypes.object
};

// https://gist.github.com/leocristofani/98312e61807db8f32e720c9f97a186e5

export default function RFReactSelect({
  input = {},
  options,
  isMulti,
  className,
  label,
  styles = {},
  selectProps,
  disabled,
  buttonClickHandler,
  meta: { error, submitFailed }
}) {
  const { name, value, onBlur, onChange, onFocus } = input;
  const transformedValue = transformValue(value, options, isMulti);
  return (
    <div className="select-grp">
      {/* <Button
        category="add medium"
        onClick={buttonClickHandler}
      >
        +
      </Button> */}
      <Select
        styles={styles}
        valueKey="value"
        id={name}
        name={name}
        disabled={disabled}
        value={transformedValue}
        isMulti={isMulti}
        options={options}
        onChange={
          isMulti ? multiChangeHandler(onChange) : singleChangeHandler(onChange)
        }
        onFocus={onFocus}
        className={className}
        {...selectProps}
      />
      {submitFailed && <span className="field_err">{error}</span>}
    </div>
  );
}

/**
 * onChange from Redux Form Field has to be called explicity.
 */
function singleChangeHandler(func) {
  return function handleSingleChange(value) {
    func(value ? value.value : "");
  };
}

/**
 * onBlur from Redux Form Field has to be called explicity.
 */
function multiChangeHandler(func) {
  return function handleMultiHandler(values) {
    func(values.map(value => value.value));
  };
}

/**
 * For single select, Redux Form keeps the value as a string, while React Select
 * wants the value in the form { value: "grape", label: "Grape" }
 *
 * * For multi select, Redux Form keeps the value as array of strings, while React Select
 * wants the array of values in the form [{ value: "grape", label: "Grape" }]
 */
function transformValue(value, options, multi) {
  if (multi && typeof value === "string") return [];

  const filteredOptions = options.filter(option => {
    return multi ? value.indexOf(option.value) !== -1 : option.value === value;
  });

  return multi ? filteredOptions : filteredOptions[0];
}
