import React from "react";
import moment from "moment";
import PerfectScrollBar from "react-perfect-scrollbar";

import styles from "../../../components/Orders/Orders.module.scss";
import fileIcon from "../../../assets/images/editor/ic-attach-file.svg";

const DispatchDetails = ({ data: { dispatches = [] } = {} }) => {
  return (
    <PerfectScrollBar style={{ maxHeight: window.innerHeight }}>
      <div
        style={{
          minHeight: "150px",
          backgroundColor: "#fff",
          padding: "14px",
          margin: "10px 0px",
          borderTop: "1px solid var(--fill-grey-light3)"
        }}
      >
        {dispatches.map(
          ({
            quantity,
            trackingCode,
            ewayBillFile,
            challanFile,
            invoiceFile,
            packingListFile,
            isOrderComplete,
            courierPartner,
            numberOfBoxes,
            dispatchDate
          }) => {
            return (
              <div
                style={{
                  marginBottom: "12px"
                }}

                className={"borderBottom__enhancer"}
              >
                <div style={{ lineHeight: "25px" }}>
                  <span style={{ fontSize: "12px" }}>
                    Dispatch Date: &nbsp;
                  </span>
                  <span>
                    {moment(dispatchDate).format("MMMM DD, YYYY")} &nbsp;
                  </span>
                  {isOrderComplete == 1 ? (
                    <span style={{ color: "#02bd70" }}>
                      This completes your order
                    </span>
                  ) : null}
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    alignItems: "center",
                    padding: "12px 0px"
                  }}
                >
                  <div className={styles.dispatch__item} style={{ flex: 1 }}>
                    <div className={styles.dispatch__item_value}>
                      Dispatch Quantity
                    </div>
                    <div className={styles.dispatch__item_label}>
                      {quantity}
                    </div>
                  </div>

                  <div className={styles.dispatch__item} style={{ flex: 1 }}>
                    <div className={styles.dispatch__item_value}>
                      Tracking ID
                    </div>
                    <div className={styles.dispatch__item_label}>
                      {trackingCode}
                    </div>
                  </div>

                  <div className={styles.dispatch__item} style={{ flex: 1 }}>
                    <div className={styles.dispatch__item_value}>
                      No of Boxes
                    </div>
                    <div className={styles.dispatch__item_label}>
                      {numberOfBoxes}
                    </div>
                  </div>

                  <div className={styles.dispatch__item} style={{ flex: 1 }}>
                    <div className={styles.dispatch__item_value}>
                      Courier Partner
                    </div>
                    <div className={styles.dispatch__item_label}>
                      {courierPartner}
                    </div>
                  </div>
                </div>

                {/* files from here  */}
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    alignItems: "center",
                    padding: "12px 0px"
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flex: 1
                    }}
                  >
                    <img
                      style={{ height: "20px" }}
                      src={fileIcon}
                      alt="po-file"
                    />
                    <a href={ewayBillFile}>E-way Bill</a>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flex: 1
                    }}
                  >
                    <img
                      style={{ height: "20px" }}
                      src={fileIcon}
                      alt="po-file"
                    />
                    <a href={invoiceFile}>Invoice Copy</a>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flex: 1
                    }}
                  >
                    <img
                      style={{ height: "20px" }}
                      src={fileIcon}
                      alt="po-file"
                    />
                    <a href={packingListFile} className="visited_link">
                      Packing List
                    </a>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flex: 1
                    }}
                  >
                    <img
                      style={{ height: "20px" }}
                      src={fileIcon}
                      alt="po-file"
                    />

                    <a href={challanFile}>Challan Copy</a>
                  </div>
                </div>
              </div>
            );
          }
        )}
      </div>
    </PerfectScrollBar>
  );
};

export default DispatchDetails;
