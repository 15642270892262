import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";

import { get_service_endpoint } from "../../ServiceEndpoints";
import axios from "axios";
import { CalendarPage } from "../UI/Date";

import ImgOrderCompleteStamp from "../../assets/img/Orders/OrderCompleteStamp.svg";
import "./Orders.scss";

const eq = get_service_endpoint("notificationView");

const ViewDispatchDetailsModal = ({
  orderId,
  orderPONumber,
  setHeaderTitle
}) => {
  const [details, setDetails] = useState([]);

  useEffect(() => {
    axios
      .get(`${eq}/orders/dispatch`, { params: { orderid: orderId } })
      .then(res => {
        setDetails(res.data);
      })
      .catch(err => console.log(err));

    orderPONumber && setHeaderTitle(`Dispatch details '${orderPONumber}'`);
  }, []);


  const isThisOrderComplete = !!details.find(item => item.isOrderComplete);

  return (
    <PerfectScrollbar className="dispatch-orders-modal">
      <div>
        {isThisOrderComplete && <div className="row row-order-complete">
          <div className="content">
            <div className="line-1">Order complete!</div>
            <div className="line-2">This order has been fully dispatched.</div>
          </div>
          <div className="image">
            <img src={ImgOrderCompleteStamp} alt="" />
          </div>
        </div>}
        <div className="dispatch-items">
          {details.map(
            ({
              id,
              quantity,
              trackingCode,
              ewayBillFile,
              challanFile,
              invoiceFile,
              packingListFile,
              courierPartner,
              numberOfBoxes,
              dispatchDate
            }) => {
              return (
                <div className="row-dispatch-item" key={id}>
                  <div className="left-section">
                    <CalendarPage date={dispatchDate} />
                  </div>
                  <div className="main-section">
                    <div className="heading">
                      <span className="title">
                        Dispatched via {courierPartner}
                      </span>
                    </div>

                    <div className="details">
                      <div className="row-detail header">
                        <div className="label">Tracking ID</div>
                        <div className="label">Quantity</div>
                        <div className="label">No. of Boxes</div>
                      </div>
                      <div className="row-detail">
                        <div className="value"> {trackingCode} </div>
                        <div className="value"> {quantity}pcs </div>
                        <div className="value"> {numberOfBoxes} </div>
                      </div>
                    </div>
                    <div className="documents">
                      {ewayBillFile && <a className="download-document-item" href={ewayBillFile}>E-way Bill</a>}
                      {invoiceFile && <a className="download-document-item" href={invoiceFile}>Invoice copy</a>}
                      {packingListFile && <a className="download-document-item" href={packingListFile}>Packing list</a>}
                      {challanFile && <a className="download-document-item" href={challanFile}>Challan copy</a>}
                    </div>
                  </div>
                </div>
              );
            }
          )}
        </div>
      </div >
    </PerfectScrollbar>
  );
};

export default ViewDispatchDetailsModal;
