import React, { useState, useEffect, useRef } from "react";
import "./SwatchLibraryContainer.scss";
import Filters from "../../components/SwatchLibrary/Filters";
import PerfectScrollbar from "react-perfect-scrollbar";
import SwatchGrid from "../../components/SwatchLibrary/SwatchGrid";
import Filter from "../../components/UI/Filter/Filter";
import Button from "../../components/UI/Button/Button";
import {
  FILTER_TOP_TIME,
  FILTER_UPLOAD_PERIOD,
  ADDITIONAL_STATIC_FILTERS
} from "../../constants/SwatchLibrary";
import { connect } from "react-redux";
import actions from "../../store/actions";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import DatePicker from "react-datepicker";
import { FABRIC_SUPPLIER, BDE } from "../../constants/Auth";
import { Helmet } from "react-helmet";
import { PAGE_TITLES } from "../../constants/Titles";

// Custom usePrevious value hook
const usePrevious = value => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();

  // Store current value in ref
  useEffect(
    () => {
      ref.current = value;
    },
    [value]
  ); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
};

const SwatchLibraryContainer = ({
  isFactory = false,
  isSuperAdmin = false,
  userDetails,
  usersList,
  match,
  location,
  history,
  filterMetadata,
  paginate,
  deleteSwatch,
  addSwatchVendor,
  userRole,
  ...props
}) => {
  let swatchFilters = [];
  const allowedRoles = [FABRIC_SUPPLIER, BDE];
  const showExtraFilters =
    isFactory || isSuperAdmin || allowedRoles.indexOf(userRole) > -1;
  if (showExtraFilters) {
    swatchFilters = ADDITIONAL_STATIC_FILTERS.slice();
  } else {
    swatchFilters = ADDITIONAL_STATIC_FILTERS.filter(
      filterItem => !filterItem.notVisibleToBrand
    );
  }
  const mergedFiltersMetadata = [
    swatchFilters[0],
    ...filterMetadata,
    ...swatchFilters.slice(1)
  ];

  const [filterList, setfilterList] = useState({
    dateFilter: null,
    uploadFilter: null,
    brandId: null,
    search: null,
    ...mergedFiltersMetadata
      .map(f => f.title)
      .reduce((acc, val) => ({ ...acc, [val]: [] }), {})
  });
  const [currentUser, setCurrentUser] = useState(null);

  // Used to determine if the User is here from DesignFiles tab
  // Used to disable all other functions except "Add to Design"
  const isFilesMode = location.state && location.state.filesMode;

  const previousFilterList = usePrevious(filterList);

  let customDatePickerStartRef = null,
    customDatePickerEndRef = null;

  useEffect(() => {
    if (isFactory) {
      props.getAllBrands();
      props.getSwatchVendors();
    } else if (userRole == FABRIC_SUPPLIER || userRole == BDE) {
      props.getSwatchVendors();
    }
    return undefined;
  }, []);

  useEffect(
    () => {
      props.getSwatchFilters({
        brandId: filterList.brandId,
        dateFilter: filterList.dateFilter,
        uploadFilter: filterList.uploadFilter
      });
    },
    [filterList.brandId, filterList.dateFilter, filterList.uploadFilter]
  );

  useEffect(
    () => {
      if (_.isEqual(previousFilterList, filterList)) return undefined;

      if (!isFactory) {
        if (userRole == FABRIC_SUPPLIER || userRole == BDE) {
          props.filterSwatches(filterList);
        } else if (filterList.brandId !== null) {
          props.filterSwatches(filterList);
        }
      } else {
        props.filterSwatches(filterList);
      }
      return undefined;
    },
    [filterList]
  );

  useEffect(
    () => {
      if (userRole == FABRIC_SUPPLIER || userRole == BDE) {
        setfilterList(filterList);
      } else {
        if (!isFactory && Object.keys(usersList).length > 0) {
          setfilterList({
            ...filterList,
            brandId: usersList[parseInt(userDetails.id)].brand
          });
        }
        if (!isFactory && !currentUser && Object.keys(usersList).length) {
          let user = false;
          Object.keys(usersList).forEach(key => {
            if (userDetails.id == key) {
              user = usersList[key]["brand"];
            }
          });
          setCurrentUser(user);
        }
      }
    },
    [usersList]
  );

  useEffect(
    () => {
      // This effect is responsible for scrolling to the top of the page on next button click
      if (containerEle.current) {
        containerEle.current.scrollTop = 0;
      }
    },
    [props.swatchList]
  );

  const loadNext = () => {
    // const pageOffset = moment.utc(paginate.nextDateOffSet).format('YYYY-MM-DD HH:mm:ss');
    props.filterSwatches(filterList, paginate.nextPageOffSet);
  };

  const loadPrevious = () => {
    // const pageOffset = moment.utc(paginate.prevDateOffSet).format('YYYY-MM-DD HH:mm:ss');
    props.filterSwatches(filterList, paginate.prevPageOffSet);
  };

  const containerEle = useRef(null);

  const canCreateSwatch = isFactory || userRole == FABRIC_SUPPLIER;

  return (
    <div className="flex-col swatch-library-container" >
      <Helmet
        bodyAttributes={{
            class: 'body_container'
        }}
        title={PAGE_TITLES.swatchLibrary()}
      />
      <div className="flex header ">
        {(paginate && paginate.totalRecords !== undefined && (
          <div className="f-sz-l" style={{ marginRight: "15px" }}>
            {paginate.totalRecords} Swatches
          </div>
        )) || <span />}

        {/* hide this when coming from files */}
        {canCreateSwatch && !isFilesMode ? (
          <Button
            category="blue-bg"
            onClick={() =>
              props.openModal("ADD_SWATCH", {
                afterSubmit: () => {
                  props.filterSwatches(filterList);
                  props.getSwatchFilters(filterList);
                },
                vendors: props.vendors
              })
            }
          >
            Add Swatch
          </Button>
        ) : null}
      </div>

      <div className="flex content" id="swatch-library-container">
        <div className="left-container">
          <div className="filters" style={{ zIndex: "0" }}>
            <Filters
              filterList={filterList}
              filters={mergedFiltersMetadata}
              onFiltersChange={newFilters => {
                setfilterList({ ...filterList, ...newFilters });
              }}
            />
          </div>
        </div>
        <div className="main-container">
          <div className="filters-row">
            <div
              className="flex filters-list"
              style={{ marginLeft: "auto", alignItems: "center" }}
            >
              {showExtraFilters && (
                <Filter
                  width={150}
                  title="Brand Name"
                  onChange={({ label, value }) => {
                    setfilterList({ ...filterList, brandId: value });
                  }}
                  initialSelectedItem={{ label: "All Brands", value: null }}
                  filterOptions={[
                    { label: "All", value: null },
                    ...props.brandsList
                  ]}
                />
              )}
              <>
                <Filter
                  width={180}
                  title="Share Period"
                  onChange={({ label, value }) => {
                    if (Number(value) === 4)
                      customDatePickerStartRef.setOpen(true);
                    else
                      setfilterList({
                        ...filterList,
                        dateFilter: value,
                        startDate: null,
                        endDate: null
                      });
                  }}
                  initialSelectedItem={{ label: "All Time", value: null }}
                  filterOptions={[
                    { label: "All Time", value: null },
                    ...FILTER_TOP_TIME
                  ]}
                  style={{ marginLeft: "15px" }}
                />
                {showExtraFilters && (
                  <Filter
                    width={180}
                    title="Upload period"
                    onChange={({ label, value }) => {
                      setfilterList({
                        ...filterList,
                        uploadFilter: value,
                        startDate: null,
                        endDate: null
                      });
                    }}
                    initialSelectedItem={{ label: "All Time", value: null }}
                    filterOptions={[
                      { label: "All Time", value: null },
                      ...FILTER_UPLOAD_PERIOD
                    ]}
                    style={{ marginLeft: "15px" }}
                  />
                )}
                <div>
                  <DatePicker
                    ref={c => (customDatePickerStartRef = c)}
                    selectsStart
                    withPortal
                    fixedHeight
                    customInput={<div />}
                    startDate={filterList.startDate}
                    endDate={filterList.endDate}
                    placeholderText="Select Date"
                    dateFormat="MMMM dd, yyyy"
                    className="custom-datepicker hide"
                    selected={""}
                    onChange={arg1 => {
                      setfilterList({ ...filterList, startDate: arg1 });
                      customDatePickerStartRef.setOpen(false);
                      customDatePickerEndRef.setOpen(true);
                    }}
                  />
                  <DatePicker
                    ref={c => (customDatePickerEndRef = c)}
                    startDate={filterList.startDate}
                    endDate={filterList.endDate}
                    selectsEnd
                    withPortal
                    fixedHeight
                    customInput={<div />}
                    placeholderText="Select Date"
                    dateFormat="MMMM dd, yyyy"
                    className="custom-datepicker hide"
                    selected={""}
                    onChange={arg1 => {
                      setfilterList({
                        ...filterList,
                        endDate: arg1,
                        dateFilter: "4"
                      });
                    }}
                  />
                </div>
              </>
            </div>
          </div>
          <div className="swatches-grid-container" ref={containerEle}>
            <div style={{ padding: "0px 24px" }}>
              <SwatchGrid
                cardList={props.swatchList}
                openModal={props.openModal}
                brandid={currentUser}
                isFactory={isFactory}
                isSuperAdmin={isSuperAdmin}
                onlyAddToDesign={isFilesMode} // handle this also
                paginate={paginate}
                loadNext={loadNext}
                loadPrevious={loadPrevious}
                deleteSwatch={deleteSwatch}
                details={
                  !isFilesMode
                    ? {}
                    : {
                        designId: location.state.designId,
                        brandId: location.state.brandId,
                        collectionId: location.state.collectionId,
                        designName: location.state.designName
                      }
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ swatchLibrary, products, auth }) => ({
  swatchList: swatchLibrary.list,
  vendors: swatchLibrary.vendors,
  brandsList: products.allBrands.map(({ name, id }) => ({
    label: name,
    value: id
  })),
  filterMetadata: swatchLibrary.filters,
  paginate: swatchLibrary.paginate
});

const mapDispatchToProps = dispatch => ({
  openModal: (payload, additionalProps) =>
    dispatch(actions.openModal(payload, additionalProps)),
  filterSwatches: (details, pageOffset = null) =>
    dispatch(actions.filterSwatches(details, pageOffset)),
  getAllBrands: () => dispatch(actions.getAllBrands()),
  getSwatchFilters: details => dispatch(actions.getSwatchFilters(details)),
  getSwatchVendors: details => dispatch(actions.getSwatchVendors(details)),
  deleteSwatch: details => dispatch(actions.deleteSwatch(details)),
  addSwatchVendorFilter: details => dispatch(actions.addSwatchVendor(details))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SwatchLibraryContainer)
);
