import React, { Component } from "react";

import emptyIcon from "assets/img/Quote/ic-quote-empty.svg";
import { ReactComponent as InfoIcon } from "assets/images/action/ic-info-black.svg";
import "./styles.scss";

const QuoteCard = ({ design, quoteDetails, noteText, overlayIcon }) => {
  const quoteCost = JSON.parse(quoteDetails.quote);
  return (
    <React.Fragment>
      <div className="card-horizontal">
        <img
          src={design.coverImage.src}
          alt=""
          className="cover-image"
          style={
            design.coverImage.placeholder
              ? { objectFit: "none" }
              : { objectFit: "cover" }
          }
        />
        <div>
          <div>
            <img src={emptyIcon} alt="" />
            <img src={overlayIcon} alt="" className="action-icon" />
          </div>
          <h4>INR {quoteCost.totalCost}</h4>
          <span>Shared by {quoteDetails.factory_name}</span>
        </div>
      </div>
      {noteText && <div className="footer-note">
        <InfoIcon /> {noteText}
      </div>}
    </React.Fragment>
  );
};

export default QuoteCard;
