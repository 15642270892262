import React from "react";
import "./Note.scss";

const Note = ({ children }) => {
  return (
    <div className="note">
      <span className="f-w-semi-bold f-sz-l">Note</span>
      <br />
      <br />
      <span className="text">{children}</span>
    </div>
  );
};

export default Note;
