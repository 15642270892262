import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import CustomInput from '../../../UI/Form/Input/CustomInput';
import { IconMagnifier } from '../../../UI/Icons';
import EmptyScreen from '../../../UI/EmptyScreen';
import FactorySummary from './FactorySummary';
import OrgRow from '../OrgRow';
import './style.scss';

export default class SelectFactory extends React.Component {
  static propTypes = {
    factories: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      factoriesCount: PropTypes.number.isRequired,
    })),
    brand: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      factoriesCount: PropTypes.number.isRequired,
    }),
    factoryDesigns: PropTypes.object.isRequired,
    currency: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onOrgChange: PropTypes.func,
    getDesigns: PropTypes.func.isRequired,
    value: PropTypes.object,
  };

  state = {
    filteredFactories: [],
    searchText: '',
    selectedFactory: null,

    propsFactories: null,
    propsValue: null,
  };

  static getDerivedStateFromProps(nextProps, oldState) {
    const nextState = {};

    if (nextProps.factories !== oldState.propsFactories) {
      nextState.propsFactories = nextProps.factories;
      nextState.filteredFactories = nextProps.factories || [];
    }

    if (nextProps.value !== oldState.propsValue) {
      nextState.propsValue = nextProps.value;
      nextState.selectedFactory = nextProps.value;
    }

    return Object.keys(nextState).length > 0 ? nextState : null;
  }

  _handleSearchTextChange = (e) => {
    const { factories } = this.props;
    const nextState = {
      searchText: e.target.value,
      validSearchText: e.target.value.trim().toLowerCase(),
    };
    const validSearchText = nextState.searchText.trim().toLowerCase();

    if (nextState.validSearchText !== this.state.validSearchText) {
      if (validSearchText.length > 0) {
        nextState.filteredFactories = factories.filter(factory =>
          factory.name.toLowerCase().indexOf(validSearchText) >= 0 ||
          (factory.city && factory.city.toLowerCase().indexOf(validSearchText) >= 0)
        );
      } else {
        nextState.filteredFactories = factories;
      }
    }

    this.setState(nextState);
  }

  _handleFactorySelection = (selectedFactory, isSelected) => {
    if (selectedFactory) {
      const { getDesigns } = this.props;
      getDesigns(selectedFactory.id);
    }

    this.setState({
      selectedFactory: isSelected ? selectedFactory : null,
      designs: [],
    });
  }

  render() {
    const { onSubmit, onOrgChange, brand, factories, factoryDesigns } = this.props;
    const { filteredFactories, selectedFactory, searchText } = this.state;

    return <div className="order-select-factory">
      <div className="order-select-factory__search">
        <div className="heading">Select factory to order</div>
        <div className="order-select-factory__search__field">
          <CustomInput
            placeholder="Search factory"
            prefixText={<IconMagnifier color="grey-medium" />}
            autoFocus={true}
            value={searchText}
            onChange={this._handleSearchTextChange}
          />
        </div>
        <PerfectScrollbar className="order-select-factory__search__results">
          {
            filteredFactories.length === 0
              ?
              factories.length === 0
                ? <EmptyScreen heading="No factories available" />
                : <EmptyScreen heading="No results found" />
              : <div>
                {filteredFactories.map(factory => (<OrgRow
                  key={factory.id}
                  org={factory}
                  onChange={(e, isSelected) => this._handleFactorySelection(factory, isSelected)}
                  isSelected={selectedFactory && selectedFactory.id === factory.id}
                />))}
              </div>
          }
        </PerfectScrollbar>
      </div>
      <div className="order-select-factory__summary">
        <FactorySummary
          brand={brand}
          factory={selectedFactory}
          onSubmit={onSubmit}
          onOrgChange={(orgType) => onOrgChange(orgType, selectedFactory && selectedFactory.id)}
          designs={selectedFactory && factoryDesigns[selectedFactory.id] && factoryDesigns[selectedFactory.id].rows.slice(0, 5)}
          designsCount={selectedFactory && factoryDesigns[selectedFactory.id] && factoryDesigns[selectedFactory.id].paginate.count.total}
        />
      </div>
    </div>;
  }
}
