import React from 'react';
import ModalFooter from '../../../UI/Modal/ModalFooter';
import DesignCard from '../../../UI/Card/DesignCard';
import CollectionCard from '../../../UI/Card/CollectionCard';
import AnimMove from '../../../UI/Icons/AnimMove';
import './style.scss';


const MoveDesignConfirmModal = ({
  design,
  collection,
  closeModal,
  onConfirm,
}) => {
  return <div className="move-design-cfm-modal">
    <div class="move-design-cfm-modal__content">
      <DesignCard design={design} />
      <AnimMove speed={250}/>
      <CollectionCard collection={collection} designs={collection.designs} designCount={3}/>
    </div>

    <ModalFooter
      cancel="Dismiss"
      submit="Confirm"
      canSubmit={true}
      canCancel={true}
      onCancel={closeModal}
      onSubmit={onConfirm}
    />
  </div>;
};

export default MoveDesignConfirmModal;
