import React, { useState } from "react";
import moment, { invalid } from "moment";
import { DATESTAMP_FORMAT } from "../../../constants/Date";

const TnaItem = ({ data, firstItem, lastItem, id, ...restProps }) => {
  const [showChildrens, setshowChildrens] = useState(false);
  const getCalculatedDate = date => {
    if (!date) {
      return null;
    }

    return moment(date).format(DATESTAMP_FORMAT);
  };

  const checkAttributeValues = attrValues => {
    return attrValues.some(attr => {
      if (!attr.attributeValue || moment(attr.attributeValue).isAfter()) {
        return false;
      }

      return true;
    });
  };

  var isReturnNull = false;

  return (
    <div style={{}}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
        onClick={() => {
          setshowChildrens(!showChildrens);
        }}
      >
        <div style={{ display: "flex" }}>
          <div
            style={{
              width: "3px",
              backgroundColor: data.minDate ? "#02bd70" : "#ccc",
              marginLeft: "4px",

              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            {/* {!firstItem ? (
              <div
                style={{
                  backgroundColor: invertColor ? "#ccc" : "#02bd70",
                  width: "3px"
                }}
              >
                <div style={{ opacity: 0, height: "25px" }}>b</div>
              </div>
            ) : null} */}
            <div>
              <div
                style={{
                  width: "16px",
                  height: "16px",
                  borderRadius: "100px",
                  backgroundColor: data.minDate ? "#02bd70" : "#ccc"
                }}
              />
              {!lastItem ? (
                <div style={{ opacity: 0, height: "50px" }}>b</div>
              ) : null}
            </div>
          </div>
          <div
            style={{
              paddingLeft: "12px",
              // paddingTop: !firstItem ? "25px" : "2px",

              display: "flex",
              // alignItems: "center",

              alignSelf: "flex-start"
            }}
          >
            <div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    fontSize: "14px",
                    color: "var(--fill-locofast-black)",
                    fontWeight: 600,
                    textTransform: "uppercase"
                  }}
                >
                  {data.category}
                </div>
                <div
                  style={{
                    fontSize: "10px",
                    paddingLeft: "12px",
                    color: "var(--fill-primary-medium)"
                  }}
                >
                  {data.minDate && data.category !== "Dispatch"
                    ? `${!showChildrens ? "Collapse" : "Expand"}`
                    : null}
                </div>
              </div>
              <div>
                {data.maxDate && data.minDate !== data.maxDate
                  ? `${data.minDate} - ${data.maxDate}`
                  : `${data.minDate ? data.minDate : ""}`}
              </div>
            </div>
          </div>
        </div>
      </div>
      {!showChildrens ? (
        <div style={{ paddingLeft: "0px" }}>
          {data.data.map(
            (
              { subCategory, attributeValue, subAttributeValue, isGrey },
              index
            ) => {
              let isInvalidDate = getCalculatedDate(attributeValue);
              if (subCategory === "Dispatch") {
                return null;
              }

              if (!index && !isInvalidDate) {
                // alert(subCategory);
                isReturnNull = !isInvalidDate;
                return null;
              }

              if (isReturnNull) {
                return null;
              }

              isReturnNull = !isInvalidDate; // this returns null after empty on future date .

              // ***** this thing below converts the labels ******
              if (
                subCategory === "Sewing (Milestone 1)" ||
                subCategory === "Sewing (Milestone 2)" ||
                subCategory === "Sewing (Milestone 3)" ||
                subCategory === "Sewing (Milestone 4)"
              ) {
                if (subAttributeValue) {
                  subCategory = `Sewing (${subAttributeValue}% completed)`;
                } else {
                  subCategory = "Sewing (Next Milestone)";
                }
              } else if (subCategory === "Trims inward date") {
                subCategory = "Trims inward completed";
              } else if (subCategory === "Fabric inward date") {
                subCategory = "Fabric inward completed";
              } else if (subCategory === "Testing completion") {
                subCategory = "Testing completed";
              } else if (subCategory === "Bulk cut date") {
                subCategory = "Bulk cut completed";
              } else if (subCategory === "Sewing start date") {
                subCategory = "Sewing started";
              } else if (subCategory === "Finishing") {
                subCategory = "Finishing completed";
              } else if (subCategory === "Packing") {
                subCategory = "Packing completed";
              }

              return (
                <div
                  style={{
                    display: "flex"
                  }}
                >
                  <div
                    style={{
                      width: "3px",
                      backgroundColor: isGrey ? "#ccc" : "#02bd70",
                      marginLeft: "4px",

                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center"
                    }}
                  >
                    {/* <div style={{ opacity: 0, height: "25px" }}>b</div> */}

                    <div
                      style={{
                        width: "12px",
                        height: "12px",
                        borderRadius: "100px",
                        backgroundColor: !isGrey ? "#02bd70" : "#ccc"
                      }}
                    />
                    <div style={{ opacity: 0, height: "50px" }}>b</div>
                  </div>

                  <div
                    style={{
                      fontSize: "12px",
                      paddingLeft: "24px",
                      lineHeight: "18px",
                      marginTop: "-10px"
                    }}
                  >
                    <div style={{ fontWeight: 600 }}>{subCategory}</div>
                    <div className="label__class">{isInvalidDate}</div>
                  </div>
                </div>
              );
            }
          )}
        </div>
      ) : null}
    </div>
  );
};

export default TnaItem;
