import React, { Component } from "react";
import { Form, Field, reduxForm, formValueSelector } from "redux-form";
import "./QuoteDetails.scss";
import { connect } from "react-redux";
// import { required, alphaNumeric } from "../../../helpers/form-validations";

const formName = "newQuote";

class newQuoteModal extends Component {


  renderField = (field) => (
    <div className="input-row">
      <input {...field.input} type="text"/>
      {field.meta.touched && field.meta.error &&
       <span className="error">{field.meta.error}</span>}
    </div>
  )


  render() {
    const {
      handleSubmit,
      //   pristine,
      //   reset,
      //   submitting,
      change,
      hiddenFields
    } = this.props;

    // Add hidden fields
    if (hiddenFields) hiddenFields.forEach(f => change(f.name, f.value));

    return (
      <div className="flex-col new-quote-form">
        <Form
          name={formName}
          id={formName}
          onSubmit={handleSubmit(values => {
            if (values["quote_type"]) {
              values["status"] = "-1";
              if (values["quote_type"] === "OPEN") {
                values["quote"] = {
                  table: {
                    fabrics: [{ name: "Fabric" }],
                    trims: [
                      { name: "M / Label" },
                      { name: "Wash Care Label" },
                      { name: "Hang Tag" },
                      { name: "Thread" },
                      { name: "Fusing" },
                      { name: "Buttons" }
                    ],
                    "packaging material": [
                      { name: "Poly Bags" },
                      { name: "Packing Material" },
                      { name: "Transportation" }
                    ],
                    CMTP: [
                      { name: "Pattern + Cutting" },
                      { name: "Stitching" },
                      { name: "Testing" },
                      { name: "Washing" },
                      { name: "Finishing / Packing" },
                      { name: "Buttoning / Hole" },
                      { name: "Sample Pieces" }
                    ]
                  },
                  totalCost: 0,
                  additionalCosts: {
                    Overheads: 0,
                    "Factory Profit": 0,
                    "LF Profit": 0,
                    Profit: 0,
                    Dumpage: 0
                  },
                  total_quantity: 0
                };
              } else if (values["quote_type"] === "BUNDLED") {
                values["quote"] = {
                  quantity: 0,
                  // quotation_currency: 0,
                  price_per_garment: 0,
                  totalCost: 0,
                  bundled_quantity: 0
                };
              }

              this.props.onSubmit(values);
            }
          })}
        >
          <span className={`loco__radio ${this.props.quoteType === "BUNDLED" ? 'active_loco__radio' : ''}`}>
            <Field
              name="quote_type"
              component="input"
              type="radio"
              value="BUNDLED"
              id="bundled_quote"
            />
            <label for="bundled_quote">
              <span>Bundled Quote</span>
              <p className="quote-text">
                A bundled quote includes the total quantity(pcs) and price per
                garment.
              </p>
            </label>
          </span>
          <br />
          <span className={`loco__radio ${this.props.quoteType === "OPEN" ? 'active_loco__radio' : ''}`}>
            <Field
              name="quote_type"
              component="input"
              type="radio"
              value="OPEN"
              id="open_quote"
            />
            <label for="open_quote">
              <span>Open Quote</span>
              <p className="quote-text">
                An open quote is a detailed price breakup of all items going in
                a garment.
              </p>
            </label>
          </span>
        </Form>
      </div>
    );
  }
}

newQuoteModal = reduxForm({
  form: formName
})(newQuoteModal)

// To read form values
const selector = formValueSelector(formName)
newQuoteModal = connect(
  (state, ownProps) => {
    const quoteType = selector(state, 'quote_type')
    const data =  {
      quoteType
    }
    if(ownProps.initialValues){
      data.initialValues = { ...ownProps.initialValues }
    }
    return data;
  }
)(newQuoteModal)

export default newQuoteModal
