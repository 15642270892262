import React from "react";
import { transformSizes } from "helpers/DesignHelpers";
import { MAP_SAMPLE_TYPES } from "constants/Design";
import { TNA_STATUS } from "constants/Tna";
import "./TnaHeader.scss";

const tnaHeaderObj = {
  factory: "Factory",
  orderQuantity: "Order quantity",
  poQuantity: "Quantity from P.O.",
  isSampleApprovalRequired: "Sample approval required",
  costClosureDate: "Cost closure date",
  poNumber: "PO number",
};

const TnaHeader = props => {
  const {
    tnaStatus,
    isFactory,
    sampleType = "NA",
    sampleSize = "NA",
  } = props;

  const tnaSubmitted = tnaStatus === TNA_STATUS.SUBMITTED;

  return (
    <div className="tna-header">
      <div className="tna-header__heading">
        {(tnaStatus === TNA_STATUS.APPROVED)
          ? "Production Details"
          : isFactory
            ? `Plan requested by ${props.data["brandName"] || '--'}`
            : `Plan requested from ${props.data["factory"] || '--'}`}
      </div>
      <div className="tna-header__data">
        {Object.keys(tnaHeaderObj).map(key => {
          if ((isFactory || !tnaSubmitted) && key === "factory") return null;
          if (typeof (props.data[key]) === 'undefined') {
            return null;
          }

          return (
            <div className="tna-header__item" key={key} id={key}>
              <div className="tna-header__item__heading">
                {tnaHeaderObj[key]}
              </div>
              <div className="tna-header__item__value">{props.data[key]}</div>
            </div>
          );
        })}
        <div className="tna-header__item" key="sizes" id="sizes">
          <div className="tna-header__item__heading">
            Requested{" "}
            {sampleType === MAP_SAMPLE_TYPES.sizeSet
              ? "size-set sample sizes"
              : "PP sample size"}
          </div>
          <div className="tna-header__item__value">
            {transformSizes(sampleSize)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TnaHeader;
