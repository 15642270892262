import React from 'react';
import ImgEmptyFiltersResult from "assets/images/emptyPages/empty-filters-result.svg";
import EmptyScreen from "../EmptyScreen";
import Button from "../Button/Button";
import './index.scss';

const EmptyFilterResult = ({
  onClearFilters,
}) => {
  return <EmptyScreen
    icon={ImgEmptyFiltersResult}
    heading="No matching results"
    description="No results found for the applied filters"
    cta={<Button category="blue uppercase filter" onClick={onClearFilters}>Clear Filters</Button>}
  />;
};

export default EmptyFilterResult;
