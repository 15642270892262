import React, { useState, useEffect, useRef } from "react";
import Downshift from "downshift";
import PerfectScrollbar from "react-perfect-scrollbar";

import { get_service_endpoint } from "../../../ServiceEndpoints";
import axios from "axios";
import { connect } from "react-redux";
import { flash } from "../../../store/actions/CommonActions";
import {  } from './../../../store/actions/CollectionActions';
import Dropdown from "react-dropdown";
import Input from "../../UI/Form/Input/CustomInput";
import './ChooseFactoryModal.scss'
import DatePicker from "react-datepicker";
import _ from 'lodash';
import { closeModal, openModal } from "../../../store/actions/GlobalActions";
import { getSamplesByDesignId, getFactoryTnaTemplate } from "../../../store/actions/DesignActions";
import { getAllSamples } from "../../../store/actions/Products";
import moment from 'moment';

const newEp = get_service_endpoint("notificationView");

const ChooseFactoryModal = ({ hiddenFields, closeModal, showFlash, getSamplesByDesignId, initialValues, setShowFactoryName, getFactoryTnaTemplate, tnaTemplate, ...restProps }) => {

  let { factory, factoryName, orderQuantity: quoteQuantity } = initialValues;

  let { setFactoryAndTna } = restProps;

  const [factories, setFactories] = useState([]);
  const [selectedFactory, setSelectedFactory] = useState(null);
  const [orderQuantity, setOrderQuantity] = useState(quoteQuantity);

  const [collectionId, brandId, sampleType, sampleData] = hiddenFields;

  const isFirstTnaTemplate = useRef(true);


  let samplingData = sampleData.value;

  const [ dueDate, setDueDate ] = useState(samplingData.dueDate ? samplingData.dueDate : new Date());


  const dateDifference = () => {
    const daysDiff = moment(dueDate, 'YYYY MM DD').diff(moment().format('YYYY MM DD'), "days")
    if(daysDiff == 0)   return `Due today`
    else if(daysDiff >0) return `Due in ${daysDiff} days`;
    else
      return `Delayed by ${Math.abs(daysDiff)} days`
  }

  useEffect(() => {
    let brand = brandId.value
    if(restProps.isAdmin){
      // In case of admin, the api expects 10 as the brandId
      brand = 10;
    }
    axios
      .get(`${newEp}/factory`, {
        params: {
          brandid: `[${brand}]`,
          isLoader: true
        }
      })
      .then(res => {
        let factoriesData = res.data;
        factoriesData = _.filter(factoriesData, {isCreatorLfUser: 1});
        setFactories(factoriesData);
        if(factory){
          if(_.find(factoriesData, {id: factory})) {
            setSelectedFactory({value: factory, label: factoryName});
          }
        }
        var selectedTemp;
        factoriesData.forEach((factory, index) => {
          if(!factory || !factory.colAssociations) return;

          Object.keys(factory.colAssociations).forEach(colAssociationIndex => {
            let colAssociation = factory.colAssociations[colAssociationIndex];

            if(colAssociation.collection == collectionId.value){
              selectedTemp = {...factory, addressId : colAssociation.lfrootAddressId }
              // setSelectedFactory(selectedTemp);
            }
          });
        });
      });
  }, []);

  useEffect(()=>{

    // if(isFirstTnaTemplate.current){
    //   isFirstTnaTemplate.current = false;
    //   return;
    // }
    const { designId, brandId } = hiddenFields[3].value;
    getFactoryTnaTemplate({
      designid: designId,
      brandid: brandId
    })
    // first fetch the tna attributes from the redux store

    // If it is pp sample, then create the tna
  },[])


  const categoryTypeToNumber = {
    'Prototype/Fit Sample' : '0',
    'Size-set Sample': '1',
    'Preproduction Sample' : '2',
    'Top of Bulk (TOB) Sample' : '3',
    'Production' : '4'
  }

  const allocateFactory = () => {
    if(selectedFactory && dueDate) {
      const { designId, sampleType, id, sampleId } = samplingData;
      const request = {
        designId,
        orderQuantity,
        attributes: tnaTemplate.payload
      }
      if(sampleType == "Preproduction Sample") {
        if(orderQuantity == 0)
          return;
        if(request.attributes[0].tnaId){
          axios
          .patch(`${get_service_endpoint("notificationView")}/tna`, {...request, id: request.attributes[0].tnaId})
          .then(res => {
            if (res.ERROR) {
              // show error
            }
          })
          .catch(err => {
            console.log(err);
          });
        } else {
          axios
          .post(`${get_service_endpoint("notificationView")}/tna`, request)
          .then(res => {
            if (res.ERROR) {
              // show error
            }
          })
          .catch(err => {
            console.log(err);
          });
        }

      }
      const requestObj = {
        brandId: brandId.value,
        factoryId: selectedFactory.value,
        collection: collectionId.value,
        lfrootAddressId: _.find(factories, {id: selectedFactory.value})['addresses'][0].id,
        designId,
        category: categoryTypeToNumber[sampleType],
        designSampleDetailsId: id || sampleId,
        assignedToLf: true
      }
      axios.post(`${newEp}/factory/collections`, requestObj).then(res=>{
        axios.patch(`${newEp}/sample`, {
          id: id || sampleId,
          dueDate,
          designId,
          sampleType
        }).then(result=>{
          closeModal();

            showFlash({
              message: "Factory associated successfully.",
              type: "action",
              actionType: "Association"
            })
            setShowFactoryName && setShowFactoryName(true);
          if(samplingData.dueDate !== dueDate) {
              getSamplesByDesignId({ designId });
              if(setFactoryAndTna){
                setFactoryAndTna(dueDate,selectedFactory);
              }
          }
        }).catch(err=>{
          console.log(err);
        })

      })
    }
  }

  const options = _(factories).map(({name, id})=>{
    return {label: name, value: id}
  }).sortBy(factory=>factory.label.toLowerCase()).value()

  const convertDateToDays = date => {
    if (!date)
      return (
        <div style={{ fontSize: "12px", color: "var(--fill-primary-medium)" }}>
          Due today
        </div>
      );

    if (date < 0)
      return (
        <div style={{ fontSize: "12px", color: "var(--fill-secondary-dark)" }}>
          {`Delayed by ${Math.abs(date)} days`}
        </div>
      );

    if (date > 0)
      return (
        <div style={{ fontSize: "12px", color: "rgb(240, 105, 16)" }}>
          {`Due in ${date} days`}
        </div>
      );
  };

  return (
    <div>
    <div style={{ minWidth: "350px" }} >
      <PerfectScrollbar style={{ maxHeight: "500px" }}>
      <div className="factoryassociation__container">

       <div style={{ minWidth: "300px"}}>
          <div className="label__class" style={{ padding: "6px 2px 6px 2px", textTransform: 'capitalize' }}>
            Factory <sup>*</sup>
          </div>
          <Dropdown
            options={options}
            onChange={(factory) =>setSelectedFactory(factory)}
            value={selectedFactory ? selectedFactory : "Select factory"}
            placeholder="Select factory"
            className={"factoryassociation__dropdown_menu_white"}
            menuClassName={"factoryassociation__dropdown_menu_options"}
            controlClassName={"factoryassociation__dropdown_menu"}
            arrowClassName={"factoryassociation__dropdown_arrow"}
            style={{ cursor: "pointer" }}

          />

        </div>

      {
        sampleType.value === "Preproduction Sample" && <div>
          <div className="label__class" style={{ padding: "6px 2px 6px 2px" }}>
            Order quantity<sup>*</sup>
          </div>
          <Input
            type="number"
            value={orderQuantity}
            placeholder="Eg: 10"
            onChange={({ target: { value } }) => {
              setOrderQuantity(value)
            }}
          />
        </div>
      }
      <div>
        <div className="label__class" style={{ padding: "6px 2px 6px 2px" }}>
          Due Date<sup>*</sup>
        </div>
        <DatePicker
          // withPortal
          popperPlacement="auto"
          popperClassName="custom-datepicker-popper"
          required={true}
          popperModifiers={{
            // offset: {
            //   offset: "0,100%"
            // },
            preventOverflow: {
              enabled: true,
              escapeWithReference: false // force popper to stay in viewport (even when input is scrolled out of view)
              // boundariesElement: this.refs["datepickerContainer"]
            }
          }}
          // readOnly={"hideValue"}
          // popperPlacement="bottom-end"
          // minDate={new Date()}
          disabled={false}
          placeholderText={"Select Date"}
          dateFormat="MMMM dd, yyyy"
          className="custom-datepicker"
          selected={dueDate ? dueDate : false}
          onChange={arg1 => {
            setDueDate(arg1);
          }}
          inline
        />
        <div style={{
          padding: '10px 0',
          fontSize: '12px'
        }}>
        {dueDate && dateDifference()}
        </div>
      </div>

      </div>
      </PerfectScrollbar>
    </div>
    <div
    style={{
      display: "flex",
      justifyContent: "flex-end",
      padding: "0px 28px",
      backgroundColor: "#fff",
      alignItems: "center",
      borderTop: "1px solid var(--fill-grey-light3)"
    }}
  >
    <div
      onClick={allocateFactory}
      style={{
        padding: "8px",
        color: "var(--fill-primary-blue)",
        cursor: "pointer",
        fontWeight: 600,
        display: "flex",
        alignItems: "center"
      }}
    >
      <span style={{ paddingRight: "8px", paddingTop: "4px" }}>UPDATE</span>
    </div>
  </div>
  </div>
  );
};


const mapStateToProps = state => ({
  tnaTemplate: state.designs.factoryTnaTemplate
})

const mapDispatchToProps = dispatch => ({
  getSamplesByDesignId: details =>
    dispatch(getSamplesByDesignId(details)),
  openModal: (content, additionalProps) =>
  dispatch(openModal(content, additionalProps)),
  closeModal: () => dispatch(closeModal()),
  showFlash: details => dispatch(flash(details)),
  getFactoryTnaTemplate: details => dispatch(getFactoryTnaTemplate(details))
})

export default connect(mapStateToProps, mapDispatchToProps)(ChooseFactoryModal);
