import React, { Component } from "react";

import styles from "./Product.module.scss";

const RedButton = ({ label = "Default", onClick, noArrow = false }) => (
  <div className={styles.bottom__create_order_wrapper} onClick={onClick}>
    {label}{" "}
    {!noArrow ? (
      <>
        &nbsp; &nbsp;
        <i className="fa fa-inverted fa-chevron-right" />
      </>
    ) : null}
  </div>
);

export default RedButton;
