import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import actions from "../../store/actions";

import SamplingTracker from "../../components/SamplingTracker";
import { checkFactory } from "./../../helpers/AuthHelper";
import { PAGE_TITLES } from "../../constants/Titles";
import { Helmet } from "react-helmet";

const SmaplingTrackerContainer = ({
  isFactory,
  getAllSamples,
  samples = [],
  openModal,
  closeModal,
  allBrands,
  getAllUserForBrand,
  brandId,
  userDetails,
  usersList,
  prevSavedSamplesForTracker,
  updatedSampleWithDispatch,
  tnaTemplate,
  getBrandTnaTemplate,
  match,
  ...restProps
}) => {
  // this thing is for brand

  const isFactoryManagerAdmin = checkFactory();

  const [currentUser, setCurrentUser] = useState(userDetails.brandId);

  // if (!Object.keys(samples).length) {
  //   return (
  //     <h2
  //       style={{
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //         minHeight: "100vh"
  //       }}
  //     >
  //       Does not found any designs in sampling currently!
  //     </h2>
  //   );
  // }

  const openSampleUpdateModal = (details) => {
    openModal("UPDATE_SAMPLE_STATUS", {
      commentDetails: details
    });
  }


  // To get the current tab and the stage, will be used in the progress trackers
  const pathVariables = match.path.split("/");
  const tab = pathVariables[2];
  const stage = pathVariables[3];

  return (<>
    <Helmet
      title={PAGE_TITLES.sampling()}
    />
    <SamplingTracker
      samples={samples}
      isFactory={isFactory}
      openModal={openModal}
      closeModal={closeModal}
      allBrands={allBrands}
      getAllSamples={getAllSamples}
      getAllUserForBrand={getAllUserForBrand}
      currentUserId={currentUser}
      prevSavedSamplesForTracker={prevSavedSamplesForTracker}
      updatedSampleWithDispatch={updatedSampleWithDispatch}
      openSampleUpdateModal={openSampleUpdateModal}
      tnaTemplate={tnaTemplate}
      tab={tab}
      stage={stage}
      userDetails={userDetails}
      location={restProps.location}
    />
  </>
  );
};

const mapStateToProps = ({ products, designs, auth }) => ({
  samples: products.samplesForSampleTracker,
  prevSavedSamplesForTracker: products.prevSavedSamplesForTracker,
  updatedSampleWithDispatch: products.updatedSampleWithDispatch,
  tnaTemplate: designs.brandTnaTemplate,
  userDetails: auth.userDetails
});

const mapDispatchToProps = dispatch => ({
  getAllSamples: params =>
    dispatch(actions.getAllSamplesForSampleTracker(params)),
  openModal: (content, additionalProps) =>
    dispatch(actions.openModal(content, additionalProps)),
  closeModal: () => dispatch(actions.closeModal()),
  getAllUserForBrand: details => dispatch(actions.getAllUsersForBrand(details))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SmaplingTrackerContainer);
