import React, { Component } from "react";
import PropTypes from "prop-types";
import { Route, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import actions from "../../store/actions";

import Header from "../../components/UI/Header/Header";
import Sidebar from "./../../components/UI/Sidebar/Sidebar";

import "./FactoryDashboardContainer.scss";

import { ReactComponent as OrdersImg } from "../../assets/images/navigation/ic-nav-orders.svg";
import { ReactComponent as SwatchLibImg } from "../../assets/images/navigation/swatch.svg";
import { ReactComponent as NotificationImg } from "../../assets/images/Left Nav/ic_nav_notifications.svg";
import { ReactComponent as CollectionsImg } from "assets/images/navigation/collections.svg";
import { ReactComponent as InboxImg } from "assets/images/navigation/ic-nav-messaging.svg";
import { ReactComponent as ProductionImg } from "assets/images/navigation/ic-nav-production-2.svg";
import { ReactComponent as SamplingImg } from "assets/images/navigation/sampling.svg";
import { ReactComponent as SettingsImg } from "assets/images/navigation/settings.svg";
import { ReactComponent as quoteImg } from "assets/images/navigation/ic-nav-quote.svg";
import { ReactComponent as MaterialLibraryImg } from "assets/images/dashboard/materialLibrary.svg";
import { ReactComponent as ReportsImg } from "assets/images/dashboard/reports.svg";
import { ReactComponent as PerformanceImg } from "assets/images/dashboard/performance.svg";

import CollectionDetailsContainer from "../CollectionDetailsContainer/CollectionDetailsContainer";
import DesignDetailsContainer from "../DesignDetailsContainer/DesignDetailsContainer";

import PrivateRoute from "../../components/UI/PrivateRoute/PrivateRoute";
import AccountSettingsContainer from "./../AccountSettingsContainer/AccountSettingsContainer";
import BrandSearch from "./../../components/Factory/BrandSearch/BrandSearch";
import FactoryNotificationDashboard from "../NotificationContainer/FactoryNotificationDashboard";
import CollectionListContainer from "../CollectionListContainer/CollectionListContainer";
import SwatchLibraryContainer from "../SwatchLibraryContainer/SwatchLibraryContainer";
import QuotesContainer from "./../QuotesContainer/index";

import Products from "../ProductContainer";
import Orders from "../OrdersContainer";
import SamplingTracker from "../SamplingTrackerContainer";
import ProductionTracker from "../ProductionTrackerContainer";
import MaterialLibraryContainer from "./../MaterialLibraryContainer";
import ReportsContainer from "./../ReportsContainer";
import PerformanceContainer from "../PerformanceContainer";

import {
  ACCOUNT_MANAGER,
  FACTORY_ADMIN,
  FACTORY_MANAGER,
  LOCO_SUPER,
} from "../../constants/Auth";
import {
  getCurrentUserRole,
  refreshTokenIfOld,
} from "../../helpers/AuthHelper";
import MessagesContainer from "../MessagesContainer/index";
import CreateOrderContainer from "../OrdersContainer/CreateOrderContainer";

const REPORTS_DEFAULT_VIEWERS =
  process.env.REACT_APP_REPORTS_DEFAULT_VIEWERS?.split(",");

class FactoryDashboardContainer extends Component {
  static propTypes = {
    openModal: PropTypes.func.isRequired,
    collections: PropTypes.object.isRequired,
    userDetails: PropTypes.object.isRequired,
    getAllCollections: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    // if (props.userDetails.name === null) props.openModal("FIRST_LOGIN");
    if (!REPORTS_DEFAULT_VIEWERS?.includes(props.userDetails?.email)) {
      this.props.factoryNewGetAllBrands();
      this.props.getUsers();

      this.props.getAllNotifications({ isLoader: true });
      if (getCurrentUserRole() === LOCO_SUPER) {
        this.props.setNotificationMode(true);
      } else {
        this.props.setNotificationMode(false);
      }
    }

    refreshTokenIfOld(this.props.refreshToken);
    const { userDetails } = this.props;

    // window.lf.addNotifDetail = notificationDetails => {
    //   window.lf.notifs[notificationDetails.design_id] = notificationDetails;
    // };

    let sidebarUpperMenu = [
      // {
      //   name: "Dashboard",
      //   img: DashboardImg,
      //   link: "/factory/dashboard/"
      // },
      {
        name: "Collections",
        id: "collections",
        img: CollectionsImg,
        link: "/factory/brands/",
      },
      {
        name: "Quotes",
        id: "quotes",
        img: quoteImg,
        link: "/factory/quotes/?tab=all",
      },
      {
        name: "Sampling",
        id: "sampling",
        img: SamplingImg, // have to change this .. icon
        link: "/factory/sampling/in-progress/",
        baseRoute: "/factory/sampling",
      },
      {
        name: "Production",
        id: "production",
        img: ProductionImg,
        link: "/factory/production/in-progress/",
        baseRoute: "/factory/production",
      },
      {
        name: "Orders",
        id: "orders",
        img: OrdersImg,
        link: "/factory/orders",
      },
      {
        name: "Messages",
        id: "messages",
        img: InboxImg,
        link: "/factory/messages/",
      },
      {
        name: "Material Library",
        img: MaterialLibraryImg,
        link: "/factory/library/",
      },
      {
        name: "Swatch Library",
        id: "swatch-library",
        img: SwatchLibImg,
        link: "/factory/swatchLibrary/",
      },
      ...(userDetails.isSuperAdmin
        ? [
            {
              name: "Reports",
              id: "reports",
              img: ReportsImg,
              link: "/factory/reports/",
            },
          ]
        : []),
      {
        name: "Settings",
        id: "settings",
        img: SettingsImg,
        link: "/factory/settings/",
      },
      // {
      //   name: "Production Tracker",
      //   img: OrdersImg,
      //   link: "/factory/productiontracker"
      // }
      // {
      //   name: "Sampling",
      //   img: SamplingImg,
      //   link: "/factory/sampling/"
      // },
      // {
      //   name: "Production",
      //   img: ProductionImg,
      //   link: "/factory/production/"
      // },
      // {
      //   name: "Inbox",
      //   img: InboxImg,
      //   link: "/factory/inbox/"
      // }
    ];

    if (REPORTS_DEFAULT_VIEWERS?.includes(userDetails?.email)) {
      sidebarUpperMenu = [
        {
          name: "Performance",
          id: "performance",
          img: PerformanceImg,
          link: "/factory/performance/?tab=sm",
        },
      ];
    }

    if (
      getCurrentUserRole() === FACTORY_ADMIN ||
      getCurrentUserRole() === FACTORY_MANAGER
    ) {
      sidebarUpperMenu = [
        {
          name: "Collections",
          id: "collections",
          img: CollectionsImg,
          link: "/factory/brands/",
        },
        {
          name: "Quotes",
          id: "quotes",
          img: quoteImg,
          link: "/factory/quotes/?tab=all",
        },
        {
          name: "Sampling",
          id: "sampling",
          img: SamplingImg, // have to change this .. icon
          link: "/factory/sampling/in-progress/",
        },
        {
          name: "Production",
          id: "Production",
          img: ProductionImg,
          link: "/factory/production/in-progress/",
        },
        {
          name: "Orders",
          id: "orders",
          img: OrdersImg,
          link: "/factory/orders",
        },
        {
          name: "Messages",
          id: "messages",
          img: InboxImg,
          link: "/factory/messages/",
        },
        {
          name: "Settings",
          id: "settings",
          img: SettingsImg,
          link: "/factory/settings/",
        },
      ];
    }

    this.state = {
      upperMenu: sidebarUpperMenu,
      userRole: getCurrentUserRole(),
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.pathname === "/factory/brands/") {
      if (this.props.currentBrand.brandId !== null)
        this.props.setCurrentBrand({ brandId: null });
    }
  }

  openSendNotificationModal = () => {
    this.props.openModal("SEND_NOTIFICATION_MODAL", {
      hiddenFields: [
        {
          name: "notification",
          value: window.lf.notifs[this.props.match.params.design_id],
        },
      ],
    });
  };

  render() {
    const { upperMenu } = this.state;
    const {
      setCurrentBrand,
      currentBrand,
      notifications,
      setNotificationsRead,
      userDetails,
    } = this.props;

    return (
      <div className="brand-dash">
        <Header
          isFactory={
            this.props.userDetails.role === LOCO_SUPER ||
            this.props.userDetails.role === FACTORY_ADMIN ||
            this.props.userDetails.role === FACTORY_MANAGER
          }
          openModel={() =>
            this.props.openModal("NOTIFICATION_MODAL", {
              userDetails: this.props.userDetails,
              isFactory: true,
              brandId: currentBrand && currentBrand.brandIid,
            })
          }
          brandId={currentBrand && currentBrand.brandId}
          dontShowInLogin={window.appHistory.location !== "/auth/login/"}
          notifications={notifications}
          setNotificationsRead={setNotificationsRead}
          userDetails={userDetails}
          noBellIcon={this.props.userDetails.role === ACCOUNT_MANAGER}
          noSearchBar={this.props.userDetails.role === ACCOUNT_MANAGER}
        />
        <div
          className="brand-dash__main" // this.props.modal.isOpen
        >
          <Sidebar
            openSendNotificationModal={this.openSendNotificationModal}
            upperItems={upperMenu}
            lowerItems={
              this.props.userDetails.role === LOCO_SUPER
                ? [
                    {
                      name: "Notifications",
                      img: NotificationImg,
                      id: "notifications",
                      link: "/factory/notifications/",
                    },
                  ]
                : []
            }
            userDetails={this.props.userDetails}
          />

          {/* // TODO : Add Messages Tab */}

          <div className="brand-dash__content ">
            <PrivateRoute
              exact
              path="/factory/swatchLibrary"
              render={(routeProps) => {
                return (
                  <SwatchLibraryContainer
                    {...routeProps}
                    isFactory={true}
                    isSuperAdmin={getCurrentUserRole() === "LOCO_SUPER"}
                  />
                );
              }}
            />
            <PrivateRoute
              exact
              path="/factory/brands"
              render={(routeProps) => {
                if (currentBrand.brandId) setCurrentBrand({ brandId: null });
                if (getCurrentUserRole() === LOCO_SUPER) {
                  this.props.setNotificationMode(true);
                } else {
                  this.props.setNotificationMode(false);
                }
                return (
                  <BrandSearch
                    {...routeProps}
                    openModal={this.props.openModal}
                    brands={this.props.brandsList}
                    selectedBrand={currentBrand}
                    isFactory={true}
                  />
                );
              }}
            />
            <PrivateRoute
              exact
              path="/factory/notifications/"
              render={(routeProps) => {
                const { brandId } = currentBrand;
                const redirectBrandId =
                  this.props.brandsList[0]["brand_id"] ||
                  this.props.brandsList[0]["id"];
                return brandId ? (
                  <Redirect to={`/factory/brand/${brandId}/notifications/`} />
                ) : (
                  <Redirect
                    to={`/factory/brand/${redirectBrandId}/notifications/`}
                  />
                );
              }}
            />

            <PrivateRoute
              exact
              path="/factory/performance/"
              render={(routeProps) => {
                const canViewThisPage = REPORTS_DEFAULT_VIEWERS?.includes(
                  userDetails.email
                );
                if (canViewThisPage) {
                  return <PerformanceContainer {...routeProps} />;
                }
                return null;
              }}
            />
            <PrivateRoute
              exact
              path="/factory/brand/:brand_id/notifications/"
              render={(routeProps) => {
                let selectedBrand = {
                  ...this.props.brandsList.filter(
                    (v) =>
                      Number(v.brand_id) ===
                      Number(routeProps.match.params.brand_id)
                  )[0],
                  brandId: routeProps.match.params.brand_id,
                };
                if (currentBrand.brandId !== selectedBrand.brandId)
                  setCurrentBrand(selectedBrand);
                return <FactoryNotificationDashboard {...routeProps} />;
              }}
            />
            <PrivateRoute
              exact
              path="/factory/brands/:brand_id/collections/"
              render={(routeProps) => {
                let selectedBrand = {
                  ...this.props.brandsList.filter(
                    (v) =>
                      Number(v.brand_id) ===
                      Number(routeProps.match.params.brand_id)
                  )[0],
                  brandId: routeProps.match.params.brand_id,
                };
                if (currentBrand.brandId !== selectedBrand.brandId)
                  setCurrentBrand(selectedBrand);
                return (
                  <CollectionListContainer {...routeProps} isFactory={true} />
                );
              }}
            />
            <PrivateRoute
              exact
              path="/factory/brands/:brand_id/collections/:collection_id"
              render={(routeProps) => {
                let selectedBrand = {
                  ...this.props.brandsList.filter(
                    (v) =>
                      Number(v.brand_id) ===
                      Number(routeProps.match.params.brand_id)
                  )[0],
                  brandId: routeProps.match.params.brand_id,
                };
                if (currentBrand.brandId !== selectedBrand.brandId)
                  setCurrentBrand(selectedBrand);
                return (
                  <CollectionDetailsContainer
                    {...routeProps}
                    openModal={this.props.openModal}
                  />
                );
              }}
            />
            <PrivateRoute
              exact
              path="/factory/brands/:brand_id/collections/:collection_id/designs/:design_id"
              render={(routeProps) => {
                let selectedBrand = {
                  ...this.props.brandsList.filter(
                    (v) =>
                      Number(v.brand_id) ===
                      Number(routeProps.match.params.brand_id)
                  )[0],
                  brandId: routeProps.match.params.brand_id,
                };
                if (currentBrand.brandId !== selectedBrand.brandId)
                  setCurrentBrand(selectedBrand);
                return (
                  <DesignDetailsContainer
                    {...routeProps}
                    openModal={this.props.openModal}
                    isFactory={true}
                    isSuperAdmin={this.props.userDetails.role === LOCO_SUPER}
                    brandDetails={
                      this.props.brandsList.filter(
                        ({ id }) => Number(id) === Number(currentBrand.brandId)
                      )[0]
                    }
                  />
                );
              }}
            />
            <Route
              path="/factory/quotes/"
              render={(routeProps) => <QuotesContainer {...routeProps} />}
            />
            {/* <PrivateRoute
              exact
              path="/factory/brands/:brandName/:design_id?"
              render={routeProps => (
                <DesignDetailsContainer
                  {...routeProps}
                  isFactory={true}
                  brandName={routeProps.match["brandName"]}
                />
              )}
            /> */}
            {/* <Route
              exact
              path="/factory/collections/:collection_id/designs/:design_id"
              render={routeProps => {
                const { params } = routeProps.match;
                this.props.factoryGetDesigns({
                  collection_id: params.collection_id,
                  design_id: params.design_id
                });
                return (
                  <DesignDetailsContainer
                    {...routeProps}
                    openModal={this.props.openModal}
                  />
                );
              }}
            /> */}
            <Route
              path="/factory/messages"
              render={(routeProps) => <MessagesContainer {...routeProps} />}
            />
            <PrivateRoute
              path="/factory/library/"
              userRoles={[LOCO_SUPER]}
              render={(routeProps) => (
                <MaterialLibraryContainer
                  {...routeProps}
                  userDetails={this.props.userDetails}
                  usersList={this.props.usersList}
                />
              )}
            />
            <Route
              path="/factory/settings/"
              render={(routeProps) => {
                if (currentBrand.brandId) setCurrentBrand({ brandId: null });
                return <AccountSettingsContainer {...routeProps} />;
              }}
            />
            <Route
              path="/factory/sampling/approved/"
              render={(routeProps) => {
                if (currentBrand.brandId) setCurrentBrand({ brandId: null });
                return <Products {...routeProps} />;
              }}
            />
            <PrivateRoute
              path="/factory/orders"
              exact
              render={(routeProps) => {
                if (currentBrand.brandId) setCurrentBrand({ brandId: null });
                return <Orders {...routeProps} />;
              }}
            />
            <Route
              path="/factory/orders/new/"
              userRoles={[LOCO_SUPER]}
              render={(routeProps) => (
                <CreateOrderContainer
                  {...routeProps}
                  subHeading="Create Order"
                />
              )}
            />
            <Route
              path="/factory/sampling/in-progress/"
              render={(routeProps) => {
                if (currentBrand.brandId) setCurrentBrand({ brandId: null });
                return (
                  <SamplingTracker
                    {...routeProps}
                    isFactory={true}
                    userDetails={this.props.userDetails}
                    allBrands={this.props.brandsList}
                    usersList={this.props.usersList}
                  />
                );
              }}
            />
            <Route
              path="/factory/production/in-progress/"
              render={(routeProps) => {
                if (currentBrand.brandId) setCurrentBrand({ brandId: null });
                return (
                  <ProductionTracker
                    {...routeProps}
                    isFactory={true}
                    allBrands={this.props.brandsList}
                    userDetails={this.props.userDetails}
                  />
                );
              }}
            />
            <Route
              path="/factory/production/dispatched/"
              render={(routeProps) => {
                if (currentBrand.brandId) setCurrentBrand({ brandId: null });
                return (
                  <ProductionTracker
                    {...routeProps}
                    isFactory={true}
                    allBrands={this.props.brandsList}
                    userDetails={this.props.userDetails}
                  />
                );
              }}
            />
            <PrivateRoute
              path="/factory/reports/"
              userRoles={[LOCO_SUPER]}
              render={(routeProps) => <ReportsContainer {...routeProps} />}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  collections: state.collections,
  userDetails: state.auth.userDetails,
  designList: state.designs.list,
  brandsList: state.factory.brandsList,
  currentBrand: state.notificationView.currentBrand,
  modal: state.global.modal,
  notifications: state.notificationView.notificationsList,
  usersList: state.auth.list,
});

const mapDispatchToProps = (dispatch) => ({
  openModal: (content, additionalProps) =>
    dispatch(actions.openModal(content, additionalProps)),
  closeModal: (content, additionalProps) =>
    dispatch(actions.closeModal(content, additionalProps)),
  getAllCollections: () => dispatch(actions.getAllCollections()),
  getUsers: () => dispatch(actions.getUsers()),
  getDesignsByCollectionId: (collectionId) =>
    dispatch(actions.getDesignsByCollectionId(collectionId)),
  factoryGetDetails: (details) => dispatch(actions.factoryGetDetails(details)),
  getCollectionsByBrandId: (details) =>
    dispatch(actions.getCollectionsByBrandId(details)),
  factoryNewGetAllBrands: () => dispatch(actions.factoryNewGetAllBrands()),
  setCurrentBrand: (v) => dispatch(actions.setCurrentBrand(v)),
  setNotificationMode: (mode) => dispatch(actions.setNotificationMode(mode)),
  getAllNotifications: (details) =>
    dispatch(actions.getAllNotifications(details)),
  setNotificationsRead: (notifications) =>
    dispatch(actions.setNotificationsRead(notifications)),
  getBrandFeatures: () => dispatch(actions.getBrandFeatures),
  refreshToken: () => dispatch(actions.refreshToken()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FactoryDashboardContainer)
);
