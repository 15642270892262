import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import EmptyScreen from "../../UI/EmptyScreen/index";

class BrandSearch extends Component {

  componentDidMount() {
    const { brands, history } = this.props;
    if (brands && brands.length && (brands[0]['id'] || brands[0]['brand_id'])) {
      let brand = brands[0];
      history.push(`${brand["id"] ? brand['id'] : brand['brand_id']}/collections/`);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { brands, history } = this.props;
    if (brands && brands.length && (brands[0]['id'] || brands[0]['brand_id'])) {
      let brand = brands[0];
      history.push(`${brand["id"] ? brand['id'] : brand['brand_id']}/collections/`);
    }
  }

  render() {
    const {
      props: { brands }
    } = this;

    if (!brands || brands.length === 0) {
      return <EmptyScreen
        heading="No designs"
        description="Brand hasn't started any designs with you yet."
      />;
    }

    return (
      <div
        className="flex"
        style={{
          //   minHeight: "100%",
          padding: "1em 2em"
        }}
      >
        {brands.map(brand => (
          <Link to={`${brand["brand_id"] ? brand["brand_id"] : brand["id"]}/collections/`} key={brand["brand_id"] || brand["id"]}>
            <div
              className="card"
              style={{
                marginRight: "2em",
                backgroundColor: "var(--fill-white)",
                padding: "2em",
                cursor: "pointer"
              }}
            >
              <div className="card__section">{brand["brand_name"] ? brand["brand_name"] : brand['name']}</div>
            </div>
          </Link>
        ))}
      </div>
    );
  }
}

export default withRouter(BrandSearch);
