import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";

import PerfectScrollbar from "react-perfect-scrollbar";
import DatePicker from "react-datepicker";

import prodImage from "../../assets/product-item.jpeg";
import RedButton from "./RedButton";
import greenCheck from "../../assets/images/circle-with-check-green.svg";
import fileIcon from "../../assets/images/editor/ic-attach-file.svg";
import Filter from "../Product/Filter";
import cancelIcon from "../../assets/img/close-red.svg";
import AddressFilter from "../Orders/AddressFilter";

import thrashIcon from "../../assets/images/action/ic-delete.faded.svg";

import actions from "../../store/actions";

import styles from "./Product.module.scss";

import { get_service_endpoint } from "../../ServiceEndpoints";
import axios from "axios";
import { getSmallImageUrl } from "../../helpers/DesignHelpers";

const eq = get_service_endpoint("notificationView");

const OrderItem = ({
  design: {
    designName,
    sourceDesignId,
    designId,
    quantity,
    isDeleted = false,
    imageUrl,
    totalAmount
  },
  design,
  saveDesignQuantity,
  nextErr,
  deleteDesign
}) => {
  let smallImage;
  if (imageUrl) {
    smallImage = getSmallImageUrl(imageUrl);
  }
  return (
    <div
      className={styles.order__item_wrapper}
      style={{ backgroundColor: isDeleted ? "#f5f5f5" : "#fff" }}
    >
      <div className={styles.order__item_image_wrapper}>
        <img
          src={smallImage}
          alt="Design"
          className={styles.order__item_image}
        />
      </div>
      <div className={styles.order__item_details}>
        <div className="f-sz-l f-w-bold">{designName}</div>
        <div
          className={styles.order__item_id}
          style={{ textTransform: "upper-case" }}
        >
          {sourceDesignId}
        </div>
      </div>
      <div className={styles.order__item_qty} style={{ alignItems: "center" }}>
        <input
          type="number"
          name="orderQuantity"
          id="orderQuantity"
          disabled={isDeleted}
          className={styles.order__item_qty_input}
          onChange={ev => {
            saveDesignQuantity(designId, "quantity", ev.target.value);
          }}
          value={design.quantity}
          style={{
            border:
              !quantity && nextErr && !isDeleted ? "1px solid #ff0000" : ""
          }}
        />
      </div>
      <div className={styles.order__item_qty} style={{ alignItems: "center" }}>
        <input
          type="number"
          name="price"
          id="price"
          disabled={isDeleted}
          className={styles.order__item_qty_input}
          onChange={ev => {
            saveDesignQuantity(designId, "quoteAmount", ev.target.value);
          }}
          value={design.quoteAmount}
          style={{
            border:
              !quantity && nextErr && !isDeleted ? "1px solid #ff0000" : ""
          }}
        />
        {!isDeleted ? (
          <img
            src={thrashIcon}
            alt="delete"
            className={styles.delete__design_icon}
            onClick={() => deleteDesign(designId)}
            style={{
              padding: "10px",
              position: "absolute",
              right: "30px",
              cursor: "pointer"
            }}
          />
        ) : (
            <span
              onClick={() => deleteDesign(designId)}
              style={{
                padding: "10px",
                position: "absolute",
                right: "30px",
                cursor: "pointer"
              }}
            >
              Undo
            </span>
          )}
      </div>
    </div>
  );
};

const InputWithText = ({
  label,
  value,
  isDate = false,
  onChange,
  ID,
  type = "text",
  isError
}) => {
  const [selectedDate, setSelectedDate] = useState("");
  return (
    <div className={styles.input__with_text_wrapper}>
      <div className={styles.with__text_label}>{label}</div>

      {!isDate ? (
        <input
          type={type}
          className={styles.with__text_input}
          value={value}
          onChange={ev => {
            onChange(ID, ev.target.value);
          }}
          disabled={ID === "totalQuantity"}
        />
      ) : (
          <div style={{ marginLeft: "0px", width: "263px" }}>
            <DatePicker
              // withPortal
              popperPlacement="auto"
              popperClassName="custom-datepicker-popper"
              required={true}
              popperModifiers={{
                preventOverflow: {
                  enabled: true,
                  escapeWithReference: false
                }
              }}
              readOnly={false}
              minDate={""}
              maxDate={ID === "poDate" ? new Date() : ""}
              placeholderText="Select Date"
              dateFormat="MMM dd, yyyy"
              className="custom-datepicker"
              selected={
                selectedDate
                  ? selectedDate
                  : value
                    ? moment(value).valueOf()
                    : null
              }
              onChange={date => {
                setSelectedDate(date);
                onChange(ID, date);
              }}
              disabled={false}
            />
          </div>
        )}
    </div>
  );
};

const InputWithFileUpload = ({
  label,
  onChange,
  ID = "poFile",
  value,
  deletePOFile
}) => {
  const [fileName, setFileName] = useState("");
  return (
    <div className={styles.input__with_text_wrapper}>
      <div className={styles.with__text_label}>{label}</div>
      <div className={styles.with_input_type_file_wrapper}>
        <input
          type="file"
          className={styles.with__text_input_hidden}
          onChange={ev => {
            if (ev.target.files.length) {
              setFileName(ev.target.files[0]["name"]);
              onChange(ID, ev.target.files[0]);
            }
          }}
          style={{ display: fileName ? "none" : "" }}
        />
        {!fileName ? (
          <div className={styles.visible__file_uploader_wrapper}>
            <div className={styles.visible__file_text}>
              {fileName ? fileName : "Attach file"}
            </div>
            <div className={styles.visible__file_icon}>
              {/* <i className="fa fa-paperclip f-sz-xxl"></i> */}
              <img src={fileIcon} alt="file" className="upload__ico" />
            </div>
          </div>
        ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                // marginLeft: "10px",
                padding: "10px"
              }}
            >
              <span>
                {fileName.length > 20
                  ? `${fileName.slice(0, 10)}...${fileName.slice(
                    fileName.length - 10 - fileName.length
                  )}`
                  : fileName}
              </span>
              <img
                src={cancelIcon}
                alt="change"
                style={{ color: "#ff0000", cursor: "pointer" }}
                onClick={() => {
                  setFileName("");
                  deletePOFile();
                }}
              />
            </div>
          )}
      </div>
    </div>
  );
};

const InputWithAddressFilter = ({
  onChange,
  ID,
  addresses = [],
  label,
  title = "Address",
  value
}) => {
  let selectedValue = {};

  if (value) {
    // console.log("")
    addresses.forEach(address => {
      if (address.id === value) {
        selectedValue.label = address.addressLine1;
        selectedValue.value = address.id;
      }
    });
  } else {
    selectedValue.label = "Select one";
    selectedValue.value = "0";
  }

  return (
    <div className={styles.input__with_text_wrapper}>
      <div className={styles.with__text_label}>{label}</div>
      <div className={styles.with_input_type_file_wrapper}>
        {false ? (
          <div>
            <div>{"initialValue"}</div>
            <img src={cancelIcon} alt="cancel" />
          </div>
        ) : (
            <div style={{ marginLeft: "0px" }}>
              <AddressFilter
                title={title}
                initialSelectedItem={selectedValue}
                onChange={selection => {
                  onChange(ID, selection.value);
                }}
                filterOptions={
                  addresses &&
                  addresses.map(address => ({
                    label: address.addressLine1,
                    value: address
                  }))
                }
                // marginRight="20px"
                width="265px"
                height={ID === "shippingMode" ? "100px" : "180px"}
              />
            </div>
          )}
      </div>
    </div>
  );
};

const InputWithFilter = ({
  onChange,
  ID,
  addresses,
  label,
  height = "250px",
  title = "Address",
  value
}) => {
  let selectedValue = {};

  if (value) {
    if (value === 10) {
      selectedValue = {
        label: "Forwarder",
        value: 10
      };
    } else {
      selectedValue = {
        label: "By Hand",
        value: 20
      };
    }
  } else {
    selectedValue.label = "Select one";
    selectedValue.value = "0";
  }
  return (
    <div className={styles.input__with_text_wrapper}>
      <div className={styles.with__text_label}>{label}</div>
      <div
        className={styles.with_input_type_file_wrapper}
        style={{ marginLeft: "0px" }}
      >
        <Filter
          title={title}
          initialSelectedItem={{
            label: "Select one",
            value: "0"
          }}
          onChange={selection => {
            onChange(ID, selection.value);
          }}
          filterOptions={addresses.map(address => ({
            label: address.addressLine1,
            value: address.id
          }))}
          // marginRight="20px"
          width="265px"
          height={height}
        />
      </div>
    </div>
  );
};

const CreateOrderModel = ({
  designs,
  brandid,
  history,
  dispatch,
  isFactory,
  userDetails
}) => {
  const [activeIndex, setActiveIndex] = useState(1);
  const [withQuantityDesigns, setWithQuantityDesigns] = useState(designs);
  const [nextErr, setNextErr] = useState(false);
  const [poDetails, setPODetails] = useState({
    brandRepName: userDetails.isBrand ? userDetails.name : '',
  });
  const [addresses, setAddresses] = useState([]);
  const [poError, setPoError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("All fields are mandatory");

  const [initialRender, setInitialRender] = useState(false);

  useEffect(() => {
    axios
      .get(`${eq}/address`, { params: { brandid: brandid ? brandid : designs[0].brandId } })
      .then(res => {
        if (res.data.error) {
          setAddresses([]);
        } else {
          setAddresses(res.data);
        }
      })
      .catch(err => {
        dispatch(actions.flash({ message: "Failed to fetch addresses!" }));
      });
  }, []);

  useEffect(() => {
    if (!initialRender) {
      const updatedDeisgns = designs.map(design => {
        if (!design.quantity) {
          design.quantity = "";
        }
        design.totalAmount = design.quantity + design.quoteAmount;
        return { ...design };
      });
      console.log(updatedDeisgns);
      designs = updatedDeisgns;
      setInitialRender(true);
    }
  }, []);

  const nextBtnHandler = () => {
    setActiveIndex(2);
    setErrorMessage("All fields are mandatory");
  };

  const saveDesignQuantity = (id, key, value) => {
    const updatedWithQuantityDesigns = withQuantityDesigns.map(design => {
      if (design.designId === id) {
        design[key] = value;
        return { ...design };
      }
      return design;
    });

    setWithQuantityDesigns(updatedWithQuantityDesigns);

    if (nextErr) setNextErr(false);
  };

  // ===== This handles the validation plus $$$$$$ navigation to po and post create order api =====
  const toNextHandler = () => {
    if (activeIndex == 1) {
      let emptyQuantityError = false;
      withQuantityDesigns.forEach(design => {
        if (
          (!design.quantity || Number(design.quantity) <= 0) &&
          !design.isDeleted
        ) {
          emptyQuantityError = true;
        }
      });

      if (emptyQuantityError) {
        // setNextErr(true);
        // return;

        setPoError(true);
        setErrorMessage("Please enter quantity");
        setTimeout(() => {
          setPoError(false);
        }, 1000);
        return;
      }

      //  ==== this condition removes the deleted designs ====
      const updatedWithQuantityDesigns = withQuantityDesigns.filter(design => {
        if (!design.isDeleted) {
          return design;
        }
      });

      console.log("NEED IN PO PO", updatedWithQuantityDesigns);

      // === this checks is there any design left after removing or not ====
      if (!updatedWithQuantityDesigns.length) {
        setPoError(true);
        setErrorMessage("Please select the product");
        setTimeout(() => {
          setPoError(false);
        }, 1000);
        return;
      }

      nextBtnHandler();
    }

    if (activeIndex == 2) {
      console.log('Insided if')
      if (validatePoDetails()) {
        setPoError(true);
        setTimeout(() => {
          setPoError(false);
        }, 1000);
        return;
      }

      const updatedWithQuantityDesigns = withQuantityDesigns.filter(design => {
        if (!design.isDeleted) {
          delete design.id;
          return design;
        }
      });

      const data = new FormData();
      data.append("products", JSON.stringify(updatedWithQuantityDesigns));
      data.append("poNumber", poDetails.poNumber);
      data.append("addressId", poDetails.addressId);
      data.append("poDate", poDetails.poDate);
      data.append("dueDate", poDetails.dueDate);
      data.append("paymentDueIn", poDetails.paymentDueIn);
      data.append(
        "totalQuantity",

        withQuantityDesigns
          .filter(item => item.isDeleted !== true)
          .reduce((acc, design) => acc + Number(design.quantity), 0)
      );
      data.append("totalAmount", poDetails.totalAmount);
      data.append("shippingMode", poDetails.shippingMode);
      data.append("brandRepName", poDetails.brandRepName);
      data.append("brandId", userDetails.isBrand ? userDetails.brandId : brandid);
      data.append("poFile", poDetails.poFile);

      axios
        .post(`${eq}/orders`, data)
        .then(res => {
          if (!res.data.error) {
            // navigate to orders
            // alert("HEllo");
            dispatch(actions.closeModal());
            dispatch(
              actions.flash({
                message: `Order ${poDetails.poNumber} for ${
                  updatedWithQuantityDesigns[0]["brandName"]
                  } created`,
                type: "action"
              })
            );
            history.push(`/${isFactory ? "factory" : "brand"}/orders`);
          } else {
            dispatch(actions.flash({ message: "Something went wrong!" }));
            return;
          }
        })
        .catch(err => console.log(err));
    }
  };

  // == this function deletes the designs by adding idDeleted ket to designs =====
  const deleteDesignHandler = id => {
    const updatedDeisgns = withQuantityDesigns.filter(design => {
      if (design.designId === id) {
        design.isDeleted = !design.isDeleted;
        return { ...design };
      }
      return design;
    });

    setWithQuantityDesigns(updatedDeisgns);
  };

  // ==== this function validates the po data on submit ===
  const validatePoDetails = () => {
    const keys = [
      "poNumber",
      "addressId",
      "poDate",
      "dueDate",
      "paymentDueIn",
      "totalAmount",
      "poFile",
      "shippingMode",
      "brandRepName"
    ];
    let poDetailsError = false;
    keys.forEach(key => {
      console.log(poDetails[key], key)
      if (!poDetails[key]) {
        poDetailsError = true;
      }
    });

    console.log('Po Error details', poDetailsError)

    if (poDetailsError) return true;

    return false;
  };

  // ==== saves po details ====   ??????
  const savePODetailsHandler = (ID, value) => {
    if (ID === "poDate" || ID === "dueDate") {
      let convertedValue = moment(value).format("YYYY-MM-DD hh:mm:ss");
      value = convertedValue;
    }

    let updatedPoDetails = {
      ...poDetails,
      totalAmount: withQuantityDesigns
        .filter(item => item.isDeleted !== true)
        .reduce(
          (acc, design) => acc + Number(design.quantity) * Number(design.quoteAmount),
          0
        ),
      [ID]: value
    };
    setPODetails(updatedPoDetails);
  };

  const deletePOFileHandler = () => {
    poDetails.poFile = null;
    setPODetails({ ...poDetails });
  };

  const activeStyle = {
    fontWeight: "bold",
    color: "var(--fill-locofast-black)"
  };

  return (
    <div className={styles.model}>
      <div className={styles.scroll__wrapper}>
        <div style={{ height: "530px" }}>
          <PerfectScrollbar style={{ maxHeight: window.innerHeight }}>
            <div className={styles.bar__wrapper}>
              <div className={styles.bar__item}>
                <div className={styles.bar__label}>1. Select Design</div>
                <div className={styles.bar__icon}>
                  <img
                    style={{ display: "flex" }}
                    src={greenCheck}
                    alt="check"
                  />
                </div>
                <div className={styles.bar__line} />
              </div>
              <div className={styles.bar__item}>
                <div
                  className={styles.bar__label}
                  style={activeIndex === 1 ? activeStyle : {}}
                >
                  2. Enter Quantity
                </div>
                <div className={styles.bar__icon}>
                  {activeIndex >= 2 ? (
                    <img
                      style={{ display: "flex" }}
                      src={greenCheck}
                      alt="check"
                    />
                  ) : null}
                </div>
                <div className={styles.bar__line} />
              </div>
              <div className={styles.bar__item_last}>
                <div
                  className={styles.bar__label}
                  style={activeIndex === 2 ? activeStyle : {}}
                >
                  3. Enter PO Details
                </div>
              </div>
            </div>

            {activeIndex === 2 ? (
              <>
                <div
                  className={styles.po__back_btn}
                  onClick={() => setActiveIndex(1)}
                  style={{ width: "150px" }}
                >
                  {`< Back`}
                </div>
                <div className={styles.po__wrapper}>
                  <InputWithText
                    value={poDetails.poNumber}
                    onChange={savePODetailsHandler}
                    ID="poNumber"
                    label="PO Number"
                  />
                  {
                    // brand shouldn't see the brand-rep name
                    !userDetails.isBrand && <InputWithText
                      value={poDetails.brandRepName}
                      onChange={savePODetailsHandler}
                      ID="brandRepName"
                      label="Brand-rep name"
                    />
                  }
                  <InputWithText
                    value={poDetails.poDate}
                    onChange={savePODetailsHandler}
                    ID="poDate"
                    label="PO Date"
                    isDate
                  />
                  <InputWithText
                    value={poDetails.dueDate}
                    onChange={savePODetailsHandler}
                    ID="dueDate"
                    label="Deadline Date"
                    isDate
                  />
                  <InputWithText
                    value={poDetails.paymentDueIn}
                    onChange={savePODetailsHandler}
                    ID="paymentDueIn"
                    label="Payment Due (in days)"
                    type="number"
                  />
                  <InputWithText
                    onChange={savePODetailsHandler}
                    ID="totalQuantity"
                    label="Total Quantity"
                    value={withQuantityDesigns
                      .filter(item => item.isDeleted !== true)
                      .reduce(
                        (acc, design) => acc + Number(design.quantity),
                        0
                      )}
                    disabled={true}
                  />
                  <InputWithAddressFilter
                    onChange={savePODetailsHandler}
                    ID="addressId"
                    label="Shipping Address"
                    addresses={addresses}
                    value={poDetails.addressId}
                  />
                  {/* <InputWithText
                  onChange={savePODetailsHandler}
                  ID="location"
                  label="Location"
                /> */}
                  <InputWithText
                    onChange={savePODetailsHandler}
                    ID="totalAmount"
                    label="Total Amount"
                    type="number"
                    value={withQuantityDesigns
                      .filter(item => item.isDeleted !== true)
                      .reduce(
                        (acc, design) => acc + Number(design.quantity) * Number(design.quoteAmount),
                        0
                      )}
                  />
                  <InputWithFilter
                    onChange={savePODetailsHandler}
                    ID="shippingMode"
                    label="Shipping Mode"
                    addresses={[
                      { addressLine1: "Forwarder", id: 10 },
                      { addressLine1: "By Hand", id: 20 }
                    ]}
                    height="90px"
                    title="Shipping Mode"
                    value={poDetails.shippingMode}
                  />
                  <InputWithFileUpload
                    label="Upload PO"
                    ID={"poFile"}
                    onChange={savePODetailsHandler}
                    deletePOFile={deletePOFileHandler}
                  />
                </div>
              </>
            ) : null}
            {activeIndex === 1 ? (
              <>
                <div className={styles.model__header_wrapper}>
                  <div />
                  <div className={styles.model__header_item}>Design</div>
                  <div
                    className={styles.model__header_item}
                  >{`Quantity (pcs)`}</div>
                  <div className={styles.model__header_item}>Price</div>
                </div>

                {withQuantityDesigns.map(design => (
                  <OrderItem
                    design={design}
                    nextErr={nextErr}
                    saveDesignQuantity={saveDesignQuantity}
                    deleteDesign={deleteDesignHandler}
                  />
                ))}
              </>
            ) : null}
          </PerfectScrollbar>
        </div>
      </div>

      {poError ? (
        <div
          style={{
            fontSize: "10px",
            padding: "8px",
            backgroundColor: "#f8d7da",
            color: "#721c24",
            textAlign: "center",
            marginBottom: "-10px",
            position: "absolute",
            right: 0,
            bottom: "12%",
            width: "100%",
            opacity: poError ? 1 : 0,
            transition: "0.2s all"
          }}
        >
          {errorMessage}
        </div>
      ) : null}

      <div className={styles.order__footer_wrapper}>
        <div className={styles.order__footer_details}>
          <div className={styles.order__footer_details_label}>
            In this order
          </div>
          <div className="f-sz-l f-w-bold">{`${
            withQuantityDesigns.filter(design => design.isDeleted !== true)
              .length
            } Products`}</div>
        </div>

        <div className={styles.order__footer_btn_wrapper}>
          <RedButton
            label={activeIndex === 1 ? "Next" : "save and create order"}
            onClick={toNextHandler}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  designs: state.products.selectedDesigns,
  userDetails: state.auth.userDetails,
});

export default connect(mapStateToProps)(withRouter(CreateOrderModel));
