import React from "react";

import Masonry from "react-masonry-component";
import MaterialCard from "./MaterialCard";
import InfoButton from "components/UI/InfoButton/InfoButton";
import emptyFabricsLibIcon from "assets/images/materialLibrary/empty-fabrics.svg";
import emptyTrimsLibIcon from "assets/images/materialLibrary/empty-trims.svg";
import Button from "components/UI/Button/Button";

const masonryOptions = { gutter: 16, horizontalOrder: false };

const EMPTY_STATE_VALUES = {
  withFilters: {
    fabrics: {
      title: "No fabrics found",
      subtitle: "No matching results found"
    },
    trims: {
      title: "No trims found",
      subtitle: "No matching results found"
    }
  },
  withoutFilters: {
    fabrics: {
      title: "No fabrics available",
      subtitle: "Upload available fabrics and manage your inventory from here"
    },
    trims: {
      title: "No trims available",
      subtitle: "Upload available trims and manage your inventory from here"
    }
  }
};

const getEmptyStateValues = (anyFiltersApplied, isTrims) => {
  return EMPTY_STATE_VALUES[
    anyFiltersApplied ? "withFilters" : "withoutFilters"
  ][isTrims ? "trims" : "fabrics"];
};

const LibraryGrid = ({
  openModal,
  cardList = [],
  loadNext,
  loadPrevious,
  paginate,
  isTrims,
  reloadPage,
  anyFiltersApplied,
  filterList,
  ...props
}) => {
  if (cardList.length === 0) {
    const content = getEmptyStateValues(anyFiltersApplied, isTrims);
    return (
      <div className={`f-sz-xxl empty-list-container ${anyFiltersApplied ? "" : "no-filters"}`}>
        <InfoButton
          icon={
            <img
              src={isTrims ? emptyTrimsLibIcon : emptyFabricsLibIcon}
              alt="Empty Material Library"
            />
          }
          title={content.title}
          subtitle={content.subtitle}
          cta={
            anyFiltersApplied ? (
              <Button
                category="blue-ghost"
                onClick={() => window.location.reload()}
              >
                CLEAR FILTERS
              </Button>
            ) : null
          }
        />
      </div>
    );
  }

  return (
    <>
      <div>
        <Masonry options={masonryOptions} className="layout-grid">
          {cardList.map(sw => (
            <div key={sw.id}>
              <MaterialCard
                key={sw.id}
                img={sw.image}
                openModal={openModal}
                materialId={sw.materialId}
                generatedMaterialId={sw.generatedMaterialId}
                colors={sw.colors}
                filterList={filterList}
                inStock={sw.inStock}
              >
                {`${sw.name}`}
              </MaterialCard>
            </div>
          ))}
        </Masonry>

        <div className="library-pagination-container">
          <div className="library-pagination-left">
            <p>
              {paginate.totalRecords &&
                (isTrims
                  ? paginate.totalRecords.totalTrimsCount
                  : paginate.totalRecords.totalFabricCount)}{" "}
              materials
            </p>
          </div>

          <div className="library-pagination-right">
            <p>
              Showing {paginate.currentPageOffSet + 1} -{" "}
              {paginate.currentPageOffSet + cardList.length}
            </p>
            <div className="library-pagination-options">
              <button
                onClick={loadPrevious}
                disabled={paginate.prevPageOffSet === null}
                className={`f-w-bold swatch-pagination ${
                  paginate.prevPageOffSet === null
                    ? "library-pagination--disabled"
                    : ""
                }`}
              >
                Previous
              </button>
              <button
                onClick={loadNext}
                disabled={!paginate.nextPageOffSet}
                className={`f-w-bold swatch-pagination ${
                  !paginate.nextPageOffSet ? "library-pagination--disabled" : ""
                }`}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LibraryGrid;
