import React, { useState } from "react";
import "../../../components/Designs/QuoteDetails/OpenQuote/index.scss";
import { QuoteCard } from "./QuoteList";
import s from "./QuoteList.module.scss";
import HeaderWithLogo from "./HeaderWithLogo";
import { QUOTE_STATUS } from "../../../constants/Quote.js";

const OpenQuote = ({ table, additionalCosts, style }) => {
  const totalTableCosts = Object.keys(table).reduce(
    (acc, tableHeading) =>
      acc +
      table[tableHeading]
        .map(rowData => (rowData["consumption"] * rowData["rate"]).toFixed(2))
        .map(cost => (isNaN(cost) ? 0 : cost))
        .reduce((acc, currVal) => acc + Number(currVal), 0),
    0
  );

  const totalAdditionalCosts = Object.entries(additionalCosts).reduce(
    (acc, [costName, percent]) => acc + (percent * totalTableCosts) / 100,
    0
  );

  let quoteTotal = totalAdditionalCosts + totalTableCosts;

  return (
    <div style={style} className="open-quote">
      {Object.keys(table).map(tableHeading => {
        const tableTotal = table[tableHeading]
          .map(rowData => (rowData["consumption"] * rowData["rate"]).toFixed(2))
          .map(cost => (isNaN(cost) ? 0 : cost))
          .reduce((acc, currVal) => acc + Number(currVal), 0)
          .toFixed(2);

        // Hide tables with 0 as totalCost
        return tableTotal === (0).toFixed(2) ? null : (
          <div className="flex open-quote" style={{ width: "100vw" }}>
            <div
              className="table flex-col"
              style={{ width: "100%", marginBottom: "10px" }}
            >
              <div className="table__title f-w-semi-bold">{tableHeading}</div>
              <div
                className="table__header f-w-semi-bold table__row flex"
                style={{ width: "100%" }}
              >
                <span style={{ minWidth: "50%", textAlign: "left" }}>
                  Particulars
                </span>
                <span style={{ minWidth: "50%" }}>Amount</span>
              </div>
              <div className="table__content">
                {table[tableHeading]
                  .filter(
                    rowData =>
                      //   Check if consumption and rate attribute present
                      rowData["consumption"] &&
                      rowData["rate"] &&
                      // Check if consumption and rate are a string
                      !isNaN(rowData["consumption"] * rowData["rate"]) &&
                      // Check if the total of consumption and rate is greater than 0
                      Number(rowData["consumption"] * rowData["rate"]) > 0
                  )
                  .map((rowData, rowIndex) => (
                    <div className="table__row flex">
                      <span style={{ minWidth: "50%", textAlign: "left" }}>
                        {rowData["name"]}
                      </span>
                      <span
                        style={{ minWidth: "50%" }}
                        className="table__calculated-data"
                      >
                        {isNaN(rowData["consumption"] * rowData["rate"])
                          ? (0).toFixed(2)
                          : (rowData["consumption"] * rowData["rate"]).toFixed(
                              2
                            )}
                      </span>
                    </div>
                  ))}
              </div>

              <div className="table__row flex f-w-semi-bold">
                <span
                  style={{
                    minWidth: "50%",
                    textAlign: "left",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden"
                  }}
                >
                  Total {tableHeading} Charges
                </span>
                <span
                  style={{ minWidth: "50%" }}
                  className="table__calculated-data"
                >
                  {tableTotal}
                </span>
              </div>
            </div>
            {/* Main Table END */}
          </div>
        );
      })}
      {/* Additional Costs Table START */}
      <div className="table table--additional-costs flex-col">
        <div className="table__content">
          {Object.keys(additionalCosts).map(costName => {
            if (additionalCosts[costName] === 0) {
              return null;
            }
            return (
              <div className="table__row flex">
                <span
                  style={{ minWidth: "50%", textAlign: "left" }}
                  className=""
                >
                  {costName} ({additionalCosts[costName]}%)
                </span>

                <span
                  style={{ minWidth: "50%" }}
                  className="table__calculated-data"
                >
                  {(
                    (totalTableCosts * additionalCosts[costName]) /
                    100
                  ).toFixed(2)}
                </span>
              </div>
            );
          })}
        </div>
        <div className="table__footer table__row flex f-w-semi-bold f-sz-l">
          <span style={{ minWidth: "50%", textAlign: "left" }}>
            Total Charges
          </span>
          <span style={{ minWidth: "50%" }} className="table__calculated-data">
            {quoteTotal.toFixed(2)}
          </span>
        </div>
      </div>
      {/* Additional Costs Table END */}
      <div className="flex" style={{ padding: "0 16px" }}>
        <span
          style={{
            fontStyle: "italic",
            width: "100%",
            lineHeight: "1.33",
            letterSpacing: "0.2px",
            textAlign: "right",
            color: "var(--fill-grey-medium)",
            marginTop: "-1em",
            marginBottom: "12px"
          }}
          className="f-sz-sm"
        >
          Quote is exclusive of local taxes
        </span>
      </div>
    </div>
  );
};

const BundledQuote = ({ quote }) => (
  <div className="flex" style={{ padding: "16px 14px" }}>
    <div style={{ color: "var(--fill-grey-medium)" }} className="f-sz-sm">
      Price (INR)
    </div>
    <div style={{ marginLeft: "auto" }} className="f-w-semi-bold">
      {quote.totalCost}
    </div>
  </div>
);

const QuoteDetails = ({ updateQuoteStatus, ...props }) => {
  if (!props.quote) {
    props.history.goBack();
    return null;
  }
  const quote = JSON.parse(props.quote.quote);
  const quoteType = props.quote.quote_type;
  const quoteId = props.quote.id;
  const designId = props.quote.design_id;
  const quoteStatus = props.quote.status;
  const factoryId = props.quote.factory_id;
  return (
    <>
      <HeaderWithLogo isBackIcon />
      <QuoteCard
        quote={props.quote}
        style={{ border: "none !important" }}
        className={s.quoteDetails__quoteCard}
      />
      <div className="flex" style={{ padding: "16px 14px" }}>
        <div style={{ color: "var(--fill-grey-medium)" }} className="f-sz-sm">
          Quantity (Pcs)
        </div>
        <div style={{ marginLeft: "auto" }} className="f-w-semi-bold">
          {
            {
              BUNDLED: JSON.parse(props.quote.quote).bundled_quantity,
              OPEN: JSON.parse(props.quote.quote).total_quantity
            }[quoteType]
          }
        </div>
      </div>
      {
        {
          BUNDLED: <BundledQuote quote={quote} />,
          OPEN: (
            <OpenQuote
              table={quote.table}
              additionalCosts={quote.additionalCosts}
              style={{ marginBottom: "48px" }}
            />
          )
        }[quoteType]
      }
      <div
        className="quote-details flex"
        style={{
          backgroundColor: "var(--fill-white)",
          padding: "6px",
          height: "48px",
          boxShadow: "inset 0 1px 0 0 var(--fill-grey-light3)",
          position: "fixed",
          bottom: "0",
          width: "100%",
          left: "0"
        }}
      >
        <button
          disabled={[QUOTE_STATUS.ACCEPTED, QUOTE_STATUS.RESUBMISSION].includes(
            Number(quoteStatus)
          )}
          className="btn btn--blue f-w-semi-bold"
          style={{
            flexGrow: "1",
            marginRight: "6px"
          }}
          onClick={() =>
            updateQuoteStatus({
              design_name: this.props.design.name,
              quote_id: quoteId,
              design_id: designId,
              status: QUOTE_STATUS.RESUBMISSION,
              factoryId,
            })
          }
        >
          RESUBMIT
        </button>

        <button
          disabled={[QUOTE_STATUS.ACCEPTED, QUOTE_STATUS.RESUBMISSION].includes(
            Number(quoteStatus)
          )}
          style={{
            flexGrow: "1"
          }}
          className="btn btn--primary f-w-semi-bold"
          onClick={() =>
            updateQuoteStatus({
              design_name: this.props.design.name,
              quote_id: quoteId,
              design_id: designId,
              status: QUOTE_STATUS.ACCEPTED,
              flashMsg: `Quote for ${this.props.design.name} approved.`,
              collection_id: this.props.design.collection_id,
              factoryId,
            })
          }
        >
          Approve
        </button>
      </div>
    </>
  );
};

export default QuoteDetails;
