import { createAction } from "redux-actions";
import axios from "axios";
import moment from "moment";
import { get_service_endpoint } from "../../ServiceEndpoints";
import { flash } from "./CommonActions";

const ep = `${get_service_endpoint("fabric")}/reports`;


const GET_SM_REPORTS = createAction("GET_SM_REPORTS");
const GET_AM_REPORTS = createAction("GET_AM_REPORTS");
const GET_REPORTS_LOADING = createAction("GET_REPORTS_LOADING");
// const GET_SAMPLING_REPORT = createAction("GET_SAMPLING_REPORT");
// const GET_PRODUCTION_REPORT = createAction("GET_PRODUCTION_REPORT");
// const GET_ACTIVE_FACTORIES_REPORT = createAction("GET_ACTIVE_FACTORIES_REPORT");
// const GET_FACTORY_PERFORMANCE = createAction("GET_FACTORY_PERFORMANCE");
// const GET_BUYER_PERFORMANCE = createAction("GET_BUYER_PERFORMANCE");
// const GET_ORDERS_REPORT = createAction("GET_ORDERS_REPORT");
// const GET_DESIGNS_REPORT = createAction("GET_DESIGNS_REPORT");
// const GET_MATERIAL_REPORT = createAction("GET_MATERIAL_REPORT");

const REPORTS_LOADING = createAction("REPORTS_LOADING");

const formatParams = params => ({
  ...params,
  inlineLoader: true,
  minDate: params.minDate ? moment(params.minDate).format('YYYY-MM-DD') : null,
  maxDate: params.maxDate ? moment(params.maxDate).format('YYYY-MM-DD') : null,
  limit: params.limit ? params.limit : 20,
  brandId: params.brandId && params.brandId.value ? params.brandId.value : null
});

export const getSMReport = (params) => dispatch => {
  dispatch(GET_REPORTS_LOADING(true))
  return axios
  .get(`${ep}/performance`, {
    params: params
  })
  .then(res => {
      return dispatch(GET_SM_REPORTS({data:res?.data?.data}));
    })
    .catch(err => {
      console.log('err',err)
      dispatch(GET_REPORTS_LOADING(false))
      // dispatch(REPORTS_LOADING(false));
      dispatch(
        flash({
          message: "Could not fetch supplier manager  reports",
          type: "action",
          status: "error"
        })
      );
    });
};

export const getAMReport = (params) => dispatch => {
  dispatch(GET_REPORTS_LOADING(true))
  return axios
  .get(`${ep}/performance`, {
    params: params
  })
  .then(res => {
      return dispatch(GET_AM_REPORTS({data:res?.data?.data}));
    })
    .catch(err => {
      console.log('err',err)
      dispatch(GET_REPORTS_LOADING(false))
      // dispatch(REPORTS_LOADING(false));
      dispatch(
        flash({
          message: "Could not fetch account manager reports",
          type: "action",
          status: "error"
        })
      );
    });
};

