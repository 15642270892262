import React, { Component } from "react";
import PropTypes from "prop-types";
// import { Line as ProgressLine } from "rc-progress";
// import Trash from "../../../../assets/img/trash-24-px.svg";
import Trash from "../../../../assets/images/action/ic-delete.faded.svg";
import "./File.scss";
import icPlcAttachment from "../../../../assets/images/file/ic-plc-attachment.svg";
import icFilePlaceholder from "../../../../assets/images/file/ic-file-placeholder.svg";
import { getBgUrl } from "../../../../helpers/DesignHelpers";

export default class File extends Component {
  static defaultProps = {
    hasAdvancedOptions: true,
    acceptTypes: "*",
  };

  state = {
    file: null,
    fileType: null,
    progress: 0,
    isInitialValuesSet: false,
    placeholder: icPlcAttachment,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.initialFileName && !prevState.isInitialValuesSet) {
      return {
        ...prevState,
        progress: nextProps.progress ? nextProps.progress : 0,
        file: {
          name: nextProps.initialFileName.name,
          size: nextProps.initialFileName.size,
          url: nextProps.initialFileName.imgUrl,
        },
        isInitialValuesSet: true,
        fileType: "image",
        isEditMode: true,
        placeholder: getBgUrl(nextProps.initialFileName.name),
      };
    }

    if (nextProps.initialSelectedFile && !prevState.touched) {
      return {
        file: nextProps.initialSelectedFile,
        fileType: nextProps.initialSelectedFile.type.split("/")[0],
        touched: true
      }
    }
    return {
      ...prevState,
      progress: nextProps.progress ? nextProps.progress : 0
    };
  }

  handleChange = selectedFiles => {
    // console.dir(selectedFiles);
    const currentFile = selectedFiles[0];
    this.setState({
      file: currentFile,
      fileType: currentFile.type.split("/")[0],
      placeholder: getBgUrl(currentFile.name),
    });
    this.props.onChange(currentFile);
  };

  deleteFile = () => {
    // this.setState({
    //     file: null,
    //     fileTye: null
    // })
    this.refs["FileInput"].value = "";
    this.setState({
      file: null,
      fileType: null,
      progress: 0,
      isEditMode: false,
      placeholder: icPlcAttachment,
    });
    this.props.onChange(null);
    // console.dir(this.refs["FileInput"]);
  };

  render() {

    const { containerClass, text, metaText, isFactory } = this.props;
    return (
      <div className={`input-file ${containerClass}`}>
        <input
          type="file"
          onChange={e => {
            this.handleChange(e.target.files);
          }}
          ref="FileInput"
          className={"hide"}
          accept={this.props.acceptTypes}
        />
        {this.state.file !== null ? (
          <React.Fragment>
            <div className="input-file__wrapper flex">
              <div className="input-file__file-thumb flex">
                {(() => {
                  switch (this.state.fileType) {
                    case "image":
                      return (
                        <img
                          style={{ maxHeight: "80px", maxWidth: "80px" }}
                          alt="img"
                          src={
                            this.state.isEditMode
                              ? this.state.file.url
                              : window.URL.createObjectURL(this.state.file)
                          }
                        />
                      );
                    default:
                      return (
                        <img
                          style={{
                            maxHeight: "80px",
                            maxWidth: "80px",
                            margin: "auto"
                          }}
                          src={this.state.placeholder}
                          alt="img"
                        />
                      );
                  }
                })()}
              </div>
              <div className="input-file__progress flex-col">
                {
                  //   <span className="input-file__percent">
                  //   {this.state.progress}%
                  // </span>
                  // <ProgressLine
                  //   className="input-file__progress-bar"
                  //   percent={this.state.progress}
                  //   strokeWidth="3"
                  //   trailWidth="3"
                  //   trailColor="#e5e5e5"
                  //   strokeColor="#02bd70"
                  // />
                }
                <span className="input-file__meta">
                  {this.state.file.name.slice(0, 25)} <br />
                  <span className="f-sz-sm">
                    {!this.state.isInitialValuesSet || this.state.file.size
                      ? `(${(this.state.file.size / (1024 * 1024)).toFixed(
                        2
                      )} MB)`
                      : ''}
                  </span>
                </span>

                {// TODO: Convert to render child pattern
                  this.state.fileType === "image" &&
                  this.props.hasAdvancedOptions &&
                  !isFactory &&
                  (
                    <div className="input-file__cover-img flex f-sz-sm f-color-faded">
                      <input
                        onChange={evt => this.props.onCoverSelect(evt)}
                        type="checkbox"
                        id="cover-img__checkbox"
                      />{" "}
                      &nbsp;&nbsp;
                      <label htmlFor="cover-img__checkbox">
                        SET AS COVER IMAGE
                      </label>
                    </div>
                  )}
              </div>
              <div className="input-file__delete">
                <img src={Trash} alt="" onClick={this.deleteFile} />
              </div>
            </div>
          </React.Fragment>
        ) : (
            <React.Fragment>
              <div className="input-file__wrapper flex">
                <div className="input-file__file-thumb flex">
                  <img
                    style={{ margin: "auto" }}
                    src={icPlcAttachment}
                    alt="attachment"
                  />
                </div>
                <div className="input-file__progress flex-col">
                  {
                    //   <span className="input-file__percent">
                    //   {this.state.progress}%
                    // </span>
                    // <ProgressLine
                    //   className="input-file__progress-bar"
                    //   percent={this.state.progress}
                    //   strokeWidth="3"
                    //   trailWidth="3"
                    //   trailColor="#e5e5e5"
                    //   strokeColor="#02bd70"
                    // />
                  }
                  <span className="input-file__meta">
                    {
                      text ? text : <span>Select a File<sup>*</sup></span>
                    }
                    <br />
                    <span className="f-sz-sm">
                      You can choose a file from your <br /> computer
                  </span>
                  </span>

                  {this.state.fileType === "image" &&
                    !isFactory && (
                      <div className="input-file__cover-img flex f-sz-sm f-color-faded">
                        <input
                          onChange={evt => this.props.onCoverSelect(evt)}
                          type="checkbox"
                          id="cover-img__checkbox"
                        />{" "}
                    &nbsp;&nbsp;
                        <label htmlFor="cover-img__checkbox">
                          SET AS COVER IMAGE
                    </label>
                      </div>
                    )}
                </div>
                <div
                  className="input-file__delete"
                  onClick={() => this.refs["FileInput"].click()}
                  style={{
                    color: "var(--fill-primary-medium)",
                    lineHeight: "1.43",
                    fontWeight: "bold",
                    textTransform: "uppercase",
                    textAlign: "left"
                  }}
                >
                  BROWSE
              </div>
              </div>
            </React.Fragment>
          )}
      </div>
    );
  }
}
