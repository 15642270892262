import React from "react";
import PropTypes from "prop-types";
import Button from "../Button/Button";

import "./InfoButton.scss";

const InfoButton = ({
  icon,
  title,
  subtitle,
  onClick,
  hasButton = false,
  hasBottomBtn = false,
  onClickBottomBtn,
  notInNotification = false,
  btnContainerClass = "",
  extraBtnClass = "",
  cta
}) => {
  const clickStyling = {
    margin: "auto"
  };
  if (onClick) clickStyling["cursor"] = "pointer";

  return (
    <div className="info-btn__container flex empty-quote-details">
      <div className="info-btn__details flex">
        <div
          style={clickStyling}
          onClick={onClick}
          className={btnContainerClass}
        >
          {icon &&
            (hasButton ? (
              <div className="info-btn__dashed-outline flex">
                <Button
                  shadow={true}
                  category="circle"
                  className="info-btn__circle"
                >
                  {icon}
                </Button>
              </div>
            ) : (
              <div
                className={`flex ${extraBtnClass}`}
                style={{
                  margin: "auto",
                  marginBottom: "18px"
                }}
              >
                {icon}
              </div>
            ))}
        </div>
        <div className="info-btn__text flex">
          <span className="info-btn__title f-sz-l f-w-bold">{title}</span>
          <span className="info-btn__subtitle f-color-faded">{subtitle}</span>
          {cta && (
            <div className="cta">
              {cta}
            </div>
          )}
        </div>
        {hasBottomBtn && !notInNotification ? (
          <div>
            <div
              style={{
                fontSize: "14px",
                color: "var(--fill-primary-medium)",
                textAlign: "center",
                fontWeight: "bold",
                cursor: "pointer",
                paddingTop: "20px"
              }}
              onClick={() => onClickBottomBtn("swatch")}
            >
              SELECT FROM SWATCH LIBRARY
            </div>
            <div
              style={{
                fontSize: "14px",
                color: "var(--fill-primary-medium)",
                textAlign: "center",
                fontWeight: "bold",
                cursor: "pointer",
                paddingTop: "20px"
              }}
              onClick={() => onClickBottomBtn("file")}
            >
              UPLOAD FILE
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

InfoButton.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  onClick: PropTypes.func
};

export default InfoButton;
