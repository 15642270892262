import React, { Component } from "react";

import Button from "components/UI/Button/Button";
import checkImg from 'assets/images/navigation/ic-check-white.svg';

class MarkCompleteButton extends Component {
  render() {
    const { handleClick } = this.props;

    return (
      <Button
        category="btn primary-blue"
        onClick={() => {
          handleClick();
        }}
      >
        <img src={checkImg} alt="check"/>
        <span>Mark complete</span>
      </Button>
    );
  }
}

export default MarkCompleteButton;
