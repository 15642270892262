import * as Auth from "./AuthActions";
import * as Collection from "./CollectionActions";
import * as Common from "./CommonActions";
import * as Design from "./DesignActions";
import * as Global from "./GlobalActions";
import * as Factory from "./FactoryActions";
import * as Notification from "./NotificationActions";
import * as Products from "./Products";
import * as SwatchLibrary from "./SwatchLibraryActions";
import * as MessageActions from "./MessageActions";
import * as BrandActions from "./BrandActions";
import * as LogiPartnerActions from "./LogisticActions";
import * as orders from "./OrderActions";
import * as quotes from "./QuoteActions";
import * as dashboard from "./DashboardActions";
import * as materialLibrary from "./MaterialLibraryActions";
import * as reports from "./ReportsAction";
import * as tna from "./TnaActions";
import * as user from "./UserActions";
import * as performanceReports from "./PerformanceReportActions";
import * as fabricQualities from "./FabricQualitiesActions";

export default {
  ...Common,
  ...Auth,
  ...Global,
  ...Collection,
  ...Design,
  ...Factory,
  ...Notification,
  ...Products,
  ...SwatchLibrary,
  ...MessageActions,
  ...BrandActions,
  ...LogiPartnerActions,
  orders,
  quotes,
  ...dashboard,
  ...materialLibrary,
  ...reports,
  ...tna,
  ...user,
  ...performanceReports,
  ...fabricQualities
};
