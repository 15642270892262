import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import Header from "../UI/Header";
import Table from "../UI/Table";
import FilterContainer from "../UI/Filters";
import { DatePicker } from "../UI/Filters/DateRangePicker";
import MultiSelect from "../UI/Filters/MultiSelect";
import SingleSelect from "../UI/Filters/SingleSelect";
import { getDesignsReport } from "store/actions/ReportsAction";
import Loader from "components/UI/InlineLoader";

const LIMIT = 20;
const HEADINGS = [
  "DESIGN NAME",
  "IMAGE",
  "DESIGN ID",
  "CREATED BY",
  "CREATED ON",
  "COLLECTION",
  "CATEGORY",
  "SUBCATEGORY",
];

const DesignReport = ({
  getDesignsReport,
  designs = { list: [], filters: {}, metadata: {} },
  loading,
  userDetails,
  brandsList,
  isBrandManager
}) => {
  const [allHeadings, setAllHeadings] = useState(HEADINGS);
  const [extraGridTemplate, setExtraGridTemplate] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [filters, setFilters] = useState({});
  const [dates, setDates] = useState({});

  useEffect(() => {
    getDesignsReport({ isInit: 1 });
  }, []);

  useEffect(() => {
    if (!designs.metadata || !designs.metadata.additionalFields){
      return;
    }

    setAllHeadings([...HEADINGS, ...designs.metadata.additionalFields.map(af => af.field_name.toUpperCase())]);
    setExtraGridTemplate(Array.from(new Array(designs.metadata.additionalFields.length).fill('auto')).join(' '));
  }, [designs.metadata]);

  const paginationHandler = values => {
    if (values.offset && designs.list.length <= (currentIndex + 1) * LIMIT) {
      setCurrentIndex(currentIndex + 1);
      getDesignsReport({
        offset: designs.pagination.nextOffset,
        ...filters
      });
    } else {
      if (values.offset || (!values.offset && values.isnext === 1)) {
        setCurrentIndex(currentIndex + 1);
      } else if (values.isnext === 0) {
        setCurrentIndex(currentIndex - 1);
      }
    }
  };

  if (loading) return <Loader />;

  const getOverallReport = () => {
    return getDesignsReport({
      ...filters,
      limit: designs.pagination.totalCount,
      skipStore: true,
      inlineLoader: false
    }).then(data => {
      return data.records;
    });
  };

  return (
    <div className="report-content">
      <Header
        heading="Design Report"
        reportType="designs"
        pagination={designs.pagination}
        currentIndex={currentIndex}
        paginationHandler={paginationHandler}
        getOverallReport={getOverallReport}
        headings={allHeadings}
        metadata={designs.metadata}
      />
      <FilterContainer
        updateFilters={() => {
          getDesignsReport({
            ...filters,
            ...dates,
            isInit: 1
          });
          setCurrentIndex(0);
        }}
      >
        {userDetails.isSuperAdmin && (
          <SingleSelect
            label="Brand"
            filterOptions={brandsList}
            onChange={id => {
              setFilters({ ...filters, brandId: id ? id : null });
            }}
            selectedFilters={filters.brandId}
          />
        )}
        <DatePicker
          label="Date Range"
          updateFilterDates={dates => setDates(dates)}
          selectedDates={dates}
        />
        <MultiSelect
          label="Collection"
          filterOptions={designs.filters.collections}
          onChange={ids => {
            setFilters({ ...filters, collectionIds: ids ? ids.join() : null });
          }}
          selectedFilters={filters.collectionIds}
        />
        <MultiSelect
          label="Buyer"
          filterOptions={designs.filters.buyers}
          onChange={ids => {
            setFilters({ ...filters, creatorIds: ids ? ids.join() : null });
          }}
          selectedFilters={filters.creatorIds}
        />
      </FilterContainer>
      <Table
        headings={allHeadings}
        data={designs.list.slice(
          currentIndex * LIMIT,
          currentIndex * LIMIT + LIMIT
        )}
        gridTemplateColumns={`
        minmax(150px, 1.16fr)
        minmax(130px, 1fr)
        minmax(140px, 1.1fr)
        minmax(220px, 1.7fr)
        minmax(176px, 1.36fr)
        minmax(170px, 1.3fr)
        minmax(130px, 1fr)
        minmax(130px, 1fr)
        ${extraGridTemplate}
        `}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  designs: state.reports.designs,
  loading: state.reports.loading,
  userDetails: state.auth.userDetails
});

export default connect(mapStateToProps, {
  getDesignsReport
})(DesignReport);
