import React from "react";
import style from "./style.module.scss";
import constructionImg from "assets/images/fabricQualities/Construction.svg";
import threadCountImg from "assets/images/fabricQualities/ThreadCount.svg";
import { Tooltip } from "antd";
import ToggleSwitch from "./ToggleSwitch";
export const columnsData = (
  { loomType, productType, requirementType, certification },
  edit,
  reloader
) => [
  {
    title: "Fabric type",
    dataIndex: "productTypeId",
    render: (valueId) => {
      if (!productType) return "-";
      const productTypeName = productType.map((data) => {
        return data.id === valueId && data.name;
      });
      return productTypeName;
    },
  },
  {
    title: "Product name",
    dataIndex: "productName",
    render: (value) => {
      return <div className={style["text-wrapper"]}>{value}</div>;
    },
  },
  {
    title: "Product type",
    dataIndex: "requirementType",
    render: (valueId) => {
      if (!requirementType) return "-";
      const requirementTypeName = requirementType.map((data) => {
        return data.id === valueId && data.name;
      });
      return requirementTypeName;
    },
  },
  {
    title: "Loom-type",
    dataIndex: "loomTypeId",
    render: (valueId) => {
      if (!loomType) return "-";
      const loomTypeName = loomType.map((data) => {
        return data.id === valueId && data.name;
      });
      return loomTypeName;
    },
  },
  {
    title: "Specifications",
    render: (value, record) => {
      return (
        <>
          <div className={style["specification-column"]}>
            <Tooltip placement="bottom" title="Thread Count">
              <img src={constructionImg} alt="construction img" />
            </Tooltip>
            <div>{`${record.threadCount ? record.threadCount.x : "--"} x ${
              record.threadCount ? record.threadCount.y : "--"
            }`}</div>
          </div>
          <div className={style["specification-column"]}>
            <Tooltip placement="bottom" title="Construction" color="#FFFFFF">
              <img src={threadCountImg} alt="Threadcount img" />
            </Tooltip>
            <div>{`${record.construction ? record.construction.epi : "--"} x ${
              record.construction ? record.construction.ppi : "--"
            }`}</div>
          </div>
        </>
      );
    },
  },
  {
    title: "GSM",
    dataIndex: "weight",
    render: (value) => {
      return <div>{value ? value : "--"}</div>;
    },
  },
  {
    title: "Width",
    dataIndex: "width",
    render: (value) => {
      return <div>{`${value} in`}</div>;
    },
  },
  {
    title: "Certifications",
    dataIndex: "certifications",
    render: (value) => {
      const certificationValue = value.reduce((acc, curr) => {
        acc.push(curr.name);
        return acc;
      }, []);
      return (
        <div className={style["text-wrapper"]}>
          {certificationValue.join(", ") || "--"}
        </div>
      );
    },
  },
  {
    title: "Supplier Name",
    dataIndex: "locofastroot",
    render: (value,record) => {
      console.log(value,record,'---')
      return <div>{value?.name ? value.name :'--'}</div>;
    },
  },
  {
    title: "Price",
    dataIndex: "price",
    render: (value) => {
      return <div>{value ? `₹${value} /m` : "--"}</div>;
    },
  },
  {
    title: "",
    dataIndex: "",
    render: (value, record) => {
      return (
        <div className={style["actions"]}>
          <div className={style.edit} onClick={() => edit(record)}>
            Edit
          </div>
          <ToggleSwitch record={record} reloader={reloader} />
        </div>
      );
    },
  },
];

export const initialValues = {
  weight: "",
  fabricType: null,
  x: "",
  y: "",
  productCategory: null,
  epi: "",
  ppi: "",
  subCategory: [],
  productType: [],
  width: "",
  loomType: null,
  certifications: [],
  price: "",
  supplier: "",
  tat: "",
};

export const initialFormValues = {
  weight: "12",
  certifications: ["1", "2", "3"],
  price: "8",
};

export const transformToForm = (orderData) => {
  return {
    id: orderData.id,
    productName: orderData.productName,
    isDisable: orderData.isDisable,
    weight: orderData.weight ? Number(orderData?.weight) : "",
    fabricType: orderData.productTypeId,
    x: orderData.threadCount ? orderData.threadCount.x : "",
    y: orderData.threadCount ? orderData.threadCount.y : "",
    productCategory: orderData.productCategoryId,
    epi: orderData.construction ? Number(orderData.construction.epi) : null,
    ppi: orderData.construction ? Number(orderData.construction.ppi) : null,
    subCategory: String(orderData.productSubCategoryId),
    requirementType: String(orderData.requirementType),
    width: Number(orderData.width),
    loomType: orderData.loomTypeId,
    certifications: orderData.certifications.map((cur) => {
      return String(cur);
    }),
    price: orderData.price ? orderData.price : "",
    supplier: orderData?.locofastroot?.name ?? "",
    tat: orderData?.tat ?? "",
    supplierId: orderData.supplierId,
    isInventory: orderData.isInventory,
    inventoryUnitPrice: orderData.inventoryUnitPrice,
    maximumOrderQuantity: orderData.maximumOrderQuantity,
    minimumOrderQuantity: orderData.minimumOrderQuantity,
  };
};
