import React from "react";
import moment from "moment";

// == local imports ==
import closeIcon from "../../../assets/images/navigation/ic-close.svg";
import downloadIcon from "../../../assets/images/file/ic-download.faded.svg";

const FileDetailsModal = ({ closeModal, file = {}, user }) => {
  console.log("FILE ::", user);

  return (
    <div
      style={{
        position: "fixed",
        bottom: 0,
        right: 0,
        width: window.innerWidth,
        height: window.innerHeight,
        backgroundColor: "#0006",

        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <div
        style={{
          width: "85%",
          minHeight: "75%",
          backgroundColor: "#fff",
          borderRadius: "5px"
        }}
      >
        <div
          style={{
            padding: "16px 12px",
            textAlign: "right",
            borderBottom: "1px solid #f5f5f5"
          }}
          onClick={closeModal}
        >
          <img src={closeIcon} alt="Close" />
        </div>
        <div
          style={{
            padding: "16px"
          }}
        >
          <div
            style={{
              height: "250px",
              position: "relative"
            }}
          >
            <img
              src={file.url}
              alt="Cover"
              style={{ width: "100%", height: "100%" }}
            />
            <a href={file.url}>
              <img
                src={downloadIcon}
                alt="download"
                style={{
                  position: "absolute",
                  right: "6px",
                  bottom: "6px",
                  backgroundColor: "#fff",
                  padding: "8px",
                  borderRadius: "100px",
                  cousor: "pointer"
                }}
              />
            </a>
          </div>
          <div style={{ padding: "16px", lineHeight: "20px" }}>
            {/* File details row */}
            <div style={{ display: "flex", alignItems: "center" }}>
              {/* single details row item */}
              <div style={{ flex: 1 }}>
                <div
                  style={{
                    fontSize: "12px",
                    color: "var(--fill-grey-medium)"
                  }}
                >
                  File Name
                </div>

                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "var(--fill-locofast-black)"
                  }}
                >
                  {file.fileTitle}
                </div>
              </div>

              {/* single details row item */}
              <div style={{ flex: 1, padding: "8px 0px" }}>
                <div
                  style={{
                    fontSize: "12px",
                    color: "var(--fill-grey-medium)"
                  }}
                >
                  File Category
                </div>

                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "var(--fill-locofast-black)"
                  }}
                >
                  {file.fileCategory}
                </div>
              </div>
            </div>

            {/* File details row */}
            <div style={{ display: "flex", alignItems: "center" }}>
              {/* single details row item */}
              <div style={{ flex: 1, padding: "8px 0px" }}>
                <div
                  style={{
                    fontSize: "12px",
                    color: "var(--fill-grey-medium)"
                  }}
                >
                  Uploaded By
                </div>

                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "var(--fill-locofast-black)"
                  }}
                >
                  {user.name}
                </div>
              </div>

              {/* single details row item */}
              <div style={{ flex: 1 }}>
                <div
                  style={{
                    fontSize: "12px",
                    color: "var(--fill-grey-medium)"
                  }}
                >
                  Date
                </div>

                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "var(--fill-locofast-black)"
                  }}
                >
                  {moment(file.createdAt).format("MMM DD, YYYY")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileDetailsModal;
