import React, { Component } from "react";
import moment from "moment";
import CloseImg from "./../../assets/images/navigation/ic-close.svg";

import "./TnaImageModal.scss";
import { TIMESTAMP_FORMAT } from "./../../constants/Date";
import { getBgUrl, checkPic } from "helpers/DesignHelpers"

// FIXME: I am a rouge modal, I should be instantiated from OpenModal, but due
// to TnaImageModal complications, I was created as a rouge Modal. Try to move me
// to modal list just like other modals
export default class TnaImageModal extends Component {

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.onClose();
    }
  }

  componentDidMount() {
    window.addEventListener("keydown", this.escFunction.bind(this), false);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.escFunction.bind(this), false);
  }

  render() {
    const { placeHolder, imageUrl, title, onClose, date, uploadedBy, fileName } = this.props;
    const isImage = checkPic(imageUrl);
    return (
      <div className="modal__container tna-file__modal">
        <div className="file-details__modal flex">
          <div className="file-details__view flex">
            <img src={placeHolder ? placeHolder : isImage ? imageUrl : getBgUrl(imageUrl) } alt="file"/>
          </div>
          <div className="file-details__meta flex-col">
            <div className="file-details__header flex">
              <span className="f-sz-xl f-w-bold">{title}</span>
              <span onClick={onClose} className="file-details__close-img">
                <img src={CloseImg} />
              </span>
            </div>
            <div className="file-details__content">
              <div className="file-details__label f-sz-sm ">File Name</div>
              <div className="">{fileName}</div>

              {uploadedBy && <div className="file-details__label f-sz-sm ">Uploaded By</div>}
              {uploadedBy && <div className="">{uploadedBy}</div>}

              {date && <div className="file-details__label f-sz-sm ">Date</div>}
              {date && <div className="">{moment(date).format(TIMESTAMP_FORMAT)}</div>}

            </div>

            <a href={imageUrl} className="file-details__footer f-sz-l">
              Download File
            </a>
          </div>
        </div>
      </div>
    );
  }
}
