import React, { useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";

import EditUser from "./AddUserModal";

import styles from "./FactoryList.module.scss";
import { FACTORY_USER_ROLES } from "../../../constants/Design";
import {findIndex} from 'lodash';

const User = ({ data: { name, email, role }, onEdit, data }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "16px",
        borderBottom: "1px solid var(--fill-grey-light2)"
      }}
      className={styles.user__item}
    >
      <div style={{ lineHeight: "22px" }}>
        <div>
          <span
            style={{ fontSize: "14px", color: "var(--fill-locofast-black)" }}
          >
            {name}
          </span>

          <span
            style={{
              padding: "1px 6px",
              backgroundColor: "var(--fill-secondary-light)",
              borderRadius: "50px",
              fontSize: "12px",

              marginLeft: "16px"
            }}
          >
            {FACTORY_USER_ROLES[role]}
          </span>
        </div>
        <div className="label__class">{email}</div>
      </div>
      <div
        style={{
          fontSize: "12px",
          fontWeight: 600,
          color: "var(--fill-secondary-medium)",
          cursor: "pointer"
        }}
        className={styles.user__item_edit}
        onClick={() => onEdit(data)}
      >
        Edit
      </div>
    </div>
  );
};

const AllUsersModal = ({
  users = [],
  getSelectedUserAddresses,
  selectedUserAddresses,
  updateUser,
  deleteUser
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [userInEdit, setUserInEdit] = useState({});

  const editHandler = user => {
    setIsEdit(!isEdit);

    if (user) setUserInEdit(user);
  };

  const saveHandler = data => {
    //   save here  on edit
    updateUser(data);
  };

  const onDelete = (factoryId, userId) => {
    deleteUser(factoryId, userId).then(res => {
      setIsEdit(false);
    })
  }

  if (isEdit)
    return (
      <EditUser
        isBack
        onBack={editHandler}
        data={userInEdit}
        updateUser={saveHandler}
        deleteUser={onDelete}
        getSelectedUserAddresses={getSelectedUserAddresses}
        selectedUserAddresses={selectedUserAddresses}
      />
    );

  return (
    <PerfectScrollbar>
      <div
        style={{
          padding: "16px",

          borderTop: "1px solid var(--fill-grey-light)"
        }}
      >
        {users.map(user => (
          <User data={user} onEdit={editHandler} />
        ))}
      </div>
    </PerfectScrollbar>
  );
};

export default AllUsersModal;
