import React, { Component } from "react";

import GreenTick from "assets/img/Collections/ic_tick_circle_green.svg";
import QuoteCard from "./QuoteCardBrand";
import "./styles.scss";

const ApproveQuoteModal = ({ quoteDetails, countAwaited = 0, design }) => (
  <div className="quote-actions accept">
    <QuoteCard
      quoteDetails={quoteDetails}
      design={design}
      noteText={countAwaited > 0
        ? `Quote awaited from ${countAwaited} factories. Accepting this quote will automatically reject other quotes and cancel pending requests.`
        : null}
      overlayIcon={GreenTick}
    />
  </div>
);

export default ApproveQuoteModal;
