import { connect } from "react-redux";
import { withRouter } from "react-router";
import React, { Component } from "react";

import { Helmet } from "react-helmet";


import Messaging from '../../components/Messaging/index';
import {
  fetchDesignsForMessaging,
  fetchFactoriesForDesignId,
  fetchMessagesByDesignId,
  sendMessage,
} from "./../../store/actions/MessageActions";


import "./MessagesContainer.scss";
import { PAGE_TITLES } from "../../constants/Titles";

class MessagesContainer extends Component {
  state = {};

  componentDidMount() {
    const { fetchDesignsForMessaging } = this.props;
    fetchDesignsForMessaging();
  }

  _onSelectDesign = design => {
    const { fetchFactoriesForDesignId } = this.props;
    const designId = design.id;
    fetchFactoriesForDesignId({ designId });
    // .then(factories => fetchMessagesByDesignId({ designId, factoryId: factories[0].id }));
    this.setState({ designId });
  }

  render() {
    const {
      designs, brands, messages, userDetails, factories, sendMessage,
      fetchFactoriesForDesignId,
      fetchMessagesByDesignId,
    } = this.props;

    return <div className="messaging-console__wrapper">
      <Helmet
        bodyAttributes={{
          class: 'body_container'
        }}
        title={PAGE_TITLES.messages()}
      />
      <Messaging
        factories={factories}
        messages={messages}
        userDetails={userDetails}
        brands={brands}
        designs={designs}
        onSelectDesign={this._onSelectDesign}
        sendMessage={sendMessage}
        fetchFactories={fetchFactoriesForDesignId}
        fetchMessages={fetchMessagesByDesignId}
      />
    </div>;
  }
}

const mapStateToProps = state => ({
  userDetails: state.auth.userDetails,
  designs: state.messaging.designs,
  messages: state.messaging.messages,
  factories: state.messaging.factories,
  brands: state.factory.brandsList,
});

const mapDispatchToProps = dispatch => ({
  fetchDesignsForMessaging: (brandId = null) => dispatch(fetchDesignsForMessaging(brandId)),
  fetchFactoriesForDesignId: ({ designId }) => dispatch(fetchFactoriesForDesignId({ designId })),
  fetchMessagesByDesignId: ({ designId, factoryId }) => dispatch(fetchMessagesByDesignId({ designId, factoryId })),
  sendMessage: ({ designId, factoryId, message, file = null }) => dispatch(sendMessage({ designId, factoryId, message, file })),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(MessagesContainer));
