import React, { useCallback, useState } from "react";
import Button from "components/UI/Button/Button";

const Filters = ({ children, updateFilters }) => {
  const [wrapped, setWrapped] = useState(false);
  const ref = useCallback(node => {
    if (node !== null) {
      setWrapped(
        node.getBoundingClientRect().width >= (window.innerWidth - 322)
      );
    }
  }, []);
  return (
    <div className={`filter-container  ${wrapped && "wrapped"}`} ref={ref}>
      <div className="filters flex" >
        {children}
      </div>
      <Button category="blue-bg" onClick={updateFilters}>
        Update
      </Button>
    </div>
  );
};

export default Filters;
