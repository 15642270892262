import React, { Component } from "react";
import { Field, Form, reduxForm } from "redux-form";
import { alphaNumeric, required } from "../../../helpers/form-validations";
import Input from "../../UI/Form/Input/Input";
import File from "../../UI/Form/File/File";
import { default as CustomInput } from "../../UI/Form/Input/CustomInput";
import axios from "axios";
import "./AddFilesForm.scss";
import { get_service_endpoint } from "./../../../ServiceEndpoints";
import actions from "../../../store/actions";
import PerfectScrollbar from "react-perfect-scrollbar";
import { flash } from "../../../store/actions/CommonActions";
import Downshift from "downshift";

const DEFAULT_CATEGORIES = ["Technical Pack", "Fabric", "Construction"];

const formName = "AddFile";
class AddFilesForm extends Component {
  state = {
    progress: 0,
    selectedFile: null,
    fields: {}
  };
  handleFileChange = file => {
    this.setState({
      selectedFile: file
    });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    let newFields = prevState.fields;
    nextProps.hiddenFields.forEach(f => (newFields[f.name] = f.value));
    return {
      ...prevState,
      fields: newFields,
      selectedFile: nextProps.selectedFile
    };
  }

  handleUpload = () => {
    const data = new FormData();
    if (this.state.selectedFile === null) {
      this.props.dispatch(
        actions.flash({
          message: "Select a file before trying to submit."
        })
      );
      return;
    }

    if ((this.state.selectedFile.size / (1024 * 1024)).toFixed(2) > 15) {
      this.props.dispatch(
        actions.flash({
          message: "Please select a smaller file (<15MB)"
        })
      );
      return;
    }

    if (
      !this.state.fields.hasOwnProperty("file_title") ||
      this.state.fields["file_title"] === ""
    ) {
      this.props.dispatch(
        actions.flash({
          message: "Input a File title before trying to submit."
        })
      );
      return;
    }

    if (
      !this.state.fields.hasOwnProperty("file_category") ||
      this.state.fields["file_category"] === ""
    ) {
      this.props.dispatch(
        actions.flash({
          message: "Select a File category before trying to submit."
        })
      );
      return;
    }

    Object.keys(this.state.fields).forEach(k =>
      data.append(k, this.state.fields[k])
    );
    data.append(
      "designfile",
      this.state.selectedFile,
      this.state.selectedFile.name
    );

    return {
      data,
      designId: this.props.design.id,
      collectionId: this.props.design.collection_id,
    };
  };

  onFieldChange = ({ name, value }) => {
    this.setState({
      fields: {
        ...this.state.fields,
        [name]: value
      }
    });
  };

  render() {
    const { handleSubmit } = this.props;
    const customRadioRef = React.createRef();
    const customInputRef = React.createRef();

    const ADDITIONAL_CATEGORIES = [
      // Filter out duplicate categories
      ...new Set(
        Object.entries(this.props.design.files)
          .filter(
            ([fileId, fileObj]) =>
              !DEFAULT_CATEGORIES.includes(fileObj["file_category"]) &&
              fileObj["file_category"] !== "Inspiration"
          )
          .map(([fileId, fileObj]) => fileObj["file_category"])
      )
    ];

    return (
      <PerfectScrollbar
        option={{
          suppressScrollX: true
        }}
        className="add-file-container add-files__modal flex-col"
        containerRef={ref => (this._scrollRef = ref)}
      >
        <Form
          style={{
            padding: "1em 2em"
          }}
          name={formName}
          id={formName}
          onSubmit={handleSubmit(values => {
            const formData = this.handleUpload();
            if (formData)
              this.props
                .onSubmit({ ...formData, designName: this.props.design.name })
          })}
          className="design_file--form"
        >
          <div style={{ justifyContent: "center" }} className="flex">
            <File
              onCoverSelect={({ currentTarget: { checked } }) =>
                this.onFieldChange({ name: "cover", value: checked ? 1 : 0 })
              }
              progress={this.state.progress}
              onChange={this.handleFileChange}
              checked={this.props.isCover}
              initialSelectedFile={this.state.selectedFile}
              isFactory={this.props.isFactory}
            />
          </div>
          <Field
            component={Input}
            validate={[required, alphaNumeric]}
            maxLength={50}
            type="text"
            name="file_title"
            label="Enter File Title"
            onChange={evt => this.onFieldChange(evt.currentTarget)}
            autoFocus={true}
          />
          <label
            htmlFor="file_category"
            class="f-sz-sm"
            style={{ color: "var(--fill-grey-dark)" }}
          >
            Select file type *
            </label>
          <Downshift>
            {({
              getInputProps,
              getItemProps,
              getLabelProps,
              getMenuProps,
              isOpen,
              inputValue,
              highlightedIndex,
              selectedItem
            }) => (
                <div
                  style={{ marginBottom: "18px", marginTop: "8px" }}
                  className="custom-category__select flex-col"
                >
                  <div
                    className="flex-col"
                    style={{
                      marginTop: "-10px",
                      lineHeight: "1.43",
                      letterSpacing: "0.2px",
                      fontSize: "14px",
                      maxHeight: "190px"
                    }}
                  >
                    {[...ADDITIONAL_CATEGORIES, ...DEFAULT_CATEGORIES]
                      .map(category => ({
                        value: category,
                        label: category
                      }))
                      .map(({ label, value }) => (
                        <div className="loco__radio loco__radio--left-align">
                          <input
                            name="file_category_selectors"
                            id={label}
                            type="radio"
                            checked={
                              this.state["fields"]["file_category"] === value
                            }
                            onChange={() =>
                              this.onFieldChange({
                                name: "file_category",
                                value: value
                              })
                            }
                          />
                          <label for={label}>{label}</label>
                        </div>
                      ))}
                    <div
                      className="loco__radio loco__radio--left-align"
                      style={{ border: "none", minHeight: "48px" }}
                    >
                      <input
                        name="file_category_selectors"
                        id="custom"
                        type="radio"
                        placeHolder="Enter custom"
                        ref={customRadioRef}
                        onFocus={e => {
                          customInputRef.current.focus();
                        }}
                      />
                      <label className="files_custom_label" for="custom">
                        <CustomInput
                          {...getInputProps()}
                          name="file_category"
                          id="file_category"
                          placeHolder="Enter custom"
                          value={
                            [
                              ...DEFAULT_CATEGORIES,
                              ...ADDITIONAL_CATEGORIES
                            ].includes(this.state["fields"]["file_category"])
                              ? ""
                              : this.state["fields"]["file_category"]
                          }
                          onChange={({ target: { value } }) => {
                            if (
                              [
                                ...ADDITIONAL_CATEGORIES,
                                ...DEFAULT_CATEGORIES
                              ].includes(value)
                            ) {
                              this.props.dispatch(
                                actions.flash({
                                  message:
                                    "Selected file category already exists"
                                })
                              );
                              if (
                                this._scrollRef &&
                                this._scrollRef.scrollIntoView
                              )
                                this._scrollRef.scrollIntoView();
                            }

                            this.onFieldChange({
                              name: "file_category",
                              value: value
                            });
                            customRadioRef.current.checked = true;
                          }}
                          onFocus={() => {
                            customRadioRef.current.checked = true;
                          }}
                          type="text"
                          ref={customInputRef}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              )}
          </Downshift>
          {/* {<div
              onClick={() => (this._scrollRef.scrollTop = 100)}
              style={{ marginTop: "6px", marginBottom: "1em" }}
            >
              <Creatable
                name="file_category"
                label="File Category"
                onChange={(e, val) => {
                  // console.log();
                  this.onFieldChange({ name: "file_category", value: e.value });
                }}
                options={[
                  { value: "Technical Pack", label: "Technical Pack" },
                  { value: "Fabric", label: "Fabric" },
                  { value: "Construction", label: "Construction" }
                ]}
              />
            </div>} */}
        </Form>
      </PerfectScrollbar>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({});
export default reduxForm({ form: formName }, mapStateToProps)(AddFilesForm);
