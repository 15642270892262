import React, { Component } from "react";
import {
  getSmallImageUrl,
  getCoverImagePlaceholder
} from "../../../helpers/DesignHelpers";

const DispatchQuantity = ({ data: { products = [] } = {} }) => {
  console.log("HEY THERE ITS FORM DISPATCH QUANTITY", products);
  return (
    <>
      <div
        style={{
          padding: "16px 48px",

          display: "flex",
          justifyContent: "space-between",
          alignItems: "items",
          backgroundColor: "#fff",
          borderBottom: "1px solid var(--fill-grey-light)"
        }}
      >
        <div style={{ width: "100px" }} />
        <div className="label__class" style={{ flex: 0.7 }}>
          Design
        </div>
        <div className="label__class" style={{ flex: 1 }}>
          Order Quantity
        </div>
        <div className="label__class" style={{ flex: 1 }}>
          Dispatch Quantity
        </div>
      </div>

      {products.map(product => (
        <div
          style={{
            padding: "16px 48px",

            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid var(--fill-grey-light)"
          }}
        >
          <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: "80px",
                height: "94px"
              }}
            >
              <img
                src={
                  product.design.coverImageUrl
                    ? getSmallImageUrl(product.design.coverImageUrl)
                    : getCoverImagePlaceholder(product.design).src
                }
                alt="Design"
                style={{
                  width: "100%"
                }}
              />
            </div>
            <div
              style={{
                paddingLeft: "20px",
                lineHeight: "24px"
              }}
            >
              <div className="value__class">{product.design.name}</div>
              <div className="label__class">
                {product.design.sourceDesignId}
              </div>
            </div>
          </div>
          <div style={{ flex: 1 }}>
            <div className="value__class">{product.quantity}</div>
          </div>
          <div style={{ flex: 1 }}>
            <div className="value__class">{product.dispatchQuantity}</div>
          </div>
        </div>
      ))}
    </>
  );
};

export default DispatchQuantity;
