import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";

import "./styles.scss";
import Button from "components/UI/Button/Button";
import { ReactComponent as TrashIcon } from "assets/images/materialLibrary/trash-large.svg";

import { MATERIAL_TYPE_HASH } from "constants/MaterialLibrary";

const DeleteMaterial = ({ closeModal, extraFields, setHeaderTitle, deleteMaterialAction }) => {
  useEffect(() => {
    setHeaderTitle("");
  }, {});

  return (
    <div className="delete-material-modal">
      <div className="alert-icon">
        <TrashIcon />
      </div>

      <div className="f-sz-14 content">
        <h3>Delete Item</h3>
        <p>
          The item and all it’s details will be permanently deleted. Are you
          sure you want to continue?
        </p>
      </div>
      <footer>
        <Button
          category="btn black-ghost medium"
          onClick={() => {
            closeModal();
          }}
        >
          CANCEL
        </Button>

        <Button
          category="btn red"
          onClick={() => {
            deleteMaterialAction()
          }}
        >
          DELETE
        </Button>
      </footer>
    </div>
  );
};

export default withRouter(DeleteMaterial);
