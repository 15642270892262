import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { Field } from "formik";
import * as Yup from "yup";
import isEmpty from "lodash/isEmpty";
import find from "lodash/find";

import {
  getSizesByDesignType,
  transformFactoriesToOptions
} from "helpers/DesignHelpers";
import {
  postNewSampleDetails,
  getSamplesByDesignId
} from "store/actions/DesignActions";
import { getAllFactoriesForQuotes } from "store/actions/FactoryActions";
import { closeModal } from "store/actions/GlobalActions";

import ProgressBar from "components/UI/ProgressSteps";
import DesignCard from "components/Designs/DesignCard/Facade";

import Form from "components/CustomBrand/UI/Formik/Form";
import Select from "components/CustomBrand/UI/Formik/Select";
import ReactSelect from "components/CustomBrand/UI/Formik/Select/ReactSelect";
import Input from "components/CustomBrand/UI/Formik/Input";
import DatePicker from "components/CustomBrand/UI/Formik/Datepicker";
import Button from "components/UI/Button/Button";

import { ReactComponent as RightArrow } from "assets/images/navigation/ic-chevron-right.svg";

import { allocateFactory } from "./services";

import "./styles.scss";
import { MAP_SAMPLE_TYPES } from "../../../../constants/Design";
import { flashError } from "../../../../store/actions/CommonActions";
import { getHostBrand } from "../../../../helpers/AuthHelper";


const SampleRequest = ({
  design,
  factories,
  sampleDetails,
  quoteRequestDetails,
  postNewSampleDetails,
  closeModal,
  getAllFactoriesForQuotes,
  getSamplesByDesignId,
  flashError,
  currentUser
}) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const { category, sub_category } = design;

  const [formValue, setFormValue] = useState({});
  const [validDueDate, setValidDueDate] = useState(false);

  useEffect(() => {
    getAllFactoriesForQuotes({
      designId: design.id,
    });
  }, []);

  const sizes = getSizesByDesignType(category, sub_category);

  const sampleForm = useRef();
  const factoryForm = useRef();

  let previousFactory = {};
  if (sampleDetails && sampleDetails.samples.length > 0) {
    const {
      factoryId,
      factoryName,
      lfrootAddressId
    } = sampleDetails.samples[0];
    previousFactory = {
      factoryId,
      factoryName,
      lfrootAddressId
    };
  } else if (quoteRequestDetails) {
    const factoryDetails = find(factories, { id: design.factory_assigned });
    if (factoryDetails) {
      previousFactory = {
        factoryId: factoryDetails.id,
        factoryName: factoryDetails.name,
        lfrootAddressId: factoryDetails.addresses[0].designs
      };
    }
    const defaultSize = {};
    if (category === 'Men' && sub_category !== 'Plus size') {
      defaultSize.sampleSize = { label: 'M', value: 'M' };
    } else if (category === 'Women' && sub_category !== 'Plus size') {
      defaultSize.sampleSize = { label: 'S', value: 'S' };
    }
  }


  const defaultSize = {};
  const customBrand = getHostBrand();

  if (customBrand && customBrand.isCampusSutra) {
    if (category === 'Men' && sub_category !== 'Plus size') {
      defaultSize.sampleSize = [{ label: 'M', value: 'M' }];
      if (formValue.sampleSize == null)
        formValue.sampleSize = 'M';

    } else if (category === 'Women' && sub_category !== 'Plus size') {
      defaultSize.sampleSize = [{ label: 'S', value: 'S' }];
      if (formValue.sampleSize == null)
        formValue.sampleSize = 'S';
    }
  }

  return (
    <React.Fragment>
      <section className="sample_request">
        <ProgressBar
          activeIndex={activeIndex}
          handleClick={setActiveIndex}
          panels={[
            {
              heading: "Sample details",
              content: (
                <Form
                  validationSchema={Yup.object().shape({
                    sampleType: Yup.string()
                      .typeError("Sample type must be a string")
                      .required("Required"),
                    sampleSize: Yup.string()
                      .typeError("Sample size must be a string")
                      .required("Required")
                  })}
                  innerRef={sampleForm}
                  initialValues={{
                    sampleType: MAP_SAMPLE_TYPES.prototype,
                    sampleSize: formValue ? formValue.sampleSize : null
                  }}
                >
                  {({
                    changeFormValue,
                    validateForm,
                    setFieldValue,
                    ...formProps
                  }) => {
                    return (
                      <div className="sample_request__wrapper">
                        <div className="input_grp custom">
                          <label>Sample type</label>
                          <div className="static-name">
                            {MAP_SAMPLE_TYPES.prototype}
                          </div>
                        </div>
                        <div className="input_grp">
                          <Field
                            component={ReactSelect}
                            name="sampleSize"
                            type="select"
                            label="Select size"
                            placeholder="Select sample size"
                            options={sizes}
                            required
                            limit={1}
                            onChange={val => {
                              setFormValue({ sampleSize: val.value });
                              setFieldValue("sampleSize", val.value);
                            }}
                            defaultValue={
                              formValue
                                ? sizes.find(
                                    size => size.value === formValue.sampleSize
                                  )
                                : defaultSize.sampleSize
                            }
                          />
                        </div>
                      </div>
                    );
                  }}
                </Form>
              ),
              disabled: false
            },
            {
              heading: "Due date",
              content: (
                <div>
                  <Form
                    validationSchema={Yup.object().shape({
                      orderQuantity: Yup.string().typeError(
                        "Quantity cannot be empty"
                      ),
                      dueDate: Yup.date()
                        .min(Date())
                        .required("Required"),
                      factoryId: Yup.number().required("Required")
                    })}
                    innerRef={factoryForm}
                    initialValues={
                      previousFactory
                        ? {
                            factoryId: previousFactory.factoryId
                          }
                        : null
                    }
                  >
                    {({ changeFormValue, validateForm, ...formProps }) => {
                      if (
                        !validDueDate &&
                        formProps.dirty &&
                        formProps.isValid
                      ) {
                        setValidDueDate(formProps.values.dueDate);
                      }
                      return (
                        <React.Fragment>
                          <div>
                            <div className="sample_request__wrapper">
                              {isEmpty(previousFactory) ? (
                                <div className="input_grp">
                                  <Field
                                    component={Select}
                                    name="factory"
                                    type="select"
                                    label="Select factory"
                                    placeholder="Select factory"
                                    options={transformFactoriesToOptions(
                                      factories
                                    )}
                                    required
                                  />
                                </div>
                              ) : (
                                <div className="input_grp">
                                  <label>Factory</label>
                                  <div className="static-name">
                                    {previousFactory.factoryName}
                                  </div>
                                </div>
                              )}

                              {formValue.sampleType ===
                                "Preproduction Sample" && (
                                <div className="input_grp">
                                  <Field
                                    component={Input}
                                    name="orderQuantity"
                                    type="text"
                                    label="Order quantity"
                                    placeholder="Enter order quantity"
                                    required
                                  />
                                </div>
                              )}
                              <div className="input_grp">
                                <Field
                                  component={DatePicker}
                                  className="v2"
                                  name="dueDate"
                                  type="date"
                                  label="Due date"
                                  placeholder="Select date"
                                  required
                                  inline
                                  customInput={({ value, onClick }) => (
                                    <div
                                      className="custom_datepicker formik_datepicker"
                                      type="date"
                                      onClick={onClick}
                                    >
                                      {value || (
                                        <span className="f-color-faded">
                                          Select date
                                        </span>
                                      )}
                                    </div>
                                  )}
                                  defaultValue={validDueDate}
                                />
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      );
                    }}
                  </Form>
                </div>
              ),
              disabled: Object.keys(formValue).length === 0
            }
          ]}
        />
        <DesignCard card={design} />
      </section>
      <div className="modal__footer flex f-sz-m f-w-bold footer">
        {activeIndex === 0 ? (
          <React.Fragment>
            <Button
              category="btn primary-blue"
              className="flex center"
              onClick={() => {
                sampleForm.current.validateForm().then(errors => {
                  if (isEmpty(errors)) {
                    setFormValue(sampleForm.current.values);
                    setActiveIndex(1);
                  } else {
                    return;
                  }
                });
              }}
              disabled={isEmpty(formValue)}
            >
              Next <RightArrow />
            </Button>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Button
              category="btn primary-blue"
              onClick={() => {
                if (!factoryForm.current.values.dueDate) {
                  flashError("Invalid due date");
                  return;
                }

                if (formValue.sampleType === MAP_SAMPLE_TYPES.preProduction) {
                  const nOrderQuantity = +factoryForm.current.values
                    .orderQuantity;
                  if (nOrderQuantity <= 0) {
                    flashError("Invalid order quantity");
                    return;
                  }
                }

                const {
                  brand: brandId,
                  collection_id: collectionId,
                  id: designId,
                  name: designName
                } = design;
                const details = {
                  collectionId,
                  designId,
                  brandId,
                  designName,
                  sampleRootId: sampleDetails.id,
                  ...formValue,
                  dueDate: factoryForm.current.values.dueDate,
                  factoryId: previousFactory.factoryId,
                  factoryName: previousFactory.factoryName,
                  skipSampling: 0,
                  isProductionSample: 0
                };

                try {
                  postNewSampleDetails(details).then(sampleData => {
                    allocateFactory(
                      { ...sampleData, ...formValue },
                      factoryForm.current.values,
                      { brandId, collectionId },
                      factories
                    );
                    getSamplesByDesignId({ designId });
                    closeModal();
                  });
                } catch (err) {
                  console.log(err);
                }
              }}
              disabled={!validDueDate}
            >
              REQUEST SAMPLE
            </Button>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  factories: state.factory.factoriesForQuotes,
  currentUser: state.auth.userDetails
});

const mapDispatchToProps = dispatch => ({
  postNewSampleDetails: details => dispatch(postNewSampleDetails(details)),
  getAllFactoriesForQuotes: details =>
    dispatch(getAllFactoriesForQuotes(details)),
  getSamplesByDesignId: details => dispatch(getSamplesByDesignId(details)),
  closeModal: () => dispatch(closeModal()),
  flashError: message => dispatch(flashError(message))
});

export default connect(mapStateToProps, mapDispatchToProps)(SampleRequest);
