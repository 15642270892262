import React from 'react';
import PropTypes from 'prop-types';
import ProgressSteps from '../../UI/ProgressSteps';
import SelectBrand from './SelectBrand/index';
import SelectFactory from './SelectFactory/index';
import SelectDesigns from './SelectDesigns/index';
import DeliveryPODetail from './DeliveryPODetail/index';
import './style.scss';
import { ORG_TYPES } from '../../../constants/Global';
import OrderSuccess from './OrderSuccess';

export default class CreateOrder extends React.Component {
  static propTypes = {
    brands: PropTypes.array,
    brandFactories: PropTypes.object,
    brandAddresses: PropTypes.object,
    currentUser: PropTypes.object,
    factoryDesigns: PropTypes.object,
    currency: PropTypes.string.isRequired,
    getBrands: PropTypes.func.isRequired,
    getFactories: PropTypes.func.isRequired,
    getDesigns: PropTypes.func.isRequired,
    getAddresses: PropTypes.func.isRequired,
    createOrder: PropTypes.func.isRequired,
    openModal: PropTypes.func,
  }

  state = {
    progressStepIndex: 0,
    mapFactoryDesigns: {},
    selected: {},
  };

  constructor(props) {
    super(props);

    if (props.currentUser.isSuperAdmin) {
      props.getBrands({ limit: 1000 });
    } else {
      props.getFactories({
        brandId: props.currentUser.brandId,
        limit: 1000,
      });
    }
  }

  _getBrandId = () => {
    const { currentUser } = this.props;
    const { selected } = this.state;

    return (selected.brand && selected.brand.id) || currentUser.brandId;
  }

  _getDesignsInFactory = (factoryId, limit) => {
    const { getDesigns, factoryDesigns } = this.props;
    const brandId = this._getBrandId();

    if (!factoryDesigns[factoryId] || factoryDesigns[factoryId].paginate.limit !== limit) {
      getDesigns({ brandId, factoryId, limit });
    }
  }

  _showOrgView = (orgType) => {
    const { currentUser } = this.props;

    if (orgType === ORG_TYPES.brand) {
      this.setState({ progressStepIndex: 0 });
    } else {
      this.setState({ progressStepIndex: currentUser.isSuperAdmin ? 1 : 0 });
    }
  }

  _handleProgressStepClick = (index) => {
    this.setState({ progressStepIndex: index });
  }

  _handleOrgChange = (orgType, showModal = true) => {
    const { openModal } = this.props;

    if (!showModal) {
      this._showOrgView(orgType);
      return;
    }

    openModal("CREATE_ORDER_CHANGE_ORG", {
      orgType: orgType,
      onSuccess: () => this._showOrgView(orgType),
    });
  }

  _handleBrandSubmit = (result) => {
    if (!result || !result.brand || !result.brand.id) {
      // TODO: show some error msg
      return;
    }

    const { getFactories } = this.props;
    const { progressStepIndex } = this.state;

    this.setState({
      selected: {
        brand: result.brand,
      },
      progressStepIndex: progressStepIndex + 1,
    });

    getFactories({ brandId: result.brand.id, limit: 1000 });
  }

  _handleFactorySubmit = (result) => {
    if (!result || !result.factory || !result.factory.id) {
      // TODO: show some error msg
      return;
    }

    const { selected, progressStepIndex } = this.state;

    this.setState({
      selected: {
        brand: selected.brand,
        factory: result.factory,
      },
      progressStepIndex: progressStepIndex + 1,
    });

    this._getDesignsInFactory(result.factory.id, 1000);
  }

  _handleDesignsSubmit = (result) => {
    if (!result || !result.designs || !result.poValue) {
      // TODO: show some error msg
      return;
    }

    const { getAddresses } = this.props;
    const { selected, progressStepIndex } = this.state;

    this.setState({
      selected: {
        brand: selected.brand,
        factory: selected.factory,
        designs: result.designs,
        poValue: result.poValue,
        totalQuantity: result.totalQuantity,
      },
      progressStepIndex: progressStepIndex + 1,
    });

    getAddresses({ brandId: this._getBrandId(), limit: 1000 });
  }

  _handleDeliveryAndPOSubmit = (result) => {
    const { createOrder, currentUser } = this.props;
    const { selected } = this.state;

    this.setState({
      selected: {
        ...selected,
        address: result.address,
        poNumber: result.poNumber,
        poFile: result.poFile,
        deadline: result.deadline,
      },
    });

    const data = {
      brandId: this._getBrandId(),
      addressId: result.address.id,
      designs: selected.designs.map(({ id, quantity, price }) => ({ designId: id, quantity, quoteAmount: price })),
      poNumber: result.poNumber,
      deadline: result.deadline,
      totalQuantity: selected.totalQuantity,
      brandRepName: currentUser.name,
      poValue: selected.poValue,
      poFile: result.poFile,
    };

    createOrder(data)
      .then(({ id }) => {
        this.setState({ isOrderCreated: true });
      });
  }

  _handleAddNewAddress = () => {
    const { openModal } = this.props;

    openModal("ORDER_ADD_ADDRESS");
  }

  _renderProgressSteps() {
    const {
      currentUser,
      brands,
      brandFactories,
      brandAddresses,
      currency,
      factoryDesigns,
      onOrgChange,
    } = this.props;
    const { progressStepIndex, selected } = this.state;
    const factories = brandFactories[this._getBrandId()] || [];
    const addresses = brandAddresses[this._getBrandId()] || [];
    const isDeliveryPOStep = currentUser.isSuperAdmin ? progressStepIndex === 3 : progressStepIndex === 2;

    return <ProgressSteps
      activeIndex={progressStepIndex}
      handleClick={this._handleProgressStepClick}
      panels={[
        ...(currentUser.isSuperAdmin
          ? [{
            heading: "Select brand",
            content: <SelectBrand
              currency={currency}
              brands={brands}
              onOrgChange={onOrgChange}
              onSubmit={this._handleBrandSubmit}
              value={selected.brand}
            />,
            disabled: true,
          }]
          : []),
        {
          heading: "Select factory",
          content: <SelectFactory
            currency={currency}
            brand={selected.brand}
            factories={factories}
            factoryDesigns={factoryDesigns}
            getDesigns={(factoryId) => this._getDesignsInFactory(factoryId, 5)}
            onOrgChange={this._handleOrgChange}
            onSubmit={this._handleFactorySubmit}
            value={selected.factory}
          />,
          disabled: true,
        },
        {
          heading: "Select designs",
          content: <SelectDesigns
            currency={currency}
            brand={selected.brand}
            factory={selected.factory}
            designs={selected.factory && factoryDesigns[selected.factory.id].rows}
            onOrgChange={this._handleOrgChange}
            onSubmit={this._handleDesignsSubmit}
            value={selected.designs}
            poValue={selected.poValue}
          />,
          disabled: !isDeliveryPOStep,
        },
        {
          heading: "Delivery & PO Details",
          content: <DeliveryPODetail
            currency={currency}
            brand={selected.brand}
            factory={selected.factory}
            designs={selected.designs}
            poValue={selected.poValue}
            addresses={addresses}
            onAddNewAddress={this._handleAddNewAddress}
            onOrgChange={this._handleOrgChange}
            onSubmit={this._handleDeliveryAndPOSubmit}
          />,
          disabled: true,
        },
      ]}
    />
  }

  _renderSuccess() {
    const { currency } = this.props;
    const { selected } = this.state;

    return <OrderSuccess
      designs={selected.designs}
      factory={selected.factory}
      address={selected.address}
      totalQuantity={selected.totalQuantity}
      poValue={selected.poValue}
      currency={currency}
    />;
  }

  render() {
    const { isOrderCreated } = this.state;

    return <div className="create-order">
      <div className="create-order__progress-steps">
        {
          isOrderCreated
            ? this._renderSuccess()
            : this._renderProgressSteps()
        }
      </div>
    </div>;
  }
}
