import React, { useRef } from "react";
import { getFileData } from "helpers/swatchHelper";

import Trash from "assets/images/action/ic-delete.faded.svg";
import { ReactComponent as AttachmentIcon } from "assets/images/editor/ic-attach-file.svg";

import './styles.scss';

const anyChange = (prevProps, nextProps) => {
  if (nextProps.input.value) {
    return prevProps.input.value === nextProps.input.value;
  }
  return false;
};

const File = React.memo(
  ({
    input: { value, onChange },
    meta: { submitFailed, invalid },
    required,
    handleClick,
    ...restProps
  }) => {
    const fileInputRef = useRef(null);

    const handleChange = e => {
      onChange(e.target.files[0]);
      handleClick(e.target.files[0]);
    };

    const deleteFile = () => {
      fileInputRef.current.value = "";
      onChange("");
      handleClick(null);
    };

    const [containerBg, fileName] = getFileData(value);

    return (
      <div className="file-with-preview">
        <input
          type="file"
          accept=".jpg, .png, .jpeg, .pdf, .doc, .docx, .xls, .xlsx"
          onChange={handleChange}
          ref={fileInputRef}
          className={"hide"}
        />
        {value !== "" ? (
          <div className="input-file__meta-selected">
            <span>{`${fileName.slice(0, 30)}...`}</span>
            <img src={Trash} alt="" onClick={deleteFile} />
          </div>
        ) : (
          <div
            className="input-file__upload_image"
            onClick={() => fileInputRef.current.click()}
          >
            <div>Select file</div>
            <AttachmentIcon />
          </div>
        )}
      </div>
    );
  },
  anyChange
);

export default File;
