import React, { useEffect, useState } from "react";
import find from "lodash/find";
import axios from "axios";

import SampleFiles from "../../../SamplingDetails/SampleFiles";
import Button from "../../../../UI/Button/Button";
import { PRODUCTION_SAMPLE_STATUSES } from "constants/Sample";
import { transformHistory } from "helpers/samplingHelper";
import { toSentenceCase } from "helpers/DesignHelpers";
import "./TnaDetails.scss";
import { get_service_endpoint } from "ServiceEndpoints";
import { TextSpliced } from "../../../../UI/TextSpliced";

const ep = get_service_endpoint("apiv2");

const TnaDetails = ({
  data,
  stageId,
  inProgressStage,
  isComplete,
  design,
  sampleDetails,
  showCta,
  activeCategory,
  addNewCommint,
  openVersionHistoryModal,
  openSampleStatusUpdateModal,
  fetchTna,
  comments = [],
  designId,
  sampleHistory: designSampleHistory,
}) => {
  let tnaRowClass =
    stageId === 3 && showCta
      ? "tna-details-row row-with-cta"
      : "tna-details-row";

  const [sampleHistory, setSampleHistory] = useState([]);
  const [productionSample, setProductionSample] = useState({});
  const [sampleSize, setSampleSize] = useState();

  useEffect(() => {
    const _prodSample = find(sampleDetails.samples, { isProductionSample: 1 });

    if (_prodSample) {
      setProductionSample(_prodSample);
      setSampleSize(_prodSample.sampleSize);
    }
  }, [sampleDetails]);

  useEffect(() => {
    if (designSampleHistory && designSampleHistory[productionSample.sampleType]) {
      const newSampleHistory =
        /**  Signature: samplingHistory, sampleType, comments, sampleDispatch*/
        transformHistory(
          designSampleHistory,
          productionSample.sampleType,
          comments,
          productionSample.sampleDispatch
        );

        // set sample history
        setSampleHistory(newSampleHistory);
    }
  }, [designSampleHistory, productionSample]);

  return (
    <div className="tna-details">
      {data &&
        data.map((item, index) => {
          let timelineClass = isComplete
            ? stageId === inProgressStage
              ? item.timelineClass
              : "gray"
            : stageId === inProgressStage || stageId === inProgressStage - 1
              ? item.timelineClass
              : "gray";

          let timelineStatus =
            index !== 0 && data[index - 1].timelineStatus === "In progress"
              ? ""
              : item.timelineStatus;
          if (!isComplete && timelineStatus === "") {
            tnaRowClass = `${tnaRowClass} inactive`;
          }

          return (
            <>
              {item.timelineDate.length !== 0 ? (
                <div className={tnaRowClass} key={index}>
                  <div className="tna-details-row__heading">
                    <div>{toSentenceCase(item.subCategory)}</div>
                    {stageId === 3 && index === 0 && (
                      <div className="pp-fields dark-gray sizes">
                        <TextSpliced text={`Size: ${sampleSize}`} />
                      </div>
                    )}
                  </div>
                  <div className="tna-details-row__date">
                    <div>{item.timelineDate}</div>
                    {stageId === 3 && index === 0 && (
                      <div
                        className="pp-fields blue"
                        onClick={() => openVersionHistoryModal(sampleHistory)}
                      >
                        View History
                      </div>
                    )}
                  </div>
                  <div className={"tna-details-row__status " + timelineClass}>
                    {timelineStatus}
                  </div>
                  <div className="tna-details-row__attachment">
                    <SampleFiles files={item.files} designName={item.subCategory} />
                  </div>

                  {showCta &&
                    item.subCategory &&
                    (item.subCategory.includes(" dispatched") ||
                      item.subCategory.includes(" resubmission")) && (
                      <div className="tna-details-row__cta">
                        <Button
                          onClick={() => {
                            openSampleStatusUpdateModal({
                              commitMsg: false,
                              request: productionSample,
                              sampleStatusTypes: PRODUCTION_SAMPLE_STATUSES,
                              cardData: design,
                              designId: design.id,
                              addNewCommint,
                              withComment: true,
                              update: fetchTna
                            });
                          }}
                          category="blue-bg"
                        >
                          Update Status
                        </Button>
                      </div>
                    )}
                </div>
              ) : null}
            </>
          );
        })}
    </div>
  );
};

export default TnaDetails;
