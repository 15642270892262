import React, { useState, useEffect } from "react";

import Masonry from "react-masonry-component";
import SwatchCard from "./SwatchCard";
import InfoButton from "../UI/InfoButton/InfoButton";
import emptySwatchLibIcon from "../../assets/images/emptyPages/ic_empty_swatchlib.svg";

import BottomBar from "../UI/BottomBar";

import find from "lodash/find";

import Button from "../UI/Button/Button";

const masonryOptions = { gutter: 24, horizontalOrder: false };

const SwatchGrid = ({
  onCardSelect,
  openModal,
  brandid,
  isFactory,
  isSuperAdmin,
  deleteSwatch,
  cardList = [],
  match,
  loadNext,
  loadPrevious,
  onlyAddToDesign = false,
  details = {},
  paginate,
  reloadPage,
}) => {
  const [swatchCardSelection, changeSwatchCardSelection] = useState([]);

  const clearSelectionHandler = () => changeSwatchCardSelection([]);

  // send details as you get params from files ==
  const openModalHandler = key => {
    openModal(key, {
      swatches: swatchCardSelection,
      clearState: clearSelectionHandler,
      brandid,
      isFactory,
      onlyAddToDesign,
      isSuperAdmin,
      details
    });
  };

  if (cardList.length === 0) {
    return (
      <div
        className="f-sz-xxl flex"
        style={{
          fontSize: "30px",
          margin: "auto",
          height: "100%",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <InfoButton
          icon={<img src={emptySwatchLibIcon} alt="Empty Swatch Library" />}
          title={"No Swatches Found"}
        >
          <div>Hello world </div>
        </InfoButton>
      </div>
    );
  }

  const swatchDOMNode = document.getElementById("swatch-library-container");
  if (swatchDOMNode) {
    if (swatchCardSelection.length > 0) {
      swatchDOMNode.style.paddingBottom = '70px';
    } else {
      swatchDOMNode.style.paddingBottom = '0px';
    }
  }

  return (
    <>
      <div>
        <Masonry
          options={masonryOptions}
          style={{
            position: "relative"
            // paddingBottom: swatchCardSelection.length ? "50px" : ""
          }}
        >
          {cardList.map(sw => (
            <div key={sw.id}>
              <SwatchCard
                key={sw.id}
                isSelected={find(swatchCardSelection, { id: sw.id })}
                onSelect={selected =>
                  selected
                    ? changeSwatchCardSelection([...swatchCardSelection, sw])
                    : changeSwatchCardSelection(
                      swatchCardSelection.filter(i => i.id !== sw.id)
                    )
                }
                img={sw.image}
                openModal={openModal}
                swatchId={sw.id}
                displayId={sw.swatchId}
                brandid={brandid}
                isFactory={isFactory}
                onlyAddToDesign={onlyAddToDesign}
                details={details}
                isSuperAdmin={isSuperAdmin}
                deleteSwatch={deleteSwatch}
                colors={sw.colors}
              >
                {`${sw.name}`}
                {/* HIDE ID ONE -${sw.id} */}
              </SwatchCard>
            </div>
          ))}
        </Masonry>

        <div className="swatch-pagination-container">
          <div className="swatch-pagination-left">
            <p>{paginate.totalRecords} swatches</p>
          </div>

          <div className="swatch-pagination-right">
            <p>
              Showing {paginate.currentPageOffSet + 1} -{" "}
              {paginate.currentPageOffSet + cardList.length}
            </p>
            <div className="swatch-pagination-options">
              <button
                className="f-w-bold"
                onClick={loadPrevious}
                disabled={paginate.prevPageOffSet === null}
                className={`swatch-pagination ${
                  paginate.prevPageOffSet === null
                    ? "swatch-pagination--disabled"
                    : ""
                  }`}
              >
                Previous
              </button>
              <button
                className="f-w-bold"
                onClick={loadNext}
                disabled={!paginate.nextPageOffSet}
                className={`swatch-pagination ${
                  !paginate.nextPageOffSet ? "swatch-pagination--disabled" : ""
                  }`}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>

      {swatchCardSelection.length > 0 ? (
        <BottomBar
          count={swatchCardSelection.length}
          onClear={clearSelectionHandler}
          openModal={openModalHandler}
          isFactory={isFactory}
          onlyAddToDesign={onlyAddToDesign}
          download={true}
          swatches={swatchCardSelection}
          modalKeys={{
            shareKey: "SWATCH_LIBRARY_SHARE_WITH_BRAND_DESIGNS",
            addKey: "SWATCH_LIBRARY_ADD_SWATCH_TO_DESIGN",
            shareContactsKey: "SWATCH_LIBRARY_SHARE_WITH_CONTACTS"
          }}
        />
      ) : null}

    </>
  );
};

export default SwatchGrid;
