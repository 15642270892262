import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import Header from "../UI/Header";
import Table from "../UI/Table";
import FilterContainer from "./../UI/Filters";
import { DatePicker } from "./../UI/Filters/DateRangePicker";
import MultiSelect from "./../UI/Filters/MultiSelect";
import { getActiveFactories } from "store/actions/ReportsAction";
import Loader from "components/UI/InlineLoader";

const LIMIT = 20;
const HEADINGS = [
  "FACTORY",
  "NO. OF DESIGNS",
  "IN  QUOTE",
  "IN  SAMPLING",
  "IN  PRODUCTION",
  "PRODUCTION QTY.",
  "ACTIVE SINCE",
  "LAST ACTIVE ON"
];

const TOOLTIP_DATA = {
  "PRODUCTION QTY.": `<div class="subtext">The sum-total of quantities of all the styles under production</div>`,
  "ACTIVE SINCE": `<div class="subtext">The time when the factory user took their first action on the product</div>`,
  "LAST ACTIVE ON": `<div class="subtext">The time when the factory user took their last action on the product</div>`
};

const ActiveFactories = ({
  getActiveFactories,
  activeFactories = { list: [] },
  loading
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [filters, setFilters] = useState({});
  const [dates, setDates] = useState({});

  useEffect(() => {
    getActiveFactories({ isInit: 1 });
  }, []);

  const paginationHandler = values => {
    if (
      values.offset &&
      activeFactories.list.length <= (currentIndex + 1) * LIMIT
    ) {
      setCurrentIndex(currentIndex + 1);
      getActiveFactories({
        offset: activeFactories.pagination.nextOffset,
        ...filters
      });
    } else {
      if (values.offset || (!values.offset && values.isnext === 1)) {
        setCurrentIndex(currentIndex + 1);
      } else if (values.isnext === 0) {
        setCurrentIndex(currentIndex - 1);
      }
    }
  };

  if (loading) return <Loader />;

  const getOverallReport = () => {
    return getActiveFactories({
      ...filters,
      limit: activeFactories.pagination.totalCount,
      skipStore: true,
      inlineLoader: false
    }).then(data => {
      return data.records;
    });
  };

  return (
    <div className="report-content">
      <Header
        heading="Active Factories"
        reportType="activeFactories"
        pagination={activeFactories.pagination}
        currentIndex={currentIndex}
        paginationHandler={paginationHandler}
        getOverallReport={getOverallReport}
        headings={HEADINGS}
      />
      <FilterContainer
        updateFilters={() => {
          getActiveFactories({
            ...filters,
            ...dates,
            isInit: 1
          });
          setCurrentIndex(0);
        }}
      >
        <DatePicker
          label="Date Range"
          updateFilterDates={dates => setDates(dates)}
          selectedDates={dates}
        />
        <MultiSelect
          label="Factory"
          filterOptions={activeFactories.filters.factories}
          onChange={ids => {
            setFilters({ ...filters, factoryIds: ids ? ids.join() : null });
          }}
          selectedFilters={filters.factoryIds}
        />
      </FilterContainer>
      <Table
        headings={HEADINGS}
        tooltipData={TOOLTIP_DATA}
        data={activeFactories.list.slice(
          currentIndex * LIMIT,
          currentIndex * LIMIT + LIMIT
        )}
        gridTemplateColumns="
    minmax(200px, 1.66fr)
    minmax(140px, 1.2fr)
    minmax(120px, 1fr)
    minmax(120px, 1fr)
    minmax(120px, 1fr)
    minmax(150px, 1.4fr)
    minmax(130px, 1.2fr)
    minmax(140px, 1.3fr)
    "
      />
    </div>
  );
};

const mapStateToProps = state => ({
  activeFactories: state.reports.activeFactories,
  loading: state.reports.loading,
  userDetails: state.auth.userDetails
});

export default connect(mapStateToProps, {
  getActiveFactories
})(ActiveFactories);
