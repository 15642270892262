import { handleActions } from "redux-actions";

const INITIAL_STATE = {
  quotes: {
    rows: [],
    paginate: {},
  },
  mapStatusCount: {},
  factories: [],
};

const Orders = handleActions({
  QUOTES_GET_ALL_QUOTES: (state, action) => {
    const { rows, paginate, refresh } = action.payload;
    const updatedPaginate = {
      ...paginate,
      numberOfRecords: (rows && rows.length) || 0,
    }

    return {
      ...state,
      quotes: (refresh
        ? {
          rows,
          paginate: updatedPaginate,
        }
        : {
          rows: [...state.quotes.rows, ...rows],
          paginate: updatedPaginate,
        }),
    };
  },
  QUOTES_GET_COUNT: (state, action) => {
    return {
      ...state,
      mapStatusCount: action.payload,
    };
  },
  QUOTES_FACTORIES: (state, action) => {
    return {
      ...state,
      factories: action.payload,
    };
  },
}, INITIAL_STATE);

export default Orders;
