import React, { useMemo } from "react";
import { PropTypes } from "prop-types";

import "./style.scss";
import Tabs, { TabPanel } from "../../../UI/Tabs/index";
import { groupBy } from "lodash";

const DesignSummaryDetails = ({
  design: {
    name,
    design_id,
    category,
    sub_category,
    description,
    additional_fields
  },
  currentUser
}) => {
  const formattedAdditionalFields = useMemo(() => {
    const grouped = groupBy(additional_fields, "category");
    return grouped;
  }, [additional_fields]);

  const renderSummaryInfo = () => {
    return (
      <div className="summary-container__info">
        <div className="summary-container__row">
          <div className="label">Design name</div>
          <div className="value">{name}</div>
        </div>
        <div className="summary-container__row">
          <div className="label">Design ID</div>
          <div className="value">{design_id}</div>
        </div>
        <div className="summary-container__row">
          <div className="label">Category</div>
          <div className="category value">
            <div>
              {category} - {sub_category}
            </div>
          </div>
        </div>
        <div className="summary-container__row">
          <div className="label">Description</div>
          <div className="value">{description ? description : "NA"}</div>
        </div>
      </div>
    );
  };

  const renderAdditionalFields = () => {
    return (
      <div>
        <div className="summary-container__info summary-container__info--col-2">
          {formattedAdditionalFields["Additional details"].map(field =>
            currentUser.isFactory && field.name === "MRP" ? null : (
              <div className="summary-container__row" key={field.id}>
                <div className="label">{field.name}</div>
                <div className="value value--lines">
                  <span className="value__line-1">{field.value || "--"}</span>
                  {field.name === "MRP" && (
                    <span className="value__line-2">
                      not visible to the factory
                    </span>
                  )}
                </div>
              </div>
            )
          )}
        </div>
        <div className='summary-container__title'>Fabric details</div>
        <div className="summary-container__info summary-container__info--col-2 row-first">
          {formattedAdditionalFields["Fabric details"].map(field => (
            <div className="summary-container__row" key={field.id}>
              <div className="label">{field.name}</div>
              <div className="value value--lines">
                <span className="value__line-1">{field.value || "--"}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="summary-container">
      {!additional_fields || additional_fields.length <= 0 ? (
        renderSummaryInfo()
      ) : (
        <Tabs defaultActiveTabIndex={0}>
          <TabPanel header="Summary">{renderSummaryInfo()}</TabPanel>
          <TabPanel header="Additional details">
            {renderAdditionalFields()}
          </TabPanel>
        </Tabs>
      )}
    </div>
  );
};

DesignSummaryDetails.propTypes = {
  design: PropTypes.object.isRequired
};

export default DesignSummaryDetails;
