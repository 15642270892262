import React from "react";
import PropTypes from "prop-types";

import {
  getCoverImagePlaceholder,
  getSmallImageUrl
} from "helpers/DesignHelpers";

const DesignCard = ({ card }) => {
  const statusString = (card.statusString || '').toLowerCase();
  let imgAttributes = {
    src: card.image,
    className: "design-card__img design-card__img--placeholder"
  };

  if (!card.image) {
    if (card.files) {
      const cover_img = Object.values(card.files).find(file => file.is_cover);
      if (cover_img) {
        imgAttributes = { src: getSmallImageUrl(cover_img.fileloc) };
      } else {
        imgAttributes = {
          ...getCoverImagePlaceholder(card),
          className: "design-card__img design-card__img--placeholder"
        };
      }
    }
  }

  return (
    <div className="design-card">
      <div className="design-card__cover">
        <img
          className="design-card__img"
          {...imgAttributes}
          alt="Design card"
        />
      </div>
      <div className="design-card__caption flex f-w-semi-bold">
        <span
          className={`design-card__status ${statusString}`}
        >
          {statusString}
        </span>
        <span className="design-card__name">{card.name}</span>
      </div>
    </div>
  );
};

DesignCard.propTypes = { card: PropTypes.object };

export default DesignCard;
