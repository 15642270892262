import React, { Component } from "react";
import Form from "../../components/UI/Form/Form";
import PerfectScollbar from "react-perfect-scrollbar";
import { TIMESTAMP_FORMAT } from "../../constants/Date";
import moment from "moment";

export default class NotificationSentUserList extends Component {
  state = {
    selectedUserIndex: []
  };
  checkboxRefs = {};

  render() {
    return (
      <Form {...this.props} form="NotificationSentUserList">
        {({ changeFormValue, ...props }) => {
          return (
            <div style={{ width: "600px", maxHeight: "600px" }}>
              <div
                className="f-w-semi-bold flex"
                style={{
                  lineHeight: "1.43",
                  height: "48px",
                  padding: "14px 2em",
                  borderBottom: "1px solid var(--fill-grey-light3)",
                  fontSize: "15px"
                }}
              >
                <div style={{ flexBasis: "60%" }}>To</div>
                <div>Sent at</div>
              </div>
              <PerfectScollbar
                option={{
                  suppressScrollX: true
                }}
                style={{ maxHeight: "300px", height: "300px" }}
              >
                {props.emailList.map((user, index) => (
                  <div
                    className="flex"
                    style={{
                      padding: "20px 2em",
                      height: "60px",
                      // justifyContent: "space-between",
                      alignItems: "center"
                    }}
                  >
                    <div
                      className="flex-col"
                      style={{
                        cursor: "pointer",
                        userSelect: "none",
                        flexBasis: "60%"
                      }}
                    >
                      <div className="notif-user-list__user-name f-sz-l f-w-semi-bold">
                        {user.split(":")[1]}
                      </div>
                      <div style={{ alignSelf: "end" }} className="notif-user-list__user-email f-sz-m">
                        {user.split(":")[0]}
                      </div>
                    </div>
                    <div>{moment(props.created_on).format(TIMESTAMP_FORMAT)}</div>
                  </div>
                ))}
              </PerfectScollbar>
            </div>
          );
        }}
      </Form>
    );
  }
}
