import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DesignAvatar from '../DesignAvatar';
import './style.scss';

const DesignAvatarList = ({
  designs = [],
  size = '24px',
  count = 0,
}) => {
  const [slicedDesigns, setSlicedDesigns] = useState([]);

  useEffect(() => {
    if (Array.isArray(designs)) {
      if (count > 0) {
        setSlicedDesigns(designs.slice(0, count));
      } else {
        setSlicedDesigns(designs);
      }
    }
  }, [designs, count]);

  return <div
    className="design-avatar-list"
  >
    {slicedDesigns.map((design, ind) => <DesignAvatar
      key={design.id}
      design={design}
      size={size}
      style={{ zIndex: (slicedDesigns.length - ind) }}
    />)}
  </div>
};

DesignAvatarList.propTypes = {
  designs: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    category: PropTypes.string,
    coverPic: PropTypes.string,
    coverImageUrl: PropTypes.string,
  })),
  size: PropTypes.string,
  count: PropTypes.number,
};

export default DesignAvatarList;
