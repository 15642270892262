import React, { Component } from "react";
import merge from "lodash/merge";
import isEmpty from "lodash/isEmpty";
import { withRouter } from "react-router-dom";

import { QUOTE_STATUS_TO_STRING_BRAND, QUOTE_STATUS_AS_STRING } from "../../../../constants/Quote";
import { PRODUCTION_MERCHANT } from '../../../../constants/Auth';
import { checkFactoryOrMerchant } from "helpers/AuthHelper"
import "./index.scss"; // TODO: remove dependency from '../QuoteDetails.scss';
import QuoteDropdown from "../QuoteDropDown";

const STATUS_ORDER = {
  '1': 4,
  '0': 3,
  '2': 2,
  '-1': 1,
  '-2': 0,
}

const getQuoteMeta = (quoteDetails) => {
  let status = {
    isPreApproved: false,
    anyQuoteAccepted: false,
  }
  if(quoteDetails['1'] && quoteDetails['1'].length > 0){
    status.anyQuoteAccepted = true;
    if(quoteDetails['1'][0].is_pre_approved){
      status.isPreApproved = true;
    }
  }
  return status;
}

class QuoteDropdownList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      dropdown: {},
      allQuotes: this.getAllQuotes(props),
      isFormChanged: false,
      isSaved: false,
      countAwaited: 0,
      isPreApproved: getQuoteMeta(props.quoteDetails).isPreApproved,
      anyQuoteAccepted: getQuoteMeta(props.quoteDetails).anyQuoteAccepted,
    };
  }

  componentWillReceiveProps(nextProps, oldProps) {
    const newDropdown = {};
    const nextState = {};
    (merge(Object.values(this.props.quoteDetails))).forEach(quoteId => {
      newDropdown[quoteId] = false;
    });

    if (nextProps.initialQuoteId && this.props.initialQuoteId !== nextProps.initialQuoteId) {
      newDropdown[nextProps.initialQuoteId] = true;
    }

    if (oldProps.quoteDetails !== nextProps.quoteDetails) {
      nextState.allQuotes = this.getAllQuotes(nextProps);
      nextState.countAwaited = ((nextProps.quoteDetails['-1'] && nextProps.quoteDetails['-1'].length) || 0);
      const{ anyQuoteAccepted, isPreApproved } = getQuoteMeta(nextProps.quoteDetails)
      nextState.anyQuoteAccepted = anyQuoteAccepted;
      nextState.isPreApproved = isPreApproved;
    }

    this.setState({
      ...this.state,
      ...nextState,
      dropdown: {
        ...this.state.dropdown,
        ...newDropdown
      },
    });
  }

  componentDidMount() {
    let newDropdown = {};
    if (
      isEmpty(this.state.dropdown) &&
      Object.entries(this.props.quoteDetails).length === 1 &&
      this.props.initialQuoteId
    ) {
      newDropdown[this.props.initialQuoteId] = true;
      this.setState({
        dropdown: { ...newDropdown }
      });
    }
  }

  getAllQuotes(props) {
    return Object.keys(props.quoteDetails)
      .sort((a, b) => STATUS_ORDER[a] < STATUS_ORDER[b] ? 1 : -1)
      .map(key => ({
        status: key,
        quotes: props.quoteDetails[key]
          .map(quote => ({
            ...quote,
            quoteDetails: JSON.parse(quote.quote),
          })),
      }));
  }

  // Change observer handler ***********  enables save button ************

  changeObserverHandler = onSave => {
    if (onSave) {
      this.setState({ isFormChanged: false, isSaved: true });
      return;
    }
    // dont set it if its already set.
    if (this.state.isFormChanged) return;

    this.setState({ isFormChanged: true, isSaved: false });
  };

  renderQuotes({ status, quotes }) {
    const {
      currentUser,
      design,
      initialQuoteId,
      currency,
      updateQuote,
      openModal,
      quoteRequestDetails,
      flashError,
    } = this.props;
    const { countAwaited, anyQuoteAccepted, isPreApproved } = this.state;

    return (
      <div className="quote-dropdowns__quotes" key={status}>
        {(currentUser.isBrand || currentUser.isSuperAdmin) && !isPreApproved && <div className="quote-dropdowns__quotes-header">
          {(!anyQuoteAccepted || status !== QUOTE_STATUS_AS_STRING.DRAFT)
            ? QUOTE_STATUS_TO_STRING_BRAND[status]
            : 'Not received'
          } ({quotes.length})
        </div>
        }
        {(currentUser.isBrand || currentUser.isSuperAdmin) && isPreApproved && <div className="quote-dropdowns__quotes-header">Pre-approved Quote</div>}
        <div className="quote-dropdowns__quotes-list">
          {quotes.map(quote => <QuoteDropdown
            key={quote.qrf_id}
            quote={quote}
            design={design}
            currency={currency}
            currentUser={currentUser}
            defaultExpanded={(currentUser.isFactory || currentUser.role === PRODUCTION_MERCHANT)
              || (initialQuoteId !== null && initialQuoteId === quote.id)}
            quoteRequestDetails={quoteRequestDetails}
            updateQuote={updateQuote}
            flashError={flashError}
            anyQuoteAccepted={anyQuoteAccepted}
            openSubmitQuoteModal={(props) => openModal("SUBMIT_QUOTE_MODAL", {
              ...props,
              design,
              quote,
              quoteRequestDetails,
            })}
            openUpdateQuoteModal={(props) => openModal("UPDATE_QUOTE_MODAL", {
              ...props,
              design,
              quote,
              quoteRequestDetails,
            })}
            openRejectQuoteModal={(quote) => {
              openModal("RESUBMIT_QUOTE_MODAL", {
                quoteDetails: quote,
                design,
                hiddenFields: [
                  { name: "design_id", value: design.id },
                  { name: "quote_id", value: quote.id },
                  { name: "factoryId", value: quote.factoryId },
                  { name: "status", value: 2 },
                  { name: "design_name", value: design.name },
                  {
                    name: "flashMsg",
                    value: `Quote for ${design.name} rejected`
                  }
                ],
              });
            }}
            openApproveQuoteModal={(quote) => {
              openModal("APPROVE_QUOTE_MODAL", {
                quoteDetails: quote,
                design,
                countAwaited,
                hiddenFields: [
                  { name: "design_id", value: design.id },
                  { name: "quote_id", value: quote.id },
                  { name: "factoryId", value: quote.factoryId },
                  { name: "status", value: 1 },
                  { name: "design_name", value: design.name },
                  {
                    name: "flashMsg",
                    value: `Quote for ${design.name} approved`
                  }
                ],
              });
            }}
            openWithdrawQuoteRequestModal={(args) => openModal("WITHDRAW_QUOTE_MODAL", args)}
          />)}
        </div>
      </div>
    );
  }

  render() {
    const { currentUser } = this.props;
    const { allQuotes } = this.state;

    return <div className={`quote-dropdowns scroll-auto ${checkFactoryOrMerchant() ? 'quote-dropdowns--factory' : ''}`}>
      {allQuotes.map(quotesInfo => this.renderQuotes(quotesInfo))}
    </div>;
  }
}

export default withRouter(QuoteDropdownList);
