import React from "react";
import Radio from "components/UI/Radio";
import { TextSpliced } from "components/UI/TextSpliced";

const FactoryRadio = ({
  selected,
  handleClick,
  factoryId,
  factoryName,
}) => (
    <div>
      <Radio
        label={<TextSpliced text={factoryName} />}
        value={selected}
        onClick={() => {
          handleClick(factoryId);
        }}
        name='factory'
      />
    </div>
  );

export default FactoryRadio;
