import React from "react";
import { getCurrentUserRole } from "./../../helpers/AuthHelper";
import styles from "../Product/Product.module.scss";
import itemStyles from "./../SamplingTracker/SampleTrackerItem.module.scss";
import { Link } from "react-router-dom";
import { useHover } from "./../../customHooks/useHover";
import { computePath } from "./../../helpers/pathHelper";

const currentUserRoleValue = getCurrentUserRole();
let isAdmin = false,
  isBrand = false;
if (currentUserRoleValue == "LOCO_SUPER") {
  isAdmin = true;
} else if (
  currentUserRoleValue == "CLIENT_SUPER" ||
  currentUserRoleValue == "CLIENT"
) {
  isBrand = true;
}

const DesignImageCard = ({
  isFactory,
  brandId,
  collectionId,
  designId,
  smallImageUrl,
  brandName,
  sourceDesignId,
  designName,
  isCellV2,
  factoryId,
}) => {
  const [ref, hovered] = useHover();
  const isDummyImageUrl = smallImageUrl.startsWith('/static');

  const path = computePath();
  const newPath = tab => {
    switch (path) {
      case "manager":
      case "factory":
        return `/${path}/brands/${brandId}/collections/${collectionId}/designs/${designId}/?tab=${tab}`;
      case "merchant":
        return `/${path}/brands/${factoryId}/collections/${collectionId}/designs/${designId}/?tab=${tab}`;
      default:
        return `/brand/collections/${collectionId}/designs/${designId}/?tab=${tab}`;
    }
  };

  return (
    <div className={styles.design__image_wrapper}>
      <div
        className={` product-grid-profile ${styles.design__image_outer} ${
          itemStyles.item__image
          } ${isDummyImageUrl ? 'dummy' : ''}`}
      >
        <Link
          target="_blank"
          to={newPath("summary")}
          style={{ lineHeight: "0px" }}
        >
          <img
            src={smallImageUrl}
            alt="design"
            className={styles.design__image}
          />
        </Link>
      </div>
      <div className={styles.design__details}>
        <Link className={isCellV2 ? 'cell-v2' : ''} target="_blank" to={newPath("summary")}>
          <div ref={ref} className="link-hover">
            <div
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "200px",
                width: "160px",
              }}
            >
              <span data-tip={designName}>
                {designName}
              </span>
            </div>
          </div>
          <div className={styles.design__id}>ID: {sourceDesignId}</div>
        </Link>
      </div>
    </div>
  );
};

export default DesignImageCard;
