import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '../../../UI/Button/Button';
import { BrandImage } from '../../../UI/BrandImage';
import { ORG_TYPES, ORG_TYPE_NAMES } from '../../../../constants/Global';
import './style.scss';


export default class OrderSummary extends React.Component {
  static propTypes = {
    brand: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
    factory: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
    canChangeBrand: PropTypes.bool,
    canChangeFactory: PropTypes.bool,
    onOrgChange: PropTypes.func,
  };

  static defaultProps = {
    canChangeBrand: true,
    canChangeFactory: true,
  };

  _renderOrg = (org, orgType) => {
    const { brand, onOrgChange, canChangeBrand, canChangeFactory } = this.props;
    const hasBrand = !!brand;

    return <div className={classNames(`order-summary__org order-summary__org--${ORG_TYPE_NAMES[orgType]}`, {
      'order-summary__org--reverse': hasBrand,
    })}>
      <div className="order-summary__org-logo">
        <BrandImage id={org.id} name={org.name} orgType={ORG_TYPE_NAMES[orgType]} borderRadius={orgType === 'factory' ? '8px' : '50%'} />
      </div>
      <div className="order-summary__org-details flex-col">
        <div className="order-summary__org-name overflow-ellipsis">
          {org.name}
        </div>
        <div className="order-summary__org-info">
          {hasBrand && <span className="order-summary__org-type">
            {ORG_TYPE_NAMES[orgType]}
          </span>}
          {(
            (orgType === ORG_TYPES.brand && canChangeBrand)
            || (orgType === ORG_TYPES.factory && canChangeFactory)
          ) && <Button
            type="button"
            className="cta-change-org"
            category="primary-text"
            onClick={() => onOrgChange(orgType)}
          >CHANGE</Button>}
        </div>
      </div>
    </div>
  }

  render() {
    const { brand, factory, children } = this.props;

    return <div className='order-summary'>
      {(brand || factory) && <div className="order-summary__org-list">
        {brand && this._renderOrg(brand, ORG_TYPES.brand)}
        {factory && this._renderOrg(factory, ORG_TYPES.factory)}
      </div>}
      {children}
    </div>
  }
}
