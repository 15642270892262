import { handleActions } from "redux-actions";

import _ from "lodash";

const INITIAL_STATE = {
  designs: [],
  allBrands: [],
  prevSavedDesigns: [],
  selectedDesigns: [],
  updatedOrder: false,
  samplesForSampleTracker: {},
  prevSavedSamplesForTracker: [],
  updatedSampleWithDispatch: null,
  productionTrackerProducts: {},
  prevProductsForProductionTracker: [],
  trackerActiveTab: 0,   // this key handles the active tabs in production and sampling tracker screens ****
};

const Products = handleActions(
  {
    PRODUCTS_GET_DESIGNS: (state, action) => {
      const { data, refresh } = action.payload;

      if (refresh) {
        return {
          ...state,
          designs: data,
          prevSavedDesigns: [_.cloneDeep(data.products)]
        };
      } else {
        return {
          ...state,
          designs: data,
          prevSavedDesigns: [
            ...state.prevSavedDesigns,
            _.cloneDeep(data.products)
          ]
        };
      }
    },

    PRODUCTS_GET_ALL_BRANDS: (state, action) => {
      return {
        ...state,
        allBrands: action.payload
      };
    },
    PRODUCTS_SELECTED_COLLECTIONS: (state, action) => {
      return {
        ...state,
        selectedDesigns: action.payload
      };
    },
    ORDERS_UPDATE_SINGLE_ORDER: (state, action) => {
      // == this updated the single order on update --
      return {
        ...state,
        updatedOrder: { ...action.payload }
      };
    },
    SAMPLING_TRACKER_GET_ALL_SAMPLES: (state, action) => {
      const { data, refresh } = action.payload;

      if (refresh) {
        return {
          ...state,
          samplesForSampleTracker: data,
          prevSavedSamplesForTracker: [_.cloneDeep(data.products)]
        };
      } else {
        return {
          ...state,
          samplesForSampleTracker: data,
          prevSavedSamplesForTracker: [
            ...state.prevSavedSamplesForTracker,
            _.cloneDeep(data.products)
          ]
        };
      }
    },
    DISPATCH_DETAILS_UPDATED_IN_SAMPLING_TRACKER: (state, action) => {
      return {
        ...state,
        updatedSampleWithDispatch: action.payload.data
      };
    },
    GET_ALL_PRODUCTS_FOR_PRODUCTION_TRACKER: (state, action) => {
      // const { data, refresh } = action.payload;
      // return {
      //   ...state,
      //   productionTrackerProducts: data
      // }


      const { data, refresh } = action.payload;

      if (refresh) {
        return {
          ...state,
          productionTrackerProducts: data,
          prevProductsForProductionTracker: [_.cloneDeep(data.products)]
        };
      } else {
        return {
          ...state,
          productionTrackerProducts: data,
          prevProductsForProductionTracker: [
            ...state.prevProductsForProductionTracker,
            _.cloneDeep(data.products)
          ]
        };
      }
    },
    CHNAGE_ACTIVE_TAB_IN_TRACKER: (state, action) => {
      return {
        ...state,
        trackerActiveTab: action.payload.activeTab
      }
    },
    LOGOUT: (state, action) => {
      return {
        ...state,
        ...INITIAL_STATE
      };
    }
  },
  INITIAL_STATE
);

export default Products;
