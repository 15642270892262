export const DESIGN_CATEGORIES = [
  { value: "Women", label: "Women" },
  { value: "Men", label: "Men" },
  { value: "Kids", label: "Kids" }
];

export const DESIGN_SUB_CATEGORIES = {
  Women: [
    { value: "Indian and fusion wear", label: "Indian and fusion wear" },
    { value: "Western wear", label: "Western wear" },
    { value: "Sleep wear", label: "Sleep wear" },
    { value: "Sports and active wear", label: "Sports and active wear" },
    { value: "Accessories", label: "Accessories" },
    { value: "Plus size", label: "Plus size" }
  ],
  Men: [
    { value: "Top wear", label: "Top wear" },
    { value: "Bottom wear", label: "Bottom wear" },
    { value: "Sports and active wear", label: "Sports and active wear" },
    { value: "Indian and festive wear", label: "Indian and festive wear" },
    { value: "Inner and sleep wear", label: "Inner and sleep wear" },
    { value: "Fashion accessories", label: "Fashion accessories" },
    { value: "Plus size", label: "Plus size" }
  ],
  Kids: [
    { value: "Boys clothing", label: "Boys clothing" },
    { value: "Girls clothing", label: "Girls clothing" },
    { value: "Unisex clothing", label: "Unisex clothing" }
  ]
};

export const DESIGN_STATUS_KEYS = {
  DRAFT: 1,
  MEASUREMENT_SHEET_UPLOADED: 2,
  QUOTE_APPROVED: 5,
  SAMPLE_APPROVED: 7,
  PRODUCTION: 8,
  DISPATCHED: 9,
  ARCHIVED: 11,
  CANCELED: 12
}

export const DESIGN_STATUS = {
  1: "DRAFT",
  2: "QUOTE",
  3: "SAMPLING",
  4: "PRODUCTION",
  5: "SHIPPED",
  6: "ARCHIVED",
  7: "CANCELED"
};

export const MAP_SAMPLE_TYPES = {
  prototype: "Prototype/Fit Sample",
  sizeSet: "Size-set Sample",
  preProduction: "Preproduction Sample",
  topOfBulk: "Top of Bulk (TOB) Sample",
};

export const SAMPLE_TYPES = Object.values(MAP_SAMPLE_TYPES);

export const QUOTE_STATUS = {
  0: "Submitted",
  1: "Accepted",
  2: "Resubmission requested"
};

export const CURRENCY_SYMBOL = ["$", "₹"];
export const CURRENCY_THREE_CODES = {
  $: "USD",
  "₹": "INR"
};

export const CURRENCY = ["USD", "INR"];

export const STATUS_HASH = {
  DRAFT: [1, 2],
  QUOTE: [3,4,5],
  SAMPLING: [6,7],
  PRODUCTION: [8],
  SHIPPED: [9],
  "ORDER CLOSED": [10],
  "ARCHIVED": [11],
  "CANCELED": [12]
};
export const STATUS_NAMES = {
  1: "Details Submitted",
  2: "Files Attached",
  3: "Sample Uploaded",
  4: "Quote Summary Uploaded",
  5: "Quotes Uploaded",
  6: "Quote Accepted",
  7: "Order Production Sheet Uploaded",
  8: "Order Production Accepted",
  9: "Dispatch Sheet Uploaded",
  10: "Order Closed"
};

// Create Global Major Status array
// For example: {1: "DESIGN", 2: "DESIGN", 3: "SAMPLING", 4: "QUOTE", 5: "QUOTE", 6: "QUOTE", 7: "ORDER", 8: "ORDER", 9: "DISPATCH", 10: "ORDER_CLOSED"}1: "DESIGN"2: "DESIGN"3: "SAMPLING"4: "QUOTE"5: "QUOTE"6: "QUOTE"7: "ORDER"8: "ORDER"9: "DISPATCH"10: "ORDER_CLOSED"}
export const STATUS_ORDER = Object.entries(STATUS_HASH).reduce(
  (acc, [MAJOR_STATUS, MINOR_STATUS_ARY]) => {
    let minorStatuses = {};
    MINOR_STATUS_ARY.forEach(
      minorStatus => (minorStatuses[minorStatus] = MAJOR_STATUS)
    );
    return { ...acc, ...minorStatuses };
  },
  {}
);

export const DASHBOARD_TYPES = {
  NOTIFICATION: "NOTIFICATION",
  FACTORY: "FACTORY",
  BRAND: "BRAND"
};

export const NOTIFICATION_CATEGORT = {
  1: "Sample",
  2: "Quote",
  5: "TNA",
  6: "Dispatch"
};

export const FACTORY_USER_ROLES = {
  4: "Admin",
  5: "Manager"
}

export const MEASUREMENT_SHEET = "Measurement sheet";
export const MATERIAL = "Material";

export const SAMPLING_FILTERS = {
  brandId: "brandid",
  factory: "factory",
  sampleStage: "st",
  dispatchStatus: "dst",
  notificationStatus: "nst"
}

export const PRODUCTION_FILTERS = {
  brandId: "brandid",
  factory: "factory",
  productionStage: "pst",
  dueDate: "due",
  notificationStatus: "nst"
}

export const SAMPLE_APPROVAL_LABEL = "PP Sample Approved";
export const SAMPLE_DISPATCH_LABEL = "Pre-production Sample - Dispatch";

export const DESIGN_SAMPLING_STATUS = {
  REQUESTED: "Requested",
  SKIPPED: "Skipped"
}

export const DESIGN_ADDITIONAL_DETAILS = {
  ADDITIONAL_DETAILS: "Additional details",
  FABRIC_DETAILS: "Fabric details",
  CORE_FABRIC: "Core Fabric",
  TRIM_FABRIC: "Trim Fabric",
}
