import React, { Component } from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { submit } from "redux-form";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import isEqual from "lodash/isEqual";
import find from "lodash/find";
import findLastKey from "lodash/findLastKey";
import keys from "lodash/keys";
import moment from "moment";
import qs from "query-string";

import DesignDetails from "components/Designs/DesignDetails/DesignDetails";
import InternalSidebar from "components/UI/InternalSidebar/InternalSidebar";
import Filter from "components/Product/Filter";

import {
  generateDesignLinkDummy as generateDesignLink,
  getSmallImageUrl,
  getCoverImagePlaceholder,
  getDesignLink,
} from "helpers/DesignHelpers";
import {
  getCurrentUserRole,
  canCreateDesign,
  checkSuperAdmin,
} from "helpers/AuthHelper";
import { computePath, constructPath} from "helpers/pathHelper";

import { CLIENT, CLIENT_SUPER, PRODUCTION_MERCHANT } from "constants/Auth";
import { PAGE_TITLES } from "constants/Titles";

import actions from "store/actions";

import ImgChevronLeft from "assets/images/navigation/ic-chevron-left.svg";
import ImgAdd from "assets/images/content/ic-add.svg";

import "./DesignDetailsContainer.scss";
import PrivateContainer from "components/UI/PrivateContainer/PrivateContainer";

const MAP_TAB_TITLES = {
  summary: (designName) => PAGE_TITLES.designSummary(designName),
  quote: (designName) => PAGE_TITLES.designQuote(designName),
  sampling: (designName) => PAGE_TITLES.designSampling(designName),
  designtna: (designName) => PAGE_TITLES.designProduction(designName),
  messages: (designName) => PAGE_TITLES.designMessages(designName),
};

class DesignDetailsContainer extends Component {
  static propTypes = {
    openModal: PropTypes.func,
  };

  state = {
    emptyCollection: false,
    isTnaForBrand: true,
    userRole: getCurrentUserRole(),
    currentBrand: null,
    selectedDesigns: {},
    initialSelectedFactory: null,
    selectedFactory: {},
    initialRender: true,
  };

  componentDidMount() {
    this.props.getUsers();
    this.reloadDesigns();
    this.props.getCollectionById(this.props.match.params.collection_id);
    if(this.props.currentUser.role === PRODUCTION_MERCHANT){
      this.props.getAssignedFactories({ collectionId: this.props.match.params.collection_id });
    }
  }

  componentDidUpdate(oldProps, oldState) {
    const {
      match: { params },
      currentUser,
    } = this.props;
    const { initialRender } = this.state;
    if (!isEqual(oldProps.match.params.collection_id, params.collection_id)) {
      this.reloadDesigns();
    }

    if (
      !oldProps.selectedDesigns[params.design_id] &&
      this.props.selectedDesigns[params.design_id] &&
      this.props.selectedDesigns[params.design_id].collection_id !=
        params.collection_id
        && this.state.redirect !== true
    ) {
      const link = getDesignLink(
        {
          ...this.props.selectedDesigns[params.design_id],
          designId: params.design_id,
        },
        this.props.currentUser.isFactory || this.props.currentUser.isSuperAdmin
      );
      this.props.history.push(link);
    }

    if (
      Object.keys(oldProps.selectedDesigns).length > 0 &&
      Object.keys(this.props.selectedDesigns).length === 0
    ) {
      this.setState({ emptyCollection: true });
    }

    if (
      Object.keys(oldState.selectedDesigns).length === 0 &&
      Object.keys(this.props.selectedDesigns).length > 0 &&
      this.props.selectedDesigns[params.design_id] &&
      initialRender
    ) {
      this.setState({
        selectedDesigns: this.props.selectedDesigns,
        initialRender: false,
      });
    }

    if (
      currentUser.role === PRODUCTION_MERCHANT &&
      this.props.factoryFilterOptions &&
      !this.state.initialSelectedFactory
    ) {
      const initialSelectedFactory =
        find(this.props.factoryFilterOptions, {
          id: Number(params.brand_id),
        }) || {};

      this.setState({
        initialSelectedFactory,
        selectedFactory: initialSelectedFactory,
      });
    }

    if(this.state.redirect && !isEqual(keys(this.props.selectedDesigns), keys(oldProps.selectedDesigns))){
      const designId = findLastKey(this.props.selectedDesigns);
      const design = this.props.selectedDesigns[designId];
      if(design){
        const link = `/${constructPath(params.brand_id)}/collections/${design.collection_id}/designs/${design.id}/?tab=summary`;

        this.setState({
          redirect: false
        }, () => {
          this.props.history.push(link);
        })
      }
    }
  }

  reloadDesigns = () => {
    this.props.getDesignsByCollectionId(
      this.props.match.params.collection_id,
      this.props.match.params.design_id,
      this.state.userRole === PRODUCTION_MERCHANT ? this.props.match.params.brand_id : null
    );
  };

  reloadFiles = () => {
    this.props.getSwatchDesignFiles({
      designId: this.props.match.params.design_id,
    });
  };

  filterDesigns = (selection) => {
    const filteredDesigns = {};
    Object.entries(this.props.selectedDesigns).forEach(([key, value]) => {
      if (value.factory_assigned === selection.id) {
        filteredDesigns[key] = value;
      }
    });

    this.props.getDesignsByCollectionId(
      this.props.match.params.collection_id,
      null,
      selection.id
    );

    this.setState({
      selectedFactory: selection,
      redirect: true,
    });
  };

  getCoverImageFromFiles = (files) => {
    let coverImage;
    if (!files) return null;
    Object.keys(files).forEach((key) => {
      if (files[key].is_cover) {
        coverImage = files[key].fileloc;
      }
    });

    if (coverImage) {
      return getSmallImageUrl(coverImage);
    }

    return null;
  };

  scrollRefHandler = (ref) => {
    this._scrollRef = ref;
  };

  getTitle = (design = {}) => {
    const {
      location: { search = "" },
    } = this.props;
    const tab = (qs.parse(search)["tab"] || "summary").toLowerCase();

    return (
      MAP_TAB_TITLES[tab] ||
      ((designName) => PAGE_TITLES.designDetails(designName))
    )(design.name || "Design");
  };

  render() {
    const {
      initialSelectedFactory,
      selectedFactory,
    } = this.state;

    const {
      selectedDesigns,
      factoryFilterOptions,
    } = this.props;

    const computePrefix = computePath();
    const prefix =
      getCurrentUserRole() === CLIENT || getCurrentUserRole() === CLIENT_SUPER
        ? `/${computePrefix}`
        : `/${computePrefix}/brands/${this.props.match.params.brand_id}`;

    if (this.state.emptyCollection) {
      return <Redirect to={{ pathname: "../../" }} />;
    }

    const selectedCollection = this.props.collections[
      this.props.match.params.collection_id
    ];
    const selectedDesign = this.props.selectedDesigns[
      this.props.match.params.design_id
    ];
    const sortedDesigns = Object.keys(selectedDesigns).sort((a, b) => {
      return moment(selectedDesigns[a]["created_on"]).isBefore(
        selectedDesigns[b]["created_on"]
      )
        ? 1
        : -1;
    });

    const showFooter = canCreateDesign();

    return (
      <div className="design-container-details flex">
        <Helmet
          bodyAttributes={{
            class: "body_container",
          }}
          title={this.getTitle(selectedDesign)}
        />
        <InternalSidebar
          scrollRefHandler={this.scrollRefHandler}
          header={
            this.props.match.params.collection_id && (
              <Link
                to={`${prefix}/collections/${this.props.match.params.collection_id}/?tab=allDesigns`}
                className="design-container-details__sidebar-item flex header"
              >
                <img src={ImgChevronLeft} alt="" />
                <span>All Designs</span>
              </Link>
            )
          }
          footer={
            showFooter ? (
              <div
                className="design-container-details__sidebar-item flex footer"
                onClick={() =>
                  this.props.openModal("NEW_DESIGN", {
                    hiddenFields: [
                      {
                        name: "collection_id",
                        value: this.props.match.params.collection_id,
                      },
                      {
                        name: "brandId",
                        value: this.props.match.params.brand_id,
                      },
                    ],
                  })
                }
              >
                <span>New Design</span>
                <img src={ImgAdd} alt="" />
              </div>
            ) : (
              <div></div>
            )
          }
        >
          <>
            <PrivateContainer allowRoles={[PRODUCTION_MERCHANT]}>
              <Filter
                title="Factory"
                innerWidth="160px"
                width="160px"
                height="auto"
                initialSelectedItem={initialSelectedFactory}
                onChange={(selection) => {
                  this.filterDesigns(selection);
                }}
                selectedItem={selectedFactory}
                filterOptions={factoryFilterOptions}
                marginRight="20px"
              />
            </PrivateContainer>
            {sortedDesigns.map((dId) => {
              let coverImageFromFiles = this.getCoverImageFromFiles(
                this.props.selectedDesigns[dId] &&
                  this.props.selectedDesigns[dId]["files"]
              );
              return (
                <Link
                  className={`item-container${
                    this.props.match.params.design_id === dId ? " active" : ""
                  }`}
                  to={generateDesignLink({
                    design: this.props.selectedDesigns[dId],
                    location: this.props.location,
                    pathname: "../:design_id/",
                  })}
                  key={dId}
                >
                  <div
                    className={`design-list__item${
                      this.props.match.params.design_id === dId
                        ? " col-custom-list__item--active"
                        : ""
                    }`}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div className="design-image-container">
                        <img
                          src={
                            coverImageFromFiles
                              ? coverImageFromFiles
                              : getCoverImagePlaceholder(
                                  this.props.selectedDesigns[dId]
                                ).src
                          }
                          alt="Design"
                          style={{ width: "100%", height: "100%" }}
                        />
                      </div>
                      <div className="design-caption">
                        <div
                          className="design-list__title item__title overflow-ellipsis"
                          data-tip={this.props.selectedDesigns[dId].name}
                          data-place="right"
                          data-delay-show="500"
                        >
                          {this.props.selectedDesigns[dId].name}
                        </div>
                        <div
                          className={`design-list__subtitle item__subtitle f-sz-sm ${
                            this.props.match.params.design_id === dId
                              ? "active"
                              : ""
                          }`}
                        >
                          {this.props.selectedDesigns[
                            dId
                          ].statusString.toLowerCase()}
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              );
            })}
          </>
        </InternalSidebar>
        <div className="design-container-details__main">
          <DesignDetails
            brandId={this.props.match.params.brand_id}
            reloadDesigns={this.reloadDesigns}
            isFactory={this.props.currentUser.isFactory}
            isSuperAdmin={checkSuperAdmin(this.props.currentUser.role)}
            showFlash={this.props.showFlash}
            // updateSampleDetails={this.props.updateSampleDetails}
            sampleDetails={this.props.sampleDetails}
            openModal={this.props.openModal}
            submitEditDesignForm={this.props.submitEditDesignForm}
            editDesign={(designDetails) => {
              return this.props.editDesign(designDetails).then(() => {
                this.props.getDesignsByCollectionId(
                  designDetails.collection_id,
                  designDetails.id
                );
              });
            }}
            design={selectedDesign}
            designId={this.props.match.params.design_id}
            collection={selectedCollection}
            getQuoteDetails={this.props.getQuoteDetails}
            quoteSummary={this.props.quoteSummary}
            quoteDetails={this.props.quoteDetails}
            quoteRequestDetails={this.props.quoteRequestDetails}
            updateQuote={this.props.updateQuote}
            createQuote={this.props.createQuote}
            notifyQuoteRequest={this.props.notifyQuoteRequest}
            getAllFactories={this.props.getAllFactories}
            usersList={this.props.usersList}
            getDesignFiles={this.reloadFiles}
            patchDesignFile={(values) =>
              this.props.patchDesignFile(values).then(() => this.reloadFiles())
            }
            uploadNewDesignFile={(values) =>
              this.props
                .uploadNewDesignFile(values)
                .then(() => this.reloadFiles())
            }
            deleteDesignFile={(values) =>
              this.props
                .deleteDesignFile({
                  ...values,
                  designId: this.props.match.params.design_id,
                })
                .then(() => this.reloadFiles())
            }
            currentUser={this.props.currentUser}
            // after connecting tna with redux
            getFactoryTnaTemplate={this.props.getFactoryTnaTemplate}
            templateDetails={this.props.factoryTnaTemplateDetails}
            getBrandTnaTemplate={this.props.getBrandTnaTemplate}
            noTnaBrand={this.props.brandTnaTemplate}
            postPreProductionCommit={this.props.postPreProductionCommit}
            // for new sampling api's
            getSamplesByDesignId={this.props.getSamplesByDesignId}
            newSampleDetails={this.props.newSampleDetails}
            postSampleMeasurementSheet={this.props.postSampleMeasurementSheet}
            sampleCommints={this.props.sampleCommints}
            getAllCommints={this.props.getAllCommints}
            addNewCommint={this.props.addNewCommint}
            // delete quote
            deleteQuote={this.props.deleteQuote}
            addPendingNotification={this.props.addPendingNotification}
            removePendingNotification={this.props.removePendingNotification}
            notificationMode={this.props.notificationMode}
            notificationList={this.props.notificationList}
            messagesCount={this.props.messages.length}
            closeModal={this.props.closeModal}
            flashError={this.props.flashError}
            getAllFactoriesForQuotes={this.props.getAllFactoriesForQuotes}
            factoriesForQuotes={this.props.factoriesForQuotes}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  collections: state.collections.list,
  usersList: state.auth.list,
  selectedDesigns: state.designs.selectedDesigns,
  sampleDetails: state.designs.samplingDetails,
  quoteDetails: state.designs.quoteDetails,
  quoteRequestDetails: state.designs.quoteRequestDetails,
  quoteSummary: state.designs.quoteSummary,
  currentUser: state.auth.userDetails,
  factoryTnaTemplateDetails: state.designs.factoryTnaTemplate,
  brandTnaTemplate: state.designs.brandTnaTemplate,
  messages: state.messaging.messages,

  // new sample api's
  newSampleDetails: state.designs.newSampleDetails,
  sampleCommints: state.designs.sampleCommints,
  notificationMode: state.notificationView.notificationMode,
  notificationList: state.notificationView.pendingNotifications,

  factoriesForQuotes: state.factory.factoriesForQuotes,
  factoryFilterOptions: state.factory.transformedFilterFactories,
});

const mapDispatchToProps = (dispatch) => ({
  getAllCollections: () => dispatch(actions.getAllCollections()),
  getUsers: () => dispatch(actions.getUsers()),
  getDesignsByCollectionId: (collectionId, designId, factoryId) =>
    dispatch(actions.getDesignsByCollectionId(collectionId, designId, factoryId)),
  submitEditDesignForm: () => dispatch(submit("designSummary")),
  editDesign: (values) => dispatch(actions.editDesign(values)),
  openModal: (name, additionalProps) =>
    dispatch(actions.openModal(name, additionalProps)),
  closeModal: () => dispatch(actions.closeModal()),
  getSampleDetails: (designDetails) =>
    dispatch(actions.getSampleDetails(designDetails)),
  updateSampleDetails: (details) =>
    dispatch(actions.updateSampleDetails(details)),
  getQuoteDetails: (designDetails) =>
    dispatch(actions.getQuoteDetails(designDetails)),
  updateQuote: (details) => dispatch(actions.updateQuote(details)),
  createQuote: (details) => dispatch(actions.createQuote(details)),
  deleteQuote: (details) => dispatch(actions.deleteQuote(details)),
  notifyQuoteRequest: (details) =>
    dispatch(actions.notifyQuoteRequest(details)),
  patchDesignFile: (details) => dispatch(actions.patchDesignFile(details)),
  uploadNewDesignFile: (details) =>
    dispatch(actions.uploadNewDesignFile(details)),
  deleteDesignFile: (details) => dispatch(actions.deleteDesignFile(details)),
  getCollectionById: (details) => dispatch(actions.getCollectionById(details)),
  getFactoryTnaTemplate: (details) =>
    dispatch(actions.getFactoryTnaTemplate(details)),
  getBrandTnaTemplate: (details) =>
    dispatch(actions.getBrandTnaTemplate(details)),
  postPreProductionCommit: (details) =>
    dispatch(actions.postPreProductionCommit(details)),
  getSwatchDesignFiles: (details) =>
    dispatch(actions.getSwatchDesignFiles(details)),

  // for new sample api's
  getSamplesByDesignId: (details) =>
    dispatch(actions.getSamplesByDesignId(details)),
  postNewSampleDetails: (details) =>
    dispatch(actions.postNewSampleDetails(details)),
  postSampleMeasurementSheet: (details, pathName) =>
    dispatch(actions.postSampleMeasurementSheet(details, pathName)),
  getAllCommints: (details) =>
    dispatch(actions.getAllCommintsForSampling(details)),
  addNewCommint: (details) =>
    dispatch(actions.addNewCommintToSamplingCommints(details)),
  showFlash: (details) => dispatch(actions.showFlashMessage(details)),
  flashError: (message) => dispatch(actions.flashError(message)),

  addPendingNotification: (details) =>
    dispatch(actions.addPendingNotification(details)),
  removePendingNotification: (details) =>
    dispatch(actions.removePendingNotification(details)),

  getAllFactories: (details) =>
    dispatch(actions.getAllFactoriesAccount(details)),
  getAllFactoriesForQuotes: (arg) =>
    dispatch(actions.getAllFactoriesForQuotes(arg)),
  getAssignedFactories: (details) =>
    dispatch(actions.getAssignedFactories(details)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DesignDetailsContainer)
);
