import React, { Component } from "react";
import { Field, Form, reduxForm, formValueSelector } from "redux-form";
import { required } from "../../helpers/form-validations";
import Input from "../UI/Form/Input/Input";

import "./AddVendor.scss";
import PerfectScrollbar from "react-perfect-scrollbar";

import Downshift from "downshift";

const formName = "NewVendor";


class NewVendor extends Component {
  state = {
    name: null,
    type: null
  };

  VENDOR_TYPES = [
    { label: 'Domestic', value: 0},
    { label: 'Overseas', value: 1}
  ]

  render() {
    const {
      handleSubmit,
      //   pristine,
      //   reset,
      //   submitting,
      change,
      hiddenFields
    } = this.props;
    // Add hidden fields
    if (hiddenFields) hiddenFields.forEach(f => change(f.name, f.value));

    return (
      <PerfectScrollbar
        option={{
          suppressScrollX: true
        }}
        className="new-vendor flex-col"
        containerRef={ref => (this._scrollRef = ref)}
      >
        <Form
          name={formName}
          id={formName}
          onSubmit={handleSubmit(values => {
            console.log('called')
            console.log(values);
            this.props.onSubmit({
              ...values,
              name: this.state.name,
              type: this.state.type
            });
          })}
        >
          <Field
            normalize={value =>
              value &&
              value
                .split(" ")
                .map(w => w.charAt(0).toUpperCase() + w.slice(1))
                .join(" ")
            }
            component={Input}
            validate={[required]}
            maxLength={50}
            type="text"
            name="name"
            label="Vendor name"
            required={true}
            onChange={(event, newValue)=>{
              this.setState({
                name: newValue
              })
            }}
          />
          <Downshift>
            {({
              getInputProps,
              getItemProps,
              getLabelProps,
              getMenuProps,
              isOpen,
              inputValue,
              highlightedIndex,
              selectedItem,
              getRootProps
            }) => (
              <React.Fragment>
              
                <div {...getRootProps()} className={`flex-col`}>
                  <span class="f-sz-sm f-color-faded" style={{padding: '8px 0'}}>Vendor type<sup>*</sup></span>
                  <div style={{ padding: "0" }} className={`custom-category__select flex-col`}>
                    <PerfectScrollbar style={{ maxHeight: "150px" }}>
                      {this.VENDOR_TYPES.map(
                        ({ label, value }) => (
                          <div className="loco__radio" style={{ border: "none" }}>
                            <input
                              name="type"
                              id={`${label}-add`}
                              type="radio"
                              // checked={
                              // this.state["fields"]["file_category"] === value
                              // }
                              onChange={() =>
                                this.setState({
                                  type: value,
                                })
                              }
                            />
                            <label for={`${label}-add`}>{label}</label>
                          </div>
                        )
                      )}
                    </PerfectScrollbar>
                  </div>
                </div>
              </React.Fragment>
            )}
          </Downshift>
        </Form>
      </PerfectScrollbar>
    );
  }
}

const mapStateToProps = (state, ownProps) =>
  ownProps.initialValues
    ? {
        initialValues: { ...ownProps.initialValues }
      }
    : {};

export default reduxForm({ form: formName }, mapStateToProps)(NewVendor);
