import React, { Component } from "react";
import PerfectScrollBar from "react-perfect-scrollbar";
import SubTask from "./SubTask";
import vectorImg from "assets/images/dashboard/vector.svg";
import thumbsUpImg from "assets/images/dashboard/ic-thumbsup-grey.svg";
import EmptyScreen from "../../UI/EmptyScreen/index.jsx";

export default class Task extends Component {
  render() {
    const {
      tasks
    } = this.props;

    return (
      <div className="task-design">
        <div className="active-design-conatiner">
          <div className="design-conatiner-heading">
            <img src={vectorImg} className="active-icon" alt="tasks icon" /> Continue your tasks
          </div>
        </div>
        <div className="task-section">
          <PerfectScrollBar>
            {
              tasks.length > 0
                ? tasks.map(task => <SubTask task={task} />)
                : <EmptyScreen
                  icon={thumbsUpImg}
                  heading="Great job!"
                  description={<>
                    You don’t have any leftover<br />
                  tasks
                </>}
                />
            }
          </PerfectScrollBar>
        </div>
      </div >
    );
  }
}
