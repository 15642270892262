import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import _ from "lodash";

import { withRouter } from "react-router-dom";
import File from "components/UI/File";

import "./style.scss";
import FileDetailsModal from "components/Designs/FileDetailsModal/FileDetailsModal";
import icPlcAttachment from "assets/images/file/ic-attach-file.svg";
import measurementSheetIcon from "assets/images/file/ic-file-xls.svg";

import { MEASUREMENT_SHEET } from "constants/Design";
import keyBy from "lodash/keyBy";
import ImageModal from "components/ProductionTracker/TnaImageModal";

class DesignFiles extends Component {
  state = {
    openFileDetailsModal: false,
    filePopoverOpen: {},
    currentFileUploads: [],
    modalData: {},
    currentSelectedFile: null
  };

  escFunction(event) {
    if (this.state.openFileDetailsModal && event.keyCode === 27) {
      this.setState({
        openFileDetailsModal: false
      });
    }
  }

  componentDidMount() {
    window.addEventListener("keydown", this.escFunction.bind(this), false);
    setInterval(() => {
      if (
        !_.isEqual(this.state.currentFileUploads, window.locofastGlobals.files)
      ) {
        this.setState(() => {
          setTimeout(() => {
            this.forceUpdate();
          }, 300);
          return {
            currentFileUploads: [...window.locofastGlobals.files]
          };
        });
      }
    }, 1000);
  }

  hideImageModal = () => {
    this.setState({
      openFileDetailsModal: false,
      modalData: {}
    });
  };

  viewFileModal = file => {
    this.setState({
      openFileDetailsModal: true,
      modalData: file
    });
  };

  openAddFileModal = () => {
    this.props.openModal("UPLOAD_FILE", {
      hiddenFields: [{ name: "product_id", value: this.props.design.id }],
      selectedFile: this.state.currentSelectedFile,
      design: this.props.design,
      reloadDesigns: this.props.getDesignFiles,
      afterSubmit: this.props.getDesignFiles,
      isFactory: this.props.isFactory,
    });
  };

  openEditFileModal = file => {
    this.props.openModal("EDIT_FILE", {
      hiddenFields: [
        { name: "product_id", value: this.props.design.id },
        { name: "uploadedBy", value: file.uploaded_by }
      ],
      design: this.props.design,
      file,
      reloadDesigns: this.props.getDesignFiles,
      isFactory: this.props.isFactory,
    });
  };

  handleSelectFile = file => {
    if (Number(file.is_image) === 1)
      this.setState({
        selectedFile: file,
        openFileDetailsModal: true
      });
    else {
      this.setState({
        selectedFile: file,
        openFileDetailsModal: false
      });
      setTimeout(() => this.refs["downloadFile"].click(), 300);
    }
  };

  postSampleSheet = event => {
    this.props.postSampleMeasurementSheet(
      {
        sampleFile: event.target.files[0],
        design: this.props.design,
        sampleRootId: this.props.newSampleDetails.id
      },
      window.location.href
    );
  };

  render() {
    let files = Object.entries(
      Array.isArray(this.props.design.files)
        ? keyBy(this.props.design.files, "id")
        : this.props.design.files
    );
    let { disableDesignActions, design, isFactory, currentUser } = this.props;

    let { modalData, openFileDetailsModal } = this.state;

    files = [...files, ...this.state.currentFileUploads];

    files.sort((firstEl, secondEl) => {
      return moment(firstEl["file_added_at"]).isBefore(
        secondEl["file_added_at"]
      )
        ? 1
        : -1;
    });

    if (this.props.spreadsheetUploaded) {
      files = [
        [
          "0",
          {
            file_title: MEASUREMENT_SHEET,
            file_category: "Technical pack",
            fileloc: this.props.newSampleDetails.s3Location,
            defaultIcon: true,
            cannotView: true
          }
        ],
        ...files
      ];
    }

    const isAssignedFactory = isFactory &&
      design.status >= 5 &&
      design.factory_assigned === currentUser.brandId;

    return (
      <React.Fragment>
        {this.state.openFileDetailsModal === true ? (
          <FileDetailsModal
            file={this.state.selectedFile}
            onClose={() => this.setState({ openFileDetailsModal: false })}
          />
        ) : null}
        <div className="files-container">
          <div className="files-container__header">
            <span>
              <span className="title">Attachments</span>
            </span>
            {this.props.spreadsheetUploaded &&
              (!disableDesignActions || isAssignedFactory) && (
              <div
                className="flex center clickable"
                onClick={()=>this.refs["FileInput"].click()}
              >
                <img src={icPlcAttachment} className="f-sz-xxl file__icon" />
                Attach File
              </div>
            )}
          </div>

          <div className="files-container__content">
            {!this.props.spreadsheetUploaded ? (
              <div className="files-container__no-files">
                <div
                  className="clickable"
                  onClick={() => this.refs["sampleFileInput"].click()}
                >
                  <img src={measurementSheetIcon} className="f-sz-xxl" />
                  <input
                    ref="sampleFileInput"
                    className="hide"
                    type="file"
                    name="myfile"
                    accept=".csv,.xls,.xlsx"
                    onChange={this.postSampleSheet}
                  />
                  <div>
                    Click to upload <br />
                    measurement sheet. <br />
                    <span className="f-color-faded"> .xlsx, .xls and .csv files are allowed.</span>
                    </div>
                </div>
              </div>
            ) : (
              files.map(file => (
                <File
                  key={file[0]}
                  file={{ ...file[1], file_id: file[0] }}
                  openAddFileModal={this.openAddFileModal}
                  viewFile={this.viewFileModal}
                  openEditFileModal={this.openEditFileModal}
                  deleteDesignFile={() =>
                    this.props.deleteDesignFile({
                      file_id: file[0],
                      collection_id: this.props.design.collection_id
                    })
                  }
                  disabled={false}
                  isBrand={currentUser.isBrand}
                  isEditable={
                    (!isFactory && file[1] && (file[1].uploaded_by_brand === currentUser.brandId)) ||
                    (isFactory && isAssignedFactory && file[1] && (file[1].uploaded_by_brand === currentUser.brandId))
                  }
                  isDeletable={
                    (!isFactory && file[1] && (file[1].uploaded_by_brand === currentUser.brandId)) ||
                    (isFactory && isAssignedFactory && (file[1] && file[1].uploaded_by_brand === currentUser.brandId))
                  }
                  postSampleSheet={this.postSampleSheet}
                />
              ))
            )}
          </div>
        </div>
        <a
          className="hide"
          href={this.state.selectedFile && this.state.selectedFile.fileloc}
          ref="downloadFile"
          download
        />
        <input
          type="file"
          onChange={e => {
            this.setState({
              currentSelectedFile: e.target.files[0]
            }, ()=> {
              this.refs["FileInput"].value = "";
              this.openAddFileModal();
            })
          }}
          ref="FileInput"
          className="hide"
          accept={this.props.acceptTypes}
        />
        {openFileDetailsModal && (
          <ImageModal
            imageUrl={modalData.fileloc}
            fileName={modalData.file_title}
            title={design.name}
            date={modalData.file_added_at}
            uploadedBy={modalData.uploaded_by}
            onClose={this.hideImageModal}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(DesignFiles);
