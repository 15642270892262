import React, { useState, useEffect, useRef } from 'react';

export const useToggle = () => {
  const [open, setOpen] = useState(false);
  const ref = useRef();

  const toggle = () => setOpen(open=>!open)
  useEffect(()=>{
    ref.current.addEventListener("click", toggle)

    return ()=>{
      ref.current.removeEventListener("click", toggle)
    }
  },[ref])
  return [ref, open]
}