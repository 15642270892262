import React, { Component } from "react";
import { connect } from "react-redux";
import CreateOrder from "../../../components/Orders/CreateOrder";
import { CURRENCY_SYMBOL } from "../../../constants/Design";
import * as BrandActions from "../../../store/actions/BrandActions";
import * as OrderActions from "../../../store/actions/OrderActions";
import * as GlobalActions from "../../../store/actions/GlobalActions";

class CreateOrderContainer extends Component {
  render() {
    const {
      brands,
      brandFactories,
      brandAddresses,
      currentUser,
      factoryDesigns,

      getBrands,
      getFactories,
      getDesigns,
      getAddresses,
      createOrder,

      openModal,
    } = this.props;

    return <CreateOrder
      brands={brands}
      brandFactories={brandFactories}
      brandAddresses={brandAddresses}
      currentUser={currentUser}
      factoryDesigns={factoryDesigns}
      currency={CURRENCY_SYMBOL[1]}
      getBrands={getBrands}
      getFactories={getFactories}
      getDesigns={getDesigns}
      getAddresses={getAddresses}
      createOrder={createOrder}
      openModal={openModal}
    />;
  }
}

const mapStateToProps = state => ({
  brands: state.orders.brands,
  brandFactories: state.orders.brandFactories,
  brandAddresses: state.brands.brandAddresses,
  factoryDesigns: state.orders.factoryDesigns,
  currentUser: state.auth.userDetails,
});

const mapDispatchToProps = dispatch => ({
  getBrands: params => dispatch(OrderActions.getBrands(params)),
  getFactories: params => dispatch(OrderActions.getFactories(params)),
  getDesigns: params => dispatch(OrderActions.getDesigns(params)),
  getAddresses: params => dispatch(BrandActions.getBrandAddresses(params)),
  openModal: (name, additionalProps) => dispatch(GlobalActions.openModal(name, additionalProps)),
  createOrder: (params) => dispatch(OrderActions.createOrder(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateOrderContainer);
