import React from "react";

import notifyIcon from "../../../assets/images/action/ic-info-black.svg";

const Notify = ({
  title = "Default",
  message = "Default",
  style = {},
  containerStyles = {},
  labelStyles = {}
}) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        padding: "0px 16px",

        height: "80px",
        backgroundColor: "var(--fill-primary-light)",
        ...containerStyles
      }}
    >
      <div
        style={{
          marginRight: "16px",

          width: "48px",
          height: "48px",
          backgroundColor: "var(--fill-white)",
          borderRadius: "100px",

          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          ...style
        }}
      >
        <img src={notifyIcon} alt="Info" />
      </div>
      <div>
        <div
          style={{
            fontSize: "14px",
            color: "var(--fill-locofast-black)",
            lineHeight: "normal",
            fontWeight: "600",
            ...labelStyles
          }}
        >
          {title}
        </div>
        <div
          style={{
            fontSize: "12px",
            color: "var(--fill-grey-dark)",
            lineHeight: "normal",
            ...labelStyles
          }}
        >
          {message}
        </div>
      </div>
    </div>
  );
};

export default Notify;
