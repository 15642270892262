import React, { useState, useEffect } from "react";
import keyBy from "lodash/keyBy";
import findIndex from 'lodash/findIndex';

import EmptyList from "./EmptyList";
import FactoryItem from "./FactoryItem";
import FactoryHeader from "./FactoryHeader";
import { Helmet } from "react-helmet";
import { PAGE_TITLES } from "../../../constants/Titles";

const FactoryList = ({
  // deleteUser,
  openModal,
  allBrands = [],
  factories = [],
  cities: allCities,
  getAllFactories,
  createNewUser,
  updateUserAccount,
  getAllCities,
  merchantsList,
  createAssociation,
  updateAssociation,
  match,
  currentUser
}) => {
  const [appliedFilters, setAppliedFilters] = useState({
    brandIds: [],
    cities: [],
  });
  const [brands, setBrands] = useState([]);
  const [cities, setCities] = useState([]);
  const [setUsers] = useState([]);
  const [setUsersModalVisible] = useState(false);
  const [clicked, setClicked] = useState({});


  useEffect(() => {
    if (!allBrands.length) return;

    const brandsWithSelection = allBrands.map((brand) => ({
      key: brand.id,
      value: brand.id,
      label: brand.name,
    }));

    setBrands([
      {
        key: 'all',
        value: null,
        label: 'All',
        isSelected: true,
      }].concat(brandsWithSelection));
  }, [allBrands]);

  useEffect(() => {
    if (allCities) {
      setCities(
        allCities.map((city, index) => ({
          ...city,
          isSelected: true,
          index: index
        }))
      );
      return;
    }

    getAllCities();
  }, [allCities]);

  const toggleCity = value => {
    let cityIndex = findIndex(cities, { city: value });
    if (cityIndex === -1) return;
    cities[cityIndex].isSelected = !cities[cityIndex].isSelected;
    setCities([...cities]);
  };

  const selectAllCities = () => {
    setCities(cities.map(city => ({ ...city, isSelected: true })));
  };

  const unSelectAllCities = () => {
    setCities(cities.map(city => ({ ...city, isSelected: false })));
  };

  const applyFiltersHandler = (filters) => {
    if (filters) {
      setAppliedFilters(filters);
    } else {
      filters = appliedFilters;
    }
    // call here with filters ....

    let citiesSelected = [];
    cities.forEach(({ city, isSelected }) => {
      if (isSelected) {
        citiesSelected.push(city);
      }
    });

    if (!citiesSelected.length) citiesSelected = null;

    getAllFactories({
      brandIds: JSON.stringify(filters.brandIds),
      cities: JSON.stringify(citiesSelected)
    });
  };

  const onBrandSelect = (selectedBrands) => {
    const mapSelectedBrands = keyBy(selectedBrands, 'key');

    brands.forEach(brand => {
      if (brand.key in mapSelectedBrands) {
        brand.isSelected = true;
      } else {
        brand.isSelected = false;
      }
    });

    setBrands([...brands]);
    applyFiltersHandler({
      ...appliedFilters,
      brandIds: selectedBrands.filter(brand => brand.key !== 'all').map(brand => brand.value),
    });
  }

  const openAddUserModal = details => {
    openModal("MANAGE_FACTORY_ADD_NEW_USER", {
      data: details,
      createUser: createNewUser
    });
  };

  const openAllUsersModal = usersData => {
    setUsersModalVisible(true);
    setUsers(usersData);

    // openModal("MANAGE_FACTORY_SHOW_ALL_USERS", {
    //   users,
    //   getSelectedUserAddresses,
    //   selectedUserAddresses,
    //   updateUser: updateUserAccount,
    //   deleteUser
    // });
  };

  const openAllAddressesModal = addresses => {
    openModal("MANAGE_FACTORY_SHOW_ALL_ADDRESSES", { addresses });
  };

  const handleClick = (factoryId) => {
    if (clicked[factoryId]) {
      setClicked({ ...clicked, [factoryId]: false });
      return;
    }
    setClicked({ ...clicked, [factoryId]: true });
  };

  if (!factories.length
    && !appliedFilters.brandIds.length
    && !appliedFilters.cities.length) {
    return <EmptyList />;
  }

  return (
    <>
      <Helmet
        title={PAGE_TITLES.settingsFactories()}
      />
      <div className="factory-list-container">
        <FactoryHeader
          match={match}
          brands={brands}
          cities={cities}
          applyFiltersHandler={applyFiltersHandler}
          toggleCity={toggleCity}
          selectAllCities={selectAllCities}
          unSelectAllCities={unSelectAllCities}
          onBrandSelect={onBrandSelect}
          isSuperAdmin={currentUser.isSuperAdmin}
        />
        <div className="factory-list scrollable">
          {
            factories.length === 0
              ? <div className="no-factories">
                <div className="heading">Factories not found</div>
                <div className="sub-heading">Please reset the filters and try again.</div>
              </div>
              : factories.map(factory => (
                <FactoryItem
                  key={factory.id}
                  data={factory}
                  allBrands={allBrands}
                  addUser={openAddUserModal}
                  updateUser={updateUserAccount}
                  showUsers={openAllUsersModal}
                  showAllAddresses={openAllAddressesModal}
                  clickedFactories={clicked}
                  handleClick={() => handleClick(factory.id)}
                  openModal={openModal}
                  merchantsList={merchantsList}
                  createAssociation={createAssociation}
                  updateAssociation={updateAssociation}
                  isSuperAdmin={currentUser.isSuperAdmin}
                />
              ))}
        </div>
      </div>
    </>
  );
};

export default FactoryList;
