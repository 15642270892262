import React, { Component } from "react";
import DropDown from "react-dropdown";
import PropTypes from 'prop-types';
import keyBy from 'lodash/keyBy';

import 'react-dropdown/style.css';
import './index.scss';

export default class DesignFilters extends Component {
  static propTypes = {
    brands: PropTypes.array.isRequired,
    onApply: PropTypes.func.isRequired,
    style: PropTypes.object,
  };

  state = {
    brands: [{ label: 'All Brands', value: null }],
    selectedBrand: null,

    propBrands: null,
  };

  static getDerivedStateFromProps(nextProps, oldState) {
    const nextState = {};


    if (nextProps.brands !== oldState.propBrands) {
      nextState.propBrands = nextProps.brands;
      nextState.brands = nextProps.brands.map(brand => ({ value: brand.id, label: brand.name }));
      nextState.brands.unshift({ label: 'All Brands', value: null });
      nextState.mapBrands = keyBy(nextProps.brands, 'id');
    }

    return Object.keys(nextState).length > 0 ? nextState : null;
  }

  _onChangeBrand = (brand) => {
    const { onApply } = this.props;
    const { mapBrands } = this.state;

    this.setState({ selectedBrand: brand });

    onApply({
      brand: mapBrands[brand && brand.value],
    });
  }

  render() {
    const { style } = this.props;
    const { brands, selectedBrand } = this.state;

    return (
      <div className="messaging-design-filters" style={style}>
        <DropDown
          options={brands}
          onChange={this._onChangeBrand}
          value={selectedBrand}
          placeholder="All Brands"
          menuClassName={"messages-dropdown-menu"}
          controlClassName={"messages-dropdown"}
          arrowClassName={"messages-dropdown-arrow"}
          style={{ cursor: "pointer" }}
        />
      </div>
    );
  }
}
