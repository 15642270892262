import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { Field } from "formik";
import moment from "moment";
import * as Yup from "yup";
import { connect } from "react-redux";
import Popover from "react-tiny-popover";

import Form from "./../../UI/Formik/Form";
import Input from "./../../UI/Formik/Input";
import DatePicker from "./../../UI/Formik/Datepicker";
import Radio from "./../../UI/Formik/Radio";
import DesignCard from "components/Designs/DesignCard/Facade";
import Button from "components/UI/Button/Button";
import ProgressBar from "components/UI/ProgressSteps";
import FactoriesListing from "./FactoriesListing";
import FactoryCheckbox from "./FactoryCheckbox";
import SizeQuantity from "./SizeQuantity/index";
import { flashError } from "store/actions/CommonActions";

import {
  requestQuote,
  updateQuoteRequest,
  updateQuoteFactories,
} from "store/actions/DesignActions";
import { getAllFactoriesForQuotes } from "store/actions/FactoryActions";

import { ReactComponent as RightArrow } from "assets/images/navigation/ic-chevron-right.svg";
import { ReactComponent as CalendarIcon } from "assets/images/action/ic-calendar-today.svg";

import "./styles.scss";
import {
  REQUEST_OPTIONS,
  QUOTE_REQ_EDIT_MODE,
} from "../../../../constants/Quote";
import { getDesignSizes } from "../../../../helpers/DesignHelpers";

const QUANTITY_LBL_VALUES = Object.entries(
  REQUEST_OPTIONS.quantityStr
).map(([value, label]) => ({ value, label }));

const YUP_NEW_QUOTE_REQUEST = (preApproved) => {
  const baseSchema = {
    quantity: Yup.number()
      .typeError("Quantity must be a number")
      .min(1)
      .max(99999999)
      .integer()
      .required("Required"),
    dueDate: Yup.date().min(Date()).required("Required"),
    quantityType: Yup.number(),
    sizesQuantity: Yup.array().when("quantityType", {
      is: +REQUEST_OPTIONS.quantity.sizeWiseQuantity,
      then: Yup.array()
        .of(
          Yup.object().shape({
            size: Yup.string(),
            quantity: Yup.number()
              .typeError("Quantity must be a number")
              .positive()
              .max(999999)
              .required(),
          })
        )
        .strict(),
    }),
  };
  if (preApproved) {
    return Yup.object().shape({
      ...baseSchema,
      preApprovedQuote: Yup.number()
        .typeError("Quote must be a number")
        .min(1)
        .max(99999)
        .required("Required")
    });
  } else {
    return Yup.object().shape({
      ...baseSchema,
      price: Yup.number()
        .typeError("Price must be a number")
        .min(1)
        .max(99999)
        .required("Required"),
      closureDate: Yup.date().required("Required"),
    });
  }
};

const YUP_EDIT_QUOTE_REQUEST = Yup.object().shape({
  quantity: Yup.number()
    .typeError("Quantity must be a number")
    .moreThan(0)
    .max(99999999)
    .required("Required"),
  price: Yup.number()
    .typeError("Price must be a number")
    .moreThan(0)
    .max(99999)
    .required("Required"),
  closureDate: Yup.date().required("Required"),
  dueDate: Yup.date().required("Required"),
  quantityType: Yup.number(),
  sizesQuantity: Yup.array().when("quantityType", {
    is: +REQUEST_OPTIONS.quantity.sizeWiseQuantity,
    then: Yup.array()
      .of(
        Yup.object().shape({
          size: Yup.string(),
          quantity: Yup.number()
            .typeError("Quantity must be a number")
            .positive()
            .max(999999)
            .required(),
        })
      )
      .strict(),
  }),
});

const filterSelectedFactories = (factories) =>
  factories.filter((factory) => factory.selected);

const transformInitialValues = ({
  quantity,
  price,
  due_date,
  closure_date,
  type,
  production_mode,
  sizes_quantity,
}) => {
  return {
    quantity,
    price,
    dueDate: due_date,
    type,
    closureDate: closure_date,
    productionMode: production_mode,
    quantityType:
      sizes_quantity && sizes_quantity.length > 0
        ? REQUEST_OPTIONS.quantity.sizeWiseQuantity
        : REQUEST_OPTIONS.quantity.totalQuantity,
    sizesQuantity: sizes_quantity,
  };
};

const filterFactoriesForSearch = (searchTerm, factories) =>
  factories.filter((factory) =>
    factory.merged.includes(searchTerm.toLowerCase())
  );

const QuoteRequestModal = ({
  design,
  requestQuote,
  getAllFactoriesForQuotes,
  factories: factoriesRaw,
  editMode = QUOTE_REQ_EDIT_MODE.NoEdit,
  requestDetails,
  updateQuoteRequest,
  updateQuoteFactories,
  flashError,
  quoteDetails,
  preApproved,
}) => {
  const [initialValues] = useState(
    requestDetails
      ? transformInitialValues(requestDetails)
      : {
          type: "1",
          productionMode: "1",
          quantityType: REQUEST_OPTIONS.quantity.totalQuantity,
        }
  );
  const [factories, setFactories] = useState([]); // Doesnt have any track of selection and filters
  const formRef = useRef();

  const [designSizes] = useState(getDesignSizes(design));
  const [activeIndex, setActiveIndex] = useState(() => {
    if (editMode === QUOTE_REQ_EDIT_MODE.EditDetails) {
      return 1;
    }

    return 0;
  });
  const [filteredFactories, setFilteredFactories] = useState(factories);
  const [selectedFactories, setSelectedFactories] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showPopover, setShowPopover] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const canEdit = useMemo(() => editMode !== QUOTE_REQ_EDIT_MODE.NoEdit, [
    editMode,
  ]);
  const wasAnyQuoteSubmitted = useMemo(
    () =>
      ((quoteDetails && quoteDetails[0] && quoteDetails[0].length) || 0) +
      ((quoteDetails && quoteDetails[2] && quoteDetails[2].length) || 0),
    [quoteDetails]
  );

  useEffect(() => {
    getAllFactoriesForQuotes({
      designId: design.id,
    });
  }, []);

  useEffect(() => {
    const preSelectedFactories = {};
    if (canEdit && requestDetails) {
      requestDetails.factories.map(
        (factory) => (preSelectedFactories[factory.factory_id] = true)
      );
    }
    const transformedFilters = factoriesRaw.map((factory) => {
      const preSelected = preSelectedFactories[factory.id] ? true : false;
      return {
        ...factory,
        selected: preSelected,
        disabled: preSelected,
        merged: `${factory.name}|${
          factory.addresses[0]
            ? `${factory.addresses[0].city}|${factory.addresses[0].machines}`
            : ""
        }|${factory.factoryDetail.category || ""}`.toLowerCase(),
      };
    });
    setFactories(transformedFilters);
    setFilteredFactories(transformedFilters);
  }, [factoriesRaw]);

  useEffect(() => {
    setSelectedFactories(filterSelectedFactories(factories));
  }, [factories]);

  const memoizedCallback = useCallback(
    (factoryId) => {
      setFactories(
        factories.map((factory) => {
          if (preApproved) {
            if (factory.id === factoryId) {
              return {
                ...factory,
                selected: true,
              };
            } else {
              return {
                ...factory,
                selected: false,
              };
            }
          } else {
            if (factory.id === factoryId) {
              return {
                ...factory,
                selected: factory.selected ? false : true,
              };
            }
            return factory;
          }
        })
      );
    },
    [factories]
  );

  let newlySelectedFactories = [];
  if (canEdit) {
    newlySelectedFactories = selectedFactories.reduce((acc, factory) => {
      if (factory.selected && !factory.disabled) {
        acc.push(factory.id);
      }
      return acc;
    }, []);
  }
  useEffect(() => {
    if (searchTerm.length > 0) {
      setFilteredFactories(filterFactoriesForSearch(searchTerm, factories));
    }
  }, [factories]);

  return (
    <React.Fragment>
      <section className="quote-request-modal">
        <ProgressBar
          activeIndex={activeIndex}
          handleClick={setActiveIndex}
          panels={[
            {
              heading: "Select factory",
              content: (
                <FactoriesListing
                  factories={
                    searchTerm.length > 0 ? filteredFactories : factories
                  }
                  setSelectedFactories={() =>
                    setSelectedFactories(filterSelectedFactories(factories))
                  }
                  selectFactory={memoizedCallback}
                  handleSearch={(searchTerm) => {
                    setSearchTerm(searchTerm);
                    setFilteredFactories(
                      filterFactoriesForSearch(searchTerm, factories)
                    );
                  }}
                  searchTerm={searchTerm}
                  preApproved={preApproved}
                />
              ),
              disabled: false,
              isVisible: editMode !== QUOTE_REQ_EDIT_MODE.EditDetails,
            },
            {
              heading: "Request details",
              content: (
                <div className="quote-details">
                  <Form
                    validationSchema={
                      canEdit
                        ? YUP_EDIT_QUOTE_REQUEST
                        : YUP_NEW_QUOTE_REQUEST(preApproved)
                    }
                    innerRef={formRef}
                    onSubmit={requestQuote}
                    initialValues={initialValues}
                    validateOnMount={true}
                    onValidationChange={(isValid) => setIsFormValid(isValid)}
                  >
                    {({ changeFormValue, values, ...formProps }) => (
                      <div className="quote-request-container">
                        {(canEdit || preApproved) && !wasAnyQuoteSubmitted && (
                          <Field
                            className="quantity-type"
                            component={Radio}
                            name="quantityType"
                            type="select"
                            options={QUANTITY_LBL_VALUES}
                          />
                        )}
                        {values.quantityType ===
                        REQUEST_OPTIONS.quantity.totalQuantity ? (
                          <Field
                            component={Input}
                            type="text"
                            name="quantity"
                            label="Order quantity (pcs)"
                            placeholder="Enter quantity"
                            required
                            disabled={wasAnyQuoteSubmitted}
                          />
                        ) : (
                          <>
                            <Field
                              component={SizeQuantity}
                              name="sizesQuantity"
                              required
                              readonly={wasAnyQuoteSubmitted}
                              sizes={designSizes}
                            />
                            <div className="field-separator" />
                          </>
                        )}
                        {!preApproved && (
                          <Field
                            component={Input}
                            type="text"
                            name="price"
                            label="Target price (in INR)"
                            placeholder="Enter target price"
                            required
                            disabled={wasAnyQuoteSubmitted}
                          />
                        )}

                        <Field
                          component={DatePicker}
                          className="v2"
                          name="dueDate"
                          type="date"
                          label="Tentative production due date"
                          placeholder="Select date"
                          minDate={moment
                            .max(
                              moment(values.closureDate).add(1, "days"),
                              new moment()
                            )
                            .toDate()}
                          required
                          customInput={({ value, onClick }) => (
                            <div
                              tabIndex="0"
                              className={"custom_datepicker formik_datepicker"}
                              type="date"
                              onClick={onClick}
                            >
                              {value || (
                                <span className="f-color-faded">
                                  Select date
                                </span>
                              )}
                              <CalendarIcon />
                            </div>
                          )}
                          // popperPlacement="top"
                        />
                        {!preApproved && (
                          <Field
                            component={DatePicker}
                            className="v2"
                            name="closureDate"
                            type="date"
                            label="Quote submission deadline"
                            placeholder="Select date"
                            maxDate={moment(values.dueDate)
                              .subtract(1, "days")
                              .toDate()}
                            required
                            customInput={({ value, onClick }) => (
                              <div
                                tabIndex="0"
                                className="custom_datepicker formik_datepicker"
                                type="date"
                                onClick={onClick}
                              >
                                {value || (
                                  <span className="f-color-faded">
                                    Select date
                                  </span>
                                )}
                                <CalendarIcon />
                              </div>
                            )}
                          />
                        )}

                        {preApproved && (
                          <Field
                            component={Input}
                            type="number"
                            name="preApprovedQuote"
                            label="Pre-approved quote"
                            placeholder="Enter pre-approved quote"
                            required
                          />
                        )}
                        {!preApproved && (
                          <Field
                            component={Radio}
                            name="type"
                            type="select"
                            label="Preferred quote type"
                            options={[
                              { label: "Bundled Quote", value: "1" },
                              { label: "Open Quote", value: "0" },
                            ]}
                            disabled={canEdit}
                          />
                        )}
                        <Field
                          component={Radio}
                          name="productionMode"
                          type="select"
                          label="Production mode"
                          options={[
                            { label: "FOB", value: "1" },
                            { label: "CMT", value: "0" },
                          ]}
                          disabled={wasAnyQuoteSubmitted}
                        />
                      </div>
                    )}
                  </Form>
                  <DesignCard card={design} />
                </div>
              ),
              disabled: canEdit
                ? newlySelectedFactories.length === 0
                : selectedFactories.length === 0,
            },
          ]}
        />
      </section>
      <div className="quote-request-footer modal__footer">
        <footer className="split-column">
          {editMode !== QUOTE_REQ_EDIT_MODE.EditDetails && !preApproved ? (
            selectedFactories.length > 0 ? (
              <p className="blu-title">
                <Popover
                  isOpen={showPopover}
                  position={"top"}
                  content={
                    <div className="selected-factories">
                      {selectedFactories.map((factory) => (
                        <FactoryCheckbox
                          factoryName={factory.name}
                          selected={factory.selected}
                          factoryId={factory.id}
                          handleClick={memoizedCallback}
                          disabled={factory.disabled}
                        />
                      ))}
                    </div>
                  }
                  onClickOutside={() => setShowPopover(!showPopover)}
                  containerStyle={{ marginLeft: "68px", marginTop: "-6px" }}
                  padding={10}
                >
                  <div onClick={() => setShowPopover(!showPopover)}>
                    {selectedFactories.length} factories selected{" "}
                    <i class="fa fa-caret-down mr-2" />
                  </div>
                </Popover>
              </p>
            ) : (
              <p>0 factories selected</p>
            )
          ) : (
            <span />
          )}
          {activeIndex === 0 ? (
            <Button
              category="btn primary-blue"
              className={`next-btn ${
                newlySelectedFactories.length > 0 ||
                (selectedFactories.length > 0 &&
                  editMode === QUOTE_REQ_EDIT_MODE.NoEdit)
                  ? ""
                  : "btn-disabled"
              }`}
              onClick={() => {
                if (
                  newlySelectedFactories.length > 0 ||
                  selectedFactories.length > 0
                ) {
                  setActiveIndex(1);
                }
              }}
            >
              Next <RightArrow />
            </Button>
          ) : (
            <Button
              category="btn primary-blue"
              // disabled={!formRef.current || !formRef.current.isValid}
              disabled={!isFormValid}
              onClick={() => {
                if (formRef.current && formRef.current.isValid) {
                  if (selectedFactories.length === 0) {
                    flashError("No factories selected");
                    return;
                  }

                  if (canEdit) {
                    const { values } = formRef.current;

                    const request = { ...values };

                    if (
                      request.quantityType ===
                      REQUEST_OPTIONS.quantity.totalQuantity
                    ) {
                      request.sizesQuantity = [];
                    } else {
                      request.sizesQuantity = (
                        request.sizesQuantity || []
                      ).map(({ size, quantity }) => ({ size, quantity }));
                    }

                    delete request.type;
                    delete request.quantityType;

                    updateQuoteRequest({
                      requestId: requestDetails.id,
                      ...request,
                    });

                    if (newlySelectedFactories.length > 0) {
                      updateQuoteFactories(
                        requestDetails.id,
                        {
                          factoryIds: newlySelectedFactories,
                        },
                        design.id
                      );
                    }
                  } else {
                    const { values } = formRef.current;

                    values.sizesQuantity = (
                      values.sizesQuantity || []
                    ).map(({ size, quantity }) => ({ size, quantity }));
                    delete values.quantityType;

                    requestQuote(
                      {
                        ...values,
                        designId: design.id,
                        factories: selectedFactories.map(
                          (factory) => factory.id
                        ),
                      },
                      design.name,
                      design.collection_id,
                      design.brand
                    );
                  }
                }
              }}
            >
              {canEdit ? "Update" : "Request Quote"}
            </Button>
          )}
        </footer>
      </div>
    </React.Fragment>
  );
};

export default connect(
  (state, props) => ({
    factories: props.factories || state.factory.factoriesForQuotes,
  }),
  {
    requestQuote,
    getAllFactoriesForQuotes,
    updateQuoteRequest,
    updateQuoteFactories,
    flashError,
  }
)(QuoteRequestModal);
