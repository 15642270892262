import React from "react";

const MaterialImage = ({ image }) => (
  <div className="material__image_wrapper_inner">
    <div className="material__image_big">
      <img src={image} alt="Swatch" className="material__image" />
    </div>
  </div>
);

export default MaterialImage;
