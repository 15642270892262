import { createAction } from "redux-actions";
import axios from "axios";
import { get_service_endpoint } from "../../ServiceEndpoints";

const eq = get_service_endpoint("notificationView");

const ORDERS_GET_ALL_ORDERS = createAction("ORDERS_GET_ALL_ORDERS");
const ORDERS_GET_BRANDS = createAction("ORDERS_GET_BRANDS");
const ORDERS_GET_FACTORIES = createAction("ORDERS_GET_FACTORIES");
const ORDERS_GET_DESIGNS = createAction("ORDERS_GET_DESIGNS");

let latestOrderToken = null;
export const getAllOrders = (params = {}) => dispatch => {
  const currentToken = Math.random();
  latestOrderToken = currentToken;
  axios
    .get(`${eq}/orders`, {
      params: {
        ...params,
        brandIds: Array.isArray(params.brandIds)
          ? params.brandIds.join(",")
          : null,
        factoryIds: Array.isArray(params.factoryIds)
          ? params.factoryIds.join(",")
          : null,
        searchText:
          params.searchText && params.searchText.length > 0
            ? params.searchText
            : null
      }
    })
    .then(res => {
      if (latestOrderToken === currentToken) {
        return dispatch(
          ORDERS_GET_ALL_ORDERS({
            data: res.data,
            refresh: params.init === 1
          })
        );
      }
    });
};

export const createOrder = ({
  brandId,
  addressId,
  designs,
  poNumber,
  deadline: dueDate,
  totalQuantity,
  brandRepName,
  poValue: totalAmount,
  poFile
}) => dispatch => {
  const data = new FormData();

  data.append("products", JSON.stringify(designs));
  data.append("poNumber", poNumber);
  data.append("addressId", addressId);
  // data.append("poDate", poDate);
  data.append("dueDate", dueDate);
  // data.append("paymentDueIn", paymentDueIn);
  data.append("totalQuantity", totalQuantity);
  data.append("totalAmount", totalAmount);
  // data.append("shippingMode", shippingMode);
  data.append("brandRepName", brandRepName);
  data.append("brandId", brandId);
  data.append("poFile", poFile);

  return axios.post(`${eq}/orders/`, data).then(res => {
    return {
      id: res.data.orderPurchaseId
    };
  });
};

export const getBrands = ({ init }) => dispatch => {
  axios
    .get(`${eq}/orders/brands`, {
      params: {
        limit: 0
      }
    })
    .then(res => {
      return dispatch(
        ORDERS_GET_BRANDS({
          data: res.data.rows,
          refresh: init === 1
        })
      );
    });
};

export const getFactories = ({ brandId, init }) => dispatch => {
  axios
    .get(`${eq}/orders/brands/${brandId}/factories`, {
      params: {
        limit: 0
      }
    })
    .then(res => {
      return dispatch(
        ORDERS_GET_FACTORIES({
          brandId,
          data: res.data.rows,
          refresh: init === 1
        })
      );
    });
};

export const getDesigns = ({ brandId, factoryId, init, limit }) => dispatch => {
  axios
    .get(`${eq}/orders/brands/${brandId}/factories/${factoryId}/designs`, {
      params: {
        limit
      }
    })
    .then(res => {
      return dispatch(
        ORDERS_GET_DESIGNS({
          brandId,
          factoryId,
          data: res.data,
          refresh: init === 1
        })
      );
    });
};
