import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import PrivateRoute from "./components/UI/PrivateRoute/PrivateRoute";
import AuthContainer from "./containers/AuthContainer/AuthContainer";
import BrandDashboardContainer from "./containers/BrandDashboardContainer/BrandDashboardContainer";
import FlashMessageContainer from "./containers/FlashMessageContainer/FlashMessageContainer";
import UploadProgressContainer from "./containers/UploadProgressContainer/UploadProgressContainer";
import ModalContainer from "./containers/ModalContainer/ModalContainer";
import TestContainer from "./containers/TestContainer/TestContainer";
import LoaderContainer from "./containers/LoaderContainer/LoaderContainer";
import FactoryDashboardContainer from "./containers/FactoryDashboardContainer/FactoryDashboardContainer";
import ReactTooltip from "react-tooltip";
import NotificationContainer from "./containers/NotificationContainer/NotificationContainer";
import { getCurrentUserRole } from "./helpers/AuthHelper";
import { LOCO_SUPER, FACTORY_ADMIN, FACTORY_MANAGER, BDE, FABRIC_SUPPLIER, PRODUCT_DEVELOPMENT, PRODUCTION_MERCHANT, ACCOUNT_MANAGER, SUPPLIER_MANAGER } from "./constants/Auth";
import PerfectScrollbar from "react-perfect-scrollbar";
import FabricSupplierContainer from "./containers/FabricSupplierContainer/FabricSupplierContainer";
import SalesDashboardContainer from "./containers/SalesDashboardContainer/SalesDashboardContainer";
import ProductDevelopmentContainer from "./containers/ProductDevelopmentContainer/ProductDevelopmentContainer";
import ProductMerchantContainer from "./containers/ProductMerchantContainer/ProductMerchantContainer";

const isPathMissingTrailingSlash = () => !window.location.pathname.endsWith("/");

const Routes = () => {
  // Fix for react tooltip
  // https://github.com/wwayne/react-tooltip/issues/40#issuecomment-147552438
  setTimeout(() => {
    ReactTooltip.rebuild();
  }, 1500);

  return (
    <PerfectScrollbar>
      <div className="lf-application-container">
        <FlashMessageContainer />
        <UploadProgressContainer />
        {
          // https://www.npmjs.com/package/react-tooltip
        }
        <ReactTooltip className="custom-tooltip" place="bottom" type="dark" effect="solid" />
        <Switch>
          {
            isPathMissingTrailingSlash()
              ? <Route
                path="/:url*"
                exact
                strict
                render={props => <Redirect to={{
                  pathname: `${props.location.pathname}/`,
                  state: props.location.state
                }} />}
              />
              : null
          }
          <Route
            // path="/(login|signup|forgotPassword|signupSuccess|forgotPasswordSuccess|logout)/"
            path="/auth/"
            render={routeProps => {
              return <AuthContainer {...routeProps} />;
            }}
          />

          <PrivateRoute
            path="/brand/"
            render={routeProps => {
              return getCurrentUserRole() !== LOCO_SUPER ? (
                <BrandDashboardContainer {...routeProps} />
              ) : (
                  <Redirect to="/factory/brands/" />
                );
            }}
          />
          <PrivateRoute
            path="/factory/"
            render={routeProps => {
              let userRole = getCurrentUserRole();
              return userRole === LOCO_SUPER || userRole === FACTORY_ADMIN || userRole === FACTORY_MANAGER || userRole === ACCOUNT_MANAGER ||  userRole === SUPPLIER_MANAGER ? (
                <FactoryDashboardContainer />
              ) : (
                  <Redirect to="/brand/collections/" />
                );
            }}
          />
          <PrivateRoute
            path="/supply/"
            render={routeProps => {
              let userRole = getCurrentUserRole();
              return userRole === FABRIC_SUPPLIER ? (
                <FabricSupplierContainer />
              ) : (
                  <Redirect to="/brand/collections/" />
                );
            }}
          />
          <PrivateRoute
            path="/sales/"
            render={routeProps => {
              let userRole = getCurrentUserRole();
              return userRole === BDE ? (
                <SalesDashboardContainer />
              ) : (
                  <Redirect to="/brand/collections/" />
                );
            }}
          />
          <PrivateRoute
            path="/manager/"
            render={routeProps => {
              let userRole = getCurrentUserRole();
              return userRole === PRODUCT_DEVELOPMENT ? (
                <ProductDevelopmentContainer />
              ) : (
                  <Redirect to="/brand/collections/" />
                );
            }}
          />
          <PrivateRoute
            path="/merchant/"
            render={routeProps => {
              let userRole = getCurrentUserRole();
              return userRole === PRODUCTION_MERCHANT ? (
                <ProductMerchantContainer />
              ) : (
                  <Redirect to="/brand/collections/" />
                );
            }}
          />
          <Route
            path="/notification/:notification_id/design/:design_id?"
            render={routeProps => <NotificationContainer {...routeProps} />}
          />
          <Route path="/testFileUpload" component={TestContainer} />
          <Redirect to="/auth/login" />
        </Switch>
        <ModalContainer />
        <LoaderContainer />
      </div>
    </PerfectScrollbar>
  );
};

export default Routes;
