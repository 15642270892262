import React from 'react';
import { Autosuggest } from '..';
import ProfilePlate from '../../ProfilePlate'
import './index.scss';


// Teach Autosuggest how to calculate suggestions for any given input value.
const filterSuggestions = (value, profiles) => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  return inputLength === 0
    ? []
    : profiles.filter(profile =>
      (profile.lowerName.toLowerCase().indexOf(inputValue) !== -1) ||
      (profile.lowerEmail.toLowerCase().indexOf(inputValue) !== -1)
    );
};

// Use your imagination to render suggestions.
const renderSuggestion = (profile) => (
  <ProfilePlate profile={profile} />
);

export default class ProfilePlates extends React.Component {
  state = {
    value: '',
    profiles: [],
    filteredProfiles: []
  }

  static getDerivedStateFromProps(nextProps) {
    if (Array.isArray(nextProps.profiles) && nextProps.profiles.length) {
      return {
        profiles: nextProps.profiles.map(profile => ({
          ...profile,
          lowerEmail: profile.email.toLowerCase(),
          lowerName: (profile.name || '').toLowerCase()
        }))
      }
    }

    return null;
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue
    });
  };

  render() {
    const { profiles } = this.state;
    const { onSelect } = this.props;

    return <div className="lf-ui-autosuggest autosuggest-profile-plates">
      <Autosuggest
        suggestions={profiles}
        filterSuggestions={filterSuggestions}
        renderSuggestion={renderSuggestion}
        onSelect={onSelect}
      />
    </div>;
  }
}
