import React from "react";
import PerfectScrollBar from "react-perfect-scrollbar";

const Address = ({
  data: { addressLine1, addressLine2, city, pinCode, state, index, noBorder }
}) => {
  return (
    <div
      style={{
        lineHeight: "20px",
        borderBottom: !noBorder ? "1px solid var(--fill-grey-light3)" : "",
        marginBottom: "8px"
      }}
    >
      <div>{`Factory ${index + 1}`}</div>

      <div style={{ padding: "12px", fontSize: "12px" }}>
        <div>{addressLine1}</div>
        <div>{addressLine2}</div>
        <div>
          <span>{city}</span>
          <span style={{ paddingLeft: "12px" }}>{pinCode}</span>
        </div>
        <div>{state}</div>
      </div>
    </div>
  );
};

const AddressesModal = ({ addresses = [] }) => {
  return (
    <div
      style={{
        minWidth: "300px",
        maxHeight: "400px",
        borderTop: "1px solid var(--fill-grey-medium)",
        position: "relative",
        marginTop: "12px"
      }}
    >
      <div
        style={{
          position: "absolute",
          top: -18,
          left: 24,
          color: "var(--fill-grey-dark)"
        }}
      >
        Factory Name
      </div>

      <PerfectScrollBar style={{ maxHeight: "380px", padding: "16px" }}>
        {addresses.map((address, index) => (
          <Address data={{ ...address, index, noBorder: !addresses.length }} />
        ))}
      </PerfectScrollBar>
    </div>
  );
};

export default AddressesModal;
