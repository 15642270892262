import React, { Component } from "react";

import RedButton from "../../Product/RedButton";

import { downloadZip } from "./../../../helpers/downloadFiles";

import axios from 'axios';

import { get_service_endpoint } from "../../../ServiceEndpoints";
import { canAddToDesign, canShareWithContacts } from "./../../../helpers/swatchHelper";

const swatchEp = get_service_endpoint("swatchLibrary");

const handleDownload = swatches => {
  const files = swatches.map(swatch=>({
    name: `${swatch.swatchId}-${swatch.name}`,
    url: swatch.image
  }))
  downloadZip(files);
  const swatchIds = swatches.map(s => s.id)
  axios.patch(`${swatchEp}/downloads`, { swatchIds }, { params: {
    isLoader: true
  }})
}

const BottomBar = ({
  count,
  onClear,
  openModal,
  modalKeys,
  style,
  isFactory,
  onlyAddToDesign = false,
  selectionLabel = "Swatches Selected",
  buttonLable = "Add to Design",
  download,
  swatches
}) => {

  const showBottomButton = buttonLable === "Add to Design" ? canAddToDesign() : true;
  const shareWithContacts = canShareWithContacts();

  return (
    <div
      style={{
        padding: "14px 158px 12px 58px",
        position: "fixed",
        right: 0,
        bottom: 0,
        left: "100px",
        display: "flex",
        flex: 1,
        flexGrow: 1,
        justifyContent: "space-between",
        alignItems: "center",
        boxShadow: "0 8px 16px 0 rgba(0, 1, 38, 0.5)",
        backgroundColor: "#fff",
        width: "100%",
        zIndex: 1000,
        ...style
      }}
    >
      <div style={{
        display: 'flex',
        alignItems: 'center'
      }}>
        <div>
          <div className={"f-sz-l f-w-bold"}>{`${count} ${selectionLabel}`} {

          }</div>
          <div
            style={{
              fontSize: "12px",
              color: "var(--fill-primary-dark)",
              lineHeight: "20px",
              cursor: "pointer"
            }}
            onClick={onClear}
          >
            Clear selection
          </div>
        </div>

      </div>
      <div style={{ display: "flex", alignItems: "center" }}>

        {isFactory && !onlyAddToDesign ? (
          <div
            style={{
              fontSize: "12px",
              color: "var(--fill-secondary-dark)",
              lineHeight: "20px",
              cursor: "pointer",
              padding: "10px",
              textTransform: "uppercase"
            }}
            onClick={() => openModal(modalKeys.shareKey)}
          >
            Share swatches
          </div>

        ) : null}
        {
          shareWithContacts &&
          (
            <div
              style={{
                fontSize: "12px",
                color: "var(--fill-secondary-dark)",
                lineHeight: "20px",
                cursor: "pointer",
                padding: "10px",
                textTransform: "uppercase"
              }}
              onClick={() => openModal(modalKeys.shareContactsKey)}
            >
              Share swatches
            </div>

          )
        }
        {
            download &&
            ( <span
              style={{
                fontSize: "12px",
                color: "var(--fill-secondary-dark)",
                lineHeight: "20px",
                cursor: "pointer",
                padding: "10px",
                textTransform: "uppercase",
                borderRadius: '50px',
                margin: '0 10px'
              }}
              className="btn--blue"
              onClick={() => {handleDownload(swatches)}}
            >
              <i className="fa fa-download" />&nbsp; &nbsp;Download
            </span> )
        }
        {
          showBottomButton && (
            <RedButton
              label={buttonLable}
              onClick={() => openModal(modalKeys.addKey)}
              noArrow
            />
          )
        }

      </div>
    </div>
  );
};

export default BottomBar;
