import React from 'react';
import DesignAvatarList from '../../Avatar/DesignAvatarList';
import './style.scss';

const CollectionCard = ({
  collection: {
    name,
  },
  designs = [],
  designCount,
  tag,
}) => {

  return <div className="ui-collection-card">
    <DesignAvatarList designs={designs} size="72px" count={designCount} />
    <div className="ui-collection-card__info">
      <div className="ui-collection-card__name overflow-ellipsis-line-2"
        data-tip={name}
        data-class="dark-tooltip over-modal"
      >{name}</div>
    </div>
    {tag && <div className="ui-collection-card__tag">
      {tag}
    </div>}
  </div>
};

export default CollectionCard;
