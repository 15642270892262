import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import Header from "../UI/Header";
import Table from "../UI/Table";
import FilterContainer from "./../UI/Filters";
import { DatePicker } from "./../UI/Filters/DateRangePicker";
import MultiSelect from "./../UI/Filters/MultiSelect";
import SingleSelect from "./../UI/Filters/SingleSelect";
import { getProductionReport } from "store/actions/ReportsAction";
import Loader from "components/UI/InlineLoader";

const LIMIT = 20;
const HEADINGS = [
  "DESIGN",
  "CREATED ON",
  "COLLECTION",
  "FACTORY",
  "QUANTITY",
  "PRODUCTION TAT",
  "CURRENT STATUS",
  "ON-TIME STATUS",
  "BUYER"
];

const TOOLTIP_DATA = {
  QUANTITY: `<div class="subtext">The confirmed quantity while requesting the PP sample</div>`,
  "PRODUCTION TAT": `<div class="subtext">The time taken to get the style shipped after the PP sample is approved</div>`,
  BUYER: `<div class="subtext">The user who created the design</div>`
};

const ProductionReport = ({
  getProductionReport,
  production = { list: [] },
  loading,
  userDetails,
  brandsList,
  isBrandManager
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [filters, setFilters] = useState({});
  const [dates, setDates] = useState({});

  useEffect(() => {
    getProductionReport({ isInit: 1 });
  }, []);

  const paginationHandler = values => {
    if (values.offset && production.list.length <= (currentIndex + 1) * LIMIT) {
      setCurrentIndex(currentIndex + 1);
      getProductionReport({
        offset: production.pagination.nextOffset,
        ...filters
      });
    } else {
      if (values.offset || (!values.offset && values.isnext === 1)) {
        setCurrentIndex(currentIndex + 1);
      } else if (values.isnext === 0) {
        setCurrentIndex(currentIndex - 1);
      }
    }
  };

  if (loading) return <Loader />;

  const getOverallReport = () => {
    return getProductionReport({
      ...filters,
      limit: production.pagination.totalCount,
      skipStore: true,
      inlineLoader: false
    }).then(data => {
      return data.records;
    });
  };

  return (
    <div className="report-content">
      <Header
        heading="Production report"
        reportType="production"
        pagination={production.pagination}
        currentIndex={currentIndex}
        paginationHandler={paginationHandler}
        getOverallReport={getOverallReport}
        headings={HEADINGS}
      />
      <FilterContainer
        updateFilters={() => {
          getProductionReport({
            ...filters,
            ...dates,
            isInit: 1
          });
          setCurrentIndex(0);
        }}
      >
        {userDetails.isSuperAdmin && (
          <SingleSelect
            label="Brand"
            filterOptions={brandsList}
            onChange={id => {
              setFilters({ ...filters, brandId: id ? id : null });
            }}
            selectedFilters={filters.brandId}
          />
        )}
        <DatePicker
          label="Date Range"
          updateFilterDates={dates => setDates(dates)}
          selectedDates={dates}
        />
        <MultiSelect
          label="Factory"
          filterOptions={production.filters.factories}
          onChange={ids => {
            setFilters({ ...filters, factoryIds: ids ? ids.join() : null });
          }}
          selectedFilters={filters.factoryIds}
        />
        {!isBrandManager && (
          <MultiSelect
            label="Buyer"
            filterOptions={production.filters.buyers}
            onChange={ids => {
              setFilters({ ...filters, creatorIds: ids ? ids.join() : null });
            }}
            selectedFilters={filters.creatorIds}
          />
        )}
        <MultiSelect
          label="Current Status"
          filterOptions={production.filters.currentStatus}
          onChange={ids => {
            setFilters({
              ...filters,
              productionStatus: ids ? ids.join() : null
            });
          }}
          selectedFilters={filters.productionStatus}
        />
        <MultiSelect
          label="On-time Status"
          filterOptions={production.filters.onTimeStatus}
          onChange={ids => {
            setFilters({ ...filters, onTimeStatus: ids ? ids.join() : null });
          }}
          selectedFilters={filters.onTimeStatus}
        />
      </FilterContainer>
      <Table
        headings={HEADINGS}
        tooltipData={TOOLTIP_DATA}
        data={production.list.slice(
          currentIndex * LIMIT,
          currentIndex * LIMIT + LIMIT
        )}
        gridTemplateColumns="
    minmax(200px, 1.66fr)
    minmax(120px, 1fr)
    minmax(170px, 1.4fr)
    minmax(170px, 1.4fr)
    minmax(140px, 1.2fr)
    minmax(155px, 1.3fr)
    minmax(140px, 1.2fr)
    minmax(140px, 1.2fr)
    minmax(155px, 1.3fr)
    "
      />
    </div>
  );
};

const mapStateToProps = state => ({
  production: state.reports.production,
  loading: state.reports.loading,
  userDetails: state.auth.userDetails
});

export default connect(mapStateToProps, {
  getProductionReport
})(ProductionReport);
