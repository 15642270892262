import React from 'react';
import { IconEmail, IconPhone } from '../UI/Icons/index';
import MonikaImg from '../../assets/images/account-managers/monika.jpeg';
import './style.scss';

const AM_MONIKA = {
  firstName: 'Monika',
  lastName: 'Ambesh',
  role: 'Account Manager',
  email: 'monika.ambesh@locofast.com',
  mobile: '+91 72899 64207',
}

export default class Help extends React.Component {
  state = {
    isModalVisible: false,
  };

  UNSAFE_componentWillReceiveProps(nextProps, oldProps) {
    if (nextProps.isVisible !== oldProps.isVisible) {
      if (nextProps.isVisible) {
        window.addEventListener("keydown", this.escHandler, false);
      } else {
        window.removeEventListener("keydown", this.escHandler, false);
      }
    }
  }

  escHandler = (event) => {
    if (event.keyCode === 27) {
      typeof (this.props.onClose) === 'function' && this.props.onClose();
    }
  }

  render() {
    const { isVisible, onClose } = this.props;

    return (<div className={`help-popup ${isVisible ? 'help-popup--show' : 'help-popup--hide'}`}>
      <div className="help-popup__backdrop" onClick={() => onClose()}></div>
      <div className="help-popup__container">
        <div className="help-popup__header">
          <div className="user-avatar">
            <div className="user-avatar__container">
              <img className="user-avatar__img" src={MonikaImg} alt="Monika (AM)" />
            </div>
            <div className="user-avatar__notch"></div>
          </div>
          <div className="user-info">
            <div className="user-info__name">
              {AM_MONIKA.firstName}&nbsp;{AM_MONIKA.lastName}
            </div>
            <div className="user-info__role">
              {AM_MONIKA.role}
            </div>
          </div>
        </div>
        <div className="help-popup__content">
          <div className="para-line greeting">
            Hi <span role="img">👋</span>
          </div>
          <div className="para-line">
            I am {AM_MONIKA.firstName}! I am your dedicated {AM_MONIKA.role}.
          </div>
          <div className="para-line">
            Should you need any help, please reach out at
          </div>
          <div className="para-line">
            <a href={`mailto:${AM_MONIKA.email}`}>
              <IconEmail />
              <span className="icon-text">{AM_MONIKA.email}</span>
            </a>
          </div>
          <div className="para-line">
            <a href={`tel:${AM_MONIKA.mobile}`}>
              <IconPhone />
              <span className="icon-text">{AM_MONIKA.mobile}</span>
            </a>
          </div>
          <div className="para-line">
            I will be happy to help you.
            <br /><br />
            Happy working!
          </div>
        </div>
      </div>
    </div>);
  }
}
