import { createAction } from "redux-actions";
import axios from "axios";
import { get_service_endpoint } from "../../ServiceEndpoints";
import { closeModal } from "./GlobalActions";
import { flash } from "./CommonActions";
import actions from ".";

import {
  withFactory
} from "./../../helpers/requestManager";

const ep = get_service_endpoint("collection");
const epNew = get_service_endpoint("notificationView");

const GET_COLLECTIONS_SUCCESS = createAction("GET_COLLECTIONS_SUCCESS");
const CREATE_COLLECTION_SUCCESS = createAction("CREATE_COLLECTION_SUCCESS");
const EDIT_COLLECTION_SUCCESS = createAction("EDIT_COLLECTION_SUCCESS");
const DELETE_COLLECTION_SUCCESS = createAction("DELETE_COLLECTION_SUCCESS");
const SELECTED_USER_COLLECTIONS = createAction("SELECTED_USER_COLLECTIONS");

const SELECT_COLLECTION_BY_ID = createAction("SELECT_COLLECTION_BY_ID");
const UPDATE_COLLECTIONS_BY_ID = createAction("UPDATE_COLLECTIONS_BY_ID");

const UPDATE_FACTORY_NAME = createAction("UPDATE_FACTORY_NAME");

export const getAllCollections = (params = {}) => dispatch => {
  return axios
    .all([
      axios.get(`${ep}/collection`, { params }),
      axios.get(`${ep}/collebratedcollection`)
    ])
    .then(dataAry => {
      // console.log(dataAry);
      return dataAry.map(res => {
        if (res.data.resp && res.data.resp.code === 404) return {};
        return res.data;
      });
    })
    .then(dataAry => {
      let newCollections = { ...dataAry[0], ...dataAry[1] };
      dispatch(
        GET_COLLECTIONS_SUCCESS(
          newCollections
          // Object.keys(data).map(collectionId => data[collectionId])
        )
      );
      return newCollections;
      // dispatch(getAllCollaboratedCollections()).then(collaboratedCollections =>
      //   dispatch(
      //     GET_COLLECTIONS_SUCCESS(
      //       { ...data, ...collaboratedCollections }
      //       // Object.keys(data).map(collectionId => data[collectionId])
      //     )
      //   )
      // );
    });
  // .catch(err => {
  //   if (err.status === 404) GET_COLLECTIONS_SUCCESS({});
  // });
  // Convert data from object with keys:objects into array mapping
  // Since each obj has the CollectionId property as well
  // .then(data => )
  // .then(data => )
};

const getAllCollaboratedCollections = () => dispatch => {
  return axios.get(`${ep}/collebratedcollection`);
};

export const createCollection = collectionDetails => dispatch => {
  return (
    axios
      .post(`${ep}/collection/`, collectionDetails)
      .then(res => {
        dispatch(CREATE_COLLECTION_SUCCESS());
        dispatch(
          actions.flash({
            message: `${collectionDetails.name} collection created.`,
            type: "action",
            actionType: "Create"
          })
        );
      })
      // .then(() => dispatch(getAllCollections()))
      .then(() => dispatch(closeModal()))
  );
};

export const editCollection = collectionDetails => dispatch => {
  return axios
    .patch(`${ep}/collection/`, collectionDetails)
    .then(res => dispatch(EDIT_COLLECTION_SUCCESS()))
    .then(() => dispatch(getAllCollections()))
    .then(() => dispatch(closeModal()));
};

export const deleteCollection = collectionDetails => dispatch => {
  return axios
    .delete(`${ep}/collection/`, { data: collectionDetails })
    .then(res => dispatch(DELETE_COLLECTION_SUCCESS()))
    .then(() => dispatch(getAllCollections()))
    .then(() => dispatch(flash({ message: "Collection deleted successfully" })))
    .then(() => dispatch(closeModal()));
};

export const getCollectionsForUser = userDetails => dispatch => {
  return (
    axios
      .all([
        axios.get(`${ep}/collection`, {
          params: {
            user_id: userDetails.id
          }
        }),
        dispatch(getAllCollaboratedCollections())
      ])
      // .then(resAry => {
      //   dispatch(SELECTED_USER_COLLECTIONS(resAry));
      // });
      .then(dataAry => {
        // console.log(dataAry);
        return dataAry.map(res => {
          if (res.data.resp && res.data.resp.code === 404) return {};
          return res.data;
        });
      })
      .then(dataAry => {
        let newCollections = { ...dataAry[0], ...dataAry[1] };
        dispatch(
          SELECTED_USER_COLLECTIONS(
            newCollections
            // Object.keys(data).map(collectionId => data[collectionId])
          )
        );
      })
  );
};

export const getCollectionById = collectionId => dispatch => {
  return axios
    .get(`${ep}/collection`, {
      params: {
        id: collectionId
      }
    })
    .then(res => {
      if (res.data.resp) {
        res.data = {};
      }
      return res.data;
    })
    .then((data) => {
      dispatch(SELECT_COLLECTION_BY_ID({ collections: data, collectionId }));
      return data;
    })
    .then((data) => dispatch(UPDATE_COLLECTIONS_BY_ID(data)));
};

// export const detachCollectionFromUser = (
//   userDetails,
//   collectionDetails
// ) => dispatch => {
//   console.log(userDetails);
//   let managers = [];
//   let admins = [];
//   Object.keys(collectionDetails.collebration)
//     .filter(userId => userId !== userDetails.id)
//     .forEach(userId => {
//       console.log(userId);
//       return collectionDetails.collebration[userId].role === 1
//         ? admins.push(collectionDetails.collebration[userId].userid)
//         : managers.push(collectionDetails.collebration[userId].userid);
//     });

//   const newCollaborations = {
//     admin: admins,
//     manager: managers
//   };

//   return axios
//     .patch(`${ep}/attachcollebration`, {
//       collebration: newCollaborations,
//       id: collectionDetails.id
//     })
//     .then(() => dispatch(closeModal()))
//     .then(() => dispatch(getCollectionsForUser(userDetails)));
// };

export const updateCollectionCollab = ({
  collectionId,
  collaborations
}) => dispatch => {
  let managers = [];
  let admins = [];
  collaborations.forEach(collab =>
    collab.role === 1
      ? admins.push(collab.userid)
      : managers.push(collab.userid)
  );

  const newCollaborations = {
    admin: admins,
    manager: managers
  };

  // console.log({
  //   collebration: newCollaborations,
  //   id: collectionId
  // });

  return axios
    .patch(`${ep}/attachcollebration`, {
      collebration: newCollaborations,
      id: collectionId
    })
    .then(() => dispatch(closeModal()))
    .then(() => dispatch(flash({ message: "Changes saved successfully." })))
    .then(() => dispatch(getCollectionById(collectionId)));
};

export const getCollectionsByBrandId = ({ id, factoryId }) => dispatch => {
  const computedParams = withFactory({ brandName: id, factoryId }, 'factoryId');
  return axios
    .get(`${ep}/getAllCollection`, { params: computedParams })
    .then(res => {
      if (res.data) {
        Object.keys(res.data).map(collectionId => {
          let collection = res.data[collectionId];
          res[collectionId] = collection;
          return collection;
        })
      }
      dispatch(GET_COLLECTIONS_SUCCESS(res.data));
      dispatch(createAction("BRAND_ID_IDENT")(res.data));
      return res.data;
    });
};

export const updateCollectionFactoryName = ({ id, factoryName }) => dispatch => {
  dispatch(UPDATE_FACTORY_NAME({ id, factoryName }));
}
