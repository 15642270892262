import React, { useState, useEffect } from "react";
import Dropdown from "react-dropdown";
import axios from "axios";
import { connect } from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";
import { withRouter } from "react-router-dom";

import Button from "../UI/Button/Button";
import DesignFilter from "./DesignFilter";
import actions from "../../store/actions";
import BootstrapError from "../UI/BootstrapError";
import PopOver from "./../UI/PopOver/PopOverInvert";

import styles from "./DetailsModel.module.scss";
import "react-dropdown/style.css";

import {
  FILTER_SWATCH_AVAILABILITY
} from "../../constants/SwatchLibrary";

import { get_service_endpoint } from "../../ServiceEndpoints";
import {
  getSmallImageUrl,
  getSmallImageUrlForCover
} from "../../helpers/DesignHelpers";

import {
  canAddToDesign,
  canEditSwatch,
  transformForEdit
} from "./../../helpers/swatchHelper";

const eq = get_service_endpoint("notificationView");
const ep = get_service_endpoint("collection");

const SwatchDetailsModel = ({
  isFactory,
  showDelete,
  dispatch,
  swatchid,
  brandid,
  onlyAddToDesign,
  details,
  history,
  vendors,
  deleteSwatch,
  collectionsProp,
  filterSwatches,
  closeModal,
  openModal,
  paginate
}) => {
  const [isAddToDesign, setIsAddToDesign] = useState(false);
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [allBrands, setallBrands] = useState([]);
  const [selectedBrand, setselectedBrand] = useState(
    onlyAddToDesign ? { brandid: details.brandId } : null
  );
  const [collections, setcollections] = useState([]);
  const [selectedCollection, setselectedCollection] = useState(
    onlyAddToDesign ? { id: details.collectionId } : null
  );
  const [designs, setDesigns] = useState([]);
  const [selectedDesign, setSelectedDesign] = useState(
    onlyAddToDesign ? { id: details.designId } : null
  );
  const [isAdded, setIsAdded] = useState(false);
  const [isError, setIsError] = useState({ message: "", value: false });
  const [swatch, setSwatch] = useState({});
  const [initialCollection, setInitialCollection] = useState(null);
  const [initialBrand, setInitailBrand] = useState(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  // == This effect pulls the swatch details ==
  useEffect(() => {
    axios
      .get(`${eq}/swatch`, { params: { swatchid } })
      .then(res => {
        setSwatch(res.data);
      })
      .catch();
  }, []);

  // === This effect gets the all brands on initial load ===
  useEffect(() => {
    if (isFactory) {
      axios
        .get(`${eq}/users/getallbrands`)
        .then(res => {
          setallBrands(res.data);

          if (onlyAddToDesign) {
            const { brandId } = details;

            let brandName;
            res.data.forEach(brand => {
              if (brand.id === brandId) {
                brandName = brand.name;
              }
            });

            setInitailBrand(brandName);
          }
        })
        .catch();
    }
  }, []);

  // === This effect gets the collections on every brand selection ===
  useEffect(() => {
    if (selectedBrand && isFactory && !onlyAddToDesign) {
      axios
        .get(`${ep}/getAllCollection`, {
          params: { brandName: selectedBrand.brandid }
        })
        .then(res => {
          const { data = [] } = res;
          if (data.resp && data.resp.code === 404) {
            if (collections.length) setcollections([]);
            return;
          } // resp comes when there is no collection for selected brand.

          const convertedCollections = Object.keys(data).map(key => data[key]);
          setcollections(convertedCollections);
          setselectedCollection(null);
        });
    }
  }, [selectedBrand]);

  // === Gets the collections from brand side ===
  useEffect(() => {
    if (!isFactory && !onlyAddToDesign) {
      axios
        .get(`${eq}/orders/collections`, {
          params: { brandid }
        })
        .then(res => {
          const { data = [] } = res;
          // if (data.resp && data.resp.code === 404) {
          //   if (collections.length) setcollections([]);
          //   return;
          // } // resp comes when there is no collection for selected brand.

          const convertedCollections = Object.keys(data).map(key => data[key]);
          setcollections(convertedCollections);
          setselectedCollection(null);
        });
    }
  }, [selectedBrand]);

  // === This effect pulls the designs for every collection change ===
  useEffect(() => {
    if (selectedCollection && !onlyAddToDesign) {
      axios
        .post(`${eq}/designsdetailsmini`, {
          collectionIds: [`${selectedCollection.id}`]
        })
        .then(res => {
          if (!Object.keys(res.data).length) {
            if (designs.length) setDesigns([]);
            return;
          }
          setDesigns(res.data);
        });
    }
  }, [selectedCollection]);

  // this effect is for file walaa flow ==
  useEffect(() => {
    if (onlyAddToDesign) {
      axios
        .get(`${eq}/orders/collections`, {
          params: { brandid: onlyAddToDesign ? details.brandId : brandid }
        })
        .then(res => {
          const { data = [] } = res;

          const convertedCollections = Object.keys(data).map(key => data[key]);
          let collectionName;
          convertedCollections.forEach(col => {
            if (col.id === details.collectionId) {
              collectionName = col.name;
            }
          });
          setInitialCollection(collectionName);
        });
    }
  }, []);

  // === This function shows the form ====
  const addDesignHandler = () => {
    if (!isAddToDesign) {
      setIsAddToDesign(true);
      setIsPopoverOpen(false);
      return;
    }

    // == This checks the added state === is already added ===
    if (isAdded && !onlyAddToDesign) {
      if (isFactory) {
        closeModal();
        history.push(
          `/${"factory"}/brands/${selectedBrand.brandid}/collections/${
            selectedCollection.id
          }/designs/${selectedDesign.id}/?tab=files`
        );
      } else {
        closeModal();
        history.push(
          `/${"brand"}/collections/${selectedCollection.id}/designs/${
            selectedDesign.id
          }/?tab=files`
        );
      }
    }

    if (isAdded && onlyAddToDesign) {
      closeModal();

      if (isFactory) {
        history.push(
          `/${"factory"}/brands/${details.brandId}/collections/${
            details.collectionId
          }/designs/${details.designId}/?tab=files`
        );
      } else {
        history.push(
          `/${"brand"}/collections/${details.collectionId}/designs/${
            details.designId
          }/?tab=files`
        );
      }
    }

    // == This checks the empty selections ===
    if (
      (isFactory && !selectedBrand) ||
      !selectedCollection ||
      !selectedDesign
    ) {
      setIsError({ value: true, message: "All fields are mandatory!" });

      setTimeout(() => {
        setIsError({ value: false, message: "All fields are mandatory!" });
      }, 800);
      return;
    }

    // === this is post here ==
    const request = {
      swatches: [swatchid], // here will be only single item
      brandId: onlyAddToDesign
        ? details.brandId
        : isFactory
        ? selectedBrand.brandid
        : brandid,
      collectionId: selectedCollection.id,
      designId: selectedDesign.id
    };

    axios
      .post(`${eq}/swatch/designs`, request)
      .then(res => {
        // dispatch(actions.flash({ message: "Added successfully." }));
        // dispatch(actions.closeModal());
        setIsAdded(true);

        // if (onlyAddToDesign) {
        //   dispatch(actions.closeModal());

        //   if (isFactory) {
        //     history.push(
        //       `/${"factory"}/brands/${details.brandId}/collections/${
        //         details.collectionId
        //       }/designs/${details.designId}/?tab=files`
        //     );
        //   } else {
        //     history.push(
        //       `/${"brand"}/collections/${details.collectionId}/designs/${
        //         details.designId
        //       }/?tab=files`
        //     );
        //   }
        // }
      })
      .catch();
  };

  const backButtonHandler = () => setIsAddToDesign(false);

  const changeImageHandler = (index) => {
    setActiveImageIndex(index);
  };

  const editSwatchHandler = () => {
    const initialValues = transformForEdit(swatch);
    // close model
    closeModal();
    // open edit model
    openModal("EDIT_SWATCH", { initialValues, vendors, afterSubmit: () => filterSwatches({
      dateFilter: null,
      brandId: null,
      search: null,
    }, paginate.currentPageOffSet)});
  };

  let {
    vendorPrices = [],
    threadCount,
    construction,
    weight,
    weave,
    fabricWidth,
    finish,
    shrinkage,
    fabricType,
    swatch_id,
    fabricQuality,
    image,
    rulerImage,
    name = "Default",
    extraImages = [],
    fabricAvailability,
    quantity,
    idealFor,
    fabricCategory,
    swatchAvailable,
    yardageAvailable,
    oldId,
    unit,
    imported
    } = swatch;

  const thumnailFirst = image ? getSmallImageUrl(image) : "";
  const thumnailSecond = rulerImage ? getSmallImageUrl(rulerImage) : "";

  const cover1 = image ? getSmallImageUrlForCover(image) : "";
  const cover2 = rulerImage ? getSmallImageUrlForCover(rulerImage) : "";

  const thumnailImages = [thumnailFirst, thumnailSecond];
  const largeImages = [cover1, cover2, ...extraImages];

  const IDEAL_FOR = {
    0 : 'Top',
    1 : 'Bottom',
    2 : 'Top, Bottom'
  }

  const showAddToDesign = canAddToDesign();
  const showEdit = canEditSwatch()

  return (
    <PerfectScrollbar style={{ maxHeight: "85vh" }}>
      <div className={styles.swatch__details}>
        <div className={styles.swatch__image_wrapper}>
          <div className={styles.swatch__image_wrapper_inner}>
            <div className={styles.swatch__image_thumnail_wrapper}>
              <div className={styles.swatch__image_thumnail_wrapper_inner}>
                <img
                  src={thumnailImages[0]}
                  alt="Thumnail"
                  className={styles.swatch__thumnail_image}
                  onClick={()=>changeImageHandler(0)}
                  style={{
                    borderBottom:
                      activeImageIndex === 0
                        ? "2px solid var(--fill-secondary-dark)"
                        : "2px solid #fff"
                  }}
                />
              </div>

              <div className={styles.swatch__image_thumnail_wrapper_inner}>
                <img
                  src={thumnailImages[1]}
                  alt="Thumnail"
                  className={styles.swatch__thumnail_image}
                  onClick={()=>changeImageHandler(1)}
                  style={{
                    borderBottom:
                      activeImageIndex === 1
                        ? "2px solid var(--fill-secondary-dark)"
                        : "2px solid #fff"
                  }}
                />
              </div>
              {
                  extraImages && extraImages.map((image, index)=>(
                    <div className={styles.swatch__image_thumnail_wrapper_inner}>
                    <img
                      src={image}
                      alt="Thumnail"
                      className={styles.swatch__thumnail_image}
                      onClick={()=>changeImageHandler(index+2)}
                      style={{
                        maxWidth: '100px',
                        borderBottom:
                          activeImageIndex === index+2
                            ? "2px solid var(--fill-secondary-dark)"
                            : "2px solid #fff"
                      }}
                    />
                  </div>
                  ))
              }
            </div>
            <div className={styles.swatch__image_big}>
              <img
                src={largeImages[activeImageIndex]}
                alt="Swatch"
                style={{maxWidth : "350px", maxHeight : "410px"}}
                className="swatch__image"
              />
            </div>
          </div>
          <div className={styles.swatch__details_form}>
            <div className={styles.swatch__details_form_upper}>
              <div className={styles.swatch__name}>{`${name}`}</div>
              { !isAddToDesign && showDelete &&
              <PopOver
                // isOpen={isPopoverOpen}
                visible={isPopoverOpen}
                content={
                  <div>
                    <p>Are you sure want to delete this swatch?</p>
                    <div>
                      <Button category="blue-bg" onClick={()=> {deleteSwatch({id:swatchid});setIsPopoverOpen(false)}}>Delete</Button>
                      <Button category="blue" onClick={()=>{setIsPopoverOpen(false)}}>Cancel</Button>
                    </div>
                  </div>
                }
                >
                <Button
                  category="plain-blue"
                  style={{
                    marginTop: '10px'
                  }}
                  onClick={()=>setIsPopoverOpen(true)}
                >
                  <span
                    style={{
                      fontWeight: "600",
                      textTransform: "uppercase",
                      letterSpacing: "1px"
                    }}
                  >
                    <i className="fa fa-trash" aria-hidden="true" style={{ paddingRight: '8px', fontSize: '15px'}}></i>
                    Delete Swatch
                  </span>
              </Button>
              </PopOver>
              }
              {isAddToDesign && !isAdded ? (
                <>
                  <div
                    style={{
                      color: "var(--fill-secondary-dark)",
                      padding: "16px 0px",
                      fontSize: "12px",
                      fontWeight: "600",
                      cursor: "pointer",
                      width: "60px",
                    }}
                    onClick={backButtonHandler}
                  >
                    {`< Back`}
                  </div>

                  <div style={{ height: "40px" }} />
                  {/* ==== DROP DOWNS FROM HERE BRAND ==== */}
                  {onlyAddToDesign && isFactory ? (
                    <div
                      style={{
                        fontSize: "12px",
                        color: "var(--fill-grey-medium)",
                        padding: "0 0 3px 10px"
                      }}
                    >
                      Brand
                    </div>
                  ) : null}
                  {isFactory ? (
                    <div className={styles.dropdown__wrapper_custom}>
                      <Dropdown
                        options={allBrands.map(brand => ({
                          label: brand.name,
                          value: brand.id
                        }))}
                        onChange={({ value, label }) => {
                          setselectedBrand({
                            brandid: value,
                            brandName: label
                          });
                        }}
                        value={
                          onlyAddToDesign
                            ? initialBrand
                            : selectedBrand
                            ? selectedBrand.brandName
                            : "Select Brand"
                        }
                        placeholder={"Select brand"}
                        // className={styles.dropdown__styles}
                        // menuClassName={styles.dropdown__styles_menu}
                        style={{ cursor: "pointer" }}
                        disabled={onlyAddToDesign || isAdded}
                      />
                    </div>
                  ) : null}

                  {/* ==== DROP DOWNS FROM HERE Collection ==== */}
                  {onlyAddToDesign && (
                    <div
                      style={{
                        fontSize: "12px",
                        color: "var(--fill-grey-medium)",
                        padding: "0 0 3px 10px"
                      }}
                    >
                      Collection
                    </div>
                  )}
                  <div className={styles.dropdown__wrapper_custom}>
                    <Dropdown
                      options={collections.map(({ name, id }) => ({
                        label: name,
                        value: id
                      }))}
                      onChange={({ label, value }) => {
                        setselectedCollection({ name: label, id: value });
                      }}
                      value={
                        onlyAddToDesign
                          ? initialCollection
                          : selectedCollection
                          ? selectedCollection.name
                          : "Select Collection"
                      }
                      placeholder={"Select one"}
                      disabled={
                        (onlyAddToDesign
                          ? true
                          : isFactory
                          ? !selectedBrand
                          : false) || isAdded
                      }
                      controlClassName={`Dropdown-${
                        isFactory ? selectedBrand : true ? "active" : "disabled"
                      }`}
                    />
                  </div>

                  {onlyAddToDesign && (
                    <div
                      style={{
                        fontSize: "12px",
                        color: "var(--fill-grey-medium)",
                        padding: "0 0 3px 10px"
                      }}
                    >
                      Design
                    </div>
                  )}
                  <div className={styles.dropdown__wrapper_custom_design}>
                    <DesignFilter
                      title={"title"}
                      initialSelectedItem={{
                        label: onlyAddToDesign
                          ? details.designName
                          : "Select Design",
                        value: null
                      }}
                      onChange={({ value }) => {
                        setSelectedDesign(value);
                      }}
                      filterOptions={designs.map(design => ({
                        label: design.name,
                        value: design
                      }))}
                      disable={
                        onlyAddToDesign || !selectedCollection || isAdded
                      }
                    />
                  </div>
                </>
              ) : isAddToDesign ? (
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    textAlign: "center",
                    color: "var(--fill-green-dark)",
                    padding: "12px",
                    backgroundColor: "var(--fill-grey-light5)",
                    marginTop: "30px"
                  }}
                >
                  SWATCH ADDED TO DESIGN
                </div>
              ) : null}
            </div>
            {
              showAddToDesign && (
              <div>
                <BootstrapError message={isError.message} show={isError.value} />
                <Button
                  category="blue-bg"
                  style={{
                    width: "100%",
                    padding: "16px"
                  }}
                  onClick={addDesignHandler}
                >
                  <span
                    style={{
                      fontWeight: "600"
                    }}
                  >
                    {isAdded ? "VIEW DESIGN" : "ADD TO DESIGN"}
                  </span>
                </Button>
              </div>
              )
            }

          </div>
        </div>

        {/* ======  THESE ARE SWATCH DETAILS ===== */}
        <div className={styles.swatch__description_wrapper}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              paddingTop: "60px"
            }}
          >
            <div className={styles.swatch__description_heading}>
              Swatch Details
            </div>
            {showEdit ? (
              <div onClick={editSwatchHandler}>
                <span
                  style={{
                    fontWeight: "600",
                    color: "#d32f2f",
                    cursor: "pointer",
                    padding: "0px 20px",
                    letterSpacing: "1px"
                    // marginLeft: "30px"
                  }}
                >
                  {"EDIT"}
                </span>
              </div>
            ) : null}
          </div>

          {/* <div className={styles.swatch__description}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque
          odio enim repellendus ipsa, sint itaque sequi deserunt pariatur veniam
          consectetur qui iste eos officiis tenetur, hic numquam nobis possimus
          repellat!
        </div> */}
        </div>
        <div
          className={styles.swatch__table_wrapper}
          style={{ marginRight: "150px" }}
        >
          {fabricType && (
            <div className={styles.swatch__table_item}>
              <div className={styles.swatch__table_item_label}>Pattern</div>
              <div className={styles.swatch__table_item_value}>
                {fabricType &&
                  `${ fabricType.label}`}
              </div>
            </div>
          )}

          <div className={styles.swatch__table_item}>
            <div className={styles.swatch__table_item_label}>Quality</div>
            <div className={styles.swatch__table_item_value}>
              {fabricQuality &&
                `${ fabricQuality.label}`}
            </div>
          </div>

          {(threadCount || construction || weight) && (
            <div className={styles.swatch__table_item}>
              <div className={styles.swatch__table_item_label}>
                Count/Construction/Weight
              </div>
              <div className={styles.swatch__table_item_value}>
                {threadCount || construction || weight}
              </div>
            </div>
          )}

          {weave ? (
            <div className={styles.swatch__table_item}>
              <div className={styles.swatch__table_item_label}>Weave</div>
              <div className={styles.swatch__table_item_value}>
                {weave.label}
              </div>
            </div>
          ) : null}

        {swatch_id ? (
            <div className={styles.swatch__table_item}>
              <div className={styles.swatch__table_item_label}>Swatch ID</div>
              <div className={styles.swatch__table_item_value}>
              {swatch_id}
              </div>
            </div>
          ) : null}

          {oldId ? (
            <div className={styles.swatch__table_item}>
              <div className={styles.swatch__table_item_label}>Old ID</div>
              <div className={styles.swatch__table_item_value}>
              {oldId}
              </div>
            </div>
          ) : null}

          {fabricWidth && (
            <div className={styles.swatch__table_item}>
              <div className={styles.swatch__table_item_label}>
                Fabric Width
              </div>
              <div className={styles.swatch__table_item_value}>
                {fabricWidth}
              </div>
            </div>
          )}

          {finish && (
            <div className={styles.swatch__table_item}>
              <div className={styles.swatch__table_item_label}>Finish</div>
              <div className={styles.swatch__table_item_value}>{finish}</div>
            </div>
          )}

          {shrinkage && (
            <div className={styles.swatch__table_item}>
              <div className={styles.swatch__table_item_label}>Shrinkage</div>
              <div className={styles.swatch__table_item_value}>{shrinkage}</div>
            </div>
          )}

          {fabricAvailability != null && (
            <>
            <div className={styles.swatch__table_item}>
              <div className={styles.swatch__table_item_label}>Availability</div>
              <div className={styles.swatch__table_item_value}>
              {fabricAvailability!= null &&
                    `${
                      FILTER_SWATCH_AVAILABILITY[fabricAvailability]["label"]
                    }`}
              </div>
            </div>
            <div className={styles.swatch__table_item}>
              <div className={styles.swatch__table_item_label}>{ FILTER_SWATCH_AVAILABILITY[fabricAvailability]["value"] }</div>
              <div className={styles.swatch__table_item_value}>
                {quantity}
              </div>
            </div>
            </>
          )}

          {unit && (
            <div className={styles.swatch__table_item}>
              <div className={styles.swatch__table_item_label}>Unit of Measurement</div>
              <div className={styles.swatch__table_item_value}>{unit}</div>
            </div>
          )}

          {fabricCategory && (
            <div className={styles.swatch__table_item}>
              <div className={styles.swatch__table_item_label}>Fabric category</div>
              <div className={styles.swatch__table_item_value}>{fabricCategory}</div>
            </div>
          )}

          {idealFor != null && (
            <div className={styles.swatch__table_item}>
              <div className={styles.swatch__table_item_label}>Ideal for</div>
              <div className={styles.swatch__table_item_value}>{IDEAL_FOR[idealFor]}</div>
            </div>
          )}

          {swatchAvailable != null && (
            <div className={styles.swatch__table_item}>
              <div className={styles.swatch__table_item_label}>Swatch available</div>
              <div className={styles.swatch__table_item_value}>{swatchAvailable ? "Yes" : "No"}</div>
            </div>
          )}

          {yardageAvailable != null && (
            <div className={styles.swatch__table_item}>
              <div className={styles.swatch__table_item_label}>Yardage available</div>
              <div className={styles.swatch__table_item_value}>{yardageAvailable ? "Yes" : "No"}</div>
            </div>
          )}


        {imported != null && (
          <div className={styles.swatch__table_item}>
            <div className={styles.swatch__table_item_label}>imported</div>
            <div className={styles.swatch__table_item_value}>{imported ? "Yes" : "No"}</div>
          </div>
        )}

        </div>



        {/* ======  THESE ARE VENDOR DETAILS ===== */}
        {vendorPrices.length ? (
          <>
            <div
              className={styles.swatch__description_wrapper}
              style={{ paddingTop: "32px" }}
            >
              <div className={styles.swatch__description_heading}>
                Vendor Details
              </div>
              {/* <div className={styles.swatch__description}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque
          odio enim repellendus ipsa, sint itaque sequi deserunt pariatur veniam
          consectetur qui iste eos officiis tenetur, hic numquam nobis possimus
          repellat!
        </div> */}
            </div>

            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",

                marginRight: "150px",
                // backgroundColor: "#f5f5f5",

                border: "1px solid var(--fill-grey-light)",
                borderBottom: "none",
                marginTop: "12px"
              }}
            >
              <div
                style={{
                  padding: "8px",
                  fontWeight: 600,
                  borderRight: "1px solid #f5f5f5"
                }}
              >
                Name
              </div>
              <div
                style={{
                  padding: "8px",
                  fontWeight: 600
                }}
              >
                Price
              </div>
            </div>
            <div
              className={styles.swatch__table_wrapper}
              style={{ marginRight: "150px", marginTop: "0px" }}
            >
              {vendorPrices.map(({ vendorName, price, id }) => (
                <div className={styles.swatch__table_item} key={id}>
                  <div className={styles.swatch__table_item_label_vendor}>
                    {vendorName}
                  </div>
                  <div className={styles.swatch__table_item_value}>{price}</div>
                </div>
              ))}
            </div>
          </>
        ) : null}
      </div>
    </PerfectScrollbar>
  );
};

const mapStateToProps = ({ swatchLibrary, collections }) => ({
  vendors: swatchLibrary.vendors,
  collectionsProp: collections,
  paginate : swatchLibrary.paginate
});

const mapDispatchToProps = dispatch => ({
  filterSwatches: (details, pageOffset = null) => dispatch(actions.filterSwatches(details, pageOffset)),
  openModal: (payload, additionalProps) =>
    dispatch(actions.openModal(payload, additionalProps)),
  closeModal: () => dispatch(actions.closeModal())
})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SwatchDetailsModel)
);
