import React, { Component } from "react";
import Downshift from "downshift";
import icDropdown from "assets/images/navigation/ic-arrow-drop-down.svg";
import PerfectScrollbar from "react-perfect-scrollbar";

import "./styles.scss";

const Select = ({
  input,
  options,
  label,
  required,
  placeholder,
  form: { touched, errors, setFieldValue },
  field
}) => {
  const { name } = field;

  return (
    <div className="formik-field formik_select">
      <label>
        {label}
        {required && <sup>*</sup>}
      </label>
      <Downshift
        itemToString={item => item && item.label}
        onChange={selection => {
          setFieldValue(name, selection.value);
        }}
      >
        {({
          getInputProps,
          getToggleButtonProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          isOpen,
          inputValue,
          highlightedIndex,
          selectedItem
        }) => {
          return (
            <div
              {...getToggleButtonProps()}
              type="none"
              className="formik_select__block"
              tabIndex="0"
            >
              <span className="flex center">
                <input
                  className="select-input-transparent"
                  readOnly={true}
                  disabled={true}
                  {...input}
                  placeholder={placeholder}
                  value={selectedItem && selectedItem.label}
                />
                <img src={icDropdown} alt="dropdown" />
              </span>

              {isOpen ? (
                <div
                  className="select-pane"
                  onClick={e => {
                    e.stopPropagation();
                  }}
                  {...getMenuProps()}
                >
                  <div>
                    <PerfectScrollbar style={{ maxHeight: "240px" }}>
                      {options
                        // .filter(item => item.label !== selectedItem.label)
                        .map((item, index) => {
                          return (
                            <div
                              {...getItemProps({
                                className: `in-selection collection-collab__label_left_aigned flex ${
                                  selectedItem == item ||
                                  highlightedIndex == index
                                    ? "selected"
                                    : ""
                                }`,
                                index,
                                item,
                                key: item
                              })}
                              key={index}
                            >
                              {item.label}
                            </div>
                          );
                        })}
                    </PerfectScrollbar>
                  </div>
                </div>
              ) : null}
            </div>
          );
        }}
      </Downshift>
      {touched[field.name] &&
        errors[field.name] && <div className="error">{errors[field.name]}</div>}
    </div>
  );
};

export default Select;
