import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Table from "../../../UI/Table/CustomTable";
import { DATESTAMP_FORMAT } from "../../../../constants/Date";
import PerfectScrollBar from "react-perfect-scrollbar";
import SampleFiles from "../SampleFiles";
import "./style.scss";

export default class SampleVersionHistory extends Component {
  static propTypes = {
    sampleVersions: PropTypes.object
  };

  render() {
    const versions = this.props.sampleVersions;
    const { design } = this.props;

    return (
      <div className="sample-version-history">
        <PerfectScrollBar style={{ maxHeight: '600px' }}>
          <Table className="v2 sample-version-history__table" headers={["Status", "Remarks", ""]}>
            {versions.map(version => (
              <tr>
                <td className="cell-v2">
                  <div className="f-sz-l f-w-semi-bold" style={{ textTransform: "capitalize" }}>
                    {version['sampleStatus'] ? version['sampleStatus'] : "Sample Requested"}
                  </div>
                  <div className="f-color-faded">
                    {moment(version['updatedAt']).format(DATESTAMP_FORMAT)}, {version['updatedBy']['name']}
                  </div>
                </td>

                <td className="f-color-faded sample-version-history__comments">
                  {
                    version['remarks'] && version['remarks']['comment'] &&
                    <div style={{ textTransform: "capitalize" }}>
                      {version['remarks']['comment']}
                    </div>
                  }
                </td>
                <td className="sample-version-history__files">
                  {<SampleFiles
                    designName={design && design.name}
                    files={version.files}
                  />}
                </td>
              </tr>
            ))}
          </Table>
        </PerfectScrollBar>
      </div>
    );
  }
}
