import { PropTypes } from "prop-types";
import React, { Fragment } from "react";
import { USER_STATUSES, getUserStatus } from '../../../helpers/userHelpers';
import { useHover } from "../../../customHooks/useHover";
import editIcon from "./../../../assets/images/action/ic-edit.svg";
import { computeColors } from "../../../helpers/colorHelper";

const brandRoles = { "2": "Admin", "3": "Buyer" };
const RowRenderer = ({
  renderBaseRow,
  openModal,
  updateUser,
  userData,
  updateAssociation,
  ...props
}) => {
  const [ref] = useHover(false);

  const handleClick = () => {
    openModal("MANAGE_FACTORY_EDIT_NEW_USER", {
      initialValues: userData,
      editMode: true,
      updateUser
    });
  };

  return (
    <div className="footer-row" ref={ref}>
      {props.children}
      <div className="footer-row-image">
        <img src={editIcon} onClick={handleClick} alt="" />
      </div>
    </div>
  );
};

const FactoryRenderer = ({
  usersList,
  openModal,
  updateUser,
  updateAssociation
}) => {
  const rows = usersList.map(u => {
    const { image, name = "LF", email, phone, role, id, isEnabled } = u;
    const status = getUserStatus(u);
    return {
      name: (
        <div className="user__profile">
          <div className="img-container">
            {image ? (
              <img
                src={image}
                style={{ width: "100%", height: "100%", borderRadius: "50%" }}
                alt="LF"
              />
            ) : (
                <div
                  style={{ background: computeColors(id) }}
                  className="user__profile_image"
                >
                  {name ? name.split(" ").map(nm => nm[0]) : "LF"}
                </div>
              )}
          </div>
          <div>
            <p>{name}</p>
            <p className="p-grey">{brandRoles[role]}</p>
          </div>
        </div>
      ),
      contact: (
        <React.Fragment>
          <div className="user__contacts">
            <p className="f-w-bold">{email}</p>
            <br />
            <p>{phone}</p>
          </div>
        </React.Fragment>
      ),
      status: (
        <div className="user__activity">
          <p>
            <span className={`user-status ${USER_STATUSES[status].id}`}>{USER_STATUSES[status].name}</span>
          </p>
        </div>
      )
    };
  });
  return rows.map(({ name, contact, status }, index) => (
    <Fragment>
      <RowRenderer
        openModal={openModal}
        userData={usersList[index]}
        updateUser={updateUser}
        updateAssociation={updateAssociation}
      >
        <div>{name}</div>
        <div></div> {/* Left empty intentionally for proper spacing in UI */}
        <div>{contact}</div>
        <div>{status}</div>
      </RowRenderer>
    </Fragment>
  ));
};

FactoryRenderer.propTypes = {
  usersList: PropTypes.array.isRequired,
  updateUser: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired
};

export default FactoryRenderer;
