import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import PerfectScrollbar from "react-perfect-scrollbar";
import RedButton from "../Product/RedButton";

import styles from "../Product/Product.module.scss";

import { get_service_endpoint } from "../../ServiceEndpoints";
import axios from "axios";

import thrashIcon from "../../assets/images/action/ic-delete.faded.svg";
import actions from "../../store/actions";
import { getSmallImageUrl } from "../../helpers/DesignHelpers";
import { CURRENCY_SYMBOL } from "../../constants/Design";
import { formatNumber, formatDecimals } from "../../helpers/i18nHelper";

const eq = get_service_endpoint("notificationView");

const EDITABLE_STATUS = "Under Production";

const OrderItem = ({
  design: {
    designName,
    sourceDesignId,
    designId,
    quantity,
    isDeleted,
    imageUrl
  },
  design,
  saveDesignQuantity,
  nextErr,
  deleteDesignHandler,
  isFactory,
  orderStatus
}) => {
  let smallImage;
  if (imageUrl) {
    smallImage = getSmallImageUrl(imageUrl);
  }

  const isNonEditable =
    isDeleted || isFactory || orderStatus !== EDITABLE_STATUS;

  return (
    <div
      className={styles.order__item_wrapper_view_design}
      style={{ backgroundColor: isDeleted ? "#f5f5f5" : "#fff" }}
    >
      <div className={styles.order__item_info}>
        <div className={styles.order__item_image_wrapper}>
          <img
            src={smallImage}
            alt="Design"
            className={styles.order__item_image}
          />
        </div>
        <div className={`${styles.order__item_details} cell-v2`}>
          <div>{designName}</div>
          <div className={styles.order__item_id}>
            {sourceDesignId}
          </div>
        </div>
      </div>
      <div className={styles.order__item_qty} style={{ alignItems: "center" }}>
        {
          isNonEditable
            ? formatNumber(design.quantity) + ' pcs'
            : <input
              type="number"
              name="orderQuantity"
              id="orderQuantity"
              disabled={isNonEditable}
              className={styles.order__item_qty_input}
              onChange={ev => {
                saveDesignQuantity(designId, ev.target.value);
              }}
              value={design.quantity}
              style={{
                border:
                  !quantity && nextErr && !isDeleted ? "1px solid #ff0000" : ""
              }}
            />
        }
      </div>
      <div className={styles.order__item_qty} style={{ alignItems: "center" }}>
        {
          `${CURRENCY_SYMBOL[1]} ${formatDecimals(design.quoteAmount)}`
        }
        {!isNonEditable ? (
          !isDeleted ? (
            <img
              src={thrashIcon}
              alt="delete"
              className={styles.delete__design_icon}
              onClick={() => deleteDesignHandler(designId)}
              style={{
                padding: "10px",
                position: "absolute",
                right: "30px",
                cursor: "pointer"
              }}
            />
          ) : (
              <span
                onClick={() => deleteDesignHandler(designId)}
                style={{
                  padding: "10px",
                  position: "absolute",
                  right: "30px",
                  cursor: "pointer"
                }}
              >
                Undo
              </span>
            )
        ) : null}
      </div>
    </div>
  );
};

//  ==== here ID is purchase id ==== from order item ====
const ViewDesignsModal = ({
  brandid,
  id,
  addressId,
  orderid,
  dispatch,
  isFactory,
  orderStatus,
  order,
  setHeaderTitle,
}) => {
  const [withQuantityDesigns, setWithQuantityDesigns] = useState([]);
  const [nextErr /*, setNextErr*/] = useState(false);
  const [poError, setPoError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("All fields are mandatory");

  // useEffect(() => {
  //   axios
  //     .get(`${eq}/address`, { params: { brandid } })
  //     .then(res => {
  //       console.log("RES FORM ADDRESS api", res);
  //       setAddresses(res.data);
  //     })
  //     .catch(err => console.log(err));
  // }, []);

  useEffect(() => {
    const { productsCount, poNumber } = order;

    axios
      .get(`${eq}/orders/designs`, { params: { orderid } })
      .then(res => {
        // setAddresses(res.data);
        setWithQuantityDesigns(res.data);
      })
      .catch(err => {
        dispatch(actions.flash({ message: "Failed to fetch designs!" }));
        console.log(err);
      });

    setHeaderTitle(`${productsCount} Designs in ${poNumber}`);
  }, []);

  const deleteDesignHandler = id => {
    const updatedDeisgns = withQuantityDesigns.filter(design => {
      if (design.designId === id) {
        design.isDeleted = !design.isDeleted;
        return { ...design };
      }
      return design;
    });

    setWithQuantityDesigns(updatedDeisgns);
  };

  const saveDesignQuantity = (id, value) => {
    const updatedWithQuantityDesigns = withQuantityDesigns.map(design => {
      if (design.designId === id) {
        design.quantity = value;
        return { ...design };
      }
      return design;
    });

    setWithQuantityDesigns(updatedWithQuantityDesigns);
  };

  // ===== This handles the validation plus $$$$$$ navigation to po and post create order api =====
  const toNextHandler = () => {
    let emptyQuantityError = false;
    withQuantityDesigns.forEach(design => {
      if (!design.quantity && !nextErr) {
        emptyQuantityError = true;
      }
    });

    if (emptyQuantityError) {
      // setNextErr(true);
      // return;

      setPoError(true);
      setErrorMessage("Please enter quantity");
      setTimeout(() => {
        setPoError(false);
      }, 1000);
      return;
    }

    //  ==== this condition removes the deleted designs ====
    const updatedWithQuantityDesigns = withQuantityDesigns.filter(design => {
      if (!design.isDeleted) {
        return design;
      }
      return false;
    });

    // === this checks is there any design left after removing or not ====
    if (!updatedWithQuantityDesigns.length) {
      setPoError(true);
      setErrorMessage("Please select the product");
      setTimeout(() => {
        setPoError(false);
      }, 1000);
      return;
    }

    axios
      .patch(`${eq}/orders/designs`, { products: withQuantityDesigns })
      .then(res => {
        if (!res.data.error) {
          // navigate to orders
          // close model
          dispatch(actions.closeModal());
          dispatch(
            actions.flash({ message: "Order updated successfully." })
          );

          // ==== dispatch the new updated order so that this will update in table also ====
          dispatch(actions.updateSingleOrder(res.data[0]));
        } else {
          dispatch(actions.flash({ message: "Something went wrong!" }));
          return;
        }
      })
      .catch(err => console.log(err));
  };

  const isNonEditable = isFactory || orderStatus !== EDITABLE_STATUS;

  return (
    <div className={styles.model_view_designs}>
      <div className={styles.scroll__wrapper}>
        <div style={{ height: "530px" }}>
          <PerfectScrollbar style={{ maxHeight: window.innerHeight }}>
            <div className={styles.model__header_wrapper_view_designs}>
              <div className={styles.model__header_item}>DESIGN</div>
              <div className={styles.model__header_item}>QUANTITY</div>
              <div className={styles.model__header_item}>PRICE</div>
            </div>

            {withQuantityDesigns.map(design => (
              <OrderItem
                design={design}
                nextErr={nextErr}
                saveDesignQuantity={saveDesignQuantity}
                deleteDesignHandler={deleteDesignHandler}
                isFactory={isFactory}
                orderStatus={orderStatus}
              />
            ))}
          </PerfectScrollbar>
        </div>
      </div>

      {poError ? (
        <div
          style={{
            fontSize: "10px",
            padding: "8px",
            backgroundColor: "#f8d7da",
            color: "#721c24",
            textAlign: "center",
            marginBottom: "-10px",
            position: "absolute",
            right: 0,
            bottom: "12%",
            width: "100%",
            opacity: poError ? 1 : 0,
            transition: "0.2s all"
          }}
        >
          {errorMessage}
        </div>
      ) : null}

      {!isNonEditable && <div className={styles.order__footer_wrapper}>
        <div className={styles.order__footer_btn_wrapper} style={{marginLeft: 'auto'}}>
          {isNonEditable ? null : (
            <RedButton label={"Update"} onClick={toNextHandler} />
          )}
        </div>
      </div>}
    </div>
  );
};

const mapStateToProps = state => ({
  designs: state.products.selectedDesigns
});

export default connect(mapStateToProps)(ViewDesignsModal);
