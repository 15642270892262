import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import CustomInput from '../../../UI/Form/Input/CustomInput';
import { IconMagnifier } from '../../../UI/Icons';
import DesignCard from './DesignCard';
import EmptyScreen from '../../../UI/EmptyScreen';
import './style.scss';
import DesignsListSummary from './DesignsListSummary';

export default class SelectDesigns extends React.Component {
  static propTypes = {
    brand: DesignsListSummary.propTypes.brand,
    factory: DesignsListSummary.propTypes.factory,
    designs: DesignsListSummary.propTypes.designs,
    currency: DesignsListSummary.propTypes.currency,
    onOrgChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    value: PropTypes.array,
  };

  state = {
    filteredDesigns: [],
    selectedDesigns: [],
    mapSelectedDesigns: {},
    searchText: '',

    propsDesigns: null,
    propsValue: null,
    propsPOValue: null,
  };

  static getDerivedStateFromProps(nextProps, oldState) {
    const nextState = {};

    if (nextProps.designs !== oldState.propsDesigns) {
      nextState.propsDesigns = nextProps.designs;
      nextState.filteredDesigns = nextProps.designs || [];
    }

    if (nextProps.poValue !== oldState.propsPOValue) {
      nextState.propsPOValue = nextProps.poValue;
    }

    if (nextProps.value !== oldState.propsValue) {
      nextState.propsValue = nextProps.value;
      if (nextProps.value) {
        nextState.mapSelectedDesigns = nextProps.value.reduce((prev, next) => {
          prev[next.id] = true;
          return prev;
        }, {});
        nextState.selectedDesigns = nextProps.value;
      }
    }

    return Object.keys(nextState).length > 0 ? nextState : null;
  }

  _handleSearchTextChange = (e) => {
    const { designs } = this.props;
    const nextState = {
      searchText: e.target.value,
      validSearchText: e.target.value.trim().toLowerCase(),
    };
    const validSearchText = nextState.searchText.trim().toLowerCase();

    if (nextState.validSearchText !== this.state.validSearchText) {
      if (validSearchText.length > 0) {
        nextState.filteredDesigns = designs.filter(design =>
          (design.name && design.name.toLowerCase().indexOf(validSearchText) >= 0)
          || (design.sourceDesignId && design.sourceDesignId.toLowerCase().indexOf(validSearchText) >= 0)
        );
      } else {
        nextState.filteredDesigns = designs;
      }
    }


    this.setState(nextState);
  }

  _handleDesignSelection = (selectedDesign, isSelected) => {
    const { selectedDesigns, mapSelectedDesigns } = this.state;
    if (isSelected) {
      this.setState({
        selectedDesigns: [...selectedDesigns, { ...selectedDesign }],
        mapSelectedDesigns: {
          ...mapSelectedDesigns,
          [selectedDesign.id]: true,
        },
      });
    } else {
      const newMapSelectedDesigns = { ...mapSelectedDesigns };
      delete newMapSelectedDesigns[selectedDesign.id];

      this.setState({
        selectedDesigns: selectedDesigns.filter(design => design.id !== selectedDesign.id),
        mapSelectedDesigns: newMapSelectedDesigns,
      });
    }
  }

  _handleRemoveDesign = (design) => {
    this._handleDesignSelection(design, false);
  }

  _renderDesignsList() {
    return null;
  }

  render() {
    const { factory, brand, currency, onOrgChange, onSubmit, poValue } = this.props;
    const { filteredDesigns, selectedDesigns, searchText, mapSelectedDesigns } = this.state;

    return <div className="order-select-designs">
      <div className="order-select-designs__search">
        <div className="heading">Select designs to order</div>
        <div className="order-select-designs__search__field">
          <CustomInput
            placeholder="Search design"
            prefixText={<IconMagnifier color="grey-medium" />}
            autoFocus={true}
            value={searchText}
            onChange={this._handleSearchTextChange}
          />
        </div>
        <PerfectScrollbar className="order-select-designs__search__results">
          {
            filteredDesigns.length === 0
              ? <EmptyScreen heading="No results found" />
              : <div>
                {filteredDesigns.map(design => (<DesignCard
                  key={design.id}
                  design={design}
                  currency={currency}
                  onChange={(isSelected) => this._handleDesignSelection(design, isSelected)}
                  isSelected={mapSelectedDesigns[design.id] || false}
                />))}
              </div>
          }
        </PerfectScrollbar>
      </div>
      <div className="order-select-designs__summary">
        <DesignsListSummary
          factory={factory}
          brand={brand}
          designs={selectedDesigns}
          currency={currency}
          onRemoveDesign={this._handleRemoveDesign}
          submitText="CONTINUE"
          onOrgChange={(orgType) => onOrgChange(orgType, selectedDesigns.length > 0)}
          onSubmit={onSubmit}
          poValue={poValue}
        />
      </div>
    </div>;
  }
}
