import React, { useEffect, useState } from "react";
import ReactTooltip from 'react-tooltip';
import axios from "axios";

import ProductionItem from "./ProductionTrackerItem";
import Pagination from "../Product/Pagination";
import FloatingBar from "../UI/BottomBar";
import EmptyFilterResult from "../UI/EmptyFilterResult";
import EmptyScreen from "../UI/EmptyScreen";
import Tabs from "./ProductionTabs";
import NavHeaders from "components/NavHeaders";
import { FilterDrawerButton } from "./../UI/Filter";

import {
  getCurrentUserRole,
  checkBrand,
  checkFactory
} from "../../helpers/AuthHelper";
import { LOCO_SUPER } from "../../constants/Auth";
import ImgSewing from "assets/images/emptyPages/ic-sewing.svg";

import { get_service_endpoint } from "../../ServiceEndpoints";
const eq = get_service_endpoint("notificationView");

const ProductionTracker = ({
  isFactory,
  allBrands,
  products: { products = [], paginate },
  openModal,
  prevProductsForTracker = [],
  getAllProducts,
  currentUserId,
  getAllUserForBrand,
  userRole,
  tab,
  stage,
  showBrandFilter
}) => {
  const [filtersValue, setFiltersValue] = useState({});
  const [appliedFiltersCount, setAppliedFiltersCount] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentProducts, setCurrentProducts] = useState([]);
  const [samplesForNotification, setSamplesForNotification] = useState(null);
  const [factories, setFactories] = useState([]);

  const isSuperAdmin = getCurrentUserRole() == LOCO_SUPER;
  const isUserFromBrand = checkBrand();
  const isFactoryManagerAdmin = checkFactory();

  useEffect(
    () => {
      if (products && !products.length && !currentProducts.length) {
        return;
      }

      updateCurrentProducts([...products]);

      if (prevProductsForTracker.length === 1) {
        setCurrentIndex(0);
        setSamplesForNotification(null);
      }
    },
    [products, prevProductsForTracker]
  );

  useEffect(
    () => {
        // call api here ...
        if (isFactory) {
          getAllProducts({
            ...filtersValue,
            init: 1,
            refresh: true,
            pst: "Dispatch"
          });
        } else {
          getAllProducts({
            ...filtersValue,
            brandid: currentUserId,
            init: 1,
            refresh: true,
            pst: "Dispatch"
          });
        }
    },
    [filtersValue]
  );

  // updates states and tooltip
  const updateCurrentProducts = (products) => {
    setCurrentProducts(products);

    setTimeout(() => {
      ReactTooltip.rebuild();
    }, 1000);
  }

  const getFiltersValueHandler = value => {
    let newAppliedFilterCount = 0;
    const transformedFilters = Object.values(value.filters).reduce(
      (acc, filter) => {
        acc[filter.name] = filter.values[0];

        if (acc[filter.name]) {
          newAppliedFilterCount += 1;
        }

        return acc;
      }, {});

      setAppliedFiltersCount(newAppliedFilterCount);
    setFiltersValue({
      ...transformedFilters
    });
  };

  // ************  PAGINATION HANDLER ***********
  const paginationHandler = ({ offset, isnext }) => {
    if (isnext) {
      // check length of saved designs
      const savedDesignsLength = prevProductsForTracker.length - 1;

      if (savedDesignsLength > currentIndex) {
        // then alter the designs

        updateCurrentProducts(prevProductsForTracker[currentIndex + 1]);
        setCurrentIndex(currentIndex + 1);
      } else {
        if (isFactory) {
          getAllProducts({
            ...filtersValue,
            pst: "Dispatch",
            offset,
            isnext,
            init: 0
          });
        } else {
          getAllProducts({
            ...filtersValue,
            pst: "Dispatch",
            offset,
            isnext,
            init: 0,
            brandid: currentUserId
          });
        }
        setCurrentIndex(currentIndex + 1);
      }
      return;
    }

    if (!isnext) {
      updateCurrentProducts(prevProductsForTracker[currentIndex - 1]);
      setCurrentIndex(currentIndex - 1);

      return;
    }
  };

  const saveSampleForNotificationHandler = (sample, isAlreadyAdded) => {
    if (isAlreadyAdded) {
      let updatedSamples = samplesForNotification.filter(
        svSample => sample.tnaId !== svSample.tnaId
      );

      setSamplesForNotification(updatedSamples);
      updateCurrentSamplesOnCheck(sample);
      return;
    }

    if (!samplesForNotification) {
      setSamplesForNotification([sample]);
      updateCurrentSamplesOnCheck(sample);
      return;
    }

    setSamplesForNotification([...samplesForNotification, sample]);

    updateCurrentSamplesOnCheck(sample);
  };

  const updateCurrentSamplesOnCheck = sample => {
    const updatedSamples = currentProducts.filter(curSample => {
      if (curSample.tnaId === sample.tnaId) {
        let isSelected = curSample.isSelected;
        curSample.isSelected = !isSelected;

        return { ...curSample };
      }

      return curSample;
    });

    updateCurrentProducts(updatedSamples);
  };

  const resetAfterNotifications = () => {
    getAllProducts({
      ...filtersValue,
      init: 1,
      refresh: true,
      pst: "Dispatch"
    });
  };

  useEffect(
    () => {
      if (!isFactoryManagerAdmin) {
        axios
          .get(`${eq}/orders/sampletracker/factories`, {
            params: {
              tab,
              stage: stage == "in-progress" ? "progress" : stage
            }
          })
          .then(res => {
            setFactories(Object.keys(res.data).map(key => res.data[key]));
          });
      }
    },
    [stage]
  );

  let totalCounts = {};
  if (paginate) {
    totalCounts =
      paginate.inProgressCount != undefined
        ? {
          inProgress: paginate.inProgressCount,
          dispatched: paginate.totalCount
        }
        : {
          inProgress: paginate.totalCount,
          dispatched: paginate.otherCount
        };
  }

  const filters = [];
  if (!isUserFromBrand) {
    filters.push({
      title: "CUSTOMER",
      key: "brandid",
      items: allBrands.map(({ id, name }) => ({
        key: id,
        label: name,
        value: id,
        isSelected: filtersValue["brandid"] === id
      })),
      isSingleSelect: true
    });
  }

  if (!isFactoryManagerAdmin) {
    filters.push({
      title: "FACTORY",
      key: "factory",
      items: factories.map(({ id, name }) => ({
        key: id,
        label: name,
        value: id,
        isSelected: filtersValue["factory"] === id
      })),
      isSingleSelect: true
    });
  }

  if (isSuperAdmin) {
    filters.push({
      title: "Notification status",
      key: "nst",
      items: notificationStatusOptions.map(({ label, value }, index) => ({
        key: index,
        label,
        value,
        isSelected: filtersValue["nst"] === value
      })),
      isSingleSelect: true
    });
  }

  return (
    <>
      <div className="custom-scroll-sample">
        <div className="production__tab_headers">
          <Tabs
            isFactory={isFactory}
            labelStyles={{ textTransform: "uppercase", fontSize: "12px" }}
            totalCounts={{ ...totalCounts }}
          />
          <div className="flex">
            <Pagination
              onclick={paginationHandler}
              page={paginate}
              currentIndex={currentIndex}
              style={{ padding: "4px 32px", paddingBottom: "0px" }}
              showTotalRecords={false}
            />
            <FilterDrawerButton
              filters={[...filters]}
              onApplyFilters={appliedFilters =>
                getFiltersValueHandler(appliedFilters)
              }
            />
          </div>
        </div>

        {/* <hr
          style={{
            margin: 0,
            borderTop: "1px solid var(--fill-grey-light3)",
            position: "relative"
          }}
        /> */}
        <NavHeaders
          userRole={userRole}
          tab="production"
          slice="2"
          isFactory={isFactory}
        />

        <div className="sample-row" style={{ minHeight: "50vh" }}>
          {(() => {
            if (!currentProducts.length) {
              return (
                appliedFiltersCount > 0
                  ? <EmptyFilterResult
                    onClearFilters={() => {
                      getFiltersValueHandler({
                        filters: {
                          factory: { values: [] },
                          brand: { values: [] },
                          pst: { values: [] },
                          nst: { values: [] },
                        },
                        filtersCount: 0,
                      });
                    }}
                  />
                  : <EmptyScreen
                    icon={ImgSewing}
                    heading="No designs dispatched"
                    description={<>
                      Designs dispatched from production will be<br />
                      available here
                    </>}
                  />
              );
            }

            //  return actuls products ****
            return currentProducts.map(product => {
              return (
                <ProductionItem
                  data={product}
                  isFactory={isFactory}
                  userRole={userRole}
                  key={product.id}
                  openModal={openModal}
                  isBrandSelected={
                    filtersValue.brandid && filtersValue.brandid != "null"
                  }
                  getSampleForNotification={saveSampleForNotificationHandler}
                />
              );
            });
          })()}
        </div>
        {samplesForNotification && samplesForNotification.length ? (
          <FloatingBar
            count={samplesForNotification && samplesForNotification.length}
            onClear={() => {
              const updatedSamples = currentProducts.filter(curSample => {
                curSample.isSelected = false;

                return { ...curSample };
              });

              updateCurrentProducts(updatedSamples);
              setSamplesForNotification(null);
            }}
            openModal={() => {
              getAllUserForBrand({ brandId: filtersValue.brandid }).then(
                userList => {
                  openModal("SEND_NOTIFICATION_USER_LIST", {
                    userList,
                    hiddenFields: [
                      {
                        name: "notifications",
                        value: samplesForNotification.map(notif => {
                          return {
                            ...notif,
                            stage: "TNA",
                            brand_id: notif.brandId,
                            design_id: notif.designId,
                            collection_id: notif.collectionId,
                            tnaId: notif.tnaId,
                            tnaAttributeId: notif.tnaAttributeId
                          };
                        })
                      },
                      { name: "userList", value: userList },
                      { name: "brand_id", value: filtersValue.brandid },
                      { name: "resetScreen", value: resetAfterNotifications }
                    ]
                  });
                }
              );
            }}
            modalKeys={[]}
            style={{}}
            isFactory={isFactory}
            onlyAddToDesign={true}
            selectionLabel="Products Selected"
            buttonLable="Send Notification"
          />
        ) : null}
      </div>
    </>
  );
};

export default ProductionTracker;

// Sent in last 10 days   *** 1
// Not sent in last 10 days -- We can’t fetch the data which doesn’t exist
// -- Above is being interpreted as sent before 10 days  **** 2

const notificationStatusOptions = [
  {
    label: "All",
    value: null
  },
  {
    label: "Sent in last 10 days",
    value: 1
  },
  {
    label: "Not Sent in last 10 days",
    value: 2
  }
];
