import React, { useState, useEffect } from "react";
import "./index.scss";
import ImageModal from "../../../UI/Modal/ImageModal/index";
import { getFileDisplayUrl } from "../../../../helpers/DesignHelpers";

const SampleFiles = ({ designName, files, onClick }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [transformedFiles, setTransformedFiles] = useState([]);

  useEffect(() => {
    if (!files || files.length <= 0) {
      setTransformedFiles([]);
      return;
    }

    setTransformedFiles(
      files.map(file => ({
        ...file,
        displayUrl: getFileDisplayUrl(file)
      }))
    );
  }, [files]);

  const handleImageModalClose = () => {
    setIsModalVisible(false);
  };

  const handleFileClick = (e, file) => {
    onClick(e, file);
    setSelectedFile(file);
    setIsModalVisible(true);
  };

  return (
    <div className="sample-files">
      {transformedFiles &&
        transformedFiles.length > 0 &&
        transformedFiles.map(file => {
          let fileType = file["type"];
          let sampleFileClass =
            fileType.indexOf("image") > -1
              ? "sample-files-item"
              : "sample-files-item doc-file";
          return (
            <button
              key={file.id}
              type="button"
              className={sampleFileClass}
              onClick={e => handleFileClick(e, file)}
            >
              <img src={file.displayUrl} alt="" />
            </button>
          );
        })}
      <ImageModal
        title={designName}
        files={transformedFiles}
        selected={selectedFile}
        isVisible={isModalVisible}
        onClose={handleImageModalClose}
      />
    </div>
  );
};

SampleFiles.defaultProps = {
  onClick: () => null,
  files: []
};

export default SampleFiles;
