import React, { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";

import Header from "../UI/Header";
import Table from "../UI/Table";
import FilterContainer from "../UI/Filters";
import { DatePicker } from "../UI/Filters/DateRangePicker";
import MultiSelect from "../UI/Filters/MultiSelect";
import SingleSelect from "../UI/Filters/SingleSelect";
import { getMaterialReport } from "store/actions/ReportsAction";
import Loader from "components/UI/InlineLoader";
import { FILTER_MATERIAL_TYPES, MATERIAL_REPORT_TYPES, FILTER_MATERIAL_MOVEMENTS } from "constants/Reports";

const LIMIT = 20;
const SUB_REPORTS = [
  {
    key: 'inventory',
    label: 'Inventory',
    url: '../inventory/',
  },
  {
    key: 'stockio',
    label: 'Stock I/O',
    url: '../stockio/',
  },
];
const HEADINGS_BY_REPORT_TYPE = {
  inventory: [
    "ITEM NAME",
    "ITEM TYPE",
    "ITEM ID",
    "LAST INWARD DATE",
    "INWARD QTY",
    "INWARD PRICE",
    "LAST OUTWARD DATE",
    "OUTWARD QTY",
    "INVENTORY",
  ],
  stockio: [
    "ITEM NAME",
    "ITEM TYPE",
    "ITEM ID",
    "DATE",
    "MOVEMENT",
    "VENDOR/FACTORY",
    "QUANTITY",
    "PRICE",
    "DESIGN",
  ],
};
const GTC_BY_REPORT_TYPE = {
  inventory: `
      minmax(200px, 1.66fr)
      minmax(120px, 1fr)
      minmax(120px, 1.2fr)
      minmax(170px, 1fr)
      minmax(170px, 1fr)
      minmax(150px, 1fr)
      minmax(170px, 1fr)
      minmax(150px, 1fr)
      minmax(120px, 1fr)`,
  stockio: `
      minmax(200px, 1.66fr)
      minmax(120px, 1fr)
      minmax(120px, 1.2fr)
      minmax(170px, 1fr)
      minmax(170px, 1fr)
      minmax(150px, 1.3fr)
      minmax(140px, 1fr)
      minmax(150px, 1fr)
      minmax(160px, 1fr)`,
};

const MaterialReport = ({
  getMaterialReport,
  materials = { list: [], filters: {} },
  loading,
  userDetails,
  brandsList,
  isBrandManager,
  reportType: strReportType,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [filters, setFilters] = useState({});
  const [dates, setDates] = useState({});
  const reportType = useMemo(() => MATERIAL_REPORT_TYPES[strReportType], [strReportType]);

  // refreshes the report when reportType changes
  useEffect(() => {
    setFilters(filters => {
      // if brandId is already set and reportType hasn't changed; don't refresh the report
      if (
        (!userDetails.isSuperAdmin || filters.brandId)
        && reportType === filters.reportType
      ) {
        return filters;
      }

      // new filters to be applied
      const newFilters = {
        ...filters,
        reportType,
      };

      // if user is locofast admin
      if (userDetails.isSuperAdmin) {

        // refresh report only when brandList is present to preselect the brand
        if (brandsList && brandsList.length > 0) {
          newFilters.brandId = filters.brandId || brandsList[0];

          getMaterialReport({
            ...newFilters,
            isInit: 1,
          });
        }
      } else {
        // refresh the report with new reportType
        getMaterialReport({ isInit: 1, ...newFilters });
      }

      setCurrentIndex(0);

      return newFilters;
    });
  }, [reportType, userDetails, brandsList, getMaterialReport]);

  const paginationHandler = values => {
    if (values.offset && materials.list.length <= (currentIndex + 1) * LIMIT) {
      setCurrentIndex(currentIndex + 1);
      getMaterialReport({
        reportType,
        offset: materials.pagination.nextOffset,
        ...filters
      });
    } else {
      if (values.offset || (!values.offset && values.isnext === 1)) {
        setCurrentIndex(currentIndex + 1);
      } else if (values.isnext === 0) {
        setCurrentIndex(currentIndex - 1);
      }
    }
  };

  if (loading) {
    return <Loader />;
  }

  const getOverallReport = () => {
    return getMaterialReport({
      ...filters,
      reportType,
      limit: materials.pagination.totalCount,
      skipStore: true,
      inlineLoader: false
    }).then(data => {
      return data.records;
    });
  };

  return (
    <div className="report-content">
      <Header
        heading="Material report"
        reportType="material"
        pagination={materials.pagination}
        metadata={{ reportType: strReportType }}
        currentIndex={currentIndex}
        paginationHandler={paginationHandler}
        getOverallReport={getOverallReport}
        headings={HEADINGS_BY_REPORT_TYPE[strReportType]}
        subReports={SUB_REPORTS}
        activeSubReport={strReportType}
      />
      <FilterContainer
        updateFilters={() => {
          getMaterialReport({
            ...filters,
            ...dates,
            isInit: 1,
            reportType,
          });
          setCurrentIndex(0);
        }}
      >
        {userDetails.isSuperAdmin && (
          <SingleSelect
            label="Brand"
            filterOptions={brandsList}
            onChange={id => {
              setFilters({ ...filters, brandId: id ? id : null });
            }}
            selectedFilters={filters.brandId}
          />
        )}
        {reportType === MATERIAL_REPORT_TYPES.stockio && <DatePicker
          label="Date Range"
          updateFilterDates={dates => {
            setDates(dates);
          }}
          selectedDates={dates}
        />}
        <MultiSelect
          label="Material type"
          filterOptions={FILTER_MATERIAL_TYPES}
          onChange={ids => {
            if (filters.materialType !== ids) {
              setFilters({ ...filters, materialType: ids ? ids.join() : null });
            }
          }}
          selectedFilters={filters.materialType}
        />
        {reportType === MATERIAL_REPORT_TYPES.stockio && < MultiSelect
          label="Stock movement"
          filterOptions={FILTER_MATERIAL_MOVEMENTS}
          onChange={ids => {
            setFilters({ ...filters, stockMovement: ids ? ids.join() : null });
          }}
          selectedFilters={filters.stockMovement}
        />}
      </FilterContainer>

      <Table
        headings={HEADINGS_BY_REPORT_TYPE[strReportType]}
        data={materials.list.slice(
          currentIndex * LIMIT,
          currentIndex * LIMIT + LIMIT
        )}
        gridTemplateColumns={GTC_BY_REPORT_TYPE[strReportType]} />
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  materials: state.reports.materials[props.reportType],
  loading: state.reports.loading,
  userDetails: state.auth.userDetails
});

export default connect(mapStateToProps, {
  getMaterialReport
})(MaterialReport);
