import React from "react";
import PropTypes from "prop-types";
import "./Select.scss";

RFReactSelect.defaultProps = {
  multi: false,
  className: ""
};

RFReactSelect.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired
  }).isRequired,
  options: PropTypes.array.isRequired,
  isMulti: PropTypes.bool,
  className: PropTypes.string,
  styles: PropTypes.object
};

// https://gist.github.com/leocristofani/98312e61807db8f32e720c9f97a186e5

export default function RFReactSelect({
  input = {},
  options,
  isMulti,
  className,
  label,
  styles = {},
  defaultValue,
  selectProps,
  controlledValue,
  disabled, meta: { error, submitFailed },
  placeholder = "Select",
  select: Select,
  children
}) {

  const { name, value, onBlur, onChange, onFocus } = input;

  const transformedValue = transformValue(value, options, isMulti, name);

  return (
    <div className="select-grp">
      {label && (
        <label htmlFor={name} className="f-sz-sm">
          {label}
        </label>
      )}
      <Select
        styles={styles}
        valueKey="value"
        id={name}
        name={name}
        disabled={disabled}
        value={controlledValue !== undefined ? controlledValue : transformedValue}
        isMulti={isMulti}
        options={options}
        onChange={isMulti ? multiChangeHandler(onChange) : singleChangeHandler(onChange)}
        onFocus={onFocus}
        className={className}
        defaultValue={defaultValue}
        placeholder={placeholder}
        {...selectProps}
      />
      {children}
      {
        submitFailed &&
        <p className="field_err">{error}</p>
      }
    </div>
  );
}

/**
 * onChange from Redux Form Field has to be called explicity.
 */
function singleChangeHandler(func) {
  return function handleSingleChange(value) {
    func(value ? value.value : "");
  };
}

/**
 * onBlur from Redux Form Field has to be called explicity.
 */
function multiChangeHandler(func) {
  return function handleMultiHandler(values) {
    func(values.map(value => value.value));
  };
}

/**
 * For single select, Redux Form keeps the value as a string, while React Select
 * wants the value in the form { value: "grape", label: "Grape" }
 *
 * * For multi select, Redux Form keeps the value as array of strings, while React Select
 * wants the array of values in the form [{ value: "grape", label: "Grape" }]
 */
function transformValue(value, options, multi, label) {

  if (multi && typeof value === "string") return [];
  let parsedValue = typeof value == "number" ? String(value) : value

  if (label == "idealFor" && value == "2") {
    parsedValue = [0, 1];
  }

  const filteredOptions = (options && options.filter(option => {
    return multi ? parsedValue.indexOf(option.value) !== -1 : option.value == parsedValue;
  })) || [];

  return multi ? filteredOptions : filteredOptions[0];
}
