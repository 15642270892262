import React from 'react';
import { DebounceInput } from 'react-debounce-input';

import './styles.scss';
const DebounceInputWrapper = ({ handleChange,...restProps }) => (
  <DebounceInput
    minLength={2}
    debounceTimeout={300}
    onChange={event => handleChange(event.target.value)}
    {...restProps}
    style={{
      width: '90%',
      fontFamily: 'Nunito Sans, FontAwesome'
    }}
    className="debounced-input"
  />
)

export default DebounceInputWrapper;
