import React, { Component } from "react";
import PropTypes from "prop-types";
import Trash from "../../../assets/images/action/ic-delete.faded.svg";
import icPlcAttachment from "../../../assets/images/file/ic-attach-file.svg";

export default class File extends Component {
  
  static defaultProps = {
    hasAdvancedOptions: true,
    acceptTypes: "*"
  };

  state = {
    file: null,
    fileType: null,
    progress: 0,
    isInitialValuesSet: false
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.initialFileName) {
      const modifiedDate = new Date(nextProps.initialFileName.createdOn);
      return {
        ...prevState,
        progress: nextProps.progress ? nextProps.progress : 0,
        file: {
          name: nextProps.initialFileName.name,
          size: nextProps.initialFileName.size,
          url: nextProps.initialFileName.url,
          createdBy: nextProps.initialFileName.createdBy,
          createdOn: modifiedDate && modifiedDate.toDateString()
        },
        isInitialValuesSet: true,
        fileType: "image",
        isEditMode: true,
      };
    }
    return {
      ...prevState,
      progress: nextProps.progress ? nextProps.progress : 0
    };
  }

  handleChange = selectedFiles => {
    const currentFile = selectedFiles[0];
    this.setState({
      file: currentFile,
      fileType: currentFile.type.split("/")[0]
    });
    this.props.onChange(currentFile);
  };

  deleteFile = () => {
    this.refs["FileInput"].value = "";
    const fileName = this.state.file.name
    this.setState({
      file: null,
      fileType: null,
      progress: 0,
      isEditMode: false
    });
    this.props.onChange({ deleted: true, name: fileName, value: null});
  };


  render() {
    const { headerText, validate } = this.props;
    return (
      <div className="factory-attachment">
        <input
          type="file"
          onChange={e => {
            this.handleChange(e.target.files);
          }}
          ref="FileInput"
          className={"hide"}
          accept={this.props.acceptTypes}
        />
        {this.state.file !== null ? (
          <React.Fragment>
            <div className="input-file__wrapper flex">
              <div className="flex-col full_length">
                <span className="input-file__meta-selected">
                  <span>
                    {this.state.file.name}
                  </span>
                  <div className="input-file__delete">
                    <img src={Trash} alt="" onClick={this.deleteFile} />
                  </div>
                </span>
                {
                  this.state.file.createdBy && (
                    <span className="attachment_meta">
                      By {this.state.file.createdBy}<br/>
                      {this.state.file.createdOn}
                    </span>
                  )
                }
                
              </div>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className="input-file__wrapper empty flex">
              <div className="input-file__file-thumb flex"
                onClick={() => this.refs["FileInput"].click()}              
              >
                <img
                  style={{ margin: "auto" }}
                  src={icPlcAttachment}
                  alt="attachment"
                />
              </div>
              <div className="input-file__progress flex-col">
                <span className="input-file__meta">
                  <span className="input-header__text">
                    { headerText } 
                    { validate.length > 0 && <sup>*</sup>}
                    <br />
                  </span>
                  <span className="f-sz-14">
                    Attach file
                  </span>
                    <br/>
                  <span className="f-sz-sm">
                    Select
                  </span>
                </span>
              </div>
              
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}
