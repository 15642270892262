import React from "react";

import upArrow from "assets/images/dashboard/up.svg";
import downArrow from "assets/images/dashboard/down.svg";

export const Pagination = ({
  data: { limit, totalCount },
  onChange,
  currentIndex,
  entity: {
    singular,
    plural
  } = {
    singular: "design",
    plural: "designs"
  }
}) => {
  const noOfPages = Math.ceil(totalCount / limit);

  let showingTill = limit * (currentIndex + 1);

  if (showingTill > totalCount) {
    showingTill = totalCount;
  }

  return (
    <div className="design-table-footer">
      <div className="pagination-title">{totalCount} {totalCount === 1 ? singular : plural}</div>
      <div className="bottom-pagination">
        <div className="pagination-title">Page {noOfPages === 0 ? 0 : currentIndex + 1} of {noOfPages}</div>
        <button className="arrow-img btn"
          onClick={() => {
            onChange({ isNext: false }, currentIndex - 1);
          }}
          disabled={currentIndex === 0}
        >
          <img src={upArrow} alt="prev" />
        </button>
        <button className="arrow-img btn"
          onClick={() => {
            onChange({}, currentIndex + 1);
          }}
          disabled={currentIndex + 1 === noOfPages || noOfPages === 0}
        >
          <img src={downArrow} alt="next" />
        </button>
      </div>
    </div>
  );
};
