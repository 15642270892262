import some from "lodash/some";
import moment from "moment";

import { getCurrentUserRole } from "./AuthHelper";
import { LOCO_SUPER, FABRIC_SUPPLIER } from "./../constants/Auth";
import {
  MATERIAL_CATEGORY_HASHES,
  MATERIAL_IDEAL_FOR_HASHES
} from "constants/MaterialLibrary";

export const canEditSwatch = () => {
  const allowedRoles = [LOCO_SUPER, FABRIC_SUPPLIER];
  return allowedRoles.includes(getCurrentUserRole()) ? true : false;
};

export const getFileData = fileValue => {
  if (fileValue) {
    if (typeof fileValue == "object") {
      return [window.URL.createObjectURL(fileValue), fileValue.name];
    } else {
      return [fileValue, fileValue.split("/").slice(-1)[0]];
    }
  }
  return [];
};

const stringToBoolean = value => (value == 1 ? "1" : value == "0" ? 0 : null);

const idToValue = value => {
  if (value == 0) return "Minimum Order Quantity(MOQ)";
  else if (value == 1) return "Inventory";
  return value;
};

export const transformForEdit = (material, colors) => {
  const {
    fabricQuality,
    fabricType,
    weave,
    inStock,
    fabricAvailability,
    testReportUrl,
  } = material;
  let testReport = null;

  delete material.createdAt;
  delete material.updatedAt;
  delete material.createdBy;
  delete material.updatedBy;

  // Thread unit are static as of now. So deleting them while editing material.
  delete material.threadCountUnit;
  delete material.weightUnit;
  delete material.fabricWidthUnit;

  if (testReportUrl) {
    const parts = testReportUrl.split('/');
    const fileName = decodeURIComponent(parts[parts.length - 1]);

    testReport = [{
      name: fileName,
      url: testReportUrl,
    }];
  }

  if (!material.trimsCategory) {
    delete material.trimsCategory;
  } else {
    return {
      ...material,
      trimsCategoryId: String(material.trimsCategory.id),
      unit: String(material.unit.id),
      colors: colors.map(colorObj => String(colorObj.color.id)),
      testReport
    };
  }

  return {
    ...material,
    category: MATERIAL_CATEGORY_HASHES[material.category].value,
    categoryType: String(material.categoryType),
    idealFor: MATERIAL_IDEAL_FOR_HASHES[material.idealFor],
    colors: colors.map(colorObj => String(colorObj.color.id)),
    unit: String(material.unit.id),
    composition: String(fabricQuality.id),
    pattern: String(fabricType.id),
    weave: weave ? String(weave.id) : null,
    inStock: stringToBoolean(inStock),
    fabricAvailability: idToValue(fabricAvailability),
    testReport,
  };
};

export const normalizeForPatch = jsonData => {
  // If ideal for has 2 values then it means both 'top' and 'bottom' were selected
  if (jsonData["idealFor"] instanceof Array) {
    if (jsonData["idealFor"].length > 1) {
      jsonData["idealFor"] = "2";
    } else {
      jsonData["idealFor"] = jsonData["idealFor"][0];
    }
  }

  if (jsonData["category"] instanceof Array) {
    let computedVal = jsonData["category"][0];
    jsonData["category"]
      .slice(1)
      .forEach(value => (computedVal = computedVal << Number(value)));
    jsonData["category"] = computedVal;
  }

  if ((jsonData.testReport instanceof FileList) && jsonData.testReport.length > 0) {
    jsonData.testReport = jsonData.testReport[0];

    delete jsonData.testReportUrl;
  } else if (Array.isArray(jsonData.testReport) && jsonData.testReport.length > 0) {
    delete jsonData.testReport;
    // nothing to change
  } else {
    delete jsonData.testReport;
    delete jsonData.testReportUrl;
  }

  delete jsonData.testReportUploadedAt;

  // Had to do this because redux-form only supports string values to evaluate radio buttons checked or not
  return jsonData;
};

export const hasQuantity = obj =>
  some(
    Object.entries(obj),
    ([key, value]) => key.includes("quantity-") && value > 0
  );

export const hasValidQuantity = (obj, available) => {
  let hasTrimsSizes = false;
  for (let key in obj) {
    const keysplit = key.split("quantity-");
    if (keysplit.length > 1 && obj[key] > 0) {
      if (obj[key] > available[keysplit[1]].quantity) return false;
      else hasTrimsSizes = 1;
    }
  }
  return hasTrimsSizes;
};

export const getMaterialInwardReqBody = (details) => {
  const mStartOfToday = moment().startOf('day');
  const { id, materialId, vendorId, trimsSizes, price, quantity, expectedInwardDate } = details;
  let mExpectedInwardDate = moment(expectedInwardDate);

  const formData = new FormData();

  if(id){
    formData.append('id', id);
  }
  formData.append('materialId', materialId);
  formData.append('vendorId', vendorId);
  if(trimsSizes){
    formData.append('trimsSizes', trimsSizes);
  }
  formData.append('price', price);
  formData.append('quantity', quantity);


  // if inward is not scheduled
  // then, actual date should be today and expected inward would be set to today if its not set
  if (!details.scheduleInward) {
    mExpectedInwardDate = mStartOfToday;
    formData.append('actualInwardDate', mExpectedInwardDate.format('YYYY-MM-DD'));
    formData.append('expectedInwardDate', mExpectedInwardDate.format('YYYY-MM-DD'));
  } else {
    mExpectedInwardDate = mExpectedInwardDate.startOf('day');

    const diff = mStartOfToday.diff(mExpectedInwardDate, 'hours');

    if (diff === 0) {
      formData.append('actualInwardDate', mExpectedInwardDate.format('YYYY-MM-DD'));
    }

    formData.append('expectedInwardDate', mExpectedInwardDate.format('YYYY-MM-DD'));
  }

  const { poFile, invoiceFile } = details;
  if(poFile instanceof File){
    formData.append('poFile', poFile);
  } else if(poFile === null){
    // In case file is deleted, poFile will have '' as value
    formData.append('poFileDeleted', true)
  }

  if(invoiceFile instanceof File ){
    formData.append('invoiceFile', invoiceFile);
  } else if(invoiceFile === null) {
    formData.append('invoiceFileDeleted', true)
  }


  return formData;
}
