import { handleActions } from "redux-actions";
import cloneDeep from "lodash/cloneDeep";
import { ProcessPaginate } from "../../helpers/paginateHelper";

const INITIAL_STATE = {
  orders: [],
  prevSavedOrders: [],
  mapStatusCount: {},
  brands: [],
  brandFactories: {},
  factoryDesigns: {},
  // brandAddresses: {},
};

const Orders = handleActions({
  ORDERS_GET_ALL_ORDERS: (state, action) => {
    const { data, refresh } = action.payload;
    if (refresh) {
      return {
        ...state,
        orders: data,
        prevSavedOrders: [cloneDeep(data.orders)]
      };
    } else {
      return {
        ...state,
        orders: data,
        prevSavedOrders: [...state.prevSavedOrders, cloneDeep(data.orders)]
      };
    }
  },
  ORDERS_GET_BRANDS: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      brands: data,
    };
  },
  ORDERS_GET_FACTORIES: (state, action) => {
    const { data, brandId } = action.payload;

    return {
      ...state,
      brandFactories: {
        ...state.brandFactories,
        [brandId]: data,
      },
    };
  },
  ORDERS_GET_DESIGNS: (state, action) => {
    const { data, factoryId } = action.payload;

    return {
      ...state,
      factoryDesigns: {
        ...state.factoryDesigns,
        [factoryId]: ProcessPaginate(data, state.factoryDesigns[factoryId]),
      },
    };
  },
  // ORDERS_GET_ADDRESSES: (state, action) => {
  //   const { data, brandId } = action.payload;

  //   return {
  //     ...state,
  //     brandAddresses: {
  //       ...state.brandAddresses,
  //       [brandId]: data,
  //     },
  //   };
  // },
}, INITIAL_STATE);

export default Orders;
