import React, { Component } from "react";
import { connect } from "react-redux";
import classnames from 'classnames';

import { ProfileAvatar } from '../UI/ProfileAvatar/index';
import EmptyScreen, { Horizontal } from "../UI/EmptyScreen/index.jsx";
import { getMyTeam as getMyTeamAction } from '../../store/actions/DashboardActions';

import { daysAgo } from "../../helpers/DateHelpers";

import usersImg from "assets/images/dashboard/ic-users.svg";
import { ReactComponent as ImgArrowDown } from "assets/images/dashboard/down.svg";
import usersGreyImg from "assets/images/dashboard/users-grey.svg";

const PER_PAGE_LIMIT = 7;

class MyTeam extends Component {
  state = {
    canNavigateNext: false,
    canNavigatePrevious: false,
    totalRowsCount: 0,
    totalPagesCount: 0,
    currentPageIndex: 1,
    viewRows: [],
    myTeamData: {},
  };

  componentDidMount() {
    this.props.getMyTeam && this.props.getMyTeam();
  }

  static getDerivedStateFromProps(nextProps, oldState) {
    if (nextProps.myTeamData !== oldState.myTeamData) {
      const { myTeamData } = nextProps;
      const totalRowsCount = myTeamData.rows.length;
      const totalPagesCount = Math.ceil(totalRowsCount / PER_PAGE_LIMIT);
      const canNavigatePrevious = false;
      const canNavigateNext = totalPagesCount > 1;

      return {
        canNavigatePrevious,
        canNavigateNext,
        totalRowsCount,
        totalPagesCount,
        currentPageIndex: 1,
        viewRows: myTeamData.rows.slice(0, PER_PAGE_LIMIT),

        myTeamData: myTeamData,
      }
    }

    return null;
  }

  navigatePage = (page) => {
    const {
      myTeamData,
      totalPagesCount,
    } = this.state;

    if (page < 1 || page > totalPagesCount) {
      return;
    }

    this.setState({
      currentPageIndex: page,
      viewRows: myTeamData.rows.slice((page - 1) * PER_PAGE_LIMIT, (page * PER_PAGE_LIMIT)),
      canNavigateNext: page < totalPagesCount,
      canNavigatePrevious: page > 1,
    })
  }

  renderMemberRow = (memberStats) => {
    const strLastActivity = memberStats.last_activity
      ? daysAgo(memberStats.last_activity)
      : <span className="f-color-faded">-</span>;

    return (<div className="design-table-row">
      <div className="design-col wid-192">
        <div className="col-img">
          <ProfileAvatar
            url={memberStats.image}
            name={memberStats.first_name}
            key={memberStats.uid}
            id={memberStats.uid}
            staticColor
          />
        </div>
        <div className="col-title">{memberStats.first_name} {memberStats.last_name}</div>
      </div>
      <div className="col-title">{memberStats.total_designs_created}</div>
      <div className="col-title ">{memberStats.in_sampling_designs}</div>
      <div className="col-title">{memberStats.in_production_designs}</div>
      <div className="col-title ">{strLastActivity}</div>
    </div>);
  }

  render() {
    const {
      canNavigateNext,
      canNavigatePrevious,
      totalRowsCount,
      totalPagesCount,
      currentPageIndex,
      viewRows,
    } = this.state;

    return (
      <div className="active-design my-team-section">
        <div className="active-design-conatiner">
          <div className="design-conatiner-heading">
            <img src={usersImg} className="active-icon" alt="" />
            My Team
          </div>
        </div>
        <div className="design-active-table">
          <div className="design-table-header">
            <div className="table-header-column wid-192">name</div>
            <div className="table-header-column">designs created</div>
            <div className="table-header-column">sampling</div>
            <div className="table-header-column">production</div>
            <div className="table-header-column">last activity</div>
          </div>
          <div className="design-table-section">
            {
              viewRows.length > 0
                ? viewRows.map(row => this.renderMemberRow(row))
                : <EmptyScreen
                  icon={usersGreyImg}
                  orientation={Horizontal}
                  heading="No members"
                  description={<>
                    There are no members in<br />
                     your team
                  </>}
                />
            }
          </div>
          <div className="design-table-footer">
            <div className="pagination-title">{totalRowsCount} users</div>
            <div className="bottom-pagination">
              {totalPagesCount && <div className="pagination-title">Page {currentPageIndex} of {totalPagesCount}</div>}
              <div
                className={classnames("arrow-img", !canNavigatePrevious && 'disabled')}
                onClick={() => this.navigatePage(currentPageIndex - 1)}
              >
                <ImgArrowDown className="rot-180" />
              </div>
              <div
                className={classnames("arrow-img", !canNavigateNext && 'disabled')}
                onClick={() => this.navigatePage(currentPageIndex + 1)}
              >
                <ImgArrowDown />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  myTeamData: state.dashboard.myTeam,
});

const mapDispatchToProps = dispatch => ({
  getMyTeam: () => dispatch(getMyTeamAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyTeam);
