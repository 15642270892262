import React, { useState, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import _ from "lodash";
import axios from "axios";
import moment from "moment";
import { Helmet } from 'react-helmet'

import SampleTrackerItem from "./SampleTrackerItem";
import HeaderWithFilters from "./SampleTrackerHeaderWithFilters";
import Pagination from "../Product/Pagination";
import FloatingBar from "../UI/BottomBar";
import NavHeaders from "components/NavHeaders";
import { FilterDrawerButton } from "./../UI/Filter";
import EmptyScreen from "../UI/EmptyScreen";

import { get_service_endpoint } from "../../ServiceEndpoints";
import { getCurrentUserRole, checkBrand } from "../../helpers/AuthHelper";
import {
  LOCO_SUPER,
  PRODUCT_DEVELOPMENT,
  FACTORY_ADMIN,
  FACTORY_MANAGER,
  PRODUCTION_MERCHANT
} from "../../constants/Auth";
import EmptyFilterResult from "../UI/EmptyFilterResult";

import ImgSampling from "assets/images/emptyPages/ic_empty_sampling.svg";
import {
  SAMPLING_FILTERS
} from "constants/Design"

const eq = get_service_endpoint("notificationView");

// this is for sampling tracker branch.

const SamplingTracker = ({
  samples: { products = [], paginate },
  isFactory,
  openModal,
  closeModal,
  allBrands = [],
  getAllSamples,
  getAllUserForBrand,
  currentUserId,
  prevSavedSamplesForTracker,
  updatedSampleWithDispatch,
  openSampleUpdateModal,
  tnaTemplate,
  tab,
  stage,
  userDetails,
  ...restProps
}) => {
  const { location = {} } = restProps;
  const [samplesForNotification, setSamplesForNotification] = useState(null);

  const [collections, setCollections] = useState([]);
  const [factories, setFactories] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedCollections, setSelectedCollections] = useState(null);
  const [appliedFiltersCount, setAppliedFiltersCount] = useState(0);
  const [selectedFactory, setSelectedFactory] = useState(null);
  // const [prevSavedSamplesForTracker, setPrevSavedSamplesForTracker] = useState(
  //   prevSavedSamplesForTracker
  // );
  const [currentSamples, setCurrentSamples] = useState([]);
  const [selectedBrandId, setSelectedBrandId] = useState(null);

  const isSuperAdmin = getCurrentUserRole() == LOCO_SUPER;
  const isManager = getCurrentUserRole() == PRODUCT_DEVELOPMENT;
  const isProductionMerchant = getCurrentUserRole() == PRODUCTION_MERCHANT;
  const isUserFromBrand = checkBrand();
  const isFactoryManagerAdmin =
    getCurrentUserRole() == FACTORY_ADMIN ||
    getCurrentUserRole() == FACTORY_MANAGER;

  const [filtersValue, setFiltersValue] = useState({
    st: null,
    dst: null,
    nst: null,
    brandid: (isUserFromBrand && !isProductionMerchant) ? currentUserId : null,
    ...location.pathFilters
  });
  useEffect(() => {
    if (!_.isEqual(currentSamples, products)) {
      setCurrentSamples([...products]);
      if (prevSavedSamplesForTracker.length === 1) {
        setCurrentIndex(0);
        setSamplesForNotification(null);
      }

      setTimeout(() => {
        ReactTooltip.rebuild();
      }, 1000);
    }
  }, [products, prevSavedSamplesForTracker]);

  useEffect(() => {
    if (Object.keys(filtersValue).length) {
      // call api here ...
      if (isSuperAdmin || isProductionMerchant) {
        getAllSamples({ ...filtersValue, init: 0, refresh: true });
      } else if (isFactoryManagerAdmin) {
        getAllSamples({
          ...filtersValue,
          factory: currentUserId,
          init: 1,
          refresh: true
        });
      } else {
        getAllSamples({
          ...filtersValue,
          brandid: currentUserId,
          init: 1,
          refresh: true
        });
      }
    }
  }, [filtersValue]);

  // === This effect is for getting collections on brand change ===
  useEffect(() => {
    if (isFactory) {
      if (Object.keys(filtersValue).length && isFactory) {
        if (selectedBrandId !== filtersValue.brandid) {
          axios
            .get(`${eq}/orders/sampletracker/collections`, {
              params: { brandid: filtersValue.brandid }
            })
            .then(res => {
              setCollections(Object.keys(res.data).map(key => res.data[key]));

              setSelectedBrandId(filtersValue.brandid);
            });
        }
      }
    }
  }, [filtersValue]);

  // useEffect(()=>{
  //     if()
  // }, [filtersValue])

  // === this will pull collections on brand side
  useEffect(() => {
    if (!isFactory && currentUserId) {
      axios
        .get(`${eq}/orders/sampletracker/collections`, {
          params: { brandid: currentUserId }
        })
        .then(res => {
          setCollections(Object.keys(res.data).map(key => res.data[key]));
        });
    }
  }, [currentUserId]);

  useEffect(() => {
    axios
      .get(`${eq}/orders/sampletracker/factories`, {
        params: {
          tab,
          stage: stage == "in-progress" ? "progress" : stage
        }
      })
      .then(res => {
        setFactories(Object.keys(res.data).map(key => res.data[key]));
      });
  }, [currentUserId]);

  // this gets trigged on updating the dispatch details **
  useEffect(() => {
    if (updatedSampleWithDispatch) {
      let dueDateObj = moment(updatedSampleWithDispatch.dueDate);
      let duration = moment.duration(dueDateObj.diff());
      let days = duration.days();
      let hours = duration.hours();

      if (hours && hours > 0) {
        days = days + 1;
      }

      if (hours && hours < 0) {
        days = days - 1;
      }

      let disPatchDays = null;
      if (updatedSampleWithDispatch.dispatchSentDate) {
        let dueDateObj = moment(updatedSampleWithDispatch.dispatchSentDate);
        let duration = moment.duration(
          dueDateObj.diff(updatedSampleWithDispatch.dueDate)
        );
        let days = duration.days();
        let hours = duration.hours();

        // if (hours && hours > 0) {
        //   days = days + 1;
        // }

        // if (hours && hours < 0) {
        //   days = days - 1;
        // }

        // if(days > 0) disPatchDays = -days;
        // if(days < 0) disPatchDays = days;
        disPatchDays = -days;
      }

      // dispatchSentDate
      // courierPartner
      // trackingNumber
      // dispatchSentDays

      // updatedSampleWithDispatch.dueIn = days;  cant change props ***

      const updatedSamples = currentSamples.filter(sample => {
        if (updatedSampleWithDispatch.sampleId === sample.sampleId) {
          // alert("HELLO")
          sample.dueIn = days;
          sample.dueDate = updatedSampleWithDispatch.dueDate;
          sample.dispatchSentDate = updatedSampleWithDispatch.dispatchSentDate;
          sample.courierPartner = updatedSampleWithDispatch.courierPartner;
          sample.trackingNumber = updatedSampleWithDispatch.trackingNumber;
          sample.dispatchSentDays = disPatchDays;
          return { ...sample };
        }

        return sample;
      });

      setCurrentSamples(updatedSamples);
    }
  }, [updatedSampleWithDispatch]);

  const saveSampleForNotificationHandler = (sample, isAlreadyAdded) => {
    if (isAlreadyAdded) {
      let updatedSamples = samplesForNotification.filter(
        svSample => sample.sampleId !== svSample.sampleId
      );

      setSamplesForNotification(updatedSamples);
      updateCurrentSamplesOnCheck(sample);
      return;
    }

    if (!samplesForNotification) {
      setSamplesForNotification([sample]);
      updateCurrentSamplesOnCheck(sample);
      return;
    }

    setSamplesForNotification([...samplesForNotification, sample]);

    updateCurrentSamplesOnCheck(sample);
  };

  const updateCurrentSamplesOnCheck = sample => {
    const updatedSamples = currentSamples.filter(curSample => {
      if (curSample.sampleId === sample.sampleId) {
        let isSelected = curSample.isSelected;
        curSample.isSelected = !isSelected;

        return { ...curSample };
      }

      return curSample;
    });
    setCurrentSamples(updatedSamples);
  };

  // === saves the selcted collections and truns on checkbox ===
  const selectCollectionHandler = id => {
    const updatedCollections = collections.map(collection => {
      if (collection.id === id) {
        collection.isSelected = !collection.isSelected;
        return { ...collection };
      }
      return collection;
    });

    setCollections(updatedCollections);

    // write here logic for saving selected colections ===

    if (_.includes(selectedCollections, id)) {
      _.remove(selectedCollections, collection => collection === id);
      setSelectedCollections(selectedCollections);
    } else {
      if (selectedCollections) {
        setSelectedCollections([...selectedCollections, id]);
      } else {
        setSelectedCollections([id]);
      }
    }
  };

  // ==== this functions gets called by apply button of collection filter ===
  const applyCollectionsHandler = () => {
    setFiltersValue({
      ...filtersValue,
      col: JSON.stringify(selectedCollections)
    });
  };

  // === this function gets hitted n clicking cancel button of collection filter ===
  // === sets the selected filters to 0 ====
  // === clears the selected collection to isSelected:false ====
  // === calls the orders/products api again ...
  const cancelCollectionsHandler = () => {
    const updatedCollection = collections.map(collection => {
      collection.isSelected = false;
      return { ...collection };
    });

    setCollections(updatedCollection);
    setSelectedCollections(null);
    setFiltersValue({ ...filtersValue, col: [] });
  };

  const getFiltersValueHandler = value => {
    const transformedFilters = Object.values(value.filters).reduce(
      (acc, filter) => {
        acc[filter.name] = filter.values[0];
        return acc;
      },
      {}
    );

    setAppliedFiltersCount(value.filtersCount);
    setFiltersValue({
      ...transformedFilters
    });
  };

  // ************  PAGINATION HANDLER ***********
  const paginationHandler = ({ offset, isnext }) => {
    if (isnext) {
      // check length of saved designs
      const savedDesignsLength = prevSavedSamplesForTracker.length - 1;

      if (savedDesignsLength > currentIndex) {
        // then alter the designs

        setCurrentSamples(prevSavedSamplesForTracker[currentIndex + 1]);
        setCurrentIndex(currentIndex + 1);
      } else {
        if (isFactory || isProductionMerchant ) {
          getAllSamples({
            ...filtersValue,
            offset,
            isnext,
            init: 1
          });
        } else {
          getAllSamples({
            ...filtersValue,
            offset,
            isnext,
            init: 1,
            brandid: currentUserId
          });
        }
        setCurrentIndex(currentIndex + 1);
      }
      return;
    }

    if (!isnext) {
      setCurrentSamples(prevSavedSamplesForTracker[currentIndex - 1]);
      setCurrentIndex(currentIndex - 1);

      return;
    }
  };

  const resetAfterNotifications = () => {
    getAllSamples({ ...filtersValue, init: 1, refresh: true });
  };

  // reset filter ******
  const resetFilterHandler = () => {
    // pull new products
    if (isSuperAdmin || isProductionMerchant) {
      getAllSamples({ init: 1, refresh: true });
    } else if (isFactoryManagerAdmin) {
      getAllSamples({
        init: 1,
        refresh: true,
        factory: currentUserId
      });
    } else {
      getAllSamples({
        init: 1,
        refresh: true,
        brandid: currentUserId
      });
    }

    // clear filters values
    setFiltersValue({});

    setSelectedBrandId(null);

    if (isFactory) {
      setCollections([]);
      setSelectedCollections(null);
    } else {
      const updatedCollection = collections.map(collection => {
        collection.isSelected = false;
        return { ...collection };
      });

      setCollections(updatedCollection);
      setSelectedCollections(null);
    }
  };

  const filters = [];
  if (!isUserFromBrand) {
    filters.push({
      title: "CUSTOMER",
      key: SAMPLING_FILTERS.brandId,
      items: (allBrands || []).map(({ id, name }) => ({
        key: id,
        label: name,
        value: id,
        isSelected: filtersValue[SAMPLING_FILTERS.brandId] === id
      })),
      isSingleSelect: true
    });
  }

  if (!isFactoryManagerAdmin) {
    filters.push({
      title: "FACTORY",
      key: SAMPLING_FILTERS.factory,
      items: factories.map(({ id, name }) => ({
        key: id,
        label: name,
        value: id,
        isSelected: filtersValue[SAMPLING_FILTERS.factory] === id
      })),
      isSingleSelect: true
    });
  }

  filters.push({
    title: "Sample stage",
    key: SAMPLING_FILTERS.sampleStage,
    items: sampleStageOptions.map(({ label, value }, index) => ({
      key: index,
      label,
      value,
      isSelected: filtersValue[SAMPLING_FILTERS.sampleStage] === value
    })),
    isSingleSelect: true
  });

  filters.push({
    title: "Dispatch status",
    key: SAMPLING_FILTERS.dispatchStatus,
    items: dispatchStatusOptions.map(({ label, value }, index) => ({
      key: index,
      label,
      value,
      isSelected: filtersValue[SAMPLING_FILTERS.dispatchStatus] === value
    })),
    isSingleSelect: true
  });

  if (!isFactoryManagerAdmin && !isUserFromBrand) {
    filters.push({
      title: "Notification status",
      key: SAMPLING_FILTERS.notificationStatus,
      items: notificationStatusOptions.map(({ label, value }, index) => ({
        key: index, // key should be unique + (string | number)
        label,
        value,
        isSelected: filtersValue[SAMPLING_FILTERS.notificationStatus] === value
      })),
      isSingleSelect: true
    });
  }

  return (
    <>
      <Helmet
        bodyAttributes={{
          class: 'body_container'
        }}
      />
      {/* change perfect scroll bar to div */}
      <div className="custom-scroll-sample">
        <HeaderWithFilters
          isFactory={isFactory}
          allBrands={allBrands}
          getFiltersValue={getFiltersValueHandler}
          collections={collections}
          factories={factories}
          selectCollection={selectCollectionHandler}
          applyCollections={applyCollectionsHandler}
          cancelCollectionFilter={cancelCollectionsHandler}
          showReset={Object.keys(filtersValue).length}
          resetFilters={resetFilterHandler}
          totalItems={paginate}
          render={() => {
            return (
              <div className="flex">
                <Pagination
                  label={
                    "col" in filtersValue ||
                      "nst" in filtersValue ||
                      "st" in filtersValue ||
                      "dst" in filtersValue
                      ? "designs found"
                      : "designs in sampling"
                  }
                  onclick={paginationHandler}
                  page={paginate}
                  currentIndex={currentIndex}
                  style={{ padding: 0, paddingBottom: "0px" }}
                  showTotalRecords={false}
                />
                <FilterDrawerButton
                  filters={[...filters]}
                  onApplyFilters={appliedFilters =>
                    getFiltersValueHandler(appliedFilters)
                  }
                />
              </div>
            );
          }}
          isSuperAdmin={isSuperAdmin}
        />

        <div>
          <NavHeaders userRole={getCurrentUserRole()} tab="sampling" />

          <div
            style={{ minHeight: "50vh", backgroundColor: "white" }}
            className="sample-row"
          >
            {(() => {
              if (!currentSamples.length) {
                return (
                  appliedFiltersCount > 0
                    ? <EmptyFilterResult
                      onClearFilters={() => {
                        getFiltersValueHandler({
                          filters: {
                            factory: { values: [] },
                            brandid: { values: [] },
                            st: { values: [] },
                            dst: { values: [] },
                            nst: { values: [] },
                          },
                          filtersCount: 0,
                        });
                      }}
                    />
                    : <EmptyScreen
                      icon={ImgSampling}
                      heading="No designs in sampling"
                      description={<>
                        Requested design samples will be<br />
                         available here
                      </>}
                    />
                );
              }
              return currentSamples.map(sample => (
                <SampleTrackerItem
                  data={sample}
                  isFactory={isFactory}
                  key={sample.sampleId}
                  isBrandSelected={
                    filtersValue.brandid && filtersValue.brandid != "null"
                  }
                  getSampleForNotification={saveSampleForNotificationHandler}
                  openCourierDetailsModal={details => {
                    openModal("SAMPLING_ADD_COURIERS_DETAILS", {
                      details,
                      design: {
                        id: sample.designId,
                        name: sample.designName
                      }
                    });
                  }}
                  openAddDueDateModal={details => {
                    openModal("SAMPLING_TRACKER_ADD_DUE_DATE", { details });
                  }}
                  openModal={openModal}
                  openSampleUpdateModal={openSampleUpdateModal}
                  tnaTemplate={tnaTemplate}
                  closeModal={closeModal}
                  userDetails={userDetails}
                />
              ));
            })()}
          </div>
        </div>
        {samplesForNotification && samplesForNotification.length ? (
          <FloatingBar
            count={samplesForNotification && samplesForNotification.length}
            onClear={() => {
              const updatedSamples = currentSamples.filter(curSample => {
                curSample.isSelected = false;

                return { ...curSample };
              });

              setCurrentSamples(updatedSamples);
              setSamplesForNotification(null);
            }}
            openModal={() => {
              getAllUserForBrand({ brandId: filtersValue.brandid }).then(
                userList => {
                  openModal("SEND_NOTIFICATION_USER_LIST", {
                    userList,
                    hiddenFields: [
                      {
                        name: "notifications",
                        value: samplesForNotification.map(notif => {
                          return {
                            ...notif,
                            stage: "Sampling",
                            brand_id: notif.brandId,
                            design_id: notif.designId,
                            collection_id: notif.collectionId,
                            subCategory: notif.sampleType
                          };
                        })
                      },
                      { name: "userList", value: userList },
                      { name: "brand_id", value: filtersValue.brandid },
                      { name: "resetScreen", value: resetAfterNotifications }
                    ]
                  });
                }
              );
            }}
            modalKeys={[]}
            style={{}}
            isFactory={isFactory}
            onlyAddToDesign={true}
            selectionLabel="Samples Selected"
            buttonLable="Send Notification"
          />
        ) : null}
      </div>
    </>
  );
};

export default SamplingTracker;

const sampleStageOptions = [
  {
    label: "All",
    value: null
  },
  {
    label: "Requested",
    value: 1
  },
  {
    label: "Resubmission Requested",
    value: 2
  }
];

const dispatchStatusOptions = [
  {
    label: "All",
    value: null
  },
  {
    label: "Upcoming",
    value: 3
  },
  {
    label: "On time",
    value: 5
  },
  {
    label: "Delayed",
    value: 2
  },
  {
    label: "Dispatched",
    value: 1
  },
  {
    label: "Not Dispatched",
    value: 4
  }
];

const notificationStatusOptions = [
  {
    label: "All",
    value: null
  },
  {
    label: "Sent",
    value: 1
  },
  {
    label: "Not Sent",
    value: 2
  }
];
