import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import DashedBorder from "../DashedBorder";
import Button from "../Button/Button";
import { IconAttachFile, IconClose } from "../Icons";
import './index.scss';

const SIZE_1MB = 1 * 1024 * 1024;

export default class InputAttachments extends React.Component {
  static propTypes = {
    maxCount: PropTypes.number,
    labelText: PropTypes.string,
    fileTypes: PropTypes.string,
    direction: PropTypes.oneOf(['col', 'row']),
    labelOnMax: PropTypes.oneOf(['none', 'hide']),
    onChange: PropTypes.func,
    onError: PropTypes.func,
    maxSize: PropTypes.number, // in bytes
  };

  static defaultProps = {
    maxCount: 0,
    labelText: "Attach file",
    labelOnMax: 'none',
    direction: 'col',
    maxSize: SIZE_1MB,
    onChange: () => null,
    onError: () => null,
  };

  constructor(props) {
    super(props);

    this.state = {
      isMaxCountReached: false,
      files: [],
      totalSize: 0,

      ...(props.initialState),
    }
  }

  _handleRemoveFile = (index) => {
    const { onChange } = this.props;
    const { files, totalSize } = this.state;
    const file = files[index];

    files.splice(index, 1);

    this.setState({
      files,
      totalSize: totalSize - file.size,
      isMaxCountReached: false,
    });

    onChange(files);
  }

  _handleAddFile = (e) => {
    if (!e.target.files.length) {
      return;
    }

    const { maxCount, onChange, onError, maxSize } = this.props;
    const { isMaxCountReached, totalSize } = this.state;

    if (isMaxCountReached) {
      return;
    }

    const file = e.target.files[0];
    const files = [...this.state.files, file];
    const nextTotalSize = totalSize + file.size;

    if (nextTotalSize > maxSize) {
      onError(`Max 1 MB files are allowed. File "${file.name}" can't be added`);
      return;
    }

    this.setState({
      files,
      totalSize: nextTotalSize,
      isMaxCountReached: this.state.files.length + 1 >= maxCount
    });

    onChange(files);
  }

  _renderLabel() {
    const { labelText, labelOnMax, fileTypes } = this.props;
    const { isMaxCountReached } = this.state;
    let displayLabel = labelText;

    if (isMaxCountReached) {
      if (labelOnMax === 'hide') {
        return null;
      }
    }

    return <DashedBorder
      className={classNames("input-attachments__label", {
        'input-attachments__label--disabled': isMaxCountReached
      })}
      useLabel={true}
    >
      <input
        type="file"
        className="hide"
        onChange={this._handleAddFile}
        accept={fileTypes}
        disabled={isMaxCountReached}
      />
      <IconAttachFile />
      {displayLabel && <span className="text">{displayLabel}</span>}
    </DashedBorder>;
  }

  _renderFiles() {
    const { files } = this.state;

    return <div className="input-attachments__file-list">
      {files.map(({ name }, index) => (
        <div className="input-attachments__file-item">
          <span className="input-attachments__file-name overflow-ellipsis">{name}</span>
          <Button
            category="img shrink"
            onClick={() => this._handleRemoveFile(index)}
          >
            <IconClose color="grey-medium" />
          </Button>
        </div>
      ))}
    </div>;
  }

  render() {
    const { className, style, direction } = this.props;

    return <div
      className={classNames("input-attachments", `input-attachments--dir-${direction}`, className)}
      style={style}
    >
      {this._renderLabel()}
      {this._renderFiles()}
    </div>;
  }
}

