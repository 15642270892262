import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../../UI/Button/Button';
import OrderSummary from '../../OrderSummary/index';
import EmptyScreen from '../../../../UI/EmptyScreen';
import FactoryImg from '../../../../../assets/images/dashboard/factories.svg';
import { getCoverImage } from '../../../../../helpers/DesignHelpers';

import './style.scss';

export default class FactorySummary extends React.Component {
  static propTypes = {
    brand: PropTypes.object,
    factory: PropTypes.object,
    designs: PropTypes.array,
    designsCount: PropTypes.number,
    onSubmit: PropTypes.func.isRequired,
    onOrgChange: PropTypes.func.isRequired,
  };

  _handleSubmit = () => {
    const { onSubmit, factory } = this.props;

    if (typeof (onSubmit) === 'function' && factory) {
      onSubmit({
        factory
      });
    }
  }

  _renderDesigns() {
    const { designs = [], designsCount } = this.props;

    return <div className="factory-summary__designs">
      <div className="factory-summary__designs-list">
        {designs.map(design => <div
          key={design.id}
          className="factory-summary__designs-item"
        >
          <img src={getCoverImage(design)} alt="" />
        </div>)}
      </div>
      <div className="factory-summary__designs-info">
        {designsCount} design{designsCount > 1 ? 's' : ''} available to order
      </div>
    </div>
  }

  render() {
    const { brand, factory, onOrgChange } = this.props;

    return <OrderSummary
      brand={brand}
      factory={factory}
      canChangeFactory={false}
      onOrgChange={onOrgChange}
    >
      <div className="factory-summary__empty">
        {factory
          ? this._renderDesigns()
          : <EmptyScreen
            icon={FactoryImg}
            iconPosition='bottom'
            iconSize="72px"
            heading="No factory selected"
            description="Select a factory to continue"
          />}
      </div>
      <div className="factory-summary__cta-container">
        <Button
          className="cta-continue tooltip-container"
          category="blue-bg"
          disabled={!factory}
          onClick={() => this._handleSubmit()}
        >
          CONTINUE
          {!factory && <div className="tooltip dark-tooltip tooltip--h-center tooltip--v-top">
            Select a factory to continue
          </div>}
        </Button>
      </div>
    </OrderSummary>
  }
}
