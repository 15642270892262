import React, { useState, useEffect } from "react";
import axios from "axios";

import TnaItem from "./TnaItem";

import { get_service_endpoint } from "../../../ServiceEndpoints";
import moment from "moment";
import { DATESTAMP_FORMAT } from "../../../constants/Date";
const Tna = ({ designId, ...restProps }) => {
  const [data, setData] = useState(null);
  const [otherDetails, setOtherDetails] = useState({});

  useEffect(() => {
    axios
      .get(`${get_service_endpoint("notificationView")}/tna/brandtna/`, {
        params: { designid: designId } // this.props.designId
      })
      .then(res => {
        // setData(res.data.tnaAttributes);
        setOtherDetails(res.data);

        formatDataWithDates(res.data.tnaAttributes);
      });
  }, []);

  const checkAllHasDates = attrValues => {
    return attrValues.some(attr => {
      if (!attr.attributeValue) {
        return false;
      }

      return true;
    });
  };

  const checkAttributeDate = date => {
    if (!date) {
      return false;
    }

    return true;
  };

  const formatDataWithDates = tnaData => {
    let formatedData = tnaData.map(tnaItem => {
      let { data } = tnaItem;
      let isGreyComesTrue = false;


      // ***** this calculates min/max date for header labels *****
      if (checkAllHasDates(data)) {
        tnaItem.minDate = data[0]["attributeValue"]
          ? moment(data[0]["attributeValue"]).format("MMMM DD, YYYY")
          : null;
        tnaItem.maxDate = data[data.length - 1]["attributeValue"]
          ? moment(data[data.length - 1]["attributeValue"]).format(
              "MMMM DD, YYYY"
            )
          : null;
      } else {
        tnaItem.minDate = data[0]["attributeValue"]
          ? moment(data[0]["attributeValue"]).format("MMMM DD, YYYY")
          : null;
        tnaItem.maxDate = null;
      }

      // ***** this calculates the grey condition in headers as well as childrens *****
      // if(isGreyComesTrue) {
      //   tnaItem.isGrey = true;
      // }

      let formatedInnerData = data.map(item => {
        if (checkAttributeDate(item.attributeValue)) {
          item.isGrey = false;

          return { ...item };
        } else {
          item.isGrey = true;
          return { ...item };
        }
      });

      tnaItem.data = formatedInnerData;

      return { ...tnaItem };
    });


    // this will be data for TNA *******
    setData(formatedData);
  };

  if (!data) return null;

  const {
    poNumber,
    orderQuantity,
    costClosureDate,
    deliveryDate
  } = otherDetails;

  return (
    <div>
      {/* TOP DETAILS */}
      <div
        style={{
          padding: "16px",
          borderBottom: "1px solid #f5f5f5"
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <div
            style={{
              flex: 1
            }}
          >
            <div className="label__class">Total Order Quantity</div>
            <div className="value__class">
              {orderQuantity ? orderQuantity : "N/A"}
            </div>
          </div>
          <div
            style={{
              flex: 1
            }}
          >
            <div className="label__class">PO Number</div>
            <div className="value__class">{poNumber ? poNumber : "N/A"}</div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",

            paddingTop: "12px"
          }}
        >
          {/* <div
            style={{
              flex: 1
            }}
          >
            <div className="label__class">Delivery Date</div>
            <div className="value__class">
              {deliveryDate
                ? moment(deliveryDate).format(DATESTAMP_FORMAT)
                : "N/A"}
            </div>
          </div> */}
          <div
            style={{
              flex: 1
            }}
          >
            <div className="label__class">Cost Closure Date</div>
            <div className="value__class">
              {costClosureDate
                ? moment(costClosureDate).format(DATESTAMP_FORMAT)
                : "N/A"}
            </div>
          </div>
        </div>
      </div>
      {/* TOP DETAILS */}

      <div style={{ padding: "16px", paddingBottom: "48px" }}>
        {data.map((tnaItem, index) => (
          <TnaItem
            data={tnaItem}
            firstItem={index === 0}
            lastItem={index === data.length - 1}
            id={index}
          />
        ))}
      </div>
    </div>
  );
};

export default Tna;
