import React, { useState } from "react";

import HeaderWithLogo from "./HeaderWithLogo";
import Tab from "./Tab";
import DispatchQuantityItem from "./DispathQuantityItem";
import PoDetailItem from "./PoDetailsItem";

const PoDetails = ({ location: { state = {} } = {}, ...restProps }) => {
  const [activeTab, setActiveTab] = useState(1);

  const { po: { dispatches = [], products = [] } = {} } = state;

  const changeTabHandler = () => {
    if (!activeTab) {
      setActiveTab(1);
      return;
    }

    setActiveTab(0);
  };

  return (
    <div>
      <HeaderWithLogo isBackIcon />

      {/* TABS */}
      <div
        style={{
          display: "flex",
          borderBottom: "1px solid #f5f5f5"
        }}
      >
        <Tab
          isActive={activeTab === 0}
          label="Dispatch Quatity"
          onClick={changeTabHandler}
        />
        <Tab
          isActive={activeTab === 1}
          label="Dispatch Details"
          onClick={changeTabHandler}
        />
      </div>
      {/* TABS */}

      {/* DISPATCH QUANTITY ON ACTIVE INDEX == 0 */}
      {activeTab === 0 ? (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",

              padding: "8px 16px",
              borderBottom: "1px solid #f5f5f5"
            }}
          >
            <div
              className="label__class"
              style={{ flex: 1.33, paddingLeft: "55px" }}
            >
              Design
            </div>
            <div className="label__class" style={{ flex: 1 }}>
              Order Qty
            </div>
            <div className="label__class" style={{ flex: 1 }}>
              Dispatch Qty
            </div>
          </div>
          {products.map(disp => (
            <DispatchQuantityItem data={disp} />
          ))}
        </>
      ) : null}
      {/* DISPATCH QUANTITY ON ACTIVE INDEX == 0 */}

      {/* PO DETAILS FOR ACTIVE INDEX 1 */}
      {activeTab === 1 ? dispatches.map(po => <PoDetailItem po={po} />) : null}

      {/* PO DETAILS FOR ACTIVE INDEX 1 */}
    </div>
  );
};

export default PoDetails;
