import React, { useEffect, useState } from "react";
import { Field, FieldArray } from "redux-form";
import { connect } from "react-redux";
import qs from "query-string";
import Form from "../../UI/Form/Form";
import FileInput from "../../UI/Form/File/File";
import FormInput from "../../UI/Form/Input/Input";
import { required } from "../../../helpers/form-validations";
import { ALL_STATES } from "../../../constants/Global";
import Button from "../../UI/Button/Button";
import PerfectScrollbar from "react-perfect-scrollbar";
import Downshift from "downshift";
import icDropdown from "./../../../assets/images/navigation/ic-arrow-drop-down.svg";
import Trash from "../../../assets/images/action/ic-delete.faded.svg";
import "./BrandDetailsModal.scss";
import ToggleButton from "../../UI/Form/ToggleButton/ToggleButton";
import { prependFeature } from "helpers/brandHelpers";
import { getBrandAddresses, getBrandFeatures } from "store/actions/BrandActions";
import { getCustomerById } from "store/actions/UserActions";

const FEATURES = [
  {
    label: "Dashboard",
    name: "featureDashboard"
  },
  {
    label: "Swatch Library",
    name: "featureSwatchLibrary"
  },
  {
    label: "Factory Management",
    name: "featureFactoryManagement"
  },
  {
    label: "Locofast Suppliers",
    name: "featureLocofastSuppliers"
  },
  {
    label: "User Management",
    name: "featureUserManagement"
  },
  {
    label: "Collaboration",
    name: "featureCollaboration"
  },
  {
    label: "Notifications",
    name: "featureNotifications"
  },
  {
    label: "Material Library",
    name: "featureMaterialLibrary"
  },
  {
    label: "Brand Reports",
    name: "featureBrandReports"
  },
  {
    label: "TNA Approval",
    name: "featureTnaApproval"
  },
];

const Compress = require("compress.js");
// import PropTypes from "prop-types";
const compress = new Compress();

const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

const FormRow = ({ children, label, required = false }) => (
  <div className="flex brandDetailsModal__input_grp">
    <div>
      {label}
      {required && <sup>*</sup>}
    </div>
    {children}
  </div>
);

const FormRowWithoutLabel = ({ className, children, label, required }) => (
  <div
    className={`flex brandDetailsModal__input_grp_no_label ${className ? className : ""
      }`}
  >
    {children}
  </div>
);

const FormGroup = ({ children }) => {
  return <div className="flex brandDetailsModal__adjacent">{children}</div>;
};

const compressFile = async (file, field, changeFormValue, extraImages) => {
  if (!file || file.deleted) {
    changeFormValue(field, file);
    return;
  }

  const compressedFile = await compress
    .compress([file], {
      size: 2,
      quality: 0.9,
      maxWidth: 1920,
      maxHeight: 1920,
      resize: true
    })
    .then(data => {
      const fileBlob = b64toBlob(data[0].data, file.type);
      var compressFile = new File([fileBlob], file.name);
      changeFormValue(field, compressFile);
    });
  return await compressedFile;
};

const brandFeaturesForm = ({ props, change: changeFormValue }) => {
  return (
    <div className="form brandFeaturesForm scrollable">
      {FEATURES.map(feature => (
        <div className="feature-checkbox">
          <Field
            component={ToggleButton}
            name={feature.name}
            label={feature.label}

          // onClick={(e) => {
          //   changeFormValue(`feature_${feature.name}`, e.target.checked);
          // }}
          />
        </div>
      ))}
    </div>
  );
};

const brandDetailsForm = ({ props, change: changeFormValue }) => (
  <div className="form brandDetailsForm scrollable">
    <FormRow label="Brand Name" required>
      <Field
        name="name"
        component="input"
        type="text"
        placeholder="Eg. Zara Clothing Pvt. Ltd."
      />
    </FormRow>
    <FormGroup>
      <FileInput
        containerClass="full_span"
        acceptTypes="image/x-png"
        validate={[required]}
        hasAdvancedOptions={false}
        headerText={"Image"}
        initialFileName={
          (props.initialValues && props.initialValues.image)
            ? {
              name: props.initialValues.image.name,
              size: 0,
              imgUrl: props.initialValues.image.imgUrl
            }
            : null
        }
        onChange={file => compressFile(file, "image", changeFormValue)}
        text={
          <span style={{ color: "var(--fill-locofast-black)" }}>
            Upload brand logo (only PNG images allowed)
          </span>
        }
      />
    </FormGroup>
    <FormRow label="Website" required>
      <Field
        name="webUrl"
        component={FormInput}
        type="text"
        placeholder="https://www.zara.com"
        validate={[required]}
      />
    </FormRow>
    {/* <AddressFormGroup> */}
    <FieldArray name="address" component={ShippingAddressComponent} />
    {/* </AddressFormGroup> */}
    <FormRow label="GSTIN">
      <Field name="gstNumber" component="input" type="text" />
    </FormRow>
  </div>
);

const BrandDetailsModal = ({
  brandAddresses,
  brandDetails,
  brandFeatures,
  getBrandAddresses,
  getBrandDetails,
  getBrandFeatures,
  ...props
}) => {
  const [loading, setLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState(
    (window.appHistory.location.search &&
      qs.parse(window.appHistory.location.search)["tab"]) ||
    "details"
  );

  useEffect(() => {
    if (props.initialValues && props.initialValues.id) {
      getBrandAddresses({ brandId: props.initialValues.id });
      getBrandDetails({ id: props.initialValues.id });
      getBrandFeatures(props.initialValues.id);
    } else {
      setLoading(false);
    }
  }, [getBrandAddresses, getBrandDetails, getBrandFeatures, props.initialValues]);

  useEffect(() => {
    if (!props.initialValues || !props.initialValues.id) {
      return;
    }

    if (brandAddresses !== null && brandDetails !== null && brandFeatures != null) {
      setLoading(false);
    }
  }, [brandAddresses, brandDetails, brandFeatures, props.initialValues]);

  const initialValues = {
    ...props.initialValues,
    ...brandDetails,
    ...(prependFeature(brandFeatures)),
    address: brandAddresses,

  };
  if (!Array.isArray(initialValues.address) || initialValues.address.length === 0) {
    initialValues.address = [{}];
  } else {
    initialValues.address = initialValues.address.map(({ addressLine1, addressLine2, city, state, pinCode }) => ({
      address_line: `${addressLine1} \n ${addressLine2}`,
      city,
      state,
      pinCode
    }));
  }

  return (
    loading
      ? null
      : <Form
        {...props}
        initialValues={
          props.initialValues
            ? initialValues
            : {
              address: [{}],
              featureDashboard: 0,
              featureSwatchLibrary: 0,
              featureFactoryManagement: 0,
              featureLocofastSuppliers: 0,
              featureUserManagement: 0,
              featureNotifications: 0,
              featureCollaboration: 0,
              featureMaterialLibrary: 0,
              featureBrandReports: 0,
              featureTnaApproval: 0,
            }
        }
        form="AddBrand"
        className="brandDetailsModal"
        selectedTab={selectedTab}
      >
        {({ change, selectedTab }) => {
          const tabs = [
            {
              name: "DETAILS",
              tabUrl: "details",
              isSelected: selectedTab === "details"
            },
            {
              name: "PERMISSIONS",
              tabUrl: "permissions",
              isSelected: selectedTab === "permissions"
            }
          ];
          const panels = {
            details: brandDetailsForm({ props, change }),
            permissions: brandFeaturesForm({ props, change })
          };

          // TODO: move tab to appropriate tab component

          return (
            <>
              <div className="tabs">
                <div className="tabs__navbar flex">
                  {tabs.map(tab => (
                    <div
                      className={`tabs__tab ${tab.isSelected ? "tabs__tab--active" : ""
                        }`}
                      onClick={() => setSelectedTab(tab.tabUrl)}
                    >
                      <span>{tab.name}</span>
                    </div>
                  ))}
                </div>
              </div>
              <div className="tab-panel scrollable">{panels[selectedTab]}</div>
            </>
          );
        }}
      </Form>
  );
};

const DownshiftSelect = ({ input }) => {
  const initialSelectedItem = ALL_STATES[0];
  return (
    <Downshift
      className="downshift-select"
      initialSelectedItem={initialSelectedItem}
      itemToString={item => item}
      onChange={selection => {
        input.onChange(selection);
      }}
      style={{ fontSize: "12px", fontWeight: 600 }}
    >
      {({
        getInputProps,
        getToggleButtonProps,
        getItemProps,
        getLabelProps,
        getMenuProps,
        isOpen,
        inputValue,
        highlightedIndex,
        selectedItem
      }) => {
        return (
          <div
            {...getToggleButtonProps()}
            type="none"
            style={{
              position: "relative",
              cursor: "pointer",
              border: "solid 1px var(--fill-grey-light)",
              borderRadius: "2px",
              padding: "6px 12px",
              height: "42px"
            }}
            tabIndex="0"
          >
            <span
              className="flex"
              style={{ alignItems: "center", justifyContent: "space-between" }}
            >
              <input
                style={{
                  border: "none",
                  backgroundColor: "transparent",
                  maxWidth: "100%",
                  width: "100%",
                  fontWeight: "600",
                  color: "var(--fill-locofast-black)"
                }}
                className="select-input-transparent"
                readOnly={true}
                disabled={true}
                {...input}
                placeholder="State"
              />
              <img
                src={icDropdown}
                alt="dropdown"
                style={{ marginTop: "4px" }}
              />
            </span>

            {isOpen ? (
              <div
                className="select-pane"
                onClick={e => {
                  e.stopPropagation();
                }}
                {...getMenuProps()}
              >
                <div>
                  <PerfectScrollbar>
                    {ALL_STATES
                      // .filter(item => item.label !== selectedItem.label)
                      .map((item, index) => {
                        return (
                          <div
                            {...getItemProps({
                              className: `in-selection collection-collab__label_left_aigned flex ${selectedItem == item ||
                                highlightedIndex == index
                                ? "selected"
                                : ""
                                }`,
                              index,
                              item,
                              key: item
                            })}
                          >
                            {item}
                          </div>
                        );
                      })}
                  </PerfectScrollbar>
                </div>
              </div>
            ) : null}
          </div>
        );
      }}
    </Downshift>
  );
};

const TextArea = ({ input, fields, index }) => (
  <React.Fragment>
    <textarea
      {...input}
      type="text"
      rows="4"
      className="brandDetailsModal__textarea"
      placeholder="Enter shipping address"
    />
    <img
      className="remove_address"
      src={Trash}
      alt=""
      onClick={() => fields.remove(index)}
    />
  </React.Fragment>
);

const Input = ({ input, type, placeholder }) => (
  <input
    {...input}
    type={type}
    className="brandDetailsModal__textarea"
    placeholder={placeholder}
  />
);

const ShippingAddressComponent = ({
  fields,
  meta: { error, submitFailed },
  onChange
}) => {
  return (
    <div>
      <div
        style={{
          padding: "11px 0"
        }}
      >
        Shipping Address<sup>*</sup>
      </div>
      {fields.map((address, index) => (
        <FormRowWithoutLabel key={index}>
          <div className="brandDetailsModal__address_group">
            <Field
              index={index}
              name={`${address}.address_line`}
              fields={fields}
              component={TextArea}
            />
            <div className="flex_row">
              <FormRowWithoutLabel className="input-city-container">
                <Field
                  name={`${address}.city`}
                  component={Input}
                  type="text"
                  placeholder="City"
                />
              </FormRowWithoutLabel>
              <FormRowWithoutLabel className="input-state-container">
                <Field
                  name={`${address}.state`}
                  component={DownshiftSelect}
                  validate={[required]}
                  options={ALL_STATES}
                />
              </FormRowWithoutLabel>
              <FormRowWithoutLabel className="input-pincode-container">
                <Field
                  name={`${address}.pinCode`}
                  component={Input}
                  type="text"
                  placeholder="Pin Code"
                />
              </FormRowWithoutLabel>
            </div>
          </div>
        </FormRowWithoutLabel>
      ))}
      <div>
        <Button
          category="btn"
          className="addAddress"
          onClick={() => fields.push({})}
        >
          + Add address
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  brandAddresses: ((props.initialValues && props.initialValues.id)
    ? state.brands.brandAddresses[props.initialValues.id]
    : null),
  brandDetails: ((props.initialValues && props.initialValues.id)
    ? state.user.customers.data.find(c => c.id === props.initialValues.id)
    : null),
  brandFeatures: ((props.initialValues && props.initialValues.id)
    ? state.brands.mapBrandFeatures[props.initialValues.id]
    : null),
});

const mapDispatchToProps = (dispatch) => ({
  getBrandAddresses: (args) => dispatch(getBrandAddresses(args)),
  getBrandDetails: (args) => dispatch(getCustomerById(args)),
  getBrandFeatures: (brandId) => dispatch(getBrandFeatures(brandId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BrandDetailsModal);
