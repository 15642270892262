import React, { Component } from "react";
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { daysAgo } from "../../../helpers/DateHelpers";
import { getCoverImagePlaceholder } from "../../../helpers/DesignHelpers";
import './index.scss';

export default class DesignsList extends Component {
  static propTypes = {
    designs: PropTypes.array.isRequired,
    selected: PropTypes.number.isRequired,
    onSelect: PropTypes.func.isRequired,
    style: PropTypes.object,
  };

  render() {
    const { designs, selected, onSelect, style } = this.props;

    return (
      <div className="messaging-design-item-list scroll-auto" style={style}>
        {designs.map(design => (<div
          key={design.comment_id || design.id}
          onClick={() => onSelect(design)}
          className={classnames('messaging-design-item', {
            'messaging-design-item--selected': design.id === selected,
          })}
        >
          <div className="messaging-design-item__body">
            <span
              className={`messaging-design-item__icon ${design.design_image ? '' : 'dummy'}`}
              style={{
                backgroundImage: `${design.design_image
                  ? 'url(' + design.design_image + ')'
                  : 'url(' + getCoverImagePlaceholder(design).src + ')'}`,
                backgroundSize: "cover"
              }}
            >
              <span className="message-queue__new" />
            </span>
            <div className="messaging-design-item__content">
              <div className="row">
                <span className="design-name">
                  {design.name}
                </span>
                <span className="timestamp">
                  {(design.comment_created_at && daysAgo(design.comment_created_at)) || '--'}
                </span>
              </div>
              <div className="row">
                <span className="comment-info">
                  {design.commented_by ? `${design.commented_by}: ${design.comment}` : 'No messages here'}
                </span>
                {
                  design.unreadCount
                    ? <span className="unread-count">{design.unreadCount}</span>
                    : null
                }
              </div>
            </div>
          </div>
        </div>))
        }
      </div >
    );
  }
}
