import React, { PureComponent } from "react";
import Downshift from "downshift";
import PropTypes from "prop-types";

import icDropdown from "../../../assets/images/navigation/ic-arrow-drop-down-grey.svg";
import CheckBox from "../Checkbox/Checkbox";

import PerfectScrollbar from "react-perfect-scrollbar";
import Button from "../Button/Button";

class NotificationFilter extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    onChange: PropTypes.func,
    initialSelectedItem: PropTypes.object,
    filterOptions: PropTypes.array,
    disable: PropTypes.bool,
    applyCollections: PropTypes.func,
    cancelCollectionFilter: PropTypes.func,
    isApplyBar: PropTypes.bool,
    isTitle: PropTypes.bool,
    styles: PropTypes.object,
    selectAll: PropTypes.func,
    isCancel: PropTypes.bool
  };

  render() {
    const {
      title,
      onChange,
      initialSelectedItem,
      filterOptions = [],
      disable = false,
      applyCollections,
      cancelCollectionFilter,
      isApplyBar = false,
      isTitle = false,
      styles = {},
      selectAll,
      isCancel
    } = this.props;

    return (
      <Downshift
        initialSelectedItem={initialSelectedItem}
        itemToString={item => item.label}
        onChange={selection => {
          onChange(selection);
          // alert(JSON.stringify(selection))
          // this.handleUserAuthorityChange(collab, selection);
        }}
      >
        {({
          getInputProps,
          getToggleButtonProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          isOpen,
          inputValue,
          highlightedIndex,
          selectedItem
        }) => {
          return (
            <div
              {...getToggleButtonProps()}
              type="none"
              style={{
                userSelect: "none",
                marginTop: "0.3rem",
                position: "relative",
                cursor: "pointer",
                border: "solid 1px var(--fill-grey-light2)",
                borderRadius: "4px",
                padding: "6px 12px",
                paddingRight: "4px",
                width: "100%",
                backgroundColor: disable ? "#eee" : "#fff",
                height: "44px"
              }}
            >
              <span
                {...getLabelProps()}
                style={{ fontSize: "12px", color: "var(--fill-grey-dark)" }}
              >
                {isTitle ? title : null}
              </span>
              <span className="flex" style={{ alignItems: "center" }}>
                <input
                  style={{
                    border: "none",
                    backgroundColor: "transparent",
                    maxWidth: "200px"
                  }}
                  className="select-input-transparent"
                  readOnly={true}
                  disabled={true}
                  {...getInputProps()} // have to refactor this one mostly .. uncommit this for value in filter
                />
                <img
                  src={icDropdown}
                  alt="dropdown"
                  style={{ marginLeft: "auto" }}
                />
              </span>
              {isOpen && !disable ? (
                <div
                  className="select-pane"
                  // onClick={e => {
                  // //   e.stopPropagation();
                  // }}
                  style={{
                    width: "100%",
                    height: "220px",

                    paddingLeft: "10px",
                    alignItems: "center",
                    position: "relative"
                  }}
                >
                  {/* THIS IS COLLECTION STATUS LABEL AT TOP ==== STARTS ==== */}
                  <div
                    style={{
                      padding: "15px 0px",
                      paddingTop: "15px",
                      paddingBottom: "25px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center"
                    }}
                    id="DOEN"
                  >
                    <div className="f-w-bold">{`All Users (${
                      filterOptions.length
                      })`}</div>
                    <div
                      style={{
                        transform: "translateY(-8px)",
                        paddingRight: "10px"
                      }}
                    >
                      <CheckBox
                        value={
                          filterOptions.filter(option =>
                            option.isSelected ? option.isSelected : null
                          ).length === filterOptions.length
                        }
                        onClick={selectAll}
                      />
                    </div>
                  </div>
                  {/* THIS IS COLLECTION STATUS LABEL AT TOP ==== ENDS ==== */}

                  {/* === THESE ARE BRAND OPTION FROM HERE ==== STARTS ==== */}
                  <div
                    style={{
                      height: "150px",
                      overflow: "hidden",
                      paddingBottom: "30px"
                    }}
                  >
                    <PerfectScrollbar
                      option={{
                        suppressScrollX: true
                      }}
                    >
                      {filterOptions
                        // .filter(item => item.label !== selectedItem.label)
                        .map((item, index) => {
                          return (
                            <div
                              {...getItemProps({
                                className:
                                  "in-selection collection-collab__label_with_checkBox flex",
                                index,
                                item,
                                key: item.label
                              })}
                              style={{
                                paddingRight: "10px"
                              }}
                            >
                              <div>
                                <div
                                  style={{
                                    fontWeight: item.isSelected ? "600" : "300"
                                  }}
                                >{`${item.label}`}</div>
                                <div
                                  style={{
                                    fontSize: "10px",
                                    color: "var(--fill-grey-medium)"
                                  }}
                                >
                                  {item.email}
                                </div>
                              </div>
                              <div style={{ transform: "translateY(-8px)" }}>
                                <CheckBox
                                  value={item.isSelected}
                                  onClick={() =>
                                    onChange({ value: item.value })
                                  }
                                />
                              </div>
                            </div>
                          );
                        })}
                    </PerfectScrollbar>
                  </div>
                  {/* === THESE ARE BRAND OPTION FROM HERE ==== ENDS ==== */}

                  {/* ==== THESE ARE APPLY AND CANCEL BUTTONS ===== STARTS ==== */}
                  {isApplyBar ? (
                    <div
                      style={{
                        backgroundColor: "#fff",
                        position: "absolute",
                        right: 0,
                        bottom: 0,
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",

                        boxShadow: " 0 8px 16px 0 rgba(0, 1, 38, 0.5)"
                      }}
                    >
                      {isCancel ? (
                        <div
                          style={{
                            fontSize: "12px",
                            fontWeight: 600,
                            textTransform: "uppercase",
                            color: "var(--fill-grey-medium)",
                            letterSpacing: "1px",
                            padding: "15px 10px"
                          }}
                          onClick={cancelCollectionFilter}
                        >
                          Clear selection
                        </div>
                      ) : null}
                      <Button
                        category="blue-bg"
                      // onClick={applyCollections}
                      >
                        Apply
                      </Button>
                    </div>
                  ) : null}
                  {/* ==== THESE ARE APPLY AND CANCEL BUTTONS ===== ENDS ==== */}
                </div>
              ) : null}
            </div>
          );
        }}
      </Downshift>
    );
  }
}

export default NotificationFilter;
