import React, { Component } from "react";
import PropTypes from "prop-types";

import Header from "../Header";
import Message from "../Message";
import Footer from "../Footer";
import FactoryList from "../FactoryList";
import EmptyList from "../EmptyList";
import { CLIENT_SUPER, CLIENT, AUTH_ROLES } from "../../../constants/Auth";
import { checkMerchant } from "helpers/AuthHelper";

import "./index.scss";
import TnaImageModal from "../../ProductionTracker/TnaImageModal";

export default class DesignMessages extends Component {
  static propTypes = {
    factories: PropTypes.array.isRequired,
    brand: PropTypes.object.isRequired,
    messages: PropTypes.array.isRequired,
    userDetails: PropTypes.object.isRequired,
    design: PropTypes.object.isRequired,
    showDesignName: PropTypes.bool,
    style: PropTypes.object,
    sendMessage: PropTypes.func.isRequired,
    fetchFactories: PropTypes.func.isRequired,
    fetchMessages: PropTypes.func.isRequired,
  };
  state = {
    senderOrg: null,
    receiverOrg: null,
    selectedFactory: null,
    showImageModal: false,
    imageModalData: null,

    propsFactories: null,
    propsDesign: null,
  };

  static getDerivedStateFromProps(nextProps, oldState) {
    const nextState = {};
    const { isFactory } = nextProps.userDetails;

    if (oldState.propsDesign !== nextProps.design) {
      nextState.propsDesign = nextProps.design;

      // design selection has changed reset the selected factories
      nextState.selectedFactory = null;
    }

    // if factories are changed
    if (oldState.propsFactories !== nextProps.factories) {
      nextState.propsFactories = nextProps.factories;
    }

    if (
      !oldState.selectedFactory &&
      nextProps.factories &&
      nextProps.factories.length > 0
    ) {
      nextState.selectedFactory = nextProps.factories[0];
    }

    if (oldState.senderOrg === null && nextProps.userDetails) {
      nextState.senderOrg = {
        id: nextProps.userDetails.brandId,
        name: nextProps.userDetails.brand,
      };
    }

    if (nextProps.brand && nextProps.userDetails) {
      const { brand } = nextProps;

      if (isFactory) {
        nextState.receiverOrg = { ...brand };
      } else {
        nextState.receiverOrg = {
          ...(nextState.selectedFactory || oldState.selectedFactory),
        };
      }
    }

    if (
      (nextState.selectedFactory &&
        (!oldState.selectedFactory ||
          oldState.selectedFactory.id !== nextState.selectedFactory.id)) ||
      (nextState.propsDesign &&
        (!oldState.propsDesign ||
          oldState.propsDesign.id !== nextState.propsDesign.id))
    ) {
      const designId = (nextState.propsDesign || oldState.propsDesign || {}).id;
      const factoryId = (
        nextState.selectedFactory ||
        oldState.selectedFactory ||
        {}
      ).id;

      if (designId && (factoryId || factoryId === null)) {
        nextProps.fetchMessages({ designId, factoryId });
      }
    }

    return Object.keys(nextState).length === 0 ? null : nextState;
  }

  componentDidMount() {
    this._scrollToBottom();
    if (this.refFooter) {
      this.refFooter.focus();
    }
  }

  _scrollToBottom = () => {
    this.refMessagesEnd.scrollIntoView({ behavior: "smooth" });
  };

  _isMessageSent = (msgSenderRole) => {
    const { userDetails } = this.props;
    const isFactory = userDetails.isFactory;

    const nMsgSenderRole = parseInt(msgSenderRole);
    const wasSentByFactory =
      AUTH_ROLES[nMsgSenderRole] !== CLIENT_SUPER &&
      AUTH_ROLES[nMsgSenderRole] !== CLIENT;

    return isFactory === wasSentByFactory;
  };

  _onSelectFactory = (factory) => {
    const { fetchMessages, design } = this.props;

    this.setState({
      selectedFactory: factory,
      receiverOrg: factory,
    });

    fetchMessages({ designId: design.id, factoryId: factory.id });
  };

  _onShowImageModal = (isVisible, data) => {
    this.setState({ showImageModal: isVisible, imageModalData: data });
  };

  _onSendMessage = ({ message, files }) => {
    const { design, sendMessage } = this.props;
    const { selectedFactory } = this.state;

    sendMessage({
      designId: design.id,
      factoryId: selectedFactory.id,
      message: message,
      file: files && files[0],
    }).then(() => {
      this.setState({
        fileComment: "",
        uploadFile: null,
        showUploadImageModal: false,
      });
      this._scrollToBottom();
    });
  };

  componentDidUpdate() {
    this._scrollToBottom();
  }

  render() {
    const {
      alignFactory = "right",
      factories = [],
      design,
      messages: allMessages,
      userDetails,
      style,
      showDesignName = false,
    } = this.props;
    const {
      selectedFactory,
      receiverOrg,
      showImageModal,
      imageModalData,
    } = this.state;
    const messages =
      (selectedFactory && allMessages && allMessages[selectedFactory.id]) || [];
    const canMessage = !!(selectedFactory && selectedFactory.can_message);

    return (
      <div className="messaging-design-container" style={style}>
        <div className="messaging-design-container__content">
          <Header
            design={design}
            receiverOrg={receiverOrg}
            userDetails={userDetails}
            showDesignName={showDesignName}
          />
          <div
            className="messages-list scroll-auto"
            ref={(ref) => (this.refMessages = ref)}
          >
            {messages.length > 0
              ? messages.map((message) => (
                  <Message
                    key={message.id}
                    message={message}
                    align={
                      this._isMessageSent(message.createdBy.role)
                        ? "right"
                        : "left"
                    }
                    onShowImage={(data) => this._onShowImageModal(true, data)}
                  />
                ))
              : canMessage && <EmptyList />}
            <div className="" ref={(ref) => (this.refMessagesEnd = ref)}></div>
          </div>
          {!checkMerchant() && (
            <Footer
              ref={(ref) => (this.refFooter = ref)}
              receiverOrg={receiverOrg}
              userDetails={userDetails}
              onSendMessage={this._onSendMessage}
              canMessage={canMessage}
            />
          )}
        </div>
        {(userDetails.isBrand || userDetails.isSuperAdmin) &&
          factories.length > 1 && (
            <div
              className={`messaging-design-container__sidebar messaging-design-container__sidebar--align-${alignFactory} scroll-auto`}
            >
              <FactoryList
                factories={factories}
                selected={selectedFactory && selectedFactory.id}
                onSelect={this._onSelectFactory}
              />
            </div>
          )}

        {showImageModal && (
          <TnaImageModal
            placeHolder={imageModalData.placeHolder}
            imageUrl={imageModalData.imageUrl}
            fileName={imageModalData.fileName}
            title={imageModalData.title}
            date={imageModalData.date}
            uploadedBy={imageModalData.uploadedBy}
            onClose={() => this._onShowImageModal(false)}
          />
        )}
      </div>
    );
  }
}
