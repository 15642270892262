import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import DesktopView from "./DesktopView";
import MobileView from "./MobileView";
import qs from "query-string";
import { PAGE_TITLES } from "../../constants/Titles";
import { Helmet } from "react-helmet";

const NotificationContainer = props => {
  const [isTna, setIsTna] = useState(false);
  const query = qs.parse(props.location.search);
  // if (!isTna && (query.initialTab === "designtna" || query.tab === "tna")) {
  //   console.log("NOTIFS", "INSIDE DESIGN TNA");
  //   setIsTna(true);
  // }

  // console.log(
  //   "NOTIFS",
  //   window.innerWidth > 900,
  //   isTna,
  //   window.innerWidth > 900 || isTna
  // );

  return (
    // <>{window.innerWidth > 900 || isTna ? <DesktopView /> : <MobileView />}</>
    <>
      <Helmet
        title={PAGE_TITLES.notifications()}
      />
      {window.innerWidth > 900 ? <DesktopView /> : <MobileView />}
    </>
  );
};

export default withRouter(NotificationContainer);
