import React from "react";

const Tab = ({ isActive, label = "Default", onClick }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
        height: "42px",
        fontSize: "14px",
        fontWeight: "bold",
        borderBottom: isActive ? "2px solid #004d99" : "2px solid #fff",
        color: isActive
          ? "var(--fill-locofast-black)"
          : "var(--fill-grey-medium)",
        margin: "0px 20px"
      }}
      onClick={onClick}
    >
      {label}
    </div>
  );
};

export default Tab;
