import React from 'react';
import PerfectScrollBar from 'react-perfect-scrollbar';
import BaseCollaborationComponent from '../BaseCollaborationComponent/BaseCollaborationComponent';
import ProfilePlate from '../../UI/ProfilePlate'
import { ProfilePlates as AutosuggestProfilePlates } from '../../UI/Autosuggest'
import imgClose from 'assets/images/navigation/ic-close.svg'
import './CollaborationModal.scss';

export default class CollaborationModal extends BaseCollaborationComponent {
  static propTypes = {
    ...BaseCollaborationComponent.propTypes
  }

  addCollaborator = (event, addedUser) => {
    const { remainingUsers, collaboratedUsers } = this.state;

    this.setState({
      remainingUsers: remainingUsers.filter(user => user.id !== addedUser.id),
      collaboratedUsers: [
        ...collaboratedUsers,
        addedUser
      ]
    });
  }

  removeCollaborator = (removedUser) => {
    const { remainingUsers, collaboratedUsers } = this.state;

    this.setState({
      collaboratedUsers: collaboratedUsers.filter(user => user.id !== removedUser.id),
      remainingUsers: [
        ...remainingUsers,
        removedUser
      ]
    });
  }

  submitCollaboration = () => {
    const { collection, collaborateCollection } = this.props;
    const { collaboratedUsers } = this.state;

    collaborateCollection({
      collectionId: collection.id,
      collaborations: collaboratedUsers.map(user => ({
        userid: user.id,
        role: 1
      })),
    });
  }

  renderMembers() {
    const { collaboratedUsers } = this.state;
    const { loggedInUser } = this.props;

    return <PerfectScrollBar>
      {collaboratedUsers && collaboratedUsers.map(user => (
        <div className="member-item" key={user.id}>
          <ProfilePlate profile={user} />
          {loggedInUser.id !== user.id
            && <div className="action-list">
              <span
                className="action action-close"
                onClick={() => this.removeCollaborator(user)}
              >
                <img src={imgClose} alt="" />
              </span>
            </div>}
        </div>
      ))}
    </PerfectScrollBar>;
  }

  render() {
    const { remainingUsers, collectionOwner } = this.state;

    return (<>
      <div className="collaboration-modal">
        <div className="add-member-container">
          <div className="label">Add member</div>
          <AutosuggestProfilePlates
            profiles={remainingUsers}
            onSelect={this.addCollaborator}
          />
        </div>
        <div className="owner-container">
          <div className="label">Owner</div>
          <ProfilePlate profile={collectionOwner} />
        </div>
        <div className="members-list">
          <div className="label">Members</div>
          {this.renderMembers()}
        </div>
      </div>
      <div className="modal__footer flex f-sz-m f-w-bold filled">
        <span onClick={this.submitCollaboration}>Done</span>
      </div>
    </>);
  }
}
