import React, { useState, Fragment, useEffect, useCallback } from "react";
import { Field } from "redux-form";
import classnames from "classnames";
import { components } from "react-select";
import PerfectScrollBar from "react-perfect-scrollbar";
import { connect } from "react-redux";
import moment from 'moment';
import { Helmet } from "react-helmet";
import find from "lodash/find";

import {
  FILTER_MATERIAL_CATEGORY,
  FILTER_FABRIC_COLORS,
  FILTER_FABRIC_IDEAL_FOR
} from "constants/SwatchLibrary";

import {
  MATERIAL_UNITS,
  MATERIAL_UNITS_TRIMS,
  MATERIAL_TYPE_CATEGORY,
  MATERIAL_UNITS_HASH,
  MATERIAL_TYPE_HASH,
  MATERIAL_LIBRARY_TABS
} from "constants/MaterialLibrary";

import Form from "components/UI/Form/Form";
import FileInput from "./FileVertical";
import Select from "components/UI/Form/Select/Select";
import CreatableSelect from "components/UI/Form/Select/CreatableSelect";
import Input from "components/UI/Form/Input/Input";
import CustomSelect from "components/UI/Form/CustomSelect/CustomSelect";
import Checkbox from "components/UI/Checkbox/Checkbox";
import SizeTable from "./../SizeTable";
import StaticTable from "./../SizeTable/StaticTable";

import {
  required,
  maxLength,
  sanitised,
  minValue,
  decimalUpto2,
  validFileTypes,
} from "helpers/form-validations";
import { selectStyles } from "./select-styles";

import "./styles.scss";
import CustomFile from "../../UI/Form/CustomFile";
import { DATESTAMP__SHORT_FORMAT } from "../../../constants/Date";
import { VALID_FILE_TYPES } from "../../../constants/Global";
import { getMaterialOptions, createMaterialOptions } from "store/actions/MaterialLibraryActions";

const FORM_NAME = "AddMaterial";
const INITIAL_UNIT = MATERIAL_UNITS[0].value;

const maxLength25 = maxLength(25);
const maxLength50 = maxLength(50);
const minValue0 = minValue(0.01);
const validAnyFiles = validFileTypes(VALID_FILE_TYPES);

const { customSingleStyles, customMultiStyles, moreColorStyles } = selectStyles;

const FormRow = ({ children, label, required = false, hidden = false, className }) =>
  hidden ? null : (
    <div className={classnames("form-row", className)}>
      <div className="f-sz-m-16 form-label">
        {label}
        {required && <sup>*</sup>}
      </div>
      {children}
    </div>
  );

const FormGroup = ({ children, hidden = false }) => {
  if (hidden) return null;
  return <div className={classnames("flex")}>{children}</div>;
};

const MyColorOption = ({ innerProps, innerRef, data, ...props }) => (
  <components.Option innerRef={innerRef} innerProps={innerProps} {...props}>
    <div className="flex" style={{ alignItems: "center" }}>
      <div
        style={{
          height: "20px",
          width: "20px",
          borderRadius: "10px",
          marginRight: "5px",
          background: data.color,
          border: "1px solid #bfbfbf"
        }}
      />
      <span className="capitalize">{data.label}</span>
    </div>
  </components.Option>
);

const MoreColorMultiValue = ({
  innerProps,
  innerRef,
  data,
  children,
  ...props
}) => (
    <components.MultiValue innerRef={innerRef} innerProps={innerProps} {...props}>
      <div className="flex" style={{ alignItems: "center" }}>
        <div
          style={{
            height: "24px",
            width: "24px",
            borderRadius: "50%",
            border: "1px solid #bfbfbf",
            background: data.color
          }}
        />
      </div>
    </components.MultiValue>
  );

const AddMaterialModal = ({
  fabricCompositions,
  patterns,
  weaves,
  getMaterialOptions,
  createMaterialOptions,
  ...props
}) => {
  const [measurementUnit, setMeasurementUnit] = useState("2");
  const [trimsCategory, setTrimsCategory] = useState(null);
  const [isTestReportChanged, setTestReportChanged] = useState(false);
  const { materialType } = props.extraFields || {};
  const isTrims = materialType == MATERIAL_LIBRARY_TABS.trims;
  const alertEnabled = props.formData && props.formData.values && props.formData.values.alertEnabled === '1';

  useEffect(() => {
    if (props.formData && props.formData.values) {
      if (props.formData.values["unit"] !== measurementUnit) {
        setMeasurementUnit(props.formData.values["unit"]);
      }
    }
  }, [props.formData]);

  useEffect(() => {
    props.setHeaderTitle(
      `${props.editMode ? "Update" : "Add"} ${
      MATERIAL_TYPE_HASH[props.extraFields.materialType]
      }`
    );

    getMaterialOptions();
  }, []);

  const onAddOption = useCallback((type, optionValue) => {
    return createMaterialOptions({
      type,
      label: optionValue,
    });
  }, [createMaterialOptions]);

  return (
    <PerfectScrollBar style={{ maxHeight: "580px" }}>
      <Helmet
        bodyAttributes={{
          class: "add_material_container"
        }}
      />
      <Form
        {...props}
        initialValues={{
          unit: INITIAL_UNIT,
          inStock: 1,
          ...props.extraFields,
          ...props.initialValues,
          alertEnabled: ((props.initialValues && props.initialValues.alertEnabled) || 0).toString(),
        }}
        form="AddMaterial"
      >
        {({ changeFormValue, ...formProps }) => (
          <div className="add-material-modal">
            <div>
              <FormGroup>
                <Field
                  name="image"
                  component={FileInput}
                  headerText="Image"
                  validate={[required]}
                />
              </FormGroup>
            </div>

            <div>
              <FormRow label="ID" required>
                <Field
                  name="generatedId"
                  component={Input}
                  validate={[required, maxLength50]}
                  type="text"
                />
              </FormRow>
              {isTrims && (
                <FormRow label="Category" required>
                  <Field
                    name="trimsCategoryId"
                    component={Select}
                    options={props.trimsCategories || []}
                    styles={customSingleStyles}
                    validate={[required]}
                    theme={theme => ({
                      ...theme,
                      borderColor: "var(--fill-orangered)"
                    })}
                    selectProps={{
                      classNamePrefix: "react-select",
                      isDisabled: props.editMode
                    }}
                    className="react-select-container"
                    onChange={(event, value) => {
                      const trimsCategory = find(props.trimsCategories, { value });
                      setTrimsCategory(trimsCategory);
                      formProps.change('validTrimSizes', trimsCategory.sizes.map(size => size.id))
                    }}
                  />
                </FormRow>
              )}
              <FormRow label="Color" required>
                <Field
                  name="colors"
                  component={CustomSelect}
                  styles={moreColorStyles}
                  options={FILTER_FABRIC_COLORS}
                  validate={[required]}
                  selectProps={{
                    components: {
                      MultiValue: MoreColorMultiValue,
                      Option: MyColorOption
                    },
                    styles: moreColorStyles,
                    placeholder: "Select",
                    classNamePrefix: "react-select color-select"
                  }}
                  className="react-select-container"
                  isMulti
                />
              </FormRow>
              <FormRow label="Unit of Measurement" required>
                <div className={`flex `}>
                  <Field
                    name="unit"
                    component={RadioGroup}
                    validate={[required]}
                    options={isTrims ? MATERIAL_UNITS_TRIMS : MATERIAL_UNITS}
                    disabled={props.editMode}
                  />
                </div>
              </FormRow>
              <FormRow label="Price" required>
                <Field
                  name="price"
                  component={Input}
                  type="number"
                  validate={[maxLength25, required, minValue0, decimalUpto2]}
                  rightAddon={
                    <div>₹ / {MATERIAL_UNITS_HASH[measurementUnit]}</div>
                  }
                />
              </FormRow>
              <FormRow label="Quantity" required>
                {trimsCategory && trimsCategory.sizes.length > 0 ? (
                  <Field
                    name="quantity"
                    component={SizeTable}
                    type="number"
                    disabled={props.editMode}
                    availableSizes={trimsCategory.sizes}
                    tableHeading={`Quantity(${MATERIAL_UNITS_HASH[measurementUnit]})`}
                  />
                ) : isTrims && props.editMode && props.hasMultiSize ? (
                  <StaticTable
                    sizes={props.staticTrimsSize}
                    tableHeading={`Quantity(${MATERIAL_UNITS_HASH[measurementUnit]})`}
                  />
                ) : (
                      <Field
                        name="quantity"
                        component={Input}
                        type="number"
                        validate={[maxLength25, required, minValue0, decimalUpto2]}
                        rightAddon={
                          <div>{MATERIAL_UNITS_HASH[measurementUnit]}</div>
                        }
                        disabled={props.editMode}
                      />
                    )}
              </FormRow>
              <FormRow label="Alert when quantity falls below the limit" className="field-row-alert">
                <div className="grid-2 col-equal">
                  <div className="flex">
                    <Field
                      name="alertEnabled"
                      component={RadioGroup}
                      validate={[required]}
                      options={[
                        { title: "Off", value: '0' },
                        { title: "On", value: '1' },
                      ]}
                    />
                  </div>
                  <Field
                    name="alertQuantity"
                    component={Input}
                    type="number"
                    placeholder="Enter count"
                    validate={[maxLength25, minValue0, decimalUpto2, ...(alertEnabled ? [required] : [])]}
                    rightAddon={
                      <div>{MATERIAL_UNITS_HASH[measurementUnit]}</div>
                    }
                    disabled={!alertEnabled}
                  />
                </div>
              </FormRow>
              <FormRow label="Fabric Composition" required hidden={isTrims}>
                <Field
                  name="composition"
                  component={CreatableSelect}
                  options={fabricCompositions}
                  styles={customSingleStyles}
                  validate={[required]}
                  theme={theme => ({
                    ...theme,
                    borderColor: "var(--fill-orangered)"
                  })}
                  selectProps={{
                    classNamePrefix: "react-select"
                  }}
                  className="react-select-container"
                  addCustomText="Add to Fabric composition"
                  onAddOption={(value) => onAddOption('fabricComposition', value)}
                  minCustomOptionLength={3}
                  maxCustomOptionLength={25}
                />
              </FormRow>
              <FormRow label="Pattern" required hidden={isTrims}>
                <Field
                  name="pattern"
                  component={CreatableSelect}
                  options={patterns}
                  styles={customSingleStyles}
                  validate={[required]}
                  selectProps={{
                    classNamePrefix: "react-select"
                  }}
                  className="react-select-container"
                  addCustomText="Add to Pattern"
                  onAddOption={(value) => onAddOption('pattern', value)}
                  minCustomOptionLength={3}
                  maxCustomOptionLength={25}
                />
              </FormRow>
              <FormRow label="Weave" required hidden={isTrims}>
                <Field
                  name="weave"
                  component={CreatableSelect}
                  options={weaves}
                  styles={customSingleStyles}
                  validate={[required]}
                  selectProps={{
                    classNamePrefix: "react-select"
                  }}
                  className="react-select-container"
                  addCustomText="Add to Weave"
                  onAddOption={(value) => onAddOption('weave', value)}
                  minCustomOptionLength={3}
                  maxCustomOptionLength={25}
                />
              </FormRow>
              <FormRow label="Category" required hidden={isTrims}>
                <Field
                  name="category"
                  component={MultiOptions}
                  // Exclude ALL filter since here we only select specific tags
                  options={FILTER_MATERIAL_CATEGORY}
                  styles={customMultiStyles}
                  isMulti
                  validate={[required]}
                  type="checkbox"
                  initialValue={
                    (props.initialValues && props.initialValues.category) ||
                    null
                  }
                />
              </FormRow>
              <FormRow label="Ideal for" required hidden={isTrims}>
                <Field
                  name="idealFor"
                  component={MultiOptions}
                  // Exclude ALL filter since here we only select specific tags
                  options={FILTER_FABRIC_IDEAL_FOR}
                  styles={customMultiStyles}
                  isMulti
                  validate={[required]}
                  type="checkbox"
                  initialValue={
                    (props.initialValues && props.initialValues.idealFor) ||
                    null
                  }
                />
              </FormRow>
              <FormRow label="Type of Category" required hidden={isTrims}>
                <div className={`flex`}>
                  <Field
                    name="categoryType"
                    component={RadioGroup}
                    validate={[required]}
                    options={MATERIAL_TYPE_CATEGORY}
                  />
                </div>
              </FormRow>
              <div className="grid-2 col-equal">
                {/* Optional Fields :: START */}
                <FormRow label="Thread Count" hidden={isTrims}>
                  <Field
                    name="threadCount"
                    component={Input}
                    type="number"
                    validate={[maxLength25, sanitised]}
                    rightAddon={<div>threads / inch</div>}
                  />
                </FormRow>
                <FormRow label="Weight" hidden={isTrims}>
                  <Field
                    name="weight"
                    component={Input}
                    type="number"
                    validate={[maxLength25]}
                    rightAddon={<div>gsm</div>}
                  />
                </FormRow>
              </div>
              <div className="grid-2 col-equal">
                <FormRow label="Fabric Width" hidden={isTrims}>
                  <Field
                    name="fabricWidth"
                    component={Input}
                    type="text"
                    validate={[maxLength25]}
                    rightAddon={<div>inch</div>}
                  />
                </FormRow>
                <FormRow label="Shrinkage" hidden={isTrims}>
                  <Field
                    name="shrinkage"
                    component={Input}
                    type="number"
                    validate={[maxLength25]}
                    rightAddon={<div>%</div>}
                  />
                </FormRow>
              </div>
              <div className={isTrims ? '' : "grid-2 col-equal"}>
                <FormRow label="Finish" hidden={isTrims}>
                  <Field
                    name="finish"
                    component={Input}
                    type="text"
                    validate={[maxLength25]}
                  />
                </FormRow>
                <FormRow label="Test report">
                  <Field
                    name="testReport"
                    component={CustomFile}
                    validate={[validAnyFiles]}
                    accept={VALID_FILE_TYPES}
                    files={props.initialValues ? props.initialValues.testReport : null}
                    onChange={() => setTestReportChanged(true)}
                  />
                  {(props.initialValues
                    && props.initialValues.testReport
                    && !isTestReportChanged) && <div className="test-report-ts f-sz-sm f-color-faded">
                      updated on {moment(props.initialValues.testReportUploadedAt).format(DATESTAMP__SHORT_FORMAT)}
                    </div>}
                </FormRow>
              </div>
              {/* Optional Fields :: END */}
            </div>
          </div>
        )}
      </Form>
    </PerfectScrollBar>
  );
};

const RadioGroup = props => {
  const { input, meta, options, disabled } = props;
  const hasError = meta.touched && meta.error;

  return (
    <Fragment>
      {options.map(o => (
        <div className="loco__radio loco__radio--left-align loco__radio--light">
          <input
            type="radio"
            {...input}
            value={o.value}
            checked={o.value === input.value}
            name={o.title}
            id={`${o.value}-${o.title}`}
            disabled={disabled}
          />
          <label key={o.value} htmlFor={`${o.value}-${o.title}`}>
            {o.title}
          </label>
        </div>
      ))}
      {hasError && <div className="error">{meta.error}</div>}
    </Fragment>
  );
};

const MultiOptions = ({ options = [{ label: "1", value: "1" }], ...props }) => {
  const { meta } = props;
  const hasError =
    meta.touched && (meta.error || props.input.value.length === 0);

  const [filterSelections, setfilterSelections] = useState(
    options.reduce((acc, opt) => ({ ...acc, [opt["value"]]: false }), {})
  );

  useEffect(() => {
    props.input.onChange(
      Object.entries(filterSelections).reduce((acc, [key, value]) => {
        if (value) acc.push(key);
        return acc;
      }, [])
    );
  }, [filterSelections]);

  useEffect(() => {
    if (props.initialValue) {
      setfilterSelections(
        options.reduce((acc, opt) => {
          return {
            ...acc,
            [opt["value"]]: props.initialValue.indexOf(opt["value"]) > -1
          };
        }, {})
      );
    }
  }, []);

  return (
    <div className="multi-options">
      {options.map(({ label, value }) => (
        <Checkbox
          theme="light"
          onClick={() => {
            setfilterSelections({
              ...filterSelections,
              [value]: !filterSelections[value]
            });
          }}
          value={filterSelections[value]}
          label={label}
          readOnly
        />
      ))}
      {hasError && <div className="error">This field cannot be empty</div>}
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  formData: state.form[FORM_NAME],
  trimsCategories: props.trimsCategories || state.materialLibrary.trimsCategories,
  fabricCompositions: props.fabricCompositions || state.materialLibrary.options.fabricComposition,
  patterns: props.patterns || state.materialLibrary.options.pattern,
  weaves: props.weaves || state.materialLibrary.options.weave,
});

const mapDispatchToProps = (dispatch, props) => ({
  getMaterialOptions: () => dispatch(getMaterialOptions({ brandId: props.extraFields.brandId })),
  createMaterialOptions: (data) => dispatch(createMaterialOptions({ ...data, brandId: props.extraFields.brandId })),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddMaterialModal);
