import React, { useState, useEffect } from "react";
import Downshift from "downshift";
import PerfectScrollbar from "react-perfect-scrollbar";

import "./styles.scss";
import icDropdown from "assets/images/navigation/ic-arrow-drop-down-grey.svg";
import CheckBox from "components/UI/Checkbox/Checkbox";
import { ReactComponent as CloseImg } from "assets/images/action/close.svg";
import clone from "lodash/clone";

const getTruthyValues = (selections = {}) => {
  const values = [];
  const count = Object.entries(selections).reduce((acc, [key, value]) => {
    if (value) {
      values.push(key);
      return acc + 1;
    } else return acc;
  }, 0);
  return [count, count > 0 ? values : null];
};

const getInitialState = filterOptions => {
  const initialSelection = {};
  filterOptions.forEach(option => (initialSelection[option.value] = false));
  return initialSelection;
};

const Filter = ({
  label,
  filterOptions = [],
  initialSelectedItem = null,
  onChange = () => {},
  selectedFilters = ""
}) => {
  const [selections, setSelections] = useState({});
  const [selectedCount, setSelectedCount] = useState(0);

  useEffect(() => {
    if (filterOptions.length > 0) {
      const initialSelection = getInitialState(filterOptions);
      setSelections(initialSelection);
    }
  }, []);

  useEffect(() => {
    const [count, values] = getTruthyValues(selections);
    setSelectedCount(count);
    onChange(values);
  }, [selections]);

  useEffect(()=>{
    if(selectedFilters){
      const updatedFilters = clone(selections);
      const splitFilters = selectedFilters.split(",");
      splitFilters.forEach(filter=>{
        updatedFilters[filter] = true;
      })
      setSelections(updatedFilters);
      setSelectedCount(splitFilters.length);
    }
  }, [selectedFilters])

  return (
    <div className="reports-dropdown">
      <div className="reports-dropdown__label">{label}</div>
      <Downshift
        initialSelectedItem={initialSelectedItem}
        itemToString={item => item && item.label}
      >
        {({
          getInputProps,
          getToggleButtonProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          isOpen,
          inputValue,
          highlightedIndex,
          selectedItem,
          getRootProps,
          reset,
          clearSelection
        }) => {
          return (
            <div
              {...getToggleButtonProps()}
              className="downshift dropdown-filter"
              type="none"
            >
              <span
                className="flex input-box"
                {...getRootProps({}, { suppressRefError: true })}
              >
                {isOpen || selectedCount === 0 ? (
                  <input
                    className="select-input-transparent"
                    placeholder="All"
                    {...getInputProps()}
                  />
                ) : (
                  <div>{selectedCount} Selected</div>
                )}

                <img src={icDropdown} alt="dropdown" />
              </span>
              {isOpen ? (
                <>
                  <div className="select-pane">
                    <div {...getMenuProps()}>
                      <PerfectScrollbar
                        option={{
                          suppressScrollX: true
                        }}
                        className={`filter-scroll ${selectedCount === 0 &&
                          "full-height"}`}
                      >
                        {filterOptions
                          .filter(
                            item =>
                              !inputValue ||
                              (inputValue &&
                                item.label &&
                                item.label
                                  .toLowerCase()
                                  .includes(inputValue.toLowerCase()))
                          )
                          .map((item, index) => {
                            return (
                              <div
                                key = {item.value}
                                className="in-selection collection-collab__label_with_checkBox flex"
                                style={{
                                  fontWeight: item.isSelected ? "600" : "300",
                                  paddingRight: "10px"
                                }}
                              >
                                <div style={{ transform: "translateY(-8px)" }}>
                                  <CheckBox
                                    value={selections[item.value]}
                                    onClick={() => {
                                      setSelections({
                                        ...selections,
                                        [item.value]: !selections[item.value]
                                      });
                                    }}
                                    name={item.label}
                                    label={item.label}
                                  />
                                </div>
                              </div>
                            );
                          })}
                      </PerfectScrollbar>
                      {selectedCount > 0 ? (
                        <div
                          className="reports-dropdown__footer"
                          onClick={() => {
                            setSelections(getInitialState(filterOptions));
                            clearSelection();
                          }}
                        >
                          <CloseImg /> Clear all selection
                        </div>
                      ) : null}
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          );
        }}
      </Downshift>
    </div>
  );
};

export default Filter;
