import React from "react";
import classnames from "classnames";
import { saveAs } from "file-saver";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import "./styles.scss";
import Button from "components/UI/Button/Button";
import Pagination from "components/Product/Pagination";
import { computeCsvBlob } from "helpers/reportsTransformer.js";
import { flash } from "../../../../store/actions/CommonActions";

const ReportTabs = ({
  reports,
  activeReport
}) => {
  if (!Array.isArray(reports) || reports.length === 0) {
    return null;
  }

  return <div className="sub-report-tabs">
    {reports.map((report) => <div
      key={report.key}
      className={classnames("sub-report-tabs__item", {
        'sub-report-tabs__item--active': activeReport === report.key
      })}
    >
      <Link to={report.url}> {report.label} </Link>
    </div>)}
  </div>;
};

const Header = ({
  heading,
  reportType,
  paginationHandler = () => { },
  pagination,
  currentIndex,
  getOverallReport,
  headings,
  metadata,
  subReports,
  activeSubReport,
}) => {
  return (
    <header className="reports-header">
      <h2>{heading}</h2>
      <ReportTabs reports={subReports} activeReport={activeSubReport} />
      <div className="flex-grow"></div>
      <div className="flex center">
        <Pagination
          label="orders"
          onclick={paginationHandler}
          page={
            pagination && {
              ...pagination,
              totalCount: pagination.totalCount
            }
          }
          currentIndex={currentIndex}
          showTotalRecords={false}
        />
        <Button
          category="black-ghost"
          onClick={async () => {
            const overallReport = await getOverallReport();
            if (overallReport.length > 0) {
              try {
                var blob = new Blob([computeCsvBlob(overallReport, reportType, headings, metadata)], {
                  type: "text/csv;charset=utf-8"
                });
                saveAs(blob, `${heading}-${new Date().toLocaleDateString()}.csv`);
              } catch (err) {
                console.log(err)
                flash({
                  message: "Could not export report",
                  type: "action",
                  status: "error"
                })
              }

            } else {
              flash({
                message: "Cannot export empty report.",
                type: "action",
                status: "info"
              })
            }
          }}
        >
          Export
        </Button>
      </div>
    </header>
  );
};

export default connect(null, {
  flash
})(Header);
