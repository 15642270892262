import React, { useState } from "react";
import axios from "axios";
import moment from "moment";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import { get_service_endpoint } from "../../../ServiceEndpoints";
import actions from "../../../store/actions";
import { postNewSampleDetails } from "../../../store/actions/DesignActions"
import { flash } from "../../../store/actions/CommonActions";
import DetailsModel from "../../SwatchLibrary/DetailsModel";
import { TIMESTAMP_FORMAT } from "../../../constants/Date";

const newEp = get_service_endpoint("notificationView");

const SampleNotRequiredModal = ({ dispatch, details, postNewSampleDetails, closeModal }) => {

  return (
    <div style={{ marginBottom: "8px", maxWidth: '400px' }}>
      <div style={{margin: '24px', fontSize: '14px'}}>Are you sure you do not need a sample? You will not be able to change this later</div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0px 24px",
          backgroundColor: "#fff",
          alignItems: "center",
          paddingTop: "0px",
          marginTop: "32px",
          borderTop: "1px solid var(--fill-grey-light3)"
        }}
      >
        <div
          onClick={() => {
            closeModal();
          }}
          style={{ cursor: "pointer" }}
        >
          CANCEL
        </div>
        <div
          
          style={{
            padding: "8px",
            color: "var(--fill-secondary-medium)",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            fontWeight: 600
          }}
        >
          <span 
            style={{ paddingRight: "8px", paddingTop: "4px" }} 
            onClick={ ()=>{
              postNewSampleDetails(details)
              closeModal()
            }
            }>
              SAMPLE NOT REQUIRED
            </span>
          <i className="fa fa-2x fa-angle-right" style={{}} />
        </div>
      </div>
      </div>
  );
};



export default connect(null, {postNewSampleDetails})(SampleNotRequiredModal);
