import React from "react";
import ReactTooltip from "react-tooltip";

import Design from "./Design";
import styles from "./Product.module.scss";
import { getCurrentUserRole } from "../../helpers/AuthHelper";
import {
  FACTORY_MANAGER,
  LOCO_SUPER,
  FACTORY_ADMIN,
  PRODUCTION_MERCHANT,
  PRODUCT_DEVELOPMENT
} from "../../constants/Auth";

const DesignsWithScroll = ({
  designs = [],
  getSeletedDesign,
  disableCheckbox,
  canCreateOrder
}) => {
  const currentRole = getCurrentUserRole();

  const allowedRoles = [LOCO_SUPER, PRODUCTION_MERCHANT, PRODUCT_DEVELOPMENT];

  const isFactory = allowedRoles.indexOf(currentRole) > -1 ? true : false;
  const hideCheckbox =
    currentRole == PRODUCTION_MERCHANT ||
    currentRole == FACTORY_ADMIN ||
    currentRole == FACTORY_MANAGER;


  if (designs && designs.length) {
    setTimeout(() => {
      ReactTooltip.rebuild();
    }, 1000);
  }

  return (
    <div className={styles.scroll_wrapper_design_list}>
      {designs.map(design => (
        <Design
          key={design.id}
          getSeletedDesign={getSeletedDesign}
          data={design}
          isFactory={isFactory && getCurrentUserRole() !== FACTORY_MANAGER}
          isSampling={true}
          disableCheckbox={disableCheckbox}
          canCreateOrder={canCreateOrder}
          hideCheckbox={hideCheckbox}
        />
      ))}
    </div>
  );
};

export default DesignsWithScroll;
