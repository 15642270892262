import React from 'react';
import PropTypes from 'prop-types';
import DesignFilters from './DesignFilters/index';
import DesignsList from './DesignsList/index';
import DesignMessages from './DesignMessages/index';
import emptyIcon from "../../assets/img/Messages/ic-message-empty.svg";
import EmptyScreen from '../UI/EmptyScreen';
import './index.scss';

export default class Messaging extends React.Component {
  static propTypes = {
    designs: PropTypes.array.isRequired,
    messages: PropTypes.array.isRequired,
    brands: PropTypes.array.isRequired, // computed from messages
    userDetails: PropTypes.object.isRequired,
    onSelectDesign: PropTypes.func.isRequired,
    fetchFactories: PropTypes.func.isRequired,
    fetchMessages: PropTypes.func.isRequired,
    sendMessages: PropTypes.func.isRequired,
    style: PropTypes.object,
  };

  state = {
    designs: [],
    selectedDesign: null,
    selectedBrand: null,

    propDesigns: null,
  }

  static getDerivedStateFromProps(nextProps, oldState) {
    const nextState = {};

    if (oldState.selectedDesign === null && nextProps.designs && nextProps.designs.length > 0) {
      nextState.selectedDesign = nextProps.designs[0];
      if (nextState.selectedDesign) {
        nextProps.fetchFactories({ designId: nextState.selectedDesign.id });
      }
    }

    if (oldState.selectedBrand === null && nextProps.brands && nextProps.brands.length > 0) {
      nextState.selectedBrand = nextProps.brands[0];
    }

    if (oldState.propDesigns !== nextProps.designs) {
      nextState.propDesigns = nextProps.designs;
      nextState.designs = nextProps.designs;
    }

    return Object.keys(nextState).length > 0 ? nextState : null;
  }

  _onSelectDesign = (design) => {
    const { onSelectDesign } = this.props;
    this.setState({ selectedDesign: design });
    onSelectDesign(design);
  }

  _onFilterApply = ({ brand }) => {
    const { designs } = this.props;

    if (brand) {
      this.setState({
        selectedBrand: brand,
        designs: designs.filter(d => d.brand === brand.id),
      });
    } else {
      this.setState({
        selectedBrand: null,
        designs: designs,
      });
    }
  }

  render() {
    const {
      factories,
      brands,
      messages,
      userDetails,
      style,
      fetchFactories,
      fetchMessages,
      sendMessage,
    } = this.props;
    const { designs, selectedDesign } = this.state;

    return <div className="messaging-container" style={style}>
      {designs.length === 0
        ? <EmptyScreen
          icon={emptyIcon}
          heading="No messages"
          description="Create your first design to start messaging"
        />
        : <><div className="messaging-container__sidebar">
          {(!userDetails.isBrand && brands && brands.length > 1) && <DesignFilters
            brands={brands}
            onApply={this._onFilterApply}
          />}
          <DesignsList
            designs={designs}
            selected={selectedDesign && selectedDesign.id}
            onSelect={(design) => this._onSelectDesign(design)}
          />
        </div>
          <div className="messaging-container__body">
            {
              selectedDesign
                ? <DesignMessages
                  factories={factories && factories[selectedDesign.id]}
                  messages={messages[selectedDesign.id]}
                  userDetails={userDetails}
                  brand={{ id: selectedDesign.brand, name: selectedDesign.brand_name }}
                  design={selectedDesign}
                  showDesignName={true}
                  sendMessage={sendMessage}
                  fetchFactories={fetchFactories}
                  fetchMessages={fetchMessages}
                />
                : null
            }
          </div>
        </>}
    </div>;
  }
}
