import React, { useState } from "react";
import axios from "axios";
import moment from "moment";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import isEmpty from "lodash/isEmpty";
import classNames from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { get_service_endpoint } from "ServiceEndpoints";
import actions from "store/actions";
import Radio from "components/UI/Radio/index";
import SuccessState from "./SuccessState";
import { getCurrentUserRole } from "helpers/AuthHelper";

import { TIME_DATE_FORMAT } from "constants/Date";
import { PRODUCTION_MERCHANT, CLIENT_SUPER, CLIENT } from "constants/Auth";
import InputAttachments from "../../../UI/InputAttachments/index";
import { createFormData } from "../../../../helpers/formHelper";
import "./styles.scss";
import SampleFiles from "../SampleFiles";
import { VALID_FILE_TYPES } from "../../../../constants/Global";

const newEp = get_service_endpoint("notificationView");

const CourierDetails = ({
  details,
  extra,
  setDispatchData,
  openModal,
  closeModal,
  flash,
  dispatchDeatilsUpdatedInSaplinTracker,
  getSamplesByDesignId,
  viewSuccessState = false,
  afterSubmit,
  brandName,
  setHeaderTitle,
  prevTnaDate,
  design,
}) => {
  // console.log("DETAILS PROPS IN COURIES MODAL ", details);
  const {
    data: {
      sampleDispatch = [],
      designId,
      sampleType,
      id,
      factoryId,
      callSampleTrackerFunction = false
    } = {},
    currentSampleDispatch
  } = details;

  let initialValues = {};

  if (sampleDispatch.length > 0) {
    if (currentSampleDispatch) {
      initialValues.courierPartner = currentSampleDispatch.courierPartner;
      initialValues.sentDate = currentSampleDispatch.sentDate;
      initialValues.trackingNumber = currentSampleDispatch.trackingNumber;
      initialValues.id = currentSampleDispatch.id;
      initialValues.courierReceived = currentSampleDispatch.courierReceived;
    }
  }

  const {
    sentDate = null,
    trackingNumber: inittrackingNumber = null,
    courierPartner: initcourierPartner = null,
    courierReceived: initcourierReceived = null
  } = initialValues;

  const [date, setdate] = useState(sentDate);
  const [trackingNumber, settrackingNumber] = useState(inittrackingNumber);
  const [fileAttachments, setFileAttachments] = useState([]);

  const [courierPartner, setcourierPartner] = useState(initcourierPartner);
  const [editMode, setEditMode] = useState(initcourierPartner ? true : false);
  const [courierReceived, setCourierReceived] = useState(
    initcourierReceived || false
  );
  const [dispatchSaved, setDispatchSaved] = useState(false);

  const addCourierDetailsHandler = () => {
    const request = {
      sampleId: id,
      sentDate: moment(date.toISOString().slice(0, -1)).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
      trackingNumber,
      courierPartner,
      courierReceived: courierReceived
    };

    // data.append("designId", designId);
    // data.append("moduleRef", moduleRef);
    // data.append("comment", commit);
    // data.append("moduleType", 1);
    // data.append("moduleStatus", moduleStatus);
    // data.append("moduleId", moduleId);

    let reqPromise;
    let commentUploadData;

    if (!currentSampleDispatch) {
      reqPromise = axios.post(`${newEp}/sample/dispatch`, request);
    } else {
      // do patch here
      reqPromise = axios.patch(`${newEp}/sample/dispatch`, {
        ...request,
        id: currentSampleDispatch.id
      });
    }

    reqPromise.then(sampleDispatchData => {

      const commentsRequest = {
        designId,
        moduleRef: sampleType,
        moduleType: 1,
        moduleStatus: "Dispatch",
        moduleId: id,
        comment: `Sample dispatched via ${courierPartner} with tracking ID ${trackingNumber} on ${moment(
          date
        ).format("MMM DD, YYYY")}`
      };

      const formData = createFormData(commentsRequest);

      fileAttachments.map(file => formData.append('file', file));

      axios
        .post(`${newEp}/comments/designs/${designId}/factories/${factoryId}`, formData)
        .then(res => {
          commentUploadData = res.data;
          // console.log("RES FORM COURIER COMMENT", res);
          // this.props.dispatch(
          //   actions.flash({ message: "Comment added successfully." })
          // );
          // this.props.dispatch(actions.getSamplesByDesignId({ designId }));
          // this.props.dispatch(actions.closeModal());
          return res;
        })
        .catch(err => console.log("ERR IN COMMITS ", err))
        .finally(() => {

          flash({ message: "Sample Dispatched successfully." });
          if (viewSuccessState) {
            setDispatchSaved(true);
            afterSubmit();
          } else {
            closeModal();
          }

          if (setDispatchData) {
            setDispatchData({ date }, sampleDispatchData.data, commentUploadData);
          }

          if (callSampleTrackerFunction) {
            // dispatch(actions.getAllSamplesForSampleTracker({}));
            // callSampleTrackerFunction contains the the sample being updated in sample tracker
            callSampleTrackerFunction.dispatchSentDate = moment(date).format();

            if (currentSampleDispatch) {
              callSampleTrackerFunction.trackingNumber = trackingNumber;
              callSampleTrackerFunction.courierPartner = courierPartner;
            }
            dispatchDeatilsUpdatedInSaplinTracker({
              ...callSampleTrackerFunction
            });
          } else {
            getSamplesByDesignId({ designId: designId });
          }
        });

      // get here
    });
  };

  const handleAttachmentsChange = (files) => {
    setFileAttachments(files);
  };

  if (dispatchSaved) {
    setHeaderTitle('');
  }

  return (
    <div className="courier__details">
      {dispatchSaved ? (
        <SuccessState
          closeModal={closeModal}
          brandName={brandName}
        />
      ) : (
          <PerfectScrollbar className="dispatch-container">
            <div className="w-100 with_datepicker">
              <div className="label__class">
                Dispatch date<sup>*</sup>
              </div>
              <DatePicker
                popperPlacement="bottom-start"
                popperClassName="custom-datepicker-popper"
                required={true}
                popperModifiers={{
                  preventOverflow: {
                    enabled: true,
                    escapeWithReference: false // force popper to stay in viewport (even when input is scrolled out of view)
                  }
                }}
                disabled={editMode}
                placeholderText="Select Date"
                dateFormat="MMMM dd, yyyy"
                className="custom-datepicker"
                selected={date ? moment(date).valueOf() : ""}
                onChange={arg1 => {
                  setdate(arg1);
                }}
                minDate={prevTnaDate}
                maxDate={moment().format(TIME_DATE_FORMAT)}
              />
            </div>

            <div className="flex-grow">
              <div className="label__class">
                Courier partner
              <sup>*</sup>
              </div>
              <input
                type="text"
                className="w-100"
                onChange={ev => setcourierPartner(ev.target.value)}
                value={courierPartner}
                disabled={editMode}
                placeholder="E.g. Blue Dart"
              />
            </div>

            <div className="flex-grow">
              <div className="label__class">
                Tracking ID<sup>*</sup>
              </div>
              <input
                type="text"
                name=""
                id=""
                onChange={ev => settrackingNumber(ev.target.value)}
                value={trackingNumber}
                disabled={editMode}
                placeholder="Enter tracking ID"
                className="w-100"
              />
            </div>

            {getCurrentUserRole() !== "CLIENT" &&
              getCurrentUserRole() !== "CLIENT_SUPER" && (
                <div className="counter-field">
                  <div className={classNames("label__class", editMode && 'label__class--disabled')}>Counter created</div>
                  <Radio
                    name="counter"
                    label="Yes"
                    disabled={editMode}
                    checked={courierReceived}
                    onClick={e => {
                      setCourierReceived(!e.target.checked);
                    }}
                  />
                  <Radio
                    name="counter"
                    label="No"
                    disabled={editMode}
                    checked={!courierReceived}
                    onClick={e => {
                      setCourierReceived(e.target.checked);
                    }}
                  />
                </div>
              )}

            <div className="flex-grow field-attachment">
              <label className="label__class" htmlFor="courierAttachments">
                Attachments {!editMode && ' (up to 4)'}
              </label>
              {editMode
                ? <SampleFiles
                  designName={design.name}
                  files={currentSampleDispatch.files}
                />
                : <InputAttachments
                  maxCount={4}
                  labelOnMax="hide"
                  fileTypes={VALID_FILE_TYPES}
                  onChange={handleAttachmentsChange}
                  onError={err => flash({ message: err })}
                />}
            </div>

          </PerfectScrollbar>
        )}
      {/** footer */}
      {!dispatchSaved &&
        getCurrentUserRole() !== CLIENT &&
        getCurrentUserRole() !== CLIENT_SUPER &&
        (isEmpty(initialValues) || !editMode) && (
          <div className="modal__footer">
            <button
              onClick={addCourierDetailsHandler}
              className="btn btn--primary-blue"
            >
              UPDATE
            </button>
          </div>
        )}
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  openModal: (...args) => dispatch(actions.openModal(...args)),
  closeModal: () => dispatch(actions.closeModal()),
  flash: (...args) => dispatch(actions.flash(...args)),
  getSamplesByDesignId: (...args) =>
    dispatch(actions.getSamplesByDesignId(...args)),
  dispatchDeatilsUpdatedInSaplinTracker: (...args) =>
    dispatch(actions.dispatchDeatilsUpdatedInSaplinTracker(...args))
});

export default connect(null, mapDispatchToProps)(CourierDetails);
