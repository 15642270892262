import React, { useState } from 'react';
import propTypes from 'prop-types';
import moment from 'moment';
import classnames from 'classnames';
import Button from 'components/UI/Button/Button';
import { ProfileAvatar } from 'components/UI/ProfileAvatar';
import { DATESTAMP_FORMAT } from 'constants/Date';
import imgDownBlue from 'assets/images/navigation/ic-chevron-down-blue.svg';
import './style.scss';

export const TnaNote = ({
  note: {
    first_name,
    last_name,
    created_on,
    note,
    image: userImage,
  }
}) => {
  return (<div className="tna-note">
    <div className="tna-note__user-img">
      <ProfileAvatar name={first_name + ` ${last_name || ''}`.trim()} url={userImage} initialLength={2} size={24} />
    </div>
    <div className="tna-note__content">
      <div className="tna-note__content__line">
        <span>{first_name} {last_name}</span>
        <span>{moment(created_on).format(DATESTAMP_FORMAT)}</span>
      </div>
      <div className="tna-note__content__line">{note}</div>
    </div>
  </div>);
};

TnaNote.propTypes = {
  note: propTypes.shape({
    first_name: propTypes.string.isRequired,
    last_name: propTypes.string.isRequired,
    created_on: propTypes.string.isRequired,
    note: propTypes.string.isRequired,
    image: propTypes.string.isRequired,
  })
};

export const TnaNoteList = ({ notes = [] }) => {
  const [isExpanded, setExpanded] = useState(false);
  const canExpand = notes.length > 1;

  return <div className={classnames("tna-note-list", `tna-note-list--${isExpanded ? 'expanded' : 'collapsed'}`)}>
    {((isExpanded || notes.length === 0) ? notes : [notes[0]]).map(note => <TnaNote key={note.id} note={note} />)}
    {canExpand && <div className="tna-note-list__footer">
      <Button
        category="grid-2 primary-text shrink"
        onClick={() => setExpanded(!isExpanded)}
      >
        <span>
          {isExpanded
            ? 'Hide messages'
            : `${notes.length - 1} more message${notes.length > 2 ? 's' : ''}`}
        </span>
        <img src={imgDownBlue} alt="" />
      </Button>
    </div>}
  </div>
};

TnaNoteList.propTypes = {
  notes: propTypes.arrayOf(({
    first_name: propTypes.string.isRequired,
    last_name: propTypes.string.isRequired,
    created_on: propTypes.string.isRequired,
    note: propTypes.string.isRequired,
    image: propTypes.string.isRequired,
  }))
};
