import React from "react";
import moment from "moment";

import "./styles.scss";

import { DATESTAMP__SHORT_FORMAT } from "constants/Date";

const DateToolTip = ({ delayed, date, category = "due", children }) => (
  <div className="date-tooltip">
    {children ? (
       children
    ) : (
      <React.Fragment>
        <div className={`date-tip ${delayed ? "delayed" : "ontime"}`}>
          {delayed ? "Delayed" : "On time"}
        </div>
        <div className="due-info">
          {date
            ? delayed || moment(date).isBefore()
              ? `Was ${category} on ${moment(date).format(
                  DATESTAMP__SHORT_FORMAT
                )}`
              : `${category} on ${moment(date).format(DATESTAMP__SHORT_FORMAT)}`
            : `${category} date not available`}
        </div>
      </React.Fragment>
    )}
  </div>
);

export default DateToolTip;
