import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import "./FactoryNotificationDashboard.scss";
import NotificationList, { DispatchNotificationList } from "./NotificationList";
import Tabs from "../../components/UI/UrlTabs/index";
import actions from "../../store/actions";
import NotificationFilter from "./NotificationFilter";
import { QUOTE_STATUS } from "../../constants/Design";
import { TIMESTAMP_FORMAT } from "../../constants/Date";
import moment from "moment";
import qs from "query-string";
import { computePath } from "../../helpers/pathHelper";
import { Helmet } from "react-helmet";
import { PAGE_TITLES } from "../../constants/Titles";
// const NOTIF_TYPES = {
//   "Sampling Details": 1,
//   Quote: 2,
//   "Order Confirmation": 3,
//   DISPATCH: 4
// };
const NOTIF_STRING = {
  1: { name: "Sampling", value: "sample" },
  2: { name: "Quote", value: "quote" },
  3: { name: "Order Confirmation", value: "order confirmation" },
  4: { name: "Dispatch", value: "dispatch" },
  5: { name: "TNA", value: "tna" }
};



const NOTIF_TYPES_MODIFIERS = {
  tna: (designDetails, { tnaId, name, tnaAttributeId }) => {
    return {
      ...designDetails,
      tnaAttributeId: tnaAttributeId,
      tnaId: tnaId,
      stage: "TNA",
      subCategory: name,
      timestamp: null,
      status: { heading: "", subHeading: null },
      category: "tna"
    }
  },
  sample: (designDetails, { sampleId, name }) => {
    return {
      ...designDetails,
      sampleId,
      stage: "Sampling",
      subCategory: name,
      timestamp: null,
      status: { heading: "", subHeading: null },
      category: "sample"
    }
  },
  quote: (designDetails, { quoteId, name }) => {
    return {
      ...designDetails,
      quoteId,
      stage: "Quote",
      subCategory: name,
      timestamp: null,
      status: { heading: "", subHeading: null },
      category: "quote"
    }
  },
  order: (designDetails, { }) => {
    return {
      ...designDetails,
      orderId: null,
      stage: "Order Confirmation",
      subCategory: "Orders",
      timestamp: null,
      status: { heading: "", subHeading: null },
      category: "order"
    }
  },
  dispatch: (designDetails, { dispatchId }) => {
    return {
      ...designDetails,
      dispatchId: dispatchId,
      stage: "Dispatch",
      subCategory: "Dispatch",
      timestamp: null,
      status: { heading: "", subHeading: null },
      category: "dispatch"
    }
  }
}

// const notifsData = {
//   "109": {
//     collection_id: "64",
//     brand_id: "8",
//     Quote: {
//       "24": {
//         quote:
//           '{"table":{"fabrics":[{"name":"Fabric"}],"trims":[{"name":"M / Label"},{"name":"Wash Care Label"},{"name":"Hang Tag"},{"name":"Thread"},{"name":"Fusing"},{"name":"Buttons"}],"packaging material":[{"name":"Poly Bags"},{"name":"Packing Material"},{"name":"Transportation"}],"CMTP":[{"name":"Pattern + Cutting"},{"name":"Stitching"},{"name":"Testing"},{"name":"Washing"},{"name":"Finishing / Packing"},{"name":"Buttoning / Hole"},{"name":"Sample Pieces"}]},"totalCost":0,"additionalCosts":{"Overheads":0,"Factory Profit":0,"LF Profit":0,"Profit":0},"quantity":10}',
//         quote_type: "OPEN",
//         id: 24,
//         design_id: 109,
//         total_cost: null,
//         total_quantity: null,
//         status: "-1",
//         created_on: "2019-02-19T08:25:18.000Z",
//         modified_on: "2019-02-19T08:25:18.000Z",
//         comment: null,
//         created_by: 55,
//         modified_by: 55,
//         name: "loco super user",
//         modifier_name: "loco super user"
//       }
//     },
//     "Order Confirmation": {
//       id: 14,
//       design_id: 109,
//       collection_id: 64,
//       status: "0",
//       brand: 8,
//       created_by: 58,
//       modified_by: 58,
//       delivery_date: null,
//       sample_sent: null,
//       details: null,
//       created_on: "2019-02-19T10:51:35.000Z",
//       modified_on: "2019-02-19T10:51:35.000Z"
//     },
//     DISPATCH: {},
//     "Sampling Details": {
//       "Prototype Sample": {
//         size: "9",
//         status: "",
//         id: 164
//       },
//       "Fit / Size Sample": {
//         size: "9",
//         status: "",
//         id: 165
//       },
//       "Pre-production Sample": {
//         size: "",
//         status: "",
//         id: 166
//       }
//     }
//   }
// };

// const CustomRowRenderer = ({ renderBaseRow, ...canvasProps }) => {
//   // Here the height of the base row is overridden by a value of 100
//   return renderBaseRow({ ...canvasProps, height: 80 });
// };

class FactoryNotificationDashboard extends Component {
  static defaultProps = {
    notificationList: []
  };

  state = {
    selectedStateFilter: { value: "All", label: "All" },
    selectedBrandResponseFilter: { value: "All", label: "All" },
    selectedNotificationTypeFilter: { value: "Pending", label: "Pending" },
  };

  static getDerivedStateFromProps(props, state) {
    return {
      ...state,
      selectedBrandFilter: _.find(props.brandsList, { "value": Number(props.match.params.brand_id) })
    }
  }

  componentDidMount() {
    this.props.getNotificationsList({
      brand: { id: this.props.match.params.brand_id }
    });
    this.props.getAllDispatchNotifications({
      brand: { id: this.props.match.params.brand_id }
    });
  }

  deleteRows(rows) {
    rows.forEach(() => { });
  }

  handleBrandChange = brand => {
    const path = computePath();
    this.props.history.push(`/${path}/brand/${brand.value}/notifications`);
  }

  openDispatchNotificationUserSelectModal = rows => {
    this.props
      .getAllUserForBrand({ brandId: rows[0]["brandId"] })
      .then(userList => {
        this.props.openModal("SEND_DISPATCH_NOTIFICATION_USER_LIST", {
          userList: userList,
          hiddenFields: [
            { name: "notifications", value: rows },
            { name: "userList", value: userList },
            { name: "brand_id", value: rows[0]["brandId"] }
          ]
        });
      });
  };

  openUserSelectModal(rows) {
    // console.log(rows);
    this.props
      .getAllUserForBrand({ brandId: rows[0]["brand_id"] })
      .then(userList => {
        this.props.openModal("SEND_NOTIFICATION_USER_LIST", {
          userList: userList,
          hiddenFields: [
            { name: "notifications", value: rows },
            { name: "userList", value: userList },
            { name: "brand_id", value: rows[0]["brand_id"] }
          ]
        });
        // let deletedRowChecker = setInterval(() => {
        //   const rows = window.lf.deletedRows;
        //   if (!rows) {
        //     clearInterval(deletedRowChecker);
        //     return;
        //   }
        //   rows.forEach(row => {
        //     switch (row.category) {
        //       case "sample":
        //         delete window.lf.notifs[row.design_id][row.category][
        //           row.subCategory
        //         ];
        //         break;
        //       case "quote":
        //         delete window.lf.notifs[row.design_id][row.category][
        //           row.quoteId
        //         ];
        //         break;
        //       case "order":
        //         delete window.lf.notifs[row.design_id][row.category];
        //         break;
        //       case "dispatch":
        //         delete window.lf.notifs[row.design_id][row.category];
        //         break;
        //       case "tna":
        //         delete window.lf.notifs[row.design_id][row.category][
        //           row.subCategory
        //         ];
        //         break;
        //       default:
        //         break;
        //     }
        //     clearInterval(deletedRowChecker);
        //   });
        //   if (rows.length > 0) this.forceUpdate();
        // }, 1500);
      });
  }

  sortRows = (initialRows, sortColumn, sortDirection) => rows => {
    const comparer = (a, b) => {
      if (sortDirection === "ASC") {
        return a[sortColumn] > b[sortColumn] ? 1 : -1;
      } else if (sortDirection === "DESC") {
        return a[sortColumn] < b[sortColumn] ? 1 : -1;
      }
    };
    return sortDirection === "NONE" ? initialRows : [...rows].sort(comparer);
  };

  render() {
    const {
      selectedStateFilter,
      selectedBrandResponseFilter,
      selectedNotificationTypeFilter
    } = this.state;
    let sentNotifications = this.props.notificationList
      .map(n => {
        if (n.orderStatus === null) {
          n.orderStatus = "Pending";
        }
        let stage = _.get(NOTIF_STRING, `${n.category}.name`, null);
        let subCategory, brandResponse;
        switch (_.get(NOTIF_STRING, `${n.category}.value`, null)) {
          case "sample":
            subCategory = n.sampleType;
            brandResponse = n.sampleStatus;
            break;
          case "quote":
            subCategory = n.quoteType;
            brandResponse =
              Number(n.quoteStatus) !== 0 ? QUOTE_STATUS[n.quoteStatus] : null;
            break;
          case "order confirmation":
            subCategory = "Orders";
            brandResponse =
              n.orderStatus.toLowerCase() !== "pending"
                ? moment(n.orderStatus).format(TIMESTAMP_FORMAT)
                : null;
            break;
          case "dispatch":
            subCategory = "Dispatch";
            brandResponse =
              n.dispatchStatus.toLowerCase() !== "pending"
                ? `${n.dispatchStatus} qty on ${moment(
                  n.dispatchReceivedDate
                ).format(TIMESTAMP_FORMAT)}`
                : null;
            break;
          case "tna":
            subCategory = `${n.tnaSubCategory}`;
            brandResponse = "NA";
            break;
          default:
            subCategory = "NA";
        }
        if (brandResponse === null || brandResponse === "")
          brandResponse = "Pending";
        return {
          // collection_id,
          // design_id,
          collectionName: n.collection,
          designName: n.design,
          designCategory: n.designCategory,
          imageUrl: n.imageUrl,
          // NOTIF_STRING[n.designStatus].name ||
          stage: stage,
          subCategory:
            // n[`${NOTIF_STRING[n.designStatus].value}Status`] ||
            // n[_.get(NOTIF_STRING, n.designStatus.value + "Status")]
            subCategory,
          timestamp: n.createdOn,
          status: { heading: "Sent", subHeading: null },
          brandResponse: { heading: brandResponse },
          // Convert CSV to Array of JSON strings
          emailList: n.email.split(",")
        };
      })
      .filter(x => {
        if (selectedStateFilter.value === "All") {
          return true;
        } else {
          if (x.stage) {
            return x.stage === selectedStateFilter.value;
          } else {
            return false;
          }
        }
      })
      .filter(x => {
        if (selectedBrandResponseFilter.value === "All") {
          return true;
        } else {
          if (x.brandResponse) {
            if (selectedBrandResponseFilter.value === "Yes") {
              return x.brandResponse.heading.toLowerCase() !== "pending";
            } else {
              return x.brandResponse.heading.toLowerCase() === "pending";
            }
          } else {
            return false;
          }
        }
      });

    const rows = this.props.pendingNotifications.filter(item =>
      item.brand_id === this.props.match.params.brand_id
    )
      .map(item => {
        const { collection_id, brand_id, design_id, collectionName, designName } = item;
        const { type } = item["notif_details"];
        return NOTIF_TYPES_MODIFIERS[type]({
          collection_id,
          brand_id,
          design_id,
          collectionName,
          designName
        }, item["notif_details"]);
      }
      )

    return (
      <React.Fragment>
        <Helmet
          title={PAGE_TITLES.notifications()}
        />
        <Tabs
          header={
            <div
              style={{
                height: "80px",
                alignItems: "center",
                padding: "16px 32px",
                backgroundColor: "var(--fill-white)",
                // boxShadow: "inset 0 -1px 0 0 var(--fill-grey-light2)"
                justifyContent: "space-between"
              }}
              className="flex notifications-container">
              <div className="f-sz-xxl f-w-bold">
                Email Notifications Dashboard
              </div>
              <div
                className="flex"
                style={{ justifyContent: "flex-end", width: "600px" }}>
                <NotificationFilter
                  title="Brand"
                  selectedItem={this.state.selectedBrandFilter}
                  onChange={selection => {
                    this.handleBrandChange(selection)
                  }}
                  filterOptions={this.props.brandsList}
                />
                <NotificationFilter
                  className={
                    qs.parse(this.props.location.search).tab !== "dispatch"
                      ? ""
                      : "hide-visibility"
                  }
                  title="Stage"
                  initialSelectedItem={{
                    label: "All",
                    value: "All"
                  }}
                  onChange={selection => {
                    this.setState({
                      selectedStateFilter: selection
                    });
                  }}
                  filterOptions={[
                    "All",
                    "Sampling",
                    "Quote",
                    "Order Confirmation",
                    "Dispatch",
                    "TNA"
                  ].map(x => ({
                    label: x,
                    value: x
                  }))}
                />
                <NotificationFilter
                  className={
                    qs.parse(this.props.location.search).tab !== "dispatch"
                      ? ""
                      : "hide-visibility"
                  }
                  title="Brand Response"
                  initialSelectedItem={{
                    label: "All",
                    value: "All"
                  }}
                  onChange={selection => {
                    this.setState({
                      selectedBrandResponseFilter: selection
                    });
                  }}
                  filterOptions={[
                    { label: "All", value: "All" },
                    { label: "Pending", value: "No" },
                    { label: "Recieved", value: "Yes" }
                  ]}
                />

                <NotificationFilter
                  title="Notification"
                  selectedItem={this.state.selectedNotificationTypeFilter}
                  onChange={selection => {
                    this.setState({
                      selectedNotificationTypeFilter: selection
                    });
                  }}
                  filterOptions={["Sent", "Pending"].map(x => ({
                    label: x,
                    value: x
                  }))}
                />
              </div>
            </div>
          }
          initialTabUrl="design"
          tabs={[
            {
              name: "Design",
              tabUrl: "design",
              panel: (
                <div className="factory-notif-table" style={{ height: "100%" }}>
                  {selectedNotificationTypeFilter.value === "Pending" ? (
                    <NotificationList
                      key="pending"
                      notificationType="pending"
                      sendNotifications={rows => {
                        this.openUserSelectModal(rows);
                      }}
                      rows={rows
                        .filter(x => {
                          if (selectedStateFilter.value === "All") {
                            return true;
                          } else {
                            if (x.stage) {
                              return x.stage === selectedStateFilter.value;
                            } else {
                              return false;
                            }
                          }
                        })
                        .filter(x => {
                          if (selectedBrandResponseFilter.value === "All") {
                            return true;
                          } else {
                            if (selectedBrandResponseFilter.value === "Yes") {
                              return false;
                            } else {
                              return true;
                            }
                          }
                        })}
                    />
                  ) : (
                      <NotificationList
                        key="sent"
                        notificationType="sent"
                        sendNotifications={() => { }}
                        openSentToModal={({ emailList, timestamp }) => {
                          console.log("OPENING MODAL", emailList);
                          this.props.openModal("NOTIFICATION_SENT_TO", {
                            emailList,
                            created_on: timestamp
                          });
                        }}
                        selectionDisable={true}
                        rows={sentNotifications}
                        onGridSort={(sortColumn, sortDirection) =>
                          this.setState({
                            sentNotifications: this.sortRows(
                              sentNotifications,
                              sortColumn,
                              sortDirection
                            )
                          })
                        }
                      />
                    )}
                </div>
              )
            },
            {
              name: "Dispatch",
              tabUrl: "dispatch",
              panel: (
                <div className="factory-notif-table" style={{ height: "100%" }}>
                  {selectedNotificationTypeFilter.value === "Pending" ? (
                    <DispatchNotificationList
                      key="pending"
                      sendNotifications={
                        this.openDispatchNotificationUserSelectModal
                      }
                      rows={this.props.pendingDispatchNotifications}
                    />
                  ) : (
                      <DispatchNotificationList
                        key="sent"
                        sendNotifications={() => { }}
                        rows={this.props.dispatchNotifications}
                      />
                    )}
                </div>
              )
            }
          ]}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  notificationList: state.notificationView.list,
  dispatchNotifications: state.notificationView.dispatchNotifications,
  pendingDispatchNotifications: Object.values(
    _.get(
      state.notificationView.pendingDispatchNotifications,
      `${state.notificationView.currentBrand.brandId}`,
      {}
    )
  ),
  pendingNotifications: state.notificationView.pendingNotifications,
  brandsList: state.factory.brandsList.map(({ name, id }) => ({ value: id, label: name }))
});

const mapDispatchToProps = dispatch => ({
  getNotificationsList: details =>
    dispatch(actions.getNotificationsList(details)),
  getAllUserForBrand: details => dispatch(actions.getAllUsersForBrand(details)),
  openModal: (content, additionalProps) =>
    dispatch(actions.openModal(content, additionalProps)),
  getAllDispatchNotifications: details =>
    dispatch(actions.getAllDispatchNotifications(details))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FactoryNotificationDashboard);
