import React, { Component } from "react";
import './styles.scss';
class Radio extends Component {
  render() {
    let {
      value,
      label,
      name,
      onClick,
      style = {},
      setRef = () => ({}),
      disabled,
      ...props
    } = this.props;
    name = name ? name : label;
    return (
      <div
        className="ui__radio loco__radio loco__radio--left-align"
        // To prevent calling onClick twice (due to click on <label> and <input> bubbling up)
        // Check if the event target has type checkbox, i.e. label indirectly clicks on checkbox
        onClick={evt => {
          evt.preventDefault();
          evt.stopPropagation();
          if(disabled) return;
          return onClick ? onClick(evt) : null;
        }}
        style={style}
      >
        <input
          type="radio"
          name={name}
          id={name}
          disabled={disabled}
          checked={value}
          ref={setRef}
          onChange={() => null}
          {...props}
        />
        {label && <label htmlFor={name}>{label}</label>}
      </div>
    );
  }
}

export default Radio;
