import React, { Component } from "react";
import axios from "axios";
const Compress = require('compress.js')
// import PropTypes from "prop-types";
const compress = new Compress()

function dataURLtoFile(dataurl, filename) {

  var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
  while(n--){
      u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {type:mime});
}

export default class TestContainer extends Component {
  //   static propTypes = {
  //     prop: PropTypes
  //   }
  state = {
    endpoint: "http://localhost:8080/upload",
    loaded: 0,
    selectedFile: null,
    successMessage: null,
    imgSrc : null,
    compressedData : null
  };
  handleselectedFile = event => {


    const url = window.URL.createObjectURL(event.target.files[0]);
    
    compress.compress([...event.target.files], {
      size: 1, 
      quality: .60, 
      maxWidth: 1920, 
      maxHeight: 1920,
      resize: true, 
    }).then((data) => {
      this.setState({compressedData : data});
    })
    this.setState({
      selectedFile: event.target.files[0],
      loaded: 0,
      imgSrc : url
    });
  };
  handleUpload = async () => {
    const {imgSrc, compressedData} = this.state;
    const data = new FormData();
    if (this.state.selectedFile === null) {
      alert("Please select a file to upload");
      return;
    }
    
    let file = dataURLtoFile(compressedData[0].prefix+compressedData[0].data, this.state.selectedFile.name);
    
    data.append("file", file, this.state.selectedFile.name);

    axios
      .post(this.state.endpoint, data, {
        onUploadProgress: ProgressEvent => {
          this.setState({
            loaded: (ProgressEvent.loaded / ProgressEvent.total) * 100
          });
        }
      })
      .then(res => {
        console.log(res.statusText);
        this.setState({
          successMessage: "File uploaded successfully"
        });
        setTimeout(() => this.setState({ successMessage: null }), 2500);
      })
      .catch(reason => {
        console.error(reason);
        alert(`ERROR: \n ${reason}`);
      });
  };
  render() {
    const { imgSrc } = this.state;
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)"
        }}
      >
        <img src={imgSrc} />
        <input type="file" onChange={this.handleselectedFile} />
        <br />
        <br />
        <label htmlFor="endpoint">URL (Upload endpoint): </label> &nbsp;
        <input
          id="endpoint"
          name="endpoint"
          style={{ width: "20vw" }}
          type="text"
          value={this.state.endpoint}
          onChange={evt => this.setState({ endpoint: evt.target.value })}
        />
        <br />
        <br />
        <button onClick={this.handleUpload}>Upload</button>&nbsp;
        <span> {Math.round(this.state.loaded, 2)} %</span>
        <br />
        {this.state.successMessage ? (
          <div>{this.state.successMessage}</div>
        ) : null}
      </div>
    );
  }
}
