import React from "react";
import Downshift from "downshift";
import icDropdown from "../../assets/images/navigation/ic-arrow-drop-down-grey.svg";

import PerfectScrollbar from "react-perfect-scrollbar";
import { getSmallImageUrl } from "../../helpers/DesignHelpers";

import styles from "./DetailsModel.module.scss";

const NotificationFilter = ({
  title,
  onChange,
  initialSelectedItem,
  filterOptions = [],
  marginRight = "0px",
  height = "250px",
  width = "100%",
  innerWidth = "100%",
  disable
}) => {
  return (
    <Downshift
      initialSelectedItem={initialSelectedItem}
      itemToString={item => item.label}
      onChange={selection => {
        onChange(selection);
      }}
      style={{ fontSize: "12px", fontWeight: 600 }}
    >
      {({
        getInputProps,
        getToggleButtonProps,
        getItemProps,
        getLabelProps,
        getMenuProps,
        isOpen,
        inputValue,
        highlightedIndex,
        selectedItem
      }) => {
        return (
          <div
            {...getToggleButtonProps()}
            type="none"
            style={{
              userSelect: "none",
              marginTop: "0.3rem",
              position: "relative",
              cursor: "pointer",
              border: "solid 1px var(--fill-grey-light2)",
              borderRadius: "4px",
              padding: "6px 4px 6px 12px",
              width: "100%",
              height: "44px",
              backgroundColor: disable ? "var(--fill-grey-light3)" : ""
            }}
          >
            {/* <span {...getLabelProps()} style={{ fontSize: "12px", color: 'var(--fill-grey-dark)' }}>
              {title}
            </span> */}
            <span className="flex" style={{ alignItems: "center" }}>
              <input
                style={{
                  border: "none",
                  backgroundColor: "transparent",
                  maxWidth: "100%",
                  color: "var(--fill-grey-dark)"
                }}
                className="select-input-transparent"
                readOnly={true}
                disabled={true}
                {...getInputProps()}
              />
              <img
                src={icDropdown}
                alt="dropdown"
                style={{ marginLeft: "auto" }}
              />
            </span>

            {isOpen && !disable ? (
              <div
                className="select-pane"
                onClick={e => {
                  e.stopPropagation();
                }}
                style={{ width: innerWidth }}
              >
                <div style={{ height: height }}>
                  <PerfectScrollbar>
                    {filterOptions
                      // .filter(item => item.label !== selectedItem.label)
                      .map((item, index) => {
                        const { coverImageUrl, name } = item.value;
                        const smallImageUrl = getSmallImageUrl(coverImageUrl);
                        return (
                          <div
                            {...getItemProps({
                              className:
                                "in-selection collection-collab__label_left_aigned_with_no_paddding flex",
                              index,
                              item,
                              key: item.label
                            })}
                          >
                            <div className={styles.filter__wrapper}>
                              <div className={styles.filter__image_wrapper}>
                                <img
                                  src={smallImageUrl}
                                  alt="Design"
                                  className={styles.filter__design_image}
                                />
                              </div>
                              <div
                                className={styles.filter__design_name}
                                style={{
                                  fontWeight:
                                    item.label === selectedItem.label
                                      ? "600"
                                      : "300"
                                }}
                              >
                                {name}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </PerfectScrollbar>
                </div>
              </div>
            ) : null}
          </div>
        );
      }}
    </Downshift>
  );
};

export default NotificationFilter;
