import React, { Component } from "react";
import PropTypes from "prop-types";
import CollectionList from "../../components/Collections/CollectionList/CollectionList";
import { connect } from "react-redux";
import actions from "../../store/actions";
import { getCurrentUserRole } from "../../helpers/AuthHelper";
import { withRouter } from 'react-router-dom';
import { LOCO_SUPER, PRODUCTION_MERCHANT, PRODUCT_DEVELOPMENT, FACTORY_ADMIN, FACTORY_MANAGER } from "../../constants/Auth";
import { computePath } from "../../helpers/pathHelper";
import { Helmet }from 'react-helmet'
import { PAGE_TITLES } from "../../constants/Titles";

class CollectionListContainer extends Component {
  static propTypes = {
    prop: PropTypes
  };
  componentDidMount() {
      this.loadCollectionList();
  }

  loadCollectionList = () =>
    this.loadCollections().then(collectionIds =>
      this.props.getDesignSummariesByCollection({
        collectionIds: collectionIds
      })
    );

  reloadDesigns = () => {
    this.props.getDesignSummariesByCollection({
      collectionIds: Object.values(this.props.collections.list).map(c => c.id)
    });
  };

  // Returns an array of all the collectionIds
  loadCollections = () => {
    const { role } = this.props.userDetails;
    const lfRoles = [LOCO_SUPER, PRODUCTION_MERCHANT, PRODUCT_DEVELOPMENT, FACTORY_ADMIN, FACTORY_MANAGER]
    if (lfRoles.includes(role)) {
      return this.props
        .getCollectionsByBrandId({ id: this.props.match.params.brand_id })
        .then(data => Object.keys(data));
    } else {
      console.log('no access');
    }

    return this.props
      .getAllCollections()
      .then(data => Object.values(data).map(c => c.id));
  };

  goToBrand = id => {
    const { history } = this.props;
    const path = computePath();
    history.push(`/${path}/brands/${id}/collections`)
  }

  render() {
    const { brandsList, currentBrand } = this.props;
    return (
      <React.Fragment>
      <Helmet
        bodyAttributes={{
            class: 'body_container'
        }}
        title={PAGE_TITLES.collections()}
      />
      <CollectionList
        designList={this.props.designList}
        brands={brandsList}
        goToBrand={this.goToBrand}
        currentBrand={currentBrand}
        openNewDesignModal={collectionId =>
          this.props.openModal("NEW_DESIGN", {
            hiddenFields: [
              {
                name: "collection_id",
                value: collectionId
              },
              {
                name: "brandId",
                value: this.props.match.params.brand_id
              }
            ],
            afterSubmit: () => this.reloadDesigns()
          })
        }
        openNewCollectionModal={() =>
          this.props.openModal("NEW_COLLECTION", {
            hiddenFields: [
              {
                name: "brandId",
                value: this.props.match.params.brand_id
              }
            ],
            afterSubmit: () => this.loadCollectionList()
          })
        }
        userDetails={this.props.userDetails}
        userRole={getCurrentUserRole()}
        collections={this.props.collections.list}
        createCollection={this.props.createCollection}
        openModal={this.props.openModal}
      />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  collections: state.collections,
  userDetails: state.auth.userDetails,
  designList: state.designs.summaries,
  brandsList: state.factory.brandsList,
  currentBrand : state.notificationView.currentBrand
});

const mapDispatchToProps = dispatch => ({
  openModal: (content, additionalProps) =>
    dispatch(actions.openModal(content, additionalProps)),
  getAllCollections: () => dispatch(actions.getAllCollections()),
  getDesignSummariesByCollection: details =>
    dispatch(actions.getDesignSummariesByCollection(details)),
  getCollectionsByBrandId: details =>
    dispatch(actions.getCollectionsByBrandId(details))
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(CollectionListContainer));
