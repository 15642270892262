import React from 'react';
import { Field } from 'formik';
import Form from 'components/CustomBrand/UI/Formik/Form';
import TextAreaField from 'components/CustomBrand/UI/Formik/TextArea';
import ModalFooter from 'components/UI/Modal/ModalFooter';
import './style.scss';

const RequestResubmissionModal = ({
  closeModal,
  onSubmit
}) => {
  return <div className="req-tna-resubmission-modal">
    <Form onSubmit={(values)=> onSubmit(values)}>
      {({ values }) => <div className="modal__form">
        <div className="modal_content">
          <Field
            name="note"
            component={TextAreaField}
            maxLength={200}
            label="Add a message"
            placeholder="Add some instruction for the factory regarding the TNA resubmission request.."
          />
        </div>
        <ModalFooter
          cancel="CANCEL"
          submit="SUBMIT"
          hasBorder={true}
          canSubmit={true}
          canCancel={true}
          cancelCategory='black-ghost medium'
          onCancel={closeModal}
          // onSubmit={() => onSubmit(values)}
        />
      </div>}
    </Form>
  </div>
};

export default RequestResubmissionModal;
