import React, { Component } from 'react';
import CloseImg from "../../../assets/images/navigation/ic-close.svg";
import PerfectScrollbar from "react-perfect-scrollbar";
import "./Modal.scss";
import "../../../containers/ModalContainer/ModalContainer.scss";

export default class ModalWithChildren extends Component {

  render() {
    const { title = "Modal", subtitle, modalType = "", footer, children, isOpen, onClose } = this.props;
    return (
      <div
        className={`modal__container${isOpen ? "" : " hide"}`}
      >
        <PerfectScrollbar>
          {isOpen && <div
            className={`modal__wrapper flex-col${
              modalType === "right"
                ? " modal__wrapper--right"
                : modalType === "right-full-height"
                  ? " modal__wrapper--right-full-height"
                  : ""
              }`}
            style={{
              border: "1px solid #d4d4d4"
            }}
          >
            {title ? (
              <div className="modal__header flex f-sz-xl f-w-bold">
                <div className="flex-col">
                  <div>{title}</div>
                  {subtitle && (
                    <div style={{ fontWeight: "normal" }} className="f-sz-m">
                      {subtitle}
                    </div>
                  )}
                </div>
                <span onClick={onClose} className="modal__close">
                  <img src={CloseImg} alt="close modal" />
                </span>
              </div>
            ) : (
                <div className="modal__header--empty" />
              )}


            <div className="modal__content--text f-sz-l f-w-semi-bold">
              {children}
            </div>

            {footer && (
              <div className="modal__footer flex f-sz-m f-w-bold">
                {footer}
              </div>
            )}
          </div>}
        </PerfectScrollbar>
      </div>
    )
  }
}
