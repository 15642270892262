import React, { Component } from "react";
import { Field } from "redux-form";
import find from "lodash/find";
import groupBy from "lodash/groupBy";
import some from "lodash/some";
import countBy from "lodash/countBy";
import Input from "components/UI/Form/Input/Input";
import Form from "components/UI/Form/Form";
import { required } from "helpers/form-validations";
import Select from "components/UI/Form/Select/Select";
import {
  DESIGN_CATEGORIES,
  DESIGN_SUB_CATEGORIES,
  DESIGN_ADDITIONAL_DETAILS
} from "constants/Design";
import TextArea from "components/UI/Form/Textarea/Textarea";

import Radio from "../../../UI/Form/Radio/Radio";
import "./style.scss";
import Button from "components/UI/Button/Button";

import { ReactComponent as AddIcon } from "assets/images/content/ic-add-small.svg";

const {
  CORE_FABRIC,
  TRIM_FABRIC,
  ADDITIONAL_DETAILS,
  FABRIC_DETAILS
} = DESIGN_ADDITIONAL_DETAILS;

const fabricDetailsInitial = {
  [CORE_FABRIC]: {
    visible: 1,
    max: 3
  },
  [TRIM_FABRIC]: {
    visible: 1,
    max: 2
  }
};

class DesignSummaryForm extends Component {
  state = {
    category: null,
    sub_category: null,
    propDesign: null,
    design: null,
    initialValues: null,
    fabricDetails: fabricDetailsInitial,
    showAddMoreFabric: false,
    showAddMoreTrims: false,
    initialRender: true
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const nextState = {};

    if (prevState.propDesign !== nextProps.design) {
      nextState.propDesign = nextProps.design;

      if (nextProps.design) {
        nextState.category = nextProps.design.category;
        nextState.sub_category = nextProps.design.sub_category;
        nextState.design = {
          ...nextProps.design
        };

        if (
          nextState.design.additional_fields &&
          nextState.design.additional_fields.length > 0
        ) {
          const groupedFields = groupBy(
            nextState.design.additional_fields,
            "category"
          );
          nextState.design.additional_fields.forEach(field => {
            nextState.design[`field_${field.id}`] = field.value;
          });
          groupedFields[FABRIC_DETAILS] = groupBy(
            groupedFields[FABRIC_DETAILS],
            "sub_category"
          );
          if (prevState.initialRender) {
            nextState.fabricDetails = fabricDetailsInitial;
            if (some(groupedFields[FABRIC_DETAILS][CORE_FABRIC], "value")) {
              nextState.showAddMoreFabric = true;
              nextState.fabricDetails[
                CORE_FABRIC
              ].visible = countBy(
                groupedFields[FABRIC_DETAILS][CORE_FABRIC],
                obj => Boolean(obj.value)
              ).true;
            }

            if (some(groupedFields[FABRIC_DETAILS][TRIM_FABRIC], "value")) {
              nextState.showAddMoreTrims = true;
              nextState.fabricDetails[
                TRIM_FABRIC
              ].visible = countBy(
                groupedFields[FABRIC_DETAILS][TRIM_FABRIC],
                obj => Boolean(obj.value)
              ).true;
            }
          }
          nextState.design.groupedFields = groupedFields;
        }

        if (nextState.design.id) {
          nextState.initialValues = { ...nextState.design };
        }
      } else {
        nextState.design = null;
      }
    }
    if (prevState.initialRender) {
      nextState.initialRender = false;
    }

    return Object.keys(nextState).length > 0 ? nextState : null;
  }

  handleClick = (category, stateKey) => {
    const currentCategory = { ...this.state.fabricDetails[category] };
    if (currentCategory.visible < currentCategory.max) {
      this.setState(state => ({
        fabricDetails: {
          ...state.fabricDetails,
          [category]: {
            ...currentCategory,
            visible: currentCategory.visible + 1
          }
        },
        [stateKey]: false
      }));
    }
  };

  _renderFabricDetails = (
    changeFormValue,
    fabricType,
    buttonVisibilityKey,
    buttonVisibility
  ) => {
    const {
      design: { groupedFields },
      fabricDetails
    } = this.state;
    return (
      <>
        <div className="summary-container__row">
          <div className="label">{fabricType}</div>
          <div>
            {groupedFields[FABRIC_DETAILS][fabricType].map((field, index) => {
              return index + 1 <= fabricDetails[fabricType].visible ||
                field.value ? (
                <div className="field-input with-bottom-margin">
                  <Field
                    component={Input}
                    type="text"
                    name={`field_${field.id}`}
                    placeholder="(Optional)"
                    onChange={(evt, value) => {
                      if (value && value.trim().length > 0) {
                        this.setState({
                          [buttonVisibilityKey]: true
                        });
                      } else {
                        this.setState({
                          [buttonVisibilityKey]: false
                        });
                      }
                      changeFormValue(field.name, value);
                    }}
                  />
                </div>
              ) : null;
            })}
          </div>
          {buttonVisibility &&
            fabricDetails[fabricType].visible <
              fabricDetails[fabricType].max && (
              <Button
                onClick={() =>
                  this.handleClick(fabricType, buttonVisibilityKey)
                }
                type="button"
                category="primary-with-bg customAddDetail"
              >
                <AddIcon />
                <div>Add more</div>
              </Button>
            )}
        </div>
      </>
    );
  };

  render() {
    const {
      design,
      initialValues,
      showAddMoreFabric,
      showAddMoreTrims
    } = this.state;

    if (design === null) {
      return null;
    }

    const { groupedFields } = design;

    return (
      <Form {...this.props} initialValues={initialValues} form="designSummary">
        {({ changeFormValue, values, ...formProps }) => (
          <div className="summary-container summary-form">
            <div className="summary-container__info">
              <div className="summary-container__row">
                <div className="label">Design name</div>
                <Field
                  component={Input}
                  validate={[required]}
                  maxLength={50}
                  type="text"
                  name="name"
                  autoFocus={true}
                />
              </div>
              <div className="summary-container__row">
                <div className="label">Design ID</div>
                <Field
                  component={Input}
                  validate={[required]}
                  maxLength={25}
                  type="text"
                  name="design_id"
                />
              </div>
              <div className="summary-container__row">
                <div className="label">Category</div>
                <span className="flex design-details__2-col">
                  <span className="col_1">
                    <Field
                      name="category"
                      type="text"
                      options={DESIGN_CATEGORIES}
                      onChange={(evt, value) => {
                        this.setState({ category: value, sub_category: null });
                        this.props.onFormChange(
                          { name: "category", value: value },
                          { name: "sub_category", value: null }
                        );
                        changeFormValue("sub_category", null);
                        this.forceUpdate();
                      }}
                      component={Select}
                    />
                  </span>
                  <span className="col_2">
                    <Field
                      type="text"
                      name="sub_category"
                      controlledValue={
                        this.state.sub_category
                          ? find(DESIGN_SUB_CATEGORIES[this.state.category], {
                              value: this.state.sub_category
                            })
                          : null
                      }
                      component={Select}
                      onChange={(evt, value) => {
                        this.props.onFormChange({
                          name: "sub_category",
                          value: value
                        });
                        this.setState({ sub_category: value });
                      }}
                      // TODO: Add interaction with category field
                      // https://github.com/erikras/redux-form/issues/3080
                      options={DESIGN_SUB_CATEGORIES[this.state.category]}
                    />
                  </span>
                </span>
              </div>
              <div className="summary-container__row">
                <div className="label">Description</div>
                <Field
                  component={TextArea}
                  maxLength={150}
                  name="description"
                />
              </div>
              {groupedFields && (
                <>
                  {groupedFields[ADDITIONAL_DETAILS] &&
                    groupedFields[ADDITIONAL_DETAILS].map(field => (
                      <div className="summary-container__row">
                        <div className="label">{field.name}</div>
                        <div className="field-input">
                          {field.type === "bool" ? (
                            <Field
                              component={Radio}
                              name={`field_${field.id}`}
                              type="select"
                              data={[
                                { label: "Yes", value: "Yes" },
                                { label: "No", value: "No" }
                              ]}
                            />
                          ) : (
                            <Field
                              component={Input}
                              type="text"
                              name={`field_${field.id}`}
                              placeholder="(Optional)"
                            />
                          )}
                          {field.name === "MRP" && (
                            <span className="field-input__annotation">
                              not visible to the factory
                            </span>
                          )}
                        </div>
                      </div>
                    ))}
                  <div className="summary-container__title">Fabric details</div>

                  {groupedFields[FABRIC_DETAILS] && (
                    <>
                      {groupedFields[FABRIC_DETAILS][CORE_FABRIC] &&
                        this._renderFabricDetails(
                          changeFormValue,
                          CORE_FABRIC,
                          "showAddMoreFabric",
                          showAddMoreFabric
                        )}
                      {groupedFields[FABRIC_DETAILS][TRIM_FABRIC] &&
                        this._renderFabricDetails(
                          changeFormValue,
                          TRIM_FABRIC,
                          "showAddMoreTrims",
                          showAddMoreTrims
                        )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </Form>
    );
  }
}

DesignSummaryForm.propTypes = {};

export default DesignSummaryForm;
