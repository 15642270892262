import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { Field } from "formik";
import * as Yup from "yup";

// ******* local imports
import fileIcon from "assets/images/editor/ic-attach-file.svg";
import cancelIcon from "assets/images/navigation/ic-close-small.faded.svg";

import { get_service_endpoint } from "ServiceEndpoints";

import actions from "store/actions";

import DesignCard from "components/Designs/DesignCard/Facade";

import "./styles.scss";

import Form from "components/CustomBrand/UI/Formik/Form";
import DatePicker from "components/CustomBrand/UI/Formik/Datepicker";
import Button from "components/UI/Button/Button";
import {
  getFactoryTnaTemplate,
  getSamplesByDesignId
} from "store/actions/DesignActions";

import { BrandImage } from "../../../UI/BrandImage";
import { ProfileAvatar } from "../../../UI/ProfileAvatar";
import history from "../../../../utilites/history";

import StarRating from "../../../UI/StarRating";
import SampleFiles from "../SampleFiles";
import { VALID_FILE_TYPES } from "../../../../constants/Global";
import {
  SAMPLE_STATUS_PLACEHOLDERS,
  APPROVED,
  RESUBMIT,
  DESIGN_CANCELED
} from "../../../../constants/Sample";

const newEp = get_service_endpoint("notificationView");

const StaticCard = ({
  design,
  files,
  name = "Locofast",
  type = "prototype/fit sample"
}) => {
  return (
    <div class="factory_image">
      <div>
        <BrandImage name={name} size={24} />
      </div>
      <div>
        <div className="fac-name">{name}</div>
        <div>We have sent the {type}. Please check and let us know.</div>
        <SampleFiles designName={design.name} files={files} />
      </div>
    </div>
  );
};

class SampleStatusUpdate extends Component {
  state = {
    comment: "",
    files: [],
    fileNames: [],
    sampleStatus: "",
    canUploadFiles: true
  };

  componentDidMount() {
    const {
      dispatch,
      commentDetails: { designId },
      sampleDetails: { brandId }
    } = this.props;

    this.scrollToBottom();
    dispatch(
      getFactoryTnaTemplate({
        designid: designId,
        brandid: brandId
      })
    );
    dispatch(
      getSamplesByDesignId({
        designId
      })
    );
  }

  componentDidUpdate() {
    this.scrollToBottom();

    if (!(this.state.sampleStatus === APPROVED) && this.state.rating !== null) {
      this.updateRating(null);
    }
  }

  scrollToBottom = () => {
    if (this._scrollRef && this._scrollRef.scrollIntoView) {
      this._scrollRef.scrollIntoView();
    }
  };

  removeFileHandler = index => {
    const { fileNames, files } = this.state;

    fileNames.splice(index, 1);
    files.splice(index, 1);

    this.setState({ fileNames, files, canUploadFiles: true });
  };

  navigateToApproved = sampleStatus => {
    // if its not a sample status it not approved; return
    if (sampleStatus !== APPROVED) {
      return;
    }

    // if user is not in the sampling page, then no need to navigate to approved tab
    if (!/^\/\w+\/sampling\//g.test(window.location.pathname)) {
      return;
    }

    history.push("../approved");
  };

  updateAndContinueHandler = () => {
    let { comment, files, sampleStatus } = this.state;
    const {
      designId,
      request,
      moduleStatus,
      withComment = false,
      addNewCommint,
      update,
      cardData: design,
      request: { factoryId }
    } = this.props.commentDetails;
    const moduleRef = request.sampleType, moduleId = request.sampleId;
    const dueDate =
      this.factoryRef.current && this.factoryRef.current.values["dueDate"];

    const { dispatch } = this.props; // FIXME: please don't abuse dispatch like this.

    //  this is patch here for every update
    // this data is for comment and upload api ..

    //  this was added because der was some issue on uploading file with empty comment ******
    if (files.length && !comment) {
      comment = "User has not added any comment";
    }

    let promiseRequest;
    const data = new FormData();
    data.append("designId", designId);
    data.append("moduleRef", moduleRef);
    data.append("comment", comment);
    data.append("moduleType", 1);
    data.append("moduleStatus", sampleStatus);
    data.append("moduleId", moduleId);

    files.map(file => data.append("file", file));

    data.append("sampleStatus", this.state.sampleStatus);
    if (!request.id) {
      request.id = request.sampleId;
    }

    if (withComment) {
      promiseRequest = axios
        .patch(`${newEp}/sample`, {
          ...request,
          dueDate: dueDate || request.dueDate,
          sampleStatus: this.state.sampleStatus,
          rating: this.state.rating
        })
        .then(res => {
          if (sampleStatus === DESIGN_CANCELED) {
            dispatch(
              actions.updateDesignStatus({
                id: designId,
                collection_id: request.collectionId,
                status: 12 // 12 indicates cancelled designs
              })
            );
          }
          if (comment || files.length) {
            return axios
              .post(
                `${newEp}/comments/designs/${designId}/factories/${factoryId}`,
                data
              )
              .then(res => {
                dispatch(
                  actions.flash({
                    message: `Status for ${moduleRef} for ${
                      design.name
                      } changed ${moduleStatus ? "to " + moduleStatus : ""}`,
                    type: "action",
                    actionType: "Update"
                  })
                );
                dispatch(actions.getSamplesByDesignId({ designId }));
                dispatch(actions.getSampleHistory({ designId }));
                dispatch(actions.closeModal());

                this.navigateToApproved(sampleStatus);

                return res;
              })
              .catch(err => {
                console.log("ERR IN commentS ", err);
              });
          } else {
            dispatch(actions.getSamplesByDesignId({ designId }));
            dispatch(actions.getSampleHistory({ designId }));
            dispatch(
              actions.flash({
                message: `Status for ${moduleRef} for ${design.name} changed ${
                  moduleStatus ? "to " + moduleStatus : ""
                  }`,
                type: "action",
                actionType: "Update"
              })
            );
            dispatch(actions.closeModal());
            this.navigateToApproved(sampleStatus);

            return Promise.resolve();
          }
        });
    } else {
      promiseRequest = axios
        .post(
          `${newEp}/comments/designs/${designId}/factories/${factoryId}`,
          data
        )
        .then(res => {
          dispatch(
            actions.flash({
              message: `Message sent`,
              type: "action",
              actionType: "Update"
            })
          );

          this.setState({
            files: [],
            fileNames: [],
            comment: ""
          });

          addNewCommint({ data: res.data });
          dispatch(actions.closeModal());
          this.navigateToApproved(sampleStatus);

          return res;
        })
        .catch(err => console.log("ERR IN commentS "));
    }

    promiseRequest.then(result => {
      if (update) {
        update(this.state.sampleStatus, result && result.data);
      }
    });
  };

  handleChange = ({ target }) => {
    this.setState({
      comment: target.value
    });
  };

  updatePlaceholder = label => {
    this.setState({
      messagePlaceholder: SAMPLE_STATUS_PLACEHOLDERS[label]
    });
  };

  updateRating = rating => {
    this.setState({
      rating
    });
  };

  getAllowedSampleStatusTypes = () => {
    const { sampleStatusTypes } = this.props.commentDetails;
    const { userDetails, sampleDetails } = this.props;
    const designCreatedBy = sampleDetails && sampleDetails.designId && sampleDetails.designId.createdBy;

    if (designCreatedBy === userDetails.id) {
      return sampleStatusTypes;
    }

    return sampleStatusTypes.filter(sst => sst.value !== DESIGN_CANCELED);
  }

  sampleRef = React.createRef();
  factoryRef = React.createRef();

  render() {
    const {
      request, // this is actual data for update ..
      cardData,
    } = this.props.commentDetails;
    const {
      sampleDetails: { samples },
    } = this.props;
    const { messagePlaceholder, canUploadFiles } = this.state;
    const latestSample = samples && samples.find(sample => sample.id === (request.sampleId || request.id));
    const latestSampleDispatch = latestSample && latestSample.sampleDispatch[0];
    const dispatchFiles = latestSampleDispatch && latestSampleDispatch.files;
    const allowedSampleStatusTypes = this.getAllowedSampleStatusTypes();

    let inputRef = React.createRef();
    const { image, name } = this.props.userDetails;
    const { sampleStatus } = this.state;

    return (
      <div className="sample_status_modal flex-col">
        <section className="split_modal_view flex-grow">
          <div className="sample_status_container">
            <StaticCard
              design={cardData}
              name={request.factoryName}
              type={request.sampleType}
              files={dispatchFiles}
            />
            <Form
              validationSchema={Yup.object().shape({
                sampleStatus: Yup.string()
                  .typeError("Sample status must be a string")
                  .required("Required")
              })}
              innerRef={this.sampleRef}
            >
              {({ changeFormValue, validateForm, ...formProps }) => (
                <div>
                  <div className="input_grp">
                    <Field
                      component={({ field, form: { setFieldValue } }) => (
                        <React.Fragment>
                          <div className={`flex-col`}>
                            <div className="label">
                              Select a response <sup>*</sup>
                            </div>
                            <div className={`flex-col downshift_radio`}>
                              {allowedSampleStatusTypes.map((item, index) => (
                                <div
                                  className="loco__radio loco__radio--left-align"
                                  key={index}
                                >
                                  <div key={index}>
                                    <input
                                      name="design_category_selectors"
                                      id={item.label}
                                      type="radio"
                                      value={item.value}
                                      {...field}
                                      checked={field.value === item.value}
                                      onChange={() => {
                                        setFieldValue(field.name, item.value);
                                        this.setState({
                                          sampleStatus: item.value
                                        });
                                        this.updatePlaceholder(item.value);
                                      }}
                                    />
                                    <label htmlFor={item.label}>
                                      {item.label}
                                    </label>
                                  </div>
                                </div>
                              ))}
                            </div>
                            {sampleStatus === APPROVED && (
                              <div className="rating">
                                <div className="text">
                                  How satisfied are you with this sample?
                                </div>
                                <StarRating
                                  updateRating={this.updateRating}
                                  initialRating={this.state.rating}
                                />
                              </div>
                            )}
                          </div>
                        </React.Fragment>
                      )}
                      name="sampleStatus"
                      type="select"
                      label="Sample status"
                      placeholder="Select sample type"
                      options={allowedSampleStatusTypes}
                      required
                    />
                  </div>
                </div>
              )}
            </Form>
            {sampleStatus === RESUBMIT && (
              <Form
                validationSchema={Yup.object().shape({
                  dueData: Yup.date()
                    .typeError("Sample type must be a date")
                    .required("Required")
                })}
                innerRef={this.factoryRef}
              >
                {({ changeFormValue, validateForm, ...formProps }) => (
                  <div>
                    <div className="input_grp">
                      <Field
                        component={DatePicker}
                        className="v2"
                        name="dueDate"
                        type="date"
                        label="Due date"
                        placeholder="Select date"
                        required
                        customInput={({ value, onClick }) => (
                          <div
                            className="custom_datepicker formik_datepicker"
                            type="date"
                            onClick={onClick}
                          >
                            {value || (
                              <span className="f-color-faded">Select date</span>
                            )}
                          </div>
                        )}
                        popperPlacement="auto-start"
                      />
                    </div>
                  </div>
                )}
              </Form>
            )}
          </div>
          <DesignCard card={cardData} />
          {sampleStatus && (
            <div className="flex message_container">
              <React.Fragment>
                <ProfileAvatar url={image} name={name} />
                <textarea
                  name="commentMessage"
                  id="samplecomment"
                  cols="20"
                  rows="10"
                  onChange={this.handleChange}
                  autoFocus
                  value={this.state.comment}
                  className="custom__textarea"
                  placeholder={messagePlaceholder}
                  style={{
                    height: 100 + this.state.fileNames.length * 20
                  }}
                />
              </React.Fragment>
            </div>
          )}
        </section>
        <div className="modal__footer flex">
          <div className="files_container flex-grow">
            <input
              type="file"
              ref={inputRef}
              disabled={!canUploadFiles}
              onChange={ev => {
                if (ev.target.files.length) {
                  this.setState({
                    fileNames: [
                      ...this.state.fileNames,
                      {
                        id: this.state.fileNames.length,
                        name: ev.target.files[0]["name"]
                      }
                    ],
                    files: [...this.state.files, ev.target.files[0]],
                    canUploadFiles: this.state.files.length + 1 < 3
                  });
                }
              }}
              className="hidden_input_ref"
              accept={VALID_FILE_TYPES}
            />
            {this.state.fileNames.length > 0 ? (
              <div className="flex">
                <div
                  onClick={() => inputRef.current.click()}
                  className={`file_upload ${
                    !canUploadFiles ? "file_upload--disabled" : ""
                    }`}
                >
                  <img src={fileIcon} alt="file" className="upload__ico" />
                </div>
                {this.state.fileNames.map(({ name }, index) => (
                  <div className="file_uploaded">
                    <span className="overflow-ellipsis">{name}</span>
                    <img
                      src={cancelIcon}
                      alt="Cancel"
                      onClick={() => this.removeFileHandler(index)}
                    />
                  </div>
                ))}
              </div>
            ) : (
                <div
                  onClick={() => inputRef.current.click()}
                  className="file_upload no_files"
                >
                  <img src={fileIcon} alt="file" className="upload__ico" />
                  <span>Attach files (up to 3)</span>
                </div>
              )}
          </div>
          <div>
            <Button
              onClick={() => {
                this.updateAndContinueHandler();
              }}
              category="blue-bg"
            >
              SUBMIT
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userDetails: state.auth.userDetails,
    data: state.designs.preProductionCommit,
    design: state.designs.selectedDesigns,
    sampleDetails: state.designs.newSampleDetails,
    tnaTemplate: state.designs.factoryTnaTemplate
  };
};

export default connect(mapStateToProps, null)(SampleStatusUpdate);
