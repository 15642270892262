import React, { useRef } from "react";
import PropTypes from "prop-types";
import Popover from "react-tiny-popover";

import Button from "components/UI/Button/Button";
import FileModal from "components/ProductionTracker/TnaImageModal";

import icPlcAttachment from "assets/images/file/ic-attach-file.svg";
import excelImg from "assets/images/summary/excel.svg";
import pdfImg from "assets/images/summary/pdf.svg";
import docImg from "assets/images/summary/doc.svg";
import moreVert from "assets/images/navigation/ic-more-vert.svg";
import icEdit from "assets/images/image/ic-edit.svg";
import icDelete from "assets/images/action/ic-delete.svg";
import downloadIcon from "assets/images/file/ic-download.svg";

import "./style.scss";
import { useToggleMenu } from "customHooks/useToggleMenu";
import { checkPic } from "../../../helpers/DesignHelpers";
import { MEASUREMENT_SHEET, MATERIAL } from "constants/Design";

const getBgUrl = (file) => {
  const regex = /(xlsx|xls|csv|pdf|doc|docx){1}$/;
  const testUrl = regex.exec(file);
  if (testUrl) {
    return icons[testUrl[0]];
  }
  return file;
};

const icons = {
  xlsx: excelImg,
  xls: excelImg,
  csv: excelImg,
  pdf: pdfImg,
  doc: docImg,
  docx: docImg
}

const File = ({ openAddFileModal, file, viewFile, openEditFileModal, deleteDesignFile, disabled = false,postSampleSheet, isBrand, isEditable = false, isDeletable = false }) => {

  const [state, dispatch, possibleStates] = useToggleMenu();
  const {
    hovered_false,
    hovered_true,
    clicked_true,
    clicked_false
  } = possibleStates;
  const isPic = (file && checkPic(file.fileloc)) || false;
  const sampleFileRef = useRef(null);

  const SheetMenu = file && (
    <React.Fragment>
      {isBrand &&
        <div
          onClick={() => {
            if(sampleFileRef.current){
              sampleFileRef.current.click();
            }
          }}
          className={`pop-over__item flex f-sz-l`}
        >
          <img src={icEdit} alt="edit" />
          <span>Replace</span>
        </div>
      }
      <div className="pop-over__item flex f-sz-l">
        <img src={downloadIcon} alt="download file" />
        <a href={file.fileloc}>
          <span>Download</span>
        </a>
      </div>
    </React.Fragment>
  )

  const MaterialMenu =  file && (
    <React.Fragment>
      {isDeletable &&
        <div
          onClick={() => {
            if (window.confirm("Are you sure you want to delete this file ?")) {
              deleteDesignFile({
                file_id: file.id
              });
            }
          }}
          className="pop-over__item flex f-sz-l"
        >
          <img src={icDelete} alt="delete collection" />
          <span>Delete</span>
        </div>
      }
      <div className="pop-over__item flex f-sz-l">
        <img src={downloadIcon} alt="download file" />
        <a href={file.fileloc}>
          <span>Download</span>
        </a>
      </div>
    </React.Fragment>
  )

  const Menu = file && (
    <React.Fragment>
      {isEditable &&
        <div
          onClick={() => openEditFileModal(file)}
          className={`pop-over__item flex f-sz-l`}
        >
          <img src={icEdit} alt="edit" />
          <span>Edit</span>
        </div>
      }

      {isDeletable &&
        <div
          onClick={() => {
            if (window.confirm("Are you sure you want to delete this file ?")) {
              deleteDesignFile({
                file_id: file.id
              });
            }
          }}
          className="pop-over__item flex f-sz-l"
        >
          <img src={icDelete} alt="delete collection" />
          <span>Delete</span>
        </div>
      }

      <div className="pop-over__item flex f-sz-l">
        <img src={downloadIcon} alt="download file" />
        <a href={file.fileloc}>
          <span>Download</span>
        </a>
      </div>
    </React.Fragment>
  );
  /**
   * If file the show the title and the download options, if image then show it as  url
   */
  return (
    <div
      className={`file ${file ? "valid" : "empty"} ${
        file.is_cover || isPic ? "is-cover" : ""
      }`}
      onMouseEnter={() => dispatch({ type: hovered_true })}
      onMouseLeave={() => dispatch({ type: hovered_false })}
      onClick={() => (file.cannotView ? {} : viewFile(file))}
    >
      {file ? (
        <React.Fragment>
          <div
            className="file__image"
            style={{
              backgroundImage: `url(${
                file["defaultIcon"] ? excelImg : getBgUrl(file["fileloc"])
              })`
            }}
          ></div>
          <div className="file__headings">
            <div>
              <div className="file_title overflow-ellipsis">
                {file["file_title"]}
              </div>
              <div className="file_category overflow-ellipsis">
                {file["file_category"]}
              </div>
            </div>
            {state.hovered && !disabled && (
              <div className="file__options">
                <Popover
                  isOpen={state.clicked}
                  position={"bottom"}
                  content={
                    <div onMouseEnter={() => dispatch(clicked_true)}>
                      {file["file_title"] === MEASUREMENT_SHEET
                        ? SheetMenu
                        : file["file_category"] === MATERIAL
                        ? MaterialMenu
                        : Menu}
                    </div>
                  }
                  onClickOutside={() => dispatch({ type: clicked_false })}
                >
                  <img
                    onClick={e => {
                      state.clicked
                        ? dispatch({ type: clicked_false })
                        : dispatch({ type: clicked_true });
                      e.stopPropagation();
                    }}
                    src={moreVert}
                    alt="three dots"
                  />
                </Popover>
              </div>
            )}
          </div>
          <input
            ref={sampleFileRef}
            className="hide"
            type="file"
            name="myfile"
            accept=".csv,.xls,.xlsx"
            onChange={postSampleSheet}
          />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div>
            <img src={icPlcAttachment} className="f-sz-xxl file__icon" />
          </div>
          <Button category="btn" onClick={() => openAddFileModal("file")}>
            Attach file
          </Button>
        </React.Fragment>
      )}
    </div>
  );
};

export default File;
