import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { BrandImage } from '../../../UI/BrandImage';
import './style.scss';

const ORG_TYPES = ['BRAND', 'FACTORY'];

export default class OrgRow extends React.Component {
  static propTypes = {
    org: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      address: PropTypes.string,
    }).isRequired,
    orgType: PropTypes.oneOf(ORG_TYPES),
    isHovered: PropTypes.bool,
    isSelected: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  _handleClick = (e) => {
    const { onChange, isSelected } = this.props;

    if (typeof (onChange) === 'function') {
      onChange(e, !isSelected);
    }
  }

  _renderCTA() {
    const { isSelected } = this.props;

    return <>
      <div className="btn btn--primary-text btn--black-ghost cta--default">
        {isSelected ? 'SELECTED' : 'SELECT'}
      </div>
      <div className="btn btn--blue-bg cta--on-hover">
        {isSelected ? 'SELECTED' : 'SELECT'}
      </div>
    </>
  }

  render() {
    const { org, isSelected, isHovered, orgType } = this.props;

    return <button
      className={classNames("order-org-row", {
        'order-org-row--selected': isSelected,
        'order-org-row--hovered': isHovered,
      })}
      onClick={this._handleClick}
    >
      <div className="order-org-row__org-logo">
        <BrandImage
          id={org.id}
          name={org.name}
          borderRadius="4px"
          orgType={orgType}
        />
      </div>
      <div className="order-org-row__org-info">
        <div className="order-org-row__org-name overflow-ellipsis">
          {org.name}
        </div>
        {
          org.city && <div className="order-org-row__org-address overflow-ellipsis">
            {org.city}
          </div>
        }
      </div>
      <div className="order-org-row__cta-list">
        {this._renderCTA()}
      </div>
    </button>
  }
}
