import React, { useState, useEffect } from "react";
import isEmpty from "lodash/isEmpty";
import PerfectScrollbar from "react-perfect-scrollbar";
import Quote from "./quote";
import { HeaderTabs } from "./HeaderTabs";
import Loader from "components/UI/InlineLoader";
import EmptyScreen from "../UI/EmptyScreen/index.jsx";
import thumbsUpImg from "assets/images/dashboard/ic-thumbsup-grey.svg";

const QUOTE = "quote";
const SAMPLING = "sampling";
const PRODUCTION = "production";

const tabIndices = {
  [QUOTE]: {
    index: 0,
    tab: "quote",
    errorTitle: "quote",
    cta: "Review quote"
  },
  [SAMPLING]: {
    index: 1,
    tab: "sampling",
    errorTitle: "sample",
    cta: "Update status"
  },
  [PRODUCTION]: {
    index: 2,
    tab: "designtna",
    errorTitle: "tna",
    cta: "Update sample status"
  }
};
const Sidebar = ({ pendingApprovals }) => {
  const [filter, setFilter] = useState(QUOTE);
  const [designData, setDesignData] = useState(() => pendingApprovals.quote);

  useEffect(() => {
    setDesignData(pendingApprovals[filter]);
  }, [filter]);

  useEffect(() => {
    if (filter === QUOTE) {
      if (isEmpty(designData)) {
        // If there are no pending approvals for quote, navigate to sampling
        if (!isEmpty(pendingApprovals[SAMPLING])) {
          setFilter(SAMPLING);
        } else if (!isEmpty(pendingApprovals[PRODUCTION])) {
          // If there are no pending approvals for sampling, navigate to production
          setFilter(PRODUCTION);
        }
      }
    }
  }, [pendingApprovals]);

  if (isEmpty(pendingApprovals)) {
    return <Loader />;
  }

  const { quote, sampling, production } = pendingApprovals;

  return (
    <PerfectScrollbar>
      <div className="sidebar hover-sidebar">
        <div>
          <div className="sidebar-heading">Pending approvals</div>
        </div>
        <HeaderTabs
          onChange={value => {
            setFilter(value);
          }}
          tabs={[
            {
              label: `Quote (${quote.length})`,
              value: QUOTE
            },
            {
              label: `Sampling (${sampling.length})`,
              value: SAMPLING
            },
            {
              label: `Production (${production.length})`,
              value: PRODUCTION
            }
          ]}
          index={tabIndices[filter].index}
        />
        <div className="sidebar-listing">
          {(designData && designData.length > 0 )? (
            designData.map(design => (
              <Quote
                data={design}
                filter={filter}
                tab={tabIndices[filter].tab}
                cta={tabIndices[filter].cta}
              />
            ))
          ) : (
            <EmptyScreen
              icon={thumbsUpImg}
              heading="Great job!"
              description={
                <>
                  You don’t have any pending
                  <br />
                  {filter === QUOTE ? "quote" : "sample"} approvals
                </>
              }
            />
          )}
        </div>
      </div>
    </PerfectScrollbar>
  );
};
export default Sidebar;
