import React from "react";
import "./CustomTable.scss";
const Table = ({ headers, children, className }) => {
  return (
    <table className={`custom-table ${className}`} rules="none">
      <thead>
        <tr>
          {headers.map(header => (
            <th>{header}</th>
          ))}
        </tr>
      </thead>
      {
        //TODO: Add react-perfect-scrollbar in tbody
      }
      <tbody
        style={{
          overflowY: "visible"
        }}
        className="custom-table__body"
      >
        {children}
      </tbody>
    </table>
  );
};

export default Table;
