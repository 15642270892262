import React, { Component } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import Button from "../../UI/Button/Button";
import DesignCard from "../DesignCard/DesignCard";
// import PropTypes from "prop-types";
import ImgPlus from "assets/images/content/ic-plus.png";
import "./DesignGrid.scss";
import moment from "moment";
import { canCreateDesign } from "../../../helpers/AuthHelper";

// TODO: Use Muuri for adding in proper masonry grid
export default class DesignGrid extends Component {
  // static propTypes = {
  //   prop: PropTypes
  // };
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  componentDidMount() {
    // console.log(this.myRef);
  }

  render() {
    const showButton = canCreateDesign();

    const designs = this.props.designs.sort((a, b) => {
      return moment(a["created_on"]).isBefore(b["created_on"]) ? 1 : -1;
    });

    return (
      <div className="design-grid flex custom-scroll-grid" ref={this.myRef}>
        {/* change perfect scroll bar to div */}
        <div className="design-grid__list-container flex">
          {designs.map(d => (
            <DesignCard
              key={d.id}
              openModal={this.props.openModal}
              match={this.props.match}
              card={d}
              currentUser={this.props.currentUser}
            />
          ))}
        </div>
        {showButton && (
          <div className="design-grid__footer">
            <Button
              className="design-grid__btn"
              onClick={this.props.openNewDesignModal}
              shadow={true}
              category="circle"
            >
              <img src={ImgPlus} alt=""/>
            </Button>
          </div>
        )}
      </div>
    );
  }
}
