import React, { Component } from "react";
import { withRouter } from "react-router-dom";

// ==== Local imports ===
import locofastLogo from "../../../assets/images/ic-header-logo.svg";
import backIcon from "../../../assets/images/navigation/ic-arrow-back-white.svg";

const HeaderWithLogo = ({ isBackIcon = false, history }) => {
  return (
    <div
      style={{
        height: "56px",
        backgroundColor: "#004d99",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        zIndex: 9999999
      }}
    >
      {isBackIcon ? (
        <div
          style={{
            position: "absolute",
            top: "16px",
            left: "16px",
            cursor: "pointer"
          }}
          onClick={() => history.goBack()}
        >
          <img src={backIcon} alt="Back" style={{ cursor: "pointer" }} />
        </div>
      ) : null}
      <img src={locofastLogo} alt="LocoFast" />
    </div>
  );
};

export default withRouter(HeaderWithLogo);
