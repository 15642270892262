import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";

import QuoteItem from "../QuoteItem/index";
import EmptyFilterResult from "../../UI/EmptyFilterResult";


class Table extends Component {
  static propTypes = {
    quotes: PropTypes.array.isRequired,
    userDetails: PropTypes.object.isRequired,
    currency: PropTypes.string.isRequired,
    openModal: PropTypes.func.isRequired,
    onClearFilters: PropTypes.func.isRequired,
    refreshQuotes: PropTypes.func.isRequired,
  };

  render() {
    const {
      quotes,
      userDetails,
      onClearFilters,
      openModal,
      currency,
      refreshQuotes,
    } = this.props;

    if (quotes && quotes.length) {
      setTimeout(() => {
        ReactTooltip.rebuild();
      }, 1000);
    }

    const colsCount = userDetails.isSuperAdmin ? 7 : 6;

    return (
      <div className="quote-section">
        <div className={`quote-container cols-${colsCount}`}>
          <div className="quote-container-header">
            <div className="design-wid">DESIGN</div>
            {!userDetails.isBrand && <div className="brand-wid">BRAND</div>}
            {(!userDetails.isFactory || !!userDetails.isSuperAdmin) && <div className="factory-wid">FACTORY</div>}
            <div>QUANTITY</div>
            <div>TARGET PRICE</div>
            <div>QUOTE</div>
          </div>
          <div className="quote-container-table">
            {
              (!quotes || quotes.length === 0)
                ? (
                  <EmptyFilterResult
                    onClearFilters={onClearFilters}
                  />)
                : quotes.map((quote) => (
                  <QuoteItem
                    key={quote.design_id}
                    quote={quote}
                    userDetails={userDetails}
                    openModal={openModal}
                    currency={currency}
                    refreshQuotes={refreshQuotes}
                  />
                ))}
          </div>
        </div>
      </div>
    );
  }
}

export default Table;
