import React from "react";
import Downshift from "downshift";
import icDropdown from "../../assets/images/navigation/ic-arrow-drop-down.svg";

import PerfectScrollbar from "react-perfect-scrollbar";

const NotificationFilter = ({
  title,
  onChange,
  initialSelectedItem,
  filterOptions = [],
  marginRight = "0px",
  height = "250px",
  width = "138px",
  innerWidth,
  selectedItem = null
}) => {
  const selectedItemObject = selectedItem ? { selectedItem: selectedItem } : {};
  return (
    <Downshift
      className={`downshift`}
      initialSelectedItem={initialSelectedItem}
      itemToString={item => item.label}
      onChange={selection => {
        onChange(selection);
        // alert(JSON.stringify(selection))
        // this.handleUserAuthorityChange(collab, selection);
      }}
      {...selectedItemObject}
      style={{ fontSize: "12px", fontWeight: 600 }}
    >
      {({
        getInputProps,
        getToggleButtonProps,
        getItemProps,
        getLabelProps,
        getMenuProps,
        isOpen,
        inputValue,
        highlightedIndex,
        selectedItem
      }) => {
        return (
          <div
            {...getToggleButtonProps()}
            type="none"
            className="tab__filters tabfilters-bg"
          >
            <span
              {...getLabelProps()}
              style={{ fontSize: "12px", color: "var(--fill-grey-dark)" }}
            >
              {title}
            </span>
            <span className="flex" style={{ alignItems: "center" }}>
              <input readOnly={true} disabled={true} {...getInputProps()} />
              <img
                src={icDropdown}
                alt="dropdown"
                style={{ marginLeft: "auto" }}
              />
            </span>

            {isOpen ? (
              <div
                className="select-pane"
                onClick={e => {
                  e.stopPropagation();
                }}
                style={{ width: innerWidth }}
              >
                <div style={{ height: height }}>
                  <PerfectScrollbar style={{ maxHeight: "50vh" }}>
                    {filterOptions
                      // .filter(item => item.label !== selectedItem.label)
                      .map((item, index) => {
                        return (
                          <div
                            {...getItemProps({
                              className: `in-selection collection-collab__label_left_aigned flex ${
                                selectedItem.label === item.label
                                  ? "selected"
                                  : ""
                              }`,
                              index,
                              item,
                              key: item.label
                            })}
                          >
                            {item.label}
                          </div>
                        );
                      })}
                  </PerfectScrollbar>
                </div>
              </div>
            ) : null}
          </div>
        );
      }}
    </Downshift>
  );
};

export default NotificationFilter;
