import React, { useState } from "react";
import moment from "moment";
import NotificationToggle from "../UI/Toggle/NotificationToggle";

import { checkBrand, checkFactory, checkSuperAdmin } from "../../helpers/AuthHelper";
import { computeTimeStatus } from "../../helpers/DesignHelpers";

import fileIcon from "../../assets/images/editor/ic-attach-file.svg";
import downloadIcon from "../../assets/images/action/ic-download.svg";
import editPencilIcon from "../../assets/images/action/ic-edit-pencil.svg";
import { ReactComponent as LocationIcon } from "../../assets/images/image/pin-filled.svg";
import DateToolTip from "components/UI/DateToolTip";

import "./Orders.scss";

const OrderItem = ({
  order,
  order: {
    addressId,
    orderStatus,
    brandRepName,
    destinationAddress,
    dispatchDate,
    orderCompleteDispatchStatus,
    dueDate,
    paymentDueIn,
    poDate,
    poFile,
    poNumber,
    productsCount,
    shippingMode,
    totalAmount,
    totalQuantity,
    brandName = "default",
    city = "Default",
    brandId,
    factoryName = "Locofast",
    id
  },
  openModalHandler,
  isFactory,
  createDispatchNotification,
  removeDispatchNotification,
  isNotificationSelected,
  brandSelected,
  canEditOrder = false,
  isSuperAdmin
}) => {
  const [showDispatchDetails, setShowDispatchDetails] = useState(false);
  const [dispatchDetails, setDispatchDetails] = useState(null);

  const isUserFromFactory = checkFactory();
  const isUserFromBrand = checkBrand();
  const isUserSuperAdmin = checkSuperAdmin();

  let dispatchDateStatus;
  /**
   * Dispatch - complete and not complete(including partially completed)
   */
  if (dispatchDate || orderCompleteDispatchStatus === "Pending") {
    const mdueDate = moment(dueDate);
    const mdispatchDate = moment(dispatchDate);
    if (orderStatus === "Completed") {
      dispatchDateStatus = mdueDate.diff(mdispatchDate, 'days');
      if (dispatchDateStatus === 0) {
        dispatchDateStatus = mdueDate.diff(mdispatchDate, 'hours') <= 0 ? dispatchDateStatus : (
          moment.min(mdueDate, mdispatchDate) == mdueDate ? dispatchDateStatus + 1 : dispatchDateStatus - 1
        );
      }
    }
    else {
      dispatchDateStatus = moment(dueDate).diff(moment(), 'days');
    }
  }

  const timeStatus = computeTimeStatus(dueDate, dispatchDate, orderStatus, orderCompleteDispatchStatus);
  const isDelayed = timeStatus === "delayed";

  return (
    <div className="order__wrapper_outer table-row">
      <div className={`order ${isUserFromFactory || isUserFromBrand ? 'order-one-col' : ''}`}>

        {/* po details */}
        <div className="order__item cell-v2">
          <div>
            {poNumber} &nbsp;
              <span className="order__item_large_font_blue action">
              <a href={poFile}>
                <img src={downloadIcon} alt="" />
              </a>
            </span>
            {(canEditOrder && isUserSuperAdmin) ? (
              <span
                className="order__item_large_font_blue action"
                onClick={() =>
                  openModalHandler("ORDERS_EDIT_PO_ORDERS", {
                    orderid: id,
                    brandid: brandId,
                  })
                }
              >
                <img src={editPencilIcon} alt="" />
              </span>
            ) : null}
          </div>
        </div>

        {/* brand */}
        {!isUserFromBrand ? (
          <div className="order__brand overflow-ellipsis cell-v2">
            <span data-tip={brandName}>{brandName}</span>
          </div>
        ) : null}

        {/* factory */}
        {!isUserFromFactory ? (
          <div className="order__factory overflow-ellipsis cell-v2">
            <span data-tip={factoryName}>{factoryName}</span>
          </div>
        ) : null}

        {/* Designs */}
        <div className="amount order__item cell-v2">
          <div className="pieces order__item_large_font" >
            {`${totalQuantity} Pcs.`}
          </div>
          <div
            className="designs f-color-faded link"
            onClick={() =>
              openModalHandler("ORDERS_VIEW_DESIGNS", {
                orderid: id,
                isFactory: isFactory,
                orderStatus,
                order
              })
            }
          >{`${productsCount} design` + (productsCount > 1 ? 's' : '')}</div>
        </div>

        {/* deadline */}
        <div className="order__item">
          <div className="order__item_large_font">
            INR {totalAmount.toLocaleString('en-IN')}
          </div>
        </div>

        {/* destination */}
        <div className="order__item order__item-img-prefix">
          <LocationIcon className="location" />
          <div className="cell-v2">
            <div className="order__item_city order__item_large_font status-title-location">
              <div className="city-name">
                {city}
                {destinationAddress && (
                  <div className="visible-on-hover">
                    <div className="content">
                      <span>Delivery address</span>
                      <p>{destinationAddress}</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div>
              Due on {moment(dueDate).format(`MMM DD, YYYY`)}
            </div>
          </div>
        </div>

        {/* order status */}
        <div
          className="order__item__with__ARROW"
        >
          <div className="order__item cell-v2">
            <div
              className={`order__item_large_font order-status ${timeStatus} date-tooltip-parent`}
            >
              {orderStatus}
              {(orderCompleteDispatchStatus === "Pending") ?
                <DateToolTip
                  delayed={isDelayed}
                  date={dueDate}
                /> : (
                  <DateToolTip
                    delayed={isDelayed}
                    date={dispatchDate}
                    category="dispatched"
                  />
                )
              }
            </div>
            <div className="actions">
              {orderStatus === "Partially Completed" ||
                orderStatus === "Under Production" ? (
                  <div>
                    {isFactory && !isSuperAdmin ? (
                      <span
                        className="f-color-faded link"
                        onClick={() =>
                          openModalHandler("ORDERS_ADD_DISPATCH_DETAILS", {
                            id,
                            brandid: brandId,
                            addressId,
                            orderId: id
                          })
                        }
                        style={{ display: "contents" }}
                      >
                        Add details
                      </span>
                    ) : null}
                  </div>
                ) : (
                  <div
                    className="f-color-faded link"
                    onClick={() => {
                      openModalHandler("ORDERS_ADD_DISPATCH_DETAILS", {
                        id,
                        brandid: brandId,
                        addressId
                      });
                    }}
                    style={{ display: "contents" }}
                  >
                    {orderStatus !== "Completed" && (isFactory && !isSuperAdmin)
                      ? "Add details"
                      : null}
                  </div>
                )}
              {orderStatus !== "Under Production" ? (
                <div
                  className="f-color-faded link"
                  onClick={() => {
                    openModalHandler("ORDERS_VIEW_DISPATCH_DETAILS", {
                      orderId: id,
                      orderPONumber: poNumber
                    });
                  }}
                >
                  Details
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      {/* ============ STARTS DISPATCH DETAILS ======== */}

      {showDispatchDetails && dispatchDetails ? (
        <div
          style={{
            minHeight: "150px",
            backgroundColor: "#fff",
            padding: "14px",
            margin: "10px",
            borderTop: "1px solid var(--fill-grey-light3)"
          }}
        >
          {dispatchDetails.map(
            ({
              id,
              quantity,
              trackingCode,
              ewayBillFile,
              challanFile,
              invoiceFile,
              packingListFile,
              isOrderComplete,
              courierPartner,
              numberOfBoxes,
              dispatchDate
            }) => {
              return (
                <div
                  style={{
                    borderBottom: "1px solid var(--fill-grey-light)",
                    marginBottom: "12px"
                  }}
                >
                  <div style={{ lineHeight: "25px" }}>
                    <span style={{ fontSize: "12px" }}>
                      Dispatch Date: &nbsp;
                    </span>
                    <span>
                      {moment(dispatchDate).format("MMMM DD, YYYY")} &nbsp;
                    </span>
                    {isOrderComplete == 1 ? (
                      <span style={{ color: "#02bd70" }}>
                        This completes your order
                      </span>
                    ) : null}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      alignItems: "center",
                      padding: "12px 0px"
                    }}
                  >
                    <div className="dispatch__item" style={{ flex: 1 }}>
                      <div className="dispatch__item_value">
                        Dispatch Quantity
                      </div>
                      <div className="dispatch__item_label">
                        {quantity}
                      </div>
                    </div>

                    <div className="dispatch__item" style={{ flex: 1 }}>
                      <div className="dispatch__item_value">
                        Tracking ID
                      </div>
                      <div className="dispatch__item_label">
                        {trackingCode}
                      </div>
                    </div>

                    <div className="dispatch__item" style={{ flex: 1 }}>
                      <div className="dispatch__item_value">
                        No of Boxes
                      </div>
                      <div className="dispatch__item_label">
                        {numberOfBoxes}
                      </div>
                    </div>

                    <div className="dispatch__item" style={{ flex: 1 }}>
                      <div className="dispatch__item_value">
                        Courier Partner
                      </div>
                      <div className="dispatch__item_label">
                        {courierPartner}
                      </div>
                    </div>
                  </div>

                  {/* files from here  */}
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      alignItems: "center",
                      padding: "12px 0px"
                    }}
                  >
                    {ewayBillFile ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flex: 1
                        }}
                      >
                        <img
                          style={{ height: "20px" }}
                          src={fileIcon}
                          alt="po-file"
                        />
                        <a href={ewayBillFile}>E-way Bill</a>
                      </div>
                    ) : null}

                    {invoiceFile ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flex: 1
                        }}
                      >
                        <img
                          style={{ height: "20px" }}
                          src={fileIcon}
                          alt="po-file"
                        />
                        <a href={invoiceFile}>Invoice Copy</a>
                      </div>
                    ) : null}

                    {packingListFile ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flex: 1
                        }}
                      >
                        <img
                          style={{ height: "20px" }}
                          src={fileIcon}
                          alt="po-file"
                        />
                        <a href={packingListFile} className="visited_link">
                          Packing List
                        </a>
                      </div>
                    ) : null}

                    {challanFile ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flex: 1
                        }}
                      >
                        <img
                          style={{ height: "20px" }}
                          src={fileIcon}
                          alt="po-file"
                        />

                        <a href={challanFile}>Challan Copy</a>
                      </div>
                    ) : null}
                  </div>
                  {isFactory && brandSelected && (
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        alignItems: "center",
                        padding: "12px 0px"
                      }}
                    >
                      <div className="dispatch__item_value">
                        <NotificationToggle
                          onStateChanged={({ enabled }) => {
                            if (enabled)
                              createDispatchNotification({
                                poFile,
                                city,
                                poNumber,
                                orderPurchaseId: order.id,
                                brandId,
                                productsCount,
                                totalQuantity,
                                poDate,
                                dispatchId: id,
                                quantity,
                                trackingCode,
                                ewayBillFile,
                                challanFile,
                                invoiceFile,
                                packingListFile,
                                isOrderComplete,
                                courierPartner,
                                numberOfBoxes,
                                dispatchDate
                              });
                            else removeDispatchNotification({ dispatchId: id, brandId });
                          }}
                          theme="locofast"
                          enabled={isNotificationSelected}
                        />
                      </div>
                    </div>
                  )}
                </div>
              );
            }
          )}
        </div>
      ) : null}
    </div>
  );
};

export default OrderItem;
