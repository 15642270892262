import React, { Component } from "react";
import PropTypes from 'prop-types';
import moment from "moment";

import icFilePlaceholder from "../../../assets/images/file/ic-file-placeholder.svg";
import { TIMESTAMP_SHORT_FORMAT } from "../../../constants/Date";
import { ProfileAvatar } from "../../UI/ProfileAvatar";

import './index.scss';

export default class Message extends Component {
  static propTypes = {
    message: PropTypes.object.isRequired,
    align: PropTypes.oneOf(['left', 'right']),
    onShowImage: PropTypes.func.isRequired,
  };

  _onClickImage = file => {
    const { message, onShowImage } = this.props;

    onShowImage({
      imageUrl: file.url,
      placeHolder: file.isImage ? null : icFilePlaceholder,
      date: file.createdAt,
      title: file.name,
      fileName: file.name,
      uploadedBy: message.createdBy.name
    })
  }

  render() {
    const { message, align } = this.props;

    return (
      <div className={`message-container ${align}`}>
        <div className="message-sender">
          <ProfileAvatar
            id={message.createdBy.io}
            name={message.createdBy.name}
            url={message.createdBy.image}
          />
        </div>
        <div className="message-content">
          <div className="created-by">
            {message.createdBy.name}
          </div>

          {/* Message */}
          <div
            className="message"
            dangerouslySetInnerHTML={{ __html: message.comment.replace('\n', '<br>') }}
          ></div>

          {/* Files */}
          {
            message.files && message.files.length > 0 &&
            <div className="message-image-list-container">
              {
                message.files.map(file => (
                  <div
                    onClick={() => this._onClickImage(file)}
                    className="message-image-container"
                    key={file.id}
                  >
                    <div className="message-image">
                      <img
                        src={file.isImage ? file.url : icFilePlaceholder}
                        className="message-image-thumbnail"
                        alt=""
                      />
                    </div>
                  </div>))
              }
            </div>
          }
          {/* Date */}
          <div className="timestamp">
            {moment(message.createdAt).format(TIMESTAMP_SHORT_FORMAT)}
          </div>
        </div>
      </div>
    );
  }
}
