import React, { Component } from "react";
import { Field, Form, reduxForm } from "redux-form";

import { connect } from 'react-redux';
import { alphaNumeric, required } from "../../../helpers/form-validations";
import Input from "../../UI/Form/Input/Input";
import File from "../../UI/Form/File/File";
import { default as CustomInput } from "../../UI/Form/Input/CustomInput";
import axios from "axios";
import "./AddFilesForm.scss";
import { get_service_endpoint } from "./../../../ServiceEndpoints";
import actions from "../../../store/actions";
import PerfectScrollbar from "react-perfect-scrollbar";
import { flash } from "../../../store/actions/CommonActions";
import Downshift from "downshift";


const DEFAULT_CATEGORIES = ["Technical Pack", "Fabric", "Construction"];

const formName = "EditFile";

class EditFilesForm extends Component {

  state = {
    progress: 0,
    selectedFile: { imgUrl: this.props.file.fileloc, size: '', name: this.props.file.file_title },
    fields: {
      file_category: this.props.file.file_category
    },
    initial_values: {
      file_title: this.props.file.file_title,
      fileloc: this.props.file.fileloc,
      is_cover: this.props.file.is_cover,
      file_category: this.props.file.file_category
    },
    touched: false
  };
  handleFileChange = file => {
    this.setState({
      selectedFile: file,
      touched: true
    });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    let newFields = prevState.fields;
    if (!newFields.file_title) {
      newFields.file_title = nextProps.file.file_title;
    }
    // const { file_title } = nextProps.file.file_title;
    nextProps.hiddenFields.forEach(f => (newFields[f.name] = f.value));
    return {
      ...prevState,
      fields: { ...newFields },
      file_id: nextProps.file.file_id
    };
  }

  handleUpload = () => {

    const data = new FormData();
    if (this.state.selectedFile === null) {
      this.props.dispatch(
        actions.flash({
          message: "Select a file before trying to submit."
        })
      );
      return;
    }

    if ((this.state.selectedFile.size / (1024 * 1024)).toFixed(2) > 15) {
      this.props.dispatch(
        actions.flash({
          message: "Please select a smaller file (<15MB)"
        })
      );
      return;
    }

    if (!this.state.fields.hasOwnProperty("file_title") || this.state.fields["file_title"] === "") {
      this.props.dispatch(
        actions.flash({
          message: "Input a File title before trying to submit."
        })
      );
      return;
    }

    if (!this.state.fields.hasOwnProperty("file_category") || this.state.fields["file_category"] === "") {
      this.props.dispatch(
        actions.flash({
          message: "Select a File category before trying to submit."
        })
      );
      return;
    }

    if (!this.state.selectedFile.type) {
      Object.keys(this.state.fields).forEach(k => data.append(k, this.state.fields[k]));
      data.append('imageUrl', this.state.initial_values.fileloc);
      data.append('file_id', this.state.file_id);
      data.append('design_name', this.props.design.name);

      return {
        data,
        designId: this.props.design.id,
        collectionId: this.props.design.collection_id,
      };
    }
    else {
      Object.keys(this.state.fields).forEach(k => data.append(k, this.state.fields[k]));
      data.append('file_id', this.state.file_id);
      data.append('design_name', this.props.design.name);
      data.append("designfile", this.state.selectedFile, this.state.selectedFile.name);

      return {
        data,
        designId: this.props.design.id,
        collectionId: this.props.design.collection_id,
      };
    }
  };

  onFieldChange = ({ name, value }) => {
    this.setState({
      touched: true
    });


    this.setState(state => ({
      fields: {
        ...this.state.fields,
        [name]: value,
        // file_title: value
      },
      initial_values: {
        ...state.initial_values,
        [name]: ''
      }
    }), () => {
    });
  };

  render() {
    const { handleSubmit, isFactory } = this.props;

    const ADDITIONAL_CATEGORIES = [
      // Filter out duplicate categories
      ...new Set(
        Object.entries(this.props.design.files)
          .filter(
            ([fileId, fileObj]) =>
              !DEFAULT_CATEGORIES.includes(fileObj["file_category"]) &&
              fileObj["file_category"] !== "CoverFile7894"
          )
          .map(([fileId, fileObj]) => fileObj["file_category"])
      )
    ];


    return (
      <div>
        <PerfectScrollbar
          option={{
            suppressScrollX: true
          }}
          className="add-files__modal flex-col"
          containerRef={ref => (this._scrollRef = ref)}
        >
          <Form
            style={{
              padding: "1em 2em"
            }}
            name={formName}
            id={formName}
            onSubmit={handleSubmit(values => {
              if (!this.state.touched) {
                this.props.dispatch(
                  actions.flash({
                    message: "No changes, can not update."
                  })
                );
                return;
              }

              const formData = this.handleUpload();


              if (formData) {
                this.props.onSubmit(
                  formData
                )
              }

            })}
          >
            <div style={{ justifyContent: "center" }} className="flex">

              <File
                onCoverSelect={({ currentTarget: { checked } }) =>
                  this.onFieldChange({ name: "cover", value: checked ? 1 : 0 })
                }
                initialFileName={this.state.selectedFile}
                progress={this.state.progress}
                onChange={this.handleFileChange}
                initialCoverSelect={this.state.initial_values.is_cover}
                isFactory={isFactory}
              />
            </div>
            <Field
              component={Input}
              validate={[required, alphaNumeric]}
              maxLength={50}
              type="text"
              name="file_title"
              label="Enter File Title"
              onChange={evt => this.onFieldChange(evt.currentTarget)}
              autoFocus={true}
            />
            <label htmlFor="file_category" class="f-sz-sm" style={{ color: "var(--fill-grey-dark)" }}>
              Select File Category*
            </label>
            <Downshift>

              {({
                getInputProps,
                getItemProps,
                getLabelProps,
                getMenuProps,
                isOpen,
                inputValue,
                highlightedIndex,
                selectedItem
              }) => (
                  <div
                    style={{ marginBottom: "18px", marginTop: "8px" }}
                    className="custom-category__select flex-col"
                  >

                    <CustomInput

                      {...getInputProps()}
                      name="file_category"
                      id="file_category"
                      value={
                        [...DEFAULT_CATEGORIES, ...ADDITIONAL_CATEGORIES].includes(
                          this.state["fields"]["file_category"]
                        )
                          ? ""
                          : this.state["fields"]["file_category"]
                      }
                      onChange={({ target: { value } }) => {

                        if ([...ADDITIONAL_CATEGORIES, ...DEFAULT_CATEGORIES].includes(value)) {
                          this.props.dispatch(
                            actions.flash({ message: "Selected file category already exists" })
                          );
                          if (this._scrollRef && this._scrollRef.scrollIntoView)
                            this._scrollRef.scrollIntoView();
                        }

                        this.onFieldChange({
                          name: "file_category",
                          value: value
                        });
                      }}
                      maxLength="50"
                      type="text"
                    />
                    <PerfectScrollbar
                      ref={ref => {
                        this._scrollRef = ref;
                      }}
                    // option={{ wheelPropagation: false }}
                    >
                      <div
                        className="flex-col"
                        style={{
                          marginTop: "-10px",
                          lineHeight: "1.43",
                          letterSpacing: "0.2px",
                          fontSize: "14px",
                          maxHeight: "190px"
                        }}
                      >
                        {[...ADDITIONAL_CATEGORIES, ...DEFAULT_CATEGORIES]
                          .map(category => ({
                            value: category,
                            label: category
                          }))
                          .map(({ label, value }) => (
                            <div className="loco__radio loco__radio--left-align" style={{ border: "none", minHeight: "48px" }}>
                              <input

                                //  component={Input}

                                name="file_category_selectors"
                                id={label}
                                type="radio"
                                checked={this.state["fields"]["file_category"] === value}
                                onChange={() =>

                                  this.onFieldChange({
                                    name: "file_category",
                                    value: value
                                  })

                                }
                              />
                              <label for={label}>{label}</label>
                            </div>
                          ))}
                      </div>
                    </PerfectScrollbar>
                  </div>
                )}
            </Downshift>
            {/* {<div
              onClick={() => (this._scrollRef.scrollTop = 100)}
              style={{ marginTop: "6px", marginBottom: "1em" }}
            >
              <Creatable
                name="file_category"
                label="File Category"
                onChange={(e, val) => {
                  // console.log();
                  this.onFieldChange({ name: "file_category", value: e.value });
                }}
                options={[
                  { value: "Technical Pack", label: "Technical Pack" },
                  { value: "Fabric", label: "Fabric" },
                  { value: "Construction", label: "Construction" }
                ]}
              />
            </div>} */}
          </Form>
        </PerfectScrollbar>
        {/* <div className="add-file__footer modal__footer flex f-sz-m f-w-bold">
          <span onClick={this.handleUpload}>Upload File</span>
        </div> */}
      </div>
    );
  }
}

// const mapStateToProps = (state, ownProps) => {
//   console.log('Inside map state to props');
//   return ({
//   ...state
// })
// }

// export default reduxForm({ form: formName, initialValues: { 'file_title': 'dope'} }, mapStateToProps)(EditFilesForm);
EditFilesForm = reduxForm({
  form: formName  // a unique identifier for this form,
  , enableReinitialize: true,
  touchOnChange: true
})(EditFilesForm)

// You have to connect() to any reducers that you wish to connect to yourself
EditFilesForm = connect(
  (state, ownProps) => {
    return ({
      initialValues: ownProps.file
    })
  }
)(EditFilesForm)

export default EditFilesForm
