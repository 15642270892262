import React from "react";
import Downshift from "downshift";
import icDropdown from "../../../assets/images/navigation/ic-arrow-drop-down.svg";

const Filter = ({
  title,
  onChange,
  initialSelectedItem,
  filterOptions,
  width = 138,
  style = {}
}) => {
  return (
    <Downshift
      initialSelectedItem={initialSelectedItem}
      itemToString={item => item.label}
      onChange={selection => {
        onChange(selection);
        // alert(JSON.stringify(selection))
      }}
    >
      {({
        getInputProps,
        getToggleButtonProps,
        getItemProps,
        getLabelProps,
        getMenuProps,
        isOpen,
        inputValue,
        highlightedIndex,
        selectedItem
      }) => {
        return (
          <div
            {...getToggleButtonProps()}
            type="none"
            style={{
              userSelect: "none",
              position: "relative",
              cursor: "pointer",
              border: "solid 1px var(--fill-grey-light2)",
              borderRadius: "8px",
              padding: "6px 12px",
              width: `${width}px`,
              height: "40px",
              ...style
            }}
          >
            <span
              {...getLabelProps()}
              style={{ fontSize: "12px", color: "var(--fill-grey-medium)", display: "flex", lineHeight: "24px" }}
            >
              <span>{selectedItem ? selectedItem.label : title}</span>
              <img
                src={icDropdown}
                alt="dropdown"
                style={{ marginLeft: "auto" }}
              />
            </span>
            {isOpen ? (
              <div
                className="select-pane"
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                {filterOptions
                  .filter(item => selectedItem ? item.label !== selectedItem.label : true)
                  .map((item, index) => {
                    return (
                      <div
                        {...getItemProps({
                          className:
                            "in-selection collection-collab__label_left_aigned flex",
                          index,
                          item,
                          key: item.label
                        })}
                      >
                        <div style={{textAlign:"left"}}>{item.label}</div>
                      </div>
                    );
                  })}
              </div>
            ) : null}
          </div>
        );
      }}
    </Downshift>
  );
};

export default Filter;
