import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { PageHeader, Button, Modal, Select, Divider, Input, Radio } from "antd";
import Table from "./Table";

import EmptyScreen from "./EmptyScreen";
import {
  getOrgUsers,
  getAccountManagers,
  getCustomers,
  assignUsersToOrg,
  getProductDevelopers,
  updateFilter,
  updateSearch,
} from "store/actions/UserActions";
import {
  createNewBrand,
  getUsersForBrand,
  updateBrandDetails,
} from "store/actions/BrandActions";
import { COUNTRY_OPTIONS, STATUS_OPTIONS } from "./constants";
import "./style.scss";
const { Search } = Input;

const Customers = ({
  customers,
  users,
  brandUsers,
  assignedAccountManagers,
  allAccountManagers,
  productDevelopers,
  openModal,
  closeModal,
  assignUsersToOrg,
  getOrgUsers,
  getCustomers,
  getProductDevelopers,
  getAllAccountManagers,
  getBrandUsers,
  createCustomer,
  updateCustomer,
  updateFilter,
  updateSearch,
  filter,
  search,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [status, setStatus] = useState("");
  const [country, setCountry] = useState("");
  const [accountManagerIds, setAccountManagerIds] = useState([]);

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    updateFilter({
      country,
      status,
      accountManagerIds,
    });
    getCustomers({
      country,
      status,
      accountManagerIds,
    });
    setIsModalVisible(false);
  };

  function onStatusChange({ target: { value } }) {
    setStatus(value);
  }
  function onCountryChange({ target: { value } }) {
    setCountry(value);
  }
  function onAMChange(e) {
    setAccountManagerIds(e);
  }
  function onSearchInput(e) {
    updateSearch({
      searchText: e.target.value,
    });
    getCustomers({
      searchText: e.target.value,
    });
  }
  function filterCancelHandler() {
    setStatus(filter?.status || "");
    setCountry(filter?.country || "");
    setAccountManagerIds(filter?.accountManagerIds || []);
    setIsModalVisible(false);
  }
  function clearFilter() {
    setStatus("");
    setCountry("");
    setAccountManagerIds([]);
  }
  useEffect(() => {
    getCustomers();
    getAllAccountManagers();
    getProductDevelopers();
  }, [getAllAccountManagers, getCustomers, getProductDevelopers]);

  const handleSearch = (value) => {
    return value;
  }
  const { Option } = Select;
  return (
    <div className="settings-customers">
      <PageHeader
        className="site-page-header"
        title="Customers"
        extra={[
          <Search
            placeholder="Input search text"
            allowClear
            enterButton="Search"
            className="search-input"
            onPressEnter={onSearchInput}
          />,
          <Button key="1" type="primary" onClick={showModal} className="filter">
            Filter
          </Button>,
          <Modal
            title="Filter"
            visible={isModalVisible}
            onCancel={() => filterCancelHandler()}
            width={500}
            okText="Apply"
            cancelText="Clear"
            footer={[
              <Button
                key="submit"
                type="secondary"
                onClick={() => clearFilter()}
              >
                Clear
              </Button>,
              <Button key="submit" type="primary" onClick={() => handleOk()}>
                Apply
              </Button>,
            ]}
          >
            <span>
              <h3>Status</h3>
            </span>
            <Radio.Group
              onChange={(e) => onStatusChange(e)}
              optionType="button"
              buttonStyle="solid"
              value={status}
            >
              {STATUS_OPTIONS.map((option) => (
                <Radio.Button value={option.value} key={option.value}>
                  {option.label}
                </Radio.Button>
              ))}
            </Radio.Group>
            <Divider />
            <span>
              <h3>Country</h3>
            </span>
            <Radio.Group
              onChange={(e) => onCountryChange(e)}
              optionType="button"
              buttonStyle="solid"
              value={country}
            >
              {COUNTRY_OPTIONS.map((option) => (
                <Radio.Button value={option.value} key={option.value}>
                  {option.label}
                </Radio.Button>
              ))}
            </Radio.Group>
            <Divider />
            <span>
              <h3>Select Account Manager</h3>
            </span>
            <Select
              showSearch
              placeholder="Select Account Manager"
              onChange={(e) => onAMChange(e)}
              onSearch={(e) => handleSearch(e)}
              optionFilterProp="children"
              className="dropdown-select"
              mode="multiple"
              value={accountManagerIds}
            >
              {allAccountManagers?.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
            ,
          </Modal>,
        ]}
      />
      {customers ? (
        <Table
          data={customers?.data}
          users={users}
          accountManagers={assignedAccountManagers}
        />
      ) : (
        <EmptyScreen />
      )}
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  customers: props.customers || state.user.customers.data,
  users: props.users || state.user.users.data,
  assignedAccountManagers:
    props.assignedAccountManagers || state.user.orgAccountManagers.data,
  allAccountManagers:
    props.allAccountManagers || state.user.accountManagers.data,
  productDevelopers:
    props.productDevelopers || state.user.productDevelopers.data,
  mapUsers: props.mapUsers || state.auth.list,
  brandUsers: props.brandUsers || state.brands.brand_users,
  filter: props.filter || state.user.filter.value,
  search: props.search || state.user.search.value,
});

const mapDispatchToProps = (dispatch) => ({
  getCustomers: (param) => dispatch(getCustomers({ ...param })),
  getOrgUsers: ({ orgId }) => dispatch(getOrgUsers({ orgId })),
  getAllAccountManagers: () => dispatch(getAccountManagers()),
  getProductDevelopers: () => dispatch(getProductDevelopers()),
  assignUsersToOrg: ({ orgId, userIds }) =>
    dispatch(assignUsersToOrg({ orgId, userIds })),
  createCustomer: (data) => dispatch(createNewBrand(data)),
  updateCustomer: (data) => dispatch(updateBrandDetails(data)),
  getBrandUsers: (brandId) => dispatch(getUsersForBrand(brandId)),
  updateFilter: (data) => dispatch(updateFilter(data)),
  updateSearch: (data) => dispatch(updateSearch(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Customers);
