import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import moment from "moment";
import find from "lodash/find";
import TnaHeader from "./TnaHeader";
import TnaGraph from "./TnaGraph";
import TnaAwaited from "./TnaAwaited";
import TnaPlan from "../TnaPlan";
import actions from "../../../store/actions";
import { DATESTAMP__SHORT_FORMAT } from "constants/Date";
import { TNA_ATTRIBUTES_ORDER } from "constants/Tna";
import { get_service_endpoint } from "../../../ServiceEndpoints";
import { APPROVED } from "../../../constants/Sample";
import { TNA_STATUS } from "../../../constants/Tna";
import './BrandTna.scss';

const { SAMPLE_DISPATCH } = TNA_ATTRIBUTES_ORDER;
class BrandTna extends Component {
  state = {
    tnaHeaderData: {
      factory: "",
      orderQuantity: "",
      poQuantity: "",
      poNumber: []
    },
    tnaGraphData: [],
    skipSampleApproval: 0,
    isRequestComplete: false,
    showCta: false
  };

  fetchTna = () => {
    axios
      .get(`${get_service_endpoint("notificationView")}/tna/brandtna/`, {
        params: { designid: this.props.designId } // this.props.designId
      })
      .then(res => {
        let tnaAttributes = [];
        let showCta = false;
        if (res.data.tnaAttributes) {
          tnaAttributes = res.data.tnaAttributes;
        }

        const {
          orderQuantity,
          poNumber,
          assocQuantity,
          skipApproval,
          status
        } = res.data;

        const { sampleDetails } = this.props;

        const productionSample =
          find(sampleDetails.samples, { isProductionSample: 1 }) || {};

        const productionSampleApproved =
          productionSample.sampleStatus === APPROVED;
        let tnaHeaderData = {
          factory: productionSample.factoryName,
          orderQuantity: orderQuantity ? `${orderQuantity} pcs` : "NA",
          poQuantity: assocQuantity ? `${assocQuantity}pcs` : "NA",
          poNumber: poNumber || "NA",
          isSampleApprovalRequired: !skipApproval ? 'Yes' : 'No',
        };

        let tnaGraphData = tnaAttributes.map(tnaAttribute => {
          let minDate, maxDate, planDate;

          minDate = tnaAttribute["data"][0].attributeValue;
          maxDate =
            tnaAttribute["data"][tnaAttribute["data"].length - 1]
              .attributeValue;

          planDate =
            tnaAttribute["data"][tnaAttribute["data"].length - 1]
              .subAttributeValue;

          // In case sample is not approved we take sample dispatch plan date(previous step) as 'by' date for sampling stage
          if (!planDate) {
            planDate = tnaAttribute.data[0].subAttributeValue;
          }

          // In case sample approval is skipped the maxDate will be equivalent to sample dispatch date
          if (tnaAttribute.order === TNA_ATTRIBUTES_ORDER.SAMPLE_DISPATCH.categoryOrder && !maxDate && skipApproval) {
            maxDate = tnaAttribute["data"][0].attributeValue;
          }

          let subCategories = tnaAttribute.data.map(step => {
            let actualDate,
              planDate,
              timelineDate,
              timelineStatus,
              timelineClass;
            actualDate = step.attributeValue;
            planDate = step.subAttributeValue;

            if (!planDate) {
              //Only in PP sample approval case it's null
              if (actualDate) {
                timelineDate = `${moment(actualDate).format(
                  DATESTAMP__SHORT_FORMAT
                )}`;
                timelineStatus = `${step.attribute}`;
                timelineClass = "green";
              } else {
                timelineDate = "";
                timelineStatus = "";
                timelineClass = "";
              }
            } else {
              let timelineData = this.getTimelineStatus(actualDate, planDate);
              timelineDate = timelineData.timelineDate;
              timelineStatus = timelineData.timelineStatus;
              timelineClass = timelineData.timelineClass;
            }

            if (
              step.categoryOrder === SAMPLE_DISPATCH.categoryOrder &&
              step.attributeOrder === SAMPLE_DISPATCH.attributeOrder &&
              step.attributeValue &&
              !productionSampleApproved &&
              !skipApproval
            ) {
              showCta = true;
            }

            return {
              subCategory: step.subCategory,
              actualDate: actualDate,
              planDate: planDate,
              timelineDate,
              timelineStatus,
              timelineClass,
              files: step.files
            };
          });

          return {
            id: tnaAttribute.order,
            category: tnaAttribute.category,
            subCategories,
            minDate: moment(minDate).format(DATESTAMP__SHORT_FORMAT),
            maxDate: moment(maxDate).format(DATESTAMP__SHORT_FORMAT),
            planDate: moment(planDate).format(DATESTAMP__SHORT_FORMAT)
          };
        });

        this.setState({
          ...this.state,
          isRequestComplete: true,
          tnaHeaderData,
          tnaGraphData,
          showCta,
          skipSampleApproval: skipApproval,
          status,
          productionSample
        });
      });
  };

  componentDidMount() {
    const { getSampleHistory, designId } = this.props;

    this.fetchTna();

    getSampleHistory({ designId, isProductionSample: 1 });
  }

  getTimelineStatus = (actualDate, planDate) => {
    let timelineDate, timelineStatus, timelineClass;
    actualDate = actualDate ? moment(actualDate) : null;
    planDate = planDate ? moment(planDate) : null;

    if (actualDate) {
      let datesDiff = actualDate.diff(planDate, "days");

      if (datesDiff < 0) {
        timelineDate = moment(actualDate).format(DATESTAMP__SHORT_FORMAT);
        timelineStatus = `${datesDiff * -1} ${
          datesDiff * -1 > 1 ? "days" : "day"
          } early`;
        timelineClass = "green";
      } else if (datesDiff === 0) {
        timelineDate = moment(actualDate).format(DATESTAMP__SHORT_FORMAT);
        timelineStatus = `On time`;
        timelineClass = "green";
      } else {
        timelineDate = moment(actualDate).format(DATESTAMP__SHORT_FORMAT);
        timelineStatus = datesDiff
          ? `${datesDiff} ${datesDiff > 1 ? "days" : "day"} delay`
          : 'Delayed';
        timelineClass = "red";
      }
    } else {
      timelineDate = `due on ${moment(planDate).format(
        DATESTAMP__SHORT_FORMAT
      )}`;
      timelineStatus = `In progress`;
      timelineClass = "yellow";
    }

    return { timelineDate, timelineStatus, timelineClass };
  };

  render() {
    const {
      tnaHeaderData,
      tnaGraphData,
      isRequestComplete,
      showCta,
      skipSampleApproval,
      status,
      productionSample
    } = this.state;
    const {
      design,
      sampleDetails,
      addNewCommint,
      openVersionHistoryModal,
      openModal,
      closeModal,
      comments,
      sampleHistory,
      collection,
      getFactoryTnaTemplate,
      templateDetails,
      getBrandTnaTemplate,
      quoteDetails,
      updateDesignTnaStatus
    } = this.props;

    const tnaApproved = status === TNA_STATUS.APPROVED;
    const tnaAvailable = status === TNA_STATUS.SUBMITTED || status === TNA_STATUS.RESUBMITTED;

    return (
      <>
        {isRequestComplete ? (
          <div
            className={`brand-tna custom-scroll-container ${!tnaApproved &&
              "blue_container"}`}
          >
            <TnaHeader
              data={tnaHeaderData}
              tnaStatus={status}
              isFactory={false}
              sampleType={productionSample.sampleType}
              sampleSize={productionSample.sampleSize}
            />
            {tnaApproved ? (
              <TnaGraph
                data={tnaGraphData}
                design={design}
                sampleDetails={sampleDetails}
                showCta={showCta}
                addNewCommint={addNewCommint}
                openVersionHistoryModal={openVersionHistoryModal}
                openSampleStatusUpdateModal={commentDetails => {
                  openModal("UPDATE_SAMPLE_STATUS", {
                    commentDetails: commentDetails
                  });
                }}
                isAccordion={true}
                fetchTna={this.fetchTna}
                comments={comments}
                designId={design.id}
                skipSampleApproval={skipSampleApproval}
                sampleHistory={sampleHistory}
              />
            ) : tnaAvailable
                ? <TnaPlan
                  designId={design.id}
                  collection={collection}
                  design={design}
                  brandId={design.brand}
                  getFactoryTnaTemplate={getFactoryTnaTemplate}
                  templateDetails={templateDetails}
                  getBrandTnaTemplate={getBrandTnaTemplate}
                  quoteDetails={quoteDetails}
                  openModal={openModal}
                  closeModal={closeModal}
                  sampleDetails={sampleDetails}
                  updateDesignTnaStatus={(args) => {
                    return updateDesignTnaStatus(args)
                      .then((res) => {
                        if (args.status === 4) {
                          this.fetchTna();
                        }

                        return res;
                      });
                  }}
                  isFactory={false}
                />
                : (
                  <div className="tna-awaited-container">
                    <TnaAwaited />
                  </div>
                )}
          </div>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state, props) => ({
  comments: state.messaging.messages,
  sampleHistory: (props.designId && state.designs.sampleHistory[props.designId]) || {},
});

const mapDispatchToProps = dispatch => ({
  openModal: (content, additionalProps) =>
    dispatch(actions.openModal(content, additionalProps)),
  getSampleHistory: (params) => dispatch(actions.getSampleHistory(params)),
  updateDesignTnaStatus: (params) => dispatch(actions.updateDesignTnaStatus(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BrandTna);
