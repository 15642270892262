import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import { Field } from "formik";
import * as Yup from "yup";
import PerfectScrollbar from 'react-perfect-scrollbar';
import DesignsListSummary from '../SelectDesigns/DesignsListSummary';
import { IconCheckMark, IconPlus, IconCalendar } from '../../../UI/Icons';
import Button from '../../../UI/Button/Button';
import Form from '../../../CustomBrand/UI/Formik/Form';
import DatePickerField from '../../../CustomBrand/UI/Formik/Datepicker';
import File from '../../../CustomBrand/UI/Formik/File';
import Input from '../../../CustomBrand/UI/Formik/Input';
import '../../../Collections/ChooseFactoryModal/ChooseFactoryModal.scss';
import './style.scss';

const ADDRESS_ID = 1;
const PO_DETAILS_ID = 2;
const FILE_SIZE = 5 * 1024 * 1024;
const SUPPORTED_FORMATS = new Set([
  'image/jpeg',
  'image/png',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msexcel',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
]);
const YUP_PO_DETAILS = Yup.object().shape({
  poNumber: Yup.string()
    .max(15)
    .required("Required"),
  deadline: Yup.date()
    .min(Date())
    .required("Required"),
  poFile: Yup.mixed()
    .test('required', "Required", value => value && value.length !== 0)
    .test('only1File', "Only one file is allowed", value => value && value.length === 1)
    .test('fileSize', "File Size is too large", value => value && value[0] && value[0].size <= FILE_SIZE)
    .test('fileType', "Unsupported File Format", value => value && value[0] && SUPPORTED_FORMATS.has(value[0].type)),
});

export default class DeliveryPODetails extends React.Component {
  static propTypes = {
    brand: DesignsListSummary.propTypes.brand,
    factory: DesignsListSummary.propTypes.factory,
    designs: DesignsListSummary.propTypes.designs,
    currency: DesignsListSummary.propTypes.currency,
    poValue: PropTypes.number.isRequired,
    addresses: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      address: PropTypes.string.isRequired,
    })).isRequired,
    onAddNewAddress: PropTypes.func.isRequired,
    onOrgChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  state = {
    activeAccordion: ADDRESS_ID,
    selectedAddress: null,

    propsInitialValues: null,
  };

  constructor(props) {
    super(props);

    const selectedAddress = props.initialValues ? props.initialValues.address : null;

    this.state = {
      activeAccordion: selectedAddress ? PO_DETAILS_ID : ADDRESS_ID,
      selectedAddress: selectedAddress,
    };
    this._refInputPONumber = React.createRef();
  }

  static getDerivedStateFromProps(nextProps, oldState) {
    const nextState = {};

    if (!isEqual(nextProps.initialValues, oldState.propsInitialValues)) {
      nextState.propsInitialValues = nextProps.initialValues;

      if (oldState.propsInitialValues
        && !isEqual(nextProps.initialValues.address, oldState.propsInitialValues.address)
      ) {
        nextState.selectedAddress = nextProps.initialValues.address;

        if (nextState.selectedAddress) {
          nextState.activeAccordion = PO_DETAILS_ID;
        } else {
          nextState.activeAccordion = ADDRESS_ID;
        }
      }
    }

    return Object.keys(nextState).length > 0 ? nextState : null;
  }

  componentDidUpdate(prevProps, prevState) {
    const { activeAccordion } = this.state;

    if (prevState.activeAccordion !== activeAccordion) {
      if (this._refInputPONumber.current) {
        this._refInputPONumber.current.focus();
      }
    }
  }

  _formatAddress = (strAddress) => {
    return strAddress.replace(/(\$\s*)+/g, ', ');
  }

  _handleAddressSelect = (setFieldValue, address) => {
    setFieldValue('address', address);
    this.setState({
      selectedAddress: address,
      activeAccordion: PO_DETAILS_ID,
    });
  }

  _handleAddNewAddress = () => {
    const { onAddNewAddress } = this.props;
    onAddNewAddress();
  }

  _handleAddressChangeClick = () => {
    this.setState({ activeAccordion: ADDRESS_ID });
  }

  _handleFormSubmit = (values) => {
    const { onSubmit } = this.props;

    if (typeof (onSubmit) === 'function') {
      onSubmit({
        ...values,
        poFile: values.poFile[0],
      });
    }
  }

  _renderDeliveryAddresses(setFieldValue) {
    const { addresses } = this.props;
    const { activeAccordion, selectedAddress } = this.state;

    const isActiveAccordion = activeAccordion === ADDRESS_ID;

    return <div className={`order-delivery__address accordion ${isActiveAccordion ? 'accordion--active' : ''}`}>
      <div className="accordion__header">
        <div className="accordion__header__row">
          <div className={`heading ${selectedAddress ? 'heading--active' : ''}`}>
            {isActiveAccordion ? "Select delivery address" : "Delivery address"}
          </div>
          {
            !isActiveAccordion && selectedAddress && <Button
              type="button"
              category="primary-text black-ghost"
              onClick={this._handleAddressChangeClick}
            >CHANGE</Button>
          }
        </div>
        {selectedAddress && <div className="accordion__header__row">
          {
            selectedAddress.title && <div className="selected-address__title">
              {selectedAddress.title}
            </div>
          }
          <div className="selected-address__full">
            {this._formatAddress(selectedAddress.address)}
          </div>
        </div>}
      </div>
      <div className="accordion__content">
        <PerfectScrollbar className="order-delivery__address__list">
          <div className="order-delivery__address__grid">
            {addresses.map(address => <button
              type="button"
              key={address.id}
              className="order-delivery__address__item"
              onClick={() => this._handleAddressSelect(setFieldValue, address)}
            >
              {address.title && <div className="address-title">{address.title}</div>}
              <div className="address-line">{this._formatAddress(address.address)}</div>
              {
                address === selectedAddress
                  ? <div className={classNames("btn btn--grid-2", "btn--blue-bg")}>
                    <IconCheckMark color="white" />
                  SELECTED
                </div>
                  : <div className={classNames("btn btn--grid-2", "btn--primary-text btn--black-ghost")}>
                    <IconCheckMark color="blue" />
                  SELECT
                </div>
              }
            </button>)}
            {/*
            // Enable me once modal form submission is connected
            <button
              type="button"
              className="order-delivery__address__item"
              onClick={this._handleAddNewAddress}
            >
              <div className="address-title">Add new address</div>
              <div className="address-line">Click here to add a new delivery address</div>
              <div className={classNames("btn btn--grid-2", "btn--primary-text btn--black-ghost")}>
                <IconPlus color="blue" />
                ADD ADDRESS
              </div>
            </button> */}
          </div>
        </PerfectScrollbar>
      </div>
    </div>;
  }

  _renderPODetails() {
    const { activeAccordion, selectedAddress } = this.state;

    return <div className={`order-delivery__po-details accordion ${activeAccordion === PO_DETAILS_ID ? 'accordion--active' : ''}`}>
      <div className="accordion__header">
        <div className="heading heading">
          PO Details
        </div>
      </div>
      <div className="accordion__content">

        <div className="order-delivery__po-details__fields">
          <Field
            component={Input}
            type="hidden"
            name="address"
            value={selectedAddress}
          />
          <Field
            component={Input}
            type="hidden"
            name="poValue"
          />
          <Field
            component={Input}
            type="hidden"
            name="designs"
          />
          <Field
            component={Input}
            type="text"
            className="order-delivery__po-details__field"
            label="PO Number"
            required={true}
            autoFocus={true}
            placeholder="Enter PO Number"
            name="poNumber"
          />
          <Field
            component={DatePickerField}
            className="v2"
            name="deadline"
            type="date"
            label="Deadline"
            placeholder="Select date"
            required
            customInput={({ value, onClick }) => (
              <div
                tabIndex="0"
                className="custom_datepicker formik_datepicker v2"
                type="date"
                onClick={onClick}
              >
                {value || (
                  <span className="placeholder f-color-faded">
                    Select date
                  </span>
                )}
                <IconCalendar color="grey-medium" />
              </div>
            )}
          />
          <Field
            component={File}
            className="order-delivery__po-details__field"
            label="Attach PO"
            name="poFile"
            required={true}
          />
        </div>

        {/* <div className="order-delivery__po-details__fields">
          <CustomInput
            ref={this._refInputPONumber}
            className="order-delivery__po-details__field"
            label="PO Number"
            required={true}
            autoFocus={true}
            placeholder="Enter PO Number"
          />
          <CustomInput
            className="order-delivery__po-details__field"
            label="Deadline"
            required={true}
            placeholder="Select Date"
            suffixText={<IconCalendar color="grey-medium" />}
          />
          <CustomFile
            className="order-delivery__po-details__field"
            label="Attach PO"
            required={true}
          />
        </div> */}
      </div>
    </div>;
  }

  render() {
    const { factory, brand, currency, onOrgChange, designs, poValue } = this.props;

    return <Form
      className="order-delivery accordion"
      validationSchema={YUP_PO_DETAILS}
      validateOnMount={true}
      onSubmit={this._handleFormSubmit}
      enableReinitialize
      initialValues={{
        poValue,
        designs,
      }}
    >
      {({ isValid, setFieldValue }) => <>
        <div className="order-delivery__main">
          {this._renderDeliveryAddresses(setFieldValue)}
          {this._renderPODetails()}
        </div>
        <div className="order-delivery__summary">
          <DesignsListSummary
            factory={factory}
            brand={brand}
            designs={designs}
            currency={currency}
            poValue={poValue}
            isReadonly={true}
            submitText="CREATE ORDER"
            submitTooltip={!isValid && "Enter delivery and PO details to place order"}
            onRemoveDesign={this._handleRemoveDesign}
            onOrgChange={onOrgChange}
            canContinue={isValid}
          />
        </div>
      </>
      }
    </Form>;
  }
}
