import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet'
import qs from 'query-string';

import Quotes from '../../components/Quotes/index';
import actions from '../../store/actions';
import { PAGE_TITLES } from "../../constants/Titles";

const DEFAULT_SELECTED_TAB_FILTER = 'all';

const getSelectedTabFilter = (location) => {
  return (location
    && location.search
    && qs.parse(window.appHistory.location.search.slice(1))['tab'])
    || DEFAULT_SELECTED_TAB_FILTER;
}

class QuotesContainer extends React.Component {
  state = {
    selectedTabFilter: DEFAULT_SELECTED_TAB_FILTER,
  };

  constructor(props) {
    super(props);

    const {
      getAllBrands,
      getAllFactories,
      userDetails,
      location
    } = this.props;

    this.state.selectedTabFilter = getSelectedTabFilter(location);

    if (typeof (getAllBrands) === 'function' && !userDetails.isBrand) {
      getAllBrands();
    }

    if (typeof (getAllFactories) === 'function' && !userDetails.isFactory) {
      getAllFactories();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.location) {
      const selectedTabFilter = getSelectedTabFilter(nextProps.location);

      if (selectedTabFilter !== prevState.selectedTabFilter) {
        return { selectedTabFilter };
      }
    }

    return null;
  }

  render() {
    const {
      openModal,
      quotes,
      getAllQuotes,
      allBrands,
      allFactories,
      userDetails,
    } = this.props;
    const { selectedTabFilter } = this.state;

    return (
      <div className='quotes-container scrollable'>
        <Helmet
          bodyAttributes={{
            class: 'body_container'
          }}
          title={PAGE_TITLES.quotes()}
        />
        <Quotes
          openModal={openModal}
          quotes={quotes}
          getAllQuotes={getAllQuotes}
          allBrands={allBrands}
          allFactories={allFactories}
          selectedTabFilter={selectedTabFilter}
          userDetails={userDetails}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  allBrands: state.products.allBrands,
  allFactories: state.quotes.factories,
  userDetails: state.auth.userDetails,
  quotes: state.quotes.quotes,
});

const mapDispatchToProps = dispatch => ({
  getAllBrands: () => dispatch(actions.getAllBrands()),
  getAllFactories: () => dispatch(actions.quotes.getAllQuotesFactories()),
  getAllQuotes: params => dispatch(actions.quotes.getAllQuotes(params)),
  openModal: (name, additionalProps) => dispatch(actions.openModal(name, additionalProps)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuotesContainer);
