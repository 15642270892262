import React, { Component } from "react";
import moment from "moment";
import "./QuoteDetails.scss";
import QuoteDropdownList from "./QuoteDropdownList/index";
// import Input from "./../../UI/Form/Input/Input";
import { CURRENCY } from "./../../../constants/Design";
import InfoButton from "../../UI/InfoButton/InfoButton";
import icSamplingEmpty from "../../../assets/images/emptyPages/ic-quote-empty.svg";
import plusIcon from "assets/images/content/ic-plus.png";

import Button from "../../UI/Button/Button";
import RequestedQuoteDetails from "./RequestedQuoteDetails/index";
import EmptyList from "./EmptyList";
import { PRODUCTION_MERCHANT } from "./../../../constants/Auth";
import isEmpty from "lodash/isEmpty";
import {
  getCoverImagePlaceholder,
  hasQuoteRequestExpired
} from "./../../../helpers/DesignHelpers";
import { IconPlus } from "../../UI/Icons";
import { DATESTAMP_FORMAT } from "../../../constants/Date";
import QuoteAccordion from "./QuoteAccordion";
import { QUOTE_REQ_EDIT_MODE } from "constants/Quote";

export default class QuoteDetails extends Component {
  state = {
    // quoteSummary: {},
    quoteDetails: {},
    quoteAccepted: false,
    quoteSubmitted: false,
    quotePreapproved: false,
  };

  convertPropsToState = nextProps => {
    let quoteAccepted = this.state.quoteAccepted;
    let quoteSubmitted = this.state.quoteSubmitted;
    let quotePreapproved = this.state.quotePreapproved;
    const { quoteRequestDetails } = nextProps;

    const quoteDetails = { ...nextProps.quoteDetails };
    Object.values(quoteDetails).forEach(quotes => {
      Object.entries(quotes).forEach(([quoteId, quoteValue]) => {
        if (
          !this.state.quoteAccepted &&
          // Check even if one of the quotes has been accepted
          Number(quoteValue["status"]) === 1
        ) {
          quoteAccepted = true;
          if(quoteValue['is_pre_approved']) {
            quotePreapproved = true;
          }
        }
        if (
          !this.state.quoteSubmitted &&
          // Check even if one of the quotes has been accepted
          Number(quoteValue["status"]) === 0
        ) {
          quoteSubmitted = true;
        }
        if (quoteValue["status"] === "-1" && quoteRequestDetails) {
          quotes[quoteId] = {
            ...quoteValue,
            total_quantity: quoteRequestDetails.quantity
          };
        } else {
          quotes[quoteId] = { ...quoteValue };
        }
      });
    });
    this.setState({
      ...this.state,
      quoteDetails: quoteDetails || {},
      // quoteSummary: nextProps.quoteSummary || {},
      quoteAccepted,
      quoteSubmitted,
      quotePreapproved
    });
  };

  componentWillMount() {
    Promise.all([this.props.getQuoteDetails()]).then(() =>
      this.convertPropsToState(this.props)
    );
  }

  componentWillReceiveProps(nextProps) {
    this.convertPropsToState(nextProps);
  }

  updateRequestModal = (editMode) => {
    const { openModal, quoteDetails, quoteRequestDetails, design } = this.props;
    openModal("UPDATE_QUOTE_FACTORIES", {
      editMode,
      requestDetails: quoteRequestDetails,
      design,
      quoteDetails,
      title: 'Edit request details',
    });
  };

  _handleCreateQuote = () => {
    const { createQuote, quoteRequestDetails } = this.props;
    const values = {
      quote_type: quoteRequestDetails.type === "0" ? "OPEN" : "BUNDLED",
      status: "-1",
      total_cost: 0,
      total_quantity: quoteRequestDetails.quantity
    };

    if (values["quote_type"] === "OPEN") {
      values["quote"] = {
        table: {
          fabrics: [{ name: "Fabric" }],
          trims: [
            { name: "M / Label" },
            { name: "Wash Care Label" },
            { name: "Hang Tag" },
            { name: "Thread" },
            { name: "Fusing" },
            { name: "Buttons" }
          ],
          "packaging material": [
            { name: "Poly Bags" },
            { name: "Packing Material" },
            { name: "Transportation" }
          ],
          CMTP: [
            { name: "Pattern + Cutting" },
            { name: "Stitching" },
            { name: "Testing" },
            { name: "Washing" },
            { name: "Finishing / Packing" },
            { name: "Buttoning / Hole" },
            { name: "Sample Pieces" }
          ]
        },
        additionalCosts: {
          Overheads: 0,
          "Factory Profit": 0,
          "LF Profit": 0,
          Profit: 0,
          Dumpage: 0
        },
        totalCost: 0,
        total_quantity: quoteRequestDetails.quantity
      };
    } else if (values["quote_type"] === "BUNDLED") {
      values["quote"] = {
        quantity: quoteRequestDetails.quantity,
        // quotation_currency: 0,
        price_per_garment: 0,
        totalCost: 0,
        bundled_quantity: quoteRequestDetails.quantity
      };
    }

    createQuote(values);
  };

  render() {
    // FIXME: Refactor when desig stages are in place.
    // When quote is approved but no sample has been requested
    // const shouldShowPrompt =
    //   find(this.props.quoteDetails, { status: "1" }) &&
    //   this.props.sampleDetails.id == null;

    const { currentUser, openModal } = this.props;
    const canRequestQuote = currentUser.isBrand || currentUser.isSuperAdmin;
    const design = this.props.design || {};
    const file =
      design &&
      design.files &&
      Object.values(design.files).find(file => file.is_cover === 1);
    const fileloc =
      (file && file.fileloc) || getCoverImagePlaceholder(design).src;
    let quoteHeading = "No Quote shared";
    let isExpired = false;
    let closureDate = "";

    if (
      this.props.quoteRequestDetails &&
      this.props.quoteRequestDetails.closure_date
    ) {
      isExpired = hasQuoteRequestExpired(
        this.props.quoteRequestDetails.closure_date
      );

      // remainingTime = moment.preciseDiff(moment(this.props.quoteRequestDetails.closure_date), moment(new Date().toISOString()));  // moment-precise-range-plugin library
      closureDate = moment(this.props.quoteRequestDetails.closure_date).format(
        DATESTAMP_FORMAT
      );
      quoteHeading = isExpired
        ? `Request expired`
        : `Request expires on ${closureDate} midnight`;
    }

    let disableQuoteActions = design.factory_assigned
      ? currentUser.brandId !== design.factory_assigned
      : false;

    if (!this.props.quoteRequestDetails) {
      disableQuoteActions = true;
    }

    return (
      <div className="quote-details custom-scroll-container">
        {/* {shouldShowPrompt && (
          <SampleRequestPrompt
            openModal={this.props.openModal}
            design={this.props.design}
            sampleDetails={this.props.sampleDetails}
          />
        )} */}
        {this.props.quoteRequestDetails && (
          <RequestedQuoteDetails
            details={this.props.quoteRequestDetails}
            quotesNotAvailable={isEmpty(this.state.quoteDetails)}
            currentUser={this.props.currentUser}
            openUpdateRequestModal={() => this.updateRequestModal(QUOTE_REQ_EDIT_MODE.EditDetails)}
            quoteAccepted={this.state.quoteAccepted}
            quotePreapproved={this.state.quotePreapproved}
            design={design}
            openModal={openModal}
          />
        )}
        {// this.state.quoteSummary &&
          Object.keys(this.state.quoteDetails).length > 0 ? (
            <React.Fragment>
              <div className="cta-new-quote">
                {canRequestQuote && !this.state.quoteAccepted && (
                  <Button
                    category="plain-blue"
                    onClick={() => this.updateRequestModal(QUOTE_REQ_EDIT_MODE.AddFactories)}
                  >
                    + Request quote
                  </Button>
                )}
              </div>
              <QuoteDropdownList
                initialQuoteId={
                  this.state.quoteAccepted && this.state.quoteDetails["1"]
                    ? this.state.quoteDetails["1"][0]
                    : null
                }
                collection={this.props.collection}
                design={this.props.design}
                anyQuoteAccepted={this.state.quoteAccepted}
                readOnly={this.props.readOnly}
                currency={CURRENCY[1]}
                updateQuote={this.props.updateQuote}
                approveQuote={this.props.approveQuote}
                openModal={this.props.openModal}
                openRejectQuoteModal={this.props.openRejectQuoteModal}
                openApproveQuoteModal={this.props.openApproveQuoteModal}
                quoteDetails={this.state.quoteDetails}
                createQuote={this.props.createQuote}
                deleteQuote={
                  this.props.deleteQuote // delete quote
                }
                currentUser={this.props.currentUser}
                quoteRequestDetails={this.props.quoteRequestDetails}
                flashError={this.props.flashError}
              />
            </React.Fragment>
          ) : !this.props.readOnly && !disableQuoteActions ? (
            <div className="empty-quote">
              <EmptyList
                heading={
                  this.props.currentUser.isBrand ? "Quote awaited" : quoteHeading
                }
                description={
                  this.props.currentUser.isBrand ? (
                    <>
                      You have requested a quote. Factory <br />
                    will share the quote shortly
                    </>
                  ) : isExpired || disableQuoteActions ? (
                    <>
                      Submission deadline for this quote has expired.
                      <br />
                    The brand is not accepting more quotes.
                    </>
                  ) : (
                        <>Create a quote for this design.</>
                      )
                }
                cta={
                  this.props.currentUser &&
                  this.props.currentUser.isFactory && (
                    <>
                      <Button
                        category="white-bg"
                        onClick={() =>
                          window.appHistory.push(
                            `/factory/brands/${design.brand}/collections/${design.collection_id}/designs/${design.id}/?tab=summary`
                          )
                        }
                      >
                        <span>View Design Details</span>
                      </Button>
                      {!isExpired && !disableQuoteActions && (
                        <Button
                          className="cta-create-quote"
                          category="quote-blue-bg"
                          onClick={this._handleCreateQuote}
                        >
                          <IconPlus color="white" />
                          <span className="icon-text">Create Quote</span>
                        </Button>
                      )}
                    </>
                  )
                }
                designCoverPicUrl={fileloc}
                isExpired={isExpired}
              />
            </div>
          ) : this.props.currentUser.role == PRODUCTION_MERCHANT ? (
            <div className="design-files__no-files">
              <InfoButton
                hasButton={false}
                icon={<img src={icSamplingEmpty} alt="empty icon" />}
                title="Awaiting Quote Approval"
              />{" "}
            </div>
          ) : (
                  <div className="empty-quote">
                    <EmptyList
                      isFactory={
                        this.props.currentUser && this.props.currentUser.isFactory
                      }
                      heading={
                        this.props.currentUser.isBrand
                          ? "Quote awaited"
                          : "No quotes shared"
                      }
                      description={
                        this.props.currentUser.isBrand ? (
                          <>
                            You have requested a quote. Factory <br />
                    will share the quote shortly.
                          </>
                        ) : disableQuoteActions ? (
                          "The brand is not accepting quotes anymore."
                        ) : (
                              <>Create a quote for this design.</>
                            )
                      }
                      cta={
                        this.props.currentUser.isFactory &&
                        !disableQuoteActions && (
                          <Button
                            category="blue-bg"
                            onClick={this.props.openCreateQuoteModal}
                          >
                            <img src={plusIcon} alt="" />
                            <span>Create Quote</span>
                          </Button>
                        )
                      }
                    />
                  </div>
                )}
      </div>
    );
  }
}
