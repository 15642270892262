import React, { useState, useEffect } from "react";
import Pagination from "components/Product/Pagination";
import { TextSpliced } from "components/UI/TextSpliced";

import NavHeaders from "components/NavHeaders";
import FactoryCheckbox from "./FactoryCheckbox";
import FactoryRadio from "./FactoryRadio";
import { ReactComponent as LocationIcon } from "assets/images/image/pin_outlined.svg";
import { ReactComponent as MachineIcon } from "assets/images/image/ic_machine_outlined.svg";
import EmptyScreen from "components/UI/EmptyScreen";
import thumbsDownImg from "assets/images/action/ic-thumbsup-grey.svg";
import searchIcon from "assets/images/action/ic-search-grey.svg";
import { ReactComponent as CancelIcon } from "assets/images/navigation/ic-close-small.faded.svg";

const LIMIT = 10;

const FactoriesListing = ({
  factories,
  setSelectedFactories,
  handleSearch,
  selectFactory,
  searchTerm,
  preApproved,
}) => {
  const [currentOffset, setCurrentOffset] = useState(0);

  useEffect(() => {
    setSelectedFactories(factories);
  }, [factories]);

  const displayFactories = factories.slice(
    currentOffset,
    currentOffset + LIMIT
  );
  return (
    <div className="factories-section">
      <div className="header">
        <div className="search-factory">
          <img src={searchIcon} alt="" />

          <input
            type="text"
            value={searchTerm}
            onChange={(e) => {
              handleSearch(e.target.value);
            }}
            placeholder="Search factory"
          />
          {searchTerm.length > 0 && (
            <span onClick={() => handleSearch("")}>
              <CancelIcon />
            </span>
          )}
        </div>

        <Pagination
          onclick={(page) => {
            setCurrentOffset(page.offset || 0);
          }}
          page={{
            limit: LIMIT,
            totalCount: factories.length,
            nextOffset: currentOffset + LIMIT,
            prevOffset: currentOffset - LIMIT,
          }}
          innerWrapperStyle={{ paddingRight: 0 }}
          currentIndex={currentOffset / LIMIT}
          showTotalRecords={false}
        />
      </div>
      <div className="factory-listing">
        <NavHeaders tab="quoteRequest" />

        {displayFactories.length > 0 ? (
          factories
            .slice(currentOffset, currentOffset + LIMIT)
            .map((factory) => (
              <FactoryRow
                factory={factory}
                handleClick={selectFactory}
                preApproved={preApproved}
              />
            ))
        ) : (
          <EmptyScreen
            icon={thumbsDownImg}
            heading="No matching results"
            description={
              <>
                No results found for <b>"{searchTerm}"</b>
              </>
            }
          />
        )}
      </div>
    </div>
  );
};

const FactoryRow = ({
  factory: {
    name,
    addresses,
    factoryDetail,
    id,
    selected = false,
    disabled = false,
    recent = false,
  },
  handleClick,
  preApproved,
}) => {
  return (
    <div className="factory-row">
      {preApproved ? (
        <FactoryRadio
          factoryName={name}
          selected={selected}
          factoryId={id}
          handleClick={handleClick}
        />
      ) : (
        <FactoryCheckbox
          factoryName={name}
          selected={selected}
          factoryId={id}
          handleClick={handleClick}
          disabled={disabled}
          recent={recent}
        />
      )}

      <p>{factoryDetail.category || "-"}</p>
      <div className="with-icon">
        {addresses[0] && addresses[0].city ? (
          <>
            <LocationIcon />
            <TextSpliced text={addresses[0].city} maxWidth="130" />
          </>
        ) : (
          "-"
        )}
      </div>
      <div className="with-icon machine">
        {addresses[0] && addresses[0].machines ? (
          <>
            <MachineIcon />
            <TextSpliced text={`${addresses[0].machines}`} maxWidth="100" />
          </>
        ) : (
          "-"
        )}
      </div>
    </div>
  );
};
export default FactoriesListing;
