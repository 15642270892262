import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getImageUrl } from "helpers/DesignHelpers";

export default class SubTask extends Component {

  render() {

    const {
      task
    } = this.props;

    const {
      cover_pic_url,
      category,
      design_name,
      CTA,
      collection_id,
      design_id
    } = task;

    return (
      <div className="quote">
        <div className="quote-status-section task-req-section">
          <span className="status-section-img">
            <img src={getImageUrl(cover_pic_url, { category })} className="status-img" alt="user avatar"/>
          </span>
          <div className="status-section-content">
            <span className="status-title-top">{design_name}</span>
            <Link to={`/brand/collections/${collection_id}/designs/${design_id}/?tab=summary`}>
              <span className="req-sample-blue">{CTA}</span>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
